import type { DialogueCandidateType } from 'frontend/api/generated';

import styles from './ActionBy.scss';

const ActionBy = ({ rowData }: { rowData: DialogueCandidateType }) => (
  <div className={styles.actionBy}>
    <img
      className={styles.actionByAvatar}
      src={rowData.actionBy?.profile.avatarUrl}
      alt={rowData.actionBy?.profile.fullName}
    />
    {rowData.actionBy?.profile.fullName}
  </div>
);

export default ActionBy;
