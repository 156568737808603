import { compose, get, lowerCase, orderBy } from 'lodash/fp';
import { useMemo } from 'react';

import type { LabelType } from 'frontend/api/generated';
import { LabelBadge } from 'frontend/components';
import AdvancedTooltip from 'frontend/components/AdvancedTooltip/AdvancedTooltip';

import styles from './LabelsList.scss';

const getLabelArrays = (labels, maxItems) => {
  const sortedLabels = orderBy(compose(lowerCase, get('name')), 'asc')(labels);
  return [sortedLabels.slice(0, maxItems), sortedLabels.slice(maxItems)];
};

interface LabelListProps {
  labels: Partial<LabelType>[];
  maxItems?: number;
}

const LabelList = ({ labels, maxItems }: LabelListProps) => {
  const [currentLabels, restLabels] = useMemo(() => getLabelArrays(labels, maxItems), [labels, maxItems]);

  return (
    <div className={styles.labelsContainer}>
      {currentLabels?.map((label) => <LabelBadge key={`label-${label.id}`} label={label} className={styles.label} />)}

      {maxItems && labels.length > maxItems && (
        <AdvancedTooltip maxWidth="260" className={styles.hiddenLabels}>
          +{labels.length - maxItems}
          <AdvancedTooltip.Body>
            <div className={styles.labelsContainer}>
              {[...(currentLabels || []), ...(restLabels || [])]?.map((label) => (
                <LabelBadge key={`label-${label.id}`} label={label} className={styles.label} />
              ))}
            </div>
          </AdvancedTooltip.Body>
        </AdvancedTooltip>
      )}
    </div>
  );
};

export default LabelList;
