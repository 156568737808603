import { Field, Form } from 'react-final-form';

import { PaintbucketTilted } from 'frontend/assets/icons';
import {
  Button,
  ColorPickerInput,
  FileUpload,
  FormErrors,
  Input,
  PageBar,
  Panel,
  ToggleSwitch,
} from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { chain, max as maxLength, url } from 'frontend/form/validators';
import urlToDomain from 'frontend/utils/urlToDomain';

import DeleteDemoModal from './DeleteDemoModal';
import { useDemoForm } from '../../../../hooks';
import styles from '../Demo.scss';

const maxSlugLength = 100;

const noop = () => null;

const DemoForm = () => {
  const { initialValues, botId, onSubmit, setFile } = useDemoForm();
  const [showDeleteDemoModal] = useModal(DeleteDemoModal);

  return (
    <div className={styles.demoLayout}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form: { change }, values: { image } }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <PageBar>
              <PageBar.FormButtons />
            </PageBar>
            <FormErrors />
            <Panel>
              <div className={styles.settingsContainer}>
                <h3 className={`${styles.sectionTitle} m-b-3`}>Demo page</h3>
                <p className={styles.demoIntroduction}>
                  Create a demo page to test the chatbot. Don’t worry if it isn’t perfect yet, you can always take it
                  down and create a new one later.
                </p>
                <h5 className="m-b-1">URL</h5>
                <div className={styles.flexGroup}>
                  <span className={styles.url}>{urlToDomain(window.env.KINDLY_DEMO_HOST)}/</span>
                  <Field component={Input} name="slug" placeholder="botname" validate={maxLength(maxSlugLength)} />
                  <Button
                    onClick={() => {
                      window.open(`${window.env.KINDLY_DEMO_HOST}/${initialValues.slug}`, '_blank');
                    }}
                    size="small"
                    className="m-l-3"
                    text="Open"
                  />
                </div>
                <div className="m-t-4 m-b-4" />
                <h5 className="m-b-1">Background color</h5>
                <div className={styles.flexGroup}>
                  <ColorPickerInput
                    name="colorBackground"
                    placeholder="#FFFFFF"
                    aria-label="Background color"
                    icon={PaintbucketTilted}
                  />
                </div>
                <div className="m-t-3 m-b-4" />
                <div className={styles.flexGroup}>
                  <div className={`${styles.flexLeft} p-r-1`}>
                    <h5 className="m-b-1">Background image</h5>
                    <p className="m-b-2">
                      This image will be placed in the center of the page. Required aspect ratio is 16:9, and the
                      maximum file size is 3 MB.
                    </p>
                    <ToggleSwitch name="isBackgroundCover" status={['Cover entire background']} />
                    <div className={`${styles.uploadButtons} m-t-2`}>
                      <Field component={Input} name="image" hidden />
                      <FileUpload
                        text="Upload image"
                        sizeLimitKb={5 * 1024}
                        onUpload={([{ file, source }]) => {
                          setFile(file);
                          change('image', source);
                        }}
                      />
                      {image && <Button onClick={() => change('image', null)} flat text="Delete" />}
                      {/* Make sure field is registered: https://github.com/final-form/final-form/issues/169#issuecomment-430939734 */}
                      <Field name="image" component={noop} />
                    </div>
                  </div>
                  <div className={`${styles.flexRight} p-l-sm`}>{image && <img src={image} alt="Uploaded" />}</div>
                </div>
                <div className="m-t-5" />
                <ToggleSwitch name="includeDisabledLanguages" status={['Include disabled languages']} />
                <p>
                  Disabled languages are by default only available within the Kindly platform. If you enable this option
                  they will also be available on the demo page.
                </p>
                <h5 className="m-b-1 m-t-5">Website url</h5>
                <p className="m-b-2">
                  Cover the background with a given website. NB: Won&apos;t work with every webpage.
                </p>
                <Field
                  component={Input}
                  name="websiteUrl"
                  placeholder="https://example.com"
                  validate={chain(maxLength(2048), url)}
                />

                <h5 className="m-t-5 m-b-1">Delete demo</h5>
                <Button text="Delete" color="warning" onClick={() => showDeleteDemoModal({ botId })} />
              </div>
            </Panel>
          </form>
        )}
      />
    </div>
  );
};

export default DemoForm;
