import cx from 'classnames';

import styles from './Icon.scss';

const COLORS = [
  'default',
  'idle',
  'primary',
  'warning',
  'support',
  'success',
  'orange',
  'dark',
  'white',
  'black',
  'gray',
  'lightGray',
  'darkGray',
  'text',
  'kindlyAdmin',
] as const;
export type IconColor = (typeof COLORS)[number];
const HOVER_COLORS = [
  'default',
  'primary',
  'idle',
  'warning',
  'support',
  'orange',
  'dark',
  'white',
  'black',
  'darken',
  'lighten',
  'kindlyAdmin',
] as const;
export type IconHoverColor = (typeof HOVER_COLORS)[number];

interface IconProps extends Omit<React.SVGProps<SVGSVGElement>, 'color'> {
  className?: string;
  /** Icon stroke color. */
  color?: IconColor | null;
  /** The icon to use (as React component). */
  component: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  /** Icon stroke color when hovered. */
  hoverColor?: IconHoverColor;
  /** Callback that runs on click. If given, the icon gets interactive attributes/styles. */
  onClick?(event?: React.MouseEvent<SVGSVGElement>): void;
  /** Icon title-tooltip when hovered. */
  title?: string;
}
export const Icon = ({ component: Component, color, hoverColor, className, onClick, ...rest }: IconProps) => {
  const iconStyles = cx(styles.icon, className, {
    [styles[color || '']]: color && COLORS.includes(color),
    [styles[`${hoverColor}Hover`]]: hoverColor && HOVER_COLORS.includes(hoverColor),
    [styles.isButton]: onClick,
  });

  // Adds usability options if the icon is clickable
  const usabilityOptions = onClick
    ? {
        tabIndex: 0,
        role: 'button',
        onClick,
        onKeyDown: (e) => {
          if (e.key === 'Enter') onClick();
        },
      }
    : null;

  return <Component className={iconStyles} {...usabilityOptions} {...rest} />;
};
