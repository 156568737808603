import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { VIEW_CONVERSATION_BOTS } from 'frontend/features/Inbox/queries';

/* This implies that the chat data still reaches the browser in a websocket, it's just
not displayed to the user. This is probably not great security and should be fixed.
The naive solution is to have one Pusher channel per bot with permission instead of one
for the whole organization, but this will escalate the number of Pusher channels. */

export default function useViewConversation() {
  const { organizationId } = useParams();
  const { data, loading } = useQuery(VIEW_CONVERSATION_BOTS, {
    variables: { organizationId },
    skip: !organizationId,
  });

  const viewConversation = useCallback(
    ({ botId = undefined } = {}) => {
      if (!organizationId) return true;
      if (loading) return false;

      return data?.organization?.viewConversationBots.map(Number).includes(Number(botId));
    },
    [data, loading, organizationId],
  );

  return viewConversation;
}
