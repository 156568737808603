import cx from 'classnames';
import { useCallback, useState } from 'react';

import type { SkillSubscriptionType } from 'frontend/api/generated';
import { DIALOGUE_TYPES, buildTypes } from 'frontend/constants';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import styles from './Subscription.scss';
import { useSubscriptionContextMenu } from './hooks';
import BottomFolder from '../BottomFolder';

function checkBreadcrumbs(skillId: string, breadcrumbs: { type: string; id: string }[] | undefined): boolean {
  return (breadcrumbs || []).some(({ type, id }) => type === buildTypes.SKILL && id === skillId);
}

type Props = {
  buildIdObject: BuildIdObject;
  subscription: Pick<SkillSubscriptionType, 'name' | 'isActive'> & { skill: { id: string } };
  indentationLevel: number;
};
const Subscription = ({ buildIdObject, subscription, indentationLevel }: Props) => {
  const {
    name,
    isActive,
    skill: { id: skillId },
  } = subscription;
  const disabled = !isActive;
  const className = cx(styles.subscription, { [styles.subscriptionDisabled]: disabled });
  const { contextMenuId, actions } = useSubscriptionContextMenu({ buildIdObject, subscription });
  const [showLoader, setShowLoader] = useState(false);

  const checkBreadcrumbsMemoed = useCallback((_, breadcrumbs) => checkBreadcrumbs(skillId, breadcrumbs), [skillId]);

  return (
    <BottomFolder
      dialogueType={DIALOGUE_TYPES.REGULAR}
      skillId={skillId}
      name={name}
      buildIdObject={buildIdObject}
      disabled={disabled}
      className={className}
      indentationLevel={indentationLevel}
      checkBreadcrumbs={checkBreadcrumbsMemoed}
      contextMenuId={contextMenuId}
      actions={actions}
      showLoader={showLoader}
      setShowLoader={setShowLoader}
    />
  );
};

export default Subscription;
