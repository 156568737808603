import { gql } from '@apollo/client';

// TODO: Delete

export default gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
