import cx from 'classnames';
import React from 'react';
import type { FieldRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import Select from 'frontend/components/Select/Select';
import { useCurrentLanguage } from 'frontend/hooks';
import type { LanguageOrVariant } from 'frontend/hooks/useLanguages';
import { selectHasChanges } from 'frontend/state/dux/build';

import styles from './SelectLanguage.scss';

interface CommonSelectorProps {
  className?: string;
  /** Array of language objects. */
  languages?: LanguageOrVariant[];
  /** Controls whether to disable the input. */
  disabled?: boolean;
  wrapperClassName?: string;
}

interface SelectorProps extends CommonSelectorProps, FieldRenderProps<string> {
  onClick?(): void;
}

const Selector = ({ className, wrapperClassName, onClick, languages = [], input, disabled, meta }: SelectorProps) => (
  <Select
    input={input}
    className={cx(className, styles.selectLanguage)}
    wrapperClassName={wrapperClassName}
    onClick={onClick}
    disabled={disabled}
    meta={meta}
  >
    {languages.map((lang) => {
      const isVariant = 'baseLanguageCode' in lang && lang.baseLanguageCode;
      const firstVariantCode = 'variantsInWorkspace' in lang ? lang.variantsInWorkspace[0]?.code : undefined; // this is a base language, then we forward his first variant (if any)

      return (
        <Select.Option
          key={lang.name}
          value={lang.code}
          label={lang.name}
          data-lang={isVariant ? lang.baseLanguageCode : lang.code}
          data-variant={isVariant ? lang.code : null}
          data-first-variant={firstVariantCode}
        />
      );
    })}
  </Select>
);

interface SelectLanguageProps extends CommonSelectorProps {
  /** Controls whether to save guard before switching language. */
  guardChanges?: boolean;
  /** If true, expect a mixed list of languages and variants (if any). Set it to false if it has to work only with base languages. */
  supportVariants?: boolean;
}

const SelectLanguage = ({
  className,
  languages,
  guardChanges = true,
  disabled = false,
  supportVariants = true,
  wrapperClassName,
}: SelectLanguageProps) => {
  const [{ selectedLanguage, currentLanguage }, setCurrentLanguage] = useCurrentLanguage();
  const buildHasChanges = useSelector(selectHasChanges);

  const input = {
    name: 'pageBarSelectLanguage',
    value: supportVariants ? selectedLanguage : currentLanguage,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const { selectedOptions } = e.target;
      const selectedOption = selectedOptions[0]!; // banging because onChange is only called when a value is selected
      const optionSelectedLanguage = selectedOption.dataset.lang!;
      const optionSelectedVariant = selectedOption.dataset.variant;
      const optionSelectedFirstVariant = selectedOption.dataset.firstVariant;

      if (supportVariants) {
        setCurrentLanguage(optionSelectedLanguage, optionSelectedVariant);
      } else {
        setCurrentLanguage(optionSelectedLanguage, optionSelectedFirstVariant);
      }
    },
    onBlur: () => undefined,
    onFocus: () => undefined,
  };

  const onClick =
    // eslint-disable-next-line no-alert
    () => guardChanges && buildHasChanges && alert('You need to save or clear changes before switching language.');

  return (
    <Selector
      className={className}
      wrapperClassName={wrapperClassName}
      input={input}
      onClick={onClick}
      languages={languages}
      disabled={disabled}
      meta={{}}
    />
  );
};

SelectLanguage.Selector = Selector;

export default SelectLanguage;
