export default async (uploadPath: string, accessToken: string, file: Blob, extra: Record<string, string> = {}) => {
  const formData = new FormData();
  formData.append('file', file, extra?.name);

  Object.entries(extra).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return fetch(`${window.env.API_URL}/${uploadPath}`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
