/**
  Clears the cache for all "FieldsToClear", regardless of variables, unless they are defined in `nonExhaustiveVariables`.
  This is used to clear the cache for fields potentially containing a variables in `variablesToClear`.
  Active queries will automatically refresh when the cache is invalidated.
  @example
  // Will clear the cache for all 'Dialogues' fields with the botId '123', or no botId.
  removeFieldsFromKeyWithPotentialVariables(client, ['Dialogues'], { botId: '123' })
*/

import type { ApolloClient } from '@apollo/client';

export default (
  client: ApolloClient<object>,
  fieldsToClear: string[],
  nonExhaustiveVariables: { [key: string]: any },
) => {
  // @ts-expect-error FIXME: How to allow accessing client.cache.data...?
  Object.keys(client.cache.data.data.ROOT_QUERY).forEach((query) => {
    fieldsToClear.forEach((fieldToClear) => {
      const pattern = new RegExp(`^${fieldToClear}(?:\\((.*)\\))?$`);
      const match = pattern.exec(query);
      if (!match) return;

      const variables = match[1] ? JSON.parse(match[1]) : {};

      const shouldClear =
        Object.entries(nonExhaustiveVariables).findIndex(
          ([key, value]) => variables[key] && variables[key] !== value,
        ) === -1;
      if (shouldClear) {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: fieldToClear, args: variables });
        client.cache.gc();
      }
    });
  });
};
