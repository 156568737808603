import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { PluginDocument, type PluginType, UpdatePluginDocument } from 'frontend/api/generated';
import { PageContent } from 'frontend/components';
import { withErrorHandler } from 'frontend/enhancers';
import NotFound from 'frontend/features/NotFound/NotFound';
import { useDocumentTitle, useIsRunning, useOnSubmitWithUpload } from 'frontend/hooks';

import PluginForm from './PluginForm';

const PluginDetail = () => {
  const { pluginId } = useParams();

  const { data, loading } = useQuery(PluginDocument, { variables: { id: pluginId as string } });

  useDocumentTitle(() => (!loading && data ? `Plugin: ${data?.plugin?.name}` : 'Plugin: ...'));

  const [updatePlugin] = useMutation(UpdatePluginDocument);

  const onSubmit = useCallback(
    async (values) => {
      const variables = {
        ...values,
        contextKeys: values.contextKeys?.map?.(omit(['id', '__typename'])),
        templateDialogues: values.templateDialogues?.map?.(omit(['id', '__typename'])),
      };

      await updatePlugin({ variables });
    },
    [updatePlugin],
  );

  const [onSubmitWithUpload, setFile] = useOnSubmitWithUpload(onSubmit, `api/v2/plugin/${pluginId}/upload-logo/`);
  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmitWithUpload);

  if (loading) return null;

  if (!data) return <NotFound text="Plugin not found" />;

  return (
    <div>
      <PageContent>
        <PluginForm
          onSubmit={onSubmitWithRunning}
          initialValues={data.plugin as PluginType}
          operation="update"
          isSubmitting={isSubmitting}
          setFile={setFile}
        />
      </PageContent>
    </div>
  );
};

export default withErrorHandler(PluginDetail);
