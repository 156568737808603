import React, { Fragment } from 'react';
import { useField, useForm } from 'react-final-form';

import { Checkbox } from 'frontend/components';

import styles from './FeedbackFilter.scss';
import { feedbackData } from '../../utils/config';

const FeedbackFilter = ({ filterKey, shouldSubmitOnToggle }: { filterKey: string; shouldSubmitOnToggle: boolean }) => {
  const { change, submit } = useForm();
  const {
    input: { value },
  } = useField(filterKey);

  const renderCheckbox = ({ label, value: cbv }) => (
    <Checkbox
      key={cbv}
      className={styles.checkboxOption}
      input={{
        name: cbv,
        checked: value.includes(cbv),
      }}
      allowToggleOnWrapper
      labelClassName={styles.checkboxLabel}
      onClick={() => {
        const valueCopy = [...value];
        if (valueCopy.includes(cbv)) {
          valueCopy.splice(valueCopy.indexOf(cbv), 1);
        } else {
          valueCopy.push(cbv);
        }
        change(filterKey, valueCopy.length ? valueCopy : undefined);

        if (shouldSubmitOnToggle) {
          submit();
        }
      }}
      label={label}
    />
  );

  return feedbackData.map((feedback) =>
    Array.isArray(feedback.value) ? (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${feedback.label}`}>
        <div className={styles.groupTitle}>{feedback.label}</div>
        {feedback.value.map(renderCheckbox)}
      </Fragment>
    ) : (
      renderCheckbox(feedback)
    ),
  );
};

export default FeedbackFilter;
