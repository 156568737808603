import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from 'frontend/components';

import UserActionsDropdown from './UserActionsDropdown';
import styles from './UserOptions.scss';

const UserOptions = ({ rowData: user }) => (
  <div className={styles.container}>
    <Link to={`/admin/user/${user.id}/`}>
      <Button size="small">View</Button>
    </Link>
    <UserActionsDropdown id={user.id} email={user.email} groups={user.groups} />
  </div>
);

UserOptions.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
};

export default UserOptions;
