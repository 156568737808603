import { Searcher } from 'fast-fuzzy';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { getPlainText } from 'frontend/features/Composer';
import { insertEntityNames } from 'frontend/features/Entities/utils';
import { useDebounce } from 'frontend/hooks';

const MINIMUM_SEARCH_SCORE = 0.7;
const SEARCH_DELAY_MS = 400;

const searcherFactory = (sampleList) =>
  new Searcher(sampleList, {
    keySelector: ({ searchText, text }) => searchText || text,
    threshold: MINIMUM_SEARCH_SCORE,
    returnMatchData: true,
  });

const createSearcher = ({ samples, skillSamples }) => ({
  samples: searcherFactory((samples || []).map(insertEntityNames)),
  skillSamples: searcherFactory((skillSamples || []).map(insertEntityNames)),
});

const handleSearchResults = (searchResults) =>
  searchResults.map(({ item, score, match }) => ({ ...item, score, match }));

const performSearch = (searcher, searchQuery) =>
  isEmpty(searcher)
    ? {}
    : {
        sampleResults: handleSearchResults(searcher.samples.search(searchQuery)),
        skillSampleResults: handleSearchResults(searcher.skillSamples.search(searchQuery)),
      };

export default ({ allSamplesInLanguage, allSkillSamplesInLanguage, values }) => {
  const [searchResults, setSearchResults] = useState(null);
  const [skillSearchResults, setSkillSearchResults] = useState(null);
  const [searcher, setSearcher] = useState({});

  const newSampleText = getPlainText(values.newSample);
  const debouncedSampleText = useDebounce(newSampleText, SEARCH_DELAY_MS);

  const search = useCallback((currentSearcher, searchQuery) => {
    if (!searchQuery) return;

    const { sampleResults, skillSampleResults } = performSearch(currentSearcher, searchQuery);
    setSearchResults(sampleResults);
    setSkillSearchResults(skillSampleResults);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchResults(null);
    setSkillSearchResults(null);
  }, []);

  useEffect(() => {
    const newSearcher = createSearcher({ samples: allSamplesInLanguage, skillSamples: allSkillSamplesInLanguage });
    setSearcher(newSearcher);
  }, [allSamplesInLanguage, allSkillSamplesInLanguage]);

  useEffect(() => {
    if (debouncedSampleText) search(searcher, debouncedSampleText);
    else clearSearch();
  }, [debouncedSampleText, clearSearch, search, searcher]);

  return { searchResults, skillSearchResults };
};
