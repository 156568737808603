import { ResponsiveLine } from '@nivo/line';
import { format } from 'date-fns';
import { sum } from 'lodash/fp';
import { useMemo } from 'react';

import { EmptyState } from 'frontend/components/Stats';
import type { LineChartSeries, TimeSeriesData } from 'frontend/components/Stats/components/LineChart/types';
import {
  calculateAxisValues,
  defaultFormatLabelCallback,
  roundNumber,
  sumYData,
} from 'frontend/components/Stats/components/LineChart/utils';
import type { Granularity } from 'frontend/features/Analytics/types';
import CSVDownloadButton from 'frontend/features/Analytics/views/Sessions/components/CSVDownloadButton/CSVDownloadButton';
import { DATE_FORMAT_ISO_DATE_TIME } from 'frontend/utils/date';

import styles from './LineChart.scss';
import Tooltip from './Tooltip';

type FormatLabelCallback = (series: LineChartSeries) => string;

interface LineChartProps {
  isPreview?: boolean;
  isPercentage?: boolean;
  isRate?: boolean;
  emptyStateText?: string;
  formatLabelCallback?: FormatLabelCallback;
  data: LineChartSeries[];
  granularity?: Granularity;
  csvFilename?: string;
}

export function dataToTimeSeriesData(
  propData: LineChartSeries[],
  {
    isPercentage,
    isRate,
    formatLabelCallback,
  }: {
    isPercentage?: boolean;
    isRate?: boolean;
    formatLabelCallback?: FormatLabelCallback;
  },
) {
  return propData.map((series) => {
    const data = series.data ?? [];
    return {
      ...series,
      id: (formatLabelCallback && formatLabelCallback(series)) || series.id,
      data: data.map((item) => ({
        ...item,
        y: roundNumber(item.y, isPercentage, isRate),
        label: series.id,
      })),
    };
  });
}

export function timeSeriesDataToCSV(data: TimeSeriesData) {
  return data.map(({ x, y }) => ({
    date: format(x, DATE_FORMAT_ISO_DATE_TIME),
    count: y,
  }));
}

export default function LineChart({
  data: propData,
  granularity = 'day',
  isPreview = false,
  isPercentage = false,
  isRate = false,
  emptyStateText,
  formatLabelCallback = defaultFormatLabelCallback,
  csvFilename = 'linechart.csv',
}: LineChartProps) {
  const chartData = useMemo(
    () => dataToTimeSeriesData(propData, { isPercentage, isRate, formatLabelCallback }),
    [isPercentage, isRate, propData, formatLabelCallback],
  );

  const dataSize = chartData?.[0]?.data?.length || 0;

  const summed = sum(propData.map(sumYData));
  if (summed === 0) {
    return <EmptyState emptyStateText={emptyStateText} />;
  }
  const axisValues = calculateAxisValues(chartData, isPercentage, isRate);

  let axisBottom = { format: '%b %d', tickPadding: 16, tickSize: 0, tickValues: axisValues.bottomTicks };
  if (granularity === 'year') {
    axisBottom = { format: '%y', tickPadding: 25, tickSize: 0, tickValues: axisValues.bottomTicks };
  } else if (granularity === 'quarter') {
    axisBottom = { format: 'Q%q %Y', tickPadding: 25, tickSize: 0, tickValues: axisValues.bottomTicks };
  } else if (granularity === 'month') {
    axisBottom = { format: '%b', tickPadding: 25, tickSize: 0, tickValues: axisValues.bottomTicks };
  } else if (granularity === 'hour') {
    axisBottom = { format: '%H:%M', tickPadding: 25, tickSize: 0, tickValues: axisValues.bottomTicks };
  }

  const axisLeft = { orient: 'left', tickSize: 0, tickPadding: 16, tickValues: axisValues.leftTicks };

  const offsetUnit = 15;
  const legendOffsetY = offsetUnit * (propData.length + 2);

  return (
    <>
      {!isPreview && (
        <div className={styles.lineChartInfo}>
          <CSVDownloadButton filename={csvFilename} data={timeSeriesDataToCSV(chartData?.[0]?.data ?? [])} />
        </div>
      )}
      <div style={{ width: '100%', height: isPreview ? '260px' : '400px' }} className={styles.lineChart}>
        <ResponsiveLine
          isInteractive
          useMesh
          enableSlices="x"
          /* eslint-disable-next-line react/no-unstable-nested-components */
          sliceTooltip={({ slice }) => <Tooltip slice={slice} granularity={granularity} isPercentage={isPercentage} />}
          animate={false}
          data={chartData}
          enablePoints={dataSize < 50}
          enablePointLabel={dataSize < 13} // Enable labels for linecharts with up to 12 datapoints as per CSM request
          enableArea
          pointSize={10}
          lineWidth={2}
          areaOpacity={0.1}
          enableGridX={false}
          enableGridY
          margin={{
            top: legendOffsetY,
            bottom: 40,
            left: axisValues.leftAxisMargin,
            right: axisValues.bottomAxisMargin,
          }}
          xScale={{
            type: 'time',
          }}
          yScale={{
            type: 'linear',
            stacked: false,
            max: isPercentage ? 100 : axisValues.maxValue,
          }}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          colors={(d) => d.color}
          theme={{
            grid: {
              line: {
                stroke: '#E9E9E9',
                strokeWidth: 1,
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: '#445265',
                },
              },
            },
          }}
          legends={[
            {
              anchor: 'top-left',
              direction: 'column',
              justify: false,
              translateX: -axisValues.leftAxisMargin,
              translateY: -legendOffsetY,
              itemWidth: 100,
              itemHeight: 20,
              itemsSpacing: 1,
              symbolSize: 10,
              symbolShape: 'circle',
              itemDirection: 'left-to-right',
              itemTextColor: '#333',
            },
          ]}
        />
      </div>
    </>
  );
}
