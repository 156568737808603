import { gql } from '@apollo/client';

export default gql`
  mutation AddSkillSubscription($botId: ID!, $skillId: ID!) {
    addSkillSubscription(botId: $botId, skillId: $skillId) {
      botId
      id
    }
  }
`;
