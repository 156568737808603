import { gql } from '@apollo/client';

import { replyCandidateFragment } from '../fragments';

export default gql`
  query GetReplyCandidates($id: ID!, $botId: ID, $languageCode: String!) {
    chatMessage(id: $id) {
      id
      replyCandidates {
        ...ReplyCandidate
      }
    }
  }
  ${replyCandidateFragment}
`;
