import { useQuery } from '@apollo/client';

import { BotFeatureFlagsDocument, SkillFeatureFlagsDocument } from 'frontend/api/generated';

import useBotOrSkill from './useBotOrSkill';

type Feature = 'global-icons' | 'kindly-entities' | 'intent' | 'rules' | 'slots' | 'suggestions';

export default function useFeatureFlags(): (feature: Feature) => boolean {
  const { botId, skillId, isBot, isSkill } = useBotOrSkill();

  const { data: botFlagsData, loading: botFlagsLoading } = useQuery(BotFeatureFlagsDocument, {
    variables: { botId: botId! },
    skip: !isBot || !botId,
  });
  const { data: skillFlagsData, loading: skillFlagsLoading } = useQuery(SkillFeatureFlagsDocument, {
    variables: { skillId: skillId! },
    skip: !isSkill || !skillId,
  });

  const botBetaFlags = botFlagsData?.bot;
  const skillBetaFlags = skillFlagsData?.skill;
  const loading = botFlagsLoading || skillFlagsLoading;

  return function isFeatureEnabled(feature: Feature): boolean {
    if (loading) {
      return false;
    }

    if (isBot) {
      if (!botBetaFlags) {
        return false;
      }

      switch (feature) {
        case 'global-icons':
          return botBetaFlags.globalIconsEnabled;
        case 'kindly-entities':
          return botBetaFlags.kindlyEntitiesEnabled;
        case 'slots':
          return botBetaFlags.slotsEnabled;
        case 'suggestions':
          return botBetaFlags.suggestionsEnabled;
        case 'intent':
          return botBetaFlags.intentMatchEnabled;
        default:
          return false;
      }
    }

    if (isSkill) {
      if (!skillBetaFlags) {
        return false;
      }

      switch (feature) {
        case 'kindly-entities':
          return skillBetaFlags.kindlyEntitiesEnabled;
        default:
          return false;
      }
    }

    console.warn('Illegal code path: useFeatureFlags for neither bot nor skill?');
    return false;
  };
}
