import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LoaderSwitch, PageContent, Panel, Table } from 'frontend/components';

import { GET_ORGANIZATION_ORPHANS } from './graphql';

const OrgItem = ({ data: org }) => <Link to={`/admin/organization/${org.id}`}>{org.name}</Link>;

OrgItem.propTypes = {
  data: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
};

const OrganizationList = ({ name, organizations }) => {
  const orgs = sortBy(organizations, (o) => o.name.toLowerCase()).map((org) => ({ ...org, name: org }));
  return (
    <>
      {name && <h1>{name}</h1>}
      <Table columns={[{ key: 'name', title: 'Name', render: OrgItem }]} data={orgs} />
    </>
  );
};

OrganizationList.propTypes = {
  name: PropTypes.string,
  organizations: PropTypes.arrayOf(PropTypes.shape({})),
};

const Orphans = () => {
  const { loading, data } = useQuery(GET_ORGANIZATION_ORPHANS);

  const organizations = data?.organizationOrphans ?? [];
  const nOrganizations = organizations.length;

  return (
    <LoaderSwitch loading={loading} size="large">
      <PageContent>
        {Boolean(nOrganizations) && (
          <OrganizationList organizations={organizations} name={`Organizations (${nOrganizations})`} />
        )}
        {nOrganizations === 0 && (
          <Panel>
            There are no orphans!
            <span role="img" aria-label="Party popper emoji">
              🎉
            </span>
          </Panel>
        )}
      </PageContent>
    </LoaderSwitch>
  );
};

export default Orphans;
