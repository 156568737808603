import { formatISO } from 'date-fns';
import { isDate } from 'lodash';

type SingleParam = string | Date | number;
export type QueryParam = SingleParam[] | SingleParam | undefined;

function encode(value: SingleParam): string {
  if (!value) {
    return '';
  }

  if (isDate(value)) {
    return formatISO(value, { representation: 'date' });
  }

  return encodeURIComponent(value);
}

/** Stringify object with arrays using bracket suffix
 *
 *  @example
 *  // returns lang[]=en&lang[]=nb
 *  stringifyQueryParamsWithBrackets({lang: ['en', 'nb']})
 *
 * References https://gist.github.com/TravelingTechGuy/32454b95a50d296912b9#gistcomment-3239892
 */
export default function stringifyQueryParamsWithBrackets(
  obj: Record<string, QueryParam> | string[] | Date,
  prefix = '',
) {
  const queryParamList: string[] = [];

  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined) {
      return;
    }

    const prefixedKey = prefix ? `${prefix}[]` : key;

    const isObject = value !== null && typeof value === 'object';

    const stringified =
      isObject && !isDate(value)
        ? stringifyQueryParamsWithBrackets(value, prefixedKey)
        : `${prefixedKey}=${encode(value)}`;

    if (stringified !== '') {
      queryParamList.push(stringified);
    }
  });

  return queryParamList.join('&');
}
