export const searchFields = Object.freeze({
  ID: 'id',
  NAME: 'name',
});

export const orderTypes = Object.freeze({
  ID: 'id',
  NAME: 'name',
  MEMBERS: 'members',
  SKILLS: 'skillCount',
  BOTS: 'botCount',
  ACTIVE: 'isActive',
});

export const botFilters = Object.freeze({
  ALL: 'All',
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
});

export const ADMIN_GROUPS = Object.freeze({
  'kindly_admin:tech': 'Kindly Admin Tech',
  'kindly_admin:delivery': 'Kindly Admin Delivery',
  'kindly_admin:sales': 'Kindly Admin Sales',
});
