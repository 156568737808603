interface Props {
  className?: string;
  title?: string;
}
const StatisticsEmptyIcon = (props: Props) => {
  const { title } = props;
  return (
    <svg {...props} version="1.1" viewBox="0 0 52 45" xmlns="http://www.w3.org/2000/svg">
      <title>{title || 'Statistics empty'}</title>
      <title>58D5A310-4418-4D21-A369-4CF1B84053DD@1x</title>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-297 -1139)">
          <g transform="translate(299 1141)">
            <polyline
              points="7.6362e-14 40.421 23.995 8.7397 33.956 19.391 47.81 -3.3396e-13"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
            <circle cx="23.557" cy="10.105" r="4.0421" fill="#fff" stroke="#0069FF" strokeWidth="3" />
            <path
              d="m18.526 30.176h10.003c0.35643 0 0.64538 0.096803 0.64538 0.21622v9.5676c0 0.11941-0.28895 0.21622-0.64538 0.21622h-10.003c-0.35643 0-0.64538-0.096803-0.64538-0.21622v-3.5946"
              fillRule="nonzero"
              stroke="#0069FF"
              strokeLinecap="round"
              strokeWidth="3"
            />
            <path
              d="m47.245 9.4708v30.06c0 0.35643-0.28895 0.64538-0.64538 0.64538h-10.003c-0.35643 0-0.64538-0.28895-0.64538-0.64538"
              fillRule="nonzero"
              stroke="#DA3CFF"
              strokeLinecap="round"
              strokeWidth="3"
            />
            <path
              d="m46.6 40.176h-10.003c-0.35643 0-0.64538-0.14462-0.64538-0.32301v-14.677"
              fillRule="nonzero"
              stroke="#000"
              strokeLinecap="round"
              strokeWidth="3"
            />
            <polyline
              points="12.972 30.314 12.972 40.176 6.0695 40.176"
              stroke="#1CD300"
              strokeLinecap="round"
              strokeWidth="3"
            />
            <polyline
              transform="translate(8.5207 18.245) rotate(180) translate(-8.5207 -18.245)"
              points="11.972 13.314 11.972 23.176 5.0695 23.176"
              stroke="#1CD300"
              strokeLinecap="round"
              strokeWidth="3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StatisticsEmptyIcon;
