import useBotOrSkill from './useBotOrSkill';
import useLanguages, { type UseLanguagesReturn } from './useLanguages';

interface Props {
  ignoreNoBot?: boolean;
}

const useBotLanguages = ({ ignoreNoBot }: Props = {}): UseLanguagesReturn | Record<string, never> => {
  const botOrSkillParams = useBotOrSkill({ ignoreNoBotOrSkill: ignoreNoBot });
  const state = useLanguages(botOrSkillParams, { ignoreNoBotOrSkill: ignoreNoBot });

  if (botOrSkillParams.isBot) return state;

  if (!ignoreNoBot) console.error('not in scope of a bot');
  return {};
};

export default useBotLanguages;
