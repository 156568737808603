import {
  Fallback,
  GreetingBubble,
  Keyword,
  Pattern,
  Plus,
  Sparkles,
  TextCapitalize,
  Trigger,
} from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { useBotOrSkill } from 'frontend/hooks';

import styles from './AddDialoguesButton.scss';

function getOptions(isIntentEnabled: boolean): React.ComponentProps<typeof Dropdown.MenuOverlay>['options'] {
  return [
    {
      text: 'Greeting',
      icon: GreetingBubble,
      to: 'greeting/new',
    },
    {
      text: 'Fallback',
      icon: Fallback,
      to: 'fallback/new',
    },
    'separator',
    isIntentEnabled
      ? {
          text: 'Intent',
          icon: Sparkles,
          to: `dialogue/new?dialogueType=${DIALOGUE_TYPES.INTENT}`,
        }
      : {
          text: 'Sample',
          icon: TextCapitalize,
          to: `dialogue/new?dialogueType=${DIALOGUE_TYPES.SAMPLES}`,
        },
    {
      text: 'Keyword',
      icon: Keyword,
      to: `dialogue/new?dialogueType=${DIALOGUE_TYPES.KEYWORDS}`,
    },
    {
      text: 'Pattern',
      icon: Pattern,
      to: `dialogue/new?dialogueType=${DIALOGUE_TYPES.PATTERNS}`,
    },
    {
      text: 'Trigger',
      icon: Trigger,
      to: `dialogue/new?dialogueType=${DIALOGUE_TYPES.TRIGGER}`,
    },
  ];
}

const AddDialoguesButton = () => {
  const { botId } = useBotOrSkill();
  const { botSettings, loading } = useBotSettings(botId);
  if (loading) return null;

  const options = getOptions(botSettings?.intentMatchEnabled ?? false);
  return (
    <div className={styles.publishButtonsWrapper}>
      <div className={styles.publishButtons}>
        <Dropdown
          position="bottom-left"
          triggerClassName={styles.dropdownTrigger}
          overlay={<Dropdown.MenuOverlay options={options} />}
          title="Create new dialogue"
        >
          <Icon component={Plus} className={styles.plusIcon} />
        </Dropdown>
      </div>
    </div>
  );
};

export default AddDialoguesButton;
