import PropTypes from 'prop-types';

import { Modal } from 'frontend/features/Modals/';

const AboutSuggestions = ({ hide }) => (
  <Modal hide={hide} onOk={hide} hideCancel title="About suggestions">
    <Modal.Content>
      <p className="m-t-0">We do not recommend using suggestions in the following scenarios:</p>
      <ul>
        <li>Follow-up dialogues</li>
        <li className="m-t-1">Chit-chat and fill dialogues</li>
      </ul>
      <p>
        The last point refers to supplementary dialogues without any actual information about your service, but often
        used to make the conversation flow more naturally. An example of this can be a dialogue that says “Hi, how are
        you today?“.
      </p>
      <p className="m-b-0">Otherwise, suggestions can be used in all sample dialogues.</p>
    </Modal.Content>
  </Modal>
);

AboutSuggestions.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default AboutSuggestions;
