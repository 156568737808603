import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { DIALOGUE_TYPES } from 'frontend/constants';
import { BuildIdObjectType } from 'frontend/propTypes';

import { useSpecialDialogueContextMenu } from './hooks';
import BottomFolder from '../BottomFolder';

const getName = (dialogueType) => `${capitalize(dialogueType.toLowerCase())}s`;

const checkBreadcrumbs = (folderName, path, breadcrumbs) => {
  const folderType = folderName.toLowerCase();
  const pathSegment = `/${folderType.slice(0, -1)}/`;

  return path.includes(pathSegment) || Boolean((breadcrumbs || []).find(({ type }) => type === folderType));
};

const SpecialDialogueFolder = ({ buildIdObject, dialogueType }) => {
  const folderName = getName(dialogueType);
  const { actions, contextMenuId } = useSpecialDialogueContextMenu({ dialogueType });
  const [showLoader, setShowLoader] = useState(false);

  const checkBreadcrumbsMemoed = useCallback(
    (path, breadcrumbs) => checkBreadcrumbs(folderName, path, breadcrumbs),
    [folderName],
  );

  return (
    <BottomFolder
      buildIdObject={buildIdObject}
      dialogueType={dialogueType}
      name={folderName}
      actions={actions}
      contextMenuId={contextMenuId}
      checkBreadcrumbs={checkBreadcrumbsMemoed}
      showLoader={showLoader}
      setShowLoader={setShowLoader}
      indentationLevel={0}
    />
  );
};

SpecialDialogueFolder.propTypes = {
  buildIdObject: BuildIdObjectType.isRequired,
  dialogueType: PropTypes.oneOf(Object.values(DIALOGUE_TYPES)).isRequired,
};

export default SpecialDialogueFolder;
