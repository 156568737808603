import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import cx from 'classnames';
import { get } from 'lodash';
import { useReducer, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';

import type { ImageButtonTypeInput, ImageType } from 'frontend/api/generated';
import Accordion from 'frontend/components/Accordion';
import Button from 'frontend/components/Button';
import Select from 'frontend/components/Select';
import { FIELD_COLOR } from 'frontend/constants';
import { BUTTON_TYPES, BUTTON_TYPE_NAMES } from 'frontend/features/Build/constants';
import { getId } from 'frontend/utils';
import moveArrayItem from 'frontend/utils/moveArrayItem';

import styles from './ButtonsMeta.scss';
import ExternalLinkMeta from './ButtonsMetaTypes/ExternalLinkMeta';
import QuickReplyMeta from './ButtonsMetaTypes/QuickReplyMeta';
import TriggerFollowupMeta from './ButtonsMetaTypes/TriggerFollowupMeta';
import validateButton from './validators';
import FakeButton from '../FakeButton/FakeButton';
import shared from '../sharedStyles.scss';

const { EXTERNAL_LINK, DIALOGUE_TRIGGER, QUICK_REPLY } = BUTTON_TYPES;

const INITIAL_BUTTON_STATE = {
  id: '',
  buttonType: 'link',
  label: '',
  value: '',
  isActive: true,
} satisfies Omit<ImageButtonTypeInput, 'index'>;
export type ButtonType = typeof INITIAL_BUTTON_STATE;

const INITIAL_ERRORS_STATE: Partial<{
  [key in keyof ButtonType]: { error: string; submitFailed: boolean } | null | undefined;
}> = {
  label: null,
  value: null,
};
export type LocalErrors = typeof INITIAL_ERRORS_STATE;

interface Props {
  images: ImageType[];
  selectedImageId?: string;
  fieldName: string;
}

export default function ButtonsMeta({ images, fieldName, selectedImageId }: Props) {
  const [isEditingButton, setIsEditingButton] = useState(false);
  const [buttonForm, setButtonForm] = useReducer(
    (state: typeof INITIAL_BUTTON_STATE, newState: Partial<typeof INITIAL_BUTTON_STATE>) => ({ ...state, ...newState }),
    INITIAL_BUTTON_STATE,
  );

  const [localErrors, setLocalErrors] = useState(INITIAL_ERRORS_STATE);

  const form = useForm();
  const formValues = useFormState().values;

  const selectedImageIndex = images.findIndex((image) => getId(image) === selectedImageId);
  const pathButtonsForm = `${fieldName}.images[${selectedImageIndex}].buttons`;
  const imageButtons = get(formValues, pathButtonsForm, []);
  const hasMaxButtons = imageButtons.length === 3;

  const handleFieldChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setButtonForm({ [event.target.name]: event.target.value });
  };

  const validateAndSetErrors = (): boolean => {
    const potentialErrors = validateButton(buttonForm);
    if (Object.values(potentialErrors).some((error) => error)) {
      setLocalErrors(potentialErrors);
      return false;
    }
    setLocalErrors(INITIAL_ERRORS_STATE);
    return true;
  };

  const handleButtonAdd = (buttonId?: string) => () => {
    if (!validateAndSetErrors()) return;

    const newButtons = structuredClone(imageButtons);

    if (isEditingButton) {
      const buttonToEditIndex = newButtons.findIndex((button) => button.id === buttonId);
      newButtons[buttonToEditIndex] = buttonForm;
      setIsEditingButton(false);
    } else {
      const newButton = { ...buttonForm };
      newButton.id = `temp_${Date.now()}`;
      newButtons.push(newButton);
    }

    form.change(pathButtonsForm, newButtons);
    setButtonForm(INITIAL_BUTTON_STATE);
  };

  const handleButtonDelete = (buttonId: string) => {
    const newButtons = structuredClone(imageButtons);

    const buttonIndexFromForm = newButtons.findIndex((button) => button.id === buttonId);
    newButtons.splice(buttonIndexFromForm, 1);
    form.change(pathButtonsForm, newButtons);
  };

  const handleButtonEdit = (buttonId: string) => {
    const newButtons = structuredClone(imageButtons);

    const buttonToEdit = newButtons.find((button) => button.id === buttonId);
    setLocalErrors(INITIAL_ERRORS_STATE);
    setIsEditingButton(true);
    setButtonForm(buttonToEdit);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const newButtons = structuredClone(imageButtons);

      const oldIndex = newButtons.findIndex((button) => button.id === active.id);
      const newIndex = newButtons.findIndex((button) => button.id === over.id);
      moveArrayItem(newButtons, oldIndex, newIndex);

      form.change(pathButtonsForm, newButtons);
    }
  };

  if (selectedImageIndex < 0) return null;

  return (
    <Accordion title="Image buttons" bodyClassName={shared.accordionBody}>
      <div className={cx({ [styles.extraBottomSpace]: buttonForm.buttonType === 'dialogue_trigger' })}>
        <div className={styles.buttonFormWrapper}>
          <Select
            input={{
              value: buttonForm.buttonType,
              onChange: handleFieldChange,
              name: 'buttonType',
              onBlur: () => {},
              onFocus: () => {},
            }}
            meta={{}}
            fieldColor={FIELD_COLOR.MISCHKA}
          >
            <option value={EXTERNAL_LINK}>{BUTTON_TYPE_NAMES[EXTERNAL_LINK]}</option>
            <option value={DIALOGUE_TRIGGER}>{BUTTON_TYPE_NAMES[DIALOGUE_TRIGGER]}</option>
            <option value={QUICK_REPLY}>{BUTTON_TYPE_NAMES[QUICK_REPLY]}</option>
          </Select>
          {buttonForm.buttonType === 'link' && (
            <ExternalLinkMeta
              buttonForm={buttonForm}
              setButtonForm={handleFieldChange}
              validateButton={validateAndSetErrors}
              localErrors={localErrors}
            />
          )}
          {buttonForm.buttonType === 'dialogue_trigger' && (
            <TriggerFollowupMeta
              buttonForm={buttonForm}
              setButtonForm={handleFieldChange}
              validateButton={validateAndSetErrors}
              localErrors={localErrors}
            />
          )}
          {buttonForm.buttonType === 'quick_reply' && (
            <QuickReplyMeta
              buttonForm={buttonForm}
              setButtonForm={handleFieldChange}
              validateButton={validateAndSetErrors}
              localErrors={localErrors}
            />
          )}
        </div>

        <Button
          text={isEditingButton ? 'Done' : 'Add button'}
          onClick={handleButtonAdd(buttonForm.id)}
          disabled={hasMaxButtons && !isEditingButton}
        />
        <div className={styles.fakeButtonsWrapper}>
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={imageButtons}>
              {imageButtons.map((button) => (
                <FakeButton
                  key={button.id}
                  type={button.buttonType}
                  buttonId={button.id}
                  onDelete={() => handleButtonDelete(button.id)}
                  onEdit={() => handleButtonEdit(button.id)}
                >
                  {button.label}
                </FakeButton>
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </Accordion>
  );
}
