import { Metric } from 'frontend/components/Stats';
import { Grid, StatsPanel } from 'frontend/features/Analytics/components';
import type { ReportData } from 'frontend/features/Analytics/utils/sageData';
import { MonthlyMetricTooltip } from 'frontend/features/Organization/views/Reports/MonthlyMetricTooltip';

import styles from './Reports.scss';

export function ChatBotTraffic({
  data,
  previous,
  loading,
}: {
  data?: ReportData;
  previous?: ReportData;
  loading: boolean;
}) {
  return (
    <Grid columns={4}>
      <StatsPanel className={styles.reportMetric} loading={loading} nested>
        <Metric
          metric={data?.total?.messages}
          reference={previous?.total?.messages}
          topText="Total messages"
          TooltipComponent={MonthlyMetricTooltip}
        />
      </StatsPanel>
      <StatsPanel className={styles.reportMetric} loading={loading} nested>
        <Metric
          metric={data?.total?.sessions}
          reference={previous?.total?.sessions}
          topText="Total sessions"
          TooltipComponent={MonthlyMetricTooltip}
        />
      </StatsPanel>
      <StatsPanel className={styles.reportMetric} loading={loading} nested>
        <Metric
          metric={data?.handovers?.finished}
          reference={previous?.handovers?.finished}
          topText="Total handovers"
          TooltipComponent={MonthlyMetricTooltip}
        />
      </StatsPanel>
      <div />
      <div />
    </Grid>
  );
}
