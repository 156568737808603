import type { FormApi } from 'final-form';
import { useState } from 'react';

import { Language } from 'frontend/assets/icons';
import { InfoBox, Select } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';

import type { LanguageAppended, Values } from '../BotLanguagesController';

interface Props {
  hide: () => Promise<void>;
  args: {
    language: LanguageAppended;
    form: FormApi<Values>;
  };
}

const AddLanguageVariant = ({ hide, args: { language, form } }: Props) => {
  const [variantCode, setVariantCode] = useState('empty');

  const variantsAvailable = language.variants.flatMap(({ name, code }) => {
    const { botLanguageVariants } = form.getState().values;

    if (botLanguageVariants.has(code)) return [];
    return [{ name, code }];
  });
  const isFirstVariant = language.variants.length === variantsAvailable.length;

  const selectInput = {
    value: variantCode,
    name: 'variantCode',
    onChange: (event: React.ChangeEvent<HTMLSelectElement>): void => setVariantCode(event.target.value),
    onBlur: () => null,
    onFocus: () => null,
  };

  const onOk = async () => {
    if (variantsAvailable.length === 0 || variantCode === 'empty') return;

    const { botLanguageVariants, botActiveLanguageVariants } = form.getState().values;
    const newLanguageVariants = new Set(botLanguageVariants).add(variantCode);

    form.batch(() => {
      form.change('botLanguageVariants', newLanguageVariants);
      if (isFirstVariant && language.active) {
        const newActiveLanguageVariants = new Set(botActiveLanguageVariants).add(variantCode);
        form.change('botActiveLanguageVariants', newActiveLanguageVariants);
      }
    });
  };

  return (
    <Modal title="Add language variant" hide={hide} onOk={onOk} icon={Language}>
      <Modal.Content>
        {variantsAvailable.length === 0 ? (
          <p>There are no more variants to choose from.</p>
        ) : (
          <>
            <p className="m-t-0">Select the language you want to add.</p>
            <Select input={selectInput} meta={{}}>
              <Select.Option label="Select language" value="empty" key="empty" />
              {variantsAvailable.map(({ name, code }) => (
                <Select.Option label={name} value={code} key={code} />
              ))}
            </Select>
          </>
        )}
        <p className="m-t-2 m-b-0">
          <em>Contact support@kindly.ai if you are missing any variant.</em>
        </p>
        {isFirstVariant && variantsAvailable.length > 0 && (
          <InfoBox level="warning" title="Beware" className="m-t-2">
            <p>All existing content for this language will be converted to the variant you now choose.</p>
            <p>This will only be the case for the first language variant you add.</p>
            <p>
              If before saving the settings you will delete this variant, the existing content will be migrated to the
              second variant chosen (if any).
            </p>
          </InfoBox>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AddLanguageVariant;
