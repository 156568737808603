import { gql } from '@apollo/client';

import { messageBackupFragment } from '../fragments';

export default gql`
  query MessageBackups($botId: ID!) {
    messageBackups(botId: $botId) {
      ...MessageBackup
    }
  }
  ${messageBackupFragment}
`;
