import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DisconnectSlackWorkspaceDocument } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

interface DisconnectSlackModalProps {
  hide(): void;
  args: {
    id: string;
    userId: string;
    workspaceName: string;
  };
}

const DisconnectSlackModal = ({ hide, args: { workspaceName, id, userId } }: DisconnectSlackModalProps) => {
  const toast = useToast();

  const update = useCallback(
    (cache) => {
      cache.evict({ id: `SlackWorkspaceProfileType:${id}` });
      cache.modify({
        id: `UserType:${userId}`,
        fields: {
          slackWorkspaceProfile() {
            return null;
          },
        },
      });
    },
    [id, userId],
  );

  const [disconnect] = useMutation(DisconnectSlackWorkspaceDocument, { variables: { id }, update });
  const onSubmit = useCallback(async () => {
    await disconnect();
    toast.success(`Disconnected from slack workspace ${workspaceName}`);
  }, [workspaceName, disconnect, toast]);

  return (
    <DeleteModal
      name={`slack workspace ${workspaceName}`}
      titleName="slack workspace"
      onSubmit={onSubmit}
      hide={hide}
      action="disconnect"
      hideWarning
    />
  );
};

export default DisconnectSlackModal;
