const ActivityIcon = () => (
  <svg viewBox="0 0 48 48">
    <title>Activity log empty state icon</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-324 -241)" fillRule="nonzero">
        <g transform="translate(324 241)">
          <path
            id="Path"
            d="m5.7931 0c-3.2276 0-5.7931 2.5655-5.7931 5.7931s2.5655 5.7931 5.7931 5.7931 5.7931-2.5655 5.7931-5.7931-2.5655-5.7931-5.7931-5.7931z"
            fill="#DA3CFF"
          />
          <path
            id="Path"
            d="m5.7931 18.207c-3.2276 0-5.7931 2.5655-5.7931 5.7931s2.5655 5.7931 5.7931 5.7931 5.7931-2.5655 5.7931-5.7931-2.5655-5.7931-5.7931-5.7931z"
            fill="#1CD300"
          />
          <path
            id="Path"
            d="m5.7931 36.414c-3.2276 0-5.7931 2.5655-5.7931 5.7931 0 3.2276 2.5655 5.7931 5.7931 5.7931s5.7931-2.5655 5.7931-5.7931c0-3.2276-2.5655-5.7931-5.7931-5.7931z"
            fill="#0069FF"
          />
          <path
            id="Path"
            d="m47.172 3.3103h-28.138c-0.49655 0-0.82759 0.33103-0.82759 0.82759v3.3103c0 0.49655 0.33103 0.82759 0.82759 0.82759h28.138c0.49655 0 0.82759-0.33103 0.82759-0.82759v-3.3103c0-0.49655-0.33103-0.82759-0.82759-0.82759z"
            fill="#000"
          />
          <path
            id="a"
            d="m47.172 21.517h-28.138c-0.49655 0-0.82759 0.33103-0.82759 0.82759v3.3103c0 0.49655 0.33103 0.82759 0.82759 0.82759h28.138c0.49655 0 0.82759-0.33103 0.82759-0.82759v-3.3103c0-0.49655-0.33103-0.82759-0.82759-0.82759z"
            fill="#000"
          />
          <path
            d="m47.172 39.724h-28.138c-0.49655 0-0.82759 0.33103-0.82759 0.82759v3.3103c0 0.49655 0.33103 0.82759 0.82759 0.82759h28.138c0.49655 0 0.82759-0.33103 0.82759-0.82759v-3.3103c0-0.49655-0.33103-0.82759-0.82759-0.82759z"
            fill="#000"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ActivityIcon;
