import cx from 'classnames';

import { formatNumber } from 'frontend/utils';

import styles from './styles.scss';

const Stat = ({
  size,
  name,
  value = '-',
  type = null,
}: {
  size?: 'small';
  name: string;
  value: string | number;
  type?: string | null;
}) => {
  const valueClassNames = cx(styles.value, { [styles.valueSmall]: size === 'small' });

  return (
    <div className={styles.container}>
      {name}
      <span className={valueClassNames}>
        {typeof value === 'number' ? formatNumber(value) : value}
        {type && ` ${type}`}
      </span>
    </div>
  );
};

export default Stat;
