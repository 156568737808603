import { get, isEqual, noop } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { hasSameId } from 'frontend/features/Build/utils';
import { getId } from 'frontend/utils';

const getIds = (array) => array.map(getId);

export default (selectableGroupRef) => {
  const [selectedSamples, setSelectedSamples] = useState([]);

  const updateSelectedSamples = useCallback(() => {
    const selectedSampleNodes = get(selectableGroupRef, 'current.selectedItems', []);
    const updatedSelectedSamples = Array.from(selectedSampleNodes).map(({ props }) => props.sample);

    setSelectedSamples((currentSelectedSamples) => {
      if (!isEqual(getIds(currentSelectedSamples), getIds(updatedSelectedSamples))) return updatedSelectedSamples;
      return currentSelectedSamples;
    });
  }, [selectableGroupRef, setSelectedSamples]);

  const deselectAll = useMemo(() => selectableGroupRef?.current?.clearSelection ?? noop, [selectableGroupRef]);

  const setSampleSelected = useCallback(
    (sample, selected) => {
      const allNodes = get(selectableGroupRef, 'current.registry', []);
      const itemNode = Array.from(allNodes).find(({ props }) => hasSameId(props.sample, sample));
      if (!itemNode) {
        console.error(`Tried to select sample ${sample.id || sample.tempId}, but can't find its node`);
        return;
      }

      const selectedItems = get(selectableGroupRef, 'current.selectedItems', new Set());
      itemNode.state.isSelected = selected;
      if (selected) {
        selectedItems.add(itemNode);
        updateSelectedSamples();
      } else {
        selectedItems.delete(itemNode);
        updateSelectedSamples();
      }
    },
    [selectableGroupRef, updateSelectedSamples],
  );

  return { selectedSamples, deselectAll, setSampleSelected, updateSelectedSamples };
};
