// When the editor changes, you can get notified via the

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

export default function OnChangePlugin({ handleChange }) {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          handleChange(editorState, editor);
        });
      }),
    [editor, handleChange],
  );

  return null;
}
