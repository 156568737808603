import type { InitParams } from '@greatsumini/react-facebook-login';

import { addListItemByFragment, removeListItemById } from 'frontend/api/cacheHelpers';
import { FacebookPageFragmentDoc } from 'frontend/api/generated';

export const addPageToCache = ({ cache }, botId, facebookPage) => {
  cache.modify({
    id: `BotType:${botId}`,
    fields: {
      facebookPages: addListItemByFragment(cache, FacebookPageFragmentDoc, facebookPage),
    },
  });
};

export const removePageFromCache = ({ cache }, botId, facebookPageId) => {
  cache.modify({
    id: `BotType:${botId}`,
    fields: {
      facebookPages: removeListItemById(facebookPageId),
    },
  });
};

type MetaAPIVersion = NonNullable<InitParams['version']>;
const defaultAPIVersion = 'v20.0' as MetaAPIVersion;
export const META_GRAPH_API_VERSION: MetaAPIVersion =
  (window.env.META_GRAPH_API_VERSION as MetaAPIVersion) || defaultAPIVersion;
