import { useApolloClient } from '@apollo/client';
import type { Modifier } from '@apollo/client/cache';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { addListItemByFragment } from 'frontend/api/cacheHelpers';
import NotificationFragment from 'frontend/api/fragments/Notification.fragment.graphql';
import type { NotificationType } from 'frontend/api/generated';
import { DESKTOP_NOTIFICATIONS } from 'frontend/features/Profile/constants';
import { selectTabActive, showTabNotification } from 'frontend/state/dux/tabNotification';
import { useAppDispatch } from 'frontend/state/hooks';
import { camelCaseKeys, playNotificationSound } from 'frontend/utils';

// Used to toggle tab title change on ticket creation
// Ideally we should use useHandoverTabNotification for all notifications
// But it requires more indepth work, and this is a fast fix for it
// The idea was that on handover the title should blink, but there is no `handover` event sent
const NOTIFICATIONS_TITLE_CHANGE = ['TicketCreatedNotification'];

// eslint-disable-next-line compat/compat
const hasNotDeniedNotifications = () => window.Notification && window.Notification.permission !== 'denied';

export default ({ me }) => {
  const { cache } = useApolloClient();
  const navigate = useNavigate();
  const tabIsActive = useSelector(selectTabActive);
  const dispatch = useAppDispatch();

  const onNotification = useCallback(
    (payload) => {
      const notification: Partial<NotificationType> = {
        ...camelCaseKeys(payload),
        userId: null,
        chatId: payload.exchange_id,
        read: false,
        __typename: 'NotificationType',
      };

      const desktopNotifications = me?.profile?.desktopNotifications ?? DESKTOP_NOTIFICATIONS.ACTIVE;
      const desktopNotificationsInteraction = me?.profile?.desktopNotificationsInteraction ?? false;
      const exchangeTitle = payload.exchangeTitle ?? 'New ticket created';
      const exchangeUrl = notification.exchangeUrl;

      if (exchangeUrl && desktopNotifications === DESKTOP_NOTIFICATIONS.ACTIVE && hasNotDeniedNotifications()) {
        window.Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            const desktopNotification = new window.Notification(exchangeTitle, {
              body: notification.exchangeEvent ?? undefined,
              icon: notification.icon ?? undefined,
              requireInteraction: desktopNotificationsInteraction,
            });
            desktopNotification.addEventListener('click', () => {
              navigate(exchangeUrl);
              window.focus();
              desktopNotification.close();
            });
          }

          playNotificationSound();
          if (!tabIsActive && NOTIFICATIONS_TITLE_CHANGE.includes(payload.notification_type)) {
            dispatch(showTabNotification(exchangeTitle));
          }
        });
      }

      // Update data in cache
      cache.modify({
        id: cache.identify(me),
        fields: {
          notifications: addListItemByFragment(cache, NotificationFragment, notification) as Modifier<unknown>,
        },
      });
    },
    [cache, navigate, me, dispatch, tabIsActive],
  );

  return onNotification;
};
