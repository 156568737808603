import { Field, Form } from 'react-final-form';

import { FormErrors, Input, PageBar, Panel } from 'frontend/components';
import { chain, max, min, required } from 'frontend/form/validators';

import styles from './Engagement.scss';
import { useEngagementForm } from '../hooks';

const Engagement = () => {
  const { onSubmit, initialValues } = useEngagementForm();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <PageBar>
            <PageBar.FormButtons />
          </PageBar>
          <Panel>
            <FormErrors />
            <h3 className="m-b-3">Bot engagement id</h3>
            <div className={styles.inputContainer}>
              <Field
                component={Input}
                name="engagementId"
                className="m-b-md"
                inputLimit={5}
                validate={chain([required, min(5), max(5)])}
                label="ID"
              />
            </div>
          </Panel>
        </form>
      )}
    />
  );
};

export default Engagement;
