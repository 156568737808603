import { useQuery } from '@apollo/client';

import { INCOMPLETE_DIALOGUES } from '../queries';

export default (currentLanguage, filters, botId) => {
  const { data, loading } = useQuery(INCOMPLETE_DIALOGUES, {
    variables: {
      botId,
      languageCode: currentLanguage,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
    },
  });

  return {
    loading,
    incompleteDialogues: data?.incompleteDialogues ?? [],
  };
};
