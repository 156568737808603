import PropTypes from 'prop-types';

import { EntityInstancesType } from 'frontend/features/Entities/propTypes';

export const SampleType = PropTypes.shape({
  id: PropTypes.string,
  tempId: PropTypes.string,
  text: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  tempUpdatedAt: PropTypes.string,
  dialogueId: PropTypes.string,
  instances: EntityInstancesType,
  sampleIndex: PropTypes.string,
  match: PropTypes.shape({
    index: PropTypes.number,
    length: PropTypes.number,
  }),
});
