import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import { Copy } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';

import styles from './styles.scss';

const PasteSamples = ({ hide, args }) => {
  const [showLines, setShowLines] = useState(10);
  const { pastedText, addSamples } = args;

  const sampleTexts = useMemo(
    () =>
      pastedText
        .split('\n')
        .map((str) => str.trim())
        .filter((str) => str),
    [pastedText],
  );

  const handleSubmit = useCallback(() => addSamples(sampleTexts), [addSamples, sampleTexts]);

  const handleShowMore = useCallback(() => setShowLines(showLines + 10), [showLines]);

  return (
    <Modal title="Import samples" icon={Copy} hide={hide} onOk={handleSubmit} onOkText="Import">
      <Modal.Content className={styles.container}>
        <h4 className="m-b-sm">Do you want to import {sampleTexts.length} samples?</h4>
        <ul>
          {sampleTexts.slice(0, showLines).map((text, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`sample-text-${idx}`}>{text}</li>
          ))}
        </ul>
        {showLines < sampleTexts.length && (
          <Button flat className={styles.showMore} onClick={handleShowMore}>
            Show more
          </Button>
        )}
      </Modal.Content>
    </Modal>
  );
};

PasteSamples.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    pastedText: PropTypes.string.isRequired,
    addSamples: PropTypes.func.isRequired,
  }).isRequired,
};

export default PasteSamples;
