import { useApolloClient, useMutation } from '@apollo/client';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { isDialogueFollowupTo } from 'frontend/api/queries';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { GetDialogue, GetDialogueList } from 'frontend/features/Build/graphql';
import { useToast } from 'frontend/hooks';
import { fetchIfNecessary, getCurrentFormId } from 'frontend/utils';

import { updateDialogueParentOnRemove } from '../../cacheHelpers';
import { DELETE_DIALOGUE } from '../../mutations';

const useIsRedirectNecessary = ({ deletedDialogueId, buildIdObject, client, pathname }) => {
  const isRedirectNecessary = useCallback(async () => {
    const currentDialogueId = getCurrentFormId('dialogue', pathname);
    if (currentDialogueId && currentDialogueId === deletedDialogueId) {
      return true;
    }
    if (currentDialogueId) {
      try {
        const { data } = await client.query({
          query: isDialogueFollowupTo,
          variables: {
            ...buildIdObject,
            parentId: deletedDialogueId,
            dialogueId: currentDialogueId,
          },
        });
        return data.dialogue.isFollowupTo;
      } catch (e) {
        // If current dialogue is a skill subscription dialogue it will not be found,
        // but this also means it is not followup to a deleted dialogue
        return false;
      }
    }
    return false;
  }, [buildIdObject, client, deletedDialogueId, pathname]);

  return isRedirectNecessary;
};

const getLinkedBuildIdObject = ({ dialogue, dialogueMod }) => {
  if (dialogueMod) {
    return { botId: dialogueMod.modBot.id };
  }
  const { bot, skill } = dialogue;
  return bot ? { botId: bot.id } : { skillId: skill.id };
};

const getLinkedDialogueVariables = ({ dialogue, dialogueMod }) => ({
  dialogueId: dialogue?.id || dialogueMod?.modDialogue?.id,
  ...getLinkedBuildIdObject({ dialogue, dialogueMod }),
});

const getLinkedDialogues = ({ formsLinkedToDialogue, buttonLinkedToDialogue }) => {
  const buttonLinkedDialogues = (buttonLinkedToDialogue.buttonsLinkedToDialogue || []).map(getLinkedDialogueVariables);
  const formLinkedDialogues = (formsLinkedToDialogue.formsLinkedToDialogue || []).map(getLinkedDialogueVariables);
  return uniqBy([...buttonLinkedDialogues, ...formLinkedDialogues], 'dialogueId');
};

export const useOnSubmit = ({
  dialogue,
  buildIdObject,
  buildId,
  buildType,
  formsLinkedToDialogue,
  buttonLinkedToDialogue,
  shouldRedirect: shouldRedirectOverwrite,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteDialogue] = useMutation(DELETE_DIALOGUE);

  const isRedirectNecessary = useIsRedirectNecessary({
    deletedDialogueId: dialogue.id,
    buildIdObject,
    client,
    pathname: location.pathname,
  });

  const onSubmit = useCallback(async () => {
    const shouldRedirect = await isRedirectNecessary();
    await deleteDialogue({ variables: { ...buildIdObject, id: dialogue.id } });

    if (shouldRedirect && shouldRedirectOverwrite) {
      const url = `/${buildType}/${buildId}/build`;
      navigate(url);
    }

    updateDialogueParentOnRemove({ client, buildIdObject, dialogue });
    fetchIfNecessary({
      client,
      query: GetDialogueList,
      variables: { ...buildIdObject, includeSubscribedSkills: true },
    });
    fetchIfNecessary({
      client,
      query: GetDialogueList,
      variables: { ...buildIdObject, dialogueType: DIALOGUE_TYPES.SAMPLES },
    });
    getLinkedDialogues({ formsLinkedToDialogue, buttonLinkedToDialogue }).forEach((variables) =>
      fetchIfNecessary({ query: GetDialogue, variables }),
    );

    toast.success('Dialogue deleted!');
  }, [
    buildId,
    buildType,
    buildIdObject,
    client,
    formsLinkedToDialogue,
    buttonLinkedToDialogue,
    deleteDialogue,
    dialogue,
    isRedirectNecessary,
    navigate,
    toast,
    shouldRedirectOverwrite,
  ]);

  return onSubmit;
};
