import { gql } from '@apollo/client';

import { DIALOGUE_MOD_WITH_ENTITIES, DIALOGUE_WITH_ENTITIES } from '../fragments';

export default gql`
  query KindlyEntityDialogues($id: UUID!, $botId: ID, $skillId: ID) {
    kindlyEntity(id: $id, botId: $botId, skillId: $skillId) {
      id
      name
      dialogues(botId: $botId, skillId: $skillId) {
        ...DialogueWithEntities
      }
      dialogueMods(botId: $botId) {
        ...DialogueModWithEntities
      }
    }
  }
  ${DIALOGUE_WITH_ENTITIES}
  ${DIALOGUE_MOD_WITH_ENTITIES}
`;
