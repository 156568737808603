import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';

import type { AddBotMembershipQuery } from 'frontend/api/generated';
import { UserPlus } from 'frontend/assets/icons';
import { Button, LoaderSwitch } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';

import UserPicker from './components/UserPicker/UserPicker';
import UserRole from './components/UserRole/UserRole';
import styles from './styles.scss';
import { roleIdToFieldName } from './utils';
import type { AnyRole } from '../../types';
import Summary from './components/Summary/Summary';

const STEPS = Object.freeze({ SELECT_USER: 1, SELECT_ROLES: 2, SUMMARY: 3 });
const SUBSCRIBE = { valid: true, dirty: true };

export type PartialUser = AddBotMembershipQuery['bot']['organization']['users'][0];

type AddMemberModalProps = {
  hide: (...args: unknown[]) => Promise<void>;
  name: string;
  type: 'select' | 'input';
  users: PartialUser[];
  roles: (AnyRole & { isDefaultRole: boolean })[];
  allPermissions: string[];
  onSubmit: (...args: unknown[]) => unknown;
  isSubmitting?: boolean;
  disallowOrganizationRoles?: boolean;
  existingEmails?: string[];
  loading: boolean;
  to: { organizationId: string; botId?: never } | { botId: string; organizationId: string };
};

function AddMemberModal({
  hide,
  name,
  type,
  users,
  roles,
  onSubmit: finalSubmit,
  isSubmitting,
  allPermissions,
  existingEmails = [],
  disallowOrganizationRoles,
  to,
  loading,
}: AddMemberModalProps) {
  /*
    The modal has two versions: 'select' and 'input'.
    The 'select' version is used when the user can select from a list of existing users,
    while the 'input' version is used when the user must input an email address to be invited.

    The modal can also be used to add members to an organization or a bot, determined by the 'to' prop.
   */
  const [page, setPage] = useState<number>(STEPS.SELECT_USER);
  const modalTitle = {
    1: `Who are you adding as a member to ${name}`,
    2: 'Which roles should this member have?',
    3: 'Make sure everything looks good before completing!',
  }[page];
  const finalStepAction = users ? 'Add' : 'Send';

  const next = useCallback(() => setPage((current) => current + 1), []);
  const back = useCallback(() => setPage((current) => current - 1), []);
  const onSubmit = useCallback(
    (...args) => {
      if (page === STEPS.SELECT_USER) next();
      if (page === STEPS.SELECT_ROLES) next();
      if (page === STEPS.SUMMARY) finalSubmit(...args);
    },
    [finalSubmit, next, page],
  );

  const initialValues = useMemo(
    () => ({
      email: '',
      selectedUsers: [],
      ...roles.reduce(
        (selectedRoles, { id, isDefaultRole }) => ({ ...selectedRoles, [roleIdToFieldName(id)]: isDefaultRole }),
        {},
      ),
    }),
    [roles],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscribe={SUBSCRIBE}
      render={({ handleSubmit, valid, dirty }) => (
        <LoaderSwitch loading={loading}>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Modal
              hide={hide}
              title={modalTitle}
              icon={UserPlus}
              className={styles.modal}
              footer={false}
              valid={valid}
              disabled={!dirty}
            >
              {page === STEPS.SELECT_USER &&
                (type === 'select' ? (
                  <UserPicker type={type} users={users} existingEmails={existingEmails} to={to} />
                ) : (
                  <UserPicker type={type} users={[]} existingEmails={existingEmails} to={to} />
                ))}
              {page === STEPS.SELECT_ROLES && (
                <UserRole
                  users={users}
                  roles={roles}
                  allPermissions={allPermissions}
                  disallowOrganizationRoles={disallowOrganizationRoles ?? false}
                  organizationId={to.organizationId}
                />
              )}
              {page === STEPS.SUMMARY && users && roles && <Summary users={users} name={name} roles={roles} />}
              <Modal.Footer className={styles.footer}>
                <div className={styles.paginationContainer}>
                  <span className={cx(styles.pagination, { [styles.paginationActive]: page >= STEPS.SELECT_USER })} />
                  <span className={cx(styles.pagination, { [styles.paginationActive]: page >= STEPS.SELECT_ROLES })} />
                  <span className={cx(styles.pagination, { [styles.paginationActive]: page >= STEPS.SUMMARY })} />
                </div>
                <div className={styles.actions}>
                  {page > STEPS.SELECT_USER && <Button text="Back" onClick={back} flat className="m-r-2" />}
                  <Button
                    text={page === STEPS.SUMMARY ? finalStepAction : 'Next'}
                    onClick={handleSubmit}
                    isSubmitting={isSubmitting}
                    color={page === STEPS.SUMMARY ? 'primary' : 'secondary'}
                  />
                </div>
              </Modal.Footer>
            </Modal>
          </form>
        </LoaderSwitch>
      )}
    />
  );
}

export default AddMemberModal;
