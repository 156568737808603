import { useCallback } from 'react';

import { setLoginError, setLoginSuccess } from 'frontend/state/dux/auth';
import { useAppDispatch } from 'frontend/state/hooks';

export default () => {
  const dispatch = useAppDispatch();

  const onAzureResponse = useCallback(
    async (azureResponse) => {
      const onAzureError = (err) => dispatch(setLoginError(err));
      const onAzureSuccess = (access) => dispatch(setLoginSuccess(access));

      if (azureResponse.error) {
        onAzureError(`Login did not complete: ${azureResponse.error}`);
        return;
      }

      const formData = new FormData();
      formData.append('id_token', azureResponse.idToken);

      const response = await fetch('/api/v2/auth/oauth/azure/complete/', {
        method: 'POST',
        body: formData,
      });

      if (response.status === 401) {
        onAzureError("Couldn't log you in. Contact your organization admin to see if SSO is enabled.");
      } else if (response.status === 400) {
        const { non_field_errors: errors } = await response.json();
        onAzureError(`${errors}`);
      } else if (response.status === 200) {
        const { access } = await response.json();
        onAzureSuccess(access);
      }
    },
    [dispatch],
  );

  return onAzureResponse;
};
