export default (url: string) =>
  fetch(url, {
    method: 'HEAD',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error, status = ${response.status}`);
      }
      return response.ok;
    })
    .catch((error) => {
      console.error('Error checking image existence:', error);
      throw error;
    });
