import { gql } from '@apollo/client';

export default gql`
  mutation CreateMessageBackup(
    $botId: ID!
    $days: Int
    $backupFormat: String!
    $onlyBotReplies: Boolean
    $languageCode: String
    $from: DateTime
    $to: DateTime
  ) {
    createMessageBackup(
      botId: $botId
      days: $days
      backupFormat: $backupFormat
      onlyBotReplies: $onlyBotReplies
      languageCode: $languageCode
      dateFrom: $from
      dateTo: $to
    ) {
      id
      moreThanOneChunk
      password
    }
  }
`;
