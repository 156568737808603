import PropTypes from 'prop-types';

import { ImageAvatar } from 'frontend/components';
import { InboxAgentType } from 'frontend/features/Inbox/propTypes';
import { IDType } from 'frontend/propTypes';

import styles from './ChatPreview.scss';
import useAgent from './useAgent';

const AgentAvatar = ({ handoverByAgentId, assignedAgent, completedHandover, ticketSolved, botId }) => {
  const handoverAgent = useAgent(handoverByAgentId, botId);
  const agent = assignedAgent || handoverAgent; // Most of the time these should be identical

  if (!agent?.profile) return null;

  const text = `${assignedAgent ? 'Ticket assigned to' : 'Handover by'} ${agent?.profile?.alias}`;
  const activeAssignedTicket = assignedAgent && !ticketSolved;
  const activeHandover = handoverByAgentId && !completedHandover;

  if (!activeAssignedTicket && !activeHandover) return null;

  return (
    <span className={styles.agentAvatar}>
      <ImageAvatar img={agent.profile.avatarUrl} size="small" alt={text} title={text} />
      <span>{agent.profile.alias || ''}</span>
    </span>
  );
};

AgentAvatar.propTypes = {
  handoverByAgentId: IDType,
  botId: IDType.isRequired,
  assignedAgent: InboxAgentType,
  completedHandover: PropTypes.bool.isRequired,
  ticketSolved: PropTypes.bool.isRequired,
};

export default AgentAvatar;
