import { orderBy } from 'lodash';
import XRegExp from 'xregexp';

import { entityLimits } from 'frontend/constants';
import { hasSameId } from 'frontend/features/Build/utils';
import { samplesEqual } from 'frontend/features/Samples/utils';
import { getId } from 'frontend/utils';
import randomUUID from 'frontend/utils/randomUUID';

export const sampleIsLegal = (sample) =>
  sample && (sample.instances || []).length <= entityLimits.MAX_INSTANCES_PER_SAMPLE;

export const orderByLegalFirst = ({ instances }) => (sampleIsLegal({ instances }) ? 0 : 1);

export const arrayToBoolObject = (array) =>
  array.reduce((boolObject, item) => ({ ...boolObject, [getId(item)]: true }), {});

export const boolObjectToArray = (boolObject) =>
  Object.entries(boolObject).reduce((array, [key, boolValue]) => [...array, ...(boolValue ? [key] : [])], []);

export const searchAndReplaceValidator = ({ includedSamples }) => {
  const nChosenSamples = boolObjectToArray(includedSamples).length;
  return nChosenSamples > 0 ? undefined : { Samples: 'You need to choose at least one sample' };
};

const intervalsOverlap = (firstIntervalStart, firstIntervalEnd, secondIntervalStart, secondIntervalEnd) =>
  (firstIntervalStart <= secondIntervalStart && firstIntervalEnd >= secondIntervalStart) ||
  (firstIntervalStart <= secondIntervalEnd && firstIntervalEnd >= secondIntervalEnd);

// In general the word boundary "\b" doesn't work with unicode characters; we make our own
const ignoreCharacters = '\\s\\n\\r\\t\\.,\'"!?';
const wordStart = `(?:^|[${ignoreCharacters}]+)`;
const wordEnd = `(?:$|[${ignoreCharacters}]+)`;

export const replaceTextByEntity = (replaceText, entity, samples) =>
  (samples || []).reduce((replacedSamples, currentSample) => {
    const { text, instances } = currentSample;
    const searchString = text.toLowerCase();
    const searchQuery = replaceText.toLowerCase().trim();
    const regex = new XRegExp(`${wordStart}${searchQuery}${wordEnd}`, 'iug');
    const replaceLength = searchQuery.length;

    let match = regex.exec(searchString);
    const newEntityInstances = [];

    while (match) {
      // Since we may capture characters in front of the word we have to check where it actually starts
      const initialCharacters = match[0].indexOf(searchQuery[0]);
      const foundIndex = match.index + initialCharacters;
      const foundEndIndex = foundIndex + replaceLength;
      const hasEntityAlready = Boolean(
        // eslint-disable-next-line no-loop-func
        instances.find(({ start, end }) => intervalsOverlap(start, end, foundIndex, foundEndIndex)),
      );
      if (hasEntityAlready) break;

      const entityInstance = { entity, tempId: randomUUID(), start: foundIndex, end: foundEndIndex };
      newEntityInstances.push(entityInstance);
      match = regex.exec(searchString);
    }

    if (newEntityInstances.length === 0) return replacedSamples;

    const updatedEntityInstances = orderBy([...instances, ...newEntityInstances], 'start');
    const updatedSample = { ...currentSample, instances: updatedEntityInstances };
    const otherSamples = [...samples, ...replacedSamples].filter((other) => !hasSameId(other, currentSample));
    const equalSampleExists = otherSamples.find((sample) => samplesEqual(sample, updatedSample));

    if (equalSampleExists) return replacedSamples;

    return [...replacedSamples, updatedSample];
  }, []);
