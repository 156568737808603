/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-bitwise */

/** Polyfill crypto.randomUUID in a pure fashion.
 * FIXME: Remove this when browser support is good enough.
 * */
export default function randomUUID(): string {
  if (!('randomUUID' in window.crypto)) {
    // https://stackoverflow.com/a/2117523/2800218
    // LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode
    /* @ts-ignore */
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      /* @ts-ignore */
      (c ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
  }
  return window.crypto.randomUUID();
}
