import { CheckboxOnly } from 'frontend/components';

import styles from './menus-styles.scss';
import type { MenuProps } from './types';

export type TypeMenuProps = {
  intentMatchEnabled?: boolean;
};

export const TypeMenu = ({ onFilterChange, checked, setChecked, intentMatchEnabled }: MenuProps & TypeMenuProps) => {
  const data = [
    {
      title: 'Greeting dialogue',
      key: 'GREETING',
    },
    {
      title: 'Fallback dialogue',
      key: 'FALLBACK',
    },
    intentMatchEnabled
      ? {
          title: 'Intent dialogue',
          key: 'INTENT',
        }
      : {
          title: 'Sample dialogue',
          key: 'SAMPLES',
        },
    {
      title: 'Keyword dialogue',
      key: 'KEYWORDS',
    },
    {
      title: 'Pattern dialogue',
      key: 'PATTERNS',
    },
    {
      title: 'Trigger dialogue',
      key: 'TRIGGER',
    },
  ];

  const handleCheck = (key) => {
    const isChecked = !checked.includes(key);

    if (isChecked) {
      checked = [...checked, key];
    } else {
      checked = checked.filter((val) => val !== key);
    }

    if (setChecked) {
      setChecked(checked);
    }
    onFilterChange(checked);
  };

  return (
    <ul className={styles.list}>
      {data.map((item) => (
        <li className={styles.item} key={item.key}>
          <label htmlFor={`LibrarySearchTypeMenu-${item.key}`}>
            <CheckboxOnly
              className={styles.checkbox}
              checked={checked.includes(item.key)}
              id={`LibrarySearchTypeMenu-${item.key}`}
              onChange={() => handleCheck(item.key)}
            />
            <span className={styles.typeTitle}>{item.title}</span>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default TypeMenu;
