import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from 'frontend/components';
import Table from 'frontend/components/Table/Table';

import styles from './styles.scss';

const ActiveCell = ({ data: isActive }) => (isActive ? 'Active' : 'Inactive');
const ConnectorName = ({ rowData: connector }) => <Link to={`/admin/connector/${connector.id}`}>{connector.name}</Link>;
const Actions = ({ rowData: connector }) => (
  <Button size="small" text="Edit" to={`/admin/connector/${connector.id}`} className={styles.editButton} />
);

const columns = [
  {
    key: 'name',
    render: ConnectorName,
  },
  {
    key: 'connectorType',
    title: 'Type',
  },
  {
    key: 'description',
  },
  {
    key: 'isActive',
    title: 'Status',
    component: ActiveCell,
  },
  {
    key: 'actions',
    render: Actions,
  },
];

const ConnectorList = ({ connectors }) => <Table data={connectors} columns={columns} />;

ConnectorList.propTypes = {
  connectors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ConnectorList;
