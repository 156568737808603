import { useParams } from 'react-router-dom';

import { AnalyticsContent } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import { useBooleanState } from 'frontend/hooks';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

import Filters, { FilterInfo } from '../../../Filters';
import LabelStats from '../Labels/LabelsStats';

const HIDE_FILTERS = { granularity: true, engagementCampaigns: true, nudges: true, feedbacks: true };
const EXTRA = { limit: 100 };
const SKIP = Object.keys(HIDE_FILTERS);

const LabelsDetail = () => {
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const { botId } = useParams();
  const filters = useFilters({ extra: EXTRA, skip: SKIP });

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} hideFilter={HIDE_FILTERS} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} />
        <LabelStats scope={sageScope(SAGE_RESOURCE.BOT, botId)} filters={filters} />
      </AnalyticsContent>
    </>
  );
};

export default LabelsDetail;
