import cx from 'classnames';

import { DotsVertical, Eye, Language, Star, Trash } from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';

import styles from './table.scss';
import type { LanguageAppended } from '../BotLanguagesController';

interface Props {
  data: LanguageAppended;
  rowData: { [key: string]: any };
}

function LanguageCell({
  data: { text, name, code, active, variants },
  rowData: {
    shared: {
      enableLanguage,
      disableLanguage,
      removeLanguage,
      addLanguageVariant,
      hasBotLanguageVariants,
      isKindlyManager,
      makePrimaryLanguage,
      primaryLanguage,
    },
  },
}: Props) {
  const isPrimaryLanguage = primaryLanguage === code;
  const overlayOptions: React.ComponentProps<typeof Dropdown.MenuOverlay>['options'] = [
    ...(isKindlyManager
      ? [
          {
            icon: Language,
            text: 'Add language variant',
            onClick: () => addLanguageVariant({ name, code, active, variants }),
            color: 'pink',
          } as const,
        ]
      : []),
    ...(!isPrimaryLanguage
      ? [
          {
            icon: Language,
            text: 'Make primary language',
            onClick: () => makePrimaryLanguage(code),
          } as const,
        ]
      : []),
    {
      icon: Eye,
      text: `${active ? 'Disable' : 'Enable'} language`,
      onClick: () => (active ? disableLanguage(code) : enableLanguage(code)),
    },
    'separator',
    {
      icon: Trash,
      text: 'Remove',
      color: 'red',
      onClick: () => removeLanguage(code),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.cellTextWrapper}>
        <span className={cx(styles.languageName, { [styles.cellTextDisabled]: !active })}>{text ?? name}</span>
        {isPrimaryLanguage && text === undefined && <Icon component={Star} className={styles.primaryLanguageIcon} />}
        {!active && text == null && <span className={styles.cellTextDisabledWord}>Disabled</span>}
      </div>

      {!hasBotLanguageVariants && (
        <Dropdown overlay={<Dropdown.MenuOverlay options={overlayOptions} />} triggerClassName={styles.dropdownTrigger}>
          <Icon component={DotsVertical} className="vertical-middle" />
        </Dropdown>
      )}
    </div>
  );
}

export default LanguageCell;
