import { useQuery } from '@apollo/client';
import { compose, get, sortBy, uniqBy } from 'lodash/fp';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { INBOX_AGENTS, ORGANIZATION_AGENTS } from '../queries';

const sortByName = compose(uniqBy('user.id'), sortBy(get('user.profile.fullName')));

export default () => {
  const { botId, organizationId } = useParams();
  const { data: botData, loading: botLoading } = useQuery(INBOX_AGENTS, { variables: { id: botId }, skip: !botId });
  const { data: organizationData, loading: organizationLoading } = useQuery(ORGANIZATION_AGENTS, {
    variables: { organizationId },
    skip: !organizationId,
  });

  const agents = useMemo(() => {
    const unsorted = botId ? botData?.bot?.inboxAgentMembers : organizationData?.organization?.members;

    return sortByName(unsorted ?? []);
  }, [botId, botData, organizationData]);

  return { agents, loading: botLoading || organizationLoading };
};
