import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { LabelsForBotDocument } from 'frontend/api/generated';
import { deleteLabel as DELETE_LABEL } from 'frontend/api/mutations';
import { Button } from 'frontend/components';
import LabelsBadgeSlider from 'frontend/features/Labels/Label/LabelBadgeSlider';
import { useToast } from 'frontend/hooks';
import type { Label } from 'frontend/propTypes/LabelType';

import styles from './LabelsDeleter.scss';

interface Props {
  labelToDelete: Label;
  resetLabelsState: () => void;
}

function LabelsDeleter({ labelToDelete, resetLabelsState }: Props) {
  const { botId } = useParams();
  const toast = useToast();

  const [deleteLabelMutation] = useMutation(DELETE_LABEL, {
    refetchQueries: [{ query: LabelsForBotDocument, variables: { botId } }, 'GetLabels'],
  });

  const handleDelete = async () => {
    await deleteLabelMutation({ variables: { labelId: labelToDelete.id } });
    toast.success(`Label "${labelToDelete.name}" deleted`);
    resetLabelsState();
  };

  return (
    <>
      <div className={styles.labelBox}>
        <LabelsBadgeSlider
          labelName={labelToDelete.name}
          labelColor={labelToDelete.color}
          labelBackground={labelToDelete.background}
        />
      </div>

      <p className={styles.p}>
        Are you sure you want to delete <strong>{labelToDelete.name}</strong> label?
      </p>
      <p className={styles.p}>It will be removed from this workspace and from all the dialogues associated with it.</p>

      <div className={styles.buttonsContainer}>
        <Button color="warning" text="Delete" onClick={handleDelete} />
        <Button text="Cancel" onClick={resetLabelsState} />
      </div>
    </>
  );
}

export default LabelsDeleter;
