import { gql } from '@apollo/client';

import { DialogueFragment } from 'frontend/features/Build/graphql';

export default gql`
  mutation DropDialogueInDialogue($botId: ID, $skillId: ID, $sourceDialogueId: UUID!, $targetDialogueId: UUID!) {
    dropDialogueInDialogue(
      botId: $botId
      skillId: $skillId
      sourceDialogueId: $sourceDialogueId
      targetDialogueId: $targetDialogueId
    ) {
      ...Dialogue
    }
  }
  ${DialogueFragment}
`;
