import { useApolloClient, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { removeFieldsFromKeyWithPotentialVariables } from 'frontend/api/cacheHelpers';
import { type DialogueCandidateType, UpdateDialogueCandidateDocument } from 'frontend/api/generated';
import { MoveToArchive, Plus } from 'frontend/assets/icons';
import { Button, Input } from 'frontend/components';
import { useToast } from 'frontend/hooks';

import styles from './SingleDialogueCandidate.scss';

const SingleDialogueCandidate = ({
  rowData,
  onDialogueUpdate,
}: {
  rowData: DialogueCandidateType;
  onDialogueUpdate: () => void;
}) => {
  const { id: candidateId, generatedIntent: generatedIntentDefault, state } = rowData;

  // The locked state is used to prevent multiple requests to the server before the data has been refetched.
  const [isLocked, setIsLocked] = useState(false);

  const [generatedIntent, setGeneratedIntent] = useState<string>(generatedIntentDefault || '');
  const navigate = useNavigate();
  const { botId } = useParams();
  const apolloClient = useApolloClient();
  const toast = useToast();
  const [updateDialogueCandidate, { loading: isLoading }] = useMutation(UpdateDialogueCandidateDocument);
  const archiveDialogueCandidate = async () =>
    updateDialogueCandidate({
      variables: {
        botId: botId!,
        candidateId,
        newState: 'REJECTED',
      },
      update: () => {
        setIsLocked(true);
        removeFieldsFromKeyWithPotentialVariables(apolloClient, ['dialogueCandidatesPaginated'], { botId });
        toast.success('Dialogue candidate archived successfully.');
        onDialogueUpdate();
      },
    });
  const acceptDialogueCandidate = () =>
    updateDialogueCandidate({
      variables: {
        botId: botId!,
        candidateId,
        newState: 'ACCEPTED',
        intent: generatedIntent,
      },
      update: () => {
        setIsLocked(true);
        removeFieldsFromKeyWithPotentialVariables(apolloClient, ['dialogueCandidatesPaginated'], { botId });
        toast.success('Dialogue candidate created.');
        onDialogueUpdate();
      },
    });

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <Input
          className={styles.input}
          inputWrapperClassName={styles.inputWrapper}
          name="suggestedIntent"
          input={{ value: generatedIntent, onChange: ({ target }) => setGeneratedIntent(target.value) }}
          maxLength={500}
          required
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          onClick={async () => {
            const candidate = await acceptDialogueCandidate();
            const createdDialogueId = candidate.data?.updateDialogueCandidate?.createdDialogue?.id;
            if (createdDialogueId) {
              navigate(`/workspace/${botId}/build/dialogue/${createdDialogueId}`);
            } else {
              toast.error('Failed to create dialogue.');
            }
          }}
          icon={Plus}
          disabled={generatedIntent.trim().length === 0}
          isSubmitting={isLoading || isLocked}
        >
          Create dialogue
        </Button>
        {state !== 'REJECTED' && (
          <Button
            onClick={() => archiveDialogueCandidate()}
            title="Archive"
            icon={MoveToArchive}
            isSubmitting={isLoading || isLocked}
          />
        )}
      </div>
    </div>
  );
};

export default SingleDialogueCandidate;
