import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { getAvailableLanguages } from 'frontend/api/queries';
import type { Language } from 'frontend/types/language';

function useAvailableLanguages(onlyEnabled = false): { languages: Language[]; loading: boolean } {
  const { data, loading } = useQuery(getAvailableLanguages, {
    variables: { onlyEnabled },
  });
  const languages = useMemo(() => data?.availableLanguages ?? [], [data]);
  return { languages, loading };
}

export default useAvailableLanguages;
