import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { OrganizationMetaDocument, SkillContextDocument } from 'frontend/api/generated';
import { ChevronDown } from 'frontend/assets/icons';
import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import kindlyLogo from 'frontend/assets/images/logo.svg?url';
import defaultLogo from 'frontend/assets/images/organization_avatar.svg?url';
import { Dropdown, Icon } from 'frontend/components';
import { getUrlParameter } from 'frontend/utils';

import Names from './Names';
import OrganizationContentMenu from './OrganizationContentMenu';
import RecentlyUsedMenu from './RecentlyUsedMenu';
import styles from './styles.scss';
import { BOT_CONTEXT } from '../../queries';

const NavbarDropdown = () => {
  const { pathname } = useLocation();
  const botId = getUrlParameter('workspace', pathname);
  const skillId = getUrlParameter('skill', pathname);
  const organizationId = getUrlParameter('organization', pathname);

  const { data: botData, loading: botLoading } = useQuery(BOT_CONTEXT, { variables: { botId }, skip: !botId });
  const { data: organizationData, loading: organizationLoading } = useQuery(OrganizationMetaDocument, {
    variables: { id: organizationId! },
    skip: !organizationId,
  });
  const { data: skillData, loading: skillLoading } = useQuery(SkillContextDocument, {
    variables: { skillId: skillId! },
    skip: !skillId,
  });

  const botOrSkillView = botId || skillId;
  const organizationView = Boolean(organizationId);
  const adminView = !(botOrSkillView || organizationView);
  const organization = skillData?.skill?.organization || botData?.bot?.organization || organizationData?.organization;
  const botOrSkillname = skillData?.skill?.name || botData?.bot?.projectName || botData?.bot?.name;
  const botLogo = get(botData, 'bot.avatarUrl');
  const botOrOrganizationLogo = organizationView ? organization?.logo || defaultLogo : botLogo || defaultBotAvatar;

  const renderOverlay = useCallback(
    ({ close }) => (
      <>
        {(!!botOrSkillView || organizationView) && organization && (
          <OrganizationContentMenu organization={organization} close={close} />
        )}
        <RecentlyUsedMenu close={close} />
      </>
    ),
    [botOrSkillView, organization, organizationView],
  );

  if (botLoading || skillLoading || organizationLoading) return null;

  return (
    <Dropdown
      position="bottom-right"
      overlayClassName={styles.dropdown}
      triggerClassName={styles.dropdownTrigger}
      wrapperClassName={styles.dropdownWrapper}
      overlay={renderOverlay}
    >
      <div className={styles.logoContainer}>
        <img
          alt="Logo"
          className={cx(styles.logo, !adminView && !organizationView && styles.circle, adminView && styles.admin)}
          src={adminView ? kindlyLogo : botOrOrganizationLogo}
        />
        {(!!botOrSkillView || organizationView) && (
          <Names organizationName={organization?.name ?? ''} borOrSkillname={botOrSkillname} />
        )}
        <Icon component={ChevronDown} className={styles.logoIcon} title="Navigation dropdown" />
      </div>
    </Dropdown>
  );
};

export default NavbarDropdown;
