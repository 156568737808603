import type { Row } from 'frontend/components/Table/Table';

import styles from '../styles.scss';

export function QuantityCell({
  rowData: workspace,
  propertyName,
}: {
  rowData?: Row;
  propertyName: 'dialogueCount' | 'subscriptionCount';
}) {
  if (!workspace) return null;
  const quantity = workspace[propertyName];
  return <div className={styles.quantityCell}>{quantity > 0 ? quantity : '-'}</div>;
}
