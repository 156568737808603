import { Link } from 'react-router-dom';

import { Button, Loader } from 'frontend/components';
import { SlackAuthorization, SlackConnectionStatus } from 'frontend/features/Profile/components';

import styles from './SlackBot.scss';
import useConnectSlackBot from './useConnectSlackBot';

const SLACK_CHANNEL_NOTIFICATIONS = ['incoming-webhook'];

export const SlackBot = () => {
  const { loading, bot, handleConnect, handleDisconnect, slackWorkspaceBot, connectLoading, slackWorkspaceProfile } =
    useConnectSlackBot();

  if (loading) {
    return <Loader size="large" />;
  }

  const profileConnected = Boolean(slackWorkspaceProfile);
  const botConnected = Boolean(slackWorkspaceBot);

  return (
    <div>
      <div className={styles.status}>
        <h4>Connection status</h4>
        {!profileConnected && !botConnected && (
          <Link to="/profile/connected-accounts">
            <Button color="primary" text="Setup your connected account" />
          </Link>
        )}
        {profileConnected && (
          <p>
            Your Kindly profile is connected to the Slack workspace <b>{slackWorkspaceProfile?.workspaceName}</b>
          </p>
        )}
        {profileConnected && !botConnected && (
          <Button onClick={handleConnect} color="primary" isSubmitting={connectLoading}>
            Enable Slack actions for {bot?.name}
          </Button>
        )}
        {botConnected && <SlackConnectionStatus profile={slackWorkspaceBot} disconnect={handleDisconnect} />}
      </div>
      {botConnected && (
        <div className={styles.status}>
          <h4>Permissions</h4>
          {slackWorkspaceBot && !slackWorkspaceBot.webhookChannelName && (
            <SlackAuthorization
              scopes={SLACK_CHANNEL_NOTIFICATIONS}
              text="Give bot permission to publish to a channel"
            />
          )}
          {slackWorkspaceBot && slackWorkspaceBot.webhookChannelName && (
            <ul>
              <li>Can publish to {slackWorkspaceBot.webhookChannelName}</li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
