import PropTypes from 'prop-types';

import { ChevronUp, UserPlus } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import Dropdown from 'frontend/components/Dropdown';
import { TicketType } from 'frontend/features/Inbox/propTypes';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { IDType } from 'frontend/propTypes';

import AgentList from './AgentList';
import styles from './TicketAssignManager.scss';
import sharedMetadataStyles from '../../metadata-styles.scss';

export default function TicketAssignManager({ ticket, botId, emailCc }) {
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });

  const canManageTickets = hasBotPerms('manage_tickets');

  if (loadingPermissions || !canManageTickets) {
    return null;
  }

  const assignedAgent = ticket.assignedAgent?.profile?.fullName ?? null;
  const avatarUrl = ticket.assignedAgent?.profile?.avatarUrl ?? null;

  return (
    <Dropdown
      wrapperClassName={sharedMetadataStyles.dropdownWrapper}
      triggerClassName={sharedMetadataStyles.dropdownTrigger}
      overlayClassName={sharedMetadataStyles.dropdownOverlay}
      overlayMaxWidth="parent"
      overlay={<AgentList ticket={ticket} botId={botId} emailCc={emailCc} />}
      position="top"
    >
      <div className={sharedMetadataStyles.iconLeftAndTextWrapper}>
        {!avatarUrl ? (
          <Icon component={UserPlus} color="black" />
        ) : (
          <img className={styles.avatar} src={avatarUrl} alt="" />
        )}
        <span className={sharedMetadataStyles.buttonText}>
          {assignedAgent ? <span>{assignedAgent}</span> : 'Assign'}
        </span>
      </div>
      <Icon component={ChevronUp} className={sharedMetadataStyles.iconChevron} color="black" />
    </Dropdown>
  );
}

TicketAssignManager.propTypes = {
  ticket: TicketType,
  botId: IDType.isRequired,
  emailCc: PropTypes.arrayOf(PropTypes.string).isRequired,
};
