/* eslint-disable camelcase */
import { parseISO } from 'date-fns';

import palette from 'frontend/components/Stats/Palette';

export const baywatchSeriesToValue = (data, yKey) => data?.reduce((acc, campaign) => campaign[yKey] + acc, 0);

export const baywatchValuesToRate = (dividend, divisor) => {
  if (dividend === undefined || divisor === undefined) return undefined;
  if (divisor === 0) return 0;
  return parseFloat(((dividend / divisor) * 100).toFixed(2));
};

export const baywatchValuesToAverage = (dividend, divisor) => {
  if (dividend === undefined || divisor === undefined) return undefined;
  if (divisor === 0) return 0;
  return Math.round(dividend / divisor);
};

export const baywatchSeriesToLineData = (data, yKey = 'count', color = palette[0]) => ({
  color,
  data: (data || []).map((item) => {
    const x = parseISO(item.date);
    return {
      displayX: x,
      x,
      y: item[yKey],
      color,
    };
  }),
});

export const baywatchSeriesToRateSeries = (data, dividend, divisor, color = palette[0]) => ({
  color,
  data: data?.map((item) => {
    const x = parseISO(item.date);
    return {
      displayX: x,
      x,
      y: Number(item[dividend] / item[divisor]) || null,
      color,
    };
  }),
});

export const baywatchRateToPieData = (rate) => {
  const total = 100;
  const value = rate === undefined ? 0 : rate;

  return {
    total,
    data: [
      { value: total - value, label: 'first' },
      { value, label: 'second' },
    ],
  };
};

export const baywatchValuesToPieData = (value1, value2) => {
  const first = value1 === undefined ? 0 : value1;
  const second = value2 === undefined ? 0 : value2;
  const total = first + second;

  return {
    total,
    data: [
      { value: first, label: 'first' },
      { value: second, label: 'second' },
    ],
  };
};
