import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { Loader } from 'frontend/components';
import { NotFound, PublishSkill, SkillSubscribers } from 'frontend/features';
import { PusherMultiple, getPusherChannel } from 'frontend/features/Pusher';
import SkillSettings from 'frontend/features/SkillSettings/SkillSettings';
import { useInitialiseLanguage, useTabTitle } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { setCurrentSkill } from 'frontend/state/dux/navbar';

import BuildRoutes from './BuildRoutes';
import { useBotLikeEvents } from './hooks';

const SkillRoutes = () => {
  const { skillId } = useParams();
  const { hasSkillPerms, loading: permissionsLoading } = useMyPermissions({ skillId });
  const canReadContent = hasSkillPerms('read_skill_content');

  const dispatch = useDispatch();

  const { loading: skillLoading, skillData } = useTabTitle();

  const skill = skillData?.skill;
  const botLikeEvents = useBotLikeEvents(skill);

  useEffect(() => {
    if (!skill) {
      return;
    }
    dispatch(setCurrentSkill(skill));
  }, [dispatch, skill, skillId]);

  useInitialiseLanguage();

  if (permissionsLoading || skillLoading) {
    return <Loader size="large" />;
  }

  if (!skill) {
    return <NotFound text="Skill not found or missing permissions" />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to={`${canReadContent ? 'build' : 'subscribers'}`} replace />} />
        <Route path="/build/*" element={<BuildRoutes isSkill />} />
        <Route path="/publish" element={<PublishSkill />} />
        <Route path="/subscribers" element={<SkillSubscribers />} />
        <Route path="/settings/*" element={<SkillSettings />} />
        <Route element={<NotFound />} />
      </Routes>
      <PusherMultiple channel={getPusherChannel({ skillId })} events={botLikeEvents} />
    </>
  );
};

export default SkillRoutes;
