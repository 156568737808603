import { orderBy } from 'lodash';
import PropTypes from 'prop-types';

import { useBotOrSkill } from 'frontend/hooks';

import styles from './KindlyEntityList.scss';
import KindlyEntityListItem from './KindlyEntityListItem';
import { KindlyEntitiesType } from '../../propTypes';

const KindlyEntityList = ({ kindlyEntities, noEntities = 'None', withRemove, withProperties }) => {
  const { isBot } = useBotOrSkill();

  const entities = orderBy(kindlyEntities, 'category').map((entity) => ({
    key: `entity-${entity.id}`,
    isbot: isBot,
    withRemove,
    withProperties,
    lessInfo: true,
    kindly: true,
    ...entity,
  }));

  return (
    <>
      {kindlyEntities.length === 0 && !!noEntities && <i>{noEntities}</i>}
      {kindlyEntities.length > 0 && (
        <>
          <div className={styles.listHeader}>
            <span>Name</span>
            <span>Description</span>
          </div>
          <div className={styles.listContainer}>
            {entities.map((entity) => (
              <KindlyEntityListItem
                key={entity.key}
                entity={entity}
                withRemove={withRemove}
                withProperties={withProperties}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

KindlyEntityList.propTypes = {
  kindlyEntities: KindlyEntitiesType.isRequired,
  noEntities: PropTypes.string,
  withRemove: PropTypes.bool,
  withProperties: PropTypes.bool,
};

export default KindlyEntityList;
