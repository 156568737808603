import { useLocation } from 'react-router-dom';

import SlackIcon from 'frontend/assets/images/slack-mark.svg';
import { Button } from 'frontend/components';

import styles from './styles.scss';

interface SlackAuthorizationProps {
  scopes: string[];
  text?: string;
}

const SlackAuthorization = ({ scopes, text = 'Add to Slack' }: SlackAuthorizationProps) => {
  const { pathname: redirect } = useLocation();
  const absoluteUri = `https://slack.com/oauth/v2/authorize?scope=${scopes.join(',')}&client_id=${
    window.env.SLACK_CLIENT_ID
  }&redirect_uri=${window.env.SITE_URL}${redirect}`;

  return (
    <Button href={absoluteUri} icon={SlackIcon} color="secondary" className={styles.slackButton}>
      {text}
    </Button>
  );
};

export default SlackAuthorization;
