import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { integer } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';

import { SET_ENGAGEMENT_ID } from '../../mutations';

const EngagementId = ({ args: { organizationId, engagementId }, hide }) => {
  const toast = useToast();
  const [setEngagementId] = useMutation(SET_ENGAGEMENT_ID);
  const initialValues = useMemo(() => ({ engagementId }), [engagementId]);

  const onSubmit = useCallback(
    async ({ engagementId: newId }) => {
      await setEngagementId({ variables: { engagementId: newId, organizationId } });
      toast.success('Engagement ID updated');
    },
    [organizationId, setEngagementId, toast],
  );

  return (
    <ModalForm title="Engagement ID" hide={hide} initialValues={initialValues} onSubmit={onSubmit}>
      <Field component={Input} name="engagementId" placeholder="ID" validate={integer} autoFocus />
    </ModalForm>
  );
};

EngagementId.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    organizationId: IDType.isRequired,
    engagementId: PropTypes.number,
  }).isRequired,
};

export default EngagementId;
