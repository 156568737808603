import red0 from 'frontend/assets/images/emojis/0-red.svg?base64';
import red1 from 'frontend/assets/images/emojis/1-red.svg?base64';
import green10 from 'frontend/assets/images/emojis/10-green.svg?base64';
import red2 from 'frontend/assets/images/emojis/2-red.svg?base64';
import red3 from 'frontend/assets/images/emojis/3-red.svg?base64';
import red4 from 'frontend/assets/images/emojis/4-red.svg?base64';
import red5 from 'frontend/assets/images/emojis/5-red.svg?base64';
import red6 from 'frontend/assets/images/emojis/6-red.svg?base64';
import yellow7 from 'frontend/assets/images/emojis/7-yellow.svg?base64';
import yellow8 from 'frontend/assets/images/emojis/8-yellow.svg?base64';
import green9 from 'frontend/assets/images/emojis/9-green.svg?base64';
import excellent from 'frontend/assets/images/emojis/excellent.svg?base64';
import good from 'frontend/assets/images/emojis/good.svg?base64';
import okay from 'frontend/assets/images/emojis/okay.svg?base64';
import poor from 'frontend/assets/images/emojis/poor.svg?base64';
import thumbsDown from 'frontend/assets/images/emojis/thumbs-down.svg?base64';
import thumbsUp from 'frontend/assets/images/emojis/thumbs-up.svg?base64';
import veryPoor from 'frontend/assets/images/emojis/very-poor.svg?base64';
import type { TupifyObjectValues } from 'frontend/utils/TS';
import randomUUID from 'frontend/utils/randomUUID';

export const RHF_FORMS_NAME = 'feedbackForms' as const;
export type RHF_FORMS_FEEDBACK_PATH = `${typeof RHF_FORMS_NAME}.${number}`;

export interface FeedbackForm {
  id: string;
  isActive: boolean;
  formTitle: string;
  triggerType: 'bot_conversation' | 'handover_conversation';
  feedbackType: 'widget_feedback' | 'external_feedback';
  urlForFeedback: string;
  headline: string;
  buttonLabel: string;
  nextButton: string;
  backButton: string;
  sendButton: string;
  thanksText: string;
  ratingScale: {
    ratingSystem: 2 | 3 | 4 | 5 | 11;
    title: string;
    editLabels: {
      id: string;
      image: string;
      rating: string;
      sentiment: Sentiment;
      showRating: boolean;
    }[];
  };
  predefinedButtons: {
    enableStep: boolean;
    useSentimentButtons: boolean;
    sentiments: Record<Sentiment, { title: string; buttons: { id: string; text: string }[] }>;
  };
  freeForm: {
    enableStep: boolean;
    title: string;
    placeholder: string;
  };
}

export type Sentiment = 'noSentiment' | 'negative' | 'neutral' | 'positive';

export const DEFAULT_RATING_SYSTEMS_TO_RATINGS: Record<
  FeedbackForm['ratingScale']['ratingSystem'],
  TupifyObjectValues<
    Omit<FeedbackForm['ratingScale']['editLabels'][number], 'id'>,
    FeedbackForm['ratingScale']['ratingSystem']
  >
> = {
  2: {
    image: [thumbsDown, thumbsUp],
    rating: ['No', 'Yes'],
    sentiment: ['negative', 'positive'],
    showRating: [true, true],
  },
  3: {
    image: [poor, okay, excellent],
    rating: ['Poor', 'Okay', 'Excellent'],
    sentiment: ['negative', 'neutral', 'positive'],
    showRating: [true, true, true],
  },
  4: {
    image: [veryPoor, poor, good, excellent],
    rating: ['Poor', 'Fair', 'Good', 'Excellent'],
    sentiment: ['negative', 'negative', 'positive', 'positive'],
    showRating: [true, true, true, true],
  },
  5: {
    image: [veryPoor, poor, okay, good, excellent],
    rating: ['Very poor', 'Poor', 'Okay', 'Good', 'Excellent'],
    sentiment: ['negative', 'negative', 'neutral', 'positive', 'positive'],
    showRating: [true, true, true, true, true],
  },
  11: {
    image: [red0, red1, red2, red3, red4, red5, red6, yellow7, yellow8, green9, green10],
    rating: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    sentiment: [
      'negative',
      'negative',
      'negative',
      'negative',
      'negative',
      'negative',
      'negative',
      'neutral',
      'neutral',
      'positive',
      'positive',
    ],
    showRating: [false, false, false, false, false, false, false, false, false, false, false],
  },
} as const;

export const getDefaultLabelImage = (
  ratingSystem: FeedbackForm['ratingScale']['ratingSystem'],
  index: number,
): FeedbackForm['ratingScale']['editLabels'][number]['image'] =>
  DEFAULT_RATING_SYSTEMS_TO_RATINGS[ratingSystem].image[index]!;

export const createNewForm = (): FeedbackForm => ({
  id: randomUUID(),
  isActive: false,
  formTitle: 'My new feedback form',
  triggerType: 'bot_conversation',
  feedbackType: 'widget_feedback',
  urlForFeedback: 'https://www.example.com',
  headline: 'Rate the conversation',
  buttonLabel: 'Send',
  nextButton: 'Next',
  backButton: 'Back',
  sendButton: 'Send',
  thanksText: 'Thanks for your feedback!',
  ratingScale: {
    ratingSystem: 2,
    title: 'How would you rate your conversation?',
    editLabels: [
      {
        id: randomUUID(),
        sentiment: DEFAULT_RATING_SYSTEMS_TO_RATINGS[2].sentiment[0],
        image: DEFAULT_RATING_SYSTEMS_TO_RATINGS[2].image[0],
        rating: DEFAULT_RATING_SYSTEMS_TO_RATINGS[2].rating[0],
        showRating: DEFAULT_RATING_SYSTEMS_TO_RATINGS[2].showRating[0],
      },
      {
        id: randomUUID(),
        sentiment: DEFAULT_RATING_SYSTEMS_TO_RATINGS[2].sentiment[1],
        image: DEFAULT_RATING_SYSTEMS_TO_RATINGS[2].image[1],
        rating: DEFAULT_RATING_SYSTEMS_TO_RATINGS[2].rating[1],
        showRating: DEFAULT_RATING_SYSTEMS_TO_RATINGS[2].showRating[1],
      },
    ],
  },
  predefinedButtons: {
    enableStep: true,
    useSentimentButtons: false,
    sentiments: {
      noSentiment: {
        title: 'Anything else to add?',
        buttons: [
          { id: randomUUID(), text: 'Issue was not resolved' },
          { id: randomUUID(), text: 'Confusing communication' },
          { id: randomUUID(), text: 'Issue was partially resolved' },
          { id: randomUUID(), text: 'Informative answers' },
        ],
      },
      negative: {
        title: 'What went wrong?',
        buttons: [
          { id: randomUUID(), text: "Bot didn't understand my question" },
          { id: randomUUID(), text: "Didn't get the help I needed" },
          { id: randomUUID(), text: 'Responses too slow' },
          { id: randomUUID(), text: 'Issue was not resolved' },
        ],
      },
      neutral: {
        title: 'What could be improved?',
        buttons: [
          { id: randomUUID(), text: 'Bot could be smarter' },
          { id: randomUUID(), text: 'Bot could be faster' },
          { id: randomUUID(), text: 'Clearer communication' },
        ],
      },
      positive: {
        title: 'What went well?',
        buttons: [
          { id: randomUUID(), text: 'Informative answers' },
          { id: randomUUID(), text: 'User-friendly interface' },
          { id: randomUUID(), text: 'Clear communication' },
          { id: randomUUID(), text: 'Issue was resolved' },
        ],
      },
    },
  },
  freeForm: {
    enableStep: true,
    title: 'Anything else to add?',
    placeholder: 'Tell us more',
  },
});

export const TRIGGER_TYPES_TO_TEXT: Record<FeedbackForm['triggerType'], string> = {
  bot_conversation: 'Bot conversation',
  handover_conversation: 'Handover conversation',
} as const;
