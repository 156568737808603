/** Check if the UA Platform is the one you passed as argument. Case insensitive.
 * @example isPlatform('ios') // true if the UA is an iOS device
 * @example isPlatform('mac') // true if the UA is a Mac device
 */
function isPlatform(platform: string): boolean {
  // eslint-disable-next-line compat/compat, deprecation/deprecation
  const UAPlatform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
  const isAskedPlatform = UAPlatform.toLowerCase().includes(platform.toLowerCase());

  return isAskedPlatform;
}

export default isPlatform;
