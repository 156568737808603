/**
  Clears the cache for all keys using the variables in `variablesToClear`.
  Active queries will automatically refresh when the cache is invalidated.
  @example
  // Will clear the cache for all fields with the botId '123'.
  removeFieldsContainingVariables(client, { botId: '123' })
*/
import type { ApolloClient } from '@apollo/client';

export default function removeFieldsContainingVariables(
  client: ApolloClient<object>,
  variablesToClear: { [key: string]: string },
): void {
  const cacheData = client.cache.extract();
  // Cache is already empty, no need to clear anything.
  if (!cacheData || Object.keys(cacheData).length === 0) return;

  // @ts-expect-error FIXME: How to allow accessing client.cache.data...?
  Object.keys(client.cache.data.data.ROOT_QUERY).forEach((query) => {
    const match = /^(\w+)(?:\((.*)\))?$/.exec(query);
    if (match?.[1] && match?.[2]) {
      const field = match[1];
      const variables = JSON.parse(match[2]);

      const shouldClear = Object.entries(variablesToClear).findIndex(([key, value]) => variables[key] !== value) === -1;
      if (shouldClear) {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: field, args: variables });
        client.cache.gc();
      }
    }
  });
}
