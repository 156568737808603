import { gql } from '@apollo/client';

import { botBackupFragment } from '../fragments';

export default gql`
  query BotBackups($botId: ID!) {
    botBackups(botId: $botId) {
      ...BotBackup
    }
  }
  ${botBackupFragment}
`;
