import { useCallback, useEffect, useState } from 'react';

import { Loader, PageContent, Panel } from 'frontend/components';

import styles from './Feature.scss';
import useCannyToken from './useCannyToken';

// The following script is documented here: https://developers.canny.io/
function cannyScript(w: any, d: any, i: string, s: string): void {
  /* eslint-disable */
  function l() {
    if (!d.getElementById(i)) {
      const f = d.getElementsByTagName(s)[0];
      const e = d.createElement(s);
      (e.type = 'text/javascript'),
        (e.async = !0),
        (e.src = 'https://canny.io/sdk.js'),
        f.parentNode.insertBefore(e, f);
    }
  }

  if (typeof w.Canny !== 'function') {
    var c = function () {
      // @ts-ignore
      c.q.push(arguments);
    };
    // @ts-ignore
    (c.q = []),
      (w.Canny = c),
      d.readyState === 'complete'
        ? l()
        : w.attachEvent
          ? w.attachEvent('onload', l)
          : w.addEventListener('load', l, !1);
  }
  /* eslint-enable */
}

export default function Feature() {
  const [loading, setLoading] = useState(true);

  const cannyToken = useCannyToken();

  const setCannyLoadingComplete = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    const boardToken = window.env.CANNY_BOARD_FEATURE_REQUESTS;

    cannyScript(window, document, 'canny-jssdk', 'script');

    if (cannyToken) {
      /* eslint-disable */
      // @ts-ignore
      Canny('render', {
        boardToken,
        ssoToken: cannyToken,
        onLoadCallback: setCannyLoadingComplete,
      });
      /* eslint-enable */
    }
  }, [cannyToken, setCannyLoadingComplete]);

  return (
    <PageContent>
      <h1 className={styles.title}>Feature ideas</h1>
      <div className={styles.content}>
        <p className={styles.description}>
          If you would like to suggest a new feature in Kindly, please create a new post and describe it in as much
          detail as possible.
          <br />
          Remember to check if someone else has posted a similar suggestion before you add something new.
          <br />
          By voting on existing posts, you give us the opportunity to know which features are most wanted, so if you see
          anything you like, click on the arrow on the left hand side!
        </p>
        <strong>Remember to:</strong>
        <ul>
          <li>Write in English</li>
          <li>One feature per post</li>
          <li>Add a descriptive title and detailed description</li>
        </ul>
      </div>
      <Panel className={styles.panel}>
        {loading && <Loader size="large" />}
        <div data-canny />
      </Panel>
    </PageContent>
  );
}
