// TODO: Rename Checkbox to CheckboxAndLabel (using this component), then rename this component to Checkbox.
// Also, decide for a style on :hover:checked when discussion about lighten/darken is resolved.

import cx from 'classnames';

import styles from './CheckboxOnly.scss';

interface CheckboxOnlyProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

/** Kindly checkbox with all the default styles and a nice animation.
 *
 * It's just an `<input type="checkbox">` element, using modern CSS without hacks to style/replace the checkbox. */
function CheckboxOnly({ className, ...rest }: CheckboxOnlyProps) {
  return <input type="checkbox" className={cx(styles.checkbox, className)} {...rest} />;
}

export default CheckboxOnly;
