import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Link, useParams } from 'react-router-dom';

import { BotPermissionsDocument } from 'frontend/api/generated';
import { LoaderSwitch, Panel } from 'frontend/components';
import { BOT_ROLES } from 'frontend/features/BotSettings/queries';
import { RoleDetails } from 'frontend/features/Organization/components';
import { CUSTOM_ROLE_NAME } from 'frontend/features/Organization/constants';

import styles from './DefaultRoles.scss';

const DefaultRoles = () => {
  const { botId } = useParams();
  const { data: rolesData, loading: rolesLoading } = useQuery(BOT_ROLES, { variables: { botId } });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(BotPermissionsDocument);

  const predefinedRoles = get(rolesData, 'bot.roles', []).filter(({ name }) => name !== CUSTOM_ROLE_NAME);
  const allPermissions = get(permissionsData, 'botPermissions', []);

  return (
    <Panel>
      <div className={styles.description}>
        When you want to grant users permissions in a workspace on top of those they have in the organization you can
        use workspace roles. Update member&apos;s roles{' '}
        <Link to={`/workspace/${botId}/settings/memberships`}>here</Link>.
      </div>
      <br />
      <br />
      <LoaderSwitch loading={rolesLoading || permissionsLoading} size="large">
        <RoleDetails roles={predefinedRoles} allPermissions={allPermissions} />
      </LoaderSwitch>
    </Panel>
  );
};

export default DefaultRoles;
