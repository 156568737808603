import { Form } from 'react-final-form';

import { FormErrors, GuardUnsaved, Loader, PageBar, Panel, ToggleSwitch } from 'frontend/components';

import styles from './styles.scss';
import useBetaSettings from './useBetaSettings';

const organizationLevelInboxSwitch = ['Organization level inbox enabled', 'Enable organization level inbox'];
const organizationBillingSwitch = [
  'Organization admins to view Kindly GPT billing page enabled',
  'Enable organization admins to view Kindly GPT billing page',
];

export const BetaSettings = () => {
  const { onSubmit, initialValues, isKindlyManager, loading } = useBetaSettings();
  if (loading) {
    return <Loader />;
  }
  return (
    <Panel>
      <h3 className="m-b-3">Beta settings</h3>{' '}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors />
              {isKindlyManager && (
                <div className={styles.betaSettingsWrapper}>
                  <Panel.Separator />
                  <ToggleSwitch name="enableOrganizationInbox" status={organizationLevelInboxSwitch} />
                  <Panel.Separator />
                  <ToggleSwitch name="enableOrganizationBilling" status={organizationBillingSwitch} />
                </div>
              )}
            </form>
          </>
        )}
      />
    </Panel>
  );
};
