import cx from 'classnames';
import { useRef, useState } from 'react';

import type { LabelType } from 'frontend/api/generated';
import { ChevronLeft, Edit, Labels, Search } from 'frontend/assets/icons';
import { Button, EmptyState, Icon, Input, LoaderSwitch } from 'frontend/components';
import LabelsCreatorAndUpdater from 'frontend/features/Labels/LabelsCreatorAndUpdater/LabelsCreatorAndUpdater';
import LabelsDeleter from 'frontend/features/Labels/LabelsDeleter/LabelsDeleter';
import { LABEL_PHASES } from 'frontend/features/Labels/constants';
import { useSearch } from 'frontend/hooks';
import type { Label } from 'frontend/propTypes/LabelType';

import LabelList from './LabelList/LabelList';
import LabelRow from './LabelRow/LabelRow';
import styles from './LabelsSection.scss';

const SEARCH_OPTIONS = { threshold: 0.6 };
const LABEL_EMPTY_STATE = {
  id: '',
  name: '',
  color: '',
  background: '',
  active: undefined,
};

interface Props {
  activeLabelsIds: string[];
  botLabels?: Partial<LabelType>[];
  canEditLabels: boolean;
  className?: string;
  handleLabelToggle: (label: Label, status: boolean) => void;
  inputAutofocus?: boolean;
  inputPlaceholder?: string;
  loading?: boolean;
  shouldDisplaySystemLabels?: boolean;
}

const LabelsSection = ({
  activeLabelsIds,
  botLabels,
  canEditLabels,
  className,
  handleLabelToggle,
  inputAutofocus,
  inputPlaceholder = canEditLabels ? 'Search or add labels' : 'Search labels',
  loading = false,
  shouldDisplaySystemLabels = false,
}: Props) => {
  const [labelManagerPhase, setLabelManagerPhase] = useState(LABEL_PHASES.DEFAULT);
  const [labelToManage, setLabelToManage] = useState<Label>(LABEL_EMPTY_STATE);
  const inputRef = useRef<HTMLInputElement>(null);

  // @ts-expect-error TS useSearch
  const { searchResults, query, setQuery } = useSearch(botLabels, 'name', SEARCH_OPTIONS);

  const isLabelAlreadyExisting = botLabels?.some((label) => label.name?.toLowerCase() === query.toLowerCase());

  const resetLabelsState = () => {
    setLabelManagerPhase(LABEL_PHASES.DEFAULT);
    setLabelToManage(LABEL_EMPTY_STATE);
    setQuery('');
  };

  return (
    <LoaderSwitch loading={loading} size="large">
      <div className={cx(styles.wrapper, className)}>
        {labelManagerPhase !== LABEL_PHASES.DELETE && (
          <div className={styles.inputContainer}>
            <Input
              ref={inputRef}
              input={{ name: 'labelsInput', value: query, onChange: ({ target }) => setQuery(target.value) }}
              className={styles.input}
              placeholder={labelManagerPhase === LABEL_PHASES.EDIT ? 'Update the label' : inputPlaceholder}
              aria-label="Search labels"
              adornment={
                <Icon
                  width={18}
                  height={18}
                  color="gray"
                  component={labelManagerPhase === LABEL_PHASES.EDIT ? Edit : Search}
                />
              }
              autoFocus={inputAutofocus}
              adornmentPosition="left"
              autoComplete="off"
            />
          </div>
        )}

        {labelManagerPhase === LABEL_PHASES.EDIT && (
          <LabelRow
            label={labelToManage}
            subText="Old"
            buttonComponent={
              <Button
                icon={ChevronLeft}
                flat
                className={styles.editLabelButton}
                text="Cancel"
                onClick={resetLabelsState}
              />
            }
          />
        )}

        {canEditLabels && (!isLabelAlreadyExisting || labelManagerPhase === LABEL_PHASES.EDIT) && !!query && (
          <LabelsCreatorAndUpdater
            labelToCreate={query}
            labelManagerPhase={labelManagerPhase}
            setLabelManagerPhase={setLabelManagerPhase}
            labelToEdit={labelToManage}
            resetLabelsState={resetLabelsState}
          />
        )}

        {!botLabels?.length && (
          <EmptyState
            title="Create a label"
            description="Nobody has added any labels to this Workspace yet. Be the first to do it!"
            icon={Labels}
            color="red"
            maxWidth="90%"
          />
        )}

        {botLabels && labelManagerPhase !== LABEL_PHASES.DELETE && labelManagerPhase !== LABEL_PHASES.EDIT && (
          <LabelList
            totalLabelsLength={botLabels.length}
            handleLabelToggle={handleLabelToggle}
            searchedLabels={searchResults}
            activeLabelsIds={activeLabelsIds}
            shouldDisplaySystemLabels={shouldDisplaySystemLabels}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(canEditLabels
              ? { canEditLabels, setLabelManagerPhase, setLabelToManage, setInput: setQuery, inputRef }
              : { canEditLabels })}
          />
        )}

        {labelManagerPhase === LABEL_PHASES.DELETE && (
          <LabelsDeleter labelToDelete={labelToManage} resetLabelsState={resetLabelsState} />
        )}
      </div>
    </LoaderSwitch>
  );
};

export default LabelsSection;
