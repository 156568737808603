import styles from './styles.scss';

export interface Product {
  tempId: string;
  imageUrl?: string;
  altText?: string;
  title?: string;
  linkText?: string;
  price?: string;
  description?: string;
}

interface Props {
  product: Product;
}
const ProductCard = ({ product }: Props) => {
  const { imageUrl = '', altText = '', title = '', linkText = '', price = '', description = '' } = product;

  return (
    <div className={styles.product}>
      <div className={styles.productWrapper}>
        {!!imageUrl && <img className={styles.productImage} src={imageUrl} alt={altText} />}
        <div className={styles.content}>
          {!!title && <span className={styles.title}>{title}</span>}
          {!!description && <span className={styles.description}>{description}</span>}
          {!!price && <span className={styles.price}>{price}</span>}
        </div>
      </div>
      {!!linkText && <span className={styles.link}>{linkText}</span>}
    </div>
  );
};

export default ProductCard;
