import { useMutation } from '@apollo/client';
import { noop } from 'lodash';

import { type MeQuery, ToggleFavoriteBotDocument } from 'frontend/api/generated';
import { DotsVertical, HeartMinus, HeartPlus, MoveToArchive, Pin, Unpin } from 'frontend/assets/icons';
import { DropdownButton, Icon } from 'frontend/components';
import type { Row } from 'frontend/components/Table/Table';
import ArchiveBot from 'frontend/features/BotSettings/General/modals/ArchiveBot';
import { useModal } from 'frontend/features/Modals';
import TOGGLE_BOT_PINNED from 'frontend/features/Organization/mutations/TOGGLE_BOT_PINNED';
import { RecoverBot } from 'frontend/features/RecoverBot/modals';
import { useIsRunning, useToast } from 'frontend/hooks';

import styles from '../styles.scss';

export function DropDownActions({ rowData: workspace, me }: { rowData: Row; me: MeQuery['me'] }) {
  const { isPinned, isArchived } = workspace;
  const isFavorite = me.profile?.favoriteBots?.some((bot) => bot.id === workspace.id);
  const [toggleFavoriteBot] = useMutation(ToggleFavoriteBotDocument, {
    variables: {
      botId: String(workspace.id)!,
      favorite: !isFavorite,
    },
    refetchQueries: ['Me'],
  });
  const toast = useToast();
  const onClickFavorite = async () => {
    await toggleFavoriteBot();
    toast.success('Your list of favorite bots is updated. Click the user profile to see the list.');
  };
  const [togglePinned] = useMutation(TOGGLE_BOT_PINNED, { variables: { botId: workspace.id } });
  const [togglePinnedWithSubmitting, isSubmitting] = useIsRunning(togglePinned);
  const [showArchiveBot] = useModal(ArchiveBot);
  const [showRecoverBot] = useModal(RecoverBot);
  return (
    <DropdownButton
      wrapperClassName={styles.options}
      actions={[
        {
          title: isFavorite ? 'Remove from your favorites' : 'Add to your favorites',
          key: 'favorite-or-unfavorite',
          onClick: onClickFavorite,
          icon: isFavorite ? <Icon component={HeartMinus} /> : <Icon component={HeartPlus} />,
        },
        {
          title: isPinned ? 'Unpin in organization' : 'Pin in organization',
          key: 'pin-or-unpin',
          onClick: isSubmitting ? noop : togglePinnedWithSubmitting,
          icon: isPinned ? <Icon component={Unpin} /> : <Icon component={Pin} />,
        },
        {
          key: 'divider',
          className: styles.divider,
        },
        {
          title: isArchived ? 'Recover bot' : 'Move to archive',
          key: 'archive-or-recover',
          onClick: () =>
            isArchived
              ? showRecoverBot({ botId: workspace.id, name: workspace.name })
              : showArchiveBot({ botId: workspace.id, name: workspace.name }),
          icon: <Icon component={MoveToArchive} />,
          className: 'bottomOption',
        },
      ]}
    >
      <Icon component={DotsVertical} />
    </DropdownButton>
  );
}
