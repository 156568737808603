import { useMutation } from '@apollo/client';
import { ContentState, EditorState, Modifier, RichUtils, convertToRaw, getDefaultKeyBinding } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import type { HandoverType } from 'frontend/features/Inbox/constants';
import { useToast } from 'frontend/hooks';
import { setHandoverMessages } from 'frontend/state/dux/inbox';
import { CHAT_SOURCE } from 'frontend/types/chat';
import { formatSubmitErrors } from 'frontend/utils';
import { formatLinks } from 'frontend/utils/formatLinks';

import useCustomStyle from './useCustomStyle';
import useUploadAttachment from './useUploadAttachment';
import { AGENT_STARTED_TYPING, AGENT_STOPPED_TYPING, SEND_HANDOVER_MESSAGE } from '../mutations';

const trimHtmlString = (string) =>
  string
    .replace(/\n|\r/g, '') // Remove new lines and carriage returns in order to render a single bubble in kindly-chat
    .replace(/<p>(&nbsp;)+<\/p>/g, '') // Remove blocks containing only spaces
    .replace(/(<p><br><\/p>)+\1/g, '$1') // Remove consecutive empty blocks (keep max one)
    .replace(/^<p><br><\/p>|<p><br><\/p>$/g, '') // Remove empty line from start and beggining
    .replace(/<code [^>]*>|<\/code>/g, ''); // Remove code blocks with duplicate styles

export default ({ chat, messages, botId }) => {
  const toast = useToast();
  const [handoverType, setHandoverType] = useState<HandoverType>('LIVE_CHAT');
  const [indicatedTyping, setIndicatedTyping] = useState(false);
  const { chatId = '' } = useParams();

  const dispatch = useDispatch();

  // @ts-expect-error type Redux
  const { handoverMessages } = useSelector(({ inbox }) => inbox);

  // Use value from Redux store only after Editor has been initialized
  const editorState = handoverMessages?.[chatId]?.getCurrentContent
    ? handoverMessages[chatId]
    : EditorState.createEmpty();

  const textLength = editorState.getCurrentContent().getPlainText().trim().length;
  const hasInputText = textLength > 0;
  // If user toggles code block type before entering any text we hide the placeholder.
  const placeholderHidden = editorState.getCurrentContent().getBlockMap().first().getType() === 'code-block';
  const [startTyping] = useMutation(AGENT_STARTED_TYPING, { variables: { botId, chatId } });
  const [stopTyping] = useMutation(AGENT_STOPPED_TYPING, { variables: { botId, chatId } });
  const [send] = useMutation(SEND_HANDOVER_MESSAGE, { variables: { botId, chatId } });

  const { uploadAttachment, onUpload, removeFile, file } = useUploadAttachment({ botId, chatId, messages });
  const { options } = useCustomStyle(editorState);

  /** Get the editor current text, including spaces and new lines. */
  const getCurrentEditorText = (): string => {
    const editorContentState = editorState.getCurrentContent();
    const blocks = convertToRaw(editorContentState).blocks;
    return blocks.map((block) => (!block.text.trim() && '\n') || block.text).join('\n');
  };

  /** Replace the editor current text. */
  const replaceEditorState = (replacementText: string): void => {
    const newContentState = ContentState.createFromText(replacementText);
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
    dispatch(setHandoverMessages(chatId, newEditorState));
  };

  const onUploadError = ({ message }) => toast.error(message);

  const onSubmit = async (values?) => {
    try {
      await uploadAttachment();
    } catch (err) {
      toast.error(`Could not upload image. ${err ? err.toString() : ''}`);
      return undefined;
    }

    const message = formatLinks(trimHtmlString(stateToHTML(editorState.getCurrentContent(), options)));
    try {
      await send({
        variables: {
          botId,
          chatId,
          message,
          ...(handoverType === 'EMAIL' && values && { email: { cc: values.ccEmails } }),
        },
      });
    } catch (err) {
      return formatSubmitErrors(err);
    }

    dispatch(setHandoverMessages(chatId, null));
    setIndicatedTyping(false);
    return undefined;
  };

  // By default, pressing enter combined with any other key adds a new line.
  // Let's add a custom key binding for command handler to submit the form when ctrl + enter is pressed.
  const onKeyCommand = (e) => {
    if ((hasInputText || file) && e.keyCode === 13 && handoverType !== 'EMAIL') {
      return e.shiftKey ? 'shift-enter' : 'enter';
    }
    return getDefaultKeyBinding(e);
  };

  const onKeySubmit = () => {
    if ((hasInputText || file) && handoverType !== 'EMAIL') onSubmit();
  };

  const onChange = (newState) => {
    // There is a draftjs bug that causes the cursor to move backwards when you type one char in a new EditorState
    if (textLength === 1) {
      newState = EditorState.moveFocusToEnd(newState);
    }

    dispatch(setHandoverMessages(chatId, newState));

    if (!hasInputText) {
      setIndicatedTyping(false);
      stopTyping();
    } else if (!indicatedTyping) {
      setIndicatedTyping(true);
      startTyping();
    }
  };

  const handleKeyCommand = (command, state) => {
    // Handles custom key binding from the onKeyCommand callback
    if (command === 'enter') {
      onSubmit();
      return 'handled';
    }

    let newState;

    if (command === 'shift-enter') {
      // Insert new line
      const newContent = Modifier.splitBlock(state.getCurrentContent(), state.getSelection());
      const tempState = EditorState.push(state, newContent, 'split-block');
      // Move cursor right after insertion
      newState = EditorState.forceSelection(tempState, newContent.getSelectionAfter());
    } else {
      // Allow for default key bindings to toggle the style
      newState = RichUtils.handleKeyCommand(state, command);
    }

    if (newState) {
      dispatch(setHandoverMessages(chatId, newState));
      return 'handled';
    }
    return 'not-handled';
  };

  const onToggleBlockStyle = (blockStyle) =>
    dispatch(setHandoverMessages(chatId, RichUtils.toggleBlockType(editorState, blockStyle)));

  const onToggleInlineStyle = (inlineStyle) =>
    dispatch(setHandoverMessages(chatId, RichUtils.toggleInlineStyle(editorState, inlineStyle)));

  const onEmojiSelect = (emoji) => {
    // If there is text selected, replace it with emoji
    const newContent = Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), emoji);
    const tempState = EditorState.push(editorState, newContent, 'insert-characters');
    // Move cursor right after inserted emoji
    const newState = EditorState.forceSelection(tempState, newContent.getSelectionAfter());

    dispatch(setHandoverMessages(chatId, newState));
  };

  useEffect(() => {
    const messageQuantity = messages?.chatMessages?.messages?.length;
    if (messageQuantity) {
      const lastMessage = messages.chatMessages.messages[messageQuantity - 1];
      if (lastMessage.chatSource === CHAT_SOURCE.EMAIL) {
        setHandoverType('EMAIL');
      } else {
        setHandoverType('LIVE_CHAT');
      }
    }
  }, [messages]);

  const initialValues = {
    ccEmails: [...(chat?.emailCc || [])],
  };

  return {
    file,
    editorState,
    onSubmit,
    onChange,
    onUpload,
    onEmojiSelect,
    onUploadError,
    removeFile,
    hasInputText,
    placeholderHidden,
    onKeyCommand,
    onKeySubmit,
    handleKeyCommand,
    onToggleBlockStyle,
    onToggleInlineStyle,
    handoverType,
    setHandoverType,
    initialValues,
    getCurrentEditorText,
    replaceEditorState,
  };
};
