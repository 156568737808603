import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';

import { useKeyDown } from 'frontend/hooks';

import styles from './DropdownContainer.scss';
import { useComposerState } from '../../hooks';
import { insertCustomTag } from '../../stateHandlers';

const INITIAL = 'DROPDOWN_INITIAL';
const OPEN = 'DROPDOWN_OPEN';
const CLOSED = 'DROPDOWN_CLOSED';

const DropdownContainer = ({
  dropdownComponent: DropdownComponent,
  extra,
  close,
  name,
  selectedText,
  fromHashtag,
  constructTagItem,
  onInsertTag,
}) => {
  const { state, setState } = useComposerState(name);
  const ref = useRef();
  const [dropdownState, setDropdownState] = useState(INITIAL);

  const ready = useCallback(() => {
    setTimeout(() =>
      setDropdownState((currentDropdownState) => {
        if (currentDropdownState === INITIAL) return OPEN;
        return currentDropdownState;
      }),
    );
  }, []);

  const handleClose = useCallback(
    (updatedState) => {
      setDropdownState(CLOSED);
      setTimeout(() => setDropdownState(INITIAL), 150);
      setTimeout(() => close(updatedState), 150);
    },
    [close],
  );

  const onKeyDown = useKeyDown({ action: handleClose, key: 'Escape' });

  const addTag = useCallback(
    (item) => {
      const tagItem = constructTagItem(item);
      const stateWithTag = insertCustomTag(state, { item: tagItem, setCursorAtEnd: true });
      setState(stateWithTag);
      onInsertTag?.(tagItem);
      handleClose(stateWithTag);
    },
    [constructTagItem, handleClose, onInsertTag, setState, state],
  );

  const className = cx(styles.dropdownContainer, { [styles.dropdownContainerOpen]: dropdownState === OPEN });

  return (
    <div className={className} ref={ref} onKeyDown={onKeyDown} role="button" tabIndex={0}>
      <DropdownComponent
        close={handleClose}
        ready={ready}
        addTag={addTag}
        dropdownRef={ref}
        selectedText={selectedText}
        fromHashtag={fromHashtag}
        extra={extra}
      />
    </div>
  );
};

DropdownContainer.propTypes = {
  dropdownComponent: PropTypes.elementType.isRequired,
  extra: PropTypes.shape({}),
  close: PropTypes.func.isRequired,
  constructTagItem: PropTypes.func.isRequired,
  onInsertTag: PropTypes.func,
  name: PropTypes.string.isRequired,
  selectedText: PropTypes.string.isRequired,
  fromHashtag: PropTypes.bool,
};

export default DropdownContainer;
