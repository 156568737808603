import EntityTag from 'frontend/features/Entities/components/EntityTag';
import SlotTag from 'frontend/features/Slots/components/SlotTag';

import TAG_TYPES from './TAG_TYPES';

export default Object.freeze({
  [TAG_TYPES.ENTITY]: EntityTag,
  [TAG_TYPES.KINDLY_ENTITY]: EntityTag,
  [TAG_TYPES.SLOT]: SlotTag,
});
