import { Pencil, Trash } from 'frontend/assets/icons';
import { Button, Table } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { RemoveOrganizationSubscription, UpdateSubscription } from 'frontend/features/Organization/modals';

import styles from './SubscriptionList.scss';

const Actions = ({ data: { subscription, organization } }) => {
  const [showUpdateSubscriptionModal] = useModal(UpdateSubscription);
  const [showRemoveSubscriptionModal] = useModal(RemoveOrganizationSubscription);
  return (
    <>
      <Button
        className={`m-l-n ${styles.button}`}
        flat
        onClick={() => showUpdateSubscriptionModal({ subscription })}
        icon={Pencil}
        text="Update"
      />
      <Button
        flat
        onClick={() => showRemoveSubscriptionModal({ subscription, organization })}
        icon={Trash}
        text="Remove"
        className={styles.button}
      />
    </>
  );
};

const columns = [
  { title: 'Plan', key: 'planName' },
  { title: 'Started', key: 'startedAt' },
  { title: 'Ends', key: 'endedAt', render: ({ data: endedAt }) => endedAt || 'No expiry' },
  {
    title: 'Status',
    key: 'isActive',
    render: (isActive) => (isActive ? 'Active' : 'Inactive'),
  },
  {
    key: 'actions',
    title: 'Actions',
    render: Actions,
  },
];

const SubscriptionList = ({ organization }) => {
  const tableData = (organization?.subscriptions ?? []).map((subscription) => ({
    ...subscription,
    actions: { subscription, organization },
  }));

  return <Table data={tableData} columns={columns} />;
};

export default SubscriptionList;
