import { Modal } from 'frontend/features/Modals';

function NoEligibleUsers({ hide }: { hide: () => void }) {
  return (
    <Modal title="No eligible users" hide={hide} onOk={hide} hideCancel>
      <Modal.Content>There are no eligible users. Invite them to the organization first!</Modal.Content>
    </Modal>
  );
}

export default NoEligibleUsers;
