import { get } from 'lodash';

import { stripScheme } from 'frontend/components/UrlInput';
import { REQUIRED_ALTERNATIVES } from 'frontend/constants';
import { email, url } from 'frontend/form/validators';

import { buttonTypeUsesValue } from './utils';
import { BUTTON_TYPES } from '../../constants';

const { EMAIL, EXTERNAL_LINK, PHONE } = BUTTON_TYPES;

const getTrimmedHit = (regex, value) => regex.exec((value || '').replace(' ', ''));

const phoneValidator = (value) => {
  const INTEGER_REGEX = /\d+/g;
  const PHONE_NUMBER_REGEX = /^\+?[0-9()\- ]+$/;

  let integerHit = getTrimmedHit(INTEGER_REGEX, value);
  const integerHits = [];

  while (integerHit) {
    integerHits.push(integerHit);
    integerHit = getTrimmedHit(INTEGER_REGEX, value);
  }
  const longestInteger = Math.max(...integerHits.map((hit) => get(hit, '[0].length', 0)));

  return longestInteger > 2 && value.match(PHONE_NUMBER_REGEX);
};

export const getValueValidator = (buttonType, isActive) => (value) => {
  const CONTEXT_BRACKET_REGEX = /\{[\w\d\\. ]+?\}/i;

  if (!isActive && !value) return undefined;
  if ((value || '').match(CONTEXT_BRACKET_REGEX)) return undefined;

  if (buttonType === EXTERNAL_LINK && !stripScheme(value)) return "This can't be blank";
  if (buttonTypeUsesValue[buttonType] === REQUIRED_ALTERNATIVES.REQUIRED && !value) return "This can't be blank";

  switch (buttonType) {
    case EXTERNAL_LINK:
      return url(value);
    case EMAIL:
      return email(value);
    case PHONE:
      return phoneValidator(value) ? undefined : 'Must be a valid phone number';
    default:
      return undefined;
  }
};

export const validateField =
  (path, ...validators) =>
  (_, button) => {
    if (validators && Array.isArray(validators) && Object.values(BUTTON_TYPES).includes(button?.buttonType)) {
      for (let i = 0; i <= validators.length; i += 1) {
        if (typeof validators[i] === 'function') {
          const value = get(button, path);
          const validate = validators[i](typeof value === 'string' ? value?.trim() : value, button);

          if (typeof validate !== 'undefined') return validate;
        }
      }
    }
    return undefined;
  };
