import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { Login, ResetPassword, ResetPasswordConfirm, TermsOfUse } from 'frontend/features';
import Invitation, { InvalidToken, InvitationCompleted } from 'frontend/features/Invitation';
import { LOGIN, logout, selectAuth } from 'frontend/state/dux/auth';

import AuthenticatedRoutes from './AuthenticatedRoutes';
import PolicyRedirect from './PolicyRedirect';

const RootRoutes = () => {
  const auth = useSelector(selectAuth);
  const client = useApolloClient();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth?.error && auth?.error.type !== LOGIN.ERROR) {
      dispatch(logout(client, true));
    }
  }, [auth, client, dispatch]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/sso-login" element={<Login />} />
      <Route path="/reset_password/confirm/:uidb64/:token" element={<ResetPasswordConfirm />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/invitation/invalid" element={<InvalidToken />} />
      <Route path="/invitation/complete" element={<InvitationCompleted />} />
      <Route path="/invitation" element={<Invitation />} />
      <Route path="/policy" element={<PolicyRedirect />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/*" element={<AuthenticatedRoutes />} />
    </Routes>
  );
};
export default RootRoutes;
