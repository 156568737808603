import { isNil } from 'lodash';

import type { BuildFormType } from 'frontend/api/generated';
import randomUUID from 'frontend/utils/randomUUID';

import inputTypes, { fileUploadPlaceholderText, formTexts } from './constants';

export const findFormByLanguageAndRule = (formList: BuildFormType[], language: string, ruleId?: string) =>
  formList.find((form) => form.languageCode === language && form.rule?.id === ruleId);

export const findFieldValidator = (
  array: Record<string, unknown>[] | undefined,
  validate: string,
): undefined | Record<string, unknown> => {
  if (!Array.isArray(array)) return undefined;
  return array.find((validator) => Boolean(validator?.[validate]));
};

export const getFormDefaultText = (language = 'en') => ({
  id: `temporary-uuid-${randomUUID()}`,
  title: '',
  ...(formTexts[language] || formTexts.en),
});

export const getFormFieldDefaultText = (type, currentLanguage) => ({
  id: `temporary-uuid-${randomUUID()}`,
  helpText: '',
  label: '',
  placeholderText: type === inputTypes.FILE ? fileUploadPlaceholderText[currentLanguage] : '',
  requiredText: '',
  affixValue: '',
});

export const handleFloatInputs = (value) => {
  if (!isNil(value) && value) {
    const valString = value.toString();
    if (valString.endsWith('.')) {
      return valString.trim();
    }
    const val = parseFloat(value.toString());
    if (!Number.isNaN(val)) {
      return val;
    }
    return undefined;
  }
  return value;
};
