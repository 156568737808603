import cx from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { ChildrenType } from 'frontend/propTypes';

import styles from './Attachment.scss';

const Loading = ({ children, loading = false, fullWidth = false }) => {
  const [hide, setHide] = useState(!loading);

  useEffect(() => {
    if (!hide && !loading) setTimeout(() => setHide(true), 1300);
  }, [hide, loading]);

  const className = cx(styles.loading, { [styles.loadingFinished]: !loading, [styles.loadingFullWidth]: fullWidth });

  if (hide) return null;

  return (
    <div className={className}>
      <div />
      <div />
      <div>{children}</div>
    </div>
  );
};

Loading.propTypes = {
  children: ChildrenType,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Loading;
