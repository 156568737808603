import { gql } from '@apollo/client';

export const CHANGELOG_ENTRY_FRAGMENT = gql`
  fragment ChangelogEntry on ChangelogEntryType {
    id
    created
    labels
    markdownDetails
    publishedAt
    status
    title
    types
    featured
  }
`;
