import { useMutation } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { useToast } from 'frontend/hooks';

import { CREATE_CONTEXT_FIELD, UPDATE_CONTEXT_FIELD } from '../mutations';
import { CONTEXT_FIELDS } from '../queries';

export default () => {
  const toast = useToast();
  const { botId } = useParams();
  const [activeField, setActiveField] = useState(null);

  const updateAddContextField = useCallback(
    (cache, { data }) => {
      toast.success('Context field was created');
      appendArrayInCache({
        variables: { botId },
        pathToItemInMutationData: 'createContextField',
        pathToArrayInCache: 'allContextFields',
        query: CONTEXT_FIELDS,
      })(cache, { data });
    },
    [botId, toast],
  );

  const [createContextField] = useMutation(CREATE_CONTEXT_FIELD, { update: updateAddContextField });

  const [updateContextField] = useMutation(UPDATE_CONTEXT_FIELD, {
    update: () => {
      toast.success('Context field was updated');
      setActiveField(null);
    },
  });

  const onFieldCreate = useCallback(
    async (values) => {
      const variables = {
        botId,
        ...values,
      };

      await createContextField({ variables });
    },
    [botId, createContextField],
  );

  const onFieldUpdate = useCallback(
    async (values) => {
      const variables = {
        botId,
        ...omit(values, ['id', '__typename', 'type']),
        contextFieldId: values.id,
      };

      await updateContextField({ variables });
    },
    [botId, updateContextField],
  );

  return { botId, onFieldCreate, onFieldUpdate, activeField, setActiveField };
};
