import { Exclamation } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './Panel.scss';

interface ErrorStateProps {
  children: React.ReactNode;
}
const ErrorState = ({ children = 'An error occurred' }: ErrorStateProps) => (
  <div className={styles.errorState}>
    <Icon component={Exclamation} className={styles.icon} />
    <p>{children}</p>
  </div>
);

export default ErrorState;
