import cx from 'classnames';
import { get } from 'lodash';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import type { ReplyCandidateType } from 'frontend/api/generated';
import { useModal } from 'frontend/features/Modals';

import styles from './ReplyCandidate.scss';
import ConfidenceScoreModal from '../ConfidenceScoreModal/ConfidenceScoreModal';
import MessageTag from '../MessageTag/MessageTag';
import Score from '../Score/Score';

const scoreValue = (value) => {
  const score = value * 100;
  if (score < 10) return score.toFixed(1);
  return Math.round(score);
};

interface ReplyCandidateProps {
  replyCandidate: ReplyCandidateType;
  botId: string;
}

const ReplyCandidate = ({ replyCandidate, botId }: ReplyCandidateProps) => {
  const score = scoreValue(replyCandidate.score);
  const replies = replyCandidate.replies;
  const [showConfidenceScore] = useModal(ConfidenceScoreModal);
  const onClick = useCallback(() => showConfidenceScore({ botId }), [botId, showConfidenceScore]);

  const firstReply = get(replies, '0.text', '').replace(/(<([^>]+)>)/gi, ''); // Strip HTML tags

  return (
    <div
      className={cx(styles.reply, {
        [styles.replyDisabled]: !replies,
      })}
    >
      <Score className={styles.replyScore} title="Confidence score" onClick={onClick}>{`${score}%`}</Score>
      {replies ? (
        <Link
          to={`/workspace/${botId}/build/dialogue/${replyCandidate.dialogueId}`}
          target="_blank"
          className={styles.text}
        >
          {firstReply || <MessageTag>WEBHOOK ONLY</MessageTag>}
        </Link>
      ) : (
        <div className={styles.text}>This dialogue is deleted.</div>
      )}
    </div>
  );
};

export default ReplyCandidate;
