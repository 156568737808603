import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { ConnectorType, PluginType } from 'frontend/api/generated';
import { Button, Panel } from 'frontend/components';
import Table, { type Column } from 'frontend/components/Table/Table';

import styles from './ConnectList.scss';
import type { KindlyGptSourcesType } from '../../KindlyGPTSources/config/kindlyGptSourcesConfig';

type CellType = {
  rowData: PluginType;
};

const ActiveCell = ({ data: isActive }) => (isActive ? 'Active' : 'Inactive');
const Logo = ({ rowData }: CellType) => (
  <img className={styles.pluginLogo} src={rowData.logoUrl as string} alt={`${rowData.name} logo`} />
);
const AdminActions = ({ rowData }: CellType) => <Button size="small" text="Edit" to={rowData.id} />; // admin/plugins/<pluginId>
const Actions = ({ rowData }: CellType) => <Button size="small" text="Read more" to={rowData.id} />; // workspace/<botId>/connect/plugins/<pluginId>

const Description = ({ rowData }: CellType) => (
  <div>
    <Link className={styles.pluginTitle} to={rowData.id}>
      {rowData.name}
    </Link>
    <div className={styles.pluginDescription}>{rowData.description}</div>
  </div>
);

const columns = [
  {
    key: 'logo',
    render: Logo,
  },
  {
    key: 'description',
    render: Description,
  },
  {
    key: 'isActive',
    title: 'Status',
    component: ActiveCell,
  },
  {
    key: 'actions',
    render: Actions,
  },
];

const getGridTemplateColumns = (cols: Column[]) => {
  let columnStr = '';
  for (let i = 0; i < cols.length; i += 1) {
    if (i === 0 || i === cols.length - 1) {
      columnStr += 'auto ';
    } else {
      columnStr += '1fr ';
    }
  }
  return columnStr;
};

interface PluginsListProps {
  data: PluginType[] | ConnectorType[] | KindlyGptSourcesType;
  isAdmin?: boolean;
  headerComponent?: React.ReactElement;
}

const ConnectList = ({ data, isAdmin = false, headerComponent }: PluginsListProps) => {
  const columnsUpdated = useMemo(
    () =>
      columns
        .filter((col) => {
          if (!isAdmin) {
            return col.key !== 'isActive';
          }
          return true;
        })
        .map((col) => {
          if (col.key === 'actions' && isAdmin) {
            return {
              ...col,
              render: AdminActions,
            };
          }
          if (col.key === 'description') {
            return {
              ...col,
              render: (args) => <Description {...args} isAdmin={isAdmin} />,
            };
          }
          return col;
        }),
    [isAdmin],
  ) as unknown as Column[];

  return (
    <Panel className={styles.connectListPanel}>
      {headerComponent && <div className={styles.headerComponentWrapper}>{headerComponent}</div>}
      <Table
        getGridTemplateColumns={getGridTemplateColumns}
        className={styles.connectTable}
        showTableHeader={isAdmin}
        data={data}
        columns={columnsUpdated}
      />
    </Panel>
  );
};

export default ConnectList;
