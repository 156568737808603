import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import useAttachmentError from './useAttachmentError';
import useCreateAttachment from './useCreateAttachment';
import { UPLOAD_ATTACHMENTS_COMPLETE } from '../mutations';

const getFileStream = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onabort = () => reject(new Error(reader.error));
  });

export default ({ botId, chatId, messages }) => {
  const [file, setFile] = useState();
  const [uploadComplete] = useMutation(UPLOAD_ATTACHMENTS_COMPLETE);
  const { cache } = useApolloClient();
  const onError = useAttachmentError(cache);

  const createAttachment = useCreateAttachment({ botId, chatId, messages, cache });

  const uploadAttachment = useCallback(async () => {
    if (!file) return;

    const { attachment, temporaryChatMessageId } = await createAttachment(file);
    const uploadData = await getFileStream(file.fileData);
    const headers = {
      'Content-Type': file.type,
      'x-goog-upload-bot-id': botId,
      'x-goog-upload-chat-id': chatId,
    };

    try {
      const uploadResponse = await fetch(attachment.uploadUrl, { method: 'put', body: uploadData, headers });

      if (uploadResponse.status !== 200) onError({ attachments: [attachment], error: uploadResponse.statusText });

      const variables = { botId, chatId, attachmentIds: [attachment.id], temporaryChatMessageId };
      await uploadComplete({ variables });
    } catch (error) {
      onError({ attachments: [attachment], error });
    }

    setFile();
  }, [botId, chatId, createAttachment, file, onError, uploadComplete]);

  const onUpload = useCallback(
    ([{ source, sizeKb, file: fileData }]) =>
      setFile({ source, name: fileData.name, type: fileData.type, fileData, sizeKb }),
    [],
  );

  const removeFile = useCallback(() => setFile(), []);

  return { uploadAttachment, onUpload, removeFile, file };
};
