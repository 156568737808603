import { useMutation } from '@apollo/client';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';

import { getOptimisticResponse } from 'frontend/api/cacheHelpers';
import { type TopicType, UpdateTopicDocument } from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import TopicForm from '../TopicForm';

const UpdateTopic = ({
  hide,
  args: { topic, buildIdObject },
}: {
  hide: () => void;
  args: {
    topic: TopicType;
    buildIdObject: BuildIdObject;
  };
}) => {
  const [update] = useMutation(UpdateTopicDocument);
  const toast = useToast();
  const initialValues = useMemo(() => pick(topic, ['name', 'isActive']), [topic]);

  const onSubmit = useCallback(
    async (topicInput) => {
      const mutation = 'updateTopic';
      const response = { ...buildIdObject, ...topic, ...topicInput };
      const optimisticResponse = getOptimisticResponse({ response, mutation, typeName: 'TopicType' });

      const result = await update({
        variables: { ...buildIdObject, topicId: topic.id, topicInput },
        ...optimisticResponse,
      });
      const topicResultName = result.data!.updateTopic!.name;

      toast.success(`Folder '${topicResultName}' was updated!'`);
    },
    [buildIdObject, toast, topic, update],
  );

  return <TopicForm onSubmit={onSubmit} initialValues={initialValues} actionName="Edit" hide={hide} />;
};

export default UpdateTopic;
