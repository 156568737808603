import cx from 'classnames';

import { Drag, Edit, Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import type { ButtonWithDialogueLink } from 'frontend/features/Build/components/Buttons/types';
import { useSortableItem } from 'frontend/features/Dnd/hooks';

import styles from './EditableButton.scss';
import Button from '../Button';

interface Props {
  button: ButtonWithDialogueLink;
  setEditingButton: (button: ButtonWithDialogueLink) => void;
  removeButtonById: (id: string) => void;
  isEditing?: boolean;
  isSorting?: boolean;
  disabled?: boolean;
  strikeThrough?: boolean;
}

const EditableButton = ({
  button,
  setEditingButton,
  removeButtonById,
  isEditing,
  isSorting,
  disabled,
  strikeThrough,
}: Props) => {
  const { style, dragAttributes, onPointerDown, setNodeRef } = useSortableItem(button, disabled);

  const removeButton = (event) => {
    if (disabled) {
      return;
    }
    removeButtonById(button.id);
    event.stopPropagation();
  };

  const editButton = () => (disabled ? null : setEditingButton(button));

  return (
    <Button
      // @ts-expect-error: TODO: typify Button
      item={button}
      isEditing={isEditing}
      setNodeRef={setNodeRef}
      style={style}
      className={cx(
        styles.button,
        { [styles.buttonDragging]: isSorting },
        { [styles.buttonStrikeThrough]: strikeThrough },
      )}
      dragAttributes={dragAttributes}
    >
      {!isEditing && (
        <span className={styles.options}>
          <span className={styles.optionsButtons}>
            <Icon className={styles.optionsButtonsItem} component={Edit} color="black" onClick={editButton} />
            <Icon
              className={`${styles.optionsButtonsItem} ${styles.dragHandle}`}
              component={Drag}
              color="black"
              onPointerDown={onPointerDown}
            />
            <Icon className={styles.optionsButtonsItem} component={Trash} color="warning" onClick={removeButton} />
          </span>
        </span>
      )}
    </Button>
  );
};

export default EditableButton;
