import Editor from '@draft-js-plugins/editor';
import cx from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { ChevronDown, ChevronUp } from 'frontend/assets/icons';
import { Badge, Button, Icon } from 'frontend/components';
import { HANDOVER_TYPES } from 'frontend/features/Inbox/constants';
import sharedStyles from 'frontend/features/Inbox/inbox-styles.scss';
import { chain, emailArray, requiredArray } from 'frontend/form/validators';
import { useKeyDown } from 'frontend/hooks';
import type { CHAT_SOURCE } from 'frontend/types/chat';

import Attachment from './Attachment';
import EmailTagField from './EmailTagField';
import styles from './HandoverForm.scss';
import Toolbar from './Toolbar';
import { useHandoverActions } from '../../hooks';
import type { ChatMessageShape, MessagesDataShape } from '../../propTypes';

interface HandoverFormProps {
  botId: string;
  chat: ChatMessageShape;
  messages: MessagesDataShape;
  source: CHAT_SOURCE;
}

export const ExtraEmailRecipients = ({ cc }: { cc: string[] }) => {
  if (!cc?.length) return null;

  return <Badge title={`+${cc.length}`} tooltip={`Cc: ${cc.join(', ')}`} tooltipMaxWidth="100px" />;
};

const HandoverForm = ({ chat, messages, source, botId }: HandoverFormProps) => {
  const {
    editorState,
    file,
    handleKeyCommand,
    handoverType,
    hasInputText,
    initialValues,
    onChange,
    onKeyCommand,
    onKeySubmit,
    onSubmit,
    onUpload,
    onUploadError,
    placeholderHidden,
    removeFile,
  } = useHandoverActions({
    chat,
    messages,
    botId,
  });

  const [isCcOpen, setIsCcOpen] = useState(false);
  const editorRef = useRef<HTMLElement>();
  const focus = useCallback(() => editorRef?.current?.focus?.(), [editorRef]);
  const onKeyDown = useKeyDown({ action: onKeySubmit, stopEvent: true });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => (
        <form
          onSubmit={(e) =>
            handleSubmit(e)?.then(() => {
              form.reset();
            })
          }
          autoComplete="off"
          className={sharedStyles.formWrapper}
        >
          {handoverType === HANDOVER_TYPES.EMAIL && (
            <div className={cx(sharedStyles.textWrapper, styles.input, styles.inputRecipients)}>
              <Button
                className={cx(styles.icon, styles.inputDropdown, {
                  [styles.iconActive]: isCcOpen,
                })}
                onClick={() => setIsCcOpen(!isCcOpen)}
                onMouseDown={(e) => e.preventDefault()}
                flat
              >
                <Icon
                  component={isCcOpen ? ChevronUp : ChevronDown}
                  color="text"
                  title={`${isCcOpen ? 'Collapse' : 'Expand'} Cc`}
                />
              </Button>
              <div className={styles.inputEmailsWrapper}>
                <div className={styles.toWrap}>
                  <span className={styles.recipientLabel}>To</span>
                  <span className={styles.to}>
                    {`${chat.fullName} <${chat.email}>`} {!isCcOpen && <ExtraEmailRecipients cc={chat.emailCc} />}
                  </span>
                </div>
                {isCcOpen && (
                  <div className={styles.ccWrap}>
                    <label htmlFor="cc-1680251845064" className={styles.recipientLabel}>
                      Cc
                    </label>
                    <Field
                      id="cc-1680251845064"
                      // @ts-expect-error: Not sure what's the matter with React-Final-Form
                      component={EmailTagField}
                      name="ccEmails"
                      validate={chain([requiredArray, emailArray])}
                      onlyUnique
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className={cx(sharedStyles.sendForm, sharedStyles.textWrapper, styles.editor, {
              [styles.editorPlaceholderHidden]: placeholderHidden,
              [styles.editorExpanded]: handoverType === HANDOVER_TYPES.EMAIL,
            })}
          >
            <Editor
              // @ts-expect-error: Type DraftJS?
              ref={editorRef}
              editorState={editorState}
              onChange={onChange}
              handleKeyCommand={handleKeyCommand}
              placeholder="Type message..."
              keyBindingFn={onKeyCommand}
              customStyleMap={{ BOLD: { fontWeight: '500' } }}
              spellCheck
            />
            {file && (
              <div className={styles.attachments}>
                <Attachment file={file} removeFile={removeFile} />
              </div>
            )}
          </div>
          <Toolbar
            botId={botId}
            editorState={editorState}
            email={chat.email}
            focus={focus}
            handoverType={handoverType}
            messages={messages}
            onKeyDown={onKeyDown}
            // @ts-expect-error TS Inbox/views/Conversation/hooks/useUploadAttachment.js
            onUpload={onUpload}
            onUploadError={onUploadError}
            source={source}
            submitDisabled={!file && !hasInputText}
          />
        </form>
      )}
    />
  );
};

export default HandoverForm;
