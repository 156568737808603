import { Link } from 'react-router-dom';

import { timeAgo } from 'frontend/utils';

import styles from './ActivityItem.scss';
import { ActivityType } from '../../propTypes';

const ActivityItem = ({ activity }) => {
  const { createdAt, targetDialogue, message } = activity;

  return (
    <div className={styles.itemContainer}>
      {targetDialogue ? <Link to={targetDialogue.url}>{message}</Link> : <span>{message}</span>}
      <div>{timeAgo(createdAt)}</div>
    </div>
  );
};

ActivityItem.propTypes = {
  activity: ActivityType.isRequired,
};

export default ActivityItem;
