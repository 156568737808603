import cx from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useTabTitle } from 'frontend/hooks';
import { ChildrenType } from 'frontend/propTypes';
import { selectNavbarHeight } from 'frontend/state/dux/navbar';

import styles from './InboxLayout.scss';

const InboxLayout = ({ children }) => {
  const { search } = useLocation();
  const isMetaScreen = new URLSearchParams(search).get('screen') === 'meta';
  const isChatlistScreen = new URLSearchParams(search).get('screen') === 'chat-list';
  const isViewsScreen = new URLSearchParams(search).get('screen') === 'views';
  const isConversationScreen = !isChatlistScreen && !isMetaScreen && !isViewsScreen;
  const height = useSelector(selectNavbarHeight);
  const style = useMemo(() => ({ top: `${height}px` }), [height]);

  useTabTitle('Inbox');

  return (
    <div
      className={cx(styles.grid, {
        [styles.mobileScreenViews]: isViewsScreen,
        [styles.mobileScreenList]: isChatlistScreen,
        [styles.mobileScreenConversation]: isConversationScreen,
        [styles.mobileScreenMeta]: isMetaScreen,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

InboxLayout.propTypes = {
  children: ChildrenType.isRequired,
};

export default InboxLayout;
