/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames';
import React, {
  type ReactElement,
  cloneElement,
  isValidElement,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useEventListener } from 'frontend/hooks';

import styles from './DropdownOverlay.scss';
import type { DropdownPosition } from '../../Dropdown';

interface OverlayProps {
  close: () => void;
}

// Arbitrary number
// If the distance between the dropdown top to the bottom of the viewport is less than this number
// Then shift to top
const BOTTOM_INTERSECTION_OFFSET = 224;

const getScrollParent = (node: HTMLElement | ParentNode | null) => {
  if (node === null || !(node instanceof HTMLElement)) {
    return null;
  }

  const computedStyle = window.getComputedStyle(node);

  // In case you are in a element that is taken out of the layout
  // We don't want to check further for scrollable content
  if (computedStyle.getPropertyValue('position') === 'fixed') {
    return null;
  }

  if (node.scrollHeight - 10 > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode);
};

interface DropdownOverlayProps {
  overlay: ((props: OverlayProps) => React.ReactElement<OverlayProps>) | ReactElement<OverlayProps>;
  arrow?: boolean;
  isHoverMode?: boolean;
  overlayClassName?: string;
  overlayLeft?: string;
  overlayTop?: string;
  position: DropdownPosition;
  style?: React.CSSProperties;
  overlayMaxWidth?: 'parent' | number;
  closeDropdown: () => void;
  handlePointerEnter: () => void;
}

const DropdownOverlay = ({
  arrow,
  overlayClassName,
  closeDropdown,
  isHoverMode,
  style,
  overlay,
  overlayMaxWidth,
  position,
  handlePointerEnter,
  overlayLeft,
  overlayTop,
}: DropdownOverlayProps) => {
  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition | null>();
  const [hasSetPosition, setHasSetPosition] = useState(false);
  const [maxHeight, setMaxHeight] = useState<null | number>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const scrollableParent = useRef<HTMLElement | null>(null);
  const intersectionObserver = useRef<IntersectionObserver | null>(null);

  // Handle position of the dropdown in case it goes out of boundaries of the viewport
  const handleDropdownPosition = useCallback(() => {
    if (overlayRef.current?.parentElement && !dropdownPosition) {
      const { left, right, width, top, height } = overlayRef.current.getBoundingClientRect();

      // In case the dropdown is about to exit the bounds of the viewport
      const isLeftIntersecting = left - width + 20 < 0;
      const isRightIntersecting = right > window.innerWidth;
      const isTopIntersecting = top < 0;
      const isBottomIntersecting =
        top + height - window.document.documentElement.scrollTop > window.innerHeight &&
        window.innerHeight - top < BOTTOM_INTERSECTION_OFFSET;
      switch (position) {
        case 'top':
          setDropdownPosition(`${isTopIntersecting ? 'bottom' : 'top'}${isRightIntersecting ? '-right' : ''}`);
          break;
        case 'bottom':
          setDropdownPosition(`${isBottomIntersecting ? 'top' : 'bottom'}${isRightIntersecting ? '-right' : ''}`);
          break;
        case 'right':
          setDropdownPosition(isRightIntersecting ? 'left' : 'right');
          break;
        case 'left':
          setDropdownPosition(isLeftIntersecting ? 'right' : 'left');
          break;
        case 'top-left':
          setDropdownPosition(`${isTopIntersecting ? 'bottom' : 'top'}-${isRightIntersecting ? 'right' : 'left'}`);
          break;
        case 'top-right':
          setDropdownPosition(`${isTopIntersecting ? 'bottom' : 'top'}-${isLeftIntersecting ? 'left' : 'right'}`);
          break;
        case 'bottom-left':
          setDropdownPosition(`${isBottomIntersecting ? 'top' : 'bottom'}-${isRightIntersecting ? 'right' : 'left'}`);
          break;
        case 'bottom-right':
          setDropdownPosition(`${isBottomIntersecting ? 'top' : 'bottom'}-${isLeftIntersecting ? 'left' : 'right'}`);
          break;
        default:
          setDropdownPosition(position);
      }

      if (position === 'top' || (position === 'bottom' && isBottomIntersecting)) {
        setMaxHeight(null);
      } else {
        setMaxHeight(window.innerHeight - top - 20);
      }

      setHasSetPosition(true);
    }
  }, [position, dropdownPosition]);

  const handleMaxWidth = useCallback(() => {
    if (overlayRef.current) {
      const dropdownParent = overlayRef.current.parentElement;
      if (overlayMaxWidth === 'parent') {
        overlayRef.current.style.maxWidth = `${dropdownParent?.clientWidth || 0}px`;
      } else if (typeof overlayMaxWidth === 'number') {
        overlayRef.current.style.maxWidth = `${overlayMaxWidth}px`;
      }
      overlayRef.current.style.width = '100%';
    }
  }, [overlayMaxWidth]);

  // Handle position in pixels depending on the latest dropdownPosition
  const handlePosition = useCallback(() => {
    if (overlayRef.current?.parentElement && dropdownPosition) {
      const { width, top, bottom, left, right } = overlayRef.current.parentElement.getBoundingClientRect();
      const { width: oLayWidth } = overlayRef.current.getBoundingClientRect();

      switch (dropdownPosition) {
        case 'top':
          overlayRef.current.style.left = `${left}px`;
          overlayRef.current.style.top = `${top - overlayRef.current.clientHeight - 8}px`;
          break;
        case 'right':
          overlayRef.current.style.left = `${right + 8}px`;
          overlayRef.current.style.top = `${top}px`;
          break;
        case 'left':
          overlayRef.current.style.left = `${left - oLayWidth - 8}px`;
          overlayRef.current.style.top = `${top}px`;
          break;
        case 'top-left':
          overlayRef.current.style.left = `${left}px`;
          overlayRef.current.style.top = `${top - overlayRef.current.clientHeight - 8}px`;
          break;
        case 'top-right':
          overlayRef.current.style.left = `${left - oLayWidth + width}px`;
          overlayRef.current.style.top = `${top - overlayRef.current.clientHeight - 8}px`;
          break;
        case 'bottom-left':
          overlayRef.current.style.left = `${left}px`;
          overlayRef.current.style.top = `${bottom + 8}px`;
          break;
        case 'bottom-right':
          overlayRef.current.style.left = `${left - oLayWidth + width}px`;
          overlayRef.current.style.top = `${bottom + 8}px`;
          break;
        case 'custom':
          overlayRef.current.style.left = `${left + (parseInt(overlayLeft || '0', 10) || 0) - 8}px`;
          overlayRef.current.style.top = `${bottom + (parseInt(overlayTop || '0', 10) || 0) + 8}px`;
          break;
        default:
          overlayRef.current.style.left = `${left}px`;
          overlayRef.current.style.top = `${bottom + 8}px`;
      }
    }
  }, [dropdownPosition, overlayLeft, overlayTop]);

  const handleScroll = useCallback(() => {
    if (overlayRef.current) {
      handlePosition();
    }
  }, [overlayRef, handlePosition]);

  const handleScrollableContent = useCallback(() => {
    if (overlayRef.current?.parentElement) {
      scrollableParent.current = getScrollParent(overlayRef.current.parentElement);
      if (scrollableParent.current) {
        if (scrollableParent.current.tagName?.toLowerCase() === 'html') {
          scrollableParent.current = window.document as unknown as HTMLElement;
        }
        scrollableParent.current.addEventListener('scroll', handleScroll);

        if (scrollableParent.current !== (window.document as unknown as HTMLElement)) {
          intersectionObserver.current = new IntersectionObserver(
            ([entry]) => {
              if (entry && overlayRef.current) {
                const { isIntersecting } = entry;
                if (isIntersecting) {
                  overlayRef.current.style.display = 'block';
                } else {
                  overlayRef.current.style.display = 'none';
                }
              }
            },
            {
              root: scrollableParent.current,
              rootMargin: '0px',
              threshold: 1,
            },
          );
          intersectionObserver.current.observe(overlayRef.current.parentElement);
        }
      }
    }
  }, [handleScroll]);

  useLayoutEffect(() => {
    const overlayReference = overlayRef.current;
    if (overlayReference) {
      if (overlayMaxWidth === 'parent' || typeof overlayMaxWidth === 'number') {
        handleMaxWidth();
      }
      handleDropdownPosition();
      handlePosition();
      handleScrollableContent();
    }

    return () => {
      intersectionObserver.current?.disconnect();
      intersectionObserver.current = null;
      scrollableParent.current?.removeEventListener('scroll', handleScroll);
      scrollableParent.current = null;
    };
  }, [handleDropdownPosition, overlayMaxWidth, handleScroll, handleScrollableContent, handlePosition, handleMaxWidth]);

  useEventListener('resize', handlePosition, window);

  const contentStyles = cx(overlayClassName, styles.dropdownContent, {
    [styles.arrow]: arrow,
    [styles.active]: hasSetPosition,
    [styles.topLeft]: dropdownPosition === 'top-left',
    [styles.topRight]: dropdownPosition === 'top-right',
    [styles.top]: dropdownPosition === 'top',
    [styles.bottom]: dropdownPosition === 'bottom',
    [styles.bottomLeft]: dropdownPosition === 'bottom-left',
    [styles.bottomRight]: dropdownPosition === 'bottom-right',
    [styles.right]: dropdownPosition === 'right',
    [styles.custom]: dropdownPosition === 'custom',
  });

  const customStyles: React.CSSProperties = {
    ...style,
    ...(maxHeight && { maxHeight }),
  };

  return (
    <div
      className={contentStyles}
      style={customStyles}
      ref={overlayRef}
      {...(isHoverMode && { onPointerEnter: handlePointerEnter })}
      {...(isHoverMode && { onPointerLeave: closeDropdown })}
      role="complementary"
    >
      {isValidElement(overlay) && cloneElement<OverlayProps>(overlay, { close: closeDropdown })}
      {!isValidElement(overlay) && overlay({ close: closeDropdown })}
    </div>
  );
};

export default DropdownOverlay;
