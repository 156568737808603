import cx from 'classnames';

import libraryStyles from '../Library.scss';

export default (open, { hide = false, folder = false }) =>
  cx(libraryStyles.icon, libraryStyles.arrow, {
    [libraryStyles.arrowFolder]: folder,
    [libraryStyles.arrowDown]: !hide && open,
    [libraryStyles.arrowRight]: !hide && !open,
  });
