import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { Table, ToggleSwitch } from 'frontend/components';
import { EVENT_KEYS } from 'frontend/constants';

import styles from './AddKindlyEntities.scss';
import Searching from './Searching';
import Waiting from './Waiting';
import EntityTag from '../../components/EntityTag';
import { KindlyEntitiesType } from '../../propTypes';

const Description = ({ data: { description } }) => <div className={styles.description}>{description}</div>;

Description.propTypes = {
  data: PropTypes.shape({ description: PropTypes.string.isRequired }).isRequired,
};

const Added = ({ data: { isAdded } }) => (
  <div className={styles.toggleContainer}>
    <ToggleSwitch.Switch input={{ value: isAdded }} />
  </div>
);

Added.propTypes = {
  data: PropTypes.shape({ isAdded: PropTypes.bool.isRequired }).isRequired,
};

const Tag = ({ data: { name, description } }) => <EntityTag name={name} description={description} kindly lessInfo />;

Tag.propTypes = {
  data: PropTypes.shape({ name: PropTypes.string.isRequired, description: PropTypes.string }),
};

const columns = [
  { key: 'name', component: Tag },
  { key: 'description', component: Description },
  { key: 'add', component: Added },
];

const AddKindlyEntitiesForm = ({ kindlyEntities, waitMinutes, isSearchingForEntities, hide }) => {
  const form = useForm();
  const { values } = useFormState();
  const data = useMemo(
    () =>
      orderBy(kindlyEntities, 'category').map((kindlyEntity) => {
        const toggle = () => form.change(kindlyEntity.id, !values[kindlyEntity.id]);

        return {
          name: kindlyEntity,
          description: kindlyEntity,
          add: { isAdded: values[kindlyEntity.id] },
          onClick: toggle,
          onKeyDown: ({ key }) => key === EVENT_KEYS.ENTER && toggle(),
        };
      }),
    [form, kindlyEntities, values],
  );

  const isWaiting = waitMinutes > 0;
  if (isWaiting) return <Waiting waitMinutes={waitMinutes} hide={hide} />;
  if (isSearchingForEntities && !isWaiting) return <Searching />;

  return (
    <>
      <div className={styles.addKindlyEntitiesWarning}>
        Once added, words and phrases in user messages matching the Kindly entity description will be converted to
        Kindly entities.
        <br />
        <br />
        Make sure you use the appropriate Kindly Entity in your samples as well. Read more here (TODO: link)
      </div>
      <br />
      <Table
        className={styles.addKindlyEntities}
        rowClassName={styles.addKindlyEntitiesRow}
        columns={columns}
        data={data}
        bordered
      />
    </>
  );
};

AddKindlyEntitiesForm.propTypes = {
  kindlyEntities: KindlyEntitiesType.isRequired,
  waitMinutes: PropTypes.number.isRequired,
  isSearchingForEntities: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default AddKindlyEntitiesForm;
