import { gql } from '@apollo/client';

import { CHANGELOG_ENTRY_FRAGMENT } from 'frontend/features/Admin/views/Changelog/graphql';

export const SET_FEATURED_CHANGELOG_ENTRY = gql`
  mutation SetFeaturedChangelogEntry($entryId: ID!) {
    setFeaturedChangelogEntry(entryId: $entryId) {
      ...ChangelogEntry
    }
  }
  ${CHANGELOG_ENTRY_FRAGMENT}
`;
