import { useMutation } from '@apollo/client';
import { format, subMonths } from 'date-fns';

import { CreateMonthlyReportDocument, MonthlyReportDocument, MonthlyReportFragmentDoc } from 'frontend/api/generated';
import { DEFAULT_TIMEZONE } from 'frontend/features/Analytics/constants';
import { DATE_FORMAT_YEAR_MONTH } from 'frontend/utils/date';

export function useCreateReport(organizationId: string, month: string, tz?: string) {
  const prevMonth = format(subMonths(new Date(month), 1), DATE_FORMAT_YEAR_MONTH);
  tz = tz ?? DEFAULT_TIMEZONE;

  const [createReport] = useMutation(CreateMonthlyReportDocument, {
    variables: { organizationId, month: month!, tz },
    update: (cache, { data: result }) => {
      cache.modify({
        fields: {
          allMonthlyReports(existingReportRefs = [], { readField }) {
            const newReportRef = cache.writeFragment({
              data: result?.createMonthlyReport,
              fragment: MonthlyReportFragmentDoc,
            });
            if (existingReportRefs.some((ref) => readField('id', ref) === result?.createMonthlyReport.id)) {
              return existingReportRefs;
            }
            return [...existingReportRefs, newReportRef];
          },
        },
      });
    },
    refetchQueries: [
      { query: MonthlyReportDocument, variables: { organizationId, month, tz } },
      { query: MonthlyReportDocument, variables: { organizationId, month: prevMonth, tz } },
    ],
  });

  return createReport;
}
