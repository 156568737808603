import { gql, useMutation, useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { MyOrganizationsDocument } from 'frontend/api/generated';
import { Select } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

const SKILL_IN_ORGANIZATION = gql`
  query SkillInOrganization($skillId: ID!) {
    skill(id: $skillId) {
      id
      organization {
        id
      }
    }
  }
`;
const TRANSFER_SKILL_TO_ORGANIZATION = gql`
  mutation TransferSkillToOrganization($organizationId: ID!, $skillId: ID!) {
    transferSkillToOrganization(organizationId: $organizationId, skillId: $skillId) {
      id
      organization {
        id
        name
      }
    }
  }
`;

const TransferSkillToOrganizationModal = ({ hide, args: { skillId } }) => {
  const toast = useToast();
  const { data: organizationsData } = useQuery(MyOrganizationsDocument);
  const { data: skillInOrgData } = useQuery(SKILL_IN_ORGANIZATION, { variables: { skillId } });
  const [mutate] = useMutation(TRANSFER_SKILL_TO_ORGANIZATION);
  const organizationsList = useMemo(
    () => orderBy(organizationsData?.organizations ?? [], ['name', 'id']),
    [organizationsData],
  );

  const organizationId = skillInOrgData?.skill?.organization?.id;
  const initialValues = { organizationId };

  const onSubmit = useCallback(
    async (values) => {
      await mutate({
        variables: {
          organizationId: values.organizationId,
          skillId,
        },
        update: (cache, { data }) => {
          toast.success(`The skill has been transferred to ${data.transferSkillToOrganization.organization.name}`);
        },
      });
    },
    [mutate, skillId, toast],
  );

  return (
    <ModalForm
      title="Transfer skill to organization"
      onOkText="Transfer to organization"
      onSubmit={onSubmit}
      hide={hide}
      initialValues={initialValues}
    >
      <p>Choose the organization you want to move the skill to.</p>
      <Field component={Select} name="organizationId">
        {organizationsList.map(({ id, name }) => (
          <option key={`transfer-to-organization-${id}`} value={id}>
            {name}
          </option>
        ))}
      </Field>
    </ModalForm>
  );
};

TransferSkillToOrganizationModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    skillId: PropTypes.string.isRequired,
  }),
};

export default TransferSkillToOrganizationModal;
