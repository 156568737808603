import { SortableContext } from '@dnd-kit/sortable';
import cx from 'classnames';
import { type Dispatch, type SetStateAction, useRef, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { DotsVertical, KindlyBlob, Plus, Tickets, Trash, Wink } from 'frontend/assets/icons';
import { Button, EmptyState, Icon, Input, PageBar, Panel, Radio, Range, ToggleSwitch } from 'frontend/components';
import AddAdornment from 'frontend/components/AddAdornment/AddAdornment';
import ContextMenu, { type ContextMenuRef } from 'frontend/components/ContextMenu/ContextMenu';
import MenuOverlay from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { FIELD_COLOR } from 'frontend/constants';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { DRAGGABLE_TYPES } from 'frontend/features/Dnd/constants';
import { useFormSort } from 'frontend/features/Dnd/hooks';
import { useModal } from 'frontend/features/Modals';
import { required, url as urlValidate } from 'frontend/form/validators';
import { useCurrentLanguage } from 'frontend/hooks';
import type { UploadFileType } from 'frontend/hooks/useOnSubmitWithUpload';
import { getContrastColor, getId } from 'frontend/utils';

import styles from './GlobalIconItem.scss';
import EditableIcon from '../EditableIcon/EditableIcon';
import GlobalIconModal from '../GlobalIconModal/GlobalIconModal';

const MAXIMUM_ICONS = 5;

type GlobalIconItemProps = {
  name: string;
  change: (name: string, value: any) => void;
  setFile: Dispatch<SetStateAction<UploadFileType>> | ((...args: any[]) => Promise<any>) | undefined;
};

export enum TriggerOnDeviceEnum {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

const GlobalIconItem = ({ name, change, setFile }: GlobalIconItemProps) => {
  const {
    input: { value },
  } = useField(name);

  const ref = useRef<ContextMenuRef[]>([]);
  const [urlHasError, setUrlHasError] = useState('');

  const { initialValues } = useChatbubbleForm();
  const { botId } = useParams();
  const [showIconModal] = useModal(GlobalIconModal);
  const [{ currentLanguage }] = useCurrentLanguage();
  const sortingButtonId = useFormSort(DRAGGABLE_TYPES.ICON_BUTTON, `${name}.icons`);

  const onSubmit = (values) => {
    const index = value.icons?.findIndex((icon) => getId(icon) === getId(values));

    change(`${name}.icons[${index > -1 ? index : value.icons?.length || 0}]`, {
      ...{
        ...values,
        ...(!values.id && { id: values.tempId }),
      },
      __typename: DRAGGABLE_TYPES.ICON_BUTTON,
    });
  };

  const renderIconsEmptyState = () => (
    <EmptyState
      title="No icons yet"
      maxWidth="218px"
      icon={Tickets}
      color="blue"
      description="Add the first one to get started. You can add up to 5 icons for each set of global icons."
    >
      <Button
        className={styles.addIconBtn}
        icon={Plus}
        color="white"
        title="Add icon"
        onClick={() => {
          showIconModal({
            botId,
            onSubmit,
            setFile,
            globalIconId: value?.id,
            initialValues: {},
            change,
          });
        }}
        text="Add icon"
      />
    </EmptyState>
  );

  const removeByIndex = (index: number) => () => {
    const iconsCopy = [...value.icons];

    const tempId = iconsCopy[index].tempId;
    if (tempId) {
      setFile?.((current) => {
        if (Array.isArray(current)) {
          const indexToRemove = current.findIndex((cur) => tempId === cur.extra.tempId);
          if (indexToRemove >= 0) {
            current.splice(indexToRemove, 1);
          }
        }
        return current;
      });
    }
    iconsCopy.splice(index, 1);
    change(`${name}.icons`, iconsCopy);
  };

  const renderIconsList = () => (
    <div className={styles.globalIconsList}>
      {value.icons.length < MAXIMUM_ICONS && (
        <Button
          className={styles.addMoreIconBtn}
          color="white"
          icon={Plus}
          onClick={() =>
            showIconModal({
              botId,
              onSubmit,
              setFile,
              globalIconId: value?.id,
              initialValues: {},
              change,
            })
          }
        />
      )}
      <SortableContext items={value.icons?.map((icon) => icon.id)}>
        <div className={styles.globalIconsContent}>
          {value.icons
            .filter((icon) => icon.id || icon.tempId)
            ?.map((icon, index) => (
              <EditableIcon
                className={styles.globalIconsEditableBtn}
                key={`button-${icon.id || icon.tempId}`}
                button={icon}
                setEditingButton={(vals) =>
                  showIconModal({
                    onSubmit,
                    botId,
                    setFile,
                    globalIconId: value?.id,
                    initialValues: vals,
                    change,
                  })
                }
                style={{
                  color: getContrastColor(initialValues.colorGlobalIconsButtonBackground, '#fff', '#232f41'),
                  backgroundColor: initialValues.colorGlobalIconsButtonBackground,
                }}
                removeByIndex={removeByIndex(index)}
                isSorting={sortingButtonId === getId(icon)}
              >
                <div className={styles.globalIconTitle}>{icon.title}</div>
                {icon.imageUrl && (
                  <div className={styles.globalIconImg}>
                    <img src={icon.imageUrl} alt={icon.altText} />
                  </div>
                )}
              </EditableIcon>
            ))}
        </div>
      </SortableContext>
      <div
        style={{
          color: getContrastColor(initialValues.colorButtonBackground, '#fff', '#232f41'),
          backgroundColor: initialValues.colorButtonBackground,
        }}
        className={cx(styles.globalIconPlaceholder, {
          [styles.globalIconPlaceholderText]: !(
            initialValues.useChatbubbleText && initialValues.chatbubbleText?.[currentLanguage]
          ),
        })}
      >
        {initialValues.useChatbubbleText && `${initialValues.chatbubbleText?.[currentLanguage]}`}
        <div className={styles.globalIconChatbubbleBtnImageContainer}>
          {initialValues.chatbubbleIconAvatarUrl ? (
            <img src={initialValues.chatbubbleIconAvatarUrl as string} alt="logo" />
          ) : (
            <Icon width={36} height={36} component={KindlyBlob} />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Panel.Header className={styles.buildPanelHeader}>
        <div className={styles.buildPanelTitle}>
          <Wink />
          &nbsp;{value?.title?.[currentLanguage] || 'New global icon'}
        </div>
        <PageBar.FormButtons className={styles.formSubmitBtn} />
      </Panel.Header>
      <div className={styles.chatbubbleSettingsWrapper}>
        <div className={cx(styles.globalIconsPreview, { [styles.emptyPreview]: !value.icons?.length })}>
          {!value.icons?.length ? renderIconsEmptyState() : renderIconsList()}
        </div>
        <div className={styles.globalIconsContainer}>
          <div className={styles.globalIconsTitle}>Setup for Global Icons</div>
          <div className={styles.inputContainer}>
            <Field
              component={Input}
              validate={required}
              fieldColor={FIELD_COLOR.MISCHKA}
              name={`${name}.title.${currentLanguage}`}
              label="Name this global icon"
              placeholder="Write a global icon title"
            />
          </div>
          <div className={cx(styles.inputContainer, styles.urlInputContainer)}>
            <Input
              adornmentPosition="right"
              errorPosition="bottom"
              fieldColor={FIELD_COLOR.MISCHKA}
              adornment={<AddAdornment />}
              input={{
                name: `url_input`,
                onChange: () => {
                  if (urlHasError) {
                    setUrlHasError('');
                  }
                },
              }}
              forceValidate
              meta={{
                error: urlHasError,
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && e.currentTarget.value) {
                  if (value.url?.includes(e.currentTarget.value)) {
                    setUrlHasError('Please provide unique url.');
                    return;
                  }
                  const validateUrl = urlValidate(e.currentTarget.value);
                  if (validateUrl) {
                    setUrlHasError(validateUrl);
                    return;
                  }
                  const newArray = [...(value.url || []), e.currentTarget.value];
                  change(`${name}.url`, newArray);
                  e.currentTarget.value = '';
                }
              }}
              label="URL to trigger on"
              placeholder="https://www.website.com"
            />
            <div className={styles.urlsWrapper}>
              {value.url?.map((url, index) => (
                <ContextMenu
                  menuPosition="left"
                  key={url}
                  id={`context-menu-${url}`}
                  ref={(el) => {
                    ref.current[index] = el as ContextMenuRef;
                  }}
                  overlay={
                    <MenuOverlay
                      options={[
                        {
                          text: 'Delete',
                          icon: Trash,
                          onClick: () => {
                            const newArray = [...(value.url || [])];
                            newArray.splice(
                              newArray.findIndex((item) => item === url),
                              1,
                            );
                            change(`${name}.url`, newArray);
                          },
                        },
                      ]}
                    />
                  }
                >
                  <div className={styles.urlContent}>
                    <Icon
                      onClick={(e) => ref?.current[index]?.openMenu(e as React.MouseEvent<SVGSVGElement>)}
                      component={DotsVertical}
                    />

                    <div>{url}</div>
                  </div>
                </ContextMenu>
              ))}
            </div>
          </div>
          <div className={styles.fieldDescription}>
            <p>
              Use the URL field if you only want the Global Icons to appear on a certain page. If left blank, the Global
              Icons appear on every page of your website. You can add as many URLs you need.
            </p>
          </div>
          <div className={styles.inputContainer}>
            <strong>Trigger on device</strong>
            <div className={styles.inputRadioWrapper}>
              <Field
                component={Radio}
                input={{
                  checked: value.triggerOnDevice?.length > 1,
                  onChange: (e) => {
                    if (value.triggerOnDevice.length === 1) {
                      change(`${name}.triggerOnDevice`, [TriggerOnDeviceEnum.MOBILE, TriggerOnDeviceEnum.DESKTOP]);
                    }
                    e.stopPropagation();
                  },
                }}
                type="radio"
                name={`${name}.triggerOnDevice`}
                label="Both"
              />
              <Field
                input={{
                  checked:
                    value.triggerOnDevice?.length === 1 && value.triggerOnDevice.includes(TriggerOnDeviceEnum.MOBILE),
                  value: 'MOBILE',
                  onChange: (e) => {
                    if (
                      value.triggerOnDevice.length > 1 ||
                      !value.triggerOnDevice.includes(TriggerOnDeviceEnum.MOBILE)
                    ) {
                      change(`${name}.triggerOnDevice`, [e.target.value]);
                    }
                    e.stopPropagation();
                  },
                }}
                component={Radio}
                type="radio"
                name={`${name}.triggerOnDevice`}
                label="Mobile"
              />
              <Field
                input={{
                  value: TriggerOnDeviceEnum.DESKTOP,
                  checked:
                    value.triggerOnDevice?.length === 1 && value.triggerOnDevice.includes(TriggerOnDeviceEnum.DESKTOP),
                  onChange: (e) => {
                    if (
                      value.triggerOnDevice.length > 1 ||
                      !value.triggerOnDevice.includes(TriggerOnDeviceEnum.DESKTOP)
                    ) {
                      change(`${name}.triggerOnDevice`, [e.target.value]);
                    }
                    e.stopPropagation();
                  },
                }}
                component={Radio}
                type="radio"
                name={`${name}.triggerOnDevice`}
                label="Desktop"
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <strong>Trigger rule</strong>
            <div className={styles.inputRadioWrapper}>
              <Field
                component={Radio}
                type="radio"
                value="USER_INACTIVITY"
                name={`${name}.triggerRule`}
                label="User inactivity"
              />
              <Field
                component={Radio}
                type="radio"
                value="USER_SCROLLING"
                name={`${name}.triggerRule`}
                label="User scrolling"
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <Range
              name={`${name}.triggerAfter`}
              min={0}
              max={value.triggerRule === 'USER_INACTIVITY' ? 30 : 100}
              step={1}
              unit={value.triggerRule === 'USER_INACTIVITY' ? 's' : '%'}
              className={styles.slider}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.switchContainer}>
              <ToggleSwitch name={`${name}.enabled.${currentLanguage}`} />
              <label htmlFor="ToggleSwitch-tabNotification">Publish</label>
            </div>
            <div className={styles.fieldDescription}>
              Activate the global icons when they are ready to see them live on your website.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalIconItem;
