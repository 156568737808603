/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useCurrentLanguage } from 'frontend/hooks';

import { DEFAULT_RULE_ID, type DialogueFeature } from '../../constants';
import useCurrentRuleId from '../../hooks/useCurrentRuleId';
import { AddPluginInstance } from '../../modals';
import type { BuildFormType } from '../../propTypes/BuildFormType';
import Plugin from '../Plugin';
import Webhook from '../Webhook';

interface ButtonProps {
  dialogueId?: Props['dialogueId'];
  setFeatureType: React.Dispatch<React.SetStateAction<DialogueFeature>>;
}

const FeatureButtons = ({ dialogueId, setFeatureType }: ButtonProps) => {
  const { change } = useForm();
  const [showAddPluginModal] = useModal(AddPluginInstance);

  const handlePluginClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>): void => {
    showAddPluginModal?.({ dialogueId, change });
    event.preventDefault();
  };

  const handleWebhookClick = () => setFeatureType('WEBHOOK');

  return (
    <div className="m-b-2">
      <Button className="m-r-md" onClick={handlePluginClick} text="Add plugin" />
      <Button className="m-r-md" onClick={handleWebhookClick} text="Add webhook" />
    </div>
  );
};

const PluginDeleted = () => <p>After removing a plugin, you must save the dialogue to add a new one.</p>;

const getFeatureType = ({
  isModDialogue,
  webhookUrls,
  currentRuleId,
  selectedLanguage,
  pluginTemp,
  pluginInstance,
  buildFormType,
}): DialogueFeature => {
  const hasWebhook = Boolean(webhookUrls?.[selectedLanguage]?.[currentRuleId]);
  if (pluginTemp && !pluginTemp.plugin) return 'PLUGIN_DELETED';
  if (pluginInstance || pluginTemp) return 'PLUGIN';
  if (isModDialogue || hasWebhook || buildFormType !== 'dialogue') return 'WEBHOOK';

  return 'NONE';
};

interface Props {
  dialogueId?: string;
  isModDialogue: boolean;
  pluginInstance?: object;
  buildFormType: BuildFormType;
  webhookUrls?: string;
  pluginTemp?: object;
}

const FeatureContents = ({
  isModDialogue,
  webhookUrls,
  pluginTemp,
  pluginInstance,
  buildFormType,
  dialogueId,
}: Props) => {
  const [{ selectedLanguage }] = useCurrentLanguage();
  const currentRuleId = useCurrentRuleId() || DEFAULT_RULE_ID;

  const [featureType, setFeatureType] = useState<DialogueFeature>(() =>
    getFeatureType({
      isModDialogue,
      webhookUrls,
      currentRuleId,
      selectedLanguage,
      pluginTemp,
      pluginInstance,
      buildFormType,
    }),
  );

  useEffect(() => {
    setFeatureType(
      getFeatureType({
        isModDialogue,
        webhookUrls,
        currentRuleId,
        selectedLanguage,
        pluginTemp,
        pluginInstance,
        buildFormType,
      }),
    );
  }, [isModDialogue, webhookUrls, selectedLanguage, currentRuleId, pluginTemp, pluginInstance, buildFormType]);

  if (featureType === 'PLUGIN') {
    return (
      <Plugin
        pluginInstance={pluginInstance}
        pluginTemp={pluginTemp}
        setFeatureType={setFeatureType}
        isModDialogue={isModDialogue}
      />
    );
  }

  if (featureType === 'PLUGIN_DELETED') {
    return <PluginDeleted />;
  }

  if (featureType === 'WEBHOOK') {
    return <Webhook setFeatureType={setFeatureType} isModDialogue={isModDialogue} />;
  }

  return <FeatureButtons setFeatureType={setFeatureType} dialogueId={dialogueId} />;
};

export default FeatureContents;
