import cx from 'classnames';
import PropTypes from 'prop-types';
import { Suspense, lazy, useRef } from 'react';

import { EmojiHappy } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import { useClickOutside, useToggle } from 'frontend/hooks';

import styles from './HandoverForm.scss';

const Picker = lazy(() => import('emoji-picker-react'));

const EmojiPicker = ({ onEmojiSelect }) => {
  const emojiPickerRef = useRef();
  const [active, toggleActive, unsetActive] = useToggle();

  useClickOutside(emojiPickerRef, unsetActive);

  const onClick = ({ emoji }) => {
    unsetActive();
    onEmojiSelect(emoji);
  };

  return (
    <div className={styles.emojiWrapper} ref={emojiPickerRef}>
      <Button className={cx(styles.icon, { [styles.iconActive]: active })} onClick={toggleActive} flat>
        <Icon component={EmojiHappy} title="Select emoji" />
      </Button>
      {active && (
        <Suspense fallback={null}>
          <Picker onEmojiClick={onClick} disableSkinTonePicker native />
        </Suspense>
      )}
    </div>
  );
};

EmojiPicker.propTypes = {
  onEmojiSelect: PropTypes.func.isRequired,
};

export default EmojiPicker;
