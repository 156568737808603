import cx from 'classnames';
import { useCallback } from 'react';

import styles from './Pagination.scss';

interface NumberButtonProps {
  number: number;
  currentPage: number;
  setPage(page: number): void;
}

const NumberButton = ({ number, currentPage, setPage }: NumberButtonProps) => {
  const onClick = useCallback(() => setPage(number), [number, setPage]);
  const className = cx(styles.pageButton, { [styles.active]: number === currentPage });

  return (
    <button key={`paginator-number-${number}`} className={className} onClick={onClick} type="button">
      {number}
    </button>
  );
};

export default NumberButton;
