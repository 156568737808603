import cx from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Bold, CodeInput as Code, Italic, Underline } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';

import styles from './HandoverForm.scss';

const StyleButton = ({ editorState, onToggle, type }) => {
  const [active, setActive] = useState(false);
  const componentType = type === 'code-block' ? 'Code' : type.charAt(0) + type.slice(1).toLowerCase();
  const component = {
    Bold,
    Code,
    Italic,
    Underline,
  }[componentType];

  useEffect(() => {
    if (type === 'code-block') {
      // Only code button toggles block style
      const selection = editorState.getSelection();
      const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
      setActive(blockType === 'code-block');
    } else {
      // Any other button toggles inline style
      const currentStyle = editorState.getCurrentInlineStyle();
      setActive(currentStyle.has(type));
    }
  }, [editorState, type]);

  return (
    <Button
      className={cx(styles.icon, { [styles.iconActive]: active })}
      onMouseDown={(e) => {
        e.preventDefault();
        onToggle(type);
      }}
      flat
    >
      <Icon component={component} title={`Toggle ${type.toLowerCase()} style`} />
    </Button>
  );
};

StyleButton.propTypes = {
  editorState: PropTypes.shape({}).isRequired,
  onToggle: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default StyleButton;
