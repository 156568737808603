import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

import { Plus, Rows2, Settings } from 'frontend/assets/icons';
import { Button, DropdownButton, EmptyState, InfoBox, Tooltip } from 'frontend/components';
import { DRAGGABLE_TYPES } from 'frontend/features/Dnd/constants';
import { useCurrentLanguage } from 'frontend/hooks';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './Forms.scss';
import FieldsArray from './components/FieldsArray/FieldsArray';
import FormSettings from './components/FormSettings/FormSettings';
import inputTypes from './utils/constants';
import { findFormByLanguageAndRule, getFormDefaultText, getFormFieldDefaultText } from './utils/helpers';
import { DEFAULT_RULE_ID } from '../../constants';
import useCurrentRuleId from '../../hooks/useCurrentRuleId';

const MAXIMUM_FIELDS = 5;

const getDefaultForm = (selectedLanguage: string, ruleId: string) => ({
  languageCode: selectedLanguage,
  texts: getFormDefaultText(selectedLanguage),
  ...(ruleId && { rule: { id: ruleId } }),
  fields: [],
});

const getFormIndex = (form, forms) => {
  const foundIndex = forms.findIndex((f) => f.id === form?.id);

  if (foundIndex >= 0) {
    return foundIndex;
  }

  return forms.length;
};

const getFormFieldInitialValidators = (inputType: string) => {
  if (inputType === inputTypes.RANGE) {
    return [
      {
        minimum: 0,
        id: `temporary-uuid-${randomUUID()}`,
        texts: {
          id: `temporary-uuid-${randomUUID()}`,
          text: '',
        },
      },
      {
        maximum: 1,
        id: `temporary-uuid-${randomUUID()}`,
        texts: {
          id: `temporary-uuid-${randomUUID()}`,
          text: '',
        },
      },
    ];
  }

  return [];
};

const Forms = ({ tabSelected }) => {
  const { change, getState } = useForm();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const currentRuleId = useCurrentRuleId();
  const [settingsOpen, setSettingsOpen] = useState(false);

  const currentForm = findFormByLanguageAndRule(getState().values?.buildForms, selectedLanguage, currentRuleId);
  const formIndex = getFormIndex(currentForm, getState().values?.buildForms);

  useEffect(() => {
    setSettingsOpen(false);
  }, [selectedLanguage]);

  // Only activate dragging when moving a minimum of 5px
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  });
  const sensors = useSensors(pointerSensor);

  const createButton = (newFormFields: string): void => {
    const formFields = [
      ...(currentForm?.fields || []),
      {
        id: `temporary-uuid-${randomUUID()}`,
        order: currentForm?.fields?.length || 0,
        inputType: newFormFields,
        required: false,
        texts: getFormFieldDefaultText(newFormFields, selectedLanguage),
        validators: getFormFieldInitialValidators(newFormFields),
        __typename: DRAGGABLE_TYPES.FORM_FIELD,
        ...((newFormFields === inputTypes.SELECT ||
          newFormFields === inputTypes.CHECKBOX ||
          newFormFields === inputTypes.RADIO) && {
          attributes: {
            options: [
              {
                id: `temporary-uuid-${randomUUID}`,
                value: '',
                label: '',
                __typename: DRAGGABLE_TYPES.FORM_FIELD_ATTRIBUTE_OPTIONS,
              },
            ],
          },
        }),
      },
    ];

    if (!currentForm) {
      change('buildForms', [
        ...getState().values.buildForms,
        {
          ...getDefaultForm(selectedLanguage, currentRuleId),
          fields: formFields,
          rule: currentRuleId ? { id: currentRuleId } : undefined,
        },
      ]);
    } else {
      change(`buildForms[${formIndex}].fields`, formFields);
    }
  };

  useEffect(() => {
    if (!tabSelected && settingsOpen) {
      setSettingsOpen(false);
    }
  }, [tabSelected, settingsOpen]);

  const handleSettingsOpen = () => {
    if (!currentForm) {
      change(`buildForms.[${formIndex}]`, getDefaultForm(selectedLanguage, currentRuleId));
    }

    setSettingsOpen((currValue) => !currValue);
  };

  const actions = [
    { title: 'Text', key: 'dropdown-item-field-text', onClick: () => createButton(inputTypes.TEXT) },
    { title: 'Email', key: 'dropdown-item-field-email', onClick: () => createButton(inputTypes.EMAIL) },
    { title: 'Number', key: 'dropdown-item-field-number', onClick: () => createButton(inputTypes.NUMBER) },
    { title: 'Slider', key: 'dropdown-item-field-range', onClick: () => createButton(inputTypes.RANGE) },
    { title: 'Dropdown', key: 'dropdown-item-field-select', onClick: () => createButton(inputTypes.SELECT) },
    {
      title: 'Multiple choice',
      key: 'dropdown-item-field-multiple-choice',
      onClick: () => createButton(inputTypes.CHECKBOX),
    },
    {
      title: 'File upload',
      key: 'dropdown-item-field-file-upload',
      onClick: () => createButton(inputTypes.FILE),
    },
  ];

  const hasFields = !!currentForm?.fields?.length;

  const renderAddFieldButton = () => (
    <Tooltip>
      {currentForm && currentForm.fields?.length >= MAXIMUM_FIELDS && (
        <Tooltip.Body>Maximum of {MAXIMUM_FIELDS} fields allowed.</Tooltip.Body>
      )}
      <DropdownButton
        isDisabled={currentForm && currentForm.fields?.length >= MAXIMUM_FIELDS}
        element={Button}
        elementProps={{
          color: 'white',
          icon: Plus,
          text: 'Add field',
        }}
        triggerClassName={styles.addFieldTrigger}
        actions={actions}
        position="bottom"
        title={
          currentForm && currentForm.fields?.length >= MAXIMUM_FIELDS
            ? `Maximum of ${MAXIMUM_FIELDS} fields allowed.`
            : undefined
        }
        contentClassName={styles.formDropdownContent}
        wrapperClassName={styles.formDropdownWrapper}
      />
    </Tooltip>
  );

  const renderForm = () => (
    <div className={styles.formContainer}>
      <DndContext sensors={sensors}>
        <SortableContext items={currentForm?.fields.map((field) => field.id) || []}>
          <FieldsArray formIndex={formIndex} />
        </SortableContext>
      </DndContext>
    </div>
  );

  const renderEmptyForm = () => (
    <div className={styles.emptyFormWrapper}>
      <div className={styles.emptyFormContent}>
        <EmptyState
          maxWidth="280px"
          color="pink"
          icon={Rows2}
          title="Add form field"
          description="This dialogue contains no form field yet. Create a field that suits this dialogue's context"
        />
        <div className={styles.emptyFormAddActions}>{renderAddFieldButton()}</div>
      </div>
      <div className={styles.emptyFormInfo}>
        <InfoBox className={styles.emptyFormInfoBox}>
          Forms are useful for receiving feedback from your customers.
        </InfoBox>
      </div>
    </div>
  );

  return (
    <div className={styles.formWrapper}>
      <div className={styles.formWrapperContainer}>
        {hasFields && renderForm()}
        {!hasFields && renderEmptyForm()}
        <div className={styles.formActions}>
          <Button
            active={settingsOpen}
            color="white"
            icon={Settings}
            text="Form settings"
            onClick={handleSettingsOpen}
          />
          {hasFields && renderAddFieldButton()}
        </div>
      </div>
      {settingsOpen && (
        <FormSettings
          onClose={() => setSettingsOpen(false)}
          currentLanguage={selectedLanguage}
          currentRuleId={currentRuleId || DEFAULT_RULE_ID}
          currentForm={`buildForms[${formIndex}]`}
          onFormRemove={() => {
            setSettingsOpen(false);
            const buildFormsCopy = [...getState().values.buildForms];
            buildFormsCopy.splice(formIndex, 1);
            change('buildForms', buildFormsCopy);
          }}
        />
      )}
    </div>
  );
};

export default Forms;
