import cx from 'classnames';

import styles from './TextDiff.scss';

interface DefaultComponentProps {
  value: string;
  alteredValue?: string;
  added?: boolean;
  removed?: boolean;
  diffByCharacters?: boolean;
}

const DefaultComponent = ({ value, alteredValue, added, removed, diffByCharacters }: DefaultComponentProps) => {
  const classes = cx({ [styles.textDiffRemoved]: removed, [styles.textDiffAdded]: added });
  return alteredValue ? (
    <>
      <span className={styles.textDiffRemoved}>{value}</span>
      {!diffByCharacters && ' '}
      <span className={styles.textDiffAdded}>{alteredValue}</span>
    </>
  ) : (
    <span className={classes}>{value}</span>
  );
};

export default DefaultComponent;
