import { useCallback } from 'react';
import { Field } from 'react-final-form';

const SUBSCRIPTION = { value: true };

interface ConditionProps {
  when: string;
  is?: unknown;
  isNot?: unknown | undefined;
  children: React.ReactNode;
  isAny?: unknown[];
}
const Condition = ({ when, is, isNot, isAny = [], children }: ConditionProps) => {
  const render = useCallback(
    ({ input: { value } }) => {
      if (is !== undefined && value === is) return children;
      if (isNot !== undefined && value !== isNot) return children;
      if (isAny.length > 0 && isAny.includes(value)) return children;
      return null;
    },
    [children, is, isAny, isNot],
  );

  return <Field name={when} subscription={SUBSCRIPTION} render={render} />;
};

export default Condition;
