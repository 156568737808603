import { Rocket } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';

import styles from './CannyHighlight.scss';

const CannyHighlight = () => (
  <div className={styles.container}>
    <div className={styles.iconWrapper}>
      <Icon component={Rocket} title="Help us improve" color="primary" />
    </div>

    <div className={styles.textWrapper}>
      <h4>
        <strong>Ideas on how to improve Kindly?</strong>
      </h4>
      <p>
        Let us know your ideas on what you think would make Kindly even better. You can also vote and browse other user
        ideas.
      </p>
    </div>

    <div className={styles.buttonWrapper}>
      <Button color="secondary" to="/feedback/feature">
        Share your idea
      </Button>
    </div>
  </div>
);

export default CannyHighlight;
