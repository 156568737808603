import { EditorState } from 'draft-js';
import { noop } from 'lodash';
import { useField } from 'react-final-form';

const FIELD_CONFIG = { subscription: { value: true } };

const EMPTY_EDITOR_STATE = EditorState.createEmpty();

export default function useComposerState(name) {
  const {
    input: { value, onChange },
  } = useField(name, FIELD_CONFIG);

  if (!(value instanceof EditorState)) {
    // State is not ready yet; return empty EditorState to avoid errors
    return { state: EMPTY_EDITOR_STATE, setState: noop };
  }

  return { state: value, setState: onChange };
}
