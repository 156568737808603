import kindlyLogo from 'frontend/assets/images/logo.svg?url';

import styles from './NavbarStaticLogo.scss';

const NavbarStaticLogo = () => (
  <div className={styles.logoWrapper}>
    <img alt="Kindly logo" className={styles.logo} src={kindlyLogo} />
  </div>
);

export default NavbarStaticLogo;
