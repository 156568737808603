import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useForm } from 'react-final-form';

import { Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';

import styles from './Plugin.scss';
import { DeletePluginInstance } from '../../modals';
import { pluginInstanceType } from '../../propTypes';

const Plugin = ({ pluginInstance, pluginTemp, setFeatureType, isModDialogue }) => {
  const form = useForm();
  const instance = pluginInstance || pluginTemp;
  const [showDeleteModal] = useModal(DeletePluginInstance);

  const onClickDelete = useCallback(
    () => showDeleteModal({ plugin: instance, setFeatureType, form }),
    [instance, setFeatureType, showDeleteModal, form],
  );

  if (!instance?.plugin) return null;

  return (
    <div className={styles.pluginContainer}>
      <div className={`${styles.pluginInner} p-md`}>
        <h3>{instance.plugin.name}</h3>
        <div className={styles.pluginFooter}>
          <p className={`${styles.pluginDescription} m-b-n`}>{instance.plugin.description}</p>
          {instance.id && !isModDialogue && (
            <Icon
              onClick={onClickDelete}
              color="default"
              hoverColor="warning"
              component={Trash}
              title="Remove"
              className={styles.deleteIcon}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Plugin.propTypes = {
  setFeatureType: PropTypes.func.isRequired,
  pluginInstance: pluginInstanceType,
  pluginTemp: pluginInstanceType,
  isModDialogue: PropTypes.bool.isRequired,
};

export default Plugin;
