import PropTypes from 'prop-types';

import { DotsVertical } from 'frontend/assets/icons';
import { Button, Dropdown } from 'frontend/components';

import styles from './DotsButton.scss';
import DotsOverlay from './DotsOverlay';

function DotsButton({ handleDelete, handleEdit }) {
  return (
    <Dropdown
      overlay={<DotsOverlay handleDelete={handleDelete} handleEdit={handleEdit} />}
      overlayClassName={styles.content}
      wrapperClassName={styles.container}
      element="span"
    >
      <Button flat icon={DotsVertical} className={styles.editButton} />
    </Dropdown>
  );
}

DotsButton.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default DotsButton;
