import { gql } from '@apollo/client';

import { COMMENT } from '../fragments';

export default gql`
  mutation CreateOrUpdateChatComment($botId: ID!, $chatId: ID!, $message: String!, $commentId: ID) {
    createOrUpdateChatComment(botId: $botId, chatId: $chatId, message: $message, commentId: $commentId) {
      ...ChatComment
    }
  }
  ${COMMENT}
`;
