import { EditorState, Modifier } from 'draft-js';

import { createSelectionState } from '../utils';

export default (state, { focus } = {}) => {
  const hasFocus = focus === undefined ? state.getSelection().getHasFocus() : focus;
  const content = state.getCurrentContent();
  const firstBlock = content.getFirstBlock();
  const lastBlock = content.getLastBlock();

  const selection = createSelectionState({
    anchorKey: firstBlock.getKey(),
    focusKey: lastBlock.getKey(),
    anchorOffset: 0,
    focusOffset: lastBlock.getText().length,
    hasFocus,
  });

  const clearedContent = Modifier.removeRange(content, selection, 'backward');
  return EditorState.push(state, clearedContent, 'remove-range');
};
