import cx from 'classnames';
import PropTypes from 'prop-types';

import { UserCheck, UserPlus, Users } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './ChatStatus.scss';

export const CHAT_STATUS_LABELS = Object.freeze({
  has_requested_takeover: {
    label: 'Agent requested',
    icon: UserPlus,
  },
  has_requested_handover: {
    label: 'Agent requested',
    icon: UserPlus,
  },
  awaiting_response: {
    label: 'Awaiting response',
    icon: Users,
  },
  taken_over: {
    label: 'In handover',
    icon: Users,
  },
  handed_over: {
    label: 'In handover',
    icon: Users,
  },
  completed_takeover: {
    label: 'Handover completed',
    icon: UserCheck,
  },
  completed_handover: {
    label: 'Handover completed',
    icon: UserCheck,
  },
  contact_requested: {
    label: 'Contact requested',
    icon: UserPlus,
  },
});

const ChatStatus = ({ status, notification = false, asBadge = false }) => {
  if (!status) {
    return null;
  }
  return (
    <div className={cx(styles.chatStatus, { [styles.badge]: asBadge })}>
      {CHAT_STATUS_LABELS[status]?.icon && (
        <Icon component={CHAT_STATUS_LABELS[status].icon} title="Chat status" className={styles.icon} />
      )}
      <span>
        {CHAT_STATUS_LABELS[status]?.label ||
          `${status.charAt(0).toUpperCase()}${status.slice(1).replaceAll('_', ' ')}`}
      </span>
      {notification && <div className={styles.dot} />}
    </div>
  );
};

ChatStatus.propTypes = {
  status: PropTypes.oneOf(Object.keys(CHAT_STATUS_LABELS)),
  notification: PropTypes.bool,
  asBadge: PropTypes.bool,
};

export default ChatStatus;
