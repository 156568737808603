import { useMutation, useQuery } from '@apollo/client';
import { pick } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { BotSettingsDocument, UpdateBotSettingsDocument } from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';

export default () => {
  const toast = useToast();
  const { botId } = useParams();

  const update = useCallback(() => toast.success('Settings updated'), [toast]);
  const [updateSettings] = useMutation(UpdateBotSettingsDocument, { update });

  const { data, loading } = useQuery(BotSettingsDocument, { variables: { botId } });
  const initialValues = data?.bot;

  const onSubmit = useCallback(
    async (values) => {
      await updateSettings({
        variables: {
          botId,
          botInput: {
            ...pick(values, ['name', 'chatbubbleDomains', 'confidenceThreshold']),
          },
        },
      });
    },
    [botId, updateSettings],
  );

  return { loading, onSubmit, initialValues };
};
