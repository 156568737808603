import { DragOverlay, useDndMonitor } from '@dnd-kit/core';
import { useCallback, useState } from 'react';

import { Image } from 'frontend/components/ImageCarousel/components';
import { Button } from 'frontend/features/Build/components';
import { ChildrenType } from 'frontend/propTypes';

import { DRAGGABLE_TYPES } from './constants';
import { getDraggableItem } from './utils';

const getOverlayComponent = (item) => {
  switch (item?.__typename) {
    case DRAGGABLE_TYPES.BUTTON:
      return Button;
    case DRAGGABLE_TYPES.IMAGE:
      return Image;
    default:
      return undefined;
  }
};

// We can only render one DragOverlay or there will be issues; hence this component.

const DndWrapper = ({ children }) => {
  const [draggingItem, setDraggingItem] = useState();

  const onDragStart = useCallback(({ active }) => {
    const item = getDraggableItem(active);
    if (item) setDraggingItem(item);
  }, []);

  const onDragEnd = useCallback(() => {
    setDraggingItem();
  }, []);

  useDndMonitor({ onDragStart, onDragEnd });

  const OverlayComponent = getOverlayComponent(draggingItem);

  return (
    <>
      {children}
      <DragOverlay>{Boolean(OverlayComponent && draggingItem) && <OverlayComponent item={draggingItem} />}</DragOverlay>
    </>
  );
};

DndWrapper.propTypes = {
  children: ChildrenType.isRequired,
};

export default DndWrapper;
