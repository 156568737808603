import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import UpdateS3BackupSettings from 'frontend/features/BotSettings/mutations/UpdateS3InboxBackupSettings.mutation.graphql';
import { useIsRunning } from 'frontend/hooks';

export default ({ botId, initialValues }) => {
  const [bucketUpdateSuccessful, setBucketUpdateSuccessful] = useState();
  const [update] = useMutation(UpdateS3BackupSettings);

  const onSubmit = useCallback(
    async ({ awsAccessKeyId, s3Enabled, bucketName, pathPrefix, awsSecretAccessKey }) => {
      // only update secret if its been changed, since we censor the private key with asterix
      const secretHasBeenChanged = !initialValues || awsSecretAccessKey !== initialValues.awsSecretAccessKey;
      const variables = {
        botId,
        awsAccessKeyId,
        enabled: s3Enabled,
        bucketName,
        pathPrefix,
        ...(secretHasBeenChanged ? { awsSecretAccessKey } : {}),
      };

      try {
        await update({ variables });
        if (s3Enabled) setBucketUpdateSuccessful(true);
      } catch (e) {
        setBucketUpdateSuccessful(false);
      }
    },
    [botId, initialValues, update],
  );

  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmit);

  return { onSubmit: onSubmitWithRunning, isSubmitting, bucketUpdateSuccessful };
};
