import cx from 'classnames';

import { Icon } from 'frontend/components';
import { BUTTON_TYPES, BUTTON_TYPE_NAMES, LINK_STATE, TYPE_TO_ICON } from 'frontend/features/Build/constants';

import styles from './Buttons.scss';

interface Props {
  type: string;
  urlStatus: string | null;
}

const ButtonsOverviewItem = ({ type, urlStatus }: Props) => {
  const isBroken = type === BUTTON_TYPES.EXTERNAL_LINK && urlStatus === LINK_STATE.BROKEN;

  const buttonType = isBroken ? LINK_STATE.BROKEN : type;
  const iconImg = TYPE_TO_ICON[buttonType];
  const buttonTypeName = BUTTON_TYPE_NAMES[buttonType];

  return (
    <>
      {!!iconImg && <Icon component={iconImg} className={cx(styles.buttonIcon, { [styles.brokenLink]: isBroken })} />}
      <span className={cx({ [styles.brokenLink]: isBroken })}>{buttonTypeName}</span>
    </>
  );
};

export default ButtonsOverviewItem;
