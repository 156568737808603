import { gql } from '@apollo/client';

import SUBSCRIPTION_FRAGMENT from 'frontend/features/Organization/fragments/Subscription.fragment.graphql';

export default gql`
  mutation UpdateOrganizationSubscription($subscriptionId: ID!, $planName: String!, $expirationDate: Date) {
    updateOrganizationSubscription(
      subscriptionId: $subscriptionId
      planName: $planName
      expirationDate: $expirationDate
    ) {
      ...Subscription
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;
