import cx from 'classnames';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { Alert, Check, SquarePlus, UserCirclePlus } from 'frontend/assets/icons';
import { Icon, Input } from 'frontend/components';
import { email as emailValidation, phoneNumber as phoneNumberValidation } from 'frontend/form/validators';

import styles from './UserDetails.scss';
import useUpdateChatDetails from '../../hooks/useUpdateChatDetails';

function validateOrEmpty(validator) {
  return (value) => {
    if (value === undefined || value === '') {
      return undefined;
    }
    return validator(value);
  };
}

interface VerifiedIconProps {
  verified?: boolean | null;
}

const VerifiedIcon = ({ verified }: VerifiedIconProps) => {
  if (verified) {
    return <Icon component={Check} className={styles.verified} title="Verified" />;
  }
  return <Icon component={Alert} className={styles.unverified} title="Unverified" />;
};

interface UserDetailsProps {
  email?: string | null;
  phoneNumber?: string | null;
  emailVerified?: boolean | null;
  phoneNumberVerified?: boolean | null;
}

const UserDetails = ({ email, phoneNumber, emailVerified, phoneNumberVerified }: UserDetailsProps) => {
  const { botId, chatId } = useParams();
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [updateChatDetails] = useUpdateChatDetails({ botId, chatId });

  const onSubmit = useCallback(
    async (values, form) => {
      if (!isEqual(values, form.getState().initialValues)) {
        await updateChatDetails({
          email: '',
          phoneNumber: '',
          ...values,
        });
      }
      setEditEmail(false);
      setEditPhone(false);
    },
    [updateChatDetails],
  );

  return (
    <div className="m-b-2">
      <Form
        onSubmit={onSubmit}
        initialValues={{ email, phoneNumber }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.field}>
              <div className={styles.fieldName} aria-hidden>
                Email
              </div>
              {editEmail ? (
                <Field
                  component={Input}
                  name="email"
                  aria-label="Email"
                  onBlur={handleSubmit}
                  autoFocus
                  validate={validateOrEmpty(emailValidation)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                    if (e.key === 'Escape') {
                      setEditEmail(false);
                      form.reset();
                    }
                  }}
                />
              ) : (
                <>
                  <button
                    onClick={() => setEditEmail(true)}
                    className={cx(styles.textField, {
                      [styles.textFieldEmpty]: !email,
                    })}
                    type="button"
                  >
                    {email || (
                      <>
                        <Icon component={UserCirclePlus} width={16} height={16} /> Add Email
                      </>
                    )}
                  </button>
                  {email && <VerifiedIcon verified={emailVerified} />}
                </>
              )}
            </div>
            <div className={styles.field}>
              <div className={styles.fieldName} aria-hidden>
                Phone
              </div>
              {editPhone ? (
                <Field
                  component={Input}
                  name="phoneNumber"
                  aria-label="Phone number"
                  onBlur={handleSubmit}
                  autoFocus
                  validate={validateOrEmpty(phoneNumberValidation)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                    if (e.key === 'Escape') {
                      setEditPhone(false);
                      form.reset();
                    }
                  }}
                />
              ) : (
                <>
                  <button
                    onClick={() => setEditPhone(true)}
                    className={cx(styles.textField, {
                      [styles.textFieldEmpty]: !phoneNumber,
                    })}
                    type="button"
                  >
                    {phoneNumber || (
                      <>
                        <Icon width={16} height={16} component={SquarePlus} /> Add number
                      </>
                    )}
                  </button>
                  {phoneNumber && <VerifiedIcon verified={phoneNumberVerified} />}
                </>
              )}
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default UserDetails;
