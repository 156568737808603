import { PublicLayout } from 'frontend/layouts/';

import styles from './styles.scss';

const TermsOfUse = () => (
  <PublicLayout hideContact>
    <div className={styles.container}>
      <div className={styles.section}>
        <h1>Terms of Use</h1>
        <p>
          Convertelligence AS, organisation number 817&nbsp;617&nbsp;492 (“Convertelligence”) provides a chatbot
          platform which allows users to communicate with customers or other third parties through an automated chat
          service (the “Service”). The Service is subscription based and offered exclusively to professional customers
          (the “Customer”). You may only use this Service if you are given access by a Customer with a valid license.
        </p>

        <p>
          Your use of the Service is subject to the following terms and conditions (the “Terms”). You acknowledge to
          have read and understood the Terms prior to using the Service.
        </p>

        <p>
          In order to use the Service you must be connected to a valid e-mail address, hosted by a Customer. You may not
          download or otherwise store any content processed in connection with the Service on your own hard drive or any
          private storage media. You shall treat all information processed as part of the Service confidential. Such
          information shall only be shared internally in the organization of the Customer on a strict need-to-know
          basis.
        </p>

        <p>
          You may not use the Service in any way that violates any laws or infringes anyone’s rights, is offensive or
          interferes with the Service or any features of the Service. Convertelligence is not liable for any losses of
          any kind, such as direct, indirect or consequential losses, caused by your use of the Service.
        </p>

        <p>
          You shall indemnify and hold Convertelligence, its affiliates, officers, employees, agents, consultants and
          advisers, harmless from any and all losses, damages or liability (including reasonable attorneys’ fees) for
          any claims, suits, actions, demands or threats of any third party based upon any act, omission, failure to act
          or misrepresentation by you in accordance with these Terms.
        </p>

        <p>
          The license is not granted to you personally, and the license does not grant you any personal rights to the
          Service. Your use of the Service is strictly limited to the terms provided to the Customer. In particular, you
          do not have the right to sell, lend, sub-license, distribute, copy, create derivate works, modify, decompile,
          access source code or object code, mirror or reverse engineer any part or feature of the Service, including
          all underlying intellectual property and know-how. The list is non-exhaustive.
        </p>

        <p>
          These Terms reflect the terms set in the license agreement between Convertelligence and the Customer. The
          Terms may be changed from time to time in accordance with any changes made in the license agreement. Upon
          changes to the Terms, you will be notified in advance.
        </p>
      </div>
    </div>
  </PublicLayout>
);

export default TermsOfUse;
