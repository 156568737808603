import { useEffect, useLayoutEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSearchParams } from 'react-router-dom';

import { useMixpanel } from 'frontend/hooks';
import { isPlatform } from 'frontend/utils';

import TabContents from './TabContents';
import TabTitle from './TabTitle';
import styles from './Tabs.scss';
import useHiddenTabs from './useHiddenTabs';

export interface Tab {
  title: string | ((isTabSelected: boolean) => string);
  errorNames: string[];
  key: string;
  contentClassName?: string;
  render?: (...args: any[]) => React.ReactNode;
  component?: (...args: any[]) => React.JSX.Element;
  tooltip?: string;
}

interface Props {
  args?: object;
  children?: React.ReactNode;
  className?: string;
  listView?: boolean;
  /** Callback to run on Tab click. It gets passed the `title` of the clicked Tab.  */
  onTabClick?: (tabName: string) => void;
  queryParam?: string;
  selectedTab?: number;
  tabColor?: string;
  tabs: Tab[];
  /** A component to display before the tabs titles. */
  componentBeforeTitles?: React.ReactNode;
}

const selectTab = 'alt+1, alt+2, alt+3, alt+4, alt+5, alt+6, alt+7, alt+8, alt+9';

const Tabs = ({
  args,
  children,
  className,
  listView,
  queryParam = 'tab',
  onTabClick,
  selectedTab = 0,
  tabColor,
  tabs,
  componentBeforeTitles,
}: Props): React.JSX.Element => {
  const [selected, setSelected] = useState(0);
  const { hide, show, isHidden } = useHiddenTabs();
  const { mixpanel } = useMixpanel();
  const [searchParams, setSearchParams] = useSearchParams();

  const hotkeyLabel = isPlatform('mac') ? '⌥' : 'Ctrl';

  const queryTab = useSearchParams()[0].get(queryParam)?.toLowerCase();
  const tabNames = tabs.map((tab: Tab, idx) => {
    if (typeof tab.title === 'function') return tab.title(selected === idx).toLowerCase();
    return tab.title.toLowerCase();
  });
  const queryTabIndex = queryTab ? tabNames.indexOf(queryTab) : undefined;

  /* Allow to select tab from URL Query Param, using either the number of the tab or
  the name of the tab. If no Query Param exists, start with the first tab. `Layout` because
  we don't want the Tabs to flinch when navigating from the default to the chosen one.  */
  useLayoutEffect(() => {
    const queryToNumber = Number(queryTab);

    // If the Query Param is a number, select correspondent tab.
    if (typeof queryToNumber === 'number' && !Number.isNaN(queryToNumber)) {
      setSelected(queryToNumber);

      // If the Query Param is a string, select correspondent tab index.
    } else if (typeof queryTab === 'string' && queryTabIndex !== -1) {
      setSelected(queryTabIndex!);

      // If the Query Param is not a number or a known string, use the prop value.
    } else setSelected(selectedTab);
  }, [queryTab, selectedTab, queryTabIndex]);

  /* Guard against selecting non-existing indexes and consequently update the URL parameter. */
  useEffect(() => {
    if (selected >= tabs.length || isHidden(selected)) {
      const newSelected = selected - 1;

      setSelected(newSelected);
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), [queryParam]: tabNames[newSelected]! });
    }
  }, [isHidden, selected, tabs, queryTab, tabNames, queryParam, searchParams, setSearchParams]);

  const onHotkeySelect = (event: KeyboardEvent) => {
    event.preventDefault();
    const { code } = event;
    const digitPressed = Number(code.match(/^Digit(?<digit>\d)/)?.groups?.digit);

    if (!digitPressed) return;
    if (tabs.length < digitPressed) return;

    setSelected(digitPressed - 1);
    document.getElementById(`tab-${digitPressed - 1}`)?.focus();

    mixpanel.track('Output Tab: Switching tab using hotkey');
  };

  useHotkeys(selectTab, onHotkeySelect, [setSelected, tabs], {});

  return (
    <div className={className}>
      <div className={styles.tabsHeaderRow}>
        <ul className={styles.tabList} role="tablist">
          {componentBeforeTitles}
          {tabs.map(({ title, errorNames, key, tooltip }, idx) => {
            if (isHidden(idx)) return null;

            const tabTitle = typeof title === 'function' ? title(selected === idx) : title;

            return (
              <TabTitle
                key={`tab-${key}`}
                ariaLabel={tabTitle}
                title={tabTitle}
                errorNames={errorNames}
                selected={selected}
                idx={idx}
                onTabClick={onTabClick}
                select={setSelected}
                tooltip={tooltip || `${hotkeyLabel}+${idx + 1}`}
                listView={listView}
                tabColor={tabColor}
              />
            );
          })}
        </ul>
        {children}
      </div>

      {tabs.map(({ key, contentClassName, render, component: Component, title }, idx) => (
        <TabContents
          key={`content-${key}`}
          contentClassName={contentClassName}
          render={render}
          selectedTab={selectedTab}
          component={Component}
          title={typeof title === 'function' ? title(selected === idx) : title}
          idx={idx}
          hide={hide}
          show={show}
          selected={selected}
          isHidden={isHidden}
          args={args}
        />
      ))}
    </div>
  );
};

export default Tabs;
