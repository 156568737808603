import cx from 'classnames';

import { ChevronDown, Language } from 'frontend/assets/icons';
import { Icon, SelectLanguage } from 'frontend/components';
import type { LanguageOrVariant } from 'frontend/hooks/useLanguages';

import styles from './LanguagePicker.scss';

interface LanguagePickerProps {
  className?: string;
  languages: LanguageOrVariant[];
  /** If true, expect a mixed list of languages and variants (if any). Set it to false if it has to work only with base languages. */
  supportVariants?: boolean;
}

function LanguagePicker({ languages, className, supportVariants = true }: LanguagePickerProps) {
  return (
    <div className={cx(styles.languagePicker, className)}>
      <div className={styles.languageIcon}>
        <Icon component={Language} />
      </div>
      <SelectLanguage
        wrapperClassName={styles.languagePickerSelectorWrapper}
        languages={languages}
        supportVariants={supportVariants}
      />
      <div className={styles.selectIcon}>
        <Icon color="gray" component={ChevronDown} />
      </div>
    </div>
  );
}

export default LanguagePicker;
