import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { buildTypes } from 'frontend/constants';
import { useUploadCarouselImages } from 'frontend/hooks';
import { getUploadPath } from 'frontend/hooks/useUploadCarouselImages';
import { finalFormArraysMutatorsFix } from 'frontend/utils';

import SpecialDialogueFormContents from './SpecialDialogueFormContents';
import type { BuildFormType } from '../../propTypes/BuildFormType';
import { replyForm } from '../../validators';

const SUBSCRIPTION = {};

const mutators = finalFormArraysMutatorsFix(arrayMutators);

interface Props {
  buildFormType: BuildFormType;
  createdUrl?: string;
  selectedLanguage: string;
  currentRuleId?: string;
  hasChanges: boolean;
  initialValues: { [key: string]: any };
  onSubmit: (...args: unknown[]) => void;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

function SpecialDialogueForm({
  onSubmit,
  initialValues,
  selectedLanguage,
  currentRuleId,
  buildFormType,
  createdUrl,
  hasChanges,
  setHasChanges,
}: Props) {
  const { botId } = useParams();

  const onSubmitWithUpload = useUploadCarouselImages(onSubmit, {
    isModDialogue: false,
    uploadPath: getUploadPath(buildTypes.BOT, botId!),
  });

  const validate = replyForm(selectedLanguage, currentRuleId);

  return (
    <Form
      onSubmit={onSubmitWithUpload}
      mutators={mutators}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      validate={validate}
      subscription={SUBSCRIPTION}
      // @ts-expect-error React Final Form doesn't like the component, why?
      component={SpecialDialogueFormContents}
      selectedLanguage={selectedLanguage}
      currentRuleId={currentRuleId}
      buildFormType={buildFormType}
      createdUrl={createdUrl}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
    />
  );
}

export default SpecialDialogueForm;
