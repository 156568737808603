import { useParams } from 'react-router-dom';

import { AnalyticsContent, Grid } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import Filters, { FilterInfo } from 'frontend/features/Analytics/views/Filters';
import { useBooleanState } from 'frontend/hooks';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

import ConversionLineChart from './components/ConversionLineChart';
import getConversionUrl from './getConversionUrl';

const HIDE_FILTERS = { feedbacks: true, nudges: true, engagementCampaigns: true, sessionTypes: true };
const SKIP = Object.keys(HIDE_FILTERS);

const ConversionOverview = () => {
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const { botId } = useParams();
  const filters = useFilters({ skip: SKIP });
  const scope = sageScope(SAGE_RESOURCE.BOT, botId);

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} hideFilter={HIDE_FILTERS} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} />
        <div className="m-b-4">
          <Grid>
            <div>
              <ConversionLineChart
                title="Orders"
                subtitle={`Number of orders per ${filters.granularity}.`}
                scope={scope}
                filters={filters}
                event={ConversionLineChart.ACTION_TYPES.TOTAL_ORDERS}
                to={getConversionUrl(botId, 'total-orders')}
                isPreview
              />
            </div>
            <div>
              <ConversionLineChart
                title="Conversions"
                subtitle={`Number of conversions per ${filters.granularity}.`}
                scope={scope}
                filters={filters}
                event={ConversionLineChart.ACTION_TYPES.TOTAL_CONVERSIONS}
                to={getConversionUrl(botId, 'total-conversions')}
                isPreview
              />
            </div>
          </Grid>
          <Grid>
            <div>
              <ConversionLineChart
                title="Orders split data"
                subtitle={`Number of orders per ${filters.granularity} for each split.`}
                scope={scope}
                filters={filters}
                event={ConversionLineChart.ACTION_TYPES.ORDERS}
                to={getConversionUrl(botId, 'split-orders')}
                isPreview
              />
            </div>
            <div>
              <ConversionLineChart
                title="Conversions split data"
                subtitle={`Number of orders per ${filters.granularity} for each split.`}
                scope={scope}
                filters={filters}
                event={ConversionLineChart.ACTION_TYPES.CONVERSIONS}
                to={getConversionUrl(botId, 'split-conversions')}
                isPreview
              />
            </div>
          </Grid>
        </div>
      </AnalyticsContent>
    </>
  );
};

export default ConversionOverview;
