import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Input, LoaderSwitch, SelectWithSearch } from 'frontend/components';
import { required } from 'frontend/form/validators';
import { onEnter } from 'frontend/utils';

import styles from './EntitySearchAndReplace.scss';
import { EntityTag } from '../../components';
import { EntitiesType } from '../../propTypes';

const ChooseForm = ({ handleSubmit, entities, currentLanguage, loading }) => {
  const options = orderBy(entities, 'name')
    .filter(({ id, languageCode }) => id && languageCode === currentLanguage)
    .map(({ id, name, hue }) => ({ name, value: id, hue }));

  return (
    <LoaderSwitch loading={loading} size="medium">
      <Field
        className={styles.field}
        component={Input}
        name="replaceText"
        placeholder="Word to be replaced"
        onKeyDown={onEnter(handleSubmit)}
        validate={required}
        label="Word"
        autoFocus
      />
      <br />
      <Field
        component={SelectWithSearch}
        name="entityId"
        options={options}
        placeholder="Entity"
        entities={entities}
        renderOption={(optionProps, { name, hue }) => (
          <button type="button" {...optionProps} className={styles.option}>
            <EntityTag name={name} hue={hue} />
          </button>
        )}
        validate={required}
      />
    </LoaderSwitch>
  );
};

ChooseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  entities: EntitiesType.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ChooseForm;
