import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { UploadedContentFilesDocument } from 'frontend/api/generated';
import { UploadCloud } from 'frontend/assets/icons';
import { FileUpload } from 'frontend/components';
import type { ReadFile } from 'frontend/components/FileUpload/FileUpload';
import { useUpload } from 'frontend/features/Evaluation/hooks';

import styles from './ImportAction.scss';

const ImportAction = () => {
  const { botId } = useParams();
  const apolloClient = useApolloClient();
  const upload = useUpload(`api/v2/bot/${botId}/import-export/upload`);

  const onUpload: (files: ReadFile[]) => void = async (files) => {
    if (!files.length) return;

    const { source, file } = files[0]!; // asserting because we have early return above
    await upload(source, { name: file.name });
    await apolloClient.query({
      query: UploadedContentFilesDocument,
      fetchPolicy: 'network-only', // refresh cache
      variables: { botId: botId || '' },
    });
  };

  return (
    <div className={styles.action}>
      <FileUpload
        containerClassName={styles.uploadButton}
        text="Import content"
        accept="application/JSON"
        color="secondary"
        readAs="blob"
        onUpload={onUpload}
        icon={UploadCloud}
      />
    </div>
  );
};

export default ImportAction;
