import { useDndMonitor } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { useForm } from 'react-final-form';

import { hasSameId, indexList } from 'frontend/features/Build/utils';
import { getId } from 'frontend/utils';

import { getDraggableItem } from '../utils';

export default (draggableType, name, changePropertyName) => {
  const { change, getState } = useForm();
  const [sortingItemId, setSortingItemId] = useState();

  const onDragStart = useCallback(
    ({ active }) => {
      const item = getDraggableItem(active, draggableType);
      if (item) setSortingItemId(getId(item));
    },
    [draggableType],
  );

  const onDragEnd = useCallback(
    ({ active, over }) => {
      setSortingItemId();

      const activeItem = getDraggableItem(active, draggableType);
      const overItem = getDraggableItem(over, draggableType);
      if (!activeItem || !overItem) return;

      // Have to use 'get' instead of optional chaining because name might have '.' accessors
      const itemList = get(getState()?.values, name, []);

      const activeIndex = itemList.findIndex(hasSameId(activeItem));
      const overIndex = itemList.findIndex(hasSameId(overItem));
      if ([activeIndex, overIndex].includes(-1)) return;

      const updatedItemList = arrayMove(itemList, activeIndex, overIndex);
      change(name, indexList(updatedItemList, changePropertyName));
    },
    [change, draggableType, getState, name, changePropertyName],
  );

  useDndMonitor({ onDragStart, onDragEnd });

  return sortingItemId;
};
