import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Plus } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './FieldRow.scss';
import { FieldContext } from '../../context/FieldContext';
import type { FieldConfigFieldType, FieldsConfigType } from '../../utils/configs';
import { validators } from '../../utils/constants';
import Field from '../Field/Field';
import SortableFields, { SortableField } from '../SortableFields/SortableFields';

interface FieldRowProps {
  propertyKey: keyof FieldsConfigType;
  property: FieldConfigFieldType;
}

const FieldRow = ({ propertyKey, property }: FieldRowProps) => {
  const { baseFieldName, setActiveFieldInputs, field, activeFieldInputs } = useContext(FieldContext);

  const { change } = useForm();

  const renderFieldArray = (sortable = false) => (
    <FieldArray
      key={`f-a-${field.id}`}
      name={(
        property as { getFieldArrayName: ({ key, fieldName }: { key: string; fieldName: string }) => string }
      ).getFieldArrayName({ key: propertyKey, fieldName: baseFieldName })}
    >
      {({ fields: fieldArrayFields }) => {
        const { push, value, remove: removeFieldArrayField, length } = fieldArrayFields;

        return (
          <>
            {fieldArrayFields.map((name, index) =>
              sortable ? (
                <SortableField fieldIndex={index} fieldName={name} key={`so-${name}-${value[index]?.id}`}>
                  <Field
                    property={property}
                    propertyKey={propertyKey}
                    fieldIndex={index}
                    onFieldRemove={() => removeFieldArrayField(index)}
                    canHideRow={typeof property.canHide === 'function' ? property.canHide(length) : property.canHide}
                  />
                </SortableField>
              ) : (
                <Field
                  property={property}
                  propertyKey={propertyKey}
                  key={`so-${name}-${value[index]?.id}`}
                  fieldIndex={index}
                  onFieldRemove={() => removeFieldArrayField(index)}
                  canHideRow={typeof property.canHide === 'function' ? property.canHide(length) : property.canHide}
                />
              ),
            )}

            <Button
              className={styles.addMoreBtn}
              icon={Plus}
              color="white"
              onClick={() => {
                push(property.defaults?.());
              }}
            >
              {property.addMoreTitle}
            </Button>
          </>
        );
      }}
    </FieldArray>
  );

  const handleFieldHide = useCallback(() => {
    if (property.onRemove && property.canHide) {
      if (!validators.includes(propertyKey)) {
        const name = property.getName({
          key: propertyKey,
          fieldName: baseFieldName,
        });

        change(name, property.onRemove());
        if (property.groupWith && property.groupWith.length > 0) {
          property.groupWith.forEach((groupedProperty) => {
            if (groupedProperty.key && groupedProperty.getName && groupedProperty.onRemove) {
              const includeName = groupedProperty.getName({
                key: groupedProperty.key as string,
                fieldName: baseFieldName,
              }) as string;
              change(includeName, groupedProperty.onRemove());
            }
          });
        }
      } else {
        let remainingValidators = field.validators.filter((validator) => !validator[propertyKey]);
        if (property.groupWith.length > 0) {
          property.groupWith.forEach((included) => {
            remainingValidators = remainingValidators.filter((remainingValidator) => {
              if (included.key && Object.keys(remainingValidator).includes(included.key)) {
                return undefined;
              }
              return included;
            });
          });
        }

        change(`${baseFieldName}.validators`, remainingValidators);
      }
    }

    setActiveFieldInputs?.((currentActiveFieldInputs) => ({
      ...currentActiveFieldInputs,
      id: `temporary-uuid-${randomUUID()}`,
      [propertyKey]: !currentActiveFieldInputs[propertyKey],
    }));
  }, [baseFieldName, change, propertyKey, property, field, setActiveFieldInputs]);

  if (typeof activeFieldInputs[propertyKey] === 'boolean' && !activeFieldInputs[propertyKey] && property.canHide) {
    return null;
  }

  if (property.isFieldArray) {
    if (property.canSort) {
      return (
        <SortableFields
          fieldName={(
            property as { getFieldArrayName: ({ key, fieldName }: { key: string; fieldName: string }) => string }
          ).getFieldArrayName({ key: propertyKey, fieldName: baseFieldName })}
        >
          {renderFieldArray(true)}
        </SortableFields>
      );
    }

    return renderFieldArray();
  }

  return (
    <Field
      canHideRow={typeof property.canHide === 'function' ? property.canHide(1) : property.canHide}
      propertyKey={propertyKey}
      property={property}
      onFieldRemove={handleFieldHide}
    />
  );
};

export default FieldRow;
