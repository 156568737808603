import { gql } from '@apollo/client';

export default gql`
  query ViewConversationBots($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      viewConversationBots
    }
  }
`;
