import { useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import { GET_AVAILABLE_TIMEZONES } from 'frontend/api/queries';
import { Button, SelectWithSearch } from 'frontend/components';
import useTimezone from 'frontend/features/Analytics/hooks/useTimezone';
import { useUrlSearchParams } from 'frontend/hooks';

import styles from './TimezonePicker.scss';

const TimezonePicker = () => {
  const [, setParams] = useUrlSearchParams();
  const { data: timezones, loading } = useQuery(GET_AVAILABLE_TIMEZONES);
  const { timezone, loading: timezoneLoading, setTimezone: dispatchTimezone } = useTimezone();
  const [isClicked, setIsClicked] = useState(false);

  const handleOnBlur = useCallback(() => {
    setIsClicked(false);
  }, []);

  const handleOnClick = useCallback(() => {
    setIsClicked(true);
  }, []);

  const setTimezone = useCallback(
    (value) => {
      setParams({ tz: value });
      dispatchTimezone(value);
      handleOnBlur();
    },
    [setParams, handleOnBlur, dispatchTimezone],
  );

  return (
    <div className={styles.container}>
      {!loading && !timezoneLoading && isClicked && (
        <SelectWithSearch
          input={{
            name: 'tz',
            value: timezone,
            onChange: setTimezone,
            onBlur: handleOnBlur,
            onFocus: () => {},
          }}
          meta={{}}
          options={timezones.availableTimezones.map((tzItem) => ({ name: tzItem, value: tzItem }))}
        />
      )}
      {loading ||
        (!isClicked && (
          <div className={styles.selectedTimezone}>
            <Button flat onClick={handleOnClick} text={`Timezone: ${timezone}`} />
          </div>
        ))}
    </div>
  );
};

TimezonePicker.propTypes = {};

export default TimezonePicker;
