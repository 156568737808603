import { useSelector } from 'react-redux';

import { Metric } from 'frontend/components/Stats';
import { StatsPanel } from 'frontend/features/Analytics/components';
import { selectCurrentTraffic } from 'frontend/state/dux/analytics/dashboard';

const KindlyChatPresence = () => {
  const { chatbubbleActive } = useSelector(selectCurrentTraffic);

  return (
    <StatsPanel>
      <Metric metric={chatbubbleActive.length} bottomText="Open chats" />
    </StatsPanel>
  );
};

export default KindlyChatPresence;
