import { useQuery } from '@apollo/client';
import cx from 'classnames';
import type { ComponentType, SVGProps } from 'react';
import { Link, useParams } from 'react-router-dom';

import { BotImprovementsOverviewDocument } from 'frontend/api/generated';
import { EmojiSad, IntentSuggestion, LinkBreak, Send, SpeechMessage } from 'frontend/assets/icons';
import { Panel } from 'frontend/components';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';

import styles from './BotImprovementTasks.scss';
import dashboardStyles from '../../views/styles.scss';

const BotImprovementTask = ({
  iconComponent: IconComponent,
  title,
  count,
  link,
  iconColor,
  backgroundColorClass,
}: {
  iconComponent: ComponentType<SVGProps<SVGSVGElement>>;
  title: string;
  count: number;
  link: string;
  iconColor: string;
  backgroundColorClass?: string;
}) => (
  <Link className={styles.botImprovementsTasksListItem} to={link}>
    <div className={styles.botImprovementsTasksListItemIcon}>
      <div className={cx(styles.botImprovementsTasksIconWrapper, backgroundColorClass)}>
        <IconComponent color={iconColor} fill="white" width={16} height={16} />
      </div>
    </div>
    <div className={styles.botImprovementsTasksListItemTitle}>{title}</div>
    <div className={styles.botImprovementsTasksListItemCount}>{count}</div>
  </Link>
);

const BotImprovementTasks = () => {
  const { botId } = useParams();

  const { loading: botSettingsLoading, botSettings } = useBotSettings(botId);

  const { data, loading } = useQuery(BotImprovementsOverviewDocument, {
    variables: {
      botId: botId!,
    },
    skip: !botId,
  });

  if (loading || botSettingsLoading) {
    return null;
  }

  const total =
    (data?.sampleCandidatesOverview?.totalCount || 0) +
    (data?.dialogueConfusionOverview?.totalCount || 0) +
    (data?.incompleteDialoguesOverview?.totalCount || 0) +
    (data?.dialogueWithBrokenLinksOverview?.totalCount || 0) +
    (data?.dialogueCandidatesOverview?.totalCount || 0);

  const totalCount: string = total > 99 ? '99+' : String(total);

  return (
    <div>
      <div className={dashboardStyles.sectionHeader}>
        <h3>Bot improvements</h3>
      </div>
      <Panel className={styles.botImprovementTasksContainer}>
        <div className={styles.botImprovementsLeftColumn}>
          <div className={styles.botImprovementsTotalCount}>{totalCount}</div>
          <div className={styles.botImprovementsTotalInfo}>
            <h4 className={styles.botImprovementTasksTitle}>New bot improvement tasks</h4>
            <div className={styles.botImprovementTasksDescription}>
              Head over to our AI driven Bot Improvements to continue the great work developing your bot!
            </div>
          </div>
        </div>
        <div className={styles.botImprovementsRightColumn}>
          {!botSettings?.intentMatchEnabled ? (
            <>
              <BotImprovementTask
                iconComponent={Send}
                iconColor="white"
                backgroundColorClass={styles.botImprovementsTasksIconOrange}
                title="Sample candidates"
                count={data?.sampleCandidatesOverview?.totalCount || 0}
                link={`/workspace/${botId}/build/bot-improvements/sample-candidates`}
              />
              <BotImprovementTask
                iconComponent={SpeechMessage}
                iconColor="warning"
                backgroundColorClass={styles.botImprovementsTasksIconRed}
                title="Dialogue confusion"
                count={data?.dialogueConfusionOverview?.totalCount || 0}
                link={`/workspace/${botId}/build/bot-improvements/dialogue-confusion`}
              />
              <BotImprovementTask
                iconComponent={EmojiSad}
                iconColor="primary"
                backgroundColorClass={styles.botImprovementsTasksIconBlue}
                title="Incomplete dialogues"
                count={data?.incompleteDialoguesOverview?.totalCount || 0}
                link={`/workspace/${botId}/build/bot-improvements/incomplete-dialogues`}
              />
            </>
          ) : (
            <>
              <BotImprovementTask
                iconComponent={LinkBreak}
                iconColor="primary"
                title="Broken links"
                count={data?.dialogueWithBrokenLinksOverview?.totalCount || 0}
                link={`/workspace/${botId}/build/bot-improvements/broken-links`}
              />
              <BotImprovementTask
                iconComponent={IntentSuggestion}
                iconColor="primary"
                title="Dialogue candidates"
                count={data?.dialogueCandidatesOverview?.totalCount || 0}
                link={`/workspace/${botId}/build/bot-improvements/dialogue-candidates`}
              />
            </>
          )}
        </div>
      </Panel>
    </div>
  );
};

export default BotImprovementTasks;
