import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Field } from 'react-final-form';

import { Button, Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import styles from './styles.scss';

const APIKeyModal = ({ hide, args: { apiKey } }) => {
  const toast = useToast();
  return (
    <ModalForm title="API Key" onSubmit={hide} hide={hide} initialValues={{ apiKey }} footer={false}>
      <Field
        className={styles.key}
        readOnly
        component={Input}
        id="api-key"
        name="apiKey"
        placeholder="Your key"
        label="API Key"
        onClick={(e) => e.target.select()}
      />
      <br />
      <div className={styles.wrapButton}>
        <CopyToClipboard
          onCopy={() => {
            toast.success('Key copied to clipboard');
            hide();
          }}
          text={apiKey}
        >
          <Button color="primary">Copy key to clipboard</Button>
        </CopyToClipboard>
      </div>
    </ModalForm>
  );
};

APIKeyModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({ apiKey: PropTypes.string.isRequired }).isRequired,
};

export default APIKeyModal;
