import { useQuery } from '@apollo/client';
import { noop } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { Button, LoaderSwitch, Search } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import { getArray } from 'frontend/utils';

import EmptyState from './EmptyState';
import styles from './Entities.scss';
import SubscriptionEntities from './SubscriptionEntities';
import EntityList from './components/EntityList';
import { useEntities, useRelevantSearchResults } from './hooks';
import { CreateEntity } from './modals';
import { SUBSCRIPTIONS_WITH_ENTITIES } from './queries';
import { EntitySearch } from './utils';

const Entities = () => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const botOrSkillParams = useBotOrSkill();
  const { isBot, botId, buildIdObject } = botOrSkillParams;
  const [searchQuery, setSearchQuery] = useState('');
  const [showCreateEntityModal] = useModal(CreateEntity);
  const [searchResults, setSearchResults] = useState(null);

  const { entities, loading: entitiesLoading } = useEntities({ buildIdObject, currentLanguage });
  const { data: subscriptionsData, loading: subscriptionsLoading } = useQuery(SUBSCRIPTIONS_WITH_ENTITIES, {
    variables: { botId },
    skip: !isBot,
  });

  const subscriptionsWithEntities = getArray('subscriptionsWithEntities', subscriptionsData);
  const someSubscribedSkillHasEntities = subscriptionsWithEntities.some(({ skill }) => skill.entities.length > 0);

  const allEntities = useMemo(
    () => [...entities, ...subscriptionsWithEntities.map(({ skill }) => getArray('entities', skill)).flat()],
    [entities, subscriptionsWithEntities],
  );

  const entitiesFromSelf = useRelevantSearchResults(entities, searchResults);

  const onEscape = useCallback(
    (inputRef) => {
      if (searchQuery) setSearchQuery('');
      else if (inputRef) inputRef.current.blur();
    },
    [searchQuery],
  );

  const input = useMemo(() => ({ value: searchQuery, onChange: setSearchQuery, name: 'entitySearch' }), [searchQuery]);

  const openCreateModal = useCallback(() => {
    showCreateEntityModal({ name: searchQuery, botOrSkillParams });
  }, [botOrSkillParams, searchQuery, showCreateEntityModal]);

  return (
    <LoaderSwitch loading={entitiesLoading || subscriptionsLoading} size="large">
      <div className={styles.search}>
        <Search
          input={input}
          searchItems={allEntities}
          setSearchResults={setSearchResults}
          onSubmit={searchQuery.trim() ? openCreateModal : noop}
          wrapper={EntitySearch.wrapper}
          searchOptions={EntitySearch.options}
          onEscape={onEscape}
          placeholder="Find or create entity"
        />
        <Button onClick={openCreateModal} color="primary">
          Create
        </Button>
      </div>
      {entitiesFromSelf.length === 0 && !someSubscribedSkillHasEntities && <EmptyState />}
      <EntityList
        entities={entitiesFromSelf}
        noEntities={someSubscribedSkillHasEntities ? 'None' : ''}
        withProperties
        keepOrder={Boolean(searchResults)}
      />
      {subscriptionsWithEntities.map(({ id, skill }) => (
        <SubscriptionEntities key={`entity-subscription-${id}`} skill={skill} searchResults={searchResults} />
      ))}
    </LoaderSwitch>
  );
};

export default Entities;
