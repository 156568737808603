import { useForm, useFormState } from 'react-final-form';

import { useLabels } from 'frontend/features/Inbox/hooks';
import LabelsSection from 'frontend/features/Labels/LabelsSection';
import type { Label } from 'frontend/propTypes/LabelType';

import styles from './LabelsFilter.scss';

const LabelsFilter = () => {
  const labelsArray = useLabels();
  const { change } = useForm();
  const { values } = useFormState({ subscription: { values: true } });

  const handleLabelToggle = (label: Label) => {
    if (!values.labelFilter?.includes(label.id)) {
      change('labelFilter', [...(values.labelFilter || []), label.id]);
    } else {
      const labels = [...values.labelFilter];
      labels.splice(labels.indexOf(label.id), 1);
      change('labelFilter', labels);
    }
  };

  return (
    <LabelsSection
      className={styles.labelFilterWrapper}
      activeLabelsIds={values.labelFilter || []}
      botLabels={labelsArray}
      canEditLabels={false}
      handleLabelToggle={handleLabelToggle}
      inputPlaceholder="Search"
    />
  );
};

export default LabelsFilter;
