import { addDays, format } from 'date-fns';
import PropTypes from 'prop-types';

import { DATE_FORMAT_DATE } from 'frontend/utils/date';

const ArchiverContent = ({ args: { createdAt, info } }) => {
  const timeToArchive = addDays(createdAt, info.days);
  return (
    <>
      This workspace will be archived on {format(timeToArchive, DATE_FORMAT_DATE)}.
      <br />
      <br />
      To avoid this, have a chat with the bot!
    </>
  );
};

ArchiverContent.propTypes = {
  args: PropTypes.shape({
    info: PropTypes.shape({}).isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default ArchiverContent;
