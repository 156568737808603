import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useForm } from 'react-final-form';

import { useModal } from 'frontend/features/Modals';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import Options from './Options';
import { RemoveEntityFromDialogue } from '../../modals';
import { EntityType } from '../../propTypes';

const OptionsWithDeleteFromDialogue = ({
  entity,
  areSkillEntities,
  withProperties,
  hideDelete,
  isModDialogue,
  botOrSkillParams,
}) => {
  const form = useForm();
  const [showRemoveModal] = useModal(RemoveEntityFromDialogue);

  const extraActions = useMemo(
    () => [
      {
        title: 'Remove from this dialogue',
        key: 'entity-option-delete-from-dialogue',
        onClick: () => showRemoveModal({ entity, isModDialogue, form }),
      },
    ],
    [entity, form, isModDialogue, showRemoveModal],
  );

  return (
    <Options
      entity={entity}
      areSkillEntities={areSkillEntities}
      withProperties={withProperties}
      hideDelete={hideDelete}
      extraActions={extraActions}
      botOrSkillParams={botOrSkillParams}
    />
  );
};

OptionsWithDeleteFromDialogue.propTypes = {
  areSkillEntities: PropTypes.bool,
  withProperties: PropTypes.bool,
  entity: EntityType.isRequired,
  hideDelete: PropTypes.bool,
  isModDialogue: PropTypes.bool,
  botOrSkillParams: BotOrSkillParamsType.isRequired,
};

export default OptionsWithDeleteFromDialogue;
