import arrayMutators from 'final-form-arrays';
import { isEqual, orderBy, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { Input, LoaderSwitch, SelectLanguage } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { useLanguages } from 'frontend/hooks';
import { BotOrSkillParamsType } from 'frontend/propTypes';
import { capitalize, onEnter } from 'frontend/utils';

import styles from './EntityModal.scss';
import { OPERATIONS } from './constants';
import EntityItemsInput from '../../components/EntityItemsInput';
import { useEntities } from '../../hooks';
import { entityName } from '../../validators';

const EntityModal = ({
  onSubmit,
  hide,
  id = null,
  initialName = '',
  initialItems = [],
  operation,
  hue = null,
  currentLanguage,
  newEntityItems,
  setNewEntityItems,
  botOrSkillParams,
}) => {
  const { languages, loading: languagesLoading } = useLanguages(botOrSkillParams);
  const { entities, loading: entitiesLoading } = useEntities({ ...botOrSkillParams, currentLanguage });
  const initialValues = useMemo(
    () => ({ name: initialName, items: orderBy(initialItems) }),
    [initialItems, initialName],
  );

  const renderForm = useCallback(
    ({ handleSubmit, form }) => {
      const otherEntities = entities.filter(({ id: entityId }) => entityId && id !== entityId);
      const addItems = (newItems) => form.change('items', uniq([...initialItems, ...newItems]));

      return (
        <LoaderSwitch loading={languagesLoading || entitiesLoading} size="medium">
          {operation === OPERATIONS.CREATE && languages && languages.length > 1 && (
            <SelectLanguage languages={languages} guardChanges={false} supportVariants={false} />
          )}
          <div className={styles.entityFormFieldsContainer}>
            <Field
              className={styles.field}
              component={Input}
              name="name"
              placeholder="Name"
              aria-label="Name"
              onKeyDown={onEnter(handleSubmit)}
              validate={entityName(otherEntities)}
              autoFocus
            />
            <EntityItemsInput
              className={styles.field}
              currentLanguage={currentLanguage}
              hue={hue}
              addItems={addItems}
              isInModal
              autoFocus={false}
              newEntityItems={newEntityItems}
              setNewEntityItems={setNewEntityItems}
              buildIdObject={botOrSkillParams.buildIdObject}
            />
          </div>
        </LoaderSwitch>
      );
    },
    [
      botOrSkillParams,
      currentLanguage,
      entities,
      entitiesLoading,
      hue,
      id,
      initialItems,
      languages,
      languagesLoading,
      newEntityItems,
      operation,
      setNewEntityItems,
    ],
  );

  return (
    <ModalForm
      title={`${capitalize(operation)} entity`}
      onOkText={capitalize(operation)}
      onSubmit={onSubmit}
      hide={hide}
      render={renderForm}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      guardUnsaved
    />
  );
};

EntityModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  operation: PropTypes.oneOf(Object.values(OPERATIONS)).isRequired,
  id: PropTypes.string,
  currentLanguage: PropTypes.string.isRequired,
  initialName: PropTypes.string,
  hue: PropTypes.number,
  initialItems: PropTypes.arrayOf(PropTypes.string),
  newEntityItems: PropTypes.string.isRequired,
  setNewEntityItems: PropTypes.func.isRequired,
  botOrSkillParams: BotOrSkillParamsType.isRequired,
};

export default EntityModal;
