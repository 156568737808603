export const welcomePageTypes = Object.freeze({
  NONE: 'NONE',
  COLOR: 'COLOR',
  PICTURE: 'PICTURE',
});

export const bubbleSizeTypes = Object.freeze({
  SMALL: 'SMALL',
  NORMAL: 'NORMAL',
});
