import { useEffect } from 'react';

const setStyles = (reset?: boolean) => {
  window.document.body.style.height = reset ? '' : '100%';
  window.document.documentElement.style.height = reset ? '' : '100%';
  const appElement = window.document.getElementById('app');
  if (appElement) {
    appElement.style.height = reset ? '' : '100%';
    appElement.style.display = reset ? '' : 'flex';
    appElement.style.flexDirection = reset ? '' : 'column';
  }
  const mainElements = window.document.getElementsByTagName('main');
  [...(mainElements || [])].forEach((element) => {
    element.style.flex = reset ? '' : '1';
    element.style.display = reset ? '' : 'flex';
    element.style.flexDirection = reset ? '' : 'column';
  });
};

// Ideally those styles should be applied for all pages, not just the insights
// But some pages seems to act differently when applied
// So for now we apply only to insights, and eventually we can revisit
export default () => {
  useEffect(() => {
    setStyles();
    return () => {
      setStyles(true);
    };
  }, []);
};
