import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useToast } from 'frontend/hooks';

import PlatformAlertForm from '../PlatformAlertForm';
import { CREATE_PLATFORM_ALERT } from '../mutations';

const initialValues = {
  published: true,
};

const CreatePlatformAlert = () => {
  const [createPlatformAlertMutation] = useMutation(CREATE_PLATFORM_ALERT);
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (values) => {
      await createPlatformAlertMutation({
        variables: { platformAlertInput: values },
      });
      toast.success('Platform alert created');
      navigate('/admin/platform-alerts');
    },
    [navigate, toast, createPlatformAlertMutation],
  );

  return <PlatformAlertForm onSubmit={onSubmit} initialValues={initialValues} />;
};
export default CreatePlatformAlert;
