import { PublicClientApplication } from '@azure/msal-browser';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import AzureLogo from 'frontend/assets/icons-unsync/MicrosoftLogo.svg';
import { Button } from 'frontend/components';

import styles from './Login.scss';

// Config object to be passed to Msal on creation
const msalConfig = {
  auth: {
    clientId: window.env.MICROSOFT_SSO_CLIENT_ID || '',
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: `${window.env.SITE_URL}/oauth/complete`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
  prompt: 'select_account',
};

const AzureLoginButton = ({ onAzureResponse, text = 'Sign in with Azure AD' }) => {
  const handleLogin = useCallback(async () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);
      onAzureResponse(loginResponse);
    } catch (error) {
      onAzureResponse({ error });
    }
  }, [onAzureResponse]);

  return (
    <Button className={styles.ssoButton} onClick={handleLogin} icon={AzureLogo}>
      <span>{text}</span>
    </Button>
  );
};

AzureLoginButton.propTypes = {
  onAzureResponse: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default AzureLoginButton;
