import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';

import type { UserType } from 'frontend/api/generated';
import { Agent } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import type { AnyRole } from 'frontend/features/Organization/types';

import Summary from './components/Summary/Summary';
import UserRole from './components/UserRole/UserRole';
import styles from './styles.scss';
import { roleIdToFieldName } from './utils';

const STEPS = Object.freeze({ SELECT_ROLES: 1, SUMMARY: 2 });

function UpdateMemberModal({
  hide,
  name,
  email,
  currentRoles,
  users,
  roles,
  onSubmit: finalSubmit,
  isSubmitting,
  allPermissions,
  disallowOrganizationRoles,
  organizationId,
  userId,
}: {
  hide: (...args: unknown[]) => Promise<void>;
  name: string;
  email: string;
  currentRoles: AnyRole[];
  users: UserType[];
  roles: AnyRole[];
  allPermissions: string[];
  onSubmit: (...args: unknown[]) => unknown;
  isSubmitting?: boolean;
  disallowOrganizationRoles: boolean;
  organizationId: string;
  userId?: string;
}) {
  const [page, setPage] = useState<number>(STEPS.SELECT_ROLES);
  const modalTitle = {
    1: 'Which roles should this member have?',
    2: 'Make sure everything looks good before completing!',
  }[page];

  const next = useCallback(() => setPage((current: number) => current + 1), []);
  const back = useCallback(() => setPage((current: number) => current - 1), []);

  const onSubmit = useCallback(
    (...args) => {
      if (page === STEPS.SELECT_ROLES) next();
      if (page === STEPS.SUMMARY) finalSubmit(...args);
    },
    [finalSubmit, next, page],
  );

  const initialValues = useMemo(
    () => ({
      email,
      ...roles.reduce(
        (selectedRoles, { id }) => ({
          ...selectedRoles,
          [roleIdToFieldName(id)]: Boolean(currentRoles.find(({ id: existingRoleId }) => existingRoleId === id)),
        }),
        {},
      ),
    }),
    [email, currentRoles, roles],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscribe={{ valid: true, dirty: true }}
      render={({ handleSubmit, valid, dirty }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Modal
            title={modalTitle}
            icon={Agent}
            hide={hide}
            className={styles.modal}
            footer={false}
            valid={valid}
            disabled={!dirty}
          >
            {page === STEPS.SELECT_ROLES && (
              <UserRole
                users={users}
                roles={roles}
                allPermissions={allPermissions}
                disallowOrganizationRoles={disallowOrganizationRoles}
                organizationId={organizationId}
                userId={userId}
              />
            )}
            {page === STEPS.SUMMARY && <Summary users={users} name={name} roles={roles} />}
            <Modal.Footer className={styles.footer}>
              <div className={styles.paginationContainer}>
                <span className={cx(styles.pagination, { [styles.paginationActive]: page >= STEPS.SELECT_ROLES })} />
                <span className={cx(styles.pagination, { [styles.paginationActive]: page >= STEPS.SUMMARY })} />
              </div>
              <div className={styles.actions}>
                {page > STEPS.SELECT_ROLES && <Button text="Back" onClick={back} flat className="m-r-2" />}
                <Button
                  text={page === STEPS.SUMMARY ? 'Update' : 'Next'}
                  onClick={handleSubmit}
                  isSubmitting={isSubmitting}
                  color={page === STEPS.SUMMARY ? 'primary' : 'secondary'}
                />
              </div>
            </Modal.Footer>
          </Modal>
        </form>
      )}
    />
  );
}

export default UpdateMemberModal;
