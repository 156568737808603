import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

import { publishingTypes } from 'frontend/constants';

import { getPublishingMode } from '../api/queries';

export default function useDraftEnabled() {
  const { botId } = useParams();
  const { data } = useQuery(getPublishingMode, { variables: { botId }, skip: !botId });
  const publishingMode = get(data, 'bot.publishingMode', {});

  const draftText = ['Save draft', 'Saved'];
  const draftEnabled = publishingMode === publishingTypes.MANUAL.value;

  return { draftEnabled, draftText };
}
