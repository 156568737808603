import cx from 'classnames';
import PropTypes from 'prop-types';

import { Tag } from 'frontend/components';
import { SIZES } from 'frontend/components/Tag/constants';
import { EntityTag } from 'frontend/features/Entities/components';
import { EntityType } from 'frontend/features/Entities/propTypes';
import { ChildrenType } from 'frontend/propTypes';

import styles from './SlotTag.scss';

const SlotTag = ({ children, name, size, detail, lessInfo, removeTag, slotEntity, transparent, className }) => {
  if (slotEntity) {
    const { hue, items, name: entityName } = slotEntity;
    return (
      <EntityTag
        name={entityName}
        hue={hue}
        items={items}
        lessInfo={lessInfo}
        removeTag={removeTag}
        transparent={transparent}
        size={size}
      >
        {children}
      </EntityTag>
    );
  }

  return (
    <Tag
      className={cx(className, styles.slotTag)}
      name={name}
      size={size}
      tooltipText={lessInfo ? undefined : detail}
      removeTag={removeTag}
      transparent={transparent}
    >
      {children}
    </Tag>
  );
};

SlotTag.propTypes = {
  children: ChildrenType,
  name: PropTypes.string,
  lessInfo: PropTypes.bool,
  transparent: PropTypes.bool,
  detail: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  slotEntity: PropTypes.oneOfType([EntityType]),
  removeTag: PropTypes.func,
  className: PropTypes.string,
};

export default SlotTag;
