import { gql } from '@apollo/client';

export const GetAllSlugs = gql`
  query GetAllSlugs($botId: ID, $skillId: ID) {
    dialogues(botId: $botId, skillId: $skillId, dialogueType: "TRIGGER") {
      id
      slug
    }
  }
`;
