import cx from 'classnames';
import { useMemo } from 'react';

import styles from './ButtonClicks.scss';

type Props = {
  data: { ratio: number; count: number; className?: string };
};
const PercentageBar = ({ data: { ratio, count, className } }: Props) => {
  const colorbarStyle = useMemo(() => ({ width: `${ratio * 100}%` }), [ratio]);
  const backgroundbarStyle = useMemo(() => ({ width: `${(1 - ratio) * 100}%` }), [ratio]);

  return (
    <div className={cx(styles.percentageBar, className)}>
      <div className={styles.counts}>
        <span>{Math.round(ratio * 100)} %</span>
        <span>({count})</span>
      </div>
      <div className={styles.bar}>
        <div style={colorbarStyle} />
        <div style={backgroundbarStyle} />
      </div>
    </div>
  );
};

export default PercentageBar;
