import { format } from 'date-fns';

import type { DialogueCandidateType } from 'frontend/api/generated';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

const UpdatedAt = ({ rowData }: { rowData: DialogueCandidateType }) => (
  // <div>{new Intl.DateTimeFormat('en-GB').format(new Date(rowData.updatedAt))}</div>
  <div>{format(rowData.updatedAt, DATE_FORMAT_DATE)}</div>
);

export default UpdatedAt;
