import cx from 'classnames';
import { useState } from 'react';

import { DotsVertical } from 'frontend/assets/icons';
import defaultLogo from 'frontend/assets/images/organization_avatar.svg?url';
import { DropdownButton, Icon } from 'frontend/components';

import styles from './styles.scss';

type Option = {
  title: string;
  key: string;
  onClick: () => void;
};

type Row = {
  name?: string;
  element?: React.ReactNode;
  image?: string;
  rightElement?: React.ReactNode;
  options?: Option[];
  className?: string;
  fallbackDefaultImage?: boolean;
  key?: string;
};

interface Props {
  title: Row;
  rows: Row[];
  maximumRows?: number;
  type?: string;
  loading: boolean;
  className?: string;
}

const Card = ({ title, rows, maximumRows, type, loading, className }: Props) => {
  const [showAll, setShowAll] = useState(false);

  const visibleRows = (() => {
    if (showAll) {
      return rows;
    }
    if (maximumRows) {
      return rows.slice(0, maximumRows);
    }
    return rows;
  })();

  return (
    <div className={cx(styles.card, className)}>
      {!loading && (
        <>
          <div className={styles.header}>
            {title.fallbackDefaultImage && !title.image && (
              <img alt={`${title.name} logo`} className={styles.image} src={defaultLogo} />
            )}
            {title.image && (
              <img
                alt={`${title.name} logo`}
                className={styles.image}
                src={title.image}
                onError={(e) => {
                  const imgElement = e.target as HTMLImageElement;
                  imgElement.src = defaultLogo;
                }}
              />
            )}
            <h4 className={styles.title}>{title.name}</h4>
            {title.rightElement}
          </div>
          {rows.map((row, index) => (
            <div
              className={cx(styles.row, {
                [styles.rowVisible]: index < visibleRows.length,
                [styles.rowHasOption]: row.options,
                [row.className || '']: row.className,
              })}
              key={row.key || row.name || index}
              aria-hidden={index >= visibleRows.length}
            >
              {row.fallbackDefaultImage && !row.image && (
                <img alt={`${row.name} logo`} className={styles.image} src={defaultLogo} />
              )}
              {row.image && (
                <img
                  alt={`${row.name} logo`}
                  className={styles.image}
                  src={row.image}
                  onError={(e) => {
                    const imgElement = e.target as HTMLImageElement;
                    imgElement.src = defaultLogo;
                  }}
                />
              )}
              {row.name && <div className={styles.name}>{row.name}</div>}
              {row.element}
              {row.rightElement}
              {row.options && (
                <DropdownButton
                  wrapperClassName={styles.dropdownWrapper}
                  className={styles.dropdown}
                  actions={row.options}
                >
                  <Icon component={DotsVertical} />
                </DropdownButton>
              )}
            </div>
          ))}
          {maximumRows && rows.length > maximumRows && (
            <button
              className={styles.showAll}
              type="button"
              onClick={() => setShowAll((currentShowAll) => !currentShowAll)}
            >
              Show {showAll ? 'less' : 'all'}
              {type && ` ${type}`}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Card;
