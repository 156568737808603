import { ExternalLink } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';

import styles from './MessageReferences.scss';
import type { DedupedMessageReferences } from '../../utils/messageReferences';

interface MessageReferencesProps {
  references: DedupedMessageReferences[];
}

function MessageReferences({ references }: MessageReferencesProps) {
  return (
    <div className={styles.container}>
      <span className={styles.title}>Reference to reply</span>
      <div className={styles.referenceList}>
        {references.map(({ id, url }, index) => (
          <Button
            className={styles.reference}
            color="transparent"
            size="small"
            key={id}
            href={url}
            rel="noreferrer"
            target="_blank"
          >
            <sup className={styles.sup}>{index + 1}</sup>
            <span className={styles.referenceText}>Read more</span>
            <Icon width={12} height={12} component={ExternalLink} />
          </Button>
        ))}
      </div>
    </div>
  );
}

export default MessageReferences;
