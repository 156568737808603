import ROLE_NAMES from './ROLE_NAMES';

export default Object.freeze([
  ROLE_NAMES.ORGANIZATION_ADMIN,
  ROLE_NAMES.BOT_ADMIN,
  ROLE_NAMES.BOT_BUILDER,
  ROLE_NAMES.INBOX_AGENT,
  ROLE_NAMES.NUDGE_ADMIN,
  ROLE_NAMES.MEMBER,
]);
