import { useCallback, useRef } from 'react';
import InputAutosize from 'react-input-autosize';

import { EVENT_KEYS } from 'frontend/constants';

import EntityItemTag from '../EntityItemTag';

interface Props {
  input: {
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
  };
  remove: (idx: number) => void;
  hue?: number;
  idx: number;
  className?: string;
}

export default function Item({ input: { value, onChange }, remove, hue, idx, className }: Props) {
  const inputRef = useRef<HTMLInputElement>();

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === EVENT_KEYS.ENTER) inputRef?.current?.blur?.();
  };

  const removeItem = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      remove(idx);
    },
    [idx, remove],
  );

  return (
    <div>
      <EntityItemTag remove={removeItem} hue={hue} className={className}>
        <InputAutosize value={value} onChange={onChange} ref={inputRef} onKeyDown={onKeyDown} tabIndex={-1} />
      </EntityItemTag>
    </div>
  );
}
