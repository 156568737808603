import { AGENT_VIEW_FILTERS } from 'frontend/state/dux/inbox';

const myTicketsPredicate =
  (userId) =>
  ({ assignedAgent } = {}) =>
    userId && assignedAgent?.id?.toString() === userId.toString();

const unassignedTicketsPredicate = ({ assignedAgent } = {}) => !assignedAgent;

const ticketStatusPredicate =
  (statuses) =>
  ({ status }) =>
    statuses.includes(status);

export default ({ ticketAgentFilter, ticketStatusFilter }, userId) => {
  const predicates = [];

  if (ticketAgentFilter === AGENT_VIEW_FILTERS.MY_TICKETS) predicates.push(myTicketsPredicate(userId));
  else if (ticketAgentFilter === AGENT_VIEW_FILTERS.UNASSIGNED) predicates.push(unassignedTicketsPredicate);
  if (ticketStatusFilter?.length > 0) predicates.push(ticketStatusPredicate(ticketStatusFilter));

  return predicates;
};
