import { ErrorBoundary } from 'frontend/components';
import type { Row } from 'frontend/components/Table/Table';
import BotActivity from 'frontend/features/Organization/components/BotCard/BotActivity';

import styles from '../styles.scss';

export const ActivityCell = ({ rowData: workspace }: { rowData?: Row }) => {
  if (!workspace || !workspace.id) return null;
  return (
    <ErrorBoundary errorComponent={null}>
      <div className={styles.botActivity}>
        <BotActivity botId={String(workspace.id)} />
      </div>
    </ErrorBoundary>
  );
};
