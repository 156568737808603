import { useApolloClient, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { cacheSkillSubscription } from 'frontend/api/cacheHelpers';
import { REMOVE_SUBSCRIPTION } from 'frontend/api/mutations';
import { operations } from 'frontend/constants';
import { DeleteModal } from 'frontend/features/Modals';

const UnsubscribeSkill = ({ hide, args: { botId, skillId, subscriptionId } }) => {
  const [mutate] = useMutation(REMOVE_SUBSCRIPTION);
  const client = useApolloClient();
  const onSubmit = useCallback(async () => {
    await mutate({ variables: { skillId, botId } });
    cacheSkillSubscription(operations.REMOVE, subscriptionId, { client, botId, skillId });
  }, [botId, client, mutate, skillId, subscriptionId]);

  return (
    <DeleteModal onSubmit={onSubmit} hide={hide} action="remove" name="skill">
      Are you sure you want to remove this skill? All changes made to the skill in this bot will be lost.
    </DeleteModal>
  );
};

UnsubscribeSkill.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    botId: PropTypes.string.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    skillId: PropTypes.string.isRequired,
  }).isRequired,
};

export default UnsubscribeSkill;
