import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { getArray, hasLanguage } from 'frontend/utils';

import { ENTITIES } from '../queries';

export default ({ buildIdObject, currentLanguage }) => {
  const { data, loading } = useQuery(ENTITIES, { variables: buildIdObject });
  const entities = useMemo(
    () => getArray('entities', data).filter(hasLanguage(currentLanguage)),
    [currentLanguage, data],
  );

  return { entities, loading };
};
