import { useMemo } from 'react';

import { useBotOrSkill } from 'frontend/hooks';

import useEntities from './useEntities';
import useKindlyEntities from './useKindlyEntities';

export default ({ currentLanguage }) => {
  const { buildIdObject } = useBotOrSkill();

  const { entities, loading: entitiesLoading } = useEntities({ currentLanguage, buildIdObject });
  const { kindlyEntities, loading: kindlyEntitiesLoading } = useKindlyEntities({ currentLanguage, buildIdObject });
  const loading = entitiesLoading || kindlyEntitiesLoading;
  const allEntities = useMemo(() => [...kindlyEntities, ...entities], [entities, kindlyEntities]);

  const extra = useMemo(
    () => ({ currentLanguage, buildIdObject, entities: allEntities, loading }),
    [allEntities, buildIdObject, currentLanguage, loading],
  );

  return { extra, allEntities };
};
