import { gql } from '@apollo/client';

export default gql`
  fragment DialogueWithEntities on DialogueType {
    id
    title
    url(botId: $botId)
    samples {
      id
      instances {
        id
        entity {
          id
        }
        kindlyEntity {
          id
        }
      }
    }
  }
`;
