import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { InfoButton, Radio } from 'frontend/components';
import InfoModal from 'frontend/components/InfoModal/InfoModal';
import type { ButtonWithDialogueLink } from 'frontend/features/Build/components/Buttons/types';
import { DRAGGABLE_TYPES } from 'frontend/features/Dnd/constants';
import { useFormSort } from 'frontend/features/Dnd/hooks';
import { useModal } from 'frontend/features/Modals';
import { usePrevious } from 'frontend/hooks';
import { getId } from 'frontend/utils';
import hasRule from 'frontend/utils/hasRule';
import moveArrayItem from 'frontend/utils/moveArrayItem';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './Buttons.scss';
import buildStyles from '../../Build.scss';
import { BUTTON_TYPES } from '../../constants';
import { indexList } from '../../utils';
import ButtonForm from '../ButtonForm';
import EditableButton from '../EditableButton';

type FormValues = {
  id: string;
  buttons?: ButtonWithDialogueLink[];
  modButtons?: ButtonWithDialogueLink[];
};

type Props = {
  args: {
    currentLanguage: string;
    currentRuleId: string | null;
    isModDialogue: boolean;
    initialValues: FormValues;
  };
};

const Buttons = ({
  args: {
    currentLanguage,
    currentRuleId,
    isModDialogue,
    initialValues: { id: ownerId },
  },
}: Props) => {
  const [editingButton, setEditingButton] = useState<ButtonWithDialogueLink | undefined>();
  const prevOwnerId = usePrevious(ownerId);
  const prevLanguage = usePrevious(currentLanguage);

  const [showInfoModal] = useModal(InfoModal);

  const { change, getState } = useForm<FormValues>();

  const { submitting: disabled, values } = useFormState<FormValues>();
  const buttonsFormName = isModDialogue ? 'modButtons' : 'buttons';
  const buttons = values?.[buttonsFormName] ?? [];
  const sortingButtonId = useFormSort(DRAGGABLE_TYPES.BUTTON, buttonsFormName);

  const editingButtonIndex = buttons.findIndex((button) => getId(button) === getId(editingButton));

  const createButton = (newButtonFields: Pick<ButtonWithDialogueLink, 'label' | 'buttonType' | 'isActive'>) => {
    const currentButtons = getState()?.values?.[buttonsFormName] ?? [];
    const tempId = randomUUID();
    const newButton: ButtonWithDialogueLink = {
      ...newButtonFields,
      languageCode: currentLanguage,
      rule: currentRuleId
        ? {
            id: currentRuleId,
          }
        : undefined,
      id: `temp-${tempId}`,
      ...(newButtonFields?.label && { label: newButtonFields.label.trim() }),
      __typename: DRAGGABLE_TYPES.BUTTON,
    };

    change(buttonsFormName, [...currentButtons, newButton]);
  };

  const removeButtonById = (id: string) => {
    const currentButtons = getState()?.values?.[buttonsFormName] ?? [];
    const updatedButtons = currentButtons.filter((button) => getId(button) !== id);
    change(buttonsFormName, indexList(updatedButtons));
  };

  const buttonsByRule = buttons.filter(hasRule(currentRuleId));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const copyFormButtons = structuredClone(buttons);
      const copyButtonsByRule = structuredClone(buttonsByRule);

      const oldIndex = copyButtonsByRule.findIndex((button) => button.id === active.id);
      const newIndex = copyButtonsByRule.findIndex((button) => button.id === over.id);
      moveArrayItem(copyButtonsByRule, oldIndex, newIndex);

      // find in form buttons the buttons of the current rule and remove them, then add them again (updated) at the end and in the right order
      copyButtonsByRule.forEach((button) => {
        const index = copyFormButtons.findIndex((formButton) => formButton.id === button.id);
        copyFormButtons.splice(index, 1);
      });
      const newFormButtons = copyFormButtons.concat(copyButtonsByRule);

      change(buttonsFormName, newFormButtons);
    }
  };

  useEffect(() => {
    if (ownerId !== prevOwnerId || currentLanguage !== prevLanguage) {
      setEditingButton(undefined);
    }
  }, [currentLanguage, ownerId, prevLanguage, prevOwnerId, setEditingButton]);

  return (
    <>
      <h3 className={buildStyles.sectionTitle}>Buttons</h3>
      <Field
        // @ts-expect-error ButtonForm types are wrong
        component={ButtonForm}
        name={editingButton ? `${buttonsFormName}[${editingButtonIndex}]` : 'newButton'}
        isNew={!editingButton}
        onEditFinished={setEditingButton}
        create={createButton}
        currentLanguage={currentLanguage}
        currentRuleId={currentRuleId}
        ownerId={ownerId}
      />
      {buttonsByRule.length > 0 && (
        <>
          <div className={buildStyles.buildContent}>
            <h3 className={buildStyles.sectionTitle}>Buttons in this dialogue</h3>
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={buttons}>
                <div className={styles.buttonListWrapper}>
                  {buttonsByRule.map((button) => (
                    <EditableButton
                      key={`button-${button.id || button.tempId}`}
                      button={button}
                      setEditingButton={setEditingButton}
                      removeButtonById={removeButtonById}
                      isEditing={getId(editingButton) === getId(button)}
                      isSorting={sortingButtonId === getId(button)}
                      disabled={disabled}
                      strikeThrough={
                        button.buttonType === BUTTON_TYPES.DIALOGUE_TRIGGER &&
                        !button.dialogueLink?.dialogue.isActive[currentLanguage]
                      }
                    />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          </div>
          {!isModDialogue && (
            <div className={buildStyles.buildContent}>
              <h3 className={buildStyles.sectionTitle}>
                Buttons display option{' '}
                <InfoButton
                  onClick={() =>
                    showInfoModal({
                      title: 'About button display option',
                      text: 'This option let you decide how to display buttons for this dialogue. Horizontal works great for buttons with short and snappy text, like products. Vertical is better for buttons the require more text.',
                    })
                  }
                />
              </h3>
              <Field
                name="buttonsDisplayOrientation"
                type="radio"
                value="HORIZONTAL"
                label="Horizontal"
                component={Radio}
              />
              <Field
                name="buttonsDisplayOrientation"
                type="radio"
                value="VERTICAL"
                label="Vertical"
                component={Radio}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Buttons;
