import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from '../Evaluation.scss';

const findLanguageName = (languages, code) => {
  const language = languages.find((lang) => lang.code === code.toLowerCase());
  return language?.name ?? code;
};

const EvaluationSetList = ({ evaluations, folderId, availableLanguages, simple = false }) =>
  evaluations.map((evaluation) => {
    const to = (
      simple ? `simple/${evaluation.id}` : `${evaluation.id}${evaluation.totalFinishedReports > 0 ? '/reports' : ''}`
    ).replace('//', '/');

    return (
      <Link key={evaluation.id} className={styles.listItem} to={to}>
        {evaluation.title}
        <div className={styles.labelWrap}>
          {simple && <div className={cx(styles.label, 'm-r-sm')}>{evaluation.state}</div>}
          <div className={styles.label} title={findLanguageName(availableLanguages, evaluation.languageCode)}>
            {evaluation.languageCode}
          </div>
        </div>
        {folderId && (
          <Link to={`${evaluation.id}/${folderId}`} className={styles.link}>
            Create queries for folder
          </Link>
        )}
      </Link>
    );
  });

export default EvaluationSetList;
