import type { Item } from 'frontend/constants/itemTypes';
import type { Subset } from 'frontend/utils/TS';

export const BUILD_FORM_TYPES: readonly Subset<Item, 'dialogue' | 'system-dialogue' | 'greeting' | 'fallback'>[] = [
  'greeting',
  'fallback',
  'system-dialogue',
  'dialogue',
] as const;
export type BuildFormType = (typeof BUILD_FORM_TYPES)[number];
