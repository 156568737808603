import { useMutation } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import avatar from 'frontend/assets/images/avatar.svg?url';
import { Button, CenteredComponent, Loader, PageContent } from 'frontend/components';
import ButtonStyles from 'frontend/components/Button/Button.scss';
import { useModal } from 'frontend/features/Modals';
import { PUBLISH_SKILL_MUTATION } from 'frontend/features/PublishSkill/PUBLISH_SKILL_MUTATION';
import { useTabTitle } from 'frontend/hooks';

import styles from './PublishSkill.scss';
import UnpublishSkillModal from './components/UnpublishSkillModal';

const PublishedSkill = ({ skill }) => {
  const linkButtonStyles = cx(styles.linkButton, ButtonStyles.btn, ButtonStyles.btnDefault, ButtonStyles.btnMedium);
  const [showUnpublishSkillModal] = useModal(UnpublishSkillModal);
  return (
    <PageContent>
      <CenteredComponent>
        <div style={{ textAlign: 'center' }}>
          <img className="m-b-md" src={avatar} alt="Publish your skill" />
          <h2 className="m-b-md">Skill is published</h2>
          <h4 className="m-b-md">Share the landing page with workspace owners to start connecting bots!</h4>
          <Link className={linkButtonStyles} to={`/skills/${skill.landingPageSlug}`} target="_blank">
            Go to landing page
          </Link>
          <Button onClick={() => showUnpublishSkillModal({ skillId: skill.id })} flat>
            Unpublish
          </Button>
        </div>
      </CenteredComponent>
    </PageContent>
  );
};

PublishedSkill.propTypes = {
  skill: PropTypes.shape({ landingPageSlug: PropTypes.string, id: PropTypes.string }).isRequired,
};

const PublishSkill = () => {
  const { skillId } = useParams();

  const { skillData, skillLoading } = useTabTitle('Publish skill');
  const [mutate] = useMutation(PUBLISH_SKILL_MUTATION);
  const publishSkill = async () => {
    await mutate({
      variables: {
        skillId,
      },
    });
  };

  if (skillLoading) {
    return <Loader size="large" />;
  }

  const { skill } = skillData;
  if (skill.landingPageActive) {
    return <PublishedSkill skill={skill} />;
  }
  return (
    <PageContent>
      <CenteredComponent>
        <div style={{ textAlign: 'center' }}>
          <img className="m-b-md" src={avatar} alt="Publish your skill" />
          <h2 className="m-b-md">Your skill is only visible to you</h2>
          <h4 className="m-b-lg">Publishing your skill will let bots subscribe to it!</h4>
          <Button onClick={publishSkill} color="primary">
            Publish now
          </Button>
        </div>
      </CenteredComponent>
    </PageContent>
  );
};
export default PublishSkill;
