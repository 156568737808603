import { useApolloClient, useQuery } from '@apollo/client';
import cx from 'classnames';
import { maxBy, merge, minBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { Button, Loader } from 'frontend/components';
import { hasSameId } from 'frontend/features/Build/utils';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useBooleanState, useBotOrSkill } from 'frontend/hooks';

import styles from './ActivityLog.scss';
import EmptyState from './EmptyState';
import { ACTIVITY_GROUPS, SOME_DIALOGUE } from '../../queries';
import ActivityGroup from '../ActivityGroup';

const NUMBER_OF_GROUPS = 50;

const getGroups = (data) => data?.activityGroups?.groups ?? [];

const getHasMore = (data) => data?.activityGroups?.hasMore ?? false;

const mergeData = (prevData, newData, updatedBefore) => {
  const isUpdatingOlder = !!updatedBefore;
  const newHasMore = isUpdatingOlder ? getHasMore(newData) : getHasMore(prevData);
  const newGroups = isUpdatingOlder
    ? [...getGroups(prevData), ...getGroups(newData)]
    : [...getGroups(newData), ...getGroups(prevData)];

  return merge({}, newData, { activityGroups: { groups: newGroups, hasMore: newHasMore } });
};

// Could not make the update work with cache.modify or typePolicies
const updateCache = async ({ data, client, variables, updatedAfter, updatedBefore }) => {
  const result = await client.query({
    query: ACTIVITY_GROUPS,
    variables: { ...variables, updatedAfter, updatedBefore },
  });

  const updatedData = mergeData(data, result.data, updatedBefore);

  client.cache.writeQuery({ query: ACTIVITY_GROUPS, variables, data: updatedData });
};

const getUpdatedDate = ({ updatedAt }) => new Date(updatedAt);

const ActivityLog = () => {
  const client = useApolloClient();
  const { buildIdObject, buildType } = useBotOrSkill();
  const [hasNewer, setHasNewer, unsetHasNewer] = useBooleanState();
  const pusherChannel = getPusherChannel(buildIdObject);
  const { data: someDialogueData } = useQuery(SOME_DIALOGUE, { variables: buildIdObject });
  const someDialogueId = someDialogueData?.someDialogue?.id;

  const variables = useMemo(() => ({ ...buildIdObject, limit: NUMBER_OF_GROUPS }), [buildIdObject]);
  const { data, loading, error } = useQuery(ACTIVITY_GROUPS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const groups = useMemo(() => getGroups(data), [data]);
  const groupIsInView = useCallback((id) => !!groups.find(hasSameId({ id })), [groups]);
  const hasMore = getHasMore(data);

  const getOlder = useCallback(async () => {
    const oldestGroup = minBy(getGroups(data), getUpdatedDate);
    updateCache({ data, client, variables, updatedBefore: oldestGroup?.updatedAt });
  }, [client, data, variables]);

  const getNewer = useCallback(async () => {
    const newestGroup = maxBy(getGroups(data), getUpdatedDate);
    updateCache({ data, client, variables, updatedAfter: newestGroup?.updatedAt });
    unsetHasNewer();
  }, [client, data, unsetHasNewer, variables]);

  usePusherEvent(pusherChannel, 'build-activity-group-created', setHasNewer);

  if (loading || error) {
    return (
      <>
        <div className="m-t-3" />
        <br />
        <Loader size="large" type="activity_log" />
      </>
    );
  }

  return (
    <div className={cx('m-t-3', styles.listContainer)}>
      {hasNewer && (
        <Button size="small" onClick={getNewer} className={styles.loadNewerButton}>
          Load newer
        </Button>
      )}
      <br />
      <br />
      {groups.length === 0 ? (
        <EmptyState someDialogueId={someDialogueId} buildType={buildType} />
      ) : (
        groups.map((group) => (
          <ActivityGroup
            key={`activity-group-${group.id}`}
            activityGroup={group}
            buildIdObject={buildIdObject}
            pusherChannel={pusherChannel}
            groupIsInView={groupIsInView}
            setHasNewer={setHasNewer}
          />
        ))
      )}
      {hasMore && (
        <Button size="small" onClick={getOlder} className="m-t-2">
          Load older
        </Button>
      )}
    </div>
  );
};

export default ActivityLog;
