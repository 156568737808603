import { EditorState, Modifier } from 'draft-js';

import { HIGHLIGHT } from '../constants';
import { checkCharacterForTags, createSelectionState } from '../utils';

const updateContentWithTags = (content, updateFunction, tagTypes) => {
  let updatedContent;

  const checkCharacter = checkCharacterForTags(tagTypes, content);

  content.getBlockMap().forEach((contentBlock) => {
    const callback = (start, end) => {
      updatedContent = updateFunction(updatedContent || content, contentBlock, start, end);
    };

    contentBlock.findEntityRanges(checkCharacter, callback);
  });

  return updatedContent || content;
};

export default (state) => {
  const content = state.getCurrentContent();
  const initialSelection = state.getSelection();
  const tagTypes = [HIGHLIGHT];

  const updateFunction = (updatedContent, contentBlock, start, end) => {
    const selection = createSelectionState({ anchorKey: contentBlock.getKey(), start, end });
    return Modifier.applyEntity(content, selection, null);
  };

  const contentWithoutHighlightTag = updateContentWithTags(content, updateFunction, tagTypes);

  if (contentWithoutHighlightTag === content) return state;

  const stateWithoutHighlightTag = EditorState.push(state, contentWithoutHighlightTag, 'apply-entity');
  return EditorState.forceSelection(stateWithoutHighlightTag, initialSelection);
};
