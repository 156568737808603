import { LoaderSwitch, Panel, SelectLanguage } from 'frontend/components';
import { KindlyEntities } from 'frontend/features/Entities';
import { useBotOrSkill, useCurrentLanguage, useLanguages } from 'frontend/hooks';

import styles from '../../../General/styles.scss';

const Entities = () => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const botOrSkillParams = useBotOrSkill();
  const { languages, loading } = useLanguages(botOrSkillParams);

  return (
    <Panel>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Entities</h3>
      <LoaderSwitch loading={loading}>
        {languages && languages.length > 1 && (
          <div className={styles.selectLanguageContainer}>
            <SelectLanguage languages={languages} guardChanges={false} supportVariants={false} />
            <br />
            <br />
          </div>
        )}
        <KindlyEntities currentLanguage={currentLanguage} />
      </LoaderSwitch>
    </Panel>
  );
};

export default Entities;
