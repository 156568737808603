import { useApolloClient, useMutation } from '@apollo/client';
import { cloneDeep, merge } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { CHAT_META, CHAT_TICKET } from 'frontend/features/Inbox/queries';
import { useToast } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';

import useAssignUserToTicket from './useAssignUserToTicket';
import useSubmitMutation from './useSubmitMutation';
import { CREATE_TICKET, END_HANDOVER, START_HANDOVER } from '../mutations';

export default ({ botId, chatId, ticket }) => {
  const toast = useToast();
  const { data: dataMe } = useMe();
  const [isSubmitting, setIsSubmitting] = useState();
  const client = useApolloClient();
  const variables = useMemo(() => ({ botId, chatId }), [botId, chatId]);
  const { assignUser, userAssigned } = useAssignUserToTicket({ ticket, agent: dataMe?.me });

  const update = useCallback(
    (_, { data }) => {
      client.writeQuery({
        query: CHAT_TICKET,
        variables: { chatId, botId },
        data: { ticketForChat: data.createTicket },
      });
      toast.success('Ticket created!');
      setIsSubmitting(false);
    },
    [chatId, client, toast, botId],
  );

  const updateHandover = useCallback(
    (cache, value, assignMe) => {
      const dataUpdate = { chatAndBot: { chat: { takenOver: value } } };
      const data = merge({}, cloneDeep(cache.readQuery({ query: CHAT_META, variables })), dataUpdate);
      cache.writeQuery({ query: CHAT_META, variables, data });
      // Assign self if starting handover
      if (ticket?.id && assignMe && !userAssigned) {
        assignUser();
      }
      setIsSubmitting(false);
    },
    [variables, assignUser, ticket?.id, userAssigned],
  );

  const [startHandoverMutation] = useMutation(START_HANDOVER, { update: (cache) => updateHandover(cache, true, true) });
  const startHandoverFunction = useCallback(
    async (languageCode) => {
      setIsSubmitting(true);
      try {
        await startHandoverMutation({ variables: { ...variables, languageCode } });
      } catch (err) {
        console.error(err);
        setIsSubmitting(false);
      }
    },
    [startHandoverMutation, variables],
  );

  const endHandoverFunction = useSubmitMutation({
    mutation: END_HANDOVER,
    setIsSubmitting,
    variables,
    update: (cache) => updateHandover(cache, false, false),
  });

  const createTicketFunction = useSubmitMutation({ mutation: CREATE_TICKET, setIsSubmitting, variables, update });

  return {
    isSubmitting,
    startHandover: startHandoverFunction,
    endHandover: endHandoverFunction,
    createTicket: createTicketFunction,
  };
};
