import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';

import { Exclamation } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';

import styles from './Replies.scss';

const identity = (x) => x;

const normalizeItem = (item = '') => item.trim().toLowerCase();

const overlappingEntity =
  ({ items: items1 = [] } = {}) =>
  ({ items: items2 = [] } = {}) => {
    const normalizedItems2 = items2.map(normalizeItem);

    return items1.find((item) => normalizedItems2.includes(normalizeItem(item)));
  };

const getEntities = (currentLanguage, buildItems, initial = []) =>
  buildItems.reduce((result, { instances, languageCode }) => {
    if (languageCode !== currentLanguage) {
      return result;
    }
    const entities = (instances || []).map(({ slot, entity } = {}) => entity || slot?.entity).filter(identity);

    return [...result, ...entities];
  }, initial);

const overlappingReplyEntities = (currentLanguage, replies = [], samples = []) => {
  const replyEntities = uniqBy(getEntities(currentLanguage, replies), 'id');

  // Reply entities followed by sample only entities
  const dialogueEntities = uniqBy(getEntities(currentLanguage, samples, replyEntities), 'id');

  // Use a loop with slicing and early stop to reduce number of iterations
  // eslint-disable-next-line no-restricted-syntax
  for (const [idx, replyEntity] of replyEntities.entries()) {
    const otherEntity = dialogueEntities.slice(idx + 1).find(overlappingEntity(replyEntity));
    if (otherEntity) {
      return { replyEntity, otherEntity };
    }
  }

  return null;
};

const getWarningText = ({ replyEntity, otherEntity }) =>
  `The reply entity "${replyEntity.name}" overlaps with the entity "${otherEntity.name}". This may lead to issues interpreting user messages.`;

const SlotsWarning = ({ currentLanguage }) => {
  const isFeatureEnabled = useFeatureFlags();
  const { values } = useFormState();
  const [slotsWarning, setSlotsWarning] = useState('');

  const slotsEnabled = isFeatureEnabled('slots');

  useEffect(() => {
    if (!slotsEnabled) {
      return;
    }

    const overlapping = overlappingReplyEntities(currentLanguage, values.replies, values.samples);

    if (overlapping) {
      setSlotsWarning(getWarningText(overlapping));
    } else {
      setSlotsWarning('');
    }
  }, [currentLanguage, slotsEnabled, values.replies, values.samples]);

  if (!slotsEnabled || !slotsWarning) {
    return null;
  }

  return (
    <div className={styles.slotsWarning}>
      <Icon component={Exclamation} color="support" className={styles.slotsWarningIcon} />
      {slotsWarning}
    </div>
  );
};

SlotsWarning.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
};

export default SlotsWarning;
