import { gql } from '@apollo/client';

export default gql`
  query SkillSearch($skillId: ID!, $query: String!, $languageCode: String!) {
    skill(id: $skillId) {
      id
      search(query: $query, languageCode: $languageCode) {
        id
        title
        dialogueType
        isActive
        samplesCount
        numberOfFollowups
        url
      }
    }
  }
`;
