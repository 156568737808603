const DANGEROUS_PATTERNS = [
  '(.)',
  '(.+)',
  '(.*)',
  '(?s).',
  '(?s).+',
  '(?s).*',
  '([\\s\\S])',
  '([\\s\\S]+)',
  '([\\s\\S]*)',
  '(.?)',
  '(.+?)',
  '(.*?)',
  '([\\w\\W])',
  '([\\w\\W]+)',
  '([\\w\\W]*)',
  '([\\d\\D])',
  '([\\d\\D]+)',
  '([\\d\\D]*)',
  '([^\n])',
  '([^\n]+)',
  '([^\n]*)',
  '([a]|[^a])',
  '([a]|[^a]+)',
  '([a]|[^a]*)',
];

export default (parentId) => (value) =>
  parentId == null && DANGEROUS_PATTERNS.includes(value)
    ? `The pattern ${value} will capture anything and can only be used in a followup`
    : undefined;
