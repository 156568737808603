import { addSeconds, formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';

import { Range } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';

const RunEvaluation = ({ hide, args: { run, confidenceThreshold, estimateRunTimeSeconds } }) => {
  const onSubmit = (values) => {
    run(values.confidenceThreshold);
  };
  const initialValues = {
    confidenceThreshold,
  };
  return (
    <ModalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      title="Create evaluation report"
      onOkText="Run"
      hide={hide}
      ignoreDirty
    >
      <Range
        label="Specify confidence threshold:"
        name="confidenceThreshold"
        min={0}
        max={1}
        step={0.01}
        displayValue={(value) => value * 100}
        unit="%"
        displayFixedDecimals={0}
      />
      <p>
        You will have your results ready in roughly{' '}
        {formatDistanceToNow(addSeconds(new Date(), estimateRunTimeSeconds))}
      </p>
    </ModalForm>
  );
};

RunEvaluation.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    estimateRunTimeSeconds: PropTypes.number.isRequired,
    confidenceThreshold: PropTypes.number.isRequired,
    run: PropTypes.func.isRequired,
  }).isRequired,
};

export default RunEvaluation;
