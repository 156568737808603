import { useMemo, useState } from 'react';

const useInput = (name) => {
  const [value, setValue] = useState('');
  const input = useMemo(
    () => ({
      name,
      value,
      onChange: (evt) => setValue(evt.target.value),
    }),
    [name, value],
  );
  return input;
};

export default useInput;
