import { useCallback, useEffect, useRef, useState } from 'react';

export default () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMountedRef = useRef(true);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  const submitWrapper = useCallback(
    (onSubmit, { updateWhenFinished } = { updateWhenFinished: true }) =>
      async (...params) => {
        setIsSubmitting(true);
        try {
          await onSubmit(...params);
          if (updateWhenFinished && isMountedRef.current) {
            setIsSubmitting(false);
          }
        } catch (e) {
          if (updateWhenFinished && isMountedRef.current) {
            setIsSubmitting(false);
          }
        }
      },
    [setIsSubmitting],
  );

  return [isSubmitting, submitWrapper] as [
    boolean,
    (onSubmit: any, { updateWhenFinished }?: any) => (...params: any[]) => Promise<void>,
  ];
};
