import { gql } from '@apollo/client';

import { REPLY_SLOT } from 'frontend/features/Slots/fragments';

export const Reply = gql`
  fragment Reply on ReplyType {
    id
    createdAt
    instances {
      ...ReplySlot
    }
    languageCode
    rule {
      id
    }
    text
    updatedAt
  }
  ${REPLY_SLOT}
`;
