import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Menu from './Menu';

const staticMenuItems = [
  {
    name: 'Admin',
    slug: 'admin',
    path: '/admin/*',
    className: 'kindlyAdminManager',
    submenu: [
      { name: 'Platform dashboard', slug: 'dashboard', subNavigation: true },
      { name: 'Platform analytics', slug: 'analytics', subNavigation: true },
      { name: 'Members', slug: 'members', subNavigation: true },
      { name: 'Groups', slug: 'groups', subNavigation: true },
      {
        name: 'Organizations',
        slug: 'organizations',
        subNavigation: true,
      },
      { name: 'Workspaces', slug: 'workspaces', subNavigation: true },
      { name: 'Skills', slug: 'skills', subNavigation: true },
      { name: 'Changelog', slug: 'changelog', subNavigation: true },
      { name: 'Platform alerts', slug: 'platform-alerts', subNavigation: true },
      { name: 'Plugins', slug: 'plugins', subNavigation: true },
      { name: 'Connectors', slug: 'connectors', subNavigation: true },
      { name: 'Orphans', slug: 'orphans', subNavigation: true },
      { name: 'Activity feed', slug: 'activity', subNavigation: true },
    ],
  },
];

const AdminMenu = ({ rootItems }) => {
  const menuItems = useMemo(() => [...(rootItems ?? []), ...staticMenuItems], [rootItems]);
  return <Menu menuItems={menuItems} />;
};
AdminMenu.propTypes = {
  rootItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      path: PropTypes.string,
      className: PropTypes.string,
      submenu: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          subNavigation: PropTypes.bool,
        }),
      ),
    }),
  ),
};

export default AdminMenu;
