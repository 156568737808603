import cx from 'classnames';
import { noop } from 'lodash';
import { useCallback, useMemo } from 'react';

import styles from './DropdownButton.scss';
import DropdownMenu from './DropdownMenu';
import type { ButtonProps } from '../Button/Button';
import Dropdown, { type DropdownPosition } from '../Dropdown/Dropdown';

export type DropdownAction = {
  title?: string;
  key: string;
  onClick?: () => void;
  className?: string;
  separator?: boolean;
  hide?: boolean;
  icon?: React.ReactNode;
};

interface Props {
  className?: string;
  wrapperClassName?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  position?: DropdownPosition;
  elementProps?: ButtonProps;
  element?: React.ElementType;
  title?: string;
  isHoverMode?: boolean;
  onClick?: () => void;
  vertical?: boolean;
  'data-testid'?: string;
  onToggle?: (isShown: boolean) => void;
  actions?: DropdownAction[];
  isDisabled?: boolean;
  triggerClassName?: string;
}

const DropdownButton = ({
  className,
  wrapperClassName,
  contentClassName,
  children,
  position,
  actions = [],
  onClick = noop,
  vertical = false,
  elementProps,
  element,
  title,
  isHoverMode,
  'data-testid': dataTestid,
  onToggle = noop,
  isDisabled,
  triggerClassName,
}: Props) => {
  const visibleActions = useMemo(() => actions.filter(({ hide }) => !hide), [actions]);

  // Pass the overlay DropdownMenu as a render function instead of an element to stay performant
  const renderOverlay = useCallback(
    ({ close }) => <DropdownMenu actions={visibleActions} close={close} />,
    [visibleActions],
  );

  if (visibleActions.length < 1) return null;

  return (
    <Dropdown
      overlay={renderOverlay}
      data-testid={dataTestid}
      position={position}
      overlayClassName={contentClassName}
      wrapperClassName={wrapperClassName}
      onClick={onClick}
      element={element}
      elementProps={elementProps}
      title={title}
      isHoverMode={isHoverMode}
      onToggle={onToggle}
      isDisabled={isDisabled}
      triggerClassName={triggerClassName}
    >
      {typeof element === 'function'
        ? null
        : children || (
            <div className={cx(styles.options, className)}>
              <span className={cx(styles.optionsButton, { [styles.optionsButtonVertical]: vertical })} />
            </div>
          )}
    </Dropdown>
  );
};

export default DropdownButton;
