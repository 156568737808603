import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Plus } from 'frontend/assets/icons';
import { Button, LoaderSwitch, PageContent, Pagination, Search } from 'frontend/components';
import { buildTypes } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';
import { CreateBotLikeModal } from 'frontend/features/Organization/modals';
import { usePagination, useTabTitle } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './styles.scss';
import { EmptyBotSkillState, SkillsList } from '../../components';
import { useSkills } from '../../hooks';

const SKILL_PAGE_SIZE = 10;

const Skills = () => {
  const { organizationId } = useParams();
  const { skills, loading } = useSkills();
  const { organizationPermissions } = useMyPermissions({ organizationId });
  const canCreateSkills = organizationPermissions.includes('create_skills');
  const [showCreateBotLikeModal] = useModal(CreateBotLikeModal);
  const [searchResults, setSearchResults] = useState(skills);

  const hasNoSkills = skills.length === 0;
  const noSkillsFound = !hasNoSkills && searchResults.length === 0;
  const skillsFound = !hasNoSkills && searchResults.length > 0;

  const [currentSkills, skillPage, skillPages, setSkillPage] = usePagination({
    objects: searchResults,
    pageSize: SKILL_PAGE_SIZE,
  });

  useEffect(() => {
    if ((skillPage - 1) * SKILL_PAGE_SIZE > searchResults.length) {
      setSkillPage(1);
    }
  }, [skillPage, searchResults.length, setSkillPage]);

  useTabTitle('Skills');

  return (
    <LoaderSwitch size="large" loading={loading}>
      <PageContent>
        <div className={styles.skillsMenu}>
          <div className={styles.inputContainer}>
            <Search
              searchItems={skills}
              searchKey="name"
              setSearchResults={setSearchResults}
              placeholder="Search"
              aria-label="Search skills"
              autoFocus
              icon
            />
          </div>
          {canCreateSkills && (
            <Button
              onClick={() => showCreateBotLikeModal({ organizationId, buildType: buildTypes.SKILL })}
              color="primary"
              icon={Plus}
              text="Create skill"
              className="m-r-sm"
            />
          )}
        </div>
        {skillsFound > 0 && (
          <>
            <div className={styles.listHeader}>
              <span>Skills</span>
              <span>Subscribers</span>
              <span>Dialogues</span>
            </div>
            <SkillsList skills={currentSkills} loading={loading} />
          </>
        )}
        {skillsFound && (
          <div className={styles.pagination}>
            <Pagination currentPage={skillPage} pages={skillPages} setPage={setSkillPage} />
          </div>
        )}
        {hasNoSkills && <EmptyBotSkillState type="skill" />}
        {noSkillsFound && <h2 className={styles.noResult}>No match found</h2>}
      </PageContent>
    </LoaderSwitch>
  );
};

export default Skills;
