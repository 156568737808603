import cx from 'classnames';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { SampleWithEntities } from 'frontend/features/Entities/components';
import { selectSorting, sortingTypes } from 'frontend/state/dux/build';

import styles from './ExpandedSamples.scss';
import { SampleType } from '../../propTypes';
import { sortByText } from '../../utils';

const ExpandedSamples = ({ expandedSamples, expandedSampleCount }) => {
  const sorting = useSelector(selectSorting);
  const sorter = sorting === sortingTypes.ALPHABETICAL ? sortByText : identity;
  return (
    <div
      className={cx(styles.sampleList, {
        [styles.sampleListTruncated]: expandedSampleCount > expandedSamples.length,
      })}
    >
      {sorter(expandedSamples).map(({ id: sampleId, text, instances }) => (
        <div className={styles.sampleItem} key={`expanded-sample:${sampleId}-${text}`}>
          <SampleWithEntities instances={instances.filter(({ id, kindlyEntity }) => id && kindlyEntity)} text={text} />
        </div>
      ))}
    </div>
  );
};

ExpandedSamples.propTypes = {
  expandedSamples: PropTypes.arrayOf(SampleType).isRequired,
  expandedSampleCount: PropTypes.number.isRequired,
};

export default ExpandedSamples;
