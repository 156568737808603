import { Alert, Nudge, Plus, Tickets, Wink } from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';

import styles from './AddDialoguesButton.scss';

function getOptions(): React.ComponentProps<typeof Dropdown.MenuOverlay>['options'] {
  return [
    {
      text: 'Nudge',
      icon: Nudge,
      to: 'proactivity/nudge',
    },
    {
      text: 'Global icon',
      icon: Tickets,
      to: 'proactivity/global-icons',
    },
    {
      text: 'Banner message',
      icon: Alert,
      to: 'proactivity/banner-message',
    },
    {
      text: 'Push greeting',
      icon: Wink,
      to: 'proactivity/push-greetings',
    },
  ];
}

const AddProactivityButton = () => {
  const options = getOptions();
  return (
    <div className={styles.publishButtonsWrapper}>
      <div className={styles.publishButtons}>
        <Dropdown
          position="bottom-left"
          triggerClassName={styles.dropdownTrigger}
          overlay={<Dropdown.MenuOverlay options={options} />}
          title="Create new proactivity"
        >
          <Icon component={Plus} className={styles.plusIcon} />
        </Dropdown>
      </div>
    </div>
  );
};

export default AddProactivityButton;
