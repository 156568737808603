import { gql } from '@apollo/client';

export default gql`
  query OrganizationBots($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      bots {
        id
        name
        projectName
        avatarUrl
        isPinned
      }
    }
  }
`;
