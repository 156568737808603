import { gql } from '@apollo/client';

export default gql`
  fragment SkillSettings on SkillType {
    id
    name
    descriptionHeadline
    permissions
    kindlyEntitiesEnabled
    languages {
      id
      name
      code
    }
  }
`;
