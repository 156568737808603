import { gql } from '@apollo/client';

export default gql`
  fragment KindlyEntity on KindlyEntityType {
    id
    name
    description
    restricted
    category
    languageCode
  }
`;
