import { PageContent, Panel } from 'frontend/components';

import styles from './Support.scss';

const Support = () => (
  <PageContent>
    <Panel className={styles.panel}>
      <h1 className={styles.title}>Support</h1>
      <p>If you discovered a bug or have any questions or comments, we will be glad to hear from you.</p>
      <p>
        To create a support ticket, send us an email at:{' '}
        <a className={styles.link} href="mailto:support@kindly.ai">
          support@kindly.ai
        </a>
      </p>
      <p>We would appreciate if you followed these guidelines when writing to us:</p>
      <ul>
        <li>Descriptive subject</li>
        <li>A detailed explanation about the case</li>
      </ul>
      <p>If it’s a bug or an issue</p>
      <ul>
        <li>Include workspace ID or a link (The workspace ID is the number you find the the URL) </li>
        <li>Include which operating system and browser you are using</li>
      </ul>
    </Panel>
  </PageContent>
);

export default Support;
