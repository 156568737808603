import { useMutation } from '@apollo/client';
import { captureEvent } from '@sentry/browser';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RUN_EVALUATION } from '../graphql';

const useRunReport = ({ evaluationId }) => {
  const { botId } = useParams();
  const [isRunning, setIsRunning] = useState(false);
  const [mutate] = useMutation(RUN_EVALUATION);
  const run = useCallback(
    async (threshold) => {
      setIsRunning(true);
      try {
        await mutate({
          variables: {
            botId,
            evaluationId,
            nlpOverrides: { threshold: parseFloat(threshold) },
          },
        });
      } catch (e) {
        const errorEvent = {
          message: 'Could not run evaluation',
          extra: { error: e.message, botId, evaluationId },
        };
        console.error(errorEvent);
        captureEvent(errorEvent);
        setIsRunning(false);
      }
    },
    [botId, mutate, evaluationId],
  );
  return { run, isRunning, setIsRunning };
};
export default useRunReport;
