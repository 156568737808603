import { sum, upperFirst, zip } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { LineChart } from 'frontend/components/Stats';
import palette from 'frontend/components/Stats/Palette';
import { StatsPanel } from 'frontend/features/Analytics/components/';
import { useSageData } from 'frontend/features/Analytics/hooks';
import { sageSeriesToLineData } from 'frontend/features/Analytics/utils/sage';
import { formatNumber } from 'frontend/utils';

export const title = 'Messages and button clicks';
export const subtitle = 'Number of user messages and button clicks';

const Interactions = ({ filters, to, scope, priority, isPreview }) => {
  const {
    loading: messagesLoading,
    error: messagesError,
    data: messagesData,
  } = useSageData(scope, '/messages', filters, {
    priority,
    apiVersion: 'v2',
  });
  const {
    loading: buttonsLoading,
    error: buttonsError,
    data: buttonsData,
  } = useSageData(scope, '/buttons/series', filters, {
    priority,
    apiVersion: 'v1',
  });
  const messagesLineData = useMemo(
    () => sageSeriesToLineData(messagesData, 'count', 'messages', palette[1]),
    [messagesData],
  );
  const buttonsLineData = useMemo(
    () => sageSeriesToLineData(buttonsData, 'count', 'button clicks', palette[2]),
    [buttonsData],
  );
  const totalLineData = useMemo(() => {
    if (messagesLoading || buttonsLoading || !messagesData || !buttonsData) {
      return undefined;
    }

    const data = zip(messagesData, buttonsData).map((result) => {
      const a = result[0]?.count ?? 0;
      const b = result[1]?.count ?? 0;
      const date = result[0]?.date;
      return {
        count: a + b,
        date,
      };
    });
    return sageSeriesToLineData(data, 'count', 'total interactions', palette[-1]);
  }, [messagesData, buttonsData, messagesLoading, buttonsLoading]);

  const formatLabelCallback = useCallback((dataPoint) => {
    const label = upperFirst(dataPoint.id);
    const summed = sum(dataPoint.data.map((dp) => dp.y));

    return `${label}: ${formatNumber(summed)}`;
  }, []);

  return (
    <StatsPanel
      loading={messagesLoading || buttonsLoading}
      error={messagesError || buttonsError}
      to={to}
      title={title}
      subtitle={subtitle}
    >
      {messagesLineData && buttonsLineData && totalLineData && (
        <LineChart
          data={[messagesLineData, buttonsLineData, totalLineData]}
          granularity={filters.granularity}
          isPreview={isPreview}
          formatLabelCallback={formatLabelCallback}
          csvFilename="interactions.csv"
        />
      )}
    </StatsPanel>
  );
};

Interactions.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  to: PropTypes.string,
  scope: PropTypes.string.isRequired,
  priority: PropTypes.number,
  isPreview: PropTypes.bool,
};

export default Interactions;
