import { useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Minus, Plus } from 'frontend/assets/icons';
import { Button } from 'frontend/components';

import styles from './RequestInboxBackup.scss';
import { MAX_NUMBER_OF_DAYS } from './constants';

const ChooseNumberOfDays = () => {
  const form = useForm();
  const { values } = useFormState();

  const increase = useCallback(() => {
    form.change('days', values.days + 1);
  }, [form, values.days]);

  const decrease = useCallback(() => {
    form.change('days', values.days - 1);
  }, [form, values.days]);

  const setMax = useCallback(() => {
    form.change('days', MAX_NUMBER_OF_DAYS);
  }, [form]);

  return (
    <div className="text-center">
      <p>Specify how many days of backup you want:</p>
      <Button onClick={decrease} icon={Minus} shape="circle" className={styles.button} />
      <Field className={styles.plusMinusNumber} component="input" type="text" name="days" aria-label="Number of days" />
      <Button onClick={increase} icon={Plus} shape="circle" className={styles.button} />
      <p>or</p>
      <Button onClick={setMax}>Get max days</Button>
    </div>
  );
};

export default ChooseNumberOfDays;
