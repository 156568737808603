import { useMemo, useState } from 'react';

import { Loader, Panel } from 'frontend/components';

import styles from '../Build.scss';
import SpecialDialogueForm from '../components/SpecialDialogueForm';
import { useCreateSpecialDialogue, useSpecialDialogue } from '../hooks';
import { emptyButton, setupInitialLanguages } from '../utils';
import getEmptyReply from '../utils/getEmptyReply';

const CreateSpecialDialogue = () => {
  const { loading, languagesAndVariants, dialogueType, selectedLanguage, botId } = useSpecialDialogue();
  const [onSubmit, createdUrl] = useCreateSpecialDialogue({
    botId: botId!,
    dialogueType: dialogueType!,
    selectedLanguage,
  });
  const [hasChanges, setHasChanges] = useState(false);

  const initialValues = useMemo(() => {
    const isActive = setupInitialLanguages(languagesAndVariants, selectedLanguage);
    return {
      isActive,
      replies: [getEmptyReply(selectedLanguage)],
      newButton: emptyButton,
      buildForms: [],
      buttonsDisplayOrientation: 'VERTICAL',
      context: {},
    };
  }, [selectedLanguage, languagesAndVariants]);

  if (loading) {
    return (
      <Panel className={styles.loadingContainer}>
        <Loader size="large" />
      </Panel>
    );
  }

  return (
    <SpecialDialogueForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      selectedLanguage={selectedLanguage}
      buildFormType={dialogueType!}
      createdUrl={createdUrl!}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
    />
  );
};

export default CreateSpecialDialogue;
