import cx from 'classnames';
import { useState } from 'react';

import styles from './DoughnutChart.scss';

const structureData = (data) => {
  const totalAmount = data.total;
  let combinedPercentValue = 0;
  let lastPercentValue = 0;
  return data.data.map((x, i) => {
    const percentValue = (x.value / totalAmount) * 100;
    combinedPercentValue += lastPercentValue;
    lastPercentValue = percentValue;
    const { imageUrl, label } = x;

    const strokeDashoffsetValue = i === 0 ? 0 : (combinedPercentValue * 440) / 100;
    return { value: x.value, percentValue, strokeDashoffsetValue, imageUrl, label };
  });
};

interface ChartPoint {
  value: number;
  label?: string;
  imageUrl?: string;
}

interface ChartData {
  total: number;
  data?: ChartPoint[];
}

interface DoughnutChartProps {
  /** Object containing data for the graph (total, data). */
  data?: ChartData;
  className?: string;
  nested?: boolean;
}

/** Render _data_ in a Doughnut Chart with labels on the left side (hide labels if chart is nested). */
const DoughnutChart = ({ data, className, nested, ...rest }: DoughnutChartProps) => {
  const [hover, setHover] = useState(-1);
  const doughnutValues = structureData(data);
  return (
    <div className={cx(styles.wrapper, className, { [styles.wrapperNested]: nested })} {...rest}>
      {!nested && (
        <div className={cx(styles.doughnutValues, { [styles.doughnutHovered]: hover !== -1 })}>
          {doughnutValues.map((value, i) => (
            <div
              key={`doughnut-${value.label}`}
              className={cx(styles.doughnutValuesOption, { [styles.doughnutValuesSelected]: hover === i })}
            >
              <div className={styles.colorBox} />
              {value.imageUrl && <img src={value.imageUrl} className={styles.image} alt={`${value.percentValue}%`} />}
              <span>
                {value.value} {value.label ?? ''} ({Math.round(value.percentValue)}%)
              </span>
            </div>
          ))}
        </div>
      )}
      <div className={styles.doughnut}>
        <svg width={nested ? '60' : '160'} height={nested ? '60' : '160'} xmlns="http://www.w3.org/2000/svg">
          <g>
            {doughnutValues.map((value, i) => (
              <circle
                key={`circle-${value.label}`}
                id="circle"
                className={cx({ [styles.doughnutNested]: nested })}
                r="69.85699"
                cy="81"
                cx="81"
                strokeWidth="14"
                stroke="#333"
                fill="none"
                onMouseOver={() => setHover(i)}
                onMouseLeave={() => setHover(-1)}
                onFocus={() => setHover(i)}
                onBlur={() => setHover(-1)}
                style={{ strokeDashoffset: value.strokeDashoffsetValue }}
              />
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
};

export default DoughnutChart;
