import { Link } from 'react-router-dom';

import type { BotType } from 'frontend/api/generated';
import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import { Metric } from 'frontend/components/Stats';
import { StatsPanel } from 'frontend/features/Analytics/components';
import type { ReportBot } from 'frontend/features/Analytics/utils/sageData';
import { MonthlyMetricTooltip } from 'frontend/features/Organization/views/Reports/MonthlyMetricTooltip';

import styles from './Reports.scss';

function BotMeta({
  botId,
  startDate,
  endDate,
  bot,
}: {
  botId: number;
  startDate: string;
  endDate: string;
  bot?: BotType;
}) {
  if (!bot) return <span className={styles.botAvatarWrap}>Deleted bot ({botId})</span>;

  const url = `/bot/${botId}/analytics/sessions?startDate=${startDate}&endDate=${endDate}&granularity=day`;
  let name = bot.name;
  if (bot.projectName) {
    name = `{name} (${bot.projectName})`;
  }
  return (
    <Link to={url} className={styles.botAvatarWrap}>
      <img src={bot.avatarUrl || defaultBotAvatar} alt={`Avatar for ${bot.name}`} className={styles.botAvatar} />
      <span>{name}</span>
    </Link>
  );
}

export function BotReport({
  botReport,
  loading,
  startDate,
  endDate,
}: {
  botReport: { data: ReportBot; meta: BotType; previous?: ReportBot };
  loading: boolean;
  startDate: string;
  endDate: string;
}) {
  const { meta: bot, previous, data } = botReport;

  return (
    <div className={styles.botReportGrid} key={data.bot_id}>
      <BotMeta botId={data.bot_id} bot={bot} startDate={startDate} endDate={endDate} />
      <StatsPanel className={styles.reportMetric} loading={loading} nested>
        <Metric
          metric={data?.messages}
          reference={previous?.messages}
          topText="Messages"
          TooltipComponent={MonthlyMetricTooltip}
        />
      </StatsPanel>
      <StatsPanel className={styles.reportMetric} loading={loading} nested>
        <Metric
          metric={data?.sessions}
          reference={previous?.sessions}
          topText="Sessions"
          TooltipComponent={MonthlyMetricTooltip}
        />
      </StatsPanel>
      <StatsPanel className={styles.reportMetric} loading={loading} nested>
        <Metric
          metric={data?.handovers?.finished}
          reference={previous?.handovers?.finished}
          topText="Handovers"
          TooltipComponent={MonthlyMetricTooltip}
        />
      </StatsPanel>
      <div />
    </div>
  );
}
