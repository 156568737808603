import { Modal } from 'frontend/features/Modals';

interface ResetFormModalProps {
  hide(): Promise<void>;
  args: {
    handleCancel(): Promise<Record<string, unknown> | void>;
  };
}

const ResetFormModal = ({ hide, args: { handleCancel } }: ResetFormModalProps) => (
  <Modal title="Clear changes" color="warning" hide={hide} onOk={handleCancel} onOkText="Yes, clear changes">
    <Modal.Content className="m-t-2 m-b-0 text-center">Are you sure you want to discard your changes?</Modal.Content>
  </Modal>
);

export default ResetFormModal;
