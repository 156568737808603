import { gql } from '@apollo/client';

import { SIMPLE_EVALUATION_FRAGMENT } from './fragments';

const ALL_SIMPLE_EVALUATIONS = gql`
  query AllSimpleEvaluations($botId: ID!) {
    allSimpleEvaluations(botId: $botId) {
      ...SimpleEvaluation
    }
  }
  ${SIMPLE_EVALUATION_FRAGMENT}
`;
export default ALL_SIMPLE_EVALUATIONS;
