import Loader from '../Loader';
import type { LoaderSize } from '../Loader/Loader';

export interface LoaderSwitchProps {
  children: React.ReactNode;
  /** Sets whether in loading state or not. */
  loading: boolean;
  /** Sets no margin on `<Loader />`. */
  noMargin?: boolean;
  /** Sets size on `<Loader />`. */
  size?: LoaderSize;
  /** Passes some text to `<Loader />`. */
  loaderText?: string;
  /** Sets whether to animate `<Loader />`. */
  animate?: boolean;
}

/** Manages the `<Loader>` switch between loading states. */
const LoaderSwitch = ({ children, loading, size, loaderText, noMargin, animate }: LoaderSwitchProps) => {
  if (loading) return <Loader size={size} text={loaderText} noMargin={noMargin} animate={animate} />;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default LoaderSwitch;
