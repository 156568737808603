import { orderBy } from 'lodash';
import { useCallback, useState } from 'react';
import { useForm } from 'react-final-form';

import { formValuesToComposerState } from 'frontend/features/Composer';

export const useHover = () => {
  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = useCallback(() => !isHovered && setIsHovered(true), [isHovered]);
  const onMouseLeave = useCallback(() => isHovered && setIsHovered(false), [isHovered]);

  return { isHovered, onMouseEnter, onMouseLeave };
};

const reverseSamplesByIndex = (samples) => orderBy(samples, ({ sampleIndex }) => -Number(sampleIndex));

export const useSampleItemCallbacks = ({
  removeSample,
  sample,
  selectedSamples,
  setSampleSelected,
  isSelected,
  deselectAll,
  name,
  editingSample,
  setEditingSample,
}) => {
  const { change, getState } = useForm();
  const { sampleIndex } = sample;
  const isEditing = editingSample === sampleIndex;

  const onClickItem = useCallback(
    (e) => {
      if (e.shiftKey) setSampleSelected(sample, !isSelected);
      const { submitting } = getState();
      if (e.shiftKey || submitting || isEditing) return;

      const editorState = formValuesToComposerState(name, sample.text, sample.instances);
      change(name, { ...sample, editorState });
      deselectAll();
      setEditingSample(sampleIndex);
      e.stopPropagation();
      e.preventDefault();
    },
    [
      change,
      deselectAll,
      getState,
      isEditing,
      isSelected,
      name,
      sample,
      sampleIndex,
      setEditingSample,
      setSampleSelected,
    ],
  );

  const remove = useCallback(
    (e) => {
      if (getState().submitting) return;
      removeSample(sampleIndex);
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [getState, removeSample, sampleIndex],
  );

  const removeSelected = useCallback(
    (e) => {
      if (getState().submitting) return;
      // The samples should be deleted in reverse order, since the item keys are updated consecutively in final-form
      reverseSamplesByIndex(selectedSamples).forEach(({ sampleIndex: index }) => removeSample(index));
      deselectAll();
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [deselectAll, getState, removeSample, selectedSamples],
  );

  return { onClickItem, remove, removeSelected, isEditing };
};
