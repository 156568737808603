import { Modal } from 'frontend/features/Modals';

import styles from './ImportExportDeleteModal.scss';

interface ImportExportDeleteModalProps {
  hide: () => Promise<void>;
  args: {
    handleDelete: () => Promise<Record<string, any> | void>;
    title: string;
    description: string;
  };
}

const ImportExportDeleteModal = ({
  hide,
  args: { handleDelete, title, description },
}: ImportExportDeleteModalProps) => (
  <Modal title={title} color="warning" hide={hide} onOk={handleDelete} onOkText="Delete">
    <Modal.Content className={styles.modalContent}>
      <p>{description}</p>
    </Modal.Content>
  </Modal>
);

export default ImportExportDeleteModal;
