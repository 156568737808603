import { lowerFirst, sum } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { LineChart } from 'frontend/components/Stats';
import { StatsPanel } from 'frontend/features/Analytics/components/';
import { useSageData } from 'frontend/features/Analytics/hooks';
import { sageSeriesToLineData, sageSeriesToMultiLineData } from 'frontend/features/Analytics/utils/sage';
import { formatNumber } from 'frontend/utils';

const ACTION_TYPES = Object.freeze({
  CONVERSIONS: 'conversions',
  ORDERS: 'orders',
  TOTAL_CONVERSIONS: 'total_conversions',
  TOTAL_ORDERS: 'total_orders',
});

const getTotalLabel = (event) =>
  ({
    total_conversions: 'Conversions',
    total_orders: 'Orders',
  })[event];

const isTotal = (event) => [ACTION_TYPES.TOTAL_CONVERSIONS, ACTION_TYPES.TOTAL_ORDERS].indexOf(event) > -1;

const ConversionLineChart = ({ title, subtitle, filters, scope, event, to, isPreview }) => {
  const { loading, error, data } = useSageData(scope, '/conversions/series', filters);
  const customLegends = Object.keys(data?.[0]?.[event] ?? {}).sort((a, b) => b.charCodeAt(0) - a.charCodeAt(0));
  const chartData = useMemo(() => {
    if (isTotal(event)) {
      return [sageSeriesToLineData(data, event, getTotalLabel(event))];
    }
    return sageSeriesToMultiLineData(data, event, customLegends);
  }, [data, event, customLegends]);

  const formatLabelCallback = (dataPoint) =>
    `Total ${lowerFirst(dataPoint.id)}: ${formatNumber(sum(dataPoint.data.map((dp) => dp.y)))}`;

  // Hide the graph if there is no data available
  if ((isTotal(event) && !chartData.length) || (!isTotal(event) && chartData.length <= 1)) {
    return null;
  }

  return (
    <StatsPanel loading={loading} error={error} to={to} title={title} subtitle={subtitle}>
      {data && (
        <LineChart
          formatLabelCallback={formatLabelCallback}
          data={chartData}
          granularity={filters.granularity}
          isPreview={isPreview}
          csvFilename="conversions.csv"
        />
      )}
    </StatsPanel>
  );
};

ConversionLineChart.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  filters: PropTypes.shape({}).isRequired,
  scope: PropTypes.string.isRequired,
  event: PropTypes.oneOf(Object.values(ACTION_TYPES)),
  to: PropTypes.string,
  isPreview: PropTypes.bool,
};

ConversionLineChart.ACTION_TYPES = ACTION_TYPES;

export default ConversionLineChart;
