import { noop } from 'lodash';
import { Form } from 'react-final-form';

import { mapToObject } from 'frontend/utils';

import ButtonsOverview from './ButtonsOverview';
import { EXTRA_FILTER_TYPES, buttonTypes } from './constants';

const INITIAL_VALUES = {
  buttonTypes: { ...mapToObject((type) => ({ [type]: false }), buttonTypes), [EXTRA_FILTER_TYPES.ALL]: true },
};

const Buttons = () => <Form initialValues={INITIAL_VALUES} onSubmit={noop} component={ButtonsOverview} />;

export default Buttons;
