import { Button } from 'frontend/components';
import { useMixpanel } from 'frontend/hooks';

import { useFakeData } from '../../hooks';

interface Props {
  className?: string;
}

export default function AnalyticsDemoButton({ className }: Props) {
  const { toggleFakeData, eligibleForFakeData, fakeDataEnabled } = useFakeData(undefined);
  const { mixpanel } = useMixpanel();

  const onClickDemoButton = () => {
    toggleFakeData();
    if (!fakeDataEnabled) mixpanel.track('Apply Analytics fake data');
  };

  return eligibleForFakeData ? (
    <Button onClick={onClickDemoButton} color={fakeDataEnabled ? 'primary' : undefined} className={className}>
      Demo mode
    </Button>
  ) : null;
}
