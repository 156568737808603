import PropTypes from 'prop-types';

import { Button } from 'frontend/components';
import { useToggle } from 'frontend/hooks';
import { getId } from 'frontend/utils';

import styles from './DropdownList.scss';
import Item from './Item';
import { TagItemType } from '../../propTypes';

const LISTED_ITEMS_DEFAULT = 5;

const DropdownList = ({ items, addTag, numberOfListedItems = LISTED_ITEMS_DEFAULT }) => {
  const [expanded, toggleExpanded] = useToggle();
  const hasHiddenItems = items.length > numberOfListedItems;
  const expandText = expanded ? '- Show less' : '+ Show more';

  return (
    <div className={styles.dropdownList}>
      {items.slice(0, expanded ? undefined : numberOfListedItems).map((item) => (
        <Item key={`dropdown-item-${getId(item)}`} item={item} addTag={addTag} />
      ))}
      {hasHiddenItems && (
        <Button flat className={styles.dropdownListExpand} onClick={toggleExpanded}>
          {expandText}
        </Button>
      )}
    </div>
  );
};

DropdownList.propTypes = {
  addTag: PropTypes.func.isRequired,
  numberOfListedItems: PropTypes.number,
  items: PropTypes.arrayOf(TagItemType).isRequired,
};

export default DropdownList;
