import { useCallback } from 'react';

import { ChevronUp, GreetingBubble } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import Dropdown from 'frontend/components/Dropdown';
import type { ContactDetails } from 'frontend/features/Inbox/propTypes';
import { hasRequestedContact } from 'frontend/features/Inbox/utils';
import { CONTACT_DETAILS_STATUS } from 'frontend/state/dux/inbox';

import ContactStatusList from './ContactStatusList';
import { getStatus } from './utils';
import sharedStyles from '../../metadata-styles.scss';

interface Props {
  email?: string;
  botId: string | number;
  phoneNumber?: string;
  contactDetails: ContactDetails;
}

export const contactWasCompleted = ({ contactDetails }: { contactDetails: ContactDetails }): boolean =>
  contactDetails?.status === CONTACT_DETAILS_STATUS.done;

const ContactStatus = ({ email, phoneNumber, contactDetails, botId }: Props) => {
  const requestedContact = hasRequestedContact({ contactDetails });
  const completedContact = contactWasCompleted({ contactDetails });
  const status = getStatus({ requestedContact, completedContact, contactDetails });

  const renderOverlay = useCallback(
    ({ close }) => (
      <ContactStatusList
        close={close}
        email={email}
        phoneNumber={phoneNumber}
        contactDetails={contactDetails}
        requestedContact={requestedContact}
        completedContact={completedContact}
        botId={botId}
      />
    ),
    [botId, completedContact, contactDetails, email, phoneNumber, requestedContact],
  );

  return (
    <Dropdown
      overlay={renderOverlay}
      wrapperClassName={sharedStyles.dropdownWrapper}
      triggerClassName={sharedStyles.dropdownTrigger}
      overlayClassName={sharedStyles.dropdownOverlay}
      position="top"
    >
      <div className={sharedStyles.iconLeftAndTextWrapper}>
        <Icon component={GreetingBubble} />
        <span className={sharedStyles.buttonText}>{status}</span>
      </div>
      <Icon component={ChevronUp} className={sharedStyles.iconChevron} color="black" />
    </Dropdown>
  );
};

export default ContactStatus;
