import { gql } from '@apollo/client';

import { INBOX_AGENT } from '../fragments';

// FIXME: Have not found a performant way to fetch only users with
// view_conversations permission (test on organization.id 2)

export default gql`
  query OrganizationAgents($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      members {
        id
        user {
          ...InboxAgent
        }
      }
    }
  }
  ${INBOX_AGENT}
`;
