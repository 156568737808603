import { Route, Routes } from 'react-router-dom';

import ConversionOverview from './ConversionOverview';
import ConversionDetail from './components/ConversionDetail';
import ConversionLineChart from './components/ConversionLineChart';
import ConversionSplitDetail from './components/ConversionSplitDetail';

const TotalOrdersDetail = () => (
  <ConversionDetail
    title="Total orders"
    subtitle="Number of orders"
    event={ConversionLineChart.ACTION_TYPES.TOTAL_ORDERS}
  />
);

const TotalConversionsDetail = () => (
  <ConversionDetail
    title="Total conversions"
    subtitle="Number of conversions"
    event={ConversionLineChart.ACTION_TYPES.TOTAL_CONVERSIONS}
  />
);

const SplitOrderDetails = () => (
  <ConversionSplitDetail
    title="Orders split data"
    subtitle="Number of orders for each split"
    event={ConversionLineChart.ACTION_TYPES.ORDERS}
  />
);

const SplitConversionDetails = () => (
  <ConversionSplitDetail
    title="Conversions split data"
    subtitle="Number of conversions for each split"
    event={ConversionLineChart.ACTION_TYPES.CONVERSIONS}
  />
);

const Conversion = () => (
  <Routes>
    <Route path="/total-orders" element={<TotalOrdersDetail />} />
    <Route path="/total-conversions" element={<TotalConversionsDetail />} />
    <Route path="/split-orders" element={<SplitOrderDetails />} />
    <Route path="/split-conversions" element={<SplitConversionDetails />} />
    <Route path="/" element={<ConversionOverview />} />
  </Routes>
);

export default Conversion;
