import { orderBy } from 'lodash';
import { useMemo } from 'react';
import type { FieldRenderProps } from 'react-final-form';

import type { SearchResultType } from 'frontend/api/generated';
import { useCurrentLanguage } from 'frontend/hooks';

import DialogueOption from './DialogueOption';
import styles from './SelectDialogue.scss';
import InputErrorWrapper from '../InputErrorWrapper';
import type { ErrorPosition } from '../InputErrorWrapper/InputErrorWrapper';
import Loader from '../Loader';
import SelectWithSearch from '../SelectWithSearch';

// look for matches in the first 1000*0.3=300 characters in the title; see https://fusejs.io/api/options.html#distance
const FUSE_CONFIG = { distance: 1000, keys: ['name'], threshold: 0.3 };

const dialogueToOption =
  ({ currentBaseLanguage, selectedLanguage, highlightedDialogueId, activeOnly }) =>
  // eslint-disable-next-line camelcase
  ({ id, title, path, is_active }) => {
    const name = title[currentBaseLanguage] ?? title.default;
    const highlighted = id === highlightedDialogueId;

    return {
      name,
      value: id,
      highlighted,
      tooltip: path?.[currentBaseLanguage],
      // eslint-disable-next-line camelcase
      isActive: is_active?.[selectedLanguage],
      activeOnly,
    };
  };

const lowercaseName = ({ name = '' }) => name.toLowerCase();

const filterActive = (selectedLanguage: string, activeOnly: boolean, dialogues: Dialogue[] = []) =>
  activeOnly ? dialogues.filter(({ is_active: isActive }) => isActive?.[selectedLanguage]) : dialogues;

interface Dialogue {
  id: string;
  title: Record<string, string>;
  path: Record<string, string>;
  is_active: Record<string, boolean>;
}

interface SelectDialogueProps extends FieldRenderProps<string> {
  placeholder?: string;
  highlightedDialogueId?: string;
  loading?: boolean;
  activeOnly?: boolean;
  allowEmpty?: boolean;
  dialogueList?: SearchResultType;
  errorPosition?: ErrorPosition;
  /** If true, the select dropdown will be half the size. */
  shortDropdown?: boolean;
}

const SelectDialogue = ({
  input,
  dialogueList,
  placeholder,
  loading,
  highlightedDialogueId,
  activeOnly = false,
  allowEmpty = false,
  label,
  className,
  meta,
  errorPosition,
  shortDropdown,
}: SelectDialogueProps) => {
  const [{ selectedLanguage, currentLanguage: currentBaseLanguage }] = useCurrentLanguage();

  const hasError = Boolean(meta?.submitFailed && (meta?.submitError || meta?.error));
  const errorMessage = meta?.submitError || meta?.error || '';

  const options = useMemo(
    () => [
      ...(allowEmpty ? [{ name: 'None', value: '' }] : []),
      ...orderBy(
        filterActive(selectedLanguage, activeOnly, dialogueList?.dialogues).map(
          dialogueToOption({ currentBaseLanguage, selectedLanguage, highlightedDialogueId, activeOnly }),
        ),
        lowercaseName,
      ),
      ...(dialogueList?.subscribedSkills ?? []).map(({ name, dialogues: skillDialogues }) => ({
        name: `Skill: ${name}`,
        type: 'group',
        items: orderBy(
          filterActive(selectedLanguage, activeOnly, skillDialogues).map(
            dialogueToOption({ currentBaseLanguage, selectedLanguage, highlightedDialogueId, activeOnly }),
          ),
          lowercaseName,
        ),
      })),
    ],
    [activeOnly, allowEmpty, highlightedDialogueId, selectedLanguage, currentBaseLanguage, dialogueList],
  );

  return (
    <>
      {label && (
        <div className={styles.labelWrapper}>
          <label htmlFor={input.name}>{label}</label>
        </div>
      )}
      {loading ? (
        <div className={styles.loaderWrapper}>
          <Loader size="small" />
        </div>
      ) : (
        <InputErrorWrapper
          hasError={hasError}
          className={className}
          errorMessageClassName={styles.errorMessage}
          displayError={errorMessage}
          errorPosition={errorPosition}
        >
          <SelectWithSearch
            input={input}
            /* @ts-expect-error react-select-search types are wrong */
            options={options}
            hasError={hasError}
            placeholder={placeholder}
            fuse={FUSE_CONFIG}
            renderOption={DialogueOption}
            meta={{}}
            shortDropdown={shortDropdown}
          />
        </InputErrorWrapper>
      )}
    </>
  );
};

export default SelectDialogue;
