import React, { type SetStateAction, useState } from 'react';
import { useForm } from 'react-final-form';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Filter } from 'frontend/assets/icons';
import { Button, Dropdown, Panel } from 'frontend/components';
import type { Option } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';

import styles from './FiltersWrapper.scss';
import { FILTERS_LIST } from '../../utils/config';
import { checkActiveFilters } from '../../utils/helpers';
import ActiveInboxFilters from '../ActiveInboxFilters/ActiveInboxFilters';

const getFilterComponent = (componentKey) => (
  <div className={styles.filterOverlayWrapper} role="complementary">
    {FILTERS_LIST.find((filterItem) => filterItem.key === componentKey)?.component || null}
  </div>
);

const FiltersWrapper = () => {
  const { submit, getState, reset } = useForm();
  const { values } = getState();
  const [searchParams] = useSearchParams();
  const { organizationId } = useParams();
  const [currentActiveDropdown, setCurrentActiveDropdown] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { filteredValues, hasActiveFilters } = checkActiveFilters(values, searchParams);

  // TODO remove when we ditch the organization inbox
  let filtersList = FILTERS_LIST;
  if (!organizationId) {
    filtersList = filtersList.filter(({ onlyOrganizationInbox }) => !onlyOrganizationInbox);
  }

  const menuOptionsList = filtersList.map((option) => ({
    text: option.text,
    key: option.key,
    icon: option.icon,
    closeMenuOnSelection: false,
    disabled: filteredValues.includes(option.key),
    onClick: (_, clickOption: Option) => {
      setCurrentActiveDropdown(clickOption.key as SetStateAction<string | null>);
    },
  }));

  return (
    <Panel className={styles.filtersPanel} size="small">
      <Dropdown
        element={Button}
        elementProps={{
          color: 'white',
          size: 'small',
          icon: Filter,
          text: 'Add filter',
        }}
        triggerClassName={styles.trigger}
        overlayClassName={styles.dropdown}
        position="bottom-right"
        title="Add filters"
        outsideClick="pointerdown"
        onToggle={(active) => {
          if (!active) {
            if (currentActiveDropdown) {
              submit();
            }
            setCurrentActiveDropdown(null);
          }
        }}
        overlay={
          currentActiveDropdown ? (
            getFilterComponent(currentActiveDropdown)
          ) : (
            <Dropdown.MenuOverlay options={menuOptionsList} />
          )
        }
      />
      {hasActiveFilters && (
        <>
          <ActiveInboxFilters />
          <Button
            className={styles.clearFiltersBtn}
            flat
            text="Clear filters"
            onClick={() => {
              navigate(location.pathname);
              reset();
            }}
          />
        </>
      )}
    </Panel>
  );
};

export default FiltersWrapper;
