import type { UseFormReturn } from 'react-hook-form';

import InputRHF from 'frontend/components/Input/InputRHF';
import ToggleRHF from 'frontend/components/ToggleSwitch/ToggleRHF';

import styles from './FreeFormSection.scss';
import PreviewCard from './PreviewCard';
import type { CustomerFeedbackInputs } from '../CustomerFeedback';
import type { RHF_FORMS_NAME } from '../constants';
import shared from '../shared.scss';

interface Props {
  rhfMethods: UseFormReturn<CustomerFeedbackInputs>;
  rhfFormPath: `${typeof RHF_FORMS_NAME}.${number}`;
}

export default function FreeFormSection({ rhfMethods, rhfFormPath }: Props): React.JSX.Element {
  const { watch } = rhfMethods;

  const watchEnableStep = watch(`${rhfFormPath}.freeForm.enableStep`);
  const watchFreeFormTitle = watch(`${rhfFormPath}.freeForm.title`);
  const watchFreeFormPlaceholder = watch(`${rhfFormPath}.freeForm.placeholder`);

  return (
    <section className={shared.sectionSplit}>
      <div className={shared.leftSideSection}>
        <div>
          <h4 className={shared.heading}>Freeform text</h4>
          <p>
            Give the end users the opportunity to express themselves in their own words! Adding a text input field
            allows them to share detailed feedback and suggestions.
          </p>
        </div>

        {watchEnableStep && (
          <>
            <div className={shared.maxWidth}>
              <InputRHF
                name={`${rhfFormPath}.freeForm.title`}
                rhfMethods={rhfMethods}
                rhfOptions={{ required: true, maxLength: 50 }}
                label="Title description"
                inputLimit={50}
                fieldColor="mischka"
              />
            </div>

            <div className={shared.maxWidth}>
              <InputRHF
                name={`${rhfFormPath}.freeForm.placeholder`}
                rhfMethods={rhfMethods}
                rhfOptions={{ required: true, maxLength: 300 }}
                label="Text input field placeholder"
                inputLimit={300}
                fieldColor="mischka"
              />
            </div>
          </>
        )}
      </div>

      <div className={shared.rightSideSection}>
        <ToggleRHF name={`${rhfFormPath}.freeForm.enableStep`} rhfMethods={rhfMethods} label="Enable this step" />
        {watchEnableStep && (
          <PreviewCard heading={watchFreeFormTitle}>
            <div className={styles.previewCardInput}>
              <span>{watchFreeFormPlaceholder}</span>
            </div>
          </PreviewCard>
        )}
      </div>
    </section>
  );
}
