import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import { Button, Input, Table } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';
import { useIsSubmitting } from 'frontend/hooks';

import styles from './ContextFields.scss';
import DataCell from './DataCell';
import FieldActions from './FieldActions';
import { useContextFields } from '../../hooks';
import { DeleteContextField } from '../../modals';
import { CONTEXT_FIELDS } from '../../queries';

const ContextFields = () => {
  const [deleteFieldModal] = useModal(DeleteContextField);
  const [isSubmitting, submitWrapper] = useIsSubmitting();
  const { botId, onFieldCreate, onFieldUpdate, activeField, setActiveField } = useContextFields();

  const { data, loading } = useQuery(CONTEXT_FIELDS, { variables: { botId } });
  const fieldsList = useMemo(() => data?.allContextFields ?? [], [data?.allContextFields]);

  const columns = useMemo(() => {
    const dataValues = {
      onFieldUpdate,
      activeField,
      submitWrapper,
      editField: (rowData) => {
        setActiveField({ ...rowData });
      },
    };
    return [
      {
        key: 'key',
        render: DataCell,
        data: dataValues,
      },
      {
        key: 'value',
        render: DataCell,
        data: dataValues,
      },
      {
        key: 'actions',
        title: '',
        render: FieldActions,
        data: {
          deleteField: ({ id }) => {
            deleteFieldModal({ id, botId });
          },
        },
      },
    ];
  }, [botId, deleteFieldModal, onFieldUpdate, activeField, submitWrapper, setActiveField]);

  return (
    <div className="m-t-3">
      <p>
        Context fields lets you define global variables that can be used in any dialogue, the same way patterns are
        used. <br />
        Built-in fields are default variables that cannot be edited or deleted.
      </p>
      <div className={styles.formContainer}>
        <Form
          onSubmit={submitWrapper(onFieldCreate)}
          initialValues={{}}
          render={({ handleSubmit, form }) => (
            <form onSubmit={(event) => handleSubmit(event).then(() => form.reset())} autoComplete="off">
              <div className={styles.formGroup}>
                <Field
                  component={Input}
                  name="key"
                  inputLimit={255}
                  validate={chain([required, max(255)])}
                  label="Field key"
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="value">Field value</label>
                <Field component={Input} name="value" validate={required} />
              </div>
              <div className={styles.formButton}>
                <Button type="submit" color="primary" isSubmitting={isSubmitting}>
                  Add context field
                </Button>
              </div>
            </form>
          )}
        />
      </div>
      <Table columns={columns} data={fieldsList} loading={loading} />
    </div>
  );
};

export default ContextFields;
