import cx from 'classnames';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { LogItem, TicketIcon } from 'frontend/features/Inbox/components';
import { getUserName } from 'frontend/features/Inbox/utils';
import { AutoUserType } from 'frontend/propTypes';

import styles from './TicketLogItem.scss';
import { TICKET_STATUS } from '../../../../constants';
import { TicketLogType } from '../../propTypes';

const TICKET_LOG_ACTIONS = Object.freeze({
  CHANGED_STATUS: 'CHANGED_STATUS',
  LEAVE_TICKET: 'LEAVE_TICKET',
  ASSIGNED_USER: 'ASSIGNED_USER',
  CREATED: 'CREATED',
});

const createTextOutput = (ticketLogData = {}, chatFullName, autoUser) => {
  const { action, user, actionByEndUser, valueAfter, valueBefore } = ticketLogData;
  const userName = actionByEndUser ? getUserName({ fullName: chatFullName, autoUser }) : user?.profile?.fullName;

  if (!userName) return null;

  switch (action) {
    case TICKET_LOG_ACTIONS.LEAVE_TICKET:
      return `${userName} unassigned ${valueBefore}`;
    case TICKET_LOG_ACTIONS.ASSIGNED_USER:
      return `${userName} assigned ${valueAfter}`;
    case TICKET_LOG_ACTIONS.CHANGED_STATUS:
      return `${userName} changed status from ${capitalize(valueBefore)} to ${capitalize(valueAfter)}`;
    case TICKET_LOG_ACTIONS.CREATED:
      return `${userName} created a ticket`;
    default:
      return null;
  }
};

const TicketLogItem = ({ data, autoUser, chatFullName }) => {
  const textOutput = createTextOutput(data, chatFullName, autoUser);
  const status = data.action === 'CREATED' ? TICKET_STATUS.OPEN : data.valueAfter;
  const infoIcon = !Object.values(TICKET_STATUS).includes(status);

  if (!textOutput) return null;

  return (
    <LogItem timeStamp={data.created} text={textOutput}>
      <div className={cx(styles.iconWrapper, { [styles.iconWrapperInfo]: infoIcon })}>
        <TicketIcon className={styles.icon} status={status} infoIcon={infoIcon} />
      </div>
    </LogItem>
  );
};

TicketLogItem.propTypes = {
  data: TicketLogType.isRequired,
  autoUser: AutoUserType,
  chatFullName: PropTypes.string,
};

export default TicketLogItem;
