import { UrlInput } from 'frontend/components';
import Input from 'frontend/components/Input';
import { FIELD_COLOR } from 'frontend/constants';

import type { ButtonType, LocalErrors } from '../ButtonsMeta';

interface Props {
  buttonForm: ButtonType;
  setButtonForm: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  localErrors: LocalErrors;
  validateButton: () => void;
}

export default function ExternalLinkMeta({ buttonForm, setButtonForm, localErrors, validateButton }: Props) {
  // <UrlInput> doesn't pass an event to its onChange handler (just the url), so we fake one for simplicity
  const handleURLInputChange = (url: string) => {
    const fakeEvent = {
      target: {
        name: 'value',
        value: url,
      },
    } as React.ChangeEvent<HTMLSelectElement>;
    setButtonForm(fakeEvent);
  };

  return (
    <>
      <Input
        input={{
          name: 'label',
          value: buttonForm.label,
          onChange: setButtonForm,
        }}
        onBlur={() => {
          if (localErrors.label) validateButton();
        }}
        placeholder="Button text"
        fieldColor={FIELD_COLOR.MISCHKA}
        meta={localErrors.label || {}}
      />
      <UrlInput
        input={{
          value: buttonForm.value,
          onChange: handleURLInputChange,
          name: 'value',
          onBlur: () => {
            if (localErrors.value) validateButton();
          },
          onFocus: () => {},
        }}
        placeholder="URL"
        meta={localErrors.value || {}}
      />
    </>
  );
}
