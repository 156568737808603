import isUrl from 'is-url-superb';
import { startsWith } from 'lodash';

const validatorCurry = (httpsRequired) => (url) => {
  if (!url) {
    return undefined;
  }

  const isHttp = startsWith(url, 'http://');
  const isHttps = startsWith(url, 'https://');
  const validUrl = isUrl(url) && (isHttp || isHttps);

  if (validUrl) {
    if (httpsRequired && !isHttps) {
      return 'Must be a valid HTTPS URL.';
    }
    return undefined;
  }

  return `Must be a valid URL, starting with https://${httpsRequired ? '' : ' or http://'}`;
};

export default validatorCurry(false);

export const httpsRequired = validatorCurry(true);
