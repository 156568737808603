import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Radio } from 'frontend/components';

import styles from './SessionTypePickerForm.scss';

const SessionTypePickerForm = ({ className }) => (
  <>
    <span className={styles.label}>Session type shown in graphs</span>
    <div className={cx(styles.wrapper, className)}>
      <Field name="type" type="radio" value="" label="All" component={Radio} />
      <Field name="type" type="radio" value="conversation" label="Conversation" component={Radio} />
      <Field name="type" type="radio" value="nudge" label="Nudge" component={Radio} />
    </div>
  </>
);

SessionTypePickerForm.propTypes = {
  className: PropTypes.string,
};

export default SessionTypePickerForm;
