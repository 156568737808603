import { gql } from '@apollo/client';

export default gql`
  mutation CreateOrganization($name: String!, $planName: String!, $expirationDate: Date, $timezone: String!) {
    createOrganization(name: $name, planName: $planName, expirationDate: $expirationDate, timezone: $timezone) {
      id
      name
    }
  }
`;
