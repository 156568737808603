import PropTypes from 'prop-types';

import styles from './DropdownList.scss';

const TextDescription = ({ detail, description }) => (
  <span className={styles.textDescription}>{detail || description}</span>
);

TextDescription.propTypes = {
  detail: PropTypes.string,
  description: PropTypes.string,
};

export default TextDescription;
