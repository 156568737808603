/* eslint-disable @graphql-eslint/naming-convention */
import { gql } from '@apollo/client';

import SLOT from './SLOT';

export default gql`
  fragment DialogueOutputSlot on DialogueOutputSlotType {
    id
    required
    prompt
    exactMatch
    scope
    slot {
      ...SLOT
    }
  }
  ${SLOT}
`;
