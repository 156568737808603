import cx from 'classnames';
import React from 'react';

import styles from './Section.scss';

interface Props {
  InfoBox: React.JSX.Element;
  title: string;
  listHeader?: string[];
  children: React.ReactNode;
  hasData?: boolean;
  className?: string;
  listContentClassName?: string;
}

const Section = ({
  InfoBox,
  title,
  listHeader = [],
  children,
  hasData = false,
  className,
  listContentClassName,
}: Props) => (
  <div className={cx(styles.sectionContainer, className)}>
    <h1 className={styles.title}>
      {title}&nbsp;{InfoBox}
    </h1>
    {hasData && (
      <>
        {!!listHeader.length && (
          <>
            <div className={styles.seperator} />

            <div className={styles.listHeader}>
              {listHeader.map((header) => (
                <span key={header}>{header}</span>
              ))}
            </div>
          </>
        )}
        <div className={styles.seperator} />
      </>
    )}

    <div className={cx(styles.listContent, listContentClassName)}>{children}</div>
  </div>
);
export default Section;
