import cx from 'classnames';

import type { LabelType } from 'frontend/api/generated';
import { Agent, Close, Crown, FolderPerson } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import { setInsightsChatDetailsOpen } from 'frontend/state/dux/insights';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';

import styles from './ChatDetails.scss';
import useActiveBotLabels from '../../hooks/useActiveBotLabels';
import parseContext from '../../utils/parseContext';
import Context from '../Context/Context';
import DetailsAccordion from '../DetailsAccordion/DetailsAccordion';
import LabelList from '../LabelsList/LabelsList';
import UserMeta from '../UserDetails/UserDetails';

const ChatDetails = () => {
  const { selectedChat } = useAppSelector((state) => state.insights);

  const { source, email, userMetadata, emailVerified, phoneNumber, phoneNumberVerified, isAuthenticated, context } =
    selectedChat || {};

  const [, labels] = useActiveBotLabels(selectedChat);
  const dispatch = useAppDispatch();

  const parsedContext = parseContext(context);

  return (
    <div className={styles.wrapper} data-cy="inbox-metadata">
      <div className={styles.chatDetailsHeader}>
        <div className={styles.chatDetailsHeaderLeft}>
          <Icon component={FolderPerson} width={24} height={24} />
          Chat detail
        </div>
        <Button flat onClick={() => dispatch(setInsightsChatDetailsOpen(false))} icon={Close} />
      </div>
      <div className={styles.chatDetailsContainer}>
        {/* TODO swap to Accordion later, too much trouble right now */}
        <DetailsAccordion
          defaultOpen
          dataTestId="user-details-accordion"
          header={
            <span className={styles.accordionTitle}>
              <Icon width={16} height={16} component={Agent} />
              User details
            </span>
          }
        >
          <>
            <UserMeta
              email={email}
              phoneNumber={phoneNumber}
              emailVerified={emailVerified}
              phoneNumberVerified={phoneNumberVerified}
            />
            <div className={cx('m-b-1', styles.chatDetailsInfo)}>
              {isAuthenticated && <span>User is authenticated</span>}
              {source && (
                <div className={styles.chatDetailsInfoRow}>
                  Source: <span>{source}</span>
                </div>
              )}
              {userMetadata && (
                <>
                  {userMetadata.device && (
                    <div className={styles.chatDetailsInfoRow}>
                      Device:{' '}
                      <span>
                        {userMetadata.device.vendor} {userMetadata.device.model}
                      </span>
                    </div>
                  )}
                  {userMetadata.os && (
                    <div className={styles.chatDetailsInfoRow}>
                      OS:{' '}
                      <span>
                        {userMetadata.os.name} {userMetadata.os.version}
                      </span>
                    </div>
                  )}
                  {userMetadata.browser && (
                    <div className={styles.chatDetailsInfoRow}>
                      Browser:{' '}
                      <span>
                        {userMetadata.browser?.name} {userMetadata.browser.version}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.labels}>
              <LabelList labels={labels as LabelType[]} />
            </div>
          </>
        </DetailsAccordion>
        <DetailsAccordion
          defaultOpen={false}
          dataTestId="context-details-accordion"
          header={
            <span className={styles.accordionTitle}>
              <Icon width={16} height={16} component={Crown} />
              Chat context (beta)
            </span>
          }
        >
          <Context context={parsedContext || {}} />
        </DetailsAccordion>
      </div>
    </div>
  );
};

export default ChatDetails;
