import type { ButtonsOverviewQuery } from 'frontend/api/generated';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import { LINK_STATE } from 'frontend/features/Build/constants';
import { getId } from 'frontend/utils';

import { EXTRA_FILTER_TYPES } from '../components/Buttons/constants';

type PartialButton = ButtonsOverviewQuery['buttons'][number];
type PartialDialogue = NonNullable<PartialButton['dialogue']>;

function hasOneOfButtonTypes(selectedTypes: string[]): (button: PartialButton) => boolean {
  return (button) => {
    if (!button.buttonType) {
      return false;
    }

    return (
      selectedTypes.includes(EXTRA_FILTER_TYPES.ALL) ||
      selectedTypes.includes(button.buttonType) ||
      (button.urlStatus === LINK_STATE.BROKEN && selectedTypes.includes(EXTRA_FILTER_TYPES.BROKEN))
    );
  };
}

export default function compileButtonList(
  languageCode: string,
  selectedTypes: string[],
  buttons: PartialButton[],
): {
  key: string;
  value: string;
  actions: { button: PartialButton; id: string };
  dialogue: {
    title: string;
    id: string;
    dialogueType: DialogueType;
    isActive: boolean;
  };
}[] {
  return buttons.filter(hasOneOfButtonTypes(selectedTypes)).map(({ dialogue, value, ...button }) => {
    const { title, id, dialogueType, isActive } = dialogue as PartialDialogue;
    return {
      ...button,
      value: value ?? '',
      dialogue: {
        title: title?.[languageCode] ?? title.default ?? '',
        id,
        dialogueType,
        isActive: isActive[languageCode] ?? false,
      },
      key: `button${getId(button)}`,
      actions: { button, id },
    };
  });
}
