import cx from 'classnames';
import { isEqual } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import type { FieldRenderProps } from 'react-final-form';

import { Search as SearchIcon } from 'frontend/assets/icons';
import { EVENT_KEYS } from 'frontend/constants';
import { usePrevious, useSearch } from 'frontend/hooks';

import styles from './Search.scss';
import { Icon } from '../Icon/Icon';
import Input from '../Input';

const identityWrapper = {
  wrap: (searchItems) => searchItems,
  unwrap: (query, searchResults) => searchResults,
};

interface Wrapper {
  wrap(searchItems): typeof searchItems;
  unwrap(query, searchResults): void;
}

interface SearchOptions {
  listName?: string;
  threshold?: number;
  delay?: number;
}
interface FinalFormFieldRenderProps extends Omit<FieldRenderProps<string>, 'input' | 'meta'> {
  input?: Omit<FieldRenderProps<string>['input'], 'onBlur' | 'onFocus'>;
}

interface SearchProps extends FinalFormFieldRenderProps {
  /** SetState function to set parent searchResults */
  setSearchResults(searchResults: any): void;
  /** Text placeholder for the input. */
  placeholder?: string;
  /** Control whether to autofocus the input or not. */
  autoFocus?: boolean;
  onSubmit?(query: string): void;
  onEscape?(ref: React.RefObject<HTMLInputElement>): void;
  className?: string;
  'aria-label'?: string;
  icon?: boolean;
  /** Input label */
  label?: string;
  /** The list of items to search through */
  searchItems: string | Record<string, any>[];
  /** The search key value */
  searchKey?: string | string[] | ((query: string) => string | string[]);
  /** Optional object to tell that searchItems are a list of objects */
  searchOptions?: SearchOptions;
  wrapper?: Wrapper;
}

export interface SearchRef {
  clear: () => void;
}

/** Search box that renders an `<Input>` under the hood with appropriate searching props. */
const Search = forwardRef<SearchRef, SearchProps>((props, forwardedRef) => {
  const {
    input: externalInput,
    placeholder,
    className,
    setSearchResults,
    onSubmit,
    onEscape,
    searchItems,
    icon,
    label,
    'aria-label': ariaLabel,
    autoFocus,
    searchKey,
    searchOptions = {},
    wrapper = identityWrapper,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const wrappedSearchItems = useMemo(() => wrapper.wrap(searchItems), [searchItems, wrapper]);

  const { searchResults, debouncedQuery, query, setQuery } = useSearch(wrappedSearchItems, searchKey, searchOptions);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === EVENT_KEYS.ENTER) {
        onSubmit?.(query);
      } else if (event.key === EVENT_KEYS.ESCAPE) {
        onEscape?.(inputRef);
      }
    },
    [query, onSubmit, onEscape, inputRef],
  );

  useImperativeHandle(forwardedRef, () => ({
    clear: () => {
      setQuery('');
      setSearchResults(searchItems);
    },
  }));

  useEffect(() => {
    externalInput?.onChange?.(query);
  }, [externalInput, query]);

  const previousSearchResults = usePrevious(searchResults);
  useEffect(() => {
    if (previousSearchResults && !isEqual(searchResults, previousSearchResults)) {
      setSearchResults(wrapper.unwrap(debouncedQuery, searchResults));
    }
  }, [debouncedQuery, previousSearchResults, searchResults, setSearchResults, wrapper]);

  useEffect(() => {
    if (autoFocus && inputRef.current) setTimeout(() => inputRef.current?.focus?.());
  }, [autoFocus, inputRef]);

  const input = useMemo(() => ({ value: query, onChange: ({ target }) => setQuery(target.value) }), [query, setQuery]);

  return (
    <Input
      input={input}
      className={cx(styles.search, className)}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      ref={inputRef}
      aria-label={ariaLabel}
      label={label}
      adornment={icon ? <Icon component={SearchIcon} /> : undefined}
      adornmentPosition={icon ? 'left' : undefined}
    />
  );
});

Search.displayName = 'Search';

export default Search;
