import { gql } from '@apollo/client';

import PluginInstanceFragment from 'frontend/api/fragments/PluginInstance.fragment.graphql';

export const CreatePluginInstance = gql`
  mutation CreatePluginInstance($dialogueId: UUID!, $pluginId: UUID!) {
    createPluginInstance(dialogueId: $dialogueId, pluginId: $pluginId) {
      ...PluginInstance
    }
  }
  ${PluginInstanceFragment}
`;
