import cx from 'classnames';

import { InfoBox, ToggleSwitch } from 'frontend/components';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';

import styles from '../InboxSettings.scss';

const Workflow = () => (
  <section>
    <h3 className={styles.sectionTitle}>Workflow</h3>
    <ToggleSwitch status={['End handover when ticket status is set to solved']} name="endHandoverOnSolve" />
    <div className={styles.boxContainer}>
      <InfoBox className={cx(styles.helpText, styles.boxContainer)} title="What is ticket status?">
        Tickets status is the status of a chat that has been taken over by a human.
        <br />
        <HelpCenterLink path="/handover-in-the-inbox">You can read more about ticket status here</HelpCenterLink>.
      </InfoBox>
    </div>
  </section>
);
export default Workflow;
