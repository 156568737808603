import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Exclamation, Search } from 'frontend/assets/icons';
import { EmptyState, InfoButton, LoaderSwitch, Pagination } from 'frontend/components';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import { useModal } from 'frontend/features/Modals';
import { useCurrentLanguage, usePagination } from 'frontend/hooks';
import { selectLibraryFilters } from 'frontend/state/dux/library';

import styles from './SampleCandidate.scss';
import { CandidateRow } from './components';
import { useSampleCandidates } from './hooks';
import sharedStyles from '../bot-improvements.scss';
import { Section } from '../components';
import { AboutFeature } from '../modals';

const stateInfo = {
  noResults: {
    icon: Search,
    header: 'No results',
    text: 'There are no sample candidates results for the current filter. Change or reset the filter to load results.',
  },
  noTasks: {
    icon: Exclamation,
    header: 'No tasks',
    text: 'There are currently no sample candidates.',
  },
};

const aboutFeatureText = (
  <>
    <strong> How to use Sample Candidates </strong>
    <p>
      A Sample Candidate is a user message which likely would be a good sample for this relevant dialogue. Use this to
      add more samples to improve your bot and help it answer more questions correctly.
    </p>
    <p>
      If any personally identifiable information is included in a sample, for example phone numbers, these should be
      removed.
    </p>
    <p>
      Read more on our <HelpCenterLink>Help Center</HelpCenterLink>
    </p>
  </>
);

const SampleCandidate = () => {
  const [ignoredDialogues, setIgnoredDialogues] = useState<{ id: string }[]>([]);
  const [{ selectedLanguage }] = useCurrentLanguage();
  const [showAboutFeature] = useModal(AboutFeature);
  const { filters } = useSelector(selectLibraryFilters);
  const InfoBox = (
    <InfoButton
      className={styles.infoButton}
      onClick={() => showAboutFeature({ title: 'About Sample Candidates', text: aboutFeatureText })}
    />
  );
  const { botId } = useParams();
  const { dialoguesLoading, dialogueList, sampleCandidatesLoading, languageSampleCandidates } = useSampleCandidates(
    filters,
    botId,
  );

  const filteredSampleCandidates = useMemo(
    () =>
      orderBy(languageSampleCandidates || [], ['score'], ['desc']).filter(
        ({ languageCode, id }) => languageCode === selectedLanguage && !ignoredDialogues?.includes(id),
      ),
    [selectedLanguage, languageSampleCandidates, ignoredDialogues],
  );

  const [currentData, page, pages, setPage] = usePagination({ objects: filteredSampleCandidates, pageSize: 7 });

  if (!dialogueList) {
    return null;
  }

  const emptyState = isEmpty(filters) ? 'noTasks' : 'noResults';

  return (
    <LoaderSwitch loading={sampleCandidatesLoading || dialoguesLoading} size="large">
      <Section
        title="Sample candidates"
        listHeader={['Message', 'Recommended Dialogue']}
        hasData={!!filteredSampleCandidates?.length}
        InfoBox={InfoBox}
      >
        {!filteredSampleCandidates?.length ? (
          <EmptyState
            className={sharedStyles.emptyState}
            color="blue"
            description={stateInfo[emptyState].text}
            icon={stateInfo[emptyState].icon}
            title={stateInfo[emptyState].header}
          />
        ) : (
          <>
            {currentData.map((candidate) => (
              <CandidateRow
                key={candidate.id}
                sampleCandidate={candidate}
                dialogueList={dialogueList}
                languageCode={selectedLanguage}
                setIgnoredDialogues={setIgnoredDialogues}
                ignoredDialogues={ignoredDialogues}
              />
            ))}
            {pages > 1 && (
              <div className={styles.pagination}>
                <Pagination currentPage={page} pages={pages} setPage={setPage} />
              </div>
            )}
          </>
        )}
      </Section>
    </LoaderSwitch>
  );
};

SampleCandidate.propTypes = {
  filters: PropTypes.shape({}),
};

export default SampleCandidate;
