import { useMutation, useQuery } from '@apollo/client';
import { formatISO } from 'date-fns';
import { sum } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Badge, Button, LoaderSwitch, PageContent, Table } from 'frontend/components';
import { CLEAR_FEATURED_CHANGELOG_ENTRY } from 'frontend/features/Admin/views/Changelog/CLEAR_FEATURED_CHANGELOG_ENTRY';
import { GET_CHANGELOG } from 'frontend/features/Admin/views/Changelog/GET_CHANGELOG';
import { SET_FEATURED_CHANGELOG_ENTRY } from 'frontend/features/Admin/views/Changelog/SET_FEATURED_CHANGELOG_ENTRY';
import { SYNC_CHANGELOG } from 'frontend/features/Admin/views/Changelog/SYNC_CHANGELOG';
import { ChangelogEntryModal } from 'frontend/features/Changelog';
import { useModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

const TitleCell = ({ data: title, rowData: entry }) => (
  <>
    <Link to={`/admin/changelog/${entry.id}`}>{title}</Link>
    {entry.featured && <Badge title="⭐ Featured" tooltip="This entry is featured" />}
  </>
);

const updateOnSync = (cache, { data }) => cache.modify({ fields: { changelog: () => data.syncChangelog.entries } });

const updateOnFeature = (cache, { data }) => {
  // Unset previous featured entry
  const { changelog } = cache.readQuery({ query: GET_CHANGELOG });
  const prevFeaturedEntry = changelog.find((entry) => entry.id !== data.setFeaturedChangelogEntry.id && entry.featured);
  if (!prevFeaturedEntry) return;

  cache.modify({
    id: cache.identify(prevFeaturedEntry),
    fields: {
      featured: () => false,
    },
  });
};

const Actions = ({
  rowData: { id: entryId, featured, status },
  columnData: { showChangelogEntryModal, setFeaturedChangelogEntry },
}) => (
  <>
    <Button size="small" onClick={() => showChangelogEntryModal({ entryId })} text="Preview" className="m-r-sm" />
    {!featured && status !== 'draft' && (
      <Button size="small" onClick={() => setFeaturedChangelogEntry({ variables: { entryId } })} text="Feature" />
    )}
  </>
);
const Changelog = () => {
  const toast = useToast();
  const { data, loading } = useQuery(GET_CHANGELOG);
  const [showChangelogEntryModal] = useModal(ChangelogEntryModal);
  const [syncChangelogMutation] = useMutation(SYNC_CHANGELOG, { update: updateOnSync });
  const [setFeaturedChangelogEntry] = useMutation(SET_FEATURED_CHANGELOG_ENTRY, { update: updateOnFeature });
  const [clearFeaturedChangelogEntry] = useMutation(CLEAR_FEATURED_CHANGELOG_ENTRY);

  const columns = useMemo(
    () => [
      { key: 'title', render: TitleCell },
      { key: 'types', render: ({ data: types }) => types.join(', ') },
      { key: 'status' },
      {
        key: 'publishedAt',
        render: ({ data: publishedAt }) => (publishedAt ? formatISO(publishedAt) : ''),
      },
      {
        key: 'actions',
        render: Actions,
        data: { setFeaturedChangelogEntry, showChangelogEntryModal },
      },
    ],
    [setFeaturedChangelogEntry, showChangelogEntryModal],
  );

  const syncChangelog = useCallback(async () => {
    const {
      data: {
        syncChangelog: { changes },
      },
    } = await syncChangelogMutation();
    const [created, updated, deleted] = changes;
    const hasChanges = sum(changes) !== 0;
    const msg = hasChanges ? `${created} created, ${updated} updated and ${deleted} deleted.` : 'Nothing has changed';
    toast.success(`Sync complete: ${msg}`);
  }, [syncChangelogMutation, toast]);

  const tableData = data?.changelog ?? [];

  return (
    <PageContent>
      <LoaderSwitch loading={loading}>
        <Button text="Sync changelog" onClick={syncChangelog} className="m-b-sm" color="primary" />
        <Button text="Unset featured" onClick={clearFeaturedChangelogEntry} className="m-b-sm m-l-sm" />
        <p>Changelog is synced from Canny. Entries published more than 30 days ago are not shown to users.</p>
        <Table columns={columns} data={tableData} />
      </LoaderSwitch>
    </PageContent>
  );
};
export default Changelog;
