import noHits from 'frontend/assets/images/no-hits.svg?url';

import styles from './NoHits.scss';

interface NoHitsProps {
  name: string;
}

const NoHits = ({ name }: NoHitsProps) => (
  <div className={styles.container}>
    <img className={styles.avatar} src={noHits} alt="Confused avatar" />
    <div className={styles.text}> No {name} found</div>
  </div>
);

export default NoHits;
