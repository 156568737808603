const SIZES = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
const KILO = 1024;

export default (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const sizeIdx = Math.floor(Math.log(bytes) / Math.log(KILO));

  return `${parseFloat((bytes / KILO ** sizeIdx).toFixed(decimals))} ${SIZES[sizeIdx]}`;
};
