import cx from 'classnames';

import styles from './Panel.scss';

interface HorizontalRuleProps {
  className?: string;
}

const HorizontalRule = ({ className }: HorizontalRuleProps) => <hr className={cx(styles.horizontalRule, className)} />;

export default HorizontalRule;
