import PropTypes from 'prop-types';

import { StatsPanel } from 'frontend/features/Analytics/components/';
import { useSageData } from 'frontend/features/Analytics/hooks';

import HandoverNumbers from '../HandoverNumbers/HandoverNumbers';
import styles from '../HandoverNumbers/styles.scss';

const HandoverWaitTime = ({ filters, scope, priority }) => {
  const { loading, error, data } = useSageData(scope, '/takeovers/wait_time', filters, {
    priority,
  });

  return (
    <StatsPanel loading={loading} error={error} className={styles.statsPanel}>
      <HandoverNumbers data={data} title="Wait time" description="Average wait time" />
    </StatsPanel>
  );
};

HandoverWaitTime.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  scope: PropTypes.string.isRequired,
  priority: PropTypes.number,
};

export default HandoverWaitTime;
