import cx from 'classnames';
import Slider from 'rc-slider';
import { Field, type FieldRenderProps } from 'react-final-form';

import 'rc-slider/assets/index.css';
import styles from './Range.scss';

interface RangeSliderProps {
  displayFixedDecimals?: number;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  color?: string;
  unit?: string;
  className?: string;
  onChange?(...args: unknown[]): unknown;
  displayValue?(val: number): number;
  hideDisplayValue?: boolean;
}

const RangeSlider = ({
  value,
  onChange,
  color,
  className,
  min = 0,
  max = 1,
  step = 0.1,
  unit = '',
  displayValue = (val) => val,
  displayFixedDecimals = undefined,
  hideDisplayValue = false,
}: RangeSliderProps) => {
  const decimals = step.toString().split('.');
  const defaultDecimalPlaces = decimals.length === 2 ? decimals[1]?.length : 0;
  const sliderClassNames = cx(styles.range, { [styles.rangeDeactive]: color === 'deactive' });
  const tabIndex = color === 'deactive' ? -1 : 0;

  return (
    <div className={cx(sliderClassNames, className)}>
      <Slider min={min} max={max} step={step} value={value} onChange={onChange} tabIndex={tabIndex} />
      {!hideDisplayValue && (
        <span className={styles.unit}>
          {displayValue(value).toFixed(displayFixedDecimals === null ? defaultDecimalPlaces : displayFixedDecimals)}
          {unit}
        </span>
      )}
    </div>
  );
};

interface RangeProps {
  name: string;
  displayFixedDecimals?: number;
  min?: number;
  max?: number;
  step?: number;
  color?: string;
  unit?: string;
  className?: string;
  displayValue?(val: number): number;
  hideDisplayValue?: boolean;
  parse?: FieldRenderProps<number>['parse'];
  format?: FieldRenderProps<number>['format'];
}

const Range = ({
  name,
  color,
  min,
  max,
  step,
  unit,
  className,
  displayValue,
  displayFixedDecimals,
  hideDisplayValue,
  parse,
  format,
}: RangeProps) => (
  <Field
    name={name}
    parse={parse}
    format={format}
    render={({ input: { onChange, value } }) => (
      <RangeSlider
        value={value || 0}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        displayFixedDecimals={displayFixedDecimals}
        unit={unit}
        displayValue={displayValue}
        color={color}
        className={className}
        hideDisplayValue={hideDisplayValue}
      />
    )}
  />
);

export default Range;
