import { useParams } from 'react-router-dom';

import nudgeIllustration from 'frontend/assets/images/nudge-illustration.png';
import { Panel } from 'frontend/components';

import choiceIllustration from './assets/choice-nudge.png';
import customIllustration from './assets/custom-nudge.png';
import formIllustration from './assets/form-nudge.png';
import productIllustration from './assets/product-nudge.png';
import randomFormIllustration from './assets/random-form.png';
import { CreateCard } from './components';
import styles from './styles.scss';

const NudgeEditor = () => {
  const { botId } = useParams();

  return (
    <>
      <Panel className={styles.emptyPanel}>
        <div>
          <h1>Engage your users with Nudge!</h1>
          <p className="m-b-0">
            Nudge is a great way to create an enhanced user experience. You can tip your users about great offers, or
            encourage them to sign up to your newsletter, among other things. Start now and nudge your users today!
          </p>
        </div>
        <img className={styles.nudgeIllustration} src={nudgeIllustration as string} alt="Nudge layout" />
      </Panel>
      <div className={`${styles.cardContainer} m-t-2`}>
        <CreateCard
          title="Form"
          text="Use a form nudge to engage your customers to sign up for your newsletter, report data or generate leads to boost your sales"
          link={`/workspace/${botId}/build/proactivity/nudge/new-form`}
          image={formIllustration as string}
        />
        <CreateCard
          title="Product recommendation"
          text="Show tailored products to your customers. Get them talking and engaging with your products"
          link={`/workspace/${botId}/build/proactivity/nudge/new-product`}
          image={productIllustration as string}
        />
        <CreateCard
          title="Multiple choice"
          text="Ask questions to get multiple choice style answers back"
          link={`/workspace/${botId}/build/proactivity/nudge/new-multiple-choice`}
          image={choiceIllustration as string}
        />
        <CreateCard
          title="Text"
          text="Use a text nudge to deliver news and deals to your customers"
          link={`/workspace/${botId}/build/proactivity/nudge/new-text`}
          image={randomFormIllustration as string}
        />
        <CreateCard
          title="Custom nudge"
          text="Create text fields to be used in a customized Nudge"
          link={`/workspace/${botId}/build/proactivity/nudge/new-custom`}
          image={customIllustration as string}
        />
      </div>
    </>
  );
};

export default NudgeEditor;
