import cx from 'classnames';
import { Link, type NavigateFunction, useNavigate } from 'react-router-dom';

import type { BotType } from 'frontend/api/generated';
import { Buildings, LogOut } from 'frontend/assets/icons';
import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import defaultAvatar from 'frontend/assets/images/member_avatar.svg?url';
import { Icon, List } from 'frontend/components';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { onEnter } from 'frontend/utils';

import styles from './styles.scss';

type UserMenuData = {
  title: string;
  key: string;
  onClick?: () => NavigateFunction | void;
  href?: string;
  className?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  avatarUrl?: string;
  name?: string;
  projectName?: string;
};

type FavoriteBot = Pick<BotType, 'id' | 'avatarUrl' | 'name' | 'projectName'>;

interface UserMenuProps {
  close?: () => void;
  onSignOut: () => void;
  userName: string;
  userAvatar: string;
  favoriteBots: FavoriteBot[];
}

const UserMenu = ({ close = () => null, onSignOut, userName, userAvatar, favoriteBots }: UserMenuProps) => {
  const navigate = useNavigate();
  const { platformPermissions } = useMyPermissions();
  const isKindlyAdminViewer = platformPermissions?.some((perm) => perm.includes('view_admin'));
  const hasFavoriteBots = favoriteBots.length > 0;

  const favoriteBotsList = hasFavoriteBots
    ? favoriteBots.map(({ id, avatarUrl, name, projectName }) => ({
        title: projectName || name,
        onClick: () => navigate(`/workspace/${id}`),
        avatarUrl: avatarUrl || defaultBotAvatar,
        key: `navbar-usermenu-${id}-key`,
        className: 'listWorkspaceItem',
      }))
    : [];

  const data = [...favoriteBotsList];

  return (
    <List
      width="254px"
      dataSource={data}
      className={styles.listWrapper}
      headerClassName={styles.listHeader}
      bodyClassName={styles.listItemsWrapper}
      renderHeader={() => (
        <>
          <div className={styles.userContainer}>
            <img className={styles.avatar} src={userAvatar || defaultAvatar} alt={userName} />
            <div className={styles.name}>{userName}</div>
            <Link className={cx(styles.link, 'link')} to="/profile">
              Manage profile
            </Link>
          </div>
          <div className={styles.divider} />
          {isKindlyAdminViewer && (
            <Link to="/admin" className={styles.infoMenuItem}>
              <span>
                <div className={cx(styles.title, 'kindlyAdminManager')}>Admin</div>
              </span>
            </Link>
          )}
          <Link to="/organizations" className={styles.infoMenuItem}>
            <span>
              <Icon component={Buildings} className={styles.iconLink} />
              <div className={styles.title}>My organizations</div>
            </span>
          </Link>
          <div
            onClick={() => onSignOut()}
            onKeyPress={onEnter(onSignOut)}
            className={styles.infoMenuItem}
            role="button"
            tabIndex={0}
          >
            <span>
              <Icon component={LogOut} className={styles.iconLink} />
              <div className={styles.title}>Sign out</div>
            </span>
          </div>
          {hasFavoriteBots && (
            <div className={cx(styles.infoMenuItem, styles.listSubHeader)}>
              <span>
                <div className={styles.title}>Your favorite workspaces</div>
              </span>
            </div>
          )}
        </>
      )}
      renderItem={(item: UserMenuData) => (
        <List.Item
          className={cx(styles.infoMenuItem, item.className)}
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }

            close();
          }}
          key={item.key}
          tabIndex={item.href ? -1 : 0}
        >
          {item.href ? (
            <a rel="noopener noreferrer" target="_blank" href={item.href}>
              {item.icon && <Icon component={item.icon} className={styles.iconLink} />}
              <div className={styles.title}>{item.title}</div>
            </a>
          ) : (
            <span>
              {item.avatarUrl && (
                <img
                  src={item.avatarUrl}
                  className={styles.workspaceAvatar}
                  alt={`Workspace avatar for ${item?.projectName || item?.name}`}
                />
              )}
              {item.icon && <Icon component={item.icon} className={styles.iconLink} />}
              <div className={styles.title}>{item.title}</div>
            </span>
          )}
        </List.Item>
      )}
    />
  );
};

export default UserMenu;
