import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Checkbox, Radio } from 'frontend/components';
import { BUTTON_TYPE_NAMES } from 'frontend/features/Build/constants';

import styles from './ButtonsFilter.scss';
import { buttonTypes as BUTTON_TYPES } from './constants';

const FilterOptions = ({ buttonTypes }) => (
  <div className={styles.wrapper}>
    <Field
      component={Radio}
      className={cx(styles.checkbox, styles.radio)}
      key="checkbox-type-all"
      name="buttonTypes.all"
      type="radio"
      label="All"
      value
    />
    {buttonTypes
      .filter((name) => name !== 'all')
      .map((type) => (
        <Field
          component={Checkbox}
          className={styles.checkbox}
          key={`checkbox-type${type}`}
          name={`buttonTypes.${type}`}
          type="checkbox"
          label={BUTTON_TYPE_NAMES[type]}
        />
      ))}
  </div>
);

FilterOptions.propTypes = {
  buttonTypes: PropTypes.arrayOf(PropTypes.oneOf(BUTTON_TYPES)).isRequired,
};

export default FilterOptions;
