import type { LabelType } from 'frontend/api/generated';

export default (context: string | Partial<LabelType>): Partial<LabelType> => {
  if (typeof context === 'string') {
    try {
      return JSON.parse(context);
    } catch (e) {
      return {};
    }
  } else {
    return context;
  }
};
