import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { type ChatMessagesQuery, WebhookRequestsDocument, type WebhookRequestsQuery } from 'frontend/api/generated';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

type Messages = NonNullable<NonNullable<ChatMessagesQuery>['chatMessages']>['messages'];

export type WebhookRequest = WebhookRequestsQuery['webhookRequests'][0];

function useWebhookRequests(
  botId: string,
  messages: Messages,
): {
  loading: boolean;
  webhooks: WebhookRequest[] | undefined;
} {
  const { hasBotPerms, loading: permsLoading } = useMyPermissions({ botId });
  const { chatId } = useParams();
  const canConnectBot = hasBotPerms('connect_bot');

  const { loading, data } = useQuery(WebhookRequestsDocument, {
    variables: { botId, chatId: chatId!, loggedBy: ['BOT_WEBHOOK'] },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !canConnectBot || !botId || !chatId,
  });

  const webhooks = useMemo(() => {
    const orderedMessages = orderBy(messages ?? [], 'created', 'asc');
    const oldestMessageDatetime = orderedMessages?.[0]?.created;

    return data?.webhookRequests?.filter((webhook) => webhook?.created > oldestMessageDatetime);
  }, [data, messages]);

  return { loading: loading || permsLoading, webhooks };
}

export default useWebhookRequests;
