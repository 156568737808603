import { get, isEmpty, isEqual, mapValues } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { max, number, required } from 'frontend/form/validators';
import { usePrevious } from 'frontend/hooks';
import { filterObject } from 'frontend/utils';

import { getValueValidator, validateField } from './validators';
import { BUTTON_TYPES } from '../../constants';

export const useLocalErrors = ({ button, getButtonErrors, ownerId, currentLanguage }) => {
  const [localErrors, setLocalErrors] = useState({});
  const [localSubmitFailed, setLocalSubmitFailed] = useState(false);
  const prevOwnerId = usePrevious(ownerId);
  const prevLanguage = usePrevious(currentLanguage);

  const updateLocalErrors = useCallback((errors) => {
    setLocalErrors(errors);
    setLocalSubmitFailed(!isEmpty(errors));
  }, []);

  useEffect(() => {
    if (!localErrors) return;
    const errors = getButtonErrors(button);
    if (!isEqual(localErrors, errors)) setLocalErrors(errors);
    if (isEmpty(errors)) setLocalSubmitFailed(false);
  }, [button, getButtonErrors, localErrors]);

  useEffect(() => {
    if (ownerId !== prevOwnerId || currentLanguage !== prevLanguage) updateLocalErrors({});
  }, [currentLanguage, ownerId, prevLanguage, prevOwnerId, updateLocalErrors]);

  return { localSubmitFailed, localErrors, updateLocalErrors };
};

const editTextValidator = (_, button) =>
  button?.buttonType === BUTTON_TYPES.CONTACT_DETAILS ? required(button?.contactDetails?.editText) : undefined;

const affixValidator = (value, button) => {
  if (button?.sliderDetails?.affixValue?.length > 0 && !value) return "This can't be blank";
  return undefined;
};

const affixValueValidator = (value, button) => {
  if (button?.sliderDetails?.affix?.length > 0 && !value) return "This can't be blank";
  return undefined;
};

const valueValidator = (value, button) => {
  if (value < button.sliderDetails.fromValue) return "Default Value can't be lower than From Value";
  if (value > button.sliderDetails.toValue) return "Default Value can't be bigger than To Value";
  return undefined;
};

const toValueValidator = (value, button) => {
  if (value <= button.sliderDetails.fromValue) return "To Value can't be less than From Value";
  return undefined;
};

const stepValueValidator = (value, button) => {
  if (value > button.sliderDetails.toValue - button.sliderDetails.fromValue) {
    return "Step Value can't be bigger than the range of values(To Value - From Value)";
  }
  return undefined;
};

const getSliderValidators = () => ({
  label: validateField('label', required, max(150)),
  fromValue: validateField('sliderDetails.fromValue', required, number),
  toValue: validateField('sliderDetails.toValue', required, number, toValueValidator),
  stepValue: validateField('sliderDetails.stepValue', required, number, stepValueValidator),
  value: validateField('sliderDetails.value', required, number, valueValidator),
  affix: validateField('sliderDetails.affix', affixValidator),
  affixValue: validateField('sliderDetails.affixValue', affixValueValidator, max(30)),
});

const getDefaultValidators = (buttonType, isActive) => ({
  label: validateField('label', required, max(150)),
  value: getValueValidator(buttonType, isActive),
  editText: editTextValidator,
  placeholder: max(30),
});

export const useValidation = ({ buttonType, isActive }) => {
  const validators = useMemo(
    () => (buttonType === BUTTON_TYPES.SLIDER ? getSliderValidators() : getDefaultValidators(buttonType, isActive)),
    [buttonType, isActive],
  );

  const getButtonErrors = useCallback(
    (potentialButton) =>
      filterObject(
        (value) => value !== undefined,
        mapValues(validators, (validate, fieldName) => validate(get(potentialButton, fieldName), potentialButton)),
      ),
    [validators],
  );

  return { validators, getButtonErrors };
};
