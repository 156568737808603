import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { type MouseEventHandler, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  BotIndicesDocument,
  type BotIndicesQuery,
  type DashboardBotPublishingModeChoices,
  IndexDraftDocument,
  PublishBotDocument,
} from 'frontend/api/generated';
import { Button, Panel } from 'frontend/components';
import { publishingTypes } from 'frontend/constants';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useToast } from 'frontend/hooks';
import { DATE_FORMAT_DATE, TIME_FORMAT_HOURS } from 'frontend/utils/date';

import styles from './BotIndexStatus.scss';

function formatDate(date: Date): string {
  return `${format(date, DATE_FORMAT_DATE)} - ${format(date, TIME_FORMAT_HOURS)}`;
}

type DraftIndexProps = {
  draftIndex: BotIndicesQuery['draftIndex'];
  mainAction: MouseEventHandler;
  reindexing: boolean;
};

function DraftIndex({ draftIndex, mainAction, reindexing }: DraftIndexProps) {
  const createdAt = draftIndex?.createdAt;
  const indexedAt = draftIndex?.indexedAt;

  return (
    <div className="m-b-4">
      <h3 className={styles.headline}>
        Draft index
        <Button onClick={mainAction} color="warning" size="small" isSubmitting={reindexing}>
          Reindex now
        </Button>
      </h3>

      {draftIndex ? (
        <>
          <div>
            <strong>Name: </strong>
            {draftIndex.name}
          </div>
          <div>
            <strong>Created: </strong>
            {createdAt ? formatDate(createdAt) : 'Unknown'}
          </div>
          <div>
            <strong>Indexed: </strong>
            {indexedAt ? formatDate(indexedAt) : 'Unknown'}
          </div>
          <div>
            <strong>Content changed at: </strong>
            {draftIndex.contentChangedAt ? formatDate(draftIndex.contentChangedAt) : '-'}
          </div>
          <div>
            <strong>State: </strong>
            {draftIndex.state}
          </div>
        </>
      ) : (
        <p>Does not have any draft index.</p>
      )}
    </div>
  );
}

type PublishedIndicesProps = {
  publishedIndices: BotIndicesQuery['publishedIndices'];
  mode: DashboardBotPublishingModeChoices;
  mainAction: MouseEventHandler;
  publishing: boolean;
};

function PublishedIndices({ publishedIndices, mode, mainAction, publishing }: PublishedIndicesProps) {
  return (
    <>
      <h3 className={styles.headline}>
        Published indices
        <Button
          onClick={mainAction}
          color="warning"
          size="small"
          disabled={mode === publishingTypes.AUTOMATIC.value}
          isSubmitting={publishing}
        >
          Publish now
        </Button>
      </h3>

      {publishedIndices ? (
        publishedIndices.map((item) => (
          <div key={item.id} className="m-b-2">
            <div>
              <strong>Name: </strong>
              {item.name}
            </div>
            <div>
              <strong>Activated: </strong>
              {item.activatedAt ? formatDate(item.activatedAt) : '-'}
            </div>
            <div>
              <strong>Deactivated: </strong>
              {item.deactivatedAt ? formatDate(item.deactivatedAt) : '-'}
            </div>
            <div>
              <strong>Created: </strong>
              {item.createdAt ? formatDate(item.createdAt) : '-'}
            </div>
            <div>
              <strong>State: </strong>
              {item.state}
            </div>
          </div>
        ))
      ) : (
        <p>Does not have any published indices.</p>
      )}
    </>
  );
}

function BotIndexStatus() {
  const toast = useToast();
  const { botId } = useParams();
  const [reindexingDraft, setReindexingDraft] = useState(false);
  const [publishingBot, setPublishingBot] = useState(false);
  const { data, loading, refetch } = useQuery(BotIndicesDocument, {
    variables: { botId: botId! },
    fetchPolicy: 'network-only',
  });
  const mode = data?.bot?.publishingMode;
  const draftIndex = data?.draftIndex ?? undefined;
  const publishedIndices = data?.publishedIndices ?? [];

  const [indexDraft] = useMutation(IndexDraftDocument);
  const [publishBot] = useMutation(PublishBotDocument);

  const onIndexDraft = useCallback(
    async ({ canSkip }: { canSkip: boolean }) => {
      try {
        setReindexingDraft(true);
        await indexDraft({ variables: { botId: botId!, canSkip } });
        toast.success('Started indexing');
      } catch (err) {
        console.error(err);
        setReindexingDraft(false);
      }
    },
    [botId, indexDraft, toast],
  );

  const onPublishBot = useCallback(async () => {
    try {
      setPublishingBot(true);
      await publishBot({ variables: { botId: botId! } });
      toast.success('Started indexing');
      refetch();
    } catch (err) {
      console.error(err);
      setPublishingBot(false);
    }
  }, [refetch, botId, publishBot, toast]);

  const onDraftIndexCompleted = useCallback(() => {
    setReindexingDraft(false);
    refetch();
    toast.success('Indexing complete');
  }, [refetch, toast]);

  const onPublishBotCompleted = useCallback(() => {
    setReindexingDraft(false);
    refetch();
    toast.success('Indexing complete');
  }, [refetch, toast]);

  const pusherChannel = getPusherChannel({ botId });
  usePusherEvent(pusherChannel, 'reindex-draft-completed', onDraftIndexCompleted);
  usePusherEvent(pusherChannel, 'publish-bot-completed', onPublishBotCompleted);

  if (loading || !mode) {
    return null;
  }

  return (
    <Panel className={styles.list}>
      <h1>Index status</h1>
      <p>
        Publishing mode is <code>{mode}</code>.
      </p>
      <DraftIndex
        draftIndex={draftIndex}
        mainAction={() => onIndexDraft({ canSkip: false })}
        reindexing={reindexingDraft}
      />
      <PublishedIndices
        publishedIndices={publishedIndices}
        mode={mode}
        mainAction={() => onPublishBot()}
        publishing={publishingBot}
      />
    </Panel>
  );
}

export default BotIndexStatus;
