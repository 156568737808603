import { zip } from 'lodash';

import { isEqualExceptField } from 'frontend/utils';

import composerStateToFormValues from './composerStateToFormValues';

const instancesEqual = ([instance1, instance2]) => isEqualExceptField('original', instance1, instance2);

export default (editorState1, { text: text2, instances: instances2 }) => {
  const { text: text1, instances: instances1 } = composerStateToFormValues(editorState1);

  return text1 === text2 && zip(instances1, instances2).every(instancesEqual);
};
