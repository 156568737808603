import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Trash } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';

import styles from './BackupOptions.scss';
import { DeleteBackup } from '../../modals';

const BackupOptions = ({ data: { type, backup, botId, downloadBackup } }) => {
  const [showDeleteModal] = useModal(DeleteBackup);

  const onClickDelete = useCallback(
    () => showDeleteModal({ botId, backup, type }),
    [backup, botId, showDeleteModal, type],
  );

  const onClickDownload = useCallback(() => downloadBackup(backup.id), [backup.id, downloadBackup]);

  return (
    <div className={styles.optionsWrapper}>
      {backup.status === 'Finished' && (
        <Button className="m-r-sm" size="small" disabled={backup.status !== 'Finished'} onClick={onClickDownload}>
          Download
        </Button>
      )}
      {backup.status !== 'Processing' && (
        <Icon
          component={Trash}
          color="default"
          hoverColor="warning"
          title="Remove"
          onClick={onClickDelete}
          className={styles.deleteIcon}
        />
      )}
    </div>
  );
};

BackupOptions.propTypes = {
  data: PropTypes.exact({
    type: PropTypes.string.isRequired,
    botId: PropTypes.string.isRequired,
    backup: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string,
    }).isRequired,
    downloadBackup: PropTypes.func.isRequired,
  }).isRequired,
};

export default BackupOptions;
