import { useMutation } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback, useMemo } from 'react';

import { UpdateProfileDocument } from 'frontend/api/generated';
import { useOnSubmitWithUpload, useToast } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';

const useProfileSettings = () => {
  const toast = useToast();
  const { data, loading } = useMe();
  const me = data?.me;
  const [updateSettings] = useMutation(UpdateProfileDocument);

  const onSubmit = useCallback(
    async (values) => {
      const variables = {
        ...omit(values, ['avatarUrl']),
        deleteAvatar: values.avatarUrl === null,
      };

      await updateSettings({ variables });
      toast.success('Profile settings updated');
    },
    [toast, updateSettings],
  );

  const initialValues = useMemo(() => {
    const profile = me?.profile;

    return {
      email: me?.username,
      ...profile,
      deleteAvatar: false,
    };
  }, [me]);

  const [onSubmitWithUpload, setFile] = useOnSubmitWithUpload(onSubmit, 'api/v2/user/upload-avatar/');

  return { loading, onSubmit: onSubmitWithUpload, setFile, initialValues };
};

export default useProfileSettings;
