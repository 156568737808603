import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { RemoveBotMembershipDocument, RemoveOrganizationMembershipDocument } from 'frontend/api/generated';
import { LoaderSwitch } from 'frontend/components';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { IDType } from 'frontend/propTypes';

import { MEMBERSHIP_TYPES } from '../../constants';
import { membershipRefetches } from '../../utils';

const RemoveOwnMembership = ({ args: { membershipType, botId, organizationId }, hide }) => {
  const toast = useToast();
  const { data, loading } = useMe();
  const isWorkspace = membershipType === MEMBERSHIP_TYPES.WORKSPACE;
  const userId = data?.me?.id;

  const refetchQueries = membershipRefetches({ botId, organizationId, membershipType });
  const [removeBotMembership] = useMutation(RemoveBotMembershipDocument, {
    variables: { userId: userId!, botId },
    refetchQueries,
  });
  const [removeOrganizationMembership] = useMutation(RemoveOrganizationMembershipDocument, {
    variables: { userId: userId!, organizationId, retainLastMembership: true },
    refetchQueries,
  });

  const onSubmit = useCallback(async () => {
    const mutation = isWorkspace ? removeBotMembership : removeOrganizationMembership;
    await mutation();
    toast.success(`${data?.me?.profile?.fullName}'s ${membershipType} membership was removed`);
  }, [data, isWorkspace, membershipType, removeBotMembership, removeOrganizationMembership, toast]);

  return (
    <DeleteModal hide={hide} action="leave" name={membershipType} onSubmit={onSubmit}>
      <LoaderSwitch size="large" noMargin loading={loading}>
        Are you sure you want to leave this {membershipType}?
        {isWorkspace && (
          <p className="m-t-2 m-b-0">
            Note that your organization membership will remain. It can be removed{' '}
            <Link className="link" to={`/organization/${organizationId}`}>
              here
            </Link>
          </p>
        )}
      </LoaderSwitch>
    </DeleteModal>
  );
};

RemoveOwnMembership.propTypes = {
  args: PropTypes.exact({
    membershipType: PropTypes.oneOf(Object.values(MEMBERSHIP_TYPES)).isRequired,
    botId: IDType,
    organizationId: IDType.isRequired,
  }).isRequired,
  hide: PropTypes.func.isRequired,
};

export default RemoveOwnMembership;
