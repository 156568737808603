import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { ChevronLeft } from 'frontend/assets/icons';
import { Button, Loader, Panel } from 'frontend/components';
import { SimpleEvalSetup } from 'frontend/features/Evaluation/components';

import styles from '../Evaluation.scss';
import { GET_SIMPLE_EVALUATION } from '../graphql';

const SimpleEvaluationDetail = () => {
  const { botId } = useParams();
  const { evaluationId } = useParams();
  const { data, loading } = useQuery(GET_SIMPLE_EVALUATION, { variables: { evaluationId } });

  if (loading) {
    return <Loader size="large" />;
  }
  const { simpleEvaluation: evaluation } = data;

  return (
    <div className={styles.reportContainer}>
      <h1 className="m-b-0">Simple evaluation: {evaluation.title}</h1>
      <p className="m-t-0 m-b">
        Language: <strong>{evaluation.languageCode}</strong>
        <br />
        State: <strong>{evaluation.state}</strong>
      </p>
      <Button
        flat
        icon={ChevronLeft}
        to={`/workspace/${botId}/build/evaluation/`}
        className={styles.backButton}
        text="Back"
      />
      <div className={styles.evalDetailGrid}>
        <SimpleEvalSetup evaluation={evaluation} botId={botId} />
        <Panel className="m-t">
          <h2>Results</h2>
          <p>The results are stored as JSON and can be downloaded for further analysis</p>
          <Button href={evaluation.results} disabled={!evaluation.results} color="primary">
            Download results
          </Button>
        </Panel>
      </div>
    </div>
  );
};

export default SimpleEvaluationDetail;
