import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useViewConversation from './useViewConversation';
import { ORGANIZATION_BOTS } from '../queries';

export default () => {
  const { organizationId } = useParams();
  const viewConversation = useViewConversation();
  const { data: botsData, loading } = useQuery(ORGANIZATION_BOTS, {
    variables: { organizationId },
    skip: !organizationId,
  });

  const bots = useMemo(
    () => botsData?.organization?.bots?.filter?.(({ id }) => viewConversation({ botId: id })),
    [botsData, viewConversation],
  );

  return { bots, loading };
};
