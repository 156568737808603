import { useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { updateBotsOrSkillsInCache } from 'frontend/api/cacheHelpers';
import {
  OrganizationInvitationsDocument,
  OrganizationMetaDocument,
  OrganizationSettingsDocument,
  OrganizationUsersDetailDocument,
} from 'frontend/api/generated';
import { buildTypes } from 'frontend/constants';
import { ORGANIZATION_USERS } from 'frontend/features/Organization/queries';
import { useToast } from 'frontend/hooks';
import { resourceRemoved } from 'frontend/state/dux/navbar';
import { fetchIfNecessary } from 'frontend/utils';

const missingKindlyEntitiesWarning = (missingKindlyEntities, toast, id) => {
  if (missingKindlyEntities && missingKindlyEntities.length > 0) {
    const entityNames = missingKindlyEntities.map(({ name: entityName }) => `'${entityName}'`).join(', ');
    toast.linkWarning(
      `Some Kindly entities are missing from '${window.name}', namely: ${entityNames}. For the relevant dialogues to
      function correctly they must be added under Kindly Entities (click this message...)`,
      `/workspace/${id}/build/entities`,
      { autoClose: false },
    );
  }
};

const botsUpdate =
  ({ client, dispatch }) =>
  ({ organizationId, deletedBotId }) => {
    if (deletedBotId) {
      dispatch(resourceRemoved({ id: deletedBotId, resourceType: 'bot' }));
    }

    updateBotsOrSkillsInCache(client, buildTypes.BOT, organizationId);
  };

const skillsUpdate =
  ({ client, dispatch }) =>
  ({ organizationId, deletedSkillId }) => {
    if (deletedSkillId) {
      dispatch(resourceRemoved({ id: deletedSkillId, resourceType: 'skill' }));
    }

    updateBotsOrSkillsInCache(client, buildTypes.SKILL, organizationId);
  };

const membersUpdate =
  ({ client }) =>
  ({ organizationId }) => {
    [
      OrganizationMetaDocument,
      OrganizationSettingsDocument,
      ORGANIZATION_USERS,
      OrganizationUsersDetailDocument,
    ].forEach((query) => {
      fetchIfNecessary({ client, query, variables: { id: organizationId } });
    });
  };

const invitationsUpdate =
  ({ client }) =>
  ({ organizationId }) => {
    fetchIfNecessary({ client, query: OrganizationInvitationsDocument, variables: { organizationId } });
  };

const botToSkillUpdate =
  ({ client, toast }) =>
  ({ id, name, executing, organizationId }) => {
    updateBotsOrSkillsInCache(client, buildTypes.SKILL, organizationId);
    if (executing) {
      toast.linkSuccess(`Skill '${name}' (created from bot) is ready!`, `/skill/${id}`);
    }
  };

const clonedBotUpdate =
  ({ client, toast }) =>
  ({ id, name, executing, missingKindlyEntities, organizationId }) => {
    missingKindlyEntitiesWarning(missingKindlyEntities, toast, id);
    updateBotsOrSkillsInCache(client, buildTypes.BOT, organizationId);
    if (executing) {
      toast.linkSuccess(`Workspace clone '${name}' is ready!`, `/workspace/${id}`);
    }
  };

const clonedSkillUpdate =
  ({ client, toast }) =>
  ({ id, name, executing, organizationId }) => {
    updateBotsOrSkillsInCache(client, buildTypes.SKILL, organizationId);
    if (executing) {
      toast.linkSuccess(`Skill clone '${name}' is ready!`, `/skill/${id}`);
    }
  };

const skippedItemsWarning = (toast, itemName, targetName, skippedArray) => {
  if (!skippedArray || skippedArray.length === 0) {
    return;
  }

  const names = skippedArray.map(({ name }) => `'${name}'`).join(', ');

  toast.warning(
    `Some ${itemName} names already existed in '${targetName}'. Assuming that these are the same
      entities as the ones transferred: ${names}`,
    { autoClose: false },
  );
};

const buildItemTransferredUpdate =
  ({ toast }) =>
  ({ skippedEntities, skippedSlots, targetName, type, renamedSlugs, targetId, missingKindlyEntities }) => {
    missingKindlyEntitiesWarning(missingKindlyEntities, toast, targetId);
    toast.success(`${type} was transferred to '${targetName}'!`);

    skippedItemsWarning(toast, 'entity', targetName, skippedEntities);
    skippedItemsWarning(toast, 'slot', targetName, skippedSlots);

    if (renamedSlugs && renamedSlugs.length > 0) {
      const renamed = renamedSlugs.map(([from, to]) => `'${from}' → '${to}'`).join(', ');
      toast.warning(`Some trigger dialogue slugs were renamed to avoid name clashes:\n${renamed}`, {
        autoClose: false,
      });
    }
  };

export default () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const toast = useToast();

  const events = useMemo(
    () => ({
      'bots-updated': botsUpdate({ client, dispatch }),
      'skills-updated': skillsUpdate({ client, dispatch }),
      'members-updated': membersUpdate({ client }),
      'invitations-updated': invitationsUpdate({ client }),
      'bot-to-skill': botToSkillUpdate({ client, toast }),
      'cloned-bot': clonedBotUpdate({ client, toast }),
      'cloned-skill': clonedSkillUpdate({ client, toast }),
      'build-item-transferred': buildItemTransferredUpdate({ toast }),
    }),
    [client, dispatch, toast],
  );

  return events;
};
