import cx from 'classnames';
import { type MouseEventHandler, useCallback } from 'react';

import { Check } from 'frontend/assets/icons';
import { Icon, ImageAvatar } from 'frontend/components';
import type { InboxAgent, Ticket } from 'frontend/features/Inbox/types';
import { useAssignUserToTicket } from 'frontend/features/Inbox/views/MetaData/hooks';

import styles from './Agent.scss';

interface Props {
  ticket: Ticket;
  agent: InboxAgent;
}

const Agent = ({ agent, ticket }: Props) => {
  const { assignUser, userAssigned } = useAssignUserToTicket({ ticket, agent });
  const {
    username,
    profile: { fullName, avatarUrl },
  } = agent;

  const onEnter = useCallback(
    (e) => {
      if (e.key === 'Enter') assignUser();
    },
    [assignUser],
  );

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={assignUser as MouseEventHandler}
      onKeyDown={onEnter}
      className={cx(styles.agent, {
        [styles.agentAssigned]: userAssigned,
      })}
    >
      <div className={styles.agentImageWrapper}>
        {userAssigned && <Icon component={Check} color="white" className={styles.agentCheckIcon} />}
        <ImageAvatar img={avatarUrl} alt={`User avatar for ${fullName}`} className={styles.agentImage} />
      </div>
      <span className={styles.agentName}>{fullName || username}</span>
    </div>
  );
};

export default Agent;
