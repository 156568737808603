import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Close } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { useMixpanel, useToast } from 'frontend/hooks';

import styles from './DialogueActions.scss';
import { IGNORE_INCOMPLETE_DIALOGUE } from '../../mutations';

const DialogueActions = ({ dialogueId, botId, setIgnored }) => {
  const { mixpanel } = useMixpanel();
  const toast = useToast();
  const [ignore] = useMutation(IGNORE_INCOMPLETE_DIALOGUE, {
    variables: {
      botId,
      dialogueId,
    },

    update: () => {
      // FIXME refactor to update apollo cache
      setIgnored(true);
      toast.success('Dialogue was ignored');
      mixpanel.track('Ignore Incomplete Dialogue sample', { botId, dialogueId });
    },
    onError: () => toast.error('Something went wrong, please try again'),
  });

  return (
    <div className={styles.actions}>
      <Button
        onClick={() => mixpanel.track('Complete incomplete dialogue', { botId, dialogueId })}
        className={styles.resolve}
        to={`../dialogue/${dialogueId}`}
      >
        Complete dialogue
      </Button>
      <Button icon={Close} className={styles.resolve} flat onClick={() => ignore()} />
    </div>
  );
};

DialogueActions.propTypes = {
  dialogueId: PropTypes.string,
  botId: PropTypes.string,
  setIgnored: PropTypes.func,
};

export default DialogueActions;
