import type { ApolloClient } from '@apollo/client';

import { updateArrayInCache } from 'frontend/api/cacheHelpers';
import {
  type CreateTopicMutation,
  type DeleteTopicMutation,
  LibraryTopicsDocument,
  type TopicType,
} from 'frontend/api/generated';

type Props = {
  variables: Record<string, unknown>;
  client: ApolloClient<object>;
} & (
  | {
      addedTopic: NonNullable<CreateTopicMutation['createTopic']>;
      removedTopic?: never;
    }
  | {
      addedTopic?: never;
      removedTopic: NonNullable<DeleteTopicMutation['deleteTopic']>;
    }
);
export default ({ variables, client, removedTopic, addedTopic }: Props) =>
  updateArrayInCache({
    variables,
    pathToArrayInCache: 'topics',
    query: LibraryTopicsDocument,
    cache: client,
    arrayUpdate: (topics: TopicType[]) =>
      addedTopic ? [...topics, addedTopic] : topics.filter(({ id: topicId }) => topicId !== removedTopic.id),
  });
