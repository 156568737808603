import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { BotDocument } from 'frontend/api/generated';
import { Connect, Home, Inbox, PieChart, Robot, Settings as SettingsIcon } from 'frontend/assets/icons';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import Menu from './Menu';

export default function BotMenu() {
  const { botId } = useParams();
  const { loading: botLoading, data: botData } = useQuery(BotDocument, {
    variables: { botId: botId! },
    skip: !botId,
  });

  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });

  const loading = botLoading || loadingPermissions;
  const isArchived = Boolean(botData?.bot?.isArchived);

  if (loading || isArchived) {
    return null;
  }

  const canConnectBot = hasBotPerms('connect_bot');
  const canManageBot = hasBotPerms('manage_bot');
  const canManageClustering = hasBotPerms('manage_clustering');
  const canManageNudges = hasBotPerms('manage_nudges');
  const canManageZodiac = hasBotPerms('manage_zodiac');
  const canViewConversations = hasBotPerms('view_conversations');
  const canViewTemplates = hasBotPerms('view_templates');

  const menuItems = [
    { name: 'Dashboard', slug: 'dashboard', icon: Home },
    {
      name: 'Analytics',
      slug: 'analytics',
      path: '/analytics/*',
      icon: PieChart,
      submenu: [
        {
          name: 'Analytics (new)',
          subNavigation: true,
          slug: 'new-analytics',
        },
        {
          name: 'Sessions',
          subNavigation: true,
          slug: 'sessions',
        },
        {
          name: 'Handover',
          subNavigation: true,
          slug: 'handover',
        },
        {
          name: 'Nudge',
          subNavigation: true,
          slug: 'nudge',
        },
        {
          name: 'Email',
          subNavigation: true,
          slug: 'email',
        },
        {
          name: 'Conversion',
          subNavigation: true,
          slug: 'conversion',
        },
      ],
    },
    {
      name: 'Insights',
      slug: 'insights',
      path: '/insights/*',
      hide: !canViewConversations,
      icon: Inbox,
    },
    {
      name: 'Build',
      hide: !canViewTemplates && !canManageNudges,
      slug: 'build',
      path: '/build/*',
      icon: Robot,
    },
    {
      name: 'Inbox',
      slug: 'inbox',
      path: '/inbox/*',
      hide: !canViewConversations,
      icon: Inbox,
      submenu: [
        {
          name: 'Inbox',
          subNavigation: true,
          slug: 'chat',
        },
        {
          name: 'Message groups',
          subNavigation: true,
          slug: 'message-groups',
          hide: !canManageClustering,
        },
      ],
    },
    {
      name: 'Train',
      slug: 'train',
      path: '/train',
      className: 'kindlyAdminManager',
      hide: !canManageZodiac,
    },
    {
      name: 'Connect',
      slug: 'connect',
      path: '/connect/*',
      hide: !canConnectBot,
      icon: Connect,
    },
    {
      name: 'Settings',
      slug: 'settings',
      path: '/settings/*',
      hide: !canManageBot,
      icon: SettingsIcon,
    },
  ];

  return <Menu menuItems={menuItems} />;
}
