import PropTypes from 'prop-types';

import { Equals, IDType } from 'frontend/propTypes';

export const TicketLogType = PropTypes.shape({
  action: PropTypes.string,
  valueBefore: PropTypes.string,
  valueAfter: PropTypes.string,
  created: PropTypes.string,
  user: PropTypes.shape({
    profile: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }),
});

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export const ChatMessageType = PropTypes.shape({
  id: IDType.isRequired,
  fromBot: PropTypes.bool,
  name: PropTypes.string,
  message: PropTypes.string,
  created: PropTypes.string,
  webHost: PropTypes.string,
  webPath: PropTypes.string,
  image: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
  imageCarousel: PropTypes.arrayOf(PropTypes.shape({})),
  email: PropTypes.string,
  emailCc: PropTypes.arrayOf(PropTypes.string),
});

export interface ChatMessageShape {
  buttons: Record<string, unknown>[];
  created: string;
  email: string;
  emailCc: string[];
  fromBot: boolean;
  fullName: string;
  id: string;
  image: string;
  imageCarousel: Record<string, unknown>[];
  message: string;
  name: string;
  webHost: string;
  webPath: string;
}

export const ConversationDataType = PropTypes.shape({
  bot: PropTypes.shape({
    id: IDType.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    avatarUrl: PropTypes.string,
    chat: PropTypes.shape({
      id: IDType.isRequired,
      avatarUrl: PropTypes.string,
      fullName: PropTypes.string,
      autoUser: PropTypes.shape({}),
      commentsCount: PropTypes.number.isRequired,
      /* FIXME(legacy-takeover): Rename fields */
      takeoverByAgentId: IDType,
      completedTakeover: PropTypes.bool,
      takenOver: PropTypes.bool,
    }).isRequired,
  }),
});

export const MessageOrLogType = PropTypes.oneOfType([ChatMessageType, TicketLogType]);

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export const MessagesDataType = PropTypes.exact({
  chatMessages: PropTypes.exact({
    id: IDType.isRequired,
    messages: PropTypes.arrayOf(ChatMessageType).isRequired,
    remaining: PropTypes.number.isRequired,
    __typename: Equals('ChatMessagesResponse').isRequired,
  }).isRequired,
});

export interface MessagesDataShape {
  chatMessages: {
    id: string | number;
    messages: ChatMessageShape[];
    remaining: number;
    __typename: 'ChatMessagesResponse';
  };
}
