import { pickBy } from 'lodash';
import { useMemo } from 'react';

import { PERMISSION_CATEGORIES } from '../../constants';

export const useRelevantPermissionCategories = (allPermissions: string[]): Record<string, Record<string, string>> =>
  useMemo(
    () =>
      Object.entries(PERMISSION_CATEGORIES).reduce((categories, [category, permissionObjects]) => {
        const relevantPermissionObjects = pickBy(permissionObjects, (_, permission) =>
          allPermissions.includes(permission),
        );

        if (Object.keys(relevantPermissionObjects).length === 0) return categories;
        return { ...categories, [category]: relevantPermissionObjects };
      }, {}),
    [allPermissions],
  );
