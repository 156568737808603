import { format } from 'date-fns/format';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import type { BotMembershipType, OrganizationMembershipType, UserType } from 'frontend/api/generated';
import { DropdownButton, ImageAvatar, Panel } from 'frontend/components';
import { AVAILABILITY } from 'frontend/features/Inbox/views/ChatViewSelector/AgentAvailability/AgentAvailability';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

import styles from './styles.scss';
import { RoleList } from '../../../../components';
import { useOptions } from '../../../../components/MemberList/MemberList';

const UserColumn = ({ user }: { user: UserType }) => (
  <>
    <ImageAvatar className={styles.avatar} img={get(user, 'profile.avatarUrl')} alt={get(user, 'profile.fullName')} />
    <span>{get(user, 'profile.fullName', user.username || '')}</span>
  </>
);

type Props = {
  user: UserType;
  organizationId?: string;
  membership: (BotMembershipType & { agentProfile: never }) | OrganizationMembershipType;
  showOptions: boolean;
  shouldShowStatus: boolean;
  botId?: string;
  isInheritedFromOrg: boolean;
};

function Member({ user, organizationId, membership, showOptions, shouldShowStatus, botId, isInheritedFromOrg }: Props) {
  const options = useOptions({ user, botId, organizationId, showOptions, isInheritedFromOrg });

  let status = '';

  if (shouldShowStatus && membership.agentProfile?.availability) {
    status = AVAILABILITY[membership.agentProfile.availability]?.text;
  }

  return (
    <Panel role="row" size="small">
      <div className={styles.userItem}>
        <div role="cell" className={styles.name}>
          {organizationId ? (
            <Link to={`/organization/${organizationId}/user/${user.id}`}>
              <UserColumn user={user} />
            </Link>
          ) : (
            <UserColumn user={user} />
          )}
        </div>
        <div role="cell" className={styles.bots}>
          <span className={styles.sortRoles}>{status}</span>
        </div>
        <div role="cell" className={styles.bots}>
          <span className={styles.sortRoles}>{user.username || ''}</span>
        </div>
        <div role="cell" className={styles.bots}>
          <span className={styles.sortRoles}>
            <RoleList roles={membership.roles} />
          </span>
        </div>
        <div role="cell" className={styles.lastLogin}>
          {user.lastLogin ? format(user.lastLogin, DATE_FORMAT_DATE) : ''}
        </div>
        <div role="cell" className={styles.actions}>
          {showOptions ? (
            <DropdownButton wrapperClassName={styles.options} actions={options}>
              <span className={styles.optionsButton} />
            </DropdownButton>
          ) : null}
        </div>
      </div>
    </Panel>
  );
}

export default Member;
