import { useApolloClient, useMutation } from '@apollo/client';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import { Eye, EyeOff, FollowUp, Pencil, TextCapitalize, Trash } from 'frontend/assets/icons';
import { DropdownButton, Loader } from 'frontend/components';
import DIALOGUE_TYPES, { type DialogueType } from 'frontend/constants/dialogueTypes';
import type { LabelColor } from 'frontend/features/Library/propTypes/ColorLabelType';
import { useBotOrSkill, useCurrentLanguage, useLanguages, useToast } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

import styles from './FlowNode.scss';
import DialogueIcon from '../../../Library/components/DialogueIcon';
import { useDeleteDialogue, useEnableDialogue, useEnableDialogueMod } from '../../../Library/hooks';
import { getDialogueTypeName } from '../../../Library/utils';
import { UpdateDialogueMutation } from '../../graphql';
import FlowDialoguePreview from '../FlowDialoguePreview';

interface FlowNode {
  dialogue: {
    advancedOptions: object;
    context: object;
    dialogueType: DialogueType;
    isActive: object;
    mod: string;
    keywords?: object[];
  };
  dialogueId: string;
  label: string;
  isActive: boolean;
  dialogueColorLabel: LabelColor;
  parentIsDisabled: boolean;
}

const FlowNode = ({ dialogue, dialogueId, label, isActive, dialogueColorLabel, parentIsDisabled }: FlowNode) => {
  const [showPreview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState(label);
  const [dropdownActive, setDropdownActive] = useState(false);
  const { dialogueId: currentDialogueId } = useParams();
  const [{ selectedLanguage, currentLanguage }] = useCurrentLanguage();
  const client = useApolloClient();
  const botOrSkillParams = useBotOrSkill();
  const { isBot, buildType, buildIdObject } = botOrSkillParams;
  const { languageNameMapVariants } = useLanguages({ isBot, buildIdObject, buildType } as BotOrSkill);
  const toast = useToast();
  const dialogueTypeName = getDialogueTypeName(dialogue.dialogueType);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();

  const deleteFunction = useDeleteDialogue({
    dialogue,
    selectedLanguage,
    botOrSkillParams,
    languageNameMapVariants,
    client,
    toast,
    shouldRedirect: false,
  });

  const onClickPreview = (event) => {
    if (!editingTitle) {
      setPreview(true);
    } else {
      event.stopPropagation();
    }
  };

  const enableMod = useEnableDialogueMod({
    dialogue,
    selectedLanguage,
    skillId: '0',
    disabled: !isActive,
    client,
    buildIdObject,
  });
  const enable = useEnableDialogue({
    dialogue,
    disabled: !isActive,
    selectedLanguage,
    buildIdObject,
    languageNameMapVariants,
    client,
    toast,
  });

  useEffect(() => {
    if (editingTitle) {
      const valueLength = textareaRef.current?.value.length || null;
      textareaRef.current?.setSelectionRange(valueLength, valueLength);
      textareaRef.current?.focus();
    }
  }, [editingTitle]);

  const [updateMutation] = useMutation(UpdateDialogueMutation);

  const onUpdateDialogue = async () => {
    setLoading(true);
    setEditingTitle(false);
    const dialogueInput = {
      advancedOptions: dialogue.advancedOptions,
      context: dialogue.context,
      isActive: dialogue.isActive,
      title: { [selectedLanguage]: title },
      dialogueType: dialogue.dialogueType,
      ...(dialogue.dialogueType === DIALOGUE_TYPES.KEYWORDS && { keywords: dialogue.keywords }),
    };

    const variables = { ...buildIdObject, dialogueId, languageCode: selectedLanguage, dialogueInput };
    await updateMutation({ variables });
    setLoading(false);
  };

  const actions = [
    {
      title: 'Edit dialogue name',
      icon: <TextCapitalize />,
      key: 'entity-option-edit-name',
      onClick: () => {
        setEditingTitle((currState) => !currState);
      },
    },
    {
      title: 'Create follow-up',
      icon: <FollowUp />,
      key: 'entity-option-create-follow-up',
      onClick: () => {
        const createDialogueUrl = `/workspace/${
          botOrSkillParams?.botId || botOrSkillParams?.skillId
        }/build/dialogue/new?parentId=${dialogueId}`;

        navigate(createDialogueUrl);
      },
    },
    {
      title: 'Edit dialogue',
      icon: <Pencil />,
      key: 'entity-option-edit-dialogue',
      onClick: () => {
        const editDialogueUrl = `/workspace/${
          botOrSkillParams?.botId || botOrSkillParams?.skillId
        }/build/dialogue/${dialogueId}`;

        navigate(editDialogueUrl);
      },
    },
    {
      title: `${isActive ? 'Disable' : 'Enable'} ${dialogueTypeName}`,
      icon: isActive ? <EyeOff /> : <Eye />,
      key: 'entity-option-edit',
      // eslint-disable-next-line no-nested-ternary
      onClick: !parentIsDisabled ? (dialogue?.mod ? enableMod : enable) : undefined,
      disabled: parentIsDisabled,
    },
    {
      title: 'Delete dialogue',
      icon: <Trash />,
      className: styles.deleteItem,
      key: 'entity-option-delete-dialogue',
      onClick: deleteFunction,
    },
  ];

  return (
    <div
      className={cx(styles.nodeInnerContent, styles.nodeWrapper, {
        [styles.currentDialogue]: currentDialogueId === dialogueId,
        [styles.disabled]: !isActive || parentIsDisabled,
        [styles.active]: dropdownActive,
        [styles.previewActive]: showPreview,
      })}
    >
      <div
        className={styles.flowDialogueHeadingWrapper}
        role="button"
        tabIndex={0}
        onKeyPress={onClickPreview}
        onClick={onClickPreview}
      >
        <DialogueIcon
          dialogueType={dialogue?.dialogueType}
          colorLabel={dialogueColorLabel}
          currentLanguage={currentLanguage}
          className={styles.dialogueDot}
        />
        <TextareaAutosize
          ref={textareaRef}
          disabled={!editingTitle}
          onBlur={() => {
            onUpdateDialogue();
          }}
          onChange={(event) => {
            setTitle(event.currentTarget.value);
          }}
          value={title}
          className={styles.editor}
        />
      </div>
      {loading ? (
        <Loader className={styles.saveLoader} />
      ) : (
        <DropdownButton
          actions={actions}
          position="bottom-right"
          wrapperClassName={`${styles.optionButton}${dropdownActive ? ` ${styles.optionButtonActive}` : ''}`}
          contentClassName={styles.optionDropdown}
          onToggle={(isShown) => setDropdownActive(isShown)}
        >
          <div className={styles.nodeOptions}>
            <span className={styles.optionDot} />
            <span className={styles.optionDot} />
            <span className={styles.optionDot} />
          </div>
        </DropdownButton>
      )}

      {showPreview && <FlowDialoguePreview dialogueId={dialogueId} setPreview={setPreview} />}
    </div>
  );
};

export default FlowNode;
