import { useApolloClient } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Close } from 'frontend/assets/icons';
import { Icon, Input } from 'frontend/components';
import { EVENT_KEYS, FIELD_COLOR } from 'frontend/constants';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import {
  resetLibrarySearch,
  searchLibrary,
  selectLibrarySearch,
  setLibrarySearchOpen,
  setLibrarySearchQuery,
  setLibrarySearchSelected,
} from 'frontend/state/dux/library';
import { useAppDispatch } from 'frontend/state/hooks';

import styles from './InputField.scss';
import useFocus from '../../hooks/useFocus';

export const InputField = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const focused = useFocus(inputRef);
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const navigate = useNavigate();

  const [{ selectedLanguage }] = useCurrentLanguage();
  const { buildIdObject, buildType } = useBotOrSkill();

  useEffect(() => {
    if (focused) {
      dispatch(setLibrarySearchOpen(true));
    }
  }, [focused, dispatch]);

  const { searchQuery, filters, selected, hits, isOpen } = useSelector(selectLibrarySearch);
  const { enabledDialogueLabels, enabledDialogueTypes, enabledDialogueColors } = filters;

  const dialogueHits = hits.dialogues;

  const reset = () => {
    dispatch(resetLibrarySearch(false));
    inputRef.current?.blur();
  };

  useEffect(() => {
    if (!searchQuery && isOpen) {
      inputRef.current?.focus();
    }
  }, [searchQuery, isOpen]);

  const onKeyDown = (event) => {
    switch (event?.key) {
      case EVENT_KEYS.ENTER:
        if (selected > -1 && selected < dialogueHits.length) {
          const selectedItem = dialogueHits[selected];
          if (selectedItem) {
            navigate(selectedItem.url);
            reset();
          }
        }
        break;
      case EVENT_KEYS.ESCAPE:
        reset();

        break;
      case EVENT_KEYS.ARROW_UP:
        if (dialogueHits && selected > 0) dispatch(setLibrarySearchSelected(selected - 1));
        else if (dialogueHits && selected <= 0) dispatch(setLibrarySearchSelected(dialogueHits.length - 1));
        break;
      case EVENT_KEYS.ARROW_DOWN:
        if (dialogueHits && selected < dialogueHits.length - 1) dispatch(setLibrarySearchSelected(selected + 1));
        else if (dialogueHits && selected >= dialogueHits.length - 1) dispatch(setLibrarySearchSelected(0));
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.searchContent}>
      <Input
        ref={inputRef}
        input={{
          value: searchQuery,
          onChange: ({ target }) => {
            dispatch(setLibrarySearchQuery(target.value));

            dispatch(
              searchLibrary({
                buildType,
                buildIdObject,
                languageCode: selectedLanguage,
                client,
                query: target.value,
                labels: enabledDialogueLabels,
                types: enabledDialogueTypes,
                colors: enabledDialogueColors,
              }),
            );
          },
          name: 'librarySearch',
        }}
        onKeyDown={onKeyDown}
        aria-label="Search in library"
        className={styles.searchInput}
        placeholder="Search"
        fieldColor={FIELD_COLOR.MISCHKA}
        autoComplete="off"
      />
      {isOpen && (
        <button type="button" tabIndex={-1} className={styles.searchClose} onClick={reset}>
          <Icon component={Close} title="Reset" className={styles.iconClose} />
        </button>
      )}
    </div>
  );
};
