import { gql } from '@apollo/client';

import { botBackupFragment } from 'frontend/api/fragments';

export default gql`
  mutation CreateBotBackup($botId: ID!) {
    createBotBackup(botId: $botId) {
      ...BotBackup
    }
  }
  ${botBackupFragment}
`;
