import { CACHE_TYPE } from 'frontend/features/Inbox/constants';
import { camelCaseKeys } from 'frontend/utils';

import prepareAgent from './prepareAgent';

const EMPTY_TICKET_LOG = {
  id: '',
  action: '',
  created: '',
  valueAfter: '',
  valueBefore: '',
  user: null,
  __typename: CACHE_TYPE.TICKET_LOG_TYPE,
};

export default (ticketLog) => ({
  ...EMPTY_TICKET_LOG,
  ...camelCaseKeys({
    ...ticketLog,
    user: prepareAgent(ticketLog.user),
  }),
});
