import PropTypes from 'prop-types';

import { InputErrorWrapper } from 'frontend/components';
import Composer from 'frontend/features/Composer';
import { EntityDropdown } from 'frontend/features/Entities/components';
import { useEntityDropdown } from 'frontend/features/Entities/hooks';
import { useCurrentLanguage } from 'frontend/hooks';

import styles from './EditSample.scss';
import { useEditSampleFinished, useOnSave } from './hooks';
import { useSampleError } from '../../hooks';

const EditSample = ({ name, sampleValidator, stopEditing, remove }) => {
  const [{ currentLanguage }] = useCurrentLanguage();

  const { extra, allEntities } = useEntityDropdown({ currentLanguage });
  const { error, setError } = useSampleError({ name, sampleValidator });

  const editSampleFinished = useEditSampleFinished({ name, stopEditing, setError, sampleValidator, remove });
  const onSave = useOnSave(editSampleFinished);

  return (
    <InputErrorWrapper hasError={!!error} displayError={error} errorMessageClassName={styles.errorMessage}>
      <Composer
        dropdownComponent={EntityDropdown}
        dropdownExtra={extra}
        name={`${name}.editorState`}
        potentialTagItems={allEntities}
        onClickOutside={editSampleFinished}
        onEscape={editSampleFinished}
        onEnter={editSampleFinished}
        onSave={onSave}
        className={styles.editor}
        maxLength={500}
        autoFocus
      />
    </InputErrorWrapper>
  );
};

EditSample.propTypes = {
  name: PropTypes.string.isRequired,
  stopEditing: PropTypes.func.isRequired,
  sampleValidator: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

export default EditSample;
