import { FORM_ERROR } from 'final-form';

interface GraphQLSubmitError {
  submitErrors: { [k: string]: string[] };
}

interface SubmitErrors {
  message?: string;
  graphQLErrors?: GraphQLSubmitError[];
}
const formatSubmitErrors = (err: SubmitErrors) => {
  if (err.graphQLErrors?.length !== 1 || !err.graphQLErrors?.[0]?.submitErrors) {
    return { [FORM_ERROR]: err.message };
  }
  const { submitErrors } = err.graphQLErrors[0];
  return Object.fromEntries(Object.entries(submitErrors).map(([key, val]) => [key, val.join('\n')]));
};
export default formatSubmitErrors;
