import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Tags } from 'frontend/components';

import styles from '../../../../../General/styles.scss';

const TAB_KEY_CODE = 9;
const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;
const ADD_KEYS = [ENTER_KEY_CODE, SPACE_KEY_CODE, TAB_KEY_CODE];

const AllowList = ({ currentLanguage }) => (
  <div>
    <h3>Allowlist</h3>
    <p>
      These words will not be corrected.
      <br />
      Only single words are allowed.
    </p>
    <div className="m-t-md m-b-sm">
      <div className={styles.inputContainer}>
        <Field
          component={Tags}
          name={`nlpSpellingCorrectionWhitelist.${currentLanguage}`}
          placeholder="Enter word"
          storeAsJson
          addKeys={ADD_KEYS}
        />
      </div>
    </div>
  </div>
);

AllowList.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
};

export default AllowList;
