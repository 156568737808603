import PropTypes from 'prop-types';

import { InfoIcon, LogItem } from 'frontend/features/Inbox/components';

const UserLeft = ({ userName, userLeft }) => (
  <LogItem timeStamp={userLeft} text={`${userName} left the chat`}>
    <InfoIcon />
  </LogItem>
);

UserLeft.propTypes = {
  userName: PropTypes.string.isRequired,
  userLeft: PropTypes.string.isRequired,
};

export default UserLeft;
