import { Link } from 'react-router-dom';

import defaultAvatar from 'frontend/assets/images/avatar.svg?url';
import { Panel } from 'frontend/components';
import type { BotLanguage } from 'frontend/types/language';

import styles from './styles.scss';

interface ActiveBotCardProps {
  bot: {
    id: string;
    name: string;
    languages: BotLanguage[];
    logo: string;
    organizationName: string;
    messages: string;
    percentage: string;
  };
}

const ActiveBotCard = ({ bot }: ActiveBotCardProps) => {
  const { name, languages, logo, organizationName, messages, percentage } = bot;

  return (
    <Panel size="medium" hover>
      <Link to={`/workspace/${bot.id}`}>
        <div className={styles.content}>
          <p className={styles.percentage}>{percentage}</p>
          <img className={styles.avatar} src={logo || defaultAvatar} alt="" />
          <div>
            <span className={styles.botName}>{name}</span>
            <p className={styles.orgName}>{organizationName}</p>
          </div>

          <div className={styles.languages}>
            {languages.map((language) => (
              <div key={language.code}>{language.code}</div>
            ))}
          </div>
          <div className={styles.statContainer}>
            <span className={styles.statHeader}>Messages</span>
            <span className={styles.statContent}>{messages}</span>
          </div>
        </div>
      </Link>
    </Panel>
  );
};

export default ActiveBotCard;
