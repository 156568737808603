import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Check } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { ContactDetailsType } from 'frontend/features/Inbox/propTypes';
import { useKeyDown } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';
import { CONTACT_DETAILS_STATUS } from 'frontend/state/dux/inbox';

import styles from './ContactStatusList.scss';
import { getStatus } from './utils';
import { useUpdateChatMeta } from '../../hooks';
import DropdownOverlay from '../DropdownOverlay';

const { done, requested } = CONTACT_DETAILS_STATUS;

const ContactStatusList = ({
  botId,
  close,
  email,
  phoneNumber,
  contactDetails,
  requestedContact,
  completedContact,
}) => {
  const { chatId } = useParams();

  const [updateChatMeta, isSubmitting] = useUpdateChatMeta({ botId, chatId });

  const setCompleted = useCallback(
    () => updateChatMeta({ email, phoneNumber, status: done }),
    [email, phoneNumber, updateChatMeta],
  );
  const setRequested = useCallback(
    () => updateChatMeta({ email, phoneNumber, status: requested }),
    [email, phoneNumber, updateChatMeta],
  );
  const setCompletedOnEnter = useKeyDown({ action: setCompleted });
  const setRequestedOnEnter = useKeyDown({ action: setRequested });

  return (
    <DropdownOverlay header="Contact status" close={close}>
      <div
        role="button"
        tabIndex={0}
        className={styles.listItem}
        onClick={setCompleted}
        onKeyDown={setCompletedOnEnter}
        disabled={isSubmitting}
      >
        {getStatus({ completedContact: true })}
        {completedContact && <Icon component={Check} color="black" className={styles.checkIcon} />}
      </div>
      <div
        role="button"
        tabIndex={0}
        className={styles.listItem}
        onClick={setRequested}
        onKeyDown={setRequestedOnEnter}
        disabled={isSubmitting}
      >
        {getStatus({ requestedContact: true, contactDetails })}
        {requestedContact && <Icon component={Check} color="black" className={styles.checkIcon} />}
      </div>
    </DropdownOverlay>
  );
};

ContactStatusList.propTypes = {
  close: PropTypes.func.isRequired,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  contactDetails: ContactDetailsType.isRequired,
  requestedContact: PropTypes.bool.isRequired,
  completedContact: PropTypes.bool.isRequired,
  botId: IDType.isRequired,
};

export default ContactStatusList;
