// @babel/preset-env replaces this with the specific polyfills based on our browserslist targets
// Ref: https://github.com/zloirock/core-js#babelpreset-env
import 'core-js/stable';
import { createRoot } from 'react-dom/client';

// The import order matters here: base.scss must be before App so that the base styles appear first
import './styles/base.scss';

import App from './App';
import sentryInit from './utils/sentryInit';

sentryInit();

const container = document.getElementById('app');
if (!container) {
  throw new Error('Missing #app');
}

const root = createRoot(container);
root.render(<App />);
