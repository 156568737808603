import { gql } from '@apollo/client';

import { TICKET_LOG } from 'frontend/features/Inbox/fragments';

export default gql`
  query GetTicketLog($chatId: ID!, $botId: ID!) {
    ticketLog(chatId: $chatId, botId: $botId) {
      ...TicketLog
    }
  }
  ${TICKET_LOG}
`;
