import cx from 'classnames';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

import type { DialogueRuleType } from 'frontend/api/generated';
import { DotsVertical, DoubleChevronLeft, DoubleChevronRight, Edit, SpeechMessage, Trash } from 'frontend/assets/icons';
import { Button, Dropdown, Icon } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useUrlSearchParams } from 'frontend/hooks';
import { flattenObject } from 'frontend/utils/flattenObject';

import styles from './RuleSelector.scss';
import { DEFAULT_RULE_ID, DEFAULT_RULE_OPERATOR } from '../../constants';
import AddRuleModal from '../../modals/AddRule/AddRuleModal';

interface Props {
  dialogueRules: Partial<DialogueRuleType>[];
}

export default function RuleSelector({ dialogueRules }: Props): React.JSX.Element {
  const form = useForm();
  const [{ rule: ruleId }, setParams, unsetParams] = useUrlSearchParams();
  const [showRuleModal] = useModal(AddRuleModal);

  const dialogueRulesWithDefault = [
    {
      id: undefined,
      name: 'Default rule',
      conditions: [],
      operator: DEFAULT_RULE_OPERATOR as DialogueRuleType['operator'],
    },
    ...dialogueRules,
  ];

  const { errors, submitFailed } = useFormState();
  const errorKeys = errors ? Object.keys(flattenObject(errors)).map((str) => str.toLowerCase()) : [];
  const dialogueRulesWithErrors = submitFailed
    ? dialogueRulesWithDefault.filter(({ id }) => {
        if (id) {
          return errorKeys.some((errorKey) => errorKey.includes(id.toLocaleLowerCase()));
        }
        return errorKeys.some((errorKey) => errorKey.includes(DEFAULT_RULE_ID));
      })
    : [];

  const selectedRuleIndex = Math.max(
    dialogueRulesWithDefault.findIndex(({ id }) => id === ruleId),
    0,
  );

  const selectedRule = dialogueRulesWithDefault[selectedRuleIndex]!;
  const previousRule = dialogueRulesWithDefault[selectedRuleIndex - 1];
  const nextRule = dialogueRulesWithDefault[selectedRuleIndex + 1];

  let hasPreviousRuleError = false;
  let hasCurrentRuleError = false;
  let hasNextRuleError = false;
  dialogueRulesWithDefault.forEach((dialogueRule, index) => {
    if (!dialogueRulesWithErrors.some(({ id }) => id === dialogueRule.id || DEFAULT_RULE_ID)) return;

    if (index < selectedRuleIndex) hasPreviousRuleError = true;
    if (index === selectedRuleIndex) hasCurrentRuleError = true;
    if (index > selectedRuleIndex) hasNextRuleError = true;
  });

  function toggleRuleURLParam(newRule: Partial<DialogueRuleType> | undefined) {
    if (newRule?.id) {
      setParams({ rule: newRule.id });
    } else {
      unsetParams(['rule']);
    }
  }

  return (
    <div className={styles.container}>
      <Button
        flat
        className={cx(styles.previousRuleButton, { [styles.redDot]: hasPreviousRuleError })}
        icon={DoubleChevronLeft}
        onClick={() => toggleRuleURLParam(previousRule)}
        disabled={!previousRule}
      />

      <div className={cx(styles.ruleIconWrapper, { [styles.redDot]: hasCurrentRuleError })}>
        <Icon component={SpeechMessage} className="vertical-middle" />
      </div>

      <div className={styles.rule}>
        <div className={cx(styles.ruleNameWrapper)}>{selectedRule.name}</div>
        {!!selectedRule.id && (
          <div className={styles.dropdown}>
            <Dropdown
              overlay={
                <Dropdown.MenuOverlay
                  options={[
                    {
                      icon: Edit,
                      text: 'Edit rule',
                      onClick: () => {
                        showRuleModal({
                          id: selectedRule.id,
                          modalType: 'edit',
                        });
                      },
                    },
                    {
                      icon: Trash,
                      text: 'Delete rule',
                      onClick: () => {
                        const newList = dialogueRules.filter(({ id }) => id !== selectedRule.id);
                        form.change(`dialogueRules`, newList);
                      },
                    },
                  ]}
                />
              }
              position="bottom"
              wrapperClassName={styles.settings}
            >
              <Icon component={DotsVertical} />
            </Dropdown>
          </div>
        )}
      </div>

      <Button
        flat
        icon={DoubleChevronRight}
        className={cx(styles.nextRuleButton, { [styles.redDot]: hasNextRuleError })}
        onClick={() => toggleRuleURLParam(nextRule)}
        disabled={!nextRule}
      />
    </div>
  );
}
