import { gql } from '@apollo/client';

const DELETE_EVALUATION_QUERY = gql`
  mutation DeleteEvaluation($queryId: ID!) {
    deleteEvaluationQuery(queryId: $queryId) {
      id
    }
  }
`;
export default DELETE_EVALUATION_QUERY;
