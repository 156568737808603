import classnames from 'classnames';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';

import type { WebhookRequest } from 'frontend/features/Insights/hooks/useWebhookRequests';

import Label from './Label';
import styles from './RequestDetails.scss';

/** Wrap react-json-view with defaults */
const JSONView = ({ data }) => (
  <ReactJson src={JSON.parse(data)} collapsed={2} sortKeys name={null} displayDataTypes={false} />
);

interface Props {
  req: WebhookRequest;
  collapsed: boolean;
}

function RequestDetails({
  req: {
    botId,
    appId,
    statusCode,
    time,
    url,
    created,
    appName,
    responseData,
    responseText,
    responseHeaders,
    data,
    headers,
    loggedBy,
    errorMessage,
  },
  collapsed,
}: Props) {
  const appUrl = `/workspace/${botId}/connect/applications/${appId}`;
  const detailedClasses = classnames(styles.detailed, { [styles.collapsed]: collapsed });

  return (
    <div className={detailedClasses}>
      <div className={styles.responseDetails}>
        <h4>Response details</h4>
        <ul className={styles.list}>
          <li>
            <span>Status code: </span>
            {statusCode ? <strong>{statusCode}</strong> : <em>None</em>}
          </li>
          <li>
            Response time: <strong>{`${time}ms `}</strong>
          </li>
        </ul>
      </div>
      <div className={styles.details}>
        <h4>Details</h4>
        <ul className={styles.list}>
          <li>
            Time: <strong>{created}</strong>
          </li>
          <li>
            URL: <a href={url}>{url}</a>
          </li>
          <li>
            Logged by: <Label>{loggedBy}</Label>
          </li>
          {!!appName && (
            <li>
              App: <Link to={appUrl}>{appName}</Link>
            </li>
          )}
        </ul>
      </div>
      <div className={styles.headersAndData}>
        <div className={styles.headersAndDataSection}>
          <h4>Response data</h4>
          {!!responseData && <JSONView data={responseData} />}
          {!!responseText && <pre>{responseText}</pre>}
          {!responseText && !responseData && <em>Empty response</em>}
        </div>
        <div className={styles.headersAndDataSection}>
          <h4>Response headers</h4>
          {!!responseHeaders && <JSONView data={responseHeaders} />}
          {!responseHeaders && <em>Empty response</em>}
        </div>
        {!!errorMessage && (
          <div className={styles.headersAndDataSection}>
            <h4>Error (in request)</h4>
            <pre>{errorMessage}</pre>
          </div>
        )}
        {!!data && (
          <div className={styles.headersAndDataSection}>
            <h4>Data</h4>
            <JSONView data={data} />
          </div>
        )}
        {!!headers && (
          <div className={styles.headersAndDataSection} style={{ borderBottom: 0 }}>
            <h4>Headers</h4>
            <JSONView data={headers} />
          </div>
        )}
      </div>
    </div>
  );
}

export default RequestDetails;
