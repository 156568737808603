import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';

import { DialoguesWithBrokenLinksDocument } from 'frontend/api/generated';
import { LinkBreak } from 'frontend/assets/icons';
import { EmptyState, InfoButton, Table } from 'frontend/components';
import { Section } from 'frontend/features/BotImprovements/components';
import { AboutFeature } from 'frontend/features/BotImprovements/modals';
import { LINK_STATE } from 'frontend/features/Build/constants';
import { useColumns } from 'frontend/features/BuildDashboard/components/BuildResources/hooks';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useCurrentLanguage, usePagination } from 'frontend/hooks';
import { getId } from 'frontend/utils';

import styles from './BrokenLinks.scss';
import useBrokenLinksRenderMap from './hooks/useBrokenLinksRenderMap';

const AboutFeatureText = (
  <p>
    Broken Links are a feature to check if external links on buttons are broken. Some links may change over time,
    resulting in links breaking.
  </p>
);

const PAGE_SIZE = 8;

export const COLUMN_FIELDS = ['label', 'dialogue', 'link'];

const ORDERED_COLUMNS = COLUMN_FIELDS.filter((field) => !['link', 'label'].includes(field));

const BrokenLinks = () => {
  const { buildIdObject, buildType } = useBotOrSkill();
  const { data: dialoguesWithBrokenLinks, loading: brokenLinksLoading } = useQuery(DialoguesWithBrokenLinksDocument, {
    variables: { ...buildIdObject },
  });

  const [descending, setDescending] = useState(false);
  const [orderByField, setOrderByField] = useState(() =>
    ORDERED_COLUMNS?.length > 0 ? ORDERED_COLUMNS[0] : COLUMN_FIELDS[1],
  );

  const [showAboutFeature] = useModal(AboutFeature);

  const [{ currentLanguage }] = useCurrentLanguage();

  const brokenLinksList = useMemo(
    () =>
      dialoguesWithBrokenLinks?.dialoguesWithBrokenLinks
        ?.map((dialogue) =>
          (dialogue?.buttons ?? [])
            .filter(
              ({ languageCode, urlStatus }) => languageCode === currentLanguage && urlStatus === LINK_STATE.BROKEN,
            )
            .map((button) => ({
              ...button,
              dialogue: {
                title: dialogue?.title?.[currentLanguage] ?? dialogue?.title.default,
                id: dialogue?.id,
                dialogueType: dialogue?.dialogueType,
                isActive: dialogue?.isActive[currentLanguage],
              },
              key: `button${getId(button)}`,
              actions: { button, id: dialogue?.id },
            })),
        )
        .flat(),
    [currentLanguage, dialoguesWithBrokenLinks],
  );

  const renderMap = useBrokenLinksRenderMap({ buildIdObject, buildType });

  const columns = useColumns({
    columnFields: COLUMN_FIELDS,
    orderByField,
    setOrderByField,
    descending,
    setDescending,
    orderedColumns: ORDERED_COLUMNS,
    renderMap,
  });

  const orderedResults = useMemo(
    () => orderBy(brokenLinksList, orderByField, descending ? 'desc' : undefined),
    [descending, orderByField, brokenLinksList],
  );

  const [currentObjects, page, pages, setPage] = usePagination({ objects: orderedResults, pageSize: PAGE_SIZE });

  const pagination = useMemo(
    () => ({
      currentPage: page,
      pages,
      setPage,
      summary: {
        totalCount: orderedResults.length,
        firstVisible: (page - 1) * PAGE_SIZE + 1,
        lastVisible: page === pages ? orderedResults.length : page * PAGE_SIZE,
      },
    }),
    [page, pages, setPage, orderedResults],
  );

  return (
    <Section
      className={cx(styles.brokenLinksSection, { [styles.brokenListSectionEmpty]: !brokenLinksList?.length })}
      listContentClassName={styles.brokenListSectionContent}
      InfoBox={
        <InfoButton
          className={styles.infoButton}
          onClick={() => showAboutFeature({ title: 'About Broken Links', text: AboutFeatureText })}
        />
      }
      title="Broken links"
      listHeader={[]}
      hasData={!!brokenLinksList?.length}
    >
      {!brokenLinksLoading && !brokenLinksList?.length ? (
        <EmptyState
          className={styles.brokenLinksEmptyState}
          title="No broken links"
          description="There are currently no broken links."
          color="blue"
          icon={LinkBreak}
        />
      ) : (
        <Table data={currentObjects} columns={columns} pagination={pagination} loading={brokenLinksLoading} />
      )}
    </Section>
  );
};

export default BrokenLinks;
