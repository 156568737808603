import { REQUIRED_ALTERNATIVES } from 'frontend/constants';

import { BUTTON_TYPES } from '../../constants';

const { REQUIRED, RESTRICTED, OPTIONAL } = REQUIRED_ALTERNATIVES;

export const buttonTypeUsesValue = Object.freeze({
  [BUTTON_TYPES.QUICK_REPLY]: REQUIRED,
  [BUTTON_TYPES.EXTERNAL_LINK]: REQUIRED,
  [BUTTON_TYPES.EMAIL]: REQUIRED,
  [BUTTON_TYPES.PHONE]: REQUIRED,
  [BUTTON_TYPES.DIALOGUE_TRIGGER]: REQUIRED,
  [BUTTON_TYPES.HANDOVER_REQUEST]: RESTRICTED,
  [BUTTON_TYPES.PRIVACY_EXPORT]: RESTRICTED,
  [BUTTON_TYPES.PRIVACY_DELETE]: RESTRICTED,
  [BUTTON_TYPES.CONTACT_DETAILS]: RESTRICTED,
  [BUTTON_TYPES.ABORT_FOLLOWUP]: OPTIONAL,
  [BUTTON_TYPES.CHECKBOX]: REQUIRED,
  [BUTTON_TYPES.SUBMIT]: REQUIRED,
  [BUTTON_TYPES.UPLOAD_ATTACHMENT]: REQUIRED,
});

// eslint-disable-next-line eqeqeq
export const parseSliderValue = (val) => (parseFloat(val) === 0 ? 0 : parseFloat(val) || val);
