import { useMutation } from '@apollo/client';
import cx from 'classnames';
import { useCallback, useState } from 'react';

import { Button, FileUpload, Panel, Radio } from 'frontend/components';
import type { ReadFile } from 'frontend/components/FileUpload/FileUpload';
import { RUN_SIMPLE_EVALUATION } from 'frontend/features/Evaluation/graphql';
import { useUpload } from 'frontend/features/Evaluation/hooks';

import styles from '../Evaluation.scss';

const SimpleEvalSetup = ({ evaluation, botId }) => {
  const { id: evaluationId } = evaluation;
  const [step, setStep] = useState(1);
  const [headers, setHeaders] = useState<string[]>([]);
  const [messageHeader, setMessageHeader] = useState('');

  const upload = useUpload(`api/v2/evaluation/${evaluationId}/upload-csv/`);

  const [mutate] = useMutation(RUN_SIMPLE_EVALUATION);

  const onUpload = async ([{ source }]: [ReadFile]): Promise<void> => {
    // FIXME: This CSV header parsing is very naive
    if (!source || typeof source !== 'string') return;
    const parsedHeaders = source.split('\n')[0]?.split(',');
    if (!parsedHeaders) return;

    setHeaders(parsedHeaders);
    await upload(new Blob([source], { type: 'text/csv' }));
    setStep(2);
  };

  const onGenerateClick = useCallback(() => {
    mutate({ variables: { evaluationId, botId, messageHeader } });
  }, [botId, evaluationId, messageHeader, mutate]);

  return (
    <Panel className="m-t">
      <h2>Upload & Generate</h2>
      {evaluation.inputFile && (
        <>
          <p>CSV containing user messages</p>
          <Button href={evaluation.inputFile}>Download</Button>
        </>
      )}
      {evaluation.state === 'NEW' && (
        <p>
          Start by uploading a CSV containing the user messages you want to evaluate, pick the column containing the
          user messages and click generate.
        </p>
      )}
      {evaluation.state === 'NEW' && (
        <>
          <section className={styles.simpleSection}>
            <h3>1. Upload CSV</h3>
            <div className={styles.uploadButtons}>
              <FileUpload
                containerClassName={styles.selectFileButton}
                accept="text/csv"
                sizeLimitKb={10 * 1024}
                text="Select file"
                color="primary"
                readAs="text"
                onUpload={onUpload as (files: ReadFile[]) => Promise<void>}
              />
            </div>
          </section>
          <section className={cx(styles.simpleSection, { [styles.simpleSectionDisabled]: step < 2 })}>
            <h3>2. Select column</h3>
            {headers.map((header) => (
              <Radio
                key={header}
                label={header}
                input={{
                  name: 'header',
                  onChange: (event) => {
                    setMessageHeader(event.currentTarget.value);
                    if (step !== 3) setStep(3);
                  },
                  value: header,
                }}
              />
            ))}
          </section>
          <section className={cx(styles.simpleSection, { [styles.simpleSectionDisabled]: step < 3 })}>
            <h3>3. Generate report</h3>
            <Button color="primary" onClick={onGenerateClick} disabled={step < 3}>
              Generate
            </Button>
          </section>
        </>
      )}
    </Panel>
  );
};
export default SimpleEvalSetup;
