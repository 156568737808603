import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { zodiacModelFragment } from 'frontend/api/fragments';
import { ModalForm } from 'frontend/features/Modals';
import { TRAIN_ZODIAC_MODEL } from 'frontend/features/Train/components/TRAIN_ZODIAC_MODEL';
import { useCurrentLanguage, useToast } from 'frontend/hooks';

const updateCache = (cache, { data }) => {
  const { trainZodiacModel: newModel } = data;
  cache.modify({
    fields: {
      zodiacModels: (currentModelRefs = []) => {
        const newRef = cache.writeFragment({
          data: newModel,
          fragment: zodiacModelFragment,
        });
        return [newRef, ...currentModelRefs];
      },
    },
  });
};

const TrainZodiacModel = ({ hide, args: { botId, enabledLanguages } }) => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const toast = useToast();
  const currentLanguageObject = enabledLanguages.find(({ code }) => code === currentLanguage);

  const [mutate] = useMutation(TRAIN_ZODIAC_MODEL, {
    update: updateCache,
  });
  const onSubmit = async () => {
    await mutate({ variables: { botId, languageCode: currentLanguageObject.code } });
    toast.success('Training initiated');
  };

  return (
    <ModalForm title="Train Zodiac Model" hide={hide} onOkText="Yes" onSubmit={onSubmit} ignoreDirty>
      Are you sure you want to train a new Zodiac model for {currentLanguageObject ? currentLanguageObject.name : ''}?
    </ModalForm>
  );
};

TrainZodiacModel.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    botId: PropTypes.string.isRequired,
    enabledLanguages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
};

export default TrainZodiacModel;
