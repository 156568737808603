import { useMutation } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { removeListItemById } from 'frontend/api/cacheHelpers';
import { Check } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { TicketIcon } from 'frontend/features/Inbox/components';
import { TICKET_STATUS } from 'frontend/features/Inbox/constants';
import { TicketType } from 'frontend/features/Inbox/propTypes';
import { useQueryParams } from 'frontend/hooks';

import styles from './StatusList.scss';
import { CHANGE_TICKET_STATUS } from '../../mutations';
import DropdownOverlay from '../DropdownOverlay';

const StatusList = ({ ticket, close }) => {
  const { botId } = useParams();
  const params = useQueryParams();
  const ticketStatusFilter = params.get('ticketStatusFilter');
  const [changeStatus] = useMutation(CHANGE_TICKET_STATUS, {
    variables: {
      ticketId: ticket.id,
    },
    refetchQueries: ['TicketViewCounts'],
    update: (cache, { data }) => {
      if (ticketStatusFilter && data?.changeTicketStatus.status !== ticketStatusFilter) {
        cache.modify({
          id: `ChatsAndTicketsType:chats-and-tickets-bot-${botId}`,
          fields: { chats: removeListItemById(ticket.chatId) },
        });
      }
    },
  });

  const updateStatus = useCallback(
    (status) => {
      changeStatus({
        variables: { status },
        optimisticResponse: {
          changeTicketStatus: {
            __typename: 'TicketType',
            ...ticket,
            status,
          },
        },
      });
    },
    [changeStatus, ticket],
  );

  return (
    <DropdownOverlay header="Ticket status" close={close}>
      {Object.keys(TICKET_STATUS).map((status) => (
        <Fragment key={`ticket-statis-${status}`}>
          <TicketIcon status={status} />
          <div
            role="button"
            tabIndex={0}
            className={cx(styles.listItem, styles[status.toLowerCase()])}
            onClick={() => updateStatus(status)}
            onKeyDown={(e) => e.key === 'Enter' && changeStatus({ variables: { status } })}
          >
            <span>{status.toLowerCase()}</span>
            {ticket.status === status && <Icon component={Check} color="black" className={styles.checkedIcon} />}
          </div>
        </Fragment>
      ))}
    </DropdownOverlay>
  );
};

StatusList.propTypes = {
  ticket: TicketType,
  close: PropTypes.func,
};

export default StatusList;
