import { orderBy, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { ChatBubble } from 'frontend/components';
import type { ButtonWithDialogueLink } from 'frontend/features/Build/components/Buttons/types';
import { BUTTON_TYPES } from 'frontend/features/Build/constants';
import { useCurrentLanguage } from 'frontend/hooks';

import { ButtonType } from '../../propTypes';

interface Props {
  buttons: ButtonWithDialogueLink[];
}

const ChatbubbleButtons = ({ buttons }: Props) => {
  const orderedButtons = useMemo(
    () =>
      uniqBy(
        orderBy(
          buttons.filter(({ isActive }) => isActive),
          'index',
        ),
        ({ id, tempId }) => id || tempId || '',
      ),
    [buttons],
  );
  const [{ currentLanguage }] = useCurrentLanguage();

  if (orderedButtons.length === 0) {
    return null;
  }

  return (
    <>
      {orderedButtons.map(({ label, id: serverId, tempId, buttonType, sliderDetails, dialogueLink }) => {
        const id = serverId || tempId;
        if (buttonType === BUTTON_TYPES.CHECKBOX) {
          return <ChatBubble.CheckboxButton key={`preview-button-${id}`}>{label}</ChatBubble.CheckboxButton>;
        }
        if (buttonType === BUTTON_TYPES.SLIDER && sliderDetails) {
          return (
            <ChatBubble.Slider
              name={`preview-slider-${id}`}
              sliderDetails={sliderDetails}
              key={`preview-slider-${id}`}
            />
          );
        }
        if (dialogueLink?.dialogue.isActive[currentLanguage] === false) {
          return null;
        }
        return <ChatBubble.Button key={`preview-button-${id}`}>{label}</ChatBubble.Button>;
      })}
    </>
  );
};

ChatbubbleButtons.propTypes = {
  buttons: PropTypes.arrayOf(ButtonType).isRequired,
};

export default ChatbubbleButtons;
