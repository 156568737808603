import { get } from 'lodash';
import { useCallback } from 'react';
import { useForm } from 'react-final-form';

import { FileUpload } from 'frontend/components';
import { EMPTY_IMAGE } from 'frontend/constants';
import { useToast } from 'frontend/hooks';
import randomUUID from 'frontend/utils/randomUUID';

interface UploadProps {
  fieldName: string;
  currentLanguage: string;
  currentRuleId?: string | undefined;
}

const Upload = ({ fieldName, currentLanguage, currentRuleId }: UploadProps) => {
  const toast = useToast();
  const { change, getState } = useForm();

  const onUpload = useCallback(
    (files) => {
      const { values } = getState();
      const imageCarousel = get(values, fieldName);
      const nExistingImages = imageCarousel?.images?.length ?? 0;

      if (!imageCarousel) {
        change(fieldName, {
          tempId: randomUUID(),
          languageCode: currentLanguage,
          images: [],
          size: 1000,
        });
        if (currentRuleId) {
          change(`${fieldName}.rule.id`, currentRuleId);
        }
      }

      files.forEach(({ source, file }, index) => {
        const id = randomUUID();

        change(`${fieldName}.images[${index + nExistingImages}]`, {
          ...EMPTY_IMAGE,
          file,
          imageUrl: source,
          id,
          tempId: id,
          index: index + nExistingImages,
        });
      });
    },
    [change, currentLanguage, currentRuleId, fieldName, getState],
  );

  const onError = useCallback((err) => toast.error(err.toString()), [toast]);

  return (
    <FileUpload text="Upload" size="small" multiple onUpload={onUpload} onError={onError} sizeLimitKb={10 * 1024} />
  );
};

export default Upload;
