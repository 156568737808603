import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  BotMembersDocument,
  type BotMembersQuery,
  OrganizationMembersDocument,
  type OrganizationMembersQuery,
} from 'frontend/api/generated';

export type BotMember = NonNullable<BotMembersQuery['bot']>['members'][number];
export type OrganizationMember = NonNullable<OrganizationMembersQuery['organization']>['members'][number];

export default function useMembers({ id, organizationId }: { id: string; organizationId?: string }) {
  const { data: botMembersData, loading: botLoading } = useQuery(BotMembersDocument, { variables: { id } });
  const { data: orgMembersData, loading: orgLoading } = useQuery(OrganizationMembersDocument, {
    variables: { id: organizationId! },
    skip: !organizationId,
  });

  const loading = botLoading || orgLoading;

  const botMembers: BotMember[] = useMemo(() => {
    if (!botMembersData?.bot?.members) {
      return [];
    }
    return botMembersData.bot.members.filter(({ membership }) => Boolean(membership?.id));
  }, [botMembersData]);

  const organizationMembers: OrganizationMember[] = useMemo(() => {
    if (!orgMembersData?.organization?.members) {
      return [];
    }
    return orgMembersData.organization.members.filter(({ membership }) => Boolean(membership.id));
  }, [orgMembersData]);

  /* Annotating bot memberships with every agent's availability, from the organization query. */
  const botMembersWithStatus = botMembers.map((member) => {
    const userId = member.user.id;
    const organizationMember = organizationMembers.find(({ user }) => user.id === userId);
    const orgMemberAvailability = organizationMember?.membership?.agentProfile?.availability ?? 'OFFLINE';

    return {
      ...member,
      membership: {
        ...member.membership,
        agentProfile: {
          availability: orgMemberAvailability,
        },
      },
    };
  });

  return {
    botMembers: botMembersWithStatus,
    organizationMembers,
    loading,
  };
}
