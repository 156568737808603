import { useUrlSearchParams } from 'frontend/hooks';

import { removeEmptyValues } from '../utils';

const filterToUrlParams = (type) => removeEmptyValues({ type });

const DEFAULT = { type: '' };

const useSessionType = () => {
  const [{ type }] = useUrlSearchParams({ default: DEFAULT });
  return [type, filterToUrlParams];
};

export default useSessionType;
