import type { MessageReferencesType } from 'frontend/api/generated';

export type DedupedMessageReferences = { id: string; url: string; offsets: number[] };

/** Group references offsets by their url.
 * @param messageReferences - The message references to be deduplicated.
 * @return The deduplicated message references, each with an array of offsets.
 * @example
 * dedupeMessageReferences([
 *  { offset: 0, url: 'https://example.com' },
 * { offset: 1, url: 'https://example.com' },
 * { offset: 2, url: 'https://example.com' },
 * { offset: 0, url: 'https://example.com/2' },
 * { offset: 1, url: 'https://example.com/2' },
 * { offset: 0, url: 'https://example.com/3' },
 * ]);
 * // returns
 * [
 * { offsets: [0, 1, 2], url: 'https://example.com' },
 * { offsets: [0, 1], url: 'https://example.com/2' },
 * { offsets: [0], url: 'https://example.com/3' },
 * ]
 */
export function dedupeMessageReferences(messageReferences?: MessageReferencesType[]): DedupedMessageReferences[] {
  if (!messageReferences) {
    return [];
  }

  return messageReferences
    .reduce<DedupedMessageReferences[]>((acc, { offset, url, id }) => {
      const existingReference: DedupedMessageReferences | undefined = acc.find((reference) => reference.url === url);
      if (existingReference) {
        existingReference.offsets.push(offset);
      } else {
        acc.push({ offsets: [offset], url, id });
      }
      return acc;
    }, [])
    .sort((a, b) => Math.min(...a.offsets) - Math.min(...b.offsets));
}

/**
 * Adds annotations to the message at the positions specified by the deduplicated message references.
 * @param message - The message to be annotated.
 * @param messageReferencesDeduped - The deduplicated message references, each with an array of offsets.
 * @return The annotated message.
 * @example
 * annotateReferences('Hello world', [
 * { offsets: [0, 5], url: 'https://example.com' },
 * { offsets: [6], url: 'https://example.com/2' },
 * ]);
 * // returns
 * '<sup>1</sup>Hello<sup>1</sup> <sup>2</sup>world'
 */
export function annotateReferences(message: string, messageReferencesDeduped: DedupedMessageReferences[]): string {
  if (!messageReferencesDeduped || !messageReferencesDeduped.length) {
    return message;
  }

  // Array.from(str) counts emojis the same way as Python's len(str), but differently than str.length()
  const annotatedMessage = Array.from(message);
  let cursor = 0;

  const offsets = messageReferencesDeduped
    .flatMap((ref, index) => ref.offsets.map((offset) => ({ offset, number: index + 1 })))
    .sort((a, b) => a.offset - b.offset);

  offsets.forEach(({ offset: refOffset, number }) => {
    const slicePosition = cursor + refOffset;
    const annotation = `<sup>${number}</sup>`;
    annotatedMessage.splice(slicePosition, 0, ...Array.from(annotation));
    cursor += annotation.length;
  });

  return annotatedMessage.join('');
}
