import { useQuery } from '@apollo/client';
import { groupBy, sortBy } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { GET_EVALUATION_REPORT } from '../graphql';

const useDialogueResults = (evaluationId, reportId) => {
  const { data, loading } = useQuery(GET_EVALUATION_REPORT, { variables: { evaluationId, reportId } });
  const evaluationReport = data?.evaluation?.evaluationReport;
  const { botId } = useParams();
  // group queries by their respective dialogue
  const resultsPerDialogue = useMemo(
    () => groupBy(evaluationReport?.queryResults ?? [], ({ dialogueTitle }) => dialogueTitle),
    [evaluationReport?.queryResults],
  );

  // to be used later
  const sortPredictorMatchAsc = (result) => (result.predictorMatchesId ? -1 : 1);
  const sortPredictorFallbackDesc = (result) => (result.predictorReplyIsFallback ? 1 : -1);

  // construct an object per dialogue with its results
  const resultsPerDialogueArray = useMemo(
    () =>
      Object.keys(resultsPerDialogue).map((title) => ({
        title,
        dialogueLink: resultsPerDialogue[title][0].dialogue
          ? `/workspace/${botId}/build/dialogue/${resultsPerDialogue[title][0].dialogue.id}`
          : null,
        tests: sortBy(resultsPerDialogue[title], [sortPredictorMatchAsc, sortPredictorFallbackDesc]),
      })),
    [botId, resultsPerDialogue],
  );

  return { dialogueResults: resultsPerDialogueArray, loading };
};
export default useDialogueResults;
