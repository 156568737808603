import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { buildTypes } from 'frontend/constants';
import { IDType } from 'frontend/propTypes';

import { DASHBOARD_VIEW } from '../../constants';
import EmptyStateComponent from '../EmptyState';

const EmptyState = ({ someDialogueId, buildType }) => (
  <EmptyStateComponent headLine="No activity to report" dashboardView={DASHBOARD_VIEW.ACTIVITY}>
    This is where you will see updates made to the {buildType} content. At the moment there is nothing to report. Why
    don&apos;t you{' '}
    {someDialogueId ? (
      <span>
        make some changes to <Link to={`build/dialogue/${someDialogueId}`}>this dialogue</Link>?
      </span>
    ) : (
      <span>
        <Link to="build/dialogue/new">create a dialogue</Link>?
      </span>
    )}
  </EmptyStateComponent>
);

EmptyState.propTypes = {
  someDialogueId: IDType,
  buildType: PropTypes.oneOf(Object.values(buildTypes)).isRequired,
};

export default EmptyState;
