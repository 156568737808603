import { gql } from '@apollo/client';

import demoFragment from 'frontend/api/fragments/demoFragment';

export default gql`
  query Demo($botId: ID!) {
    demo(botId: $botId) {
      ...Demo
    }
  }
  ${demoFragment}
`;
