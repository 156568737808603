import type { TopicType } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { useDeleteTopic } from './hooks';

const DeleteTopic = ({
  hide,
  args: { topic, botOrSkillParams },
}: {
  hide: () => void;
  args: {
    topic: Pick<TopicType, 'id' | 'name' | 'parentTopicId'>;
    botOrSkillParams: {
      buildIdObject: BuildIdObject;
      buildType: string;
      buildId: string;
    };
  };
}) => {
  const onSubmit = useDeleteTopic({ topic, botOrSkillParams });

  return <DeleteModal onSubmit={onSubmit} hide={hide} name={`folder ${topic.name}`} />;
};

export default DeleteTopic;
