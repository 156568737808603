import { useEffect, useState } from 'react';

export default (ref: React.RefObject<HTMLInputElement>) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    const onFocus = () => setState(true);
    const onBlur = () => setState(false);
    const ourRef = ref;
    if (!ourRef.current) return undefined;

    ourRef.current.addEventListener('focus', onFocus);
    ourRef.current.addEventListener('blur', onBlur);

    return () => {
      if (!ourRef.current) return;
      ourRef.current.removeEventListener('focus', onFocus);
      ourRef.current.removeEventListener('blur', onBlur);
    };
  }, [ref]);

  return state;
};
