import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { Language } from 'frontend/assets/icons';
import { Icon, RenderHTML } from 'frontend/components';
import { useMarkdownToHtml } from 'frontend/hooks';

import styles from './WelcomePagePreview.scss';
import { useStyles } from './hooks';
import { welcomePageTypes } from '../../constants';

const WelcomePagePreview = ({ currentLanguage, hasLanguagePicker }) => {
  const { values } = useFormState();
  const type = get(values, 'welcomePageType', welcomePageTypes.NONE);
  const header = get(values, `welcomePageHeader.${currentLanguage}`, '');
  const markdownString = get(values, `welcomePageText.${currentLanguage}`, '');
  const startButton = get(values, `welcomePageStartButton.${currentLanguage}`, '');

  const { html } = useMarkdownToHtml(markdownString);

  const { headerContentStyle, contentWrapperStyle, buttonStyle } = useStyles(values);

  if (type === welcomePageTypes.NONE) return null;

  return (
    <div className={styles.container}>
      <div className={styles.chatPreview}>
        <div className={styles.headerContent} style={headerContentStyle} />
        <div className={styles.contentWrapper} style={contentWrapperStyle}>
          <div className={styles.content}>
            {hasLanguagePicker && (
              <div className={styles.languagePicker}>
                <div className={styles.iconContainer}>
                  <Icon component={Language} color="text" className={styles.languageIcon} />
                </div>
                <p>English</p>
              </div>
            )}
            <div className={styles.text}>
              <h2>{header}</h2>
              <h4>
                <RenderHTML html={html} />
              </h4>
            </div>
            <div className={styles.button} style={buttonStyle}>
              {startButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WelcomePagePreview.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  hasLanguagePicker: PropTypes.bool,
};

export default WelcomePagePreview;
