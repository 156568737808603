import cx from 'classnames';
import { useState } from 'react';

import autoUserAvatars from 'frontend/assets/auto-user-avatars';

import AutoAvatar from './AutoAvatar';
import DefaultAvatar from './DefaultAvatar';
import styles from './ImageAvatar.scss';

export type ImageAvatarSize = 'extraSmall' | 'small' | 'large';

export interface AutoUser {
  avatar: keyof typeof autoUserAvatars;
  color: string;
}
interface ImageAvatarProps {
  /** The URL of the image you want as a avatar */
  img?: string;
  /** Any additional className you want to apply */
  className?: string;
  /** Size of the avatar */
  size?: ImageAvatarSize;
  /** Hovering title of the avatar */
  title?: string;
  /** Avatar's image alt */
  alt?: string;
  /** Data object containing some props together */
  autoUser?: AutoUser;
}

function isAutoUser(autoUser?: AutoUser): autoUser is AutoUser {
  return Boolean(autoUser?.avatar && autoUser.color);
}

/** It displays an **avatar** container.
 *
 * It will show a placeholder if no images are given.
 */
export const ImageAvatar = ({ img, className, autoUser, size, alt, title }: ImageAvatarProps) => {
  const [errored, setErrored] = useState(false);

  const classNames = cx(
    styles.avatar,
    {
      [styles.imageAvatar]: Boolean(img),
      [styles.extraSmall]: size === 'extraSmall',
      [styles.small]: size === 'small',
      [styles.large]: size === 'large',
    },
    className,
  );

  if (img && !errored) {
    return (
      <img className={classNames} src={img} alt={alt || 'Avatar'} onError={() => setErrored(true)} title={title} />
    );
  }

  if (isAutoUser(autoUser)) {
    return <AutoAvatar className={classNames} img={autoUserAvatars[autoUser.avatar]} color={autoUser.color} />;
  }

  return <DefaultAvatar className={className} alt={alt || 'Avatar'} />;
};

export default ImageAvatar;
