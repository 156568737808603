import { useEffect } from 'react';

import styles from './Login.scss';

const CLIENT_ID = window.env.GOOGLE_SSO_CLIENT_ID;

interface IdConfiguration {
  client_id: string;
  auto_select?: boolean;
  callback: (handleCredentialResponse: CredentialResponse) => void;
  login_uri?: string;
  native_callback?: (...args: any[]) => void;
  cancel_on_tap_outside?: boolean;
  prompt_parent_id?: string;
  nonce?: string;
  context?: string;
  state_cookie_domain?: string;
  ux_mode?: 'popup' | 'redirect';
  allowed_parent_origin?: string | string[];
  intermediate_iframe_close_callback?: (...args: any[]) => void;
}

interface CredentialResponse {
  credential: string;
  select_by?:
    | 'auto'
    | 'user'
    | 'user_1tap'
    | 'user_2tap'
    | 'btn'
    | 'btn_confirm'
    | 'brn_add_session'
    | 'btn_confirm_add_session';
  clientId?: string;
}

interface GsiButtonConfiguration {
  type: 'standard' | 'icon';
  theme?: 'outline' | 'filled_blue' | 'filled_black';
  size?: 'large' | 'medium' | 'small';
  text?: 'signin_with' | 'signup_with' | 'continue_with' | 'signup_with';
  shape?: 'rectangular' | 'pill' | 'circle' | 'square';
  logo_alignment?: 'left' | 'center';
  width?: string;
  local?: string;
}

interface PromptMomentNotification {
  isDisplayMoment: () => boolean;
  isDisplayed: () => boolean;
  isNotDisplayed: () => boolean;
  getNotDisplayedReason: () =>
    | 'browser_not_supported'
    | 'invalid_client'
    | 'missing_client_id'
    | 'opt_out_or_no_session'
    | 'secure_http_required'
    | 'suppressed_by_user'
    | 'unregistered_origin'
    | 'unknown_reason';
  isSkippedMoment: () => boolean;
  getSkippedReason: () => 'auto_cancel' | 'user_cancel' | 'tap_outside' | 'issuing_failed';
  isDismissedMoment: () => boolean;
  getDismissedReason: () => 'credential_returned' | 'cancel_called' | 'flow_restarted';
  getMomentType: () => 'display' | 'skipped' | 'dismissed';
}

interface RevocationResponse {
  successful: boolean;
  error: string;
}

interface Credential {
  id: string;
  password: string;
}

interface Google {
  accounts: {
    id: {
      initialize: (input: IdConfiguration) => void;
      prompt: (momentListener?: (res: PromptMomentNotification) => void) => void;
      renderButton: (parent: HTMLElement, options: GsiButtonConfiguration) => void;
      disableAutoSelect: () => void;
      storeCredential: (credentials: Credential, callback: () => void) => void;
      cancel: () => void;
      onGoogleLibraryLoad: () => void;
      revoke: (hint: string, callback: (done: RevocationResponse) => void) => void;
    };
  };
}

declare global {
  interface Window {
    google?: Google;
    GoogleAuthSuccess: (res: CredentialResponse) => void;
  }
}

const GoogleLoginButton = ({ onGoogleResponse }: { onGoogleResponse: (res: CredentialResponse) => unknown }) => {
  const GoogleAuthSuccess = (res: CredentialResponse) => {
    if (!res.clientId || !res.credential) return;

    onGoogleResponse(res);
  };

  if (typeof window !== 'undefined') {
    window.GoogleAuthSuccess = GoogleAuthSuccess;
  }

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;

    window.document.body.appendChild(script);

    return () => {
      window.google?.accounts.id.cancel();
      window.document.body.removeChild(script);
    };
  }, [onGoogleResponse]);

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={CLIENT_ID}
        data-text="Continue with google"
        data-auto_prompt="false"
        data-callback="GoogleAuthSuccess"
      />

      <div className={styles.ssoGoogleButton}>
        <div
          className="g_id_signin"
          data-type="standard"
          data-size="large"
          data-theme="outline"
          data-text="signin_with"
          data-shape="rectangular"
          data-width="192"
          data-logo_alignment="center"
        />
      </div>
    </>
  );
};

export default GoogleLoginButton;
