import { useCallback } from 'react';

const keyWasPressed = ({ key, keys, event }: { key?: string; keys?: string[]; event: KeyboardEvent }) =>
  (keys && keys.includes(event.key)) || (key && event.key === key);

export default ({
  action,
  key = 'Enter',
  keys,
  stopEvent = false,
}: {
  action?: (event: React.SyntheticEvent) => void;
  key?: string;
  keys?: string[];
  stopEvent?: boolean;
}) =>
  useCallback(
    (event) => {
      if (!action || !keyWasPressed({ key, keys, event })) return;
      if (stopEvent) {
        event.preventDefault();
        event.stopPropagation();
      }
      action(event);
    },
    [action, key, keys, stopEvent],
  );
