import { gql } from '@apollo/client';

export const GetNumberOfPatternsInOtherDialogues = gql`
  query NumberOfPatternsInOtherDialogues(
    $languageCode: String!
    $dialogueId: UUID
    $botId: ID
    $skillId: ID
    $parentId: UUID
  ) {
    numberOfPatternsInOtherDialogues(
      languageCode: $languageCode
      id: $dialogueId
      botId: $botId
      skillId: $skillId
      parentId: $parentId
    )
  }
`;
