import { identity } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { BuildIdObjectType, IDType } from 'frontend/propTypes';

import { LibraryDialogueType } from '../../propTypes';
import { sortDialogues } from '../../utils';
import DialogueItem from '../DialogueItem';

const DialogueList = ({
  dialogues,
  selectedLanguage,
  buildIdObject,
  skillId,
  parentDisabled,
  parentId,
  indentationLevel,
  sorting = sortDialogues,
  keyPostfix = '',
  hideFollowups = false,
}) => {
  const sorted = useMemo(
    () => sorting(dialogues ?? [], selectedLanguage).filter(identity),
    [selectedLanguage, dialogues, sorting],
  );

  const { botId } = useParams();
  const { botSettings } = useBotSettings(botId);
  const intentEnabled = botSettings?.intentMatchEnabled;

  return sorted
    .filter((d) => (intentEnabled ? d.dialogueType !== 'SAMPLES' : d.dialogueType !== 'INTENT'))
    .filter(parentId === 'BOTTOM_LEVEL' ? Boolean : (d) => d.parentId === (parentId || null))
    .map((dialogue) => (
      <DialogueItem
        key={`library-${dialogue.dialogueType}-${dialogue.id}${keyPostfix}`}
        dialogue={dialogue}
        buildIdObject={buildIdObject}
        skillId={skillId}
        parentDisabled={parentDisabled}
        indentationLevel={indentationLevel}
        hideFollowups={hideFollowups}
      />
    ));
};

DialogueList.propTypes = {
  dialogues: PropTypes.arrayOf(LibraryDialogueType),
  selectedLanguage: PropTypes.string.isRequired,
  buildIdObject: BuildIdObjectType.isRequired,
  skillId: IDType,
  parentDisabled: PropTypes.bool,
  parentId: PropTypes.string,
  sorting: PropTypes.func,
  indentationLevel: PropTypes.number.isRequired,
  keyPostfix: PropTypes.string,
  hideFollowups: PropTypes.bool,
};

export default DialogueList;
