import { useQuery } from '@apollo/client';
import { addDays, differenceInDays, formatISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Calendar } from 'react-date-range';
import { Field } from 'react-final-form';

import { Checkbox, Condition, LoaderSwitch, Select } from 'frontend/components';
import { SUBSCRIPTION_PLANS } from 'frontend/features/Organization/queries';
import { required } from 'frontend/form/validators';

const INITIAL_EXPIRATION_DATE = formatISO(addDays(new Date(), 15), { representation: 'date' });

const ExpirationDate = ({ input: { onChange, value } }) => {
  const datestringToDate = useMemo(() => new Date(value), [value]);
  return (
    <>
      <label htmlFor="days-until-expiry">Expires in {differenceInDays(datestringToDate, new Date())} days</label>
      <Calendar
        minDate={new Date()}
        date={datestringToDate}
        value={value}
        onChange={(date) => onChange(formatISO(date, { representation: 'date' }))}
      />
    </>
  );
};
ExpirationDate.propTypes = {
  input: PropTypes.shape({}).isRequired,
};

const SubscriptionSelect = () => {
  const { data: subscription, loading } = useQuery(SUBSCRIPTION_PLANS);
  const subscriptionPlans = useMemo(() => subscription?.subscriptionPlans ?? [], [subscription]);

  return (
    <LoaderSwitch loading={loading} size="large" noMargin>
      <Field component={Select} name="planName" validate={required}>
        <Select.Option key="subscription-plan-select" value="" label="Select Subscription" disabled />
        {subscriptionPlans.map((name) => (
          <Select.Option key={`subscription-plan-${name}`} value={name} label={name} />
        ))}
      </Field>
      <Field component={Checkbox} name="noExpiration" type="checkbox" label="No expiration date" />
      <Condition when="noExpiration" is={false}>
        <Field name="expirationDate" component={ExpirationDate} initialValue={INITIAL_EXPIRATION_DATE} />
      </Condition>
    </LoaderSwitch>
  );
};

export default SubscriptionSelect;
