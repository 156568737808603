import { useMemo } from 'react';
import { useForm } from 'react-final-form';

import { useUrlSearchParams } from 'frontend/hooks';

import { FILTERS_LIST, type FilterType } from '../utils/config';
import { getFilterWithoutIndex, getKeyIndex } from '../utils/helpers';

export default () => {
  const [params] = useUrlSearchParams();
  const { getState } = useForm();

  const filters = useMemo(() => {
    const { values } = getState();
    return Object.entries(values)
      .filter(
        ([key, value]) =>
          // Check if the key in the url exists in the filters list
          // and that value exists
          FILTERS_LIST.some(({ filterKey }) => filterKey === getFilterWithoutIndex(key)) &&
          (Array.isArray(value) ? value.length : !!value),
      )
      .sort(([a], [b]) => {
        const aIndex = getKeyIndex(a);
        const bIndex = getKeyIndex(b);
        return aIndex - bIndex;
      })
      .map(([paramFilterKey]) => ({
        ...FILTERS_LIST.find(({ filterKey }) => paramFilterKey.includes(filterKey)),
        filterKey: paramFilterKey,
      })) as FilterType[];
    // The params as dependency is so we update the filters only when we close the dropdown
    // When we do that we submit and we update the params, thus triggering the memo hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, getState]);

  return filters;
};
