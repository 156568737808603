import { gql } from '@apollo/client';

import USER_DETAIL_FRAGMENT from '../fragments/UserDetail.fragment.graphql';

export default gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserDetail
    }
  }
  ${USER_DETAIL_FRAGMENT}
`;
