import { Field, Form } from 'react-final-form';

import { FormErrors, LoaderSwitch, PageBar, Panel, Radio } from 'frontend/components';
import { publishingTypes } from 'frontend/constants';
import { useGeneralForm } from 'frontend/features/BotSettings/hooks';

import styles from '../../styles.scss';

const Build = () => {
  const { onSubmit, initialValues, loading } = useGeneralForm();

  return (
    <LoaderSwitch loading={loading} size="large">
      <div className={styles.generalLayout}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>

              <Panel>
                <FormErrors className={styles.formErrors} />
                <h3 className={`${styles.sectionTitle} m-b-3`}>Draft &amp; publish</h3>
                <p>Choose whether to publish changes right away or draft changes before publishing them.</p>
                <br />
                <div className={styles.publishingContainer}>
                  <Field
                    name="bot.publishingMode"
                    type="radio"
                    component={Radio}
                    label={publishingTypes.AUTOMATIC.title}
                    value={publishingTypes.AUTOMATIC.value}
                  />
                  <Field
                    name="bot.publishingMode"
                    type="radio"
                    component={Radio}
                    label={publishingTypes.MANUAL.title}
                    value={publishingTypes.MANUAL.value}
                  />
                </div>
              </Panel>
            </form>
          )}
        />
      </div>
    </LoaderSwitch>
  );
};

export default Build;
