import { flatten, uniqBy } from 'lodash';
import { useMemo } from 'react';

import { getId } from 'frontend/utils';

export default ({ replies }) => {
  const replySlots = useMemo(
    () => uniqBy(flatten(replies.map(({ instances = [] }) => instances.map(({ slot }) => slot))), getId),
    [replies],
  );

  return replySlots;
};
