import { capitalize } from 'lodash';
import { Field } from 'react-final-form';

import { Input } from 'frontend/components';
import { equalsRedactedMessage } from 'frontend/form/validators';

import ModalForm from '../ModalForm';

interface Props {
  hide(): void;
  onSubmit(): void;
  action?: string;
  children?: React.ReactNode;
  closeOnSubmit?: boolean;
  confirmName?: boolean;
  disableAnimation?: boolean;
  disabled?: boolean;
  hideWarning?: boolean;
  name?: string;
  text?: string;
  titleName?: string;
  updateOnSubmit?: boolean;
}

export default function DeleteModal({
  hide,
  name,
  titleName,
  onSubmit,
  children,
  text,
  disableAnimation,
  hideWarning,
  action = 'delete',
  closeOnSubmit = true,
  confirmName = false,
  updateOnSubmit = false,
  disabled = false,
}: Props): React.JSX.Element {
  if (!children && !text && !name) console.warn('DeleteModal was not supplied with neither children, text nor name');
  if (!titleName && !name) console.warn('DeleteModal was not supplied with neither titleName nor name');
  if (text && children) {
    console.warn('DeleteModal was supplied with both text and children, but only accepts one or the other.');
  }

  return (
    <ModalForm
      onSubmit={onSubmit}
      title={`${capitalize(action)} ${titleName || name}`}
      footer
      onOkText={capitalize(action)}
      hide={hide}
      color="warning"
      closeOnSubmit={closeOnSubmit}
      updateOnSubmit={updateOnSubmit}
      disableAnimation={disableAnimation}
      ignoreDirty
      disabled={disabled}
    >
      {text || children || (
        <>
          {`Are you sure you want to ${action} ${name}?`}
          {!hideWarning && (
            <>
              <br />
              <br />
              This can not be undone.
            </>
          )}
        </>
      )}
      {confirmName && (
        <>
          <label>
            To {action} {name}, type &quot;{name}&quot; below to confirm.
          </label>
          <Field
            component={Input}
            autoComplete="off"
            className="m-b-md"
            placeholder="Name"
            name="name"
            aria-label="Name"
            validate={equalsRedactedMessage(name)}
          />
        </>
      )}
    </ModalForm>
  );
}
