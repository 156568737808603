import PropTypes from 'prop-types';

import { DoughnutChart, Metric } from 'frontend/components/Stats';
import { Grid, StatsPanel } from 'frontend/features/Analytics/components';

import styles from '../../../styles.scss';

const DoughnutPanel = ({ metric, data, text }) => (
  <StatsPanel className={styles.statsPanel} nested>
    <Grid noMargin>
      <DoughnutChart data={data} nested />
      <Metric metric={metric} postfix="%" topText={text} center={false} />
    </Grid>
  </StatsPanel>
);

DoughnutPanel.propTypes = {
  metric: PropTypes.number,
  data: PropTypes.shape({}),
  text: PropTypes.string,
};

export default DoughnutPanel;
