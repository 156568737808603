import { minBy } from 'lodash';

import { CHATS_AND_TICKETS } from 'frontend/features/Inbox/queries';
import { dateFilter } from 'frontend/features/Inbox/utils';

/* This query should not interfere with the cache; instead it's added to the
 cache of the main chats request manually below */
const fetchPolicy = 'no-cache';

export default function useFetchMoreChats({ query, addChats, botId, organizationId, filters, chats, setHasMoreChats }) {
  /** Fetch more chats, starting from the oldest one displayed (excluded), then add them to the Conversation List. */
  const fetchMoreChats = async () => {
    const oldestChat = minBy(chats, ({ updated }) => updated);

    const { data } = await query({
      query: CHATS_AND_TICKETS,
      fetchPolicy,
      variables: { botId, organizationId, ...filters, ...dateFilter(filters), before: oldestChat.updated },
    });

    const olderChats = data?.chatsAndTickets?.chats || [];

    if (olderChats.length > 0) {
      addChats(data.chatsAndTickets, { after: true });
    } else {
      setHasMoreChats(false);
    }
  };

  return fetchMoreChats;
}
