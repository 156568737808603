import { useMutation } from '@apollo/client';
import { isEqual, toPairs } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';

import { ModalForm, useModal } from 'frontend/features/Modals';
import { Pusher, getPusherChannel } from 'frontend/features/Pusher';
import { useBooleanState } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { IDType } from 'frontend/propTypes';
import { any } from 'frontend/utils';

import AddKindlyEntitiesForm from './AddKindlyEntitiesForm';
import { ADD_KINDLY_ENTITIES } from '../../mutations';
import { KindlyEntitiesType } from '../../propTypes';
import AddKindlyEntitiesReport from '../AddKindlyEntitiesReport';

const validate = (values) =>
  any(Object.values(values)) ? undefined : { KindlyEntities: 'You need to select at least one' };

const AddKindlyEntities = ({ hide, args: { kindlyEntities, botId } }) => {
  const [addKindlyEntities] = useMutation(ADD_KINDLY_ENTITIES);
  const [showReportModal] = useModal(AddKindlyEntitiesReport);
  const kindlyEntityIdsRef = useRef();

  const [isSearchingForEntities, setSearching, setNotSearching] = useBooleanState();
  const [waitMinutes, setWaitMinutes] = useState(0);
  const isWaiting = waitMinutes > 0;
  const onOkText = isWaiting ? 'Ok' : 'Save';

  const { data: meData } = useMe();
  const userId = meData?.me?.id;
  const pusherChannel = getPusherChannel({ userId });

  const onSubmit = useCallback(
    async (values) => {
      if (isWaiting) {
        hide();
        return;
      }

      if (isSearchingForEntities) {
        return;
      }

      setSearching();
      const ids = toPairs(values)
        .filter(([, added]) => added)
        .map(([id]) => id);
      kindlyEntityIdsRef.current = ids;

      const { data } = await addKindlyEntities({ variables: { botId, kindlyEntityIds: ids } });
      const newWaitMinutes = data?.addKindlyEntities?.waitMinutes || 0;

      if (newWaitMinutes > 0) {
        setWaitMinutes(newWaitMinutes);
      }
    },
    [addKindlyEntities, botId, hide, isSearchingForEntities, isWaiting, setSearching],
  );

  const onUpdate = useCallback(
    ({ kindlyEntityReportId }) => {
      setNotSearching();
      showReportModal({
        kindlyEntityReportId,
        botId,
        addedKindlyEntityIds: kindlyEntityIdsRef.current,
        hideFirstModal: hide,
      });
    },
    [botId, hide, setNotSearching, showReportModal],
  );

  const initialValues = useMemo(
    () => kindlyEntities.reduce((added, { id }) => ({ ...added, [id]: false }), {}),
    [kindlyEntities],
  );

  return (
    <ModalForm
      title="Add kindly entities"
      onOkText={onOkText}
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      validate={validate}
      hide={hide}
      footer={!isSearchingForEntities && !isWaiting}
      hideCancel={isWaiting}
      ignoreDirty
      closeOnSubmit={false}
      updateOnSubmit={false}
    >
      <AddKindlyEntitiesForm
        kindlyEntities={kindlyEntities}
        isSearchingForEntities={isSearchingForEntities}
        waitMinutes={waitMinutes}
        hide={hide}
      />
      <Pusher channel={pusherChannel} event="found-kindly-entities" onUpdate={onUpdate} />
      <Pusher channel={pusherChannel} event="finding-kindly-entities-error" onUpdate={setNotSearching} />
    </ModalForm>
  );
};

AddKindlyEntities.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    kindlyEntities: KindlyEntitiesType.isRequired,
    botId: IDType.isRequired,
  }).isRequired,
};

export default AddKindlyEntities;
