import { useQuery } from '@apollo/client';

import { BotImprovementsOverviewDocument } from 'frontend/api/generated';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';

interface BotImprovementCountProps {
  show?: 'sampleCandidates' | 'dialogueConfusion' | 'incompleteDialogues' | 'brokenLinks' | 'dialogueCandidates';
}

function overviewForLanguageCode(
  overview:
    | ({
        taskCount: number;
        languageCode: string;
      } | null)[]
    | undefined,
  languageCode: string,
) {
  return overview?.find((languageOverview) => languageOverview?.languageCode === languageCode);
}

const BotImprovementCount = ({ show }: BotImprovementCountProps) => {
  const { botId } = useBotOrSkill();
  const { data, loading } = useQuery(BotImprovementsOverviewDocument, {
    variables: {
      botId: botId!,
    },
    skip: !botId,
  });

  const [{ currentLanguage }] = useCurrentLanguage();

  let totalCount: number | string = 0;
  if (!loading) {
    const total =
      (data?.sampleCandidatesOverview?.totalCount || 0) +
      (data?.dialogueConfusionOverview?.totalCount || 0) +
      (data?.incompleteDialoguesOverview?.totalCount || 0) +
      (overviewForLanguageCode(data?.dialogueWithBrokenLinksOverview?.languageOverview, currentLanguage)?.taskCount ||
        0) +
      (data?.dialogueCandidatesOverview?.totalCount || 0);

    totalCount = total > 99 ? '99+' : total;

    if (show === 'sampleCandidates') {
      const count = overviewForLanguageCode(
        data?.sampleCandidatesOverview?.languageOverview,
        currentLanguage,
      )?.taskCount;
      return <span>{count || ''}</span>;
    }
    if (show === 'dialogueConfusion') {
      const count = overviewForLanguageCode(
        data?.dialogueConfusionOverview?.languageOverview,
        currentLanguage,
      )?.taskCount;
      return <span>{count || ''}</span>;
    }
    if (show === 'incompleteDialogues') {
      const count = overviewForLanguageCode(
        data?.incompleteDialoguesOverview?.languageOverview,
        currentLanguage,
      )?.taskCount;
      return <span>{count || ''}</span>;
    }
    if (show === 'dialogueCandidates') {
      const count = overviewForLanguageCode(
        data?.dialogueCandidatesOverview?.languageOverview,
        currentLanguage,
      )?.taskCount;
      return <span>{count || ''}</span>;
    }
    if (show === 'brokenLinks') {
      const count = overviewForLanguageCode(
        data?.dialogueWithBrokenLinksOverview?.languageOverview,
        currentLanguage,
      )?.taskCount;
      return <span>{count || ''}</span>;
    }

    return <span>{totalCount || ''}</span>;
  }

  return null;
};

export default BotImprovementCount;
