import { addDays, format } from 'date-fns';
import { isEmpty, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import type { PlatformPath, SageDataPaths, SagePath } from 'frontend/features/Analytics/utils/sageData';
import { BUTTON_TYPES } from 'frontend/features/Build/constants';
import {
  PRIORITY_DEFAULT,
  createSelectSageDataState,
  subscribeSageData,
  unsubscribeSageData,
} from 'frontend/state/dux/analytics/dataQueue';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';
import { useAppDispatch } from 'frontend/state/hooks';
import { CHAT_SOURCE } from 'frontend/types/chat';
import { stringifyQueryParamsWithBrackets } from 'frontend/utils';
import { DATE_FORMAT_ISO_DATE } from 'frontend/utils/date';
import type { QueryParam } from 'frontend/utils/stringifyQueryParamsWithBrackets';

import useFakeData from './useFakeData';

const DEFAULT_BUTTON_TYPES = [`-${BUTTON_TYPES.SUGGESTION_DIALOGUE_TRIGGER}`];
const DEFAULT_SOURCES = [`-${CHAT_SOURCE.TEST}`];

// cleans route of pre/post-fix slash: "/foo/bar/" => "foo/bar"
function cleanRoute(route: string): string {
  return route.replace(/(^\/|\/$)/g, '');
}

type MaybeDateString = string | undefined;

function formatDates({ from, to }: { from: MaybeDateString; to: MaybeDateString }): {
  from: MaybeDateString;
  to: MaybeDateString;
} {
  const sageFrom = from !== undefined ? format(new Date(from), DATE_FORMAT_ISO_DATE) : undefined;
  // Add 1 day here because Sage parses the date range as end-exclusive.
  // So if you want to include Dec 31st in the data, the to-date should be Jan 1st.
  const sageTo = to !== undefined ? format(addDays(new Date(to), 1), DATE_FORMAT_ISO_DATE) : undefined;
  return { from: sageFrom, to: sageTo };
}

function makeQueryParams(
  { from, to, languageCodes = [], sources, buttonTypes, ...rest }: Record<string, QueryParam>,
  filterOnButtonTypes = false,
  report = false,
  pickProps = ['limit', 'tz', 'granularity', 'sort', 'nudge_id', 'engagement_id', 'campaign_id', 'type', 'month'],
) {
  const params: Record<string, QueryParam> = report
    ? rest
    : {
        ...formatDates({ from: from as string | undefined, to: to as string | undefined }),
        ...pick(rest, pickProps),
        language_codes: languageCodes,
        sources: isEmpty(sources) ? DEFAULT_SOURCES : sources,
        ...(filterOnButtonTypes ? { button_types: isEmpty(buttonTypes) ? DEFAULT_BUTTON_TYPES : buttonTypes } : {}),
      };
  return stringifyQueryParamsWithBrackets(params);
}

function makeUrl(scope: string, route: string, apiVersion = 'v1', report = false): string {
  const API_BASE = `${window.env.SAGE_HOST}/api`;
  if (scope === sageScope(SAGE_RESOURCE.PLATFORM)) {
    return `${API_BASE}/_internal/stats/platform/${cleanRoute(route)}`;
  }

  const [resource, resourceId] = scope.split('-');
  if (report) {
    return `${API_BASE}/${apiVersion}/reports/${resource}/${resourceId}/${cleanRoute(route)}`;
  }

  return `${API_BASE}/${apiVersion}/stats/${resource}/${resourceId}/${cleanRoute(route)}`;
}

interface SageDataOptions {
  /** Priority constants are defined in the object { PRIORITY } from 'frontend/state/dux/analytics/dataQueue */
  priority?: number;
  /** Disables subscribe/fetch */
  enable?: boolean;
  /** Fetches a report */
  report?: boolean;
  filterOnButtonTypes?: boolean;
  apiVersion?: string;
}

/**
 * useSageData dispatches a request to queue fetching data.
 *
 * FIXME: Accept a resource and an identifier instead of scope
 */

export default function useSageData<R extends SagePath | PlatformPath = SagePath | PlatformPath>(
  /** ${resource}-${id} OR sageScope(SAGE_RESOURCE.PLATFORM) */
  scope: string,
  /** Sage endpoint */
  route: R,
  /** Data query params */
  filters: Record<string, QueryParam>,
  {
    priority = PRIORITY_DEFAULT,
    enable = true,
    filterOnButtonTypes = false,
    report = false,
    apiVersion = 'v1',
  }: SageDataOptions = {},
): Record<string, unknown> & { waiting: boolean; loading: boolean; error?: string; data: SageDataPaths[R] } {
  const resource = makeUrl(scope, route, apiVersion, report);
  const query = makeQueryParams(filters, filterOnButtonTypes);
  const url = `${resource}?${query}`;

  const dispatch = useAppDispatch();
  const { fakeData, fakeDataEnabled } = useFakeData(route);
  const [willSubscribe, setWillSubscribe] = useState(enable);

  useEffect(() => {
    if (enable) {
      dispatch(subscribeSageData(url, scope, { priority }));
      setWillSubscribe(false);
      return () => {
        dispatch(unsubscribeSageData(url));
      };
    }
    return undefined;
  }, [url, dispatch, scope, priority, enable]);

  const state = useSelector(createSelectSageDataState(url));

  return {
    ...state,
    waiting: willSubscribe || state?.waiting,
    loading: willSubscribe || state?.loading,
    data: (fakeDataEnabled && fakeData ? fakeData : state?.data) as SageDataPaths[R],
  };
}
