import getTooltipBody from './getTooltipBody';

export default (action: 'enter' | 'leave', maxWidth?: string) => (e: React.SyntheticEvent<HTMLElement>) => {
  const el = e.currentTarget;
  if (el) {
    const tooltipBody = getTooltipBody(el);
    if (tooltipBody) {
      const { bottom, left } = el.getBoundingClientRect();
      tooltipBody.animate(
        [
          {
            opacity: action === 'enter' ? '0' : '1',
          },
          {
            opacity: action === 'enter' ? '1' : '0',
          },
        ],
        {
          duration: action === 'enter' ? 250 : 0,
          delay: action === 'enter' ? 1250 : 0,
          fill: 'forwards',
        },
      );
      tooltipBody.style.display = action === 'enter' ? 'block' : 'none';
      tooltipBody.style.top = action === 'enter' ? `${bottom}px` : '';
      tooltipBody.style.maxWidth = action === 'enter' ? `${maxWidth || el.clientWidth}px` : '';
      tooltipBody.style.left = action === 'enter' ? `${left + 20}px` : '';
    }
  }
};
