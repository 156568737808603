import cx from 'classnames';
import { type ReactNode, forwardRef } from 'react';

import { useKeyDown } from 'frontend/hooks';

import { getFolderClassnames } from '../../utils';

type Props = {
  children: ReactNode;
  toggleOpen: () => void;
  className?: string;
  title?: string;
  disabled?: boolean;
  isDragging?: boolean;
  canDrop?: boolean;
  isOver?: boolean;
  active?: boolean;
};

const LibraryFolder = forwardRef(({ children, toggleOpen, className, title, ...styleProps }: Props, ref) => {
  const onKeyDown = useKeyDown({ action: toggleOpen });
  const classNames = cx(className, getFolderClassnames(styleProps));

  return (
    <div
      // @ts-expect-error: How to type refs correctly?
      ref={ref}
      className={classNames}
      onClick={toggleOpen}
      onKeyDown={onKeyDown}
      title={title}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
});

LibraryFolder.displayName = 'LibraryFolder';

export default LibraryFolder;
