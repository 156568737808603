import { Exclamation } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './ZodiacWarning.scss';

const ZodiacWarning = () => (
  <div className={styles.zodiacWarning}>
    <Icon component={Exclamation} color="primary" className={styles.icon} />
    <div className={styles.text}>A minimum of 20 samples is recommended.</div>
  </div>
);

export default ZodiacWarning;
