import cx from 'classnames';
import type React from 'react';

import styles from './PreviewCard.scss';

interface Props {
  heading: string;
  children: React.ReactNode;
  className?: string;
}

export default function PreviewCard({ heading, children, className }: Props): React.JSX.Element {
  return (
    <div className={cx(styles.previewCard, className)}>
      <h3 className={styles.previewCardHeading}>{heading}</h3>
      {children}
    </div>
  );
}
