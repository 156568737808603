import cx from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, useLocation } from 'react-router-dom';

import { Check, Connect, ExternalLink, GoTo } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { Avatar } from 'frontend/features/Inbox/components';
import { ChatMessageType } from 'frontend/features/Inbox/views/Conversation/propTypes';
import { useToast } from 'frontend/hooks';
import { AutoUserType, IDType } from 'frontend/propTypes';
import ChildrenType from 'frontend/propTypes/ChildrenType';
import { DATE_FORMAT_WITH_FULL_TIME, TIME_FORMAT_HOURS } from 'frontend/utils/date';

import styles from './ChatGroup.scss';
import ConfidenceScore from './ConfidenceScore';
import { ExtraEmailRecipients } from '../HandoverForm/HandoverForm';
import ReplyCandidates from '../ReplyCandidates';

const exchangeTypeInBuild = (exchangeType) =>
  ['usersays', 'greeting', 'fallback', 'system-dialogue'].includes(exchangeType);

const goToDialogue = (chatMessage, botId) => {
  const { fromBot, exchangeType, fromWebhook, exchangeId } = chatMessage;
  if (!exchangeId || fromWebhook || !fromBot || !exchangeTypeInBuild(exchangeType)) return null;

  let url = null;
  if (exchangeType === 'system-dialogue') url = `/workspace/${botId}/build/system-dialogue/${exchangeId}`;
  else if (exchangeType === 'usersays') url = `/workspace/${botId}/build/dialogue/${exchangeId}`;
  else if (exchangeType === 'fallback') url = `/workspace/${botId}/build/fallback/${exchangeId}`;
  else if (exchangeType === 'greeting') url = `/workspace/${botId}/build/greeting/${exchangeId}`;

  return url;
};

const ChatGroup = ({
  children,
  botId,
  botAvatarUrl,
  chatAvatarUrl,
  autoUser,
  firstMessage,
  showModal,
  fromBot,
  botOrAgentName,
  userName,
  canViewDialogues,
  seen,
  email,
}) => {
  const [showML, setShowML] = useState(false);
  const time = format(firstMessage.created, TIME_FORMAT_HOURS);
  const toast = useToast();
  const { pathname } = useLocation();
  // Checking the first message for reply candidates.
  // For now (10.10.2019), it should not be possible for the bot to answer
  // two times in a row. If this happens, the reply candidates can be misleading.
  // If this becaomes a feature, it will need to check all messages in the group,
  // and not just the first message.
  const replyCandidates = firstMessage.replyCandidates.filter((item) => item.selected === false);
  const hasCandidates = replyCandidates && replyCandidates.length > 0;

  const className = cx(styles.chatGroup, {
    [styles.hasCandidates]: hasCandidates,
    [styles.chatGroupActive]: showML,
    [styles.user]: !firstMessage.fromBot,
    [styles.botOrAgent]: firstMessage.fromBot,
  });
  const name = fromBot ? botOrAgentName : userName;

  const url = goToDialogue(firstMessage, botId);
  const showExtraOptions = false; // Use later when applicable

  const isEmail = firstMessage.chatSource === 'email';

  const onCopy = useCallback(() => {
    toast.success('A link to the message was copied to the clipboard');
  }, [toast]);

  return (
    <section className={className} id={`#${firstMessage.id}`} data-cy="section-chat-group">
      <Avatar
        hash={pathname}
        className={styles.avatar}
        botAvatarUrl={botAvatarUrl}
        chatAvatarUrl={chatAvatarUrl}
        autoUser={autoUser}
        message={firstMessage}
        size="small"
      />
      {isEmail && <div className={styles.verticalLine} />}
      <div className={styles.bubbleInfo}>
        <span className={styles.name}>{name}</span>
        <span className={styles.time} title={format(firstMessage.created, DATE_FORMAT_WITH_FULL_TIME)}>
          {time}
        </span>
        {seen && <Icon component={Check} color="gray" className={styles.seenIcon} title="Seen by user" />}
        <ConfidenceScore firstMessage={firstMessage} showModal={showModal} />
        {firstMessage.isFollowup && <span className={styles.followup}>FOLLOW-UP</span>}
      </div>
      {isEmail && (
        <div className={styles.email}>
          Email <span>{fromBot ? 'to' : 'from'}</span>: {email} <ExtraEmailRecipients cc={firstMessage.emailCc} />
        </div>
      )}
      <div className={styles.options}>
        {!!url && canViewDialogues && (
          <Link to={url} target="_blank" title="Go to dialogue" className={styles.optionsItem}>
            <Icon
              component={GoTo}
              color="gray"
              hoverColor="primary"
              title="Go to dialogue"
              className={styles.externalLinkIcon}
            />
          </Link>
        )}
        {hasCandidates && (
          <button
            className={styles.optionsItem}
            title="Potential dialogues"
            onClick={() => setShowML(!showML)}
            type="button"
          >
            <Icon
              component={Connect}
              color="gray"
              hoverColor="primary"
              title="Potential dialogues"
              className={styles.mlIcon}
            />
          </button>
        )}
        <CopyToClipboard text={`${window.env.SITE_URL}${pathname}#${firstMessage.id}`} onCopy={onCopy}>
          <button className={styles.optionsItem} title="Copy link to clipboard" type="button">
            <Icon
              component={ExternalLink}
              color="gray"
              title="Copy link to clipboard"
              hoverColor="primary"
              className={styles.urlIcon}
            />
          </button>
        </CopyToClipboard>
        {showExtraOptions && (
          <button className={cx(styles.optionsItem, styles.threeDots)} type="button">
            <span className={styles.dots} />
          </button>
        )}
      </div>
      <div className={styles.chatbubbles}>{children}</div>
      {hasCandidates && showML && (
        <div className={styles.replyCandidate}>
          <ReplyCandidates messageId={firstMessage.id} languageCode={firstMessage.chatLanguageCode} botId={botId} />
        </div>
      )}
    </section>
  );
};

ChatGroup.propTypes = {
  children: ChildrenType.isRequired,
  botAvatarUrl: PropTypes.string,
  chatAvatarUrl: PropTypes.string,
  autoUser: AutoUserType,
  firstMessage: ChatMessageType,
  showModal: PropTypes.func.isRequired,
  botOrAgentName: PropTypes.string,
  fromBot: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  canViewDialogues: PropTypes.bool.isRequired,
  seen: PropTypes.bool,
  botId: IDType.isRequired,
  email: PropTypes.string,
};

export default ChatGroup;
