export const IGNORED_BOT_SETTINGS = [
  '__typename',
  'avatarUrl',
  'zodiacSettings',
  'juxtapose__typename',
  'nlp__typename',
  'permissions',
  'languages',
  'juxtaposeId',
  'nlpSettings',
  'nlpId',
  'organization',
];
/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export const EXPORT_STATUSES = Object.freeze({
  ERROR: 'ERROR',
  PROCESSING: 'PROCESSING',
  FINISHED: 'FINISHED',
});

export type ExportStatuses = keyof typeof EXPORT_STATUSES;
// type ExportStatuses = 'FINISHED' | 'PROCESSING' | 'ERROR';

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export const IMPORT_STATUSES = Object.freeze({
  ERROR: 'ERROR',
  PROCESSING: 'PROCESSING',
  IMPORTED: 'IMPORTED',
  READY: 'READY',
});

export type ImportStatuses = keyof typeof IMPORT_STATUSES;
// type ImportStatuses = 'IMPORTED' | 'PROCESSING' | 'ERROR' | 'READY';
