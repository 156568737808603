import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';

import { OrganizationMemberDocument, type OrganizationRoleType } from 'frontend/api/generated';
import { ChevronDown } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import type { PartialUser } from 'frontend/features/Organization/modals/AddMemberModal/AddMemberModal';
import type { AnyRole } from 'frontend/features/Organization/types';

import styles from './styles.scss';
import RoleDetails from '../../../../components/RoleDetails/RoleDetails';
import DefinedRoles from '../DefinedRoles/DefinedRoles';

const DISPLAY_TYPES = ['PREDEFINED', 'DETAILED'] as const;

function getMemberUserId({
  userId,
  values,
  users,
}: {
  userId?: string;
  values: { email: string };
  users: PartialUser[];
}): string | undefined {
  if (userId) {
    return userId;
  }

  const user = users.find((us) => us?.username && values.email === us?.username);

  return user?.id;
}

function useRelevantRoles({
  roles,
  users,
  disallowOrganizationRoles,
  organizationId,
  userId,
}: {
  roles: AnyRole[];
  users: PartialUser[];
  disallowOrganizationRoles: boolean;
  organizationId: string;
  userId?: string;
}) {
  const { values } = useFormState<{ email: string }>();
  const memberUserId = getMemberUserId({ userId, values, users });
  const { data, loading } = useQuery(OrganizationMemberDocument, {
    variables: { userId: memberUserId!, organizationId },
    skip: !disallowOrganizationRoles || !memberUserId,
  });

  return useMemo(() => {
    if (loading) {
      return [];
    }

    if (!disallowOrganizationRoles) {
      return roles;
    }

    const allUserPermissions = ((data?.organization?.member?.membership?.roles ?? []) as OrganizationRoleType[]).reduce(
      (allPermissions: string[], { permissions }) => [...allPermissions, ...(permissions ?? ([] as string[]))],
      [],
    );

    return roles.filter(
      ({ permissions }) => !permissions.every((permission) => allUserPermissions.includes(permission)),
    );
  }, [loading, data, disallowOrganizationRoles, roles]);
}

type Props = {
  users: PartialUser[];
  roles: AnyRole[];
  allPermissions: string[];
  disallowOrganizationRoles: boolean;
  organizationId: string;
  userId?: string;
};

function UserRole({ users, roles, allPermissions, disallowOrganizationRoles, organizationId, userId }: Props) {
  const [display, setDisplay] = useState<(typeof DISPLAY_TYPES)[number]>('PREDEFINED');
  const showDefinedRoles = DISPLAY_TYPES.includes(display);
  const showDetailsView = display === 'DETAILED';

  const toggleDetailed = useCallback(
    () => setDisplay((currentDisplay) => (currentDisplay === 'DETAILED' ? 'PREDEFINED' : 'DETAILED')),
    [],
  );

  const relevantRoles = useRelevantRoles({ roles, users, disallowOrganizationRoles, organizationId, userId });

  return (
    <Modal.Content>
      <p className="m-t-0">A role will define what kind of access and changes a member can do.</p>
      {showDefinedRoles && (
        <>
          <DefinedRoles roles={relevantRoles} />

          <button type="button" className={styles.showDetails} onClick={toggleDetailed}>
            {display === 'DETAILED' ? 'Hide' : 'Show'} role details
            <Icon
              component={ChevronDown}
              className={cx(styles.triangle, {
                [styles.rotateTriangle]: display === 'DETAILED',
              })}
              color="black"
            />
          </button>
        </>
      )}
      {showDetailsView && <RoleDetails roles={roles} allPermissions={allPermissions} />}
    </Modal.Content>
  );
}

export default UserRole;
