import cx from 'classnames';

import styles from './ButtonClicks.scss';

type Props = { data: { label: string; className?: string } };
const Label = ({ data: { label, className } }: Props) => (
  <div className={cx(styles.label, className)}>
    <span>{label || 'N/A'}</span>
  </div>
);

export default Label;
