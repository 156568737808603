import { Metric } from 'frontend/components/Stats';
import { Grid, StatsPanel } from 'frontend/features/Analytics/components';

interface MetricProps {
  metric: number;
  text: string;
  postfix: string;
}
interface Props {
  campaignId: string;
  metrics: MetricProps[];
  loading: boolean;
}
const SummaryPanel = ({ campaignId, metrics, loading }: Props) => (
  <Grid columns={1}>
    <StatsPanel>
      <h3>{campaignId ? `Campaign ID: ${campaignId}` : 'All campaigns'}</h3>
      <Grid columns={3} noMargin>
        {metrics.map(({ metric, text, postfix }) => (
          <StatsPanel key={text} loading={loading} nested>
            {!Number.isNaN(metric) && <Metric metric={metric} postfix={postfix} topText={text} center={false} />}
          </StatsPanel>
        ))}
      </Grid>
    </StatsPanel>
  </Grid>
);

export default SummaryPanel;
