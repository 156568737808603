import { truncate } from 'lodash';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { AnnotationWarning, DoorExit, GoTo, Info, Www } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import AdvancedTooltip from 'frontend/components/AdvancedTooltip/AdvancedTooltip';
import { useAppSelector } from 'frontend/state/hooks';

import styles from './SystemMessage.scss';
import { SYSTEM_MESSAGE_TYPE } from '../../utils/constants';
import getUserName from '../../utils/getUserName';
import Timestamp from '../Timestamp/Timestamp';

type KeysType = keyof typeof SYSTEM_MESSAGE_TYPE;

interface SystemMessageProps {
  type: (typeof SYSTEM_MESSAGE_TYPE)[KeysType];
  created: number;
  message?: string | null;
  statusCode?: number | null;
}

const SystemMessage = ({ message, type, created, statusCode }: SystemMessageProps) => {
  const { selectedChat } = useAppSelector((state) => state.insights);
  const { botId } = useParams();

  const getSystemMessage = () => {
    const user = getUserName(selectedChat);
    const hasSuccessfulResponse = statusCode && statusCode >= 200 && statusCode < 300;
    switch (type) {
      case SYSTEM_MESSAGE_TYPE.URL:
        return (
          <>
            <Icon component={Www} width={20} height={20} color="gray" />
            &nbsp;{user} is at&nbsp;
            {message && (
              <AdvancedTooltip maxWidth="480">
                <a href={message} target="_blank" rel="noreferrer">
                  {truncate(message, {
                    length: 40,
                  })}
                </a>
                <AdvancedTooltip.Body>{message}</AdvancedTooltip.Body>
              </AdvancedTooltip>
            )}
          </>
        );
      case SYSTEM_MESSAGE_TYPE.USER_LEFT:
        return (
          <>
            <Icon component={DoorExit} width={20} height={20} color="gray" />
            &nbsp;User left chat
          </>
        );
      case SYSTEM_MESSAGE_TYPE.FEEDBACK:
        return (
          <>
            <Icon component={AnnotationWarning} width={20} height={20} color="gray" />
            &nbsp;User left feedback
          </>
        );
      case SYSTEM_MESSAGE_TYPE.WEBHOOK:
        return (
          <>
            <Icon component={Info} width={20} height={20} color="gray" />
            &nbsp;{hasSuccessfulResponse ? 'Successful' : 'Failed'} webhook request &nbsp;
            <Link
              to={`/workspace/${botId}/connect/debug-console`}
              target="_blank"
              title="Go to Debug Console"
              className={styles.optionsItem}
            >
              <Icon
                component={GoTo}
                width={14}
                height={14}
                color="gray"
                hoverColor="darken"
                title="Go to Debug Console"
              />
            </Link>
          </>
        );
      default:
        return message;
    }
  };

  return (
    <div className={styles.chatSystemMessage}>
      {getSystemMessage()}
      <div className={styles.chatSystemMessageTimestamp}>
        <Timestamp timestamp={created} />
      </div>
    </div>
  );
};

export default SystemMessage;
