import { Field, useFormState } from 'react-final-form';

import { Tags } from 'frontend/components';

interface Props {
  currentLanguage: string;
  isModDialogue?: boolean;
}

const Keywords = ({ currentLanguage, isModDialogue }: Props) => {
  const { submitting: disabled } = useFormState();

  return (
    <div>
      <Field
        name={`keywords.${currentLanguage}`}
        component={Tags}
        disabled={isModDialogue || disabled}
        storeAsJson
        tagsHeader="Keywords"
        inputFooter={`Keywords can consist of one or several words. Words in keyword
                    phrases can be separated by for example spaces or hyphens, but
                    the bot will differentiate between these.`}
      />
    </div>
  );
};
export default Keywords;
