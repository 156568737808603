import { gql } from '@apollo/client';

import { SIMPLE_EVALUATION_FRAGMENT } from './fragments';

const RUN_SIMPLE_EVALUATION = gql`
  mutation RunEvaluation($botId: ID!, $evaluationId: ID!, $messageHeader: String!) {
    runSimpleEvaluation(botId: $botId, evaluationId: $evaluationId, messageHeader: $messageHeader) {
      ...SimpleEvaluation
    }
  }
  ${SIMPLE_EVALUATION_FRAGMENT}
`;
export default RUN_SIMPLE_EVALUATION;
