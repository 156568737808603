import type { Row } from 'frontend/components/Table/Table';

import styles from '../styles.scss';

export const LanguageCell = ({ rowData: workspace }: { rowData?: Row }) => {
  if (!workspace) return null;
  const { languages } = workspace;
  return (
    <div className={styles.languageCell}>
      {languages.map((language) => (
        <div className={styles.languageCode} key={language.code}>
          {language.code}
        </div>
      ))}
    </div>
  );
};
