import { Field, useForm } from 'react-final-form';

import { Minus, Plus } from 'frontend/assets/icons';
import { Button, ToggleSwitch } from 'frontend/components';
import { toNumber } from 'frontend/form/parsers';

import styles from '../../Privacy.scss';

const numbersOnly = (e) => {
  // Allow: backspace, delete, tab, escape, enter and .
  if (
    [46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
    // Allow: Ctrl/cmd+A
    (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: Ctrl/cmd+C
    (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: Ctrl/cmd+X
    (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: home, end, left, right
    (e.keyCode >= 35 && e.keyCode <= 39)
  ) {
    // let it happen, don't do anything
    return;
  }
  // Ensure that it is a number and stop the keypress
  if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
    e.preventDefault();
  }
};

const PersonalData = () => {
  const form = useForm();
  return (
    <div className={styles.sectionContainer}>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Automatic deletion of chat messages</h3>
      <p>This feature will erase all personal data after a set time.</p>
      <div className="m-t-lg m-b-lg">
        <ToggleSwitch name="deletePersonalData" status={['Use auto-deletion of chat messages and personal data.']} />
      </div>
      <div className={`${styles.personalDaysWrapper} m-b-md`}>
        <Field name="deletePersonalDataDays" parse={toNumber}>
          {({ input: { value } }) => (
            <Button
              className={styles.personalDaysButton}
              onClick={() => {
                if (value > 0) {
                  form.change('deletePersonalDataDays', value - 1);
                }
              }}
              icon={Minus}
              shape="circle"
              flat
            />
          )}
        </Field>
        <Field
          className={styles.plusMinusNumber}
          component="input"
          type="text"
          name="deletePersonalDataDays"
          onKeyDown={(e) => numbersOnly(e)}
          parse={toNumber}
        />
        <Field name="deletePersonalDataDays" parse={toNumber}>
          {({ input: { value } }) => (
            <Button
              className={styles.personalDaysButton}
              onClick={() => form.change('deletePersonalDataDays', value + 1)}
              icon={Plus}
              shape="circle"
              flat
            />
          )}
        </Field>
        <span className="m-l-md">Days</span>
      </div>
      <p className="m-b-0">
        Specify how long you want to keep personal data before it will be deleted from the system.
      </p>
    </div>
  );
};

export default PersonalData;
