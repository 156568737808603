import { useQuery } from '@apollo/client';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

import { GetOrganizationDocument } from 'frontend/api/generated';
import { Bird, Buildings, ShieldTick, UserCircleCheck, UserCirclePlus } from 'frontend/assets/icons';
import { PageContent } from 'frontend/components';
import { NotFound } from 'frontend/features';
import SubNav from 'frontend/features/SubNav/SubNav';
import { useTabTitle } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import { BetaSettings, CustomRoles, LegalCompliance, OrgSettings, Roles, Security } from './components';
import styles from './styles.scss';

function FixedLayout() {
  const { organizationId } = useParams();
  const { data } = useQuery(GetOrganizationDocument, { variables: { id: organizationId! } });
  const hasDefaultLicense = data?.organization?.subscriptions.some(({ planName }) => planName === 'DEFAULT');

  const { isKindlyAdminViewer } = useMyPermissions();

  return (
    <PageContent hasFormValidation className={styles.container}>
      <SubNav>
        <SubNav.Item icon={Buildings} to="organization">
          Organization
        </SubNav.Item>
        <SubNav.Item icon={ShieldTick} to="security">
          Security
        </SubNav.Item>
        <SubNav.Item icon={UserCircleCheck} to="roles">
          Roles
        </SubNav.Item>
        <SubNav.Item icon={UserCirclePlus} to="custom-roles">
          Custom roles
        </SubNav.Item>
        {hasDefaultLicense === false && (
          <SubNav.Item icon={UserCirclePlus} to="legal-compliance">
            Legal compliance
          </SubNav.Item>
        )}
        {isKindlyAdminViewer && (
          <SubNav.Item icon={Bird} to="beta" className="kindlyAdminManager">
            Beta
          </SubNav.Item>
        )}
      </SubNav>

      <Outlet />
    </PageContent>
  );
}

export default function Settings() {
  const { organizationId } = useParams();
  const { organizationPermissions } = useMyPermissions({ organizationId });
  const isOrgManager = organizationPermissions.includes('manage_organization');

  useTabTitle('Settings');

  if (!isOrgManager) {
    return <NotFound text="Missing permissions to view page" />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="organization" />} />

      <Route element={<FixedLayout />}>
        <Route path="organization" element={<OrgSettings />} />
        <Route path="security" element={<Security />} />
        <Route path="roles" element={<Roles />} />
        <Route path="custom-roles" element={<CustomRoles />} />
        <Route path="legal-compliance" element={<LegalCompliance />} />
        <Route path="beta" element={<BetaSettings />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
