import { debounce } from 'lodash';
import { defineAction } from 'redux-define';

import { buildTypes } from 'frontend/constants';
import { types as libraryTypes } from 'frontend/features/Library/constants';
import { BotSearch, SkillSearch } from 'frontend/features/Library/queries';

const LIBRARY = defineAction('library');
export const SET_LIBRARY_WIDTH = LIBRARY.defineAction('SET_LIBRARY_WIDTH');
export const SET_LIBRARY_FILTERS = LIBRARY.defineAction('SET_LIBRARY_FILTERS');
export const SET_LIBRARY_FILTERS_OPEN = LIBRARY.defineAction('SET_LIBRARY_FILTERS_OPEN');
export const RESET_LIBRARY_FILTERS = LIBRARY.defineAction('RESET_LIBRARY_FILTERS');
export const SET_LIBRARY_SEARCH_OPEN = LIBRARY.defineAction('SET_LIBRARY_SEARCH_OPEN');
export const SET_LIBRARY_SEARCH_HITS = LIBRARY.defineAction('SET_LIBRARY_SEARCH_HITS');
export const SET_LIBRARY_SEARCH_SELECTED = LIBRARY.defineAction('SET_LIBRARY_SEARCH_SELECTED');
export const SET_LIBRARY_SEARCH_LOADING = LIBRARY.defineAction('SET_LIBRARY_SEARCH_LOADING');
export const SET_LIBRARY_SEARCH_QUERY = LIBRARY.defineAction('SET_LIBRARY_SEARCH_QUERY');
export const SET_LIBRARY_SEARCH_FILTERS = LIBRARY.defineAction('SET_LIBRARY_SEARCH_FILTERS');
export const RESET_LIBRARY_SEARCH = LIBRARY.defineAction('RESET_LIBRARY_SEARCH');

const defaultLocalStorageObject = { library: { width: 270 } };

const initialWidth = JSON.parse(
  localStorage.getItem(libraryTypes.KINDLY_LIBRARY) || JSON.stringify(defaultLocalStorageObject),
);

export const setLibraryWidth = (width) => ({
  type: SET_LIBRARY_WIDTH.ACTION,
  width,
});

export const setLibraryFiltersOpen = (isOpen) => ({
  type: SET_LIBRARY_FILTERS_OPEN.ACTION,
  isOpen,
});

export const setLibraryFilters = (libraryFilters) => ({
  type: SET_LIBRARY_FILTERS.ACTION,
  libraryFilters,
});

export const resetLibraryFilters = () => ({
  type: RESET_LIBRARY_FILTERS.ACTION,
});

export const setLibrarySearchOpen = (isOpen) => ({
  type: SET_LIBRARY_SEARCH_OPEN.ACTION,
  isOpen,
});

export const setLibrarySearchHits = (hits) => ({
  type: SET_LIBRARY_SEARCH_HITS.ACTION,
  hits,
});

export const setLibrarySearchSelected = (selected) => ({
  type: SET_LIBRARY_SEARCH_SELECTED.ACTION,
  selected,
});

export const setLibrarySearchLoading = (isLoading) => ({
  type: SET_LIBRARY_SEARCH_LOADING.ACTION,
  isLoading,
});

export const setLibrarySearchQuery = (searchQuery) => ({
  type: SET_LIBRARY_SEARCH_QUERY.ACTION,
  searchQuery,
});

export const setLibrarySearchFilters = (filter) => ({
  type: SET_LIBRARY_SEARCH_FILTERS.ACTION,
  filter,
});

export const resetLibrarySearch = (isOpen = true) => ({
  type: RESET_LIBRARY_SEARCH.ACTION,
  isOpen,
});

const selectLibrary = ({ library }) => library;

const initialState = {
  width: initialWidth.library.width,
  libraryFilters: {
    isOpen: false,
    filters: {
      fromDate: undefined,
      toDate: undefined,
      dialogueId: undefined,
    },
  },
  search: {
    isOpen: false,
    isLoading: false,
    hits: { dialogues: [], nudges: [] },
    selected: -1,
    searchQuery: '',
    filters: {
      enabledDialogueColors: [],
      enabledDialogueTypes: [],
      enabledDialogueLabels: [],
    },
  },
};

export const selectLibraryWidth = (state) => selectLibrary(state).width;
export const selectLibraryFilters = (state) => selectLibrary(state).libraryFilters;
export const selectLibrarySearch = (state) => selectLibrary(state).search;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LIBRARY_WIDTH.ACTION:
      defaultLocalStorageObject.library.width = action.width;
      localStorage.setItem(libraryTypes.KINDLY_LIBRARY, JSON.stringify(defaultLocalStorageObject));

      return { ...state, width: action.width };
    case SET_LIBRARY_FILTERS.ACTION:
      return { ...state, libraryFilters: { ...state.libraryFilters, filters: action.libraryFilters } };

    case SET_LIBRARY_FILTERS_OPEN.ACTION:
      return { ...state, libraryFilters: { ...state.libraryFilters, isOpen: action.isOpen } };

    case RESET_LIBRARY_FILTERS.ACTION:
      return { ...state, libraryFilters: initialState.libraryFilters, search: initialState.search };

    case SET_LIBRARY_SEARCH_OPEN.ACTION:
      return { ...state, search: { ...state.search, isOpen: action.isOpen } };

    case SET_LIBRARY_SEARCH_HITS.ACTION:
      return { ...state, search: { ...state.search, hits: action.hits } };

    case SET_LIBRARY_SEARCH_SELECTED.ACTION:
      return { ...state, search: { ...state.search, selected: action.selected } };

    case SET_LIBRARY_SEARCH_LOADING.ACTION:
      return { ...state, search: { ...state.search, isLoading: action.isLoading } };

    case SET_LIBRARY_SEARCH_QUERY.ACTION:
      return { ...state, search: { ...state.search, searchQuery: action.searchQuery } };

    case SET_LIBRARY_SEARCH_FILTERS.ACTION:
      return { ...state, search: { ...state.search, filters: { ...state.search.filters, ...action.filter } } };

    case RESET_LIBRARY_SEARCH.ACTION:
      return {
        ...state,
        search: {
          ...initialState.search,
          isOpen: typeof action.isOpen !== 'undefined' ? action.isOpen : state.search.isOpen,
        },
      };

    default:
      return state;
  }
};

const DEBOUNCE_DELAY_MS = 300;

const debounceSearch = debounce(
  async ({ buildType, buildIdObject, languageCode, query, labels, types, colors, client }, dispatch) => {
    const variables = { ...buildIdObject, query, languageCode, labels, types, colors };

    if (!query && !labels.length && !types.length && !colors.length) {
      return undefined;
    }

    dispatch(setLibrarySearchLoading(true));

    const { data } = await client.query({
      query: buildType === buildTypes.BOT ? BotSearch : SkillSearch,
      variables,
      fetchPolicy: 'network-only',
    });

    dispatch(
      setLibrarySearchHits({
        dialogues: data?.[buildType]?.search || [],
        nudges: data?.[buildType]?.searchNudges || [],
      }),
    );
    dispatch(setLibrarySearchSelected(-1));

    dispatch(setLibrarySearchLoading(false));

    return undefined;
  },
  DEBOUNCE_DELAY_MS,
);

export const searchLibrary = (variables) => async (dispatch) => debounceSearch(variables, dispatch);
