import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { IDType } from 'frontend/propTypes';

import styles from './Entities.scss';
import EntityList from './components/EntityList';
import { useRelevantSearchResults } from './hooks';
import { EntitiesType } from './propTypes';

const SubscriptionEntities = ({ skill: { id, name, entities }, searchResults }) => {
  const subscriptionEntities = useRelevantSearchResults(entities, searchResults);

  if (subscriptionEntities.length === 0) return null;

  return (
    <Fragment key={`entities-page-subscription-${id}`}>
      <h3 className={styles.headerRow}>{name} entities</h3>
      <EntityList
        entities={subscriptionEntities}
        noEntities="None"
        withProperties
        areSkillEntities
        keepOrder={Boolean(searchResults)}
      />
    </Fragment>
  );
};

SubscriptionEntities.propTypes = {
  skill: PropTypes.shape({
    id: IDType.isRequired,
    name: PropTypes.string.isRequired,
    entities: EntitiesType.isRequired,
  }),
  searchResults: EntitiesType,
};

export default SubscriptionEntities;
