import { gql } from '@apollo/client';

export default gql`
  fragment BuildActivity on ActivityType {
    id
    action
    createdAt
    message
    extra
    targetDialogue {
      id
      title
      url
    }
  }
`;
