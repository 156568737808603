import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { selectSorting, setSampleSorting, sortingTypes } from 'frontend/state/dux/build';

import styles from './SortSamples.scss';

const SortSamples = () => {
  const sortedBy = useSelector(selectSorting);
  const dispatch = useDispatch();

  const sortByWordCount = () => dispatch(setSampleSorting(sortingTypes.WORD_COUNT));
  const sortByChanged = () => dispatch(setSampleSorting(sortingTypes.RECENTLY_CHANGED));
  const sortByText = () => dispatch(setSampleSorting(sortingTypes.ALPHABETICAL));

  return (
    <div className={styles.sortSamples}>
      Sort by:
      <button
        className={cx(styles.sortButton, { [styles.sortButtonActive]: sortedBy === sortingTypes.RECENTLY_CHANGED })}
        onClick={sortByChanged}
        type="button"
      >
        Recently changed
      </button>
      <button
        className={cx(styles.sortButton, { [styles.sortButtonActive]: sortedBy === sortingTypes.ALPHABETICAL })}
        onClick={sortByText}
        type="button"
      >
        Alphabetical order
      </button>
      <button
        className={cx(styles.sortButton, { [styles.sortButtonActive]: sortedBy === sortingTypes.WORD_COUNT })}
        onClick={sortByWordCount}
        type="button"
      >
        Word count
      </button>
    </div>
  );
};

export default SortSamples;
