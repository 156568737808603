type LearnerName =
  | 'zodiac_mlp'
  | 'zodiac_bilstm'
  | 'zodiac_cnn'
  | 'zodiac_self_attention_cnn'
  | 'zodiac_self_attention_lstm'
  | 'zodiac_logistic_regression';

type FeaturizerName = 'mle5b' | 'labse';

interface Learner {
  name: LearnerName;
  label: string;
}

interface Featurizer {
  name: FeaturizerName;
  label: string;
  supportedLearners: Learner[];
  isMultilingual: boolean;
}

export const learnerChoices: Record<LearnerName, Learner> = Object.freeze({
  zodiac_mlp: {
    name: 'zodiac_mlp',
    label: 'MLP',
  },
  zodiac_bilstm: {
    name: 'zodiac_bilstm',
    label: 'BiLSTM',
  },
  zodiac_cnn: {
    name: 'zodiac_cnn',
    label: 'CNN',
  },
  zodiac_self_attention_cnn: {
    name: 'zodiac_self_attention_cnn',
    label: 'zodiac_cnn_self_attention',
  },
  zodiac_self_attention_lstm: {
    name: 'zodiac_self_attention_lstm',
    label: 'LSTM with Self Attention',
  },
  zodiac_logistic_regression: {
    name: 'zodiac_logistic_regression',
    label: 'Logistic Regression',
  },
});

export const featurizerChoices: Record<FeaturizerName, Featurizer> = Object.freeze({
  labse: {
    name: 'labse',
    label: 'Multilingual LaBSE',
    supportedLearners: [learnerChoices.zodiac_mlp, learnerChoices.zodiac_logistic_regression],
    isMultilingual: true,
  },
  mle5b: {
    name: 'mle5b',
    label: 'Multilingual E5 Base',
    supportedLearners: [learnerChoices.zodiac_mlp, learnerChoices.zodiac_logistic_regression],
    isMultilingual: true,
  },
});
