/*
  Example usage: add chat item to bot.chats

  cache.modify({
    id: cache.identify(bot),
    fields: { chats: addListItemByFragment(cache, chatListItemFragment, chat) },
  });
*/

export default (cache, fragment, data) =>
  (current = []) => [cache.writeFragment({ fragment, data }), ...current];
