import { Field, useField } from 'react-final-form';

import { Wink } from 'frontend/assets/icons';
import { Input, PageBar, Panel, Range, ToggleSwitch } from 'frontend/components';
import { undefinedToString } from 'frontend/form/parsers';
import { max } from 'frontend/form/validators';
import { useCurrentLanguage } from 'frontend/hooks';

import styles from './PushGreetingItem.scss';

const max200 = max(200);

type PushGreetingItemProps = {
  name: string;
};

const PushGreetingItem = ({ name }: PushGreetingItemProps) => {
  const {
    input: { value },
  } = useField(name);

  const [{ currentLanguage }] = useCurrentLanguage();

  return (
    <>
      <Panel.Header className={styles.buildPanelHeader}>
        <div className={styles.buildPanelTitle}>
          <Wink />
          &nbsp;{value?.title?.[currentLanguage] || 'New push greeting'}
        </div>
        <PageBar.FormButtons className={styles.formSubmitBtn} />
      </Panel.Header>
      <div className={styles.chatbubbleSettingsWrapper}>
        <div className={styles.editGreeting}>
          <div className={styles.inputContainer}>
            <Field
              component={Input}
              name={`${name}.title.${currentLanguage}`}
              label="Name this Push greeting"
              placeholder="Write a greeting title"
            />
          </div>
          <div className={styles.inputContainer}>
            <Field
              component={Input}
              name={`${name}.message.${currentLanguage}`}
              label="Push message"
              validate={max200}
              placeholder="Write a greeting message"
            />
          </div>
          <div className={styles.inputContainer}>
            <Field
              component={Input}
              name={`${name}.url`}
              label="URL to trigger on"
              placeholder="https://www.website.com"
              parse={undefinedToString}
            />
          </div>
          <div className={styles.fieldDescription}>
            <p>
              Use the URL field if you only want the push greeting to appear on a certain page. If left blank, the push
              greeting appears on every page of your website.
            </p>
            <p>
              The URL string matches at any point of the user URL, so you don&apos;t need <code>www</code> or{' '}
              <code>https</code>. It&apos;s enough that the user location contains the URL string.
            </p>
            <p>
              The URL string supports wildcards (<code>*</code>), e.g. <code>/receipt/*/productId=</code> will match:
            </p>
            <ul className="m-bl-1">
              <li>
                <code>www.example.com/receipt/en/productId=1234</code>
              </li>
              <li>
                <code>www.example.com/receipt/no/productId=5678</code>
              </li>
              <li>
                <code>www.example.com/receipt/sweden/productId=</code>
              </li>
            </ul>
          </div>
          <div className={styles.inputContainer}>
            <strong>Show push greeting after:</strong>
            <Range
              name={`${name}.autopopupTime`}
              min={0}
              max={10000}
              step={10}
              displayValue={(v) => v / 1000}
              displayFixedDecimals={1}
              unit="s"
              className={styles.slider}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.switchContainer}>
              <ToggleSwitch name={`${name}.enabled.${currentLanguage}`} />
              <label htmlFor="ToggleSwitch-tabNotification">Publish</label>
            </div>
            <div className={styles.fieldDescription}>This push greeting will just be a draft until you publish it.</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PushGreetingItem;
