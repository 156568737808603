import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'frontend/components';
import { DIALOGUE_FILTERS, toggleFallback, toggleMatch } from 'frontend/state/dux/evaluation';

import styles from '../Evaluation.scss';

const ResultFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.evaluation.filters);
  const fallbackEnabled = filters.indexOf(DIALOGUE_FILTERS.HIDE_FALLBACK) !== -1;
  const matchEnabled = filters.indexOf(DIALOGUE_FILTERS.HIDE_MATCH) !== -1;

  return (
    <div className={styles.filterContainer}>
      <Button onClick={() => dispatch(toggleFallback())}>
        {fallbackEnabled ? 'Show fallbacks' : 'Hide fallbacks'}
      </Button>
      <Button onClick={() => dispatch(toggleMatch())} className="m-l-1">
        {matchEnabled ? 'Show correct replies' : 'Hide correct replies'}
      </Button>
    </div>
  );
};

export default ResultFilters;
