import type { MonthlyReportType } from 'frontend/api/generated';
import type { ReportData } from 'frontend/features/Analytics/utils/sageData';

export const REPORT_STATES = Object.freeze({
  PENDING: 'PENDING',
  AVAILABLE: 'AVAILABLE',
  SENT: 'SENT',
  ERROR: 'ERROR',
  NO_DATA: 'NO_DATA',
});

export const EMPTY_REPORT_DATA: ReportData = {
  org_id: undefined,
  generated_at: undefined,
  bots: [],
  handovers: {
    finished: 0,
    exited: 0,
    requests: 0,
    requests_while_closed: 0,
    started: 0,
  },
  hours: [],
  total: {
    messages: 0,
    sessions: 0,
    button_clicks: 0,
  },
};

export function isEmptyReport(report: Partial<MonthlyReportType>): boolean {
  return Boolean(report?.state && ['PENDING', 'ERROR'].includes(report.state));
}
