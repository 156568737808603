import { gql } from '@apollo/client';

export default gql`
  fragment Attachment on AttachmentType {
    id
    created
    storagePath
    uploadUrl
    url
    name
    type
    status
    sender
    sizeKb
  }
`;
