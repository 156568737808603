import { useMemo } from 'react';

const useRelevantSearchResults = (relevantEntities, searchResults) => {
  const relevantSearchResults = useMemo(() => {
    if (!searchResults) return relevantEntities;
    const relevantEntityIds = relevantEntities.map(({ id: entityId }) => entityId);
    return searchResults.filter(({ id: entityId }) => relevantEntityIds.includes(entityId));
  }, [searchResults, relevantEntities]);

  return relevantSearchResults;
};

export default useRelevantSearchResults;
