import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { MyOrganizationsDocument, type OrganizationType } from 'frontend/api/generated';
import { Loader } from 'frontend/components';
import { OrganizationList } from 'frontend/features/Organization/components';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { removeOrganizationDeleteInProgress } from 'frontend/state/dux/platform';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';

export default function OrganizationListRoute() {
  const { loading: loadingOrganizations, data: organizationsData } = useQuery(MyOrganizationsDocument);
  const { platformPermissions, loading: loadingPermissions } = useMyPermissions();
  const { organizationDeleteInProgress } = useAppSelector(({ platform }) => platform);
  const dispatch = useAppDispatch();

  const loading = loadingOrganizations || loadingPermissions;

  const organizations = useMemo(
    () =>
      organizationsData?.organizations?.map((org) => ({
        ...(organizationDeleteInProgress?.some(({ id }) => id === org.id) ? { state: 'DELETING' } : {}),
        ...org,
      })) ?? [],
    [organizationsData, organizationDeleteInProgress],
  );

  useEffect(() => {
    if (organizationDeleteInProgress?.length) {
      const deleteOrgIds = organizationDeleteInProgress.map(({ id }) => id);
      const organizationsToRemove = deleteOrgIds.filter(
        (deleteOrgId) => !organizations.some(({ id }) => id === deleteOrgId),
      );
      organizationsToRemove.forEach((deleteOrgId) => {
        if (deleteOrgId) {
          dispatch(removeOrganizationDeleteInProgress(deleteOrgId));
        }
      });
    }
  }, [organizationDeleteInProgress, organizations, dispatch]);

  const canAddOrganization = platformPermissions.includes('organizations.add_organization');

  if (loading) {
    return <Loader size="large" />;
  }

  if (!canAddOrganization && organizations.length === 1) {
    return <Navigate to={`/organization/${organizations[0]!.id}`} />;
  }

  return (
    <OrganizationList
      organizations={organizations as (OrganizationType & { state?: string })[]}
      canAddOrganization={canAddOrganization}
    />
  );
}
