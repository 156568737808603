import PropTypes from 'prop-types';

const Circle = ({ title, color = '#FFAC4A' }) => (
  <svg width="8px" height="8px" viewBox="0 0 7 7">
    <title>{title || 'Circle icon'}</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Build-Library" transform="translate(-55.000000, -262.000000)" fill={color}>
        <g id="Group-26" transform="translate(55.000000, 261.000000)">
          <g id="keywords" transform="translate(0.000000, 1.000000)">
            <circle id="Oval" cx="3.5" cy="3.5" r="3.5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Circle.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

export default Circle;
