import { gql } from '@apollo/client';

export default gql`
  query TicketViewCounts(
    $botId: ID
    $organizationId: ID
    $sourceFilter: [String]
    $handoverStatusFilter: [String]
    $contactDetailsStatusFilter: [String]
    $labelFilter: [ID]
    $languageFilter: [String]
    $botFilter: [String]
  ) {
    ticketViewCounts(
      botId: $botId
      organizationId: $organizationId
      sourceFilter: $sourceFilter
      handoverStatusFilter: $handoverStatusFilter
      contactDetailsStatusFilter: $contactDetailsStatusFilter
      labelFilter: $labelFilter
      languageFilter: $languageFilter
      botFilter: $botFilter
    ) {
      id
      unassignedTickets
      assignedToMe
      openTickets
      pendingTickets
      solvedTickets
    }
  }
`;
