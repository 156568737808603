export default function isValidHTML5Pattern(regexString: string): undefined | string {
  // Check if the regexString is empty
  if (!regexString?.trim()) {
    return undefined;
  }

  // Check if the regexString is a valid JavaScript RegExp
  try {
    RegExp(regexString);
  } catch (e) {
    return 'Invalid regular';
  }

  // Validate against HTML5 pattern restrictions
  const invalidPatterns = [
    { name: 'start caret', pattern: /^\^/ },
    { name: 'end dolar', pattern: /\$$/ },
    { name: 'dot star', pattern: /\.\*/ },
    { name: 'dot plus', pattern: /\.\+/ },
    { name: 'empty range', pattern: /\{,\}/ },
    { name: 'open ended range', pattern: /\{\d+,\}/ },
    { name: 'alternation', pattern: /\|/ },
    { name: 'lookaheads and non capturing', pattern: /\(\?[=!:]/ },
  ];

  for (let i = 0; i < invalidPatterns.length; i++) {
    if (invalidPatterns[i]?.pattern.test(regexString)) {
      return `Invalid pattern: ${invalidPatterns[i]?.name} ${regexString.match(invalidPatterns[i]?.pattern as RegExp)}`;
    }
  }

  return undefined;
}
