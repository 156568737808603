import { gql } from '@apollo/client';

import { CHAT_MESSAGE } from '../fragments';

export default gql`
  query GetChatMessages($botId: ID!, $chatId: ID!, $limit: Int, $after: DateTime, $before: DateTime) {
    chatMessages(botId: $botId, chatId: $chatId, limit: $limit, after: $after, before: $before) {
      id
      messages {
        ...ChatMessage
      }
      remaining
    }
  }
  ${CHAT_MESSAGE}
`;
