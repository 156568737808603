import { gql } from '@apollo/client';

import { BUILT_IN_CONTEXT_FIELD, CONTEXT_FIELD } from '../fragments';

export default gql`
  query AllContextFields($botId: ID!) {
    allContextFields(botId: $botId) {
      ... on ContextFieldType {
        ...ContextField
      }
      ... on BuiltinContextFieldType {
        ...BuiltInContextField
      }
    }
  }
  ${CONTEXT_FIELD}
  ${BUILT_IN_CONTEXT_FIELD}
`;
