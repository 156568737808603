import { Trash } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import type { ImportStatuses } from 'frontend/features/BotSettings/constants';

import styles from './ImportTableOptions.scss';

interface ImportWorkspaceOptionsProps {
  data: {
    status: ImportStatuses;
    preview: () => void;
    deleteImport: () => void;
  };
}

const ImportWorkspaceOptions = ({ data: { status, preview, deleteImport } }: ImportWorkspaceOptionsProps) => (
  <div className={styles.container}>
    {status.toUpperCase() === 'READY' && (
      <Button className="m-r-sm" size="small" onClick={preview}>
        Preview
      </Button>
    )}
    <button type="button" onClick={deleteImport} className={styles.button}>
      <Icon component={Trash} color="text" className={styles.icon} />
    </button>
  </div>
);

export default ImportWorkspaceOptions;
