import { gql } from '@apollo/client';

import { DialogueModFragment } from 'frontend/features/Build/graphql/fragments/DialogueModFragment';

export const SaveDialogueMod = gql`
  mutation SaveDialogueMod(
    $botId: ID!
    $dialogueId: UUID!
    $dialogueModInput: DialogueModInput!
    $languageCode: String!
  ) {
    saveDialogueMod(
      botId: $botId
      dialogueId: $dialogueId
      dialogueModInput: $dialogueModInput
      languageCode: $languageCode
    ) {
      ...DialogueMod
    }
  }
  ${DialogueModFragment}
`;
