import { gql } from '@apollo/client';

export default gql`
  query AutoGeneratedItems($botId: ID, $skillId: ID, $languageCode: String!, $items: [String]!) {
    autoGenerateItems(botId: $botId, skillId: $skillId, languageCode: $languageCode, items: $items) {
      id
      items
    }
  }
`;
