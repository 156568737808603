import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ConnectorDocument, UpdateConnectorDocument } from 'frontend/api/generated';
import { PageContent } from 'frontend/components';
import NotFound from 'frontend/features/NotFound/NotFound';
import { useDocumentTitle, useIsRunning, useOnSubmitWithUpload } from 'frontend/hooks';

import ConnectorForm from './ConnectorForm';

const ConnectorDetail = () => {
  const { connectorId } = useParams();

  const { data, loading } = useQuery(ConnectorDocument, { variables: { connectorId: connectorId as string } });

  useDocumentTitle(() => (!loading && data ? `Connector: ${data?.connector?.name}` : 'Connector: ...'));

  const [updateConnector] = useMutation(UpdateConnectorDocument);

  const onSubmit = useCallback(
    async (values) => {
      const variables = {
        ...values,
        connectorId: values.id,
        ...omit(values, ['__typename']),
        deleteLogo: values.logoUrl === null,
      };
      await updateConnector({ variables });
    },
    [updateConnector],
  );

  const [onSubmitWithUpload, setFile] = useOnSubmitWithUpload(onSubmit, `api/v2/connector/${connectorId}/upload-logo/`);
  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmitWithUpload);

  if (loading) return null;

  if (!data?.connector) return <NotFound text="Connector not found" />;

  return (
    <div>
      <PageContent>
        <ConnectorForm
          onSubmit={onSubmitWithRunning}
          initialValues={data.connector}
          operation="update"
          isSubmitting={isSubmitting}
          setFile={setFile}
        />
      </PageContent>
    </div>
  );
};

export default ConnectorDetail;
