interface RollingProps {
  stroke?: string;
  animate?: boolean;
  step?: number;
  steps?: number[];
}
const Rolling = ({
  stroke,
  animate = true,
  step,
  steps = [54.97787143782138, 109.95574287564276, 164.93361431346415],
}: RollingProps) => {
  const isAnimating = animate && (step === undefined || step >= steps.length);

  const calculateStrokeDasharray = () =>
    `${step !== undefined && step < steps.length ? steps[step] : steps[steps.length - 1]} 164.93361431346415`;

  return (
    <svg
      width="200px"
      height="200px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{ background: 'none' }}
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        strokeWidth="10"
        r="35"
        strokeDasharray={calculateStrokeDasharray()}
        transform="rotate(257.846 50 50)"
        stroke={stroke}
      >
        {isAnimating && (
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        )}
      </circle>
    </svg>
  );
};

export default Rolling;
