import { useApolloClient, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { SAMPLES_WITH_ENTITY } from 'frontend/features/Entities/queries';
import { useCurrentLanguage, useToast } from 'frontend/hooks';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import { UPDATE_ENTITY } from '../../mutations';
import { EntityType } from '../../propTypes';
import { getEntityItemsIncludingInput } from '../../utils';
import EntityModal, { OPERATIONS } from '../EntityModal';

const query = SAMPLES_WITH_ENTITY;
const fetchPolicy = 'network-only';

const UpdateEntity = ({
  args: {
    entity: { id, name: initialName, items: initialItems },
    botOrSkillParams,
  },
  hide,
}) => {
  const toast = useToast();
  const client = useApolloClient();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { buildIdObject } = botOrSkillParams;
  const [newEntityItems, setNewEntityItems] = useState('');

  const [updateEntity] = useMutation(UPDATE_ENTITY);

  const onSubmit = useCallback(
    async ({ name, items }) => {
      const entityInput = { name, items: getEntityItemsIncludingInput(items, newEntityItems) };
      await updateEntity({ variables: { ...buildIdObject, id, languageCode: currentLanguage, entityInput } });
      client.query({ query, variables: { ...buildIdObject, entityId: id }, fetchPolicy });
      toast.success('Entity updated!');
    },
    [buildIdObject, client, currentLanguage, id, newEntityItems, toast, updateEntity],
  );

  return (
    <EntityModal
      operation={OPERATIONS.UPDATE}
      onSubmit={onSubmit}
      initialName={initialName}
      initialItems={initialItems}
      hide={hide}
      currentLanguage={currentLanguage}
      id={id}
      newEntityItems={newEntityItems}
      setNewEntityItems={setNewEntityItems}
      botOrSkillParams={botOrSkillParams}
    />
  );
};

UpdateEntity.propTypes = {
  args: PropTypes.exact({
    entity: EntityType.isRequired,
    botOrSkillParams: BotOrSkillParamsType.isRequired,
  }).isRequired,
  hide: PropTypes.func.isRequired,
};

export default UpdateEntity;
