import emptyInbox from 'frontend/assets/images/empty-inbox.svg?url';
import sharedStyles from 'frontend/features/Inbox/inbox-styles.scss';

const EMPTY_STYLE = { backgroundImage: `url(${emptyInbox})` };

const EmptyState = () => (
  <div className={sharedStyles.emptyInboxList}>
    <div className={sharedStyles.emptyInboxImage} style={EMPTY_STYLE} />
    <p>No conversations found.</p>
    <p>Try adjusting your filter to find what you&apos;re looking for.</p>
  </div>
);

export default EmptyState;
