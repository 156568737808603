import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

import ColorLabelType from './ColorLabelType';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({}).isRequired,
  dialogueType: PropTypes.string.isRequired,
  numberOfFollowups: PropTypes.number.isRequired,
  isActive: PropTypes.shape({}).isRequired,
  samplesCount: PropTypes.shape({}),
  expandedSampleCount: PropTypes.shape({}),
  category: PropTypes.string,
  messageType: PropTypes.string,
  topicId: IDType,
  parentId: IDType,
  dialogueModParentId: IDType,
  colorLabel: ColorLabelType.isRequired,
  mod: PropTypes.shape({
    id: IDType.isRequired,
    modIsActive: PropTypes.shape({}).isRequired,
    modSamplesCount: PropTypes.shape({}),
    expandedModSampleCount: PropTypes.shape({}),
    numberOfModFollowups: PropTypes.number.isRequired,
    modColorLabel: ColorLabelType.isRequired,
  }),
});
