import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { ChevronLeft } from 'frontend/assets/icons';
import defaultAvatar from 'frontend/assets/images/avatar.svg?url';
import { Button, FileUpload, Input, Select, ToggleSwitch } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';
import { IDType } from 'frontend/propTypes';
import { capitalize } from 'frontend/utils';

import styles from './styles.scss';
import { DeleteConnector } from '../modals';

const ConnectorForm = ({ onSubmit, initialValues, operation, setFile, isSubmitting }) => {
  const [showDeleteConnectorModal] = useModal(DeleteConnector);
  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({ handleSubmit, form: { change }, values, dirty }) => (
          <form className={styles.connectorForm} onSubmit={handleSubmit}>
            <div className={`${styles.formHeader} m-b-lg`}>
              <Button onClick={() => navigate(-1)} icon={ChevronLeft} className={styles.headerButton}>
                Back
              </Button>
              <Button
                color={dirty ? 'primary' : 'secondary'}
                className={styles.headerButton}
                onClick={(e) => {
                  handleSubmit();
                  e.preventDefault();
                }}
                isSubmitting={isSubmitting}
              >
                {capitalize(operation)}
              </Button>
              {Boolean(initialValues.id) && (
                <Button
                  color="warning"
                  className={styles.headerButton}
                  onClick={() =>
                    showDeleteConnectorModal({
                      name: initialValues.name,
                      connectorId: initialValues.id,
                    })
                  }
                >
                  Delete
                </Button>
              )}
            </div>
            <div className={styles.formBody}>
              <div className={styles.logoContainer}>
                {values.id ? (
                  <>
                    <img className={styles.portrait} src={values.logoUrl || defaultAvatar} alt={values.name} />
                    <div className={styles.upload}>
                      <Field component={Input} name="logoUrl" hidden />
                      <FileUpload
                        containerClassName={styles.uploadButton}
                        text="Select logo"
                        accept="image/png,image/jpeg"
                        onUpload={([{ source, file }]) => {
                          change('logoUrl', source);
                          setFile(file);
                        }}
                      />
                      {values.logoUrl && (
                        <Button flat onClick={() => change('logoUrl', null)}>
                          Delete logo
                        </Button>
                      )}
                    </div>
                  </>
                ) : (
                  'Save Connector to upload logo'
                )}
              </div>
              <Field
                component={Input}
                name="name"
                placeholder="Name"
                inputLimit={50}
                validate={chain([required, max(50)])}
                label="Name"
              />
              <p className="m-b-md">The name that will appear in the Connect section</p>
              <Field
                component={Input}
                name="slug"
                placeholder="slug"
                inputLimit={64}
                validate={chain([required, max(64)])}
                label="Slug"
              />
              <p className="m-b-md">The slug</p>
              <label htmlFor="connectorType">Connector type</label>
              <Field component={Select} name="connectorType" validate={required}>
                <Select.Option value="" label="Choose connector type" />
                <Select.Option value="CUSTOM" label="Custom" />
                <Select.Option value="CHAT_CLIENT" label="Chat client" />
              </Field>
              <p className="m-b-md">The connector type. Anything but chat client is considered custom.</p>
              <Field
                component={Input}
                name="description"
                placeholder="Enter description"
                label="Description"
                multiline
                validate={required}
              />
              <p>What does the connector do?</p>
              <Field
                component={Input}
                name="ingress"
                placeholder="Short description"
                label="Ingress"
                multiline
                validate={max(128)}
              />
              <p>Short description</p>
              <Field
                component={Input}
                name="ctaButtonLabel"
                placeholder="Button label"
                label="CTA button label"
                validate={max(64)}
              />
              <p className="m-b-md">The label on the CTA button</p>
              <Field
                component={Input}
                name="ctaButtonValue"
                placeholder="https://www.example.com"
                label="CTA button value"
                validate={max(500)}
              />
              <p className="m-b-md">The value (e.g. URL) of the CTA button</p>
              <ToggleSwitch status={['Active', 'Inactive']} name="isActive" />
            </div>
          </form>
        )}
      />
    </div>
  );
};

ConnectorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ id: IDType, name: PropTypes.string }).isRequired,
  setFile: PropTypes.func,
  isSubmitting: PropTypes.bool.isRequired,
  operation: PropTypes.oneOf(['create', 'update']).isRequired,
};

export default ConnectorForm;
