import { Panel } from 'frontend/components';

interface EmptyStateProps {
  emptyStateText?: string;
}

const EmptyState = ({ emptyStateText = 'Try setting another time period or filter option.' }: EmptyStateProps) => (
  <Panel.EmptyState>
    <strong>No data found</strong>
    {emptyStateText}
  </Panel.EmptyState>
);

export default EmptyState;
