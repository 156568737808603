import { omit } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { EMPTY_IMAGE } from 'frontend/constants';
import { getAccessToken } from 'frontend/state/dux/auth';
import { upload } from 'frontend/utils';

import useToast from './useToast';

export function getUploadPath(type: 'bot' | 'skill' | 'nudge', id: number | string): string {
  switch (type) {
    case 'bot': {
      return `api/v2/bot/${id}/dialogues/upload-image/`;
    }
    case 'nudge': {
      return `api/v2/bot/${id}/nudges/upload-image/`;
    }
    case 'skill': {
      return `api/v2/skill/${id}/dialogues/upload-image/`;
    }
    default: {
      return type satisfies never;
    }
  }
}

type PseudoImage = { file: Blob; tempId: string };
export default function useUploadCarouselImages(
  onSubmit,
  {
    isModDialogue,
    uploadPath,
  }: {
    isModDialogue?: boolean;
    uploadPath: string;
  },
) {
  const dispatch = useDispatch();
  const toast = useToast();

  const uploadCarouselImages = useCallback(
    async (values) => {
      const fieldName = isModDialogue ? 'modImageCarousels' : 'imageCarousels';
      const imageCarousels = values?.[fieldName];
      const images: Partial<PseudoImage>[] = imageCarousels?.flatMap(({ images: imageList }) => imageList);

      if (!images || images.length === 0) return { success: true };

      // @ts-expect-error: FIXME: type dispatch stuff correctly
      const accessToken: string = await dispatch(getAccessToken());

      const uploads = images
        // Images without tempId are already uploaded
        .filter(
          (pseudoImage): pseudoImage is PseudoImage =>
            pseudoImage.file instanceof Blob && typeof pseudoImage.tempId === 'string',
        )
        .map(({ file, tempId }) => upload(uploadPath, accessToken, file, { tempId }));

      try {
        const responses = await Promise.all(uploads);
        const urlMappings = await Promise.all(responses.map((response) => response.json()));

        const copyOfImageCarousels = structuredClone(imageCarousels);
        copyOfImageCarousels.forEach((imageCarousel) => {
          imageCarousel.images.forEach((image, index) => {
            if (image.tempId) {
              let updatedImage = images.find(({ tempId }) => tempId === image.tempId);
              const { url, id } = urlMappings.find(({ tempId }) => tempId === image.tempId);
              updatedImage = omit({ ...EMPTY_IMAGE, ...image, imageUrl: url, id }, ['tempId', 'file']);

              if (updatedImage) {
                imageCarousel.images[index] = updatedImage;
              }
            }
          });
        });

        const updatedValues = { ...values, [fieldName]: copyOfImageCarousels };

        return { success: true, updatedValues };
      } catch (err) {
        console.error(err);
        toast.error('Could not upload images');
        return { success: false };
      }
    },
    [dispatch, isModDialogue, toast, uploadPath],
  );

  const onSubmitWithUpload = useCallback(
    async (values, form) => {
      const { success, updatedValues } = await uploadCarouselImages(values);
      if (success) {
        await onSubmit(updatedValues ?? values, form);
      } else {
        toast.error('Could not upload images');
      }
    },
    [onSubmit, toast, uploadCarouselImages],
  );

  return onSubmitWithUpload;
}
