import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

import { type DateRange, WEEK_STARTS_ON_MONDAY } from 'frontend/utils/date';

const TODAY = new Date();
const YESTERDAY = addDays(TODAY, -1);
const LAST_WEEK = addDays(TODAY, -7);
const LAST_MONTH = addMonths(TODAY, -1);
const LAST_YEAR = addYears(TODAY, -1);

const defineds = {
  startOfWeek: startOfWeek(TODAY, WEEK_STARTS_ON_MONDAY),
  endOfWeek: endOfWeek(TODAY, WEEK_STARTS_ON_MONDAY),
  startOfLastWeek: startOfWeek(LAST_WEEK, WEEK_STARTS_ON_MONDAY),
  endOfLastWeek: endOfWeek(LAST_WEEK, WEEK_STARTS_ON_MONDAY),
  startOfToday: startOfDay(TODAY),
  endOfToday: endOfDay(TODAY),
  startOfYesterday: startOfDay(YESTERDAY),
  endOfYesterday: endOfDay(YESTERDAY),
  startOfMonth: startOfMonth(TODAY),
  endOfMonth: endOfMonth(TODAY),
  startOfLastMonth: startOfMonth(LAST_MONTH),
  endOfLastMonth: endOfMonth(LAST_MONTH),
  startOfYear: startOfYear(TODAY),
  endOfYear: endOfYear(TODAY),
  startOfLastYear: startOfYear(LAST_YEAR),
  endOfLastYear: endOfYear(LAST_YEAR),
};

export function createStaticRanges(staticRanges: { label: string; range: () => DateRange }[]) {
  return staticRanges.map((o) => ({
    ...o,
    isSelected({ startDate, endDate }: DateRange): boolean {
      return isSameDay(startDate, o.range().startDate) && isSameDay(endDate, o.range().endDate);
    },
  }));
}

export function getStaticRanges(
  maxNumberOfDays: number | undefined,
): { label: string; range: () => DateRange; isSelected: (DateRange) => boolean }[] {
  const staticRangeArray = [
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
  ];

  if (!maxNumberOfDays || maxNumberOfDays > 1) {
    staticRangeArray.push({
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    });
  }

  if (!maxNumberOfDays || maxNumberOfDays > 6) {
    staticRangeArray.push({
      label: 'This week',
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    });
    staticRangeArray.push({
      label: 'Last week',
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
    });
  }

  if (!maxNumberOfDays || maxNumberOfDays > 29) {
    staticRangeArray.push({
      label: 'This month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    });
    staticRangeArray.push({
      label: 'Last month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    });
    staticRangeArray.push({
      label: 'Year to date',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfToday,
      }),
    });
  }

  if (!maxNumberOfDays || maxNumberOfDays > 364) {
    staticRangeArray.push({
      label: 'This year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
      }),
    });
    staticRangeArray.push({
      label: 'Last year',
      range: () => ({
        startDate: defineds.startOfLastYear,
        endDate: defineds.endOfLastYear,
      }),
    });
  }

  return createStaticRanges(staticRangeArray);
}
