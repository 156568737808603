import PropTypes from 'prop-types';

import { Modal } from 'frontend/features/Modals';

const NoBackupsInInterval = ({ hide }) => (
  <Modal title="Inbox backup: No chats " hideCancel hide={hide} onOk={hide}>
    <Modal.Content>No chats were found in this time interval and language</Modal.Content>
  </Modal>
);

NoBackupsInInterval.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default NoBackupsInInterval;
