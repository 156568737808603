import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import ButtonField from './ButtonField';
import { BUTTON_TYPES } from '../../constants';

const Email = ({ name, disabled, localErrors, localSubmitFailed, onKeyDown, isNew, validators, buttonType }) => (
  <>
    <Field
      component={ButtonField}
      name={`${name}.label`}
      placeholder="Button text"
      autoFocus={!isNew}
      validate={isNew ? undefined : validators.label}
      readOnly={disabled}
      ariaLabel="Button text"
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      onKeyDown={onKeyDown}
    />
    <Field
      component={ButtonField}
      name={`${name}.value`}
      placeholder="Email"
      validate={isNew ? undefined : validators.value}
      readOnly={disabled}
      ariaLabel="Button value"
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      onKeyDown={onKeyDown}
      buttonType={buttonType}
    />
    <Field
      component={ButtonField}
      name={`${name}.emailDetails.subject`}
      placeholder="Prefilled subject (optional)"
      readOnly={disabled}
      ariaLabel="Input field text"
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      onKeyDown={onKeyDown}
      defaultValue=""
    />
    <Field
      component={ButtonField}
      name={`${name}.emailDetails.body`}
      placeholder="Prefilled email text (optional)"
      readOnly={disabled}
      ariaLabel="Placeholder"
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      multiline
      defaultValue=""
    />
  </>
);

Email.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  localErrors: PropTypes.shape({}).isRequired,
  localSubmitFailed: PropTypes.bool.isRequired,
  onKeyDown: PropTypes.func,
  isNew: PropTypes.bool.isRequired,
  validators: PropTypes.shape({}).isRequired,
  buttonType: PropTypes.oneOf([BUTTON_TYPES.EMAIL]),
};

export default Email;
