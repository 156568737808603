import type { FieldMetaState } from 'react-final-form';

import { Input } from 'frontend/components';

import styles from './ContextRow.scss';

const getContextKey = (value: string | undefined) => value?.split?.('.')[0] || '';
const getContextValue = (value: string | undefined) => value?.split?.('.').slice(1).join('.') || '';

interface Props {
  input: {
    name: string;
    value: string;
    onChange: (value: string) => void;
  };
  meta?: FieldMetaState<string>;
}

export default function ContextRow({ input: { name, onChange, value }, meta }: Props) {
  const contextKey = getContextKey(value);
  const contextValue = getContextValue(value);

  const handleContextKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newContextKey = e.target.value;

    const newContext = newContextKey || contextValue ? `${newContextKey}.${contextValue}` : '';
    onChange(newContext);
  };

  const handleContextValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newContextValue = e.target.value;

    const newContext = contextKey || newContextValue ? `${contextKey}.${newContextValue}` : '';
    onChange(newContext);
  };

  return (
    <>
      <Input
        forceValidate
        meta={meta}
        name={`${name}.key`}
        input={{ onChange: handleContextKeyChange, value: contextKey }}
        className={styles.keyInput}
      />
      <Input
        name={`${name}.value`}
        input={{ onChange: handleContextValueChange, value: contextValue }}
        className={styles.valueInput}
      />
    </>
  );
}
