import { Link } from 'react-router-dom';

import { Search } from 'frontend/assets/icons';
import { Button, Icon, Input, PageContent, Table } from 'frontend/components';
import styles from 'frontend/features/Admin/views/AdminBots/styles.scss';
import { CreateOrganizationButton } from 'frontend/features/Organization/components/OrganizationList/components';

import Filters from './Filters';
import useOrganizationList from './useOrganizationList';

const MembersCell = ({ data: members }) => members?.length || 0;

const SubscriptionCell = ({ rowData: org }) => (
  <>
    {org.isActive &&
      org.subscriptions?.map(({ id, planName }) => (
        <Link className="m-r-sm" to={`/admin/organization/${org.id}`} key={id}>
          {planName}
        </Link>
      ))}
    {!org.isActive && <em className="m-r-sm">No subscription</em>}
  </>
);
const NameCall = ({ rowData: org }) => <Link to={`/organization/${org.id}`}>{org.name}</Link>;

const ActionsCell = ({ rowData: org }) => (
  <Button size="small" to={`/admin/organization/${org.id}`}>
    Edit
  </Button>
);

const columns = [
  { key: 'id', title: 'ID' },
  { key: 'name', component: NameCall },
  { key: 'members', component: MembersCell },
  { key: 'botCount' },
  { key: 'skillCount' },
  { key: 'activeSubscription', title: 'Active subscription', component: SubscriptionCell },
  { key: 'actions', component: ActionsCell },
];
const OrganizationList = () => {
  const { pagination, onResetClick, searchInput, dirty, organizations, loading, filters, setFilters } =
    useOrganizationList();

  return (
    <PageContent>
      <div className={styles.header}>
        <div>
          <Input
            input={searchInput}
            className={styles.search}
            placeholder="Search organizations"
            aria-label="Search organizations"
            adornment={<Icon component={Search} />}
            adornmentPosition="left"
            autoFocus
          />
          {dirty && <Button flat onClick={onResetClick} text="Reset" />}
        </div>
        <CreateOrganizationButton redirectTo="/admin/organization/:orgId" />
      </div>
      <Filters filters={filters} setFilters={setFilters} />
      <Table columns={columns} data={organizations} loading={loading} pagination={pagination} />
    </PageContent>
  );
};

export default OrganizationList;
