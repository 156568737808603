import { Field } from 'react-final-form';

import { Input } from 'frontend/components';
import { chain, max, required } from 'frontend/form/validators';

const SkillFields = () => (
  <>
    <h3 className="m-b-lg">Skill settings</h3>
    <Field
      component={Input}
      name="name"
      className="m-b-md"
      placeholder="Give me a name"
      label="Name"
      inputLimit={50}
      validate={chain([required, max(50)])}
    />
    <Field
      component={Input}
      name="descriptionHeadline"
      className="m-b-md"
      placeholder="Fill me in"
      label="Description headline"
      inputLimit={50}
      validate={max(50)}
    />
  </>
);

export default SkillFields;
