import { gql } from '@apollo/client';

export const GET_USER_PERMISSIONS = gql`
  query GetUserPermissions($id: ID!, $model: String!) {
    user(id: $id) {
      id
      email
      fullName
      typePermissions
      objectPerms(model: $model)
    }
  }
`;
