import { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';

import type { ImageType } from 'frontend/api/generated';
import { Accordion, Button, Input, ToggleSwitch } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { chain, integer, max, positive, url } from 'frontend/form/validators';
import { getId } from 'frontend/utils';

import type { ImageField } from '../Image/Image';
import shared from '../sharedStyles.scss';

const positiveInteger = chain(integer, positive);
const max50 = max(50);
const max300 = max(300);

interface ImageMetaProps {
  images: ImageType[];
  setSelectedImageId(id: string): void;
  imageFields?: ImageField[];
  selectedImageId?: string;
  fieldName: string;
}

const ImageMeta = ({ images, fieldName, setSelectedImageId, selectedImageId, imageFields = [] }: ImageMetaProps) => {
  const { change } = useForm();
  const selectedImageIndex = images.findIndex((image) => getId(image) === selectedImageId);
  const name = `${fieldName}.images[${selectedImageIndex}]`;

  const onDelete = useCallback(() => {
    const updatedImages = images.filter((image) => getId(image) !== selectedImageId);
    change(`${fieldName}.images`, updatedImages);
    setSelectedImageId('');
  }, [change, fieldName, images, selectedImageId, setSelectedImageId]);

  if (selectedImageIndex < 0) return null;

  return (
    <Accordion title="Image description" bodyClassName={shared.accordionBody}>
      {imageFields.includes('title') && (
        <Field
          component={Input}
          label="Title"
          name={`${name}.title`}
          validate={max50}
          placeholder="Title"
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      )}
      <div className="m-t-1" />
      {imageFields.includes('description') && (
        <Field
          component={Input}
          label="Description"
          placeholder="Description"
          name={`${name}.description`}
          validate={max300}
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      )}
      {imageFields.includes('productUrl') && (
        <Field
          component={Input}
          label="Product link"
          name={`${name}.productUrl`}
          placeholder="https://example.com"
          validate={url}
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      )}
      <div className="m-t-1" />
      {imageFields.includes('altText') && (
        <Field
          component={Input}
          label="Alt-text"
          name={`${name}.altText`}
          placeholder="Describe the image (for screen readers)"
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      )}
      <div className="m-t-1" />
      {imageFields.includes('price') && (
        <Field
          component={Input}
          label="Price"
          name={`${name}.price`}
          placeholder="0"
          validate={positiveInteger}
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      )}
      {imageFields.includes('linkText') && (
        <Field
          component={Input}
          label="Link text"
          name={`${name}.linkText`}
          placeholder="Click to view"
          validate={max50}
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      )}
      {imageFields.includes('linkUrl') && (
        <Field
          component={Input}
          label="Link"
          name={`${name}.linkUrl`}
          placeholder="https://example.com"
          validate={url}
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      )}
      <div className="m-t-2" />
      {imageFields.includes('newTab') && <ToggleSwitch name={`${name}.newTab`} status={['Open link in new tab']} />}
      <div className="m-t-2" />
      <Button size="small" color="warning" onClick={onDelete} className="m-b-2" text="Delete image" />
    </Accordion>
  );
};

export default ImageMeta;
