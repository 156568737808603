import cx from 'classnames';

import { Close, Labels } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import LabelsController from './LabelsController';
import styles from './RightSidePanel.scss';

interface Props {
  className?: string;
  heading?: string;
  toggleRightSidePanel: () => void;
}

function RightSidePanel({ heading, toggleRightSidePanel, className }: Props) {
  return (
    <section className={cx(styles.rightSidePanel, className)}>
      <header className={styles.header}>
        <h2 className={styles.heading}>
          <Icon component={Labels} /> {heading}
        </h2>

        <button type="button" className={styles.closeButton} onClick={toggleRightSidePanel}>
          <Icon component={Close} />
        </button>
      </header>

      <div className={styles.content}>
        <LabelsController />
      </div>
    </section>
  );
}

export default RightSidePanel;
