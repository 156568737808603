import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { COMPUTATION_STATES } from '../constants';

const INITIAL_DELAY_DEFAULT_MS = 0;
const POLL_INTERVAL_DEFAULT_MS = 6000;

export default ({
  onFinished,
  pollFunction,
  initialDelay = INITIAL_DELAY_DEFAULT_MS,
  pollInterval = POLL_INTERVAL_DEFAULT_MS,
}) => {
  const [error, setError] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const stopPolling = useCallback(() => {
    setTimeoutId(null);
  }, []);

  const onPollFinished = useCallback(
    (pollingResult) => {
      stopPolling();
      onFinished(pollingResult);
    },
    [onFinished, stopPolling],
  );

  const onPollError = useCallback(
    (errorMessage) => {
      stopPolling();
      setError('Looks like something went wrong..');
      if (errorMessage) console.error(errorMessage);
    },
    [stopPolling],
  );

  // Use setTimeout rather than setInterval to have tighter control
  const poll = useCallback(
    async (...pollingArgs) => {
      try {
        const pollResult = await pollFunction(...pollingArgs);

        switch (get(pollResult, 'status')) {
          case COMPUTATION_STATES.PENDING: {
            const newTimeoutId = setTimeout(() => poll(...pollingArgs), pollInterval);
            setTimeoutId(newTimeoutId);
            break;
          }
          case COMPUTATION_STATES.ERROR:
            onPollError(get(pollResult, 'error'));
            break;
          default:
            onPollFinished(pollResult);
            break;
        }
      } catch (err) {
        onPollError(err);
      }
    },
    [onPollError, onPollFinished, pollFunction, pollInterval],
  );

  const startPolling = useCallback(
    (...pollingArgs) => {
      const newTimeoutId = setTimeout(() => poll(...pollingArgs), initialDelay);

      onFinished(null);
      setError(null);
      setTimeoutId(newTimeoutId);
    },
    [initialDelay, onFinished, poll],
  );

  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  return { error, startPolling, isPolling: Boolean(timeoutId), stopPolling };
};
