import { Fragment, useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';

import { Button } from 'frontend/components';
import { indexList } from 'frontend/features/Build/utils';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import { ArrayFieldsType } from 'frontend/propTypes';
import { getId, hasLanguage } from 'frontend/utils';

import styles from './SlotsTab.scss';
import { useReplySlots } from '../../hooks';
import { AddDialogueSlot } from '../../modals';
import SlotItem from '../SlotItem';

const SlotList = ({ fields }) => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { buildIdObject } = useBotOrSkill();
  const [showAddDialogueSlot] = useModal(AddDialogueSlot);
  const { values } = useFormState();
  const replies = useMemo(() => (values.replies || []).filter(hasLanguage(currentLanguage)), [currentLanguage, values]);
  const replySlots = useReplySlots({ replies });
  const outputSlots = indexList(values.outputSlots || []).filter(({ slot }) => hasLanguage(currentLanguage)(slot));

  const addDialogueSlot = useCallback((dialogueSlot) => fields.pushFixed(fields.name, dialogueSlot), [fields]);
  const removeDialogueSlot = useCallback((index) => fields.removeFixed(fields.name, index), [fields]);

  const clickAddDialogueSlot = useCallback(() => {
    showAddDialogueSlot({ currentLanguage, addDialogueSlot, buildIdObject });
  }, [addDialogueSlot, buildIdObject, currentLanguage, showAddDialogueSlot]);

  return (
    <>
      {outputSlots.map(({ index, slot }, counter) => {
        const replySlotNames = replySlots.map(({ name: slotName }) => slotName);
        const canRemoveSlot = !replySlotNames.includes(slot.name);

        return (
          <Fragment key={`slot-${getId(slot)}`}>
            <SlotItem index={index} counter={counter} remove={removeDialogueSlot} canRemoveSlot={canRemoveSlot} />
            {counter < outputSlots.length - 1 && <hr className={styles.separator} />}
          </Fragment>
        );
      })}
      <br />
      <Button size="small" onClick={clickAddDialogueSlot}>
        Add slot
      </Button>
    </>
  );
};

SlotList.propTypes = {
  fields: ArrayFieldsType.isRequired,
};

export default SlotList;
