import { camelCase, pick } from 'lodash';

import getArrayUpdateV2 from 'frontend/utils/getArrayUpdateV2';

const getFieldName = (firstSegment, secondSegment?) =>
  camelCase(`${firstSegment ?? ''}_${secondSegment ?? ''}_dialogue_rules`);

export default ({
  initialValues,
  values,
  language,
  prefix,
}: {
  initialValues: Record<string, any>;
  values: Record<string, any>;
  language: string;
  prefix?: string;
}) => {
  const initial = initialValues?.[getFieldName(prefix)]?.filter((rule) => rule.languageCode === language) ?? [];
  const current =
    values?.[getFieldName(prefix)]
      ?.filter((rule) => rule.languageCode === language)
      .map((rule) => ({
        ...rule,
        conditions: rule.conditions?.map((condition) =>
          Object.fromEntries(
            // we trim all condition values, especially for context entries
            Object.entries(condition).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value]),
          ),
        ),
      })) ?? [];
  const currentRulesWithOrder = current.map((rule, index) => ({ ...rule, order: index }));

  const { created, updated, deleted } = getArrayUpdateV2(initial, currentRulesWithOrder);
  return {
    [getFieldName('created', prefix)]: created,
    [getFieldName('deleted', prefix)]: deleted.map((o) => pick(o, ['id'])),
    [getFieldName('updated', prefix)]: updated,
  };
};
