import ProductCard, { type Product } from './ProductCard';
import styles from './styles.scss';
import sharedStyles from '../../shared.scss';
import Section from '../Section';

interface Props {
  carouselTitle?: string;
  imageCarousel: Product[];
}
const ProductCarousel = ({ carouselTitle, imageCarousel }: Props) => (
  <Section>
    {!!carouselTitle && <span className={sharedStyles.title}>{carouselTitle}</span>}
    <div className={styles.carouselContainer}>
      <div>
        {imageCarousel.map((product) => (
          <ProductCard key={product.tempId} product={product} />
        ))}
      </div>
    </div>
  </Section>
);

export default ProductCarousel;
