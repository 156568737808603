import cx from 'classnames';
import { times } from 'lodash';

import styles from './styles.scss';
import animationStyles from '../animation.scss';

const ColumnLoader = () => (
  <div className={styles.ghostLines}>
    <svg viewBox="0 0 32 32" width="100%" height="32" preserveAspectRatio="none">
      <defs>
        <mask id="mask1" x="0" y="0" width="100%" height="16">
          <rect x="0" y="0" width="100%" height="16" fill="#FFF" />
          <rect x="0" y="0" width="100%" height="16px" />
        </mask>
      </defs>
      <rect x="0" y="0" width="100%" height="16" mask="url(#mask1)" fill="#FFF" fillOpacity="1" />
    </svg>
  </div>
);

interface RowLoaderProps {
  children?: React.ReactNode;
  isFirst?: boolean;
}

const RowLoader = ({ children, isFirst }: RowLoaderProps) => (
  <div className={styles.ghostContainer} style={{ marginLeft: isFirst ? '24px' : '8px' }}>
    <div className={cx(animationStyles.ghostStripe, styles.ghostStripe)}>{children}</div>
  </div>
);

interface TableProps {
  options: { columns: number; rows?: number };
}

const Table = ({ options }: TableProps) => (
  <>
    {times(options.columns * (options.rows || 4), (key) => (
      <RowLoader key={key} isFirst={key % options.columns === 0}>
        <ColumnLoader />
      </RowLoader>
    ))}
  </>
);

export default Table;
