import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import { Handover, Keyword, Pattern, Placeholder, Scale, SkipBack, TextCapitalize } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './FieldHeader.scss';
import { FieldContext } from '../../context/FieldContext';
import inputTypes from '../../utils/constants';

const getFieldArrayName = (baseFieldName: string, baseFieldIndex) => {
  if (baseFieldName?.endsWith(`[${baseFieldIndex}]`)) {
    const index = baseFieldName.lastIndexOf(`[${baseFieldIndex}]`);
    const newBaseFieldName = baseFieldName.slice(0, index);
    return newBaseFieldName;
  }

  return baseFieldName;
};

const FieldHeader = () => {
  const { activeFieldInputs, setActiveFieldInputs, baseFieldName, baseFieldIndex, field } = useContext(FieldContext);
  const { change } = useForm();

  const { fields } = useFieldArray(getFieldArrayName(baseFieldName, baseFieldIndex));

  const { update } = fields;

  const toggleActiveFieldInputs = (name: string) => {
    setActiveFieldInputs((currentActiveFieldInputs) => ({
      ...currentActiveFieldInputs,
      id: `temporary-uuid-${randomUUID()}`,
      [name]: !currentActiveFieldInputs[name],
    }));
  };

  const onValidatorCreate = useCallback(
    (...args) => {
      const argsCopy = [...args];
      const newValidators = {
        ...field,
        validators: [
          ...(field?.validators || []),
          ...argsCopy.map((arg) => ({
            [arg[0]]: arg[1],
            id: `temporary-uuid-${randomUUID()}`,
            texts: {
              id: `temporary-uuid-${randomUUID()}`,
              text: '',
            },
          })),
        ],
      };

      update(baseFieldIndex, newValidators);
    },
    [baseFieldIndex, update, field],
  );

  return (
    <div className={styles.fieldActionsWrapper}>
      {![inputTypes.RANGE, inputTypes.CHECKBOX, inputTypes.RADIO].includes(field.inputType) &&
        !activeFieldInputs.placeholderText && (
          <Button
            icon={Placeholder}
            text="Placeholder"
            color="white"
            onClick={() => toggleActiveFieldInputs('placeholderText')}
          />
        )}
      {!activeFieldInputs.helpText && (
        <Button icon={Handover} text="Help text" color="white" onClick={() => toggleActiveFieldInputs('helpText')} />
      )}
      {![inputTypes.NUMBER, inputTypes.RANGE, inputTypes.SELECT, inputTypes.CHECKBOX, inputTypes.RADIO].includes(
        field.inputType,
      ) &&
        !activeFieldInputs.maxLength && (
          <Button
            icon={TextCapitalize}
            text="Character limit"
            color="white"
            onClick={() => {
              toggleActiveFieldInputs('maxLength');
              onValidatorCreate(['maxLength', 30]);
            }}
          />
        )}
      {!activeFieldInputs.slug && (
        <Button
          icon={Keyword}
          text="Context key"
          color="white"
          onClick={() => {
            toggleActiveFieldInputs('slug');
          }}
        />
      )}
      {[inputTypes.NUMBER, inputTypes.RANGE].includes(field.inputType) && (
        <>
          {!activeFieldInputs.minimum && field.inputType === inputTypes.NUMBER && (
            <Button
              icon={Scale}
              text="Range"
              color="white"
              className={styles.rangeButton}
              onClick={() => {
                toggleActiveFieldInputs('minimum');
                onValidatorCreate(['minimum', 0], ['maximum', 1]);
              }}
            />
          )}
          {!activeFieldInputs.affix && (
            <Button
              icon={SkipBack}
              text="Affix"
              color="white"
              onClick={() => {
                change(`${baseFieldName}.affix`, field?.affix || 'PREFIX');
                toggleActiveFieldInputs('affix');
              }}
            />
          )}
        </>
      )}
      {[inputTypes.TEXT, inputTypes.EMAIL].includes(field.inputType) && !activeFieldInputs.pattern && (
        <Button
          icon={Pattern}
          text="Pattern"
          color="white"
          onClick={() => {
            toggleActiveFieldInputs('pattern');
            onValidatorCreate(['pattern', '']);
          }}
        />
      )}
    </div>
  );
};

export default FieldHeader;
