import { inRange, max } from 'lodash';
import debounce from 'lodash/debounce'; // needs explicit import of lodash/debounce for mocking in testing
import { defineAction } from 'redux-define';

import { getBotJWToken, getOrgJWToken, getPlatformJWToken } from 'frontend/state/dux/analytics/auth';
import { SAGE_RESOURCE } from 'frontend/state/dux/analytics/sageScope';

// Data queue behavior settings
export const MAX_CONCURRENT_REQUESTS = 2;
export const MAX_FAILURE_COUNT = 3;
export const ABORT_UNSUBSCRIBED_REQUESTS = true;
export const POP_QUEUE_DEBOUNCE_TIME = 200;

export const PRIORITY = Object.freeze({ HIGH: 100, MEDIUM: 10, LOW: 1, LOWEST: 0 });
export const PRIORITY_DEFAULT = PRIORITY.MEDIUM;
const GENERIC_ERROR = 'Could not fetch analytics data';

const DATA_QUEUE = defineAction(
  'dataQueue',
  ['SUBSCRIBE', 'UNSUBSCRIBE', 'REQUEUE', 'REQUEST', 'SUCCESS', 'FAILURE', 'ABORT'],
  'analytics',
);

const initialState = {}; // url -> urlState
const initialUrlState = {
  subscribers: 0,
  scope: null,
  priority: PRIORITY.LOW,
  pending: false,
  data: null,
  error: '',
  failureCount: 0,
  statusCode: 0,
};
const retryRequests = {};

const getUrlState = (state, url) => state[url] || initialUrlState;
const mergeUrlState = (state, url, urlUpdate) => ({
  ...state,
  [url]: { ...getUrlState(state, url), ...urlUpdate },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case DATA_QUEUE.SUBSCRIBE: {
      const old = getUrlState(state, action.url);
      const newPriority = action.priority ?? PRIORITY_DEFAULT;
      return mergeUrlState(state, action.url, {
        subscribers: old.subscribers + 1,
        scope: action.scope || null,
        priority: max([old.priority, newPriority]),
      });
    }
    case DATA_QUEUE.UNSUBSCRIBE: {
      const old = getUrlState(state, action.url);
      return mergeUrlState(state, action.url, {
        subscribers: old.subscribers - 1,
      });
    }
    case DATA_QUEUE.REQUEUE: {
      return mergeUrlState(state, action.url, {
        data: null,
        error: '',
        statusCode: 0,
      });
    }

    case DATA_QUEUE.REQUEST: {
      return mergeUrlState(state, action.url, {
        pending: true,
        data: null,
        error: '',
      });
    }
    case DATA_QUEUE.SUCCESS: {
      return mergeUrlState(state, action.url, {
        pending: false,
        data: action.data,
        failureCount: 0,
      });
    }
    case DATA_QUEUE.FAILURE: {
      const old = getUrlState(state, action.url);
      return mergeUrlState(state, action.url, {
        pending: false,
        error: action.error || GENERIC_ERROR,
        statusCode: action.statusCode,
        failureCount: old.failureCount + 1,
      });
    }
    case DATA_QUEUE.ABORT: {
      const old = getUrlState(state, action.url);
      return mergeUrlState(state, action.url, {
        pending: false,
        data: null,
        error: '',
        failureCount: old.failureCount - 1, // cancel out the failure action's increment of failureCount
      });
    }

    default:
      return state;
  }
};

const selectState = (state) => state.analytics.dataQueue;

// internal actions, queue:
export const subscribe = (url, scope, { priority } = {}) => ({ type: DATA_QUEUE.SUBSCRIBE, url, scope, priority });
export const unsubscribe = (url) => ({ type: DATA_QUEUE.UNSUBSCRIBE, url });
export const requeue = (url) => ({ type: DATA_QUEUE.REQUEUE, url });
// internal actions, request:
export const request = (url) => ({ type: DATA_QUEUE.REQUEST, url });
export const success = (url, data) => ({ type: DATA_QUEUE.SUCCESS, url, data });
export const failure = (url, error, statusCode) => ({ type: DATA_QUEUE.FAILURE, url, error, statusCode });
export const abort = (url) => ({ type: DATA_QUEUE.ABORT, url });

/** internal logic, exported for testing only */
export const canStartRequest = ({ subscribers, pending, data, error }) => {
  if (subscribers === 0) return false;
  if (pending) return false;
  if (data) return false;
  if (error) return false;
  return true;
};

/** internal logic, exported for testing only */
export const canAbort = ({ subscribers, pending }) => {
  if (subscribers > 0) return false;
  if (!pending) return false;
  return true;
};

export const canRequeueRequest = ({ subscribers, pending, data, failureCount, error }) => {
  if (subscribers === 0) return false;
  if (pending) return false;
  if (failureCount >= MAX_FAILURE_COUNT) return false;
  if (data === null && error === '') return false;
  return true;
};

const abortControllers = {};

/** internal logic, exported for testing only */
export function popQueueImplementation(dispatch, getState) {
  const entries = Object.entries(selectState(getState())); // [[url, urlState]]

  // abort requests
  if (ABORT_UNSUBSCRIBED_REQUESTS) {
    const abortable = entries.filter(([url, urlState]) => canAbort(urlState) && abortControllers[url]);
    if (abortable.length) {
      abortable.forEach(([url]) => {
        abortControllers[url].abort();
        dispatch(abort(url));
      });
    }
  }

  // start requests
  const pendingCount = entries.filter(([, urlState]) => urlState.pending).length;
  const maxNewRequests = MAX_CONCURRENT_REQUESTS - pendingCount;
  if (maxNewRequests > 0) {
    entries
      .filter(([, urlState]) => canStartRequest(urlState))
      .sort(([, a], [, b]) => b.priority - a.priority)
      .slice(0, maxNewRequests)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .forEach(([url, urlState]) => dispatch(requestSageData(url, urlState.scope)));
  }
}

/** internal logic, exported for testing only */
export const popQueue = debounce(popQueueImplementation, POP_QUEUE_DEBOUNCE_TIME, undefined);

/** internal logic, exported for testing only */
export const requestSageData = (url, scope) => async (dispatch, getState) => {
  if (retryRequests[url]) {
    clearTimeout(retryRequests[url]);
    delete retryRequests[url];
  }
  try {
    dispatch(request(url));
    const abortController = new AbortController();
    abortControllers[url] = abortController;
    const signal = abortController.signal;

    const [resource, resourceId] = scope.split('-');
    const getTokenByResource = {
      [SAGE_RESOURCE.PLATFORM]: getPlatformJWToken,
      [SAGE_RESOURCE.BOT]: getBotJWToken,
      [SAGE_RESOURCE.ORG]: getOrgJWToken,
    };
    const dispatchFunc = getTokenByResource[resource.toUpperCase()];
    if (!dispatchFunc) {
      throw new Error(`Invalid scope '${scope}', could not dispatch.`);
    }

    const token = await dispatch(dispatchFunc(resourceId));
    const response = await fetch(url, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      signal,
    });
    if (inRange(response.status, 200, 300)) {
      const { data } = await response.json();
      if (data) {
        dispatch(success(url, data));
        return;
      }
    }
    const error = new Error('should not reach');
    error.response = response;
    throw error;
  } catch (err) {
    const statusCode = err.response?.status;
    const retryAfter = parseInt(err.response?.headers?.get('Retry-After'), 10) || 30;

    if (statusCode === 429) {
      retryRequests[url] = setTimeout(() => {
        dispatch(requestSageData(url, scope));
      }, retryAfter * 1000);
      return;
    }
    dispatch(failure(url, GENERIC_ERROR, statusCode));
  } finally {
    delete abortControllers[url];
    popQueue(dispatch, getState);
  }
};

export const createSelectSageDataState = (url) => (state) => {
  if (!url) {
    return null;
  }
  const urlState = getUrlState(selectState(state), url);
  const waiting = !urlState.pending && canStartRequest(urlState);
  return {
    ...urlState,
    waiting,
    loading: waiting || urlState.pending,
  };
};

export function subscribeSageData(url, scope, { priority } = {}) {
  return async (dispatch, getState) => {
    dispatch(subscribe(url, scope, { priority }));
    const urlState = createSelectSageDataState(url)(getState());
    if (urlState.error && canRequeueRequest(urlState)) {
      dispatch(requeue(url));
    }
    popQueue(dispatch, getState);
  };
}

export function unsubscribeSageData(url) {
  return async (dispatch, getState) => {
    dispatch(unsubscribe(url));
    popQueue(dispatch, getState);
  };
}

export function requeueSageData(url) {
  return (dispatch, getState) => {
    const urlState = createSelectSageDataState(url)(getState());
    if (canRequeueRequest(urlState)) {
      dispatch(requeue(url));
      popQueue(dispatch, getState);
    } else {
      console.warn('requeue not allowed, perhaps guard with canRequeueRequest()');
    }
  };
}
