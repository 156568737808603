import { isEqual, orderBy } from 'lodash';
import { useMemo } from 'react';
import { Form } from 'react-final-form';

import type { OrganizationRoleType } from 'frontend/api/generated';
import { FormErrors, GuardUnsaved, LoaderSwitch, PageBar, Panel } from 'frontend/components';

import CustomRoleSettings from './CustomRoleSettings';

const subscribe = {};

const getAllPairs = (array) => array.map((item1, idx) => array.slice(idx + 1).map((item2) => [item1, item2])).flat();

const permissionsEqual = ([{ permissions: permissions1 }, { permissions: permissions2 }]) =>
  isEqual(orderBy(permissions1), orderBy(permissions2));

const getValidator = (extraValidation: CallableFunction) => (values: { customRoles: string[] }) => {
  const allPairs = getAllPairs(values.customRoles);
  if (allPairs.some(permissionsEqual)) {
    return { CustomRoles: 'Two custom roles cannot have identical permissions' };
  }

  return extraValidation(values);
};

const RoleSettings = ({
  loading,
  onSubmit,
  initialValues,
  allPermissions,
  emptyStateText,
  wrapperClassName,
  children,
  extraValidation = () => undefined,
}: {
  loading: boolean;
  onSubmit: any;
  initialValues: { customRoles: Partial<OrganizationRoleType>[] };
  allPermissions: string[];
  emptyStateText: string;
  wrapperClassName?: string;
  children?: unknown;
  extraValidation?: any;
}) => {
  const validate = useMemo(() => getValidator(extraValidation), [extraValidation]);

  return (
    <LoaderSwitch loading={loading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscribe={subscribe}
        validate={validate}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors />
              <div className={wrapperClassName}>
                <Panel>
                  <CustomRoleSettings allPermissions={allPermissions} emptyStateText={emptyStateText}>
                    {children}
                  </CustomRoleSettings>
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
};

export default RoleSettings;
