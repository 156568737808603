import { defineAction } from 'redux-define';

import { MESSAGE_TYPES } from 'frontend/features/Inbox/views/MessageClustering/constants';

const MESSAGE_CLUSTERING = defineAction('messageClustering');
const SET_CURRENT_CLUSTER_INDEX = MESSAGE_CLUSTERING.defineAction('SET_CURRENT_CLUSTER_INDEX');
const SET_MESSAGE_TYPE = MESSAGE_CLUSTERING.defineAction('SET_MESSAGE_TYPE');

export const setCurrentClusterIndex = (currentClusterIndex) => ({
  type: SET_CURRENT_CLUSTER_INDEX.ACTION,
  currentClusterIndex,
});

export const setMessageType = (messageType) => ({
  type: SET_MESSAGE_TYPE.ACTION,
  messageType,
});

const initialState = {
  currentClusterIndex: 0,
  messageType: MESSAGE_TYPES.FALLBACKS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CLUSTER_INDEX.ACTION:
      return { ...state, currentClusterIndex: action.currentClusterIndex };
    case SET_MESSAGE_TYPE.ACTION:
      return { ...state, messageType: action.messageType };
    default:
      return state;
  }
};
