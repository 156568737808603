import { gql } from '@apollo/client';

export default gql`
  mutation DuplicateButton($botId: ID, $skillId: ID, $targetDialogueId: UUID!, $sourceButtonId: UUID!) {
    duplicateButton(
      botId: $botId
      skillId: $skillId
      targetDialogueId: $targetDialogueId
      sourceButtonId: $sourceButtonId
    ) {
      id
      dialogue {
        id
        dialogueType
      }
    }
  }
`;
