import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Loader, PageContent, Table } from 'frontend/components';
import { BackButton } from 'frontend/features/Admin/components';
import { ADMIN_GROUPS } from 'frontend/features/Admin/constants';
import { useTabTitle } from 'frontend/hooks';

const GET_GROUP = gql`
  query GetGroup($groupId: ID) {
    group(groupId: $groupId) {
      id
      name
      permissions {
        id
        codename
        name
        appLabel
      }
    }
  }
`;

const columns = [{ key: 'appLabel' }, { key: 'codename' }, { key: 'name', title: 'Description' }];

const GroupDetail = () => {
  useTabTitle('Groups');

  const { groupId } = useParams();

  const { data, loading } = useQuery(GET_GROUP, { variables: { groupId } });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <BackButton />
      <PageContent className="p-t-0">
        <h1>Group: {ADMIN_GROUPS?.[data.group.name] ?? data.group.name}</h1>
        <h2>Global permissions</h2>
        <Table data={data.group.permissions} columns={columns} />
      </PageContent>
    </>
  );
};

export default GroupDetail;
