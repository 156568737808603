import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { SkillDocument } from 'frontend/api/generated';

import Menu from './Menu';

const SkillMenu = () => {
  const { skillId } = useParams();

  const { data, loading } = useQuery(SkillDocument, { variables: { skillId: skillId! }, skip: !skillId });
  const skill = data?.skill;

  const noData = loading || !skill?.id;

  const menuItems = useMemo(() => {
    const hideBuild = !skill?.permissions?.includes('read_skill_content');
    const hideManageView = !skill?.permissions?.includes('manage_skill');

    return noData
      ? []
      : [
          {
            name: 'Build',
            hide: hideBuild,
            slug: 'build',
            path: '/build/*',
          },
          { name: 'Publish', hide: hideManageView, path: '/publish', slug: 'publish' },
          { name: 'Subscribers', path: '/subscribers', slug: 'subscribers' },
          {
            name: 'Settings',
            hide: hideManageView,
            path: '/settings/*',
            slug: 'settings',
          },
        ];
  }, [skill, noData]);

  if (noData) {
    return null;
  }

  return <Menu menuItems={menuItems} />;
};

export default SkillMenu;
