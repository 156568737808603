import { gql } from '@apollo/client';

import { skillSettingsFragment } from 'frontend/api/fragments';

const UPDATE_SKILL_SETTINGS = gql`
  mutation UpdateSkillSettings($skillId: ID!, $skillInput: SkillInputType!) {
    updateSkillSettings(skillId: $skillId, skillInput: $skillInput) {
      ...SkillSettings
    }
  }
  ${skillSettingsFragment}
`;
export default UPDATE_SKILL_SETTINGS;
