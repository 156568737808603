import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentLanguage } from 'frontend/state/dux/platform';

/** Custom Hook to r/w in Redux `selectedLanguage`, `currentLanguage` and `currentVariant`. */
export default function useCurrentLanguage() {
  const dispatch = useDispatch();

  const { currentLanguage, currentVariant }: { currentLanguage: string; currentVariant?: string | null } = useSelector(
    // @ts-expect-error Type the DefaultRootState
    ({ platform }) => platform,
  );
  const setSelectedLanguage = useCallback(
    (baseLanguageCode: string, variantCode?: string) => dispatch(setCurrentLanguage(baseLanguageCode, variantCode)),
    [dispatch],
  );

  const selectedLanguage = currentVariant || currentLanguage;

  return [
    {
      /** The current base language code chosen.
       * @example 'en'
       */
      currentLanguage,
      /** The current variant code chosen.
       * @example 'en-gb'
       */
      currentVariant,
      /** The selected variant or language code, in this logical order.
       * @example 'en-gb' or 'en'
       */
      selectedLanguage,
    },
    /** Function to change `currentLanguage` and `currentVariant`.
     * @example setSelectedLanguage('en', 'en-gb')
     */
    setSelectedLanguage,
  ] as const;
}
