import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { ModalForm } from 'frontend/features/Modals';

import { SubscriptionSelect } from '../../components';
import { CREATE_ORGANIZATION_SUBSCRIPTION } from '../../mutations';

const AddSubscription = ({ hide, args }) => {
  const { organization } = args;
  const [create] = useMutation(CREATE_ORGANIZATION_SUBSCRIPTION);

  const onSubmit = useCallback(
    async ({ name, planName, expirationDate }) => {
      await create({ variables: { organizationId: organization.id, name, planName, expirationDate } });
      hide();
    },
    [create, hide, organization.id],
  );

  const initialValues = {
    noExpiration: true,
  };

  return (
    <ModalForm
      onSubmit={onSubmit}
      title={`Add subscription to ${organization.name}`}
      hide={hide}
      onOkText="Create"
      initialValues={initialValues}
    >
      <SubscriptionSelect />
    </ModalForm>
  );
};

AddSubscription.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ organization: PropTypes.shape({}).isRequired }),
};

export default AddSubscription;
