import defaultLogo from 'frontend/assets/images/organization_avatar.svg?url';
import type { Row } from 'frontend/components/Table/Table';

import styles from '../styles.scss';

export const WorkspaceCell = ({ rowData: workspace }: { rowData?: Row }) => {
  if (!workspace) return null;
  const { avatarUrl, name, projectName } = workspace;
  return (
    <div className={styles.workspaceCell}>
      <img
        alt={`${name} logo`}
        className={styles.image}
        src={avatarUrl || defaultLogo}
        onError={(e) => {
          const imgElement = e.target as HTMLImageElement;
          imgElement.src = defaultLogo;
        }}
      />
      <div className={styles.title}>
        <span data-cy="workspace-title">{name}</span>
        <div className={styles.projectName}>{projectName}</div>
      </div>
    </div>
  );
};
