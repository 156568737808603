import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { get, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { cacheSkillSubscription } from 'frontend/api/cacheHelpers';
import { getPrivacySettings } from 'frontend/api/queries';
import { operations } from 'frontend/constants';

import { getAllAnonymizations, updatePrivacySettings } from '../Privacy/graphql';

export default () => {
  const { botId } = useParams();
  const client = useApolloClient();

  const { data: anonymizationsData, loading: anonymizationLoading } = useQuery(getAllAnonymizations);
  const { data: privacyData, loading, refetch } = useQuery(getPrivacySettings, { variables: { botId } });

  const initialValues = useMemo(() => {
    const privacySettings = privacyData?.bot?.privacySettings;
    const anonymizations = get(anonymizationsData, 'anonymizations', []);
    const privacyAnonymizations = get(privacySettings, 'anonymizations', []);

    return {
      ...privacySettings,
      anonymizations: anonymizations.map((anonymization) => ({
        ...anonymization,
        isActive: privacyAnonymizations.some(({ id }) => id === anonymization.id),
      })),
    };
  }, [anonymizationsData, privacyData]);

  const update = useCallback(
    (cache, { data }) => {
      const { useGdprSkill, gdprSkillSubscription } = data.updatePrivacySettings;
      let operation = false;
      if (initialValues.useGdprSkill && !useGdprSkill) operation = operations.REMOVE;
      else if (!initialValues.useGdprSkill && useGdprSkill) operation = operations.ADD;

      if (operation) {
        cacheSkillSubscription(operation, gdprSkillSubscription.subscriptionId, {
          client,
          botId,
          skillId: gdprSkillSubscription.skillId,
        });
      }
    },
    [botId, client, initialValues.useGdprSkill],
  );

  const [updateSettings] = useMutation(updatePrivacySettings, { update });

  const onSubmit = useCallback(
    async (values) => {
      const variables = {
        botId,
        privacySettingsInput: {
          ...pick(values, [
            'deletePersonalData',
            'deletePersonalDataDays',
            'useGdprSkill',
            'messageOnDeleteChat',
            'anonymizationIsActive',
            'anonymizationDuringHandover',
          ]),
          anonymizations: values.anonymizations
            .filter(({ isActive }) => isActive)
            .map((item) => pick(item, ['id', 'label', 'value'])),
        },
      };
      await updateSettings({ variables });
    },
    [botId, updateSettings],
  );

  return {
    initialValues,
    onSubmit,
    botId,
    privacyData,
    privacyLoading: loading,
    anonymizationLoading,
    refetch,
  };
};
