import PropTypes from 'prop-types';

import styles from './VideoEmbedPreview.scss';

const setCurrentTime = (source) => {
  const url = source.replace('?t=', '?start=');
  return url.replace('&t=', '&start=');
};

const constructParamaters = (source) => {
  const sourceLowercase = source.toLowerCase();
  let url = source;

  if (sourceLowercase.includes('youtube.com')) {
    url = source.replace('&', '?');
    url = setCurrentTime(url);
    return url.split('v=')[1];
  }
  if (sourceLowercase.includes('youtu.be')) {
    url = setCurrentTime(source);
    return url.split('youtu.be/')[1];
  }

  return url;
};

const YoutubeEmbed = ({ source }) => {
  const params = constructParamaters(source);

  return (
    <iframe
      width="240"
      height="auto"
      src={`https://www.youtube.com/embed/${params}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Video preview from Youtube"
      className={styles.iframe}
    />
  );
};

YoutubeEmbed.propTypes = {
  source: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
