import ReactJson from 'react-json-view';

import type { ChatType } from 'frontend/api/generated';

import styles from './Context.scss';

/** Wrap react-json-view with defaults */
const JSONView = ({ data }: { data: ChatType['context'] }) => (
  <ReactJson
    src={data}
    collapsed={2}
    style={{ fontSize: 12 }}
    sortKeys
    name={null}
    displayDataTypes={false}
    indentWidth={2}
    displayObjectSize={false}
    theme={{
      base00: 'white',
      base01: '#ddd',
      base02: '#ddd',
      base03: '#444',
      base04: 'purple',
      base05: '#444',
      base06: '#444',
      base07: '#444',
      base08: '#444',
      base09: 'rgba(208, 53, 43, 1)',
      base0A: 'rgba(53, 112, 41, 1)',
      base0B: 'rgba(53, 112, 41, 1)',
      base0C: 'rgba(53, 112, 41, 1)',
      base0D: 'rgba(53, 112, 41, 1)',
      base0E: 'rgba(53, 112, 41, 1)',
      base0F: 'rgba(53, 112, 41, 1)',
    }}
  />
);

const mapContextKeys = (context) =>
  Object.keys(context).reduce((obj, key) => {
    obj[key] = context[key];
    return obj;
  }, {});

const Context = ({ context }: { context: ChatType['context'] }) => (
  <div className={styles.chatDetailsContextWrapper}>
    <JSONView data={mapContextKeys(context)} />
  </div>
);

export default Context;
