import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { LoaderSwitch } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import { useCurrentLanguage, useToast } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';
import { getArray, pluralize } from 'frontend/utils';

import styles from './RemoveKindlyEntity.scss';
import { REMOVE_KINDLY_ENTITY } from '../../mutations';
import { KindlyEntityType } from '../../propTypes';
import { KINDLY_ENTITY_DIALOGUE_INFO } from '../../queries';

const RemoveKindlyEntity = ({ hide, args }) => {
  const {
    entity: { name, id },
    botId,
  } = args;

  const [{ currentLanguage }] = useCurrentLanguage();
  const toast = useToast();
  const client = useApolloClient();
  const { data, loading } = useQuery(KINDLY_ENTITY_DIALOGUE_INFO, {
    variables: { botId, id },
    fetchPolicy: 'network-only',
  });
  const [removeKindlyEntity] = useMutation(REMOVE_KINDLY_ENTITY);

  const dialogues = getArray('kindlyEntity.dialogues', data);
  const dialogueMods = getArray('kindlyEntity.dialogueMods', data);
  const skillSubscriptions = getArray('kindlyEntity.subscriptions', data);
  const nDialogues = dialogues.length + dialogueMods.length;
  const nSubscriptions = skillSubscriptions.length;

  const onSubmit = useCallback(async () => {
    const response = await removeKindlyEntity({ variables: { botId, id } });

    toast.success(`${response.data.removeKindlyEntity.kindlyEntity.name} was removed!`);
    // Clear the entire cache, since fetching the relevant dialogues is complicated and this should happen rarely
    client.resetStore();
  }, [botId, client, id, removeKindlyEntity, toast]);

  return (
    <Modal
      title={`Remove '${name}'`}
      hide={hide}
      color="warning"
      onOk={onSubmit}
      onOkText="Yes, remove the kindly entity"
    >
      <Modal.Content>
        <LoaderSwitch loading={loading} size="medium">
          Are you sure you want to remove {`'${name}'`} from the bot?
          {nDialogues > 0 && (
            <>
              <br />
              <br />
              <span>It will be removed from the following {pluralize('dialogue', nDialogues)}:</span>
              <br />
              <br />
              <div className={styles.dialogueList}>
                {dialogues.map(({ id: dialogueId, title, url }) => (
                  <Link key={`kindly-entity-dialogue-${dialogueId}`} className="link" to={url} onClick={hide}>
                    {title[currentLanguage] || title.default}
                  </Link>
                ))}
                {dialogueMods.map(({ id: dialogueModId, url, modDialogue: { title } }) => (
                  <Link key={`kindly-entity-dialogue-${dialogueModId}`} className="link" to={url} onClick={hide}>
                    {title[currentLanguage] || title.default}
                  </Link>
                ))}
              </div>
            </>
          )}
          {nSubscriptions > 0 && (
            <>
              <br />
              <br />
              <span>
                It&apos;s {nDialogues > 0 ? 'also' : ''} in use in the following subscribed{' '}
                {pluralize('skill', nSubscriptions)}, whose dialogues will no longer function as intended:
              </span>
              <br />
              <br />
              <ul>
                {skillSubscriptions.map(({ skill: { id: subscriptionId, name: skillName } }) => (
                  <li key={`skill-with-kindly-entity-${subscriptionId}`}>{skillName}</li>
                ))}
              </ul>
            </>
          )}
        </LoaderSwitch>
      </Modal.Content>
    </Modal>
  );
};

RemoveKindlyEntity.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ entity: KindlyEntityType.isRequired, botId: IDType.isRequired }),
};

export default RemoveKindlyEntity;
