import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useCurrentLanguage from './useCurrentLanguage';
import useLanguages from './useLanguages';

/** Custom hook to set an initial language in Redux when missing. */
function useInitializeLanguage() {
  const [{ selectedLanguage }, setCurrentLanguage] = useCurrentLanguage();
  const { botId, skillId } = useParams<{ botId?: string; skillId?: string }>();
  const { languagesAndVariants, loading } = useLanguages(
    botId
      ? {
          isBot: true,
          isSkill: false,
          botId,
          buildIdObject: { botId },
          buildType: 'bot',
          buildId: botId,
        }
      : {
          isBot: false,
          isSkill: true,
          skillId: skillId!,
          buildIdObject: { skillId: skillId! },
          buildType: 'skill',
          buildId: skillId!,
        },
  );

  useEffect(() => {
    const languageCodes = languagesAndVariants.map(({ code }) => code);
    const firstLanguage = languagesAndVariants[0];
    if (languageCodes.includes(selectedLanguage) || loading || !firstLanguage) return;

    if ('baseLanguageCode' in firstLanguage) {
      setCurrentLanguage(firstLanguage.baseLanguageCode, firstLanguage.code); // if first language is a variant
    } else setCurrentLanguage(firstLanguage.code); // if first language is a base language
  }, [languagesAndVariants, loading, selectedLanguage, setCurrentLanguage]);
}

export default useInitializeLanguage;
