import { Loader, LoaderSwitch, Panel } from 'frontend/components';
import { SlackAuthorization, SlackConnectionStatus } from 'frontend/features/Profile/components';

import useConnectedAccounts from './useConnectedAccounts';

// https://api.slack.com/scopes
const SLACK_BASE_PROFILE_SCOPES = ['commands', 'im:write', 'im:history', 'chat:write', 'chat:write.customize'];

const ConnectedAccounts = () => {
  const { loading, isSubmitting, slackWorkspaceProfile, handleSlackDisconnect } = useConnectedAccounts();

  if (loading) {
    return <Loader size="large" />;
  }

  const isConnected = Boolean(slackWorkspaceProfile);

  return (
    <Panel>
      <h2>Connected accounts</h2>
      <p>Here you can manage your connected accounts.</p>
      <h3>Slack</h3>
      <LoaderSwitch loading={isSubmitting} size="medium">
        {!isConnected && (
          <div>
            <p>You can recieve private notifications from Kindly in Slack.</p>
            <SlackAuthorization scopes={SLACK_BASE_PROFILE_SCOPES} />
          </div>
        )}
        {isConnected && <SlackConnectionStatus profile={slackWorkspaceProfile} disconnect={handleSlackDisconnect} />}
      </LoaderSwitch>
    </Panel>
  );
};
export default ConnectedAccounts;
