export const ALERTERS = Object.freeze({
  KINDLY_ARCHIVER: 'KINDLY_ARCHIVER',
  SEASON_CHECK: 'SEASON_CHECK',
});

export const ALERT_LEVELS = Object.freeze({
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
});
