import cx from 'classnames';
import PropTypes from 'prop-types';

import { Panel } from 'frontend/components';
import { ChildrenType } from 'frontend/propTypes';

import ActivityIcon from './ActivityIcon';
import ButtonsIcon from './ButtonsIcon';
import styles from './EmptyState.scss';
import WebhooksIcon from './WebhooksIcon';
import { DASHBOARD_VIEW } from '../../constants';

const { ACTIVITY, BUTTONS, WEBHOOKS } = DASHBOARD_VIEW;

const getIcon = (dashboardView) => {
  switch (dashboardView) {
    case ACTIVITY:
      return ActivityIcon;
    case BUTTONS:
      return ButtonsIcon;
    case WEBHOOKS:
      return WebhooksIcon;
    default:
      console.error('Invalid build dashboard icon type');
      return ActivityIcon;
  }
};

const EmptyState = ({ children, headLine, text, dashboardView, className }) => {
  const Icon = getIcon(dashboardView);

  return (
    <Panel className={cx(styles.container, className)}>
      <div className={cx(styles.icon, { [styles.iconButtons]: dashboardView === BUTTONS })}>
        <Icon />
      </div>
      <div className={styles.content}>
        <h2>{headLine}</h2>
        {children || text}
      </div>
    </Panel>
  );
};

EmptyState.propTypes = {
  headLine: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: ChildrenType,
  dashboardView: PropTypes.oneOf(Object.values(DASHBOARD_VIEW)).isRequired,
  className: PropTypes.string,
};

export default EmptyState;
