import { gql } from '@apollo/client';

export default gql`
  fragment DialogueModWithEntities on DialogueModType {
    id
    url
    modSamples {
      id
      instances {
        id
        entity {
          id
        }
        kindlyEntity {
          id
        }
      }
    }
    modDialogue {
      id
      title
    }
  }
`;
