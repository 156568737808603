import { createLogger } from 'redux-logger';

export default createLogger({
  collapsed: true,
  predicate: () => window?.env?.NODE_ENV === 'development',
  colors: {
    title: () => '#FFA500',
    prevState: () => '#9E9E9E',
    action: () => '#03A9F4',
    nextState: () => '#4CAF50',
    error: () => '#F20404',
  },
});
