import { useBotLabels } from 'frontend/hooks';
import type { BotLabel } from 'frontend/hooks/useBotLabels';
import useOrgLabels, { type OrgLabel } from 'frontend/hooks/useOrgLabels';

export default function useLabels({ hideBotName }: { hideBotName?: boolean } = {}): BotLabel[] | OrgLabel[] {
  // This is an either-or situation, only one query is going to get the ID it needs and return data
  const botLabels = useBotLabels();
  const orgLabels = useOrgLabels({ prefixBotName: !hideBotName });

  return [...botLabels, ...orgLabels];
}
