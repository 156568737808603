import { useMemo } from 'react';

import { getArray, hasLanguage } from 'frontend/utils';
import hasRule from 'frontend/utils/hasRule';

/*
ruleId is null when we do not want to filter by ruleId, undefined when we want to filter by the default rule.
 */
export function useBuildItems(values: unknown, languageCode: string, ruleId: string | undefined | null, name: string) {
  const items = useMemo(() => {
    const filteredValues = (getArray(name, values) ?? []).filter(hasLanguage(languageCode));
    if (ruleId !== null) {
      return filteredValues.filter(hasRule(ruleId));
    }
    return filteredValues;
  }, [languageCode, ruleId, name, values]);
  return items;
}
