import { subDays } from 'date-fns';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Checkbox, Condition, DatePicker, Radio, ToggleSwitch } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import ChooseNumberOfDays from './ChooseNumberOfDays';
import styles from './RequestInboxBackup.scss';
import SetLanguage from './SetLanguage';
import { MAX_NUMBER_OF_DAYS } from './constants';
import validator from './validator';

const JSON = 'JSON';
const CSV = 'CSV';
const MAX_DATE = new Date();

const initialValues = {
  days: 7,
  backupFormat: JSON,
  onlyBotReplies: false,
  dateRangeEnabled: false,
  to: new Date(),
  from: subDays(new Date(), 7),
  currentLanguage: undefined,
};

const TOGGLE_DATE_RANGE_STATUS = Object.freeze(['Use specific date range']);

const RequestInboxBackup = ({ hide, args: { onOk, botOrSkillParams } }) => (
  <ModalForm
    title="Create Inbox backup"
    hide={hide}
    onSubmit={onOk}
    initialValues={initialValues}
    validate={validator}
    onOkText="Create"
    showFormErrors
  >
    <SetLanguage botOrSkillParams={botOrSkillParams} />
    <div className="m-b-lg">
      <ToggleSwitch name="dateRangeEnabled" status={TOGGLE_DATE_RANGE_STATUS} />
    </div>
    <div className="m-b-md text-center">
      <p>Select file format</p>
      <Field name="backupFormat" type="radio" component={Radio} label={JSON} value={JSON} />
      <Field name="backupFormat" type="radio" component={Radio} label={CSV} value={CSV} />
    </div>
    <Condition when="backupFormat" is={CSV}>
      <div className="m-b-md">
        <Field type="checkbox" component={Checkbox} name="onlyBotReplies" label="Only include bot messages" />
      </div>
    </Condition>
    <div className="m-b-md">
      <Condition when="dateRangeEnabled" is>
        <Field
          name="datePicker"
          render={({ input }) => (
            <div className={styles.chooseDateRange}>
              Choose date range (max {MAX_NUMBER_OF_DAYS} days):
              <DatePicker
                showDatepickerStaticRange
                name={input.name}
                onChange={input.onChange}
                hideCompare
                maxDate={MAX_DATE}
                maxNumberOfDays={MAX_NUMBER_OF_DAYS}
              />
            </div>
          )}
        />
      </Condition>
      <Condition when="dateRangeEnabled" is={false}>
        <ChooseNumberOfDays />
      </Condition>
      <div className={styles.helpText}>
        <br />
        <p>Creating backup might take a while, depending on the message volume</p>
      </div>
    </div>
  </ModalForm>
);

RequestInboxBackup.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ onOk: PropTypes.func.isRequired, botOrSkillParams: BotOrSkillParamsType.isRequired })
    .isRequired,
};

export default RequestInboxBackup;
