import PropTypes from 'prop-types';
import { ChangeEvent } from 'react';

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
const ValueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape({}),
  PropTypes.bool,
  PropTypes.array,
]);
type Value = string | number | boolean | object | any[];

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export default PropTypes.shape({
  /** The value of the select; reflected by the selected option. */
  value: ValueType,
  /** Handle change events on the select. */
  onChange: PropTypes.func,
  name: PropTypes.string,
});
export interface Input {
  value: Value;
  onChange: (event: React.ChangeEvent) => void;
  name: string;
}
