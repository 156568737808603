import PropTypes from 'prop-types';

import styles from './ChatGroup.scss';
import MessageTag from '../MessageTag';
import Score from '../Score';

const SlotPrompt = () => (
  <span className={styles.exchangeType} title="Prompted to fill slot">
    SLOT PROMPT
  </span>
);

const ConfidenceScore = ({ firstMessage: { score, exchangeType, fromWebhook, handler }, showModal }) => {
  const isKeywordMatch = handler && (handler.includes('KEYWORD_EXACT_MATCH') || handler.includes('KEYWORD_MENTION'));
  const isSlotPrompt = handler && handler.includes('SLOT_PROMPT');
  const isMimirAnswer = handler && handler.includes('MIMIR');
  const isIntentAnswer = handler && handler === 'INTENT_MATCH';
  const isIntentSimplifiedAnswer = handler && handler === 'INTENT_MATCH_SIMPLIFIED';
  const isAiSmalltalk = handler && handler === 'AI_SMALLTALK';

  if (isKeywordMatch) {
    return (
      <>
        <span className={styles.exchangeType} title="Triggered by a keyword dialogue">
          KEYWORD
        </span>
        {isSlotPrompt && <SlotPrompt />}
      </>
    );
  }

  if (isMimirAnswer) {
    return <MessageTag>AI generated</MessageTag>;
  }
  if (isIntentAnswer) {
    return <MessageTag>Intent match</MessageTag>;
  }
  if (isIntentSimplifiedAnswer) {
    return <MessageTag>Intent match simplfied</MessageTag>;
  }
  if (isAiSmalltalk) {
    return <MessageTag>AI smalltalk</MessageTag>;
  }

  if (score) {
    return (
      <>
        <Score title="Confidence score" onClick={showModal}>
          {Math.round(score * 100)}%
        </Score>
        {isSlotPrompt && <SlotPrompt />}
      </>
    );
  }

  switch (exchangeType) {
    case 'greeting':
      return <MessageTag>GREETING</MessageTag>;
    case 'fallback':
      return <MessageTag>FALLBACK</MessageTag>;
    case 'system-dialogue':
      return <MessageTag>SYSTEM DIALOGUE</MessageTag>;
    case 'fallback_suggestion':
      return <MessageTag>FALLBACK SUGGESTION</MessageTag>;
    default:
      if (fromWebhook) return <MessageTag>WEBHOOK</MessageTag>;
      return null;
  }
};

ConfidenceScore.propTypes = {
  firstMessage: PropTypes.shape({}).isRequired,
  showModal: PropTypes.func.isRequired,
};

export default ConfidenceScore;
