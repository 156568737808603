import cx from 'classnames';
import { lowerFirst } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { Trash } from 'frontend/assets/icons';
import Button from 'frontend/components/Button';
import Dropdown from 'frontend/components/Dropdown';
import { Icon } from 'frontend/components/Icon/Icon';
import { capitalize } from 'frontend/utils';

import styles from './Filter.scss';
import { EXCLUDED_FILTERS_PREFIX, type FilterType } from '../../utils/config';
import { extractFilterKey, getFilterWithoutIndex, getKeyIndex } from '../../utils/helpers';
import type { FilterKeyType } from '../ButtonTitle/ButtonTitle';
import IncludeExclude from '../IncludeExclude/IncludeExclude';

const getInitialExcludedState = (filterKey) => {
  const searchParams = new URLSearchParams(window.location.search);
  const filterParamKey = getFilterWithoutIndex(filterKey);
  return Array.from(searchParams).some((searchParam) => {
    const [key] = searchParam as [string, string];
    return key.startsWith(EXCLUDED_FILTERS_PREFIX) && filterParamKey === key;
  });
};

const Filter = ({
  component,
  filterKey,
  canExclude,
  icon,
  activeTitle,
  shouldSubmitOnToggle,
  pinnedFilters,
}: FilterType & { filterKey: FilterKeyType; shouldSubmitOnToggle: boolean; pinnedFilters?: FilterKeyType[] }) => {
  const { submit, reset } = useForm();

  const { current: isExcluded } = useRef<boolean>(getInitialExcludedState(filterKey));
  const { values } = useFormState({ subscription: { values: true } });

  const submitFilters = (isRemove?: boolean) => {
    if (isRemove) {
      const valuesCopy = { ...values };
      delete valuesCopy[filterKey];

      const orderedKeys = Object.keys(valuesCopy).sort((a, b) => a.localeCompare(b));

      const newValues = {};
      orderedKeys.forEach((key, index) => {
        const keyWithoutIndex = getFilterWithoutIndex(key);
        newValues[`${keyWithoutIndex}{${index}}`] = valuesCopy[key];
      });
      reset(newValues);
    }
    if (!shouldSubmitOnToggle || isRemove) {
      submit();
    }
  };

  const handleIncludeExcludeChange = useCallback(
    (key: string) => {
      const reverseKeyBase = key.startsWith(EXCLUDED_FILTERS_PREFIX)
        ? lowerFirst(key.replace(EXCLUDED_FILTERS_PREFIX, ''))
        : `${EXCLUDED_FILTERS_PREFIX}${capitalize(key)}`;

      const extractedKeyIndex = getKeyIndex(key);

      const reverseKey = extractFilterKey(reverseKeyBase, extractedKeyIndex);

      const valuesCopy = { ...values };

      const vals = values[key];
      valuesCopy[reverseKey] = vals;
      delete valuesCopy[key];

      const orderedValues = Object.entries(valuesCopy).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
      const orderValuesObj = Object.fromEntries(orderedValues);
      reset(orderValuesObj);
      submit();
    },
    [submit, values, reset],
  );

  const canRemove = !pinnedFilters?.includes(getFilterWithoutIndex(filterKey) as FilterKeyType);

  return (
    <div className={styles.activeFilter}>
      <div className={styles.activeIconWrapper}>
        <Icon width={18} height={18} component={icon} />
      </div>
      <div className={styles.buttonWrapper}>
        {canExclude && (
          <IncludeExclude
            isExcluded={isExcluded}
            filterKey={filterKey}
            onIncludeExcludeChange={handleIncludeExcludeChange}
          />
        )}
        <Dropdown
          key={`active-filters-${filterKey}`}
          element={Button}
          elementProps={{
            color: 'white',
            size: 'small',
            className: cx(styles.horizontalDropdownButton, {
              [styles.horizontalDropdownButtonPinned]: !canRemove,
            }),
          }}
          triggerClassName={styles.trigger}
          overlayClassName={styles.filtersDropdownOverlay}
          position="bottom-left"
          onClose={() => {
            submitFilters();
          }}
          overlay={component(filterKey, shouldSubmitOnToggle)}
        >
          {activeTitle(filterKey)}
        </Dropdown>
        {canRemove && (
          <Button
            flat
            size="small"
            className={styles.filterRemoveButton}
            onClick={() => {
              submitFilters(true);
            }}
          >
            <Icon width={18} height={18} color="gray" hoverColor="orange" component={Trash} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Filter;
