import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

export default ({ mutation, setIsSubmitting, variables, update }) => {
  const [mutationFunction] = useMutation(mutation, { update });

  const submitAction = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await mutationFunction({ variables });
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
    }
  }, [mutationFunction, setIsSubmitting, variables]);

  return submitAction;
};
