import PropTypes from 'prop-types';

import IDType from './IDType';

export type BuildIdObjectBot = {
  botId: string;
  skillId?: never;
};

export type BuildIdObjectSkill = {
  botId?: never;
  skillId: string;
};

export type BuildIdObject = BuildIdObjectBot | BuildIdObjectSkill;
export type BuildType = 'bot' | 'skill';

export default PropTypes.oneOfType([PropTypes.shape({ botId: IDType }), PropTypes.shape({ skillId: IDType })]);
