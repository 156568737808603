import { gql } from '@apollo/client';

import { DialogueFragment } from 'frontend/features/Build/graphql/fragments/DialogueFragment';

export const GetDialogue = gql`
  query Dialogue($dialogueId: UUID!, $botId: ID, $skillId: ID) {
    dialogue(id: $dialogueId, botId: $botId, skillId: $skillId) {
      ...Dialogue
    }
  }
  ${DialogueFragment}
`;
