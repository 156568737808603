import PropTypes from 'prop-types';

import { Modal } from 'frontend/features/Modals';

const AboutImageCarousel = ({ hide }) => (
  <Modal hide={hide} onOk={hide} hideCancel title="About image uploads">
    <Modal.Content>
      <p className="m-t-0">
        Upload multiple images to create a carousel.
        <br />
        Carousel images will be cropped to a square 1:1 ratio.
      </p>
      <p className="m-b-0">
        <strong>File size:</strong> max 2 MB.
        <br />
        <strong>File type:</strong> .jpg, .jpeg, .png, .gif <br />
        <strong>Crop:</strong> If cropped, the center portion is kept.
      </p>
    </Modal.Content>
  </Modal>
);

AboutImageCarousel.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default AboutImageCarousel;
