import cx from 'classnames';

import styles from './Score.scss';

interface ScoreProps {
  onClick: () => void;
  children: (string | number | string)[] | string;
  title?: string;
  className?: string;
}

const Score = ({ title = '', onClick, children, className }: ScoreProps) => (
  <span
    className={cx(styles.score, className)}
    title={title}
    onClick={onClick}
    onKeyDown={(e) => e.key === 'Enter' && onClick()}
    role="button"
    tabIndex={0}
  >
    {children}
  </span>
);

export default Score;
