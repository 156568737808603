import { Searcher } from 'fast-fuzzy';
import { at, get, isFunction } from 'lodash';
import { useMemo, useState } from 'react';

import useDebounce from './useDebounce';

const DEFAULT_THRESHOLD = 0.76;
const DEFAULT_DEBOUNCE_DELAY = 300;

const curriedAt = (paths) => (object) => at(object, paths);

/**
 * Fuzzy search in array of candidate items by given keys. Uses the fast-fuzzy module.
 *
 * @param {string[]|object[]} candidates - The list of candidate items that will be searched.
 * @param {string|string[]|function} [keys] - The key names to search in each item. If a function is supplied, it is called with current query and should return a string or an array.
 * @param {Object} [options] - Optional configuration object.
 * @param {string} [options.listName] - If supplied, the search will apply to the corresponding field of each candidate instead; i.e. 'candidates' is a list of objects each containing a list of name 'listName'.
 * @param {number} [options.threshold] - The search fuzzyness threshold. Defaults to 0.76.
 * @param {number} [options.delay] - The search debounce delay in milliseconds. Defaults to 300.
 * @returns {Object} An object containing search results and related functions.
 */
export default (candidates, keys, { listName, threshold, delay } = {}) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, delay || DEFAULT_DEBOUNCE_DELAY);
  const keySelector = useMemo(() => curriedAt(isFunction(keys) ? keys(debouncedQuery) : keys), [debouncedQuery, keys]);

  const searcherObject = useMemo(() => {
    const options = { threshold: threshold || DEFAULT_THRESHOLD };
    if (keys) options.keySelector = keySelector;

    if (!listName) return new Searcher(candidates, options);

    return candidates.map((candidate) => ({
      ...candidate,
      _searcher: new Searcher(get(candidate, listName, []), options),
    }));
  }, [threshold, keySelector, candidates, keys, listName]);

  const searchResults = useMemo(() => {
    if (!debouncedQuery) return candidates;
    if (!listName) return searcherObject.search(debouncedQuery);

    return searcherObject.map((item) => ({
      ...item,
      [listName]: item._searcher.search(debouncedQuery),
      _searcher: undefined,
    }));
  }, [candidates, debouncedQuery, listName, searcherObject]);

  return { searchResults, query, debouncedQuery, setQuery };
};
