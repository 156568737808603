import cx from 'classnames';
import { nth, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useMemo } from 'react';

import styles from './ClusterItem.scss';
import { SalientTokensType } from '../../propTypes';

const element = (idx) => (array) => nth(array, idx);

const MAX_SALIENT_TOKENS = 10;

const ClusterItem = forwardRef(({ currentClusterIndex, index, selectCluster, salientTokens }, ref) => {
  const selected = currentClusterIndex === index;
  const wrapperStyles = cx(styles.clusterItemWrapper, { [styles.clusterItemWrapperSelected]: selected });

  const onClick = useCallback(() => selectCluster(index), [index, selectCluster]);
  const onKeyDown = useCallback((event) => event.key === 'Enter' && onClick(), [onClick]);

  const tokens = useMemo(
    () => orderBy(Object.entries(salientTokens), element(1), 'desc').map(element(0)),
    [salientTokens],
  );

  return (
    <div ref={ref} onClick={onClick} onKeyDown={onKeyDown} className={wrapperStyles} tabIndex={0} role="button">
      <div className={styles.index}>{index + 1}</div>
      <div className={styles.clusterItem}>
        {tokens.slice(0, MAX_SALIENT_TOKENS).map((token) => (
          <div key={token} className={styles.token}>
            {token}
          </div>
        ))}
      </div>
    </div>
  );
});

ClusterItem.displayName = 'ClusterItem';

ClusterItem.propTypes = {
  selectCluster: PropTypes.func.isRequired,
  currentClusterIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  salientTokens: SalientTokensType.isRequired,
};

export default ClusterItem;
