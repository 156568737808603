import { gql } from '@apollo/client';

import { EVALUATION_REPORT_FRAGMENT } from './fragments';

const GET_EVALUATION_REPORT = gql`
  query GetEvaluationReport($evaluationId: ID!, $reportId: ID!) {
    evaluation(evaluationId: $evaluationId) {
      id
      evaluationReport(reportId: $reportId) {
        ...EvaluationReport
        queryResults {
          id
          dialogue {
            id
          }
          evaluationQuery {
            id
          }
          isKeyword
          dialogueTitle
          dialogueReply
          userSays
          predictorReply
          predictorReplyIsFallback
          predictorMatchesReply
          predictorMatchesId
          predictorScore
          predictorReplyDialogue {
            id
          }
        }
      }
    }
  }
  ${EVALUATION_REPORT_FRAGMENT}
`;
export default GET_EVALUATION_REPORT;
