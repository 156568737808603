import PropTypes from 'prop-types';

import noArchivedBotsIllustration from 'frontend/assets/images/no_archived_bots.svg?url';
import noBotsIllustration from 'frontend/assets/images/no_bots.svg?url';
import noSkillsIllustration from 'frontend/assets/images/no_skills.svg?url';
import { Panel } from 'frontend/components';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';

import styles from './styles.scss';

const setIllustration = ({ type, isArchive }) => {
  if (type === 'skill') return noSkillsIllustration;
  if (type === 'bot' && isArchive) return noArchivedBotsIllustration;
  return noBotsIllustration;
};

const setTitle = ({ type, isArchive }) => {
  if (type === 'skill') return 'No skills yet';
  if (type === 'bot' && isArchive) return 'No archived workspaces';
  return 'No workspaces yet';
};

const Description = ({ type, isArchive, hasArchivedBots }) => {
  if (type === 'skill') {
    return (
      <>
        <p>
          This is where you will see all the skills in your organization, however it seems like you do not have any
          skills yet. You can create custom skills tailor-made to fit your organization’s needs. You can also subscribe
          to pre-made skills developed by Kindly.
        </p>
        <p>
          You can learn more about skill by visiting our{' '}
          <HelpCenterLink path="/sections/skills/">help center</HelpCenterLink>.
        </p>
      </>
    );
  }
  if (type === 'bot' && isArchive) {
    return (
      <p>
        This is where you will see all archived workspaces in your organization. However, it seems like yo have not
        archived workspaces yet.
      </p>
    );
  }
  if (type === 'bot' && hasArchivedBots) {
    return (
      <p>
        This is where you will see all workspaces in your organization. However, it seems they are all archived. To
        unarchive any of them click &quot;Archived&quot; above, otherwise create a new one.
      </p>
    );
  }

  return (
    <p>
      This is where you will see all workspaces in your organization. However, it seems like yo have not workspaces yet.
      <br />
      Would you like to create one?
    </p>
  );
};

Description.propTypes = {
  type: PropTypes.string.isRequired,
  isArchive: PropTypes.bool,
  hasArchivedBots: PropTypes.bool,
};

const EmptyBotSkillState = ({ type, isArchive = false, hasArchivedBots = false }) => {
  const illustration = setIllustration({ type, isArchive });
  const title = setTitle({ type, isArchive });

  return (
    <Panel className={styles.container}>
      <div className={styles.illustrationContainer}>
        <img src={illustration} alt={`No ${type}`} />
      </div>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.description}>
          <Description type={type} isArchive={isArchive} hasArchivedBots={hasArchivedBots} />
        </div>
      </div>
    </Panel>
  );
};

EmptyBotSkillState.propTypes = {
  type: PropTypes.string.isRequired,
  isArchive: PropTypes.bool,
  hasArchivedBots: PropTypes.bool,
};

export default EmptyBotSkillState;
