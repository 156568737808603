import { useMutation } from '@apollo/client';
import { pick } from 'lodash';

import { useIsRunning } from 'frontend/hooks';

import { UPDATE_CHAT_META } from '../mutations';

export default ({ botId, chatId }) => {
  const [update] = useMutation(UPDATE_CHAT_META);
  const [updateWithSubmitting, isSubmitting] = useIsRunning(update);

  const updateChatMeta = (values) =>
    updateWithSubmitting({
      variables: {
        botId,
        chatId,
        chatMetaInput: {
          ...pick(values, ['email', 'phoneNumber', 'status']),
        },
      },
    });

  return [updateChatMeta, isSubmitting];
};
