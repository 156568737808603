import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns/format';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getMessageBackups } from 'frontend/api/queries';
import { Button, Loader, PageBar, PageContent } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { Pusher, getPusherChannel } from 'frontend/features/Pusher';
import { useBotOrSkill, useIsRunning, useToast } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { pluralize } from 'frontend/utils';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import { Backups, GcsInboxBackup, S3InboxBackup } from './components';
import { DownloadEncryptedFile, GeneratePassword, NoBackupsInInterval, RequestInboxBackup } from './modals';
import { CREATE_MESSAGE_BACKUP } from '../mutations';

const backupDateTime = ({ data }) => (data ? format(data, DATE_FORMAT_WITH_TIME) : <span>&infin;</span>);

const dateRangeColumns = [
  {
    title: 'From',
    key: 'backupFrom',
    render: backupDateTime,
  },
  {
    title: 'To',
    key: 'backupTo',
    render: backupDateTime,
  },
];

const MessageBackups = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const botOrSkillParams = useBotOrSkill();
  const { botId } = botOrSkillParams;
  const [create] = useMutation(CREATE_MESSAGE_BACKUP);
  const { data: meData, loading: meLoading } = useMe();
  const { loading: backupsLoading, data: backupsData } = useQuery(getMessageBackups, { variables: { botId } });
  const [showDownloadModal] = useModal(DownloadEncryptedFile);
  const [showNoInterval] = useModal(NoBackupsInInterval);
  const [showGeneratePassword] = useModal(GeneratePassword);
  const [showRequestBackup] = useModal(RequestInboxBackup);

  const onClickBack = useCallback(
    (event) => {
      event.preventDefault();
      navigate(-1);
    },
    [navigate],
  );

  const createBackup = useCallback(() => {
    const onOk = async ({
      days,
      backupFormat,
      onlyBotReplies,
      dateRangeEnabled,
      from,
      to,
      datePicker,
      currentLanguage,
    }) => {
      let selectRange: { days?: any; from?: Date; to?: Date } = { days };
      if (dateRangeEnabled) {
        if (datePicker?.[0]) {
          const [{ startDate, endDate }] = datePicker;
          selectRange = { from: startDate, to: endDate };
        } else {
          selectRange = { from, to };
        }
      }

      const variables = {
        botId,
        backupFormat,
        onlyBotReplies,
        languageCode: currentLanguage,
        ...selectRange,
      };
      const { data } = await create({ variables });
      showGeneratePassword(data.createMessageBackup);
    };

    showRequestBackup({ onOk, botOrSkillParams });
  }, [botId, botOrSkillParams, create, showGeneratePassword, showRequestBackup]);

  const [createWithSubmitting, isSubmitting, onFinished] = useIsRunning(createBackup, { updateWhenFinished: false });

  const data = useMemo(
    () =>
      (backupsData?.messageBackups || []).map((backup) => {
        const { id, status, createdAt, backupFrom, backupTo } = backup;
        return {
          key: `${id}-${backupFrom}-${backupTo}`,
          id,
          backupFrom,
          backupTo,
          status,
          created: format(createdAt, DATE_FORMAT_WITH_TIME),
          options: {
            botId,
            backup,
            type: 'MESSAGE',
            downloadBackup: (backupId) => showDownloadModal({ backupId, botId }),
          },
        };
      }),
    [backupsData?.messageBackups, botId, showDownloadModal],
  );

  const backupsFinished = useCallback(
    ({ number }) => {
      onFinished();

      if (number === 0) {
        setTimeout(showNoInterval);
      } else {
        toast.success(`${number} ${pluralize('backup', number)} finished`);
      }
    },
    [onFinished, showNoInterval, toast],
  );

  if (backupsLoading || meLoading) {
    return <Loader size="large" />;
  }

  return (
    <>
      <PageBar>
        <Button className="m-r-md" onClick={onClickBack}>
          Go back
        </Button>
        <Button
          color="primary"
          onClick={createWithSubmitting}
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
          text="Create backup"
        />
      </PageBar>
      <PageContent>
        <S3InboxBackup botId={botId as string} />
        <hr />
        <GcsInboxBackup botId={botId as string} />
        <hr />
        <Backups backups={data} extraColumns={dateRangeColumns} />
        <Pusher
          channel={getPusherChannel({ userId: meData?.me?.id })}
          event="backups-finished"
          onUpdate={backupsFinished}
        />
      </PageContent>
    </>
  );
};

export default MessageBackups;
