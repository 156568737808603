import { camelCase, isEmpty } from 'lodash';

import { CONTACT_DETAILS_STATUS } from 'frontend/state/dux/inbox';

const labelsPredicate = (labels) => (chat) => chat.labelIds.some((labelId) => labels.includes(labelId));

const languagesPredicate = (languages) => (chat) => languages.includes(chat.languageCode);

const sourcePredicate = (sources) => (chat) => sources.includes(chat.source);

const botIdFromFilter = (botFilter) => Number(botFilter.replace('bot-', ''));

const botPredicate = (botIds) => (chat) => botIds.map(botIdFromFilter).includes(Number(chat.botId));

const handoverStatusPredicate = (handoverStatus) => (chat) => handoverStatus.some((status) => chat[camelCase(status)]);

const { done, requested } = CONTACT_DETAILS_STATUS;

const contactDetailsStatusPredicate = (filter) => (chat) => {
  if (filter.includes(done) && chat.contactDetails?.status === done) return true;
  if (chat.contactDetails?.status === requested && filter.includes(chat.contactDetails?.contact_type)) return true; // eslint-disable-line camelcase
  return false;
};

export default ({
  labelFilter,
  languageFilter,
  sourceFilter,
  handoverStatusFilter,
  contactDetailsStatusFilter,
  botFilter,
}) => {
  const predicates = [];

  if (!isEmpty(labelFilter)) predicates.push(labelsPredicate(labelFilter));
  if (!isEmpty(languageFilter)) predicates.push(languagesPredicate(languageFilter));
  if (!isEmpty(sourceFilter)) predicates.push(sourcePredicate(sourceFilter));
  if (!isEmpty(handoverStatusFilter)) predicates.push(handoverStatusPredicate(handoverStatusFilter));
  if (!isEmpty(contactDetailsStatusFilter)) predicates.push(contactDetailsStatusPredicate(contactDetailsStatusFilter));
  if (!isEmpty(botFilter)) predicates.push(botPredicate(botFilter));

  return predicates;
};
