import { useCurrentLanguage } from 'frontend/hooks';

import FlowNode from '../components/FlowNode';

/*
  Datastructure must match the ReactFlow component:
  https://reactflow.dev/examples/layouting/
*/

const horizontalSpacing = 240; // Initial width of the node + space between the nodes
const verticalSpacing = 78; // Initial height of the node + space between the nodes

const titleWidth = 141; // taken from the console, the width is 200px - paddings and what not
const fontSize = 12; // Font size of the title
const fontFactor = 1.92; // Font factor for sans
const titleLineHeight = 18; // Line height of the title

const maximumCharactersPerLine = (titleWidth * fontFactor) / fontSize;

// This method calculates how many lines of text are expected given the word
// It might not be absolutely correct, cause it depends on multiple factors to calculate it, like font family
// but it is close enough
// play with the fontFactor to adjust if needed
const getNumberOfTextLines = (title) => {
  let titleCopy = title;

  let characters = 0;
  let numberOfTextLines = 1;

  // We need to count Enters(\n) as new lines and recalculate based on that
  const newLinesCount = (titleCopy.match(/\n/g) || []).length;

  // remove the new line since its already in the calculations
  if (newLinesCount > 0) {
    titleCopy = titleCopy.replace(/\n/g, '');
  }

  const wordsInTitle = titleCopy.trim().split(' ');

  for (let i = 0; i < wordsInTitle.length; i += 1) {
    // +1 to account for intervals
    characters += wordsInTitle[i].length + (i > 0 ? 1 : 0);
    if (characters > maximumCharactersPerLine * numberOfTextLines) {
      numberOfTextLines += 1;
    }
  }

  // Add the new lines to the numberOfLines count
  numberOfTextLines += newLinesCount;

  return numberOfTextLines;
};

function useFlowDialogues(dialogues) {
  const [{ selectedLanguage, currentLanguage }] = useCurrentLanguage();
  const disabledDialogues = [];
  // Apply correction of position for multiline nodes
  let yCorrection = 0;
  const flowNodes = [];
  // we need to know how many parent ids in order to push the next node horizontally
  let parentIds = [];
  // used to know what is the position of the next node vertically
  let nodeIndex = 0;

  let previousYPosition = 0;
  let previousHorizontalMultiplier;

  const filterChildNode = (childNode) => {
    if (!childNode.numberOfFollowups) return null;

    return dialogues.dialogues
      .filter((dialogue) => dialogue.parentId === childNode.id)
      .reduce((acc, currValue) => [...acc, { ...currValue, childNodes: filterChildNode(currValue) }], []);
  };

  // group dialogoues by their parents so its easier to iterate
  const groupedElements = dialogues?.dialogues.reduce(
    (acc, currValue) => ({
      ...acc,
      ...(!currValue.parentId && {
        [currValue.id]: {
          ...currValue,
          childNodes: filterChildNode(currValue),
        },
      }),
    }),
    {},
  );

  const getFlowNodeDetails = (dialogue, verticalMultiplier = 0, horizontalMultiplier = 0) => {
    const { id, title, isActive, mod, colorLabel, parentId } = dialogue;
    const dialogueIsActive = mod?.modIsActive ?? isActive;
    const dialogueColorLabel = mod?.modColorLabel ?? colorLabel;

    if (!isActive[selectedLanguage] || disabledDialogues.includes(parentId)) disabledDialogues.push(id);
    const numberOfTextLines = getNumberOfTextLines(title[currentLanguage]);

    const yPosition =
      horizontalMultiplier > previousHorizontalMultiplier
        ? previousYPosition
        : verticalMultiplier * verticalSpacing + yCorrection;

    const data = {
      id,
      data: {
        label: (
          <FlowNode
            label={title[currentLanguage]}
            dialogueId={id}
            isActive={dialogueIsActive[selectedLanguage]}
            parentIsDisabled={disabledDialogues.includes(parentId)}
            dialogueColorLabel={dialogueColorLabel}
            dialogue={dialogue}
          />
        ),
      },
      position: {
        // position of the node horizontally
        x: horizontalMultiplier * horizontalSpacing,
        // position of the node vertically, we taken into account multiline nodes
        // where we need to push the node further down depending on how much it has pushed before and if the previous item is multiline as well
        y: horizontalMultiplier > previousHorizontalMultiplier ? previousYPosition : yPosition,
      },
      sourcePosition: 'right',
      targetPosition: 'left',
      isConnectable: false,
      // This fixes the issue with zIndex stacking
      zIndex: 1000 - verticalMultiplier - horizontalMultiplier,
    };

    const elementHeight = titleLineHeight * numberOfTextLines - verticalSpacing / 2;

    // calculate the y correction for each subsequent node
    if (numberOfTextLines > 1) {
      yCorrection += elementHeight;
    }

    // fix the Y position when we have a previous dialogue with too long text
    // Ignore the first item from the column
    if (horizontalMultiplier > previousHorizontalMultiplier && verticalMultiplier !== 0) {
      yCorrection -= yPosition - previousYPosition + elementHeight;
    }

    previousYPosition = yPosition;
    previousHorizontalMultiplier = horizontalMultiplier;

    return data;
  };

  const getChildNodesDetails = (childNode, verticalMultiplier, horizontalMultiplier, isFirstChild) => {
    if (childNode.childNodes) {
      flowNodes.push(getFlowNodeDetails(childNode, verticalMultiplier, horizontalMultiplier));
      if (!parentIds.includes(childNode.parentId) && childNode.parentId) parentIds.push(childNode.parentId);

      for (let i = 0; i < childNode.childNodes.length; i += 1) {
        nodeIndex += 1;

        if (i === 0 && nodeIndex > 0) {
          // if its a follow-up node, then we render it next to it
          nodeIndex -= 1;
        }

        getChildNodesDetails(childNode.childNodes[i], nodeIndex, parentIds.length + 1, !i);
        if (i === childNode.childNodes.length - 1) {
          // if its the last child
          // remove the parent id from the list since we have finished with those childNodes
          parentIds = [...parentIds.filter((id) => id !== childNode.parentId)];
        }
      }
    } else {
      if (!parentIds.includes(childNode.parentId)) parentIds.push(childNode.parentId);
      flowNodes.push(getFlowNodeDetails(childNode, verticalMultiplier, horizontalMultiplier, isFirstChild));
      // remove the parent id from the list since we have finished with those childNodes
      parentIds = [...parentIds.filter((id) => id !== childNode.parentId)];
    }
  };

  if (groupedElements) {
    Object.entries(groupedElements).forEach(([, value]) => {
      if (value.childNodes) {
        getChildNodesDetails(value);
      } else {
        flowNodes.push(getFlowNodeDetails(value, 0, 0));
      }
    });
  }

  const connectingLines = dialogues?.dialogues
    .filter(({ parentId }) => !!parentId)
    .map(({ id, parentId }) => ({
      id: `line-${id}`,
      source: parentId,
      target: id,
      targetPosition: 'left',
      type: 'smoothstep',
    }));

  return { nodes: flowNodes, edges: connectingLines };
}

export default useFlowDialogues;
