import { gql } from '@apollo/client';

export default gql`
  mutation DropSamplesInDialogue($sourceDialogueId: UUID!, $targetDialogueId: UUID!, $sampleIds: [UUID]!) {
    dropSamplesInDialogue(
      sourceDialogueId: $sourceDialogueId
      targetDialogueId: $targetDialogueId
      sampleIds: $sampleIds
    ) {
      id
      targetDialogueId
      sampleIds
    }
  }
`;
