import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function useSelectedReport(reports, reportId) {
  const { botId } = useParams();
  const { evaluationId } = useParams();
  const navigate = useNavigate();
  const [selectedReport, setSelectedReport] = useState();
  const [comparingReport, setCompareReport] = useState();

  // FIXME: this could probably ble simplified a lot
  useEffect(() => {
    const index = reports.indexOf(selectedReport) + 1;
    setCompareReport(index < reports.length ? reports[index] : null);
    if (selectedReport) {
      return;
    }
    if (reportId) {
      const foundReport = reports.find((report) => report.id === reportId);
      if (foundReport) {
        setSelectedReport(foundReport);
      }
    } else if (reports.length) {
      setSelectedReport(reports[0]);
    }
    // compare
  }, [selectedReport, reportId, reports]);

  const selectReport = useCallback(
    (report) => {
      const index = reports.indexOf(report);
      setSelectedReport(reports[index]);

      if (index <= reports.length) {
        setCompareReport(reports[index + 1]);
      } else {
        setCompareReport(null);
      }
      navigate(`/workspace/${botId}/build/evaluation/${evaluationId}/reports/${reports[index].id}`);
    },
    [botId, navigate, reports, evaluationId],
  );
  return { selectedReport, comparingReport, selectReport };
}

export default useSelectedReport;
