import { capitalize, isArray } from 'lodash';

import { camelToHumanReadable } from 'frontend/utils';

const strictIsObject = (o) => o instanceof Object && o.constructor === Object;

// Attempts to find a value satisfying 'condition' inside a data structure,
// and returns the first success
export const deepGetFirstValue = (data, condition) => {
  if (condition(data)) return data;
  if (strictIsObject(data)) {
    const result = deepGetFirstValue(Object.values(data), condition);
    if (result) return result;
  }
  if (isArray(data) && data.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const element of data) {
      if (condition(element)) return element;
      if (strictIsObject(element) || (isArray(element) && element.length > 0)) {
        const result = deepGetFirstValue(element, condition);
        if (result) return result;
      }
    }
  }
  return null;
};

const removeModFromKey = (key) => key.replace(/^mod(\w)/, (_, x) => x.toLowerCase());

export const formatErrorKey = (key) => `${capitalize(camelToHumanReadable(removeModFromKey(key))?.trim())}:`;
