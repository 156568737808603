import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { OrganizationUsersDetailDocument } from 'frontend/api/generated';

import { ORGANIZATION_USERS } from '../queries';

const useOrganizationMembers = ({ detailed = false } = {}) => {
  const { organizationId } = useParams();
  const { data, loading } = useQuery(detailed ? OrganizationUsersDetailDocument : ORGANIZATION_USERS, {
    variables: { id: organizationId! },
  });

  const organizationName = get(data, 'organization.name');
  const members = useMemo(() => get(data, 'organization.members', []), [data]);
  const users = useMemo(() => members.map(({ user }) => user), [members]);
  const permissions = useMemo(() => get(data, 'organization.permissions', {}), [data]);

  return { users, members, permissions, loading, organizationName };
};

export default useOrganizationMembers;
