import { useQuery } from '@apollo/client';

import { ChatMessageDocument } from 'frontend/api/generated';
import { GoTo } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './MessageListElement.scss';

const MessageListElement = ({ chatMessageId }: { chatMessageId: string }) => {
  const { data: chatMessageData, loading } = useQuery(ChatMessageDocument, {
    variables: { id: chatMessageId },
  });
  const chatMessage = chatMessageData?.chatMessage;
  if (loading || !chatMessage) return null;
  return (
    <a
      href={`/workspace/${chatMessage.botId}/inbox/chat/${chatMessage.chatId}`}
      title={chatMessage.replyToMessage || chatMessage.message || undefined}
      className={styles.messageListElement}
    >
      <span className={styles.message}>{chatMessage.replyToMessage || chatMessage.message}</span>
      <Icon component={GoTo} className={styles.goTo} />
    </a>
  );
};

export default MessageListElement;
