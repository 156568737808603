import { isArray } from 'lodash';

export default (validators, ...rest) =>
  (value, allValues) => {
    const errors = [];
    const validatorArray = isArray(validators) ? validators : [validators, ...rest];

    validatorArray.filter(Boolean).forEach((validator) => {
      const result = validator(value, allValues);
      if (result !== undefined) {
        errors.push(result);
      }
    });

    return errors.length ? errors.join(', ') : undefined;
  };
