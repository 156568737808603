import { EditorState } from 'draft-js';
import { useCallback, useState } from 'react';

import useComposerState from './useComposerState';
import { removeHighlightTag, removeSelection } from '../stateHandlers';

const CLOSED_STATE = { open: false, selectedText: null, fromHashtag: false };

export default function useDropdown(name) {
  const { state, setState } = useComposerState(name);
  const [dropdownState, setDropdownState] = useState(CLOSED_STATE);

  const openDropdown = useCallback(({ selectedText, fromHashtag = false }) => {
    setDropdownState({ open: true, selectedText, fromHashtag });
  }, []);

  const closeDropdown = useCallback(
    (updatedState) => {
      setDropdownState(CLOSED_STATE);

      const currentState = updatedState instanceof EditorState ? updatedState : state;
      const stateWithoutHighlightInstance = removeHighlightTag(currentState);

      if (currentState.getSelection().isCollapsed()) {
        setState(stateWithoutHighlightInstance);
        return;
      }

      const selectionCollapsed = removeSelection(stateWithoutHighlightInstance);
      setState(selectionCollapsed);
    },
    [setState, state],
  );

  return { dropdownState, openDropdown, closeDropdown };
}
