import cx from 'classnames';
import { Children } from 'react';
import type { To } from 'react-router-dom';

import { ChevronDown, ChevronUp } from 'frontend/assets/icons';
import { useKeyDown, useToggle } from 'frontend/hooks';

import styles from './Accordion.scss';
import HeaderRight from './HeaderRight';
import { Icon } from '../Icon/Icon';
import List from '../List/List';

interface AccordionProps {
  children: React.ReactNode;
  title: React.ReactNode;
  isOpen?: boolean;
  className?: string;
  bodyClassName?: string;
  headerClassName?: string;
  headerLeft?: React.ReactElement | string;
  headerRight?: React.ReactElement | string;
  onClick?(e: React.SyntheticEvent): void;
  innerClassName?: string;
  fullLengthTitle?: boolean;
  to?: To;
  ignoreActive?: boolean;
  hideChevron?: boolean;
}

const Accordion = ({
  title,
  headerLeft,
  headerRight,
  children,
  isOpen: initialIsOpen,
  className,
  bodyClassName,
  innerClassName,
  onClick,
  headerClassName,
  to,
  ignoreActive,
  fullLengthTitle = false,
  hideChevron,
}: AccordionProps) => {
  const [isOpen, toggleOpen] = useToggle(initialIsOpen);

  const handleClick = (e: React.SyntheticEvent) => {
    toggleOpen();
    onClick?.(e);
  };

  const onKeyDown = useKeyDown({ action: handleClick });

  const renderHeaderContent = (isNavigationItem?: boolean) => (
    <>
      <div className={styles.accordionNavLeftContainer}>
        {headerLeft}
        {isNavigationItem ? (
          <div className={styles.accordionLinkTitle}>{title}</div>
        ) : (
          <h3 className={styles.title}>{title}</h3>
        )}
      </div>

      {!fullLengthTitle && (
        <div className={styles.headerRightContainer}>
          {!!headerRight && <span className={styles.headerRightContent}>{headerRight}</span>}
          {!hideChevron && <Icon component={isOpen ? ChevronUp : ChevronDown} hoverColor="primary" />}
        </div>
      )}
    </>
  );

  return (
    <div className={className}>
      {to ? (
        <List.NavLinkItem
          className={cx(styles.accordionHeader, headerClassName, {
            [styles.accordionHeaderFullLength]: fullLengthTitle,
          })}
          onClick={handleClick}
          ignoreActive={ignoreActive}
          to={to}
        >
          {renderHeaderContent(true)}
        </List.NavLinkItem>
      ) : (
        <div
          className={cx(styles.accordionHeader, headerClassName, {
            [styles.accordionHeaderFullLength]: fullLengthTitle,
          })}
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={onKeyDown}
          role="button"
        >
          {renderHeaderContent()}
        </div>
      )}

      <div
        className={cx(
          styles.accordionBody,
          bodyClassName,
          Children.count(children) > 0 && { [styles.accordionBodyOpen]: isOpen },
        )}
      >
        <div className={cx(styles.accordionInner, innerClassName)}>{children}</div>
      </div>
    </div>
  );
};

Accordion.HeaderRight = HeaderRight;

export default Accordion;
