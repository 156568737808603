import { noop } from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import type { BotApiKeyType } from 'frontend/api/generated';
import { Keyword } from 'frontend/assets/icons';
import { Button, Input } from 'frontend/components';
import { Modal, useModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import RegenerateBotApiKey from './RegenerateBotApiKey';
import styles from './ViewBotApiKey.scss';
import { Permission } from '../components';

interface ViewBotApiKeyProps {
  hide: () => Promise<void>;
  args: {
    botId: string;
    botApiKey: BotApiKeyType;
  };
}

const ViewBotApiKey = ({ hide, args: { botId, botApiKey } }: ViewBotApiKeyProps) => {
  const toast = useToast();
  const [showRegenerateBotApiKey] = useModal(RegenerateBotApiKey);
  const { name, apiKey, viewConversations, viewStatistics } = botApiKey;

  return (
    <Modal title={`API key: ${name}`} icon={Keyword} footer={false} hide={hide}>
      <Modal.Content>
        <Input
          className={styles.apiKey}
          readOnly
          input={{ value: apiKey, onChange: noop, name: '' }}
          aria-label="API key"
        />
        <div className={styles.permissions}>
          <Permission checked={viewConversations} />
          <span>Read chats</span>
          <Permission checked={viewStatistics} />
          <span>Read statistics</span>
        </div>
        <div className={styles.buttons}>
          <CopyToClipboard
            onCopy={() => {
              toast.success('Key copied to clipboard');
              hide();
            }}
            text={apiKey}
          >
            <Button color="primary">Copy key to clipboard</Button>
          </CopyToClipboard>
          <Button color="warning" onClick={() => showRegenerateBotApiKey({ botId, botApiKey })}>
            Regenerate key
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ViewBotApiKey;
