import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import EntityItemList from 'frontend/features/Entities/components/EntityItemList';
import { useKeyDown } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';
import { getId } from 'frontend/utils';

import styles from './DropdownList.scss';
import TextDescription from './TextDescription';
import { TAG_COMPONENT_MAPPING, TAG_TYPES } from '../../constants';
import { getType } from '../../utils';

const MAX_LISTED_ENTITY_ITEMS = 10;

const DETAIL_COMPONENT_MAPPING = Object.freeze({
  [TAG_TYPES.ENTITY]: EntityItemList,
  [TAG_TYPES.KINDLY_ENTITY]: TextDescription,
  [TAG_TYPES.SLOT]: TextDescription,
});

const Item = ({ item, addTag }) => {
  const { name, items, hue, description, detail } = item;
  const id = getId(item);
  const type = getType(item);

  const onClick = useCallback(() => addTag(item), [addTag, item]);
  const onKeyDown = useKeyDown({ action: onClick, stopEvent: true });

  const TagComponent = useMemo(() => TAG_COMPONENT_MAPPING[type], [type]);
  const DetailComponent = useMemo(() => DETAIL_COMPONENT_MAPPING[type], [type]);

  return (
    <div className={styles.dropdownListRow} onClick={onClick} onKeyDown={onKeyDown} role="button" tabIndex={0}>
      <span tabIndex={0} role="button">
        <TagComponent name={name} items={items} hue={hue} description={description} detail={detail} lessInfo />
      </span>
      <span>
        <DetailComponent
          items={items}
          entityId={id}
          hue={hue}
          description={description}
          maxItems={MAX_LISTED_ENTITY_ITEMS}
        />
      </span>
    </div>
  );
};

Item.propTypes = {
  addTag: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: IDType,
    tempId: IDType,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string),
    hue: PropTypes.number,
    description: PropTypes.string,
    detail: PropTypes.string,
  }),
};

export default Item;
