import PropTypes from 'prop-types';

import { REPORT_STATES } from 'frontend/features/Organization/views/Reports/utils';
import { IDType } from 'frontend/propTypes';

export const EmojiFeedbackType = PropTypes.shape({
  ratio: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
});

export const HappyOrNotFeedbackType = PropTypes.shape({
  value: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
});

export const BinaryFeedbackType = PropTypes.shape({
  ratio: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
});

export const FeedbackType = PropTypes.shape({
  total: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([EmojiFeedbackType, BinaryFeedbackType, HappyOrNotFeedbackType])),
});

export const ChatFeedbackType = PropTypes.shape({
  id: IDType.isRequired,
  created: PropTypes.string.isRequired,
  feedbackType: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  freeformText: PropTypes.string,
});

export const HideFilterType = PropTypes.shape({
  language: PropTypes.bool,
  date: PropTypes.bool,
  granularity: PropTypes.bool,
  nudges: PropTypes.bool,
});

export const MonthlyReportType = PropTypes.shape({
  id: IDType.isRequired,
  month: PropTypes.string.isRequired,
  state: PropTypes.oneOf(Object.values(REPORT_STATES)).isRequired,
  tz: PropTypes.string.isRequired,
});
