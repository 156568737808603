import cx from 'classnames';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'frontend/components';
import styles from 'frontend/features/Admin/views/AdminBots/styles.scss';

const Filters = ({ setFilters, filters }) => (
  <div className={styles.filters}>
    <div className={styles.filterRow}>
      <Button
        className={cx(styles.filterButton, filters.isAdmin === true && styles.active)}
        onClick={() => setFilters((prev) => ({ ...prev, isAdmin: true }))}
        text="Admin"
        flat
      />
      <Button
        className={cx(styles.filterButton, filters.isAdmin === false && styles.active)}
        onClick={() => setFilters((prev) => ({ ...prev, isAdmin: false }))}
        text="Not admin"
        flat
      />
      <Button
        className={cx(styles.filterButton, filters.isAdmin === undefined && styles.active, styles.default)}
        onClick={() => setFilters((prev) => omit(prev, 'isAdmin'))}
        text="Unset"
        flat
      />
    </div>
  </div>
);
Filters.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({ isAdmin: PropTypes.bool }).isRequired,
};
export default Filters;
