/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import cx from 'classnames';

import { Dropdown } from 'frontend/components';

import styles from './BreadcrumbCollapsed.scss';
import type { OverlayPosition } from './Breadcrumbs';

interface Props {
  items: React.ReactNode[];
  title: string;
  classNames?: Record<string, string>;
  overlayPosition?: OverlayPosition | Record<string, never>;
}

function BreadcrumbsCollapsed({ items, classNames = {}, title, overlayPosition = {} }: Props) {
  const overlayPositionProps =
    overlayPosition.position === 'custom' ? { overlayLeft: overlayPosition.left, overlayTop: overlayPosition.top } : {};

  return (
    <Dropdown
      position={overlayPosition?.position || 'bottom'}
      triggerClassName={cx(styles.dropdownButton, classNames.dropdownButton)}
      overlayClassName={cx(styles.overlay, classNames.dropdownOverlay)}
      overlay={<Overlay items={items} classNames={classNames} />}
      title={title || 'Show more'}
      {...overlayPositionProps}
    >
      ...
    </Dropdown>
  );
}

interface OverlayProps {
  items: Props['items'];
  classNames?: Record<string, string>;
}

function Overlay({ items, classNames = {} }: OverlayProps) {
  return <ul className={cx(styles.ul, classNames.dropdownOverlayUl)}>{items}</ul>;
}

export default BreadcrumbsCollapsed;
