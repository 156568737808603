import { SelectionState } from 'draft-js';

export default ({ anchorKey, focusKey, anchorOffset, focusOffset, start, end, hasFocus }) => {
  const selection = SelectionState.createEmpty(anchorKey);
  return selection.merge({
    focusKey: focusKey ?? anchorKey,
    anchorOffset: anchorOffset ?? start,
    focusOffset: focusOffset ?? end ?? anchorOffset ?? start,
    hasFocus: hasFocus ?? false,
  });
};
