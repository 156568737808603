import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { LabelsForBotDocument } from 'frontend/api/generated';
import { BarChart } from 'frontend/components/Stats';
import { Grid, StatsPanel } from 'frontend/features/Analytics/components';
import { useFakeData, useSageData } from 'frontend/features/Analytics/hooks';
import type { ChatLabelsGraphqlData } from 'frontend/features/Analytics/utils/analyticMockData';
import type { ChatLabelsData, ChatLabelsTotalData } from 'frontend/features/Analytics/utils/sageData';
import CSVDownloadButton from 'frontend/features/Analytics/views/Sessions/components/CSVDownloadButton/CSVDownloadButton';
import { parseSageScope } from 'frontend/state/dux/analytics/sageScope';
import type { QueryParam } from 'frontend/utils/stringifyQueryParamsWithBrackets';

import styles from './styles.scss';

const N_LABELS = 5;

type LabelsData = { total: number; labels: { value: number; label: string; color: string }[] };

function sageLabelsToBarData(
  addedLabelsData: ChatLabelsData,
  totalData: ChatLabelsTotalData,
  labelsForBot: ChatLabelsGraphqlData,
): LabelsData {
  if (!addedLabelsData || !totalData || !labelsForBot) {
    return { total: 0, labels: [] };
  }

  const labels = addedLabelsData.flatMap((labelData) => {
    const label = labelsForBot.find(({ id, type }) => id === labelData.label_id && type === 'DEFAULT');
    if (!label) {
      return [];
    }
    return [
      {
        value: labelData.count,
        label: `${labelData.label_text}${label ? '' : ' (deleted)'}`,
        color: label?.color || '#000000',
      },
    ];
  });

  return { total: totalData.count || 0, labels };
}

function labelsToCSV(data: LabelsData) {
  return data.labels.map(({ value, label }) => ({
    label,
    count: value,
  }));
}

type Props = {
  filters: Record<string, QueryParam>;
  to?: string;
  scope: string;
  priority?: number;
  isPreview?: boolean;
};
const LabelStats = ({ filters, scope, priority, to, isPreview = false }: Props) => {
  const [botId] = parseSageScope(scope);

  const [labelsCount, setLabelsCount] = useState(0);
  const {
    loading: addedLabelsLoading,
    error: addedLabelsError,
    data: addedLabelsData,
  } = useSageData(scope, '/chatlabels/added', filters, { priority });
  const {
    loading: totalLoading,
    error: totalError,
    data: totalData,
  } = useSageData(scope, '/chatlabels/added/total', filters, { priority });

  const { fakeData: fakeLabelData, fakeDataEnabled } = useFakeData<ChatLabelsGraphqlData>('graphql:chat_labels');

  const {
    loading: labelLoading,
    data: labelData,
    error: labelError,
  } = useQuery<{ labelsForBot: ChatLabelsGraphqlData }>(LabelsForBotDocument, { variables: { botId } });

  const { loading, data, error } = useMemo(() => {
    if (fakeDataEnabled) {
      return {
        data: sageLabelsToBarData(addedLabelsData, totalData, fakeLabelData as ChatLabelsGraphqlData),
        loading: false,
        error: undefined,
      };
    }

    if (addedLabelsLoading || totalLoading || labelLoading || !labelData) {
      return { loading: true, error: undefined, data: { total: 0, labels: [] } };
    }

    return {
      data: sageLabelsToBarData(addedLabelsData, totalData, labelData.labelsForBot),
      loading: false,
      error: undefined,
    };
  }, [
    addedLabelsData,
    addedLabelsLoading,
    totalData,
    totalLoading,
    labelData,
    labelLoading,
    fakeLabelData,
    fakeDataEnabled,
  ]);

  useEffect(() => {
    if (setLabelsCount) {
      setLabelsCount(data.total);
    }
  }, [setLabelsCount, data]);

  const title = 'Triggered labels';
  const subtitle = (
    <Grid columns={2}>
      <>
        Total {labelsCount} labels triggered in selected period.
        <br />
        Showing top {N_LABELS}:
      </>
      {!isPreview && !loading && (
        <div className={styles.downloadButtonWrapper}>
          <CSVDownloadButton filename="labels.csv" data={labelsToCSV(data)} />
        </div>
      )}
    </Grid>
  );

  return (
    <StatsPanel
      loading={loading || addedLabelsLoading || totalLoading}
      error={error || addedLabelsError || totalError || labelError?.message}
      title={title}
      subtitle={subtitle}
      to={to}
    >
      <BarChart data={data} />
    </StatsPanel>
  );
};

export default LabelStats;
