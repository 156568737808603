import cx from 'classnames';

import { UserCheck, UserPlus, Users } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './ChatStatus.scss';

export const CHAT_STATUS_LABELS = Object.freeze({
  awaiting_response: {
    label: 'Awaiting response',
    icon: Users,
  },
  taken_over: {
    label: 'In handover',
    icon: Users,
  },
  handed_over: {
    label: 'In handover',
    icon: Users,
  },
  completed_takeover: {
    label: 'Handover completed',
    icon: UserCheck,
  },
  completed_handover: {
    label: 'Handover completed',
    icon: UserCheck,
  },
  contact_requested: {
    label: 'Contact requested',
    icon: UserPlus,
  },
});

interface ChatStatusProps {
  status: keyof typeof CHAT_STATUS_LABELS | null;
  notification: boolean;
  asBadge?: boolean;
}

const ChatStatus = ({ status, notification = false, asBadge = false }: ChatStatusProps) => {
  if (!status) {
    return null;
  }
  return (
    <div className={cx(styles.chatStatus, { [styles.badge]: asBadge })}>
      {CHAT_STATUS_LABELS[status]?.icon && (
        <Icon component={CHAT_STATUS_LABELS[status].icon} title="Chat status" className={styles.icon} />
      )}
      <span>
        {CHAT_STATUS_LABELS[status]?.label || `${status.charAt(0).toUpperCase()}${status.slice(1).replace(/_/g, ' ')}`}
      </span>
      {notification && <div className={styles.dot} />}
    </div>
  );
};

export default ChatStatus;
