import { gql } from '@apollo/client';

import LabelFragment from 'frontend/api/fragments/Label.fragment.graphql';

export default gql`
  mutation UpdateLabel($botId: ID!, $labelId: UUID!, $name: String!, $color: String!) {
    updateLabel(botId: $botId, labelId: $labelId, name: $name, color: $color) {
      ...Label
    }
  }
  ${LabelFragment}
`;
