import { useFormState } from 'react-final-form';

import { pluralize } from 'frontend/utils';

import styles from './ButtonTitle.scss';
import AgentTitle from '../AgentTitle/AgentTitle';
import LabelsTitle from '../LabelsTitle/LabelsTitle';

interface ButtonTitleProps {
  dropdownActive: boolean;
  filterKey:
    | 'dateFilter'
    | 'sourceFilter'
    | 'languageFilter'
    | 'labelFilter'
    | 'replyTypeFilter'
    | 'ticketAgentFilter'
    | 'handoverStatusFilter'
    | 'contactDetailsStatusFilter'
    // TODO remove when we get rid of the organization inbox
    | 'botFilter';
}

const getLength = (values) => {
  if (typeof values === 'string') {
    return 1;
  }
  if (Array.isArray(values)) {
    return values.length;
  }
  return Object.keys(values || {})?.length;
};

const ButtonTitle = ({ filterKey, dropdownActive }: ButtonTitleProps) => {
  const { values, initialValues } = useFormState({ subscription: { values: true, initialValues: true } });
  const filterValue = dropdownActive ? initialValues[filterKey] : values[filterKey];
  const length = getLength(filterValue);

  const renderTitle = () => {
    switch (filterKey) {
      case 'dateFilter': {
        if (!filterValue?.[0]) return null;
        const [{ endDate, startDate }] = filterValue;
        return `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`;
      }
      case 'sourceFilter':
        return `${length} ${pluralize('source', length)}`;
      case 'labelFilter':
        return <LabelsTitle values={filterValue} />;
      case 'handoverStatusFilter':
        return `${length} ${pluralize('status', length)}`;
      case 'languageFilter':
        return `${length} ${pluralize('language', length)}`;
      case 'replyTypeFilter':
        return `${length} ${pluralize('type', length)}`;
      case 'contactDetailsStatusFilter':
        return `${length} ${pluralize('filter', length)}`;
      // Remove when we ditch the organization inbox
      case 'botFilter':
        return `${length} ${pluralize('bot', length)}`;
      case 'ticketAgentFilter':
        return <AgentTitle agentId={filterValue} />;
      default:
        return `${length} ${pluralize('item', length)}`;
    }
  };

  return <div className={styles.buttonTitleWrapper}>{renderTitle()}</div>;
};

export default ButtonTitle;
