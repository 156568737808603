import LabelsSection from 'frontend/features/Labels/LabelsSection';
import { useBotLabels } from 'frontend/hooks';

import styles from './menus-styles.scss';
import type { MenuProps } from './types';

export const LabelsController = ({ onFilterChange, checked: checkedLabelsIds, setChecked }: MenuProps) => {
  const botLabels = useBotLabels();

  const handleCheck = (label) => {
    const isChecked = !checkedLabelsIds.includes(label.id);

    if (isChecked) {
      checkedLabelsIds = [...checkedLabelsIds, label.id];
    } else {
      checkedLabelsIds = checkedLabelsIds.filter((val) => val !== label.id);
    }
    if (setChecked) {
      setChecked(checkedLabelsIds);
    }

    onFilterChange(checkedLabelsIds);
  };

  return (
    <LabelsSection
      activeLabelsIds={checkedLabelsIds}
      botLabels={botLabels}
      canEditLabels={false}
      className={styles.labelsSection}
      handleLabelToggle={handleCheck}
      inputPlaceholder="Filter labels"
    />
  );
};
