import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DialogueModEnabledDocument } from 'frontend/api/generated';
import { GetDialogue } from 'frontend/features/Build/graphql';

export default ({ dialogue, selectedLanguage, skillId, disabled, client, buildIdObject }) => {
  const [enableMutation] = useMutation(DialogueModEnabledDocument);
  const { id } = dialogue;

  const enableFunction = useCallback(async () => {
    const variables = { skillId, ...buildIdObject, id, enabled: Boolean(disabled), languageCode: selectedLanguage };
    await enableMutation({ variables });

    client.query({ query: GetDialogue, variables: { dialogueId: id, ...buildIdObject }, fetchPolicy: 'network-only' });
  }, [skillId, buildIdObject, id, disabled, selectedLanguage, enableMutation, client]);

  return enableFunction;
};
