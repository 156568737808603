import { gql } from '@apollo/client';

import { CHANGELOG_ENTRY_FRAGMENT } from 'frontend/features/Admin/views/Changelog/graphql';

export const GET_CHANGELOG = gql`
  query GetChangelog {
    changelog {
      ...ChangelogEntry
    }
  }
  ${CHANGELOG_ENTRY_FRAGMENT}
`;
