import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, Panel, ToggleSwitch } from 'frontend/components';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import { useToast } from 'frontend/hooks';

import styles from '../KindlyChat/styles.scss';

const HTML_SNIPPET = `<script 
id="kindly-zendesk-handover" 
src="https://zendesk-handover.kindly.ai/app.js"></script>`;

const GTM_SNIPPET = `<script>
  var kindlyZendesk = document.createElement('script');
  kindlyZendesk.src = 'https://zendesk-handover.kindly.ai/app.js';
  kindlyZendesk.id = 'kindly-zendesk-handover';
  document.body.appendChild(kindlyZendesk);
</script>`;

const ZendeskHandover = () => {
  const toast = useToast();
  const [showGTM, setShowGTM] = useState(false);

  return (
    <div>
      <span>
        Read more on{' '}
        <HelpCenterLink path="/connect-integrations-and-plugins">
          configuring Zendesk handover on help center
        </HelpCenterLink>{' '}
        or contact us on{' '}
        <a className="link" rel="noopener noreferrer" href="mailto:support@kindly.ai">
          support@kindly.ai
        </a>{' '}
        if you need help.
      </span>
      <Panel.Separator />
      <div className={styles.textContainer}>
        <h3>Get HTML code</h3>
        <ToggleSwitch.Switch
          input={{ name: 'showGtm', value: showGTM }}
          onClick={() => setShowGTM(!showGTM)}
          status={['Show HTML snippet', 'Show snippet for Google Tag Manager']}
        />
        {showGTM ? (
          <>
            <p>
              When using GTM, please read the section about{' '}
              <HelpCenterLink path="/connect-integrations-and-plugins">
                Zendesk handover using GTM on help center.
              </HelpCenterLink>
            </p>
            <p>Put this code inside the &quot;Custom HTML&quot; editor when adding a new tag.</p>
            <pre className={styles.snippet}>{GTM_SNIPPET}</pre>
            <CopyToClipboard
              text={GTM_SNIPPET}
              onCopy={() => {
                toast.success('Copied to clipboard');
              }}
            >
              <Button color="primary" text="Copy to clipboard" className={`m-t-2 ${styles.copyButton}`} />
            </CopyToClipboard>
          </>
        ) : (
          <>
            <p>Put this code before the closing {'</body>'} tag</p>
            <pre className={styles.snippet}>{HTML_SNIPPET}</pre>
            <CopyToClipboard
              text={HTML_SNIPPET}
              onCopy={() => {
                toast.success('Copied to clipboard');
              }}
            >
              <Button color="primary" text="Copy to clipboard" className={`m-t-2 ${styles.copyButton}`} />
            </CopyToClipboard>
          </>
        )}
      </div>
    </div>
  );
};

export default ZendeskHandover;
