import { PusherProvider as PusherProviderComponent } from '@harelpls/use-pusher';
import PropTypes from 'prop-types';

import { ChildrenType } from 'frontend/propTypes';

import { OnConnect } from './components';
import { useAuthorizer, useDisabledTransports } from './hooks';

// If there is no activity for this long the client sends a ping (default: 120s)
const ACTIVITY_TIMEOUT_S = 60;

// If the server doesn't answer with a pong after this long the connection
// is considered lost (detault: 30s)
const PONG_TIMEOUT_S = 20;

const PusherProvider = ({ me, children }) => {
  const authorizer = useAuthorizer();
  const disabledTransports = useDisabledTransports(me);

  return (
    <PusherProviderComponent
      clientKey={window.env.PUSHER_KEY}
      cluster="eu"
      disableStats
      authorizer={authorizer}
      disabledTransports={disabledTransports}
      activityTimeout={ACTIVITY_TIMEOUT_S * 1000}
      pongTimeout={PONG_TIMEOUT_S * 1000}
    >
      <OnConnect />
      {children}
    </PusherProviderComponent>
  );
};

PusherProvider.propTypes = {
  children: ChildrenType.isRequired,
  me: PropTypes.shape({
    profile: PropTypes.shape({
      disabledTransports: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default PusherProvider;
