import { gql } from '@apollo/client';

const EVALUATION_REPORT_FRAGMENT = gql`
  fragment EvaluationReport on EvaluationReportType {
    id
    createdAt
    status
    passed
    failed
    passPercent
    queryCount
    nlpOverrides
    fScoreId
    dialogueCoverage
    runBy {
      id
      fullName
      profile {
        id
        avatarUrl
      }
    }
  }
`;

export default EVALUATION_REPORT_FRAGMENT;
