import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { FacebookPagesDocument } from 'frontend/api/generated';
import { LoaderSwitch, Panel } from 'frontend/components';

import styles from './FacebookMessenger.scss';
import { ConnectButton, ConnectedView } from './components';

export const FacebookMessenger = () => {
  const { botId } = useParams();
  const { data, loading } = useQuery(FacebookPagesDocument, { variables: { botId: botId! } });

  const facebookPages = useMemo(() => data?.bot?.facebookPages ?? [], [data]);
  const hasConnections = facebookPages.length > 0;

  return (
    <LoaderSwitch loading={loading} size="large">
      <ConnectButton facebookPages={facebookPages} />
      {hasConnections && (
        <>
          <Panel.Separator />
          <h3>Active connections</h3>
          <div className={styles.connectionsContainer}>
            {facebookPages.map((facebookPage) => (
              <ConnectedView key={facebookPage.id} facebookPage={facebookPage} />
            ))}
          </div>
        </>
      )}
    </LoaderSwitch>
  );
};
