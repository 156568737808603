import React, { useEffect } from 'react';
import { useField, useForm } from 'react-final-form';

import styles from './FieldWrapper.scss';
import inputConfigs from '../../utils/configs';
import inputTypes from '../../utils/constants';
import FieldAccordion from '../FieldAccordion/FieldAccordion';
import { SortableField } from '../SortableFields/SortableFields';

interface FieldWrapperProps {
  fieldName: string;
  index: number;
  remove: (index: number) => any;
}

const FieldWrapper = ({ fieldName, index, remove }: FieldWrapperProps): React.JSX.Element => {
  const {
    input: { value: field },
  } = useField(fieldName);

  const { change } = useForm();

  useEffect(() => {
    if (
      [inputTypes.SELECT, inputTypes.CHECKBOX, inputTypes.RADIO].includes(field.inputType) &&
      !!field.attributes?.options?.length
    ) {
      const optionsConfig = inputConfigs[field.inputType].find((config) => Object.keys(config).includes('options'));
      if (!field.attributes?.options?.length && optionsConfig) {
        change(`${fieldName}.attributes.options`, [optionsConfig.defaults]);
      }
    }
  }, [field, fieldName, change]);

  return (
    <SortableField fieldName={fieldName} fieldIndex={index} key={`sf-${field.id}`}>
      <FieldAccordion field={field} fieldIndex={index} remove={remove} fieldName={fieldName} />
      <div className={styles.fieldDivider} />
    </SortableField>
  );
};

const check = (oldProps, newProps) => oldProps.fieldName === newProps.fieldName;

export default React.memo(FieldWrapper, check);
