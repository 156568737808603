import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { IDType } from 'frontend/propTypes';

import { DOWNLOAD_MESSAGE_BACKUP } from '../../../mutations';

const INITIAL_VALUES = { password: '' };

const DownloadEncryptedFile = ({ hide, args: { botId, backupId } }) => {
  const [download] = useMutation(DOWNLOAD_MESSAGE_BACKUP);

  const onSubmit = useCallback(
    async ({ password }) => {
      const { data } = await download({ variables: { botId, backupId, password } });
      const url = data.downloadMessageBackup.url;
      if (url) window.location.assign(url);
    },
    [backupId, botId, download],
  );

  return (
    <ModalForm onSubmit={onSubmit} initialValues={INITIAL_VALUES} title="Enter password for backup" hide={hide}>
      <Field
        component={Input}
        name="password"
        validate={required}
        autoFocus
        placeholder="Password"
        aria-label="Password"
      />
    </ModalForm>
  );
};

DownloadEncryptedFile.propTypes = {
  args: PropTypes.exact({
    botId: IDType.isRequired,
    backupId: IDType.isRequired,
  }).isRequired,
  hide: PropTypes.func.isRequired,
};

export default DownloadEncryptedFile;
