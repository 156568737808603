import React from 'react';
import { useParams } from 'react-router-dom';

import kindlyGptImage from 'frontend/assets/images/kindly-gpt-bot-reply.png';
import { LoaderSwitch } from 'frontend/components';
import EmptyFeature from 'frontend/components/EmptyFeature';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';

import kindlyGptSourcesConfig from './config/kindlyGptSourcesConfig';
import { ConnectList } from '../components/ConnectList';

const KindlyGPT = () => {
  const { botId } = useParams();
  const { botSettings, loading } = useBotSettings(botId);

  return (
    <LoaderSwitch loading={!!loading}>
      {botSettings?.kindlyGptSettingsEnabled ? (
        <ConnectList data={kindlyGptSourcesConfig} />
      ) : (
        <EmptyFeature
          title="Enhance your bot with Kindly GPT"
          description={
            <>
              <p>
                Kindly GPT is a great way to expand the capability of your bot while keeping maintenance to a minimum.
              </p>
              <p>Get in touch with us to learn more about this feature.</p>
            </>
          }
          buttonText="Get in touch"
          onClick={() => {
            window.location.href =
              'mailto:support@kindly.ai?subject=Access%20to%20Kindly%20GPT&body=I%20would%20like%20to%20get%20started%20with%20Kindly%20GPT%20in%20our%20bot%2E%20%0ALink%20to%20bot%3A%20%0ACompany%20name%3A%20';
          }}
          imageSrc={kindlyGptImage as string}
        />
      )}
    </LoaderSwitch>
  );
};

export default KindlyGPT;
