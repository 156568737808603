import { Button } from 'frontend/components';

interface ClearFiltersButtonProps {
  clearSearch(): void;
}

export const ClearSearchButton = ({ clearSearch }: ClearFiltersButtonProps) => (
  <Button onClick={clearSearch} color="secondary">
    Clear search
  </Button>
);
