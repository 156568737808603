import { useMutation, useQuery } from '@apollo/client';
import { mapKeys, omit } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BotSettingsDocument, type BotSettingsQuery, UpdateBotSettingsDocument } from 'frontend/api/generated';
import { KINDLY_ENTITIES } from 'frontend/features/Entities/queries';
import { useIsRunning, useOnSubmitWithUpload, useToast } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { capitalize } from 'frontend/utils';

import { IGNORED_BOT_SETTINGS } from '../constants';

function flattenNestedSettings(settings, prefix) {
  return mapKeys(omit(settings, IGNORED_BOT_SETTINGS), (_, key) => `${prefix}${capitalize(key)}`);
}

type Return = {
  loading: boolean;
  initialValues: { bot: BotSettingsQuery['bot'] } | Record<string, never>;
  settingsData: BotSettingsQuery | undefined;
  onSubmit: (...args: any) => void;
  setFile: ((...args: any) => void) | undefined;
  isSubmitting: boolean;
};
export default function useGeneralForm(): Return {
  const toast = useToast();
  const { botId } = useParams();

  const update = useCallback(() => {
    toast.success('Settings updated!');
  }, [toast]);

  const [updateSettings] = useMutation(UpdateBotSettingsDocument, { update });

  const { data: settingsData, loading: botSettingsLoading } = useQuery(BotSettingsDocument, {
    variables: { botId: botId! },
  });
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });

  const canViewTemplates = hasBotPerms('view_templates');

  const loading = botSettingsLoading || loadingPermissions;

  const initialValues: { bot: BotSettingsQuery['bot'] } | Record<string, never> = useMemo(() => {
    if (loading || !settingsData?.bot) {
      return {};
    }

    const {
      bot: { nlpSettings, ...botRest },
      ...rest
    } = settingsData;

    return {
      bot: {
        nlpSettings,
        ...botRest,
      },
      ...flattenNestedSettings(nlpSettings, 'nlp'),
      ...rest,
    };
  }, [loading, settingsData]);

  const onSubmit = useCallback(
    async ({ bot }) => {
      await updateSettings({
        variables: {
          botId: botId!,
          botInput: omit(
            {
              ...bot,
              ...flattenNestedSettings(bot.nlpSettings, 'nlp'),
              deleteAvatar: bot.avatarUrl === null,
            },
            IGNORED_BOT_SETTINGS,
          ),
        },
        refetchQueries: ({ data }) =>
          canViewTemplates
            ? (data?.updateBot?.languages ?? []).map(({ code }) => ({
                query: KINDLY_ENTITIES,
                variables: { botId, languageCode: code },
              }))
            : [],
      });
    },
    [canViewTemplates, botId, updateSettings],
  );

  const [onSubmitWithUpload, setFile] = useOnSubmitWithUpload(onSubmit, `api/v2/bot/${botId}/upload-avatar/`);
  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmitWithUpload);

  return {
    initialValues,
    settingsData,
    loading,
    onSubmit: onSubmitWithRunning,
    setFile,
    isSubmitting,
  };
}
