import { camelCaseKeys, mapToObject } from 'frontend/utils';

import { ATTACHMENT_STATUS, CACHE_TYPE } from '../../constants';

export const isMessage = ({ __typename } = {}) => __typename === CACHE_TYPE.CHAT_MESSAGE_TYPE;

export const isMessageOrButtonClick = ({ __typename } = {}) =>
  [CACHE_TYPE.CHAT_MESSAGE_TYPE, CACHE_TYPE.BUTTON_CLICK_TYPE].includes(__typename);

const mapButton = (chatMessage) => (button) => ({ [button.id]: { button, chatMessage } });

export const BUTTON_CLICK_CONSTANT_FIELDS = {
  fromBot: false,
  exchangeType: null,
  fromWebhook: false,
  name: 'You',
  sender: 'USER',
};

// Find buttons in chat messages (including suggestions) and bundle them together with button clicks
export const handleButtonClicks = (buttonClicks, chatMessages) => {
  const buttonMapping = chatMessages.reduce((mapping, { buttons, suggestions, ...rest }) => {
    const messageButtonsMap = mapToObject(mapButton(rest), buttons || []);
    const suggestionButtonsMap = mapToObject(
      ({ buttons: suggestionButtons }) => mapToObject(mapButton(rest), suggestionButtons || []),
      suggestions || [],
    );

    return { ...mapping, ...messageButtonsMap, ...suggestionButtonsMap };
  }, {});

  return (buttonClicks || []).reduce((clicks, { id, buttonId, time, __typename }) => {
    const { button, chatMessage } = buttonMapping[buttonId] || {};
    if (!button) return clicks;

    const buttonClick = {
      ...chatMessage,
      id,
      button,
      created: time,
      __typename,
      ...BUTTON_CLICK_CONSTANT_FIELDS,
    };

    return [...clicks, buttonClick];
  }, []);
};

export const EMPTY_ATTACHMENT = Object.freeze({
  id: '',
  chatMessageId: '',
  temporaryChatMessageId: '',
  temporaryId: '',
  name: '',
  type: '',
  status: '',
  sender: '',
  created: '',
  storagePath: '',
  uploadUrl: '',
  url: '',
  __typename: CACHE_TYPE.ATTACHMENT_TYPE,
});

export const prepareAttachment = (attachment) => ({ ...EMPTY_ATTACHMENT, ...camelCaseKeys(attachment) });

export const getAttachementSource = (attachment, token) => {
  if (!attachment.storagePath || attachment.status !== ATTACHMENT_STATUS.CLEAN) {
    return attachment.url;
  }
  return `${window.env.API_URL}/api/v2/chat/attachment/${attachment.storagePath}/?token=${token}`;
};
