import { ExternalLink } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './MessageReferences.scss';
import type { DedupedMessageReferences } from './utils';

interface Props {
  references: DedupedMessageReferences[];
}

function MessageReferences({ references }: Props) {
  return (
    <div className={styles.container}>
      <span className={styles.title}>Sources</span>
      <div className={styles.referenceList}>
        {references.map(({ id, url }, index) => (
          <a className={styles.reference} key={id} href={url} target="_blank" rel="noreferrer">
            <sup className={styles.sup}>{index + 1}</sup>
            <span className={styles.referenceText}>Read more</span>
            <Icon component={ExternalLink} />
          </a>
        ))}
      </div>
    </div>
  );
}

export default MessageReferences;
