import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { Condition } from 'frontend/components';

import styles from './SlotItem.scss';
import SlotTitle from './SlotTitle';
import SlotFields from '../SlotFields';

const SlotItem = ({ index, remove, canRemoveSlot, counter }) => {
  const fieldName = `outputSlots.${index}`;
  const removeCurrent = useCallback(() => remove(index), [index, remove]);

  return (
    <div className={styles.slotItem}>
      <Condition when={`${fieldName}.slot.entity`} is="">
        <Field
          name={`${fieldName}.slot.name`}
          component={SlotTitle}
          counter={counter}
          remove={canRemoveSlot && removeCurrent}
        />
      </Condition>
      <Condition when={`${fieldName}.slot.entity`} isNot="">
        <Field
          name={`${fieldName}.slot.entity`}
          component={SlotTitle}
          counter={counter}
          remove={canRemoveSlot && removeCurrent}
          entity
        />
      </Condition>
      <SlotFields name={fieldName} />
      <br />
    </div>
  );
};

SlotItem.propTypes = {
  index: PropTypes.number.isRequired,
  counter: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  canRemoveSlot: PropTypes.bool.isRequired,
};

export default SlotItem;
