import PropTypes from 'prop-types';

import styles from './SkillSamples.scss';
import { SampleType } from '../../propTypes';
import SampleDetail from '../SampleDetail';

const SkillSamples = ({ skillSamples }) => (
  <div className={styles.skillSamples}>
    {skillSamples.map((sample) => (
      <div className={styles.skillSampleItem} key={`skill-sample-${sample.id}`}>
        <SampleDetail match={sample.match} text={sample.text} instances={sample.instances} />
      </div>
    ))}
  </div>
);

SkillSamples.propTypes = {
  skillSamples: PropTypes.arrayOf(SampleType).isRequired,
};

export default SkillSamples;
