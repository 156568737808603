import cx from 'classnames';

import loginStyles from 'frontend/features/Login/Login.scss';

import styles from './ContentBox.scss';

interface Props {
  children: React.ReactNode;
}

export default function ContentBox({ children }: Props) {
  return <div className={cx(styles.content, loginStyles.login)}>{children}</div>;
}
