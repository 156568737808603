import type { LabelType } from 'frontend/api/generated';
import LabelBadgeSlider from 'frontend/features/Labels/Label/LabelBadgeSlider';

import styles from './LabelRow.scss';

interface Props {
  label: Partial<LabelType>;
  subText: string;
  buttonComponent?: React.ReactNode;
  children?: React.ReactNode;
}

function LabelRow({ label: { name, color, background }, subText, buttonComponent, children }: Props) {
  return (
    <div className={styles.editLabelWrapper}>
      <LabelBadgeSlider labelName={name as string} labelColor={color} labelBackground={background} />
      <span className={styles.editLabelText}>{subText}</span>
      {buttonComponent || children}
    </div>
  );
}

export default LabelRow;
