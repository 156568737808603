import { useMemo } from 'react';

import {
  toastError,
  toastErrorClose,
  toastInfo,
  toastInfoClose,
  toastSuccess,
  toastSuccessClose,
  toastWarning,
  toastWarningClose,
} from 'frontend/features/Toast';
import type { ToastOptionsExtended } from 'frontend/features/Toast/showToast';

export default () =>
  useMemo(
    () => ({
      info: (msg: string, args?: ToastOptionsExtended) => toastInfo(msg, args),
      closeInfo: (msg: string) => toastInfoClose(msg),
      success: (msg: string, args?: ToastOptionsExtended) => toastSuccess(msg, args),
      linkSuccess: (msg: string, to: string, args?: ToastOptionsExtended) => toastSuccess(msg, args, to),
      closeSuccess: (msg: string) => toastSuccessClose(msg),
      error: (msg: string, args?: ToastOptionsExtended) => toastError(msg, args),
      closeError: (msg: string) => toastErrorClose(msg),
      warning: (msg: string, args?: ToastOptionsExtended) => toastWarning(msg, args),
      linkWarning: (msg: string, to: string, args?: ToastOptionsExtended) => toastWarning(msg, args, to),
      closeWarning: (msg: string) => toastWarningClose(msg),
    }),
    [],
  );
