import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';

const Finished = () => {
  const client = useApolloClient();
  useEffect(() => {
    client.resetStore();
  }, [client]);

  return <span>The Kindly entities were successfully inserted!</span>;
};

export default Finished;
