import filterByLanguageAndRule from './filterByLanguageAndRule';

export default (language, rule, dialogue: Record<string, any>) => ({
  ...dialogue,
  replies: filterByLanguageAndRule(language, rule, dialogue.replies),
  smartReplies: filterByLanguageAndRule(language, rule, dialogue.smartReplies),
  modReplies: filterByLanguageAndRule(language, rule, dialogue.modReplies),
  buttons: filterByLanguageAndRule(language, rule, dialogue.buttons),
  imageCarousels: filterByLanguageAndRule(language, rule, dialogue.imageCarousels),
  ...(dialogue.modButtons ? { modButtons: filterByLanguageAndRule(language, rule, dialogue.modButtons) } : {}),
  ...(dialogue.modImageCarousels?.length
    ? { modImageCarousels: filterByLanguageAndRule(language, rule, dialogue.modImageCarousels) }
    : {}),
  buildForms: filterByLanguageAndRule(language, rule, dialogue.buildForms),
  ...(dialogue.videoSources ? { videoSources: dialogue.videoSources[language] } : {}),
  ...(dialogue.modVideoSources ? { modVideoSources: dialogue.modVideoSources[language] } : {}),
  ...(dialogue.dialogueFallback ? { dialogueFallback: dialogue.dialogueFallback[language] } : {}),
  ...(dialogue.webhookUrls ? { webhookUrl: dialogue.webhookUrls[language] } : {}),
  ...(dialogue.modWebhookUrl ? { modWebhookUrl: dialogue.modWebhookUrl[language] } : {}),
});
