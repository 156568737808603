import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DeleteModal } from 'frontend/features/Modals';

import { getDemo } from '../graphql';

const DEACTIVATE_DEMO = gql`
  mutation DeactivateDemo($botId: ID!) {
    deactivateDemo(botId: $botId) {
      id
    }
  }
`;

const DeleteDemoModal = ({ hide, args: { botId } }) => {
  const [mutate] = useMutation(DEACTIVATE_DEMO, {
    update: (cache) => {
      try {
        cache.writeQuery({
          query: getDemo,
          data: { demo: null },
          variables: { botId },
        });
      } catch (err) {
        /* No action necessary when demo not in cache */
      }
    },
  });
  const onSubmit = useCallback(async () => {
    await mutate({ variables: { botId } });
  }, [botId, mutate]);

  return <DeleteModal onSubmit={onSubmit} hide={hide} name="demo page" />;
};
export default DeleteDemoModal;
