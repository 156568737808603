import useActiveFilters from '../../hooks/useActiveFilters';
import type { FilterKeyType } from '../ButtonTitle/ButtonTitle';
import Filter from '../Filter/Filter';

interface ActiveFiltersProps {
  shouldSubmitOnToggle: boolean;
  canIncludeExclude?: boolean;
  pinnedFilters?: FilterKeyType[];
}

const ActiveFilters = ({ canIncludeExclude, shouldSubmitOnToggle, pinnedFilters }: ActiveFiltersProps) => {
  const filters = useActiveFilters();

  return filters?.map(({ component, closeMenuOnSelection, filterKey, canExclude, text, icon, activeTitle }) => (
    <Filter
      pinnedFilters={pinnedFilters}
      closeMenuOnSelection={closeMenuOnSelection}
      key={`filter-${filterKey}`}
      shouldSubmitOnToggle={shouldSubmitOnToggle}
      component={component}
      filterKey={filterKey}
      canExclude={canIncludeExclude && canExclude}
      text={text}
      icon={icon}
      activeTitle={activeTitle}
    />
  ));
};

export default ActiveFilters;
