import useArchbeeToken from './useArchbeeToken';
import stringifyQueryParamsWithBrackets from '../../utils/stringifyQueryParamsWithBrackets';

const HELP_CENTER_URL = 'https://help.kindly.ai';

export function useHelpCenterUrl(path?: `/${string}`) {
  const archbeeToken = useArchbeeToken();
  const queryParams = stringifyQueryParamsWithBrackets({ jwt: archbeeToken ?? undefined });

  return `${HELP_CENTER_URL}${path || '/'}${queryParams ? `?${queryParams}` : ''}`;
}
