import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import { useState } from 'react';

import { type KindlyAdminGroups, UpdateAdminGroupDocument } from 'frontend/api/generated';
import { Agent } from 'frontend/assets/icons';
import { Radio } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import styles from './UpdateAdminGroupModal.scss';

function isValidAdminGroup(input: string): input is KindlyAdminGroups {
  // TODO: what is the best way to validate keep this in sync with the backend?
  return ['DELIVERY', 'NONE', 'SALES', 'TECH'].includes(input as KindlyAdminGroups);
}

const RADIO_OPTIONS: { name: KindlyAdminGroups; label: string }[] = [
  { name: 'TECH', label: 'Tech' },
  { name: 'DELIVERY', label: 'Delivery' },
  { name: 'SALES', label: 'Sales' },
  { name: 'NONE', label: 'none' },
];

interface Props {
  hide: () => void;
  args: {
    userId: string;
    email: string;
    groups: { id: string; name: string }[];
  };
}

export default function UpdateAdminGroup({ hide, args: { userId, email, groups } }: Props) {
  const toast = useToast();

  const currentAdminGroup =
    groups
      .find((group) => group.name.startsWith('kindly_admin:'))
      ?.name.split(':')[1]
      ?.toUpperCase() || 'NONE';

  const [adminGroup, setAdminGroup] = useState<KindlyAdminGroups>(() => {
    if (isValidAdminGroup(currentAdminGroup)) return currentAdminGroup;
    return 'NONE';
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionChosen = event.target.value;
    if (isValidAdminGroup(optionChosen)) setAdminGroup(optionChosen);
  };

  const shouldNotSubmit = adminGroup === currentAdminGroup;

  const [updateAdminGroupMutation] = useMutation(UpdateAdminGroupDocument);
  const updateAdminGroup = async () => {
    if (shouldNotSubmit) return undefined;

    const { errors } = await updateAdminGroupMutation({
      variables: { userId, groupName: adminGroup },
    });

    if (errors) return { [FORM_ERROR]: errors[0]?.message };

    toast.success(`${email} is now in ${adminGroup} admin group`);

    return undefined;
  };

  return (
    <Modal icon={Agent} title="Update admin group" hide={hide} onOk={updateAdminGroup} disabled={shouldNotSubmit}>
      <Modal.Content>
        <p>
          Select the new admin group for <strong>{email}</strong>:
        </p>
        <div className={styles.radioContainer}>
          {RADIO_OPTIONS.map((option) => (
            <Radio
              key={option.name}
              label={option.label}
              input={{
                name: option.name,
                value: option.name,
                checked: adminGroup === option.name,
                onChange: handleRadioChange,
              }}
            />
          ))}
        </div>
      </Modal.Content>
    </Modal>
  );
}
