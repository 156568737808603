// eslint-disable-next-line import/no-extraneous-dependencies
import { orderBy, tail } from 'lodash';

import type { EntityInstanceType } from 'frontend/api/generated';
import { entityLimits } from 'frontend/constants';

const substituteInstances = (originalText, result, instances: EntityInstanceType[], items) => {
  if (instances.length === 0) return result;

  const instance = instances[0] as EntityInstanceType;
  const remainingInstances = tail(instances).filter(({ entity }) => entity?.id);
  const item = items[0];
  const remainingItems = tail(items);
  const currentText = result.text;
  const updatedResult = { ...result };

  if (remainingInstances.length > 0) {
    updatedResult.text = `${currentText}${item}${originalText.slice(instance?.end, remainingInstances[0]?.start)}`;
  } else {
    updatedResult.text = `${currentText}${item}${originalText.slice(instance?.end)}`;
  }

  // Kindly entities placed after current instance have to be shifted accordingly
  const lengthChange = item.length - (instance.end - instance.start);
  updatedResult.instances = updatedResult.instances.map((entityInstance) => {
    const { start, end, kindlyEntity } = entityInstance;
    if (!kindlyEntity || kindlyEntity.start > instance.end) return entityInstance;
    return { ...entityInstance, start: start + lengthChange, end: end + lengthChange };
  });

  return substituteInstances(originalText, updatedResult, remainingInstances, remainingItems);
};

const combinationsWithMax = (allItems, maxCombinations, counters, combinations) => {
  if (combinations.length >= maxCombinations) return;

  combinations.push(allItems.map((items, idx) => items[counters[idx]]));

  for (let n = counters.length - 1; n >= 0; n -= 1) {
    if (counters[n] < allItems[n].length - 1) {
      counters[n] += 1;
      break;
    } else {
      counters[n] = 0;
    }
  }

  if (counters.some((counter, idx) => counter < allItems[idx].length - 1)) {
    combinationsWithMax(allItems, maxCombinations, counters, combinations);
  } else if (combinations.length < maxCombinations) {
    combinations.push(allItems.map((items, idx) => items[counters[idx]]));
  }
};

const getCombinationsWithMax = (allItems, maxCombinations) => {
  const counters = Array(allItems.length).fill(0);
  const combinations = [];
  combinationsWithMax(allItems, maxCombinations, counters, combinations);
  return combinations;
};

const expandSample = (sample, remainingToExpand) => {
  const { text, instances } = sample;
  const originalText = text;
  const orderedInstances = orderBy(
    instances.filter(({ entity }) => entity?.id),
    'start',
  );
  if (orderedInstances.length === 0) return sample;

  const allItems = orderedInstances.map(({ entity: { items } }) => items);
  const combinations = getCombinationsWithMax(allItems, remainingToExpand);
  const initialText = originalText.slice(0, orderedInstances[0].start);
  const initialExpanded = { ...sample, text: initialText };

  return combinations.map((items) => substituteInstances(originalText, initialExpanded, instances, items));
};

export default (sampleList) => {
  let expandedSampleList = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const sample of sampleList) {
    const remainingToExpand = entityLimits.SHOW_MAX_EXPANDED_SAMPLES - expandedSampleList.length;
    if (remainingToExpand <= 0) break;

    const expanded = expandSample(sample, remainingToExpand);
    expandedSampleList = expandedSampleList.concat(expanded);
  }
  return expandedSampleList;
};
