import cx from 'classnames';
import { capitalize } from 'lodash';
import { type FC, Fragment } from 'react';

import type { RolePermissionItemProps } from 'frontend/features/Organization/components/RolePermissionItem/RolePermissionItem';

import DefaultRolePermissionComponent from './DefaultRolePermissionComponent';
import DefaultRoleTitleComponent from './DefaultRoleTitleComponent';
import styles from './RoleDetails.scss';
import { CONNECTED_PERMISSIONS } from './constants';
import { useRelevantPermissionCategories } from './hooks';
import type { AnyRole } from '../../types';
import { sortRoles } from '../../utils';

const roleHasPermission = ({ permissions }, permission) => permissions.includes(permission);

const connectedPermissionsAreEqual = (permission: string, roles: AnyRole[]): boolean =>
  permission in CONNECTED_PERMISSIONS &&
  roles.every(
    (role) => roleHasPermission(role, permission) === roleHasPermission(role, CONNECTED_PERMISSIONS[permission]),
  );

interface RoleDetailsProps {
  roles: AnyRole[];
  allPermissions: string[];
  showAllPermissions?: boolean;
  roleTitleComponent?: FC<{ role: AnyRole }>;
  rolePermissionComponent?: FC<RolePermissionItemProps>;
}

const RoleDetails = ({
  roles,
  allPermissions,
  rolePermissionComponent = DefaultRolePermissionComponent,
  roleTitleComponent = DefaultRoleTitleComponent,
  showAllPermissions = false,
}: RoleDetailsProps) => {
  const gridStyle = { gridTemplateColumns: `9fr repeat(${roles.length}, 3fr)` };
  const relevantPermissionCategories = useRelevantPermissionCategories(allPermissions);
  const TitleComponent = roleTitleComponent;

  const sortedRoles = sortRoles(roles, 'title');

  return (
    <div className={styles.container}>
      <div className={cx(styles.grid, styles.gridHeader)} style={gridStyle}>
        <div />
        {sortedRoles.map((role) => (
          <TitleComponent key={role.id} role={role} />
        ))}
      </div>
      {Object.entries(relevantPermissionCategories).map(([category, permissionObjects]) => (
        <Fragment key={`permission-category-${category}`}>
          <h4 className={styles.sectionTitle}>{capitalize(category)}</h4>
          <div className={styles.grid} style={gridStyle}>
            {Object.entries(permissionObjects).map(([permission, description]) => {
              if (!showAllPermissions && connectedPermissionsAreEqual(permission, roles)) return null;

              return (
                <Fragment key={`permission-${category}-${permission}`}>
                  <div className={styles.rowTitle}>{description}</div>
                  {sortedRoles.map((role) => {
                    const key = `role-${role.id}-has-${category}-${permission}`;
                    const hasPermission = roleHasPermission(role, permission);
                    const Component = rolePermissionComponent;

                    return <Component key={key} hasPermission={hasPermission} role={role} permission={permission} />;
                  })}
                </Fragment>
              );
            })}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default RoleDetails;
