import defaultAvatar from 'frontend/assets/images/member_avatar.svg?url';
import { Button, Input } from 'frontend/components';
import { friendlyTimestamp } from 'frontend/utils';

import styles from './CommentBody.scss';

const CommentBody = ({ comment, editMode, canEdit, commentValue, setCommentValue, setEditMode, onSave }) => (
  <div key={`${comment.created}-${comment.id}`} className={styles.comment}>
    <img src={comment.avatar || defaultAvatar} className={styles.avatar} alt={`${comment.name} profile`} />
    <h4 className={styles.name}>
      {comment.name} <span className={styles.time}>{friendlyTimestamp(comment.created)}</span>
    </h4>
    {editMode && canEdit ? (
      <div>
        <Input
          className="m-b-sm"
          input={{ name: 'edit-comment', value: commentValue, onChange: (e) => setCommentValue(e.target.value) }}
          multiline
        />
        <Button color="primary" text="Save" size="small" className="m-r-sm" onClick={onSave} />
        <Button text="Cancel" size="small" onClick={() => setEditMode(false)} />
      </div>
    ) : (
      <p className={styles.note}>{comment.message}</p>
    )}
  </div>
);
export default CommentBody;
