import { useQuery } from '@apollo/client';

import { Button, Loader, PageContent, Table } from 'frontend/components';
import { ADMIN_GROUPS } from 'frontend/features/Admin/constants';
import { GET_ADMIN_GROUPS } from 'frontend/features/Admin/views/Groups/GET_ADMIN_GROUPS';
import { useTabTitle } from 'frontend/hooks';

const ActionCell = ({ rowData: group }) => (
  <Button to={`/admin/group/${group.id}`} size="small">
    View
  </Button>
);

const columns = [
  { key: 'id', title: 'ID' },
  { key: 'name', render: ({ rowData: group }) => ADMIN_GROUPS[group.name] },
  { key: 'actions', component: ActionCell },
];

const Groups = () => {
  useTabTitle('Groups');

  const { data, loading } = useQuery(GET_ADMIN_GROUPS, { variables: { names: Object.keys(ADMIN_GROUPS) } });
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <PageContent>
        <h2>Admin groups</h2>
        <Table data={data.allGroups ?? []} columns={columns} />
      </PageContent>
    </div>
  );
};

export default Groups;
