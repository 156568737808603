import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { updateArrayInCache } from 'frontend/api/cacheHelpers';
import {
  OrganizationUsersDetailDocument,
  RemoveOrganizationMembershipDocument,
  type UserType,
} from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { combineFunctions } from 'frontend/utils';

import { ORGANIZATION_USERS } from '../../queries';

const getMemberCacheUpdate = ({ organizationId, userId, query, cache }) =>
  updateArrayInCache({
    variables: { id: organizationId },
    pathToArrayInCache: 'organization.members',
    query,
    cache,
    arrayUpdate: (array) => array.filter(({ user }) => user.id !== userId),
  });

const getCacheUpdate = (organizationId, userId) => (cache) => {
  const update = getMemberCacheUpdate({ organizationId, userId, query: ORGANIZATION_USERS, cache });
  const updateDetailed = getMemberCacheUpdate({
    organizationId,
    userId,
    query: OrganizationUsersDetailDocument,
    cache,
  });

  return combineFunctions(update, updateDetailed);
};

type Props = {
  hide: () => void;
  args: { organizationId: string; user: UserType };
};
const RemoveOrganizationMember = ({ hide, args: { organizationId, user } }: Props) => {
  const toast = useToast();
  const [mutate] = useMutation(RemoveOrganizationMembershipDocument, {
    update: getCacheUpdate(organizationId, user.id),
  });

  // TODO: display more information about membership
  // e.g. what roles the member will lose
  const userName = user.username;

  const onSubmit = useCallback(async () => {
    await mutate({ variables: { organizationId, userId: user.id } });
    toast.success(`${userName} was removed.`);
    hide();
  }, [mutate, organizationId, user.id, toast, userName, hide]);

  return (
    <DeleteModal
      onSubmit={onSubmit}
      titleName="member"
      action="remove"
      text={`Are you sure you want to remove all permissions for ${userName} to this organization?`}
      hide={hide}
    />
  );
};

export default RemoveOrganizationMember;
