import { Field } from 'react-final-form';

import { Button, Input } from 'frontend/components';
import APIKeyModal from 'frontend/features/BotSettings/Security/components/APIKeyModal';
import { useModal } from 'frontend/features/Modals';
import { chain, required, url } from 'frontend/form/validators';

import styles from '../InboxSettings.scss';

const ExternalHandover = ({ data: { apiKey } }) => {
  const [showApiKey] = useModal(APIKeyModal);
  return (
    <div>
      <h3 className={`${styles.sectionTitle} m-b-3`}>External handover configuration</h3>
      <p className={styles.helpText}>
        Check out the{' '}
        <a className={styles.link} href="https://docs.kindly.ai/api/handover" target="_blank" rel="noopener noreferrer">
          documentation
        </a>{' '}
        to learn how to use the API key
      </p>
      <div className={`${styles.inputGroup} m-t-xl`}>
        <Field
          component={Input}
          name="webhookUrl"
          label="Webhook URL"
          placeholder="Webhook URL"
          validate={chain([required, url])}
        />
      </div>
      <div className={`${styles.buttonGroup} m-t-xl`}>
        <Button onClick={() => showApiKey({ apiKey })}>Show API key</Button>
        <p className="m-t-md m-b-0">API key must be attached to requests to Kindly to authorize them.</p>
      </div>
    </div>
  );
};

export default ExternalHandover;
