import { CHAT_SOURCE } from 'frontend/types/chat';

export const sourceData: { label: string; value: CHAT_SOURCE | { label: string; value: CHAT_SOURCE }[] }[] = [
  {
    label: 'Web',
    value: [
      {
        label: 'Desktop',
        value: CHAT_SOURCE.WEB,
      },
      {
        label: 'Mobile',
        value: CHAT_SOURCE.WEB_MOBILE,
      },
    ],
  },
  {
    label: 'Applications',
    value: [
      {
        label: 'Android',
        value: CHAT_SOURCE.SDK_ANDROID,
      },
      {
        label: 'iOS',
        value: CHAT_SOURCE.SDK_IOS,
      },
    ],
  },
  {
    label: 'Integrations',
    value: [
      {
        label: 'Email',
        value: CHAT_SOURCE.EMAIL,
      },
      {
        label: 'Custom Integration',
        value: CHAT_SOURCE.CUSTOM_INTEGRATION,
      },
      {
        label: 'Facebook',
        value: CHAT_SOURCE.FB_MESSENGER,
      },
      {
        label: 'Slack',
        value: CHAT_SOURCE.SLACK,
      },
    ],
  },
  {
    label: 'Test',
    value: CHAT_SOURCE.TEST,
  },
];
