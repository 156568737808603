import { useCallback } from 'react';

import { setLoginError, setLoginSuccess } from 'frontend/state/dux/auth';
import { useAppDispatch } from 'frontend/state/hooks';

export default () => {
  const dispatch = useAppDispatch();

  const onGoogleResponse = useCallback(
    async (googleResponse) => {
      const onGoogleError = (err) => dispatch(setLoginError(err));
      const onGoogleSuccess = (access) => dispatch(setLoginSuccess(access));
      const { error, credential } = googleResponse;
      if (error) {
        /* Ignore this event which can happen locally.
         * Ref: https://developers.google.com/identity/sign-in/web/reference#error_codes
         * */
        if (window.env?.NODE_ENV === 'development' && error !== 'idpiframe_initialization_failed') {
          onGoogleError(`Login did not complete: ${error}`);
        }
        return;
      }

      const formData = new FormData();
      formData.append('id_token', credential);

      const response = await fetch('/api/v2/auth/oauth/google/complete/', {
        method: 'POST',
        body: formData,
      });

      if (response.status === 401) {
        onGoogleError("Couldn't log you in. Contact your organization admin to see if SSO is enabled.");
      } else if (response.status === 400) {
        const { non_field_errors: errors } = await response.json();
        onGoogleError(`${errors}`);
      } else if (response.status === 200) {
        const { access } = await response.json();
        onGoogleSuccess(access);
      }
    },
    [dispatch],
  );

  return onGoogleResponse;
};
