import { gql } from '@apollo/client';

export const GET_ORGANIZATION_ORPHANS = gql`
  query GetOrganizationOrphans {
    organizationOrphans {
      id
      name
      logo
    }
  }
`;
