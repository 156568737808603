// FIXME: Why is this eslint-disable needed?
/* eslint-disable react/no-unused-prop-types */
import cx from 'classnames';
import { useCallback } from 'react';

import styles from './DropdownButton.scss';
import List from '../List/List';

interface DropdownMenuItemProps {
  close(): void;
  className?: string;
  disabled?: boolean;
  onClick?(event?: React.MouseEvent<HTMLDivElement>): void;
  separator?: boolean;
  title?: string;
  icon?: React.ReactNode;
  key: string;
}

const DropdownMenuItem = ({ className, close, disabled, onClick, separator, title, icon }: DropdownMenuItemProps) => {
  const onClickItem = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      close();
      onClick?.();
      e.stopPropagation();
      e.preventDefault();
    },
    [close, onClick],
  );

  const itemClassNames = cx(
    styles.overlayItem,
    {
      [styles.overlayItemSeparator]: separator,
      [styles.overlayItemDisabled]: disabled,
    },
    className,
  );
  return (
    <List.Item className={itemClassNames} onClick={onClickItem}>
      <span className={styles.iconContainer}>{icon}</span>
      <span className={styles.titleContainer}>{title}</span>
    </List.Item>
  );
};

interface DropdownMenuProps {
  close: DropdownMenuItemProps['close'];
  actions: Omit<DropdownMenuItemProps, 'close'>[];
  icon?: React.ReactNode;
}

const DropdownMenu = ({ actions, close }: DropdownMenuProps) => (
  <List
    className={styles.overlayContainer}
    dataSource={actions}
    renderItem={({ className, disabled, onClick, separator, title, icon, key }: DropdownMenuItemProps) => (
      <DropdownMenuItem
        className={cx(styles.overlayItem, { [styles.overlayItemSeparator]: separator }, className)}
        onClick={onClick}
        key={key}
        title={title}
        close={close}
        icon={icon}
        disabled={disabled}
      />
    )}
  />
);

export default DropdownMenu;
