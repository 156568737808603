import { useMemo } from 'react';
import type { FieldRenderProps } from 'react-final-form';
import Select from 'react-select';

import ShakeDiv from 'frontend/components/ShakeDiv';

import styles from './styles.scss';

interface MultiSelectProps extends FieldRenderProps<string> {
  /** Array of options that populate the select menu */
  options?: React.ComponentProps<Select>['options']; // this type needs to be typed better, ideally with a generic
  /** Text to display if there are no options. */
  noOptionsMessage?(): string;
  maxMenuHeight?: number;
  /** Placeholder displayed in the `<Select>` */
  placeholder?: string;
  /** Whether to focus the `<Select>` on mount. */
  autoFocus?: boolean;
}

export default function MultiSelect({
  input: { value, onChange },
  meta,
  options,
  noOptionsMessage,
  maxMenuHeight,
  placeholder,
  autoFocus,
}: MultiSelectProps) {
  const hasError = meta && meta.invalid && meta.submitFailed;

  const customStyles = useMemo(
    () => ({
      control: (base, state) => ({
        ...base,
        // eslint-disable-next-line no-nested-ternary
        border: `2px solid ${hasError ? '#fa4d1e' : state.isFocused ? '#0069ff' : 'var(--grayscale20)'}`,
        background: state.isSubmitting ? 'red' : 'white',
        boxShadow: 'none',
        cursor: 'text',
        ':hover': {
          borderColor: hasError ? '#fa4d1e' : '#0069ff',
        },
      }),
      option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        padding: '4px 8px',
        background: state.isFocused && 'var(--grayscale5)',
        ':active': {
          background: 'var(--grayscale5)',
        },
      }),
      menu: (base) => ({
        ...base,
        border: '1px solid var(--grayscale5)',
        boxShadow: '0px 1px 1px 0px rgba(0 0 0 0.1)',
      }),
      multiValue: (base) => ({
        ...base,
        margin: '0 2px',
      }),
      multiValueRemove: (base) => ({
        ...base,
        cursor: 'pointer',
      }),
      clearIndicator: (base) => ({
        ...base,
        cursor: 'pointer',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: () => ({
        display: 'none',
      }),
    }),
    [hasError],
  );

  return (
    <div className={styles.container}>
      <ShakeDiv animate={hasError ? 'error' : 'normal'}>
        <Select
          options={options}
          value={value}
          onChange={onChange}
          styles={customStyles}
          noOptionsMessage={noOptionsMessage}
          maxMenuHeight={maxMenuHeight}
          closeMenuOnSelect={false}
          defaultMenuIsOpen
          isMulti
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      </ShakeDiv>
      {hasError && <div className={styles.errorMessage}>{meta.error}</div>}
    </div>
  );
}
