import { useMutation, useQuery } from '@apollo/client';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import {
  BotLanguageDetectionSettingsDocument,
  type ChatbubbleLanguageSwitchType,
  UpdateChatbubbleLanguageSwitchDocument,
} from 'frontend/api/generated';
import { Input, LoaderSwitch, PageBar, Panel, SelectLanguage, ToggleSwitch } from 'frontend/components';
import { max } from 'frontend/form/validators';
import { useBotLanguages, useCurrentLanguage } from 'frontend/hooks';

import styles from './LanguageDetection.scss';
import LanguageDetectionPreview from './Preview/LanguageDetectionPreview';

const findCurrentLanguageSwitchSettings = (botId, languageSettings, currentLanguage: string) =>
  languageSettings?.find((v) => v.languageCode === currentLanguage) || {
    languageCode: currentLanguage,
    notification: '',
    enabled: false,
    id: `${botId}_${currentLanguage}`,
  };

const LanguageDetection = () => {
  const { botId } = useParams();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { data: languageDetection, loading: languageDetectionLoading } = useQuery(
    BotLanguageDetectionSettingsDocument,
    {
      skip: !botId,
      variables: { botId: botId! },
    },
  );
  const [updateBotLanguageDetectionSettings] = useMutation(UpdateChatbubbleLanguageSwitchDocument);

  const { languages, loading: languagesLoading } = useBotLanguages();
  const languageSwitchSettings = languageDetection?.chatbubbleSettings?.languageSwitch;

  const loading = languagesLoading || languageDetectionLoading;
  const currentSettings: ChatbubbleLanguageSwitchType = findCurrentLanguageSwitchSettings(
    botId,
    languageSwitchSettings,
    currentLanguage,
  );

  const updateBot = async (newLanguageSetting) => {
    await updateBotLanguageDetectionSettings({
      variables: {
        botId: botId!,
        languageSwitch: [
          ...(languageSwitchSettings || []).filter((v) => v?.languageCode !== currentLanguage),
          { notification: '', ...newLanguageSetting, languageCode: currentLanguage },
        ],
      },
    });
  };

  return (
    <LoaderSwitch loading={loading} size="large">
      <Form
        onSubmit={updateBot}
        initialValues={currentSettings}
        render={({ handleSubmit, form }) => {
          const { notification, enabled } = form.getState().values;
          return (
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <Panel>
                <h3 className="m-b-lg">Language detection</h3>
                {languages && languages.length > 1 && (
                  <div className={styles.selectLanguageContainer}>
                    <SelectLanguage languages={languages} supportVariants={false} />
                  </div>
                )}
                <p>
                  By enabling language detection, Kindly will change the chat language to the one detected from the
                  user&#39;s input. The bot will notify the user in a banner displayed at the top of the chat, along
                  with presenting the opportunity to switch back to the original language in chat settings.
                </p>
                <div className={styles.toggleSwitchWrapper}>
                  <ToggleSwitch
                    name="enabled"
                    initialValue={currentSettings?.enabled || false}
                    status={['Automatically switch to detected language']}
                  />
                </div>
                {enabled && (
                  <>
                    <div>
                      <div className={styles.previewTitle}>Preview of banner</div>
                      <LanguageDetectionPreview content={notification || currentSettings?.notification || ''} />
                    </div>
                    <Field
                      type="text"
                      name="notification"
                      label="Edit text"
                      initialValue={currentSettings?.notification || ''}
                      component={Input}
                      inputLimit={120}
                      validate={max(120)}
                    />
                    <p>
                      Input `[settings](#settings/language-select)` to create a quicklink to the chat settings. The text
                      into the brackets can be edited and translated.
                    </p>
                  </>
                )}
              </Panel>
            </form>
          );
        }}
      />
    </LoaderSwitch>
  );
};

export default LanguageDetection;
