import { useForm } from 'react-final-form';

import type { LanguageType } from 'frontend/api/generated';
import { useModal } from 'frontend/features/Modals';
import type { LanguageAugmented } from 'frontend/hooks/useLanguages';

import AddLanguageVariant from '../modals/AddLanguageVariant';

interface Props {
  languages: LanguageAugmented[];
  botLanguageVariants: Set<string>;
  botActiveLanguageVariants: Set<string>;
}

function useLanguageManager({ languages, botLanguageVariants, botActiveLanguageVariants }: Props) {
  const form = useForm();
  const { change, batch } = form;

  const [showAddLanguageModal] = useModal(AddLanguageVariant);

  const enableLanguage = (languageCode: string) => {
    const newLanguages = languages.map((language) => {
      if (language.code === languageCode) {
        return { ...language, active: true };
      }

      return language;
    });

    const language = languages.find((lang) => lang.code === languageCode)!; // language is guaranteed to exist
    const variantCodes = language.variants.map((variant) => variant.code);
    const firstVariant = variantCodes.find((code) => botLanguageVariants.has(code));

    batch(() => {
      change('languages', newLanguages);
      if (firstVariant) {
        const newActiveLanguageVariants = new Set(botActiveLanguageVariants);
        newActiveLanguageVariants.add(firstVariant);
        change('botActiveLanguageVariants', newActiveLanguageVariants);
      }
    });
  };

  const disableLanguage = (languageCode: string) => {
    const newActiveLanguageVariants = new Set(botActiveLanguageVariants);

    const newLanguages = languages.map((language) => {
      if (language.code === languageCode) {
        language.variants.forEach((variant) => newActiveLanguageVariants.delete(variant.code)); // when disabling language, also disable all its variants
        return { ...language, active: false };
      }

      return language;
    });

    batch(() => {
      change('languages', newLanguages);
      change('botActiveLanguageVariants', newActiveLanguageVariants);
    });
  };

  const removeLanguage = (codeToRemove: string) => {
    const newLanguageVariants = new Set(botLanguageVariants);
    const newActiveLanguageVariants = new Set(botActiveLanguageVariants);
    const newLanguages = languages.filter((language) => {
      // when removing language, also remove all its variants
      if (language.code === codeToRemove) {
        language.variants.forEach((variant) => {
          newLanguageVariants.delete(variant.code);
          newActiveLanguageVariants.delete(variant.code);
        });
      }
      return language.code !== codeToRemove;
    });

    batch(() => {
      change('languages', newLanguages);
      change('botLanguageVariants', newLanguageVariants);
      change('botActiveLanguageVariants', newActiveLanguageVariants);
    });
  };

  const addLanguageVariant = (language: LanguageType): void => {
    showAddLanguageModal({ language, form });
  };

  const enableVariant = (codeToEnable: string) => {
    const newActiveLanguageVariants = new Set(botActiveLanguageVariants);

    const language = languages.find((lang) => lang.variants.find((variant) => variant.code === codeToEnable))!; // language is guaranteed to exist
    const variantCodes = language.variants.map((variant) => variant.code);
    const isFirstActiveVariant = variantCodes.some((code) => newActiveLanguageVariants.has(code)) === false;

    newActiveLanguageVariants.add(codeToEnable);

    if (isFirstActiveVariant) {
      enableLanguage(language.code);
    }
    change('botActiveLanguageVariants', newActiveLanguageVariants);
  };

  const disableVariant = (codeToDisable: string) => {
    const newActiveLanguageVariants = new Set(botActiveLanguageVariants);
    newActiveLanguageVariants.delete(codeToDisable);

    const language = languages.find((lang) => lang.variants.find((variant) => variant.code === codeToDisable))!; // language is guaranteed to exist
    const variantCodes = language.variants.map((variant) => variant.code);
    const isLastActiveVariant = variantCodes.some((code) => newActiveLanguageVariants.has(code)) === false;

    if (isLastActiveVariant) disableLanguage(language.code);
    else change('botActiveLanguageVariants', newActiveLanguageVariants);
  };

  const removeVariant = (codeToRemove: string) => {
    const newLanguageVariants = new Set(botLanguageVariants);
    const newActiveLanguageVariants = new Set(botActiveLanguageVariants);
    newLanguageVariants.delete(codeToRemove);
    newActiveLanguageVariants.delete(codeToRemove);

    const language = languages.find((lang) => lang.variants.find((variant) => variant.code === codeToRemove))!; // language is guaranteed to exist
    const variantCodes = language.variants.map((variant) => variant.code);
    const isLastVariant = variantCodes.some((code) => newLanguageVariants.has(code)) === false;

    if (isLastVariant) removeLanguage(language.code);
    else {
      batch(() => {
        change('botLanguageVariants', newLanguageVariants);
        change('botActiveLanguageVariants', newActiveLanguageVariants);
      });
    }
  };

  const makePrimaryLanguage = (codeToEnable: string) => {
    batch(() => {
      change('primaryLanguage', codeToEnable);
    });
  };

  return {
    enableLanguage,
    disableLanguage,
    removeLanguage,
    addLanguageVariant,
    enableVariant,
    disableVariant,
    removeVariant,
    makePrimaryLanguage,
  };
}

export default useLanguageManager;
