import cx from 'classnames';

import styles from './EntityItemList.scss';
import EntityItemTag from '../EntityItemTag';

interface EntityItemListProps {
  items: string[];
  entityId: string;
  maxItems?: number;
  hue?: number;
  className?: string;
  itemsExpanded?: boolean;
  itemsOverflowSymbol?: boolean | string;
  children?: React.ReactNode;
}

const EntityItemList = ({
  items,
  entityId,
  maxItems,
  hue,
  className,
  itemsExpanded = false,
  itemsOverflowSymbol = '. . .',
  children = null,
}: EntityItemListProps) => {
  const canShowAll = !maxItems || items.length <= maxItems;
  const numberShown = itemsExpanded || canShowAll ? undefined : maxItems - 1;

  return (
    <div className={cx(styles.itemList, className)}>
      {items.slice(0, numberShown).map((item) => (
        <EntityItemTag className={styles.itemTag} key={`entity-${entityId}-${item}`} item={item} hue={hue} />
      ))}
      {!canShowAll && Boolean(children || itemsOverflowSymbol) && (
        <div className={cx(styles.itemTag, styles.ellipsis)}>{children || itemsOverflowSymbol}</div>
      )}
      <span className={styles.filler} />
    </div>
  );
};

export default EntityItemList;
