/** Modify the `arr`ay in place by moving an item from its `oldIndex` to the `newIndex`. */
export default function moveArrayItem(arr: unknown[], oldIndex: number, newIndex: number): void {
  if (arr.length === 0 || oldIndex === newIndex || oldIndex < 0 || oldIndex >= arr.length) {
    return;
  }

  if (newIndex >= arr.length) {
    newIndex = arr.length - 1;
  } else if (newIndex < 0) {
    newIndex = 0;
  }

  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}
