import React, { type ComponentType, type SVGProps } from 'react';
import { Field, useForm } from 'react-final-form';

import { Calendar, Connect, Timezone, Trash } from 'frontend/assets/icons';
import { Icon, Input, Radio, Select } from 'frontend/components';
import { chain, required } from 'frontend/form/validators';
import noEmpty from 'frontend/form/validators/noEmpty';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './ConditionForm.scss';
import WeekdaySelector from '../WeekdaySelector';

export type ConditionCategory = 'DATE' | 'TIME' | 'CONTEXT';

export const formBuild: {
  [key in ConditionCategory]: {
    head: {
      label: string;
      icon: ComponentType<SVGProps<SVGSVGElement>>;
    };
    inputs: Array<{
      key: string;
      name: string;
      inputType: 'select' | 'input' | 'date' | 'time' | 'text' | 'radio' | 'weekdays';
      options?: { label: string; value: string }[];
      conditionId?: string;
      props?: { [key: string]: string | boolean };
      calculatedProps?: (formState: Record<string, any>) => { [key: string]: string | boolean };
      prefix?: string;
      default?: string;
      condition?: {
        conditionId: string;
        value: string[];
      };
    }>;
  };
} = {
  DATE: {
    head: {
      label: 'Date',
      icon: Calendar,
    },
    inputs: [
      {
        key: randomUUID(),
        inputType: 'select',
        conditionId: 'operator',
        name: 'operator',
        options: [
          {
            label: 'Is',
            value: 'IS',
          },
          {
            label: 'Is not',
            value: 'IS_NOT',
          },
          {
            label: 'Is before',
            value: 'IS_BEFORE',
          },
          {
            label: 'Is after',
            value: 'IS_AFTER',
          },
          {
            label: 'Is between',
            value: 'IS_BETWEEN',
          },
          {
            label: 'Is not between',
            value: 'IS_NOT_BETWEEN',
          },
        ],
      },
      {
        key: randomUUID(),
        inputType: 'date',
        name: 'at',
        condition: {
          conditionId: 'operator',
          value: ['IS', 'IS_NOT', 'IS_BEFORE', 'IS_AFTER'],
        },
        props: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          min: new Date().toISOString().split('T')[0]!,
          required: true,
        },
      },
      {
        key: randomUUID(),
        inputType: 'date',
        name: 'start',
        condition: {
          conditionId: 'operator',
          value: ['IS_BETWEEN', 'IS_NOT_BETWEEN'],
        },
        props: {
          required: true,
        },
        calculatedProps: (formValues: Record<string, any>) => ({
          max: formValues.end,
        }),
      },
      {
        key: randomUUID(),
        prefix: '&',
        inputType: 'date',
        name: 'end',
        condition: {
          conditionId: 'operator',
          value: ['IS_BETWEEN', 'IS_NOT_BETWEEN'],
        },
        props: {
          required: true,
        },
        calculatedProps: (formValues: Record<string, any>) => ({
          min: formValues.start,
        }),
      },
    ],
  },
  TIME: {
    head: {
      label: 'Time',
      icon: Timezone,
    },
    inputs: [
      {
        key: randomUUID(),
        inputType: 'select',
        conditionId: 'operator',
        name: 'operator',
        options: [
          {
            label: 'Is before',
            value: 'IS_BEFORE',
          },
          {
            label: 'Is after',
            value: 'IS_AFTER',
          },
          {
            label: 'Is between',
            value: 'IS_BETWEEN',
          },
          {
            label: 'Is not between',
            value: 'IS_NOT_BETWEEN',
          },
          {
            label: 'When Handover is',
            value: 'WHEN_HANDOVER_IS',
          },
          {
            label: 'For weekday',
            value: 'IS_WEEKDAY',
          },
        ],
      },
      {
        key: randomUUID(),
        inputType: 'time',
        name: 'at',
        condition: {
          conditionId: 'operator',
          value: ['IS_BEFORE', 'IS_AFTER'],
        },
        props: {
          required: true,
        },
      },
      {
        key: randomUUID(),
        inputType: 'time',
        name: 'start',
        condition: {
          conditionId: 'operator',
          value: ['IS_BETWEEN', 'IS_NOT_BETWEEN'],
        },
        props: {
          required: true,
        },
      },
      {
        key: randomUUID(),
        inputType: 'weekdays',
        name: 'weekdays',
        condition: {
          conditionId: 'operator',
          value: ['IS_WEEKDAY'],
        },
        props: {
          required: true,
        },
      },
      {
        key: randomUUID(),
        prefix: '-',
        inputType: 'time',
        name: 'end',
        condition: {
          conditionId: 'operator',
          value: ['IS_BETWEEN', 'IS_NOT_BETWEEN'],
        },
        props: {
          required: true,
        },
      },
      {
        key: randomUUID(),
        inputType: 'radio',
        name: 'handoverStatus',
        condition: {
          conditionId: 'operator',
          value: ['WHEN_HANDOVER_IS'],
        },
        props: {
          required: true,
          layout: 'inline',
        },
        options: [
          {
            label: 'Open',
            value: 'OPEN',
          },
          {
            label: 'Closed',
            value: 'CLOSED',
          },
        ],
      },
    ],
  },
  CONTEXT: {
    head: {
      label: 'Context',
      icon: Connect,
    },
    inputs: [
      {
        key: randomUUID(),
        inputType: 'text',
        name: 'contextKey',
        props: {
          placeholder: 'e.g. user_email',
          required: true,
          noEmpty: true,
        },
      },
      {
        key: randomUUID(),
        inputType: 'select',
        conditionId: 'operator',
        name: 'operator',
        options: [
          {
            label: 'Exists',
            value: 'EXISTS',
          },
          {
            label: 'Does not exist',
            value: 'NOT_EXISTS',
          },
          {
            label: 'Equals',
            value: 'EQUALS',
          },
          {
            label: 'Contains',
            value: 'CONTAINS',
          },
        ],
      },
      {
        key: randomUUID(),
        inputType: 'text',
        name: 'contextValue',
        condition: {
          conditionId: 'operator',
          value: ['EQUALS', 'CONTAINS'],
        },
        props: {
          required: true,
        },
      },
    ],
  },
};

const FormTypeHead = ({
  label,
  IconElement,
  deleteCondition,
}: {
  label: string;
  IconElement: ComponentType<SVGProps<SVGSVGElement>>;
  deleteCondition: () => void;
}) => (
  <div className={styles.formTypeHeadContainer}>
    <Icon component={IconElement} />
    <span>{label}</span>
    <div className={styles.trashIcon}>
      <Icon component={Trash} onClick={deleteCondition} />
    </div>
  </div>
);

export const ConditionForm = ({
  partialSubmitFailed,
  ruleIndex,
  conditionIndex,
  condition,
}: {
  partialSubmitFailed: boolean;
  ruleIndex: number;
  conditionIndex: number;
  condition: Record<string, any>;
}) => {
  const { change, batch, getState } = useForm();

  const currentConditionCategory = condition?.category;

  const inputs = formBuild[currentConditionCategory]?.inputs || [];
  const head = formBuild[currentConditionCategory]?.head || [];

  const conditions = inputs.reduce(
    (acc, input) =>
      input.conditionId ? { ...acc, [input.conditionId]: condition[input.name] || input.options?.[0].value } : acc,
    {},
  );

  const onChangeSelect = (input) => {
    const conditionId = input.conditionId;
    if (conditionId) {
      // Reset conditional forms
      const conditionalDependantInputs = inputs.filter(
        (formInput) =>
          formInput.condition?.conditionId === conditionId &&
          formInput.condition?.value.includes(conditions[conditionId]),
      );
      if (conditionalDependantInputs.length) {
        batch(() => {
          conditionalDependantInputs.forEach((conditionalInput) => {
            change(conditionalInput.name, undefined);
          });
        });
      }
    }
  };

  const deleteCondition = (conditionId) => {
    const currentConditions = getState().values.dialogueRules[ruleIndex].conditions;
    const newConditions = currentConditions.filter((cond) => cond.id !== conditionId);

    change(`dialogueRules[${ruleIndex}].conditions`, newConditions);
  };

  const getInputName = (name: string) => `dialogueRules[${ruleIndex}].conditions[${conditionIndex}].${name}`;

  return (
    <div className={styles.form}>
      <FormTypeHead label={head.label} IconElement={head.icon} deleteCondition={() => deleteCondition(condition.id)} />
      <div className={styles.formInputs}>
        {inputs.map((input) => {
          if (input.condition && !input.condition.value.includes(conditions[input.condition.conditionId])) {
            return null;
          }

          if (input.inputType === 'select') {
            return (
              <div className={styles.selectContainer} key={input.key}>
                <Field
                  name={getInputName(input.name)}
                  defaultValue={condition[input.name] || input.options?.[0].value || undefined}
                  render={(props) => (
                    <Select
                      input={{
                        ...props.input,
                        ...input.props,
                        ...input.calculatedProps?.(condition),
                        onChange: (event) => {
                          onChangeSelect(input);
                          props.input.onChange(event);
                        },
                      }}
                      meta={{ ...props.meta }}
                      className={styles.inputWrapper}
                      data-testid={input.name}
                    >
                      {(input.options || []).map(({ label, value: inputValue }) => (
                        <Select.Option key={inputValue} value={inputValue} label={label} />
                      ))}
                    </Select>
                  )}
                />
              </div>
            );
          }

          if (input.inputType === 'time' || input.inputType === 'text' || input.inputType === 'date') {
            return (
              <React.Fragment key={input.key}>
                {input.prefix && <span className={styles.prefix}>{input.prefix}</span>}
                <Field
                  defaultValue={condition[input.name] || input.default || undefined}
                  name={getInputName(input.name)}
                  validate={chain([
                    input.props?.required ? required : undefined,
                    input.props?.noEmpty ? noEmpty : undefined,
                  ])}
                  component={Input}
                  forceValidate={partialSubmitFailed}
                  inputType={input.inputType}
                  className={styles.inputWrapper}
                  data-testid={input.name}
                  {...input.props}
                  {...input.calculatedProps?.(condition)}
                />
              </React.Fragment>
            );
          }

          if (input.inputType === 'radio') {
            return (
              <div className={styles.radioContainer} key={input.key}>
                {input.options.map((option) => (
                  <Field
                    key={option.value}
                    name={getInputName(input.name)}
                    className={styles.inputRadio}
                    type="radio"
                    component={Radio}
                    defaultValue={condition[input.name] || input.default || undefined}
                    validate={required}
                    label={option.label}
                    value={option.value}
                    {...input.props}
                    {...input.calculatedProps?.(condition)}
                  />
                ))}
              </div>
            );
          }

          if (input.inputType === 'weekdays') {
            return (
              <WeekdaySelector
                key={input.key}
                fieldName={getInputName(input.name)}
                partialSubmitFailed={partialSubmitFailed}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};
