import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  type BotMemberType,
  BotMembersDocument,
  BotMetaDocument,
  CreateBotMembershipDocument,
} from 'frontend/api/generated';
import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { MEMBERSHIP_TYPES } from 'frontend/features/Organization/constants';
import { RemoveOwnMembership, UpdateBotMember } from 'frontend/features/Organization/modals';
import { UserType } from 'frontend/features/Organization/propTypes';

import styles from './ManagePermissions.scss';

const ManagePermissions = ({ me }) => {
  const { botId } = useParams();
  const { data: botMetaData } = useQuery(BotMetaDocument, { variables: { id: botId! } });
  const { data: botMemberships } = useQuery(BotMembersDocument, { variables: { id: botId! } });
  const organizationId = botMetaData?.bot?.organization?.id;
  const [showUpdateBotMemberModal] = useModal(UpdateBotMember);
  const [showRemoveOwnMemberModal] = useModal(RemoveOwnMembership);
  const alreadyMember = Boolean(
    ((botMemberships?.bot?.members ?? []) as BotMemberType[]).find(({ user }) =>
      Boolean(user && String(me.id) === String(user.id)),
    ),
  );

  const [createMembership] = useMutation(CreateBotMembershipDocument);

  const onBeforeSubmit = useCallback(async () => {
    if (!alreadyMember) {
      await createMembership({ variables: { botId: botId!, userId: me.id, roleIds: [] } });
    }
  }, [alreadyMember, me, botId, createMembership]);

  const onManagePermissions = useCallback(
    async () => showUpdateBotMemberModal({ botId, organizationId, user: me, onBeforeSubmit }),
    [botId, me, organizationId, showUpdateBotMemberModal, onBeforeSubmit],
  );

  const onRemoveMembership = useCallback(
    () => showRemoveOwnMemberModal({ membershipType: MEMBERSHIP_TYPES.WORKSPACE, botId, organizationId }),
    [botId, organizationId, showRemoveOwnMemberModal],
  );

  return (
    <div className={styles.adminOptions}>
      <Button className="kindlyAdminManager p-l-0 p-r-0 p-b-0" onClick={onManagePermissions} flat>
        Manage Permissions
      </Button>
      {alreadyMember && (
        <Button className="kindlyAdminManager p-l-0 p-r-0 p-b-0" onClick={onRemoveMembership} flat>
          Remove my workspace membership
        </Button>
      )}
    </div>
  );
};

ManagePermissions.propTypes = {
  me: UserType.isRequired,
};

export default ManagePermissions;
