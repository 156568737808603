export enum CHAT_SOURCE {
  CUSTOM_INTEGRATION = 'app',
  EMAIL = 'email',
  FB_MESSENGER = 'facebook',
  SDK_ANDROID = 'android',
  SDK_IOS = 'ios',
  SLACK = 'slack',
  TEST = 'test',
  WEB = 'web',
  WEB_MOBILE = 'mobile',
}
