import { gql } from '@apollo/client';

export default gql`
  fragment Entity on EntityType {
    id
    name
    items
    hue
    updatedAt
    languageCode
  }
`;
