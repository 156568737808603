import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Loader } from 'frontend/components';
import { Pusher, getPusherChannel } from 'frontend/features/Pusher';
import useMe from 'frontend/hooks/useMe';

const Inserting = ({ setIsFinished }) => {
  const onUpdate = useCallback(() => setIsFinished(true), [setIsFinished]);
  const { data, loading } = useMe();

  return (
    <>
      <Loader size="large" noMargin />
      <br />
      <br />
      <h3>Inserting Kindly entities...</h3>
      {!loading && data?.me && (
        <Pusher
          channel={getPusherChannel({ userId: data.me.id })}
          event="kindly-entities-inserted"
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

Inserting.propTypes = {
  setIsFinished: PropTypes.func.isRequired,
};

export default Inserting;
