import type { DialogueCandidateType } from 'frontend/api/generated';

import styles from './CreatedDialogue.scss';
import { DEFAULT_DIALOGUE_LABEL_COLOR, DIALOGUE_LABEL_COLORS } from '../../../../constants';

const CreatedDialogue = ({
  rowData,
  botId,
  language,
}: {
  rowData: Partial<DialogueCandidateType>;
  botId: string;
  language: string;
}) => {
  const dialogue = rowData.createdDialogue;
  const color = DIALOGUE_LABEL_COLORS.get(dialogue?.colorLabel?.[language]) ?? DEFAULT_DIALOGUE_LABEL_COLOR;
  if (!dialogue) return null;
  return (
    <a className={styles.createdDialogue} href={`/workspace/${botId}/build/dialogue/${dialogue.id}`}>
      <div style={{ backgroundColor: color }} className={styles.icon} />
      <div className={styles.title}>{dialogue.title[language]}</div>
    </a>
  );
};

export default CreatedDialogue;
