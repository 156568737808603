import { isNil, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Checkbox, LoaderSwitch } from 'frontend/components';
import { SampleType } from 'frontend/features/Samples/propTypes';

import styles from './EntitySearchAndReplace.scss';
import { orderByLegalFirst, sampleIsLegal } from './helpers';
import { SampleWithEntities } from '../../components';

const PreviewForm = ({ updatedSamples, replaceText }) => (
  <LoaderSwitch loading={isNil(updatedSamples)} size="medium">
    Choose the samples that you want to change and click replace:
    <br />
    <br />
    <br />
    {updatedSamples.length === 0 && (
      <div className={styles.noHits}>No sample matched the search &quot;{replaceText}&quot;</div>
    )}
    {updatedSamples.length > 0 && (
      <div className={styles.updatedSamplesList}>
        {orderBy(updatedSamples, orderByLegalFirst).map((sample) => (
          <div key={`updated-sample-${sample.id || sample.tempId}`} className={styles.updatedSample}>
            <Field
              name={`includedSamples.${sample.id || sample.tempId}`}
              disabled={!sampleIsLegal(sample)}
              component={Checkbox}
              className={styles.checkbox}
              type="checkbox"
              label={<SampleWithEntities text={sample.text} instances={sample.instances} />}
            />
          </div>
        ))}
      </div>
    )}
  </LoaderSwitch>
);

PreviewForm.propTypes = {
  replaceText: PropTypes.string.isRequired,
  updatedSamples: PropTypes.arrayOf(SampleType).isRequired,
};

export default PreviewForm;
