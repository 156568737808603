import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteFromArrayInCache } from 'frontend/api/cacheHelpers';
import { DeleteNudgeDocument, GetAllNudgesDocument } from 'frontend/api/generated';
import { LoaderSwitch } from 'frontend/components';
import { ProactivityItem } from 'frontend/features/Library/components';
import { useNudges, useUpdateNudges } from 'frontend/features/NudgeEditor/hooks';
import { useCurrentLanguage } from 'frontend/hooks';

const NudgesList = () => {
  const { onUpdate } = useUpdateNudges();
  const { loadingNudges, nudges, botId } = useNudges();
  const navigate = useNavigate();
  const [{ currentLanguage }] = useCurrentLanguage();

  const update = async (cache, { data }: { data?: any }) => {
    await deleteFromArrayInCache({
      variables: { botId },
      pathToItemInMutationData: 'deleteNudge',
      pathToArrayInCache: 'allNudges',
      query: GetAllNudgesDocument,
    })(cache, { data });

    const { deleteNudge } = data;
    if (!window.location.pathname.endsWith(deleteNudge)) {
      const { allNudges } = cache.readQuery({
        query: GetAllNudgesDocument,
        variables: {
          botId,
        },
      });
      navigate(`/workspace/${botId}/build/proactivity/nudge${allNudges.length > 0 ? `/${allNudges[0].slug}` : ''}`);
    }
  };

  const [deleteNudge] = useMutation(DeleteNudgeDocument, { update });

  return (
    <LoaderSwitch loading={loadingNudges} size="large">
      {nudges.map((nudge) => (
        <ProactivityItem
          show="nudge"
          key={`nudge-${nudge.id}`}
          item={nudge}
          onClick={async (action) => {
            if (action === 'delete') {
              const { slug, type } = nudge;
              const variables = {
                botId: botId as string,
                slug: slug as string,
                nudgeType: type as string,
              };

              await deleteNudge({ variables });
            }

            if (action === 'publish') {
              await onUpdate(
                {
                  ...nudge,
                  enabled: {
                    ...nudge.enabled,
                    [currentLanguage]: !nudge.enabled[currentLanguage],
                  },
                },
                nudge,
              );
            }
          }}
        />
      ))}
    </LoaderSwitch>
  );
};

export default NudgesList;
