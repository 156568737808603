import PropTypes from 'prop-types';

import Section from './Section';
import styles from '../shared.scss';

const Button = ({ text }) => (
  <Section>
    <div className={styles.nudgeButton}>{text}</div>
  </Section>
);

Button.propTypes = {
  text: PropTypes.string,
};

export default Button;
