import { gql } from '@apollo/client';

import { zodiacModelFragment } from 'frontend/api/fragments';

export const TRAIN_ZODIAC_MODEL = gql`
  mutation TrainZodiacModel($botId: ID!, $languageCode: String!) {
    trainZodiacModel(botId: $botId, languageCode: $languageCode) {
      ...ZodiacModel
    }
  }
  ${zodiacModelFragment}
`;
