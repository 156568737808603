import { useApolloClient, useMutation } from '@apollo/client';
import cx from 'classnames';
import { get } from 'lodash/fp';
import { useParams } from 'react-router-dom';

import { ChevronUp, Labels } from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';
import LabelsSection from 'frontend/features/Labels/LabelsSection';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import type { Label } from 'frontend/propTypes/LabelType';

import styles from './LabelsController.scss';
import { useChatLabelArguments } from './hooks';
import { ACTIVATE_CHAT_LABEL, DEACTIVATE_CHAT_LABEL } from './mutations';
import sharedStyles from '../../metadata-styles.scss';

interface Props {
  botLabels: Label[];
}

const LabelsController = ({ botLabels }: Props) => {
  const { botId, chatId } = useParams();
  const { cache } = useApolloClient();

  const [activateLabel] = useMutation(ACTIVATE_CHAT_LABEL);
  const [deactivateLabel] = useMutation(DEACTIVATE_CHAT_LABEL);
  const chatLabelArguments = useChatLabelArguments();

  const { hasBotPerms, loading: permissionsLoading } = useMyPermissions({ botId });
  const canEditLabels = hasBotPerms('manage_bot');

  const activeLabelsIds = botLabels.filter((label) => label.active).map((label) => label.id);

  const handleLabelToggle = (label: Label, status: boolean) => {
    let newLabelList: Label[];

    if (status) {
      newLabelList = botLabels.filter(({ active, id }) => active || id === label.id);
      const args = chatLabelArguments({ chatId, label, labels: newLabelList, type: 'activateChatLabel' });
      activateLabel(args);
    } else {
      newLabelList = botLabels.filter(({ active, id }) => active && id !== label.id);
      const args = chatLabelArguments({ chatId, label, labels: newLabelList, type: 'deactivateChatLabel' });
      deactivateLabel(args);
    }

    cache.modify({
      id: `ChatType:${chatId}`,
      fields: { labelIds: () => newLabelList.map(get('id')) },
    });
  };

  return (
    <Dropdown
      wrapperClassName={cx(sharedStyles.dropdownWrapper, styles.wrapper)}
      triggerClassName={sharedStyles.dropdownTrigger}
      overlayClassName={cx(sharedStyles.dropdownOverlay)}
      overlayMaxWidth="parent"
      overlay={
        <LabelsSection
          activeLabelsIds={activeLabelsIds}
          botLabels={botLabels}
          canEditLabels={canEditLabels}
          className={styles.labelsSection}
          handleLabelToggle={handleLabelToggle}
          inputAutofocus
          loading={permissionsLoading}
        />
      }
      position="top"
      ignoreOutsideClick
    >
      <div className={sharedStyles.iconLeftAndTextWrapper}>
        <Icon component={Labels} color="black" />
        <span className={sharedStyles.buttonText}>Add label</span>
      </div>
      <Icon className={sharedStyles.iconChevron} component={ChevronUp} color="black" />
    </Dropdown>
  );
};

export default LabelsController;
