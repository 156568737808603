import { useQuery } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { type CustomNudgeType, GetAllNudgeSlugsDocument } from 'frontend/api/generated';
import { Button, FormErrors, Input, Panel, ToggleSwitch } from 'frontend/components';
import { chain, max, required } from 'frontend/form/validators';
import { useIsRunning } from 'frontend/hooks';
import { finalFormArraysMutatorsFix } from 'frontend/utils';

import styles from './styles.scss';
import { nudgeDefaultTexts } from '../../constants';
import { useNudges, useSlugValidator, useUpdateNudges } from '../../hooks';
import { Header } from '../Header';
import { Preview } from '../Preview';
import sharedStyles from '../shared.scss';

interface CustomNudgeProps {
  nudge?: CustomNudgeType;
}

const CustomNudge = ({ nudge }: CustomNudgeProps) => {
  const editing = !!nudge;
  const { onCustomUpdate } = useUpdateNudges();
  const { botId, onCustomCreate, currentLanguage } = useNudges();
  const [onSubmit] = useIsRunning(editing ? onCustomUpdate : onCustomCreate);
  const { data } = useQuery(GetAllNudgeSlugsDocument, { variables: { botId: botId as string } });
  const { validateSlug } = useSlugValidator({ data, nudge });

  return (
    <Panel className={sharedStyles.formPanel}>
      <Form
        mutators={finalFormArraysMutatorsFix(arrayMutators)}
        onSubmit={onSubmit}
        initialValues={nudge || {}}
        render={({
          handleSubmit,
          values,
          form: {
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit} className={sharedStyles.container}>
            <Header
              title={values?.title?.[currentLanguage] ?? nudgeDefaultTexts.title}
              slug={values?.slug ?? nudgeDefaultTexts.slug}
            />
            <Preview>
              <Preview.Intent />
              <Preview.Nudge nudge={values} />
            </Preview>
            <div className={sharedStyles.containerEditor}>
              <div className={sharedStyles.panelHeader}>
                <h3>Custom Nudge</h3>
                <p>Create text fields to be used in a customized nudge</p>
              </div>
              <FormErrors className={sharedStyles.formErrors} />
              <div className={sharedStyles.inputContainer}>
                <Field
                  name="slug"
                  label="Slug (this can not be edited later)"
                  component={Input}
                  readOnly={!!nudge}
                  inputLimit={50}
                  validate={chain([required, validateSlug])}
                  placeholder="Choose a slug for your nudge"
                />
              </div>
              <div className={sharedStyles.seperator} />
              <h5>Text Content</h5>
              {editing ? (
                <FieldArray name="textBlocks">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={name} className={styles.textBlock}>
                        <div>
                          <Field
                            name={`${name}.value.${currentLanguage}`}
                            component={Input}
                            label="Text value"
                            placeholder="Your text"
                            inputLimit={255}
                            validate={chain([required, max(255)])}
                          />
                        </div>
                        <div>
                          <Field
                            name={`${name}.slug`}
                            readOnly={Boolean(values?.textBlocks[index]?.id)}
                            component={Input}
                            label="Slug"
                            inputLimit={140}
                            placeholder="Unique slug"
                            validate={chain([required, max(140)])}
                          />
                        </div>
                        <div>
                          <Button onClick={() => fields.remove(index)} color="warning" text="Remove" />
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              ) : (
                <div>Hit save to create text content</div>
              )}
              {editing && <Button text="Add text field" className="m-t-3" onClick={() => push?.('textBlocks')} />}
              <div className={styles.seperator} />
              <h5>Analytics Setup</h5>
              <p className={styles.helpText}>Setup URL for completed purchase</p>
              <div className={`m-b-3 ${styles.inputContainer}`}>
                <Field
                  component={Input}
                  name="analyticsCompletionPagePath"
                  aria-label="Completion Page URL"
                  label="Completion Page URL"
                  placeholder="/order/"
                />
              </div>
              <div className={sharedStyles.seperator} />
              <h5>Toggle nudge</h5>
              <div className={`m-b-1 ${sharedStyles.inputContainer}`}>
                <span className={sharedStyles.helpText}>You can toggle this nudge for the selected language</span>
                <div className={sharedStyles.toggleContainer}>
                  <ToggleSwitch name={`enabled.${currentLanguage}`} />
                  <label htmlFor="ToggleSwitch-enabled">
                    {values?.enabled?.[currentLanguage] ? 'Enabled' : 'Disabled'}
                  </label>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </Panel>
  );
};

export default CustomNudge;
