import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { LoaderSwitch, PageContent } from 'frontend/components';
import BotList from 'frontend/features/LegacyBotList';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useBotOrSkill, useTabTitle } from 'frontend/hooks';

import { NoSkillSubscribers } from './components';
import { SKILL_SUBSCRIBERS } from './queries';

const SkillSubscribers = () => {
  const { skillId } = useBotOrSkill();
  const { data, loading, refetch } = useQuery(SKILL_SUBSCRIBERS, { variables: { skillId } });
  const bots = useMemo(() => (data?.skill?.subscriptions ?? []).map(({ bot }) => bot), [data]);

  useTabTitle('Subscribers');

  usePusherEvent(getPusherChannel({ skillId }), 'skill-subscribers-updated', refetch);

  if (!loading && bots.length === 0) return <NoSkillSubscribers />;

  return (
    <PageContent>
      <LoaderSwitch loading={loading} size="large">
        <BotList bots={bots} />
      </LoaderSwitch>
    </PageContent>
  );
};

export default SkillSubscribers;
