import { useApolloClient, useQuery } from '@apollo/client';
import cx from 'classnames';
import { uniqBy } from 'lodash/fp';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';

import Loader from 'frontend/components/Loader';
import { NavigationButton } from 'frontend/features/Inbox/components';
import { ALL_CONVERSATIONS_VIEW } from 'frontend/features/Inbox/constants';
import { useChatUpdated, useLabels, useTicketUpdated } from 'frontend/features/Inbox/hooks';
import sharedStyles from 'frontend/features/Inbox/inbox-styles.scss';
import { CHATS_AND_TICKETS } from 'frontend/features/Inbox/queries';
import { dateFilter, getChatPath, viewFromFilters } from 'frontend/features/Inbox/utils';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { pluralize } from 'frontend/utils';

import styles from './ConversationList.scss';
import { ChatPreview, EmptyState } from './components';
import {
  useAddChats,
  useChatDeleted,
  useCommentsUpdates,
  useFetchMoreChats,
  useHandoverTabNotification,
  useOnChatNotInList,
  usePusherConnectionFailed,
  useRedirect,
  useShowNewChats,
} from './hooks';
import { convertQueryParamsToPersistedValues } from '../../components/InboxFilters/utils/helpers';

const EMPTY_ARRAY = [];
const EMPTY_CHATS_AND_TICKETS = { chats: [], tickets: [] };

const ConversationList = () => {
  const [hasMoreChats, setHasMoreChats] = useState(true);

  const { botId, organizationId, chatId } = useParams();
  const { pathname, search } = useLocation();
  const { cache, query } = useApolloClient();
  const navigate = useNavigate();
  const labels = useLabels({ hideBotName: true });

  const filters = convertQueryParamsToPersistedValues(search);

  const [newerChatsAndTickets, setNewerChatsAndTickets] = useState(EMPTY_CHATS_AND_TICKETS);
  const newChatsCount = newerChatsAndTickets.chats.length;

  const reset = () => {
    setHasMoreChats(true);
    setNewerChatsAndTickets(EMPTY_CHATS_AND_TICKETS);
  };

  const { data, loading: areChatsLoading } = useQuery(CHATS_AND_TICKETS, {
    variables: { botId, organizationId, ...filters, ...dateFilter(filters) },
    fetchPolicy: 'network-only',
    onCompleted: reset,
  });

  const chatsAndTickets = data?.chatsAndTickets;
  const chats = chatsAndTickets?.chats ?? EMPTY_ARRAY;
  const tickets = chatsAndTickets?.tickets ?? EMPTY_ARRAY;

  const onChatNotInList = useOnChatNotInList({
    cache,
    setNewerChatsAndTickets,
    updateListDirectly: viewFromFilters(filters) !== ALL_CONVERSATIONS_VIEW,
  });

  const addChats = useAddChats({ cache, chatsAndTickets });
  const { onChatCommentAdded, onChatCommentDeleted } = useCommentsUpdates();
  const onChatUpdated = useChatUpdated({ cache, filters, chatsAndTickets, query, onChatNotInList });
  const onTicketUpdated = useTicketUpdated({ botId, organizationId, filters, chatsAndTickets, cache, onChatNotInList });
  const { showNewChats, chatDividers } = useShowNewChats({ addChats, newerChatsAndTickets, setNewerChatsAndTickets });
  const fetchMoreChats = useFetchMoreChats({
    query,
    botId,
    organizationId,
    filters,
    chats,
    addChats,
    setHasMoreChats,
  });
  const onChatDeleted = useChatDeleted({ botId, organizationId, chatsAndTickets, cache, chatId });
  const handleVisibilitySensorChange = (isVisible) => {
    if (!isVisible) return;

    if (chats.length > 0) fetchMoreChats();
    else setHasMoreChats(false);
  };

  useRedirect({ botId, organizationId, chatId, chats, areChatsLoading });
  useHandoverTabNotification(chats);

  const pusherChannel = getPusherChannel({ botId, organizationId, isInbox: true });
  usePusherEvent(pusherChannel, 'chat-updated', onChatUpdated);
  usePusherEvent(pusherChannel, 'ticket-updated', onTicketUpdated);
  usePusherEvent(pusherChannel, 'chat-comment-added', onChatCommentAdded);
  usePusherEvent(pusherChannel, 'chat-comment-deleted', onChatCommentDeleted);
  usePusherEvent(pusherChannel, 'chat-deleted', onChatDeleted);

  usePusherConnectionFailed();

  const uniqueChats = useMemo(() => uniqBy('id', chats), [chats]); // Precaution

  const onClickBack = useCallback(() => navigate(`${pathname}?screen=views`), [pathname, navigate]);

  if (areChatsLoading) {
    return (
      <div>
        <Loader size="large" type="inbox_chats" />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={cx(sharedStyles.navigationButtons, sharedStyles.navigationButtonsMobileOnly)}>
        <NavigationButton onClick={onClickBack} text="Back" />
      </div>

      {newChatsCount > 0 && (
        <button className={styles.unlistedChats} onClick={showNewChats} type="button" data-cy="button-new-updates">
          Show {newChatsCount} new {pluralize('update', newChatsCount)}
        </button>
      )}

      {uniqueChats.length === 0 && <EmptyState />}

      {uniqueChats.map((chat, index) => (
        <div key={`chat-preview-${chat.id}`}>
          {chatDividers.includes(index) && <hr className={styles.showNewChatsDivider} />}
          <ChatPreview
            id={chat.id}
            botId={chat.botId}
            updated={chat.updated}
            avatarUrl={chat.avatarUrl}
            autoUser={chat.autoUser}
            commentsCount={chat.commentsCount}
            fullName={chat.fullName}
            subject={chat.subject}
            handedOver={chat.takenOver}
            handoverByAgentId={chat.takeoverByAgentId}
            hasRequestedHandover={chat.hasRequestedTakeover}
            completedHandover={chat.completedTakeover}
            contactDetails={chat.contactDetails}
            requestedHandoverAt={chat.requestedHandoverAt}
            lastMessageIsFromUser={chat.lastMessageIsFromUser}
            to={{ pathname: getChatPath(`chat/${chat.id}`, { botId, organizationId }), search }}
            replaceHistory={!chatId}
            preview={chat?.preview || ''}
            botLabels={labels}
            labelIds={chat.labelIds}
            tickets={tickets}
          />
        </div>
      ))}

      {hasMoreChats && (
        <VisibilitySensor onChange={handleVisibilitySensorChange}>
          {({ isVisible }) => (
            <div className="m-t-lg m-b-lg text-center">
              <Loader animate={isVisible} />
            </div>
          )}
        </VisibilitySensor>
      )}
    </div>
  );
};

export default ConversationList;
