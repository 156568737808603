import { useLazyQuery } from '@apollo/client';
import { isEmpty, pickBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { useDebounce, useUrlSearchParams } from 'frontend/hooks';

import { ORGANIZATION_SEARCH } from '../graphql';

const SEARCH_PARAMS_CONFIG = { default: { page: 1 }, types: { active: Boolean, page: Number, trial: Boolean } };

const useAdminOrganizations = (pageSize = 25) => {
  const [{ page, q: query, active, trial }, setParams, unsetParams] = useUrlSearchParams(SEARCH_PARAMS_CONFIG);
  const debouncedQuery = useDebounce(query, 300);

  const [runSearch, { data, loading, called }] = useLazyQuery(ORGANIZATION_SEARCH, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    runSearch({
      variables: {
        query: debouncedQuery,
        filters: {
          trial,
          active,
        },
        first: pageSize,
        after: (page - 1) * pageSize,
      },
    });
  }, [pageSize, runSearch, debouncedQuery, trial, active, page]);

  const organizations = useMemo(() => data?.organizationSearch?.objects ?? [], [data]);
  const noHits = !loading && organizations.length === 0 && called;
  const dirty = useMemo(() => active || trial || query || page !== 1, [active, trial, query, page]);
  const totalCount = data?.organizationSearch?.hits ?? 0;
  const pages = Math.ceil(totalCount / pageSize);

  const searchInput = useMemo(
    () => ({
      value: query,
      onChange: ({ target }) => {
        setParams({ q: target.value, page: 1 });
      },
    }),
    [setParams, query],
  );
  const onResetClick = useCallback(() => unsetParams(['active', 'trial', 'page', 'q']), [unsetParams]);

  const pagination = useMemo(
    () => ({
      currentPage: page,
      pages,
      setPage: (newPage) => setParams({ page: newPage, ...(active && { ...active }), ...(trial && { ...trial }) }),
      summary: {
        totalCount,
        firstVisible: (page - 1) * pageSize + 1,
        lastVisible: page === pages ? organizations.length : page * pageSize,
      },
    }),
    [organizations, page, pages, setParams, active, trial, totalCount, pageSize],
  );

  const setFilters = (filters) => {
    if (!isEmpty(filters)) {
      if (filters.active === null) {
        unsetParams(['active']);
      } else if (filters.trial === null) {
        unsetParams(['trial']);
      } else {
        setParams(pickBy(filters, (v) => v !== undefined));
      }
    }
  };

  return {
    filters: {
      active,
      trial,
    },
    setFilters,
    dirty,
    loading,
    noHits,
    onResetClick,
    organizations,
    pagination,
    searchInput,
  };
};
export default useAdminOrganizations;
