import type { UseFormReturn } from 'react-hook-form';

import { ModalForm } from 'frontend/features/Modals';

import type { CustomerFeedbackInputs } from '../CustomerFeedback';
import { type FeedbackForm, RHF_FORMS_NAME } from '../constants';

interface Props {
  hide(): void;
  args: {
    triggerType: string;
    formToEnable: FeedbackForm;
    alreadyEnabledForm: FeedbackForm;
    rhfMethods: UseFormReturn<CustomerFeedbackInputs>;
  };
}

export default function EnableFormModal({
  hide,
  args: { triggerType, rhfMethods, formToEnable, alreadyEnabledForm },
}: Props): React.JSX.Element {
  const { setValue, getValues } = rhfMethods;

  const handleModalSubmit = () => {
    const allForms = getValues()[RHF_FORMS_NAME];
    const indexFormEnabled = allForms.findIndex((form) => form.id === alreadyEnabledForm!.id);
    const indexFormToEnable = allForms.findIndex((form) => form.id === formToEnable!.id);

    setValue(`${RHF_FORMS_NAME}.${indexFormEnabled}`, { ...alreadyEnabledForm, isActive: false });
    setValue(`${RHF_FORMS_NAME}.${indexFormToEnable}`, { ...formToEnable, isActive: true });
    hide();
  };

  const handleModalCancel = () => {
    const allForms = getValues()[RHF_FORMS_NAME];
    const indexFormEnabled = allForms.findIndex((form) => form.id === formToEnable!.id);

    setValue(`${RHF_FORMS_NAME}.${indexFormEnabled}`, { ...formToEnable, isActive: false });
    hide();
  };

  return (
    <ModalForm
      title="Enable form"
      hide={hide}
      onSubmit={handleModalSubmit}
      color="warning"
      onCancel={handleModalCancel}
      ignoreDirty
    >
      <p>
        You already have an active feedback form for this trigger type (<strong>{triggerType}</strong>). Enabling this
        will disable the currently active one.
      </p>
      <p>
        <strong>Conflict</strong>: {alreadyEnabledForm.formTitle}
      </p>
    </ModalForm>
  );
}
