import { useQuery } from '@apollo/client';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'frontend/components';
import { setAutoGeneratingEntityItems } from 'frontend/state/dux/build';

import styles from './AutoGeneratedEntitySection.scss';
import EntityItem from './EntityItem';
import { AUTO_GENERATED_ITEMS } from '../../queries';
import EntityItemTag from '../EntityItemTag/EntityItemTag';

interface Props {
  addItems: (items: string[]) => void;
  allItems: string[];
  buildIdObject: { buildId: string; buildType: string };
  currentLanguage: string;
  setOpenGenerationSection: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AutoGeneratedEntitySection({
  addItems,
  allItems,
  buildIdObject,
  currentLanguage,
  setOpenGenerationSection,
}: Props) {
  const dispatch = useDispatch();
  const [generatedItems, setGeneratedItems] = useState<string[]>([]);
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  const { loading, error } = useQuery(AUTO_GENERATED_ITEMS, {
    variables: { ...buildIdObject, languageCode: currentLanguage, items: allItems },
    onCompleted(dataCompleted) {
      setGeneratedItems(dataCompleted.autoGenerateItems.items);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    dispatch(setAutoGeneratingEntityItems(true));

    return () => {
      dispatch(setAutoGeneratingEntityItems(false));
    };
  }, [dispatch]);

  if (loading || error) return null;
  if (!generatedItems || generatedItems?.length === 0) return <p>No similar items could be found.</p>;

  const selectedGeneratedItems = generatedItems.filter((_, idx) => selectedIndices.includes(idx));
  const numberOfItems = uniq([...(allItems || []), ...selectedGeneratedItems]).length;

  const handleChangeEntityTag = (idx: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: textValue } = event.target;

    setGeneratedItems((prev) => {
      const newItems = [...prev];
      newItems[idx] = textValue;
      return newItems;
    });
  };

  const addGeneratedTags = () => {
    const selectedItems = generatedItems
      .filter((_, idx) => selectedIndices.includes(idx))
      .map((item) => item.trim().toLowerCase());
    addItems([...allItems, ...selectedItems]);

    setOpenGenerationSection(false);
  };

  return (
    <div className="m-t-2">
      <span>Select and edit the items you want to add. Total items: {numberOfItems}</span>

      <div className={styles.autoGeneratedItemsList}>
        {generatedItems.map((item, idx) => {
          const addItem = () => setSelectedIndices([...selectedIndices, idx]);
          const removeItem = () => setSelectedIndices(selectedIndices.filter((selectedIdx) => selectedIdx !== idx));

          if (selectedIndices.includes(idx)) {
            return (
              <EntityItem
                idx={idx}
                key={item}
                input={{ value: item, onChange: handleChangeEntityTag(idx) }}
                remove={removeItem}
                className={styles.entity}
              />
            );
          }

          return (
            <EntityItemTag
              key={item}
              item={item}
              onClick={addItem}
              onPressEnter={addItem}
              outline
              tabbable
              className={styles.entity}
            />
          );
        })}
      </div>

      <Button
        color="primary"
        onClick={addGeneratedTags}
        className={styles.buttonAddGeneratedTag}
        disabled={!selectedIndices || selectedIndices.length === 0}
      >
        Add
      </Button>
      <Button color="white" onClick={() => setOpenGenerationSection(false)} className="m-l-1">
        Cancel
      </Button>
    </div>
  );
}
