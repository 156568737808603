import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { ExportContentDocument } from 'frontend/api/generated';
import { DownloadCloud } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useBotLanguages, useCurrentLanguage, useIsRunning } from 'frontend/hooks';

import styles from './ExportAction.scss';
import ExportCreateModal from '../ExportCreateModal/ExportCreateModal';

const ExportAction = () => {
  const { botId } = useParams();
  const { languagesAndVariants } = useBotLanguages();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const [showExportModal, hideExportModal] = useModal(ExportCreateModal);

  const [create] = useMutation(ExportContentDocument, {
    update: (cache, { data }) =>
      cache.modify({ fields: { contentExportList: (current = []) => [data?.exportContent, ...current] } }),
    variables: { botId: botId || '', languageCode: selectedLanguage, includeDisabledDialogues: false },
  });

  const [createWithSubmitting, isSubmitting, onFinished] = useIsRunning(create, { updateWhenFinished: false });

  usePusherEvent(getPusherChannel({ botId }), 'content-export', onFinished);

  return (
    <div className={styles.action}>
      <Button
        color="secondary"
        onClick={() =>
          showExportModal({
            handleImport: (includeDisabledDialogues) => {
              createWithSubmitting({ variables: { includeDisabledDialogues } });
              hideExportModal();
            },
            languagesAndVariants,
          })
        }
        isSubmitting={isSubmitting}
        disabled={isSubmitting}
        text="Create version"
        icon={DownloadCloud}
      />
    </div>
  );
};

export default ExportAction;
