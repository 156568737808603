import PropTypes from 'prop-types';

import styles from './VideoEmbedPreview.scss';

const HTML5VideoEmbed = ({ source }) => {
  const fileType = source.split('.').pop();
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video width="240" controls title="Video preview" className={styles.iframe}>
        <source src={source} type={`video/${fileType}`} />
        Your browser does not support HTML5 video.
      </video>
    </>
  );
};

HTML5VideoEmbed.propTypes = {
  source: PropTypes.string.isRequired,
};

export default HTML5VideoEmbed;
