import { useFormState } from 'react-final-form';

import { Modal } from 'frontend/features/Modals';
import type { PartialUser } from 'frontend/features/Organization/modals/AddMemberModal/AddMemberModal';
import type { AnyRole } from 'frontend/features/Organization/types';
import { pluralize, prettifyList } from 'frontend/utils';

import styles from './styles.scss';
import { getUserName, roleIdsFromFields } from '../../utils';

interface SummaryProps {
  users: PartialUser[];
  name: string;
  roles: AnyRole[];
}

function Summary({ users, name, roles }: SummaryProps) {
  const { values } = useFormState<{ selectedUsers: { value: unknown }[]; email: string }>();
  const selectedEmails = values.selectedUsers ? values.selectedUsers.map((user) => user.value) : [];
  const fullNames = values.email
    ? [values.email]
    : users
        .filter((user) => (selectedEmails.length ? selectedEmails.includes(user.email) : user))
        .map((user) => getUserName(user, user.email));
  const roleTitles = roleIdsFromFields(values).map((roleId) => roles?.find(({ id }) => id === roleId)?.title);

  return (
    <Modal.Content className={styles.content}>
      <p>You can change the roles, or delete a member at any time.</p>
      <p className={styles.summary}>
        <strong>{prettifyList(fullNames)}</strong> will be {pluralize('member', fullNames.length)} of{' '}
        <strong>{name}</strong>.
        <br />
      </p>
      <p className={styles.summary}>
        The {pluralize('member', fullNames.length)} will have following {pluralize('role', roleTitles.length)}:{' '}
        <strong>{prettifyList(roleTitles)}</strong>.
      </p>
    </Modal.Content>
  );
}

export default Summary;
