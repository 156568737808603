import { useQuery } from '@apollo/client';
import { search } from 'fast-fuzzy';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { Input, Loader } from 'frontend/components';
import { SHOW_MAX_AGENTS } from 'frontend/features/Inbox/constants';
import { TicketType } from 'frontend/features/Inbox/propTypes';
import { INBOX_AGENTS } from 'frontend/features/Inbox/queries';
import useMe from 'frontend/hooks/useMe';
import { IDType } from 'frontend/propTypes';

import Agent from './Agent';
import styles from './AgentList.scss';
import DropdownOverlay from '../DropdownOverlay';

const AgentListContent = ({ ticket, botId, emailCc = [] }) => {
  const [agentSearch, setAgentSearch] = useState('');
  const {
    data: { me },
    loading: meLoading,
  } = useMe();
  const { data, loading } = useQuery(INBOX_AGENTS, {
    variables: { id: botId },
  });
  const supportAgents = useMemo(
    () =>
      get(data, 'bot.inboxAgentMembers', [])
        .map(({ user }) => user)
        .sort(({ username }) => {
          if (username === me.username || emailCc.includes(username)) {
            return -1;
          }
          return 0;
        }),
    [data, me, emailCc],
  );

  if (loading || meLoading) {
    return <Loader type="support_agents" />;
  }

  let supportAgentsToShow = supportAgents;

  if (agentSearch) {
    supportAgentsToShow = search(agentSearch || '', supportAgents, {
      keySelector: ({ username, profile }) => [profile.firstName, profile.lastName, username],
    });
  }

  return (
    <>
      {supportAgents.length > SHOW_MAX_AGENTS && (
        <Input
          className={styles.searchField}
          label="Search agents"
          autoComplete="off"
          input={{
            name: 'agent-search',
            value: agentSearch,
            onChange: (e) => setAgentSearch(e.target.value),
          }}
        />
      )}
      <div className={styles.agentList}>
        {supportAgentsToShow.map((agent) => (
          <Agent key={agent.id} agent={agent} ticket={ticket} />
        ))}
      </div>
    </>
  );
};

AgentListContent.propTypes = {
  ticket: TicketType,
  botId: IDType.isRequired,
  emailCc: PropTypes.arrayOf(PropTypes.string),
};

const AgentList = ({ ticket, close, botId, emailCc }) => (
  <DropdownOverlay header="Assign user" close={close}>
    <AgentListContent ticket={ticket} botId={botId} emailCc={emailCc} />
  </DropdownOverlay>
);

AgentList.propTypes = {
  ticket: TicketType,
  close: PropTypes.func,
  botId: IDType.isRequired,
  emailCc: PropTypes.arrayOf(PropTypes.string),
};

export default AgentList;
