import { gql } from '@apollo/client';

import ORGANIZATION_FRAGMENT from 'frontend/features/Organization/fragments/Organization.fragment.graphql';
import SUBSCRIPTION_FRAGMENT from 'frontend/features/Organization/fragments/Subscription.fragment.graphql';

export default gql`
  query OrganizationSearch(
    $query: String
    $filters: OrganizationFiltersInput
    $first: Int
    $after: Int
    $sort: [String]
  ) {
    organizationSearch(query: $query, filters: $filters, first: $first, after: $after, sort: $sort) {
      id
      hits
      objects {
        ...Organization
        subscriptions {
          ...Subscription
        }
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
`;
