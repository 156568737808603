import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  dialogue: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  dialogues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      replies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.shape(PropTypes.string.isRequired),
    }),
  ),
  plugin: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    templateDialogues: PropTypes.arrayOf(PropTypes.shape({})),
    contextKeys: PropTypes.arrayOf(PropTypes.shape({})),
    url: PropTypes.string,
  }),
});
