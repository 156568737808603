import { omit } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ChatbubblePushGreetingType } from 'frontend/api/generated';
import { LoaderSwitch } from 'frontend/components';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { ProactivityItem } from 'frontend/features/Library/components';
import { useCurrentLanguage } from 'frontend/hooks';
import randomUUID from 'frontend/utils/randomUUID';

const PushGreetingsList = () => {
  const { loading, initialValues, onSubmit } = useChatbubbleForm({
    toastSuccessMessage: 'Success',
  });

  const [{ currentLanguage }] = useCurrentLanguage();

  const navigate = useNavigate();

  const handleClick = async (
    action: 'delete' | 'publish' | 'duplicate',
    pg: ChatbubblePushGreetingType,
    index: number,
  ) => {
    if (action === 'delete') {
      await onSubmit?.({
        ...initialValues,
        pushGreetings: initialValues?.pushGreetings?.filter((pushGreeting) => pushGreeting?.id !== pg.id),
      });

      if (window.location.pathname.endsWith(pg.id)) {
        const findPreviousItemId =
          initialValues.pushGreetings[index - 1]?.id || initialValues.pushGreetings[index + 1]?.id;
        navigate(`proactivity/push-greeting/${findPreviousItemId ? `${findPreviousItemId}` : ''}`);
      }
    }

    if (action === 'publish') {
      await onSubmit?.({
        ...initialValues,
        pushGreetings: { ...initialValues }?.pushGreetings?.map((pushGreeting) => {
          if (pushGreeting?.id === pg.id) {
            const greeting = { ...pushGreeting };

            greeting.enabled = {
              ...greeting.enabled,
              [currentLanguage]: !greeting.enabled[currentLanguage],
            };
            return greeting;
          }
          return pushGreeting;
        }),
      });
    }

    if (action === 'duplicate') {
      const duplicate = omit(
        {
          ...pg,
          tempId: randomUUID(),
        },
        ['id'],
      );
      const pushGreetingsCopy = [...initialValues.pushGreetings];
      pushGreetingsCopy.push(duplicate);

      await onSubmit?.({
        ...initialValues,
        pushGreetings: [...pushGreetingsCopy],
      });
    }
  };

  return (
    <LoaderSwitch loading={loading} size="large">
      {initialValues?.pushGreetings?.map((pg, index) => (
        <ProactivityItem
          onClick={(action) => handleClick(action, pg, index)}
          show="push-greeting"
          key={`pg-${pg.id}`}
          item={pg}
        />
      ))}
    </LoaderSwitch>
  );
};

export default PushGreetingsList;
