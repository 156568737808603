import { Field, useFormState } from 'react-final-form';

import { ImageCarousel as ImageCarouselComponent, InfoBox, InfoButton, Radio } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import hasRule from 'frontend/utils/hasRule';

import styles from './ImageCarousel.scss';
import { AboutImageCarousel } from '../../modals';

interface Props {
  args: {
    currentLanguage: string;
    currentRuleId?: string | undefined;
    isModDialogue?: boolean | undefined;
  };
}

function ImageCarousel({ args: { isModDialogue, currentLanguage, currentRuleId } }: Props) {
  const { values } = useFormState();

  const imageCarouselFieldName = isModDialogue ? 'modImageCarousels' : 'imageCarousels';

  let imageCarouselIndex: number = values?.[imageCarouselFieldName]?.findIndex(hasRule(currentRuleId)) ?? 0;
  if (imageCarouselIndex === -1) {
    imageCarouselIndex = values?.[imageCarouselFieldName].length;
  }

  const [showAboutImageCarousel] = useModal(AboutImageCarousel);

  const componentImageSize = values?.[imageCarouselFieldName]?.[imageCarouselIndex] ? (
    <>
      <fieldset className={styles.imageSizesWrapper}>
        <legend>Image size:</legend>
        <Field
          component={Radio}
          name={`${imageCarouselFieldName}.${imageCarouselIndex}.size`}
          type="radio"
          layout="inline"
          label="Small"
          value={600}
          parse={(value) => Number(value)}
        />
        <Field
          component={Radio}
          name={`${imageCarouselFieldName}.${imageCarouselIndex}.size`}
          type="radio"
          layout="inline"
          label="Medium"
          value={800}
          parse={(value) => Number(value)}
        />
        <Field
          component={Radio}
          name={`${imageCarouselFieldName}.${imageCarouselIndex}.size`}
          type="radio"
          layout="inline"
          label="Large"
          value={1000}
          parse={(value) => Number(value)}
        />
      </fieldset>
      {values.imageCarousels[imageCarouselIndex].size === 600 && (
        <InfoBox className="m-t-1">
          <p>Buttons won&apos;t be displayed on small images.</p>
        </InfoBox>
      )}
    </>
  ) : undefined;

  return (
    <ImageCarouselComponent
      fieldName={`${imageCarouselFieldName}.${imageCarouselIndex}`}
      currentLanguage={currentLanguage}
      currentRuleId={currentRuleId}
      componentImageSize={componentImageSize}
      isDialogueCarousel
    >
      <InfoButton className={styles.infoButton} onClick={showAboutImageCarousel} />
    </ImageCarouselComponent>
  );
}
export default ImageCarousel;
