import { useMutation } from '@apollo/client';
import { omit } from 'lodash/fp';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { AllPluginsDocument, CreatePluginDocument } from 'frontend/api/generated';
import { PageContent } from 'frontend/components';
import { withErrorHandler } from 'frontend/enhancers';
import { useDocumentTitle, useIsRunning, useToast } from 'frontend/hooks';

import PluginForm from './PluginForm';

const update = appendArrayInCache({
  pathToItemInMutationData: 'createPlugin',
  pathToArrayInCache: 'allPlugins',
  query: AllPluginsDocument,
});

const INITIAL_VALUES = { isActive: true, readChats: false };

const PluginCreate = () => {
  useDocumentTitle('New plugin');
  const navigate = useNavigate();
  const toast = useToast();

  const [createPluginMutation] = useMutation(CreatePluginDocument, {
    onCompleted: ({ createPlugin }) => {
      toast.success(`Plugin ${createPlugin?.name} created`);
      navigate(`/admin/plugin/${createPlugin?.id}`);
    },
    update,
  });

  const onSubmit = useCallback(
    async (values) => {
      const variables = {
        ...values,
        contextKeys: values.contextKeys?.map?.(omit(['id', '__typename'])),
        templateDialogues: values.templateDialogues?.map?.(omit(['id', '__typename'])),
      };

      await createPluginMutation({ variables });
    },
    [createPluginMutation],
  );

  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmit);

  return (
    <div>
      <PageContent>
        <PluginForm
          onSubmit={onSubmitWithRunning}
          initialValues={INITIAL_VALUES}
          operation="create"
          isSubmitting={isSubmitting}
        />
      </PageContent>
    </div>
  );
};

export default withErrorHandler(PluginCreate);
