import { useMutation } from '@apollo/client';
import { Field } from 'react-final-form';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { type BotApiKeyType, BotApiKeysDocument, CreateBotApiKeyDocument } from 'frontend/api/generated';
import { Checkbox, FormErrors, Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { chain, max, notInList, required } from 'frontend/form/validators';

import styles from './CreateBotApiKey.scss';

const createBotApiKeyValidator = ({ viewStatistics, viewConversations }) => ({
  noPermissions: !viewStatistics && !viewConversations ? 'The API key must have at least one permission' : undefined,
});

interface CreateBotApiKeyProps {
  hide: () => void;
  args: {
    botId: string;
    apiKeys: BotApiKeyType[];
  };
}

const CreateBotApiKey = ({ hide, args: { botId, apiKeys } }: CreateBotApiKeyProps) => {
  const update = appendArrayInCache({
    variables: { botId },
    pathToItemInMutationData: 'createBotApiKey',
    pathToArrayInCache: 'botApiKeys',
    query: BotApiKeysDocument,
  });
  const [create] = useMutation(CreateBotApiKeyDocument, { update });
  const onSubmit = async (values) => {
    await create({ variables: { botId, ...values } });
  };
  const existingKeyNames = apiKeys.map(({ name }) => name);
  const errorMessage = 'An API key with this name already exists';

  const initialValues = { name: '', viewConversations: false, viewStatistics: false };
  return (
    <ModalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={createBotApiKeyValidator}
      title="Create API key"
      onOkText="Create"
      hide={hide}
    >
      <FormErrors />
      <Field
        className={styles.nameInput}
        component={Input}
        name="name"
        placeholder="Name"
        validate={chain([required, max(255), notInList(existingKeyNames, errorMessage)])}
        autoFocus
      />
      <Field
        name="viewConversations"
        component={Checkbox}
        type="checkbox"
        label="Read chats"
        className={styles.checkbox}
      />
      <Field
        name="viewStatistics"
        component={Checkbox}
        type="checkbox"
        label="Read statistics"
        className={styles.checkbox}
      />
    </ModalForm>
  );
};

export default CreateBotApiKey;
