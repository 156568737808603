import type { Maybe } from 'graphql/jsutils/Maybe';
import { Link } from 'react-router-dom';

import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import { Panel } from 'frontend/components';
import botCardStyles from 'frontend/features/Organization/components/BotCard/BotCard.scss';

import styles from './styles.scss';

interface Props {
  avatarUrl?: Maybe<string>;
  id?: string;
  languages?: { id: string | number; code: string }[];
  name?: string;
  isArchived?: boolean;
  link?: string;
  projectName?: string;
}

export default function BotCard({ avatarUrl, link, id, name, languages, projectName, isArchived = false }: Props) {
  const avatar = !avatarUrl ? defaultBotAvatar : avatarUrl;

  return (
    <Link to={link || `/workspace/${id}`} className={styles.link}>
      {isArchived && <div className={styles.archivedLabel}>Archived</div>}

      <div className={botCardStyles.container}>
        <Panel size="large" hover>
          <div className={botCardStyles.content}>
            <img className={botCardStyles.botAvatar} src={avatar} alt="Workspace avatar" />
            <div className={botCardStyles.about}>
              <h3 className={botCardStyles.title}>{name}</h3>
              <span className={styles.projectName}>{projectName}</span>
              {(languages ?? []).map((language) => (
                <div className={botCardStyles.languageCode} key={language.id}>
                  {language.code}
                </div>
              ))}
            </div>
          </div>
        </Panel>
      </div>
    </Link>
  );
}
