import { get, isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { mapToObject } from 'frontend/utils';

import styles from './AddKindlyEntitiesReport.scss';
import { DialogueReportType } from './propTypes';
import { SampleWithEntities } from '../../components';
import { KindlyEntitiesType } from '../../propTypes';

const createEntityInstance =
  ({ id, kindlyEntityMap, currentLanguage }) =>
  ({ kindlyEntityId, start, end, body }, idx) => ({
    id: `${id}-${kindlyEntityId}-${idx}`,
    start,
    end,
    kindlyEntity: {
      id: kindlyEntityId,
      name: kindlyEntityMap[kindlyEntityId].toLowerCase(),
      category: kindlyEntityMap[kindlyEntityId],
      description: `Replaces "${body}"`,
      languageCode: currentLanguage,
      restricted: false,
    },
  });

const orderByStart = (matches) => orderBy(matches, 'start');

const DialogueReport = ({ dialogue, kindlyEntities, currentLanguage }) => {
  const kindlyEntityMap = useMemo(
    () => mapToObject(({ id, category }) => ({ [id]: category }), kindlyEntities),
    [kindlyEntities],
  );

  const samples = useMemo(() => {
    if (isEmpty(kindlyEntityMap)) return [];
    return get(dialogue, 'samples', []).map(({ id, text, matches }) => {
      const instances = orderByStart(matches).map(createEntityInstance({ id, kindlyEntityMap, currentLanguage }));

      return { id, text, instances };
    });
  }, [currentLanguage, dialogue, kindlyEntityMap]);

  return (
    <div className={styles.sampleList}>
      {samples.map(({ id, text, instances }) => (
        <SampleWithEntities key={`sample-${id}`} text={text} instances={instances} />
      ))}
    </div>
  );
};

DialogueReport.propTypes = {
  dialogue: DialogueReportType.isRequired,
  kindlyEntities: KindlyEntitiesType.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default DialogueReport;
