import PropTypes from 'prop-types';

import { Metric } from 'frontend/components/Stats';

import styles from '../Evaluation.scss';

const ReportOverview = ({ selectedReport, comparingReport = null }) => {
  const nlpOverrides = {
    selected: null,
    comparing: null,
  };

  if (selectedReport.nlpOverrides) {
    try {
      nlpOverrides.selected = JSON.parse(selectedReport.nlpOverrides);
    } catch (e) {
      console.error(`couldnt parse nlpOverrides for report ${selectedReport.id}`);
    }
  }
  if (comparingReport?.nlpOverrides) {
    try {
      nlpOverrides.comparing = JSON.parse(comparingReport.nlpOverrides);
    } catch (e) {
      console.error(`couldnt parse nlpOverrides for report ${comparingReport.id}`);
    }
  }

  return (
    <div className={styles.reportOverview}>
      <Metric.Grid className={styles.reportOverviewGrid}>
        {typeof selectedReport.fScoreId === 'number' && (
          <Metric bottomText="Score" metric={selectedReport.fScoreId} reference={comparingReport?.fScoreId} />
        )}
        <Metric bottomText="Test queries" metric={selectedReport.queryCount} reference={comparingReport?.queryCount} />
        {Boolean(typeof nlpOverrides?.selected.threshold === 'number') && (
          <Metric
            bottomText="Confidence threshold"
            postfix="%"
            metric={nlpOverrides.selected.threshold * 100}
            reference={nlpOverrides.comparing ? parseFloat(nlpOverrides.comparing.threshold * 100) : null}
          />
        )}
        {Boolean(selectedReport.dialogueCoverage) && (
          <Metric
            bottomText="Coverage"
            metric={parseFloat(selectedReport.dialogueCoverage)}
            postfix="%"
            reference={comparingReport?.dialogueCoverage ? parseFloat(comparingReport?.dialogueCoverage) : null}
          />
        )}
      </Metric.Grid>
    </div>
  );
};

ReportOverview.propTypes = {
  selectedReport: PropTypes.shape({}).isRequired,
  comparingReport: PropTypes.shape({}),
};

export default ReportOverview;
