import { useQuery } from '@apollo/client';
/* TODO: Revert to original repo once https://github.com/valerybugakov/react-selectable-fast/pull/63 and
https://github.com/valerybugakov/react-selectable-fast/pull/65 are merged */
import { SelectableGroup } from '@kindly/react-selectable-fast';
import { compose, get, join, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';

import { GET_ZODIAC_ENABLED } from 'frontend/api/queries';
import { InfoBox } from 'frontend/components';
import { insertEntityNames } from 'frontend/features/Entities/utils';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import { useToast } from 'frontend/hooks';

import {
  DragContainer,
  ExpandedSamples,
  NewSample,
  SampleItem,
  SamplesHeadline,
  SkillSamples,
  SortSamples,
  ZodiacWarning,
} from './components';
import { useSampleLists, useSelectedSamples, useShiftKey } from './hooks';

import './Samples.scss';

const ignoreList = ['.ignoreSelect'];
const disableSelectStartList = ['.disableSelect'];

const Samples = ({ currentLanguage, isModDialogue }) => {
  const { botId } = useParams();
  const { data } = useQuery(GET_ZODIAC_ENABLED, { variables: { botId }, skip: !botId });

  const fieldName = isModDialogue ? 'modSamples' : 'samples';
  const selectableGroupRef = useRef();
  // NOTE: Get `dialogueId` from final-form so that it is synchronized with samples
  // (otherwise there can be a delay, giving effects etc with outdated samples)
  const {
    dialogueId,
    sampleValidator,
    sampleList,
    expandedSamples,
    skillSampleList,
    expandedSampleCount,
    totalSampleCount,
    expandedSkillSamples,
    totalSkillSampleCount,
    expandedSkillSampleCount,
  } = useSampleLists({ currentLanguage, isModDialogue });
  const { selectedSamples, deselectAll, setSampleSelected, updateSelectedSamples } =
    useSelectedSamples(selectableGroupRef);
  const { mutators } = useForm();
  const [editingSample, setEditingSample] = useState();
  const [samplesExpanded, setSamplesExpanded] = useState(false);
  const shiftKey = useShiftKey();
  const toast = useToast();

  const addSample = useCallback((value) => mutators.pushFixed('', fieldName, value), [fieldName, mutators]);
  const removeSample = useCallback((value) => mutators.removeFixed('', fieldName, value), [fieldName, mutators]);
  const stopEditing = useCallback(() => setEditingSample(), []);

  const zodiacEnabled = Boolean(data?.bot?.zodiacSettings?.find((x) => x.languageCode === currentLanguage)?.enabled);
  const insufficientSamples = totalSampleCount + totalSkillSampleCount < 20; // recommended minimum number of samples
  const showZodiacWarning = Boolean(dialogueId) && insufficientSamples && zodiacEnabled;

  const copySamples = () => {
    if (selectedSamples?.length === 0) return;
    const getSampleTexts = compose(join('\n'), map(get('searchText')), map(insertEntityNames));
    const stringToCopy = getSampleTexts(selectedSamples);

    navigator.clipboard.writeText(stringToCopy);
    toast.success('Samples copied to clipboard');
  };

  useHotkeys('mod+c', copySamples, [selectedSamples, toast], {
    enableOnFormTags: true,
  });

  return (
    <>
      <NewSample
        currentLanguage={currentLanguage}
        add={addSample}
        sampleValidator={sampleValidator}
        label="Add sample"
      />
      <SortSamples />
      {showZodiacWarning && <ZodiacWarning />}
      <SamplesHeadline
        sampleList={sampleList}
        sampleCount={sampleList.length}
        sampleValidator={sampleValidator}
        dialogueId={dialogueId}
        setSamplesExpanded={setSamplesExpanded}
        samplesExpanded={samplesExpanded}
        fieldName={fieldName}
        actionsEnabled
        showZodiacWarning={showZodiacWarning}
        expandedSampleCount={expandedSampleCount}
        totalSampleCount={totalSampleCount}
        totalSkillSampleCount={totalSkillSampleCount}
        expandedSkillSampleCount={expandedSkillSampleCount}
        currentLanguage={currentLanguage}
        botId={botId}
      >
        {isModDialogue ? 'Custom samples' : 'Samples'}
      </SamplesHeadline>
      {!samplesExpanded && (
        <SelectableGroup
          className="selectable-group"
          ref={selectableGroupRef}
          onSelectionStart={stopEditing}
          onSelectionFinish={updateSelectedSamples}
          ignoreList={ignoreList}
          disableSelectStartList={disableSelectStartList}
          resetOnStart={!shiftKey}
          disabled={!!editingSample}
          selectOnClick={false}
          allowShiftClick
        >
          <DragContainer
            selectedSamples={selectedSamples}
            dialogueId={dialogueId}
            isModDialogue={isModDialogue}
            deselectAll={deselectAll}
            shiftKey={shiftKey}
            isEditingSample={!!editingSample}
          >
            {sampleList.map((sample) => (
              <SampleItem
                sample={sample}
                key={`sample-${sample.id || sample.tempId}`}
                name={`${fieldName}.${sample.sampleIndex}`}
                selectedSamples={selectedSamples}
                sampleValidator={sampleValidator}
                removeSample={removeSample}
                deselectAll={deselectAll}
                fieldName={fieldName}
                setSampleSelected={setSampleSelected}
                editingSample={editingSample}
                setEditingSample={setEditingSample}
                stopEditing={stopEditing}
              />
            ))}
          </DragContainer>
        </SelectableGroup>
      )}
      {samplesExpanded && (
        <ExpandedSamples expandedSamples={expandedSamples} expandedSampleCount={expandedSampleCount} />
      )}
      {isModDialogue && (
        <>
          <SamplesHeadline
            fieldName={fieldName}
            sampleCount={skillSampleList.length}
            samplesExpanded={samplesExpanded}
            totalSampleCount={totalSkillSampleCount}
            expandedSampleCount={expandedSkillSampleCount}
          >
            Predefined samples
          </SamplesHeadline>
          {!samplesExpanded && <SkillSamples skillSamples={skillSampleList} />}
          {samplesExpanded && (
            <ExpandedSamples expandedSamples={expandedSkillSamples} expandedSampleCount={expandedSkillSampleCount} />
          )}
        </>
      )}
      <br />
      <br />
      <br />
      <InfoBox title="About samples">
        A well-performing chatbot needs a good data set. Train your chatbot by adding samples.&nbsp;
        <HelpCenterLink path="/samples">Click here to read more about samples</HelpCenterLink>
      </InfoBox>
    </>
  );
};

Samples.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  isModDialogue: PropTypes.bool,
};

export default Samples;
