import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { ModalProvider } from 'react-modal-hook';
import { useParams } from 'react-router-dom';

import { FormErrors, GuardUnsaved, NoteFormChanges, Panel } from 'frontend/components';
import { useUrlSearchParams } from 'frontend/hooks';

import styles from './SpecialDialogueForm.scss';
import { useDialogueOutputs } from '../../hooks';
import type { BuildFormType } from '../../propTypes/BuildFormType';
import DialogueFormOutput from '../DialogueFormOutput';
import DialoguePageBar from '../DialoguePageBar';
import DialoguePath from '../DialoguePath';
import EditingNow from '../EditingNow';
import Preview from '../Preview';
import VerticalToolbar from '../VerticalToolbar/VerticalToolbar';

interface Props {
  selectedLanguage: string;
  currentRuleId: string | undefined;
  buildFormType: BuildFormType | null;
  createdUrl?: string;
  hasChanges: boolean;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpecialDialogueFormContents = ({
  selectedLanguage,
  currentRuleId,
  buildFormType,
  createdUrl,
  hasChanges,
  setHasChanges,
}: Props) => {
  const { initialValues, values } = useFormState({ subscription: { initialValues: true, values: true } });
  const { id, isActive, colorLabel, mod, dialogueType } = initialValues;

  const { tabsShown, setTabsToShowExtra } = useDialogueOutputs({ isModDialogue: false, buildFormType });
  const { dialogueId } = useParams<{ dialogueId: string }>();

  const dialogueLabelColorData = {
    colorLabel: mod?.modColorLabel ?? colorLabel,
    dialogueType,
  };

  const dialogueData = {
    isModDialogue: false,
    initialValues,
    dialogueModId: null,
    selectedLanguage,
    currentRuleId,
    buildFormType,
  };

  const [, , unsetParams] = useUrlSearchParams();

  useEffect(() => {
    const rules = values.dialogueRules || [];
    if (dialogueId !== values.id) {
      return;
    }

    if (
      currentRuleId &&
      rules.length &&
      !rules?.some(({ id: ruleId, languageCode }) => ruleId === currentRuleId && languageCode === selectedLanguage)
    ) {
      unsetParams(['rule'], { replace: true });
    } else if (currentRuleId && !rules.length) {
      unsetParams(['rule'], { replace: true });
    }
  }, [currentRuleId, unsetParams, values.dialogueRules, values.id, selectedLanguage, dialogueId]);

  return (
    <ModalProvider>
      {/* ModalProvider needs to be inside the form provider (from react-final-form) if we want to access the form from within a modal */}
      <NoteFormChanges hasChanges={hasChanges} setHasChanges={setHasChanges} notifyBuild />
      <GuardUnsaved hasChanges={hasChanges} allowRedirect={Boolean(createdUrl)} allowSamePathRedirect />

      <div className={styles.specialDialogueFormWrapper}>
        {!isNil(id) && <EditingNow dialogueLikeId={id} />}
        <Panel className={styles.pageContent}>
          <FormErrors />
          <div className={styles.pageHeader}>
            <DialoguePath
              dialogueLabelColorData={dialogueLabelColorData}
              isActive={isActive}
              readOnly={buildFormType === 'system-dialogue'}
            />
            <DialoguePageBar hasChanges={hasChanges} />
          </div>
          <div className={styles.content} id="dialogue-form-contents">
            <VerticalToolbar isOutputPanelShown buttonsToDisable={['input', 'label']} />
            <div className={styles.verticalSeparator} />
            <Preview
              className={styles.previewContainer}
              isModDialogue={false}
              setTabsToShowExtra={setTabsToShowExtra}
              tabsShownMap={tabsShown}
            />
            <div className={styles.verticalSeparator} />
            <div className={styles.tabContentWrapper}>
              <DialogueFormOutput
                dialogueData={dialogueData}
                setTabsToShowExtra={setTabsToShowExtra}
                tabsShownMap={tabsShown}
              />
            </div>
          </div>
        </Panel>
      </div>
    </ModalProvider>
  );
};

export default SpecialDialogueFormContents;
