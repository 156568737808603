import cx from 'classnames';

import avatarTalkBubble from 'frontend/assets/images/avatar-talk-bubble.svg?url';
import defaultAvatar from 'frontend/assets/images/avatar.svg?url';
import sharedStyles from 'frontend/features/Inbox/inbox-styles.scss';

const EmptyInbox = () => (
  <div className={sharedStyles.emptyInbox}>
    <div className={sharedStyles.emptyInboxSplash}>
      <div
        className={cx(sharedStyles.emptyInboxImage, sharedStyles.avatar)}
        style={{ backgroundImage: `url(${defaultAvatar})` }}
      >
        <div
          className={cx(sharedStyles.emptyInboxImage, sharedStyles.talkBubble)}
          style={{ backgroundImage: `url(${avatarTalkBubble})` }}
        />
      </div>
      <p>Ahoy! This is your inbox. Browse through messages or provide your customers with real-time support!</p>
    </div>
  </div>
);

export default EmptyInbox;
