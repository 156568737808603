import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateApplicationDocument } from 'frontend/api/generated';
import { Button, Input } from 'frontend/components';
import ConnectDetails from 'frontend/features/Connect/components/ConnectDetails/ConnectDetails';
import { chain, max, required, url } from 'frontend/form/validators';
import { useIsRunning, useToast } from 'frontend/hooks';

import styles from '../ApplicationSettings.scss';

const SUBSCRIBE = {};
const INITIAL_VALUES = { name: '', webhookUrl: '' };

const UpdateApplicationForm = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { botId } = useParams();
  const [mutate] = useMutation(CreateApplicationDocument);

  const submit = useCallback(
    async ({ name, webhookUrl }) => {
      const { data } = await mutate({ variables: { name, botId: botId as string, webhookUrl } });
      navigate(`/workspace/${botId}/connect/applications/${data?.createApplication?.id}`);
      toast.success('Application was created');
    },
    [botId, navigate, mutate, toast],
  );

  const [onSubmit, isSubmitting] = useIsRunning(submit);

  return (
    <Form
      initialValues={INITIAL_VALUES}
      subscribe={SUBSCRIBE}
      onSubmit={onSubmit}
      render={({ handleSubmit, values: { name } }) => (
        <form>
          <ConnectDetails backButtonTitle={name || 'Back'}>
            <h3 className={styles.title}>{name || 'New application'}</h3>
            <div className={styles.inputContainer}>
              <Field
                component={Input}
                label="Name"
                name="name"
                placeholder="Application name"
                validate={chain(max(50), required)}
              />
            </div>

            <div className={styles.inputContainer}>
              <Field
                component={Input}
                label="Webhook"
                name="webhookUrl"
                placeholder="https://example.com"
                validate={chain(max(300), url, required)}
              />
            </div>
            <p>
              We will post replies to the following URL. Only replies to requests with API key for this app will be
              posted. <br />
              For more information,{' '}
              <a
                href="https://docs.kindly.ai/api/application"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                check out the docs
              </a>
              .
            </p>
            <Button onClick={handleSubmit} color="primary" isSubmitting={isSubmitting}>
              Create application
            </Button>
          </ConnectDetails>
        </form>
      )}
    />
  );
};

export default UpdateApplicationForm;
