import { Table } from 'frontend/components';
import type { Row } from 'frontend/components/Table/Table';

import ExportWorkspaceOptions from './ExportTableOptions';

const columns = [
  { key: 'created' },
  { key: 'language' },
  { key: 'status' },
  { title: '', key: 'options', component: ExportWorkspaceOptions },
];

interface ExportTableProps {
  imports: (Row & {
    created: string;
    language: string;
    status: string;
  })[];
}

const ExportTable = ({ imports }: ExportTableProps) => <Table data={imports} columns={columns} />;

export default ExportTable;
