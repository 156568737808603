import styles from './Contact.scss';

const Contact = () => (
  <div className={styles.contactBanner}>
    <p>
      No user in Kindly?{' '}
      <a className={styles.contactLink} href="mailto:sales@kindly.ai">
        Contact us
      </a>{' '}
      for a demo.
    </p>
  </div>
);

export default Contact;
