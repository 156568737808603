/* eslint-disable camelcase */

import { useUrlSearchParams } from 'frontend/hooks';

import { removeEmptyValues } from '../utils';

const filterToUrlParams = (campaign_id) => removeEmptyValues({ campaign_id });

const DEFAULT = { campaign_id: '' };

const useCampaignId = () => {
  const [{ campaign_id }] = useUrlSearchParams({ default: DEFAULT });
  return [campaign_id, filterToUrlParams];
};

export default useCampaignId;
