import avatar from 'frontend/assets/images/avatar-nerd.svg?url';
import { CenteredComponent, PageContent } from 'frontend/components';

const NoSkillSubscribers = () => (
  <PageContent>
    <CenteredComponent>
      <img className="m-b-md" src={avatar} alt="Publish your skill" />
      <h2 className="m-b-md">You have no skill subscribers yet</h2>
      <h4 className="m-b-lg">Share the landing page with workspace owners to start connecting workspaces.</h4>
    </CenteredComponent>
  </PageContent>
);

export default NoSkillSubscribers;
