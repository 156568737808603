import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { PluginDocument, type PluginType } from 'frontend/api/generated';
import defaultLogo from 'frontend/assets/images/plugin_logo.png';
import { Button, Loader } from 'frontend/components';
import { NotFound } from 'frontend/features';
import ConnectDetails from 'frontend/features/Connect/components/ConnectDetails/ConnectDetails';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';

import styles from './styles.scss';

const PluginConfig = () => {
  const { botId, pluginId } = useParams();

  const { data, loading } = useQuery(PluginDocument, { variables: { id: pluginId } });
  if (loading) return <Loader size="large" />;

  if (!data) return <NotFound text="Plugin not found" />;
  const { name, description, logoUrl } = data.plugin as PluginType;

  return (
    <ConnectDetails backButtonTitle={name}>
      <img className={styles.logo} src={(logoUrl || defaultLogo) as string} alt={name} />
      <div className={styles.textContainer}>
        <h3>{name}</h3>
        <p>{description}</p>
        <p>
          Dialogue plugins are triggered based on an end user hitting a specific intent/dialogue. They are configured
          per dialogue in the Build section in Kindly. Read more about how to setup a plugin in our{' '}
          <HelpCenterLink path="/sections/features#advanced-features">Help Center</HelpCenterLink>
        </p>
      </div>
      <Button className={styles.inlineButton} color="primary" to={`/workspace/${botId}/build`}>
        Add to bot
      </Button>
    </ConnectDetails>
  );
};

export default PluginConfig;
