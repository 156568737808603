import { gql } from '@apollo/client';

import { zodiacModelFragment } from '../fragments';

export default gql`
  query ZodiacModels($botId: ID!) {
    zodiacModels(botId: $botId) {
      ...ZodiacModel
    }
  }
  ${zodiacModelFragment}
`;
