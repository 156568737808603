import { differenceBy, identity, mapKeys } from 'lodash';

import { capitalize } from 'frontend/utils';

export default (initialLabelSet, labelSet, preFix = '') => {
  const addedLabels = differenceBy(labelSet, initialLabelSet, 'id').map(({ id }) => id);
  const removedLabels = differenceBy(initialLabelSet, labelSet, 'id').map(({ id }) => id);

  return mapKeys({ addedLabels, removedLabels }, (_, key) => `${preFix}${(preFix ? capitalize : identity)(key)}`);
};
