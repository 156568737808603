import { gql } from '@apollo/client';

export default gql`
  query ButtonsLinkedToDialogue($dialogueId: UUID!, $botId: ID, $skillId: ID) {
    buttonsLinkedToDialogue(dialogueId: $dialogueId, botId: $botId, skillId: $skillId) {
      id
      label
      languageCode
      dialogue {
        id
        url
        bot {
          id
        }
        skill {
          id
        }
      }
      dialogueMod {
        id
        url
        modDialogue {
          id
        }
        modBot {
          id
        }
      }
    }
  }
`;
