type Filters = {
  dateFilter: [
    {
      startDate?: Date;
      endDate?: Date;
    },
  ];
};

export default (filters: Filters) => {
  const dateFilterToSend: {
    before?: Date;
    after?: Date;
  } = {};

  if (filters.dateFilter) {
    Object.values(filters.dateFilter).forEach((value) => {
      if (value.startDate) {
        dateFilterToSend.after = value.startDate;
      }

      if (value.endDate) {
        dateFilterToSend.before = value.endDate;
      }
    });
  }

  return dateFilterToSend;
};
