import PropTypes from 'prop-types';

import { Pencil, Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './DotsOverlay.scss';

function DotsOverlay({ handleDelete, handleEdit }) {
  return (
    <div className={styles.container}>
      <div
        role="button"
        tabIndex={0}
        className={styles.choiceWrapper}
        onClick={handleEdit}
        onKeyDown={(e) => e.key === 'Enter' && handleEdit()}
      >
        <Icon component={Pencil} className={styles.icon} />
        <p className={styles.choiceText}>Edit label</p>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={styles.choiceWrapper}
        onClick={handleDelete}
        onKeyDown={(e) => e.key === 'Enter' && handleDelete()}
      >
        <Icon component={Trash} className={styles.icon} />
        <p className={styles.choiceText}>Delete label</p>
      </div>
    </div>
  );
}

DotsOverlay.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default DotsOverlay;
