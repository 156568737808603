import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { Trash } from 'frontend/assets/icons';
import { Button, Input } from 'frontend/components';
import { chain, required } from 'frontend/form/validators';
import { IDType } from 'frontend/propTypes';

import styles from './Patterns.scss';
import { pattern } from '../../validators';

const Regex = ({ fieldName, index, parentId, change, currentPatterns, readOnly }) => {
  const validate = useMemo(() => chain(required, pattern(parentId)), [parentId]);
  const remove = useCallback(() => {
    change(fieldName, [...currentPatterns.slice(0, index), ...currentPatterns.slice(index + 1)]);
  }, [change, currentPatterns, fieldName, index]);

  return (
    <div className={styles.regex}>
      <div className={cx(styles.regexPart, styles.regexLabel)}>{index + 1}</div>
      <Field
        component={Input}
        className={styles.input}
        name={`${fieldName}.${index}.pattern`}
        validate={validate}
        readOnly={readOnly}
        data-testid={`dialogue-pattern-${index}`}
      />
      <div className={cx(styles.regexButton)}>
        <Button icon={Trash} flat onClick={remove} data-testid={`delete-dialogue-pattern-${index}`} />
      </div>
    </div>
  );
};

Regex.propTypes = {
  fieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  parentId: IDType,
  readOnly: PropTypes.bool,
  change: PropTypes.func.isRequired,
  currentPatterns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Regex;
