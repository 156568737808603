import cx from 'classnames';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

import { capitalize } from 'frontend/utils';

import styles from './SidebarNavigation.scss';

interface NavigationItem {
  key: string;
  url: string;
  title?: string;
  className?: string;
  hide?: boolean;
}

interface NavigationItemProps {
  item: NavigationItem;
}

const NavigationItem = ({ item }: NavigationItemProps) => {
  const resolvedPath = useResolvedPath(item.url);
  const match = useMatch({ path: resolvedPath.pathname, end: true });
  const linkStyles = cx(styles.link, item?.className ?? '', {
    [styles.linkActive]: !!match,
  });
  return (
    <Link className={linkStyles} to={item.url}>
      {item.title || capitalize(item.key)}
    </Link>
  );
};

interface SidebarNavigationProps {
  /** The list of items to display. If one item object in the array has a `hide` property set to true, the item will be hidden. */
  items: NavigationItem[];
}

/** Displays a list of items. */
const SidebarNavigation = ({ items }: SidebarNavigationProps) => {
  const filteredItems = items.filter((item) => !item.hide);
  return (
    <div className={styles.container}>
      {filteredItems.map((item) => (
        <NavigationItem key={item.key} item={item} />
      ))}
    </div>
  );
};

export default SidebarNavigation;
