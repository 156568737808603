import { camelCase, isEmpty, omit, pick } from 'lodash';

import type { DialogueType, ImageCarouselType, ImageTypeInput } from 'frontend/api/generated';

import { relevantFieldsDifferent } from './relevantFieldsDifferent';

function getFieldName(firstSegment: string, secondSegment?: string): string {
  return camelCase(`${firstSegment ?? ''}_${secondSegment ?? ''}_image_carousels`);
}

// The 'index' fields are not updated when editing - iterating to set the indices equal to the current array order
function redoImageIndices<T extends Partial<ImageTypeInput>>(images: T[]): T[] {
  return images.map((image, index) => ({ ...image, index }));
}

function carouselIsEmpty(carousel: ImageCarouselType): boolean {
  return isEmpty(carousel) || !carousel.images || carousel.images.length === 0;
}

export default function getImageCarouselsUpdate(
  initialValues: Partial<DialogueType>[] | undefined,
  values: Partial<DialogueType>[],
  options: { prefix: string; isNudgeCarousel?: boolean } = { prefix: '' },
) {
  const initial: ImageCarouselType[] = initialValues?.[getFieldName(options.prefix)] ?? [{ images: [] }];
  const current: ImageCarouselType[] = values?.[getFieldName(options.prefix)] ?? [{ images: [] }];

  const carousels = {};

  current.forEach((currentCarousel) => {
    const initialCarousel = initial.find((c) => c.id && c.id === currentCarousel.id);

    const data = omit(
      {
        ...currentCarousel,
        images: redoImageIndices(currentCarousel.images ?? []).map((image) =>
          omit(image, ['file', 'tempId', options.isNudgeCarousel ? 'buttons' : '']),
        ),
        rule: currentCarousel.rule ?? undefined,
      },
      ['tempId'],
    );

    if (!initialCarousel) {
      if (carouselIsEmpty(currentCarousel)) {
        return;
      }
      const fieldName = getFieldName('created', options.prefix);
      carousels[fieldName] = [...(carousels[fieldName] || []), data];
      return;
    }

    if (initialCarousel && carouselIsEmpty(currentCarousel) && initialCarousel.id) {
      const fieldName = getFieldName('deleted', options.prefix);
      carousels[fieldName] = [...(carousels[fieldName] || []), pick(initialCarousel, ['id'])];
      return;
    }

    if (relevantFieldsDifferent([initialCarousel, currentCarousel])) {
      const fieldName = getFieldName('updated', options.prefix);
      carousels[fieldName] = [...(carousels[fieldName] || []), data];
    }
  });

  return carousels;
}
