import { gql } from '@apollo/client';

import { PLATFORM_ALERT_FRAGMENT } from '../fragments';

const GET_PLATFORM_ALERT_PUBLISHED = gql`
  query GetPlatformAlertPublished {
    platformAlert {
      ...PlatformAlert
    }
  }
  ${PLATFORM_ALERT_FRAGMENT}
`;
export default GET_PLATFORM_ALERT_PUBLISHED;
