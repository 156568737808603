import cx from 'classnames';
import { useCallback } from 'react';

import type { AnyRole } from 'frontend/features/Organization/types';
import { normalizeKey } from 'frontend/utils';

import styles from './styles.scss';
import { ROLE_DESCRIPTIONS } from '../../../../constants';
import { roleIdToFieldName } from '../../utils';

function Role({
  role,
  values,
  change,
}: {
  role: AnyRole;
  values: Record<string, string | boolean | unknown[]>;
  change: CallableFunction;
}) {
  const { id, title } = role;
  const description = role.description || ROLE_DESCRIPTIONS[normalizeKey(title)];
  const fieldName = roleIdToFieldName(id);
  const isSelected = values[fieldName];
  const onClick = useCallback(() => change(fieldName, !isSelected), [change, fieldName, isSelected]);

  return (
    <button
      className={cx(styles.selectionBox, {
        [styles.selectionBoxActive]: isSelected,
      })}
      onClick={onClick}
      type="button"
    >
      <h3 className={styles.roleTitle}>{title}</h3>
      <p className={styles.roleDescription}>{description || 'No description'}</p>
    </button>
  );
}

export default Role;
