import React, { type ComponentType, type SVGProps } from 'react';

import { Modal } from 'frontend/features/Modals';

import styles from './InfoModal.scss';

interface InfoModal {
  hide: () => Promise<void>;
  args: {
    title: string;
    text: string;
    icon?: ComponentType<SVGProps<SVGSVGElement>> | undefined;
  };
}

const InfoModal = ({ hide, args: { title, text, icon } }: InfoModal) => (
  <Modal icon={icon} headerClassName={styles.infoModalHeader} hide={hide} title={title} footer hideCancel>
    <Modal.Content className={styles.infoModalContent}>{text}</Modal.Content>
  </Modal>
);

export default InfoModal;
