import { useForm } from 'react-final-form';

import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useKeyDown } from 'frontend/hooks';
import type { BotLanguage } from 'frontend/types/language';

import AddLanguage from './modals/AddLanguage';

const AddLanguageButton = () => {
  const { change, getState } = useForm();
  const [showAddLanguageModal] = useModal(AddLanguage);

  const onClick = () => {
    const { values } = getState();
    showAddLanguageModal({ change, languages: values.languages as BotLanguage[] });
  };

  const onKeyDown = useKeyDown({ action: onClick });

  return <Button onClick={onClick} onKeyDown={onKeyDown} text="Add language" />;
};

export default AddLanguageButton;
