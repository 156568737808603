import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { commentsCountCacheUpdate } from 'frontend/features/Inbox/views/Comments/utils';

export default () => {
  const { cache } = useApolloClient();

  const onChatCommentAdded = useCallback(
    ({ chat: chatId }) => {
      commentsCountCacheUpdate({ cache, chatId, countDifference: 1 });
    },
    [cache],
  );

  const onChatCommentDeleted = useCallback(
    ({ chat: chatId }) => {
      commentsCountCacheUpdate({ cache, chatId, countDifference: -1 });
    },
    [cache],
  );

  return { onChatCommentAdded, onChatCommentDeleted };
};
