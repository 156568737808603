import { gql } from '@apollo/client';

import { CHANGELOG_ENTRY_FRAGMENT } from 'frontend/features/Admin/views/Changelog/graphql';

export const CLEAR_FEATURED_CHANGELOG_ENTRY = gql`
  mutation ClearFeaturedChangelogEntry {
    clearFeaturedChangelogEntry {
      ...ChangelogEntry
    }
  }
  ${CHANGELOG_ENTRY_FRAGMENT}
`;
