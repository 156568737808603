import { gql } from '@apollo/client';

import { ACTIVITY_GROUP } from '../fragments';

export default gql`
  query ActivityGroups($botId: ID, $skillId: ID, $limit: Int, $updatedBefore: DateTime, $updatedAfter: DateTime) {
    activityGroups(
      botId: $botId
      skillId: $skillId
      limit: $limit
      updatedBefore: $updatedBefore
      updatedAfter: $updatedAfter
    ) {
      id
      hasMore
      groups {
        ...ActivityGroup
      }
    }
  }
  ${ACTIVITY_GROUP}
`;
