import { useRef, useState } from 'react';
import type {
  ControllerRenderProps,
  FieldValues,
  Path,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from 'react-hook-form';
import InputAutosize from 'react-input-autosize';

import { CheckboxCheck, Close, Drag, Edit, Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import { useSortableItem } from 'frontend/features/Dnd/hooks';

import styles from './FakeButton.scss';
import type { CustomerFeedbackInputs } from '../CustomerFeedback';
import type { RHF_FORMS_NAME } from '../constants';

interface Props<T extends FieldValues> {
  field: ControllerRenderProps<
    CustomerFeedbackInputs,
    | `feedbackForms.${number}.predefinedButtons.sentiments.positive.buttons.${number}.text`
    | `feedbackForms.${number}.predefinedButtons.sentiments.noSentiment.buttons.${number}.text`
    | `feedbackForms.${number}.predefinedButtons.sentiments.negative.buttons.${number}.text`
    | `feedbackForms.${number}.predefinedButtons.sentiments.neutral.buttons.${number}.text`
  >;
  /** The button index among other buttons of the same sentiment. */
  index: number;
  removeButton: UseFieldArrayRemove;
  updateButton:
    | UseFieldArrayUpdate<
        CustomerFeedbackInputs,
        `${typeof RHF_FORMS_NAME}.${number}.predefinedButtons.sentiments.noSentiment.buttons`
      >
    | UseFieldArrayUpdate<
        CustomerFeedbackInputs,
        `${typeof RHF_FORMS_NAME}.${number}.predefinedButtons.sentiments.positive.buttons`
      >
    | UseFieldArrayUpdate<
        CustomerFeedbackInputs,
        `${typeof RHF_FORMS_NAME}.${number}.predefinedButtons.sentiments.negative.buttons`
      >
    | UseFieldArrayUpdate<
        CustomerFeedbackInputs,
        `${typeof RHF_FORMS_NAME}.${number}.predefinedButtons.sentiments.neutral.buttons`
      >;
  buttonId: string;
  name: Path<T>;
}

export default function FakeButton<T extends FieldValues>({
  field,
  index,
  removeButton,
  updateButton,
  buttonId,
}: Props<T>): React.JSX.Element {
  const [intermediateValue, setIntermediateValue] = useState(field.value);
  const [isEditing, setIsEditing] = useState(false);
  const valueBeforeEditing = useRef(intermediateValue);

  const { style, dragAttributes, onPointerDown, setNodeRef } = useSortableItem({ id: buttonId });

  const handleDelete = () => {
    removeButton(index);
  };

  const handleEdit = () => {
    valueBeforeEditing.current = intermediateValue;
    setIsEditing(true);
  };

  const handleConfirmEdit = () => {
    updateButton(index, { id: buttonId, text: intermediateValue });
    setIsEditing(false);
  };

  const handleAbortEdit = () => {
    setIntermediateValue(valueBeforeEditing.current);
    setIsEditing(false);
  };

  return (
    <div className={styles.container}>
      <div ref={setNodeRef} {...dragAttributes} style={style} className={styles.inputWrapper}>
        <InputAutosize
          inputClassName={styles.fakeButton}
          translate="no"
          value={intermediateValue}
          readOnly={!isEditing}
          onInput={(e) => setIntermediateValue((e.target as HTMLInputElement).value)}
        />
        {!isEditing && (
          <div className={styles.iconsWrapper}>
            <button type="button" className={styles.iconButton} onClick={handleEdit}>
              <Icon component={Edit} />
            </button>
            <button type="button" className={styles.iconButton} onPointerDown={onPointerDown}>
              <Icon component={Drag} />
            </button>
            <button type="button" className={styles.iconButton} onClick={handleDelete}>
              <Icon component={Trash} className={styles.iconTrash} />
            </button>
          </div>
        )}
      </div>

      {isEditing && (
        <div className={styles.editButtonsWrapper}>
          <button type="button" onClick={handleConfirmEdit}>
            <Icon component={CheckboxCheck} />
          </button>
          <button type="button" onClick={handleAbortEdit}>
            <Icon component={Close} />
          </button>
        </div>
      )}
    </div>
  );
}
