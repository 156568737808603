import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Button, ImageAvatar, Panel } from 'frontend/components';
import { getUserName, hasRequestedContact } from 'frontend/features/Inbox/utils';
import { useToggle } from 'frontend/hooks';
import { timeAgo } from 'frontend/utils';

import styles from './styles.scss';
import { useChats } from '../../hooks';
import dashboardStyles from '../../views/styles.scss';

const MIN_ITEMS = 5;

interface ActionsListProps {
  className?: string;
}

const ActionsList = ({ className }: ActionsListProps) => {
  const [showAll, toggleShowAll] = useToggle();
  const { chats, loading, error } = useChats();

  if (loading || error) return null;
  if (chats.length === 0) return null;

  const amountToShow = showAll ? chats.length : MIN_ITEMS;

  return (
    <>
      <div className={cx(className, dashboardStyles.sectionHeader)}>
        <h3>Actions needed {chats && <span>{chats.length}</span>}</h3>
      </div>
      <div>
        {chats.slice(0, amountToShow).map((chat) => (
          <Link to={`../inbox/chat/${chat.id}`} key={chat.id}>
            <Panel size="medium" hover className={styles.panel}>
              <div className={styles.actionInfo}>
                <ImageAvatar className={styles.avatar} img={chat.avatarUrl} autoUser={chat.autoUser} size="small" />
                <div className={styles.textContainer}>
                  {chat.hasRequestedTakeover && (
                    <>
                      <span>Requested handover</span>
                      <p>{`${getUserName(chat)} requested handover ${timeAgo(
                        chat.requestedHandoverAt ?? chat.updated,
                      )}`}</p>
                    </>
                  )}
                  {hasRequestedContact(chat) && (
                    <>
                      <span>Requested contact</span>
                      <p>{`${getUserName(chat)} requested contact ${timeAgo(chat.updated)}`}</p>
                    </>
                  )}
                </div>
              </div>
            </Panel>
          </Link>
        ))}
      </div>
      {chats.length > MIN_ITEMS && (
        <div className="f-c">
          <Button onClick={toggleShowAll}>{showAll ? 'Show less' : `Show ${chats.length - MIN_ITEMS} more`}</Button>
        </div>
      )}
      <div className="m-b-5" />
    </>
  );
};

export default ActionsList;
