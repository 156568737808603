import PropTypes from 'prop-types';

import { BUTTON_CONTACT_DETAILS_TYPES } from 'frontend/features/Build/constants';
import { Equals, IDType } from 'frontend/propTypes';
import { CONTACT_DETAILS_STATUS } from 'frontend/state/dux/inbox';

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export const TicketType = PropTypes.shape({
  id: IDType.isRequired,
  status: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    id: IDType.isRequired,
    fullName: PropTypes.string.isRequired,
  }),
});

export interface Ticket {
  id: string | number;
  status: string;
  profile: {
    id: string;
    fullName: string;
  };
}

export const ReplyCandidateType = PropTypes.shape({
  id: IDType.isRequired,
  dialogueId: PropTypes.string.isRequired,
  handler: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  sample: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  replies: PropTypes.arrayOf(
    PropTypes.shape({
      id: IDType.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
});

export const InboxAgentType = PropTypes.shape({
  id: IDType.isRequired,
  email: PropTypes.string,
  profile: PropTypes.shape({
    id: IDType.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    avatarUrl: PropTypes.string.isRequired,
  }),
});

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export const ContactDetailsType = PropTypes.exact({
  contact_type: PropTypes.oneOf(Object.values(BUTTON_CONTACT_DETAILS_TYPES)),
  status: PropTypes.oneOf(Object.keys(CONTACT_DETAILS_STATUS)),
});

export interface ContactDetails {
  contact_type: (typeof BUTTON_CONTACT_DETAILS_TYPES)[keyof typeof BUTTON_CONTACT_DETAILS_TYPES];
  status: (typeof CONTACT_DETAILS_STATUS)[keyof typeof CONTACT_DETAILS_STATUS];
}

/** @deprecated For new uses, use TS one in `generated.ts`. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS, if not used at runtime.
export const AttachmentType = PropTypes.shape({
  id: IDType,
  created: PropTypes.string,
  chatMessageId: PropTypes.string,
  storagePath: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string,
  source: PropTypes.string,
  sender: PropTypes.string, // FIX when moved to tsx
  url: PropTypes.string,
  __typename: Equals('AttachmentType'),
});

export const WebhookDataType = PropTypes.shape({
  appId: PropTypes.number,
  appName: PropTypes.string,
  botId: PropTypes.number.isRequired,
  created: PropTypes.string,
  data: PropTypes.string,
  errorMessage: PropTypes.string,
  headers: PropTypes.string,
  id: IDType,
  loggedBy: PropTypes.string,
  responseData: PropTypes.string,
  responseHeaders: PropTypes.string,
  responseText: PropTypes.string,
  statusCode: PropTypes.number,
  time: PropTypes.string,
  url: PropTypes.string,
  __typename: Equals('WebhookRequestType'),
});
