import { EditorState, Modifier } from 'draft-js';

import createSelectionState from './createSelectionState';

export default (state, setState, blockKey, start, end, newText = '') => {
  const content = state.getCurrentContent();
  const initialSelection = state.getSelection();
  const selection = createSelectionState({ anchorKey: blockKey, start, end });

  const contentWithoutTag = Modifier.applyEntity(content, selection, null);
  const contentWithNewText = Modifier.replaceText(contentWithoutTag, selection, newText);
  const stateWithoutTag = EditorState.push(state, contentWithNewText, 'apply-entity');

  const newState = EditorState.forceSelection(stateWithoutTag, initialSelection);

  setState(newState);
};
