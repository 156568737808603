import { useCallback, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Button, Input } from 'frontend/components';
import { GoogleLoginButton } from 'frontend/features/Login';
import { chain, email, required } from 'frontend/form/validators';
import { useGoogleLogin } from 'frontend/hooks';
import { login, selectLoginError } from 'frontend/state/dux/auth';
import { useAppDispatch } from 'frontend/state/hooks';

import styles from '../../Invitation.scss';
import { InvitationType } from '../../propTypes';
import ContentBox from '../ContentBox';
import Info from '../Info';

const LoginForm = ({ invitation }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = useMemo(() => ({ username: invitation.email }), [invitation.email]);
  const { pathname, search } = useLocation();
  const resetPasswordUrl = `/reset_password/?next=${window.encodeURI(pathname + search)}`;
  const loginError = useSelector(selectLoginError);
  const onGoogleResponse = useGoogleLogin();

  const handleLogin = useCallback(
    async ({ username, password }) => {
      if (isSubmitting) return;
      setIsSubmitting(true);

      try {
        await dispatch(login(username, password));
      } finally {
        setIsSubmitting(false);
      }
    },
    [dispatch, isSubmitting],
  );

  return (
    <ContentBox>
      <>
        <Info invitation={invitation} />
        <p className={styles.description}>Accept the invitation by logging in:</p>
        <Form
          onSubmit={handleLogin}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                component={Input}
                className="m-b-md"
                name="username"
                placeholder="Email"
                inputType="email"
                label="Email"
                readOnly
                validate={chain([email, required])}
              />
              <Field
                component={Input}
                className="m-b-md"
                name="password"
                placeholder="Password"
                label="Password"
                inputType="password"
                validate={required}
                autoFocus
              />
              <div className={styles.loginError}>{loginError}</div>
              <div className={styles.loginSubmitForgot}>
                <Link to={resetPasswordUrl}>Forgotten password</Link>
                <Button color="primary" type="submit" isSubmitting={isSubmitting} text="Log in" />
              </div>
              <div className={styles.ssoLogin}>
                <GoogleLoginButton onGoogleResponse={onGoogleResponse} />
              </div>
            </form>
          )}
        />
      </>
    </ContentBox>
  );
};

LoginForm.propTypes = {
  invitation: InvitationType.isRequired,
};

export default LoginForm;
