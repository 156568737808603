import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { getReplyCandidates } from 'frontend/api/queries';
import { Loader } from 'frontend/components';
import { IDType } from 'frontend/propTypes';

import styles from './styles.scss';
import ReplyCandidate from '../ReplyCandidate';

const ReplyCandidates = ({ languageCode, messageId, botId }) => {
  const { data, loading } = useQuery(getReplyCandidates, { variables: { id: messageId, botId, languageCode } });

  const candidates = useMemo(
    () =>
      orderBy(
        (data?.chatMessage?.replyCandidates ?? []).filter(({ selected }) => !selected),
        'rank',
        'asc',
      ),
    [data],
  );

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>Alternative dialogues</div>
        <Loader type="reply_candidates" />
      </div>
    );
  }

  if (!candidates.length) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Potential dialogues</div>
      {candidates.map((replyCandidate) => (
        <ReplyCandidate key={replyCandidate.id} replyCandidate={replyCandidate} botId={botId} />
      ))}
    </div>
  );
};

ReplyCandidates.propTypes = {
  messageId: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  botId: IDType.isRequired,
};

export default ReplyCandidates;
