import { useMemo, useState } from 'react';

export default ({ objects, pageSize }) => {
  const [page, setPage] = useState(1);
  const pages = Math.ceil(objects.length / pageSize);
  const currentObjects = useMemo(
    () => objects.slice((page - 1) * pageSize, page * pageSize),
    [objects, page, pageSize],
  );

  return [currentObjects, page, pages, setPage];
};
