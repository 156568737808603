import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { DeleteModal } from 'frontend/features/Modals';

import { ACTIVATE_ORGANIZATION, DEACTIVATE_ORGANIZATION } from '../../mutations';

const OrganizationActivation = ({ args, hide }) => {
  const { reactivate, organization } = args;
  const [mutate] = useMutation(reactivate ? ACTIVATE_ORGANIZATION : DEACTIVATE_ORGANIZATION);
  const action = reactivate ? 'Reactivate' : 'Deactivate';

  const onSubmit = useCallback(async () => {
    await mutate({ variables: { id: organization.id } });
    hide();
  }, [hide, organization, mutate]);

  return (
    <DeleteModal
      onSubmit={onSubmit}
      text={`${action} organization ${organization.name}?`}
      titleName="organization"
      action={action}
      hide={hide}
    />
  );
};

OrganizationActivation.propTypes = {
  args: PropTypes.exact({ organization: PropTypes.shape({}).isRequired, reactivate: PropTypes.bool }).isRequired,
  hide: PropTypes.func.isRequired,
};

export default OrganizationActivation;
