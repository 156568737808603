import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { ConnectorDocument, CreateConnectorDocument } from 'frontend/api/generated';
import { PageContent } from 'frontend/components';
import { useDocumentTitle, useIsRunning, useToast } from 'frontend/hooks';

import ConnectorForm from './ConnectorForm';

const update = appendArrayInCache({
  pathToItemInMutationData: 'createConnector',
  pathToArrayInCache: 'allConnectors',
  query: ConnectorDocument,
});

const INITIAL_VALUES = { isActive: true };

const CreateConnector = () => {
  useDocumentTitle('New connector');
  const navigate = useNavigate();
  const toast = useToast();

  const [createConnectorMutation] = useMutation(CreateConnectorDocument, {
    onCompleted: ({ createConnector }) => {
      toast.success(`Plugin ${createConnector.name} created`);
      navigate(`/admin/connector/${createConnector.id}`);
    },
    update,
  });

  const onSubmit = useCallback(
    async (values) => {
      await createConnectorMutation({ variables: values });
    },
    [createConnectorMutation],
  );

  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmit);

  return (
    <div>
      <PageContent>
        <ConnectorForm
          onSubmit={onSubmitWithRunning}
          initialValues={INITIAL_VALUES}
          operation="create"
          isSubmitting={isSubmitting}
        />
      </PageContent>
    </div>
  );
};

export default CreateConnector;
