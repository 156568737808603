import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { LineChart, Metric } from 'frontend/components/Stats';
import { Grid, LineChartModal, StatsPanel } from 'frontend/features/Analytics/components';
import { useModal } from 'frontend/features/Modals';

import styles from '../../../styles.scss';

const LineChartPanel = ({ granularity, isPreview, isRate, title, loading, metrics, chartData, onHeaderIconClick }) => {
  const ModalComponent = (
    <StatsPanel title={title}>
      <LineChart data={[chartData]} granularity={granularity} isRate={isRate} />
    </StatsPanel>
  );

  const [showDetailModal] = useModal(LineChartModal);

  const onDetailCallback = useCallback((content) => showDetailModal({ content }), [showDetailModal]);

  chartData.id = `${title} per ${granularity}`;

  return (
    <StatsPanel
      title={title}
      loading={loading}
      onShowDetailClick={() => onDetailCallback(ModalComponent)}
      onHeaderIconClick={onHeaderIconClick}
    >
      <Grid flow="row">
        {metrics?.map(
          ({ metric, text, postfix }) =>
            !Number.isNaN(metric) && (
              <StatsPanel key={text} className={styles.statsPanel} nested>
                <Metric metric={metric} postfix={postfix} topText={text} center={false} />
              </StatsPanel>
            ),
        )}
        <LineChart data={[chartData]} granularity={granularity} isPreview={isPreview} isRate={isRate} />
      </Grid>
    </StatsPanel>
  );
};

LineChartPanel.propTypes = {
  granularity: PropTypes.string.isRequired,
  isPreview: PropTypes.bool,
  isRate: PropTypes.bool,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      postfix: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  chartData: PropTypes.shape({}),
  onHeaderIconClick: PropTypes.func,
};

export default LineChartPanel;
