import { orderBy } from 'lodash';
import React from 'react';
import { useField, useForm } from 'react-final-form';

import { Checkbox } from 'frontend/components';
import { useOrganizationBotsWithPermission } from 'frontend/features/Inbox/hooks';

// TODO remove when we get rid of the organization inbox
const BotsFilter = () => {
  const { change } = useForm();
  const {
    input: { value },
  } = useField('botFilter');
  const { bots } = useOrganizationBotsWithPermission();

  return orderBy(bots, 'name').map(({ id, name }) => (
    <Checkbox
      key={id}
      input={{
        name: `bot-${id}`,
        checked: value.includes(`bot-${id}`),
        onChange: () => {},
      }}
      onClick={() => {
        const valueCopy = [...value];
        if (valueCopy.includes(`bot-${id}`)) {
          valueCopy.splice(valueCopy.indexOf(`bot-${id}`), 1);
        } else {
          valueCopy.push(`bot-${id}`);
        }
        change('botFilter', valueCopy);
      }}
      label={name}
    />
  ));
};

export default BotsFilter;
