import { useState } from 'react';

import { DownloadCloud } from 'frontend/assets/icons';
import { CheckboxOnly, SelectLanguage } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import type { LanguageOrVariant } from 'frontend/hooks/useLanguages';

import styles from './ExportCreateModal.scss';

interface ExportCreateModalProps {
  hide: () => Promise<void>;
  args: {
    handleImport: (includeDisabledDialogues: boolean) => Promise<Record<string, any> | void>;
    languagesAndVariants: LanguageOrVariant[];
    includeDisabledDialogues: boolean;
  };
}

const ExportCreateModal = ({ hide, args: { handleImport, languagesAndVariants } }: ExportCreateModalProps) => {
  const [disabledDialogues, setDisabledDialogues] = useState(true);
  return (
    <Modal
      title="Create new workspace version"
      icon={DownloadCloud}
      color="primary"
      hide={hide}
      onOk={() => handleImport(disabledDialogues)}
      onOkText="Start"
    >
      <Modal.Content className={styles.modalContent}>
        <p>
          Download your data as a JSON file to edit your content in other systems.
          <br />
          At a later time you can import the file again to update your bot.
        </p>
        {(languagesAndVariants?.length || 0) > 1 && (
          <div className={styles.languageSelection}>
            <label htmlFor="pageBarSelectLanguage">Select a language to export:</label>
            <div>
              <SelectLanguage className={styles.selectLanguage} languages={languagesAndVariants} />
            </div>
          </div>
        )}
        <label className={styles.checkboxLabel}>
          <CheckboxOnly
            className={styles.checkbox}
            checked={disabledDialogues}
            onChange={() => {
              setDisabledDialogues(!disabledDialogues);
            }}
          />
          Export disabled dialogues
        </label>
      </Modal.Content>
    </Modal>
  );
};

export default ExportCreateModal;
