import { lowerFirst } from 'lodash';

import { DeleteModal, Modal } from 'frontend/features/Modals';

interface Props {
  hide(): Promise<void>;
  args: {
    enable: (...args: any[]) => Promise<void>;
    featureTitle: string;
    description: string;
    /** If true, the beta feature can only be activated once and never disabled. */
    oneTimeOnly?: boolean;
  };
}

export default function EnableBetaFeature({
  hide,
  args: { enable, featureTitle, description, oneTimeOnly },
}: Props): React.JSX.Element {
  const modalTextContent = (
    <>
      <p>
        <b>{featureTitle}</b> is a beta feature and should be used with care.
      </p>
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </>
  );

  if (oneTimeOnly) {
    return (
      <DeleteModal hide={hide} onSubmit={enable} action="enable" name={featureTitle} confirmName>
        {modalTextContent}
      </DeleteModal>
    );
  }

  return (
    <Modal
      title={`Enable ${lowerFirst(featureTitle)}`}
      hide={hide}
      color="warning"
      onOk={enable}
      onOkText={`Enable ${lowerFirst(featureTitle)}`}
    >
      <Modal.Content>{modalTextContent}</Modal.Content>
    </Modal>
  );
}
