import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import { DELETE_BOT_BACKUP, DELETE_MESSAGE_BACKUP } from '../../../mutations';

interface Props {
  hide(): void;
  args: {
    backup: { createdAt: string; id: string };
    botId: string | number;
    type: 'BOT' | 'MESSAGE';
  };
}

export default function DeleteBackup({ hide, args: { backup, botId, type } }: Props): React.JSX.Element {
  const toast = useToast();

  const update = (cache) => {
    const fieldName = `${type.toLowerCase()}Backups`;
    cache.modify({
      fields: {
        [fieldName]: (currentBackups, { readField }) =>
          currentBackups.filter((current) => readField('id', current) !== backup.id),
      },
    });
    toast.success(`${capitalize(type)} backup was deleted`);
  };

  const [deleteBotBackup] = useMutation(DELETE_BOT_BACKUP, { variables: { botId, id: backup.id }, update });
  const [deleteMessageBackup] = useMutation(DELETE_MESSAGE_BACKUP, { variables: { botId, id: backup.id }, update });

  return (
    <DeleteModal
      name={`backup from ${format(backup.createdAt, DATE_FORMAT_WITH_TIME)}`}
      titleName="backup"
      hide={hide}
      onSubmit={type === 'BOT' ? deleteBotBackup : deleteMessageBackup}
    />
  );
}
