import { useCallback, useMemo, useRef } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { DropdownButton } from 'frontend/components';
import type { AnyRole } from 'frontend/features/Organization/types';

import styles from './RoleTitle.scss';
import RoleTitleInput from './RoleTitleInput';

const RoleTitle = ({ role }: { role: AnyRole }) => {
  const form = useForm();
  const { values } = useFormState();
  const customRoles = useMemo(() => values?.customRoles ?? [], [values]);
  const roleIndex = customRoles.findIndex(({ id }) => id === role.id);
  const inputRef = useRef<HTMLInputElement>(null);

  const deleteRole = useCallback(() => {
    form.change(
      'customRoles',
      customRoles.filter(({ id }) => id !== role.id),
    );
  }, [customRoles, form, role.id]);

  const options = useMemo(
    () => [
      {
        title: 'Rename',
        key: `role-${role.id}-rename`,
        onClick: () => inputRef?.current?.focus(),
        className: styles.optionsItem,
      },
      { title: 'Delete', key: `role-${role.id}-title`, onClick: deleteRole, className: styles.optionsItem },
    ],
    [deleteRole, role.id],
  );

  return (
    <div className={styles.roleTitle}>
      <Field component={RoleTitleInput} name={`customRoles[${roleIndex}].title`} inputRef={inputRef} />
      <DropdownButton wrapperClassName={styles.options} actions={options}>
        <span className={styles.optionsButton} />
      </DropdownButton>
    </div>
  );
};

export default RoleTitle;
