import { Close, Exclamation } from 'frontend/assets/icons';
import { Button, CopyToClipBoardButton, Icon } from 'frontend/components';

import ToastWrapper from './ToastWrapper';
import styles from '../styles.scss';

const stripPeriod = (message) => message.replace(/\.(\s)*$/, '');

interface Props {
  message: string;
  sentryId: string;
  closeToast: () => void;
  to?: string;
}

const ToastSentryError = ({ message, sentryId, closeToast, to }: Props) => (
  <div>
    <ToastWrapper to={to}>
      <div className={styles.icon}>
        <Icon component={Exclamation} color="warning" />
      </div>
      <div>
        <p className={styles.errorMessage}>{stripPeriod(message)}.</p>
        <p>
          Please send us this error code when reporting <code className={styles.sentryId}>{sentryId}</code>
        </p>
      </div>
      <Button icon={Close} className={styles.closeToast} onClick={closeToast} flat />
    </ToastWrapper>
    <hr />
    <CopyToClipBoardButton text="Copy error ID" copyText={sentryId} />
  </div>
);

export default ToastSentryError;
