import { convertToRaw } from 'draft-js';

import composerBlockToFormValues from './composerBlockToFormValues';

const rawToValues = ({ blocks = [], entityMap }) =>
  blocks.reduce(
    ({ text: currentText, instances: currentInstances }, { text, entityRanges }, idx) => {
      const instances = entityRanges.map(({ key, offset: start, length }) => ({
        ...entityMap[key].data,
        start,
        end: start + length,
      }));

      const { text: formText, instances: formInstances } = composerBlockToFormValues(text, instances);

      const isLastBlock = idx === blocks.length - 1;
      const updatedText = `${currentText}${formText}${isLastBlock ? '' : '\n'}`;

      const blockStart = currentText.length;
      const newInstances = formInstances.map(({ start, end, ...rest }) => ({
        ...rest,
        start: start + blockStart,
        end: end + blockStart,
      }));

      return { text: updatedText, instances: [...currentInstances, ...newInstances] };
    },
    { text: '', instances: [] },
  );

export default (editorState) => rawToValues(convertToRaw(editorState.getCurrentContent()));
