import { validate } from 'email-validator';

export function parseEmailAddress(text: string): string {
  const match = text.match(/^(?<name>.+) <(?<email>.+)>$/);
  if (match?.groups?.name && match?.groups?.email) {
    return match.groups.email;
  }
  return text;
}
export default (texts: string[]): undefined | string => {
  if (!Array.isArray(texts) || !texts.length) return 'Invalid email address';

  const numValid = texts.map(parseEmailAddress).filter(validate).length;
  const plural = texts.length - numValid > 1 ? 'es' : '';
  if (numValid !== texts.length) return `Invalid email address${plural}`;

  return undefined;
};
