import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  tempId: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  productUrl: PropTypes.string,
  price: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  altText: PropTypes.string,
  newTab: PropTypes.bool,
});
