import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { LoaderSwitch } from 'frontend/components';
import NotFound from 'frontend/features/NotFound/NotFound';
import { useToast } from 'frontend/hooks';

import PlatformAlertForm from '../PlatformAlertForm';
import { UPDATE_PLATFORM_ALERT } from '../mutations';
import { GET_PLATFORM_ALERT } from '../queries';

const UpdatePlatformAlert = () => {
  const [updatePlatformAlertMutation] = useMutation(UPDATE_PLATFORM_ALERT);
  const { alertId } = useParams();
  const toast = useToast();
  const { data, loading } = useQuery(GET_PLATFORM_ALERT, { variables: { alertId } });
  const onSubmit = useCallback(
    async (values) => {
      const platformAlertInput = omit(values, ['__typename', 'createdAt', 'id']);
      await updatePlatformAlertMutation({
        variables: { alertId: values.id, platformAlertInput },
      });
      toast.success('Platform alert updated');
    },
    [toast, updatePlatformAlertMutation],
  );

  if (!loading && !data?.platformAlert) {
    return <NotFound text="Platform alert not found" />;
  }

  return (
    <LoaderSwitch loading={loading}>
      <PlatformAlertForm onSubmit={onSubmit} initialValues={data?.platformAlert} />
    </LoaderSwitch>
  );
};
export default UpdatePlatformAlert;
