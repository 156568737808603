import { at, orderBy } from 'lodash';

import { DIALOGUE_TYPES } from 'frontend/constants';

import byTitle from './byTitle';

const byType = ({ dialogueType }) =>
  at(DIALOGUE_TYPES, ['SAMPLES', 'KEYWORDS', 'PATTERNS', 'TRIGGER']).indexOf(dialogueType);

export default (dialogues, currentLanguage) => orderBy(dialogues, [byType, byTitle(currentLanguage)]);
