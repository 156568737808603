import React from 'react';
import { useField, useForm } from 'react-final-form';

import { Checkbox, LoaderSwitch } from 'frontend/components';
import { useInboxLanguages } from 'frontend/features/Inbox/hooks';

const LanguageFilter = () => {
  const { languages, loading: languagesLoading } = useInboxLanguages();

  const { change } = useForm();
  const {
    input: { value },
  } = useField('languageFilter');

  return (
    <LoaderSwitch loading={languagesLoading}>
      {languages.map(({ code, name }) => (
        <Checkbox
          key={code}
          input={{
            name: code,
            checked: value.includes(code),
          }}
          onClick={() => {
            const valueCopy = [...value];
            if (valueCopy.includes(code)) {
              valueCopy.splice(valueCopy.indexOf(code), 1);
            } else {
              valueCopy.push(code);
            }
            change('languageFilter', valueCopy);
          }}
          label={name}
        />
      ))}
    </LoaderSwitch>
  );
};

export default LanguageFilter;
