import cx from 'classnames';
import React from 'react';

import Button from 'frontend/components/Button';
import Dropdown from 'frontend/components/Dropdown';

import styles from './IncludeExclude.scss';

interface IncludeExclude {
  isExcluded: boolean;
  onIncludeExcludeChange: (filterKey: string, state: 'include' | 'exclude') => void;
  filterKey: string;
}

const IncludeExclude = ({ isExcluded, onIncludeExcludeChange, filterKey }: IncludeExclude) => (
  <Dropdown
    element={Button}
    elementProps={{
      color: 'white',
      size: 'small',
      className: styles.horizontalDropdownButton,
    }}
    position="bottom-right"
    overlay={
      <Dropdown.MenuOverlay
        wrapperClassName={styles.includeExcludeDropdownMenuWrapper}
        options={[
          {
            className: cx(styles.includeExcludeOption, { [styles.activeIncludeExcludeOption]: !isExcluded }),
            text: 'Include',
            key: 'include',
            onClick: () => onIncludeExcludeChange(filterKey, 'include'),
          },
          {
            className: cx(styles.includeExcludeOption, { [styles.activeIncludeExcludeOption]: isExcluded }),
            text: 'Exclude',
            key: 'exclude',
            onClick: () => onIncludeExcludeChange(filterKey, 'exclude'),
          },
        ]}
      />
    }
  >
    {isExcluded ? 'Excl' : 'Incl'}
  </Dropdown>
);

export default IncludeExclude;
