import PropTypes from 'prop-types';

const Diamond = ({ title, color = '#0069ff' }) => (
  <svg width="9px" height="9px" viewBox="0 0 9 9">
    <title>{title || 'Diamond icon'}</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Build-Library" transform="translate(-33.000000, -122.000000)" fill={color} fillRule="nonzero">
        <g id="Group-9-Copy" transform="translate(34.000000, 122.000000)">
          <g id="greeting" transform="translate(0.000000, 1.000000)">
            <rect
              id="Rectangle-10-Copy"
              transform="translate(3.616117, 3.616117) rotate(45.000000) translate(-3.616117, -3.616117) "
              x="0.352029227"
              y="0.352029227"
              width="6.52817459"
              height="6.52817459"
              rx="1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Diamond.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

export default Diamond;
