import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Loader, PageContent, Select, Table } from 'frontend/components';
import { NotFound } from 'frontend/features';
import { BackButton } from 'frontend/features/Admin/components';
import { GET_USER_PERMISSIONS } from 'frontend/features/Admin/views/Users/views/UserPermissions/GET_USER_PERMISSIONS';
import { useTabTitle, useUrlSearchParams } from 'frontend/hooks';
import { intersperse } from 'frontend/utils';

const MODELS = Object.freeze({
  ORGANIZATION: 'organizations.organization',
  BOT: 'dashboard.bot',
  SKILL: 'skills.skill',
});

const PermissionsCell = ({ data: perms }) => intersperse(', ', perms);

const objectTableColumns = [
  {
    key: 'pk',
    title: 'Object PK',
  },
  {
    key: 'perms',
    title: 'Permissions',
    component: PermissionsCell,
  },
];
const globalTableColumns = [
  {
    key: 'permission',
  },
];
const UserPermissions = () => {
  const { userId } = useParams();
  const [{ model }, setParams] = useUrlSearchParams();
  const { data, loading } = useQuery(GET_USER_PERMISSIONS, {
    variables: { id: userId, model: model || MODELS.ORGANIZATION },
  });
  const { email, fullName } = data?.user ?? {};
  useTabTitle(`Users: ${fullName || ''} permissions`);

  const onChange = useCallback(
    (e) => {
      setParams({ model: e.target.value });
    },
    [setParams],
  );
  const input = useMemo(
    () => ({
      name: 'model',
      value: model,
      onChange,
    }),
    [model, onChange],
  );

  const globalPermissions = useMemo(
    () => (data?.user?.typePermissions || []).map((permission) => ({ permission })),
    [data?.user?.typePermissions],
  );
  if (loading) {
    return <Loader size="large" />;
  }

  if (!loading && !email) return <NotFound text="User not found" />;

  return (
    <>
      <BackButton />
      <PageContent className="p-t-0">
        <h1>
          Permissions for {fullName} {email}
        </h1>
        <h2>Global permissions</h2>
        {!data.user.typePermissions.length ? (
          <div className="m-b">No global permissions</div>
        ) : (
          <Table className="m-b" columns={globalTableColumns} data={globalPermissions} />
        )}
        <h2>Object permissions</h2>
        <label htmlFor="select-model">Model</label>
        <Select input={input} className="m-b-sm">
          {Object.keys(MODELS).map((val) => (
            <Select.Option key={MODELS[val]} value={MODELS[val]} label={val[0] + val.slice(1).toLowerCase()} />
          ))}
        </Select>
        <Table columns={objectTableColumns} data={data.user.objectPerms} />
      </PageContent>
    </>
  );
};

export default UserPermissions;
