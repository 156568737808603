import { camelCase, omit } from 'lodash';

import getArrayUpdateV2 from 'frontend/utils/getArrayUpdateV2';

const relevantFields = [
  'label',
  'value',
  'buttonType',
  'isActive',
  'index',
  'contactDetails',
  'sliderDetails',
  'emailDetails',
  'hideOutsideBusinessHours',
];

type ArrayUpdateType = { id: string; [key: string]: any }[];

const createPropName = (firstSegment?: string, secondSegment?: string) =>
  camelCase(`${firstSegment ?? ''}_${secondSegment ?? ''}_buttons`);

const fieldsToOmit = ['dialogueLink'];
const clean = (buttons: []): Record<string, any>[] => buttons.map((button) => omit(button, ...fieldsToOmit));

/** Index buttons per rule with `index` property. */
const indexButtonsForBE = (buttons: Record<string, any>[], rules: (string | undefined)[]): void => {
  rules.forEach((ruleId) => {
    if (!ruleId) {
      // Default rule
      const defaultRuleButtons = buttons.filter((button) => !button.rule);
      defaultRuleButtons.forEach((button, index) => {
        button.index = index;
      });
    } else {
      const ruleButtons = buttons.filter((button) => button.rule?.id === ruleId);
      ruleButtons.forEach((button, index) => {
        button.index = index;
      });
    }
  });
};

export default (initialValues: Record<string, any> | undefined, values: Record<string, any>, prefix?: string) => {
  const initialButtons = initialValues?.[createPropName(prefix)] ?? [];

  /* Add `index` to buttons so that BE can know if the order changed */
  const dialogueRules: (string | undefined)[] = [undefined, ...(values.dialogueRules || []).map((r) => r.id)];
  const newCurrentButtons = structuredClone(values?.[createPropName(prefix)]) ?? [];
  indexButtonsForBE(newCurrentButtons, dialogueRules);

  const { created, updated, deleted } = getArrayUpdateV2(
    clean(initialButtons) as ArrayUpdateType,
    clean(newCurrentButtons) as ArrayUpdateType,
    {
      onlyCheckProps: relevantFields,
    },
  );

  return {
    [createPropName('created', prefix)]: created.map((button) => {
      const { id: _tempId, ...pickProps } = button;
      return pickProps;
    }),
    [createPropName('deleted', prefix)]: deleted.map((button) => ({ id: button.id })),
    [createPropName('updated', prefix)]: updated,
  };
};
