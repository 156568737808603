import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { dialogueFeatureTypes } from 'frontend/features/Build/constants';
import { DeleteModal } from 'frontend/features/Modals';

const DeletePluginInstance = ({ hide, args: { setFeatureType, plugin, form } }) => {
  const remove = useCallback(() => {
    const pluginTemp = { pluginId: plugin.id };
    setFeatureType(dialogueFeatureTypes.NONE);
    form.change('pluginTemp', pluginTemp);
  }, [form, plugin, setFeatureType]);

  return (
    <DeleteModal
      titleName="plugin"
      hide={hide}
      onSubmit={remove}
      action="Remove"
      text={`Are you sure you want to remove "${plugin.plugin.name}" from this dialogue? This cannot be undone`}
    />
  );
};

DeletePluginInstance.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    plugin: PropTypes.shape({}).isRequired,
    setFeatureType: PropTypes.func.isRequired,
    form: PropTypes.shape({ change: PropTypes.func.isRequired }).isRequired,
  }).isRequired,
};

export default DeletePluginInstance;
