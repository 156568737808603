import { gql } from '@apollo/client';

export default gql`
  fragment MessageBackup on MessageBackupType {
    id
    status
    createdAt
    backupFrom
    backupTo
  }
`;
