import { DownloadCloud, Trash } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';

import styles from './ExportTableOptions.scss';
import type { ExportStatuses } from '../../../constants';

interface ExportWorkspaceOptionsProps {
  data: {
    status: ExportStatuses;
    downloadExport: () => void;
    deleteExport: () => void;
  };
}

const ExportWorkspaceOptions = ({ data: { status, downloadExport, deleteExport } }: ExportWorkspaceOptionsProps) => (
  <div className={styles.container}>
    {status.toUpperCase() === 'FINISHED' && (
      <Button className="m-r-sm" size="small" onClick={downloadExport} icon={DownloadCloud}>
        Download
      </Button>
    )}
    <button type="button" onClick={deleteExport} className={styles.button}>
      <Icon component={Trash} color="text" className={styles.icon} />
    </button>
  </div>
);

export default ExportWorkspaceOptions;
