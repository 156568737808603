import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { intersperse } from 'frontend/utils';

import styles from './LinkList.scss';

const intersperseComma = intersperse(', ');

const LinkList = ({ type, collection, prefix = '', size = '' }) => {
  if (!collection || !collection.length) {
    return '';
  }
  const classNames = cx(styles.link, {
    [styles.linkSmall]: size === 'small',
  });
  return (
    <div>
      {intersperseComma(
        collection.map(({ id, name }) => (
          <Link className={classNames} key={`link-to-${type}-${id}`} to={`${prefix}/${type}/${id}`}>
            <p>{name}</p>
          </Link>
        )),
      )}
    </div>
  );
};

LinkList.propTypes = {
  type: PropTypes.string.isRequired,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  prefix: PropTypes.string,
  size: PropTypes.string,
};

export default LinkList;
