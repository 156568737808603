import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { SignPolicy } from 'frontend/features/Organization/modals';
import { IDType } from 'frontend/propTypes';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

import styles from './styles.scss';

const Policy = ({ policy, organizationId }) => {
  const { id, name, url, policyChangedAt, signature } = policy;
  const [showSignPolicyModal] = useModal(SignPolicy);

  return (
    <div className={styles.wrapper}>
      <div>
        <a href={url} className={styles.title} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </div>
      {policyChangedAt && <p className={styles.updatedOn}>Updated on: {format(policyChangedAt, DATE_FORMAT_DATE)}</p>}
      {signature && (
        <p className={styles.signedBy}>
          Signed by: {signature.signedBy.username} ({format(signature.updatedAt, DATE_FORMAT_DATE)})
        </p>
      )}
      {(!signature || signature?.isOutdated) && (
        <Button onClick={() => showSignPolicyModal({ policyId: id, organizationId, url, name })}>
          Review and Accept
        </Button>
      )}
    </div>
  );
};
export default Policy;

Policy.propTypes = {
  organizationId: IDType.isRequired,
  policy: PropTypes.shape({
    id: IDType.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    policyChangedAt: PropTypes.string,
    signature: PropTypes.shape({
      signedBy: PropTypes.shape({
        id: IDType.isRequired,
        username: PropTypes.string.isRequired,
      }),
      isOutdated: PropTypes.bool.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }),
  }),
};
