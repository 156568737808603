import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { getAvailableLanguages } from 'frontend/api/queries';
import { Button, Loader, Panel } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useQueryParams } from 'frontend/hooks';

import styles from '../Evaluation.scss';
import { EvaluationSetList } from '../components';
import { ALL_SIMPLE_EVALUATIONS, GET_EVALUATIONS } from '../graphql';
import { CreateEvaluation, CreateSimpleEvaluation } from '../modals';

const EvaluationIndex = () => {
  const { botId } = useParams();
  const [showCreateEvaluationModal] = useModal(CreateEvaluation);
  const [showCreateSimpleEvaluationModal] = useModal(CreateSimpleEvaluation);

  const { data: evalSetData, loading: evalSetLoading } = useQuery(GET_EVALUATIONS, { variables: { botId } });
  const { data: simpleEvalData, loading: simpleEvalLoading } = useQuery(ALL_SIMPLE_EVALUATIONS, {
    variables: { botId },
  });
  const { data: languageData, loading: languagesLoading } = useQuery(getAvailableLanguages);

  const queryParams = useQueryParams();

  if (evalSetLoading || simpleEvalLoading || languagesLoading) {
    return <Loader size="large" />;
  }

  const { evaluations } = evalSetData;
  const { allSimpleEvaluations } = simpleEvalData;
  const { availableLanguages } = languageData;

  return (
    <div className={styles.indexGrid}>
      <Panel className="p-md">
        <h2>Evaluations</h2>
        {Boolean(evaluations?.length) && (
          <>
            <h3>Sets</h3>
            <EvaluationSetList
              evaluations={evaluations}
              availableLanguages={availableLanguages}
              folderId={queryParams.get('folderId')}
            />
          </>
        )}
        {Boolean(allSimpleEvaluations?.length) && (
          <>
            <h3>Simple</h3>
            <EvaluationSetList evaluations={allSimpleEvaluations} availableLanguages={availableLanguages} simple />
          </>
        )}
      </Panel>
      <Panel className="p-md">
        <h2>Evaluation sets</h2>
        <p>
          Create a set of user queries and assert the expected response from your chatbot. <br />
          When you think you have enough queries you can create an evaluation report to see how well the bot is
          performing.
        </p>
        <p>
          You can create an evaluation report at any time throughout the bots growing phase to measure its increase in
          accuracy, and help spot areas that need improvement.
        </p>
        <Button onClick={() => showCreateEvaluationModal({ botId })} color="primary">
          Create evaluation set
        </Button>
      </Panel>
      <Panel className="p-md">
        <h2>Simple evaluations</h2>
        <p>Provide your own CSV file with user queries and recieve a report of triggered dialogues.</p>
        <Button onClick={() => showCreateSimpleEvaluationModal({ botId })} color="primary">
          Create simple evaluation
        </Button>
      </Panel>
    </div>
  );
};

export default EvaluationIndex;
