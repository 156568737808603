import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { BUTTON_TYPES } from 'frontend/features/Build/constants';
import { Equals, IDType } from 'frontend/propTypes';

import styles from './ButtonClicks.scss';
import Dialogue from './Dialogue';
import { DialogueType } from './propTypes';

const showValue = (buttonType) =>
  [
    BUTTON_TYPES.QUICK_REPLY,
    BUTTON_TYPES.EXTERNAL_LINK,
    BUTTON_TYPES.EMAIL,
    BUTTON_TYPES.PHONE,
    BUTTON_TYPES.ABORT_FOLLOWUP,
  ].includes(buttonType);

const Action = ({ data: { button, botId } }) => {
  const dialogueData = useMemo(
    () => ({ botId, dialogue: button?.dialogueLink?.dialogue, languageCode: button?.languageCode }),
    [botId, button?.dialogueLink?.dialogue, button?.languageCode],
  );

  if (!button) return <>N/A</>;
  if (showValue(button.buttonType)) return <span className={styles.value}>{button.value}</span>;
  if ([BUTTON_TYPES.DIALOGUE_TRIGGER, BUTTON_TYPES.UPLOAD_ATTACHMENT].includes(button.buttonType)) {
    return <Dialogue data={dialogueData} />;
  }
  return null;
};

Action.propTypes = {
  data: PropTypes.exact({
    botId: IDType.isRequired,
    button: PropTypes.exact({
      id: IDType.isRequired,
      buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
      dialogue: DialogueType,
      dialogueMod: PropTypes.exact({
        id: IDType.isRequired,
        modDialogue: DialogueType.isRequired,
        __typename: Equals('DialogueModType').isRequired,
      }),
      dialogueLink: PropTypes.exact({
        id: IDType.isRequired,
        dialogue: DialogueType.isRequired,
        __typename: Equals('ButtonDialogueLinkType').isRequired,
      }),
      label: PropTypes.string,
      languageCode: PropTypes.string,
      value: PropTypes.string,
      __typename: Equals('ButtonType').isRequired,
    }),
  }).isRequired,
};

export default Action;
