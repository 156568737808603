import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { addListItemByFragment } from 'frontend/api/cacheHelpers';
import { useCurrentLanguage } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import randomUUID from 'frontend/utils/randomUUID';

import useAttachmentError from './useAttachmentError';
import { EMPTY_CHAT_MESSAGE, SENDER_TYPES } from '../../../constants';
import { ATTACHMENT, CHAT_MESSAGE } from '../fragments';
import { CREATE_ATTACHMENTS } from '../mutations';
import { prepareAttachment } from '../utils';

export default ({ botId, chatId, messages, cache }) => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const [createAttachmentsMutation] = useMutation(CREATE_ATTACHMENTS);
  const { data: meData } = useMe();
  const onError = useAttachmentError(cache);

  const createAttachment = useCallback(
    async (file) => {
      const { name, type, sizeKb } = file;

      const { data } = await createAttachmentsMutation({
        variables: {
          botId,
          chatId,
          attachmentInputs: [{ name, type, sizeKb: Math.round(sizeKb) }],
        },
      });

      if (!Array.isArray(data?.createAttachments?.attachments)) {
        onError();
      }

      const newAttachment = prepareAttachment({ ...data?.createAttachments?.attachments[0], url: file.source });
      cache.modify({
        fields: {
          attachments: addListItemByFragment(cache, ATTACHMENT, newAttachment),
        },
      });

      // Create temporary message as optimistic update
      const tempChatMessage = {
        ...EMPTY_CHAT_MESSAGE,
        id: randomUUID(),
        chatLanguageCode: currentLanguage,
        sender: SENDER_TYPES.AGENT,
        created: new Date().toISOString(),
        attachmentIds: [newAttachment.id],
        name: meData?.me?.profile?.alias,
        avatar: meData?.me?.profile?.avatarUrl,
      };

      cache.modify({
        id: cache.identify(messages?.chatMessages),
        fields: {
          messages: addListItemByFragment(cache, CHAT_MESSAGE, tempChatMessage),
        },
      });

      return {
        attachment: newAttachment,
        temporaryChatMessageId: tempChatMessage.id,
      };
    },
    [botId, cache, chatId, createAttachmentsMutation, currentLanguage, meData?.me, messages?.chatMessages, onError],
  );

  return createAttachment;
};
