import { useQuery } from '@apollo/client';
import { useRef } from 'react';
import { Field, Form } from 'react-final-form';

import { type FormNudgeType, GetAllNudgeSlugsDocument } from 'frontend/api/generated';
import { FormErrors, Input, Panel, SelectDialogue, ToggleSwitch } from 'frontend/components';
import { GetDialogueList } from 'frontend/features/Build/graphql';
import { chain, max, required, url } from 'frontend/form/validators';
import { useBotOrSkill, useIsSubmitting } from 'frontend/hooks';

import { nudgeDefaultTexts } from '../../constants';
import { useNudges, useSlugValidator, useUpdateNudges } from '../../hooks';
import { Header } from '../Header';
import { Preview } from '../Preview';
import styles from '../shared.scss';

const FormNudge = ({ nudge }: { nudge?: FormNudgeType }) => {
  const ref = useRef();
  const { buildIdObject } = useBotOrSkill();

  const [, submitWrapper] = useIsSubmitting();

  const { onFormUpdate } = useUpdateNudges();
  const { botId, onFormCreate, currentLanguage } = useNudges();
  const { data, loading } = useQuery(GetDialogueList, {
    variables: {
      ...buildIdObject,
      includeSubscribedSkills: true,
    },
  });
  const { data: allNudges } = useQuery(GetAllNudgeSlugsDocument, { variables: { botId: botId as string } });
  const { validateSlug } = useSlugValidator({ data: allNudges, nudge });

  const editing = !!nudge;

  return (
    <Panel className={styles.formPanel}>
      <Form
        onSubmit={submitWrapper(editing ? onFormUpdate : onFormCreate)}
        initialValues={nudge || {}}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className={styles.container}>
            <Header
              title={values?.title?.[currentLanguage] ?? nudgeDefaultTexts.title}
              slug={values?.slug ?? nudgeDefaultTexts.slug}
            />
            <Preview>
              <Preview.Intent />
              <Preview.Nudge nudge={values} />
            </Preview>
            <div className={styles.containerEditor}>
              <div className={styles.panelHeader}>
                <h3>Form</h3>
                <p>Use a form nudge to notify your customer to sign up to your Email newsletter</p>
              </div>
              <FormErrors className={styles.formErrors} />
              <div className={styles.inputContainer}>
                <Field
                  name="slug"
                  label="Slug (this can not be edited later)"
                  component={Input}
                  readOnly={!!nudge}
                  inputLimit={50}
                  validate={chain([required, validateSlug])}
                  placeholder="Choose a slug for your nudge"
                />
              </div>
              <div className={styles.seperator} />
              <h5>Text Content</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <Field
                  name={`title.${currentLanguage}`}
                  label="Title"
                  component={Input}
                  inputLimit={80}
                  validate={chain([required, max(80)])}
                  placeholder="Choose a title"
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  name={`bodyText.${currentLanguage}`}
                  label="Body text"
                  component={Input}
                  inputLimit={255}
                  validate={chain([required, max(255)])}
                  placeholder="Choose a body text"
                  multiline
                />
              </div>
              <div className={styles.seperator} />
              <h5>Call to action</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <Field
                  name={`inputLabel.${currentLanguage}`}
                  label="Input label"
                  component={Input}
                  inputLimit={80}
                  validate={chain([required, max(80)])}
                  placeholder="Choose an input label"
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  name={`inputPlaceholder.${currentLanguage}`}
                  label="Input placeholder"
                  component={Input}
                  inputLimit={40}
                  validate={chain([required, max(40)])}
                  placeholder="Choose an input placeholder"
                />
              </div>
              <div className={styles.seperator} />
              <h5>Submit action</h5>
              <p className={styles.helpText}>Trigger a specific dialogue when the form is submitted</p>
              <div className={`m-b-3 ${styles.inputContainer}`}>
                <Field
                  component={SelectDialogue}
                  name="dialogueId"
                  aria-label="Dialogue"
                  placeholder="Dialogue"
                  componentRef={ref}
                  dialogueList={data?.dialogueList}
                  loading={loading}
                  activeOnly
                  allowEmpty
                />
              </div>
              <p className={`m-b-0 ${styles.helpText}`}>Make a webhook call when the form is submitted</p>
              <div className={styles.inputContainer}>
                <Field name="webhook" label="Webhook" component={Input} validate={url} placeholder="Webhook URL" />
              </div>
              <div className={styles.seperator} />
              <h5>Analytics Setup</h5>
              <p className={styles.helpText}>Setup url for completed purchase</p>
              <div className={`m-b-3 ${styles.inputContainer}`}>
                <Field
                  component={Input}
                  name="analyticsCompletionPagePath"
                  aria-label="Completion Page Url"
                  label="Completion Page Url"
                  placeholder="/order/"
                  componentRef={ref}
                  loading={loading}
                />
              </div>
              <div className={styles.seperator} />
              <h5>Toggle nudge</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <p className={styles.helpText}>You can toggle this nudge for the selected language</p>
                <div className={styles.toggleContainer}>
                  <ToggleSwitch name={`enabled.${currentLanguage}`} />
                  <label htmlFor="ToggleSwitch-enabled">
                    {values?.enabled?.[currentLanguage] ? 'Enabled' : 'Disabled'}
                  </label>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </Panel>
  );
};

export default FormNudge;
