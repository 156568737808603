import { KeyBindingUtil, getDefaultKeyBinding } from 'draft-js';
import { useCallback } from 'react';

import { EVENT_KEYS } from 'frontend/constants';

import useComposerState from './useComposerState';
import { EVENT } from '../constants';
import { selectionContainsTag } from '../utils';

const keyRemovesTag = (key) => (key ?? '').length === 1 || key === EVENT_KEYS.ENTER;

const preserveTags = (state, key) => {
  if (!keyRemovesTag(key)) return false;

  return selectionContainsTag(state, { withinTag: true });
};

export default function useKeyHandlers({ name, multiline, onEnter, onEscape, onSave, handlerProps }) {
  const { state } = useComposerState(name);

  const keyBindingFn = useCallback(
    (event) => {
      const hasSelection = !state.getSelection().isCollapsed();
      if (hasSelection && event.key === EVENT_KEYS.ENTER) return EVENT.HANDLED;

      if (!multiline && event.key === EVENT_KEYS.ENTER) return EVENT.ENTER;
      if (event.key === EVENT_KEYS.ESCAPE) return EVENT.ESCAPE;

      if (KeyBindingUtil.hasCommandModifier(event) && event.key === 's') {
        if (onSave) {
          event.preventDefault();
          event.stopPropagation();
        }
        return EVENT.SAVE;
      }

      if (preserveTags(state, event.key)) return EVENT.HANDLED;

      return getDefaultKeyBinding(event);
    },
    [multiline, onSave, state],
  );

  const handleKeyCommand = useCallback(
    (command) => {
      if (onEnter && command === EVENT.ENTER) {
        onEnter(handlerProps);
        return EVENT.HANDLED;
      }

      if (onEscape && command === EVENT.ESCAPE) {
        onEscape(handlerProps);
        return EVENT.HANDLED;
      }

      if (onSave && command === EVENT.SAVE) {
        onSave(handlerProps);
        return EVENT.HANDLED;
      }

      return EVENT.NOT_HANDLED;
    },
    [handlerProps, onEnter, onEscape, onSave],
  );

  return { keyBindingFn, handleKeyCommand };
}
