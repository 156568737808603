import { defineAction } from 'redux-define';

const BUILD = defineAction('BUILD');

export const SET_CURRENT_BREADCRUMBS = BUILD.defineAction('SET_CURRENT_BREADCRUMBS');
export const SET_SAMPLE_SORTING = BUILD.defineAction('SET_SAMPLE_SORTING');
export const HAS_CHANGES = BUILD.defineAction('HAS_CHANGES');
export const SET_AUTO_GENERATING_ENTITY_ITEMS = BUILD.defineAction('SET_AUTO_GENERATING_ENTITY_ITEMS');

export const setCurrentBreadcrumbs = (currentBreadcrumbs) => ({
  type: SET_CURRENT_BREADCRUMBS.ACTION,
  currentBreadcrumbs,
});

export const setSampleSorting = (sorting) => ({
  type: SET_SAMPLE_SORTING.ACTION,
  sorting,
});

export const sortingTypes = Object.freeze({
  ALPHABETICAL: 'alphabetical',
  RECENTLY_CHANGED: 'recent',
  WORD_COUNT: 'wordCount',
});

export const setHasChanges = (hasChanges) => ({
  type: HAS_CHANGES.ACTION,
  hasChanges,
});

export const setAutoGeneratingEntityItems = (isAutoGeneratingEntityItems) => ({
  type: SET_AUTO_GENERATING_ENTITY_ITEMS.ACTION,
  isAutoGeneratingEntityItems,
});

export const initialState = {
  currentBreadcrumbs: null,
  sorting: sortingTypes.RECENTLY_CHANGED,
  isAutoGeneratingEntityItems: false,
  // Need this globally so that library can know whether build has
  // changes when attempting to change language
  hasChanges: false,
};

export const selectBuild = ({ build }) => build;
export const selectSorting = (state) => selectBuild(state).sorting;
export const selectHasChanges = (state) => selectBuild(state).hasChanges;
export const selectCurrentBreadcrumbs = (state) => selectBuild(state).currentBreadcrumbs;
export const selectAutoGeneratingEntityItems = (state) => selectBuild(state).isAutoGeneratingEntityItems;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_BREADCRUMBS.ACTION:
      return { ...state, currentBreadcrumbs: action.currentBreadcrumbs };
    case SET_SAMPLE_SORTING.ACTION:
      return { ...state, sorting: action.sorting };
    case HAS_CHANGES.ACTION:
      return { ...state, hasChanges: action.hasChanges };
    case SET_AUTO_GENERATING_ENTITY_ITEMS.ACTION:
      return { ...state, isAutoGeneratingEntityItems: action.isAutoGeneratingEntityItems };
    default:
      return state;
  }
};
