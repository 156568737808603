import { useEffect } from 'react';
import { initialize, pageview, set } from 'react-ga';
import { useLocation } from 'react-router-dom';

/** A custom hook to initialize Google Analytics.
 *
 * _Requires to be under a React Router provider_. */
const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    initialize(window.env.GOOGLE_ANALYTICS_PROPERTY || 'dummy', {
      testMode: !window.env.GOOGLE_ANALYTICS_PROPERTY,
    });
    pageview(window.location.pathname + window.location.search);
    set({ anonymizeIp: true });
  }, []);

  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);
};
export default useGoogleAnalytics;
