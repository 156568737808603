import { type RefObject, useCallback } from 'react';
import InputAutosize from 'react-input-autosize';

import { useKeyDown } from 'frontend/hooks';

import styles from './RoleTitle.scss';
import { ROLE_TITLE_DELIMITER } from '../../constants';

interface RoleTitleInputProps {
  input: { value: string; onChange: (value: string) => void };
  inputRef?: RefObject<HTMLInputElement>;
}

const RoleTitleInput = ({ input: { value, onChange }, inputRef }: RoleTitleInputProps) => {
  const [prefix, title] = value.split(ROLE_TITLE_DELIMITER);

  const onClick = useCallback(() => inputRef?.current?.focus(), [inputRef]);
  const onKeyDown = useKeyDown({ action: onClick });
  const handleChange = useCallback(
    (event) => onChange(`${prefix}${ROLE_TITLE_DELIMITER}${event.target.value}`),
    [onChange, prefix],
  );

  return (
    <div className={styles.roleTitleInput} onClick={onClick} onKeyDown={onKeyDown} role="button" tabIndex={0}>
      <InputAutosize value={title || ''} onChange={handleChange} ref={inputRef} />
    </div>
  );
};

export default RoleTitleInput;
