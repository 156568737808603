import { useApolloClient } from '@apollo/client';
import cx from 'classnames';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Labels, PaintbucketTilted, Settings } from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import {
  searchLibrary,
  selectLibrarySearch,
  setLibrarySearchFilters,
  setLibrarySearchHits,
  setLibrarySearchLoading,
} from 'frontend/state/dux/library';
import { useAppDispatch } from 'frontend/state/hooks';
import pluralize from 'frontend/utils/pluralize';

import styles from './Filters.scss';
import { ColorMenu } from '../Menus/ColorMenu';
import { LabelsController } from '../Menus/LabelsController';
import { TypeMenu } from '../Menus/TypeMenu';

export const Filters = () => {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const { buildIdObject, buildType, botId } = useBotOrSkill();
  const [{ selectedLanguage }] = useCurrentLanguage();

  const { searchQuery, filters } = useSelector(selectLibrarySearch);
  const { enabledDialogueLabels, enabledDialogueTypes, enabledDialogueColors } = filters;

  const { botSettings } = useBotSettings(botId);
  const { intentMatchEnabled } = botSettings || {};

  const enabledDialogueColorLength = enabledDialogueColors.length;
  const enabledDialogueTypesLength = enabledDialogueTypes.length;
  const enabledDialogueLabelsLength = enabledDialogueLabels.length;

  const search = useCallback(
    (query, labels, types, colors) => {
      dispatch(
        searchLibrary({
          buildType,
          buildIdObject,
          languageCode: selectedLanguage,
          client,
          query,
          labels,
          types,
          colors,
        }),
      );
    },
    [buildType, buildIdObject, selectedLanguage, client, dispatch],
  );

  const onEnabledDialogueLabelsChange = (dialogueLabels: string[]) => {
    dispatch(setLibrarySearchFilters({ enabledDialogueLabels: dialogueLabels }));
    search(searchQuery, dialogueLabels, enabledDialogueTypes, enabledDialogueColors);
  };
  const onEnabledDialogueTypesChange = (dialogueTypes: string[]) => {
    dispatch(setLibrarySearchFilters({ enabledDialogueTypes: dialogueTypes }));
    search(searchQuery, enabledDialogueLabels, dialogueTypes, enabledDialogueColors);
  };
  const onEnabledDialogueColorsChange = (dialogueColors: string[]) => {
    dispatch(setLibrarySearchFilters({ enabledDialogueColors: dialogueColors }));
    search(searchQuery, enabledDialogueLabels, enabledDialogueTypes, dialogueColors);
  };

  useEffect(() => {
    const activeFilters =
      enabledDialogueLabels.length > 0 || enabledDialogueColors.length > 0 || enabledDialogueTypes.length > 0;

    if (!activeFilters && !searchQuery) {
      dispatch(setLibrarySearchLoading(false));
      dispatch(setLibrarySearchHits({ dialogues: [], nudges: [] }));
    }
  }, [enabledDialogueLabels, enabledDialogueTypes, enabledDialogueColors, searchQuery, dispatch]);

  return (
    <div className={styles.searchFilters}>
      <Dropdown
        position="bottom-right"
        wrapperClassName={styles.dropdownWrapper}
        triggerClassName={cx(styles.dropdownButton, {
          [styles.filterSelected]: enabledDialogueLabelsLength > 0,
        })}
        overlayClassName={styles.dropdownOverlay}
        overlay={<LabelsController onFilterChange={onEnabledDialogueLabelsChange} checked={enabledDialogueLabels} />}
      >
        <Icon color="white" component={Labels} className={styles.icon} />
        {enabledDialogueLabelsLength > 0
          ? `${enabledDialogueLabelsLength} ${pluralize('label', enabledDialogueLabelsLength)}`
          : 'Label'}{' '}
      </Dropdown>

      <Dropdown
        position="bottom-right"
        wrapperClassName={styles.dropdownWrapper}
        triggerClassName={cx(styles.dropdownButton, {
          [styles.filterSelected]: enabledDialogueTypesLength > 0,
        })}
        overlayClassName={styles.dropdownOverlay}
        overlay={
          <TypeMenu
            checked={enabledDialogueTypes}
            onFilterChange={onEnabledDialogueTypesChange}
            intentMatchEnabled={intentMatchEnabled}
          />
        }
      >
        <Icon color="white" component={Settings} className={styles.icon} />
        {enabledDialogueTypesLength > 0
          ? `${enabledDialogueTypesLength} ${pluralize('type', enabledDialogueTypesLength)}`
          : 'Type'}
      </Dropdown>

      <Dropdown
        position="bottom-right"
        wrapperClassName={styles.dropdownWrapper}
        triggerClassName={cx(styles.dropdownButton, {
          [styles.filterSelected]: enabledDialogueColors.length > 0,
        })}
        overlayClassName={styles.dropdownOverlay}
        overlay={<ColorMenu checked={enabledDialogueColors} onFilterChange={onEnabledDialogueColorsChange} />}
      >
        <Icon color="white" component={PaintbucketTilted} className={styles.icon} />
        {enabledDialogueColorLength > 0
          ? `${enabledDialogueColorLength} ${pluralize('color', enabledDialogueColorLength)}`
          : 'Color'}
      </Dropdown>
    </div>
  );
};

export default Filters;
