import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Plus } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { NewOrganization } from 'frontend/features/Organization/modals';

const CreateOrganizationButton = ({ redirectTo = '/organization/:orgId' }) => {
  const [showNewOrganizationModal] = useModal(NewOrganization);

  const onClick = useCallback(() => showNewOrganizationModal({ redirectTo }), [redirectTo, showNewOrganizationModal]);
  return (
    <Button color="primary" icon={Plus} onClick={onClick}>
      New organization
    </Button>
  );
};
CreateOrganizationButton.propTypes = {
  redirectTo: PropTypes.string,
};
export default CreateOrganizationButton;
