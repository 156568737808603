import { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { SelectLanguage } from 'frontend/components';
import { useLanguages } from 'frontend/hooks';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import styles from './RequestInboxBackup.scss';

const SetLanguage = ({ botOrSkillParams }) => {
  const form = useForm();
  const { values } = useFormState();
  const { languages, loading } = useLanguages(botOrSkillParams);
  const showLanguagePicker = !loading && languages.length > 1;

  useEffect(() => {
    if (showLanguagePicker && !values.currentLanguage) {
      form.change('currentLanguage', languages.find(({ active }) => active).code);
    }
  }, [form, languages, showLanguagePicker, values.currentLanguage]);

  if (!showLanguagePicker) return null;

  return (
    <div className={styles.lanuageSelector}>
      <Field component={SelectLanguage.Selector} languages={languages} name="currentLanguage" />
    </div>
  );
};

SetLanguage.propTypes = {
  botOrSkillParams: BotOrSkillParamsType.isRequired,
};

export default SetLanguage;
