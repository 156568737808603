import { isEqual, pick } from 'lodash';

import { ALL_CONVERSATIONS_VIEW, VIEWS } from '../constants';

const removeNull = (object) =>
  Object.entries(object).reduce((result, [key, value]) => (value == null ? result : { ...result, [key]: value }), {});

const viewMatchesFilters =
  (filters) =>
  ([, viewFilters]) =>
    isEqual(pick(filters, Object.keys(removeNull(viewFilters))), removeNull(viewFilters));

export default (filters) => Array.from(VIEWS).find(viewMatchesFilters(filters))?.[0] ?? ALL_CONVERSATIONS_VIEW;
