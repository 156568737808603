import { gql } from '@apollo/client';

import { sampleFragment } from 'frontend/api/fragments';

export default gql`
  query SamplesWithEntity($entityId: UUID!, $botId: ID, $skillId: ID) {
    samplesWithEntity(entityId: $entityId, botId: $botId, skillId: $skillId) {
      ...Sample
    }
  }
  ${sampleFragment}
`;
