import { Navigate, Route, Routes, useMatch, useParams, useResolvedPath } from 'react-router-dom';

import { NavLinkTab } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './BuildDashboard.scss';
import { ActivityLog, BuildStatistics, Buttons, ContextFields, Images, Webhooks } from './components';

interface BuildDashboardProps {
  isSkill: boolean;
}

const BuildDashboard = ({ isSkill }: BuildDashboardProps) => {
  // This fixes the issue with the NavLink not being selected for the activity log
  // Since we want to show the Activity Log in the /build route
  // Which is also used for the dialogue open click

  const resolvedPath = useResolvedPath('');
  const match = useMatch(resolvedPath.pathname);

  const { botId } = useParams();
  const { hasBotPerms } = useMyPermissions({ botId });

  const canManageNudges = hasBotPerms('manage_nudges');
  const canViewTemplates = hasBotPerms('view_templates');

  if (!isSkill && canManageNudges && !canViewTemplates) {
    return <Navigate to="proactivity/nudge" replace />;
  }

  return (
    <>
      <div className={styles.tabs}>
        <NavLinkTab title="Activity log" to={match ? '' : 'activity-log'} tabColor={FIELD_COLOR.MISCHKA} />
        <NavLinkTab title="Buttons" to="buttons" tabColor={FIELD_COLOR.MISCHKA} />
        <NavLinkTab title="Images" to="images" tabColor={FIELD_COLOR.MISCHKA} />
        <NavLinkTab title="Webhooks" to="webhooks" tabColor={FIELD_COLOR.MISCHKA} />
        <NavLinkTab title="Context fields" to="context-fields" tabColor={FIELD_COLOR.MISCHKA} />
        <NavLinkTab title="Statistics" to="statistics" tabColor={FIELD_COLOR.MISCHKA} />
      </div>
      <Routes>
        <Route path="/" element={<Navigate replace to="activity-log" />} />
        <Route path="activity-log" element={<ActivityLog />} />
        <Route path="buttons" element={<Buttons />} />
        <Route path="images" element={<Images />} />
        <Route path="webhooks" element={<Webhooks />} />
        <Route path="context-fields" element={<ContextFields />} />
        <Route path="statistics" element={<BuildStatistics />} />
      </Routes>
    </>
  );
};

export default BuildDashboard;
