import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { skillFragment } from 'frontend/api/fragments';
import { Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';

const CLONE_SKILL = gql`
  mutation CloneSkill($skillId: ID!, $cloneName: String!) {
    cloneSkill(skillId: $skillId, cloneName: $cloneName) {
      ...Skill
    }
  }
  ${skillFragment}
`;

const CloneSkillModal = ({ hide, args: { skillId, name: skillName } }) => {
  const validName = skillName.substr(0, 42); // max 50 chars in backend minus 8 chars for the text ' (clone)'
  const initialValues = { name: `${validName} (clone)` };

  const [mutate] = useMutation(CLONE_SKILL);
  const toast = useToast();
  const onSubmit = async ({ name }) => {
    await mutate({ variables: { skillId, cloneName: name } });
    toast.success(`The skill ${name} is being cloned. This may take a moment...`);
  };
  return (
    <ModalForm
      onSubmit={onSubmit}
      title="Clone skill"
      onOkText="Clone"
      hide={hide}
      initialValues={initialValues}
      ignoreDirty
    >
      <Field
        component={Input}
        label="Name your cloned skill"
        className="m-b-md"
        placeholder="Name"
        name="name"
        inputLimit={50}
        validate={chain([required, max(50)])}
        autoFocus
      />
    </ModalForm>
  );
};

CloneSkillModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    skillId: PropTypes.string,
    name: PropTypes.string,
  }),
};
export default CloneSkillModal;
