import { Route, Routes } from 'react-router-dom';

import SessionsOverview from './SessionsOverview';
import {
  ButtonClicksDetail,
  ChatbubblePagesDetail,
  EngagementDetail,
  FallbackRateDetail,
  FrequentDialoguesDetail,
  InteractionsDetail,
  LabelsDetail,
  SessionsDetail,
} from './components';

const Sessions = () => (
  <Routes>
    <Route path="/button-clicks" element={<ButtonClicksDetail />} />
    <Route path="/chatbubble-pages" element={<ChatbubblePagesDetail />} />
    <Route path="/engagement" element={<EngagementDetail />} />
    <Route path="/fallback-rate" element={<FallbackRateDetail />} />
    <Route path="/frequent-dialogues" element={<FrequentDialoguesDetail />} />
    <Route path="/labels-detail" element={<LabelsDetail />} />
    <Route path="/messages-detail" element={<InteractionsDetail />} />
    <Route path="/sessions-detail" element={<SessionsDetail />} />
    <Route path="" element={<SessionsOverview />} />
  </Routes>
);

export default Sessions;
