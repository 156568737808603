import { ChildrenType } from 'frontend/propTypes';

import styles from './MessageTag.scss';

const MessageTag = ({ children }) => <span className={styles.messageTag}>{children}</span>;

MessageTag.propTypes = {
  children: ChildrenType.isRequired,
};

export default MessageTag;
