import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { AllConnectorsDocument } from 'frontend/api/generated';
import { Plus } from 'frontend/assets/icons';
import { Button, PageContent } from 'frontend/components';
import NotFound from 'frontend/features/NotFound/NotFound';
import { useDocumentTitle } from 'frontend/hooks';

import ConnectorList from './components/ConnectorList';

const Connectors = () => {
  useDocumentTitle('Connectors');
  const { data, loading } = useQuery(AllConnectorsDocument);
  const connectors = useMemo(() => orderBy(data?.allConnectors ?? [], ({ name }) => name.toLocaleString()), [data]);

  if (loading) return null;

  return (
    <div>
      <PageContent>
        <div className="p-b-lg">
          <Link to="/admin/connectors/new">
            <Button color="primary" icon={Plus} text="Create connector" />
          </Link>
        </div>

        {!connectors.length ? <NotFound text="No connectors found" /> : <ConnectorList connectors={connectors} />}
      </PageContent>
    </div>
  );
};

export default Connectors;
