import { gql } from '@apollo/client';

import LabelFragment from 'frontend/api/fragments/Label.fragment.graphql';

export default gql`
  mutation CreateLabel($botId: ID!, $name: String!, $color: String!) {
    createLabel(botId: $botId, name: $name, color: $color) {
      ...Label
    }
  }
  ${LabelFragment}
`;
