import cx from 'classnames';

import { useKeyDown } from 'frontend/hooks';

import EmptyState from './EmptyState';
import ErrorState from './ErrorState';
import Header from './Header';
import LoadingState from './LoadingState';
import styles from './Panel.scss';
import Separator from './Separator';

export type PanelSize = 'small' | 'medium' | 'large' | 'noPadding';

interface PanelProps {
  className?: string;
  onClick?(event: React.SyntheticEvent): void;
  children: React.ReactNode;
  centered?: boolean;
  side?: boolean;
  hover?: boolean;
  nested?: boolean;
  elementRef?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
  size?: PanelSize;
  role?: string;
}

const Panel = ({
  className,
  style,
  children,
  centered = false,
  side = false,
  onClick,
  size = 'large',
  hover = false,
  nested = false,
  role,
  elementRef,
}: PanelProps) => {
  const onKeyDown = useKeyDown({ action: onClick });

  return (
    <div
      style={style}
      ref={elementRef}
      className={cx(styles.panel, className, {
        [styles.panelSide]: side,
        [styles.panelCentered]: centered,
        [styles.panelNested]: nested,
        [styles.panelHover]: hover === true,
        [styles.panelSmall]: size === 'small',
        [styles.panelMedium]: size === 'medium',
        [styles.panelLarge]: size === 'large',
        [styles.panelNoPadding]: size === 'noPadding',
      })}
      role={role || (onClick ? 'button' : undefined)}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {children}
    </div>
  );
};

Panel.EmptyState = EmptyState;
Panel.ErrorState = ErrorState;
Panel.LoadingState = LoadingState;
Panel.Separator = Separator;
Panel.Header = Header;

export default Panel;
