import { useEffect } from 'react';

import { useMixpanel } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';

/** Keep the mixpanel track/no-track cookie updated based on profile flag */
export const useAllowTracking = () => {
  const { mixpanel } = useMixpanel();
  const { data } = useMe();

  useEffect(() => {
    if (!data?.me) {
      return undefined;
    }

    const profile = data.me.profile;
    if (profile.trackingConsentApproved && mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_in_tracking();
    } else if (!profile.trackingConsentApproved && !mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_out_tracking();
    }

    if (!profile.trackingConsentApproved) {
      mixpanel.disable();
    }

    return undefined;
  }, [mixpanel, data?.me]);
};

/** Track visit once by page name */
export const useTrackPageVisit = (pageName, properties) => {
  const { mixpanel } = useMixpanel();
  useEffect(() => {
    mixpanel.track(`Visit ${pageName}`, properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
