import { gql } from '@apollo/client';

import POLICY_FRAGMENT from 'frontend/features/Organization/fragments/Policy.fragment.graphql';
import POLICY_SIGNATURE from 'frontend/features/Organization/fragments/PolicySignature.fragment.graphql';

export default gql`
  query GetPolicies($organizationId: ID!) {
    policies {
      ...Policy
    }
    signatures(organizationId: $organizationId) {
      ...PolicySignature
    }
  }
  ${POLICY_FRAGMENT}
  ${POLICY_SIGNATURE}
`;
