import { InMemoryCache } from '@apollo/client/cache';
import { isEqual, uniqWith } from 'lodash';

import { overwriteList } from './cacheMerge';

// Ref: https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
export const possibleTypes = {
  BuildItemInterfaceType: ['DialogueType', 'SuggestionDialogueType'],
  ContextFieldUnion: ['ContextFieldType', 'BuiltinContextFieldType'],
  NudgeUnion: ['FormNudgeType', 'ProductNudgeType', 'TextNudgeType', 'CustomNudgeType', 'MultipleChoiceNudgeType'],
};

// This is so we know when the user has used filters/search or is just paginating
let previousChatsVariables = {};

// Doesn't look like there's a way to set merge: false as default:
// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
export const typePolicies = {
  Query: {
    fields: {
      allInvitations: { merge: false },
      botWebhooks: { merge: false },
      dialogues: { merge: false },
      entities: { merge: false },
      labelsForBot: { merge: false },
      me: { merge: false },
      ticketLog: { merge: false },
      topics: { merge: false },
      allNudges: { merge: false },
    },
  },
  OrganizationType: {
    fields: {
      members: { merge: false },
      roles: { merge: false },
    },
  },
  BotType: {
    fields: {
      chats: { merge: false },
      languages: { merge: false },
      roles: { merge: false },
    },
  },
  SkillType: {
    fields: {
      availableBots: { merge: false },
      languages: { merge: false },
    },
  },
  ChatType: {
    fields: {
      comments: { merge: false },
      labels: { merge: false },
    },
  },
  DialogueType: {
    fields: {
      samples: { merge: false },
      imageCarousels: { merge: false },
      replies: { merge: false },
      buildForms: { merge: false },
      dialogueRules: { merge: false },
    },
  },
  SampleType: {
    fields: {
      instances: { merge: false },
    },
  },
  SampleCandidateType: {
    fields: {
      suggestedDialogues: { merge: false },
    },
  },
  ChatsAndTicketsType: {
    fields: {
      chats: { merge: false },
      tickets: { merge: false },
    },
  },
  TicketType: {
    fields: {
      chat: { merge: false },
    },
  },
  ChatMessagesResponse: {
    fields: {
      messages: {
        merge: (existing = [], incoming) => uniqWith([...existing, ...incoming], isEqual),
      },
    },
  },
  ImageCarouselType: {
    fields: {
      images: { merge: false },
    },
  },
  UpdateProfileNotificationsOutput: {
    fields: {
      organizationNotifications: {
        merge: overwriteList,
      },
    },
  },
  ProfileType: {
    fields: {
      favoriteBots: {
        merge: false,
      },
    },
  },
  ChatsType: {
    fields: {
      chats: {
        merge: (existing = [], incoming, args) => {
          const { variables } = args;
          if (!isEqual(variables, previousChatsVariables)) {
            existing = [];
          }
          previousChatsVariables = { ...variables };

          return uniqWith([...existing, ...incoming], isEqual);
        },
      },
    },
  },
  LanguageType: { keyFields: ['code'] },
};

export default function cache(options = {}) {
  return new InMemoryCache({ possibleTypes, typePolicies, ...options });
}
