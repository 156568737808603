import { gql } from '@apollo/client';

export const PUBLISH_SKILL_MUTATION = gql`
  mutation PublishSkill($skillId: ID!) {
    publishSkill(skillId: $skillId) {
      id
      landingPageActive
      landingPageSlug
    }
  }
`;
