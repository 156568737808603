import { sum } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { LineChart } from 'frontend/components/Stats';
import { StatsPanel } from 'frontend/features/Analytics/components/';
import { useSageData } from 'frontend/features/Analytics/hooks';
import { sageSeriesToLineData } from 'frontend/features/Analytics/utils/sage';
import { formatNumber } from 'frontend/utils';

const EVENT_TYPES = Object.freeze({ ENDED: 'ended', EXITED: 'exited', REQUESTS: 'requests' });
const LABELS = Object.freeze({
  ENDED: 'Ended handovers',
  EXITED: 'Cancelled requests',
  REQUESTS: 'Handover requests',
});

const HandoverLineChart = ({ filters, scope, event }) => {
  const { loading, error, data } = useSageData(scope, '/takeovers/series', filters);
  const chartData = useMemo(() => sageSeriesToLineData(data, event, event), [data, event]);

  const formatLabelCallback = useCallback((dataPoint) => {
    const label = LABELS[dataPoint.id.toUpperCase()];
    const summed = sum(dataPoint.data.map((dp) => dp.y));

    return `${label}: ${formatNumber(summed)}`;
  }, []);

  return (
    <StatsPanel loading={loading} error={error}>
      {data && (
        <LineChart
          data={[chartData]}
          granularity={filters.granularity}
          formatLabelCallback={formatLabelCallback}
          csvFilename="handovers.csv"
        />
      )}
    </StatsPanel>
  );
};

HandoverLineChart.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  scope: PropTypes.string.isRequired,
  event: PropTypes.oneOf(Object.values(EVENT_TYPES)),
};

HandoverLineChart.EVENT_TYPES = EVENT_TYPES;

export default HandoverLineChart;
