import cx from 'classnames';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field } from 'react-final-form';

import lightning from 'frontend/assets/images/lightning.svg?url';
import { Button, Tooltip } from 'frontend/components';
import { entityLimits } from 'frontend/constants';
import { useCurrentLanguage } from 'frontend/hooks';
import { BuildIdObjectType, InputMetaType } from 'frontend/propTypes';

import AutoGeneratedEntitySection from './AutoGeneratedEntitySection';
import EntityItem from './EntityItem';
import styles from './EntityItemsInput.scss';
import { handleEntityItemsInput } from '../../utils';

const SUPPORTED_LANGUAGES = ['da', 'de', 'en', 'es', 'nb', 'nn', 'sv'];
const canGenerateItems = (currentLanguage) => SUPPORTED_LANGUAGES.includes(currentLanguage);

const getAutoGenerateProhibited = (nItems) => {
  if (nItems < 1) return 'Create some items in order to auto-generate more';
  if (nItems >= entityLimits.MAX_ITEMS_FOR_GENERATE) {
    return 'You cannot auto-generate any more items (but you can still write them yourself)';
  }

  return null;
};

export default function InputForm({
  fields: { value, push, remove, name },
  meta: { error, submitFailed },
  autoFocus,
  onEscape,
  hue,
  addItems,
  newEntityItems,
  setNewEntityItems,
  buildIdObject,
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ currentLanguage }] = useCurrentLanguage();
  const [openGenerationSection, setOpenGenerationSection] = useState(false);

  const handleInputChange = useCallback((e) => setNewEntityItems(e.target.value), [setNewEntityItems]);
  const allItems = useMemo(() => uniq([...handleEntityItemsInput(newEntityItems), ...value]), [newEntityItems, value]);
  const nItems = allItems.length;
  const showError = Boolean(submitFailed && error);
  const autoGenerateProhibited = getAutoGenerateProhibited(nItems);

  const handlePaste = (event) => {
    const text = event.clipboardData.getData('Text');
    const pastedItems = uniq(text.split(/[\n,]/).map((item) => item.trim().toLowerCase()));

    if (pastedItems.length <= 1) return;

    event.stopPropagation();
    event.preventDefault();
    pastedItems.filter((item) => !value.includes(item)).forEach((item) => push(item));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const newItems = handleEntityItemsInput(newEntityItems);
      newItems.filter((item) => item && !value.includes(item)).forEach((item) => push(item));
      setNewEntityItems('');
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'Escape') onEscape?.();
  };

  useEffect(() => {
    if (autoFocus && inputRef.current) setTimeout(() => inputRef?.current?.focus(), 0);
  }, [autoFocus, inputRef]);

  const itemsCountNumberStyles = cx(styles.itemsCountNumber, {
    [styles.itemsCountNumberWarning]: nItems >= 0.9 * entityLimits.MAX_ITEMS_PER_ENTITY,
    [styles.itemsCountNumberError]: nItems > entityLimits.MAX_ITEMS_PER_ENTITY,
  });

  return (
    <>
      <input
        className={styles.inputField}
        type="text"
        value={newEntityItems}
        onChange={handleInputChange}
        placeholder="Items (press enter to add)"
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        disabled={openGenerationSection}
      />

      <div className={styles.itemList}>
        {value.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`entity-item-input-${idx}`} className={styles.item}>
            <Field name={`${name}[${idx}]`}>
              {(props) => <EntityItem {...props} remove={remove} hue={hue} idx={idx} />}
            </Field>
          </div>
        ))}
        {nItems >= 10 && (
          <Tooltip>
            <div className={styles.itemsCount}>
              (<span className={itemsCountNumberStyles}>{nItems}</span>)
            </div>
            <Tooltip.Body>Number of items (maximum {entityLimits.MAX_ITEMS_FOR_GENERATE})</Tooltip.Body>
          </Tooltip>
        )}
        {canGenerateItems(currentLanguage) && (
          <Button
            className={cx(styles.generateButton, {
              [styles.generateButtonDisabled]: autoGenerateProhibited,
            })}
            disabled={!!autoGenerateProhibited}
            onClick={() => setOpenGenerationSection((prev) => !prev)}
            title={autoGenerateProhibited || 'Auto-generate entity items'}
            flat
          >
            <img src={lightning} alt="Lightning" />
          </Button>
        )}
      </div>

      {showError && <div className={styles.error}>{error}</div>}

      {openGenerationSection && (
        <AutoGeneratedEntitySection
          allItems={allItems}
          addItems={addItems}
          currentLanguage={currentLanguage}
          buildIdObject={buildIdObject}
          setOpenGenerationSection={setOpenGenerationSection}
        />
      )}
    </>
  );
}

InputForm.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }),
  meta: InputMetaType.isRequired,
  autoFocus: PropTypes.bool,
  onEscape: PropTypes.func,
  hue: PropTypes.number,
  addItems: PropTypes.func.isRequired,
  newEntityItems: PropTypes.string.isRequired,
  setNewEntityItems: PropTypes.func.isRequired,
  buildIdObject: BuildIdObjectType.isRequired,
};
