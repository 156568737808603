import { Sparkles } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';

import styles from './LuzmoHighlight.scss';
import { LUZMO_DASHBOARDS } from '../EmbeddedAnalytics/constants';

export default function LuzmoHighlight({ botId }: { botId: string }) {
  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <Icon component={Sparkles} title="Help us improve" color="primary" />
      </div>

      <div className={styles.textWrapper}>
        <h4>
          <strong>Have a sneak peek at the new Analytics pages</strong>
        </h4>
        <p>
          We are enhancing the experience, and you will soon see a new look and new metrics in Analytics. More to come!
        </p>
      </div>

      <div className={styles.buttonWrapper}>
        <Button color="secondary" to={`/workspace/${botId}/analytics/${LUZMO_DASHBOARDS.BOT_DASHBOARD.route}`}>
          View Analytics
        </Button>
      </div>
    </div>
  );
}
