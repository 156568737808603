import { useQuery } from '@apollo/client';

import { MyPermissionsDocument } from 'frontend/api/generated';

const PLATFORM_PERMISSIONS_PREFIXES = {
  bot: 'dashboard',
  organization: 'organizations',
  skill: 'skills',
};

interface Props {
  /** You need to pass this to check Bot permissions. */
  botId?: string;
  /** You need to pass this to check Organization permissions. */
  organizationId?: string;
  /** You need to pass this to check Skill permissions. */
  skillId?: string;
}

interface Return {
  /** Permissions in the scope of `bot`. */
  botPermissions: string[];
  isKindlyAdminManager: boolean;
  isKindlyAdminViewer: boolean;
  loading: boolean;
  /** Permissions in the scope of `organization`. */
  organizationPermissions: string[];
  /** Permissions that are global and available for Kindly Employees only. */
  platformPermissions: string[];
  /** Permissions in the scope of `skill`. */
  skillPermissions: string[];
  hasBotPerms: (perms: string | string[]) => boolean;
  hasOrganizationPerms: (perms: string | string[]) => boolean;
  hasSkillPerms: (perms: string | string[]) => boolean;
}

/** Hook to get the permissions of the current user. */
export default function useMyPermissions({ botId, skillId, organizationId }: Props = {}): Return {
  const { data, loading } = useQuery(MyPermissionsDocument, {
    variables: { organizationId, botId, skillId },
  });

  const platformPermissions = data?.myPermissions?.platformPermissions || [];
  const botPermissions = data?.myPermissions?.botPermissions || [];
  const organizationPermissions = data?.myPermissions?.organizationPermissions || [];
  const skillPermissions = data?.myPermissions?.skillPermissions || [];

  const hasPermissions = (
    permissionsToCheck: string[] | string,
    existingPermissions: string[],
    prefix: string,
  ): boolean => {
    permissionsToCheck = typeof permissionsToCheck === 'string' ? [permissionsToCheck] : permissionsToCheck;

    return permissionsToCheck.every(
      (permissionToCheck) =>
        existingPermissions.includes(permissionToCheck) ||
        platformPermissions
          .filter((permission: string) => permission.startsWith(prefix))
          .some((permission: string) => permission.includes(permissionToCheck)),
    );
  };

  const hasBotPerms = (perms: string | string[]): boolean =>
    hasPermissions(perms, botPermissions, PLATFORM_PERMISSIONS_PREFIXES.bot);
  const hasSkillPerms = (perms: string | string[]): boolean =>
    hasPermissions(perms, skillPermissions, PLATFORM_PERMISSIONS_PREFIXES.skill);
  const hasOrganizationPerms = (perms: string | string[]): boolean =>
    hasPermissions(perms, organizationPermissions, PLATFORM_PERMISSIONS_PREFIXES.organization);

  const isKindlyAdminManager = platformPermissions.includes('accounts.manage_kindly');
  const isKindlyAdminViewer = platformPermissions.includes('accounts.view_admin');

  return {
    organizationPermissions,
    botPermissions,
    skillPermissions,
    platformPermissions,
    hasBotPerms,
    hasSkillPerms,
    hasOrganizationPerms,
    isKindlyAdminManager,
    isKindlyAdminViewer,
    loading,
  };
}
