import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

const usingTab = () => document.body.classList.add('using-tab');
const notUsingTab = () => document.body.classList.remove('using-tab');

export default () => {
  useHotkeys('tab', usingTab);

  useEffect(() => {
    document.body.addEventListener('mousedown', notUsingTab);
    return () => () => document.body.removeEventListener('mousedown', notUsingTab);
  }, []);
};
