import type { MonthlyReportType } from 'frontend/api/generated';
import { Panel } from 'frontend/components';
import Button from 'frontend/components/Button';
import { REPORT_STATES } from 'frontend/features/Organization/views/Reports/utils';

type Props = {
  report?: Partial<MonthlyReportType>;
  month?: string;
  tz?: string;
  createReport: () => void;
};

export default function NoData({ report, month, tz, createReport }: Props) {
  const isPending = report?.state === REPORT_STATES.PENDING;
  const isError = report?.state === REPORT_STATES.ERROR;

  return (
    <Panel>
      <p>
        Report data is not yet available for month <code>{month}</code> and timezone <code>{tz}</code>.
      </p>
      {!isPending && !isError && <Button onClick={createReport}>Create report</Button>}
      {isError && (
        <div>
          <p>
            We are figuring out why this report is having troubles. Please come back later to find your report data.
          </p>
        </div>
      )}
      {isPending && (
        <div>
          <p>Please wait while we process your organization&apos;s data. This can take a while...</p>
          <Button
            onClick={() => {
              window.location.reload();
              return false;
            }}
          >
            Refresh
          </Button>
        </div>
      )}
    </Panel>
  );
}
