import { pick } from 'lodash';
import { defineAction } from 'redux-define';

const TAB_NOTIFICATION = defineAction('tabNotification', ['SHOW', 'HIDE', 'SET_TAB_ACTIVE']);

const INTERVAL_MS = 2000;

let originalFaviconHref: string;

// Swap between favicons
// we just change the fileName to keep the path to the file intact in the different environments
const swapFavicon = (originalHref: string, fileName: string) => {
  const slashIndex = originalHref.lastIndexOf('/');
  const slicedHref = originalHref.slice(0, slashIndex);
  return `${slicedHref}/${fileName}`;
};

const setTabNotification = (message, originalTitle) => {
  const favicon = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  originalFaviconHref = favicon.href;

  const intervalId = setInterval(() => {
    document.title = document.title === message ? originalTitle : message;
    favicon.href =
      favicon.href === originalFaviconHref
        ? swapFavicon(originalFaviconHref, 'favicon-alert.png')
        : originalFaviconHref;
  }, INTERVAL_MS);

  return intervalId;
};

const unsetTabNotification = (originalTitle) => {
  document.title = originalTitle;
  const favicon = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  favicon.href = originalFaviconHref;
};

export const showTabNotification = (message) => (dispatch, getState) => {
  const originalTitle = document.title;

  const previousIntervalId = getState().tabNotification.intervalId;

  if (previousIntervalId) {
    clearInterval(previousIntervalId);
  }

  const intervalId = setTabNotification(message, originalTitle);

  return dispatch({ type: TAB_NOTIFICATION.SHOW, message, originalTitle, intervalId });
};

export const hideTabNotification = () => (dispatch, getState) => {
  const {
    tabNotification: { message, originalTitle, intervalId },
  } = getState();

  clearInterval(intervalId);
  if (!message) return null;
  unsetTabNotification(originalTitle);

  return dispatch({ type: TAB_NOTIFICATION.HIDE });
};
export const setTabActive = (tabActive) => ({ type: TAB_NOTIFICATION.SET_TAB_ACTIVE, tabActive });

const selectTabNotification = ({ tabNotification }) => tabNotification;
export const selectTabNotificationMessage = (state) => selectTabNotification(state).message;
export const selectTabActive = (state) => selectTabNotification(state).tabActive;

const initialState = {
  message: null,
  originalTitle: null,
  intervalId: null,
  tabActive: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TAB_NOTIFICATION.SHOW:
      return { ...state, ...pick(action, ['message', 'originalTitle', 'intervalId']) };
    case TAB_NOTIFICATION.HIDE:
      return { ...state, message: null, originalTitle: null, intervalId: null };
    case TAB_NOTIFICATION.SET_TAB_ACTIVE:
      return { ...state, tabActive: action.tabActive };
    default:
      return state;
  }
};
