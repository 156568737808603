import { gql } from '@apollo/client';

import { TICKET } from 'frontend/features/Inbox/fragments';

export default gql`
  mutation CreateTicket($chatId: ID!) {
    createTicket(chatId: $chatId) {
      ...Ticket
    }
  }
  ${TICKET}
`;
