import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getZodiacModels } from 'frontend/api/queries';
import { Loader, Table } from 'frontend/components';
import { featurizerChoices, learnerChoices } from 'frontend/features/Train/constants';
import { usePagination } from 'frontend/hooks';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import styles from './ModelList.scss';
import TrainStatusIcon from './TrainStatusIcon';

const floatColumn = ({ data: float }) => (float === 0.0 ? <span>–</span> : <span>{parseFloat(float).toFixed(2)}</span>);

const statusColumn = ({ data: status }) => (
  <div className={styles.statusIcon}>
    <TrainStatusIcon status={status} />
    <span>{status}</span>
  </div>
);

const samplesColumn = ({ data: samples }) => (samples === 0 ? '–' : samples);

const columns = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    key: 'status',
    render: statusColumn,
  },
  {
    key: 'created',
    title: 'Creation time',
  },
  {
    key: 'learner',
  },
  {
    key: 'featurizer',
  },
  {
    key: 'samples',
    render: samplesColumn,
  },
  {
    key: 'accuracy',
    render: floatColumn,
  },
  {
    key: 'apsc',
    title: 'APSC',
    render: floatColumn,
  },
  {
    key: 'f1',
    render: floatColumn,
  },
  {
    key: 'precision',
    render: floatColumn,
  },
  {
    key: 'recall',
    render: floatColumn,
  },
];

const PAGE_SIZE = 20;

const ModelList = ({ selectedLanguage, className }) => {
  const { botId } = useParams();
  const { data: modelData, loading: modelsLoading } = useQuery(getZodiacModels, { variables: { botId } });

  const zodiacModels = useMemo(() => modelData?.zodiacModels ?? [], [modelData?.zodiacModels]);
  const objects = zodiacModels
    .map(({ learner, featurizer, ...zodiacModel }) => ({
      ...zodiacModel,
      created: format(zodiacModel.created, DATE_FORMAT_WITH_TIME),
      learner: learnerChoices[learner] || '–',
      featurizer: featurizerChoices[featurizer] || '–',
    }))
    .sort(({ status }) => (status === 'ACTIVE' ? -1 : 1))
    .filter(({ language }) => language === selectedLanguage);

  const [currentData, page, pages, setPage] = usePagination({ objects, pageSize: 20 });
  const pagination = useMemo(
    () => ({
      currentPage: page,
      pages,
      setPage,
      summary: {
        totalCount: zodiacModels.length,
        firstVisible: (page - 1) * PAGE_SIZE + 1,
        lastVisible: page === pages ? objects.length : page * PAGE_SIZE,
      },
    }),
    [page, pages, setPage, zodiacModels, objects],
  );

  if (modelsLoading) {
    return <Loader size="small" />;
  }
  return <Table data={currentData} columns={columns} pagination={pagination} className={className} />;
};

ModelList.propTypes = {
  selectedLanguage: PropTypes.string,
  className: PropTypes.string,
};

export default ModelList;
