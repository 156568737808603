import { isNil, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Checkbox, LoaderSwitch } from 'frontend/components';
import { SampleWithEntities } from 'frontend/features/Entities/components';
import { orderByLegalFirst, sampleIsLegal } from 'frontend/features/Entities/modals/EntitySearchAndReplace/helpers';
import { SampleType } from 'frontend/features/Samples/propTypes';

import styles from '../../../Entities/modals/EntitySearchAndReplace/EntitySearchAndReplace.scss';

const PreviewForm = ({ updatedSamples, oldWord }) => (
  <LoaderSwitch loading={isNil(updatedSamples)} size="medium">
    Choose the samples that you want to change and click replace:
    <br />
    <br />
    <br />
    {updatedSamples.length === 0 && (
      <div className={styles.noHits}>No sample matched the search &ldquo;{oldWord}&ldquo;</div>
    )}
    {updatedSamples.length > 0 && (
      <div className={styles.updatedSamplesList}>
        {orderBy(updatedSamples, orderByLegalFirst).map((sample) => (
          <div key={`updated-sample-${sample.id || sample.tempId}`} className={styles.updatedSample}>
            <Field
              name={`includedSamples.${sample.id || sample.tempId}`}
              disabled={!sampleIsLegal(sample)}
              component={Checkbox}
              className={styles.checkbox}
              type="checkbox"
              label={<SampleWithEntities instances={sample.instances} text={sample.text} />}
            />
          </div>
        ))}
      </div>
    )}
  </LoaderSwitch>
);

PreviewForm.propTypes = {
  oldWord: PropTypes.string.isRequired,
  updatedSamples: PropTypes.arrayOf(SampleType).isRequired,
};

export default PreviewForm;
