import { gql } from '@apollo/client';

import { EVALUATION_FRAGMENT } from './fragments';

const GET_EVALUATIONS = gql`
  query GetEvaluations($botId: ID!) {
    evaluations(botId: $botId) {
      ...Evaluation
    }
  }
  ${EVALUATION_FRAGMENT}
`;
export default GET_EVALUATIONS;
