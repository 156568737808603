import { isEqual, pick } from 'lodash';

import { hasSameId } from 'frontend/features/Build/utils';
import { formValuesToComposerBlock } from 'frontend/features/Composer';

const pruneInstances = (instances) => instances.map((instance) => pick(instance, ['start', 'entity.name']));

export default (sample1, sample2) => {
  if (!sample1 || !sample2 || hasSameId(sample1, sample2)) return false;

  // Easiest to compare samples in their editor representation
  const { text: text1, instances: instances1 } = formValuesToComposerBlock(sample1.text, sample1.instances, {
    trimText: true,
    lowerCaseText: true,
  });
  const { text: text2, instances: instances2 } = formValuesToComposerBlock(sample2.text, sample2.instances, {
    trimText: true,
    lowerCaseText: true,
  });

  return text1 === text2 && isEqual(pruneInstances(instances1), pruneInstances(instances2));
};
