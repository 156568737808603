import type { FieldRenderProps } from 'react-final-form';
import SelectSearch, { type SelectSearchProps } from 'react-select-search';

import RenderDefaultOption from './RenderDefaultOption';
import styles from './SelectWithSearch.scss';

export type SelectWithSearchProps = FieldRenderProps<string> & SelectSearchProps & { shortDropdown?: boolean };

const SelectWithSearch = ({
  input: { value, onChange },
  renderOption = RenderDefaultOption,
  hasError,
  shortDropdown,
  ...rest
}: SelectWithSearchProps) => (
  <SelectSearch
    search
    value={value}
    onChange={(selectValue) => onChange(selectValue)}
    renderOption={renderOption}
    className={{
      ...styles,
      ...(hasError ? { input: styles.selectSearchError } : {}),
      ...(shortDropdown ? { input: styles.shortDropdown } : {}),
    }}
    fuzzySearch
    {...rest}
  />
);

export default SelectWithSearch;
