import styles from './FeedbackTitle.scss';
import { feedbackData } from '../../utils/config';

interface FeedbackTitle {
  values: string[];
}

const FeedbackTitle = ({ values }: FeedbackTitle) => {
  const renderTitle = (val) =>
    feedbackData.map(({ value, label }) =>
      Array.isArray(value) ? (
        value.map(({ value: feedbackVal, label: lab }) => {
          if (feedbackVal === val) {
            return <div key={val}>{lab}</div>;
          }
          return null;
        })
      ) : (
        <div key={val}>{label}</div>
      ),
    );
  return <div className={styles.feedbackFilterRow}>{values.map(renderTitle)}</div>;
};

export default FeedbackTitle;
