import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { Alert } from 'frontend/assets/icons';
import BannerMessageImage from 'frontend/assets/images/banner_message.png';
import { FormErrors, GuardUnsaved, Input, LoaderSwitch, PageBar, Panel, ToggleSwitch } from 'frontend/components';
import EmptyFeature from 'frontend/components/EmptyFeature';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { chain, max, required, url } from 'frontend/form/validators';

import styles from './BannerMessage.scss';

const SUBSCRIBE = {};
const TEXT_CONTENT_LIMIT = 120;

const BannerMessage = () => {
  const { onSubmit, initialValues, currentLanguage, loading, languagesLoading } = useChatbubbleForm();
  const [isCreating, setIsCreating] = useState(false);
  const [hasBannerMessage, setHasBannerMessage] = useState(false);

  useEffect(() => {
    if (initialValues && !loading) {
      const bannerMessageExists =
        Object.values(initialValues.maintenanceTextContent).some((value) => !!(value as string).trim()) ||
        Object.values(initialValues.maintenanceLinkText).some((value) => !!(value as string).trim()) ||
        Object.values(initialValues.maintenanceLinkLocation).some((value) => !!(value as string).trim());

      setHasBannerMessage(bannerMessageExists);
    }
  }, [initialValues, loading]);

  const renderForm = () => (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscribe={SUBSCRIBE}
      render={({ handleSubmit }) => (
        <Panel size="medium">
          <GuardUnsaved />
          <form onSubmit={handleSubmit}>
            <FormErrors className={styles.formErrors} />
            <Panel.Header className={styles.buildPanelHeader}>
              <div className={styles.buildPanelTitle}>
                <Alert />
                &nbsp;Banner message
              </div>
              <PageBar.FormButtons className={styles.formSubmitBtn} />
            </Panel.Header>
            <div className={styles.chatbubbleSettingsWrapper}>
              <div className={styles.inputContainer}>
                <Field
                  id="Textarea-maintenanceTextContent"
                  name={`maintenanceTextContent.${currentLanguage}`}
                  component={Input}
                  inputLimit={TEXT_CONTENT_LIMIT}
                  validate={chain([required, max(TEXT_CONTENT_LIMIT)])}
                  placeholder="Choose a banner message"
                  multiline
                  label="Text"
                />
              </div>
              <div className={styles.inputContainer}>
                <p>
                  Use the fields below to direct users to a page that holds more information. If left empty, no links
                  will be displayed.
                </p>
                <Field
                  id="Textarea-maintenanceLinkText"
                  name={`maintenanceLinkText.${currentLanguage}`}
                  component={Input}
                  inputLimit={25}
                  validate={max(25)}
                  placeholder="Choose a link text"
                  label="Link text"
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  id="Textarea-maintenanceLinkLocation"
                  name={`maintenanceLinkLocation.${currentLanguage}`}
                  component={Input}
                  inputLimit={999}
                  validate={chain([url, max(999)])}
                  placeholder="Choose a link location"
                  label="Link location"
                />
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.switchContainer}>
                  <ToggleSwitch name="maintenanceAlertEnabled" className="m-r-md" />
                  <label htmlFor="ToggleSwitch-maintenanceAlertEnabled">Publish</label>
                </div>
                <div className={styles.fieldDescription}>
                  This banner message will just be a draft until you publish it.
                </div>
              </div>
            </div>
          </form>
        </Panel>
      )}
    />
  );

  return (
    <LoaderSwitch loading={loading || languagesLoading} size="large">
      {!hasBannerMessage && !isCreating ? (
        <EmptyFeature
          imageSrc={BannerMessageImage as string}
          buttonText="Create banner message"
          title="Highlight important information"
          description="Use banner message to inform users of known issues."
          onClick={() => setIsCreating(true)}
        />
      ) : (
        renderForm()
      )}
    </LoaderSwitch>
  );
};

export default BannerMessage;
