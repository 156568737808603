import { split } from 'lodash';
import { useMemo } from 'react';

import { useUrlSearchParams } from 'frontend/hooks';

import { getUrlArrayParam } from '../utils';

const filterToUrlParams = getUrlArrayParam('sources');

const useSource = () => {
  const [{ sources: paramSources }] = useUrlSearchParams();
  const sources = useMemo(() => (paramSources ? split(paramSources, '-') : []), [paramSources]);

  return [sources, filterToUrlParams] as const;
};

export default useSource;
