import PropTypes from 'prop-types';

import { Panel } from 'frontend/components';

import styles from './styles.scss';
import { SkillType } from '../../propTypes';

const SkillCard = ({ skill, uncklickable }) => (
  <div className={styles.container}>
    <Panel size="medium" hover={!uncklickable}>
      <div className={styles.skillContainer}>
        <div className={styles.skillDescription}>
          <p>{skill.name}</p>
          {skill.descriptionHeadline && <p>{skill.descriptionHeadline}</p>}
        </div>
        <div className={styles.skillStats}>
          <div className={styles.statContainer}>
            <p>Subscribers</p>
            <h3>{skill.subscriptionCount}</h3>
          </div>
          <div className={styles.statContainer}>
            <p>Dialogues</p>
            <h3>{skill.dialogueCount}</h3>
          </div>
        </div>
      </div>
    </Panel>
  </div>
);

SkillCard.propTypes = {
  skill: SkillType,
  uncklickable: PropTypes.bool,
};

export default SkillCard;
