import React, { createContext, useMemo, useState } from 'react';
import { useField } from 'react-final-form';

import inputConfigs from '../utils/configs';
import { findFieldValidator } from '../utils/helpers';

type ActiveFieldInputs = {
  placeholderText: boolean;
  helpText: boolean;
  maxLength: boolean;
  minimum: boolean;
  affix: boolean;
  slug: boolean;
  pattern: boolean;
};

export type SharedFieldPropsType = {
  activeFieldInputs: ActiveFieldInputs;
  setActiveFieldInputs: React.Dispatch<React.SetStateAction<ActiveFieldInputs>>;
  baseFieldName: string;
  baseFieldIndex: number;
  field: any;
};

interface FieldContextProviderProps {
  children: React.ReactChild | React.ReactChild[];
  values: {
    baseFieldName: string;
    baseFieldIndex: number;
  };
}

export const FieldContext = createContext<SharedFieldPropsType>({} as SharedFieldPropsType);

const getIfExistingAndVisible = (inputType, fieldName) =>
  inputConfigs[inputType].some((config) => !!config[fieldName]?.canHide);

const FieldContextProvider = ({ children, values }: FieldContextProviderProps) => {
  const { baseFieldName, baseFieldIndex } = values;

  const {
    input: { value: field },
  } = useField(baseFieldName);

  const [activeFieldInputs, setActiveFieldInputs] = useState({
    placeholderText:
      !!field.texts?.placeholderText || !getIfExistingAndVisible(field.inputType, 'placeholderText')?.canHide,
    helpText: !!field.texts?.helpText,
    maxLength:
      !!findFieldValidator(field.validators, 'maxLength')?.maxLength ||
      !getIfExistingAndVisible(field.inputType, 'maxLength'),
    minimum:
      !!findFieldValidator(field.validators, 'minimum')?.minimum ||
      !!findFieldValidator(field.validators, 'maximum')?.maximum,
    affix: !!field.affix,
    slug: !!field.slug,
    pattern: !!findFieldValidator(field.validators, 'pattern')?.pattern,
  });

  const value = useMemo(
    () => ({ activeFieldInputs, setActiveFieldInputs, baseFieldName, baseFieldIndex, field }),
    [activeFieldInputs, setActiveFieldInputs, baseFieldName, baseFieldIndex, field],
  );

  return <FieldContext.Provider value={value}>{children}</FieldContext.Provider>;
};

export default FieldContextProvider;
