import cx from 'classnames';
import { useCallback, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';

import { Cloud } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { selectNavbarHeight } from 'frontend/state/dux/navbar';

import styles from './PageBar.scss';
import ResetFormModal from './ResetFormModal';

const SLIM_NAVBAR_HEIGHT = 67;

interface PageBarProps {
  children: React.ReactNode;
  className?: string;
  noShadow?: boolean;
}

export default function PageBar({ children, className, noShadow }: PageBarProps) {
  const isNavbarStartingSlim = useSelector(selectNavbarHeight) === SLIM_NAVBAR_HEIGHT;
  const classNames = cx(className, styles.pageBar, {
    [styles.noShadow]: noShadow,
    [styles.responsiveSlimNav]: isNavbarStartingSlim,
    [styles.fatNav]: !isNavbarStartingSlim,
  });

  return <div className={classNames}>{children}</div>;
}

interface FormButtonsProps {
  isSubmitting?: boolean;
  hasChanges?: boolean;
  hasChangesText?: string;
  noChangesText?: string;
  className?: string;
  reset: () => void;
  isDirty: boolean;
}

const FormButtons = ({
  isSubmitting,
  hasChanges,
  hasChangesText = 'Save',
  noChangesText = 'Saved',
  className,
  reset,
  isDirty,
}: FormButtonsProps) => {
  const [showResetModal] = useModal(ResetFormModal);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const formHasChanges = hasChanges ?? isDirty;
  const formIsSubmitting = isSubmitting;

  const handleCancel = useCallback(() => {
    reset();
  }, [reset]);

  const onClickReset = useCallback(() => showResetModal({ handleCancel }), [handleCancel, showResetModal]);

  useHotkeys(
    'meta+s',
    () => {
      if (formHasChanges && !formIsSubmitting) {
        submitButtonRef.current?.click();
      }
    },
    [formHasChanges, formIsSubmitting],
    {
      enableOnFormTags: ['input', 'textarea', 'select'],
      preventDefault: true,
    },
  );

  return (
    <div className={styles.formButtonsContainer}>
      {formHasChanges && (
        <Button color="white" onClick={onClickReset}>
          Clear changes
        </Button>
      )}
      <Button
        buttonRef={submitButtonRef}
        type="submit"
        disabled={!formHasChanges}
        className={cx(styles.saveButton, className)}
        color={formHasChanges ? 'primary' : 'secondary'}
        isSubmitting={formIsSubmitting}
        icon={formHasChanges ? Cloud : undefined}
        text={formHasChanges ? hasChangesText : noChangesText}
      />
    </div>
  );
};

PageBar.FormButtons = FormButtons;
