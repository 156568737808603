import cx from 'classnames';
import { useFormState } from 'react-final-form';

import { FIELD_COLOR } from 'frontend/constants';
import { flattenObject } from 'frontend/utils/flattenObject';

import styles from './Tabs.scss';

interface Props {
  ariaLabel?: string;
  errorNames?: string[];
  idx: number;
  listView?: boolean;
  /** Callback to run on Tab click. It gets passed the `title` of the clicked Tab.  */
  onTabClick?: (tabName: string) => void;
  select: (idx: number) => void;
  selected: number;
  tabColor?: string;
  title: string;
  tooltip?: string;
}

const TabTitle = ({
  title,
  errorNames = [],
  selected,
  idx,
  tooltip,
  listView,
  select,
  tabColor,
  ariaLabel,
  onTabClick,
}: Props) => {
  const { errors, submitFailed } = useFormState();
  const errorKeys = errors ? Object.keys(flattenObject(errors)).map((str) => str.toLowerCase()) : [];
  const hasErrors = submitFailed && errorNames.some((name) => errorKeys.includes(name.toLowerCase()));

  const onClick = () => {
    select(idx);
    onTabClick?.(title);
  };

  const classNames = cx(styles.btn, styles.tab, {
    [styles.tabSelected]: selected === idx,
    [styles.tabError]: hasErrors,
    [styles.tabHorizontal]: listView,
    [styles.tabColorMischka]: tabColor === FIELD_COLOR.MISCHKA,
  });

  return (
    <button
      className={classNames}
      role="tab"
      tabIndex={0}
      id={`tab-${idx}`}
      onClick={onClick}
      title={tooltip}
      type="button"
      aria-label={ariaLabel}
    >
      <span>{title}</span>
    </button>
  );
};

export default TabTitle;
