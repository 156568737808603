import { useState } from 'react';

import { ChevronDown, ChevronRight } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './MetaAccordion.scss';

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  defaultOpen: boolean;
}

const MetaAccordion = ({ children, header, defaultOpen }: Props) => {
  const [open, setOpen] = useState(defaultOpen ?? false);

  return (
    <div>
      <div
        className={styles.accordion}
        onClick={() => setOpen(!open)}
        onKeyDown={(e) => e.key === 'Enter' && setOpen(!open)}
        tabIndex={0}
        role="button"
        aria-expanded={open}
      >
        <div>{header}</div>
        <Icon component={open ? ChevronDown : ChevronRight} title={open ? 'Minimize' : 'Expand'} />
      </div>
      <div className={styles.children} style={{ display: open ? 'block' : 'none' }}>
        {children}
      </div>
    </div>
  );
};

export default MetaAccordion;
