import { differenceInDays, endOfDay, formatISO, startOfDay, subDays } from 'date-fns';
import { useMemo } from 'react';

import type { Granularity } from 'frontend/features/Analytics/types';
import { useToast, useUrlSearchParams } from 'frontend/hooks';

import useGranularity from './useGranularity';

const useDateRanges = () => {
  const toast = useToast();
  const [{ startDate, endDate }] = useUrlSearchParams();
  const [granularityFromUrl, granularityToUrlParams] = useGranularity();

  const filterToUrlParams = ({ after: from, before: to }, { granularity = undefined } = {}) => {
    const startDateParsed = formatISO(from, { representation: 'date' });
    const endDateParsed = formatISO(to, { representation: 'date' });
    const dateDifferenceInDays = differenceInDays(new Date(endDateParsed), new Date(startDateParsed));

    const urlParams = granularityToUrlParams(granularity || granularityFromUrl);
    let updatedGranularity: Granularity = urlParams.granularity;
    const isHour = updatedGranularity === 'hour';

    // Change granularity when date range is more than 7 days
    if (isHour && dateDifferenceInDays >= 7) {
      updatedGranularity = 'day';
      toast.success('Changed time frame to "Day"');
    }

    return { startDate: startDateParsed, endDate: endDateParsed, granularity: updatedGranularity };
  };

  const from = useMemo(
    () => (startDate && endDate ? new Date(startDate) : subDays(startOfDay(new Date()), 7)),
    [startDate, endDate],
  );
  const to = useMemo(() => (startDate && endDate ? new Date(endDate) : endOfDay(new Date())), [startDate, endDate]);

  return { from, to, toParams: filterToUrlParams };
};

export default useDateRanges;
