import { gql } from '@apollo/client';

import { EVALUATION_FRAGMENT } from './fragments';

const CreateEvaluationSet = gql`
  mutation CreateEvaluationSet($botId: ID!, $languageCode: String!, $title: String!) {
    createEvaluation(botId: $botId, languageCode: $languageCode, title: $title) {
      ...Evaluation
    }
  }
  ${EVALUATION_FRAGMENT}
`;
export default CreateEvaluationSet;
