import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

// Maps strings to boolean values
const BOOL_VALUES = { true: true, false: false };

const parseValue = (key, value, types) => {
  const type = types?.[key];

  if (type === Number) return Number(value);
  if (type === Boolean) return BOOL_VALUES[value];
  if (type === Date) return new Date(value);
  if (typeof type === 'function') return type(value);
  return value;
};

/**
 * Get and set URL search parameters, using them as a single source of truth
 *
 * @param {object} default - Default values for parameters not found in the URL
 * @param {object} types - Used to parse values; can be Number, Boolean, Date or a function
 */

export default ({ default: defaultValues, types } = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    const searchParamsObject = Object.fromEntries(searchParams);

    const parsedSearchParams = Object.fromEntries(
      Object.entries(searchParamsObject).map(([key, value]) => [key, parseValue(key, value, types)]),
    );

    return { ...defaultValues, ...parsedSearchParams };
  }, [defaultValues, searchParams, types]);

  const setParams = (newParamsObject = {}, { merge = true, replace = false } = { merge: true, replace: false }) => {
    Object.entries(newParamsObject).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams, { merge, replace });
  };

  const unsetParams = (paramsToUnset = [], { replace = false } = { replace: false }) => {
    paramsToUnset.forEach((param) => {
      searchParams.delete(param);
    });
    setSearchParams(searchParams, { replace });
  };

  return [params, setParams, unsetParams];
};
