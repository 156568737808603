export const LUZMO_DASHBOARDS = {
  AUTHENTICATED: { luzmoSlug: 'authenticated', route: 'authenticated', queryParamsGroup: 'A' },
  BEHAVIOR: { luzmoSlug: 'behavior', route: 'behavior', queryParamsGroup: 'A' },
  BOT_DASHBOARD: { luzmoSlug: 'botdashboard', route: 'bot-dashboard', queryParamsGroup: 'A' },
  BOT_FEEDBACK: { luzmoSlug: 'botfeedback', route: 'bot-feedback', queryParamsGroup: 'A' },
  CONTAINMENT_RATE: { luzmoSlug: 'containmentrate', route: 'containment-rate', queryParamsGroup: 'A' },
  HANDOVER_FEEDBACK: { luzmoSlug: 'handoverfeedback', route: 'handover-feedback', queryParamsGroup: 'A' },
  KINDLY_GPT: { luzmoSlug: 'kindlygpt', route: 'kindly-gpt', queryParamsGroup: 'A' },
  LABELS: { luzmoSlug: 'labels', route: 'labels', queryParamsGroup: 'A' },
  TOPICS: { luzmoSlug: 'topicsoverview', route: 'topics', queryParamsGroup: 'A' },
  USAGE: { luzmoSlug: 'usage', route: 'usage', queryParamsGroup: 'A' },
  ESTIMATED_COSTS: { luzmoSlug: 'estimatedcosts', route: 'billing', queryParamsGroup: 'C' },
  EMAIL_DETAILS: { luzmoSlug: 'emaildetails', route: 'email-details', queryParamsGroup: 'B' },
  EMAIL_OVERVIEW: { luzmoSlug: 'emailoverview', route: 'email-overview', queryParamsGroup: 'B' },
  NUDGE_DETAILS: { luzmoSlug: 'nudgedetails', route: 'nudge-details', queryParamsGroup: 'B' },
  NUDGE_OVERVIEW: { luzmoSlug: 'nudgeoverview', route: 'nudge-overview', queryParamsGroup: 'B' },
};
