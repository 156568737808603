import cx from 'classnames';
import { Field } from 'react-final-form';

import { InfoButton, Input } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';
import { url } from 'frontend/form/validators';

import styles from './VideoEmbed.scss';
import buildStyles from '../../Build.scss';
import { DEFAULT_RULE_ID } from '../../constants';
import useCurrentRuleId from '../../hooks/useCurrentRuleId';
import { AboutVideoEmbed } from '../../modals';

interface Props {
  args: {
    currentLanguage: string;
    isModDialogue?: boolean;
  };
}

function VideoEmbed({ args: { currentLanguage, isModDialogue } }: Props) {
  const [showAboutVideoEmbed] = useModal(AboutVideoEmbed);
  const ruleId = useCurrentRuleId() || DEFAULT_RULE_ID;
  return (
    <>
      <div className={styles.titleWrapper}>
        <h3 className={cx(buildStyles.sectionTitle, 'm-b-0')}>Video embed</h3>
        <InfoButton className={styles.infoButton} onClick={showAboutVideoEmbed} />
      </div>
      <Field
        component={Input}
        name={`${isModDialogue ? 'modVideoSources' : 'videoSources'}.${currentLanguage}.${ruleId}`}
        validate={url}
        aria-label="Url to video, Vimeo or Youtube"
        placeholder="https://www.youtube.com/watch?v=XIxOvSH5MZg"
        fieldColor={FIELD_COLOR.MISCHKA}
        parse={(val) => {
          if (!val) return null;
          return val;
        }}
      />
      <p className="m-t-1 text-color-light">Paste the URL to a video hosted on YouTube, Vimeo or your own hosting.</p>
    </>
  );
}

export default VideoEmbed;
