import { orderBy } from 'lodash';

import { getItemName } from 'frontend/features/Composer';

export default (item, { modifyName } = {}) => {
  const { text: textWithEntityNames } = orderBy(item.instances || [], 'start').reduce(
    ({ text, lengthChange }, instance) => {
      const { start, end } = instance;
      const rawName = getItemName(instance);
      const name = typeof modifyName === 'function' ? modifyName(rawName) : rawName;
      const updatedText = `${text.slice(0, start + lengthChange)}${name}${text.slice(end + lengthChange)}`;
      const updatedLengthChange = lengthChange + (updatedText.length - text.length);

      return { text: updatedText, lengthChange: updatedLengthChange };
    },
    { text: item.text, lengthChange: 0 },
  );

  return { ...item, searchText: textWithEntityNames };
};
