import { gql } from '@apollo/client';

import { ENTITY } from 'frontend/features/Entities/fragments';

export default gql`
  fragment SLOT on SlotType {
    id
    name
    languageCode
    detail
    entity {
      ...Entity
    }
  }
  ${ENTITY}
`;
