import { get } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import { Blob, CenteredComponent } from 'frontend/components';

import styles from '../../Invitation.scss';
import ContentBox from '../ContentBox';

const InvalidToken = () => {
  const { state } = useLocation();
  const inviterEmail = get(state, 'invitation.inviter_email', 'contact@kindly.ai');

  return (
    <CenteredComponent className={styles.fullPage}>
      <div className={styles.branding}>
        <div className={styles.blobContainer}>
          <Blob speed={0.003} intensity={2} vectorAmount={300} />
        </div>
        <Link to="/" className={styles.brandingLink}>
          <span className={styles.brandingName}>Kindly</span>
        </Link>
      </div>
      <ContentBox noGrid>
        <>
          <h1 className={styles.title}>Invalid invitation</h1>
          <p className={styles.description}>This invitation link is used, expired or invalid.</p>
          <p className={styles.description}>
            If you need a new invitation, send an email to{' '}
            <a href={`mailto:${inviterEmail}`} className={styles.link}>
              {inviterEmail}
            </a>
          </p>
        </>
      </ContentBox>
    </CenteredComponent>
  );
};

export default InvalidToken;
