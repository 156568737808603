import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { ContentExportDocument, ContentExportListDocument, DeleteExportDocument } from 'frontend/api/generated';
import { DownloadCloud } from 'frontend/assets/icons';
import { Loader } from 'frontend/components';
import EmptyState from 'frontend/components/EmptyState/EmptyState';
import { useModal } from 'frontend/features/Modals';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useToast } from 'frontend/hooks';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';
import { convertISO639toEnglish } from 'frontend/utils/language';

import styles from './Export.scss';
import ImportExportDeleteModal from '../DeleteModal/ImportExportDeleteModal';
import ExportTable from '../ExportTable/ExportTable';

const Export = () => {
  const { botId } = useParams();
  const apolloClient = useApolloClient();
  const toast = useToast();

  const [showExportDeleteModal, hideExportDeleteModal] = useModal(ImportExportDeleteModal);

  const {
    data: contentExportListData,
    loading,
    refetch,
  } = useQuery(ContentExportListDocument, { variables: { botId: botId || '' } });
  const contentExportList = contentExportListData?.contentExportList || ([] as (typeof contentExportListData)[]);

  const [deleteWorkspaceExport] = useMutation(DeleteExportDocument, {
    update: (cache, { data }) => {
      const id = data?.deleteExport?.id;
      if (id) {
        cache.modify({
          fields: {
            contentExportList: (currentExportList, { readField }) =>
              currentExportList.filter((current) => readField('id', current) !== id),
          },
        });
        toast.success('Workspace export was deleted');
      }
    },
  });

  const handleDeleteWorkspaceExport = (id) => {
    showExportDeleteModal({
      handleDelete: () => {
        deleteWorkspaceExport({ variables: { botId: botId || '', id } });
        hideExportDeleteModal();
      },
      title: 'Delete workspace export',
      description: 'You are about to delete this workspace export file. Do you want to continue?',
    });
  };

  const download = async ({ variables }) => {
    const { data } = await apolloClient.query({
      query: ContentExportDocument,
      fetchPolicy: 'no-cache', // The download URL expires, so we do not want a cached version.
      variables,
    });
    const url = data.contentExport?.downloadUrl;
    if (url) window.location.assign(url);
  };

  usePusherEvent(getPusherChannel({ botId }), 'content-export', refetch);

  if (loading) {
    return <Loader size="medium" className={styles.loader} />;
  }

  const formattedExportData = contentExportList.map((exportLine) => {
    const { id, status, createdAt, languageCode } = exportLine;
    return {
      status,
      language: convertISO639toEnglish(languageCode),
      created: format(createdAt, DATE_FORMAT_WITH_TIME),
      options: {
        status,
        downloadExport: () => download({ variables: { botId, id } }),
        deleteExport: () => handleDeleteWorkspaceExport(id),
      },
    };
  });

  if (formattedExportData.length === 0) {
    return (
      <EmptyState
        icon={DownloadCloud}
        color="teal"
        maxWidth="300px"
        title="Create a version for exporting"
        description="You can download your data as a JSON file to edit your content in other systems. You can also import these files to update your bot at a later time."
      />
    );
  }

  return <ExportTable imports={formattedExportData} />;
};

export default Export;
