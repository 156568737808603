import cx from 'classnames';

import styles from './Panel.scss';

interface SeparatorProps {
  className?: string;
}

const Separator = ({ className }: SeparatorProps) => (
  <div className={cx(className, styles.separator)}>
    <span />
  </div>
);

export default Separator;
