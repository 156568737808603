import cx from 'classnames';

import Contents from './Contents';
import type { CellProps, Column, RenderFunction, Row } from './Table';
import styles from './Table.scss';

interface TableCellProps {
  data?: Record<string, unknown>;
  render?: RenderFunction;
  component?: React.ComponentType<CellProps>;
  columnName?: string;
  rowData?: Row;
  columnData?: Column;
  rowIdx?: string | number;
  cellClassName?: string;
}

const TableCell = ({
  data,
  render,
  component,
  columnName,
  rowData,
  columnData,
  rowIdx,
  cellClassName,
}: TableCellProps) => (
  <div className={cx(styles.cell, cellClassName)} role="cell">
    <Contents
      data={data}
      render={render}
      rowData={rowData}
      component={component}
      columnName={columnName}
      columnData={columnData}
      rowIdx={rowIdx}
    />
  </div>
);

export default TableCell;
