import cx from 'classnames';
import { isEmpty, startCase as startCaseLodash, toLower } from 'lodash';
import { useCallback } from 'react';

import type { AlertType, UserAlertType } from 'frontend/api/generated';
import { Exclamation } from 'frontend/assets/icons';
import { Button, Icon, Panel } from 'frontend/components';
import type { ALERTERS, ALERT_LEVELS } from 'frontend/features/BotDashboard/constants';
import { useModal } from 'frontend/features/Modals';

import styles from './AlertsList.scss';
import { AlertDescription } from '../../modals';

/** Remove underscores and uppercasing letters. */
function startCase(str: string) {
  return startCaseLodash(toLower(str));
}

export type OtherAlert = Pick<AlertType, 'id' | 'message' | 'info' | 'createdAt' | '__typename'> & {
  alerter: keyof typeof ALERTERS;
  level: keyof typeof ALERT_LEVELS;
};
export type AnyAlert = AlertType | UserAlertType | OtherAlert;

const Alert = ({
  alert,
  dismiss,
  botId,
}: {
  alert: AnyAlert;
  dismiss: (id: string, type?: string) => void;
  botId: string;
}) => {
  const { id, info, level, alerter, message, createdAt, __typename: type } = alert;
  const [showDescriptionModal] = useModal(AlertDescription);
  const title = `${startCase(level)} from ${startCase(alerter)}`;

  const handleDismiss = useCallback(
    (event) => {
      dismiss(id, type);
      event.stopPropagation();
      event.preventDefault();
    },
    [id, dismiss, type],
  );

  const openModal = useCallback(() => {
    showDescriptionModal({ alerter, info, title, botId, createdAt, level });
  }, [alerter, botId, createdAt, info, level, showDescriptionModal, title]);

  const onClick = isEmpty(info) ? undefined : openModal;

  return (
    <Panel key={alert.id} className={cx(styles.alert, { [styles.alertClickable]: Boolean(onClick) })} onClick={onClick}>
      <Icon component={Exclamation} className={cx(styles.icon, styles[level])} />
      <div>
        <h4 className={styles.alertTitle}>{title}</h4>
        {Boolean(message) && <p className={styles.alertMessage}>{message}</p>}
      </div>
      {type && (
        <Button onClick={handleDismiss} className={styles.dismiss} flat>
          Dismiss
        </Button>
      )}
    </Panel>
  );
};

export default Alert;
