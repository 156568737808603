import PropTypes from 'prop-types';

import { Button } from 'frontend/components';

import styles from './ClearButton.scss';

const ClearButton = ({ resetFilters }) => (
  <Button className={styles.clearButton} color="secondary" text="Clear all" onClick={resetFilters} />
);

ClearButton.propTypes = {
  resetFilters: PropTypes.func.isRequired,
};

export default ClearButton;
