import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import { isEmpty, isString, omit } from 'lodash';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import type { RootState } from 'frontend/state';

import styles from './FormErrors.scss';
import { deepGetFirstValue, formatErrorKey } from './utils';

interface FormErrorsProps {
  className?: string;
  inModal?: boolean;
}

const FormErrors = ({ className, inModal }: FormErrorsProps) => {
  const { getRegisteredFields } = useForm();
  const fields = getRegisteredFields();
  const { submitFailed, errors: formErrors, submitErrors } = useFormState();
  const errors = { ...formErrors, ...omit(submitErrors, fields) };
  const location = useLocation();
  const libraryWidth = useSelector<RootState>(({ library }) => library.width);

  const isInBuild = location.pathname.match('/build/');
  const style = isInBuild && !inModal ? { transform: `translateX(${libraryWidth}px)` } : {};
  const hasErrors = submitFailed && !isEmpty(errors);

  const errorClassNames = cx(styles.error, className, {
    [styles.errorInModal]: inModal,
  });

  if (!hasErrors) return null;

  let keyWord = '';
  let errorMessage = 'One or more input fields contain errors that must be fixed.';

  if (hasErrors) {
    const [errorKey, errorObject] = Object.entries(errors)[0]!;
    const nonEmptyStringInErrorObject = deepGetFirstValue(errorObject, (s) => isString(s) && s);

    if (errorKey) keyWord = errorKey;
    if (keyWord === FORM_ERROR) keyWord = '';
    if (nonEmptyStringInErrorObject) errorMessage = nonEmptyStringInErrorObject;
  }

  return (
    <div className={errorClassNames} style={style}>
      {!!keyWord && <span className={styles.errorKey}>{formatErrorKey(keyWord)}</span>}
      <div className={styles.errorText}>{errorMessage}</div>
    </div>
  );
};

export default FormErrors;
