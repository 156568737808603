import { gql } from '@apollo/client';

export default gql`
  fragment ChatMessage on ChatMessageType {
    id
    attachments
    message
    messageReferences {
      id
      url
      offset
    }
    correctedMessage
    messageWithKindlyEntities
    created
    fromBot
    name
    avatar
    buttons
    buttonsDisplayOrientation
    score
    exchangeType
    exchangeId
    imageCarousel
    imageCarouselSize
    isFollowup
    skillSubscriptionId
    handler
    fromWebhook
    form
    formSubmission {
      id
      context
      state
    }
    chatLanguageCode
    replyCandidates {
      id: Id
      selected
    }
    webHost
    webPath
    sender
    suggestions {
      id: Id
      preface
      message
      buttons
    }
    attachmentIds
    chatSource
    emailCc
  }
`;
