import { useParams } from 'react-router-dom';

import { AnalyticsContent, Grid } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import { useBooleanState } from 'frontend/hooks';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

import Filters, { FilterInfo } from '../../../Filters';
import Sessions from '../Sessions/Sessions';

const HIDE_FILTERS = { engagementCampaigns: true, nudges: true, feedbacks: true };

const SessionsDetail = () => {
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const { botId } = useParams();
  const filters = useFilters();

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} hideFilter={HIDE_FILTERS} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} />
        <Grid columns={1}>
          <Sessions scope={sageScope(SAGE_RESOURCE.BOT, botId)} filters={filters} />
        </Grid>
      </AnalyticsContent>
    </>
  );
};

export default SessionsDetail;
