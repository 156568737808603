import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { OrganizationLabelsDocument, type OrganizationLabelsQuery } from 'frontend/api/generated';

// FIXME: Make OrganizationLabelsQuery and LabelsForBotDocument have a consistent naming convention and format
export type OrgLabel = NonNullable<OrganizationLabelsQuery>[`organizationLabels`][number][`labels`][number];

export default function useOrgLabels(options?: { prefixBotName: boolean }): OrgLabel[] {
  const { organizationId } = useParams();
  const { data, loading } = useQuery(OrganizationLabelsDocument, {
    variables: { organizationId: organizationId! },
    skip: !organizationId,
  });

  const orgLabels: OrgLabel[] = useMemo(() => {
    if (loading || !data?.organizationLabels) {
      return [];
    }

    return data.organizationLabels
      .map(({ labels, botName }) => {
        if (options?.prefixBotName) {
          return labels.map(({ name, ...label }) => ({ ...label, name: `${name} (${botName})` }));
        }

        return labels;
      })
      .flat();
  }, [loading, data?.organizationLabels, options?.prefixBotName]);

  return orgLabels;
}
