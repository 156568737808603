import { gql } from '@apollo/client';

export default gql`
  mutation TransferDialogue(
    $id: UUID!
    $botId: ID
    $skillId: ID
    $deleteOriginal: Boolean
    $title: String
    $languageCode: String!
  ) {
    transferDialogue(
      id: $id
      botId: $botId
      skillId: $skillId
      deleteOriginal: $deleteOriginal
      title: $title
      languageCode: $languageCode
    ) {
      id
      deletedOriginal
    }
  }
`;
