/* eslint-disable react/jsx-props-no-spreading */

import { useParams } from 'react-router-dom';

import { EmojiSad, IntentSuggestion, LinkBreak, Send, SpeechBubble } from 'frontend/assets/icons';
import { List } from 'frontend/components';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';

import styles from './BotImprovementsView.scss';
import { BotImprovementCount } from '../../components';

export enum BotImprovementsViewEnum {
  SAMPLE_CANDIDATES = 'SAMPLE_CANDIDATES',
  DIALOGUE_CONFUSION = 'DIALOGUE_CONFUSION',
  INCOMPLETE_DIALOGUES = 'INCOMPLETE_DIALOGUES',
  BROKEN_LINKS = 'BROKEN_LINKS',
  DIALOGUE_CANDIDATES = 'DIALOGUE_CANDIDATES',
}

export type NavigationProperties = {
  title: string;
  key: string;
  hasPermissions?: string;
  icon?: React.ReactElement;
  getUrl?: (args: { botId: string; skillId?: string }) => string;
  isBotOnly?: boolean;
  rightContent?: React.ReactElement;
  showOnIntentWorkspace?: boolean;
  showOnNonIntentWorkspace?: boolean;
};

export type BotImprovementsViewNavigation = Record<keyof typeof BotImprovementsViewEnum, NavigationProperties>;

const navigationPages: BotImprovementsViewNavigation = {
  [BotImprovementsViewEnum.SAMPLE_CANDIDATES]: {
    title: 'Sample candidates',
    key: 'bi-sample-candidates',
    icon: <Send />,
    showOnIntentWorkspace: false,
    showOnNonIntentWorkspace: true,
    rightContent: <BotImprovementCount show="sampleCandidates" />,
    getUrl: ({ botId, skillId }) =>
      `/${botId ? 'workspace' : 'skill'}/${botId || skillId}/build/bot-improvements/sample-candidates`,
  },
  [BotImprovementsViewEnum.DIALOGUE_CONFUSION]: {
    title: 'Dialogue confusion',
    key: 'bi-dialogue-confusion',
    icon: <SpeechBubble />,
    showOnIntentWorkspace: false,
    showOnNonIntentWorkspace: true,
    rightContent: <BotImprovementCount show="dialogueConfusion" />,
    getUrl: ({ botId }) => `/workspace/${botId}/build/bot-improvements/dialogue-confusion`,
  },
  [BotImprovementsViewEnum.INCOMPLETE_DIALOGUES]: {
    title: 'Incomplete dialogues',
    key: 'bi-incomplete-dialogues',
    icon: <EmojiSad />,
    showOnIntentWorkspace: false,
    showOnNonIntentWorkspace: true,
    rightContent: <BotImprovementCount show="incompleteDialogues" />,
    getUrl: ({ botId }) => `/workspace/${botId}/build/bot-improvements/incomplete-dialogues`,
  },
  [BotImprovementsViewEnum.BROKEN_LINKS]: {
    title: 'Broken links',
    key: 'bi-broken-links',
    icon: <LinkBreak />,
    showOnIntentWorkspace: true,
    showOnNonIntentWorkspace: true,
    // TODO: Do broken links work on a skill level?
    getUrl: ({ botId, skillId }) =>
      `/${botId ? 'workspace' : 'skill'}/${botId || skillId}/build/bot-improvements/broken-links`,
    rightContent: <BotImprovementCount show="brokenLinks" />,
  },
  [BotImprovementsViewEnum.DIALOGUE_CANDIDATES]: {
    title: 'Dialogue candidates',
    key: 'bi-dialogue-candidates',
    icon: <IntentSuggestion />,
    showOnIntentWorkspace: true,
    showOnNonIntentWorkspace: false,
    // TODO: Do intent suggestion work on a skill level?
    getUrl: ({ botId, skillId }) =>
      `/${botId ? 'workspace' : 'skill'}/${botId || skillId}/build/bot-improvements/dialogue-candidates`,
    rightContent: <BotImprovementCount show="dialogueCandidates" />,
  },
};

const BotImprovementsView = () => {
  const { botId } = useParams();
  const { botSettings } = useBotSettings(botId);

  const checkConditions = (item: NavigationProperties) => {
    if (botSettings?.intentMatchEnabled) {
      return item.showOnIntentWorkspace;
    }
    return item.showOnNonIntentWorkspace;
  };

  return (
    <div className={styles.navigationContainer}>
      <List
        dataSource={Object.values(navigationPages)}
        renderItem={(item: NavigationProperties) =>
          checkConditions(item) && (
            <List.NavLinkItem key={item.key} to={item.getUrl?.({ botId: botId! }) as string}>
              <div className={styles.navigationElContainer}>
                {item.icon}
                <span>{item.title}</span>
              </div>
              <div className={styles.botImprovementsRightContent}>{item.rightContent}</div>
            </List.NavLinkItem>
          )
        }
      />
    </div>
  );
};

export default BotImprovementsView;
