import { Field, Form } from 'react-final-form';

import defaultAvatar from 'frontend/assets/images/member_avatar.svg?url';
import { Button, Checkbox, FileUpload, FormErrors, GuardUnsaved, Input, Loader, PageBar } from 'frontend/components';
import Panel, { HorizontalRule } from 'frontend/components/Panel';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import { chain, max, required } from 'frontend/form/validators';

import styles from './ProfileSettings.scss';
import useProfileSettings from './useProfileSettings';

const SUBSCRIBE = {};

const ProfileSettings = () => {
  const { loading, onSubmit, initialValues, setFile } = useProfileSettings();

  if (loading) {
    return <Loader size="large" />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscribe={SUBSCRIBE}
      render={({ handleSubmit, form, values }) => (
        <>
          <GuardUnsaved />
          <form onSubmit={handleSubmit}>
            <PageBar>
              <PageBar.FormButtons />
            </PageBar>
            <FormErrors className={styles.formErrors} />
            <div className={styles.profileSettings}>
              <Panel>
                <section className={styles.sectionWidthLimited}>
                  <h3>Name</h3>
                  <Field
                    component={Input}
                    name="firstName"
                    label="First name"
                    placeholder="First name"
                    inputLimit={150}
                    validate={chain([required, max(150)])}
                  />
                  <Field
                    component={Input}
                    name="lastName"
                    label="Last name"
                    placeholder="Last name"
                    inputLimit={150}
                    validate={chain([required, max(150)])}
                  />
                  <Field
                    component={Input}
                    name="alias"
                    label="Agent alias"
                    placeholder="Visible to end users when chatting"
                    inputLimit={150}
                    validate={chain([required, max(150)])}
                  />
                  <p>
                    <strong>Who can see my name?</strong>
                    <br /> Your name will appear on your profile.
                    <br /> It will also appear in chats with your users if you haven&apos;t customised your{' '}
                    <em>Agent Alias</em> yet.
                  </p>
                  <h3>Email</h3>
                  <Field component={Input} name="email" className="m-b-md" readOnly aria-label="Email" />

                  <h3>Profile picture</h3>
                  <div className={styles.avatarField}>
                    <div
                      className={styles.avatar}
                      style={{ backgroundImage: `url(${values.avatarUrl || defaultAvatar})` }}
                    />
                    <div className="flex-column">
                      <Field component={Input} name="avatarUrl" hidden />
                      <FileUpload
                        text="Upload picture"
                        accept="image/png,image/jpeg"
                        onUpload={([{ source, file }]) => {
                          form.change('avatarUrl', source);
                          setFile(file);
                        }}
                      />
                      {values.avatarUrl && (
                        <Button onClick={() => form.change('avatarUrl', null)} flat>
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                </section>

                <HorizontalRule />

                <section className={styles.sectionWidthLimited}>
                  <h3>Privacy</h3>
                  <Field
                    component={Checkbox}
                    name="trackingConsentApproved"
                    className="m-b-2"
                    type="checkbox"
                    label="Allow tracking my usage on app.kindly.ai"
                  />
                  <p>
                    <strong>What info about me is tracked?</strong>
                    <br /> Your actions on app.kindly.ai including metadata such as the time and your browser info.
                  </p>
                </section>

                <HorizontalRule />

                <section className={styles.sectionWidthLimited}>
                  <h3>Kindly Chat Admin</h3>
                  <Field
                    component={Checkbox}
                    name="enableChatAdminMode"
                    className="m-b-2"
                    type="checkbox"
                    label="Enable Chat Admin mode"
                  />
                  Enabling this setting gives your account additional features for the chat bubble that are only visible
                  to you, and only when you are inside the platform.{' '}
                  <HelpCenterLink path="/your-profile#ld-kindly-chat-admin">
                    Read more about Kindly Chat Admin in our Help Center
                  </HelpCenterLink>
                </section>
              </Panel>
            </div>
          </form>
        </>
      )}
    />
  );
};

export default ProfileSettings;
