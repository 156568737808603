import { get } from 'lodash';
import { useMemo } from 'react';

import { welcomePageTypes } from '../../constants';

export const useStyles = (values) => {
  const colorHeaderBackground = get(values, 'colorHeaderBackground', '');
  const colorButtonBackground = get(values, 'colorButtonBackground', '');
  const colorButtonText = get(values, 'colorButtonText', '');
  const url = get(values, 'welcomePageImageUrl', null);
  const type = get(values, 'welcomePageType', welcomePageTypes.NONE);

  const headerContentStyle = useMemo(
    () =>
      type === welcomePageTypes.COLOR
        ? {
            background: colorHeaderBackground,
            backgroundColor: colorHeaderBackground,
          }
        : {
            background: colorHeaderBackground,
            backgroundColor: colorHeaderBackground,
            backgroundImage: `url(${url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
    [colorHeaderBackground, type, url],
  );

  const contentWrapperStyle = useMemo(
    () => ({
      borderColor: colorHeaderBackground,
    }),
    [colorHeaderBackground],
  );

  const buttonStyle = useMemo(
    () => ({
      background: colorButtonBackground,
      backgroundColor: colorButtonBackground,
      borderColor: colorButtonBackground,
      color: colorButtonText,
    }),
    [colorButtonBackground, colorButtonText],
  );

  return { headerContentStyle, contentWrapperStyle, buttonStyle };
};
