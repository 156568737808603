import { useQuery } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';

import { GetAllNudgeSlugsDocument, type ImageCarouselType, type ProductNudgeType } from 'frontend/api/generated';
import { FormErrors, ImageCarousel, Input, Panel, ToggleSwitch } from 'frontend/components';
import { chain, max, required, url } from 'frontend/form/validators';
import { useIsRunning, useUploadCarouselImages } from 'frontend/hooks';
import { getUploadPath } from 'frontend/hooks/useUploadCarouselImages';
import { finalFormArraysMutatorsFix } from 'frontend/utils';

import { nudgeDefaultTexts } from '../../constants';
import { useNudges, useSlugValidator, useUpdateNudges } from '../../hooks';
import { Header } from '../Header';
import { Preview } from '../Preview';
import styles from '../shared.scss';

function findImageCarouselIndex(carousels = [], languageCode) {
  const idx = carousels.findIndex((carousel: ImageCarouselType) => carousel.languageCode === languageCode);
  if (idx === -1) {
    return carousels.length;
  }
  return idx;
}

interface ProductNudgeProps {
  nudge?: ProductNudgeType;
}

const ProductNudge = ({ nudge }: ProductNudgeProps) => {
  const editing = Boolean(nudge);
  const { onProductUpdate } = useUpdateNudges();
  const { botId, onProductCreate, currentLanguage } = useNudges();
  const { data } = useQuery(GetAllNudgeSlugsDocument, { variables: { botId: botId as string } });

  const { validateSlug } = useSlugValidator({ data, nudge });

  const onSubmitWithUpload = useUploadCarouselImages(editing ? onProductUpdate : onProductCreate, {
    uploadPath: getUploadPath('nudge', botId as string),
  });

  const [onSubmit] = useIsRunning(onSubmitWithUpload);

  if (!currentLanguage) {
    return null;
  }

  return (
    <Panel className={styles.formPanel}>
      <Form
        mutators={finalFormArraysMutatorsFix(arrayMutators)}
        onSubmit={onSubmit}
        initialValues={nudge}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className={styles.container}>
            <Header
              title={values?.title?.[currentLanguage] ?? nudgeDefaultTexts.title}
              slug={values?.slug ?? nudgeDefaultTexts.slug}
            />
            <Preview>
              <Preview.Intent />
              <Preview.Nudge nudge={values} />
            </Preview>
            <div className={styles.containerEditor}>
              <div className={styles.panelHeader}>
                <h3>Product recommendation</h3>
                <p>Use a product nudge to notify users about new releases</p>
              </div>
              <FormErrors className={styles.formErrors} />
              <div className={styles.inputContainer}>
                <Field
                  name="slug"
                  label="Slug (this can not be edited later)"
                  component={Input}
                  readOnly={!!nudge}
                  inputLimit={50}
                  validate={chain([required, validateSlug])}
                  placeholder="Choose a slug for your nudge"
                />
              </div>
              <div className={styles.seperator} />
              <h5>Text Content</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <Field
                  name={`title.${currentLanguage}`}
                  label="Title"
                  component={Input}
                  inputLimit={80}
                  validate={chain([required, max(80)])}
                  placeholder="Choose a title"
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  name={`bodyText.${currentLanguage}`}
                  label="Body text"
                  component={Input}
                  inputLimit={255}
                  validate={chain([required, max(255)])}
                  placeholder="Choose a body text"
                  multiline
                />
              </div>
              <div className={styles.seperator} />
              <h5>Call to action</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <Field
                  name={`buttonText.${currentLanguage}`}
                  label="Button text"
                  component={Input}
                  inputLimit={80}
                  validate={chain([required, max(80)])}
                  placeholder="Choose a button text"
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  name={`buttonLink.${currentLanguage}`}
                  label="Button link"
                  component={Input}
                  validate={chain([required, url])}
                  placeholder="Choose a button link"
                />
              </div>
              <div className={styles.seperator} />
              <h5>Product carousel</h5>
              <div className={styles.inputContainer}>
                <Field
                  name={`carouselTitle.${currentLanguage}`}
                  label="Carousel title"
                  component={Input}
                  inputLimit={80}
                  validate={chain([required, max(80)])}
                  placeholder="Choose a carousel title"
                />
              </div>
              <ImageCarousel
                fieldName={`imageCarousels.${findImageCarouselIndex(values.imageCarousels, currentLanguage)}`}
                currentLanguage={currentLanguage}
                className={styles.carousel}
                imageFields={['title', 'description', 'altText', 'linkText', 'linkUrl', 'productUrl', 'price']}
              />
              <div className={styles.seperator} />
              <h5>Analytics Setup</h5>
              <p className={styles.helpText}>Setup url for completed purchase</p>
              <div className={`m-b-3 ${styles.inputContainer}`}>
                <Field
                  component={Input}
                  name="analyticsCompletionPagePath"
                  aria-label="Completion Page Url"
                  label="Completion Page Url"
                  placeholder="/order/"
                />
              </div>
              <div className={styles.seperator} />
              <h5>Toggle nudge</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <p className={styles.helpText}>You can toggle this nudge for the selected language</p>
                <div className={styles.toggleContainer}>
                  <ToggleSwitch name={`enabled.${currentLanguage}`} />
                  <label htmlFor="ToggleSwitch-enabled">
                    {values?.enabled?.[currentLanguage] ? 'Enabled' : 'Disabled'}
                  </label>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </Panel>
  );
};

export default ProductNudge;
