import { CompositeDecorator, EditorState } from 'draft-js';
import { useCallback, useEffect, useLayoutEffect } from 'react';

import useComposerState from './useComposerState';
import { CustomTag, HashTag, Highlight } from '../components';
import { HIGHLIGHT, TAG_TYPES } from '../constants';
import { checkCharacterForTags, getHashtagMatch, getHashtagRegex } from '../utils';

const findDraftEntities = (tagTypes) => (contentBlock, callback, content) => {
  const checkCharacter = checkCharacterForTags(tagTypes, content);
  contentBlock.findEntityRanges(checkCharacter, callback);
};

const findHashtag = (contentBlock, callback) => {
  const hashtagRegex = getHashtagRegex();
  const text = contentBlock.getText();
  let match = getHashtagMatch(hashtagRegex, text);

  while (match) {
    callback(match.start, match.end);
    match = getHashtagMatch(hashtagRegex, text);
  }
};

const createDecorator = ({ highlightButtonText, openDropdown, name }) => {
  const findCustomTag = findDraftEntities(Object.values(TAG_TYPES));
  const findHighlight = findDraftEntities([HIGHLIGHT]);
  const highlightProps = { name, buttonText: highlightButtonText, openDropdown };

  const decorator = new CompositeDecorator([
    { strategy: findCustomTag, component: CustomTag, props: { name } },
    { strategy: findHighlight, component: Highlight, props: highlightProps },
    { strategy: findHashtag, component: HashTag },
  ]);

  return decorator;
};

export default function useDecorator({ highlightButtonText, openDropdown, name }) {
  const { state, setState } = useComposerState(name);

  const setDecorator = useCallback(() => {
    if (state.getDecorator()) return;

    const decorator = createDecorator({ highlightButtonText, openDropdown, name });

    setState(EditorState.set(state, { decorator }));
  }, [highlightButtonText, name, openDropdown, setState, state]);

  // Some use cases require useEffect (bot reply, or the slots will not show until a use action forces a rerender)
  // while others require useLayoutEffect (edit sample, or the entities will flicker when starting edit). Note that,
  // due to the guard in setDecorator, the decorator is still only set once.
  useEffect(() => setDecorator(), [setDecorator]);
  useLayoutEffect(() => setDecorator(), [setDecorator]);
}
