import { gql } from '@apollo/client';

import { EVALUATION_FRAGMENT } from './fragments';

const CREATE_EVALUATION_QUERY = gql`
  mutation CreateEvaluation($botId: ID!, $evaluationId: ID!, $dialogueId: UUID!, $userSays: String!) {
    createEvaluationQuery(botId: $botId, evaluationId: $evaluationId, dialogueId: $dialogueId, userSays: $userSays) {
      ...Evaluation
    }
  }
  ${EVALUATION_FRAGMENT}
`;
export default CREATE_EVALUATION_QUERY;
