import { gql } from '@apollo/client';

import { DialogueModFragment } from 'frontend/features/Build/graphql';

export default gql`
  query GetDialogueMod($id: UUID, $botId: ID!) {
    dialogueMod(id: $id, botId: $botId) {
      ...DialogueMod
    }
  }
  ${DialogueModFragment}
`;
