import { gql } from '@apollo/client';

import { DialogueFragment } from 'frontend/features/Build/graphql';

export default gql`
  mutation DropDialogueInTopic($botId: ID, $skillId: ID, $sourceDialogueId: UUID!, $targetTopicId: ID) {
    dropDialogueInTopic(
      botId: $botId
      skillId: $skillId
      sourceDialogueId: $sourceDialogueId
      targetTopicId: $targetTopicId
    ) {
      ...Dialogue
    }
  }
  ${DialogueFragment}
`;
