import cx from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import TagsInput from 'react-tagsinput';

import { Close } from 'frontend/assets/icons';
import { Button, Icon, InputErrorWrapper } from 'frontend/components';
import sharedStyles from 'frontend/features/Inbox/inbox-styles.scss';

import styles from './HandoverForm.scss';

const EmailTagField = ({
  id,
  input: { onChange, value },
  meta: { submitFailed, submitError, error },
  placeholder,
  maxTags,
  onlyUnique,
  isCcOpen,
}) => {
  const hasError = Boolean(submitFailed && (submitError || error));
  const errorMessage = submitError || error || '';
  const tagsValue = useMemo(() => (value ? [...value] : []), [value]);
  const renderLayout = (tagComponents, inputComponents) => (
    <>
      {tagComponents.map(({ key, props: { children } }) => (
        <span key={key} className={styles.tag}>
          {children[0]}
          <Button
            className={cx(styles.closeButton, styles.closeButtonEmailTag)}
            onClick={children[1].props.onClick}
            flat
          >
            <Icon component={Close} color="white" title="Remove email address" />
          </Button>
        </span>
      ))}
      {inputComponents}
    </>
  );

  const ENTER_KEY = 13;
  return (
    <InputErrorWrapper
      hasError={hasError}
      displayError={errorMessage}
      className={cx({ [sharedStyles.textWrapper]: placeholder === 'To' && isCcOpen })}
      errorMessageClassName={styles.inputErrorMessage}
    >
      <TagsInput
        className={styles.tagsInput}
        value={tagsValue}
        inputProps={{ placeholder, className: styles.tagsInput, id }}
        onChange={onChange}
        addKeys={[ENTER_KEY]} // Add new tag when Enter is pressed
        maxTags={maxTags}
        onlyUnique={onlyUnique}
        renderLayout={renderLayout}
        addOnBlur
      />
    </InputErrorWrapper>
  );
};

EmailTagField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }).isRequired,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    submitError: PropTypes.string,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  maxTags: PropTypes.number,
  onlyUnique: PropTypes.bool,
  isCcOpen: PropTypes.bool,
};

export default EmailTagField;
