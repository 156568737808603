import { gql } from '@apollo/client';

import { privacySettingsFragment } from '../fragments';

export default gql`
  query GetPrivacySettings($botId: ID!) {
    bot(id: $botId) {
      id
      privacySettings {
        ...PrivacySettings
      }
    }
  }
  ${privacySettingsFragment}
`;
