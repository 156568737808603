import { useEffect, useState } from 'react';

export default () => {
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    if (mounted) setMounted(false);
  }, [mounted]);

  return mounted;
};
