import { gql } from '@apollo/client';

import { ENTITY } from '../fragments';

export default gql`
  mutation UpdateEntity($botId: ID, $skillId: ID, $id: UUID!, $entityInput: EntityInputType!) {
    updateEntity(botId: $botId, skillId: $skillId, id: $id, entityInput: $entityInput) {
      ...Entity
    }
  }
  ${ENTITY}
`;
