import { isNil } from 'lodash';
import type { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { FormFieldType } from 'frontend/api/generated';
import { DRAGGABLE_TYPES } from 'frontend/features/Dnd/constants';
import { max, required as requiredValidator } from 'frontend/form/validators';
import numberOrFloat from 'frontend/form/validators/numberOrFloat';
import patternPattern from 'frontend/form/validators/pattern';

import inputTypes from './constants';
import { handleFloatInputs } from './helpers';
import { linkedValidatorComparer, validateSlug } from './validation';

export type FieldConfigFieldType = {
  label?: ((val: string) => string) | string;
  placeholderText: string;
  type: string;
  canHide: boolean | ((value: any) => boolean);
  validation: any[];
  tooltip?: string | ReactElement;
  key?: string;
  onRemove: () => string | number | object | null | undefined;
  identity?: (value: string | number) => string | number | boolean | undefined;
  groupWith: Partial<FieldConfigFieldType>[];
  groupDisplay?: string;
  addMoreTitle?: string;
  isFieldArray?: boolean;
  defaults?: () => object;
  switchValues?: [string | boolean | number, string | boolean | number];
  getFieldArrayName?: ({ key, fieldName }: { key: string; fieldName: string }) => string;
  additionalInputProps?: {
    min?: number;
    step?: string;
    defaultValue?: boolean | string | number;
  };
  canSort?: boolean;
  getName: ({
    key,
    fieldName,
    index,
    field,
  }: {
    key: string;
    fieldName: string;
    index?: number;
    field?: FormFieldType;
  }) => string;
  description?: string | React.ReactElement;
  options?: { label: string; value: string | number }[];
};

export type FieldsConfigType = {
  required: FieldConfigFieldType;
  label: FieldConfigFieldType;
  slug: FieldConfigFieldType;
  placeholderText: FieldConfigFieldType;
  helpText: FieldConfigFieldType;
  affix: FieldConfigFieldType;
  maxLength: FieldConfigFieldType;
  minimum: FieldConfigFieldType;
  maximum: FieldConfigFieldType;
  defaultValue: FieldConfigFieldType;
  options: FieldConfigFieldType;
  pattern: FieldConfigFieldType;
  inputDescription?: string;
};

export type InputTypes = 'TEXT' | 'EMAIL' | 'NUMBER' | 'RANGE' | 'SELECT' | 'CHECKBOX' | 'RADIO' | 'FILE' | 'TEXTAREA';

export type InputConfigsType = {
  TEXT?: Partial<FieldsConfigType>[];
  EMAIL?: Partial<FieldsConfigType>[];
  NUMBER?: Partial<FieldsConfigType>[];
  RANGE?: Partial<FieldsConfigType>[];
  SELECT?: Partial<FieldsConfigType>[];
  CHECKBOX?: Partial<FieldsConfigType>[];
  RADIO?: Partial<FieldsConfigType>[];
  FILE?: Partial<FieldsConfigType>[];
  TEXTAREA?: Partial<FieldsConfigType>[];
};

const requiredField = {
  required: {
    label: 'Required field',
    type: 'checkbox',
    placeholderText: '',
    canHide: false,
    validation: [],
    tooltip: '',
    additionalInputProps: {},
    onRemove: () => null,
    groupDisplay: 'column',
    groupWith: [],
    identity: undefined,
    getName: ({ fieldName, key }) => `${fieldName}.${key}`,
  },
};

const label = {
  label: {
    label: 'Title',
    type: 'text',
    placeholderText: 'Type here',
    validation: [requiredValidator, max(500)],
    tooltip: '',
    canHide: false,
    additionalInputProps: {},
    onRemove: () => '',
    groupDisplay: 'row',
    groupWith: [],
    identity: (value) => (value.toString().trim().length > 0 ? value.toString() : ''),
    getName: ({ fieldName, key }) => `${fieldName}.texts.${key}`,
  },
};

const slug = {
  slug: {
    label: 'Context key',
    type: 'text',
    placeholderText: 'Type here',
    validation: [validateSlug, max(100)],
    tooltip: 'Auto-generated if empty',
    canHide: (value) => !(value && typeof value !== 'undefined' && value !== null),
    additionalInputProps: {},
    onRemove: () => '',
    groupDisplay: 'column',
    groupWith: [],
    identity: (value) => value.toString()?.trim(),
    getName: ({ fieldName, key }) => `${fieldName}.${key}`,
  },
};

const placeholderText = {
  placeholderText: {
    label: 'Placeholder',
    type: 'text',
    placeholderText: 'Type here',
    validation: [],
    tooltip: '',
    canHide: true,
    additionalInputProps: {},
    onRemove: () => '',
    groupDisplay: 'column',
    groupWith: [],
    identity: (value) => value.toString(),
    getName: ({ fieldName, key }) => `${fieldName}.texts.${key}`,
  },
};

const helpText = {
  helpText: {
    label: 'Help text',
    type: 'text',
    placeholderText: 'Type here',
    validation: [],
    tooltip: '',
    canHide: true,
    onRemove: () => '',
    groupDisplay: 'column',
    groupWith: [],
    identity: (value) => value.toString(),
    getName: ({ fieldName, key }) => `${fieldName}.texts.${key}`,
  },
};

const maxLength: Partial<FieldsConfigType> = {
  maxLength: {
    label: 'Character limit',
    placeholderText: 'Type here',
    type: 'number',
    canHide: true,
    validation: [],
    onRemove: () => 30,
    groupWith: [],
    identity: (value) => parseInt(value.toString(), 10) || '',
    additionalInputProps: {
      min: 0,
      defaultValue: 30,
    },
    getName: ({ field, fieldName, key }) => {
      if (field) {
        const findIndex = field.validators.findIndex((validator) => !isNil(validator[key]));
        if (findIndex >= 0) {
          return `${fieldName}.validators[${findIndex}].maxLength`;
        }
        return `${fieldName}.validators[${field.validators.length + 1}].maxLength`;
      }
      return '';
    },
  },
};

const maximum = {
  maximum: {
    label: 'Maximum',
    placeholderText: 'Type here',
    key: 'maximum',
    type: 'number',
    canHide: true,
    validation: [numberOrFloat, linkedValidatorComparer('maximum', 'minimum')],
    onRemove: () => 1,
    groupWith: [],
    additionalInputProps: {
      defaultValue: 1,
    },
    identity: handleFloatInputs,
    getName: ({ field, fieldName, key }) => {
      if (field) {
        const findIndex = field.validators.findIndex((validator) => !isNil(validator[key]));
        if (findIndex >= 0) {
          return `${fieldName}.validators[${findIndex}].maximum`;
        }
        return `${fieldName}.validators[${field.validators.length}].maximum`;
      }
      return '';
    },
  },
};

const minimum = {
  minimum: {
    label: 'Minimum',
    placeholderText: 'Type here',
    type: 'number',
    canHide: true,
    validation: [numberOrFloat, linkedValidatorComparer('minimum', 'maximum')],
    onRemove: () => 0,
    additionalInputProps: {
      defaultValue: 0,
    },
    groupDisplay: 'row',
    identity: handleFloatInputs,
    groupWith: [],
    getName: ({ field, fieldName, key }) => {
      if (field) {
        const findIndex = field.validators.findIndex((validator) => !isNil(validator[key]));
        if (findIndex >= 0) {
          return `${fieldName}.validators[${findIndex}].minimum`;
        }
        return `${fieldName}.validators[${field.validators.length}].minimum`;
      }
      return '';
    },
  },
};

const minMax = {
  minimum: {
    ...minimum.minimum,
    groupWith: [maximum.maximum as FieldConfigFieldType],
  } as FieldConfigFieldType,
};

const affix = {
  affix: {
    label: 'Affix',
    type: 'select',
    options: ['PREFIX', 'SUFFIX'].map((key) => ({
      label: key.toUpperCase(),
      value: key.toUpperCase(),
    })),
    placeholderText: 'Type here',
    validation: [],
    tooltip: '',
    canHide: true,
    additionalInputProps: {},
    onRemove: () => null,
    groupDisplay: 'row',
    identity: undefined,
    groupWith: [],
    getName: ({ fieldName, key }) => `${fieldName}.${key}`,
  },
};

const EXAMPLE_PATTERN_EMAIL = /[^@\s]+@[^@\s]+\.[^@\s]+/; // https://stackoverflow.com/a/36379040
const EXAMPLE_PATTERN_DIGITS = /\d{10}/;

function stringifyPattern(pattern: RegExp): string {
  return pattern.toString().replace(/^\/|\/$/g, '');
}

function PatternExample({ pattern }: { pattern: RegExp }): React.JSX.Element {
  /*
    Renders a pattern as a stringified regular expression, removing the leading and trailing slashes.
   */
  return <code>{stringifyPattern(pattern)}</code>;
}

const pattern: Partial<FieldsConfigType> = {
  pattern: {
    label: 'Pattern',
    placeholderText: `e.g. ${stringifyPattern(EXAMPLE_PATTERN_DIGITS)}`,
    type: 'text',
    canHide: true,
    validation: [patternPattern],
    onRemove: () => 0,
    additionalInputProps: {
      defaultValue: '',
    },
    groupDisplay: 'column',
    groupWith: [],
    description: (
      <>
        Pattern is an html attribute that validates the input to a regular expression string. It has some limitations
        compared to Javascript regular expressions. More on the topic:{' '}
        <a href="https://html.spec.whatwg.org/multipage/input.html#the-pattern-attribute">whatwg spec</a>,{' '}
        <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/pattern">mdn</a>,{' '}
        <a href="https://html.com/attributes/input-pattern/">html</a>.
        <br />
        Examples: <br />
        Email that must have a period character in the domain part: <PatternExample pattern={EXAMPLE_PATTERN_EMAIL} />
        <br />
        Exactly 10 digits: <PatternExample pattern={EXAMPLE_PATTERN_DIGITS} />
      </>
    ),
    getName: ({ field, fieldName, key }) => {
      if (field) {
        const findIndex = field.validators.findIndex((validator) => !!validator[key]);

        if (findIndex >= 0) {
          return `${fieldName}.validators[${findIndex}].pattern`;
        }
        return `${fieldName}.validators[${field.validators.length}].pattern`;
      }
      return '';
    },
  },
};

const affixValue = {
  label: 'Affix Value',
  key: 'affixValue',
  type: 'text',
  placeholderText: 'Type here',
  validation: [requiredValidator],
  tooltip: '',
  canHide: true,
  additionalInputProps: {},
  onRemove: () => '',
  groupDisplay: 'column',
  groupWith: [],
  identity: (value) => value.toString(),
  getName: ({ fieldName, key }) => `${fieldName}.texts.${key}`,
};

const affixAffixValue = {
  ...affix,
  affix: {
    ...affix.affix,
    groupWith: [affixValue],
  },
};

const inputConfigs: InputConfigsType = {
  TEXT: [
    {
      required: {
        ...requiredField.required,
        groupWith: [
          {
            type: 'text',
            key: 'multiline-switch',
            getName: ({ fieldName }) => `${fieldName}.inputType`,
            label: 'Multiline',
            switchValues: [inputTypes.TEXT, inputTypes.TEXTAREA],
            identity: () => inputTypes.TEXTAREA,
          },
        ],
      },
    },
    label,
    slug,
    placeholderText,
    helpText,
    maxLength,
    pattern,
  ],
  EMAIL: [requiredField, label, slug, placeholderText, helpText, maxLength, pattern],

  NUMBER: [requiredField, label, slug, placeholderText, helpText, minMax, affixAffixValue],

  RANGE: [
    label,
    slug,
    helpText,
    {
      ...minMax,
      minimum: {
        ...minMax.minimum,
        canHide: false,
        validation: [requiredValidator, ...[minMax.minimum?.validation || []]],
        groupWith: [
          {
            ...maximum.maximum,
            canHide: false,
            validation: [requiredValidator, ...[maximum.maximum?.validation || []]],
          },
        ] as FieldConfigFieldType[],
      } as FieldConfigFieldType,
    },
    {
      defaultValue: {
        label: 'Default',
        placeholderText: 'Type here',
        type: 'number',
        canHide: false,
        validation: [
          linkedValidatorComparer('defaultValue', 'minimum', 'attributes'),
          linkedValidatorComparer('defaultValue', 'maximum', 'attributes'),
          linkedValidatorComparer('defaultValue', 'step', 'attributes', 'attributes'),
        ],
        onRemove: () => 0,
        additionalInputProps: {
          defaultValue: 0,
        },
        groupDisplay: 'row',
        identity: handleFloatInputs,
        getName: ({ fieldName, key }) => `${fieldName}.attributes.${key}`,
        groupWith: [
          {
            label: 'Step',
            placeholderText: 'Type here',
            key: 'step',
            type: 'number',
            canHide: false,
            validation: [
              requiredValidator,
              linkedValidatorComparer('step', 'minimum', 'attributes'),
              linkedValidatorComparer('step', 'maximum', 'attributes'),
              linkedValidatorComparer('step', 'defaultValue', 'attributes', 'attributes'),
            ],
            onRemove: () => 1,
            groupWith: [],
            additionalInputProps: {
              step: '.01',
              defaultValue: 1,
            },
            getName: ({ fieldName, key }) => `${fieldName}.attributes.${key}`,
            identity: handleFloatInputs,
          },
        ],
      },
    },
    affixAffixValue,
  ],
  SELECT: [
    requiredField,
    label,
    slug,
    placeholderText,
    helpText,
    {
      options: {
        canSort: true,
        label: (val) => `Option ${val + 1}`,
        type: 'text',
        placeholderText: 'Option name',
        validation: [requiredValidator],
        tooltip: '',
        canHide: (val) => val && val > 1,
        onRemove: () => '',
        key: 'select',
        groupDisplay: 'column',
        isFieldArray: true,
        getFieldArrayName: ({ fieldName, key }) => `${fieldName}.attributes.${key}`,
        defaults: () => ({
          label: '',
          value: '',
          id: `temporary-uuid-${uuidv4()}`,
          __typename: DRAGGABLE_TYPES.FORM_FIELD_ATTRIBUTE_OPTIONS,
        }),
        addMoreTitle: 'Add option',
        getName: ({ fieldName, key, index }) => `${fieldName}.attributes.${key}[${index}].label`,
        groupWith: [
          {
            label: undefined,
            type: 'text',
            placeholderText: 'Value',
            validation: [requiredValidator],
            tooltip: '',
            canHide: false,
            onRemove: () => '',
            groupDisplay: 'column',
            groupWith: [],
            identity: (value) => value.toString(),
            // This name could come from the fieldArray
            // But for consistency and flexibility, we combine it here
            getName: ({ fieldName, key, index }) => `${fieldName}.attributes.${key}[${index}].value`,
          },
        ],
        identity: (value) => value.toString(),
      },
    },
  ],
  CHECKBOX: [
    {
      required: {
        ...requiredField.required,
        groupWith: [
          {
            type: 'checkbox',
            key: 'multiple-options-switch',
            getName: ({ fieldName }) => `${fieldName}.inputType`,
            label: 'Single option',
            switchValues: [inputTypes.CHECKBOX, inputTypes.RADIO],
            identity: () => inputTypes.RADIO,
          },
        ],
      },
    },
    label,
    slug,
    helpText,
    {
      options: {
        canSort: true,
        label: (val) => `Checkbox ${val + 1}`,
        type: 'text',
        placeholderText: 'Option name',
        validation: [requiredValidator],
        tooltip: '',
        canHide: (val) => val && val > 1,
        onRemove: () => '',
        key: 'checkbox',
        groupDisplay: 'column',
        isFieldArray: true,
        getFieldArrayName: ({ fieldName, key }) => `${fieldName}.attributes.${key}`,
        defaults: () => ({
          label: '',
          value: '',
          id: `temporary-uuid-${uuidv4()}`,
          __typename: DRAGGABLE_TYPES.FORM_FIELD_ATTRIBUTE_OPTIONS,
        }),
        addMoreTitle: 'Add Checkbox',
        getName: ({ fieldName, key, index }) => `${fieldName}.attributes.${key}[${index}].label`,
        groupWith: [
          {
            label: undefined,
            type: 'text',
            placeholderText: 'Value',
            validation: [requiredValidator],
            tooltip: '',
            canHide: false,
            onRemove: () => '',
            groupDisplay: 'column',
            groupWith: [],
            identity: (value) => value.toString(),
            getName: ({ fieldName, key, index }) => `${fieldName}.attributes.${key}[${index}].value`,
          },
        ],
        identity: (value) => value.toString(),
      },
    },
  ],
  RADIO: [
    {
      required: {
        ...requiredField.required,
        groupWith: [
          {
            type: 'checkbox',
            key: 'multiple-options-switch',
            getName: ({ fieldName }) => `${fieldName}.inputType`,
            label: 'Single option',
            switchValues: [inputTypes.CHECKBOX, inputTypes.RADIO],
            identity: () => inputTypes.CHECKBOX,
          },
        ],
      },
    },
    label,
    slug,
    helpText,
    {
      options: {
        label: (val) => `Radio ${val + 1}`,
        type: 'text',
        canSort: true,
        placeholderText: 'Option name',
        validation: [requiredValidator],
        tooltip: '',
        canHide: (val) => val && val > 1,
        onRemove: () => '',
        key: 'radio',
        groupDisplay: 'column',
        isFieldArray: true,
        getFieldArrayName: ({ fieldName, key }) => `${fieldName}.attributes.${key}`,
        defaults: () => ({
          label: '',
          value: '',
          id: `temporary-uuid-${uuidv4()}`,
          __typename: DRAGGABLE_TYPES.FORM_FIELD_ATTRIBUTE_OPTIONS,
        }),
        addMoreTitle: 'Add Radio',
        getName: ({ fieldName, key, index }) => `${fieldName}.attributes.${key}[${index}].label`,
        groupWith: [
          {
            label: undefined,
            type: 'text',
            placeholderText: 'Value',
            validation: [requiredValidator],
            tooltip: '',
            canHide: false,
            onRemove: () => '',
            groupDisplay: 'column',
            groupWith: [],
            identity: (value) => value.toString(),
            getName: ({ fieldName, key, index }) => `${fieldName}.attributes.${key}[${index}].value`,
          },
        ],
        identity: (value) => value.toString(),
      },
    },
  ],
  FILE: [
    requiredField,
    label,
    helpText,
    slug,
    {
      placeholderText: {
        ...placeholderText.placeholderText,
        label: 'Button text',
        placeholderText: 'Drag and drop or [click to upload]',
        canHide: false,
        validation: [requiredValidator],
        groupDisplay: 'column',
        description:
          'Input `[click to upload]` creates a quicklink to upload files. The text inside the brackets can be edited.',
      },
    },
    {
      inputDescription:
        'Users can upload files up to file size of 50MB. Supported file formats: PDF, CSV, TXT, JPG, PNG.',
    },
  ],
  TEXTAREA: [
    {
      required: {
        ...requiredField.required,
        groupWith: [
          {
            type: 'text',
            key: 'multiline-switch',
            getName: ({ fieldName }) => `${fieldName}.inputType`,
            label: 'Multiline',
            switchValues: [inputTypes.TEXT, inputTypes.TEXTAREA],
            identity: () => inputTypes.TEXT,
          },
        ],
      },
    },
    label,
    slug,
    placeholderText,
    helpText,
    maxLength,
  ],
};

export default inputConfigs;
