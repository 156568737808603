import { useCallback, useEffect, useState } from 'react';

export default () => {
  const [shiftKey, setShiftKey] = useState(false);

  const onKeyDown = useCallback(
    (e) => {
      if (e.shiftKey && !shiftKey) setShiftKey(true);
    },
    [shiftKey],
  );

  const onKeyUp = useCallback(() => {
    if (shiftKey) setShiftKey(false);
  }, [shiftKey]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  return shiftKey;
};
