import { gql } from '@apollo/client';

export default gql`
  query MessageClusterReport($botId: ID!, $id: UUID!) {
    messageClusterReport(botId: $botId, id: $id) {
      id
      status
      messageType
      chatMessages
      redacted
      fromDate
      toDate
      clusters {
        id
        index
        userMessages
        salientTokens
      }
    }
  }
`;
