import { useEffect } from 'react';

const useOnEscape = (onEscape) => {
  useEffect(() => {
    const onKeyDown = ({ keyCode }) => {
      if (keyCode === 27) {
        onEscape();
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onEscape]);
};
export default useOnEscape;
