import cx from 'classnames';

import styles from './Modal.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Header = ({ children, className }: Props) => <div className={cx(styles.header, className)}>{children}</div>;

export default Header;
