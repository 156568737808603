import { EditorState, Modifier } from 'draft-js';
import { camelCase } from 'lodash';

import randomUUID from 'frontend/utils/randomUUID';

import applyDraftEntity from './applyDraftEntity';
import { createSelectionState, getType } from '../utils';

const getSelectedText = ({ state, anchorKey, start, end }) => {
  const block = state.getCurrentContent().getBlockForKey(anchorKey);
  return block.getText().slice(start, end);
};

export default (state, { item, currentSelection, setCursorAtEnd } = {}) => {
  const selection = currentSelection || state.getSelection();
  const content = state.getCurrentContent();
  const anchorKey = selection.getAnchorKey();

  if (anchorKey !== selection.getFocusKey()) {
    console.error('Composer: Tried to insert tag on selection spanning several lines');
    return state;
  }

  const type = getType(item);
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();

  const tagData = {
    start,
    end,
    tempId: randomUUID(),
    original: getSelectedText({ state, anchorKey, start, end }),
    [camelCase(type)]: item,
  };

  const name = item.entity?.name || item.name;
  const contentWithNewText = Modifier.replaceText(content, selection, name);
  const stateWithNewText = EditorState.push(state, contentWithNewText, 'insert-characters');

  const stateWithDraftEntity = applyDraftEntity(stateWithNewText, {
    type,
    data: tagData,
    length: name.length,
    selection,
  });

  if (!setCursorAtEnd) return stateWithDraftEntity;

  const selectionAtEnd = createSelectionState({ anchorKey, start: start + name.length, hasFocus: true });
  return EditorState.forceSelection(stateWithDraftEntity, selectionAtEnd);
};
