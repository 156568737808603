import cx from 'classnames';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Field, useForm } from 'react-final-form';

import type { BusinessHoursType, HandoverSettingsType } from 'frontend/api/generated';
import { CalendarTimer, Clock, DotsVertical, Info, Plus, Trash } from 'frontend/assets/icons';
import { Button, DatePicker, Dropdown, Icon, Select, Table } from 'frontend/components';
import EditableText from 'frontend/components/EditableText/EditableText';
import InfoModal from 'frontend/components/InfoModal';
import type { CellProps, Column } from 'frontend/components/Table/Table';
import { FIELD_COLOR } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { DATE_FORMAT_ISO_DATE } from 'frontend/utils/date';

import styles from './BusinessHoursTable.scss';

const SELECT_OPTIONS = [
  { value: 'OPEN', text: 'Always open' },
  { value: 'CLOSED', text: 'Closed' },
  { value: 'CUSTOM', text: 'Custom time' },
];

const getSeasonalIndex = (idx: number) => idx + 7; // 7 days in a week

const getGridTemplateColumns = (cols: Column[]) => {
  let columnStr = '';
  for (let i = 0; i < cols.length; i += 1) {
    if (i === 1 || i === 0 || i === 3 || i === 4) {
      columnStr += 'auto ';
    } else {
      columnStr += '1fr ';
    }
  }
  return columnStr;
};

const BusinessHoursTable = () => {
  const { getState, change } = useForm();
  const { values, dirty } = getState();
  const [rowEdit, setRowEdit] = useState({
    type: '',
    rowIdx: -1,
    target: '',
  });

  const [showInfoModal] = useModal(InfoModal);

  const handleHighlighting = useMemo(
    () => (type: string, rowIdx: number) => ({
      onBlur: () => setRowEdit({ type: '', rowIdx: -1, target: '' }),
      onFocus: () => {
        setRowEdit({
          type,
          rowIdx,
          target: 'input',
        });
      },
    }),
    [setRowEdit],
  );

  const weekdays = useMemo(() => {
    if (values) {
      return values.businessHours
        .filter(({ type }) => type === 'WEEKDAY')
        .map((data, index) => ({ ...data, className: rowEdit.rowIdx === index ? styles.editingRow : undefined }));
    }
    return [];
  }, [values, rowEdit]);

  const seasonal = useMemo(() => {
    if (values) {
      return values.businessHours
        .filter(({ type }) => type === 'SEASONAL')
        .map((data, index) => ({ ...data, className: rowEdit.rowIdx === index + 7 ? styles.editingRow : undefined }));
    }
    return [];
  }, [values, rowEdit]);

  const renderTimeframes = ({ rowData, rowIdx, columnName }: CellProps, isSeasonal?: boolean) => {
    const { timeframes, status, type } = rowData as BusinessHoursType;

    if (status !== 'CUSTOM') {
      return null;
    }

    const rowIndex = isSeasonal ? getSeasonalIndex(rowIdx as number) : (rowIdx as number);

    return (
      <div className={styles.timeframesRow}>
        {timeframes.map((_, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.timeframeContainer} key={`${rowIndex}-${idx}`}>
            <Field
              name={`businessHours[${rowIndex}].${columnName}[${idx}].fromTime`}
              {...(dirty && { autoFocus: true })}
              className={cx(styles.dateTimeInput, styles.timeInput)}
              type="time"
              validate={required}
              render={({ input, meta }) => (
                <EditableText
                  {...input}
                  meta={meta}
                  inputStyle={{ width: 'auto' }}
                  tooltip="enter-to-add"
                  className={styles.dateTimeInput}
                  {...handleHighlighting(type, rowIndex)}
                />
              )}
            />
            &nbsp;-&nbsp;
            <Field
              name={`businessHours[${rowIndex}].${columnName}[${idx}].toTime`}
              validate={(value, allVals) => {
                if (!value) {
                  return `This can't be blank`;
                }
                const fromTime = (allVals as HandoverSettingsType).businessHours?.[rowIndex]?.[columnName as string]?.[
                  idx
                ]?.fromTime;

                if (fromTime && value < fromTime) {
                  return 'End time cannot be earlier than start time';
                }
                return undefined;
              }}
              className={cx(styles.dateTimeInput, styles.timeInput)}
              type="time"
              render={({ input, meta }) => (
                <EditableText
                  {...input}
                  meta={meta}
                  inputStyle={{ width: 'auto' }}
                  tooltip="enter-to-add"
                  className={styles.dateTimeInput}
                  {...handleHighlighting(type, rowIndex)}
                />
              )}
            />
            {timeframes.length > 1 && (
              <Icon
                className={styles.deleteTimeframeIcon}
                width={20}
                height={20}
                color="gray"
                hoverColor="warning"
                onClick={() =>
                  change(
                    `businessHours[${rowIndex}].${columnName}`,
                    timeframes.filter((item, i) => i !== idx),
                  )
                }
                component={Trash}
              />
            )}
          </div>
        ))}
        <Button
          className={styles.addBtn}
          color="white"
          onClick={() => {
            const timeframesCopy = [...timeframes];
            timeframesCopy.push({ fromTime: '00:00', toTime: '23:59' });
            change(`businessHours[${rowIndex}].${columnName}`, timeframesCopy);
          }}
        >
          <Icon component={Plus} />
        </Button>
      </div>
    );
  };

  const renderStatus = ({ rowIdx, columnName }: CellProps, isSeasonal) => {
    let idx = parseInt(rowIdx as string, 10) || 0;

    if (isSeasonal) {
      idx = getSeasonalIndex(idx);
    }

    return (
      <Field name={`businessHours[${idx}].${columnName}`} component={Select} fieldColor={FIELD_COLOR.WHITE}>
        {SELECT_OPTIONS.map(({ value, text }) => (
          <Select.Option key={value} value={value} label={text} />
        ))}
      </Field>
    );
  };

  const weekdaysColumns: Column[] = [
    {
      key: 'name',
      cellClassName: styles.nameCell,
      render: ({ rowData }) => <div>{(rowData as BusinessHoursType).name}</div>,
    },
    {
      key: 'status',
      cellClassName: styles.statusCell,
      render: (data) => renderStatus(data, false),
    },
    {
      key: 'timeframes',
      render: (data) => renderTimeframes(data, false),
    },
  ];

  const seasonalColumns: Column[] = [
    {
      key: 'date',
      cellClassName: styles.dateCell,
      render: ({ rowData, rowIdx }) => {
        const { fromDate, toDate } = rowData as BusinessHoursType;
        return (
          <Dropdown
            position="bottom"
            keepOpenOnActionElementClick
            overlay={
              <DatePicker
                initialStartDate={fromDate}
                initialEndDate={toDate}
                onChange={(date) => {
                  const { startDate, endDate } = date[0];
                  change(`businessHours[${getSeasonalIndex(rowIdx as number)}]`, {
                    ...values.businessHours[getSeasonalIndex(rowIdx as number)],
                    fromDate: format(startDate, DATE_FORMAT_ISO_DATE),
                    toDate: format(endDate, DATE_FORMAT_ISO_DATE),
                  });
                }}
              />
            }
          >
            <>
              <Field
                name={`businessHours[${getSeasonalIndex(rowIdx as number)}].fromDate`}
                defaultValue={format(new Date(), DATE_FORMAT_ISO_DATE)}
                type="date"
                parse={(value) => {
                  try {
                    return format(new Date(value), DATE_FORMAT_ISO_DATE);
                  } catch (err) {
                    console.warn('Invalid date format');
                    return value;
                  }
                }}
                render={({ input }) => (
                  <EditableText
                    {...input}
                    className={styles.dateTimeInput}
                    {...handleHighlighting((rowData as BusinessHoursType).type, getSeasonalIndex(rowIdx as number))}
                  />
                )}
              />
              &nbsp;-&nbsp;
              <Field
                name={`businessHours[${getSeasonalIndex(rowIdx as number)}].toDate`}
                parse={(value) => format(new Date(value), DATE_FORMAT_ISO_DATE)}
                defaultValue={format(new Date(), DATE_FORMAT_ISO_DATE)}
                type="date"
                validate={(value, allVals) => {
                  const fromDateVal = (allVals as HandoverSettingsType).businessHours?.[
                    getSeasonalIndex(rowIdx as number)
                  ]?.fromDate;
                  if (fromDateVal && value < fromDateVal) {
                    return 'End date cannot be earlier than start date';
                  }
                  return undefined;
                }}
                render={({ input, meta }) => (
                  <EditableText
                    {...input}
                    meta={meta}
                    className={styles.dateTimeInput}
                    {...handleHighlighting((rowData as BusinessHoursType).type, getSeasonalIndex(rowIdx as number))}
                  />
                )}
              />
            </>
          </Dropdown>
        );
      },
    },
    {
      key: 'status',
      cellClassName: styles.statusCell,
      render: (data) => renderStatus(data, true),
    },
    {
      key: 'timeframes',
      render: (data) => renderTimeframes(data, true),
    },
    {
      key: 'name',
      cellClassName: styles.nameCellSeasonal,
      render: ({ rowData, rowIdx }) => (
        <Field
          name={`businessHours[${getSeasonalIndex(rowIdx as number)}].name`}
          format={(value) => (value === 'Seasonal' ? null : value)}
          render={({ input }) => (
            <EditableText
              {...input}
              className={styles.nameInput}
              tooltip="enter-to-add"
              placeholder="Add name"
              {...handleHighlighting((rowData as BusinessHoursType).type, getSeasonalIndex(rowIdx as number))}
              minWidth={100}
            />
          )}
        />
      ),
    },
    {
      key: '',
      cellClassName: styles.actionsCell,
      render: ({ rowData, rowIdx }) => (
        <Dropdown
          onClose={() => setRowEdit({ type: '', rowIdx: -1, target: '' })}
          onToggle={(active) => {
            if (active && rowEdit.rowIdx === -1) {
              const index = getSeasonalIndex(rowIdx as number);
              setRowEdit({
                type: rowData?.type,
                rowIdx: index,
                target: 'dropdown',
              });
            }
          }}
          position="bottom"
          overlay={
            <Dropdown.MenuOverlay
              options={[
                {
                  icon: Trash,
                  text: 'Delete',
                  color: 'red',
                  onClick: () => {
                    change(
                      'businessHours',
                      [...values.businessHours].filter((_, idx) => idx !== getSeasonalIndex(rowIdx as number)),
                    );
                  },
                },
              ]}
            />
          }
        >
          <Icon component={DotsVertical} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Table
        className={styles.weekdaysTable}
        rowClassName={styles.row}
        tableHeadingComponent={
          <>
            <div className={styles.tableTitle}>
              <Icon component={Clock} />
              Business Hours
            </div>
            <div className={styles.customHeader}>Day</div>
            <div className={styles.customHeader}>Opening hours</div>
            <div className={styles.customHeader} />
          </>
        }
        showTableHeader={false}
        getGridTemplateColumns={getGridTemplateColumns}
        data={weekdays}
        columns={weekdaysColumns}
      />
      <Table
        className={styles.seasonalTable}
        rowClassName={styles.row}
        tableHeadingComponent={
          <>
            <div className={cx(styles.tableTitle, styles.tableTitleSeasonal)}>
              <div>
                <Icon component={CalendarTimer} />
                Seasonal Hours
              </div>
              <div>
                <Icon
                  component={Info}
                  onClick={() =>
                    showInfoModal({
                      title: 'About Seasonal hours',
                      icon: CalendarTimer,
                      text: 'Use seasonal hours to override your regular business hours with specific times for holidays, or any other dates when your schedule varies from the norm.',
                    })
                  }
                />
              </div>
            </div>
            {seasonal.length > 0 && (
              <>
                <div className={styles.customHeader}>Date</div>
                <div className={styles.customHeader}>Opening hours</div>
                <div className={styles.customHeader} />
                <div className={styles.customHeader}>Name</div>
                <div className={styles.customHeader} />
              </>
            )}
          </>
        }
        tableFooterComponent={
          rowEdit.rowIdx === -1 || rowEdit.type === 'WEEKDAY' || rowEdit.target === 'dropdown' ? (
            <div className={styles.seasonalFooter}>
              {!seasonal.length && (
                <div className={styles.seasonalFooterText}>
                  Use seasonal hours to override your regular business hours with specific times for holidays, or any
                  other dates when your schedule varies from the norm.
                </div>
              )}
              <Button
                color="white"
                icon={Plus}
                text="Add seasonal hour"
                className={styles.addSeasonalBtn}
                onClick={() => {
                  change('businessHours', [
                    ...values.businessHours,
                    {
                      name: 'Seasonal',
                      status: 'OPEN',
                      timeframes: [{ fromTime: '00:00', toTime: '23:59' }],
                      type: 'SEASONAL',
                    },
                  ]);
                }}
              />
            </div>
          ) : null
        }
        showTableHeader={false}
        getGridTemplateColumns={getGridTemplateColumns}
        data={seasonal}
        columns={seasonalColumns}
      />
    </>
  );
};

export default BusinessHoursTable;
