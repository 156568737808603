import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { SelectLanguage as SelectedLanguageComponent } from 'frontend/components';
import { DeleteModal, ModalForm } from 'frontend/features/Modals';
import type { Language } from 'frontend/types/language';

interface SelectLanguageProps {
  hide: (...args: any[]) => void;
  args: {
    onSubmit: (...args: any[]) => void;
    initialValues: Record<string, unknown>;
    languages: Language[];
    remove: boolean;
  };
}
const SelectLanguage = ({ hide, args: { languages, initialValues, onSubmit, remove } }: SelectLanguageProps) => {
  const removeLanguage = useCallback(async () => {
    await onSubmit({ ...initialValues, selectedLanguage: '' });
  }, [initialValues, onSubmit]);

  if (remove) {
    return (
      <DeleteModal
        onSubmit={removeLanguage}
        hide={hide}
        name="the selected language"
        titleName="selected language"
        action="remove"
        hideWarning
      />
    );
  }

  return (
    <ModalForm initialValues={initialValues} hide={hide} onSubmit={onSubmit} title="Select language">
      <Field
        component={SelectedLanguageComponent.Selector}
        name="selectedLanguage"
        languages={[{ code: '', name: 'No default language' }, ...languages]}
      />
    </ModalForm>
  );
};

export default SelectLanguage;
