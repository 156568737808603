import { gql } from '@apollo/client';

import { CHAT } from '../fragments';

export default gql`
  query ChatAndBot($chatId: ID!) {
    chatAndBot(chatId: $chatId) {
      id
      chat {
        ...Chat
      }
      bot {
        id
        name
        projectName
        avatarUrl
      }
    }
  }
  ${CHAT}
`;
