import { useQuery } from '@apollo/client';

import { PreviewContentImportDocument } from 'frontend/api/generated';
import { Loader } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';

import styles from './ImportPreviewModal.scss';

interface ImportPreviewModalProps {
  hide: () => Promise<void>;
  args: {
    handleApply: (string) => Promise<void>;
    id: string;
    botId: string;
  };
}

const ImportPreviewModal = ({ hide, args: { handleApply, id, botId } }: ImportPreviewModalProps) => {
  const { data, loading } = useQuery(PreviewContentImportDocument, {
    fetchPolicy: 'no-cache',
    variables: { pk: id, botId },
  });
  const preview = data?.previewContentImport?.preview;
  return (
    <Modal
      title="Import new workspace version"
      color="primary"
      hide={hide}
      onOk={() => handleApply(id)}
      onOkText="Import"
    >
      <Modal.Content className={styles.modalContent}>
        {loading ? <Loader size="medium" /> : <pre>{preview}</pre>}
        <p className={styles.warning}>
          This is a potentially destructive action. We recommend creating an export as a backup before importing a new
          version.
        </p>
      </Modal.Content>
    </Modal>
  );
};

export default ImportPreviewModal;
