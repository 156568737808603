import cx from 'classnames';
import PropTypes from 'prop-types';

import ChildrenType from 'frontend/propTypes/ChildrenType';

import styles from './Grid.scss';

const Grid = ({ children, flow = 'column', className, columns = 2, noMargin }) => {
  const classNames = cx(styles.grid, className, {
    [styles.autoFlowColumn]: flow === 'column',
    [styles.autoFlowRow]: flow === 'row',
    [styles.columns1]: columns === 1,
    [styles.columns2]: columns === 2,
    [styles.columns3]: columns === 3,
    [styles.noMargin]: noMargin,
  });
  return <div className={classNames}>{children}</div>;
};

Grid.propTypes = {
  children: ChildrenType.isRequired,
  flow: PropTypes.string,
  columns: PropTypes.number,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
};

export default Grid;
