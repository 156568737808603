import { useMutation } from '@apollo/client';
import { capitalize, isEqual, isNil, pick } from 'lodash';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { FormErrors, GuardUnsaved, Input, PageBar, Panel } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { systemDialogueMessageTypes } from 'frontend/features/Library/constants';
import { chain, max, required } from 'frontend/form/validators';
import { useCurrentLanguage } from 'frontend/hooks';

import styles from './SuggestionDialogueForm.scss';
import sharedStyles from '../../Build.scss';
import { UpdateSuggestionDialogue } from '../../graphql';
import DialoguePath from '../DialoguePath';
import EditingNow from '../EditingNow';
import SuggestionPreview from '../SuggestionPreview';

const fallbackLabels = {
  preface: 'Pre fallback',
  prompt: 'Did you mean',
  accept: 'Yes button',
  dismiss: 'No button',
  dismissalResponse: 'Fallback when user says No',
};

const replyLabels = {
  prompt: 'See also',
  accept: 'Yes button',
};

const isFallbackSuggestionType = (messageType) => messageType === systemDialogueMessageTypes.FALLBACK;

const getSuggestionDialogueLabel = (messageType, fieldName) => {
  if (isFallbackSuggestionType(messageType)) return fallbackLabels[fieldName];
  return replyLabels[fieldName];
};

const SuggestionDialogueForm = ({ initialValues }) => {
  const [update] = useMutation(UpdateSuggestionDialogue);
  const { botId } = useParams();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const { messageType, colorLabel, mod, dialogueType } = initialValues;
  const fallbackType = isFallbackSuggestionType(messageType);

  const dialogueLabelColorData = {
    colorLabel: mod?.modColorLabel ?? colorLabel,
    dialogueType,
  };

  const onSubmit = (values) => {
    const suggestionDialogueInput = pick(values, ['preface', 'prompt', 'accept', 'dismiss', 'dismissalResponse']);

    update({ variables: { botId, id: initialValues.id, suggestionDialogueInput } });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      render={({ handleSubmit }) => (
        <>
          <GuardUnsaved />

          <div className={sharedStyles.dialogueFormWrapper}>
            <form onSubmit={handleSubmit} autoComplete="off">
              {!isNil(initialValues.id) && <EditingNow dialogueLikeId={initialValues.id} />}

              <Panel className={styles.pageContent}>
                <FormErrors />
                <div className={styles.pageHeader}>
                  <DialoguePath
                    dialogueLabelColorData={dialogueLabelColorData}
                    isActive={initialValues.isActive}
                    readOnly
                  />
                  <PageBar className={styles.pageBar}>
                    <PageBar.FormButtons />
                  </PageBar>
                </div>
                <div className={styles.contentWrapper}>
                  <SuggestionPreview selectedLanguage={selectedLanguage} className={styles.previewContainer} />
                  <div className={styles.verticalSeparator} />
                  <div className={styles.content}>
                    <div className={styles.outputWrapper}>
                      <h3 className="m-b-n">{capitalize(messageType)} suggestion</h3>
                      <p>
                        Set default values when the suggestion is triggered. You enable each suggestion per dialogue.
                      </p>
                      <div className="m-t-md" />
                      {fallbackType && (
                        <Field
                          component={Input}
                          name={`preface.${selectedLanguage}`}
                          label={getSuggestionDialogueLabel(messageType, 'preface')}
                          inputLimit={500}
                          validate={chain([required, max(500)])}
                          fieldColor={FIELD_COLOR.MISCHKA}
                        />
                      )}
                      <Field
                        component={Input}
                        name={`prompt.${selectedLanguage}`}
                        label={getSuggestionDialogueLabel(messageType, 'prompt')}
                        inputLimit={100}
                        validate={chain([required, max(100)])}
                        fieldColor={FIELD_COLOR.MISCHKA}
                      />
                      <Field
                        component={Input}
                        name={`accept.${selectedLanguage}`}
                        label={getSuggestionDialogueLabel(messageType, 'accept')}
                        inputLimit={20}
                        validate={chain([required, max(20)])}
                        fieldColor={FIELD_COLOR.MISCHKA}
                      />
                      {fallbackType && (
                        <>
                          <Field
                            component={Input}
                            name={`dismiss.${selectedLanguage}`}
                            label={getSuggestionDialogueLabel(messageType, 'dismiss')}
                            inputLimit={20}
                            validate={chain([required, max(20)])}
                            fieldColor={FIELD_COLOR.MISCHKA}
                          />
                          <Field
                            component={Input}
                            name={`dismissalResponse.${selectedLanguage}`}
                            label={getSuggestionDialogueLabel(messageType, 'dismissalResponse')}
                            inputLimit={1000}
                            validate={chain([required, max(1000)])}
                            multiline
                            fieldColor={FIELD_COLOR.MISCHKA}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Panel>
            </form>
          </div>
        </>
      )}
    />
  );
};

SuggestionDialogueForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isActive: PropTypes.objectOf(PropTypes.bool).isRequired,
    messageType: PropTypes.oneOf(Object.values(systemDialogueMessageTypes)).isRequired,
    mod: PropTypes.shape({}),
    colorLabel: PropTypes.shape({}),
    dialogueType: PropTypes.string,
  }).isRequired,
};

export default SuggestionDialogueForm;
