import cx from 'classnames';
import { motion } from 'framer-motion';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useCurrentLanguage, useKeyDown, useMixpanel } from 'frontend/hooks';
import {
  resetLibraryFilters,
  selectLibraryFilters,
  setLibraryFilters,
  setLibraryFiltersOpen,
} from 'frontend/state/dux/library';

import styles from './LibraryFilters.scss';
import { FilterItems } from './components';

const SUBSCRIPTION = {};

interface FilterAnimationProps {
  children: React.ReactNode;
  initial: boolean;
  animate: string;
  className: string;
}

const FilterAnimation = ({ children, animate, className, initial }: FilterAnimationProps) => {
  const variants = {
    show: {
      x: 0,
      transition: {
        ease: [0.2, 0.2, 0.2, 0.9],
        duration: 0.2,
      },
    },
    hide: {
      x: '-100%',
      transition: { duration: 0.2 },
    },
  };
  return (
    <motion.div variants={variants} animate={animate} className={className} initial={initial}>
      {children}
    </motion.div>
  );
};

const initialValues = {};

const LibraryFilters = () => {
  const { botId } = useParams();

  const [, setCurrentLanguage] = useCurrentLanguage();
  const dispatch = useDispatch();

  const { isOpen, filters } = useSelector(selectLibraryFilters);

  const hideFilters = () => {
    dispatch(setLibraryFiltersOpen(false));
  };

  const enter = useKeyDown({ action: hideFilters });

  const [showResetFilters, setShowResetFilters] = useState(false);
  const { mixpanel } = useMixpanel();

  const onSubmit = ({ dialogueId, datePicker, languageSelect }) => {
    const { endDate, startDate } = datePicker?.[0] || {};
    if (languageSelect) {
      setCurrentLanguage(languageSelect);
    }

    dispatch(setLibraryFilters({ dialogueId, fromDate: startDate, toDate: endDate }));
    dispatch(setLibraryFiltersOpen(false));

    mixpanel.track('Apply Bot Improvements filters', {
      botId,
      val: { dialogueId, fromDate: startDate, toDate: endDate },
    });
  };

  useEffect(() => {
    setShowResetFilters(!isEqual(initialValues, filters));
  }, [setShowResetFilters, filters]);

  const resetFilters = () => dispatch(resetLibraryFilters());
  // on component unmount, reset filters
  useEffect(
    () => () => {
      resetFilters();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FilterAnimation animate={isOpen ? 'show' : 'hide'} className={`${styles.animated}`} initial={false}>
      <Form
        onSubmit={onSubmit}
        subscription={SUBSCRIPTION}
        render={({ handleSubmit }) => (
          <FilterItems
            handleSubmit={handleSubmit}
            filters={filters}
            resetFilters={resetFilters}
            showResetFilters={showResetFilters}
          />
        )}
      />
      <div
        className={cx(styles.backdrop, {
          [styles.backdropVisible]: isOpen,
        })}
        role="button"
        tabIndex={0}
        onKeyDown={enter}
        onClick={hideFilters}
      />
    </FilterAnimation>
  );
};

export default LibraryFilters;
