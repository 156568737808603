import { Directions } from 'frontend/assets/icons';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import { Modal } from 'frontend/features/Modals';

export default function RuleInfoModal({ hide }: { hide: () => Promise<void> }): React.JSX.Element {
  return (
    <Modal icon={Directions} title="About Rules" hide={hide} hideCancel>
      <Modal.Content>
        <p>
          A rule is a powerful tool to give users tailored replies in different scenarios. You can e.g. define a rule to
          give a user a certain reply that is more suitable after a set time, or according to a specific context.
        </p>
        <p>
          You can also have multiple rules in a dialogue and choose the order of importance. Your bot will then scan
          from top to bottom the rule list and choose the first rule in the list that matches the requirements.
        </p>
        <p>
          You can read more about rules in our <HelpCenterLink path="/rules">Help Center</HelpCenterLink>.
        </p>
      </Modal.Content>
    </Modal>
  );
}
