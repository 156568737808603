const DEFAULT_PROTOCOL = 'https';

// Inspired by: https://stackoverflow.com/a/3809435/4301645
const linkPattern = /[(http(s)?)://(www.)?\-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+,.~#?&//=;()/]*)/g;
const protocolPattern = /(http|https):\/\//;

/** Find links the text and wrap them with anchor tags */
export const formatLinks = (text: string, target = '_blank'): string => {
  const targetAttr = target ? ` target="${target}"` : '';

  return text.replace(linkPattern, (link) => {
    // Add https if not exisiting
    const hasPrefix = link.match(protocolPattern);
    const href = hasPrefix ? link : `${DEFAULT_PROTOCOL}://${link}`;
    return `<a${targetAttr} href="${href}">${link}</a>`;
  });
};
