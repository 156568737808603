import { useEffect, useMemo } from 'react';

// Defined by Pusher app
const PUSHER_LOCAL_STORAGE_KEY = 'pusherTransportTLS';

export default (me) => {
  const disabledTransports = useMemo(() => me.profile.disabledTransports || [], [me]);

  // Have to manually remove transport ws
  useEffect(() => {
    const storedPusherProps = JSON.parse(window.localStorage.getItem(PUSHER_LOCAL_STORAGE_KEY));
    if (!storedPusherProps) return;

    const wsIsExcluded = !disabledTransports.includes('ws');
    const pusherDoesntUseWs = storedPusherProps.transport !== 'ws';

    if (wsIsExcluded && pusherDoesntUseWs) window.localStorage.removeItem(PUSHER_LOCAL_STORAGE_KEY);
  }, [disabledTransports]);

  return disabledTransports;
};
