/* eslint-disable react/no-array-index-key */

import { useLabels } from 'frontend/features/Inbox/hooks';
import { pluralize } from 'frontend/utils';

import styles from './LabelsTitle.scss';

const LabelsTitle = ({ values }: { values: string[] }) => {
  const labels = useLabels();
  const length = Object.entries(values).length;

  const labelColors = labels.filter(({ id }) => values.includes(id))?.slice(0, 3);

  return (
    <div className={styles.labelTitleWrapper}>
      <div
        style={{
          minWidth:
            (labelColors.length % 2 !== 0
              ? labelColors.length - (labelColors.length - 1) * 0.5
              : labelColors.length / 2 + 0.5) * 18,
        }}
        className={styles.labelTitleColorsWrapper}
      >
        {labelColors.map(({ color, background }, index) => (
          <div
            key={`${color}-${index}`}
            style={{
              backgroundColor: background,
              zIndex: index,
              transform: `translate(${index * 9}px, -50%)`,
            }}
            className={styles.labelTitleColor}
          >
            <div style={{ backgroundColor: color }} className={styles.labelTitleDot} />
          </div>
        ))}
      </div>
      {length} {pluralize('label', length)}
    </div>
  );
};

export default LabelsTitle;
