import type { Mutator } from 'final-form';
import arrayMutators from 'final-form-arrays';
import type { Dispatch, SetStateAction } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useNavigate } from 'react-router-dom';

import { ChevronLeft, Plus, Trash } from 'frontend/assets/icons';
import defaultAvatar from 'frontend/assets/images/avatar.svg?url';
import { Button, Checkbox, FileUpload, Input } from 'frontend/components';
import type { ReadFile } from 'frontend/components/FileUpload/FileUpload';
import { useModal } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';
import type { UploadFileType } from 'frontend/hooks/useOnSubmitWithUpload';
import { capitalize } from 'frontend/utils';

import styles from './PluginForm.scss';
import { DeletePlugin } from '../modals';

interface PluginFormProps {
  onSubmit: () => void;
  initialValues: {
    id?: string;
    name?: string;
    isActive?: boolean;
    readChats?: boolean;
  };
  setFile?: Dispatch<SetStateAction<UploadFileType>> | ((values: any, form?: any) => Promise<any>) | undefined;
  isSubmitting: boolean;
  operation: 'create' | 'update';
}

const PluginForm = ({ onSubmit, initialValues, operation, setFile, isSubmitting }: PluginFormProps) => {
  const [showDeletePluginModal] = useModal(DeletePlugin);
  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        mutators={arrayMutators as unknown as { [key: string]: Mutator<Record<string, any>, any> }}
        initialValues={initialValues}
        render={({ handleSubmit, form: { change }, values, dirty }) => (
          <form className={styles.pluginForm} onSubmit={handleSubmit}>
            <div className={`${styles.formHeader} m-b-lg`}>
              <Button onClick={() => navigate(-1)} icon={ChevronLeft}>
                Back
              </Button>
              <Button
                color={dirty ? 'primary' : 'secondary'}
                onClick={(e) => {
                  handleSubmit();
                  e.preventDefault();
                }}
                isSubmitting={isSubmitting}
              >
                {capitalize(operation)}
              </Button>
              {Boolean(initialValues.id) && (
                <Button
                  color="warning"
                  onClick={() => showDeletePluginModal({ name: initialValues.name, pluginId: initialValues.id })}
                >
                  Delete
                </Button>
              )}
            </div>
            <div className={styles.formBody}>
              <div className={styles.logoContainer}>
                {values.id ? (
                  <>
                    <img className={styles.portrait} src={values.logoUrl || defaultAvatar} alt={values.name} />
                    <div className={styles.upload}>
                      <Field component={Input} name="logoUrl" hidden />
                      <FileUpload
                        containerClassName={styles.uploadButton}
                        text="Select logo"
                        accept="image/png,image/jpeg"
                        onUpload={(files) => {
                          const [firstFile] = files;
                          const { source, file } = firstFile as ReadFile;
                          change('logoUrl', source);
                          setFile?.(file);
                        }}
                      />
                      {values.logoUrl && (
                        <Button flat onClick={() => change('logoUrl', null)}>
                          Delete logo
                        </Button>
                      )}
                    </div>
                  </>
                ) : (
                  'Save Plugin to upload logo'
                )}
              </div>
              <Field
                component={Input}
                name="name"
                placeholder="Name"
                inputLimit={200}
                validate={chain([required, max(200)])}
                label="Name"
              />
              <p className="m-b-md">The name that will appear in the build section.</p>
              <Field
                component={Input}
                name="url"
                placeholder="https://example.com/"
                inputType="url"
                label="Webhook URL"
                validate={required}
              />
              <p className="m-b-md">The URL your plugin POST&apos;s requests to.</p>
              <Field
                component={Input}
                name="description"
                placeholder="Lookup data in service x"
                label="Description"
                multiline
              />
              <p className="m-b-md">What does the plugin do?</p>

              <h4>Template dialogues</h4>
              <FieldArray name="templateDialogues">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name} className={styles.contextKeys}>
                        <Field
                          name={`${name}.title`}
                          component={Input}
                          label="Title"
                          className={styles.contextKeysWrap}
                          validate={required}
                        />
                        <Field
                          name={`${name}.slug`}
                          component={Input}
                          label="Slug"
                          className={styles.contextKeysWrap}
                          validate={required}
                        />
                        <Field
                          name={`${name}.text`}
                          component={Input}
                          label="Text"
                          className={styles.contextKeysWrap}
                          validate={required}
                        />
                        <Button
                          icon={Trash}
                          onClick={() => fields.remove(index)}
                          className={styles.deleteDialogueButton}
                        />
                      </div>
                    ))}
                    <Button icon={Plus} onClick={() => fields.push({ title: '', slug: '', text: '' })}>
                      Add
                    </Button>
                  </div>
                )}
              </FieldArray>
              <p className="m-b-md">Template dialogues created by the plugin on setup.</p>

              <h4>Required Context Keys</h4>
              <FieldArray name="contextKeys">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name} className={styles.contextKeys}>
                        <Field
                          name={`${name}.key`}
                          component={Input}
                          label="Key"
                          className={styles.contextKeysWrap}
                          validate={required}
                        />
                        <Field
                          name={`${name}.description`}
                          component={Input}
                          label="Description"
                          className={styles.contextKeysWrap}
                          validate={required}
                        />
                        <Button
                          icon={Trash}
                          onClick={() => fields.remove(index)}
                          className={styles.deleteDialogueButton}
                        />
                      </div>
                    ))}
                    <Button icon={Plus} onClick={() => fields.push({ key: '', description: '' })}>
                      Add
                    </Button>
                  </div>
                )}
              </FieldArray>
              <p className="m-b-md">Required plugin context keys and their description.</p>
              <Field component={Checkbox} type="checkbox" name="isActive" label="Active" />
              <Field component={Checkbox} type="checkbox" name="readChats" label="Read chats" />
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default PluginForm;
