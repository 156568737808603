import cx from 'classnames';
import React from 'react';

import styles from './AdvancedTooltip.scss';
import handleMouseEvents from './utils/handleMouseEvents';

interface AdvancedTooltipProps {
  children: React.ReactNode;
  className?: string;
  maxWidth?: string;
}

interface AdvancedTooltipContentProps {
  children: React.ReactNode;
  className?: string;
}

const Body = ({ children, className }: AdvancedTooltipContentProps) => (
  <div data-advancedtooltip className={cx(styles.tooltip, className)}>
    {children}
  </div>
);

const AdvancedTooltip = ({ children, className, maxWidth }: AdvancedTooltipProps) => (
  <div
    className={className}
    onMouseEnter={handleMouseEvents('enter', maxWidth)}
    onMouseLeave={handleMouseEvents('leave', maxWidth)}
  >
    {children}
  </div>
);

export default AdvancedTooltip;
AdvancedTooltip.Body = Body;
