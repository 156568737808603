import { useCallback } from 'react';

import { addListItemByFragment } from 'frontend/api/cacheHelpers';
import { valuesToFunctions } from 'frontend/utils';

import { ATTACHMENT } from '../fragments';
import { prepareAttachment } from '../utils';

export default ({ cache }) => {
  const onAddedAttachments = useCallback(
    ({ attachments }) => {
      attachments.forEach((attachment) => {
        if (attachment.sender === 'USER') {
          cache.modify({
            fields: { attachments: addListItemByFragment(cache, ATTACHMENT, prepareAttachment(attachment)) },
          });
          return;
        }
        cache.modify({
          id: `AttachmentType:${attachment.id}`,
          fields: valuesToFunctions(attachment),
        });
      });
    },
    [cache],
  );

  return onAddedAttachments;
};
