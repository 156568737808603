import { Field, Form } from 'react-final-form';

import { Button, Checkbox, FormErrors, GuardUnsaved, Loader, PageBar, Panel } from 'frontend/components';
import { HorizontalRule } from 'frontend/components/Panel';

import useProfileNotifications from './useProfileNotifications';
import { ALERTERS } from '../../constants';
import styles from '../ProfileSettings/ProfileSettings.scss';

const SUBSCRIBE = {};

const ProfileNotifications = () => {
  const { loading, onSubmit, initialValues, notificationsByOrg, toggleOrgNotifications } = useProfileNotifications();

  if (loading) {
    return <Loader size="large" />;
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscribe={SUBSCRIBE}
      render={({ handleSubmit, form }) => {
        const areNotificationsOn = form.getFieldState('desktopNotifications')?.value;

        return (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={styles.formErrors} />
              <Panel>
                <h3>Inbox agent notifications</h3>
                <Field
                  component={Checkbox}
                  name="desktopNotifications"
                  type="checkbox"
                  label="Show a desktop notification on new tickets and handover requests"
                />
                <Field
                  component={Checkbox}
                  name="desktopNotificationsInteraction"
                  type="checkbox"
                  label="Keep desktop notifications visible until manually dismissed"
                  disabled={!areNotificationsOn}
                  className="m-l-4"
                />
                <HorizontalRule className={styles.horizontalLine} />
                <h3>Alert emails</h3>
                <p>We send you an an email alert when:</p>
                {Object.entries(ALERTERS).map(([alerter, label]) => (
                  <Field
                    component={Checkbox}
                    name={alerter}
                    key={`checkbox-${alerter}`}
                    type="checkbox"
                    label={label}
                  />
                ))}
                <HorizontalRule className={styles.horizontalLine} />
                <h3>Organization emails</h3>
                <p>Select the emails you want to receive for each of your organizations</p>
                <Button onClick={() => toggleOrgNotifications(form)} className="m-b-md">
                  Select/Deselect all
                </Button>
                {notificationsByOrg.map((ons) => (
                  <div key={ons.id} className={styles.notificationsByOrgItem}>
                    <h4>{ons.name}</h4>
                    {ons.notifications.map((on) => (
                      <Field key={on.name} type="checkbox" name={on.name} component={Checkbox} label={on.label} />
                    ))}
                  </div>
                ))}
              </Panel>
            </form>
          </>
        );
      }}
    />
  );
};
export default ProfileNotifications;
