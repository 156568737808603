import { formatISO } from 'date-fns/formatISO';
import { set } from 'date-fns/set';

import type { DateFiltersType } from '../../../components/NewFilters/utils/helpers';

const getDateFilterISO = (value) =>
  value.split(',').map((val) =>
    val
      .split('|')
      .map((date, index) => {
        const convertedDate = formatISO(date);
        return index === 0 ? convertedDate : formatISO(set(convertedDate, { hours: 23, minutes: 59, seconds: 59 }));
      })
      .join('|'),
  );

export default (filters: DateFiltersType) => ({
  dateFilter: filters.dateFilter?.map((filter) => getDateFilterISO(filter)).flat(),
  exclDateFilter: filters.exclDateFilter?.map((filter) => getDateFilterISO(filter)).flat(),
});
