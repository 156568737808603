import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import defaultAvatar from 'frontend/assets/images/member_avatar.svg?url';
import { Button, LoaderSwitch, PageContent, Panel, Radio, Table } from 'frontend/components';
import { NotFound } from 'frontend/features';
import { BackButton } from 'frontend/features/Admin/components';
import { AddUserToOrg, DeleteUser } from 'frontend/features/Admin/views/Users/modals';
import { SET_PRIMARY_ORGANIZATION } from 'frontend/features/Admin/views/Users/views/UserDetail/SET_PRIMARY_ORGANIZATION';
import { useModal } from 'frontend/features/Modals';
import { ROLE_NAMES } from 'frontend/features/Organization/constants';
import { UpdateOrganizationMember } from 'frontend/features/Organization/modals';
import { useTabTitle, useToast } from 'frontend/hooks';
import { nbsp } from 'frontend/utils';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import styles from './UserDetail.scss';
import UserItem from './UserItem';
import { GET_USER } from '../../queries';

const OrgName = ({ rowData: om }) => (
  <Link to={`/admin/organization/${om.organization.id}/`}>{om.organization.name}</Link>
);

const UserDetail = () => {
  const [showDeleteUserModal] = useModal(DeleteUser);
  const [showAddUserToOrgModal] = useModal(AddUserToOrg);
  const [showUpdateMemberModal] = useModal(UpdateOrganizationMember);
  const navigate = useNavigate();
  const { userId } = useParams();
  const { data, loading } = useQuery(GET_USER, { variables: { id: userId } });
  const { email, organizationMemberships, lastLogin, dateJoined, profile } = data?.user ?? {};
  useTabTitle(`Members: ${profile?.fullName || ''}`);

  const onClickDelete = useCallback(
    () => showDeleteUserModal({ id: userId, email, onDelete: () => navigate('/admin/members/') }),
    [userId, email, navigate, showDeleteUserModal],
  );

  const onClickAddToOrg = useCallback(
    () => showAddUserToOrgModal({ userId, username: email }),
    [email, userId, showAddUserToOrgModal],
  );

  const toast = useToast();
  const [setPrimaryOrganization] = useMutation(SET_PRIMARY_ORGANIZATION);
  const primaryOrganization = organizationMemberships?.find((orgMembership) => orgMembership.membership.isPrimary);

  const handlePrimaryChange = useCallback(
    async (e) => {
      const { value: organizationId } = e.currentTarget;
      await setPrimaryOrganization({ variables: { organizationId, userId } });
      const membership = organizationMemberships.find(({ organization }) => organization.id === organizationId);
      const userName = data?.user?.email;
      const name = nbsp(membership.organization?.name);
      toast.success(`${name} was set as the primary organization for ${userName}`);
    },
    [setPrimaryOrganization, userId, organizationMemberships, toast, data],
  );

  const OrgMembershipActions = useCallback(
    ({ rowData: om }) => (
      <Button
        size="small"
        onClick={() => {
          showUpdateMemberModal({ organizationId: om.organization.id, user: data?.user });
        }}
      >
        Edit
      </Button>
    ),
    [data?.user, showUpdateMemberModal],
  );

  const OrgSetPrimary = useCallback(
    ({ rowData: om }) => (
      <Radio
        className={cx(styles.button, 'm-l-2')}
        input={{
          checked: om.membership.isPrimary,
          value: om.organization.id,
          name: 'primary',
          onChange: handlePrimaryChange,
        }}
      />
    ),
    [handlePrimaryChange],
  );

  if (!loading && !email) {
    return <NotFound text="User not found" />;
  }

  return (
    <>
      <BackButton />
      <PageContent className="p-t-0">
        <LoaderSwitch loading={loading} size="large">
          <div className={styles.wrap}>
            <div className="m-r-md">
              <h3>User details</h3>
              <Panel className={styles.details}>
                <ul className={styles.list}>
                  <UserItem title="Name">{profile?.fullName}</UserItem>
                  <UserItem title="E-mail">{email}</UserItem>
                  <UserItem title="Avatar">
                    <div
                      className={styles.avatar}
                      style={{
                        backgroundImage: `url(${profile?.avatarUrl || defaultAvatar})`,
                      }}
                    />
                  </UserItem>
                  <UserItem title="User ID">{userId}</UserItem>
                  <UserItem title="Last logged in">
                    {lastLogin ? format(lastLogin, DATE_FORMAT_WITH_TIME) : ''}
                  </UserItem>
                  <UserItem title="Date joined">{dateJoined ? format(dateJoined, DATE_FORMAT_WITH_TIME) : ''}</UserItem>
                  <UserItem title="Permissions">
                    <Link to={`/admin/user/${userId}/permissions`}>View detailed</Link>
                  </UserItem>
                  <UserItem title="Primary organization">
                    {primaryOrganization ? primaryOrganization.organization.name : <>&#8212;</>}
                  </UserItem>
                </ul>
              </Panel>
              <h3>Actions</h3>
              <Panel className="m-b-md m-r-md">
                <Button onClick={onClickAddToOrg}>Add to organization</Button>
              </Panel>
              <h3>Danger zone</h3>
              <Panel className="m-r-md">
                <Button color="warning" onClick={onClickDelete}>
                  Delete user
                </Button>
              </Panel>
            </div>
            <div className={styles.relations}>
              <h3>Organization memberships</h3>
              <Table
                columns={[
                  {
                    key: 'org',
                    title: 'Name',
                    render: OrgName,
                  },
                  {
                    key: 'roles',
                    title: 'Roles',
                    render: ({ rowData: om }) =>
                      (om.membership.roles || []).map((role) => ROLE_NAMES[role.name]).join(', '),
                  },
                  {
                    key: 'actions',
                    title: 'Actions',
                    render: OrgMembershipActions,
                  },
                  {
                    key: 'primary',
                    title: 'Primary',
                    render: OrgSetPrimary,
                  },
                ]}
                data={organizationMemberships || []}
                compareFn={(column1, column2) => column1.organization.name.localeCompare(column2.organization.name)}
              />
            </div>
          </div>
        </LoaderSwitch>
      </PageContent>
    </>
  );
};

export default UserDetail;
