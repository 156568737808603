import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Portal from 'frontend/components/Portal/Portal';
import { setNavbarHeight } from 'frontend/state/dux/navbar';

import type { MenuItem as MenuItemType } from './Menu';
import styles from './Menu.scss';
import MenuItem from './MenuItem';

interface Props {
  menuItems: MenuItemType[];
}

export default function SubMenu({ menuItems }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    const setHeight = () => dispatch(setNavbarHeight(103));
    const resetHeight = () => dispatch(setNavbarHeight(67));

    setHeight();
    return () => {
      resetHeight();
    };
  }, [dispatch]);

  // TODO: Tigran (06/04/2023) -> the navigation layout needs to be fixed
  // making the entire layout with position: fixed and taking it out of the layout I don't think its a good solution
  // for now I am portalling to its relevant position, so no breaking visual change
  return (
    <Portal attachElementId="submenu-target" wrapperId="submenu">
      <div className={styles.subMenu}>
        {menuItems.map(
          (item) =>
            !item.hide && (
              <MenuItem
                subNavigation={item.subNavigation}
                title={item.name}
                slug={item.slug}
                key={`sub-menu-${item.name}`}
                className={item?.className}
                keepQuery={item?.keepQuery}
                queryParams={item?.queryParams}
              />
            ),
        )}
      </div>
    </Portal>
  );
}
