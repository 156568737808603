import { Form } from 'react-final-form';

import { FormErrors, LoaderSwitch, PageBar, Panel } from 'frontend/components';

import AllowedDomains from './components/AllowedDomains';
import WebhookKeys from './components/WebhookKeys';
import useSecurityForm from './useSecurityForm';

const Security = () => {
  const { onSubmit, loading, initialValues } = useSecurityForm();

  return (
    <LoaderSwitch loading={loading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <PageBar>
              <PageBar.FormButtons />
            </PageBar>

            <Panel>
              <FormErrors />
              <AllowedDomains />
              <Panel.Separator />
              <WebhookKeys />
            </Panel>
          </form>
        )}
      />
    </LoaderSwitch>
  );
};

export default Security;
