import { useQuery } from '@apollo/client';
import { compose, get, sortBy, uniqBy } from 'lodash/fp';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { InboxAgentsDocument, OrganizationAgentsDocument } from 'frontend/api/generated';

const sortByName = compose(uniqBy('user.id'), sortBy(get('user.profile.fullName')));

export default () => {
  const { botId, organizationId } = useParams();
  const { data: botData, loading: botLoading } = useQuery(InboxAgentsDocument, {
    variables: { id: botId as string },
    skip: !botId,
  });
  const { data: organizationData, loading: organizationLoading } = useQuery(OrganizationAgentsDocument, {
    variables: { organizationId: organizationId as string },
    skip: !organizationId,
  });

  const agents = useMemo(() => {
    const unsorted = botId ? botData?.bot?.inboxAgentMembers : organizationData?.organization?.members;

    return sortByName(unsorted ?? []);
  }, [botId, botData, organizationData]);

  return { agents, loading: botLoading || organizationLoading };
};
