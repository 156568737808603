import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { deleteFromArrayInCache } from 'frontend/api/cacheHelpers';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import { DELETE_CONTEXT_FIELD } from '../../mutations';
import { CONTEXT_FIELDS } from '../../queries';

const DeleteContextField = ({ hide, args: { id: contextFieldId, botId } }) => {
  const toast = useToast();

  const [deleteContextField] = useMutation(DELETE_CONTEXT_FIELD, {
    update: (cache, { data }) => {
      toast.success('Context field was deleted');
      deleteFromArrayInCache({
        variables: { botId, contextFieldId },
        pathToItemInMutationData: 'deleteContextField',
        pathToArrayInCache: 'allContextFields',
        query: CONTEXT_FIELDS,
      })(cache, { data });
    },
  });

  const onSubmit = useCallback(async () => {
    const variables = {
      botId,
      contextFieldId,
    };

    await deleteContextField({ variables });
  }, [botId, deleteContextField, contextFieldId]);

  return (
    <DeleteModal onSubmit={onSubmit} titleName="contextField" action="remove" hide={hide} onOkText="Yes, delete field">
      <>
        <p>Are you sure you want to delete this field?</p>
        <p>Remember to update all dialogues using this context field before deleting, or they will stop working.</p>
        This can not be undone
      </>
    </DeleteModal>
  );
};

DeleteContextField.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    id: PropTypes.string.isRequired,
    botId: PropTypes.string.isRequired,
  }),
};

export default DeleteContextField;
