import GhostLoaderTop from './GhostLoaderTop';
import styles from './styles.scss';

const InboxChats = () => (
  <div className={styles.ghostMetaContainer}>
    <GhostLoaderTop />
  </div>
);

export default InboxChats;
