import React, { type ComponentType, type SVGProps } from 'react';

import SubNav from 'frontend/features/SubNav/SubNav';
import { useLinkTo } from 'frontend/hooks';

interface AnalyticsNavLinkProps {
  title: string;
  slug: string;
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  className?: string;
  keepQuery?: boolean;
  queryParams?: string;
}

const AnalyticsNavLink = ({ title, slug, keepQuery = false, queryParams, icon, className }: AnalyticsNavLinkProps) => {
  const linkTo = useLinkTo(slug, { keepQuery, queryParams });
  return (
    <SubNav.Item className={className} icon={icon} to={linkTo}>
      {title}
    </SubNav.Item>
  );
};

export default AnalyticsNavLink;
