import { useMutation } from '@apollo/client';
import FacebookLogin, {
  type FailResponse,
  type ProfileSuccessResponse,
  type SuccessResponse,
} from '@greatsumini/react-facebook-login';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { CreateOrUpdateFacebookProfileDocument, type FacebookPagesQuery } from 'frontend/api/generated';
import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import { SelectFacebookPageModal } from './SelectFacebookPageModal';
import { META_GRAPH_API_VERSION } from '../utils';

export const APP_ID = window.env.FACEBOOK_APP_ID?.trim();
export const SCOPE = ['public_profile', 'email', 'pages_manage_metadata', 'pages_messaging'].join(',');

type Props = { facebookPages: NonNullable<FacebookPagesQuery['bot']>['facebookPages'] };

function ConnectButton({ facebookPages }: Props) {
  const toast = useToast();
  const { botId } = useParams();
  const [showSelectPageModal] = useModal(SelectFacebookPageModal);
  const [createOrUpdate, { loading }] = useMutation(CreateOrUpdateFacebookProfileDocument);

  const createOrUpdateFacebookProfile = useCallback(
    async (userId: string, userAccessToken: string): Promise<void> => {
      const { data: createData } = await createOrUpdate({ variables: { userId, userAccessToken } });

      const { error } = createData?.createOrUpdateFacebookProfile as { error?: string };
      if (error) {
        toast.error(error);
      } else {
        showSelectPageModal({ botId });
      }
    },
    [botId, createOrUpdate, showSelectPageModal, toast],
  );

  const onSuccess = useCallback(
    async (response: SuccessResponse): Promise<void> => {
      console.info('Facebook login response:', response);
      const { userID, accessToken } = response;
      await createOrUpdateFacebookProfile(userID, accessToken);
      showSelectPageModal({ botId, facebookPages });
    },
    [botId, facebookPages, createOrUpdateFacebookProfile, showSelectPageModal],
  );

  const render = useCallback(
    // FIXME: I have no idea what the linter is complaining about here
    // eslint-disable-next-line react/no-unused-prop-types
    ({ onClick }: { onClick?: () => void }) => (
      <Button onClick={onClick} type="button" color="primary" className="m-t-3" isSubmitting={loading}>
        Connect
      </Button>
    ),
    [loading],
  );

  return (
    <FacebookLogin
      appId={APP_ID!}
      scope={SCOPE}
      onSuccess={onSuccess}
      onProfileSuccess={(response: ProfileSuccessResponse) => {
        console.info('Facebook profile response:', response);
      }}
      onFail={(err: FailResponse) => {
        console.error('Facebook login error:', err);
      }}
      render={render}
      initParams={{
        version: META_GRAPH_API_VERSION,
      }}
    />
  );
}

export default ConnectButton;
