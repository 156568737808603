import cx from 'classnames';
import { get } from 'lodash';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { ReplyCandidateType } from 'frontend/features/Inbox/propTypes';
import { useModal } from 'frontend/features/Modals';
import { IDType } from 'frontend/propTypes';

import styles from './styles.scss';
import { ConfidenceScore } from '../../modals';
import MessageTag from '../MessageTag';
import Score from '../Score';

const scoreValue = (value) => {
  const score = value * 100;
  if (score < 10) return score.toFixed(1);
  return Math.round(score);
};

const ReplyCandidate = ({ replyCandidate, botId }) => {
  const score = scoreValue(replyCandidate.score);
  const replies = replyCandidate.replies;
  const [showConfidenceScore] = useModal(ConfidenceScore);
  const onClick = useCallback(() => showConfidenceScore({ botId }), [botId, showConfidenceScore]);

  let firstReply = get(replies, '0.text', '').replace(/(<([^>]+)>)/gi, ''); // Strip HTML tags

  if (firstReply.length > 140) {
    firstReply = `${firstReply.substring(0, 140)}...`;
  }

  return (
    <div
      className={cx(styles.reply, {
        [styles.replyDisabled]: !replies,
      })}
    >
      <Score title="Confidence score" onClick={onClick}>{`${score}%`}</Score>
      {replies ? (
        <Link
          to={`/workspace/${botId}/build/dialogue/${replyCandidate.dialogueId}`}
          target="_blank"
          className={styles.text}
        >
          {firstReply || <MessageTag>WEBHOOK ONLY</MessageTag>}
        </Link>
      ) : (
        <div className={styles.text}>This dialogue is deleted.</div>
      )}
    </div>
  );
};

ReplyCandidate.propTypes = {
  replyCandidate: ReplyCandidateType,
  botId: IDType.isRequired,
};

export default ReplyCandidate;
