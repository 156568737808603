import { format, isSameDay, isSameYear } from 'date-fns';

import {
  DATE_FORMAT_DATE,
  DATE_FORMAT_MONTH,
  DATE_FORMAT_MONTH_NAME,
  DATE_FORMAT_MONTH_NAME_YEAR,
  TIME_FORMAT_HOURS,
} from 'frontend/utils/date';

interface FriendlyOptions {
  force?: 'full';
  monthName?: boolean;
  year?: boolean;
}
const friendlyTimestamp = (date: number | Date, { force, monthName, year }: FriendlyOptions = {}) => {
  const now = new Date();

  if (force === 'full') return format(date, DATE_FORMAT_DATE); // e.g 21/01/2019
  if (isSameDay(now, date)) return format(date, TIME_FORMAT_HOURS); // e.g 13:17
  if (isSameYear(now, date)) {
    if (monthName && year) return format(date, DATE_FORMAT_MONTH_NAME_YEAR);
    if (monthName) return format(date, DATE_FORMAT_MONTH_NAME); // e.g 21. Nov
    return format(date, DATE_FORMAT_MONTH); // e.g 21/01
  }

  return format(date, DATE_FORMAT_DATE); // e.g 21/01/2019
};

export default friendlyTimestamp;
