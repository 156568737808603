import { InfoBox } from 'frontend/components';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';

import styles from './Entities.scss';

const EmptyState = () => (
  <div className={styles.emptyState}>
    <InfoBox title="Power up your chatbot with entities!" className={styles.infoBox}>
      Entities is a way to write samples faster. Instead of having to write several near-identical samples where only a
      single word differs, you can define an entity that represents several words and use it in your samples.&nbsp;
      <HelpCenterLink path="/entity">Read more about entities</HelpCenterLink>.
    </InfoBox>
  </div>
);

export default EmptyState;
