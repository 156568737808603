import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { Tooltip } from 'frontend/components';
import { ChatFeedbackType } from 'frontend/features/Analytics/propTypes';
import { FeedbackRating } from 'frontend/features/Analytics/views/Feedback';

import styles from './Feedback.scss';
import LogItem from '../LogItem';

const Feedback = ({ feedback, className, singleLine, onClick, hideTimestamp = false }) => {
  const ratingData = useMemo(() => ({ rating: feedback?.rating, feedbackType: feedback?.feedbackType }), [feedback]);

  if (!feedback) return null;

  return (
    <div className={styles.feedbackWrapper}>
      <LogItem
        text={feedback.freeformText}
        timeStamp={feedback.created}
        className={className}
        onClick={onClick}
        singleLine={singleLine}
        hideTimestamp={hideTimestamp}
      >
        <FeedbackRating data={ratingData} />
      </LogItem>
      <Tooltip.Standalone className={styles.tooltip}>Feedback left by user after finishing the chat</Tooltip.Standalone>
    </div>
  );
};

Feedback.propTypes = {
  feedback: ChatFeedbackType,
  singleLine: PropTypes.bool,
  hideTimestamp: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Feedback;
