import cx from 'classnames';
import { Field } from 'react-final-form';
import { Link, useParams } from 'react-router-dom';

import { InfoBox, Radio } from 'frontend/components';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import { HANDOVER_MODE } from 'frontend/features/Inbox/constants';

import styles from '../InboxSettings.scss';

const HandoverMode = () => {
  const { botId } = useParams();

  return (
    <div>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Select handover mode</h3>
      <p className={styles.helpText}>
        If you want it to be possible for a human to take over a chat conversation, you can enable that here. Keep in
        mind that handover request buttons must be added to relevant dialogues and fallbacks for the user to be able to
        request a handover.
      </p>

      <div className={`${styles.radioContainer} m-t-xl`}>
        <Field
          name="mode"
          type="radio"
          component={Radio}
          label={HANDOVER_MODE.DISABLED.title}
          value={HANDOVER_MODE.DISABLED.value}
        />
        <Field
          name="mode"
          type="radio"
          component={Radio}
          label={HANDOVER_MODE.PLATFORM.title}
          value={HANDOVER_MODE.PLATFORM.value}
        />
        <Field
          name="mode"
          type="radio"
          component={Radio}
          label={HANDOVER_MODE.EXTERNAL.title}
          value={HANDOVER_MODE.EXTERNAL.value}
        />
      </div>

      <div className={styles.boxContainer}>
        <InfoBox className={cx(styles.helpText, styles.boxContainer)} title="Inbox section or external platform?">
          Chat with users in real time in the{' '}
          <Link className="link" to={`/workspace/${botId}/inbox`}>
            Inbox
          </Link>{' '}
          section.
          <br /> Would you rather integrate with your own customer support platform? <br /> No problem!&nbsp;
          <HelpCenterLink path="/connect-integrations-and-plugins">Get in touch and we’ll help you out!</HelpCenterLink>
        </InfoBox>
      </div>
    </div>
  );
};

export default HandoverMode;
