import PropTypes from 'prop-types';

import { Modal } from 'frontend/features/Modals';

const AboutUrlTrigger = ({ hide }) => (
  <Modal hide={hide} onOk={hide} hideCancel title="About greeting based on URL">
    <Modal.Content>
      <p>You can use this field to specify which URL this greeting will appear on.</p>
      <p>Remember to always have a default greeting without the URL specified.</p>
      <p>
        <strong>URL path match</strong>: Matching domain and path. Query parameters are excluded.
        <br />
        The URL string supports wildcards (<code>*</code>), e.g. <code>/receipt/*/productId=</code> will match:
        <ul className="m-bl-1">
          <li>
            <code>www.example.com/receipt/en/productId=1234</code>
          </li>
          <li>
            <code>www.example.com/receipt/no/productId=5678</code>
          </li>
          <li>
            <code>www.example.com/receipt/sweden/productId=</code>
          </li>
        </ul>
      </p>
      <p>
        <strong>Exact match</strong>: Matching domain, path and query parameters. Must be a perfect match.
      </p>
    </Modal.Content>
  </Modal>
);

AboutUrlTrigger.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default AboutUrlTrigger;
