import { noop } from 'lodash';

export default async function fetchIfNecessary({ client, query, variables = {}, onNotInCache = noop }): Promise<void> {
  try {
    const resultFromCache = client.readQuery({ query, variables });
    if (resultFromCache) await client.query({ query, variables, fetchPolicy: 'network-only' });
    else onNotInCache();
  } catch (e) {
    onNotInCache(e);
  }
}
