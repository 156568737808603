import { gql } from '@apollo/client';

import { sampleFragment } from 'frontend/api/fragments';

import { CONFUSED_SAMPLE_PAIR_FRAGMENT } from '../fragments';

export default gql`
  query ConfusedDialogues($botId: ID!, $confusionReportLanguage: String) {
    confusedDialogues(botId: $botId, confusionReportLanguage: $confusionReportLanguage) {
      id
      confusedSamplePairs {
        ...ConfusedSamplePair
      }
      dialogueA {
        id
        title
        colorLabel
      }
      dialogueB {
        id
        title
        colorLabel
      }
      report {
        id
        bot {
          id
        }
      }
    }
  }
  ${CONFUSED_SAMPLE_PAIR_FRAGMENT}
  ${sampleFragment}
`;
