import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-final-form';

import { ButtonsOverviewDocument } from 'frontend/api/generated';
import BuildTypes from 'frontend/constants/buildTypes';
import { useBotOrSkill, useCurrentLanguage, usePrevious } from 'frontend/hooks';

import { COLUMN_FIELDS } from './constants';
import { DASHBOARD_VIEW } from '../../constants';
import { useButtonsRenderMap } from '../../hooks';
import { compileButtonList } from '../../utils';
import BuildResources from '../BuildResources';

const ORDERED_COLUMNS = COLUMN_FIELDS.filter((field) => !['dialogue', 'actions'].includes(field));

function ButtonsOverview({ values }: { values: { buttonTypes: { [key: string]: boolean } } }) {
  const { buildIdObject, buildType } = useBotOrSkill();
  const [{ selectedLanguage }] = useCurrentLanguage();

  const {
    data,
    loading,
    refetch: refetchButtonsOverview,
  } = useQuery(ButtonsOverviewDocument, {
    variables: { botId: buildIdObject!.botId!, languageCode: selectedLanguage },
    fetchPolicy: 'no-cache',
    skip: !selectedLanguage,
  });
  const form = useForm();
  const prevButtonTypes = usePrevious(values.buttonTypes);
  // Check if something else than "all" is selected
  const isNotAll = Boolean(
    Object.entries(values.buttonTypes).find(([name, value]) => (name === 'all' ? false : value)),
  );
  const isUnselected = !Object.entries(values.buttonTypes).find(([, value]) => value);

  useEffect(() => {
    // If other than "all": unselect "all".
    if (isNotAll) {
      form.change('buttonTypes.all', false);
    }
    // If all is marked: unselect other options
    if (values.buttonTypes?.all && !prevButtonTypes?.all) {
      form.reset();
    }
    // If nothing is marked: select "all"
    if (isUnselected) {
      form.change('buttonTypes.all', true);
    }
  }, [isNotAll, values.buttonTypes, prevButtonTypes, form, isUnselected]);

  const selectedTypes = useMemo(
    () =>
      Object.entries(values.buttonTypes)
        .filter(([, value]) => value)
        .map(([key]) => key),
    [values.buttonTypes],
  );

  const buttonList = useMemo(() => {
    if (!data?.buttons) {
      return [];
    }
    return compileButtonList(selectedLanguage, selectedTypes, data.buttons);
  }, [selectedLanguage, data, selectedTypes]);

  const renderMap = useButtonsRenderMap({
    buildIdObject: buildIdObject!,
    buildType: buildType!,
    refetchButtonsOverview,
    selectedLanguage,
  });

  const noData = loading ? false : !data || data.buttons.length === 0;
  return (
    <BuildResources
      resources={buttonList}
      columnFields={COLUMN_FIELDS}
      orderedColumns={ORDERED_COLUMNS}
      renderMap={renderMap}
      dashboardView={DASHBOARD_VIEW.BUTTONS}
      buildType={buildType ?? BuildTypes.BOT}
      loading={loading}
      noData={noData}
    />
  );
}

export default ButtonsOverview;
