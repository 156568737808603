import React from 'react';

import { Plus } from 'frontend/assets/icons';
import noBotIllustration from 'frontend/assets/images/no_bots.svg?url';
import { Button } from 'frontend/components';

import styles from './styles.scss';

interface NoWorkspaceProps {
  onCreateWorkspace: () => void;
}

const NoWorkspace = ({ onCreateWorkspace }: NoWorkspaceProps) => (
  <div className={styles.container}>
    <div>
      <img src={noBotIllustration} alt="No Workspace" className={styles.illustration} />
    </div>
    <article>
      <div>
        <h3 className={styles.title}>No Workspace</h3>
        This is where you can manage all aspects of your team&apos;s collaboration and projects. However, it seems like
        you currently have no workspaces.
      </div>
      <Button
        icon={Plus}
        className={styles.button}
        color="primary"
        text="Create workspace"
        onClick={onCreateWorkspace}
      />
    </article>
  </div>
);

export default NoWorkspace;
