import { gql } from '@apollo/client';

export default gql`
  query FetchNudgeCampaignData(
    $botId: ID!
    $engagementId: Int!
    $campaignId: String
    $fromDate: Date!
    $toDate: Date!
    $granularity: String!
  ) {
    nudgeCampaignData(
      botId: $botId
      engagementId: $engagementId
      campaignId: $campaignId
      fromDate: $fromDate
      toDate: $toDate
      granularity: $granularity
    ) {
      id
      engagementId
      campaignId
      date
      conversions
      conversionsMobile
      conversionsDesktop
      conversionValue
      conversionValueInNok
      displays
      positiveClicks
      allClicks
    }
  }
`;
