import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { MyBotsDocument, MySkillsDocument } from 'frontend/api/generated';
import { List } from 'frontend/components';
import { useMixpanel } from 'frontend/hooks';
import { isSameResource, resourceRemoved, resourceUpdated } from 'frontend/state/dux/navbar';

import styles from '../styles.scss';

const MAX_LENGTH = 3;

const getResourceIds = (type, list) =>
  list.reduce((idList, { resourceType, id }) => (resourceType === type ? [id, ...idList] : idList), []);

const addItem = (newItem) => (currentItems) => [...currentItems, newItem];

const useRecentlyVisited = () => {
  const dispatch = useDispatch();
  const recentlyVisited = useSelector((state) => state.navbar.recentlyVisited);
  const botIds = useMemo(() => getResourceIds('bot', recentlyVisited), [recentlyVisited]);
  const skillIds = useMemo(() => getResourceIds('skill', recentlyVisited), [recentlyVisited]);

  const { data: botsData, loading: botsLoading } = useQuery(MyBotsDocument, { variables: { botIds } });
  const { data: skillsData, loading: skillsLoading } = useQuery(MySkillsDocument, { variables: { skillIds } });

  const [deletedItems, setDeletedItems] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);

  const existingRecentlyVisited = useMemo(() => {
    if (botsLoading || skillsLoading) return [];

    const existingResources = [
      ...get(botsData, 'me.bots', []).map((bot) => ({ ...bot, resourceType: 'bot' })),
      ...get(skillsData, 'me.skills', []).map((skill) => ({ ...skill, resourceType: 'skill' })),
    ];

    const existing = recentlyVisited.reduce((existingList, item) => {
      const existingResource = existingResources.find((existingItem) => isSameResource(item, existingItem));

      if (!existingResource) {
        setDeletedItems(addItem(item));
        return existingList;
      }

      if (existingResource.name !== item.name) setUpdatedItems(addItem(existingResource));

      return [...existingList, existingResource];
    }, []);

    return existing;
  }, [botsData, botsLoading, recentlyVisited, skillsData, skillsLoading]);

  useEffect(() => {
    if (deletedItems.length > 0) {
      deletedItems.forEach((item) => dispatch(resourceRemoved(item)));
      setDeletedItems([]);
    }
  }, [deletedItems, dispatch]);

  useEffect(() => {
    if (updatedItems.length > 0) {
      updatedItems.forEach((item) => dispatch(resourceUpdated(item)));
      setUpdatedItems([]);
    }
  }, [updatedItems, dispatch]);

  return existingRecentlyVisited;
};

const Header = () => <div className={styles.listHeader}>Recently used workspaces</div>;

const RecentlyUsedMenu = ({ close = () => null }) => {
  const recentlyVisited = useRecentlyVisited();
  const { mixpanel } = useMixpanel();

  const data = useMemo(
    () =>
      recentlyVisited.slice(0, MAX_LENGTH).map((item) => ({
        title: item.projectName || item.name,
        key: `navbar-recentlyused-${item.id}`,
        resourceUrl: `/${item.resourceType}/${item.id}`,
      })),
    [recentlyVisited],
  );

  const onClickMenuItem = () => {
    mixpanel.track('Click on recently used workspace');
    close();
  };

  const renderItem = (item) => (
    <List.Item className={styles.userMenuItem} onClick={onClickMenuItem} key={item.key} tabIndex={item.href ? -1 : 0}>
      <Link to={item.resourceUrl}>{item.title}</Link>
    </List.Item>
  );

  if (data.length <= 1) return null;

  return (
    <List
      width="255px"
      dataSource={data}
      className={styles.listWrapper}
      renderHeader={Header}
      renderItem={renderItem}
    />
  );
};

RecentlyUsedMenu.propTypes = {
  close: PropTypes.func,
};

export default RecentlyUsedMenu;
