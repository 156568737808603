import cx from 'classnames';
import PropTypes from 'prop-types';

import { Check, Close } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './Permission.scss';

const Permission = ({ checked }) => (
  <div className={cx(styles.permission, { [styles.permissionFalse]: !checked })}>
    {checked && <Icon component={Check} color="success" />}
    {!checked && <Icon component={Close} color="warning" />}
  </div>
);

Permission.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default Permission;
