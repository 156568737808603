import { gql } from '@apollo/client';

import { sampleCandidateFragment } from '../fragments';

export default gql`
  query SampleCandidates($botId: ID!, $toDate: DateTime, $fromDate: DateTime) {
    sampleCandidates(botId: $botId, fromDate: $fromDate, toDate: $toDate) {
      ...SampleCandidate
    }
  }
  ${sampleCandidateFragment}
`;
