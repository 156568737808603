import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useBotOrganization } from 'frontend/features/Organization/hooks';
import { useBotOrSkill, useUrlSearchParams } from 'frontend/hooks';
import { selectAnalyticsFilters, setAnalyticsFilters } from 'frontend/state/dux/analytics/filters';
import { useAppDispatch } from 'frontend/state/hooks';

import { DEFAULT_TIMEZONE } from '../constants';
import { getUrlArrayParam } from '../utils';

const filterToUrlParams = getUrlArrayParam('tz');

const useTimezone = () => {
  const filters = useSelector(selectAnalyticsFilters);
  const [{ tz: timezone }, setParams] = useUrlSearchParams();
  const { botId } = useBotOrSkill();
  const { organization, loading } = useBotOrganization({ botId, skip: filters?.timezone });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (timezone && !filters?.timezone) {
      dispatch(setAnalyticsFilters({ ...filters, timezone }));
    } else if (filters.timezone && !timezone) {
      setParams({ tz: filters.timezone });
    } else if (organization?.timezone) {
      dispatch(setAnalyticsFilters({ ...filters, timezone: organization.timezone }));
      setParams({ tz: organization.timezone });
    }
  }, [organization?.timezone, dispatch, filters, setParams, timezone]);

  const setTimezone = (tz: string) => {
    dispatch(setAnalyticsFilters({ ...filters, timezone: tz }));
    setParams({ tz });
  };

  return {
    timezone: timezone || filters.timezone || organization?.timezone || DEFAULT_TIMEZONE,
    filterToUrlParams,
    loading,
    setTimezone,
  };
};

export default useTimezone;
