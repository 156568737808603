import { gql } from '@apollo/client';

import { ATTACHMENT } from '../fragments';

export default gql`
  query Attachments($botId: ID!, $chatId: ID!) {
    attachments(botId: $botId, chatId: $chatId) {
      ...Attachment
    }
  }
  ${ATTACHMENT}
`;
