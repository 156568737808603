import type { OnUpdate } from '../../hooks/usePusherEvent';
import Pusher from '../Pusher';

interface PusherMultipleProps {
  channel: string;
  events: Record<string, OnUpdate>;
}

/**
Used to subscribe to several events in the same channel.

Example: events = { 'dialogue-created': dialogueCreated, 'dialogue-updated': dialogueUpdated }
this will run dialogueCreated on the event 'dialogue-created' and dialogueUpdated on 'dialogue-updated'
*/
const PusherMultiple = ({ channel, events }: PusherMultipleProps) => (
  <>
    {Object.entries(events).map(([event, onUpdate]) => (
      <Pusher key={`pusher-event-${event}`} channel={channel} event={event} onUpdate={onUpdate} />
    ))}
  </>
);

export default PusherMultiple;
