import { gql } from '@apollo/client';

import LabelFragment from 'frontend/api/fragments/Label.fragment.graphql';

export default gql`
  query GetChatMeta($chatId: ID!) {
    chatAndBot(chatId: $chatId) {
      id
      chat {
        id
        avatarUrl
        fullName
        source
        takenOver
        autoUser
        labels {
          ...Label
        }
        email
        phoneNumber
        emailVerified
        phoneNumberVerified
        contactDetails
        isAuthenticated
        subject
        context
      }
    }
  }
  ${LabelFragment}
`;
