import { format } from 'date-fns';
import { isPlainObject, isString, startCase } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Modal } from 'frontend/features/Modals';
import { IDType } from 'frontend/propTypes';

import styles from './AlertDescription.scss';
import ArchiverContent from './ArchiverContent';
import { ALERTERS, ALERT_LEVELS } from '../../constants';

const getUrl = (alerter, botId) => {
  switch (alerter) {
    case ALERTERS.SEASON_CHECK:
      return `/workspace/${botId}/settings/chat/appearance`;
    default:
      return null;
  }
};

const CONTENT_MAP = { [ALERTERS.KINDLY_ARCHIVER]: ArchiverContent };

const AlertDescription = ({ hide, args }) => {
  const { info, title, alerter, botId, createdAt } = args;
  const url = getUrl(alerter, botId);
  const onClick = useCallback(
    (event) => {
      // Probably not opening in new tab -> close modal
      if (!event.shiftKey && !event.metaKey && !event.ctrlKey) hide();
    },
    [hide],
  );

  const Content = CONTENT_MAP[alerter];

  return (
    <Modal title={title} footer hide={hide} onOk={hide} hideCancel>
      <Modal.Content>
        {Content && <Content args={args} />}
        {!Content && (
          <>
            {format(createdAt)}
            <br />
            <br />
            {isPlainObject(info) &&
              Object.entries(info).map(([key, value]) => (
                <div key={`info-item-${key}'`} className={styles.descriptionItem}>
                  <strong>{startCase(key)}:</strong> {value}
                </div>
              ))}
            {isString(info) && <div className={styles.descriptionItem}>{info}</div>}
            {Boolean(url) && (
              <>
                <br />
                <br />
                <Link className="link" to={url} onClick={onClick}>
                  More info
                </Link>
              </>
            )}
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

AlertDescription.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    info: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    botId: IDType.isRequired,
    alerter: PropTypes.oneOf(Object.values(ALERTERS)).isRequired,
    level: PropTypes.oneOf(Object.values(ALERT_LEVELS)),
  }).isRequired,
};

export default AlertDescription;
