import { NudgeWrapper, Section, Text } from '..';
import styles from './styles.scss';

const MultipleChoiceNudge = ({ nudge, currentLanguage }) => {
  const { title, bodyText, buttonBlocks } = nudge;

  return (
    <NudgeWrapper>
      <Text title={title?.[currentLanguage]} description={bodyText?.[currentLanguage]} />
      {buttonBlocks && (
        <>
          <div className={styles.separator} />
          <Section>
            {(buttonBlocks || []).map((block) =>
              block?.buttonText?.[currentLanguage] ? (
                <div key={block.buttonText[currentLanguage]} className={styles.button}>
                  {block.buttonText[currentLanguage]}
                </div>
              ) : null,
            )}
          </Section>
        </>
      )}
    </NudgeWrapper>
  );
};

export default MultipleChoiceNudge;
