import { useEvent, usePusher } from '@harelpls/use-pusher';
import { get, set } from 'lodash';
import { useEffect } from 'react';

export type OnUpdateData = any;
export type OnUpdate = (data: OnUpdateData) => void;

const channelEventMap: {
  [channelName: string]: { [eventName: string]: [onUpdate: (data: OnUpdateData) => void] };
} = {};

export default (channelName: string, event: string, onUpdate: OnUpdate) => {
  const { client } = usePusher();
  let channel;

  if (!Object.keys(client?.channels.channels || {}).includes(channelName)) {
    channel = client?.subscribe(channelName);
  } else {
    channel = client?.channel(channelName);
  }

  useEffect(() => {
    // Update the channelEventMap object
    set(channelEventMap, [channelName, event], [...get(channelEventMap, [channelName, event], []), onUpdate]);

    return () => {
      // Remove callback from channelEventMap object
      const onUpdateFunctions = get(channelEventMap, [channelName, event], []);
      set(
        channelEventMap,
        [channelName, event],
        onUpdateFunctions.filter((fn) => fn !== onUpdate),
      );

      // Unsubscribe the channel if there are no more listeners
      if (Object.values(channelEventMap[channelName] || {}).flat().length === 0) {
        client?.unsubscribe(channelName);
      }
    };
  }, [event, client, channelName, onUpdate]);

  useEvent<OnUpdateData>(channel, event, onUpdate);
};
