import { useCallback, useMemo, useState } from 'react';

import { Pencil } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { usePusherEvent } from 'frontend/features/Pusher';
import { useBotOrSkill } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';

import styles from './EditingNow.scss';

interface Props {
  dialogueLikeId: number | string;
}

const EditingNow = ({ dialogueLikeId }: Props) => {
  const { buildType, buildId } = useBotOrSkill();
  const pusherChannel = `presence-build-${buildType}-${buildId}-${dialogueLikeId}`;
  const [users, setUsers] = useState<{ [key: string]: unknown; id: string | number }[]>([]);
  const { data, loading } = useMe();

  const onSubscriptionSucceeded = useCallback(({ members }) => setUsers(Object.values(members)), []);
  const onUserAdded = useCallback(({ info }) => setUsers((currentUsers) => [...currentUsers, info]), []);
  const onUserRemoved = useCallback(
    ({ info }) => setUsers((currentUsers) => currentUsers.filter(({ id }) => id !== info.id)),
    [],
  );

  usePusherEvent(pusherChannel, 'pusher:subscription_succeeded', onSubscriptionSucceeded);
  usePusherEvent(pusherChannel, 'pusher:member_added', onUserAdded);
  usePusherEvent(pusherChannel, 'pusher:member_removed', onUserRemoved);

  const usersEditing = useMemo(
    () => users.filter(({ id }) => id.toString() !== data?.me?.id).map(({ name }) => name),
    [data, users],
  );

  if (loading || usersEditing.length < 1) {
    return null;
  }

  return (
    <div className={styles.editingWrapper}>
      <span className={styles.userItem}>
        <Icon component={Pencil} color="white" />
        <strong className="m-r-xs">Also editing:&nbsp;</strong> {usersEditing.join(', ')}
      </span>
    </div>
  );
};

export default EditingNow;
