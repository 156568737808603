import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Link, useParams } from 'react-router-dom';

import { LibraryDialoguesDocument } from 'frontend/api/generated';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { systemDialogueMessageTypes } from 'frontend/features/Library/constants';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from '../InboxSettings.scss';

const Messages = () => {
  const { botId } = useParams();
  const { hasBotPerms } = useMyPermissions({ botId });
  const canViewTemplates = hasBotPerms('view_templates');

  const { data, loading } = useQuery(LibraryDialoguesDocument, {
    variables: { botId, regular: false, dialogueType: DIALOGUE_TYPES.SYSTEM },
    skip: !canViewTemplates,
  });

  if (loading) {
    return null;
  }

  const { id: requestDialogueId } =
    get(data, 'dialogues', []).find(({ messageType }) => messageType === systemDialogueMessageTypes.REQUEST) || {};

  if (!requestDialogueId) {
    return null;
  }

  return (
    <div>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Messages</h3>
      <p className={`m-b-0 ${styles.helpText}`}>
        A smooth and silky transition from bot to human support makes for happy customers! Customize your
        handover-related bot replies in the relevant{' '}
        <Link to={`/workspace/${botId}/build/system-dialogue/${requestDialogueId}`} className="link">
          section of Build.
        </Link>
      </p>
    </div>
  );
};

export default Messages;
