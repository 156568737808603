import { useParams } from 'react-router-dom';

import { AnalyticsContent, Grid } from 'frontend/features/Analytics/components';
import Filters, { FilterInfo } from 'frontend/features/Analytics/views/Filters';
import { useBooleanState } from 'frontend/hooks';

import { EmailRemarketingStats, OnsiteRemarketingStats } from './components';
import { HIDE_FILTERS } from './constants';
import useEngagementStats from './hooks/useEngagementStats';
import styles from './styles.scss';

const Conversion = () => {
  const { organizationId } = useParams();
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();

  const { campaigns, loading, error } = useEngagementStats({
    organizationId: organizationId!,
    organizationLoading: !organizationId,
  });

  return (
    <>
      <Filters
        hideFilterView={hideFilterView}
        filterViewShowing={filterViewShowing}
        hideFilter={HIDE_FILTERS}
        platformAnalytics
      />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} includeLanguages={false} />
        <div className={`m-b-4 ${styles.gridWrapper}`}>
          <Grid>
            <div className={styles.statsWrapper}>
              <OnsiteRemarketingStats campaigns={campaigns} loading={loading} error={error} />
            </div>
            <div className={styles.statsWrapper}>
              <EmailRemarketingStats campaigns={campaigns} loading={loading} error={error} />
            </div>
          </Grid>
        </div>
      </AnalyticsContent>
    </>
  );
};

export default Conversion;
