import { gql } from '@apollo/client';

export default gql`
  query BotChannels($botId: ID!) {
    bot(id: $botId) {
      id
      applications {
        id
      }
      facebookPages {
        id
      }
    }
  }
`;
