import PropTypes from 'prop-types';

import { DeleteModal } from 'frontend/features/Modals';

const DeleteChat = ({ hide, args: { userName, deleteChat } }) => (
  <DeleteModal onSubmit={deleteChat} hide={hide} titleName="chat" name={`this chat with ${userName}`} />
);

DeleteChat.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    deleteChat: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteChat;
