import cx from 'classnames';
import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Check } from 'frontend/assets/icons';

import styles from './styles.scss';
import { Icon } from '../Icon/Icon';

interface CopyToClipBoardButtonProps {
  children?: React.ReactNode;
  text?: string;
  copyText: string;
}

const CopyToClipBoardButton = ({ children, text, copyText }: CopyToClipBoardButtonProps) => {
  const [showIcon, setShowIcon] = useState(false);
  const onCopy = useCallback(() => setShowIcon(true), [setShowIcon]);
  const onAnimationEnd = useCallback(() => setShowIcon(false), [setShowIcon]);

  return (
    <CopyToClipboard text={copyText} onCopy={onCopy}>
      <button type="button" className={cx('link', styles.button)}>
        {children || text}
        <Icon
          component={Check}
          className={cx(styles.icon, { [styles.showIcon]: showIcon })}
          onAnimationEnd={onAnimationEnd}
          color="gray"
        />
      </button>
    </CopyToClipboard>
  );
};

export default CopyToClipBoardButton;
