import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { Bell, GitFork, ShieldTick, UserCircle } from 'frontend/assets/icons';
import { PageContent } from 'frontend/components';
import { NotFound } from 'frontend/features';

import styles from './Profile.scss';
import { ConnectedAccounts, ProfileNotifications, ProfileSecurity, ProfileSettings } from './views';
import SubNav from '../SubNav/SubNav';

function FixedLayout() {
  return (
    <PageContent hasFormValidation className={styles.container}>
      <SubNav>
        <SubNav.Item icon={UserCircle} to="profile">
          Profile
        </SubNav.Item>
        <SubNav.Item icon={Bell} to="notifications">
          Notifications
        </SubNav.Item>
        <SubNav.Item icon={ShieldTick} to="security">
          Security
        </SubNav.Item>
        <SubNav.Item icon={GitFork} to="connected-accounts">
          Connected accounts
        </SubNav.Item>
      </SubNav>

      <Outlet />
    </PageContent>
  );
}

export default function Settings() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="profile" />} />

      <Route element={<FixedLayout />}>
        <Route path="profile" element={<ProfileSettings />} />
        <Route path="notifications" element={<ProfileNotifications />} />
        <Route path="connected-accounts" element={<ConnectedAccounts />} />
        <Route path="security" element={<ProfileSecurity />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
