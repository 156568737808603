import { format } from 'date-fns/format';
import React from 'react';

import AdvancedTooltip from 'frontend/components/AdvancedTooltip/AdvancedTooltip';
import { TIME_FORMAT_HOURS } from 'frontend/utils/date';

import styles from './Timestamp.scss';

interface TimestampProps {
  timestamp: number;
}

const Timestamp = ({ timestamp }: TimestampProps) => (
  <AdvancedTooltip maxWidth="480" className={styles.timestamp}>
    {format(timestamp, TIME_FORMAT_HOURS)}
    <AdvancedTooltip.Body>{new Date(timestamp).toUTCString()}</AdvancedTooltip.Body>
  </AdvancedTooltip>
);

export default Timestamp;
