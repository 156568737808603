import { useMutation } from '@apollo/client';

import { ASSIGN_USER_TO_TICKET } from '../mutations';

const useAssignUserToTicket = ({ ticket, agent }) => {
  const assignedAgentId = ticket?.assignedAgent?.id ?? 0;
  const userAssigned = assignedAgentId === agent?.id;

  const [assignUser] = useMutation(ASSIGN_USER_TO_TICKET, {
    variables: {
      ticketId: ticket?.id,
      userId: agent?.id,
      assign: !userAssigned,
    },
    optimisticResponse: {
      ticketAssignUser: {
        __typename: 'TicketType',
        ...ticket,
        assignedAgent: {
          __typename: 'UserType',
          id: agent?.id,
          profile: {
            __typename: 'ProfileType',
            id: agent?.profile?.id,
            firstName: agent?.firstName || agent?.profile?.firstName,
            lastName: agent?.lastName || agent?.profile?.lastName,
            fullName: agent?.profile?.fullName,
            avatarUrl: agent?.profile?.avatarUrl,
            alias: agent?.profile?.alias,
          },
        },
      },
    },
    refetchQueries: ['TicketViewCounts'],
  });

  return { assignUser, userAssigned };
};
export default useAssignUserToTicket;
