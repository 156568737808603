import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  AllPermissionsDocument,
  OrganizationInvitationDocument,
  UpdateInvitationDocument,
} from 'frontend/api/generated';
import { useIsSubmitting, useToast } from 'frontend/hooks';

import UpdateMemberModal from '../AddMemberModal/UpdateMemberModal';
import { roleIdsFromFields } from '../AddMemberModal/utils';

const UpdateInvitation = ({
  hide,
  args: { email, organizationId, id },
}: {
  hide: () => Promise<void>;
  args: {
    organizationId: string;
    id: string;
    email: string;
  };
}) => {
  const toast = useToast();
  const [isSubmitting, submitWrapper] = useIsSubmitting();

  const { data, loading } = useQuery(OrganizationInvitationDocument, {
    variables: { organizationId, invitationId: id },
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(AllPermissionsDocument);
  const allPermissions = permissionsData?.allPermissions ?? [];
  const roles = useMemo(() => data?.organization.roles ?? [], [data]);
  const currentRoles = useMemo(() => data?.organization.invitation?.organizationRoles ?? [], [data]);
  const name = useMemo(() => data?.organization.name ?? '', [data]);

  const [mutate] = useMutation(UpdateInvitationDocument);

  const onSubmit = useCallback(
    async (values) => {
      const organizationRoleIds = roleIdsFromFields(values);
      const input = { organizationId, organizationRoleIds };
      await mutate({ variables: { invitationId: id, input } });
      toast.success(`The invitation to ${email} was updated!`);
      hide();
    },
    [email, hide, id, mutate, organizationId, toast],
  );

  if (loading || permissionsLoading) return null;

  return (
    <UpdateMemberModal
      organizationId={organizationId}
      users={[]}
      disallowOrganizationRoles={false}
      currentRoles={currentRoles}
      hide={hide}
      name={name}
      email={email}
      roles={roles}
      onSubmit={submitWrapper(onSubmit)}
      isSubmitting={isSubmitting}
      allPermissions={allPermissions}
    />
  );
};

export default UpdateInvitation;
