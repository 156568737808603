import { Form, NudgeWrapper, Text } from '..';

const FormNudge = ({ nudge, currentLanguage }) => {
  const { title, bodyText, inputLabel, inputPlaceholder } = nudge;

  return (
    <NudgeWrapper>
      <Text title={title?.[currentLanguage]} description={bodyText?.[currentLanguage]} />
      <Form label={inputLabel?.[currentLanguage]} placeholder={inputPlaceholder?.[currentLanguage]} />
    </NudgeWrapper>
  );
};

export default FormNudge;
