import { maxBy } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getChatPath } from 'frontend/features/Inbox/utils';

export default ({ botId, organizationId, chatId, chats, areChatsLoading }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const isOnEmptyState = pathname.includes('empty');
    const doNotRedirect = !pathname.match(/^\/(?:workspace|organization)\/\d+\/inbox/);
    const isOnChats = pathname.includes('chat/');

    if (areChatsLoading || doNotRedirect || chatId || isOnEmptyState) return;

    if (!isOnChats && chats?.length > 0) {
      if (chatId) return;
      const newestChat = maxBy(chats, ({ updated }) => updated);
      navigate({ pathname: getChatPath(`chat/${newestChat.id}`, { botId, organizationId }), replace: true, search });
    } else if (!(chats?.length > 0)) {
      navigate({ pathname: getChatPath('chat/empty', { botId, organizationId }), replace: true, search });
    }
  }, [areChatsLoading, botId, chatId, chats, navigate, organizationId, pathname, search]);
};
