import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { Copy } from 'frontend/assets/icons';
import { Button, PageBar } from 'frontend/components';
import useModal from 'frontend/features/Modals/hooks/useModal';
import { useDraftEnabled } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import CopyOutputModal from './CopyOutputModal';
import styles from './DialoguePageBar.scss';

const DialoguePageBar = ({ hasChanges, followUpCount, isNewDialogue }) => {
  const { draftEnabled, draftText } = useDraftEnabled();
  const [searchParamsFlow] = useSearchParams({ view: 'flow' });
  const [hasChangesText, noChangesText] = (draftEnabled && draftText) || [];
  const flowButtonText = followUpCount ? `View flow (${followUpCount})` : 'View flow';
  const [searchParams] = useSearchParams();
  const isOnOutputTab = searchParams.get('formContent') === 'output';

  const { isKindlyAdminViewer } = useMyPermissions();

  const [showCopyModal] = useModal(CopyOutputModal);

  return (
    <PageBar className={styles.pageBar}>
      {isOnOutputTab && isKindlyAdminViewer && !hasChanges && (
        <Button
          icon={Copy}
          className={styles.copyOutputButton}
          onClick={() => showCopyModal()}
          title="Copy output to another dialogue"
          color="kindlyAdmin"
        />
      )}

      {!isNewDialogue && <Button to={`?${searchParamsFlow.toString()}`} text={flowButtonText} />}
      <PageBar.FormButtons hasChanges={hasChanges} hasChangesText={hasChangesText} noChangesText={noChangesText} />
    </PageBar>
  );
};

DialoguePageBar.propTypes = {
  hasChanges: PropTypes.bool.isRequired,
  followUpCount: PropTypes.number,
  isNewDialogue: PropTypes.bool,
};

export default DialoguePageBar;
