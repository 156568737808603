import { gql } from '@apollo/client';

import { EVALUATION_REPORT_FRAGMENT } from './fragments';

const RUN_EVALUATION = gql`
  mutation RunEvaluation($botId: ID!, $evaluationId: ID!, $nlpOverrides: NlpOverrideInputType) {
    runEvaluation(botId: $botId, evaluationId: $evaluationId, nlpOverrides: $nlpOverrides) {
      ...EvaluationReport
    }
  }
  ${EVALUATION_REPORT_FRAGMENT}
`;
export default RUN_EVALUATION;
