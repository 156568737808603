import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { FormsLinkedToDialoguesDocument } from 'frontend/api/generated';
import { Loader } from 'frontend/components';
import { DeleteModal } from 'frontend/features/Modals';
import { useCurrentLanguage, useLanguages } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import styles from './DeleteDialogue.scss';
import { useOnSubmit } from './hooks';
import { BUTTONS_LINKED_TO_DIALOGUE } from '../../queries';
import { getBuildUrl } from '../../utils';

function formatFormLinkedDialogues({
  formsLinkedToDialogue,
  buildIdObject,
  currentDialogueId,
  languageNameMapVariants,
}) {
  return (formsLinkedToDialogue?.formsLinkedToDialogue || []).map(
    ({ cancelDialogue, submitDialogue, dialogue, languageCode, rule }) => {
      const type = (() => {
        const result: string[] = [];
        if (currentDialogueId === submitDialogue?.id) {
          result.push('submit dialogue');
        }
        if (currentDialogueId === cancelDialogue?.id) {
          result.push('cancel dialogue');
        }
        return result.length > 0 ? result.join(' / ') : null;
      })();
      const { id, dialogueType } = dialogue!;
      const url = getBuildUrl({ buildIdObject, target: id, dialogueType });
      return {
        id: `${id}-${languageCode}-${rule}`,
        label: (
          <u>
            <b>{dialogue?.title[languageCode] ?? dialogue?.title.default}</b> -{' '}
            <em>
              {languageNameMapVariants[languageCode]}
              {rule?.name ? ` - ${rule?.name}` : ''}
            </em>{' '}
            - <em>{type}</em>
          </u>
        ),
        url,
      };
    },
  );
}

const DeleteDialogue = ({ hide, args: { dialogue, botOrSkillParams, shouldRedirect } }) => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { buildIdObject, buildType, isBot, buildId } = botOrSkillParams;
  const { languageNameMapVariants, loading: languagesLoading } = useLanguages({
    isBot,
    buildIdObject,
    buildType,
  } as BotOrSkill);

  const { data: buttonLinkedToDialogue, loading: loadingButtonLinkedToDialogue } = useQuery(
    BUTTONS_LINKED_TO_DIALOGUE,
    {
      variables: { ...buildIdObject, dialogueId: dialogue.id },
      fetchPolicy: 'network-only',
    },
  );

  const { data: formsLinkedToDialogue, loading: loadingFormsLinkedToDialogue } = useQuery(
    FormsLinkedToDialoguesDocument,
    {
      variables: { ...buildIdObject, dialogueId: dialogue.id },
      fetchPolicy: 'network-only',
    },
  );

  const dialoguesWithFormLinkedToDialogues = formatFormLinkedDialogues({
    formsLinkedToDialogue,
    buildIdObject,
    currentDialogueId: dialogue.id,
    languageNameMapVariants,
  });
  const loading = loadingButtonLinkedToDialogue || loadingFormsLinkedToDialogue || languagesLoading;

  const onSubmit = useOnSubmit({
    dialogue,
    buildIdObject,
    buildId,
    buildType,
    formsLinkedToDialogue,
    buttonLinkedToDialogue,
    shouldRedirect,
  });

  if (loading) return <Loader size="large" noMargin />;

  if (dialoguesWithFormLinkedToDialogues.length > 0) {
    return (
      <DeleteModal hide={hide} titleName="dialogue" onSubmit={onSubmit} disabled>
        <div className="m-t-2">
          This dialogue is used by forms in other dialogues and can&apos;t be deleted immediately. The following forms
          linked to the dialogue need to be changed:
        </div>
        <ul className={cx('m-t-1', styles.buttonList)}>
          {dialoguesWithFormLinkedToDialogues.map(({ id, label, url }) => (
            <li key={`linked-form-${id}`}>
              <Link to={url} onClick={hide}>
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </DeleteModal>
    );
  }

  return (
    <DeleteModal hide={hide} titleName="dialogue" onSubmit={onSubmit}>
      Are you sure you want to delete <em>{dialogue.title[currentLanguage] ?? dialogue.title.default}</em>
      {Object.keys(languageNameMapVariants).length > 1 &&
        ` for all languages (${Object.keys(languageNameMapVariants).join(', ')})`}
      ?
      {buttonLinkedToDialogue.buttonsLinkedToDialogue.length > 0 && (
        <>
          <div className="m-t-2">The following buttons linked to the dialogue will also be deleted:</div>
          <ul className={cx('m-t-1', styles.buttonList)}>
            {Object.entries(groupBy(buttonLinkedToDialogue.buttonsLinkedToDialogue, 'languageCode')).map(
              ([languageCode, buttons], _, groups) => (
                <Fragment key={`button-list-${languageCode}`}>
                  <div className={styles.buttonListLanguage}>
                    {groups.length > 1 && <span>{languageNameMapVariants[languageCode]}</span>}
                  </div>
                  {buttons.map(({ id, dialogue: buttonDialogue, dialogueMod, label }) => (
                    <li key={`linked-button-${id}`}>
                      <Link to={buttonDialogue?.url ?? dialogueMod?.url}>{label}</Link>
                    </li>
                  ))}
                </Fragment>
              ),
            )}
          </ul>
        </>
      )}
      <br />
      This can not be undone.
    </DeleteModal>
  );
};

DeleteDialogue.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    dialogue: PropTypes.shape({ title: PropTypes.shape({}).isRequired }).isRequired,
    botOrSkillParams: BotOrSkillParamsType.isRequired,
    shouldRedirect: PropTypes.bool,
  }).isRequired,
};

export default DeleteDialogue;
