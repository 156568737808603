import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './Insights.scss';
import Conversations from './components/Conversations/Conversations';
import Overview from './components/Overview/Overview';
import PanelMenu from './components/PanelMenu/PanelMenu';
import useLayout from './hooks/useLayout';

const Insights = () => {
  useLayout();
  const { botId } = useParams();

  const { isKindlyAdminManager } = useMyPermissions({ botId });

  return (
    <div className={styles.insightsWrapper}>
      <PanelMenu />
      <Routes>
        <Route path="/chat" element={<Conversations />} />
        <Route path="/chat/:chatId" element={<Conversations />} />
        {isKindlyAdminManager && <Route path="/overview" element={<Overview />} />}
        <Route path="*" element={<Navigate to="chat" />} />
      </Routes>
    </div>
  );
};

export default Insights;
