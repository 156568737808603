import { useForm, useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { Labels } from 'frontend/assets/icons';
import { EmptyState } from 'frontend/components';
import { useBotLabels } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import type { Label } from 'frontend/propTypes/LabelType';

import styles from './LabelsController.scss';
import LabelsSection from '../../../Labels/LabelsSection';

function LabelsController() {
  const form = useForm();
  const { botId } = useParams();
  const { values } = useFormState({ subscription: { values: true } });
  const dialogueId = values?.mod?.id ?? values.id;

  const botLabels = useBotLabels();

  const activeLabelsIds = (values.labels || []).map((label) => label.id);

  const { hasBotPerms, loading: permissionsLoading } = useMyPermissions({ botId });
  const canEditLabels = hasBotPerms('manage_bot');

  const handleLabelToggle = (label: Label, status: boolean) => {
    if (status) {
      form.mutators.push?.('labels', label);
    } else {
      const indexActiveLabel = values.labels?.findIndex((item) => item.id === label.id);
      form.mutators.remove?.('labels', indexActiveLabel);
    }
  };

  /* If it's a new dialogue being created, show an Empty State info panel. */
  if (!dialogueId) {
    return (
      <EmptyState
        title="Save the dialogue"
        description="Before being able to add labels, you need to save the dialogue first."
        icon={Labels}
        color="red"
        maxWidth="90%"
      />
    );
  }

  return (
    <LabelsSection
      botLabels={botLabels}
      className={styles.labelsSection}
      handleLabelToggle={handleLabelToggle}
      activeLabelsIds={activeLabelsIds}
      canEditLabels={canEditLabels}
      loading={permissionsLoading}
      shouldDisplaySystemLabels
    />
  );
}

export default LabelsController;
