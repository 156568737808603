import { isEmpty, kebabCase } from 'lodash';

import { DIALOGUE_TYPES, isRegularDialogueType } from 'frontend/constants';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

function getBaseSegment({
  buildType,
  buildId,
  buildIdObject,
}: {
  buildType?: string;
  buildId?: string;
  buildIdObject?: BuildIdObject;
}) {
  if (buildType === 'bot') buildType = 'workspace';

  if (buildType && buildId) {
    return `${buildType}/${buildId}/build`;
  }

  if (!buildIdObject || isEmpty(buildIdObject)) {
    throw new Error();
  }

  if ('skillId' in buildIdObject) {
    return `skill/${buildIdObject.skillId}/build`;
  }

  return `workspace/${buildIdObject.botId}/build`;
}

function getTypeSegment(dialogueType: string): string {
  if (isRegularDialogueType(dialogueType)) return 'dialogue';
  if (dialogueType === DIALOGUE_TYPES.SYSTEM_DIALOGUE) return 'system-dialogue';
  return `${kebabCase(dialogueType)}`;
}

type Props = {
  buildType?: string;
  buildId?: string;
  buildIdObject?: BuildIdObject;
  dialogueType: string;
  target: string;
  ruleId?: string;
};
export default function getBuildUrl({
  buildType,
  buildId,
  buildIdObject,
  dialogueType,
  target,
  ruleId,
}: Props): string {
  const baseSegment = getBaseSegment({ buildType, buildId, buildIdObject });
  const typeSegment = getTypeSegment(dialogueType);

  return `/${baseSegment}/${typeSegment}/${target}${ruleId ? `?rule=${ruleId}` : ''}`;
}
