import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { softDeleteInCache } from 'frontend/api/cacheHelpers';
import { PLATFORM_ALERT_FRAGMENT } from 'frontend/api/fragments';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import { DELETE_PLATFORM_ALERT } from './mutations';

const update = softDeleteInCache({
  fragment: PLATFORM_ALERT_FRAGMENT,
  fragmentName: 'PlatformAlert',
  typeName: 'PlatformAlertType',
  mutationName: 'deletePlatformAlert',
});

export const DeletePlatformAlertModal = ({ hide, args: { alert } }) => {
  const toast = useToast();
  const [deletePlatformAlert] = useMutation(DELETE_PLATFORM_ALERT, { update });

  const onSubmit = useCallback(async () => {
    await deletePlatformAlert({ variables: { alertId: alert.id } });
    toast.success('Deleted platform alert');
  }, [toast, deletePlatformAlert, alert]);

  const text = alert.text.length < 50 ? alert.text : `${alert.text.slice(0, 30)}...`;
  return <DeleteModal onSubmit={onSubmit} hide={hide} name={text} />;
};

DeletePlatformAlertModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({ alert: PropTypes.shape({}) }),
};
