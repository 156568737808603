import cx from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'frontend/components';

import DetailedDialogueResult from './DetailedDialogueResult';
import styles from '../Evaluation.scss';

const ResultsForDialogue = ({ title, results, dialogueLink }) => {
  const [expanded, setExpanded] = useState(false);
  const successRate = results.filter(({ predictorMatchesId }) => predictorMatchesId).length / results.length;

  const dialogueStatus = cx(styles.status, {
    [styles.statusAll]: successRate === 1,
    [styles.statusSome]: successRate > 0 && successRate !== 1,
    [styles.statusNone]: successRate === 0,
  });
  const dialogueHeader = cx(styles.dialogueHeader, {
    [styles.dialogueHeaderExpanded]: expanded,
  });

  return (
    <div className={styles.resultForDialogue}>
      <div className={dialogueHeader}>
        <div className={styles.status}>
          <div className={dialogueStatus} />
        </div>
        <div className={styles.passCount}>
          {results.filter(({ predictorMatchesId }) => predictorMatchesId).length}/{results.length}
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.icons}>
          {dialogueLink && (
            <Link to={dialogueLink} target="_blank" alt="go to dialogue in Build">
              <Button>Open dialogue</Button>
            </Link>
          )}
          <Button
            className="m-l-1"
            onClick={(e) => {
              setExpanded(!expanded);
              e.stopPropagation();
            }}
          >
            {expanded ? 'Hide queries' : 'Show queries'}
          </Button>
        </div>
      </div>
      {expanded && (
        <div className={styles.sampleResults}>
          {results.map((result) => (
            <DetailedDialogueResult result={result} key={result.id} />
          ))}
        </div>
      )}
    </div>
  );
};

ResultsForDialogue.propTypes = {
  title: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
  dialogueLink: PropTypes.string,
};

export default ResultsForDialogue;
