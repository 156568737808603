import cx from 'classnames';
import { useCallback } from 'react';

import { Drag, Edit } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import { useSortableItem } from 'frontend/features/Dnd/hooks';
import { getId } from 'frontend/utils';

import styles from './EditableImage.scss';
import Image, { type ImageType } from '../Image/Image';

interface EditableImageProps {
  image: ImageType;
  setSelectedImageId(id: string): void;
  singleImage?: boolean;
  isSorting?: boolean;
  disabled?: boolean;
  selectedImageId?: string;
}

const EditableImage = ({
  image,
  setSelectedImageId,
  selectedImageId,
  singleImage,
  isSorting,
  disabled,
}: EditableImageProps) => {
  const id = getId(image);
  const isSelected = id === selectedImageId;
  const { style, dragAttributes, onPointerDown, setNodeRef } = useSortableItem(image, disabled);

  const onClick = useCallback(
    (event) => {
      if (disabled) return;
      // Stop ImageMeta's useClickOutside from immediately closing again
      event?.nativeEvent?.stopImmediatePropagation?.();
      setSelectedImageId(id);
    },
    [disabled, id, setSelectedImageId],
  );

  const className = cx(styles.imageWithOptions, {
    [styles.imageDragging]: isSorting,
    [styles.imageSingle]: singleImage,
  });

  return (
    <Image
      className={className}
      isSelected={isSelected}
      item={image}
      style={style}
      setNodeRef={setNodeRef}
      dragAttributes={dragAttributes}
    >
      <div className={styles.imageButtons}>
        <button className={styles.imageEditButton} onClick={onClick} type="button">
          <Icon component={Edit} color="primary" />
        </button>
        <Icon component={Drag} color="primary" onPointerDown={onPointerDown} />
      </div>
    </Image>
  );
};

export default EditableImage;
