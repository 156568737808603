import cx from 'classnames';
import React, { useCallback, useRef } from 'react';
import { Field, type FieldRenderProps } from 'react-final-form';

import styles from './ToggleSwitch.scss';

interface SwitchProps extends Omit<FieldRenderProps<string>, 'input'> {
  input: Omit<FieldRenderProps<string>['input'], 'onBlur' | 'onChange' | 'onFocus'>;
  className?: string;
  status?: [string, string] | string;

  onClick?(event?: React.MouseEvent<HTMLSpanElement>): void;

  values?: [string | boolean | number, string | boolean | number];
  disabled?: boolean;
  isKindlyAdmin?: boolean;
}

const Switch = ({
  input: { value, name },
  className,
  status = ['', ''],
  onClick,
  values,
  disabled = false,
  isKindlyAdmin,
}: SwitchProps) => {
  let isChecked = value;

  if (Array.isArray(values)) {
    isChecked = values.findIndex((val) => val === value) === 1;
  }

  const classNames = cx(styles.switch, {
    [styles.switchOn]: isChecked,
    [styles.switchOff]: !isChecked,
    [styles.disabled]: disabled,
  });

  let labelValue = status;
  if (Array.isArray(status) && status.length > 1) labelValue = isChecked ? status[0] : status[1];

  const handleClick = useCallback((event) => typeof onClick === 'function' && onClick(event), [onClick]);

  return (
    <div
      className={cx(styles.switchWrapper, {
        kindlyAdminManager: isKindlyAdmin,
      })}
    >
      <span
        className={cx(classNames, className)}
        role="switch"
        aria-checked={Boolean(isChecked)}
        onClick={handleClick}
        onKeyDown={(e) => e.key === 'Enter' && onClick?.()}
        tabIndex={0}
      />
      {!!labelValue && (
        <label
          htmlFor={`ToggleSwitch-${name}`}
          className={cx(styles.status, {
            [styles.statusDisabled]: disabled,
          })}
        >
          {labelValue}
        </label>
      )}
    </div>
  );
};

interface ToggleSwitchProps {
  status?: string[];
  name: string;
  className?: string;

  onClick?(): void;

  defaultValue?: boolean;
  initialValue?: boolean;

  format?(val): string;

  parse?(val): string | boolean | number | undefined;

  disabled?: boolean;
  values?: [string | boolean | number, string | boolean | number];
  'data-testid'?: string;
  isKindlyAdmin?: boolean;
}

/** Toggle to use inside React Final Form. */
const ToggleSwitch = ({
  name,
  status,
  className,
  onClick,
  defaultValue,
  initialValue,
  disabled = false,
  'data-testid': dataTestid,
  format,
  values,
  parse,
  isKindlyAdmin,
}: ToggleSwitchProps) => {
  const inputRef = useRef<HTMLInputElement | null>();

  return (
    <>
      <Field
        ref={inputRef}
        name={name}
        value={inputRef.current?.value === values?.[0] ? values?.[1] : values?.[0]}
        defaultValue={defaultValue}
        initialValue={initialValue}
        className={styles.checkbox}
        type="checkbox"
        id={`ToggleSwitch-${name}`}
        component="input"
        disabled={disabled}
        format={format}
        parse={parse}
      />
      <label className={styles.label} htmlFor={`ToggleSwitch-${name}`} data-testid={dataTestid}>
        <Field
          name={name}
          className={className}
          component={Switch}
          status={status}
          onClick={onClick}
          disabled={disabled}
          format={format}
          parse={parse}
          values={values}
          isKindlyAdmin={isKindlyAdmin}
        />
      </label>
    </>
  );
};

ToggleSwitch.Switch = Switch;
export default ToggleSwitch;
