import PropTypes from 'prop-types';

import { Equals } from 'frontend/propTypes';

export default PropTypes.shape({
  id: PropTypes.string,
  tempId: PropTypes.string,
  name: PropTypes.string.isRequired,
  hue: PropTypes.number.isRequired,
  languageCode: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  __typename: Equals('EntityType'),
});
