import { gql } from '@apollo/client';

const botAdminEntry = gql`
  fragment BotAdminEntry on BotType {
    id
    name
    projectName
    avatarUrl
    archivedAt
    isArchived
    organization {
      id
      name
    }
    languages {
      id
      code
    }
  }
`;
export default gql`
  query BotSearch($query: String, $filters: BotFiltersInput, $first: Int, $after: Int, $sort: [String]) {
    botSearch(query: $query, filters: $filters, first: $first, after: $after, sort: $sort) {
      id
      hits
      objects {
        ...BotAdminEntry
      }
    }
  }
  ${botAdminEntry}
`;
