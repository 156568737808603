import { sum } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { LineChart } from 'frontend/components/Stats';
import { StatsPanel } from 'frontend/features/Analytics/components';
import { useSageData } from 'frontend/features/Analytics/hooks';
import { sageSeriesToLineData } from 'frontend/features/Analytics/utils/sage';
import { formatNumber } from 'frontend/utils';

const Sessions = ({ filters, to, scope, priority, isPreview }) => {
  const { loading, error, data } = useSageData(scope, '/sessions', filters, {
    priority,
    apiVersion: 'v2',
  });
  const lineData = useMemo(() => sageSeriesToLineData(data, undefined, 'sessions'), [data]);

  const formatLabelCallback = useCallback((dataPoint) => {
    const summed = sum(dataPoint.data.map((dp) => dp.y));

    return `Total sessions: ${formatNumber(summed)}`;
  }, []);

  return (
    <StatsPanel
      loading={loading}
      error={error}
      to={to}
      title="Unique user sessions"
      subtitle={`Number of ${filters.type || 'conversation and nudge'} sessions`}
    >
      {lineData && (
        <LineChart
          data={[lineData]}
          granularity={filters.granularity}
          isPreview={isPreview}
          formatLabelCallback={formatLabelCallback}
          csvFilename="sessions.csv"
        />
      )}
    </StatsPanel>
  );
};

Sessions.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  to: PropTypes.string,
  scope: PropTypes.string.isRequired,
  priority: PropTypes.number,
  isPreview: PropTypes.bool,
};

export default Sessions;
