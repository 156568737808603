import cx from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { ImageCarouselType } from 'frontend/api/generated';
import { Close, Fullscreen } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import { typeToIcon as imageButtonTypeToIcon } from 'frontend/components/ImageCarousel/components/FakeButton/FakeButton';

import styles from './ImageCarouselPreview.scss';

const IMAGE_WIDTH = Object.freeze({
  1000: '250',
  800: '178',
  600: '124',
});

interface Props {
  imageCarousel: Partial<ImageCarouselType>;
  isButtonClick?: boolean;
  clickedButtonId?: string;
}

const ImageCarouselPreview = ({ imageCarousel, isButtonClick, clickedButtonId }: Props) => {
  const [imageCarouselIndex, setImageCarouselIndex] = useState(0);
  const [fullscreenImage, setFullscreenImage] = useState<null | string>(null);
  const nImages = imageCarousel.images?.length || 0;
  const currentImage = useMemo(() => imageCarousel.images?.[imageCarouselIndex], [imageCarousel, imageCarouselIndex]);
  const imageWidth = IMAGE_WIDTH[imageCarousel.size || 1000];
  const [imageHeight, setImageHeight] = useState(imageWidth);

  const next = useCallback(() => {
    setImageCarouselIndex((imageCarouselIndex + 1) % nImages);
  }, [imageCarouselIndex, nImages, setImageCarouselIndex]);

  useEffect(() => {
    let isMounted = true;

    if ((imageCarousel.images?.length || 0) > 0) {
      const firstImage = new Image();
      firstImage.src = imageCarousel?.images?.[0]?.imageUrl || '';
      firstImage.onload = () => {
        const widthRatio = firstImage.width / imageWidth;
        const newHeight = firstImage.height / widthRatio;
        if (isMounted) setImageHeight(newHeight.toString());
      };
    }

    return () => {
      isMounted = false;
    };
  }, [imageCarousel, setImageHeight, imageWidth]);

  if (!currentImage) return null;

  const carousel = nImages > 1;
  const title = currentImage?.title ?? '';
  const description = currentImage?.description ?? '';
  const hasCaptions = Boolean(title?.trim() || description?.trim());
  const imageButtons = currentImage.buttons ?? [];

  return (
    <div
      className={cx(styles.carouselWrapper, {
        [styles.carouselWrapperButtonClick]: isButtonClick,
      })}
    >
      <div
        className={cx(styles.imagePreview, {
          [styles.imagePreviewCarousel]: carousel,
          [styles.hasText]: hasCaptions,
          [styles.imageSmall]: imageCarousel.size === 600,
          [styles.imageMedium]: imageCarousel.size === 800,
          [styles.imageLarge]: imageCarousel.size === 1000,
        })}
        title={carousel ? 'Click to see next image' : ''}
        onClick={next}
        onKeyDown={(e) => e.key === 'Enter' && next()}
        role="button"
        tabIndex={0}
      >
        <div className={styles.imageWrapper}>
          <Button
            className={styles.imageEnlarge}
            onClick={(e) => {
              e.stopPropagation();
              setFullscreenImage(currentImage.imageUrl);
            }}
          >
            <Icon component={Fullscreen} width={16} height={16} color="white" />
          </Button>
          <img
            src={currentImage.imageUrl}
            alt="User upload"
            style={{
              width: `${imageWidth}px`,
              height: `${imageHeight}px`,
            }}
          />
        </div>
        {hasCaptions && (
          <div className={styles.imageCaptions}>
            {!!title && (
              <h4 className={styles.title} translate="no">
                {title}
              </h4>
            )}
            {!!description && (
              <div className={styles.description} translate="no">
                {description}
              </div>
            )}
          </div>
        )}
        {imageCarousel.size !== 600 &&
          imageButtons.map((button) => (
            <div
              key={button.id}
              className={cx(styles.imageButton, {
                [styles.imageButtonClicked]: clickedButtonId === button.id,
              })}
            >
              <span className={styles.imageButtonLabel}>{button.label}</span>
              <Icon component={imageButtonTypeToIcon[button.buttonType]} />
            </div>
          ))}
      </div>
      {fullscreenImage && (
        <div className={styles.imageFullScreen}>
          <div className={styles.imagFullScreenContent}>
            <Button
              className={styles.imageEnlarge}
              onClick={(e) => {
                e.stopPropagation();
                setFullscreenImage(null);
              }}
            >
              <Icon component={Close} width={16} height={16} color="white" />
            </Button>
            <img src={currentImage.imageUrl} alt="User upload" />
          </div>
        </div>
      )}
      {!isButtonClick && nImages > 1 && (
        <div
          className={styles.imageCardPlaceholder}
          style={{
            maxWidth: `${imageWidth}px`,
          }}
        >
          <div className={styles.imagePlaceholder}>
            <div className={styles.imagePlaceholderNumber}>{nImages - 1}+</div>
          </div>
          <div>
            {!!title && <div className={styles.placeholderTitle} />}
            {!!description && <div className={styles.placeholderDescription} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCarouselPreview;
