import { gql } from '@apollo/client';

export default gql`
  query IsDialogueFollowupTo($botId: ID, $skillId: ID, $parentId: UUID!, $dialogueId: UUID!) {
    dialogue(botId: $botId, skillId: $skillId, id: $dialogueId) {
      id
      isFollowupTo(dialogueId: $parentId)
    }
  }
`;
