import { isArray, mergeWith } from 'lodash';

// eslint-disable-next-line consistent-return
const replaceLists = (originalItem, newItem) => {
  if (isArray(originalItem) && isArray(newItem)) {
    return newItem;
  }
};

export default (object1, object2) => mergeWith({}, object1, object2, replaceLists);
