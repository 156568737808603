import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Loader, PageContent, Panel } from 'frontend/components';
import { BackButton } from 'frontend/features/Admin/components';
import { useMarkdownToHtml } from 'frontend/hooks';
import { friendlyTimestamp } from 'frontend/utils';

import { CHANGELOG_ENTRY_FRAGMENT } from './graphql';
import styles from './styles.scss';

const GET_CHANGELOG_ENTRY = gql`
  query GetChangelogEntry($entryId: ID!) {
    changelogEntry(entryId: $entryId) {
      ...ChangelogEntry
    }
  }
  ${CHANGELOG_ENTRY_FRAGMENT}
`;
const ChangelogEntryDetail = () => {
  const { entryId } = useParams();
  const { data, loading } = useQuery(GET_CHANGELOG_ENTRY, { variables: { entryId } });
  const { html: detailsHtml } = useMarkdownToHtml(data?.changelogEntry?.markdownDetails || '');

  if (loading) {
    return <Loader />;
  }

  const entry = data?.changelogEntry;
  return (
    <>
      <BackButton />
      <PageContent className="p-t-0">
        <Panel className={styles.newsItem}>
          <h5 className={styles.date}>{friendlyTimestamp(entry.created, { force: 'full' })}</h5>
          <h3 className={styles.title}>{entry.title}</h3>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: detailsHtml,
            }}
          />
        </Panel>
      </PageContent>
    </>
  );
};

export default ChangelogEntryDetail;
