import type { Granularity } from 'frontend/features/Analytics/types';
import { useUrlSearchParams } from 'frontend/hooks';

import { removeEmptyValues } from '../utils';

const filterToUrlParams = (granularity) => removeEmptyValues({ granularity });

const useGranularity = (initial: Granularity = 'day') => {
  const [{ granularity }] = useUrlSearchParams({ default: { granularity: initial } });
  return [granularity, filterToUrlParams] as const;
};

export default useGranularity;
