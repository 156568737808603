import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { OrganizationLanguagesDocument } from 'frontend/api/generated';
import { useBotLanguages } from 'frontend/hooks';

export default function useInboxLanguages() {
  const { organizationId } = useParams();
  const {
    loading: botLanguagesLoading,
    languages: botLanguages,
    languagesAndVariants: botLanguagesAndVariants,
    languageNameMap: botLanguageNameMap,
    languageNameMapVariants: botLanguageNameMapVariants,
  } = useBotLanguages({ ignoreNoBot: true });

  // There is a bug here, tho we prolly don't need to fix it
  // the languages is expecting an active property, which is part of the LanguageType, but it expects a botId, which doesn't fit here
  // Noticed when I tried to convert this to typescript
  // Prolly we can not care since if this is going away, the entire query will be removed, so those the problem
  const { data, loading: organizationLanguagesLoading } = useQuery(OrganizationLanguagesDocument, {
    variables: { organizationId },
    skip: !organizationId,
  });

  const loading = botLanguagesLoading || organizationLanguagesLoading;

  const languages = useMemo(() => {
    if (loading) return [];

    const allLanguages = organizationId ? data?.organizationLanguages : botLanguages;
    return (allLanguages ?? []).filter(({ active }) => organizationId || active);
  }, [botLanguages, data, loading, organizationId]);

  const languagesAndVariants = useMemo(() => {
    if (loading) return [];

    return organizationId ? data?.organizationLanguages : botLanguagesAndVariants;
  }, [botLanguagesAndVariants, data, loading, organizationId]);

  const languageNameMap = useMemo(() => {
    if (!organizationId) return botLanguageNameMap;

    return (languages || []).reduce((nameMap, baseLanguage) => {
      const baseLanguageCode = baseLanguage.code;
      nameMap[baseLanguageCode] = baseLanguage.name;
      return nameMap;
    }, {});
  }, [botLanguageNameMap, languages, organizationId]);

  const languageNameMapVariants = useMemo(() => {
    if (!organizationId) return botLanguageNameMapVariants;

    return (languagesAndVariants || []).reduce((nameMap, variantOrBaseLanguage) => {
      const variantOrBaseLanguageCode = variantOrBaseLanguage.code;
      nameMap[variantOrBaseLanguageCode] = variantOrBaseLanguage.name;
      return nameMap;
    }, {});
  }, [botLanguageNameMapVariants, languagesAndVariants, organizationId]);

  return {
    languages,
    languagesAndVariants,
    languageNameMap,
    languageNameMapVariants,
    loading,
  };
}
