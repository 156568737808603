import cx from 'classnames';
import { get } from 'lodash';
import React from 'react';
import type { FieldValues, Path, RegisterOptions, UseFormReturn } from 'react-hook-form';

import ShakeDiv from 'frontend/components/ShakeDiv';
import { FIELD_COLOR } from 'frontend/constants';
import * as rhf from 'frontend/constants/rhf';

import styles from './Select.scss';

interface SelectProps<T extends FieldValues> {
  /** The RHF field name. */
  name: Path<T>;
  /** The RHF methods returned by `useForm()`. */
  rhfMethods: UseFormReturn<T>;
  /* The RHF register options. */
  rhfOptions?: RegisterOptions<T>;
  'data-testid'?: string;
  children?: React.ReactNode;
  classNames?: {
    select?: string;
    label?: string;
    wrapper?: string;
  };
  /** The direction of the text in the select. */
  direction?: 'ltr' | 'rtl';
  fieldColor?: string;
  label?: string | React.ReactNode;
  labelClassName?: string;
  wrapperClassName?: string;
}

/**
 * Kindly's custom Select component to use inside React Hook Form.
 */
export default function SelectRHF<T extends FieldValues>({
  name,
  rhfMethods,
  'data-testid': dataTestid,
  children,
  classNames = {},
  direction = 'ltr',
  fieldColor = FIELD_COLOR.WHITE,
  label,
  rhfOptions,
}: SelectProps<T>): React.JSX.Element {
  const {
    formState: { errors },
  } = rhfMethods;

  const rhfError = get(errors, name);
  const hasError = Boolean(rhfError);
  const errorMessage =
    (rhfError?.message as string) ||
    rhf.defaultValidationErrors[rhfError?.type as keyof typeof rhf.defaultValidationErrors] ||
    '';

  const classNamesCx = cx(styles.formControl, classNames.select, {
    [styles.formControlError]: hasError,
    [styles.formControlMischka]: fieldColor === FIELD_COLOR.MISCHKA,
  });

  const labelClass = cx(styles.labelWrapper, classNames.label);

  return (
    <>
      {!!label && (
        <div className={labelClass}>
          <label htmlFor={`select-${name}`}>{label}</label>
        </div>
      )}
      <ShakeDiv
        animate={hasError ? 'error' : 'normal'}
        className={cx(styles.arrow, styles.selectWrapper, classNames.wrapper)}
      >
        <select
          className={classNamesCx}
          data-testid={dataTestid}
          dir={direction}
          id={`select-${name}`}
          {...rhfMethods.register(name, rhfOptions)}
        >
          {children}
        </select>
        {hasError && <div className={styles.errorMessage}>{String(errorMessage)}</div>}
      </ShakeDiv>
    </>
  );
}

interface OptionProps {
  value: string | number;
  label: string;
}

const Option = ({ value, label, ...rest }: OptionProps) => (
  <option value={value} key={value} {...rest}>
    {label}
  </option>
);

SelectRHF.Option = Option;
