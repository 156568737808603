import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { useNudges } from 'frontend/features/NudgeEditor/hooks';

import styles from './ProactivityCount.scss';

type ProactivityCountProps = {
  show: 'nudge' | 'push-greeting' | 'global-icons';
};

const ProactivityCount = ({ show }: ProactivityCountProps) => {
  const { loadingNudges, nudges } = useNudges();
  const { loading, initialValues } = useChatbubbleForm();

  if (!loadingNudges && show === 'nudge') {
    return <span className={styles.countContent}>{nudges.length || ''}&nbsp;</span>;
  }

  if (!loading && show === 'push-greeting') {
    return <span className={styles.countContent}>{initialValues.pushGreetings?.length || ''}&nbsp;</span>;
  }

  if (!loading && show === 'global-icons') {
    return <span>{initialValues.globalIcons?.length || ''}</span>;
  }

  return null;
};

export default ProactivityCount;
