import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import type { Tab } from 'frontend/components/Tabs/Tabs';
import { DIALOGUE_TYPES } from 'frontend/constants';
import SmartReplies from 'frontend/features/Build/components/SmartReplies';
import { SlotsTab } from 'frontend/features/Slots/components';
import { useReplySlots } from 'frontend/features/Slots/hooks';
import { useCurrentLanguage } from 'frontend/hooks';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';
import hasLanguage from 'frontend/utils/hasLanguage';

import useCurrentRuleId from './useCurrentRuleId';
import AdvancedFeatures from '../components/AdvancedFeatures';
import Buttons from '../components/Buttons';
import styles from '../components/DialogueFormOutput/DialogueFormOutput.scss';
import SuggestionTab from '../components/DialogueFormOutput/SuggestionTab/SuggestionTab';
import Forms from '../components/Forms';
import ImageCarousel from '../components/ImageCarousel';
import Replies from '../components/Replies';
import VideoEmbed from '../components/VideoEmbed';
import { DEFAULT_RULE_ID } from '../constants';
import type { BuildFormType } from '../propTypes/BuildFormType';
import filterDialogueOutputByLanguageAndRule from '../utils/filterDialogueOutputByLanguageAndRule';

const TABS = {
  REPLIES: 'Reply',
  SMART_REPLIES: 'Smart Reply',
  BUTTONS: 'Button',
  IMAGES: 'Image',
  VIDEOS: 'Video',
  ADVANCED: 'Advanced Options',
  SUGGESTION: 'Suggestion',
  SLOTS: 'Slot',
  FORMS: 'Form',
} as const;
export type TabsShown = Record<keyof typeof TABS, Tab | null>;
export type TabName = Lowercase<(typeof TABS)[keyof typeof TABS]>;

function getTabFromCurrentRuleId(tabType: (typeof TABS)[keyof typeof TABS], currentRuleId: string | undefined) {
  switch (tabType) {
    case TABS.REPLIES:
      return {
        title: TABS.REPLIES,
        errorNames: ['replies', 'modReplies'].map((errorName) =>
          currentRuleId ? `${errorName}.${currentRuleId}` : `${errorName}.${DEFAULT_RULE_ID}`,
        ),
        key: 'dialogue-replies',
        contentClassName: styles.tabContent,
        component: Replies,
      };
    case TABS.SMART_REPLIES:
      return {
        title: TABS.SMART_REPLIES,
        errorNames: [currentRuleId ? `smartReplies.${currentRuleId}` : `smartReplies.${DEFAULT_RULE_ID}`],
        key: 'dialogue-smart-replies',
        contentClassName: styles.tabContent,
        component: SmartReplies,
      };
    case TABS.BUTTONS:
      return {
        title: TABS.BUTTONS,
        errorNames: ['buttons', 'modButtons'].map((errorName) =>
          currentRuleId ? `${errorName}.${currentRuleId}` : `${errorName}.${DEFAULT_RULE_ID}`,
        ),
        key: 'dialogue-buttons',
        contentClassName: styles.tabContent,
        component: Buttons,
      };
    case TABS.IMAGES:
      return {
        title: TABS.IMAGES,
        errorNames: [],
        key: 'dialogue-images',
        contentClassName: styles.tabContent,
        component: ImageCarousel,
      };
    case TABS.VIDEOS:
      return {
        title: TABS.VIDEOS,
        errorNames: [],
        key: 'dialogue-videos',
        contentClassName: styles.tabContent,
        component: VideoEmbed,
      };
    case TABS.ADVANCED:
      return {
        title: TABS.ADVANCED,
        errorNames: ['webhookUrls', 'modWebhookUrls'],
        key: 'dialogue-advanced-features',
        contentClassName: styles.tabContent,
        component: AdvancedFeatures,
      };
    case TABS.SUGGESTION:
      return {
        title: TABS.SUGGESTION,
        errorNames: ['suggestions'],
        key: 'dialogue-suggestion',
        contentClassName: styles.tabContent,
        component: SuggestionTab,
      };
    case TABS.SLOTS:
      return {
        title: TABS.SLOTS,
        errorNames: ['slots'],
        key: 'dialogue-slots',
        contentClassName: styles.tabContent,
        component: SlotsTab,
      };
    case TABS.FORMS:
      return {
        title: TABS.FORMS,
        errorNames: [currentRuleId ? `BuildForms.${currentRuleId}` : `BuildForms.${DEFAULT_RULE_ID}`],
        key: 'dialogue-forms',
        contentClassName: styles.tabContent,
        component: Forms,
      };
    default:
      throw new Error(`Unknown tab type: ${tabType}`);
  }
}

/** This custom hook abstracts away the logic that decides which tabs are to show in the Output view.
 *
 * The _extra tabs_ selection is cleaned when the form is submitting or the pathname changes. We need these
 * extra tabs state to imperatively show the tabs that are not yet added in the form.
 *
 * @return an object with the following properties:
 * - `tabsShown`, a map of tabs to show
 * - `setTabsToShowExtra`, a function to imperatively show certain tabs
 */
const useDialogueOutputs = ({
  isModDialogue,
  buildFormType,
  shouldShowSmartReplies = false,
}: {
  isModDialogue?: boolean;
  buildFormType?: BuildFormType | null;
  shouldShowSmartReplies?: boolean;
}) => {
  const [tabsToShowExtra, setTabsToShowExtra] = useState<TabName[]>([]);
  const { pathname } = useLocation();

  const isFeatureEnabled = useFeatureFlags();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const currentRuleId = useCurrentRuleId();

  const { values: formValues, submitting } = useFormState({ subscription: { values: true, submitting: true } });

  const values: Record<string, any> = filterDialogueOutputByLanguageAndRule(
    selectedLanguage,
    currentRuleId,
    formValues,
  );
  const replies = (values.replies || []).filter(hasLanguage(selectedLanguage));
  const replySlots = useReplySlots({ replies });

  /* When form is submitting or changing pathname, reset the user's choice for extra shown tabs. */
  useEffect(() => {
    setTabsToShowExtra([]);
  }, [submitting, pathname]);

  const slotsEnabled = isFeatureEnabled('slots');
  const suggestionsEnabled = isFeatureEnabled('suggestions');

  const hasReplies = isModDialogue
    ? values.mod?.modReplies?.length > 0 || values.modReplies?.length > 0
    : replies?.length > 0;
  const hasSmartReply = Boolean(values.smartReplies?.length);

  const hasButtons = isModDialogue ? values.mod?.modButtons?.length > 0 : values.buttons?.length > 0;

  const hasVideos = (isModDialogue ? values.modVideoSources : values.videoSources)?.[currentRuleId || DEFAULT_RULE_ID];

  const hasAdvancedSettings = isModDialogue
    ? values.mod?.modWebhookUrl?.[selectedLanguage]?.[currentRuleId || DEFAULT_RULE_ID] || values.mod?.modPluginInstance
    : !!values.webhookUrls?.[selectedLanguage]?.[currentRuleId || DEFAULT_RULE_ID] ||
      !!values.plugininstance ||
      Object.keys(values.urlTrigger || {}).length > 0 ||
      !!Object.keys(values.context?.[selectedLanguage]?.[currentRuleId || DEFAULT_RULE_ID] || {}).length;

  const hasAdvancedOptions =
    values.advancedOptions?.[selectedLanguage]?.[currentRuleId || DEFAULT_RULE_ID]?.chatbubbleHideInputField ||
    values.advancedOptions?.[selectedLanguage]?.[currentRuleId || DEFAULT_RULE_ID]?.handoverRequestWhenTriggered;

  const hasSuggestion = !!values.dialogueFallback;
  const hasSlots = !!replySlots?.length;
  const hasOutputSlot = slotsEnabled && hasSlots && !!values.outputSlots?.length;
  const hasRules = !!values.rules?.length;
  const hasForms = values.buildForms.length > 0;

  const hasImages = (isModDialogue ? values.modImageCarousels : values.imageCarousels)?.length > 0;

  const shouldShowSmartRepliesTab =
    shouldShowSmartReplies && (hasSmartReply || tabsToShowExtra.includes('smart reply'));
  const shouldShowRepliesTab = hasReplies || tabsToShowExtra.includes('reply') || !shouldShowSmartRepliesTab;
  const shouldShowButtonsTab = hasButtons || tabsToShowExtra.includes('button');
  const shouldShowImagesTab = hasImages || tabsToShowExtra.includes('image');
  const shouldShowVideosTab = hasVideos || tabsToShowExtra.includes('video');
  const shouldShowFormsTab = !isModDialogue && (hasForms || tabsToShowExtra.includes('form'));
  const shouldShowAdvancedSettingsTab =
    hasAdvancedSettings || hasAdvancedOptions || tabsToShowExtra.includes('advanced options');

  const shouldShowSuggestionTab =
    (hasSuggestion &&
      !isModDialogue &&
      values.dialogueType === DIALOGUE_TYPES.SAMPLES &&
      buildFormType === 'dialogue' &&
      suggestionsEnabled) ||
    tabsToShowExtra.includes('suggestion');
  const shouldShowSlotsTab = (hasOutputSlot && !isModDialogue && !hasRules) || tabsToShowExtra.includes('slot');

  const getTab = (tabType) => getTabFromCurrentRuleId(tabType, currentRuleId);

  const tabsShown: TabsShown = {
    REPLIES: shouldShowRepliesTab ? getTab(TABS.REPLIES) : null,
    SMART_REPLIES: shouldShowSmartRepliesTab ? getTab(TABS.SMART_REPLIES) : null,
    BUTTONS: shouldShowButtonsTab ? getTab(TABS.BUTTONS) : null,
    IMAGES: shouldShowImagesTab ? getTab(TABS.IMAGES) : null,
    VIDEOS: shouldShowVideosTab ? getTab(TABS.VIDEOS) : null,
    SUGGESTION: shouldShowSuggestionTab ? getTab(TABS.SUGGESTION) : null,
    SLOTS: shouldShowSlotsTab ? getTab(TABS.SLOTS) : null,
    FORMS: shouldShowFormsTab ? getTab(TABS.FORMS) : null,
    ADVANCED: shouldShowAdvancedSettingsTab ? getTab(TABS.ADVANCED) : null,
  };

  return {
    /** Map of tabs to show. */
    tabsShown,
    /** Function to show programmatically a tab. */
    setTabsToShowExtra,
  };
};

export default useDialogueOutputs;
