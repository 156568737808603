import { useQuery } from '@apollo/client';

import { Plus, Trash } from 'frontend/assets/icons';
import { Button, LoaderSwitch, PageContent, Table } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useTabTitle } from 'frontend/hooks';

import styles from './PlatformAlerts.scss';
import { DeletePlatformAlertModal } from './modals';
import { GET_ALL_PLATFORM_ALERTS } from './queries';

const Actions = ({ rowData: alert }) => {
  const [showDeletePlatformAlert] = useModal(DeletePlatformAlertModal);
  return (
    <div className={styles.buttonWrapper}>
      <Button size="small" to={`${alert.id}`}>
        Edit
      </Button>
      <Button
        icon={Trash}
        size="small"
        className={`m-l-sm ${styles.deleteButton}`}
        onClick={() => showDeletePlatformAlert({ alert })}
      >
        Delete
      </Button>
    </div>
  );
};

const columns = [
  { key: 'createdAt' },
  { key: 'published', render: ({ data: published }) => (published ? 'Published' : 'Not published') },
  { key: 'text' },
  { key: 'readMoreUrl' },
  {
    key: 'actions',
    render: Actions,
  },
];

const PlatformAlerts = () => {
  useTabTitle('Platform alerts');
  const { data, loading } = useQuery(GET_ALL_PLATFORM_ALERTS);
  const platformAlerts = data?.platformAlerts?.filter(({ id }) => id) ?? [];

  return (
    <PageContent>
      <LoaderSwitch loading={loading}>
        <Button to="new" icon={Plus} text="New platform alert" color="primary" className={styles.newAlertButton} />
        <div className={`${styles.alertIte} m-t-xl`}>
          <Table data={platformAlerts} columns={columns} />
        </div>
      </LoaderSwitch>
    </PageContent>
  );
};

export default PlatformAlerts;
