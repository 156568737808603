import type { ApolloClient } from '@apollo/client';

import { updateItemInCache } from 'frontend/api/cacheHelpers';
import type { DialogueType } from 'frontend/api/generated';
import LibraryDialogueFragment from 'frontend/features/Library/fragments/LibraryDialogue.fragment.graphql';

export default function updateFollowupsCount({
  client,
  update,
  id,
  variables,
}: {
  client: ApolloClient<unknown>;
  update: number;
  id: string;
  variables: unknown;
}) {
  return updateItemInCache({
    fragment: LibraryDialogueFragment,
    typeName: 'DialogueType',
    fragmentName: 'LibraryDialogue',
    variables,
    update: (dialogue: DialogueType): DialogueType => ({
      ...dialogue,
      numberOfFollowups: (dialogue.numberOfFollowups ?? 0) + update,
    }),
    cache: client,
    id,
  });
}
