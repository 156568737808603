import { useMutation, useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import {
  MyNotificationsDocument,
  MyOrganizationsDocument,
  UpdateProfileNotificationsDocument,
} from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';

import { ALERTERS, DESKTOP_NOTIFICATIONS, ORG_NOTIFICATION_NAMES } from '../../constants';

const NAME_PREFIX = 'org:';

function useProfileNotifications() {
  const toast = useToast();
  const { data, loading: notificationsLoading } = useQuery(MyNotificationsDocument);
  const me = data?.me;
  const [mutateProfileNotifications] = useMutation(UpdateProfileNotificationsDocument, {
    refetchQueries: [MyNotificationsDocument],
  });
  const { loading: organizationsLoading, data: myOrganizations } = useQuery(MyOrganizationsDocument);
  const organizations = myOrganizations?.organizations;
  const organizationNotifications = me?.organizationNotifications;

  const notificationsByOrg = useMemo(
    () =>
      (organizations || []).map((org) => {
        const currentNotifications = (organizationNotifications ?? []).filter((on) => on?.organization?.id === org.id);
        const notifications = Object.entries(ORG_NOTIFICATION_NAMES).map(([notificationName, notificationLabel]) => ({
          value: !currentNotifications.find(({ disabled }) => disabled.includes(notificationName)),
          label: notificationLabel,
          name: `${NAME_PREFIX}${org.id}:notification:${notificationName}`,
        }));
        return {
          ...org,
          notifications,
        };
      }),
    [organizationNotifications, organizations],
  );

  const onSubmit = useCallback(
    async (values) => {
      const desktopNotifications = values.desktopNotifications
        ? DESKTOP_NOTIFICATIONS.ACTIVE
        : DESKTOP_NOTIFICATIONS.INACTIVE;

      const desktopNotificationsInteraction = values.desktopNotificationsInteraction;

      const onsObjectsByOrg = groupBy(
        Object.entries(values)
          .filter(([key]) => key.startsWith(NAME_PREFIX))
          .map(([key, enabled]) => {
            const [, organizationId, , name] = key.split(':');
            return { organizationId, name, enabled };
          }),
        (obj) => obj.organizationId,
      );
      const ons = Object.entries(onsObjectsByOrg).map(([organizationId, objs]) => ({
        organizationId,
        disabled: objs.map(({ enabled, name }) => (enabled ? [] : name!)).flat(),
      }));

      const variables = {
        disabledEmailNotifications: Object.keys(ALERTERS).filter((alerter) => !values[alerter]),
        desktopNotifications,
        desktopNotificationsInteraction,
        organizationNotifications: ons,
      };

      await mutateProfileNotifications({ variables });
      toast.success('Notifications updated');
    },
    [toast, mutateProfileNotifications],
  );

  const initialValues = useMemo(() => {
    const profile = me?.profile;
    const disabledAlerters = profile?.disabledEmailNotifications || [];
    const desktopNotifications = profile?.desktopNotifications === DESKTOP_NOTIFICATIONS.ACTIVE;
    const desktopNotificationsInteraction = profile?.desktopNotificationsInteraction ?? false;

    return {
      desktopNotifications,
      desktopNotificationsInteraction,
      ...Object.assign(
        {},
        ...notificationsByOrg.map((nbo) => nbo.notifications.map((on) => ({ [on.name]: on.value }))).flat(),
      ),
      ...Object.keys(ALERTERS).reduce(
        (enabledAlerters, alerter) => ({
          ...enabledAlerters,
          [alerter]: !disabledAlerters.includes(alerter),
        }),
        {},
      ),
    };
  }, [me, notificationsByOrg]);

  const initialSelection = useMemo(
    () =>
      Object.entries(initialValues)
        .filter(([name]) => name.startsWith(NAME_PREFIX))
        .every(([, value]) => value),
    [initialValues],
  );

  const [orgNotificationSelection, setSelection] = useState(initialSelection);
  const toggleOrgNotifications = useCallback(
    (form) => {
      const { values } = form.getState();
      const fields = Object.entries(values).filter(([name]) => name.startsWith(NAME_PREFIX));
      fields.forEach(([name]) => {
        form.change(name, !orgNotificationSelection);
      });
      setSelection(!orgNotificationSelection);
    },
    [orgNotificationSelection],
  );

  const loading = notificationsLoading || organizationsLoading;

  return {
    loading,
    onSubmit,
    initialValues,
    notificationsByOrg,
    toggleOrgNotifications,
  };
}

export default useProfileNotifications;
