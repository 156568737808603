import { useFormState } from 'react-final-form';

import { Range } from 'frontend/components';
import { pluralize } from 'frontend/utils';

import styles from '../InboxSettings.scss';

const getUnit = (value) => {
  if (value === 0 || value == null) return 'ff'; // Will be displayed as "Off"
  return pluralize(' day', value, { noPluralYEnding: true });
};

const nullToZero = (value) => (value == null ? 0 : value);

const Cleanup = () => {
  const {
    values: { cleanUpRequestedHandoversAfterDays, cleanUpActiveHandoversAfterDays },
  } = useFormState();

  return (
    <div>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Cleanup</h3>
      <p className={`m-b-0 ${styles.helpText}`}>
        Sometimes it can be hard to get an overview when there are a lot of old, inactive chats with handovers requested
        or in progress. Here you can set up automatic cleanup of such chats, meaning that they will be marked as
        cancelled or completed after a number of days without activity.
      </p>
      <label className="m-t-2" htmlFor="cleanUpRequestedHandoversAfterDays">
        Requested handovers
      </label>
      <Range
        id="cleanUpRequestedHandoversAfterDays"
        name="cleanUpRequestedHandoversAfterDays"
        min={0}
        max={30}
        step={1}
        format={nullToZero}
        unit={getUnit(cleanUpRequestedHandoversAfterDays)}
      />
      <label className="m-t-1" htmlFor="cleanUpActiveHandoversAfterDays">
        Handovers in progress
      </label>
      <Range
        id="cleanUpActiveHandoversAfterDays"
        name="cleanUpActiveHandoversAfterDays"
        min={0}
        max={30}
        step={1}
        format={nullToZero}
        unit={getUnit(cleanUpActiveHandoversAfterDays)}
      />
    </div>
  );
};

export default Cleanup;
