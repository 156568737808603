import { gql } from '@apollo/client';

import botLanguageSettingsFragment from 'frontend/api/fragments/BotLanguageSettings.fragment.graphql';

export default gql`
  mutation UpdateBotLanguages(
    $botId: ID!
    $selectLanguageGreeting: String!
    $languageCodes: [String!]!
    $activeLanguageCodes: [String!]!
    $activeLanguageVariants: [String!]
    $languageVariants: [String!]
    $convertToVariant: GenericScalar
    $primaryLanguage: String!
  ) {
    updateBotLanguages(
      botId: $botId
      selectLanguageGreeting: $selectLanguageGreeting
      languageCodes: $languageCodes
      activeLanguageCodes: $activeLanguageCodes
      activeLanguageVariants: $activeLanguageVariants
      languageVariants: $languageVariants
      convertToVariant: $convertToVariant
      primaryLanguage: $primaryLanguage
    ) {
      ...BotLanguageSettings
    }
  }
  ${botLanguageSettingsFragment}
`;
