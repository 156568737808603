import { defineAction } from 'redux-define';

const INBOX = defineAction('inbox');

export const SET_HANDOVER_MESSAGES = INBOX.defineAction('SET_HANDOVER_MESSAGES');
export const SET_SCROLL_HEIGHT = INBOX.defineAction('SET_SCROLL_HEIGHT');

export const setHandoverMessages = (chatId, message) => ({
  type: SET_HANDOVER_MESSAGES.ACTION,
  data: {
    chatId,
    message,
  },
});

export const setScrollHeight = (scrollHeight) => ({
  type: SET_SCROLL_HEIGHT.ACTION,
  scrollHeight,
});

export const initialState = {
  /** Inbox metadata */
  scrollHeight: 0,

  /** Inbox filters */
  handoverMessages: {},
  botFilter: [],

  takeoverMessages: null,
};

export const AGENT_VIEW_FILTERS = {
  MY_TICKETS: 'MY_TICKETS',
  UNASSIGNED: 'UNASSIGNED',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HANDOVER_MESSAGES.ACTION: {
      const handoverMessages = {
        ...(state.handoverMessages || {}),
        [action.data.chatId]: action.data.message,
      };
      return { ...state, handoverMessages };
    }
    case SET_SCROLL_HEIGHT.ACTION:
      return {
        ...state,
        scrollHeight: action.scrollHeight,
      };
    default:
      return state;
  }
};

export const CONTACT_DETAILS_STATUS = Object.freeze({
  requested: 'requested',
  done: 'done',
});
