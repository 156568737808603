import styles from '../../Invitation.scss';
import type { Invitation } from '../../propTypes';

interface Props {
  invitation: Invitation;
}

export default function Info({ invitation }: Props) {
  return (
    <div className={styles.collaboratorInfo}>
      <h1 className={styles.title}>Invitation to organization</h1>
      <p className={styles.description}>
        You have been invited by <strong>{invitation.inviter_email}</strong> to join{' '}
        <strong>{invitation.organization.name}</strong>.
      </p>
    </div>
  );
}
