import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import {
  type DialogueCandidateState,
  type DialogueCandidateType,
  DialogueCandidatesPaginatedDocument,
} from 'frontend/api/generated';
import { LinkBreak } from 'frontend/assets/icons';
import { Button, EmptyState, InfoButton, Table } from 'frontend/components';
import { Section } from 'frontend/features/BotImprovements/components';
import { AboutFeature } from 'frontend/features/BotImprovements/modals';
import { useColumns } from 'frontend/features/BuildDashboard/components/BuildResources/hooks';
import { useModal } from 'frontend/features/Modals';
import { useCurrentLanguage } from 'frontend/hooks';

import styles from './DialogueCandidates.scss';
import ActionBy from './components/ActionBy/ActionBy';
import CreatedDialogue from './components/CreatedDialogue/CreatedDialogue';
import Occurrences from './components/Occurrences/Occurrences';
import SingleDialogueCandidate from './components/SingleDialogueCandidate/SingleDialogueCandidate';
import UpdatedAt from './components/UpdatedAt/UpdatedAt';
import { reducer } from './reducers/DialogueCandidatesReducer';

const AboutFeatureText = (
  <p>
    Dialogue candidates are created from frequently occurring types of user questions and can be either questions that
    the bot could or couldn&apos;t reply to.
    <br />
    Adding those candidates will make the scope of the bot broader and enable more complex replies in frequently
    occurring questions, improving user experience and accelerating bot reply time.
  </p>
);

export const ordering = {
  occurrences: ['occurrences'],
  actionBy: ['action_by__first_name', 'action_by__last_name'],
  updatedAt: ['updated_at'],
} as const;

const columnFieldPerState: Record<DialogueCandidateState, string[][]> = {
  NEW: [
    ['occurrences', 'generatedIntent'],
    ['Message count', 'Suggested candidate'],
  ],
  ACCEPTED: [
    ['updatedAt', 'actionBy', 'createdDialogue'],
    ['Date accepted', 'Added by', 'Intent'],
  ],
  REJECTED: [
    ['occurrences', 'generatedIntent'],
    ['Message count', 'Suggested candidate'],
  ],
};

const tableGridTemplateColumnsPerState: Record<DialogueCandidateState, string> = {
  NEW: 'max-content auto',
  ACCEPTED: 'auto auto auto',
  REJECTED: 'max-content auto',
};

const stateToHumanReadable: Record<DialogueCandidateState, string> = {
  NEW: 'New',
  ACCEPTED: 'Added',
  REJECTED: 'Archived',
};

const BuildRenderCreatedDialogueForLanguageAndBotId = (language: string | undefined, botId: string | undefined) =>
  function renderCreatedDialogue({ rowData }: { rowData: DialogueCandidateType }) {
    if (!language || !botId) return null;
    return <CreatedDialogue rowData={rowData} botId={botId} language={language} />;
  };

const BuildRenderSingleDialogueCandidate = (onDialogueUpdate: () => void) =>
  function renderSingleDialogueCandidate({ rowData }: { rowData: DialogueCandidateType }) {
    return <SingleDialogueCandidate rowData={rowData} onDialogueUpdate={onDialogueUpdate} />;
  };

const DialogueCandidates = () => {
  const { botId } = useParams();

  const [{ orderBy, orderDescending, dialogueCandidateState, page }, dispatch] = useReducer(reducer, {
    orderBy: 'occurrences',
    orderDescending: true,
    dialogueCandidateState: 'NEW',
    page: 1,
  });

  const { data, loading } = useQuery(DialogueCandidatesPaginatedDocument, {
    variables: {
      botId: botId!,
      page,
      pageSize: 10,
      states: [dialogueCandidateState],
      orderBy: ordering[orderBy].map((field) => (orderDescending ? `-${field}` : field)),
    },
  });

  const [{ selectedLanguage }] = useCurrentLanguage();

  const [showAboutFeature] = useModal(AboutFeature);

  const dialogueCandidates = data?.dialogueCandidatesPaginated?.objects ?? [];

  // This function gets triggered before the data is updated.
  // It will rollback the page if the last item's state is changed.
  const onDialogueUpdate = () => {
    if (page > 1 && dialogueCandidates.length === 1) {
      dispatch({ type: 'setPage', page: page - 1 });
    }
  };

  const columns = useColumns({
    columnFields: columnFieldPerState[dialogueCandidateState][0]!,
    columnLabels: columnFieldPerState[dialogueCandidateState][1],
    orderByField: orderBy,
    setOrderByField: (orderByField) => dispatch({ type: 'setOrderBy', orderBy: orderByField as keyof typeof ordering }),
    descending: orderDescending,
    setDescending: (descending) => dispatch({ type: 'setOrderDescending', orderDescending: descending }),
    orderedColumns: Object.keys(ordering),
    renderMap: {
      occurrences: Occurrences,
      generatedIntent: BuildRenderSingleDialogueCandidate(onDialogueUpdate),
      createdDialogue: BuildRenderCreatedDialogueForLanguageAndBotId(selectedLanguage, botId),
      actionBy: ActionBy,
      updatedAt: UpdatedAt,
    },
  });

  const pagination = useMemo(
    () => ({
      currentPage: page || 0,
      pages: data?.dialogueCandidatesPaginated?.pages || 0,
      setPage: (newPage: number) => dispatch({ type: 'setPage', page: newPage }),
      summary: {
        totalCount: data?.dialogueCandidatesPaginated?.count || 0,
        firstVisible: data?.dialogueCandidatesPaginated?.startIndex || 0,
        lastVisible: data?.dialogueCandidatesPaginated?.endIndex || 0,
      },
    }),
    [data, page],
  );

  return (
    <Section
      className={cx(styles.DialogueCandidateSection, {
        [styles.DialogueCandidateSectionEmpty]: !dialogueCandidates?.length,
      })}
      listContentClassName={styles.DialogueCandidateSectionContent}
      InfoBox={
        <InfoButton
          className={styles.infoButton}
          onClick={() => showAboutFeature({ title: 'About Dialogue candidates', text: AboutFeatureText })}
        />
      }
      title="Dialogue candidates"
      hasData={Boolean(dialogueCandidates?.length)}
    >
      <div>
        <div className={styles.buttonContainer}>
          <Button
            color={dialogueCandidateState === 'NEW' ? 'secondary' : 'transparent'}
            text={stateToHumanReadable.NEW}
            onClick={() =>
              dispatch({
                type: 'setDialogueCandidateState',
                dialogueCandidateState: 'NEW',
              })
            }
          />
          <Button
            color={dialogueCandidateState === 'REJECTED' ? 'secondary' : 'transparent'}
            text={stateToHumanReadable.REJECTED}
            onClick={() =>
              dispatch({
                type: 'setDialogueCandidateState',
                dialogueCandidateState: 'REJECTED',
              })
            }
          />
          <Button
            color={dialogueCandidateState === 'ACCEPTED' ? 'secondary' : 'transparent'}
            text={stateToHumanReadable.ACCEPTED}
            onClick={() =>
              dispatch({
                type: 'setDialogueCandidateState',
                dialogueCandidateState: 'ACCEPTED',
              })
            }
          />
        </div>
        {!loading && !dialogueCandidates?.length ? (
          <EmptyState
            className={styles.DialogueCandidateEmptyState}
            title={`No ${stateToHumanReadable[dialogueCandidateState].toLowerCase()} dialogue candidates`}
            description="There are currently no dialogue candidates."
            color="blue"
            icon={LinkBreak}
          />
        ) : (
          <Table
            data={dialogueCandidates.filter((candidate): candidate is DialogueCandidateType => Boolean(candidate))}
            columns={columns}
            loading={loading}
            pagination={pagination}
            getGridTemplateColumns={() => tableGridTemplateColumnsPerState[dialogueCandidateState]}
          />
        )}
      </div>
    </Section>
  );
};

export default DialogueCandidates;
