import { entityLimits } from 'frontend/constants';

import { samplesEqual } from './utils';

export const sample = (sampleList) => (currentSample) => {
  const nEntities =
    currentSample && (currentSample.instances || []).filter(({ entity }) => entity && entity.items).length;

  if (nEntities && nEntities > entityLimits.MAX_INSTANCES_PER_SAMPLE) {
    return `Each sample can have up to ${entityLimits.MAX_INSTANCES_PER_SAMPLE} entities`;
  }
  if (currentSample && sampleList.find((otherSample) => samplesEqual(otherSample, currentSample))) {
    return 'Two samples cannot be identical';
  }

  return undefined;
};
