import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';

import { Button } from 'frontend/components';
import { CHANGELOG_ENTRY_FRAGMENT } from 'frontend/features/Admin/views/Changelog/graphql';
import { Modal } from 'frontend/features/Modals';
import { useMarkdownToHtml, useWindowSize } from 'frontend/hooks';

import styles from './ChangelogEntryModal.scss';

const GET_CHANGELOG_ENTRY = gql`
  query GetChangelogEntry($entryId: ID) {
    changelogEntry(entryId: $entryId) {
      ...ChangelogEntry
    }
  }
  ${CHANGELOG_ENTRY_FRAGMENT}
`;
const ChangelogEntryModal = ({ hide, args }) => {
  // If entryId is unset, we get a potentially featured one
  const { data, loading } = useQuery(GET_CHANGELOG_ENTRY, { variables: { entryId: args?.entryId } });
  const { width, height } = useWindowSize();
  const {
    html: detailsHtml,
    loading: detailsLoading,
    called,
  } = useMarkdownToHtml(data?.changelogEntry?.markdownDetails || '');

  if (loading || detailsLoading || !called || !data?.changelogEntry) {
    return null;
  }

  const { title } = data.changelogEntry;

  return (
    <>
      <Modal title={`New feature: ${title}`} footer={false} onOk={hide} hide={hide}>
        <Modal.Content className="p-t-0">
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: detailsHtml,
            }}
          />
          <div className={styles.footerWrap}>
            <Button color="primary" text="Got it" onClick={hide} />
          </div>
        </Modal.Content>
      </Modal>
      <Confetti width={width} height={height} style={{ zIndex: 899 }} />
    </>
  );
};

ChangelogEntryModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    entryId: PropTypes.string,
  }),
};

export default ChangelogEntryModal;
