import cx from 'classnames';

import { DotsVertical, Eye, Language, Trash } from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';

import styles from './table.scss';
import type { VariantAppended } from '../BotLanguagesController';

interface Props {
  data: VariantAppended;
  rowData: { [key: string]: any };
}

function VariantCell({
  data: { text, name, code, active },
  rowData: {
    language: { name: languageName, code: languageCode, active: languageActive, variants: languageVariants },
    shared: {
      addLanguageVariant,
      enableLanguage,
      disableLanguage,
      removeLanguage,
      enableVariant,
      disableVariant,
      removeVariant,
      isKindlyManager,
      makePrimaryLanguage,
      primaryLanguage,
    },
  },
}: Props) {
  const isHeader = !!text;
  const hasLanguageNoVariants = text === '-';
  const isPrimaryLanguage = primaryLanguage === languageCode;

  const overlayOptions: React.ComponentProps<typeof Dropdown.MenuOverlay>['options'] = isHeader
    ? [
        ...(isKindlyManager
          ? [
              {
                icon: Language,
                text: `Add language variant`,
                color: 'pink',
                onClick: () => {
                  addLanguageVariant({
                    name: languageName,
                    code: languageCode,
                    active: languageActive,
                    variants: languageVariants,
                  });
                },
              } as const,
            ]
          : []),
        ...(!isPrimaryLanguage
          ? [
              {
                icon: Language,
                text: 'Make primary language',
                onClick: () => makePrimaryLanguage(languageCode),
              } as const,
            ]
          : []),
        {
          icon: Eye,
          text: `${languageActive ? 'Disable' : 'Enable'} language`,
          onClick: () => (languageActive ? disableLanguage(languageCode) : enableLanguage(languageCode)),
        },
        'separator',
        {
          icon: Trash,
          text: 'Remove',
          color: 'red',
          onClick: () => removeLanguage(languageCode),
        },
      ]
    : [
        {
          icon: Eye,
          text: `${active ? 'Disable' : 'Enable'} variant`,
          onClick: () => (active ? disableVariant(code) : enableVariant(code)),
        },
        'separator',
        {
          icon: Trash,
          text: 'Remove',
          color: 'red',
          onClick: () => removeVariant(code),
        },
      ];

  return (
    <div className={styles.container}>
      <div className={styles.cellTextWrapper}>
        <span
          className={cx({
            [styles.dot]: isHeader && !hasLanguageNoVariants,
            [styles.cellTextDisabled]: !active,
          })}
        >
          {text || name}
        </span>
        {!active && <span className={styles.cellTextDisabledWord}>Disabled</span>}
      </div>

      <Dropdown overlay={<Dropdown.MenuOverlay options={overlayOptions} />} triggerClassName={styles.dropdownTrigger}>
        <Icon component={DotsVertical} className="vertical-middle" />
      </Dropdown>
    </div>
  );
}

export default VariantCell;
