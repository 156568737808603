/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';

import { ChevronDown, Trash } from 'frontend/assets/icons';
import { Button, Dropdown, Icon } from 'frontend/components';

import styles from './ActiveInboxFilters.scss';
import { FILTERS_LIST } from '../../utils/config';
import { checkActiveFilters, convertQueryParamsToPersistedValues } from '../../utils/helpers';

const ActiveInboxFilters = () => {
  const { change, submit, batch } = useForm();
  const [searchParams] = useSearchParams();
  const [dropdownActive, setDropdownActive] = useState(false);

  const { values } = useFormState({ subscription: { values: true } });

  const { filteredValues, hasActiveFilters } = checkActiveFilters(values, searchParams);

  const paramsFiltersKeys = Object.keys(convertQueryParamsToPersistedValues(searchParams.toString()));

  const filteredFilters = FILTERS_LIST.filter(({ key }) =>
    paramsFiltersKeys.some((filterKey) => key === filterKey),
  ).sort((a, b) => paramsFiltersKeys.indexOf(a.key) - paramsFiltersKeys.indexOf(b.key));

  if (!hasActiveFilters) return null;

  // So we keep the unsubmitted filters in the filters list in case they need to be submitted on a later stage
  // and prevent a scenario when toy have unsubmitted filters, but you delete some from the horizontal filters list
  // which submits the form
  const submitFilters = (key: string, isRemove?: boolean) => {
    const diff = filteredValues.filter((filter) => !paramsFiltersKeys.includes(filter));
    const copyMissingFilters = {};
    batch(async () => {
      diff.forEach((diffFilter) => {
        copyMissingFilters[diffFilter] = values[diffFilter];
        change(diffFilter, undefined);
      });
      if (isRemove) {
        change(key, undefined);
      }
      submit();
      Object.entries(copyMissingFilters).forEach(([copyFilterKey, copyFilterValue]) => {
        change(copyFilterKey, copyFilterValue);
      });
    });
  };

  return filteredFilters.map(({ activeTitle, text, icon, component, key }) => (
    <div key={`active-filters-${text}`} className={styles.inboxActiveFilter}>
      <div className={styles.inboxActiveIconWrapper}>
        <Icon width={18} height={18} component={icon} />
      </div>
      <div className={styles.inboxButtonWrapper}>
        <Dropdown
          element={Button}
          elementProps={{
            color: 'white',
            size: 'small',
            icon: ChevronDown,
            iconPosition: 'right',
            className: styles.horizontalDropdownButton,
          }}
          triggerClassName={styles.trigger}
          overlayClassName={styles.filtersDropdownOverlay}
          position="bottom-right"
          onClose={() => {
            submitFilters(key);
            setDropdownActive(false);
          }}
          onToggle={(active) => {
            if (active) {
              setDropdownActive(active);
            }
          }}
          overlay={component}
        >
          {activeTitle(dropdownActive)}
        </Dropdown>
        <Icon
          width={18}
          height={18}
          color="gray"
          hoverColor="orange"
          onClick={() => {
            submitFilters(key, true);
          }}
          component={Trash}
        />
      </div>
    </div>
  ));
};

export default ActiveInboxFilters;
