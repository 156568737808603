import type { DialogueCandidateState } from 'frontend/api/generated';

import type { ordering } from '../DialogueCandidates';

type ReducerState = {
  orderBy: string;
  orderDescending: boolean;
  dialogueCandidateState: DialogueCandidateState;
  page: number;
};
type ReducerAction =
  | {
      type: 'setOrderBy';
      orderBy: keyof typeof ordering;
    }
  | {
      type: 'setOrderDescending';
      orderDescending: boolean;
    }
  | {
      type: 'setDialogueCandidateState';
      dialogueCandidateState: DialogueCandidateState;
    }
  | {
      type: 'setPage';
      page: number;
    };

export function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case 'setOrderBy':
      return {
        ...state,
        orderBy: action.orderBy,
        orderDescending: false,
      };
    case 'setOrderDescending':
      return { ...state, orderDescending: action.orderDescending };
    case 'setDialogueCandidateState':
      return {
        ...state,
        dialogueCandidateState: action.dialogueCandidateState,
        orderBy: action.dialogueCandidateState === 'ACCEPTED' ? 'updatedAt' : 'occurrences',
        orderDescending: true,
        page: 1,
      };
    case 'setPage':
      return { ...state, page: action.page };
    default:
      return state;
  }
}
