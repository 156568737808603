import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import { getOptimisticResponse } from 'frontend/api/cacheHelpers';
import { ActivateSubscriptionDocument, DeactivateSubscriptionDocument } from 'frontend/api/generated';
import { getPrivacySettings } from 'frontend/api/queries';
import { Check, DoubleChevronLeft, Forbid, Trash } from 'frontend/assets/icons';
import type { MenuOverlayOptions } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { useModal } from 'frontend/features/Modals';

import { ResetSubscription, UnsubscribeSkill } from '../../modals';

export const useSubscriptionContextMenu = ({ buildIdObject, subscription }) => {
  const {
    isActive,
    skill: { id: skillId },
  } = subscription;

  const [showUnsubscribeSkill] = useModal(UnsubscribeSkill);
  const [showResetSubscription] = useModal(ResetSubscription);
  const variables = { ...buildIdObject, skillId };
  const refetchQueries = [
    {
      query: getPrivacySettings,
      variables: buildIdObject,
    },
  ];
  const response = { ...subscription, isActive: !isActive };
  const typeName = 'SkillSubscriptionType';

  const [activate] = useMutation(ActivateSubscriptionDocument, {
    variables,
    refetchQueries,
    ...getOptimisticResponse({ mutation: 'ActivateSubscription', response, typeName }),
  });

  const [deactivate] = useMutation(DeactivateSubscriptionDocument, {
    variables,
    refetchQueries,
    ...getOptimisticResponse({ mutation: 'DeactivateSubscription', response, typeName }),
  });

  const contextMenuId = `library-context-menu`;

  const actions = useMemo(
    (): MenuOverlayOptions => [
      ...[
        isActive
          ? { text: 'Deactivate', onClick: deactivate, icon: Forbid }
          : { text: 'Activate', onClick: activate, icon: Check },
      ],
      {
        text: 'Reset skill subscription',
        onClick: () => showResetSubscription({ ...buildIdObject, skillId }),
        icon: DoubleChevronLeft,
      },
      'separator',
      {
        text: 'Remove',
        onClick: () => showUnsubscribeSkill({ ...buildIdObject, subscriptionId: subscription.id, skillId }),
        icon: Trash,
        color: 'red',
      },
    ],
    [
      activate,
      buildIdObject,
      deactivate,
      isActive,
      showResetSubscription,
      showUnsubscribeSkill,
      skillId,
      subscription.id,
    ],
  );

  return { actions, contextMenuId };
};
