import { EditorState, Modifier } from 'draft-js';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Plus } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { EVENT_KEYS } from 'frontend/constants';
import { ChildrenType } from 'frontend/propTypes';

import styles from './Highlight.scss';
import { useComposerState } from '../../hooks';
import { removeHighlightTag } from '../../stateHandlers';

const Highlight = ({ children, buttonText, name, openDropdown, contentState, entityKey, decoratedText }) => {
  const { state, setState } = useComposerState(name);
  const draftEntity = contentState.getEntity(entityKey);
  const { noButton } = draftEntity.getData();
  const [hideButton, setHideButton] = useState(false);
  const buttonRef = useRef();

  useEffect(() => {
    if (noButton || !buttonRef.current) return;
    // Focus button on mount so that dropdown can be opened with keyboard press
    setTimeout(() => buttonRef.current && buttonRef.current.focus());
  }, [noButton]);

  const handleOpenDropdown = useCallback(() => {
    openDropdown({ selectedText: decoratedText });
    setHideButton(true);
  }, [decoratedText, openDropdown]);

  const onKeyDown = useCallback(
    (event) => {
      // Open dropdown
      if (event.key === EVENT_KEYS.ENTER) {
        event.preventDefault();
        event.stopPropagation();
        handleOpenDropdown();
        return;
      }

      const stateWithoutHighlightTag = removeHighlightTag(state);

      // To avoid losing focus in input: remove selection manually
      if ([EVENT_KEYS.BACKSPACE, EVENT_KEYS.DELETE].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
        const content = stateWithoutHighlightTag.getCurrentContent();
        const selection = stateWithoutHighlightTag.getSelection();
        const contentWithoutText = Modifier.replaceText(content, selection, '');
        const stateWithoutText = EditorState.push(stateWithoutHighlightTag, contentWithoutText);
        setState(stateWithoutText);
        return;
      }

      // Remove highlight tag (but not selection), let keyboard event continue
      setState(stateWithoutHighlightTag);
    },
    [handleOpenDropdown, setState, state],
  );

  return (
    <span className={styles.highlight}>
      {children}
      {!noButton && !hideButton && (
        <button
          ref={buttonRef}
          className={styles.highlightButton}
          onClick={handleOpenDropdown}
          onKeyDown={onKeyDown}
          contentEditable={false}
          type="button"
          tabIndex={0}
        >
          <span className={styles.highlightButtonIcon}>
            <Icon component={Plus} color="white" title={buttonText} />
          </span>
          <svg className={styles.highlightButtonTriangle}>
            <polygon points="0 8, 8 0, 16 8" />
          </svg>
          <span>{buttonText}</span>
        </button>
      )}
    </span>
  );
};

Highlight.propTypes = {
  name: PropTypes.string.isRequired,
  children: ChildrenType.isRequired,
  buttonText: PropTypes.string.isRequired,
  decoratedText: PropTypes.string.isRequired,
  openDropdown: PropTypes.func.isRequired,
  contentState: PropTypes.shape({}).isRequired,
  entityKey: PropTypes.string,
};

export default Highlight;
