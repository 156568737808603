import { get, isEqual } from 'lodash';
import { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { composerStateToFormValues } from 'frontend/features/Composer';
import { selectAutoGeneratingEntityItems } from 'frontend/state/dux/build';

export const useEditSampleFinished = ({ name, stopEditing, setError, sampleValidator, remove }) => {
  const { change, getState } = useForm();
  const isGenerating = useSelector(selectAutoGeneratingEntityItems);

  const editSampleFinished = useCallback(
    ({ state, setState, resolveHashtags, stopClickEvent, event, composerRef }) => {
      if (isGenerating) return false;

      const { state: resolvedState, dropdownOpened } = resolveHashtags(state);
      setState(resolvedState);

      if (dropdownOpened) {
        stopClickEvent(event);
        setTimeout(composerRef.current.focus);
        return false;
      }

      // eslint-disable-next-line unused-imports/no-unused-vars
      const { editorState, sampleIndex, match, ...currentSample } = get(getState().values, name);
      const { text, instances } = composerStateToFormValues(resolvedState);

      if (!text) {
        stopEditing();
        remove();
        return true;
      }

      if (text === currentSample.text && isEqual(instances, currentSample.instances)) {
        stopEditing();
        change(name, currentSample);
        return true;
      }

      const updatedSample = { ...currentSample, text, instances };

      const newError = sampleValidator(updatedSample);

      setError(newError);

      if (newError) {
        stopClickEvent(event);
        setTimeout(composerRef.current.focus);
        return false;
      }

      stopEditing();
      change(name, updatedSample);
      return true;
    },
    [change, getState, isGenerating, name, remove, sampleValidator, setError, stopEditing],
  );

  return editSampleFinished;
};

export const useOnSave = (editSampleFinished) => {
  const { submit } = useForm();

  const onSave = useCallback(
    (handlerProps) => {
      const shouldSubmit = editSampleFinished(handlerProps);
      if (shouldSubmit) submit();
    },
    [editSampleFinished, submit],
  );

  return onSave;
};
