import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { updateArrayInCache } from 'frontend/api/cacheHelpers';
import { GetOrganizationDocument } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import { REMOVE_ORGANIZATION_SUBSCRIPTION } from 'frontend/features/Organization/mutations';

const updateCache = (organizationId, subscriptionId) => (cache) =>
  updateArrayInCache({
    variables: { id: organizationId },
    pathToArrayInCache: 'organization.subscriptions',
    query: GetOrganizationDocument,
    cache,
    arrayUpdate: (array) => array.filter(({ id }) => id !== subscriptionId),
  });

const RemoveOrganizationSubscription = ({ args: { subscription, organization }, hide }) => {
  const [mutate] = useMutation(REMOVE_ORGANIZATION_SUBSCRIPTION, {
    update: updateCache(organization.id, subscription.id),
  });

  const onSubmit = useCallback(async () => {
    await mutate({ variables: { subscriptionId: subscription.id } });
  }, [mutate, subscription.id]);

  return (
    <DeleteModal
      onSubmit={onSubmit}
      text={`Remove ${subscription.planName} for ${organization.name}?`}
      titleName={`subscription ${subscription.planName}`}
      action="Remove"
      hide={hide}
    />
  );
};

RemoveOrganizationSubscription.propTypes = {
  args: PropTypes.exact({ subscription: PropTypes.shape({}).isRequired, organization: PropTypes.shape({}).isRequired })
    .isRequired,
  hide: PropTypes.func.isRequired,
};

export default RemoveOrganizationSubscription;
