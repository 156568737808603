export default (cache, { data }) => {
  const dialogueIds = Object.values(data)?.[0]?.dialogueIds;

  if (!Array.isArray(dialogueIds)) {
    console.error('Encountered an issue removing mods from cache');
    return;
  }

  dialogueIds.forEach((dialogueId) => {
    cache.modify({
      id: `DialogueType:${dialogueId}`,
      fields: { mod: (_, { DELETE }) => DELETE },
    });
  });
};
