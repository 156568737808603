import { gql } from '@apollo/client';

import { webhookSigningApiKeyFragment } from 'frontend/api/fragments';

export const GET_WEBHOOK_SIGNING_KEY = gql`
  query GetWebhookSigningKey($botId: ID!) {
    webhookSigningKey(botId: $botId) {
      ...WebhookSigningKey
    }
  }
  ${webhookSigningApiKeyFragment}
`;

export const REGENERATE_WEBHOOK_SIGNING_KEY = gql`
  mutation RegenerateWebhookSigningKey($botId: ID!) {
    regenerateWebhookSigningKey(botId: $botId) {
      ...WebhookSigningKey
    }
  }
  ${webhookSigningApiKeyFragment}
`;
