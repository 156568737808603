import { CSVLink } from 'react-csv';

import { Button } from 'frontend/components';
import type { ButtonColor } from 'frontend/components/Button/Button';

import styles from './styles.scss';

type Props = { filename: string; color?: ButtonColor; data: Record<string, unknown>[] };

function CSVDownloadButton({ filename, data, color = 'secondary' }: Props) {
  return (
    <CSVLink className={styles.downloadButton} filename={filename} data={data}>
      <Button size="small" text="Download CSV" color={color} />
    </CSVLink>
  );
}

export default CSVDownloadButton;
