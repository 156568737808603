import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { usePrevious } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { selectTabActive, selectTabNotificationMessage, showTabNotification } from 'frontend/state/dux/tabNotification';

const NOTIFICATION_MESSAGE = 'New message';

// FIXME(legacy-takeover): Rename fields
const isInHandover = (currentAgentId, { takenOver: handedOver, takeoverByAgentId: handoverByAgentId }) =>
  handedOver && Number(handoverByAgentId) === Number(currentAgentId);

const hasNewHandoverRequest = (
  { hasRequestedTakeover: hasRequestedHandover },
  { hasRequestedTakeover: prevHasRequestedHandover } = {},
) => hasRequestedHandover && !prevHasRequestedHandover;

const showNotificationIfNecessary = ({ tabIsActive, tabNotification, chats, prevChats, agentId, dispatch }) => {
  if (tabIsActive || tabNotification === NOTIFICATION_MESSAGE) {
    return;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const chat of chats) {
    const prevChat = (prevChats || []).find(({ id }) => id === chat.id);
    if (isEqual(chat, prevChat)) {
      continue; // eslint-disable-line no-continue
    }

    if (isInHandover(agentId, chat) || hasNewHandoverRequest(chat, prevChat)) {
      dispatch(showTabNotification(NOTIFICATION_MESSAGE));
      break;
    }
  }
};

export default (chats) => {
  const { data } = useMe();
  const agentId = data?.me?.id;
  const dispatch = useDispatch();
  const tabNotification = useSelector(selectTabNotificationMessage);
  const tabIsActive = useSelector(selectTabActive);
  const prevChats = usePrevious(chats);
  const chatsUpdated = !isEqual(chats, prevChats);

  // It was hard to make this work correctly with useEffect and a dependency array.
  // Example case: have a chat in takeover. Click on another chat. Go to another tab
  // and send a message in the chat in takeover -> no notification.
  if (chatsUpdated) {
    showNotificationIfNecessary({ tabIsActive, tabNotification, chats, prevChats, agentId, dispatch });
  }
};
