export const SORT_ITEMS = Object.freeze({
  NAME: 'name',
  LAST_LOGIN: 'lastLogin',
  EMAIL: 'email',
  STATUS: 'status',
  USERNAME: 'user.username',
  ORGANIZATION_ROLES: 'organizationRolesText',
  BOT: 'bot',
  BOT_ROLES: 'botRolesText',
  CREATED: 'invitedAt',
});

export const USER_SORT_ITEMS = Object.freeze([
  SORT_ITEMS.NAME,
  SORT_ITEMS.ORGANIZATION_ROLES,
  SORT_ITEMS.LAST_LOGIN,
  SORT_ITEMS.USERNAME,
  SORT_ITEMS.STATUS,
]);
