import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { BotMetaDocument } from 'frontend/api/generated';
import { LoaderSwitch, PageContent } from 'frontend/components';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import {
  ActionsList,
  AlertsList,
  BotImprovementTasks,
  CannyHighlight,
  DashboardHeader,
  LatestFallbacks,
  NotificationsList,
} from '../components';

const BotDashboard = () => {
  const { botId } = useParams();

  const { data: botMetaData, loading: botMetaLoading } = useQuery(BotMetaDocument, { variables: { id: botId } });
  const organizationId = botMetaData?.bot?.organization?.id;

  const { botPermissions } = useMyPermissions({ botId, organizationId });
  const canConnectBot = botPermissions.includes('connect_bot');
  const canViewConversations = botPermissions.includes('view_conversations');
  const canEditTemplates = botPermissions.includes('edit_templates');

  return (
    <PageContent>
      <DashboardHeader organizationId={organizationId} />
      <LoaderSwitch loading={botMetaLoading} size="large">
        <div>
          {canViewConversations && <ActionsList className="m-t-5" />}
          {canConnectBot && <AlertsList botId={botId} />}
          {canEditTemplates && <BotImprovementTasks />}
          <NotificationsList />
          {canViewConversations && <LatestFallbacks botId={botId} />}
        </div>
        <CannyHighlight />
      </LoaderSwitch>
    </PageContent>
  );
};

export default BotDashboard;
