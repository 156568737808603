import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'frontend/components';
import { usePrevious } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';

import styles from './Comments.scss';
import { Comment, CommentsForm } from './components';
import { COMMENTS } from './queries';

const EMPTY_ARRAY = [];

const Comments = ({ botId }) => {
  const bottom = useRef();
  const { chatId } = useParams();
  const { data, loading } = useQuery(COMMENTS, {
    variables: {
      botId,
      chatId,
    },
  });

  const comments = data?.chatAndBot?.chat?.comments ?? EMPTY_ARRAY;

  const scrollToBottom = useCallback(() => {
    if (bottom.current) bottom.current.scrollIntoView({ behavior: 'instant' });
  }, []);

  const numberOfComments = comments.length;
  const prevNumberOfComments = usePrevious(numberOfComments);

  useEffect(() => {
    if (numberOfComments > prevNumberOfComments) scrollToBottom();
  }, [numberOfComments, prevNumberOfComments, scrollToBottom]);

  useEffect(scrollToBottom, [scrollToBottom]);

  if (loading) {
    return (
      <div className={cx(styles.comments, styles.loadingContainer)}>
        <Loader size="medium" />
      </div>
    );
  }

  return (
    <div className={styles.comments}>
      <div className={styles.commentsInner}>
        {comments.length === 0 && <p>There are no notes yet</p>}
        {orderBy(comments, 'created', 'asc').map((comment) => (
          <Comment key={comment.id} comment={comment} botId={botId} />
        ))}
        <div ref={bottom} />
      </div>
      <CommentsForm botId={botId} chatId={chatId} />
    </div>
  );
};

Comments.propTypes = {
  botId: IDType.isRequired,
};

export default Comments;
