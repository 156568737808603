import { gql } from '@apollo/client';

import { ACTIVITY_GROUP } from '../fragments';

export default gql`
  query ActivityGroup($groupId: ID!, $botId: ID, $skillId: ID) {
    activityGroup(groupId: $groupId, botId: $botId, skillId: $skillId) {
      ...ActivityGroup
    }
  }
  ${ACTIVITY_GROUP}
`;
