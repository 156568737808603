import { useQuery } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';

import { GetAllNudgeSlugsDocument, type TextNudgeType } from 'frontend/api/generated';
import { FormErrors, Input, Panel, Range, ToggleSwitch } from 'frontend/components';
import { chain, max, required, url } from 'frontend/form/validators';
import { useIsSubmitting } from 'frontend/hooks';
import { finalFormArraysMutatorsFix } from 'frontend/utils';

import { nudgeDefaultTexts } from '../../constants';
import { useNudges, useSlugValidator, useUpdateNudges } from '../../hooks';
import { Header } from '../Header';
import { Preview } from '../Preview';
import styles from '../shared.scss';

interface TextNudgeProps {
  nudge?: TextNudgeType;
}

const TextNudge = ({ nudge }: TextNudgeProps) => {
  const [, submitWrapper] = useIsSubmitting();

  const { onTextUpdate } = useUpdateNudges();
  const { botId, onTextCreate, currentLanguage } = useNudges();
  const { data } = useQuery(GetAllNudgeSlugsDocument, { variables: { botId: botId as string } });
  const { validateSlug } = useSlugValidator({ data, nudge });

  const editing = !!nudge;

  return (
    <Panel className={styles.formPanel}>
      <Form
        mutators={finalFormArraysMutatorsFix(arrayMutators)}
        onSubmit={submitWrapper(editing ? onTextUpdate : onTextCreate)}
        initialValues={nudge || {}}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className={styles.container}>
            <Header
              title={values?.title?.[currentLanguage] ?? nudgeDefaultTexts.title}
              slug={values?.slug ?? nudgeDefaultTexts.slug}
            />
            <Preview>
              <Preview.Intent />
              <Preview.Nudge nudge={values} />
            </Preview>
            <div className={styles.containerEditor}>
              <div className={styles.panelHeader}>
                <h3 className={styles.title}>Text</h3>
                <p>Use a text nudge to deliver news and deals to your customers</p>
              </div>
              <FormErrors className={styles.formErrors} />
              <div className={styles.inputContainer}>
                <Field
                  name="slug"
                  label="Slug (this can not be edited later)"
                  component={Input}
                  readOnly={!!nudge}
                  inputLimit={50}
                  validate={chain([required, validateSlug])}
                  placeholder="Choose a slug for your nudge"
                />
              </div>
              <div className={styles.seperator} />
              <h5 className={styles.smallTitle}>Text Content</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <Field
                  name={`title.${currentLanguage}`}
                  label="Title"
                  component={Input}
                  inputLimit={80}
                  validate={chain([required, max(80)])}
                  placeholder="Choose a title"
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  name={`bodyText.${currentLanguage}`}
                  label="Body text"
                  component={Input}
                  inputLimit={255}
                  validate={chain([required, max(255)])}
                  placeholder="Choose a body text"
                  multiline
                />
              </div>
              <div className={styles.seperator} />
              <h5 className={styles.smallTitle}>Call to action</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <Field
                  name={`linkText.${currentLanguage}`}
                  label="Link text"
                  component={Input}
                  inputLimit={80}
                  validate={chain([required, max(80)])}
                  placeholder="Choose a link text"
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  component={Input}
                  name={`linkLocation.${currentLanguage}`}
                  label="Link location"
                  placeholder="Choose a link location"
                />
              </div>
              <div className={styles.seperator} />
              <h5 className={styles.smallTitle}>Nudge trigger</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <span className={styles.helpText}>
                  Use the URL field if you only want the push greeting to appear on a certain page. If left blank, the
                  push greeting appears on every page of your website.
                </span>
                <Field
                  name="urlTrigger"
                  label="Url trigger"
                  component={Input}
                  validate={url}
                  placeholder="Choose a url to trigger nudge"
                />
              </div>
              <div className={`m-t-1 ${styles.inputContainer}`}>
                <label htmlFor="autopopupTime">Show nudge after: </label>
                <Range
                  name="autopopupTime"
                  min={0}
                  max={10000}
                  step={10}
                  displayValue={(v) => v / 1000}
                  displayFixedDecimals={1}
                  unit="s"
                  className={styles.slider}
                />
              </div>
              <div className={styles.seperator} />
              <h5>Analytics Setup</h5>
              <p className={styles.helpText}>Setup url for completed purchase</p>
              <div className={`m-b-3 ${styles.inputContainer}`}>
                <Field
                  component={Input}
                  name="analyticsCompletionPagePath"
                  aria-label="Completion Page Url"
                  label="Completion Page Url"
                  placeholder="/order/"
                />
              </div>
              <div className={styles.seperator} />
              <h5 className={styles.smallTitle}>Toggle nudge</h5>
              <div className={`m-b-1 ${styles.inputContainer}`}>
                <span className={styles.helpText}>You can toggle this nudge for the selected language</span>
                <div className={styles.toggleContainer}>
                  <ToggleSwitch name={`enabled.${currentLanguage}`} />
                  <label htmlFor="ToggleSwitch-enabled">
                    {values?.enabled?.[currentLanguage] ? 'Enabled' : 'Disabled'}
                  </label>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </Panel>
  );
};

export default TextNudge;
