import { useQuery } from '@apollo/client';

import { formatNumber } from 'frontend/utils';

import { GET_ALL_BOTS } from '../queries';

const useMostActiveBots = ({ bots, total: { count: total } }) => {
  const botIds = bots.map((item) => item.bot_id);
  const { data: botsData, loading } = useQuery(GET_ALL_BOTS, {
    variables: {
      botIds,
    },
  });

  if (loading) return { bots: [], loading };

  const getBotMeta = (botId) => {
    const found = botsData.allBots.find(({ id }) => id === botId.toString());
    if (found) {
      return found;
    }

    return {
      name: `Unknown (id ${botId})`,
      organization: { name: 'Unknown' },
    };
  };

  // Keep bot ordering from sage
  const mergedData = bots.map(({ bot_id: botId, count: messages }, index) => {
    const { id, name, avatar, organization, languages } = getBotMeta(botId);

    const messageRatio = messages / total;
    const percentage = Math.round(messageRatio * 100);
    const percentageWithDecimal = (messageRatio * 100).toFixed(1);
    return {
      name,
      id,
      languages: languages || [],
      logo: (organization ? organization.logo : null) || avatar || null,
      organizationName: (organization ? organization.name : null) || '?',
      messages: `${formatNumber(messages)}`,
      percentage: percentage < 10 ? `${percentageWithDecimal}%` : `${percentage}%`,
      key: `${name}-${index}`,
    };
  });

  return { bots: mergedData, loading: false };
};

export default useMostActiveBots;
