import { get } from 'lodash';
import { useCallback } from 'react';

import { addListItemByFragment } from 'frontend/api/cacheHelpers';
import { CACHE_TYPE, EMPTY_CHAT_MESSAGE, SENDER_TYPES } from 'frontend/features/Inbox/constants';
import { camelCaseKeys, valuesToFunctions } from 'frontend/utils';

import { CHAT_MESSAGE } from '../fragments';

const getPreview = (chat, message) => {
  if (chat.sender === SENDER_TYPES.USER && message?.message) return message.message;
  return chat?.preview ?? '';
};

export default ({ cache, messages }) => {
  const onChatUpdate = useCallback(
    ({ message, ...chat }) => {
      const incomingChat = {
        ...camelCaseKeys(chat),
        preview: getPreview(chat, message),
        __typename: CACHE_TYPE.CHAT_TYPE,
      };
      cache.modify({
        id: cache.identify(incomingChat),
        fields: valuesToFunctions(incomingChat),
      });

      // If the chat update doesn't include a new message no further action is necessary
      if (!message) return;

      const replyCandidates = get(message, 'reply_candidates', []).map((item) => ({
        ...camelCaseKeys(item),
        id: item._id,
        __typename: 'ReplyCandidateType',
      }));

      const suggestions = get(message, 'suggestions', []).map((item) => ({
        ...camelCaseKeys(item),
        id: item._id,
        __typename: 'SuggestionType',
      }));

      const newMessage = {
        ...EMPTY_CHAT_MESSAGE,
        ...camelCaseKeys(message),
        replyCandidates,
        suggestions,
      };

      const hasTemporaryMessage = !!cache.readFragment({
        id: `ChatMessageType:${newMessage.chatbubbleTemporaryId}`,
        fragment: CHAT_MESSAGE,
      });

      if (hasTemporaryMessage) {
        cache.modify({
          id: cache.identify({ ...newMessage, id: newMessage.chatbubbleTemporaryId }),
          fields: valuesToFunctions(newMessage),
        });
      } else {
        cache.modify({
          id: cache.identify(messages?.chatMessages),
          fields: { messages: addListItemByFragment(cache, CHAT_MESSAGE, newMessage) },
        });
      }
    },
    [cache, messages?.chatMessages],
  );

  return onChatUpdate;
};
