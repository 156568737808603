import { combineReducers } from 'redux';

import auth from './auth';
import dashboard from './dashboard';
import dataQueue from './dataQueue';
import engagement from './engagement';
import filters from './filters';

export default combineReducers({
  auth,
  dashboard,
  dataQueue,
  engagement,
  filters,
});
