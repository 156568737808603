import { useEffect, useMemo } from 'react';

import type { BotMember, OrganizationMember } from 'frontend/features/BotDashboard/hooks/useMembers';
import type { Invitation } from 'frontend/features/Organization/hooks/useInvitations';
import { useSearch } from 'frontend/hooks';

type AnnotatedInvitation = Invitation & { organizationRolesText: string; botRolesText: string };
type AnnotatedMember<T extends BotMember | OrganizationMember> = T & {
  email: string;
  lastLogin: number;
  organizationRolesText: string;
  name: string;
  status: string;
};

function annotateMember<T extends BotMember | OrganizationMember>(member: T): AnnotatedMember<T> {
  const status =
    member.__typename === 'OrganizationMemberType' ? member.membership?.agentProfile?.availability : undefined;

  return {
    ...member,
    email: member.user.username ?? '',
    lastLogin: member.user.lastLogin ? new Date(member.user.lastLogin).getTime() : 0,
    organizationRolesText: member.membership!.roles.map((role) => role.title).join(', '),
    name: member.user.profile?.fullName ?? member.user.username,
    status: status ?? '',
  };
}

function annotateInvitation(invitation: Invitation): AnnotatedInvitation {
  return {
    ...invitation,
    organizationRolesText: invitation.organizationRoles.map((role) => role.title).join(', '),
    botRolesText: invitation.botRoles.map((role) => role.title).join(', '),
  };
}

export function useFilteredMembers<T extends BotMember | OrganizationMember>(
  list: T[],
  {
    filter,
    sortFunction,
  }: {
    filter: string;
    sortFunction: (list: AnnotatedMember<T>[]) => AnnotatedMember<T>[];
  },
): (T &
  (
    | {
        email: string;
        lastLogin: number;
        organizationRolesText: string;
        name: string;
        status: string;
      }
    | { organizationRolesText: string; botRolesText: string }
  ))[] {
  const annotated = list.map(annotateMember);
  const searchKeys = ['email', 'name', 'organizationRolesText'];
  const { searchResults, setQuery } = useSearch(annotated, searchKeys);
  const sorted = useMemo(() => sortFunction(searchResults), [searchResults, sortFunction]);

  useEffect(() => {
    setQuery(filter);
  }, [filter, setQuery]);

  return sorted;
}

export function useFilteredInvitations(
  invitations: Invitation[],
  {
    filter,
    sortFunction,
  }: {
    filter: string;
    sortFunction: (list: AnnotatedInvitation[]) => AnnotatedInvitation[];
  },
): AnnotatedInvitation[] {
  const annotated = invitations.map(annotateInvitation);
  const searchKeys = ['email', 'organizationRolesText', 'botRolesText'];
  const { searchResults, setQuery } = useSearch(annotated, searchKeys);
  const sorted = useMemo(() => sortFunction(searchResults), [searchResults, sortFunction]);

  useEffect(() => {
    setQuery(filter);
  }, [filter, setQuery]);

  return sorted;
}
