import { identity, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { Table } from 'frontend/components';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';

import styles from './EntityList.scss';
import EntityListItemCell from './EntityListItemCell';
import { EntitiesType } from '../../propTypes';
import EntityTag from '../EntityTag';

const EntityTagComponent = ({ data: { name, hue } }) => (
  <EntityTag className={styles.name} name={name} hue={hue} lessInfo />
);

const useColumns = ({
  hideDelete,
  maxItems,
  allowExpand,
  isModDialogue,
  deleteFromDialogue,
  areSkillEntities,
  withProperties,
  botOrSkillParams,
}) => {
  const renderEntityListItems = useCallback(
    ({ data: { entity } }) => (
      <EntityListItemCell
        entity={entity}
        hideDelete={hideDelete}
        maxItems={maxItems}
        allowExpand={allowExpand}
        isModDialogue={isModDialogue}
        deleteFromDialogue={deleteFromDialogue}
        areSkillEntities={areSkillEntities}
        withProperties={withProperties}
        botOrSkillParams={botOrSkillParams}
      />
    ),
    [
      hideDelete,
      maxItems,
      allowExpand,
      isModDialogue,
      deleteFromDialogue,
      areSkillEntities,
      withProperties,
      botOrSkillParams,
    ],
  );

  const columns = useMemo(
    () => [
      {
        id: 'entity-list-name',
        title: 'Name',
        key: 'name',
        component: EntityTagComponent,
      },
      {
        id: 'entity-list-items',
        title: 'Items',
        key: 'items',
        render: renderEntityListItems,
      },
    ],
    [renderEntityListItems],
  );

  return columns;
};

const EntityList = ({
  entities,
  maxItems,
  allowExpand,
  isModDialogue,
  className = '',
  areSkillEntities = false,
  hideDelete = false,
  withProperties = false,
  keepOrder = false,
  noEntities = 'None',
  deleteFromDialogue = false,
}) => {
  const botOrSkillParams = useBotOrSkill();
  const [{ currentLanguage }] = useCurrentLanguage();
  const filteredEntities = useMemo(
    () => (entities || []).filter(({ id, languageCode }) => id && languageCode === currentLanguage),
    [currentLanguage, entities],
  );

  const columns = useColumns({
    hideDelete,
    maxItems,
    allowExpand,
    isModDialogue,
    deleteFromDialogue,
    areSkillEntities,
    withProperties,
    botOrSkillParams,
  });

  const data = useMemo(() => {
    const orderFunction = keepOrder ? identity : (list) => orderBy(list, 'name');
    return orderFunction(filteredEntities).map((entity) => {
      const { id, name, hue } = entity;
      return {
        id: `entity-${id}`,
        name: { name, hue, lessInfo: true },
        items: {
          entity,
          hideDelete,
          maxItems,
          allowExpand,
          isModDialogue,
          deleteFromDialogue,
          withProperties,
          areSkillEntities,
        },
        rowClassName: styles.entityRow,
      };
    });
  }, [
    filteredEntities,
    keepOrder,
    hideDelete,
    maxItems,
    allowExpand,
    isModDialogue,
    deleteFromDialogue,
    withProperties,
    areSkillEntities,
  ]);

  return (
    <>
      {filteredEntities.length === 0 && !!noEntities && <i>{noEntities}</i>}
      {filteredEntities.length > 0 && <Table data={data} columns={columns} className={className} />}
    </>
  );
};

EntityList.propTypes = {
  entities: EntitiesType.isRequired,
  areSkillEntities: PropTypes.bool,
  hideDelete: PropTypes.bool,
  withProperties: PropTypes.bool,
  maxItems: PropTypes.number,
  allowExpand: PropTypes.bool,
  keepOrder: PropTypes.bool,
  noEntities: PropTypes.string,
  deleteFromDialogue: PropTypes.bool,
  isModDialogue: PropTypes.bool,
  className: PropTypes.string,
};

export default EntityList;
