import PropTypes from 'prop-types';

import styles from './Score.scss';

const Score = ({ title = '', onClick, children }) => (
  <span
    className={styles.score}
    title={title}
    onClick={onClick}
    onKeyDown={(e) => e.key === 'Enter' && onClick(e)}
    role="button"
    tabIndex={0}
  >
    {children}
  </span>
);

Score.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Score;
