import React from 'react';

import styles from './ChatViewContent.scss';
import { InboxFilters } from '../../components';

interface ChatViewContentProps {
  children: React.ReactNode | React.ReactNode[] | undefined;
}

const ChatViewContent = ({ children }: ChatViewContentProps) => (
  <div className={styles.chatViewContentWrapper}>
    <InboxFilters />
    <div className={styles.chatViewContents}>{children}</div>
  </div>
);

export default ChatViewContent;
