import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { Select } from 'frontend/components';

import { MESSAGE_TYPES } from '../../constants';

const { ALL, DIALOGUES, FALLBACKS } = MESSAGE_TYPES;

const SelectMessageType = ({ messageType, setMessageType, disabled }) => {
  const selectInput = useMemo(
    () => ({
      onChange: (event) => setMessageType(event.target.value),
      value: messageType,
      name: 'clusteringMessageType',
    }),
    [messageType, setMessageType],
  );

  return (
    <Select input={selectInput} disabled={disabled}>
      <Select.Option key="message-type-fallbacks" value={FALLBACKS} label="Fallbacks" />
      <Select.Option key="message-type-dialogues" value={DIALOGUES} label="Dialogues" />
      <Select.Option key="message-type-all" value={ALL} label="All" />
    </Select>
  );
};

SelectMessageType.propTypes = {
  messageType: PropTypes.oneOf(Object.values(MESSAGE_TYPES)).isRequired,
  setMessageType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectMessageType;
