export default (regex, text) => {
  const match = regex.exec(text);
  if (!match) return null;

  const hasWhitespace = match[0].startsWith(' ');
  const start = match.index + (hasWhitespace ? 1 : 0);
  const word = match[1];
  const end = start + word.length + 1;

  return { start, end, word };
};
