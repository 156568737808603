import type { ApolloCache } from '@apollo/client';

import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

/** This function invalidates cache for the query `LibraryDialogues` (Apollo fieldName: `dialogues`) for specific dialogue ids.
 * This query is used for dialogues in Flow Viewer.
 * @returns true if all evictions went fine, false otherwise, undefined if there were no dialogues to run operations on */
export default function invalidateCacheDialogues(
  dialogueIds: string[],
  queryArgs: BuildIdObject,
  cache: ApolloCache<unknown>,
): boolean | undefined {
  let areEvictionsSuccessful: boolean | undefined;

  dialogueIds.forEach((dialogueId) => {
    const isSuccessful = cache.evict({
      fieldName: 'dialogues',
      args: {
        endToEnd: true,
        regular: true,
        parentId: dialogueId,
        ...queryArgs,
      },
    });

    if (areEvictionsSuccessful !== false) {
      areEvictionsSuccessful = isSuccessful;
    }
  });

  return areEvictionsSuccessful;
}
