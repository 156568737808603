import { useCallback } from 'react';

import { isRegularDialogueType } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';

import useCanDisableDialogue from './useCanDisableDialogue';
import { DeleteDialogue } from '../modals';
import { getDialogueTypeName } from '../utils';

export default ({
  dialogue,
  selectedLanguage,
  botOrSkillParams,
  client,
  toast,
  languageNameMapVariants,
  shouldRedirect = false,
}) => {
  const checkCanDisable = useCanDisableDialogue();
  const [showDeleteModal] = useModal(DeleteDialogue);

  const deleteFunction = useCallback(async () => {
    const { id, dialogueType } = dialogue;
    const dialogueTypeName = getDialogueTypeName(dialogueType);
    const regular = isRegularDialogueType(dialogueType);
    const variables = { ...botOrSkillParams.buildIdObject, id, regular, languageCode: selectedLanguage };
    const languageName = languageNameMapVariants[selectedLanguage];

    const canDelete = await checkCanDisable({ client, variables });

    if (!canDelete) {
      toast.error(`Cannot be deleted. Is it the only ${dialogueTypeName} enabled in ${languageName}?`);
      return;
    }

    showDeleteModal({ dialogue, botOrSkillParams, shouldRedirect });
  }, [
    dialogue,
    botOrSkillParams,
    selectedLanguage,
    languageNameMapVariants,
    checkCanDisable,
    client,
    showDeleteModal,
    shouldRedirect,
    toast,
  ]);

  return deleteFunction;
};
