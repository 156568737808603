import { useQuery } from '@apollo/client';
import { get, orderBy } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { CHAT_MESSAGES_LIMIT } from 'frontend/features/Inbox/constants';

import { CHAT_MESSAGES } from '../queries';

const useChatMessages = (botId) => {
  const { chatId } = useParams();
  const { loading, data, fetchMore } = useQuery(CHAT_MESSAGES, {
    variables: { botId, chatId, limit: CHAT_MESSAGES_LIMIT },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !botId,
  });

  const loadMore = useCallback(() => {
    const messages = orderBy(get(data, 'chatMessages.messages', []), ['created'], ['asc']);
    const before = get(messages, '[0].created', null);

    fetchMore({
      variables: { before },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          chatMessages: {
            ...fetchMoreResult.chatMessages,
            messages: [...prev.chatMessages.messages, ...fetchMoreResult.chatMessages.messages],
          },
        };
      },
    });
  }, [data, fetchMore]);

  return { loading: loading || !botId, data, loadMore };
};

export default useChatMessages;
