import { gql } from '@apollo/client';

import { skillFragment } from '../fragments';

export default gql`
  mutation CreateSkill($name: String!, $initialLanguage: String!, $organizationId: ID!) {
    createSkill(name: $name, initialLanguage: $initialLanguage, organizationId: $organizationId) {
      ...Skill
    }
  }
  ${skillFragment}
`;
