import { compose, get, isEmpty, isEqual } from 'lodash/fp';
import { useCallback } from 'react';

import { hasSameId } from 'frontend/features/Build/utils';
import { CHAT_TICKET } from 'frontend/features/Inbox/queries';
import {
  addTicket,
  chatPredicatesFromFilters,
  checkPredicates,
  prepareChat,
  removeChatAndTicket,
  ticketPredicatesFromFilters,
} from 'frontend/features/Inbox/utils';
import useMe from 'frontend/hooks/useMe';
import { valuesToFunctions } from 'frontend/utils';

import useViewConversation from './useViewConversation';

const hasChatId = ({ id }) => compose(isEqual(id), get('chatId'));

const fetchChatTicket = async (query, chat) => {
  const variables = { chatId: chat.id, botId: chat.botId };
  const response = await query({ query: CHAT_TICKET, fetchPolicy: 'no-cache', variables });
  return response.data.ticketForChat;
};

const updateChat = ({ cache, chat }) => cache.modify({ id: cache.identify(chat), fields: valuesToFunctions(chat) });

const shouldHaveTicket = ({ ticketStatusFilter, ticketAgentFilter }) =>
  !isEmpty(ticketAgentFilter || ticketStatusFilter);

export default ({ cache, filters, chatsAndTickets, query, onChatNotInList }) => {
  const { data } = useMe();
  const userId = data?.me?.id;
  const viewConversation = useViewConversation();

  const onChatUpdated = useCallback(
    async (payload) => {
      const chat = prepareChat(payload);
      if (!viewConversation(chat)) {
        return;
      }

      const currentChat = (chatsAndTickets?.chats || []).find(hasSameId(chat));
      const currentTicket = (chatsAndTickets?.tickets || []).find(hasChatId(chat));

      updateChat({ cache, chat });

      const chatMatchesFilters = checkPredicates(chatPredicatesFromFilters(filters), chat);
      const ticket = currentTicket || (await fetchChatTicket(query, chat));
      const ticketMatchesFilters = !ticket || checkPredicates(ticketPredicatesFromFilters(filters, userId), ticket);
      const missingTicket = shouldHaveTicket(filters) && !ticket;
      const chatShouldBeInList = chatMatchesFilters && ticketMatchesFilters && !missingTicket;

      if (!currentChat) {
        onChatNotInList({ chatsAndTickets, chat, ticket, chatShouldBeInList });
        return;
      }

      if (chatShouldBeInList && ticket && !currentTicket) {
        addTicket({ cache, chatsAndTickets, ticket });
      }

      if (!chatShouldBeInList) {
        removeChatAndTicket({ cache, chatsAndTickets, chat, ticket });
      }
    },
    [onChatNotInList, cache, chatsAndTickets, filters, query, userId, viewConversation],
  );

  return onChatUpdated;
};
