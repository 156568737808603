import { useNavigate } from 'react-router-dom';

import { PageContent, Panel } from 'frontend/components';

import styles from './Feedback.scss';
import arrow from './arrow.svg?url';

const Feedback = () => {
  const navigate = useNavigate();
  return (
    <PageContent>
      <h1 className={styles.title}>Feedback</h1>
      <p className={styles.description}>
        We like to receive feedback, both good and bad.
        <br />
        Is your feedback a support issue or a request for a new feature? Take your pick below.
      </p>
      <div className="m-b-6" />
      <div className={styles.feedbackColumns}>
        <Panel onClick={() => navigate('/feedback/support')} className={styles.panel}>
          <h2 className={styles.panelTitle}>Support</h2>
          <p className="m-t-1 m-b-4">Let us know about bugs, technical issues or any questions you might have.</p>
          <img src={arrow} alt="" className={styles.arrowIcon} />
        </Panel>
        <Panel onClick={() => navigate('/feedback/feature')} className={styles.panel}>
          <h2 className={styles.panelTitle}>Feature</h2>
          <p className="m-t-1 m-b-4">Do you have any ideas for new functionality? Tell us about them here!</p>
          <img src={arrow} alt="" className={styles.arrowIcon} />
        </Panel>
      </div>
    </PageContent>
  );
};

export default Feedback;
