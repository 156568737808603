import { useQuery } from '@apollo/client';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { LibraryDialoguesDocument, LibraryTopicsDocument } from 'frontend/api/generated';
import { DIALOGUE_TYPES, isRegularDialogueType } from 'frontend/constants';
import { BuildIdObjectType, IDType } from 'frontend/propTypes';

import { BOTTOM_LEVEL } from '../../constants';
import { sortTopics } from '../../utils';
import DialogueList from '../DialogueList';
import TopicItem from '../TopicItem';

const LibraryItemContents = ({
  selectedLanguage,
  buildIdObject,
  skillId,
  parentDisabled,
  topicId,
  dialogueId,
  dialogueModId,
  dialogueType,
  indentationLevel,
  setShowLoader = noop,
}) => {
  const buildVariables = { skillId, ...buildIdObject };
  const regular = isRegularDialogueType(dialogueType);

  const parentTopicId = topicId === BOTTOM_LEVEL ? undefined : topicId;
  const subtopicsConfig = { variables: { ...buildVariables, parentTopicId }, skip: !regular || !topicId };
  const { data: subtopicsData, loading: subtopicsLoading } = useQuery(LibraryTopicsDocument, subtopicsConfig);

  const dialogueTypeVariables = regular ? { regular: true } : { regular: false, dialogueType };
  const parentId = dialogueId === BOTTOM_LEVEL ? undefined : dialogueId;
  const dialogueTopicId = topicId === BOTTOM_LEVEL ? undefined : topicId;
  const dialogueVariables = { ...buildVariables, ...dialogueTypeVariables, parentId, topicId: dialogueTopicId };
  const { data: dialogueData, loading: dialogueLoading } = useQuery(LibraryDialoguesDocument, {
    variables: dialogueVariables,
  });

  const modFollowupsConfig = {
    variables: { ...buildIdObject, dialogueModParentId: dialogueModId, regular: true },
    skip: !dialogueModId,
  };
  const { data: modFollowupsData, loading: modFollowupsLoading } = useQuery(
    LibraryDialoguesDocument,
    modFollowupsConfig,
  );

  const loading = subtopicsLoading || dialogueLoading || modFollowupsLoading;
  useEffect(() => {
    setShowLoader(loading);
  }, [loading, setShowLoader]);

  return (
    <>
      {sortTopics(get(subtopicsData, 'topics')).map((topic) => (
        <TopicItem
          key={`library-topic-${topic.id}`}
          topic={topic}
          selectedLanguage={selectedLanguage}
          skillId={skillId}
          parentDisabled={parentDisabled}
          indentationLevel={indentationLevel}
        />
      ))}
      <DialogueList
        dialogues={get(modFollowupsData, 'dialogues')}
        selectedLanguage={selectedLanguage}
        buildIdObject={buildIdObject}
        parentDisabled={parentDisabled}
        indentationLevel={indentationLevel}
      />
      <DialogueList
        dialogues={get(dialogueData, 'dialogues')}
        selectedLanguage={selectedLanguage}
        buildIdObject={buildIdObject}
        skillId={skillId}
        parentDisabled={parentDisabled}
        parentId={dialogueId}
        indentationLevel={indentationLevel}
      />
    </>
  );
};

LibraryItemContents.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  buildIdObject: BuildIdObjectType.isRequired,
  skillId: IDType,
  parentDisabled: PropTypes.bool,
  topicId: PropTypes.oneOfType([IDType, PropTypes.oneOf([BOTTOM_LEVEL])]),
  dialogueId: PropTypes.oneOfType([IDType, PropTypes.oneOf([BOTTOM_LEVEL])]),
  dialogueType: PropTypes.oneOf(Object.values(DIALOGUE_TYPES)),
  dialogueModId: IDType,
  indentationLevel: PropTypes.number.isRequired,
  setShowLoader: PropTypes.func,
};

export default LibraryItemContents;
