import { useMemo } from 'react';

import { useModal } from 'frontend/features/Modals';

import { RemoveOrganizationMember, UpdateBotMember, UpdateOrganizationMember } from '../../modals';

const EMPTY_ARRAY = [];

export const useOptions = ({ user, botId, organizationId, showOptions, isInheritedFromOrg }) => {
  const [showRemoveMemberModal] = useModal(RemoveOrganizationMember);
  const [showUpdateBotMemberModal] = useModal(UpdateBotMember);
  const [showUpdateMemberModal] = useModal(UpdateOrganizationMember);
  const context = botId ? 'bot' : 'organization';

  const options = useMemo(
    () => [
      {
        title: `Update ${context} membership`,
        key: `${context}-card-add-member`,
        onClick: () => {
          if (context === 'bot') {
            showUpdateBotMemberModal({ botId, organizationId, user });
          } else {
            showUpdateMemberModal({ organizationId, user });
          }
        },
      },
      ...(!isInheritedFromOrg
        ? [
            {
              title: `Remove ${context} membership`,
              key: `${context}-card-remove-member`,
              onClick: () => {
                showRemoveMemberModal({ organizationId, user });
              },
            },
          ]
        : []),
    ],
    [
      botId,
      context,
      organizationId,
      showUpdateBotMemberModal,
      showUpdateMemberModal,
      user,
      isInheritedFromOrg,
      showRemoveMemberModal,
    ],
  );

  if (!showOptions) {
    return EMPTY_ARRAY;
  }

  return options;
};

export default useOptions;
