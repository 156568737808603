import { Pencil } from 'frontend/assets/icons';
import { Modal } from 'frontend/features/Modals';

interface WordCountModalProps {
  hide: (...someArgs: any[]) => Promise<void>;
  args: {
    title: string;
    text: string;
  };
}
const WordCountModal = ({ hide, args: { title, text } }: WordCountModalProps) => (
  <Modal hide={hide} onOk={hide} hideCancel title={title} icon={Pencil}>
    <Modal.Content>
      <p className="m-bl-0">{text}</p>
    </Modal.Content>
  </Modal>
);

export default WordCountModal;
