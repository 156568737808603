import { isArray, isObjectLike } from 'lodash';
import { useState } from 'react';
import { useModal as reactUseModal } from 'react-modal-hook';

// Make sure not to treat an array or an event as an object
const isRegularObject = (potentialObject) =>
  isObjectLike(potentialObject) && !isArray(potentialObject) && !potentialObject.target;

export default function useModal(Component): [(callArgs?: any) => void, () => void] {
  const [args, setArgs] = useState({});

  const [showModal, hideModal] = reactUseModal(() => <Component args={args} hide={hideModal} />, [args]);

  const showModalWithArgs = (callArgs) => {
    if (isRegularObject(callArgs)) setArgs(callArgs);
    showModal();
  };

  return [showModalWithArgs, hideModal];
}
