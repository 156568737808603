import cx from 'classnames';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import { entityLimits } from 'frontend/constants';
import { getItemName } from 'frontend/features/Composer';
import { getId } from 'frontend/utils';

import styles from './SampleWithEntities.scss';
import { EntityInstancesType } from '../../propTypes';
import EntityTag from '../EntityTag';

const SampleWithEntities = ({ instances, text, className = '' }) => {
  const sortedInstances = sortBy(instances, 'start');
  const illegal = (instances || []).filter(({ entity }) => entity).length > entityLimits.MAX_INSTANCES_PER_SAMPLE;
  const tooltip = illegal ? `Samples can have up to ${entityLimits.MAX_INSTANCES_PER_SAMPLE} entities` : undefined;

  let previousPosition = 0;
  return (
    <span className={cx(className, { [styles.illegal]: illegal })} title={tooltip}>
      {sortedInstances.map((instance) => {
        const { entity, kindlyEntity, start, end } = instance;
        const name = getItemName(instance);

        const element = (
          <span key={getId(instance)}>
            <span>{text.slice(previousPosition, start)}</span>
            <EntityTag
              name={name}
              items={entity?.items}
              hue={entity?.hue}
              description={kindlyEntity?.description}
              kindly={Boolean(kindlyEntity)}
              size="small"
            />
          </span>
        );
        previousPosition = end;
        return element;
      })}
      {text.slice(previousPosition) && <span>{text.slice(previousPosition)}</span>}
    </span>
  );
};

SampleWithEntities.propTypes = {
  instances: EntityInstancesType.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SampleWithEntities;
