import { orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { countExpandedSamples, expandSamples, sortByText } from 'frontend/features/Samples/utils';
import { selectSorting, sortingTypes } from 'frontend/state/dux/build';
import { getId } from 'frontend/utils';

import { sample } from '../validators';

const addItemKeys = (array) => (array || []).map((item, index) => ({ ...item, sampleIndex: index.toString() }));

const sortByDate = (array) => orderBy(array, ({ updatedAt, tempUpdatedAt }) => tempUpdatedAt || updatedAt, ['desc']);

const samplesInLanguage = (language, samples) =>
  addItemKeys(samples).filter((sampleItem) => getId(sampleItem) && sampleItem.languageCode === language);

export default ({ currentLanguage, values }) => {
  const sorting = useSelector(selectSorting);
  const [allSamplesInLanguage, setAllSamplesInLanguage] = useState([]);

  const [expandedSampleCount, setExpandedSampleCount] = useState(0);
  const [expandedSamples, setExpandedSamples] = useState([]);

  useEffect(() => {
    const allSamples = samplesInLanguage(currentLanguage, values);

    const sorter = sorting === sortingTypes.ALPHABETICAL ? sortByText : sortByDate;

    const sortedSamples = sorter(allSamples);

    setAllSamplesInLanguage(sortedSamples);

    setExpandedSamples(expandSamples(sortedSamples));
    setExpandedSampleCount(countExpandedSamples(allSamples));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage, sorting]);

  const sampleValidator = useCallback((...args) => sample(allSamplesInLanguage)(...args), [allSamplesInLanguage]);

  return {
    sampleValidator,
    sampleList: allSamplesInLanguage,
    totalSampleCount: allSamplesInLanguage.length,
    expandedSamples,
    expandedSampleCount,
  };
};
