import { defineAction } from 'redux-define';

const FILTERS = defineAction('filters', ['SET_FILTERS', 'RESET_FILTERS'], 'analytics');

export const setAnalyticsFilters = (filters) => async (dispatch) => {
  dispatch({
    type: FILTERS.SET_FILTERS,
    filters,
  });
};

export const selectAnalyticsFilters = (state) => state.analytics.filters;

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTERS.SET_FILTERS:
      return { ...state, ...action.filters };
    case FILTERS.RESET_FILTERS:
      return initialState;
    default:
      return state;
  }
};
