import { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { useEventListener, usePrompt } from 'frontend/hooks';

interface Props {
  /** If true, doesn't `<Prompt>` a _guard_ even if it has changes. */
  allowRedirect?: boolean;
  /** If true, doesn't `<Prompt>` a _guard_ if redirecting to the same `pathname`. Useful if you
   * don't want to prompt when navigating inside the same page but with different Query Params. */
  allowSamePathRedirect?: boolean;
  /** If true, "guards" the unload page event with a `<Prompt>`. Default is `dirty` from `react-final-form`. */
  hasChanges?: boolean;
}

const GuardUnsaved = ({ allowRedirect, allowSamePathRedirect, hasChanges }: Props) => {
  const { dirty: isFormDirty } = useFormState();
  const { pathname: fromPathname } = useLocation();

  const hasPageChanges = hasChanges ?? isFormDirty;

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (hasPageChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    [hasPageChanges],
  );

  // Prompt if the user has changes and is trying to close the page/window.
  useEventListener('beforeunload', handleBeforeUnload);

  usePrompt(({ pathname: toPathname }: { pathname: string }) => {
    if ((allowSamePathRedirect && fromPathname === toPathname) || allowRedirect) return true;
    if (hasPageChanges) return 'You have unsaved changes. Are you sure you want to leave?';
    return true;
  }, !allowRedirect && hasPageChanges);

  return null;
};

export default GuardUnsaved;
