import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import {
  DeleteOrganizationDocument,
  MyOrganizationsDocument,
  PreviewDeletingOrganizationDocument,
} from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import { useMixpanel, useToast } from 'frontend/hooks';
import { setOrganizationDeleteInProgress } from 'frontend/state/dux/platform';
import { useAppDispatch } from 'frontend/state/hooks';

interface DeleteOrganizationProps {
  hide(): Promise<void>;

  args: {
    name?: string;
    organizationId: string;
    redirect?: string | number;
  };
}

const DeleteOrganization = ({ hide, args: { name, organizationId, redirect = -1 } }: DeleteOrganizationProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mixpanel } = useMixpanel();
  const previewDeletingOrganization = useQuery(PreviewDeletingOrganizationDocument, {
    variables: { organizationId },
  });
  const [deleteOrganizationMutation] = useMutation(DeleteOrganizationDocument, {
    variables: { organizationId },
    update: () => {
      dispatch(setOrganizationDeleteInProgress(organizationId));
      navigate(redirect as string);
      toast.success(`Deleting organization ${name}`);
      mixpanel.track('Delete organization', { organizationId });
    },
    refetchQueries: [MyOrganizationsDocument],
  });

  const { bots, skills, users, memberships } = previewDeletingOrganization.data?.previewDeletingOrganization ?? {};

  return (
    <DeleteModal
      titleName={`organization ${name}`}
      name={name}
      onSubmit={deleteOrganizationMutation}
      hide={hide}
      confirmName
    >
      <p>
        Are you sure you want to delete the organization <strong>{name}</strong>?
      </p>
      <p>This will permanently delete the organization and all its data. This action cannot be undone.</p>
      {bots && bots.length > 0 && (
        <>
          <p>
            These <b>workspaces</b> will be deleted:{' '}
          </p>
          <ul>
            {bots.map((bot) => (
              <li key={bot.id}>
                {bot.name} ({bot.id})
              </li>
            ))}
          </ul>
        </>
      )}
      {skills && skills.length > 0 && (
        <>
          <p>
            These <b>skills</b> will be deleted:{' '}
          </p>
          <ul>
            {skills.map((skill) => (
              <li key={skill.id}>
                {skill.name} ({skill.id})
              </li>
            ))}
          </ul>
        </>
      )}
      <p>
        Users who are only members in this organization will be permanently deleted. Users who are members in other
        organizations will not be deleted, but their memberships in this organization will be deleted.
      </p>
      {users && users.length > 0 && (
        <>
          <p>
            These <b>user accounts</b> will be permanently deleted as they are only members in this organization:{' '}
          </p>
          <ul>
            {users.map((user) => (
              <li key={user.id}>{user.email}</li>
            ))}
          </ul>
        </>
      )}
      {memberships && memberships.length > 0 && (
        <>
          <p>
            These <b>memberships</b> will be deleted:{' '}
          </p>
          <ul>
            {memberships.map((membership) => (
              <li key={membership.id}>{membership.user.email}</li>
            ))}
          </ul>
        </>
      )}
      <p>
        This might take some time, so please do not operate in the organization or its workplaces while its in process
        of deletion.
      </p>
    </DeleteModal>
  );
};

export default DeleteOrganization;
