import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { SpeechMessage } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './ConfusionRow.scss';
import { SectionRow } from '../../../components';
import ConfusionActions from '../ConfusionActions/ConfusionActions';

interface DialogueProps {
  colorLabel: string;
  title: string;
}

const Dialogue = ({ colorLabel, title }: DialogueProps) => (
  <div className={styles.dialogue}>
    <div style={{ backgroundColor: colorLabel }} className={styles.icon} />
    <div className={styles.titleContainer}>
      <div className={styles.title}>{title}</div>
    </div>
  </div>
);

Dialogue.propTypes = {
  colorLabel: PropTypes.string,
  title: PropTypes.string,
};

interface Props {
  showResolveDialogueConfusion: (...args: any[]) => any;
  confusedDialogues: {
    dialogueA: { title: string[]; colorLabel: string[] };
    dialogueB: { title: string[]; colorLabel: string[] };
    confusedSamplePairs: string[];
    id: string;
  };
  botId?: string;
  selectedLanguage: string;
  setIgnoredDialogues: (...args: any[]) => any;
  refetchConfusedDialogues: (...args: any[]) => any;
  ignoredDialogues: any[];
}
const ConfusionRow = ({
  showResolveDialogueConfusion,
  confusedDialogues,
  selectedLanguage,
  botId,
  setIgnoredDialogues,
  ignoredDialogues,
  refetchConfusedDialogues,
}: Props) => {
  const { dialogueA, dialogueB, confusedSamplePairs, id } = confusedDialogues;

  const setIsIgnored = useCallback(() => {
    setIgnoredDialogues([...ignoredDialogues, id]);
  }, [setIgnoredDialogues, ignoredDialogues, id]);

  return (
    <SectionRow>
      <div className={styles.inputContainer}>
        <div className={styles.dialogueRow}>
          <div className={styles.icon}>
            <Icon component={SpeechMessage} color="warning" />
          </div>
          <div className={styles.dialogue}>
            <div>
              <Dialogue
                colorLabel={dialogueA?.colorLabel[selectedLanguage]}
                title={dialogueA?.title[selectedLanguage]}
              />
              <Dialogue
                colorLabel={dialogueB?.colorLabel[selectedLanguage]}
                title={dialogueB?.title[selectedLanguage]}
              />
            </div>
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.action}>
            <span className={styles.text}>{`${confusedSamplePairs.length} samples in conflict`}</span>
          </span>
        </div>
      </div>
      <ConfusionActions
        reportId={id}
        botId={botId}
        confusedDialogues={confusedDialogues}
        selectedLanguage={selectedLanguage}
        showResolveDialogueConfusion={showResolveDialogueConfusion}
        setIgnored={setIsIgnored}
        refetchConfusedDialogues={refetchConfusedDialogues}
      />
    </SectionRow>
  );
};

export default ConfusionRow;
