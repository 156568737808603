import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { OrganizationChannelsDocument } from 'frontend/api/generated';
import { getBotChannels } from 'frontend/api/queries';

type Channels = { hasMessenger: boolean; hasApplications: boolean; loading: boolean };
export default function useChannels(): Channels {
  const { botId, organizationId } = useParams();
  const { data: botData, loading: botLoading } = useQuery(getBotChannels, { variables: { botId }, skip: !botId });
  const { data: organizationData, loading: organizationLoading } = useQuery(OrganizationChannelsDocument, {
    variables: { organizationId: organizationId! },
    skip: !organizationId,
  });

  if (botId) {
    return {
      hasMessenger: botData?.bot?.facebookPages?.length > 0,
      hasApplications: botData?.bot?.applications?.length > 0,
      loading: botLoading,
    };
  }

  return {
    hasMessenger: Boolean(organizationData?.organizationChannels?.hasMessenger),
    hasApplications: Boolean(organizationData?.organizationChannels?.hasApplications),
    loading: organizationLoading,
  };
}
