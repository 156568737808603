import cx from 'classnames';

import styles from './Tabs.scss';

interface ContentProps {
  tabIndex: number;
  tabHidden: boolean;
  tabSelected: boolean;
  hideTab(idx: number): void;
  showTab(idx: number): void;
  selectedTab?: number;
  args?: Record<string, any>;
}

interface TabContentsProps {
  contentClassName?: string;
  render?(props: ContentProps): React.ReactNode;
  component?: React.ElementType;
  title?: string;
  idx: number;
  hide(idx: number): void;
  show(idx: number): void;
  selected: number;
  isHidden(idx: number): boolean;
  selectedTab?: number;
  args?: Record<string, any>;
}

const TabContents = ({
  contentClassName,
  render,
  component,
  title,
  idx,
  hide,
  show,
  selected,
  isHidden,
  args,
  selectedTab,
}: TabContentsProps) => {
  const classNames = cx(styles.btn, contentClassName, styles.content, {
    [styles.contentSelected]: selected === idx,
  });
  const Component = component || null;
  const tabSelected = selected === idx;

  if (Component) {
    return (
      <div className={classNames} role="tabpanel" hidden={!tabSelected}>
        <Component
          tabIndex={idx}
          tabHidden={isHidden(idx)}
          tabSelected={tabSelected}
          hideTab={() => hide(idx)}
          showTab={() => show(idx)}
          title={title}
          args={args}
        />
      </div>
    );
  }

  if (render) {
    return (
      <div className={classNames} role="tabpanel" hidden={!tabSelected}>
        {render({
          tabIndex: idx,
          tabHidden: isHidden(idx),
          tabSelected,
          hideTab: hide,
          showTab: show,
          selectedTab,
          args,
        })}
      </div>
    );
  }

  console.error('<Tabs> must be supplied with either a component or a render function');
  return null;
};

export default TabContents;
