import { useMutation } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DotsButton, Dropdown, List } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { ChatCommentType, IDType } from 'frontend/propTypes';
import { stopEvent } from 'frontend/utils';

import styles from './Comment.scss';
import { DeleteComment } from '../../modals';
import { CREATE_OR_UPDATE_COMMENT, DELETE_COMMENT } from '../../mutations';
import { commentDeletedCacheUpdate, commentsCountCacheUpdate } from '../../utils';
import CommentBody from '../CommentBody';

const MetaDataOptions = ({ actions, close }) => (
  <List
    dataSource={actions}
    renderItem={({ key, onClick, title }) => (
      <List.Item
        className={styles.optionsItem}
        key={key}
        onClick={stopEvent(() => {
          onClick();
          close();
        })}
      >
        {title}
      </List.Item>
    )}
  />
);

MetaDataOptions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  close: PropTypes.func,
};

const Comment = ({ comment, botId, hideActions = false }) => {
  const { chatId } = useParams();
  const [deleted, setDeleted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [commentValue, setCommentValue] = useState(comment.message);
  const { userId } = useSelector(({ auth: { access } }) => ({ userId: access.user_id }));
  const canEdit = userId === comment.userId;
  const [showDeleteChatCommentModal] = useModal(DeleteComment);
  const variables = useMemo(() => ({ botId, chatId }), [botId, chatId]);

  const updateOnDelete = useCallback(
    (cache, { data }) => {
      commentDeletedCacheUpdate({ comment: data.deleteChatComment, cache, chatId, botId });
      commentsCountCacheUpdate({ cache, chatId, countDifference: -1 });
    },
    [botId, chatId],
  );

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    variables,
    update: updateOnDelete,
    onError: () => setDeleted(false),
  });

  const [updateComment] = useMutation(CREATE_OR_UPDATE_COMMENT, {
    variables,
  });

  const actions = [
    {
      title: 'Edit',
      key: 'chat-comment-edit',
      onClick: () => setEditMode(true),
    },
    {
      title: 'Delete',
      key: 'chat-comment-delete',
      onClick: () =>
        showDeleteChatCommentModal({
          onConfirm: () => {
            setDeleted(true);
            setTimeout(() => {
              deleteComment({ variables: { commentId: comment.id } });
            }, 700);
          },
          comment,
        }),
    },
  ];

  const wrapperClassNames = cx(styles.wrapper, {
    [styles.wrapperDeleted]: deleted,
    [styles.wrapperFocus]: editMode && canEdit,
    'm-b-sm': !hideActions,
  });
  const onSave = useCallback(() => {
    updateComment({
      variables: { commentId: comment.id, message: commentValue },
      optimisticResponse: {
        createOrUpdateChatComment: {
          __typename: 'ChatCommentType',
          ...comment,
          message: commentValue,
        },
      },
    });
    setEditMode(false);
  }, [comment, commentValue, updateComment]);

  return (
    <div className={wrapperClassNames}>
      {!hideActions && canEdit && (
        <Dropdown wrapperClassName={styles.options} overlay={<MetaDataOptions actions={actions} />}>
          <DotsButton />
        </Dropdown>
      )}
      <CommentBody
        comment={comment}
        editMode={editMode}
        canEdit={canEdit}
        commentValue={commentValue}
        setCommentValue={setCommentValue}
        onSave={onSave}
        setEditMode={setEditMode}
      />
    </div>
  );
};

Comment.propTypes = {
  hideActions: PropTypes.bool,
  comment: ChatCommentType,
  botId: IDType.isRequired,
};

export default Comment;
