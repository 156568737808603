import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import { SpeechBubble } from 'frontend/assets/icons';
import { GET_LAST_PUBLISHED_DATE } from 'frontend/features/Build/modals/PublishBot/GET_LAST_PUBLISHED_DATE';
import { Modal } from 'frontend/features/Modals';
import { IDType } from 'frontend/propTypes';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import DialogueTable from './DialogueTable';
import styles from './styles.scss';

const PublishBot = ({ hide, args: { botId, onSubmit } }) => {
  const botName = useSelector((state) => state.bot.name);

  const { data } = useQuery(GET_LAST_PUBLISHED_DATE, { variables: { botId }, fetchPolicy: 'network-only' });

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <Modal
          title="Dialogues with updates"
          icon={SpeechBubble}
          hide={hide}
          onOkText="Publish updates"
          onOk={handleSubmit}
          className={styles.modal}
        >
          <Modal.Content>
            <p>
              {botName || 'This bot'} was last published on{' '}
              {data?.lastPublished ? format(data?.lastPublished, DATE_FORMAT_WITH_TIME) : 'an unknown date'}. The
              following dialogues have been updated since then:
            </p>
            <DialogueTable botId={botId} hide={hide} />
          </Modal.Content>
        </Modal>
      )}
    />
  );
};

PublishBot.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    botId: IDType.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }),
};

export default PublishBot;
