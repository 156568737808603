import PropTypes from 'prop-types';

import { Modal } from 'frontend/features/Modals';

const AboutFeature = ({ hide, args: { title, text } }) => (
  <Modal hide={hide} onOk={hide} hideCancel title={title}>
    <Modal.Content>
      <div className="m-t-0">{text}</div>
    </Modal.Content>
  </Modal>
);

AboutFeature.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({}),
};

export default AboutFeature;
