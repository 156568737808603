import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { deleteFromArrayInCache } from 'frontend/api/cacheHelpers';
import { DeleteModal } from 'frontend/features/Modals';
import { IDType } from 'frontend/propTypes';

import { DELETE_MESSAGE_CLUSTERS_REPORT } from '../../mutations';
import { MESSAGE_CLUSTER_REPORTS } from '../../queries';

const DeleteMessageClustersReport = ({ hide, args }) => {
  const { botId, reportId, stopPolling } = args;

  const update = deleteFromArrayInCache({
    pathToItemInMutationData: 'deleteMessageClustersReport',
    variables: { botId },
    pathToArrayInCache: 'messageClusterReports',
    query: MESSAGE_CLUSTER_REPORTS,
    onNotInCache: console.error,
  });
  const [deleteReport] = useMutation(DELETE_MESSAGE_CLUSTERS_REPORT, { update });

  const onSubmit = useCallback(async () => {
    stopPolling();
    await deleteReport({ variables: { botId, reportId } });
  }, [botId, deleteReport, reportId, stopPolling]);

  return <DeleteModal onSubmit={onSubmit} hide={hide} name="this report" titleName="report" />;
};

DeleteMessageClustersReport.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    botId: IDType.isRequired,
    reportId: IDType.isRequired,
    stopPolling: PropTypes.func.isRequired,
  }),
};

export default DeleteMessageClustersReport;
