import { useQuery } from '@apollo/client';
import { formatISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BotMetaDocument, InboxStatusDocument } from 'frontend/api/generated';
import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import messengerLogo from 'frontend/assets/images/messenger-logo.svg?url';
import { Loader, Panel, Tooltip } from 'frontend/components';
import { useSageData } from 'frontend/features/Analytics/hooks';
import type { Granularity } from 'frontend/features/Analytics/types';
import { useHandoverMode } from 'frontend/features/Inbox/views/MetaData/hooks';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import useMe from 'frontend/hooks/useMe';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { selectActiveChatbubbles } from 'frontend/state/dux/analytics/dashboard';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

import styles from './styles.scss';
import ManagePermissions from '../ManagePermissions';

interface HeaderStatsProps {
  title: string;
  data: number | string;
  loading?: boolean;
  unit?: string;
  tooltip?: {
    key: string;
    text: string;
  }[];
}

function HeaderStats({ title, data, unit = '', tooltip = [], loading = false }: HeaderStatsProps) {
  if (typeof data === 'number') {
    data = data > 0 ? `${data}${unit}` : `-`;
  }

  return (
    <div className={styles.statContainer}>
      <Tooltip>
        <p>{title}</p>
        <h1>{loading ? <Loader /> : data}</h1>
        {tooltip.length > 0 && (
          <Tooltip.Body>
            {tooltip.map((item) => (
              <div key={item.key}>{item.text}</div>
            ))}
          </Tooltip.Body>
        )}
      </Tooltip>
    </div>
  );
}

const today = formatISO(new Date(), { representation: 'date' });

type FilterParams = {
  from: string;
  to: string;
  granularity: Granularity;
  languageCodes: string[];
  sources: string[];
};
const FILTERS: FilterParams = {
  from: today,
  to: today,
  granularity: 'day',
  languageCodes: [],
  sources: ['-test'],
};

export default function DashboardHeader({ organizationId }: { organizationId: string }) {
  const { data: meData, loading: meLoading } = useMe();
  const { isKindlyAdminManager } = useMyPermissions();
  const { botId } = useParams();
  const { data: botMetaData, loading: botMetaLoading } = useQuery(BotMetaDocument, { variables: { id: botId! } });
  const activeChatbubbles = useSelector(selectActiveChatbubbles);
  const scope = sageScope(SAGE_RESOURCE.BOT, botId);

  const { data: sessionsData, loading: sessionsLoading } = useSageData(scope, '/sessions/total', FILTERS, {
    apiVersion: 'v2',
  });
  const { data: handoversData, loading: handoversLoading } = useSageData(scope, '/takeovers/totals', FILTERS);
  const { data: fallbacksData, loading: fallbacksLoading } = useSageData(scope, '/fallbacks/total', FILTERS);

  const { mode, loading: handoverModeLoading } = useHandoverMode(botId);
  const isHandoverEnabled = mode === 'PLATFORM';
  const {
    data: statusData,
    loading: statusLoading,
    refetch: refetchStatus,
  } = useQuery(InboxStatusDocument, {
    variables: { botId: botId || '' },
  });
  usePusherEvent(getPusherChannel({ organizationId, isAvailability: true }), 'availability-updated', refetchStatus);
  const onlineAgentsNum = statusData?.inboxStatus?.agents.filter((agent) => agent.availability === 'ONLINE').length;
  const autoAgentsNum = statusData?.inboxStatus?.agents.filter((agent) => agent.availability === 'AUTO').length;
  const isHandoverOpen = statusData?.inboxStatus?.openForBusiness;

  if (botMetaLoading || !botMetaData?.bot || meLoading || !meData?.me || handoverModeLoading) {
    return <Loader size="large" />;
  }

  const {
    bot: { name, projectName, avatarUrl, languages, facebookPages },
  } = botMetaData;

  return (
    <Panel className={styles.noPaddingRight}>
      <div className={styles.headerContainer}>
        <div className={styles.botContainer}>
          <img alt={`${name} logo`} className={styles.botLogo} src={avatarUrl || defaultBotAvatar} />
          <div>
            <h1 className={styles.botName}>{name}</h1>
            <div className={styles.botProjectName}>{projectName}</div>
            {isKindlyAdminManager && <ManagePermissions me={meData.me} />}
          </div>
        </div>
        <div className={styles.metaContainer}>
          <div className={styles.languagesContainer}>
            {languages.map((language) => (
              <div className={styles.languageCode} key={language.code}>
                {language.code}
              </div>
            ))}
          </div>
          <div className={styles.connectContainer}>
            {facebookPages?.length > 0 && (
              <img className={styles.connectLogo} alt="Facebook logo" src={messengerLogo} />
            )}
          </div>
          <HeaderStats title="Open chats" data={activeChatbubbles?.length ?? 0} loading={sessionsLoading} />
          {isHandoverEnabled && (
            <HeaderStats
              title="Handover status"
              data={isHandoverOpen ? 'Open' : 'Closed'}
              tooltip={[
                { text: `Online agents: ${onlineAgentsNum}`, key: 'onlineAgents' },
                { text: `Auto agents: ${autoAgentsNum}`, key: 'autoAgents' },
              ]}
              loading={statusLoading}
            />
          )}
          <HeaderStats title="Handovers today" data={handoversData?.started ?? 0} loading={handoversLoading} />
          <HeaderStats
            title="Daily fallback"
            data={Math.round((fallbacksData?.rate ?? 0) * 100)}
            loading={fallbacksLoading}
            unit="%"
          />
          <HeaderStats title="Sessions today" data={sessionsData?.count ?? 0} />
        </div>
      </div>
    </Panel>
  );
}
