import styles from './styles.scss';
import animationStyles from '../animation.scss';

const ReplyCandidates = () => (
  <div className={styles.ghostContainer}>
    <div className={animationStyles.ghostStripe}>
      <div className={styles.ghostLines}>
        <svg viewBox="0 0 200 50" width="100%" height="60px" preserveAspectRatio="none">
          <defs>
            <mask id="mask2" x="0" y="0" width="100%" height="60">
              <rect x="0" y="0" width="100%" height="60" fill="#f7f7f7" />
              <rect x="0" y="5" rx="2" ry="2" width="100%" height="11" />
              <rect x="0" y="30" rx="2" ry="2" width="100%" height="11" />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="60px" mask="url(#mask2)" fill="#f7f7f7" fillOpacity="1" />
        </svg>
      </div>
    </div>
  </div>
);

export default ReplyCandidates;
