export default {
  INBOX: {
    view_conversations: 'View conversation and chat history',
    handover_chats: 'Handover conversations',
    manage_tickets: 'Manage tickets',
  },
  BUILD: {
    view_bot: 'View bot',
    view_templates: 'View dialogues',
    search_dialogues: 'Search dialogues',
    create_templates: 'Create new dialogues',
    edit_templates: 'Edit dialogues',
    delete_templates: 'Delete dialogues',
    view_skill: 'View skill',
    read_skill_content: 'View skill dialogues',
    create_skill_content: 'Create skill dialogues',
    update_skill_content: 'Edit skill dialogues',
    delete_skill_content: 'Delete skill dialogues',
  },
  SETUP: {
    invite_members: 'Invite members to the organization',
    connect_bot: 'Connect bot to external services',
    create_bots: 'Create bots',
    create_skills: 'Create skills',
    manage_applications: 'Manage custom applications',
    manage_skill: 'Manage skill',
    manage_bot: 'Manage bot',
    manage_organization: 'Manage organization',
  },
} as const;
