import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

import { BOT_ORGANIZATION } from '../queries';

export default ({ botId, skip = false }: { botId?: string; skip?: boolean }) => {
  const { botId: botIdFromParams } = useParams();

  const { data, loading } = useQuery(BOT_ORGANIZATION, {
    variables: { botId: botId || botIdFromParams },
    skip: skip || (!botId && !botIdFromParams),
  });

  const organization = get(data, 'bot.organization');

  return { organization, loading };
};
