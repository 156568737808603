import { Field, Form } from 'react-final-form';

import defaultLogo from 'frontend/assets/images/organization_avatar.svg?url';
import { Button, FileUpload, FormErrors, GuardUnsaved, Input, LoaderSwitch, PageBar } from 'frontend/components';
import Panel, { HorizontalRule } from 'frontend/components/Panel';
import { useModal } from 'frontend/features/Modals';
import OrganizationTimezonePicker from 'frontend/features/Organization/components/OrganizationTimezonePicker/OrganizationTimezonePicker';
import { chain, max, required } from 'frontend/form/validators';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { pluralize } from 'frontend/utils';

import styles from '../styles.scss';
import { useOrgSettings } from '../useOrgSettings';
import DeleteOrganization from './DeleteOrganization/DeleteOrganization';

const SUBSCRIBE = {};

export default function OrgSettings() {
  const { loading, onSubmit, initialValues, setFile } = useOrgSettings();

  const [showDeleteModal] = useModal(DeleteOrganization);
  const { isKindlyAdminManager } = useMyPermissions();

  return (
    <LoaderSwitch loading={loading} size="large">
      <Form
        onSubmit={onSubmit!}
        initialValues={initialValues}
        subscribe={SUBSCRIBE}
        render={({ handleSubmit, form, values }) => {
          const logo = values?.logo || defaultLogo;

          return (
            <>
              <GuardUnsaved />
              <form onSubmit={handleSubmit}>
                <PageBar>
                  <PageBar.FormButtons />
                </PageBar>
                <FormErrors className={styles.formErrors} />
                <div>
                  <Panel side>
                    <Field
                      component={Input}
                      label="Name"
                      name="name"
                      aria-label="Organization name"
                      placeholder="Organization name"
                      inputLimit={100}
                      validate={chain([required, max(100)])}
                    />
                    <p className={styles.helptext}>
                      Your organization name is displayed in menus and headings. It is normal to use, or include, the
                      name of your company.
                    </p>

                    <Field
                      component={Input}
                      label="Slug"
                      name="slug"
                      aria-label="slug"
                      placeholder="org-slug"
                      inputLimit={100}
                      validate={chain([required, max(100)])}
                    />
                    <p className={styles.helptext}>Your organization slug is only used in email domains.</p>

                    <label htmlFor="org-logo">Logo</label>
                    <div className={styles.logoField}>
                      <div className={styles.logo} style={{ backgroundImage: `url(${logo})` }} />
                      <div className="flex-column">
                        <Field component={Input} name="logo" hidden />
                        <FileUpload
                          text="Upload"
                          onUpload={(files) => {
                            if (!files || !files[0] || !setFile) {
                              return;
                            }
                            const [{ source, file }] = files;
                            form.change('logo', source);
                            setFile(file);
                          }}
                        />
                        <Button onClick={() => form.change('logo', null)} flat>
                          Delete
                        </Button>
                      </div>
                    </div>

                    <label className="m-t-2">Timezone</label>
                    <Field
                      component={OrganizationTimezonePicker}
                      label="Timezone"
                      name="timezone"
                      className={styles.timezonePicker}
                    />
                    <p className={styles.helptext}>
                      Pick a timezone for your organization. Used for displaying analytics in monthly reports.
                    </p>

                    <HorizontalRule />

                    <h3 className="m-b-md">{pluralize('Subscription', initialValues.subscriptions?.length ?? 0)}</h3>
                    {initialValues.subscriptions?.map((subscription) => (
                      <p key={subscription.id}>
                        Plan type: <strong>{subscription.planName}</strong>
                        <br />
                        Status: <strong>{subscription.isActive ? 'Active' : 'Inactive'}</strong>
                        <br />
                        Started at: <strong>{subscription.startedAt}</strong>
                        <br />
                        Ends at: <strong>{subscription.endedAt || 'No expiry'}</strong>
                      </p>
                    ))}

                    <HorizontalRule />
                    {isKindlyAdminManager && (
                      <Button
                        color="warning"
                        text="Delete organization"
                        onClick={() =>
                          showDeleteModal({
                            name: initialValues.name,
                            organizationId: initialValues.id,
                            redirect: '/organizations',
                          })
                        }
                      />
                    )}
                  </Panel>
                </div>
              </form>
            </>
          );
        }}
      />
    </LoaderSwitch>
  );
}
