import { gql } from '@apollo/client';

import ORGANIZATION_FRAGMENT from 'frontend/features/Organization/fragments/Organization.fragment.graphql';

export default gql`
  mutation SetEngagementId($organizationId: ID!, $engagementId: ID) {
    setEngagementId(organizationId: $organizationId, engagementId: $engagementId) {
      ...Organization
      engagementId
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
