import { useSelector } from 'react-redux';

import { Loader } from 'frontend/components';
import { selectLibrarySearch } from 'frontend/state/dux/library';

import styles from './LibrarySearch.scss';
import Searching from './components/Searching/Searching';

interface DialogueSearchHit {
  id: string;
  title: string;
  dialogueType: string;
  isActive: boolean;
  samplesCount: number;
  numberOfFollowups: number;
  colorLabel: Record<string, string>;
  labels: {
    id: string;
    name: string;
  }[];
  url: string;
  skill?: {
    id: string;
    skillSubscriptions: {
      id: string;
      bot: {
        id: string;
      };
    }[];
  };
}
interface NudgeHit {
  id?: number;
  slug?: string;
}

export interface SearchHits {
  dialogues: DialogueSearchHit[];
  nudges: NudgeHit[];
}

const LibrarySearch = () => {
  const { isLoading, isOpen } = useSelector(selectLibrarySearch);

  return (
    <div className={styles.searchWrapper}>
      {isOpen && isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      {isOpen && !isLoading && <Searching />}
    </div>
  );
};

export default LibrarySearch;
