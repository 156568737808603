import { useCallback } from 'react';

import { ATTACHMENT_STATUS } from '../../../constants';

export default (cache) => {
  const onError = useCallback(
    ({ attachments = [], error = 'Could not upload attachments' }) => {
      attachments.forEach((attachment) => {
        cache.modify({
          id: cache.identify(attachment),
          fields: { status: () => ATTACHMENT_STATUS.ERROR },
        });
      });

      throw new Error(error);
    },
    [cache],
  );

  return onError;
};
