import cx from 'classnames';

import styles from './CenteredComponent.scss';

interface CenteredComponentProps {
  children?: React.ReactNode;
  className?: string;
}

const CenteredComponent = ({ children, className }: CenteredComponentProps) => (
  <div className={cx(styles.centeredComponent, className)}>
    <div className={styles.centeredInner}>{children}</div>
  </div>
);

export default CenteredComponent;
