import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'frontend/components';

import styles from './NavigationButton.scss';

const NavigationButton = ({ text, onClick, active, mobileOnly, className }) => (
  <Button
    onClick={onClick}
    className={cx(className, styles.navigationButton, {
      [styles.navigationButtonActive]: active,
      [styles.navigationButtonMobile]: mobileOnly,
    })}
    flat
    text={text}
  />
);

NavigationButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  className: PropTypes.string,
};

export default NavigationButton;
