import { NudgeWrapper, Text } from '..';

const TextNudge = ({ nudge, currentLanguage }) => {
  const { title, bodyText, linkText } = nudge;

  return (
    <NudgeWrapper>
      <Text title={title[currentLanguage]} description={bodyText[currentLanguage]} link={linkText[currentLanguage]} />
    </NudgeWrapper>
  );
};

export default TextNudge;
