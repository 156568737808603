import { captureMessage } from '@sentry/browser';
import { debounce } from 'lodash';
import { useMemo } from 'react';

import { usePusherFailed } from 'frontend/features/Pusher';
import { useToast } from 'frontend/hooks';

const FAIL_MESSAGE = 'Unable to update Inbox – click here to reload the page';
const CONNECTING_MESSAGE = 'Connecting...';

// At least 5s between every call; don't delay first
const DEBOUNCE_CONFIG = [5000, { leading: true, trailing: false }];

const getOnPusherFailed = (toast) => () => {
  toast.error(FAIL_MESSAGE, {
    autoClose: false,
    closeOnClick: false,
    onClick: () => window.location.reload(),
    closeButton: true,
  });

  toast.closeWarning(CONNECTING_MESSAGE);
  captureMessage('Websocket connection lost');
};

const getOnPusherConnecting = (toast) => () => {
  toast.warning(CONNECTING_MESSAGE, { autoClose: false, closeOnClick: false, closeButton: true });
  toast.closeError(FAIL_MESSAGE);
};

const getOnPusherReconnected = (toast) => () => {
  toast.closeWarning(CONNECTING_MESSAGE);
  toast.closeError(FAIL_MESSAGE);
  toast.success('Connection reestablished');
};

export default () => {
  const toast = useToast();
  const onPusherFailed = useMemo(() => debounce(getOnPusherFailed(toast), ...DEBOUNCE_CONFIG), [toast]);
  const onPusherConnecting = useMemo(() => debounce(getOnPusherConnecting(toast), ...DEBOUNCE_CONFIG), [toast]);
  const onPusherReconnected = useMemo(() => debounce(getOnPusherReconnected(toast), ...DEBOUNCE_CONFIG), [toast]);

  usePusherFailed(onPusherFailed, onPusherConnecting, onPusherReconnected);
};
