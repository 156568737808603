import cx from 'classnames';
import { get } from 'lodash';
import { type HTMLInputTypeAttribute, forwardRef, useCallback, useEffect, useRef } from 'react';
import type { FieldValues, Path, RegisterOptions, UseFormReturn } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import type { FIELD_COLOR } from 'frontend/constants';
import * as rhf from 'frontend/constants/rhf';

import styles from './Input.scss';
import InputLimit from './InputLimit';
import InputErrorWrapper from '../InputErrorWrapper';
import type { ErrorPosition } from '../InputErrorWrapper/InputErrorWrapper';

type FieldColor = (typeof FIELD_COLOR)[keyof typeof FIELD_COLOR];
type InputSize = 'small' | 'medium';
type AdornmentPosition = 'left' | 'right';

interface Props<T extends FieldValues> {
  /** The RHF field name. */
  name: Path<T>;
  /** The RHF methods returned by useForm(). */
  rhfMethods: UseFormReturn<T>;
  /** The RHF register options. */
  rhfOptions?: RegisterOptions<T>;
  size?: InputSize;
  inputType?: HTMLInputTypeAttribute;
  onKeyDown?: () => void;
  onKeyPress?: () => void;
  onFocus?: () => void;
  className?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
  adornment?: React.ReactNode;
  adornmentPosition?: AdornmentPosition;
  inputLimit?: number;
  maxLength?: number;
  placeholder?: string;
  label?: string | React.ReactNode;
  fieldColor?: FieldColor;
  'data-testid'?: string;
  'aria-label'?: string;
  multiline?: boolean;
  labelClassName?: string;
  forceValidate?: boolean;
  focusOnError?: boolean;
  errorPosition?: ErrorPosition;
  min?: number | string;
  max?: number | string;
  minRows?: number;
}

function InputRHF<T extends FieldValues>(
  {
    name,
    rhfMethods,
    rhfOptions = {},
    onKeyDown,
    onKeyPress,
    onFocus,
    inputType = 'text',
    inputLimit,
    className,
    hidden,
    readOnly,
    autoComplete,
    autoFocus,
    size = 'medium',
    adornment,
    adornmentPosition,
    placeholder,
    label,
    maxLength,
    multiline,
    fieldColor = 'white',
    'aria-label': ariaLabel,
    'data-testid': dataTestid,
    labelClassName,
    forceValidate,
    focusOnError = true,
    errorPosition,
    min,
    max,
    minRows,
  }: Props<T>,
  externalRef: React.Ref<HTMLTextAreaElement | HTMLInputElement>,
): React.JSX.Element {
  const ref = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null);
  const adornmentRef = useRef<HTMLDivElement>(null);

  const {
    register,
    formState: { errors },
    watch,
  } = rhfMethods;

  const rhfError = get(errors, name);
  const hasError = Boolean(forceValidate || rhfError);

  const errorMessage =
    (rhfError?.message as string) ||
    rhf.defaultValidationErrors[rhfError?.type as keyof typeof rhf.defaultValidationErrors] ||
    '';

  const focus = useCallback(
    () => ((externalRef as React.MutableRefObject<HTMLInputElement | null>)?.current ?? ref.current)?.focus?.(),
    [externalRef],
  );

  useEffect(() => {
    if (hasError && errorMessage && focusOnError) focus();
  }, [focus, errorMessage, focusOnError, hasError]);

  const inputClassName = cx(styles.formControl, {
    [styles.formControlSmall]: size === 'small',
    [styles.formControlMedium]: size === 'medium',
    [styles.formControlReadOnly]: readOnly,
    [styles.formControlHidden]: hidden,
    [styles.formControlError]: hasError,
    [styles.formControlIconPaddingLeft]: adornmentPosition === 'left',
    [styles.formControlMischka]: fieldColor === 'mischka',
  });

  const adornmentClassName = cx(styles.inlineAdornment, {
    [styles.adornmentPositionRight]: adornmentPosition === 'right',
    [styles.adornmentPositionLeft]: adornmentPosition === 'left',
  });

  const InputComponent = multiline ? TextareaAutosize : 'input';

  const labelClass = cx(styles.labelWrapper, labelClassName);

  const { ref: refRegister, ...restRegister } = register(name, rhfOptions);

  return (
    <>
      {!!label && (
        <div className={labelClass}>
          <label htmlFor={name}>{label}</label>
          {!!inputLimit && !adornment && (
            <InputLimit hasError={hasError} currentInput={watch(name)?.length} limit={inputLimit} />
          )}
        </div>
      )}
      <InputErrorWrapper
        hasError={hasError}
        className={className}
        errorMessageClassName={styles.errorMessage}
        displayError={errorMessage}
        errorPosition={errorPosition}
      >
        <InputComponent
          id={name}
          onFocus={onFocus}
          ref={(e) => {
            refRegister(e);
            ref.current = e;
          }}
          readOnly={readOnly}
          type={inputType}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          className={inputClassName}
          placeholder={placeholder}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          maxLength={maxLength}
          data-testid={dataTestid}
          aria-label={ariaLabel}
          min={min}
          max={max}
          {...(minRows && { minRows })}
          style={{
            ...(adornmentPosition === 'left' && { paddingLeft: (adornmentRef.current?.clientWidth || 0) + 14 }),
            ...(adornmentPosition === 'right' && { paddingRight: (adornmentRef.current?.clientWidth || 0) + 14 }),
          }}
          {...restRegister}
        />
        {adornment && (
          <div ref={adornmentRef} className={adornmentClassName}>
            {adornment}
          </div>
        )}
      </InputErrorWrapper>
    </>
  );
}

export default forwardRef(InputRHF) as <T extends FieldValues>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLTextAreaElement | HTMLInputElement> },
) => React.ReactElement;
