import PropTypes from 'prop-types';

import { Panel } from 'frontend/components';

import styles from './ClusterList.scss';
import { SalientTokensType } from '../../propTypes';
import ClusterItem from '../ClusterItem';

const ClusterList = ({ salientTokensList, currentClusterIndex, selectCluster }) => (
  <Panel className={styles.panel}>
    {salientTokensList.map((salientTokens, idx) => (
      <ClusterItem
        /* eslint-disable-next-line react/no-array-index-key */
        key={`message-cluster-${idx}`}
        index={idx}
        currentClusterIndex={currentClusterIndex}
        selectCluster={selectCluster}
        salientTokens={salientTokens}
      />
    ))}
  </Panel>
);

ClusterList.propTypes = {
  currentClusterIndex: PropTypes.number.isRequired,
  selectCluster: PropTypes.func.isRequired,
  salientTokensList: PropTypes.arrayOf(SalientTokensType).isRequired,
};

export default ClusterList;
