import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { EmojiSad } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { DEFAULT_DIALOGUE_LABEL_COLOR, DIALOGUE_LABEL_COLORS } from 'frontend/features/Library/constants';
import { ColorLabelType } from 'frontend/features/Library/propTypes';

import { DialogueActions } from '..';
import styles from './DialogueRow.scss';
import { SectionRow } from '../../../components';

const getColor = (currentLanguage, colorLabel) =>
  DIALOGUE_LABEL_COLORS.get(colorLabel?.[currentLanguage]) ?? DEFAULT_DIALOGUE_LABEL_COLOR;

const DialogueRow = ({ currentLanguage, dialogue, setIgnoredDialogues, ignoredDialogues }) => {
  const { botId } = useParams();
  const { id, title, samplesCount, colorLabel } = dialogue;

  const setIsIgnored = useCallback(() => {
    setIgnoredDialogues([...ignoredDialogues, id]);
  }, [setIgnoredDialogues, ignoredDialogues, id]);

  const progressPercentage = useMemo(() => (samplesCount[currentLanguage] / 20) * 100, [currentLanguage, samplesCount]);

  const color = getColor(currentLanguage, colorLabel);

  return (
    <SectionRow>
      <div className={styles.wrapper}>
        <div className={styles.dialogueRow}>
          <div className={styles.icon}>
            <Icon component={EmojiSad} color="primary" />
          </div>
          <div className={styles.dialogue}>
            <div style={{ backgroundColor: color }} className={styles.icon} />
            <div className={styles.title}>{title[currentLanguage]}</div>
          </div>
        </div>
        <div className={styles.progressBar}>
          <div>
            <span className={styles.total} />
            <span className={styles.overlay} style={{ width: `${progressPercentage}%` }} />
          </div>
          <span className={styles.stats}>{samplesCount[currentLanguage]}/20</span>
        </div>
      </div>
      <DialogueActions dialogueId={id} botId={botId} setIgnored={setIsIgnored} />
    </SectionRow>
  );
};

DialogueRow.propTypes = {
  currentLanguage: PropTypes.string,
  setIgnoredDialogues: PropTypes.func,
  dialogue: PropTypes.shape({}),
  colorLabel: ColorLabelType,
  ignoredDialogues: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
};

export default DialogueRow;
