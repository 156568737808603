import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';

import { Badge, FormErrors, GuardUnsaved, Input, LoaderSwitch, PageBar, Panel } from 'frontend/components';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';

import chatBubbleStyles from '../../styles.scss';

const placeholder = `-----BEGIN PUBLIC KEY-----
...
-----END PUBLIC KEY-----`;

const SUBSCRIBE = {};

const Authentication = () => {
  const { onSubmit, initialValues, loading, languagesLoading, meLoading } = useChatbubbleForm();

  return (
    <LoaderSwitch loading={loading || languagesLoading || meLoading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        subscribe={SUBSCRIBE}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={chatBubbleStyles.formErrors} />
              <div className={chatBubbleStyles.chatbubbleSettingsWrapper}>
                <Panel>
                  <h3 className="m-b-lg">
                    Authentication <Badge title="Developer" tooltip="This is an advanced developer setting" />
                  </h3>
                  <p>
                    For authentication tokens to be verified and relayed by the Kindly chat bubble, paste your PEM
                    formatted <strong>public key</strong> below.{' '}
                  </p>
                  <div className={chatBubbleStyles.inputContainer}>
                    <Field
                      name="chatbubbleAuthPublicKey"
                      label="Public Key"
                      component={Input}
                      placeholder={placeholder}
                      validate={(val) => {
                        if (!val || !val.trim()) {
                          return undefined;
                        }

                        if (val.trim().match(/^-----BEGIN PUBLIC KEY-----\n.+\n-----END PUBLIC KEY-----$/gms)) {
                          return undefined;
                        }

                        return `Must match public key format, i.e. ${placeholder}`;
                      }}
                      multiline
                    />
                  </div>
                  <p>
                    Read more{' '}
                    <a href="https://docs.kindly.ai/api/chat#authentication" target="_blank" rel="noopener noreferrer">
                      in our documentation
                    </a>{' '}
                    about how to setup authentication.
                  </p>
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
};

export default Authentication;
