import { gql } from '@apollo/client';

import { ENTITY } from '../fragments';

export default gql`
  mutation CreateEntity($botId: ID, $skillId: ID, $languageCode: String!, $entityInput: EntityInputType!) {
    createEntity(botId: $botId, skillId: $skillId, languageCode: $languageCode, entityInput: $entityInput) {
      ...Entity
    }
  }
  ${ENTITY}
`;
