import cx from 'classnames';
import { compose, filter, flatten, identity, join, map, replace, split, toString, trim, uniq } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import TagsInput from 'react-tagsinput';

import { useActionFeedback } from 'frontend/hooks';

import styles from './Tags.scss';
import feedbackStyles from './TagsFeedback.scss';

const TAB_KEY_CODE = 9;
const ENTER_KEY_CODE = 13;
const DEFAULT_ADD_KEYS = [TAB_KEY_CODE, ENTER_KEY_CODE];
const EMPTY_ARRAY = [];

const splitOnComma = compose(split(','), toString);

const newLineToComma = compose(replace(/\n/g, ', '), toString);

const commonFormatter = compose(uniq, filter(identity), map(trim), flatten, map(split(',')));

interface TagsProps {
  /** Object data for the `<input>` */
  input: {
    onChange(tags): void;
    value?: string | string[];
  };
  /** Whether to make tags disabled to interaction. */
  disabled?: boolean;
  /** `<input>` placeholder. */
  placeholder?: string;
  storeAsJson?: boolean;
  /** Tags Header text. */
  tagsHeader?: string;
  /** Input Footer text. */
  inputFooter?: string;
  /** Keys that add the input text to the tag list; the array takes the key's keycode. */
  addKeys?: number[];
  id?: string;
}

/** It shows an `<input>` field with a list of tags underneath. */
const Tags = ({
  id,
  input: { onChange, value },
  addKeys = DEFAULT_ADD_KEYS,
  placeholder = 'Enter keyword',
  tagsHeader = '',
  inputFooter = '',
  disabled = false,
  storeAsJson = false,
}: TagsProps) => {
  const [tagWasAdded, onAddTag] = useActionFeedback();
  const tagsValue = useMemo(() => (storeAsJson ? value || [] : splitOnComma(value)), [storeAsJson, value]);
  const formatter = useMemo(() => (storeAsJson ? commonFormatter : compose(join(','), commonFormatter)), [storeAsJson]);
  const inputProps = useMemo(() => ({ placeholder }), [placeholder]);

  const handleChange = useCallback(
    (tags) => {
      onChange(formatter(tags));
      onAddTag();
    },
    [formatter, onAddTag, onChange],
  );

  const renderLayout = useCallback(
    (tagComponents, { props: layoutProps, ref }) => {
      const inputClassNames = cx(feedbackStyles.addTagFeedback, {
        [feedbackStyles.addTagFeedbackShow]: tagWasAdded,
      });

      const onPaste = (event) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text/plain');
        const currentText = ref.current?.value ?? '';
        const concatenatedText = `${currentText ? `${currentText}, ` : ''}${pastedText}`;
        const inputEvent = { target: { value: newLineToComma(concatenatedText) } };
        layoutProps.onChange(inputEvent);
      };

      return (
        <span>
          <div className={inputClassNames}>
            <input
              id={id}
              placeholder={placeholder}
              value={layoutProps.value}
              className={layoutProps.className}
              onChange={layoutProps.onChange}
              onKeyDown={layoutProps.onKeyDown}
              onPaste={onPaste}
              ref={ref}
            />
          </div>
          {!!inputFooter && <i className={styles.footer}>{inputFooter}</i>}
          {!!tagsHeader && <h4 className="m-t-md">{tagsHeader}</h4>}
          {tagComponents}
        </span>
      );
    },
    [id, inputFooter, placeholder, tagsHeader, tagWasAdded],
  );

  return (
    <TagsInput
      className="tags"
      addKeys={addKeys}
      inputProps={inputProps}
      removeKeys={EMPTY_ARRAY}
      preventSubmit={false}
      onChange={handleChange}
      value={tagsValue}
      disabled={disabled}
      renderLayout={renderLayout}
    />
  );
};

export default Tags;
