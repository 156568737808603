import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { Close } from 'frontend/assets/icons';
import { Badge, Button, Input, Tooltip } from 'frontend/components';
import { chain, max, required } from 'frontend/form/validators';

import styles from './ContextFields.scss';

const DataCell = ({
  rowData,
  data,
  columnName,
  columnData: { activeField, submitWrapper, onFieldUpdate, editField },
}) => {
  if (
    activeField?.[columnName] === data &&
    activeField?.columnName === columnName &&
    rowData.__typename !== 'BuiltinContextFieldType'
  ) {
    return (
      <Form
        onSubmit={submitWrapper(onFieldUpdate)}
        initialValues={activeField}
        render={({ handleSubmit, form }) => (
          <form
            onSubmit={(event) => handleSubmit(event).then(() => form.reset())}
            className={styles.editForm}
            autoComplete="off"
          >
            <Field
              component={Input}
              name={columnName}
              inputLimit={255}
              validate={chain([required, max(255)])}
              autoFocus
            />
            <Button type="submit" color="primary" text="Save" className={styles.save} />
            <Button
              flat
              icon={Close}
              onClick={() => {
                form.reset();
                editField(null);
              }}
            />
          </form>
        )}
      />
    );
  }

  const editFieldData = { ...rowData, columnName };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      editField(editFieldData);
      e.stopPropagation();
      e.preventDefault();
    }
  };
  const tooltip = columnName === 'key' ? 'Cannot be changed' : 'Go to settings to edit';
  const isBuiltIn = rowData.__typename === 'BuiltinContextFieldType';

  if (isBuiltIn) {
    return (
      <div>
        <span>{data}</span>
        <Badge title="Built in" tooltip={tooltip} />
      </div>
    );
  }

  return (
    <Tooltip>
      <div
        onClick={() => editField(editFieldData)}
        role="button"
        tabIndex={0}
        onKeyPress={onKeyPress}
        className={styles.enableForm}
      >
        <span>{data}</span>
      </div>
      <Tooltip.Body>Click to edit</Tooltip.Body>
    </Tooltip>
  );
};

DataCell.propTypes = {
  data: PropTypes.string.isRequired,
  rowData: PropTypes.shape({
    __typename: PropTypes.string,
  }).isRequired,
  columnName: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    activeField: PropTypes.shape({}),
    submitWrapper: PropTypes.func,
    onFieldUpdate: PropTypes.func,
    editField: PropTypes.func,
  }),
};

export default DataCell;
