import { Form } from 'react-final-form';

import { LoaderSwitch, PageBar } from 'frontend/components';
import { useGeneralForm } from 'frontend/features/BotSettings/hooks';

import BetaForm from './BetaForm';
import generalStyles from '../../styles.scss';

const Beta = () => {
  const { onSubmit, initialValues, loading } = useGeneralForm();

  return (
    <LoaderSwitch loading={loading} size="large">
      <div className={generalStyles.generalLayout}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>

              <div>
                <BetaForm />
              </div>
            </form>
          )}
        />
      </div>
    </LoaderSwitch>
  );
};

export default Beta;
