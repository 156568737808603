import cx from 'classnames';
import { capitalize, cloneDeep, invert } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef } from 'react';
import { Field } from 'react-final-form';
import InputAutosize from 'react-input-autosize';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Folder } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import Breadcrumbs from 'frontend/components/Breadcrumbs/Breadcrumbs';
import { DialogueIcon } from 'frontend/features/Library/components';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import { selectCurrentBreadcrumbs } from 'frontend/state/dux/build';

import styles from './DialoguePath.scss';
import { libraryItemTypes } from './constants';

const folderTypeValueToKey = invert(libraryItemTypes);

const formatType = (type) => capitalize(folderTypeValueToKey[type].toLowerCase().replace('_', ' '));

const getParentUrl = (buildId, buildType, type, id) =>
  `/${buildType}/${buildId}/build/${folderTypeValueToKey[type].replace('_', '-').toLowerCase()}/${id}`;

function FolderElement({ name, type }) {
  return (
    <span className={styles.folderElement}>
      <Icon component={Folder} />
      {name || formatType(type)}
    </span>
  );
}

FolderElement.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
};

function ParentElement({ name, currentLanguage, id, buildType, buildId, type, hasFolderParentInCollapsed }) {
  return (
    <Link
      to={getParentUrl(buildId, buildType, type, id)}
      className={cx(styles.parentElement, { [styles.hasFolderParentInCollapsed]: hasFolderParentInCollapsed })}
    >
      {name[currentLanguage] || name.default}
    </Link>
  );
}

ParentElement.propTypes = {
  buildId: PropTypes.string.isRequired,
  name: PropTypes.shape({ default: PropTypes.string }).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  buildType: PropTypes.string.isRequired,
  type: PropTypes.string,
  hasFolderParentInCollapsed: PropTypes.bool,
};

function InputElement({ selectedLanguage, currentLanguage, dialogueLabelColorData, readOnly, isActive }) {
  const inputRef = useRef(null);
  const onKeyDown = useCallback((e) => {
    if ((e.metaKey && e.key === 's') || e.key === 'Enter' || e.key === 'Escape') inputRef.current.blur();
  }, []);
  const disabled = !isActive[selectedLanguage];
  const classNames = cx(styles.inputWrapper, { [styles.inputWrapperDisabled]: disabled });

  return (
    <div className={classNames} onKeyDown={onKeyDown} role="button" tabIndex={0}>
      <DialogueIcon currentLanguage={currentLanguage} className={styles.inputIcon} {...dialogueLabelColorData} />
      <Field name={`title.${currentLanguage}`}>
        {({ input: { value, onChange } }) => (
          <InputAutosize
            value={value}
            onChange={onChange}
            ref={inputRef}
            tabIndex={readOnly ? -1 : 0}
            placeholder="Dialogue title (click to edit)"
            readOnly={readOnly}
          />
        )}
      </Field>
    </div>
  );
}

InputElement.propTypes = {
  dialogueLabelColorData: PropTypes.shape({
    colorLabel: PropTypes.shape({}),
    dialogueType: PropTypes.string,
  }).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  isActive: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
};

const skillSubscriptionElement = ({ subscribedSkillName }) => [
  <FolderElement key="folder-skills" name="Skills" />,
  ...(subscribedSkillName ? [<FolderElement key="subscribed-skill" name={subscribedSkillName} />] : []),
];

const getElementType = ({ type }, subscribedSkillName) => {
  if (type === libraryItemTypes.TOPIC) return FolderElement;

  if (type === libraryItemTypes.SKILLS) return skillSubscriptionElement;

  if (
    [
      libraryItemTypes.DIALOGUE,
      libraryItemTypes.GREETING,
      libraryItemTypes.FALLBACK,
      libraryItemTypes.SYSTEM_DIALOGUE,
    ].includes(type)
  ) {
    return ParentElement;
  }

  if (subscribedSkillName) return null; // We don't want to display 'Dialogues' for subscriptions

  if (
    [
      libraryItemTypes.DIALOGUES,
      libraryItemTypes.GREETINGS,
      libraryItemTypes.FALLBACKS,
      libraryItemTypes.SYSTEM_DIALOGUES,
    ].includes(type)
  ) {
    return FolderElement;
  }

  return null;
};

const pathnameToSpecialFolderType = ({ pathname }) => {
  if (pathname.match('/greeting/')) return libraryItemTypes.GREETINGS;
  if (pathname.match('/fallback/')) return libraryItemTypes.FALLBACKS;
  if (pathname.match('/system-dialogue/')) return libraryItemTypes.SYSTEM_DIALOGUES;
  return null;
};

const DialoguePath = ({ readOnly, subscribedSkillName, isActive, dialogueLabelColorData }) => {
  const location = useLocation();
  const currentBreadcrumbs = useSelector(selectCurrentBreadcrumbs);

  const [{ selectedLanguage, currentLanguage }] = useCurrentLanguage();
  const { buildType, buildId } = useBotOrSkill();

  const breadcrumbs = useMemo(() => cloneDeep(currentBreadcrumbs), [currentBreadcrumbs]);

  const MAX_BREADCRUMBS = 3;

  const numberOfFolderParents = breadcrumbs?.reduce((acc, crumb) => {
    if (crumb.type === libraryItemTypes.TOPIC) return acc + 1;
    return acc;
  }, 0);

  if (location.pathname.match('/new')) {
    return (
      <InputElement
        selectedLanguage={selectedLanguage}
        currentLanguage={currentLanguage}
        isActive={isActive}
        dialogueLabelColorData={dialogueLabelColorData}
      />
    );
  }

  const initialFolders = [];
  const folderType = pathnameToSpecialFolderType(location);
  if (folderType) {
    initialFolders.push(<FolderElement key={`folder-${folderType}`} type={folderType} />);
    (breadcrumbs || []).shift();
  }

  return (
    <div className={cx(styles.dialoguePath)}>
      <Breadcrumbs
        itemsAfterCollapse={1}
        itemsBeforeCollapse={1}
        maxItems={MAX_BREADCRUMBS}
        classNames={{
          container: styles.breadcrumbsContainer,
          li: styles.breadcrumbsLi,
          dropdownOverlay: styles.breadcrumbsDropdownOverlay,
          dropdownOverlayUl: styles.breadcrumbsDropdownOverlayUl,
          dropdownOverlayLi: styles.breadcrumbsDropdownOverlayLi,
        }}
        overlayPosition={{ position: 'custom' }}
      >
        {initialFolders}
        {(breadcrumbs || []).flatMap((breadcrumb) => {
          const { type, id } = breadcrumb;
          const Component = getElementType(breadcrumb, subscribedSkillName);

          const isInCollapsedWithFolderParent = breadcrumbs.length >= MAX_BREADCRUMBS && numberOfFolderParents > 0;

          if (Component == null) return [];

          if (type === libraryItemTypes.SKILLS) return Component({ subscribedSkillName });

          return (
            <Component
              key={`breadcrumb-${type}${id}`}
              {...breadcrumb}
              currentLanguage={currentLanguage}
              buildType={buildType}
              buildId={buildId}
              subscribedSkillName={subscribedSkillName}
              hasFolderParentInCollapsed={isInCollapsedWithFolderParent}
            />
          );
        })}
        <InputElement
          selectedLanguage={selectedLanguage}
          currentLanguage={currentLanguage}
          readOnly={readOnly}
          isActive={isActive}
          dialogueLabelColorData={dialogueLabelColorData}
        />
      </Breadcrumbs>
    </div>
  );
};

DialoguePath.propTypes = {
  dialogueLabelColorData: PropTypes.shape({
    colorLabel: PropTypes.shape({}),
    dialogueType: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
  subscribedSkillName: PropTypes.string,
  isActive: PropTypes.shape({}).isRequired,
};

export default DialoguePath;
