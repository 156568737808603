import { Link } from 'react-router-dom';

import { Exclamation } from 'frontend/assets/icons';
import { Button, PageContent } from 'frontend/components';

import styles from './styles.scss';
import { Icon } from '../Icon/Icon';

const ErrorState = () => (
  <PageContent className={styles.errorState}>
    <Icon component={Exclamation} className={styles.exclamationIcon} />
    <h2>Something went wrong...</h2>
    <p className="m-b-0">You can try navigating to a different page, or reload the page.</p>
    <p className="m-b-3">
      Discovered a bug?{' '}
      <Link className="link" to="/feedback">
        Reach out to our support team.
      </Link>
    </p>
    <Button color="primary" onClick={() => window.location.reload()} text="Reload" />
  </PageContent>
);

export default ErrorState;
