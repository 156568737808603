import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { Field } from 'react-final-form';

import { AllPluginsDocument } from 'frontend/api/generated';
import { Input, LoaderSwitch, Select } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { IDType } from 'frontend/propTypes';
import { getArray, mapToObject } from 'frontend/utils';

import styles from './AddPluginInstance.scss';

const PluginSelect = ({ plugins, setPlugin, plugin }) => (
  <div className={styles.selectContainer}>
    <Select
      input={{
        name: 'plugins',
        value: plugin ? plugin.name : 'Choose plugin',
        onChange: (e) => {
          setPlugin(plugins.find((p) => p.name === e.target.value));
        },
      }}
    >
      <Select.Option key={0} value="Choose plugin" label="Choose plugin" />
      {plugins.map((p) => (
        <Select.Option key={p.id} value={p.name} label={p.name} />
      ))}
    </Select>

    {plugin && <p className="m-b-n">{plugin.description}</p>}
  </div>
);

const PluginContext = ({ plugin }) => (
  <div className={styles.contextContainer}>
    <div className={`${styles.inputContainer} m-t-sm`}>
      <h4>Required context</h4>
      <p className="m-n">Add the following as a slot key in a pattern dialogue:</p>
      <ul className={`p-n ${styles.contextList}`}>
        {plugin.contextKeys.map((contextKey) => (
          <li key={`plugin-context-${contextKey.key}`}>
            <p className={`${styles.contextPoint} m-b-n`}>{contextKey.key}</p>
            <p className={`${styles.contextDescription} m-t-sm m-b-sm`}>{contextKey.description}</p>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const PluginSetup = ({ plugin }) => (
  <div className={styles.setupContainer}>
    <p className="m-n">These texts can be edited in the trigger dialogues created by this plugin after saving.</p>
    {plugin.templateDialogues.map((dialogue) => (
      <div className={`${styles.inputContainer} m-t-sm`} key={dialogue.slug}>
        <label>{dialogue.title}</label>
        <Field component={Input} name={dialogue.slug} validate={required} multiline readOnly />
      </div>
    ))}
  </div>
);

const MAX_STEP = 2;

const AddPluginInstance = ({ hide, args: { dialogueId, change } }) => {
  const [plugin, setPlugin] = useState();
  const [step, setStep] = useState(0);
  const { data, loading } = useQuery(AllPluginsDocument);
  const plugins = getArray('allPlugins', data);

  const save = useCallback(
    (values) => {
      const pluginTemp = {
        plugin,
        dialogueId,
        pluginId: plugin.id,
        replies: values,
      };

      change('pluginTemp', pluginTemp);
      hide();
    },
    [change, dialogueId, hide, plugin],
  );

  const onSubmit = useCallback(
    (values) => {
      if (!plugin) return;
      if (step < MAX_STEP) setStep(step + 1);
      else save(values);
    },
    [plugin, save, step],
  );

  const onCancel = useCallback(() => {
    if (step > 0) setStep(step - 1);
    else hide();
  }, [hide, step]);

  const initialValues = useMemo(
    () => mapToObject(({ slug, text }) => ({ [slug]: text }), plugin?.templateDialogues ?? []),
    [plugin],
  );

  return (
    <ModalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      title={plugin ? `Plugin setup - ${plugin.name}` : 'Plugin setup'}
      hide={hide}
      onOkText={step === MAX_STEP ? 'Add plugin' : 'Next'}
      onCancelText={step === 0 ? 'Cancel' : 'Back'}
      onCancel={onCancel}
      closeOnSubmit={false}
      ignoreDirty
    >
      <LoaderSwitch loading={loading} size="large" noMargin>
        {!dialogueId ? (
          <div className>
            <p className="m-t-sm">
              You must save the dialogue to generate its ID, which is required to set up a plugin.
            </p>
          </div>
        ) : (
          <>
            {step === 0 && <PluginSelect plugins={plugins} setPlugin={setPlugin} plugin={plugin} />}
            {step === 1 && <PluginContext plugin={plugin} values={initialValues} />}
            {step === 2 && <PluginSetup plugin={plugin} values={initialValues} />}
          </>
        )}
      </LoaderSwitch>
    </ModalForm>
  );
};

AddPluginInstance.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ dialogueId: IDType, change: PropTypes.func.isRequired }).isRequired,
};

export default AddPluginInstance;
