import { useQuery } from '@apollo/client';

import { MeDocument, type MeQuery } from 'frontend/api/generated';

type Return = { data?: MeQuery; loading: boolean };

export default function useMe(): Return {
  const { data, loading } = useQuery(MeDocument);

  return { data, loading };
}
