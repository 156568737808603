import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { KINDLY_ENTITY_REPORT } from '../../queries';

export const useReport = ({ kindlyEntityReportId, botId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data, loading } = useQuery(KINDLY_ENTITY_REPORT, {
    variables: { id: kindlyEntityReportId, botId },
  });

  const dialogues = useMemo(() => get(data, 'kindlyEntityReport.dialogues', []), [data]);

  const numberFound = dialogues.length;
  const foundKindlyEntities = numberFound > 0;

  const next = useCallback(() => setCurrentIndex((idx) => (idx + 1) % numberFound), [numberFound]);
  const previous = useCallback(() => setCurrentIndex((idx) => (idx - 1 + numberFound) % numberFound), [numberFound]);

  const currentDialogue = useMemo(() => dialogues[currentIndex], [currentIndex, dialogues]);
  const currentPage = `${currentIndex + 1} / ${numberFound}`;

  return { dialogues, foundKindlyEntities, next, previous, currentPage, currentDialogue, loading };
};
