import { gql } from '@apollo/client';

export default gql`
  query IsDialogueInTopic($botId: ID, $skillId: ID, $topicId: ID!, $dialogueId: UUID!) {
    dialogue(botId: $botId, skillId: $skillId, id: $dialogueId) {
      id
      isInTopic(topicId: $topicId)
    }
  }
`;
