import cx from 'classnames';

import ActivityLog from './ActivityLog';
import InboxChats from './InboxChats';
import InboxConversation from './InboxConversation';
import InboxMetaData from './InboxMetaData';
import styles from './Loader.scss';
import ReplyCandidates from './ReplyCandidates';
import Rolling from './Rolling';
import SupportAgents from './SupportAgents';
import Table from './Table';

export type LoaderType =
  | 'inbox_chats'
  | 'inbox_meta_data'
  | 'inbox_conversation'
  | 'reply_candidates'
  | 'support_agents'
  | 'table'
  | 'activity_log';

export type LoaderSize = 'small' | 'medium' | 'large';

interface LoaderProps {
  size?: LoaderSize;
  /** Selects a Custom `<Loader>` for pre-defined pages. */
  type?: LoaderType;
  strokeColor?: string;
  className?: string;
  /** Text to display together with the `<Loader>` */
  text?: string;
  noMargin?: boolean;
  /** Options object (required) to pass for some custom loaders, e.g. `<Table>` Loader. */
  options?: { columns: number; rows?: number };
  animate?: boolean;
}

const Loader = ({
  className,
  type,
  text,
  noMargin,
  options,
  strokeColor = 'var(--grayscale60)',
  size = 'small',
  animate = true,
}: LoaderProps) => {
  const classNames = cx(
    styles.loader,
    {
      [styles.loaderSmall]: size === 'small',
      [styles.loaderMedium]: size === 'medium',
      [styles.loaderLarge]: size === 'large',
      [styles.loaderNoMargin]: noMargin,
    },
    className,
  );
  // TODO: Feature specific loaders should pass {{ children }} instead of bloating the general purpose component
  switch (type) {
    case 'inbox_chats':
      return <InboxChats />;
    case 'inbox_meta_data':
      return <InboxMetaData />;
    case 'inbox_conversation':
      return <InboxConversation />;
    case 'reply_candidates':
      return <ReplyCandidates />;
    case 'support_agents':
      return <SupportAgents />;
    case 'table':
      if (!options) throw Error('options cannot be undefined when type="table"');
      return <Table options={options} />;
    case 'activity_log':
      return <ActivityLog />;
    default:
      return (
        <div className={classNames}>
          <Rolling stroke={strokeColor} animate={animate} />
          {Boolean(text) && <div>{text}</div>}
        </div>
      );
  }
};

export default Loader;
