import cx from 'classnames';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { Tag } from 'frontend/components';
import { SIZES } from 'frontend/components/Tag/constants';
import { ChildrenType } from 'frontend/propTypes';

import styles from './EntityTag.scss';
import { hueToEntityColor } from '../../utils';

const getTooltipText = ({ isKindlyEntity, lessInfo, description, items }) => {
  if (lessInfo) return undefined;
  if (isKindlyEntity) return description;
  if (items?.length > 0) return items.join('\n');
  return undefined;
};

const EntityTag = ({ name, hue, items, children, removeTag, transparent, lessInfo, description, size, className }) => {
  const isKindlyEntity = !items && !!description;

  const style = useMemo(
    () => (!isNil(hue) && !isKindlyEntity ? { background: hueToEntityColor(hue, transparent) } : {}),
    [hue, isKindlyEntity, transparent],
  );

  const entityTagClassNames = cx(className, {
    [styles.tagKindly]: isKindlyEntity,
    [styles.tagKindlyTransparent]: isKindlyEntity && transparent,
  });

  const tooltipText = getTooltipText({ isKindlyEntity, lessInfo, description, items });

  return (
    <Tag
      className={entityTagClassNames}
      name={name}
      removeTag={removeTag}
      size={size}
      style={style}
      tooltipText={tooltipText}
    >
      {children}
    </Tag>
  );
};

EntityTag.propTypes = {
  name: PropTypes.string,
  hue: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  lessInfo: PropTypes.bool,
  removeTag: PropTypes.func,
  children: ChildrenType,
  transparent: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  className: PropTypes.string,
};

export default EntityTag;
