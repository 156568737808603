import cx from 'classnames';

import { Info } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './InfoButton.scss';
import type { IconColor } from '../Icon/Icon';

interface InfoButtonProps {
  title?: string;
  className?: string;
  color?: IconColor;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const InfoButton = ({ title = 'Info', className, color, onClick }: InfoButtonProps) => (
  <button type="button" className={cx(styles.wrapper, className)} onClick={onClick}>
    <Icon component={Info} title={title} color={color} className={styles.icon} />
  </button>
);

export default InfoButton;
