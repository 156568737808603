import { Navigate, Route, Routes } from 'react-router-dom';

import { Loader } from 'frontend/components';
import { NotFound } from 'frontend/features';
import {
  ActivityFeed,
  AdminBots,
  AdminSkills,
  Changelog,
  ChangelogEntryDetail,
  CreatePlatformAlert,
  Dashboard,
  GroupDetail,
  Groups,
  OrganizationDetails,
  OrganizationList,
  Orphans,
  PlatformAlerts,
  PlatformAnalytics,
  UpdatePlatformAlert,
  UserDetail,
  Users,
} from 'frontend/features/Admin/views';
import UserPermissions from 'frontend/features/Admin/views/Users/views/UserPermissions/UserPermissions';
import { PluginCreate, PluginDetail, Plugins } from 'frontend/features/Connect/Plugins';
import { ConnectorDetail, Connectors, CreateConnector } from 'frontend/features/Connectors';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

export default function AdminRoutes() {
  const { isKindlyAdminManager, loading: loadingPermissions } = useMyPermissions();

  if (loadingPermissions) {
    return <Loader size="large" />;
  }

  if (!isKindlyAdminManager) {
    return <NotFound />;
  }

  return (
    <Routes>
      {/* Redirect when clicking on a bot, skill or organization in admin-panel */}
      <Route path="/" element={<Navigate to="dashboard" />} />
      <Route path="/workspace/:botId" element={<Navigate to="workspace/:botId/dashboard" />} />
      <Route path="/bots" element={<Navigate to="workspaces" />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/organization/:organizationId" element={<OrganizationDetails />} />
      <Route path="/organizations" element={<OrganizationList />} />
      <Route path="/workspaces" element={<AdminBots />} />
      <Route path="/analytics" element={<PlatformAnalytics />} />
      <Route path="/skills" element={<AdminSkills />} />
      <Route path="/users/*" element={<Users />} /> {/* Deprecated */}
      <Route path="/members/*" element={<Users />} />
      <Route path="/user/:userId/permissions" element={<UserPermissions />} />
      <Route path="/user/:userId" element={<UserDetail />} />
      <Route path="/groups" element={<Groups />} />
      <Route path="/group/:groupId" element={<GroupDetail />} />
      <Route path="/changelog" element={<Changelog />} />
      <Route path="/changelog/:entryId" element={<ChangelogEntryDetail />} />
      <Route path="/platform-alerts" element={<PlatformAlerts />} />
      <Route path="/platform-alerts/new" element={<CreatePlatformAlert />} />
      <Route path="/platform-alerts/:alertId" element={<UpdatePlatformAlert />} />
      <Route path="/plugins" element={<Plugins />} />
      <Route path="/connectors" element={<Connectors />} />
      <Route path="/connectors/new" element={<CreateConnector />} />
      <Route path="/connector/:connectorId" element={<ConnectorDetail />} />
      <Route path="/plugins/new" element={<PluginCreate />} />
      <Route path="/plugins/:pluginId" element={<PluginDetail />} />
      <Route path="/orphans" element={<Orphans />} />
      <Route path="/activity" element={<ActivityFeed />} />
      <Route element={<NotFound />} />
    </Routes>
  );
}
