import cx from 'classnames';
import { Controller, type FieldValues, type Path, type RegisterOptions, type UseFormReturn } from 'react-hook-form';

import styles from './ToggleRHF.scss';

interface Props<T extends FieldValues> {
  /** The name of the field in RHF. */
  name: Path<T>;
  /** The RHF methods returned from useForm(). */
  rhfMethods: UseFormReturn<T>;
  /** The RHF register options. */
  rhfOptions?: RegisterOptions<T>;
  /** Extra logic to run on toggle. */
  handleToggle?: () => void;
  /** The values to be toggled between. The first is the `off` value, the second is the `on` one. @default boolean */
  values?: [off: string | boolean | number, on: string | boolean | number];
  /** The label to be displayed next to the toggle. */
  label?: string;
  /** If true, the toggle is disabled. */
  disabled?: boolean;
  /** Class passed to the Toggle container. */
  className?: string;
  /** The color of the toggle. @default primary */
}

/** Toggle to use inside React Hook Form. */
export default function ToggleRHF<T extends FieldValues>({
  name,
  rhfMethods,
  handleToggle,
  values = [false, true],
  label,
  disabled,
  className,
}: Props<T>) {
  return (
    <Controller
      name={name}
      control={rhfMethods.control}
      render={({ field }) => {
        const isChecked = field.value === values[1];

        return (
          <label className={cx(className, styles.container)}>
            <input
              type="checkbox"
              className={styles.checkbox}
              disabled={disabled}
              {...field}
              onChange={() => {
                handleToggle?.();
                return field.onChange(!isChecked ? values[1] : values[0]);
              }}
            />
            <span
              className={cx(styles.switch, {
                [styles.switchOn]: isChecked,
                [styles.switchOff]: !isChecked,
                [styles.switchDisabled]: disabled,
              })}
              aria-checked={isChecked}
              role="switch"
              tabIndex={0}
            />
            {!!label && <span>{label}</span>}
          </label>
        );
      }}
    />
  );
}
