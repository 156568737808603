import PropTypes from 'prop-types';

const ConnectedCircles = ({ title, color = '#FFAC4A' }) => (
  <svg width="8px" height="8px" enableBackground="new 0 0 7 7" version="1.1" viewBox="0 0 7 7" fill={color}>
    <title>{title || 'Connected circles icon'}</title>;
    <path className="st0" d="m1.5 0c0.8 0 1.5 0.7 1.5 1.5s-0.7 1.5-1.5 1.5-1.5-0.7-1.5-1.5 0.7-1.5 1.5-1.5z" />
    <path className="st0" d="m1.5 4c0.8 0 1.5 0.7 1.5 1.5s-0.7 1.5-1.5 1.5-1.5-0.7-1.5-1.5 0.7-1.5 1.5-1.5z" />
    <rect className="st0" x="2" y="5" width="5" height="1" />
    <rect className="st0" x="2" y=".9" width="5" height="1" />
    <rect
      className="st0"
      transform="matrix(-1.837e-16 1 -1 -1.837e-16 9.875 -3.125)"
      x="4"
      y="2.9"
      width="5"
      height="1"
    />
  </svg>
);

ConnectedCircles.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

export default ConnectedCircles;
