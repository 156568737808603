// Custom config for styling when converted from state to html using draft-js
export default (state) => {
  const options = {
    inlineStyles: {
      BOLD: {
        element: 'span',
        style: {
          fontWeight: '500',
        },
      },
    },
    blockStyleFn: (contentBlock) => {
      const type = contentBlock.getType();
      if (type !== 'code-block') return undefined;

      const key = contentBlock.getKey();
      const prevBlock = state.getCurrentContent().getBlockBefore(key);
      const nextBlock = state.getCurrentContent().getBlockAfter(key);
      const isPrevBlockCode = prevBlock && prevBlock.getType() === 'code-block';
      const isNextBlockCode = nextBlock && nextBlock.getType() === 'code-block';

      return {
        style: {
          borderRadius: `${isPrevBlockCode ? '0 0' : '4px 4px'} ${isNextBlockCode ? '0 0' : '4px 4px'}`,
          borderWidth: `${isPrevBlockCode ? '0' : '1px'} 1px ${isNextBlockCode ? '0' : '1px'} 1px`,
          borderColor: 'rgba(0,0,0,0.1)',
          borderStyle: 'solid',
          backgroundColor: 'rgba(0,0,0,0.05)',
          padding: '5px',
          color: '#222',
          margin: '0',
          overflow: 'hidden',
        },
      };
    },
  };
  return { options };
};
