import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

export const DELETE_SKILL = gql`
  mutation DeleteSkill($skillId: ID!) {
    deleteSkill(skillId: $skillId) {
      id
    }
  }
`;
const DeleteSkillModal = ({ hide, args: { skillId, name } }) => {
  const toast = useToast();
  const [mutate] = useMutation(DELETE_SKILL);
  const navigate = useNavigate();

  const onSubmit = async () => {
    await mutate({ variables: { skillId } });
    toast.success(`Skill ${name} deleted`);
    navigate('/skills');
  };
  return <DeleteModal onSubmit={onSubmit} hide={hide} name={name} confirmName />;
};

DeleteSkillModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    skillId: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default DeleteSkillModal;
