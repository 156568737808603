import PropTypes from 'prop-types';
import { Suspense, lazy } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ChevronLeft } from 'frontend/assets/icons';
import { Button, Loader, LoaderSwitch, Panel } from 'frontend/components';
import { useFlowDialogues } from 'frontend/features/Build/hooks';

import styles from './FlowModal.scss';
import DialoguePath from '../DialoguePath';

const FlowBuilder = lazy(() => import(/* webpackChunkName: 'FlowBuilder' */ './FlowBuilder'));

const FlowModal = ({ dialogueLabelColorData, isActive, dialogues, loading }) => {
  const [searchParams] = useSearchParams();
  const flowDialogues = useFlowDialogues(dialogues);

  searchParams.delete('view');
  const paramsWithoutFlowURL = searchParams.toString();

  return (
    <Panel className={styles.flowModal}>
      <LoaderSwitch loading={loading}>
        <div className={styles.header}>
          <div>
            <Button icon={ChevronLeft} to={`?${paramsWithoutFlowURL}`} className={styles.exitIcon} />
          </div>
          <DialoguePath dialogueLabelColorData={dialogueLabelColorData} readOnly isActive={isActive} />

          <Button to={`?${paramsWithoutFlowURL}`} text="Exit flow" />
        </div>
        <Suspense fallback={<Loader size="large" />}>
          <FlowBuilder flowDialogues={flowDialogues} />
        </Suspense>
      </LoaderSwitch>
    </Panel>
  );
};

FlowModal.propTypes = {
  dialogueLabelColorData: PropTypes.shape({
    colorLabel: PropTypes.shape({}),
    dialogueType: PropTypes.string,
  }).isRequired,
  dialogues: PropTypes.shape({}),
  isActive: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FlowModal;
