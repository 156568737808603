import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { usePrevious } from 'frontend/hooks';
import { setScrollHeight } from 'frontend/state/dux/inbox';

export default ({
  bottomRef,
  loading,
  chatMessages,
  ticketLog,
  remainingMessages,
  prevRemainingMessages,
  conversationElement,
}) => {
  const prevChatMessages = usePrevious(chatMessages);
  const prevLoading = usePrevious(loading);
  const prevTicketLog = usePrevious(ticketLog);
  const dispatch = useDispatch();
  const scrollHeight = useSelector((state) => state.inbox.scrollHeight);
  const { hash } = useLocation();
  const prevTicketLogLength = prevTicketLog ? prevTicketLog.length : 0;
  const ticketLogLength = ticketLog ? ticketLog.length : 0;
  const prevChatMessagesLength = (prevChatMessages || []).length;
  const chatMessagesLength = chatMessages.length;

  const scrollToBottom = useCallback(() => {
    bottomRef?.current?.scrollIntoView?.({ behavior: 'instant' });
  }, [bottomRef]);

  useEffect(() => {
    const newTicketLog = prevTicketLogLength !== ticketLogLength;
    const newChatMessages = prevChatMessagesLength !== chatMessagesLength;
    const firstRender = loading !== prevLoading;
    const notLoadedMoreMessages = prevRemainingMessages === undefined || prevRemainingMessages === remainingMessages;

    if (firstRender) {
      // Scroll to either hash or bottom on intial render
      const hashElement = document.getElementById(hash);

      if (hashElement && hash) {
        const existingElements = document.getElementsByClassName('inbox-message-hash-highlight');
        while (existingElements.length > 0) {
          existingElements[0].classList.remove('inbox-message-hash-highlight');
        }
        hashElement.scrollIntoView();
        hashElement.classList.add('inbox-message-hash-highlight');
      } else if (bottomRef?.current) {
        scrollToBottom();
      }
    } else if (!notLoadedMoreMessages) {
      // When loading more messages (pagination)
      // Scroll either to hash or keep users scroll position
      const wrapper = conversationElement.current;
      const hashElement = document.getElementById(hash);
      const existingElements = document.getElementsByClassName('inbox-message-hash-highlight');

      if (hashElement && existingElements.length === 0) {
        hashElement.scrollIntoView();
        hashElement.classList.add('inbox-message-hash-highlight');
      } else if (wrapper && wrapper.scrollHeight !== scrollHeight) {
        wrapper.scrollTop = wrapper.scrollHeight - scrollHeight;
        dispatch(setScrollHeight(wrapper.scrollHeight));
      }
    } else if (bottomRef?.current && (newTicketLog || newChatMessages)) {
      // Scroll to bottom for all new messages
      // TODO: Don't throw the user down if they have scrolled up.
      // Instead give them a indication that there are new messages
      scrollToBottom();
    }
  }, [
    bottomRef,
    loading,
    ticketLogLength,
    prevTicketLogLength,
    prevChatMessagesLength,
    chatMessagesLength,
    prevLoading,
    prevRemainingMessages,
    remainingMessages,
    hash,
    conversationElement,
    scrollHeight,
    scrollToBottom,
    dispatch,
  ]);

  return { scrollToBottom };
};
