import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { DropdownButton } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import styles from './EntityList.scss';
import { DeleteEntity, EntityInfo, UpdateEntity } from '../../modals';
import { EntityType } from '../../propTypes';

const Options = ({ areSkillEntities, withProperties, entity, hideDelete, extraActions, botOrSkillParams }) => {
  const { id } = entity;

  const [showDeleteModal] = useModal(DeleteEntity);
  const [showUpdateModal] = useModal(UpdateEntity);
  const [showInfoModal] = useModal(EntityInfo);

  const actions = useMemo(() => {
    const actionsList = [];
    if (!areSkillEntities) {
      actionsList.push({
        title: 'Edit',
        key: 'entity-option-edit',
        onClick: () => showUpdateModal({ entity, botOrSkillParams }),
      });
    }
    if (!areSkillEntities && !hideDelete) {
      actionsList.push({
        title: 'Delete',
        key: 'entity-option-delete',
        onClick: () => showDeleteModal({ entity, buildIdObject: botOrSkillParams.buildIdObject }),
      });
    }
    if (withProperties) {
      actionsList.push({
        key: 'entity-option-separator',
        separator: true,
      });
      actionsList.push({
        title: 'Properties',
        key: 'entity-option-properties',
        onClick: () => showInfoModal({ entity, buildIdObject: botOrSkillParams.buildIdObject }),
      });
    }
    return actionsList.concat(extraActions || []);
  }, [
    areSkillEntities,
    entity,
    hideDelete,
    extraActions,
    showDeleteModal,
    showInfoModal,
    showUpdateModal,
    withProperties,
    botOrSkillParams,
  ]);

  return (
    <DropdownButton actions={actions} data-testid={`entity-list-dropdown-${id}`} className={styles.optionsButton} />
  );
};

Options.propTypes = {
  areSkillEntities: PropTypes.bool,
  withProperties: PropTypes.bool,
  entity: EntityType.isRequired,
  hideDelete: PropTypes.bool,
  extraActions: PropTypes.arrayOf(PropTypes.shape({})),
  botOrSkillParams: BotOrSkillParamsType.isRequired,
};

export default Options;
