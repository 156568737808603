import PropTypes from 'prop-types';

import { DIALOGUE_TYPES } from 'frontend/constants';
import { IDType } from 'frontend/propTypes';

export default PropTypes.shape({
  id: PropTypes.string,
  mod: PropTypes.shape({}),
  parentId: IDType,
  dialogueType: PropTypes.oneOf([...Object.values(DIALOGUE_TYPES), '']),
  isActive: PropTypes.shape({}),
  modIsActive: PropTypes.shape({}),
  plugininstance: PropTypes.shape({}),
  subscribedSkillName: PropTypes.string,
});
