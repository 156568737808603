import { useQuery } from '@apollo/client';
import { endOfMonth, format, subMonths } from 'date-fns';
import { sortBy } from 'lodash';
import { useMemo } from 'react';

import type { BotType } from 'frontend/api/generated';
import { DEFAULT_TIMEZONE } from 'frontend/features/Analytics/constants';
import type { ReportBot } from 'frontend/features/Analytics/utils/sageData';
import { ORGANIZATION_BOTS } from 'frontend/features/Inbox/queries';
import { useAllReports } from 'frontend/features/Organization/views/Reports/useAllReports';
import { useReport } from 'frontend/features/Organization/views/Reports/useReport';
import { EMPTY_REPORT_DATA, REPORT_STATES } from 'frontend/features/Organization/views/Reports/utils';
import { DATE_FORMAT_ISO_DATE, DATE_FORMAT_MONTH_AND_YEAR, DATE_FORMAT_YEAR_MONTH } from 'frontend/utils/date';

/* Join report data for bots with metadata and sort by sessions descending */
function joinBotData(
  currentMonthData: ReportBot[],
  prevMonthData: ReportBot[],
  metaData: BotType[],
): {
  data: ReportBot;
  previous: ReportBot | undefined;
  meta: BotType;
}[] {
  return sortBy(
    currentMonthData.map((data) => {
      const prevBotData = prevMonthData.find((prevBot) => prevBot.bot_id === data.bot_id);
      const bot = metaData.find((botMeta) => botMeta.id === data.bot_id.toString());
      return { data, meta: bot!, previous: prevBotData };
    }),
    (botReport) => botReport.data.sessions * -1,
  );
}

function useNavigationItems({
  allMonthlyReports,
  organizationId,
}: {
  allMonthlyReports: { month: string; tz: string }[];
  organizationId: string;
}): {
  key: string;
  title: string;
  tz: string;
  url: string;
}[] {
  const defaultMonth = format(subMonths(new Date(), 1), DATE_FORMAT_YEAR_MONTH);
  const reports = allMonthlyReports.length > 0 ? allMonthlyReports : [{ month: defaultMonth, tz: DEFAULT_TIMEZONE }];
  return reports.map(({ month: _month, tz: _tz }) => ({
    key: `${_month}-${_tz}`,
    title: format(new Date(_month), DATE_FORMAT_MONTH_AND_YEAR),
    tz: _tz,
    url: `/organization/${organizationId}/analytics/reports/${_month}?tz=${_tz}`,
  }));
}

export default function useReports({
  organizationId,
  month,
  tz,
}: {
  organizationId: string;
  month: string;
  tz?: string;
}) {
  const {
    organizationTimezone,
    meta: allMonthlyReports,
    loading: allMonthlyReportsLoading,
  } = useAllReports(organizationId);

  tz = tz || organizationTimezone || DEFAULT_TIMEZONE;

  /* Current monthly report data */
  const {
    loading: currentMonthLoading,
    meta: currentMonthMeta,
    data: currentMonthData,
  } = useReport(organizationId, month, tz);

  /* Previous monthly report data */
  const prevMonth = format(subMonths(new Date(month), 1), DATE_FORMAT_YEAR_MONTH);
  const isTherePreviousReport = Boolean(
    allMonthlyReports.some((report) => report.month === prevMonth && report.tz === currentMonthMeta?.tz),
  );
  const {
    loading: prevMonthLoading,
    meta: prevMonthMeta,
    data: prevMonthData,
  } = useReport(organizationId, prevMonth, tz, !isTherePreviousReport);

  const { data: botsData, loading: botsLoading } = useQuery(ORGANIZATION_BOTS, {
    variables: { organizationId },
  });

  /* Join report data for bots with metadata and sort by sessions descending */
  const botReports = useMemo(
    () => joinBotData(currentMonthData?.bots || [], prevMonthData?.bots || [], botsData?.organization?.bots || []),
    [botsData, prevMonthData, currentMonthData?.bots],
  );

  const navigationItems = useNavigationItems({
    allMonthlyReports,
    organizationId,
  });

  const loading = currentMonthLoading || prevMonthLoading || allMonthlyReportsLoading || botsLoading;

  const currentMonthReport =
    currentMonthMeta && currentMonthData
      ? {
          meta: currentMonthMeta,
          data: currentMonthMeta.state === REPORT_STATES.NO_DATA ? EMPTY_REPORT_DATA : currentMonthData,
          startDate: format(new Date(month), DATE_FORMAT_ISO_DATE),
          endDate: format(endOfMonth(new Date(month)), DATE_FORMAT_ISO_DATE),
          bots: botReports,
        }
      : undefined;
  const prevMonthReport =
    prevMonthMeta && prevMonthData
      ? {
          meta: prevMonthMeta,
          data: prevMonthMeta.state === REPORT_STATES.NO_DATA ? EMPTY_REPORT_DATA : prevMonthData,
          startDate: format(new Date(prevMonth), DATE_FORMAT_ISO_DATE),
          endDate: format(endOfMonth(new Date(prevMonth)), DATE_FORMAT_ISO_DATE),
        }
      : undefined;

  return {
    loading,
    report: currentMonthReport,
    previous: prevMonthReport,
    navigation: { loading: allMonthlyReportsLoading, items: navigationItems },
  };
}
