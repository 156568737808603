import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { FormErrors, GuardUnsaved, LoaderSwitch, PageBar, Panel, ToggleSwitch } from 'frontend/components';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';

import chatBubbleStyles from '../../styles.scss';

const SUBSCRIBE = {};

const Alerts = () => {
  const { onSubmit, initialValues, loading, languagesLoading, meLoading } = useChatbubbleForm();

  return (
    <LoaderSwitch loading={loading || languagesLoading || meLoading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        subscribe={SUBSCRIBE}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={chatBubbleStyles.formErrors} />
              <div className={chatBubbleStyles.chatbubbleSettingsWrapper}>
                <Panel>
                  <h3 className="m-b-lg">Alerts</h3>
                  <div>
                    <p>
                      The tab title in the browser informs users of new messages if the tab is inactive. Only for
                      desktop.
                    </p>
                    <div className={chatBubbleStyles.flexContainer}>
                      <ToggleSwitch name="tabNotification" className="m-r-md" />
                      <label htmlFor="ToggleSwitch-tabNotification">Tab notification</label>
                    </div>
                  </div>
                  <div>
                    <p>
                      A red dot appears above the chat bubble whenever a message is received while the bubble is
                      inactive. Only for mobile.
                    </p>
                    <div className={chatBubbleStyles.flexContainer}>
                      <ToggleSwitch name="bubbleNotification" className="m-r-md" />
                      <label htmlFor="ToggleSwitch-bubbleNotification">Bubble alert</label>
                    </div>
                  </div>
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
};

export default Alerts;
