/* eslint-disable camelcase */

import { useUrlSearchParams } from 'frontend/hooks';

import { removeEmptyValues } from '../utils';

const filterToUrlParams = (nudge_id) => removeEmptyValues({ nudge_id });

const DEFAULT = { nudge_id: '' };

const useNudge = () => {
  const [{ nudge_id }] = useUrlSearchParams({ default: DEFAULT });
  return [nudge_id, filterToUrlParams];
};

export default useNudge;
