import { ChildrenType } from 'frontend/propTypes';

import styles from './SectionRow.scss';

const SectionRow = ({ children }) => (
  <div className={styles.rowPanel}>
    <div className={styles.content}>{children}</div>
  </div>
);
SectionRow.propTypes = {
  children: ChildrenType,
};

export default SectionRow;
