import { regularName } from 'frontend/form/validators';

export const entityName = (otherEntities) => (value) => {
  if (!value || !value.trim()) return 'The entity name cannot be empty';

  if (value.length > 30) return 'The entity name can be up to 30 characters';

  if (otherEntities.find(({ name }) => name === value.toLowerCase().trim())) {
    return 'An entity with that name already exists';
  }

  return regularName(value);
};
