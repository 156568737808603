import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Exclamation } from 'frontend/assets/icons';
import { EmptyState, InfoButton, LoaderSwitch, Pagination } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useCurrentLanguage, usePagination } from 'frontend/hooks';

import styles from './DialogueConfusion.scss';
import { ConfusionRow } from './components';
import useDialogueConfusion from './hooks/useDialogueConfusion';
import ResolveConflicts from './modals';
import sharedStyles from '../bot-improvements.scss';
import { Section } from '../components';
import filterById from '../filterById';
import { AboutFeature } from '../modals';

const AboutFeatureText = (
  <>
    <b>How to use Dialogue Confusion</b>
    <p>Dialogue Confusion lists dialogues that conflicts with each other a number of times during training.</p>
    <b>How the filter affect the confusion report</b>
    <p>Only filtering on languages will affect the confusion report</p>
  </>
);

const DialogueConfusion = () => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const [ignoredDialogues, setIgnoredDialogues] = useState([]);
  const { botId } = useParams();
  const [showResolveDialogueConfusion] = useModal(ResolveConflicts);
  const [showAboutFeature] = useModal(AboutFeature);

  const { confusedDialogues, confusedDialoguesLoading, confusedLanguagesLoading, refetchConfusedDialogues } =
    useDialogueConfusion(currentLanguage);

  const [currentData, page, pages, setPage] = usePagination({
    objects: filterById(confusedDialogues, ignoredDialogues),
    pageSize: 7,
  });
  const InfoBox = (
    <InfoButton
      className={styles.infoButton}
      onClick={() => showAboutFeature({ title: 'About Dialogue Confusion', text: AboutFeatureText })}
    />
  );

  return (
    <LoaderSwitch loading={confusedDialoguesLoading || confusedLanguagesLoading} size="large">
      <Section
        InfoBox={InfoBox}
        title="Dialogue confusion"
        listHeader={['Dialogues', 'Conflicts']}
        hasData={!!confusedDialogues?.length}
      >
        {!confusedDialogues?.length ? (
          <EmptyState
            className={sharedStyles.emptyState}
            color="blue"
            description="There are currently no confused dialogues."
            icon={Exclamation}
            title="No tasks"
          />
        ) : (
          <>
            {currentData.map((currentDialogues) => (
              <ConfusionRow
                confusedDialogues={currentDialogues}
                botId={botId}
                key={currentDialogues.id}
                selectedLanguage={currentLanguage}
                showResolveDialogueConfusion={showResolveDialogueConfusion}
                refetchConfusedDialogues={refetchConfusedDialogues}
                setIgnoredDialogues={setIgnoredDialogues}
                ignoredDialogues={ignoredDialogues}
              />
            ))}
            {pages > 1 && (
              <div className={styles.pagination}>
                <Pagination currentPage={page} pages={pages} setPage={setPage} />
              </div>
            )}
          </>
        )}
      </Section>
    </LoaderSwitch>
  );
};

export default DialogueConfusion;
