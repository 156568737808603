import arrayMutators from 'final-form-arrays';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';

import {
  FormErrors,
  GuardUnsaved,
  InfoBox,
  Input,
  LoaderSwitch,
  PageBar,
  Panel,
  SelectLanguage,
  ToggleSwitch,
} from 'frontend/components';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { pattern } from 'frontend/features/Build/validators';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import chatBubbleStyles from '../../styles.scss';

const placeholder = '/receipt';

export default function CompletionPage() {
  const { loading, languagesLoading, meLoading, onSubmit, initialValues, languages } = useChatbubbleForm();
  const regexPatternValidator = pattern();
  const { isKindlyAdminManager, loading: loadingPermissions } = useMyPermissions();

  const validateUrl = useCallback(
    (value) => {
      if (!value || !value.trim()) {
        return `Must have a value, i.e. ${placeholder}`;
      }

      const regexError = regexPatternValidator(value);
      if (regexError) {
        return `The pattern ${value} will capture anything, and can not be used as a receipt page matcher`;
      }

      return undefined;
    },
    [regexPatternValidator],
  );

  if (loadingPermissions) {
    return null;
  }

  return (
    <LoaderSwitch loading={loading || languagesLoading || meLoading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={chatBubbleStyles.formErrors} />
              <div className={chatBubbleStyles.chatbubbleSettingsWrapper}>
                <Panel>
                  <div className={chatBubbleStyles.selectLanguage}>
                    {languages.length > 1 && <SelectLanguage languages={languages} supportVariants={false} />}
                  </div>
                  <h3>Completion page</h3>
                  <p>
                    Setup a receipt page URL to track orders and conversions. A conversion is a purchase where the chat
                    has been used by the customer in the funnel, while an order is a purchase where the chat has not
                    been used.
                  </p>
                  <Field
                    name="conversionPageUrl"
                    label="Receipt page"
                    component={Input}
                    placeholder={placeholder}
                    validate={validateUrl}
                    multiline
                  />
                  <InfoBox title="How does the URL matching work?" className="m-t-2">
                    <p>
                      The URL string matches at any point of the user URL, so you don&apos;t need <code>www</code> or{' '}
                      <code>https</code>. It&apos;s enough that the user location contains the URL string.
                    </p>
                    <p>
                      The URL string supports wildcards (<code>*</code>), e.g. <code>/receipt/*/productId=</code> will
                      match:
                      <ul className="m-bl-1">
                        <li>
                          <code>www.example.com/receipt/en/productId=1234</code>
                        </li>
                        <li>
                          <code>www.example.com/receipt/no/productId=5678</code>
                        </li>
                        <li>
                          <code>www.example.com/receipt/sweden/productId=</code>
                        </li>
                      </ul>
                    </p>
                  </InfoBox>

                  {isKindlyAdminManager && (
                    <>
                      <h3 className="m-b-sm m-t-md">Split testing</h3>
                      <div className={chatBubbleStyles.panelInner}>
                        <div className={`${chatBubbleStyles.flexContainer} m-t-sm`}>
                          <ToggleSwitch name="splitTestEnabled" className="m-r-md" />
                          <label htmlFor="ToggleSwitch-splitTestEnabled">Enable split test</label>
                        </div>
                        <p>
                          When split testing is enabled, 50% of the website visitors will not have chatbot or nudges
                          available.
                        </p>
                      </div>
                    </>
                  )}
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
}
