import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { UserType } from 'frontend/api/generated';
import { LoaderSwitch, NoHits, Table } from 'frontend/components';
import type { Column, RenderFunction } from 'frontend/components/Table/Table';
import AllAdminActivities from 'frontend/features/Admin/views/AllAdminActivities.query.graphql';
import { usePagination } from 'frontend/hooks';

import styles from './ActivityFeed.scss';

const PAGE_SIZE = 25;

// TODO: I don't know how this has happened or if the data is is corrupted
//  But with Norwegian Data on Page 4 there is a missing `actionBy` which breaks the page
const ActionBy: RenderFunction = ({ data: by }: { data: Pick<UserType, 'id' | 'username'> }) =>
  by ? <Link to={`/admin/user/${by.id}`}>{by.username}</Link> : null;

const columns: Column[] = [
  { title: 'Created', key: 'createdAt' },
  { title: 'Action', key: 'action' },
  { title: 'Message', key: 'message' },
  {
    title: 'Action by',
    key: 'actionBy',
    render: ActionBy,
  },
];

const ActivityFeed = () => {
  const { data, loading } = useQuery(AllAdminActivities);
  const adminActivities = useMemo(() => data?.allAdminActivities ?? [], [data]);
  const noHits = !loading && adminActivities.length === 0;

  const [currentActivities, currentPage, pages, setPage] = usePagination({
    objects: adminActivities,
    pageSize: PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pages,
      setPage,
      currentPage,
      summary: {
        totalCount: adminActivities.length,
        firstVisible: (currentPage - 1) * PAGE_SIZE + 1,
        lastVisible: currentPage === pages ? adminActivities.length : currentPage * PAGE_SIZE,
      },
    }),
    [pages, setPage, currentPage, adminActivities],
  );

  return (
    <LoaderSwitch loading={loading} size="large">
      <div className={styles.container}>
        <div className={styles.header}>Shows a log of admin activity</div>
        {!noHits && <Table columns={columns} data={currentActivities} pagination={pagination} />}
        {noHits && <NoHits name="logged admin activities" />}
      </div>
    </LoaderSwitch>
  );
};

export default ActivityFeed;
