import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { MyOrganizationsDocument } from 'frontend/api/generated';
import { Input } from 'frontend/components';
import { GET_ALL_ORGANIZATIONS } from 'frontend/features/Admin/views/graphql';
import { DEFAULT_TIMEZONE } from 'frontend/features/Analytics/constants';
import { ModalForm } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';
import { fetchIfNecessary } from 'frontend/utils';

import { SubscriptionSelect } from '../../components';
import OrganizationTimezonePicker from '../../components/OrganizationTimezonePicker/OrganizationTimezonePicker';
import { CREATE_ORGANIZATION } from '../../mutations';

type NewOrganizationProps = {
  hide: (...args: unknown[]) => unknown;
  args: { redirectTo: string };
};
export default function NewOrganization({ hide, args: { redirectTo } }: NewOrganizationProps) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [create] = useMutation(CREATE_ORGANIZATION);

  const onSubmit = useCallback(
    async ({ name, planName, timezone, expirationDate, noExpiration }) => {
      const { data } = await create({
        variables: { name, planName, timezone, expirationDate: noExpiration ? null : expirationDate },
      });
      fetchIfNecessary({ client, query: GET_ALL_ORGANIZATIONS });
      fetchIfNecessary({ client, query: MyOrganizationsDocument });
      navigate(redirectTo.replace(':orgId', data.createOrganization.id));
    },
    [client, create, navigate, redirectTo],
  );

  return (
    <ModalForm
      onSubmit={onSubmit}
      title="Add organization"
      hide={hide}
      onOkText="Create"
      initialValues={{ noExpiration: true, timezone: DEFAULT_TIMEZONE }}
    >
      <h3>Organization name</h3>
      <Field
        component={Input}
        name="name"
        placeholder="Name"
        inputLimit={100}
        validate={chain([required, max(100)])}
        autoFocus
        aria-label="Organization name"
        className="m-b-md"
      />

      <h3>Timezone</h3>
      <Field component={OrganizationTimezonePicker} name="timezone" className="m-b-2" />

      <h3>Subscription</h3>
      <SubscriptionSelect />
      <div className="m-b-md" />
    </ModalForm>
  );
}
