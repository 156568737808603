import { gql } from '@apollo/client';

export default gql`
  mutation ResetDialogueMod($botId: ID!, $skillId: ID!, $dialogueId: UUID!, $andFollowups: Boolean) {
    resetDialogueMod(botId: $botId, skillId: $skillId, dialogueId: $dialogueId, andFollowups: $andFollowups) {
      id
      dialogueIds
    }
  }
`;
