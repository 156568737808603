/* eslint-disable @graphql-eslint/naming-convention */
import { gql } from '@apollo/client';

import SLOT from './SLOT';

export default gql`
  fragment ReplySlot on ReplySlotType {
    id
    start
    end
    slot {
      ...SLOT
    }
  }
  ${SLOT}
`;
