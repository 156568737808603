import { gql } from '@apollo/client';

import ORGANIZATION_FRAGMENT from 'frontend/features/Organization/fragments/Organization.fragment.graphql';

export default gql`
  mutation CreateOrganizationSubscription($organizationId: ID!, $planName: String!, $expirationDate: Date) {
    createOrganizationSubscription(
      organizationId: $organizationId
      planName: $planName
      expirationDate: $expirationDate
    ) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
