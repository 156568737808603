import { useMutation } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import { type FacebookPageType, UpdateFacebookPageDocument } from 'frontend/api/generated';
import { PreferredHandoverApp, SelectLanguage } from 'frontend/features/Connect/ChatClients/modals';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useLanguages, useToast } from 'frontend/hooks';

const useMessengerConnection = (facebookPage: Partial<FacebookPageType>) => {
  const toast = useToast();
  const [retryDisconnect, setRetryDisconnect] = useState(false);
  const botOrSkillParams = useBotOrSkill();
  const { botId } = botOrSkillParams;
  const { languageNameMap, languages } = useLanguages(botOrSkillParams);

  const [showSelectLanguageModal] = useModal(SelectLanguage);
  const [showPreferredHandoverModal] = useModal(PreferredHandoverApp);
  const [updateFacebookPageMutation] = useMutation(UpdateFacebookPageDocument);

  const updateFacebookPage = useCallback(
    async ({ handoverAppId, selectedLanguage }) => {
      await updateFacebookPageMutation({
        variables: {
          handoverAppId,
          selectedLanguage,
          facebookPageId: facebookPage.id!,
          botId: botId!,
        },
      });
      toast.success('Messenger connection updated');
    },
    [botId, facebookPage.id, toast, updateFacebookPageMutation],
  );

  const initialValues = useMemo(
    () => ({
      handoverAppId: facebookPage.handoverPreferredSecondaryAppId,
      selectedLanguage: facebookPage.selectedLanguage || '',
    }),
    [facebookPage],
  );

  const choosePreferredHandoverId = useCallback(
    () => showPreferredHandoverModal({ onSubmit: updateFacebookPage, initialValues }),
    [initialValues, showPreferredHandoverModal, updateFacebookPage],
  );

  const removePreferredHandoverId = useCallback(
    () => showPreferredHandoverModal({ onSubmit: updateFacebookPage, initialValues, remove: true }),
    [initialValues, updateFacebookPage, showPreferredHandoverModal],
  );

  const chooseSelectedLanguage = useCallback(
    () => showSelectLanguageModal({ onSubmit: updateFacebookPage, languages, initialValues }),
    [initialValues, languages, showSelectLanguageModal, updateFacebookPage],
  );

  const removeSelectedLanguage = useCallback(
    () => showSelectLanguageModal({ onSubmit: updateFacebookPage, initialValues, remove: true }),
    [initialValues, updateFacebookPage, showSelectLanguageModal],
  );
  return {
    retryDisconnect,
    setRetryDisconnect,
    languageNameMap,
    choosePreferredHandoverId,
    removePreferredHandoverId,
    chooseSelectedLanguage,
    removeSelectedLanguage,
    botId,
    languages,
  };
};
export default useMessengerConnection;
