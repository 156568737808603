import { useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { getArray } from 'frontend/utils';

import { BOT_KINDLY_ENTITIES, KINDLY_ENTITIES } from '../queries';

export default ({ buildIdObject, currentLanguage }) => {
  const variables = { ...buildIdObject, languageCode: currentLanguage };

  const {
    data: kindlyEntitiesData,
    loading: kindlyEntitiesLoading,
    refetch: refetchKindlyEntities,
  } = useQuery(KINDLY_ENTITIES, { variables });

  const {
    data: botKindlyEntitiesData,
    loading: botKindlyEntitiesLoading,
    refetch: refetchBotKindlyEntities,
  } = useQuery(BOT_KINDLY_ENTITIES, { variables, skip: !('botId' in buildIdObject) });

  const refetch = useCallback(() => {
    refetchKindlyEntities();
    if ('botId' in buildIdObject) refetchBotKindlyEntities();
  }, [buildIdObject, refetchBotKindlyEntities, refetchKindlyEntities]);

  return {
    kindlyEntities: getArray('kindlyEntities', kindlyEntitiesData),
    botKindlyEntities: getArray('bot.kindlyEntities', botKindlyEntitiesData),
    loading: kindlyEntitiesLoading || botKindlyEntitiesLoading,
    refetch,
  };
};
