import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { ConnectFacebookPageDocument, FacebookProfileDocument } from 'frontend/api/generated';
import { LoaderSwitch, Select } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';
import { getArray } from 'frontend/utils';

import { addPageToCache } from '../utils';

/**
 * @param facebookPages is the list of pages that the bot is already connected to
 */
export const SelectFacebookPageModal = ({ hide, args: { botId, facebookPages } }) => {
  const toast = useToast();
  const client = useApolloClient();

  const [connect] = useMutation(ConnectFacebookPageDocument);
  const { data: profileData, loading } = useQuery(FacebookProfileDocument, { fetchPolicy: 'network-only' });
  const pages = getArray('me.facebookProfile.accounts', profileData);

  const onSubmit = useCallback(
    async ({ pageId }) => {
      const page = pages.find(({ id }) => id === pageId);
      const { data } = await connect({ variables: { botId, pageId: page.id, pageName: page.name } });

      if (!data || !data.connectFacebookPage) {
        return;
      }

      addPageToCache(client, botId, data.connectFacebookPage);
      toast.success('Facebook Messenger connected');
    },
    [botId, client, connect, pages, toast],
  );

  return (
    <ModalForm hide={hide} onSubmit={onSubmit} title="Select Facebook page">
      <LoaderSwitch loading={loading} size="large" noMargin>
        <Field component={Select} name="pageId">
          <Select.Option value="" label="Select a facebook page" />
          {pages.map((page) => {
            let label = page.name;
            if ((facebookPages || []).some((connectedPage) => connectedPage.name === page.name)) {
              label = `${page.name} (connected)`;
            }
            return <Select.Option key={page.id} value={page.id} label={label} />;
          })}
        </Field>
      </LoaderSwitch>
    </ModalForm>
  );
};

SelectFacebookPageModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ botId: IDType.isRequired, facebookPages: PropTypes.array }).isRequired,
};
