import type { ApolloClient } from '@apollo/client';

import {
  AdminGetAllSkillsDocument,
  OrganizationBotsDocument,
  OrganizationMetaDocument,
  OrganizationSkillsDocument,
} from 'frontend/api/generated';
import { buildTypes } from 'frontend/constants';
import type { BuildType } from 'frontend/propTypes/BuildIdObjectType';
import { fetchIfNecessary } from 'frontend/utils';

export default function updateBotsOrSkillsInCache(
  client: ApolloClient<unknown>,
  buildType: BuildType,
  organizationId: string,
): void {
  // TODO: should we be awaiting these fetches?
  if (buildType === buildTypes.BOT) {
    fetchIfNecessary({
      client,
      query: OrganizationBotsDocument,
      variables: { id: organizationId },
    });
  } else if (buildType === buildTypes.SKILL) {
    fetchIfNecessary({ client, query: AdminGetAllSkillsDocument });
    fetchIfNecessary({ client, query: OrganizationSkillsDocument, variables: { id: organizationId } });
  }

  fetchIfNecessary({ client, query: OrganizationMetaDocument, variables: { id: organizationId } });
}
