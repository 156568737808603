import styles from './styles.scss';
import animationStyles from '../animation.scss';

const SvgLoader = () => (
  <div className={styles.ghostContainer} aria-labelledby="Chat list loading">
    <div className={animationStyles.ghostStripe}>
      <div className={styles.ghostCircle}>
        <svg viewBox="0 0 60 100" width="60" height="100">
          <defs>
            <mask id="mask" x="0" y="0" width="60" height="100">
              <rect x="0" y="0" width="60" height="100" fill="#fff" />
              <circle cx="37" cy="37" r="20" />
            </mask>
          </defs>
          <rect x="0" y="0" width="60" height="100" mask="url(#mask)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>

      <div className={styles.ghostLines}>
        <svg viewBox="0 0 200 100" width="100%" height="100px" preserveAspectRatio="none">
          <defs>
            <mask id="mask2" x="0" y="0" width="100%" height="100">
              <rect x="0" y="0" width="100%" height="100" fill="#FFF" />
              <rect x="5%" y="20" rx="2" ry="2" width="90%" height="11" />
              <rect x="5%" y="40" rx="2" ry="2" width="90%" height="11" />
              <rect x="5%" y="60" rx="2" ry="2" width="60%" height="11" />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="100px" mask="url(#mask2)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>
    </div>
  </div>
);

const InboxChats = () => (
  <>
    <SvgLoader />
    <SvgLoader />
    <SvgLoader />
    <SvgLoader />
  </>
);

export default InboxChats;
