import { Table } from 'frontend/components';
import type { Row } from 'frontend/components/Table/Table';

import styles from './ImportTable.scss';
import ImportTableOptions from './ImportTableOptions';

const columns = [
  { key: 'created' },
  { key: 'language' },
  {
    key: 'name',
    component: ({ data: name }) => (
      <span className={styles.name} title={name}>
        {name}
      </span>
    ),
  },
  { key: 'status' },
  { title: '', key: 'options', component: ImportTableOptions },
];

type ExportsProps = {
  imports: (Row & {
    created: string;
    status: string;
  })[];
};
const Exports = ({ imports }: ExportsProps) => <Table data={imports} columns={columns} />;

export default Exports;
