import { gql } from '@apollo/client';

export default gql`
  fragment Ticket on TicketType {
    id
    chatId
    createdAt
    status
    solvedAt
    assignedAgent {
      id
      profile {
        id
        fullName
        alias
        avatarUrl
      }
    }
  }
`;
