export const nudgeType = Object.freeze({
  FORM: 'Form',
  PRODUCT: 'Product',
  TEXT: 'Text',
  CUSTOM: 'Custom',
  MULTIPLE_CHOICE: 'Multiple choice',
});

export const nudgeDefaultTexts = Object.freeze({
  title: 'Placeholder title',
  slug: 'placeholder-slug',
});
