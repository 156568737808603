import { useMutation } from '@apollo/client';
import { pick } from 'lodash';

import { UpdateChatMetaDocument } from 'frontend/api/generated';
import { useIsRunning } from 'frontend/hooks';

export default ({ botId, chatId }): [(values: any) => void, boolean] => {
  const [update] = useMutation(UpdateChatMetaDocument);
  const [updateWithSubmitting, isSubmitting] = useIsRunning(update);

  const updateChatDetails = (values) =>
    updateWithSubmitting({
      variables: {
        botId,
        chatId,
        chatMetaInput: {
          ...pick(values, ['email', 'phoneNumber', 'status']),
        },
      },
    });

  return [updateChatDetails, isSubmitting];
};
