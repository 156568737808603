import { useMutation } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { Form } from 'react-final-form';

import { Send } from 'frontend/assets/icons';
import { Button, Input } from 'frontend/components';
import sharedStyles from 'frontend/features/Inbox/inbox-styles.scss';

import { CREATE_OR_UPDATE_COMMENT } from '../mutations';
import { commentCreatedCacheUpdate, commentsCountCacheUpdate } from '../utils';

const CommentsForm = ({ botId, chatId }) => {
  const [comment, setComment] = useState('');
  const textInput = useRef();

  const update = useCallback(
    (cache, { data: { createOrUpdateChatComment: newComment } }) => {
      commentCreatedCacheUpdate({ comment: newComment, cache, chatId, botId });
      commentsCountCacheUpdate({ cache, chatId, countDifference: 1 });
    },
    [botId, chatId],
  );
  const [createOrUpdate] = useMutation(CREATE_OR_UPDATE_COMMENT, { update });

  const onSubmit = useCallback(
    () => createOrUpdate({ variables: { botId, chatId, message: comment } }),
    [botId, chatId, comment, createOrUpdate],
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          autoComplete="off"
          className={cx(sharedStyles.formWrapper, sharedStyles.sendForm, sharedStyles.commentForm)}
        >
          <Input
            ref={textInput}
            placeholder="Type comment..."
            onKeyDown={(e) => {
              if (e.key === 'Escape') setComment('');
              if (e.key === 'Enter') {
                e.preventDefault();
                if (comment.trim()) {
                  handleSubmit();
                  setComment('');
                }
              }
            }}
            className={sharedStyles.input}
            input={{
              name: 'message',
              value: comment,
              onChange: (e) => setComment(e.target.value),
            }}
            multiline
            autoFocus
          />
          <Button
            className={cx(sharedStyles.submit, sharedStyles.submitNotes, {
              [sharedStyles.submitDisabled]: !comment.trim(),
            })}
            onClick={() => {
              if (comment.trim()) {
                textInput.current.focus();
                handleSubmit();
                setComment('');
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                textInput.current.focus();
                if (comment.trim()) {
                  handleSubmit();
                  setComment('');
                }
              }
            }}
            icon={Send}
            flat
          />
        </form>
      )}
    />
  );
};

CommentsForm.propTypes = {
  botId: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
};

export default CommentsForm;
