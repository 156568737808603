import { Tag } from 'frontend/components';
import { ChildrenType } from 'frontend/propTypes';

import styles from './HashTag.scss';

const HashTag = ({ children }) => (
  <Tag className={styles.hashTag} size="small">
    {children}
  </Tag>
);

HashTag.propTypes = {
  children: ChildrenType.isRequired,
};

export default HashTag;
