import React, { useEffect, useState } from 'react';
import { Field, useForm } from 'react-final-form';

import { DotsVertical } from 'frontend/assets/icons';
import { Button, Dropdown, Icon, Input, Pagination, ToggleSwitch } from 'frontend/components';
import AddAdornment from 'frontend/components/AddAdornment/AddAdornment';
import Table, { type Column } from 'frontend/components/Table/Table';
import TableCell from 'frontend/components/Table/TableCell';
import TableRow from 'frontend/components/Table/TableRow';
import { FIELD_COLOR } from 'frontend/constants';
import { SCRAPE_WEB_CONTENT_MIN_CHAR_LENGTH } from 'frontend/features/Connect/constants';
import { chain, integer, noUrlFile, noUrlFragment, positive, required } from 'frontend/form/validators';
import { httpsRequired } from 'frontend/form/validators/url';
import { usePagination, useToast } from 'frontend/hooks';
import composeValidate from 'frontend/utils/composeValidate';

import styles from './KindlyGPTUrls.scss';

const MAX_URLS = 500; // Maximum number of URLs allowed

const getGridTemplateColumns = (cols: Column[]) => {
  let columnStr = '';
  for (let i = 0; i < cols.length; i += 1) {
    if (i === 0 || i === cols.length - 1) {
      columnStr += 'auto ';
    } else {
      columnStr += '1fr ';
    }
  }
  return columnStr;
};

const getUrlsToScrape = (urls: string[]) =>
  urls.map((urlString, index) => ({
    id: index,
    url: urlString,
  }));

type KindlyGPTUrlsProps = {
  isEditable: boolean;
  allowHash?: boolean;
};

const KindlyGPTUrls = ({ isEditable, allowHash }: KindlyGPTUrlsProps) => {
  const toast = useToast();
  const { change, getState, batch } = useForm();
  const { initialValues, values } = getState();

  const [error, setError] = useState<string | undefined>();
  const [urls, setUrls] = useState(getUrlsToScrape(initialValues?.urlsToScrape));

  const positiveInteger = chain(integer, positive);
  const validators = [required, httpsRequired, noUrlFile];
  if (!allowHash) {
    validators.push(noUrlFragment);
  }

  useEffect(() => {
    if (initialValues.urlsToScrape) {
      setUrls(getUrlsToScrape(initialValues?.urlsToScrape));
    }
  }, [initialValues]);

  useEffect(() => {
    if (urls.length !== values.urlsToScrape?.length) {
      setUrls(getUrlsToScrape(initialValues?.urlsToScrape));
    }
  }, [urls, values.urlsToScrape, initialValues.urlsToScrape]);

  const deleteAllUrls = () => {
    change('urlsToScrape', []); // Clear the form field
    setUrls([]); // Clear the local state
  };

  const copyAllUrls = () => {
    const urlsToCopy = urls.map(({ url: urlValue }) => urlValue).join('\n');
    navigator.clipboard.writeText(urlsToCopy);
    toast.success('All URLs copied to clipboard');
  };

  const columns: Column[] = [
    {
      key: 'id',
      render: ({ rowData }) => <span>{(Number(rowData?.id) || 0) + 1}</span>,
    },
    {
      key: 'url',
      render: ({ rowData }) => (
        <Field
          name={`urlsToScrape[${rowData?.id}]`}
          component={Input}
          multiline
          className={styles.urlInput}
          validate={composeValidate(...validators)}
          placeholder="Click to add url"
          fieldColor={FIELD_COLOR.MISCHKA}
          disabled={!isEditable}
        />
      ),
    },
    {
      key: '',
      render: ({ rowData }) =>
        isEditable ? (
          <div className={styles.actionsWrapper}>
            <Dropdown
              overlay={
                <Dropdown.MenuOverlay
                  options={[
                    {
                      text: 'Delete URL',
                      onClick: () => {
                        const scrapableUrls = [...values.urlsToScrape];
                        if (typeof rowData?.id === 'number') {
                          scrapableUrls.splice(rowData.id, 1);
                          change(`urlsToScrape`, scrapableUrls);
                          setUrls(getUrlsToScrape(scrapableUrls));
                        }
                      },
                    },
                  ]}
                />
              }
              wrapperClassName={styles.settings}
            >
              <Icon component={DotsVertical} />
            </Dropdown>
          </div>
        ) : null,
    },
  ];

  const [currentData, page, pages, setPage] = usePagination({ objects: urls, pageSize: 20 });

  return (
    <>
      <div className="m-b-3">
        <div className="m-bl-1">
          <ToggleSwitch status={['Enable Kindly GPT']} name="isActive" className="m-r-sm" disabled={!isEditable} />
        </div>
        <div className="m-bl-1">
          <ToggleSwitch status={['Auto Scrape']} name="autoScrape" className="m-r-sm" disabled={!isEditable} />
        </div>
        <div className="m-bl-4">
          {isEditable && (
            <div>
              <div>
                <p>Minimum length of content</p>
                <Field
                  name="charLimForEmptyPages"
                  component={Input}
                  className={styles.integerInput}
                  validate={positiveInteger}
                  placeholder={SCRAPE_WEB_CONTENT_MIN_CHAR_LENGTH.toString()}
                  defaultValue={SCRAPE_WEB_CONTENT_MIN_CHAR_LENGTH}
                  fieldColor={FIELD_COLOR.MISCHKA}
                  inputType="number"
                  parse={(value) => parseInt(value.toString(), 10) || undefined}
                  minimum={1}
                  required
                />
                <p className="m-t-sm text-color-light">
                  The minimum amount of characters a page should have to be considered valid for scraping.
                </p>
              </div>
              <div className="m-t-lg">
                <Button onClick={copyAllUrls} className="m-r-1">
                  Copy All URLs
                </Button>
                <Button onClick={deleteAllUrls} color="warning">
                  Delete All URLs
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Table
        tableHeadingComponent={
          isEditable ? (
            <TableRow>
              <TableCell render={() => <>-</>} />
              <TableCell
                render={() => (
                  <Input
                    input={{
                      name: 'inputData',
                    }}
                    multiline
                    className={styles.urlInput}
                    validate={httpsRequired}
                    meta={{
                      error,
                    }}
                    forceValidate={!!error}
                    placeholder="Click to add url"
                    fieldColor={FIELD_COLOR.MISCHKA}
                    onKeyPress={(e) => {
                      if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
                        const valueArray = e.target.value.split('\n');
                        let errorMessage;
                        if (values.urlsToScrape.length + valueArray.length > MAX_URLS) {
                          setError(`Cannot add more than ${MAX_URLS} URLs.`);
                          e.preventDefault();
                          return;
                        }
                        for (let i = 0; i < valueArray.length; i += 1) {
                          errorMessage = composeValidate(...validators)(valueArray[i]);
                          if (!errorMessage && values.urlsToScrape.includes(valueArray[i])) {
                            errorMessage = 'Url already exists.';
                          }
                          if (errorMessage) {
                            setError(errorMessage);
                            e.preventDefault();
                            return;
                          }
                        }

                        if (!errorMessage) {
                          batch(() => {
                            for (let i = 0; i < valueArray.length; i += 1) {
                              change(`urlsToScrape[${urls.length + i}]`, valueArray[i]);
                            }
                          });

                          setUrls((currentUrls) => [
                            ...getUrlsToScrape([
                              ...(currentUrls?.map(({ url: urlString }) => urlString) || []),
                              ...valueArray,
                            ]),
                          ]);
                          e.target.value = '';
                          setError('');
                        }

                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
              <TableCell render={() => <AddAdornment />} />
            </TableRow>
          ) : undefined
        }
        getGridTemplateColumns={getGridTemplateColumns}
        className={styles.connectTable}
        data={currentData}
        columns={columns}
      />
      <Pagination currentPage={page} pages={pages} setPage={setPage} />
    </>
  );
};

export default KindlyGPTUrls;
