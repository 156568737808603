import { gql } from '@apollo/client';

import BUTTON_CLICK from './buttonClick';

export default gql`
  fragment Chat on ChatType {
    id
    autoUser
    avatarUrl
    fullName
    takenOver
    email
    phoneNumber
    contactDetails
    takeoverByAgentId
    completedTakeover
    userLeft
    commentsCount
    languageCode
    lastMessageIdSeen
    source
    subject
    emailCc
    buttonClicks {
      ...ButtonClick
    }
  }
  ${BUTTON_CLICK}
`;
