import defaultOrgAvatar from 'frontend/assets/images/organization_avatar.svg?url';
import { Badge, Panel } from 'frontend/components';

import styles from './styles.scss';

interface Props {
  name: string;
  botCount: number;
  skillCount: number;
  logo?: string;
  isDeleting?: boolean;
}
const OrgCard = ({ name, logo, botCount, skillCount, isDeleting }: Props) => (
  <div className={styles.container}>
    <Panel size="medium" hover>
      <div className={styles.content}>
        <img className={styles.orgAvatar} src={logo || defaultOrgAvatar} alt="" />
        <div className={styles.about}>
          <h3 className={styles.title}>{name}</h3>
        </div>
        <div className={styles.statsContainer}>
          <div className={styles.stats}>
            <div className={styles.statsTitle}>Bots</div>
            <div className={styles.statsAmount}>{botCount > 0 ? botCount : '-'}</div>
          </div>
          <div className={styles.stats}>
            <div className={styles.statsTitle}>Skills</div>
            <div className={styles.statsAmount}>{skillCount > 0 ? skillCount : '-'}</div>
          </div>
          {isDeleting && (
            <Badge className={styles.deleteOrgBadge} title="Deleting" tooltip="Delete process in progress..." />
          )}
        </div>
      </div>
    </Panel>
  </div>
);

export default OrgCard;
