import { useApolloClient, useQuery } from '@apollo/client';
import { compose, filter, orderBy, uniqBy } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { addListItemByFragment } from 'frontend/api/cacheHelpers';
import { chatListItemFragment } from 'frontend/api/fragments';
import { TICKET_STATUS } from 'frontend/features/Inbox/constants';
import { TICKET } from 'frontend/features/Inbox/fragments';
import { useChatUpdated, useTicketUpdated } from 'frontend/features/Inbox/hooks';
import { CHATS_AND_TICKETS } from 'frontend/features/Inbox/queries';
import { handoverRequestedAfterTicketSolved, hasRequestedContact } from 'frontend/features/Inbox/utils';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';

const FETCH_CONFIG = { fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' };

const FILTERS = {
  handoverStatusFilter: ['has_requested_takeover'],
  orderBy: '-requested_handover_at',
};

const handoverRequestedAfterSolved = (chat, tickets = []) => {
  const solvedChatTicket = tickets.find(({ chatId, status }) => chatId === chat.id && status === TICKET_STATUS.SOLVED);
  return handoverRequestedAfterTicketSolved(solvedChatTicket, chat);
};

const showChat = (tickets) => (chat) => {
  // Contact details has it's own resolve action independent of tickets
  if (hasRequestedContact(chat)) return true;
  return handoverRequestedAfterSolved(chat, tickets);
};

const getDisplayedChats = (tickets = []) =>
  compose(
    orderBy(({ requestedHandoverAt, updated }) => requestedHandoverAt || updated, 'asc'),
    uniqBy('id'),
    filter(showChat(tickets)),
  );

const useChats = () => {
  const { cache, query } = useApolloClient();
  const { botId } = useParams();
  const { data, loading, error } = useQuery(CHATS_AND_TICKETS, { variables: { botId, ...FILTERS }, ...FETCH_CONFIG });
  const chatsAndTickets = data?.chatsAndTickets;

  const displayedChats = useMemo(
    () => getDisplayedChats(chatsAndTickets?.tickets)(chatsAndTickets?.chats ?? []),
    [chatsAndTickets],
  );

  const onChatNotInList = useCallback(
    ({ chat, ticket, chatShouldBeInList }) => {
      if (!chatShouldBeInList) return;

      cache.modify({
        id: cache.identify(chatsAndTickets),
        fields: {
          chats: addListItemByFragment(cache, chatListItemFragment, chat),
          tickets: addListItemByFragment(cache, TICKET, ticket),
        },
      });
    },
    [cache, chatsAndTickets],
  );

  const onChatUpdated = useChatUpdated({ cache, filters: FILTERS, chatsAndTickets, query, onChatNotInList });
  const onTicketUpdated = useTicketUpdated({ botId, filters: FILTERS, chatsAndTickets, cache, onChatNotInList });

  const pusherChannel = getPusherChannel({ botId, isInbox: true });
  usePusherEvent(pusherChannel, 'chat-updated', onChatUpdated);
  usePusherEvent(pusherChannel, 'ticket-updated', onTicketUpdated);

  return { chats: displayedChats, loading, error };
};

export default useChats;
