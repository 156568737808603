import PropTypes from 'prop-types';

import { Nudge } from 'frontend/assets/icons';
import { Icon, PageBar } from 'frontend/components';

import styles from './styles.scss';

const Header = ({ title, slug }) => (
  <div className={styles.containerHeader}>
    <Icon component={Nudge} />
    <span className={styles.headerTitle}>{title}</span>
    <span className={styles.headerSlug}>{slug}</span>
    <PageBar.FormButtons className={styles.formSubmitBtn} />
  </div>
);
Header.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default Header;
