import { useQuery } from '@apollo/client';
import { formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { AccessLogDocument } from 'frontend/api/generated';
import { Button, LoaderSwitch, Table } from 'frontend/components';
import Panel, { HorizontalRule } from 'frontend/components/Panel';
import { useModal } from 'frontend/features/Modals';

import styles from './ProfileSecurity.scss';
import UpdatePasswordModal from '../../modals/UpdatePasswordModal';

const AttemptTimeCell = ({ data }) => (
  <time dateTime={data} title={data.slice(0, -13)}>
    {formatDistanceToNow(data, { addSuffix: true })}
  </time>
);

AttemptTimeCell.propTypes = {
  data: PropTypes.string.isRequired,
};

const columns = [
  { key: 'time', render: AttemptTimeCell },
  { title: 'User agent', key: 'userAgent' },
  { title: 'IP address', key: 'ipAddress' },
];

const DAYS = 30;

const ProfileSecurity = () => {
  const { data, loading } = useQuery(AccessLogDocument);
  const [showUpdatePasswordModal] = useModal(UpdatePasswordModal);

  const me = data?.me;
  const accessLog = me?.accessLog;
  const tableData = useMemo(
    () =>
      (me?.accessLog ?? []).map((logItem) => ({
        time: logItem.attemptTime,
        userAgent: logItem.userAgent,
        ipAddress: logItem.ipAddress,
      })),
    [me],
  );
  const hasData = Boolean(accessLog?.length);

  return (
    <Panel>
      <section className={styles.sectionWidthLimited}>
        <h3>Password</h3>
        <p>Change your account password.</p>
        <Button onClick={showUpdatePasswordModal} text="Change password" />
      </section>

      <HorizontalRule />
      <section>
        <h3>Access log</h3>
        <p className={styles.description}>Logins for your account in the last {DAYS} days.</p>
        <LoaderSwitch loading={loading} size="large">
          {hasData && <Table className={styles.table} columns={columns} data={tableData as any} nested />}
          {!hasData && <p>No logins found</p>}
        </LoaderSwitch>
      </section>
    </Panel>
  );
};
export default ProfileSecurity;
