import { ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from '@apollo/client/link/http';

import store from 'frontend/state';
import { getAccessToken } from 'frontend/state/dux/auth';

import getCache from './cache';
import errorLink from './errorLink';

const apolloUri = `${window.env.API_URL}/api/v3/graphql`;

/*
  https://github.com/apollographql/apollo-client/issues/2160
 */
export const cleanTypename = new ApolloLink((operation, forward) => {
  const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

  if (operation.variables && !operation.getContext().hasUpload) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }

  return forward(operation);
});

const withOperationNameInQueryParams = new ApolloLink((operation, forward) => {
  if (window.env.NODE_ENV === 'development') {
    const context = operation.getContext();
    const contextURI = context.uri || apolloUri;
    operation.setContext({ uri: `${contextURI}?op=${operation.operationName}` });
  }
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: apolloUri,
  credentials: 'same-origin',
});

const authLink = setContext(async (_, { headers }) => {
  const state = store.getState();
  const token = await store.dispatch(getAccessToken());
  const authHeaders = { Authorization: `Bearer ${token}` };

  if (state.pusher.socketId) {
    authHeaders['x-pusher-socket-id'] = state.pusher.socketId;
  }
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

const link = ApolloLink.from(
  [
    cleanTypename,
    errorLink(),
    authLink,
    window.env.NODE_ENV === 'development' ? withOperationNameInQueryParams : undefined,
    httpLink,
  ].filter((apolloLink): apolloLink is ApolloLink => !!apolloLink), // filter out undefined
);

export default new ApolloClient({
  cache: getCache(),
  link,
});
