import { gql } from '@apollo/client';

export default gql`
  mutation TransferTopic($topicId: ID!, $botId: ID, $skillId: ID, $deleteOriginal: Boolean, $name: String) {
    transferTopic(topicId: $topicId, botId: $botId, skillId: $skillId, deleteOriginal: $deleteOriginal, name: $name) {
      id
      deletedOriginal
    }
  }
`;
