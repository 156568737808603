import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  type CustomNudgeType,
  type FormNudgeType,
  GetNudgeDocument,
  type MultipleChoiceNudgeType,
  type ProductNudgeType,
  type TextNudgeType,
} from 'frontend/api/generated';
import { Loader } from 'frontend/components';
import { NotFound } from 'frontend/features';

import { CustomNudge, FormNudge, MultipleChoiceNudge, ProductNudge, TextNudge } from '..';
import { nudgeType } from '../../constants';

const EditNudge = () => {
  const { slug, botId } = useParams();
  const { data, loading } = useQuery(GetNudgeDocument, {
    variables: { botId: String(botId), slug: slug! },
    skip: !botId || !slug,
  });

  const nudge = useMemo(() => data?.nudge ?? null, [data]);

  if (loading) return <Loader size="large" />;
  if (!nudge) return <NotFound text="Nudge not found" />;

  return (
    <>
      {nudge.type === nudgeType.FORM && <FormNudge nudge={nudge as FormNudgeType} />}
      {nudge.type === nudgeType.PRODUCT && <ProductNudge nudge={nudge as ProductNudgeType} />}
      {nudge.type === nudgeType.TEXT && <TextNudge nudge={nudge as TextNudgeType} />}
      {nudge.type === nudgeType.CUSTOM && <CustomNudge nudge={nudge as CustomNudgeType} />}
      {nudge.type === nudgeType.MULTIPLE_CHOICE && <MultipleChoiceNudge nudge={nudge as MultipleChoiceNudgeType} />}
    </>
  );
};

export default EditNudge;
