import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

export const InvitationType = PropTypes.exact({
  email: PropTypes.string.isRequired,
  inviter_email: PropTypes.string.isRequired,
  is_accepted: PropTypes.bool.isRequired,
  organization: PropTypes.exact({
    id: IDType.isRequired,
    name: PropTypes.string.isRequired,
    logo_url: PropTypes.string,
  }).isRequired,
});
export interface Invitation {
  email: string;
  inviter_email: string;
  is_accepted: boolean;
  organization: {
    id: number;
    name: string;
    logo_url?: string;
  };
}
