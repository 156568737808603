import PropTypes from 'prop-types';

import styles from './VideoEmbedPreview.scss';

const VimeoEmbed = ({ source }) => {
  const regExp = /^(?:https?:\/\/)?(www\.)?vimeo.com\/(\d+)($|\/)/;
  const match = source.match(regExp);
  if (!match) return null;

  return (
    <iframe
      src={`https://player.vimeo.com/video/${match[2]}?title=0&byline=0&portrait=0`}
      width="240"
      height="auto"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      title="Video preview from Vimeo"
      className={styles.iframe}
    />
  );
};

VimeoEmbed.propTypes = {
  source: PropTypes.string.isRequired,
};

export default VimeoEmbed;
