import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';

import { useUrlSearchParams } from 'frontend/hooks';

import type { FilterKeyType } from '../components/ButtonTitle/ButtonTitle';
import { FILTERS_LIST } from '../utils/config';
import { convertQueryParamsToPersistedValues, getFilterWithoutIndex } from '../utils/helpers';

export default () => {
  const { change, batch, getState, submit } = useForm();

  const [params] = useUrlSearchParams();

  useEffect(() => {
    // We listen to the changes in the url
    // and we update the form accordingly
    // in contrast, the form changes are reflected in the url on submit
    const urlFormParams = convertQueryParamsToPersistedValues();
    const { values } = getState();

    const urlParamsEntries = Object.entries(urlFormParams);
    if (urlParamsEntries.length) {
      const filterKeys = FILTERS_LIST.map(({ filterKey }) => filterKey);

      let hadChange = false;

      batch(() => {
        urlParamsEntries.forEach(([key, value]) => {
          if (filterKeys.includes(getFilterWithoutIndex(key) as FilterKeyType) && !isEqual(values[key], value)) {
            change(key, value);
            hadChange = true;
          }
        });
        if (hadChange) {
          submit();
        }
      });
    }
  }, [params, getState, batch, change, submit]);
};
