import { useNavigate } from 'react-router-dom';

import { ChevronLeft } from 'frontend/assets/icons';
import { Button } from 'frontend/components';

import styles from './BackButton.scss';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.buttonWrap}>
      <Button flat icon={ChevronLeft} onClick={() => navigate(-1)} text="Back" />
    </div>
  );
};

export default BackButton;
