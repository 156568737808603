import PropTypes from 'prop-types';

import { ToggleSwitch } from 'frontend/components';
import LanguagePicker from 'frontend/features/Library/components/LanguagePicker';
import { useBotOrSkill, useLanguages } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

import styles from './ToggleSpellingCorrection.scss';
import { spellingCorrectionAvailableInLanguage } from '../../utils';

const ToggleSpellingCorrection = ({ currentLanguage }) => {
  const { isBot, buildIdObject, buildType } = useBotOrSkill();
  const currentLanguageIsAvailable = spellingCorrectionAvailableInLanguage(currentLanguage);
  const { languageNameMap, languages } = useLanguages({ isBot, buildIdObject, buildType } as BotOrSkill);
  const currentLanguageName = languageNameMap?.[currentLanguage] || 'this language';

  return (
    <div>
      {languages && <LanguagePicker languages={languages} className={styles.languagePicker} supportVariants={false} />}
      <h3 className={`${styles.sectionTitle} m-b-3`}>Enable spelling correction</h3>
      <br />
      {currentLanguageIsAvailable ? (
        <>
          <p>
            <strong>NB! False positives may occur.</strong> E.g. &quot;airpods&quot; might be wrongly corrected to
            &quot;airport&quot;.
          </p>
          <div className="m-t-md m-b-sm">
            <ToggleSwitch name={`nlpUseSpellingCorrection.${currentLanguage}`} status={['Use spelling correction']} />
          </div>
        </>
      ) : (
        <p>Spelling correction is not yet available in {currentLanguageName}</p>
      )}
    </div>
  );
};

ToggleSpellingCorrection.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
};

export default ToggleSpellingCorrection;
