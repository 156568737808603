import { gql } from '@apollo/client';

import BOT_ROLE_FRAGMENT from 'frontend/features/Organization/fragments/BotRole.fragment.graphql';

export default gql`
  mutation UpdateCustomBotRoles(
    $botId: ID!
    $createdCustomRoles: [CustomRoleInput]
    $updatedCustomRoles: [CustomRoleInput]
    $deletedCustomRoles: [CustomRoleInput]
  ) {
    updateCustomBotRoles(
      botId: $botId
      createdCustomRoles: $createdCustomRoles
      updatedCustomRoles: $updatedCustomRoles
      deletedCustomRoles: $deletedCustomRoles
    ) {
      id
      roles {
        ...BotRole
      }
    }
  }
  ${BOT_ROLE_FRAGMENT}
`;
