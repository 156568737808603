import { gql } from '@apollo/client';

export default gql`
  mutation ResetSubscription($botId: ID!, $skillId: ID!) {
    resetSubscription(botId: $botId, skillId: $skillId) {
      id
      dialogueIds
    }
  }
`;
