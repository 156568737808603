import { gql } from '@apollo/client';

import { PLATFORM_ALERT_FRAGMENT } from 'frontend/api/fragments';

export const GET_ALL_PLATFORM_ALERTS = gql`
  query GetAllPlatformAlerts {
    platformAlerts {
      ...PlatformAlert
    }
  }
  ${PLATFORM_ALERT_FRAGMENT}
`;

export const GET_PLATFORM_ALERT = gql`
  query GetPlatformAlert($alertId: UUID!) {
    platformAlert(alertId: $alertId) {
      ...PlatformAlert
    }
  }
  ${PLATFORM_ALERT_FRAGMENT}
`;
