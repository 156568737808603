import React from 'react';

import { UserPlus } from 'frontend/assets/icons';
import { Dropdown } from 'frontend/components';
import TabButton from 'frontend/components/TabButton/TabButton';
import { useAppSelector } from 'frontend/state/hooks';

import styles from './ChatContainer.scss';
import { IS_REVIEW } from '../../hooks/useChatMessages';
import AgentsDropdown from '../AgentsDropdown/AgentsDropdown';
import ChatDetails from '../ChatDetails/ChatDetails';
import ChatHeader from '../ChatHeader/ChatHeader';
import ChatMessages from '../ChatMessages/ChatMessages';
import LabelsDropdown from '../LabelsDropdown/LabelsDropdown';

const ChatContainer = () => {
  const chatDetailsOpen = useAppSelector((state) => state.insights.chatDetailsOpen);

  return (
    <div className={styles.chatContainerWrapper}>
      <ChatHeader />
      <div className={styles.chatContainerContainer}>
        <div className={styles.chatContainerVerticalToolbar}>
          {IS_REVIEW && (
            <Dropdown
              overlay={<AgentsDropdown onClick={() => {}} />}
              position="right"
              element={TabButton}
              elementProps={{ icon: UserPlus, label: 'Assign' }}
            />
          )}
          <LabelsDropdown />
        </div>
        <div className={styles.chatContainerContent}>
          <ChatMessages />
        </div>
        {chatDetailsOpen && (
          <div data-testid="insights-chat-details" className={styles.chatContainerMeta}>
            <ChatDetails />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatContainer;
