import React from 'react';
import { Field } from 'react-final-form';

import { LoaderSwitch, Radio } from 'frontend/components';
import { useAgents } from 'frontend/features/Inbox/hooks';

import styles from './AgentsFilter.scss';

const AgentsFilter = () => {
  const { agents, loading } = useAgents();

  return (
    <LoaderSwitch loading={loading}>
      {agents.map(({ user }) => (
        <Field
          type="radio"
          className={styles.agentsFilterRadio}
          key={user.id}
          component={Radio}
          value={user.id}
          label={
            <div className={styles.agentsFilterItem}>
              <img src={user.profile.avatarUrl} alt={user.profile.firstName} />
              {user.profile.fullName}
            </div>
          }
          name="ticketAgentFilter"
        />
      ))}
    </LoaderSwitch>
  );
};

export default AgentsFilter;
