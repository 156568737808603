import cx from 'classnames';
import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import {
  Button,
  FileUpload,
  FormErrors,
  GuardUnsaved,
  Input,
  LoaderSwitch,
  PageBar,
  Panel,
  SelectLanguage,
  SimpleTextEditor,
  ToggleSwitch,
} from 'frontend/components';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { max } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';

import styles from './styles.scss';
import { welcomePageTypes } from '../../constants';
import chatBubbleStyles from '../../styles.scss';
import WelcomePagePreview from '../WelcomePagePreview';

const welcomePageEnabled = (values) => values.welcomePageType !== welcomePageTypes.NONE;
const welcomePagePicture = (values) => values.welcomePageType === welcomePageTypes.PICTURE;
const setWelcomePageType = (change, type) => change('welcomePageType', type);

const SUBSCRIBE = { values: true };

const formatWelcomePageType = (type) => Boolean(type) && type !== welcomePageTypes.NONE;
const parseWelcomePageType = (checked) => (checked ? welcomePageTypes.COLOR : welcomePageTypes.NONE);

const noop = () => null;

const WelcomePage = () => {
  const {
    onSubmitWithUpload,
    setFile,
    initialValues,
    currentLanguage,
    loading,
    languagesLoading,
    meLoading,
    languages,
    botId,
  } = useChatbubbleForm();

  const toast = useToast();

  const hasLanguagePicker = languages.length > 1;

  return (
    <LoaderSwitch loading={loading || languagesLoading || meLoading} size="large">
      <Form
        onSubmit={onSubmitWithUpload}
        initialValues={initialValues}
        initialValuesEqual={isEqual}
        mutators={{ ...arrayMutators }}
        subscribe={SUBSCRIBE}
        render={({ handleSubmit, form: { change }, values }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={chatBubbleStyles.formErrors} />
              <div className={chatBubbleStyles.chatbubbleSettingsWrapper}>
                <Panel>
                  <div className={chatBubbleStyles.selectLanguage}>
                    {hasLanguagePicker && <SelectLanguage languages={languages} supportVariants={false} />}
                  </div>
                  <h3 className="m-b-lg">Welcome page</h3>
                  <div className={`${chatBubbleStyles.flexContainer} m-t-sm m-b-md`}>
                    <ToggleSwitch
                      name="welcomePageType"
                      format={formatWelcomePageType}
                      parse={parseWelcomePageType}
                      className="m-r-md"
                    />
                    <label htmlFor="ToggleSwitch-welcomePageType">Use welcome page</label>
                  </div>
                  <p>
                    Use the welcome page to introduce the chatbot to new users before they begin chatting. Get all
                    formalities out of the way before the chat begins!
                  </p>
                  <p>
                    Colors are inherited from the{' '}
                    <Link className="link" to={`/workspace/${botId}/settings/chat/appearance`}>
                      appearance tab
                    </Link>
                    : primary color from “Header background{'“ '}
                    and button colors from “Button background“ and “Button text“.
                  </p>
                  {welcomePageEnabled(values) && (
                    <>
                      <div className="m-t-lg m-b-md">
                        <h5>Banner type</h5>
                        <div className={styles.selectionContainer}>
                          <Button
                            className={cx(styles.selectionButton, !welcomePagePicture(values) && styles.active)}
                            onClick={() => setWelcomePageType(change, welcomePageTypes.COLOR)}
                            text="Primary color"
                            flat
                          />
                          <Button
                            className={cx(styles.selectionButton, welcomePagePicture(values) && styles.active)}
                            onClick={() => setWelcomePageType(change, welcomePageTypes.PICTURE)}
                            text="Picture"
                            flat
                          />
                          {welcomePagePicture(values) && (
                            <>
                              <FileUpload
                                containerClassName={styles.uploadButton}
                                text="Upload"
                                onError={(error) => {
                                  toast.error(error?.message || 'There was an upload error. Please try again.');
                                }}
                                accept="image/png,image/jpeg"
                                sizeLimitKb={2 * 1024}
                                onUpload={([{ source, file }]) => {
                                  change('welcomePageImageUrl', source);
                                  setFile(file);
                                }}
                              />
                              {/* Make sure field is registered: https://github.com/final-form/final-form/issues/169#issuecomment-430939734 */}
                              <Field name="welcomePageImageUrl" component={noop} />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="m-t-lg m-b-md">
                        <div className={chatBubbleStyles.inputContainer}>
                          <Field
                            name={`welcomePageHeader.${currentLanguage}`}
                            label="Header"
                            component={Input}
                            inputLimit={50}
                            validate={max(50)}
                            placeholder="Choose an introduction title"
                          />
                        </div>
                      </div>
                      <div className="m-t-sm m-b-md">
                        <div className={chatBubbleStyles.inputContainer}>
                          <Field
                            name={`welcomePageStartButton.${currentLanguage}`}
                            label="Start button"
                            component={Input}
                            inputLimit={35}
                            validate={max(35)}
                            placeholder="Choose a text for the start button"
                          />
                        </div>
                      </div>
                      <div className="m-t-sm m-b-sm">
                        <div className={chatBubbleStyles.inputContainer}>
                          <Field
                            name={`welcomePageText.${currentLanguage}`}
                            component={SimpleTextEditor}
                            label="Text"
                            validate={max(300)}
                            placeholder="Choose a bot introduction"
                            preview={false}
                          />
                        </div>
                      </div>
                      <br />
                      <br />
                      <h3>Preview</h3>
                      <div className={styles.separator} />
                      <WelcomePagePreview currentLanguage={currentLanguage} hasLanguagePicker={hasLanguagePicker} />
                    </>
                  )}
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
};

export default WelcomePage;
