import { gql } from '@apollo/client';

import { skillSettingsFragment } from 'frontend/api/fragments';

const UPDATE_SKILL_LANGUAGES = gql`
  mutation UpdateSkillLanguages($skillId: ID!, $languageCodes: [String!]!) {
    updateSkillLanguages(skillId: $skillId, languageCodes: $languageCodes) {
      ...SkillSettings
    }
  }
  ${skillSettingsFragment}
`;
export default UPDATE_SKILL_LANGUAGES;
