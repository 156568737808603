import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { removeFieldsFromKeyWithPotentialVariables } from 'frontend/api/cacheHelpers';
import {
  OrganizationBotsDocument,
  OrganizationMetaDocument,
  OrganizationSkillsDocument,
  UnarchiveBotDocument,
} from 'frontend/api/generated';
import { Modal } from 'frontend/features/Modals';
import { useMixpanel, useToast } from 'frontend/hooks';

interface RecoverBotProps {
  hide(): Promise<void>;
  args: {
    name: string;
    botId: string;
  };
}

export const RecoverBot = ({ hide, args: { name, botId } }: RecoverBotProps) => {
  const toast = useToast();
  const { mixpanel } = useMixpanel();

  const client = useApolloClient();
  const { organizationId } = useParams<{ organizationId: string }>();

  const [unarchiveBot] = useMutation(UnarchiveBotDocument, {
    variables: { botId },
    awaitRefetchQueries: true,
    refetchQueries: [OrganizationMetaDocument, OrganizationBotsDocument, OrganizationSkillsDocument],
    onCompleted: () => {
      mixpanel.track('Recover workspace', { botId });
      removeFieldsFromKeyWithPotentialVariables(client, ['organization'], { organizationId });
    },
  });

  const onSubmit = useCallback(async () => {
    await unarchiveBot();
    toast.success(`Workspace "${name}" is recovered`);
  }, [unarchiveBot, name, toast]);

  return (
    <Modal title="Recover workspace" onOkText="Recover" hide={hide} onOk={onSubmit}>
      <Modal.Content>
        <h3 className="text-center">
          The workspace <strong>{name}</strong> and its content will be available once the workspace is recovered.
        </h3>
      </Modal.Content>
    </Modal>
  );
};
