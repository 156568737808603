import { gql } from '@apollo/client';

import ORGANIZATION_META_FRAGMENT from 'frontend/features/Organization/fragments/OrganizationMeta.fragment.graphql';

export default gql`
  query SkillOrganization($skillId: ID!) {
    skill(id: $skillId) {
      id
      organization {
        ...OrganizationMeta
      }
    }
  }
  ${ORGANIZATION_META_FRAGMENT}
`;
