import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  AllPermissionsDocument,
  OrganizationMembershipDocument,
  type OrganizationRoleType,
  UpdateOrganizationMembershipDocument,
  type UserType,
} from 'frontend/api/generated';
import { useIsRunning, useToast } from 'frontend/hooks';

import { MEMBERSHIP_TYPES } from '../../constants';
import { membershipRefetches } from '../../utils';
import UpdateMemberModal from '../AddMemberModal/UpdateMemberModal';
import { getUserName, roleIdsFromFields } from '../AddMemberModal/utils';

type Props = {
  hide: () => Promise<void>;
  args: {
    organizationId: string;
    user: UserType;
  };
};
const UpdateOrganizationMember = ({ hide, args: { organizationId, user } }: Props) => {
  const toast = useToast();

  const { data, loading } = useQuery(OrganizationMembershipDocument, {
    variables: { organizationId, userId: user.id },
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(AllPermissionsDocument);
  const refetchQueries = membershipRefetches({ organizationId, membershipType: MEMBERSHIP_TYPES.ORGANIZATION });
  const [mutate] = useMutation(UpdateOrganizationMembershipDocument, { refetchQueries });

  const allPermissions = permissionsData?.allPermissions ?? [];
  const roles = useMemo(() => data?.organization.roles ?? ([] as OrganizationRoleType[]), [data]);
  const currentRoles = useMemo(
    () => data?.organization.member.membership.roles ?? ([] as OrganizationRoleType[]),
    [data],
  );
  const users = useMemo(() => {
    const u = data?.organization.member.user;
    if (!u) {
      return [] as UserType[];
    }
    return [u] as UserType[];
  }, [data]);
  const name = useMemo(() => data?.organization.name ?? '', [data]);

  const submit = useCallback(
    async (values) => {
      const roleIds = roleIdsFromFields(values);
      await mutate({ variables: { organizationId, userId: user.id, roleIds } });
      toast.success(`${getUserName(user, values.email)}'s ${name} membership was updated!`);
      hide();
    },
    [organizationId, hide, mutate, name, toast, user],
  );

  const [onSubmit, isSubmitting] = useIsRunning(submit);

  if (loading || permissionsLoading) return null;

  return (
    <UpdateMemberModal
      currentRoles={currentRoles}
      users={users}
      email={user.email}
      name={name}
      roles={roles}
      allPermissions={allPermissions}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      hide={hide}
      disallowOrganizationRoles={false}
      organizationId={organizationId}
    />
  );
};

export default UpdateOrganizationMember;
