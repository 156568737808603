import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Checkbox, Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';

const TopicForm = ({ hide, onSubmit, actionName, initialValues }) => (
  <ModalForm onSubmit={onSubmit} hide={hide} initialValues={initialValues} title={`${actionName} folder`}>
    <Field
      component={Input}
      label="Name"
      name="name"
      className="m-b-lg"
      placeholder="Name"
      inputLimit={200}
      validate={chain(required, max(200))}
      autoFocus
    />
    <Field component={Checkbox} type="checkbox" name="isActive" label="Active" />
  </ModalForm>
);

TopicForm.propTypes = {
  hide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default TopicForm;
