import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { GetAllOrganizationsDocument } from 'frontend/api/generated';
import { LoaderSwitch, Select } from 'frontend/components';
import { ADD_USER_TO_ORGANIZATION } from 'frontend/features/Admin/views/Users/modals/AddUserToOrg/ADD_USER_TO_ORGANIZATION';
import { ModalForm } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { nbsp } from 'frontend/utils';

const INITIAL_VALUES = { organizationId: '' };

type Props = {
  hide: (...args: unknown[]) => unknown;
  args: {
    userId: string;
    username: string;
  };
};

function AddUserToOrg({ hide, args: { userId, username } }: Props) {
  const { loading, data } = useQuery(GetAllOrganizationsDocument);
  const organizationsList = useMemo(() => data?.allOrganizations || [], [data]);

  const toast = useToast();
  const [addUser] = useMutation(ADD_USER_TO_ORGANIZATION);
  const onSubmit = useCallback(
    async ({ organizationId }) => {
      await addUser({ variables: { organizationId, userId } });

      const organization = organizationsList.find(({ id }) => id === organizationId)!;
      const name = nbsp(organization.name);
      toast.success(`${username} added to ${name}`);
    },
    [addUser, userId, organizationsList, toast, username],
  );

  return (
    <ModalForm
      title="Add user to organization"
      onOkText="Send"
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      hide={hide}
    >
      <LoaderSwitch loading={loading} size="medium">
        <h4 className="m-b-md text-center">
          Add <strong>{username}</strong> to organization...
        </h4>
        <Field component={Select} name="organizationId" autoFocus>
          <Select.Option key="transfer-to-organization-0" value="" label="Choose organization" />
          {organizationsList.map(({ id, name }) => (
            <Select.Option key={`transfer-to-organization-${id}`} value={id} label={name} />
          ))}
        </Field>
      </LoaderSwitch>
    </ModalForm>
  );
}

export default AddUserToOrg;
