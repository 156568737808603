import { Field } from 'react-final-form';

import { Tags } from 'frontend/components';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';

import styles from '../../General/styles.scss';

const AllowedDomains = () => (
  <div>
    <h3 className={`${styles.sectionTitle} m-b-3`}>Allowed domains</h3>
    <p>
      Limit where Kindly Chat can be shown by adding allowed domains. All domains (<code>*</code>) are allowed by
      default. <br />
      <HelpCenterLink path="/general">Read more in our Help Center</HelpCenterLink>.
    </p>
    <label htmlFor="allowedDomains">Add allowed domains</label>
    <div className={styles.inputContainer}>
      <Field
        component={Tags}
        id="allowedDomains"
        name="chatbubbleDomains"
        className="m-b-sm m-t-sm"
        placeholder="*.example.com"
      />
    </div>
    <h4 className="m-t-md">Examples</h4>
    <ul>
      <li>
        Adding <code>example.com</code> will limit Kindly Chat to load on example.com.
      </li>
      <li>
        Adding <code>*.example.com</code> will allow example.com as well as <strong>any</strong> subdomain.
      </li>
    </ul>
  </div>
);

export default AllowedDomains;
