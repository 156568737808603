import { useQuery } from '@apollo/client';
import type { FormApi } from 'final-form';
import { sortBy } from 'lodash';
import { useState } from 'react';

import type { LanguageType } from 'frontend/api/generated';
import { getAvailableLanguages } from 'frontend/api/queries';
import { Language } from 'frontend/assets/icons';
import { LoaderSwitch, Select } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';

interface Props {
  hide: () => Promise<void>;
  args: {
    languages: LanguageType[];
    change: FormApi['change'];
  };
}

const languageIsAvailableToShow =
  (languages: LanguageType[]) =>
  ({ code: currentCode }: LanguageType) =>
    !languages.some(({ code }) => code === currentCode);

const AddLanguage = ({ hide, args: { change, languages } }: Props) => {
  const [languageCode, setLanguageCode] = useState('empty');
  const { data, loading } = useQuery(getAvailableLanguages);
  const viableLanguages = (data?.availableLanguages ?? []).filter(languageIsAvailableToShow(languages));

  const onOk = async () => {
    if (viableLanguages.length === 0 || languageCode === 'empty') return;

    const newLanguage = viableLanguages.find(({ code }) => code === languageCode);
    const updatedLanguages = sortBy([...languages, { ...newLanguage, active: false }], 'name');
    change('languages', updatedLanguages);
  };

  const selectInput = {
    value: languageCode,
    name: 'languageCode',
    onChange: (event) => setLanguageCode(event.target.value),
    onBlur: () => null,
    onFocus: () => null,
  };

  return (
    <Modal title="Add language" hide={hide} onOk={onOk} icon={Language}>
      <Modal.Content>
        <LoaderSwitch loading={loading} size="large" noMargin>
          {viableLanguages.length === 0 ? (
            <p>There are no more languages to choose from.</p>
          ) : (
            <>
              <p className="m-t-0">Select the language you want to add.</p>
              <Select input={selectInput} meta={{}}>
                <Select.Option label="Select language" value="empty" key="empty" />
                {viableLanguages.map(({ name, code }) => (
                  <Select.Option label={name} value={code} key={`language-${code}`} />
                ))}
              </Select>
            </>
          )}
        </LoaderSwitch>
      </Modal.Content>
    </Modal>
  );
};

export default AddLanguage;
