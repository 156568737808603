import cx from 'classnames';
import React from 'react';

import { Icon } from 'frontend/components';

import styles from './TabButton.scss';

export interface TabButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  badgeNumber?: number | string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  active?: boolean;
  isDisabled?: boolean;
  label: string;
}

function TabButton({
  icon,
  label,
  badgeNumber,
  isDisabled,
  className,
  active,
  ...props
}: TabButtonProps): React.JSX.Element {
  return (
    <button
      type="button"
      disabled={isDisabled}
      className={cx(className, styles.button, { [styles.buttonActive]: active })}
      {...props}
    >
      <Icon component={icon} />
      {label}
      {!!badgeNumber && <span className={cx(styles.badge, { [styles.badgeActive]: active })}>{badgeNumber}</span>}
    </button>
  );
}

export default TabButton;
