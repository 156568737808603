import { gql } from '@apollo/client';

// TODO: convert this to .graphql and avoid name confusion with frontend/src/features/Admin/views/AdminBots/queries/GET_ALL_BOTS.ts
const GET_ALL_BOTS = gql`
  query GetAllBots($botIds: [ID!]!) {
    allBots(botIds: $botIds) {
      id
      name
      avatar
      organization {
        id
        name
        logo
      }
      languages {
        id
        code
      }
    }
  }
`;

export default GET_ALL_BOTS;
