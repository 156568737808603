import { format } from 'date-fns';

import type { OrganizationType } from 'frontend/api/generated';
import { Button, Loader, PageContent, Panel } from 'frontend/components';
import DeleteBot from 'frontend/features/BotSettings/General/modals/DeleteBot';
import { useModal } from 'frontend/features/Modals';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { DATE_FORMAT_DATE_LONG } from 'frontend/utils/date';

import { RecoverBot as RecoverBotModal } from './modals';

interface RecoverBotProps {
  archivedAt: string;
  name: string;
  botId: string;
  organization: Partial<OrganizationType>;
}

export default function RecoverBot({ archivedAt, name, botId, organization }: RecoverBotProps) {
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });
  const [showRecoverBot] = useModal(RecoverBotModal);
  const [showDeleteBot] = useModal(DeleteBot);

  const canManageBot = hasBotPerms('manage_bot');

  if (loadingPermissions) {
    return <Loader size="large" />;
  }

  return (
    <PageContent>
      <Panel>
        <h1>
          Your workspace <strong>{name}</strong> is archived
        </h1>
        <p>Archived on: {format(new Date(archivedAt), DATE_FORMAT_DATE_LONG)}</p>
        <p>
          As long as the workspace is archived, its content is unavailable.{' '}
          {canManageBot && 'You may recover or delete your workspace.'}
        </p>
        {!canManageBot && (
          <p>
            To recover or delete this workspace, please contact the workspace admin or{' '}
            <a className="link" href="mailto:support@kindly.ai">
              support@kindly.ai
            </a>
            .
          </p>
        )}
        <div className="m-b-lg" />
        {canManageBot && (
          <>
            <Button color="primary" text="Recover workspace" onClick={() => showRecoverBot({ botId, name })} />
            <Button
              className="m-l-md"
              color="warning"
              text="Delete workspace"
              onClick={() => showDeleteBot({ botId, name, organizationId: organization.id })}
            />
          </>
        )}
      </Panel>
    </PageContent>
  );
}
