import { Language } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { useLanguageCodes } from 'frontend/features/Analytics/hooks';
import { useBotLanguages } from 'frontend/hooks';

import styles from '../../FilterInfo.scss';

const FilterInfoLanguages = () => {
  const [languageCodes] = useLanguageCodes();
  const { languageNameMap, languages: botLanguages } = useBotLanguages();

  const selectedLanguages =
    languageCodes.length === botLanguages.length || languageCodes.length === 0
      ? 'All languages'
      : languageCodes.map((lang) => languageNameMap[lang]).join(', ');

  return botLanguages?.length > 1 ? (
    <div className={styles.item}>
      <Icon component={Language} />
      <span>{selectedLanguages}</span>
    </div>
  ) : null;
};

export default FilterInfoLanguages;
