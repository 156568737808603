import React from 'react';
import { useField, useForm } from 'react-final-form';

import { Checkbox } from 'frontend/components';

import { handoverStatusData } from '../../utils/config';

const HandoverFilter = ({ filterKey, shouldSubmitOnToggle }: { filterKey: string; shouldSubmitOnToggle: boolean }) => {
  const { change, submit } = useForm();
  const {
    input: { value },
  } = useField(filterKey);

  return handoverStatusData.map((item) => (
    <Checkbox
      allowToggleOnWrapper
      key={item.value}
      input={{
        name: item.value,
        checked: value.includes(item.value),
        onChange: () => {},
      }}
      onClick={() => {
        const valueCopy = [...value];
        if (valueCopy.includes(item.value)) {
          valueCopy.splice(valueCopy.indexOf(item.value), 1);
        } else {
          valueCopy.push(item.value);
        }
        change(filterKey, valueCopy);
        if (shouldSubmitOnToggle) {
          submit();
        }
      }}
      label={item.label}
    />
  ));
};

export default HandoverFilter;
