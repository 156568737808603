import cx from 'classnames';
import PropTypes from 'prop-types';

import { useKeyDown } from 'frontend/hooks';

import styles from './RolePermissionItem.scss';

const Unchecked = ({ onClick }) => {
  const onKeyDown = useKeyDown({ action: onClick });

  return (
    <div
      className={cx(styles.checkMark, styles.checkMarkOff)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    />
  );
};

Unchecked.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Unchecked;
