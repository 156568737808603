import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { DRAGGABLE_TYPES } from 'frontend/features/Dnd/constants';
import { useFormSort } from 'frontend/features/Dnd/hooks';

import FieldWrapper from '../FieldWrapper/FieldWrapper';

const FieldsArray = ({ formIndex }: { formIndex: number }) => {
  useFormSort(DRAGGABLE_TYPES.FORM_FIELD, `buildForms[${formIndex}].fields`, 'order');

  return (
    <FieldArray key={`buildForm-array-${formIndex}`} name={`buildForms[${formIndex}].fields`}>
      {({ fields }) =>
        fields.map((name: string, index) => (
          <FieldWrapper remove={fields.remove} index={index} key={`f-${fields.value[index]?.id}`} fieldName={name} />
        ))
      }
    </FieldArray>
  );
};

export default FieldsArray;
