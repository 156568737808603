import { format } from 'date-fns/format';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DotsVertical, DownloadCloud, FolderPerson, Trash } from 'frontend/assets/icons';
import { Button, Dropdown, ImageAvatar } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { setInsightsChatDetailsOpen } from 'frontend/state/dux/insights';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';
import { friendlyTimestamp } from 'frontend/utils';
import { TIME_FORMAT_HOURS } from 'frontend/utils/date';

import styles from './ChatHeader.scss';
import { IS_REVIEW } from '../../hooks/useChatMessages';
import useChats from '../../hooks/useChats';
import useDeleteChat from '../../hooks/useDeleteChat';
import useDownloadChat from '../../hooks/useDownloadChat';
import DeleteChat from '../../modals/DeleteChat';
import getUserName from '../../utils/getUserName';

const ChatHeader = () => {
  const { botId, chatId } = useParams();
  const { selectedChat, chatDetailsOpen } = useAppSelector((state) => state.insights);
  const { chats } = useChats();

  const dispatch = useAppDispatch();

  const nextChatId = useMemo(() => {
    if (!chats) return '';
    const currentChatIndex = chats.findIndex((chat) => chat.id === chatId);
    if (currentChatIndex > 0) {
      return chats[currentChatIndex - 1]?.id;
    }
    return chats[1]?.id;
  }, [chats, chatId]) as string;

  const deleteChat = useDeleteChat({ botId, chatId, nextChatId });
  const downloadChat = useDownloadChat({ botId, chatId });

  const [showDeleteModal] = useModal(DeleteChat);

  const userName = getUserName(selectedChat);

  return (
    <div className={styles.chatContainerHeader}>
      {selectedChat && (
        <>
          <div className={styles.chatContainerHeaderLeft}>
            <div className={styles.chatContainerHeaderLeftImg}>
              <ImageAvatar
                className={styles.avatar}
                img={selectedChat.avatarUrl as string}
                autoUser={selectedChat.autoUser}
              />
            </div>
            <div className={styles.chatContainerHeaderLeftDetails}>
              <div className={styles.chatContainerHeaderLeftTitle}>{selectedChat.subject || userName}</div>
              <div className={styles.chatContainerHeaderLeftInfo}>
                {format(selectedChat.updated, TIME_FORMAT_HOURS)}&nbsp;-&nbsp;
                {friendlyTimestamp(selectedChat.updated, { monthName: true, year: true })}
              </div>
            </div>
          </div>
          <div className={styles.chatContainerHeaderRight}>
            {IS_REVIEW && <Button color="idle" text="Review" onClick={() => {}} />}
            <Dropdown
              element={Button}
              elementProps={{
                flat: true,
                icon: DotsVertical,
              }}
              data-testid="chat-details-dropdown"
              overlay={
                <Dropdown.MenuOverlay
                  options={[
                    {
                      text: `${chatDetailsOpen ? 'Hide' : 'Show'} chat details`,
                      icon: FolderPerson,
                      key: 'hide-show-chat-details',
                      onClick: () => dispatch(setInsightsChatDetailsOpen(!chatDetailsOpen)),
                    },
                    { text: 'Download chat', icon: DownloadCloud, key: 'chat-download', onClick: downloadChat },
                    {
                      text: 'Delete chat',
                      icon: Trash,
                      color: 'red',
                      key: 'chat-delete',
                      onClick: () => showDeleteModal({ deleteChat, userName }),
                    },
                  ]}
                />
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ChatHeader;
