import { gql } from '@apollo/client';

export default gql`
  fragment TicketLog on TicketLogType {
    id
    action
    valueBefore
    valueAfter
    created: createdAt
    actionByEndUser
    user {
      id
      profile {
        id
        fullName
      }
    }
  }
`;
