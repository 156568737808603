import { Field } from 'react-final-form';

import { Channel, Language } from 'frontend/assets/icons';
import { Checkbox, FilterAccordion } from 'frontend/components';
import SourcesFilter from 'frontend/features/Inbox/components/InboxFilters/components/SourcesFilter/SourcesFilter';
import { useAvailableLanguages } from 'frontend/hooks';

import styles from '../../FilterItems.scss';

interface PlatformDataItemsProps {
  hideFilter?: Record<string, boolean>;
}

const PlatformDataItems = ({ hideFilter }: PlatformDataItemsProps) => {
  const { languages, loading: languagesLoading } = useAvailableLanguages(true);

  if (languagesLoading) {
    return null;
  }

  return (
    <>
      {!hideFilter?.language && (
        <FilterAccordion title="Language" icon={Language}>
          {languages?.map(({ code, name }) => (
            <Field
              key={code}
              name={`languageCodes.${code}`}
              className={styles.filterListItem}
              component={Checkbox}
              type="checkbox"
              label={name}
            />
          ))}
        </FilterAccordion>
      )}
      {!hideFilter?.sources && (
        <FilterAccordion title="Channel" icon={Channel}>
          <SourcesFilter sourceKey="sources" />
        </FilterAccordion>
      )}
    </>
  );
};

export default PlatformDataItems;
