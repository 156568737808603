import { ChevronLeft } from 'frontend/assets/icons';

import styles from './Filters.scss';
import Button from '../Button';

interface FilterBackButtonProps {
  handleSubmit(): unknown;
  type?: 'submit' | 'button';
}

const FilterBackButton = ({ handleSubmit, type }: FilterBackButtonProps) => (
  <Button {...(type && { type })} icon={ChevronLeft} className={styles.back} onClick={handleSubmit} color="primary">
    Apply filters
  </Button>
);

export default FilterBackButton;
