import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $insertNodes } from 'lexical';
import { useLayoutEffect } from 'react';

import { cleanLexicalHTML } from 'frontend/features/Build/components/Replies/Replies';

interface Props {
  /** The initial HTML to initialize the Lexical editor to. */
  initialHtml: string;
  /** Whether the form is pristine (i.e. equal to InitialValues) or not. */
  isFormPristine: boolean;
}

/** This plugin makes sure that in case the Form is re-initialized, we update the Lexical Editor to the Initial Values. */
export default function ReinitializeEditorFromFormPlugin({ initialHtml, isFormPristine }: Props): null {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    if (!isFormPristine) return;

    editor.update(() => {
      const currentHtmlInEditor = cleanLexicalHTML($generateHtmlFromNodes(editor, null));
      if (currentHtmlInEditor === initialHtml) return;

      /* If the Form is pristine, and what's showed in Lexical is different from the Initial Values, we need to update it. */
      const root = $getRoot();
      root.clear();

      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHtml || '', 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      root.select();
      $insertNodes(nodes);
    });
  }, [editor, initialHtml, isFormPristine]);

  return null;
}
