import { gql } from '@apollo/client';

import demoFragment from 'frontend/api/fragments/demoFragment';

export default gql`
  mutation UpdateDemo($demo: DemoInput!, $botId: ID!) {
    updateDemo(demoInput: $demo, botId: $botId) {
      ...Demo
    }
  }
  ${demoFragment}
`;
