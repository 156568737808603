import { gql } from '@apollo/client';

export default gql`
  query KindlyEntityDialogueInfo($id: UUID!, $botId: ID!) {
    kindlyEntity(id: $id, botId: $botId) {
      id
      name
      dialogues(botId: $botId) {
        id
        title
        url(botId: $botId)
      }
      dialogueMods(botId: $botId) {
        id
        url
        modDialogue {
          id
          title
        }
      }
      subscriptions(botId: $botId) {
        id
        skill {
          id
          name
        }
        dialogueIdsWithKindlyEntity(kindlyEntityId: $id)
      }
    }
  }
`;
