import PropTypes from 'prop-types';

import CheckMark from '../CheckMark';

const DefaultRolePermissionComponent = ({ hasPermission }) => (hasPermission ? <CheckMark /> : <div />);

DefaultRolePermissionComponent.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
};

export default DefaultRolePermissionComponent;
