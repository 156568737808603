import cx from 'classnames';
import type { Maybe } from 'graphql/jsutils/Maybe';
import { isEqual, truncate } from 'lodash';
import React, { memo, useEffect, useRef } from 'react';
import { NavLink, type To } from 'react-router-dom';

import type { ChatFeedbackType, ChatType, ContactDetailsInput, LabelType } from 'frontend/api/generated';
import { ImageAvatar } from 'frontend/components';
import AdvancedTooltip from 'frontend/components/AdvancedTooltip/AdvancedTooltip';
import ChatStatus from 'frontend/features/Insights/components/ChatStatus/ChatStatus';
import FeedbackSentiment from 'frontend/features/Insights/components/FeedbackSentiment/FeedbackSentiment';
import getUserName from 'frontend/features/Insights/utils/getUserName';
import hasRequestedContact from 'frontend/features/Insights/utils/hasRequestedContact';
import { useAppSelector } from 'frontend/state/hooks';
import { friendlyTimestamp } from 'frontend/utils';
import isElementInViewport from 'frontend/utils/isElementInViewport';

import styles from './ChatPreview.scss';
import useLabels from '../../hooks/useLabels';
import { MINIMUM_CHARACTERS_BEFORE_SEARCH } from '../../utils/constants';
import getChatStatus from '../../utils/getChatStatus';
import LabelList from '../LabelsList/LabelsList';

function longestCommonSubstring(str1: string, str2: string): string {
  const m = str1.length;
  const n = str2.length;
  let maxLength = 0;
  let endIdx = 0;

  // Create a 2D array to store lengths of longest common suffixes
  // dp[i][j] will be the length of the longest common suffix of str1[0..i-1] and str2[0..j-1]
  const dp: number[][] = Array(m + 1)
    .fill(null)
    .map(() => Array(n + 1).fill(0));

  // Build the dp array
  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        // @ts-expect-error how to fix it?
        dp[i][j] = (dp[i - 1]?.[j - 1] || 0) + 1;
        if ((dp[i]?.[j] || 0) > maxLength) {
          maxLength = dp[i]?.[j] || 0;
          endIdx = i - 1;
        }
      }
    }
  }

  // Extract the longest common substring
  const longestSubstring = str1.slice(endIdx - maxLength + 1, endIdx + 1);
  return longestSubstring;
}

interface ChatPreview extends Partial<ChatType> {
  to: To;
  contactDetails: ContactDetailsInput;
  labelIds: string[];
  feedbacks: ChatFeedbackType[];
  onClick: (id: Maybe<string> | undefined) => void;
  botLabels: Partial<LabelType>[];
  className?: string;
  selectedChatId?: string;
  search?: string;
}

const ChatPreview = ({
  id,
  to,
  updated,
  avatarUrl,
  autoUser,
  fullName,
  subject,
  preview,
  className,
  lastMessageIsFromUser,
  contactDetails,
  takenOver: handedOver,
  completedTakeover: completedHandover,
  labelIds,
  feedbacks,
  onClick,
  selectedChatId,
  search,
  botLabels,
}: ChatPreview) => {
  const labels = useLabels({ botLabels, labelIds });

  const chatRef = useRef<HTMLAnchorElement>(null);

  const userName = getUserName({ fullName, autoUser });
  const hasLabels = labels.length > 0;
  const requestedContact = hasRequestedContact({ contactDetails });

  const searchUIFilters = useAppSelector((state) => state.insights.searchUIFilters);

  const chatStatus = getChatStatus({
    lastMessageIsFromUser,
    handedOver,
    completedHandover,
    requestedContact,
  });

  useEffect(() => {
    if (chatRef.current && id && selectedChatId && selectedChatId === id) {
      if (!isElementInViewport(chatRef.current)) {
        chatRef.current.scrollIntoView();
      }
    }
  }, [id, selectedChatId]);

  const shouldDisplayNotification = !!chatStatus && !completedHandover && lastMessageIsFromUser;

  const renderPreview = () => {
    if (search && search.length >= MINIMUM_CHARACTERS_BEFORE_SEARCH && preview) {
      const commonSubstring = longestCommonSubstring(search.toLowerCase(), preview.toLowerCase());
      const previewArray = truncate(preview, { length: 110 }).split(new RegExp(commonSubstring, 'i'));

      return previewArray.map((stringPart, index) => {
        if (index <= previewArray.length && index % 2 === 1) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`highlighted-${index}-${commonSubstring}`}>
              &nbsp;
              <span className={styles.searchHighlight}>{commonSubstring}</span>
              {stringPart}
            </React.Fragment>
          );
        }

        return stringPart;
      });
    }

    return preview;
  };

  return (
    <NavLink
      ref={chatRef}
      to={to}
      className={({ isActive }) =>
        cx(styles.wrapper, className, {
          [styles.active]: isActive,
        })
      }
      onClick={() => onClick(id)}
      data-cy="navlink-chat-preview"
    >
      <div className={styles.header}>
        <div className={styles.leftContent}>
          {searchUIFilters.userName && (
            <>
              <ImageAvatar className={styles.avatar} img={avatarUrl as string} autoUser={autoUser} />
              <AdvancedTooltip maxWidth="240" className={styles.title}>
                {subject || userName}
                <AdvancedTooltip.Body className={styles.tooltipTitle}>{subject || userName}</AdvancedTooltip.Body>
              </AdvancedTooltip>
              <span className={styles.time}>
                &nbsp;-&nbsp;{friendlyTimestamp(updated, { monthName: true, year: true })}
              </span>
            </>
          )}
        </div>

        <div className={styles.rightContent}>
          {/* TODO use when we have the review */}
          {/* {searchUIFilters.assigned && (
            <AgentAvatar
              assignedAgent={assignedAgent}
              handoverByAgentId={handoverByAgentId?.toString()}
              botId={botId}
            />
          )} */}
        </div>
      </div>
      <div className={styles.textContainer}>
        <AdvancedTooltip>
          {renderPreview()}
          <AdvancedTooltip.Body>{preview}</AdvancedTooltip.Body>
        </AdvancedTooltip>
      </div>
      {((hasLabels && searchUIFilters.showLabels) || (searchUIFilters.feedback && !!feedbacks.length)) && (
        <div className={styles.footer}>
          <div className={styles.leftContent}>
            {hasLabels && searchUIFilters.showLabels && <LabelList labels={labels} maxItems={3} />}
          </div>
          <div className={styles.rightContent}>
            {searchUIFilters.feedback && feedbacks.length ? <FeedbackSentiment data={feedbacks} /> : null}
          </div>
        </div>
      )}
      {chatStatus && <ChatStatus status={chatStatus} notification={!!shouldDisplayNotification} />}
    </NavLink>
  );
};

const arePropsEqual = (currentProps, nextProps) => isEqual(currentProps, nextProps);

export default memo(ChatPreview, arePropsEqual);
