import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';

import { AiGeneratedReplyDocument } from 'frontend/api/generated';
import { Slot } from 'frontend/assets/icons';
import { InfoBox, Input } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import { useMixpanel } from 'frontend/hooks';

import styles from './HandoverForm.scss';

const LOCAL_STORAGE_KEY = 'inbox-ai-reformulation';

interface Props {
  hide(): Promise<void>;
  args: {
    getCurrentEditorText: () => string;
    replaceEditorState: (text: string) => void;
  };
}

export default function ModalAIAssistance({ hide, args: { getCurrentEditorText, replaceEditorState } }: Props) {
  const [prompt, setPrompt] = useState(() => {
    const promptFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
    return promptFromLocalStorage || 'Reformulate this message in a kinder and clearer way';
  });

  const [getGeneratedReply, { loading: queryLoading }] = useLazyQuery(AiGeneratedReplyDocument, {
    fetchPolicy: 'network-only',
  });

  const { mixpanel } = useMixpanel();

  const handleGenerateReply = async () => {
    const currentEditorText = getCurrentEditorText();

    const { data } = await getGeneratedReply({
      variables: {
        draftText: currentEditorText,
        instruction: prompt,
      },
    });

    mixpanel.track('AI Inbox message reformulation: Reformulate', { messageLength: currentEditorText.length });
    replaceEditorState(data?.reformulatedReply || currentEditorText);
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, prompt);
    } catch (e) {
      // don't crash application if local storage is not available
    }
    hide();
  };

  return (
    <Modal
      icon={Slot}
      title="Reformulate your message with AI"
      hide={hide}
      onOk={handleGenerateReply}
      onOkText="Reformulate"
    >
      <Modal.Content>
        {queryLoading ? (
          <p>Reformulating your message...</p>
        ) : (
          <>
            <p>Use the power of AI 🤖 to help you reformulate your message to your customer!</p>
            <label htmlFor="AI-reformulation-1680262142415">Adjust the instructions to our AI to your need:</label>
            <Input
              input={{
                name: 'AI-reformulation-1680262142415',
                value: prompt,
                onChange: ({ target }) => setPrompt(target.value),
              }}
              maxLength={100}
            />
            <InfoBox className="m-t-4">
              <p>
                Try to experiment with different instructions to see how the AI reformulates your message. You can also
                ask to use a specific language or a famous person&apos;s style (like Shakespeare).
              </p>
              <p>
                If you don&apos;t like the result, you can revert to the original message with{' '}
                <span className={styles.undoText}>
                  <kbd>CTRL</kbd>/<kbd>CMD</kbd> + <kbd>Z</kbd>
                </span>
                .
              </p>
            </InfoBox>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}
