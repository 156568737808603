import { useApolloClient, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { ChevronLeft } from 'frontend/assets/icons';
import { Button, Loader, Panel } from 'frontend/components';
import { DeleteEvaluation, RunEvaluation } from 'frontend/features/Evaluation/modals';
import { useModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import styles from '../Evaluation.scss';
import { DetailedReport, ReportHistory, ReportOverview, ResultFilters } from '../components';
import { GET_EVALUATION } from '../graphql';
import { useEvaluationFinishedUpdate, useRunReport, useSelectedReport } from '../hooks';

const EvaluationSetDetail = ({ reportId = null }) => {
  const { botId } = useParams();
  const { evaluationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const client = useApolloClient();
  const [showRunModal] = useModal(RunEvaluation);
  const [showDeleteEvaluation] = useModal(DeleteEvaluation);

  const { run, isRunning, setIsRunning } = useRunReport({ evaluationId });
  useEvaluationFinishedUpdate({ botId, evaluationId, setIsRunning, toast, client });

  const { data, loading } = useQuery(GET_EVALUATION, { variables: { evaluationId } });
  const reports = useMemo(() => data?.evaluation?.evaluationReports ?? [], [data]);

  const { selectedReport, comparingReport, selectReport } = useSelectedReport(reports, reportId);

  if (loading) {
    return <Loader size="large" />;
  }
  const evaluation = data.evaluation;

  if (reports === null || reports.length === 0) {
    return <Navigate to="create" />;
  }

  // Make sure selectedReport state is ready
  if (!selectedReport) {
    return null;
  }
  return (
    <div className={styles.reportContainer}>
      <h1 className="m-b-0">Evaluation set: {evaluation.title}</h1>
      <p className="m-t-0 m-b">
        Language: <strong>{evaluation.languageCode}</strong>
      </p>
      <div className="m-b-md">
        <Button flat icon={ChevronLeft} onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button className="m-l-md" onClick={() => navigate(`../${evaluationId}`)}>
          Update queries
        </Button>
        <Button
          className="m-l-md"
          color="primary"
          onClick={() =>
            showRunModal({
              run,
              confidenceThreshold: data.evaluation.confidenceThreshold,
              estimateRunTimeSeconds: data.evaluation.estimateRunTimeSeconds,
            })
          }
          isSubmitting={isRunning}
          disabled={isRunning}
        >
          Create new report
        </Button>
        <Button flat onClick={() => showDeleteEvaluation({ evaluationId })} text="Delete" />
      </div>
      <div className={styles.grid}>
        <Panel className="p-n">
          <h3 className={styles.historyTitle}>Report history</h3>
          <ReportHistory reports={reports} selectedReport={selectedReport} selectReport={selectReport} />
        </Panel>
        <div>
          <h2 className={styles.overviewHeader}>Report #{selectedReport.id}</h2>
          <Panel className={styles.overview}>
            <ReportOverview
              selectedReport={selectedReport}
              comparingReport={comparingReport}
              reports={reports}
              selectReport={selectReport}
            />
            <h3>Results per dialogue</h3>
            <ResultFilters />
            <DetailedReport evaluationId={evaluationId} reportId={selectedReport.id} />
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default EvaluationSetDetail;
