import { formatISO } from 'date-fns';
import { EditorState } from 'draft-js';

import randomUUID from 'frontend/utils/randomUUID';

export default function getEmptyReply(
  languageCode: string,
  currentRuleId: string | null = null,
  replyEntitiesEnabled = false,
) {
  return {
    languageCode,
    rule: currentRuleId ? { id: currentRuleId } : undefined,
    tempId: randomUUID(),
    createdAt: formatISO(new Date()),
    updatedAt: formatISO(new Date()),
    ...(replyEntitiesEnabled ? { editorState: EditorState.createEmpty() } : { text: '' }),
  };
}
