import { useMutation } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback } from 'react';

import { UpdateBotSettingsDocument } from 'frontend/api/generated';
import { replaceListMerge } from 'frontend/utils';

import { IGNORED_BOT_SETTINGS } from '../constants';

export default (botSettings, onUpdate) => {
  const [updateBotMutation] = useMutation(UpdateBotSettingsDocument);

  const updateBot = useCallback(
    async (values) => {
      const botInput = { ...omit(replaceListMerge(botSettings, values), IGNORED_BOT_SETTINGS), deleteAvatar: false };
      await updateBotMutation({ variables: { botId: botSettings.id, botInput } });
      if (onUpdate) onUpdate();
    },
    [botSettings, onUpdate, updateBotMutation],
  );

  return updateBot;
};
