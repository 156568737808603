import { useCurrentLanguage } from 'frontend/hooks';

import { FormNudge, MultipleChoiceNudge, ProductNudge, TextNudge } from './components';

const NudgePreview = ({ nudge }) => {
  const [{ currentLanguage }] = useCurrentLanguage();

  if (!nudge) return null;

  const { type } = nudge;

  if (type === 'Text') return <TextNudge nudge={nudge} currentLanguage={currentLanguage} />;
  if (type === 'Product') return <ProductNudge nudge={nudge} currentLanguage={currentLanguage} />;
  if (type === 'Form') return <FormNudge nudge={nudge} currentLanguage={currentLanguage} />;
  if (type === 'Multiple choice') return <MultipleChoiceNudge nudge={nudge} currentLanguage={currentLanguage} />;

  return null;
};

export default NudgePreview;
