import { Dropdown, List } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './UserActionsDropdown.scss';
import { AddUserToOrg, DeleteUser, UpdateAdminGroupModal } from '../../modals';

type Action = {
  title: string;
  key: string;
  onClick: () => void;
};

interface UserActionsOverlayProps {
  close?: () => void;
  actions: Action[];
}

function UserActionsOverlay({ close, actions }: UserActionsOverlayProps) {
  return (
    <List
      dataSource={actions}
      renderItem={(item: Action) => (
        <List.Item
          className={styles.listItem}
          key={item.key}
          onClick={() => {
            close?.();
            item.onClick();
          }}
          data-testid={item.key}
        >
          {item.title}
        </List.Item>
      )}
    />
  );
}

interface UserActionsDropdownProps {
  id: string;
  email: string;
  groups: {
    id: string;
    name: string;
  }[];
}

export default function UserActionsDropdown({ id, email, groups }: UserActionsDropdownProps) {
  const [showDeleteUserModal] = useModal(DeleteUser);
  const [showAddUserToOrgModal] = useModal(AddUserToOrg);
  const [showUpdateAdminGroupModal] = useModal(UpdateAdminGroupModal);
  const { isKindlyAdminManager } = useMyPermissions();

  const actions = [
    {
      title: 'Add user to organization',
      key: `add-user-to-org-${id}`,
      onClick: () => showAddUserToOrgModal({ userId: id, username: email }),
    },
    {
      title: 'Delete user',
      key: `delete-user-${id}`,
      onClick: () => showDeleteUserModal({ id, email }),
    },
    isKindlyAdminManager && {
      title: 'Update admin group',
      key: `update-admin-group-${id}`,
      onClick: () => showUpdateAdminGroupModal({ userId: id, email, groups }),
    },
  ].filter((action) => Boolean(action)) as Action[];

  return (
    <Dropdown overlay={<UserActionsOverlay actions={actions} />} data-testid={`admin-user-actions-dropdown-${id}`}>
      <span className={styles.chevron}>▾</span>
    </Dropdown>
  );
}
