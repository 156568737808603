import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { Panel } from 'frontend/components';
import { AnalyticsContent } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import BaywatchOverview from 'frontend/features/Analytics/views/Baywatch/BaywatchOverview';
import Filters, { FilterInfo } from 'frontend/features/Analytics/views/Filters';
import { useEngagementForm } from 'frontend/features/BotSettings/hooks';
import { useBooleanState } from 'frontend/hooks';
import { DATE_FORMAT_YEAR_MONTH_DAY } from 'frontend/utils/date';

import { FETCH_NUDGE_CAMPAIGN_DATA } from '../../queries';

const HIDE_FILTERS = { feedbacks: true, engagementCampaigns: true, sessionTypes: true, sources: true };
const SKIP = Object.keys(HIDE_FILTERS);

const Nudge = () => {
  const { botId } = useParams();
  const { initialValues, loading: engagementDataLoading } = useEngagementForm();
  const engagementId = initialValues?.engagementId;
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const filters = useFilters({ skip: SKIP });
  const { nudge_id: campaignId, granularity } = filters;

  const { data, loading: campaignDataLoading } = useQuery(FETCH_NUDGE_CAMPAIGN_DATA, {
    variables: {
      botId,
      engagementId,
      campaignId,
      granularity,
      fromDate: format(filters.from, DATE_FORMAT_YEAR_MONTH_DAY),
      toDate: format(filters.to, DATE_FORMAT_YEAR_MONTH_DAY),
    },
    skip: !engagementId,
  });
  const campaignData = data?.nudgeCampaignData;

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} hideFilter={HIDE_FILTERS} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} />
        <div>
          {!engagementId && !engagementDataLoading && (
            <Panel>
              <h3>Engagement id is not set up for this bot.</h3>
              <p>Contact your contact person in Kindly or send us a message to support@kindly.ai</p>
            </Panel>
          )}
          {!!engagementId && (
            <BaywatchOverview
              campaignType="nudge"
              campaignId={campaignId}
              campaignData={campaignData}
              granularity={granularity}
              loading={campaignDataLoading}
            />
          )}
        </div>
      </AnalyticsContent>
    </>
  );
};

export default Nudge;
