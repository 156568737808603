import { $generateNodesFromDOM } from '@lexical/html';
import { LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import * as Sentry from '@sentry/browser';
import cx from 'classnames';
import { $getRoot, $insertNodes, type EditorState, type LexicalEditor as LexicalEditorType } from 'lexical';

import styles from './LexicalEditor.scss';
import ReinitializeEditorFromFormPlugin from './Plugins/InitializeEditorPlugin';
import OnChangePlugin from './Plugins/OnChangePlugin';
import { ToolbarPlugin } from './Plugins/ToolbarPlugin';
import { validateUrl } from './utils/validateUrl';
import InputErrorWrapper from '../InputErrorWrapper/InputErrorWrapper';

const theme = {
  editor: styles.editor,
  'editor-input': styles['editor-input'],
  paragraph: styles.paragraph,
  link: styles['text-link'],
  text: {
    bold: styles['text-bold'],
    italic: styles['text-italic'],
    underline: styles['text-underline'],
    code: styles['text-code'],
    highlight: styles['text-highlight'],
    strikethrough: styles['text-strikethrough'],
    subscript: styles['text-subscript'],
    superscript: styles['text-superscript'],
  },
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
const onError = (err) => {
  console.error(err);
  Sentry.captureException(err);
};

interface Props {
  /** The initial HTML to initialize the Lexical editor to. */
  initialHtml: string;
  /** A callback to be called when the editor changes. */
  handleChange: (edState: EditorState, editor: LexicalEditorType) => void;
  /** A component to render in the same wrapper of the editor. */
  InputSideComponent: React.ReactNode;
  /** Classnames to apply to the component parts. */
  classNames: {
    inputWrapper: string;
  };
  /** Meta field passed by React Final Form to handle errors. */
  meta: any;
}

export default function LexicalEditor({
  initialHtml,
  handleChange,
  InputSideComponent,
  classNames,
  meta,
}: Props): React.JSX.Element {
  const initialConfig = {
    namespace: 'MyEditor',
    theme,
    onError,
    nodes: [ListNode, ListItemNode, LinkNode],
    editorState: (editor: LexicalEditorType) => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHtml || '', 'text/html');
      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom);
      // Select the root
      $getRoot().select();
      // Insert them at a selection.
      $insertNodes(nodes);
    },
  };

  const hasError = Boolean(meta?.submitFailed && (meta?.submitError || meta?.error));
  const errorMessage = meta?.submitError || meta?.error || '';

  return (
    <div className={styles.wrapper}>
      <LexicalComposer initialConfig={initialConfig}>
        <ToolbarPlugin />
        <div className={cx(styles.inputWrapper, classNames?.inputWrapper)}>
          <InputErrorWrapper
            hasError={hasError}
            displayError={errorMessage}
            errorPosition="bottom"
            className={styles.errorWrapper}
          >
            <RichTextPlugin
              contentEditable={<ContentEditable className={styles.input} />}
              placeholder={<div className={styles.placeholder}>Enter some text...</div>}
              ErrorBoundary={LexicalErrorBoundary}
            />
          </InputErrorWrapper>
          {InputSideComponent}
        </div>
        <ListPlugin />
        <HistoryPlugin />
        <LinkPlugin validateUrl={validateUrl} />
        <OnChangePlugin handleChange={handleChange} />
        <ReinitializeEditorFromFormPlugin initialHtml={initialHtml} isFormPristine={meta.pristine} />
      </LexicalComposer>
    </div>
  );
}
