import { gql } from '@apollo/client';

const DELETE_EVALUATION_SET = gql`
  mutation DeleteEvaluation($evaluationId: ID!) {
    deleteEvaluation(evaluationId: $evaluationId) {
      id
    }
  }
`;
export default DELETE_EVALUATION_SET;
