import cx from 'classnames';
import { isNil } from 'lodash';

import styles from './Accordion.scss';

interface HeaderRightProps {
  color?: string;
  children: React.ReactNode;
}

const HeaderRight = ({ color, children }: HeaderRightProps) => {
  const classNames = cx(styles.headerRight, {
    [styles.headerRightDefault]: isNil(color),
    [styles.headerRightPrimary]: color === 'primary',
    [styles.headerRightWarning]: color === 'warning',
  });

  return <div className={`${classNames} ${styles.headerRight}`}>{children}</div>;
};

export default HeaderRight;
