import { normalizeKey } from 'frontend/utils';

import ROLE_NAMES from './ROLE_NAMES';

export default Object.freeze({
  [normalizeKey(ROLE_NAMES.MEMBER)]: 'Basic permissions, unable to change content',
  [normalizeKey(ROLE_NAMES.INBOX_AGENT)]: 'Includes permissions for doing customer support in the Inbox',
  [normalizeKey(ROLE_NAMES.BOT_BUILDER)]: 'Includes permissions required for editing bot content in Build',
  [normalizeKey(ROLE_NAMES.NUDGE_ADMIN)]: 'Includes permissions required for creating, editing and building nudges',
  [normalizeKey(ROLE_NAMES.BOT_ADMIN)]: 'Higher level bot operations, like changing user permissions and settings',
  [normalizeKey(ROLE_NAMES.ORGANIZATION_ADMIN)]:
    'Higher level organization operations, like changing permissions and inviting members',
});
