import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { ModalForm } from 'frontend/features/Modals';
import { SubscriptionSelect } from 'frontend/features/Organization/components';
import { UPDATE_ORGANIZATION_SUBSCRIPTION } from 'frontend/features/Organization/mutations';
import capitalizeFirstLetter from 'frontend/utils/capitalizeFirstLetter';

const UpdateSubscription = ({ hide, args: { subscription } }) => {
  const [update] = useMutation(UPDATE_ORGANIZATION_SUBSCRIPTION);

  const onSubmit = useCallback(
    async ({ planName, noExpiration, expirationDate }) => {
      await update({
        variables: {
          subscriptionId: subscription.id,
          planName: planName.toUpperCase(),
          expirationDate: noExpiration ? null : expirationDate,
        },
      });
      hide();
    },
    [hide, subscription.id, update],
  );

  const initialValues = {
    planName: capitalizeFirstLetter(subscription.planName),
    expirationDate: subscription.endedAt,
    noExpiration: !subscription.endedAt,
  };

  return (
    <ModalForm onSubmit={onSubmit} title="Edit subscription" hide={hide} onOkText="Edit" initialValues={initialValues}>
      <SubscriptionSelect />
    </ModalForm>
  );
};

UpdateSubscription.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ subscription: PropTypes.shape({}).isRequired }),
};

export default UpdateSubscription;
