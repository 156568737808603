import PropTypes from 'prop-types';

export const EvaluationQueryResultType = PropTypes.shape({
  dialogueTitle: PropTypes.string.isRequired,
  dialogueReply: PropTypes.string.isRequired,
  userSays: PropTypes.string.isRequired,
  predictorReply: PropTypes.string.isRequired,
  predictorReplyIsFallback: PropTypes.bool.isRequired,
  predictorMatchesReply: PropTypes.bool.isRequired,
  predictorScore: PropTypes.number,
  dialogue: PropTypes.shape({
    id: PropTypes.string,
  }),
  evaluationQuery: PropTypes.shape({
    id: PropTypes.string,
  }),
});
