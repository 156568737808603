import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { isBefore } from 'date-fns';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';

import { GetNotificationsDocument, type UserType } from 'frontend/api/generated';
import { Bell } from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';
import { Pusher, getPusherChannel } from 'frontend/features/Pusher';
import { useMixpanel } from 'frontend/hooks';

import styles from './Notifications.scss';
import NotificationsList from './NotificationsList';
import { useOnNotification } from './hooks';

type BellAnimation = {
  children: React.ReactElement | React.ReactElement | null;
  title: string;
  animate: string;
};

const BellAnimation = ({ children, animate, title }: BellAnimation) => {
  const variants = {
    initial: {
      rotate: 0,
    },
    ring: {
      rotate: -15,
      transition: {
        type: 'spring',
        damping: 15,
        stiffness: 1000,
      },
    },
  };
  return (
    <motion.div variants={variants} animate={animate} title={title}>
      {children}
    </motion.div>
  );
};

const LOCAL_STORAGE_KEY = 'NOTIFICATIONS_READ';

const Notifications = () => {
  const [unseenNotifications, setUnseenNotifications] = useState(true);
  const { data: userData, loading, error } = useQuery<{ me: UserType }>(GetNotificationsDocument);
  const onNotification = useOnNotification({ me: userData?.me });
  const { mixpanel } = useMixpanel();

  const notifications = userData?.me?.notifications ?? [];
  const unreadNotifications = userData?.me?.unreadNotifications ?? 0;
  const userId = userData?.me?.id;

  const latestNotificationCreated = notifications[0]?.created ?? '';
  const notificationsCount = notifications.length;
  const notificationsRead = localStorage.getItem(LOCAL_STORAGE_KEY) ?? '';

  useEffect(() => {
    // If never read and there is notifications
    // Or of the read timestamp is before the latest notification

    const hasUnseenNotifications =
      (!notificationsRead && notificationsCount > 0) ||
      isBefore(new Date(notificationsRead), latestNotificationCreated);

    setUnseenNotifications(hasUnseenNotifications);
  }, [latestNotificationCreated, loading, notificationsCount, notificationsRead]);

  const seenNotifications = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, new Date().toISOString());
    setUnseenNotifications(false);
    mixpanel.track('Seen notifications');
  }, [mixpanel]);

  if (error || loading || !userData) {
    return null;
  }

  return (
    <Dropdown
      style={{ marginTop: '-5px', zIndex: 200 }}
      overlayClassName={styles.notificationsContainer}
      onClick={seenNotifications}
      overlay={<NotificationsList notifications={notifications} />}
    >
      <span
        className={cx(styles.notificationsBell, {
          [styles.notificationsBellActive]: unseenNotifications,
        })}
      >
        {unreadNotifications > 0 && <span className={styles.badge} />}
        <BellAnimation animate={unseenNotifications ? 'ring' : 'initial'} title="View notifications">
          <Icon component={Bell} className={styles.bellIcon} />
        </BellAnimation>
      </span>
      <Pusher channel={getPusherChannel({ userId })} event="new-notification" onUpdate={onNotification} />
    </Dropdown>
  );
};

export default Notifications;
