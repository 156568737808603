import { useLazyQuery } from '@apollo/client';
import { captureException } from '@sentry/browser';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { EngagementDashboardTokenDocument } from 'frontend/api/generated';
import { showToastError } from 'frontend/config/errorLink';
import { useFilters } from 'frontend/features/Analytics/hooks';
import { usePrevious } from 'frontend/hooks';
import { stringifyQueryParamsWithBrackets } from 'frontend/utils';
import type { QueryParam } from 'frontend/utils/stringifyQueryParamsWithBrackets';

import { HIDE_FILTERS } from '../constants';

// Set default start date to ten days ago
const DEFAULT_START_DATE = new Date(+new Date() - 86400 * 10 * 1000);
const DEFAULT_END_DATE = new Date();
const SKIP = Object.keys(HIDE_FILTERS);

export type EmailCampaignData = {
  campaignName: string;
  campaignType: 'email';
  captureRate: number;
  conversionRate: number;
  currency: string;
  emailConversionValue: number;
  emailConversions: number;
  emailsOpened: number;
  emailsSent: number;
  openRate: number;
};
export type OnsiteCampaignData = {
  campaignName: string;
  campaignType: 'onsite';
  clickThroughRate: number;
  conversionRate: number;
  currency: string;
  displayRate: number;
  engagedOnsiteClicks: number;
  onsiteConversions: number;
  onsiteConversionsValue: number;
  onsiteDisplayed: number;
};
export type CampaignData = EmailCampaignData | OnsiteCampaignData;
type ClientSettings = { currencies?: unknown };

function fetchFromDashboard<T>(
  url: string,
  token: string,
  callback: (err?: string, data?: T | null) => void,
  payload: Record<string, QueryParam> = {},
): void {
  if (!window.env.ENGAGEMENT_DASHBOARD_URL) {
    console.error('ENGAGEMENT_DASHBOARD_URL is undefined');
    callback();
    return;
  }

  fetch(`${window.env.ENGAGEMENT_DASHBOARD_URL}${url}?${stringifyQueryParamsWithBrackets(payload)}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((json) => {
      callback('', json.data);
    })
    .catch((err) => callback(`Could not fetch conversion data : ${err}`, null));
}

type Props = { organizationId: string; organizationLoading: boolean };
type Return = {
  campaigns: CampaignData[];
  loading: boolean;
  error: string;
};
export default function useEngagementStats({ organizationId, organizationLoading }: Props): Return {
  const [loadToken, { data: tokenData, loading: tokenLoading }] = useLazyQuery(EngagementDashboardTokenDocument, {
    variables: { organizationId },
  });

  const filters = useFilters({ skip: SKIP });

  const [clientSettings, setClientSettings] = useState<ClientSettings>({});
  const [stats, setStats] = useState<CampaignData[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');

  const previousFilters = usePrevious(filters);

  useEffect(() => {
    if (!organizationLoading) {
      loadToken();
    }
  }, [loadToken, organizationLoading]);

  useEffect(() => {
    if (tokenData?.engagementDashboardToken) {
      setLoading(true);
      fetchFromDashboard<ClientSettings>(
        '/clients/kindlyGetClientSettings',
        tokenData.engagementDashboardToken,
        (err, data) => {
          if (err) {
            setError(err ?? '');
          } else {
            setClientSettings(data!);
          }
          setLoading(false);
        },
      );
    }
  }, [tokenData]);

  useEffect(() => {
    if (
      !isEqual(filters, previousFilters) &&
      clientSettings?.currencies?.[0] &&
      !tokenLoading &&
      tokenData?.engagementDashboardToken
    ) {
      try {
        const { to, from } = filters;
        const payload = {
          currency: clientSettings.currencies[0],
          fromDate: from || DEFAULT_START_DATE,
          toDate: to || DEFAULT_END_DATE,
        };
        setLoading(true);
        fetchFromDashboard<CampaignData[]>(
          '/clients/kindlyGetPeriodClient',
          tokenData.engagementDashboardToken,
          (err, data) => {
            if (err) {
              setError(err);
            } else {
              setStats(data!);
            }
            setLoading(false);
          },
          payload,
        );
      } catch (err) {
        const sentryId = captureException(err);
        showToastError({ message: err.message, sentryId });
      }
    }
  }, [clientSettings, tokenData, filters, tokenLoading, previousFilters]);

  return {
    loading: tokenLoading || loading,
    campaigns: stats ?? [],
    error,
  };
}
