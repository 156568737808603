import { gql } from '@apollo/client';

import ORGANIZATION_FRAGMENT from 'frontend/features/Organization/fragments/Organization.fragment.graphql';

export default gql`
  query GetAllOrganizations {
    allOrganizations {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
