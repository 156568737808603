import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { MarkdownToHtmlDocument } from 'frontend/api/generated';

import useDebounce from './useDebounce';

export default (markdownString, { renderErrors = true } = {}) => {
  const [html, setHtml] = useState('');
  const debouncedMarkdownString = useDebounce(markdownString, 300);
  const [markdownToHtmlQuery, { called, data, loading }] = useLazyQuery(MarkdownToHtmlDocument);
  const markdownToHTML = useCallback(
    async (currentMarkdownString) => {
      if (!currentMarkdownString) {
        setHtml('');
        return;
      }
      try {
        await markdownToHtmlQuery({
          variables: { text: currentMarkdownString },
        });
        if (called && !loading) {
          setHtml(data?.markdownToHtml || '');
        }
      } catch (err) {
        console.error('Error parsing markdown to html', err);
        if (renderErrors) setHtml(err.toString());
        else setHtml('');
      }
    },
    [called, loading, data, markdownToHtmlQuery, renderErrors],
  );

  useEffect(() => {
    markdownToHTML(debouncedMarkdownString);
  }, [debouncedMarkdownString, markdownToHTML]);

  return { html, loading, called };
};
