import { isObject, mapValues, omitBy } from 'lodash';

export function deepOmitBy(data, predicate) {
  if (Array.isArray(data)) {
    return data.map((arrVal) => deepOmitBy(arrVal, predicate));
  }
  if (!isObject(data)) {
    return data;
  }
  return omitBy(
    mapValues(data, (obj) => deepOmitBy(obj, predicate)),
    predicate,
  );
}
