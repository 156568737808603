import { gql } from '@apollo/client';

import { TICKET } from '../fragments';

export default gql`
  query GetTicket($chatId: ID!, $botId: ID!) {
    ticketForChat(chatId: $chatId, botId: $botId) {
      ...Ticket
    }
  }
  ${TICKET}
`;
