import { NONE_SELECTED } from './constants';

export default ({ botId, skillId }) => {
  const errors = {};

  const hasError = [botId, skillId].filter((id) => id === NONE_SELECTED).length !== 1;
  if (hasError) errors.selected = 'Must choose either a bot or a skill';

  return errors;
};
