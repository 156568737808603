import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import { REGENERATE_WEBHOOK_SIGNING_KEY } from '../graphql';

const RegenerateWebhookSigningKey = ({ hide, args: { botId } }) => {
  const toast = useToast();
  const [regenerateKey] = useMutation(REGENERATE_WEBHOOK_SIGNING_KEY, { variables: { botId } });

  const onSubmit = useCallback(async () => {
    try {
      await regenerateKey();
      toast.success('Key has been regenerated');
    } catch (e) {
      toast.error('Could not regenerate key');
    }
  }, [regenerateKey, toast]);

  return (
    <DeleteModal action="regenerate" name="webhook signing key" hide={hide} onSubmit={onSubmit}>
      <p>Warning: If you generate a new key, Kindly will start using it immediately in place of the old key.</p>
      <p>
        You will need to replace the deprecated key with the newly generated key in your webhook services to continue
        validating requests from Kindly.
      </p>
    </DeleteModal>
  );
};

RegenerateWebhookSigningKey.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({ botId: PropTypes.string.isRequired }),
};

export default RegenerateWebhookSigningKey;
