import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { useParams } from 'react-router-dom';

import { InboxStatusDocument, UpdateOrganizationAgentProfileDocument } from 'frontend/api/generated';
import { ChevronUp } from 'frontend/assets/icons';
import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import { Icon, InfoBox } from 'frontend/components';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useToast } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';

import styles from './AgentAvailability.scss';

export const AVAILABILITY = {
  ONLINE: { text: 'Available', color: '#00c853' },
  OFFLINE: { text: 'Away', color: 'var(--grayscale50)' },
  AUTO: { text: 'Auto', color: 'var(--grayscale50)' },
} as const;
type AvailabilityStatus = keyof typeof AVAILABILITY;

interface AgentAvailabilityProps {
  orgInboxId: string;
}

function AgentAvailability({ orgInboxId }: AgentAvailabilityProps) {
  const { botId } = useParams();
  const toast = useToast();

  const {
    data: statusData,
    loading: statusLoading,
    refetch: refetchStatus,
  } = useQuery(InboxStatusDocument, {
    variables: { botId: botId || '' },
  });
  const { data: meData, loading: meLoading } = useMe();
  const [updateAgentProfile, { loading: updateAgentLoading }] = useMutation(UpdateOrganizationAgentProfileDocument);

  usePusherEvent(
    getPusherChannel({ organizationId: orgInboxId, isAvailability: true }),
    'availability-updated',
    refetchStatus,
  );

  const loading = meLoading || statusLoading;

  if (loading || !meData?.me) {
    return null;
  }

  const userId = meData.me?.id;
  const agents = statusData?.inboxStatus?.agents;
  const availability = (agents ?? []).find((agent) => agent.membership.user.id === userId)?.availability || 'OFFLINE';
  const availabilityText = AVAILABILITY[availability].text;
  const dotColor = AVAILABILITY[availability].color;

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = async (event) => {
    const value = event.currentTarget.value as AvailabilityStatus;
    try {
      await updateAgentProfile({
        variables: { availability: value, userId, organizationId: orgInboxId },
      });
      refetchStatus();
    } catch (err) {
      toast.error('Could not update your availability, try again later...');
      return;
    }
    const successMessage =
      value !== 'AUTO' ? `You are now ${AVAILABILITY[value].text.toLowerCase()}` : 'You set yourself to auto';
    toast.success(successMessage);
  };

  const {
    me: { profile },
  } = meData;

  return (
    <article className={styles.componentWrapper} style={{ '--_dot-color': dotColor } as React.CSSProperties}>
      {!statusData?.inboxStatus?.openForBusiness && (
        <InfoBox title="Handover is closed" level="warning">
          <p>{statusData?.inboxStatus?.reason}</p>
          <HelpCenterLink path="/agent-availability">Read more</HelpCenterLink>
        </InfoBox>
      )}

      <div className={styles.customSelect}>
        <select
          className={styles.nativeSelect}
          value={availability}
          onChange={handleChange}
          disabled={updateAgentLoading}
        >
          <option value="ONLINE">{AVAILABILITY.ONLINE.text}</option>
          <option value="OFFLINE">{AVAILABILITY.OFFLINE.text}</option>
          <option value="AUTO">{AVAILABILITY.AUTO.text}</option>
        </select>

        <img
          className={cx(styles.agentImg, { [styles.agentImgAway]: availability === 'OFFLINE' })}
          src={profile?.avatarUrl || defaultBotAvatar}
          alt="Agent avatar"
        />
        <span className={styles.agentName}>{profile?.fullName || 'Agent'}</span>
        <span className={cx(styles.agentStatus, { [styles.agentStatusAuto]: availability === 'AUTO' })}>
          {availabilityText}
        </span>
        <Icon className={styles.iconChevron} component={ChevronUp} color="black" />
      </div>
    </article>
  );
}

export default AgentAvailability;
