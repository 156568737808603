import { gql } from '@apollo/client';

export default gql`
  fragment ActivityGroup on ActivityGroupType {
    id
    action
    updatedAt
    numActivities
    actionBy {
      id
      username
      profile {
        id
        fullName
        avatarUrl
      }
    }
  }
`;
