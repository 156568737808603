import { defineAction } from 'redux-define';

const NAVBAR = defineAction('navbar');

const HEIGHT = NAVBAR.defineAction('HEIGHT');
const SET_BOT = NAVBAR.defineAction('SET_BOT');
const SET_SKILL = NAVBAR.defineAction('SET_SKILL');
const UNSET_CURRENT = NAVBAR.defineAction('UNSET_CURRENT');
const RECENT_ITEM_REMOVED = NAVBAR.defineAction('RECENT_ITEM_REMOVED');
const RECENT_ITEM_UPDATED = NAVBAR.defineAction('RECENT_ITEM_UPDATED');

export const setNavbarHeight = (height) => ({
  type: HEIGHT.ACTION,
  height,
});

export const setCurrentBot = (bot) => ({
  type: SET_BOT.ACTION,
  item: {
    ...bot,
    resourceType: 'bot',
  },
});

export const setCurrentSkill = (skill) => ({
  type: SET_SKILL.ACTION,
  item: {
    ...skill,
    resourceType: 'skill',
  },
});

export const unsetCurrent = () => ({
  type: UNSET_CURRENT.ACTION,
});

export const resourceRemoved = (item) => ({
  type: RECENT_ITEM_REMOVED.ACTION,
  item,
});

export const resourceUpdated = (item) => ({
  type: RECENT_ITEM_UPDATED.ACTION,
  item,
});

const initialState = {
  height: 67,
  chatBubbleSettingsHaveUpdated: false,
  recentlyVisited: [],
  currentResource: null,
};

const selectNavbar = ({ navbar }) => navbar;
export const selectNavbarHeight = (state) => selectNavbar(state).height;

export const slicer =
  () =>
  ({ navbar }) => ({
    navbar: {
      ...initialState,
      recentlyVisited: navbar.recentlyVisited || [],
    },
  });

export const isSameResource = (item1, item2) =>
  item1?.id && item2?.id && item1.id.toString() === item2.id.toString() && item1.resourceType === item2.resourceType;

const removeItem = (list, removedItem) => list.filter((item) => !isSameResource(removedItem, item));

const prependRecentlyVisited = (list, item) => {
  // for now we will only add bots to the list
  if (item.resourceType === 'bot') return [item, ...removeItem(list, item)];
  return list;
};

const updateRecentlyVisited = (list, updatedItem) =>
  list.map((item) => (isSameResource(updatedItem, item) ? updatedItem : item));

export default (state = initialState, action) => {
  switch (action.type) {
    case HEIGHT.ACTION:
      return { ...state, height: action.height };
    case UNSET_CURRENT.ACTION:
      return { ...state, currentResource: null };
    case SET_BOT.ACTION:
      return {
        ...state,
        currentResource: action.item,
        recentlyVisited: prependRecentlyVisited(state.recentlyVisited, action.item),
      };
    case SET_SKILL.ACTION:
      return {
        ...state,
        currentResource: action.item,
        recentlyVisited: prependRecentlyVisited(state.recentlyVisited, action.item),
      };
    case RECENT_ITEM_REMOVED.ACTION:
      return {
        ...state,
        recentlyVisited: removeItem(state.recentlyVisited, action.item),
      };
    case RECENT_ITEM_UPDATED.ACTION:
      return {
        ...state,
        recentlyVisited: updateRecentlyVisited(state.recentlyVisited, action.item),
      };
    default:
      return state;
  }
};
