import PropTypes from 'prop-types';

import { InfoIcon, LogItem } from 'frontend/features/Inbox/components';

const MessageWithLink = ({ userName, url }) => (
  <div>
    <span>{`${userName} is at `}</span>
    <a className="text-color link" href={url} target="_blank" rel="noreferrer">
      {url}
    </a>
  </div>
);

MessageWithLink.propTypes = {
  url: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

const MessageUrlLocation = ({ url, created, userName }) => (
  <LogItem timeStamp={created} text={<MessageWithLink userName={userName} url={url} />}>
    <InfoIcon />
  </LogItem>
);

MessageUrlLocation.propTypes = {
  url: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default MessageUrlLocation;
