import cx from 'classnames';
import { useCallback, useEffect } from 'react';
import type { FieldRenderProps } from 'react-final-form';

import { URL_SCHEMES } from 'frontend/constants';

import styles from './UrlInput.scss';
import { changeAddress, changeScheme, getScheme, getSecondScheme, stripScheme } from './utils';
import InputErrorWrapper from '../InputErrorWrapper';

const { HTTP, HTTPS, NONE } = URL_SCHEMES;

interface UrlInputProps extends FieldRenderProps<string> {
  /** `<Input>` placeholder. */
  placeholder?: string;
  /** If true, disable the `<Input>`. */
  readOnly?: boolean;
  /** Function to run on `<Input>` keyDown. */
  onKeyDown?(): void;
  componentRef?: React.RefObject<HTMLInputElement>;
}

/** Render an input interface to edit URLs. */
const UrlInput = ({
  input: { value, onChange, onBlur },
  meta,
  placeholder,
  readOnly,
  onKeyDown,
  componentRef,
}: UrlInputProps) => {
  const scheme = getScheme(value);
  const address = stripScheme(value);

  const onChangeScheme = useCallback((event) => changeScheme(value, onChange, event), [value, onChange]);
  const onChangeAddress = useCallback((event) => changeAddress(value, onChange, event), [value, onChange]);

  // If a redundant scheme after the one from <select> is added, use the former
  useEffect(() => {
    const secondScheme = getSecondScheme(value);
    if (secondScheme) onChange(stripScheme(value));
  }, [onChange, value]);

  const hasError = Boolean(meta.submitFailed && meta.error);
  const inputClassname = cx(styles.input, { [styles.inputError]: hasError });

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectWrapper}>
        {/* onBlur doesn't work; use onChange */}
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select value={scheme} onChange={onChangeScheme} className={styles.select}>
          <option value={HTTPS}>{HTTPS}</option>
          <option value={HTTP}>{HTTP}</option>
          <option value={NONE}> </option> {/* Blank */}
        </select>
      </div>
      <InputErrorWrapper
        hasError={hasError}
        className={styles.errorMessageWrapper}
        errorMessageClassName={styles.errorMessage}
        displayError={meta.error}
      >
        <input
          value={address}
          onChange={onChangeAddress}
          onBlur={onBlur}
          className={inputClassname}
          placeholder={placeholder}
          readOnly={readOnly}
          onKeyDown={onKeyDown}
          ref={componentRef}
        />
      </InputErrorWrapper>
    </div>
  );
};

export default UrlInput;
