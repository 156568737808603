import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Blob } from 'frontend/components';
import CenteredComponent from 'frontend/components/CenteredComponent/CenteredComponent';

import Contact from './Contact';
import styles from './PublicLayout.scss';

interface PublicLayoutProps {
  children: React.ReactNode;
  hideContact?: boolean;
}

const PublicLayout = ({ children, hideContact }: PublicLayoutProps) => {
  const classNames = cx(styles.centeredComponent, {
    [styles.centeredComponentFooter]: !hideContact,
  });

  return (
    <div className={styles.publicLayout}>
      <header className={styles.headerContainer}>
        <div className={styles.headerInner}>
          <div className={styles.headerBranding}>
            <div className={styles.blobContainer}>
              <Blob />
            </div>
            <Link to="/">
              <span className={styles.kindlySpan}>Kindly</span>
            </Link>
          </div>
        </div>
      </header>
      <CenteredComponent className={classNames}>{children}</CenteredComponent>
      {!hideContact && <Contact />}
    </div>
  );
};

export default PublicLayout;
