import { gql } from '@apollo/client';

import USER_DETAIL_FRAGMENT from '../../fragments/UserDetail.fragment.graphql';

export const SET_PRIMARY_ORGANIZATION = gql`
  mutation SetPrimaryOrganization($userId: ID!, $organizationId: ID!) {
    setPrimaryOrganization(userId: $userId, organizationId: $organizationId) {
      ...UserDetail
    }
  }
  ${USER_DETAIL_FRAGMENT}
`;
