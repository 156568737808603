import { Form } from 'react-final-form';

import type { LanguageType, LanguageVariantType } from 'frontend/api/generated';
import { LoaderSwitch } from 'frontend/components';
import type { LanguageAugmented } from 'frontend/hooks/useLanguages';
import type { BotLanguage } from 'frontend/types/language';

import BotLanguagesView from './BotLanguagesView';
import useLanguageForm from './hooks/useLanguageForm';

const languagesValidator = (values: { languages: BotLanguage[] }) => {
  if (!values.languages || values.languages.length < 1) {
    return { language: 'The workspace must have at least one language' };
  }
  if (values.languages.filter(({ active }) => active).length < 1) {
    return { language: 'The workspace must have at least one active language' };
  }
  return undefined;
};

const BotLanguages = () => {
  const { initialValues, loading, onSubmit } = useLanguageForm();

  return (
    <LoaderSwitch loading={loading} size="large">
      <Form<Values>
        component={BotLanguagesView}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={languagesValidator}
      />
    </LoaderSwitch>
  );
};

export interface LanguageAppended extends LanguageType {
  text?: string;
  variants: VariantAppended[];
}

export interface VariantAppended extends LanguageVariantType {
  text?: string;
  active: boolean;
}

export interface Values {
  languages: LanguageAugmented[];
  botLanguageVariants: Set<string>;
  botActiveLanguageVariants: Set<string>;
  selectLanguageGreeting: string | undefined;
  primaryLanguage: string;
}

export default BotLanguages;
