import React, { useMemo, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { validate as validateUUID } from 'uuid';

import type { ChatbubbleGlobalIconsType, ChatbubbleSettingsQuery } from 'frontend/api/generated';
import GlobalIcon from 'frontend/assets/images/global-icon.png';
import { FormErrors, GuardUnsaved, LoaderSwitch, Panel } from 'frontend/components';
import EmptyFeature from 'frontend/components/EmptyFeature';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';
import { getId } from 'frontend/utils';

import GlobalIconItem, { TriggerOnDeviceEnum } from './GlobalIconItem/GlobalIconItem';
import styles from './GlobalIcons.scss';

interface FormValues {
  globalIcons: ChatbubbleGlobalIconsType[];
}

const getGlobalIconName = (globalIcons, id) => {
  if (id && validateUUID(id)) {
    return `globalIcons[${globalIcons?.findIndex((item) => id === item.id)}]`;
  }
  return `globalIcons[${globalIcons.length}]`;
};

const isOnGlobalIconsRoute = (locationPath) =>
  locationPath.endsWith('global-icons') || locationPath.endsWith('global-icons/');

const GlobalIcons = () => {
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const { botId } = useBotOrSkill();
  const [{ currentLanguage }] = useCurrentLanguage();
  const location = useLocation();
  const onSuccess = (data: NonNullable<ChatbubbleSettingsQuery>['chatbubbleSettings'] | null) => {
    if (isCreating) {
      setIsCreating(false);
    }

    if (
      data?.globalIcons?.length &&
      // navigate to the created item if new
      isOnGlobalIconsRoute(location.pathname)
    ) {
      navigate(`../proactivity/global-icon/${data.globalIcons[0]?.id || ''}`);
    }
  };

  const { onSubmit, onSubmitWithUpload, initialValues, setFile, loading, languagesLoading } = useChatbubbleForm({
    onSuccess,
    uploadUrl: `api/v2/bot/${botId}/global-icons/upload-image/`,
  });

  const defaultValues = useMemo<Record<string, any>>(
    () => ({
      ...initialValues,
      globalIcons: [
        ...(initialValues.globalIcons || []),
        ...(isOnGlobalIconsRoute(location.pathname)
          ? [
              {
                triggerAfter: 0,
                triggerOnDevice: Object.values(TriggerOnDeviceEnum),
                triggerRule: 'USER_INACTIVITY',
                enabled: { [currentLanguage]: false },
              },
            ]
          : []),
      ],
    }),
    [initialValues, currentLanguage, location],
  );

  const { globalIconId } = useParams();

  const { Form } = withTypes<FormValues>();

  const isFeatureEnabled = useFeatureFlags();
  const isGlobalIconsEnabled = isFeatureEnabled('global-icons');

  return (
    <LoaderSwitch loading={loading || languagesLoading} size="large">
      {(!initialValues.globalIcons?.length && !isCreating) || !isGlobalIconsEnabled ? (
        <EmptyFeature
          title="Engage users - at right time and location"
          buttonText="Create Global icons"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(isGlobalIconsEnabled && { onClick: () => setIsCreating(true) })}
          imageSrc={GlobalIcon as string}
          description={
            isGlobalIconsEnabled ? (
              'Create you first global icons. It is a great way to direct the users to preferred links, dialogues or other content.'
            ) : (
              <>
                Reach out to your contact person in Kindly, or contact{' '}
                <a className={styles.mailToLink} href="mailto:support@kindly.ai">
                  support@kindly.ai
                </a>{' '}
                to learn more about this feature
              </>
            )
          }
        />
      ) : (
        <Form
          onSubmit={(values) => {
            if (values.globalIcons.some((globalIcon) => globalIcon.id)) {
              return onSubmitWithUpload(values);
            }
            return onSubmit(values);
          }}
          validate={(values) => {
            const errors: { url?: string; icons?: string } = {};
            const icons = values.globalIcons;
            if (icons.length) {
              const foundIcon = globalIconId
                ? icons.find((icon) => getId(icon) === globalIconId)
                : icons[icons.length - 1];

              if (!foundIcon?.icons?.length) {
                errors.icons = 'You must have added at least one Icon';
              }
            }
            return errors;
          }}
          initialValues={defaultValues}
          render={({ handleSubmit, form: { change } }) => (
            <Panel className={styles.globalIconsPanel} size="medium">
              <form onSubmit={handleSubmit}>
                {!!globalIconId && <GuardUnsaved />}
                <FormErrors className={styles.formErrors} />
                <GlobalIconItem
                  key={getGlobalIconName(initialValues.globalIcons, globalIconId)}
                  setFile={setFile}
                  change={change as (name: string, value: any) => void}
                  name={getGlobalIconName(initialValues.globalIcons, globalIconId)}
                />
              </form>
            </Panel>
          )}
        />
      )}
    </LoaderSwitch>
  );
};

export default GlobalIcons;
