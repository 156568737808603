import { formatISO } from 'date-fns';
import PropTypes from 'prop-types';

import slackMark from 'frontend/assets/images/slack-mark.svg';
import { Button, Icon } from 'frontend/components';

import styles from './styles.scss';

export const SlackConnectionStatus = ({ profile, disconnect, logo = true }) => (
  <div className={styles.slackStatusWrap}>
    {logo && <Icon component={slackMark} height="40px" width="40px" />}
    <p>
      Connected to the workspace <strong>{profile.workspaceName}</strong>.<br />
      <small>Connected since {formatISO(profile.createdAt)}</small>
    </p>
    {disconnect && <Button text="Disconnect" color="warning" className="m-l-md" onClick={disconnect} />}
  </div>
);

SlackConnectionStatus.propTypes = {
  profile: PropTypes.shape({ workspaceName: PropTypes.string, createdAt: PropTypes.string }),
  disconnect: PropTypes.func,
  logo: PropTypes.bool,
};

export default SlackConnectionStatus;
