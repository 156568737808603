import { compose, filter, get, identity, map, orderBy } from 'lodash/fp';
import { useCallback } from 'react';

import { chatListItemFragment } from 'frontend/api/fragments';
import { TICKET } from 'frontend/features/Inbox/fragments';
import { prepareChat, prepareTicket } from 'frontend/features/Inbox/utils';

const concatLists =
  (added = [], { after } = {}) =>
  (existing = []) => [...(!after ? added : []), ...existing, ...(after ? added : [])];

const addChat = (cache) => (chat) => {
  try {
    const result = cache.writeFragment({
      data: prepareChat(chat),
      fragment: chatListItemFragment,
    });
    return result;
  } catch (err) {
    console.error('Error adding chat', err);
    return null;
  }
};

const addTicket = (cache) => (ticket) => {
  try {
    const result = cache.writeFragment({
      data: prepareTicket(ticket),
      fragment: TICKET,
    });
    return result;
  } catch (err) {
    console.error('Error adding ticket', err);
    return null;
  }
};

const getAddedItems = (itemName, addItem) =>
  compose(
    filter(identity),
    map(addItem),
    orderBy(({ updated, updatedAt }) => updated || updatedAt, 'desc'),
    filter(identity),
    get(itemName),
  );

export default ({ cache, chatsAndTickets }) => {
  const addChats = useCallback(
    (newChatsAndTickets, { after } = {}) => {
      const addedChats = getAddedItems('chats', addChat(cache))(newChatsAndTickets);
      const addedTickets = getAddedItems('tickets', addTicket(cache))(newChatsAndTickets);

      cache.modify({
        id: cache.identify(chatsAndTickets),
        fields: {
          chats: concatLists(addedChats, { after }),
          tickets: concatLists(addedTickets, { after }),
        },
      });
    },
    [cache, chatsAndTickets],
  );

  return addChats;
};
