import { formatISO } from 'date-fns';
import { useCallback } from 'react';

import { composerStateToFormValues } from 'frontend/features/Composer';
import { useModal } from 'frontend/features/Modals';
import randomUUID from 'frontend/utils/randomUUID';

import { PasteSamples } from '../../modals';

export const getEmptySample = () => ({
  dialogueId: null,
  instances: [],
  languageCode: null,
  tempId: randomUUID(),
  tempUpdatedAt: formatISO(new Date()),
  text: null,
});

export const useOnPaste = ({ add, currentLanguage, dialogueId }) => {
  const [showPasteModal] = useModal(PasteSamples);

  const addSamples = useCallback(
    (pastedSamples) => {
      pastedSamples.forEach((text) =>
        add({
          ...getEmptySample(), // Must be run for each sample to get different tempId's
          text,
          dialogueId,
          languageCode: currentLanguage,
        }),
      );
    },
    [add, currentLanguage, dialogueId],
  );

  const onPaste = useCallback(
    (pastedText) => {
      if (pastedText.indexOf('\n') === -1) return false;
      showPasteModal({ pastedText, addSamples });
      return true;
    },
    [addSamples, showPasteModal],
  );

  return onPaste;
};

export const useOnEnter = ({ add, sampleValidator, setError, currentLanguage, dialogueId }) => {
  const onSubmit = useCallback(
    ({ text, instances }) => {
      if (!text.trim()) return;
      add({
        ...getEmptySample(),
        text: text.trim(),
        dialogueId,
        languageCode: currentLanguage,
        instances,
      });
    },
    [add, currentLanguage, dialogueId],
  );

  const onEnter = useCallback(
    ({ state, setState, resolveHashtags, clear }) => {
      const { state: resolvedState, dropdownOpened } = resolveHashtags(state);

      if (dropdownOpened) {
        setState(resolvedState);
        return;
      }

      const { text, instances } = composerStateToFormValues(resolvedState);
      const newError = sampleValidator({ text, instances });
      setError(newError);

      if (newError) {
        setState(resolvedState);
        return;
      }

      onSubmit({ text, instances });

      /* Have to force focus on clearedState, otherwise the following bug appears:
      1. Create a new sample using a entity made with a hashtag
      2. Immediately start writing another sample
      3. The cursor jumps to the beginning after the first character */
      const clearedState = clear(resolvedState, { focus: true });
      setState(clearedState);
    },
    [onSubmit, sampleValidator, setError],
  );

  return onEnter;
};
