import { gql } from '@apollo/client';

import { sampleCandidateFragment } from '../fragments';

export default gql`
  mutation UndoArchivedSampleCandidate($botId: ID!, $sampleCandidateId: UUID!) {
    undoArchivedSampleCandidate(botId: $botId, sampleCandidateId: $sampleCandidateId) {
      ...SampleCandidate
    }
  }
  ${sampleCandidateFragment}
`;
