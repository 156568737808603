import ScrapeWebContentLogo from 'frontend/assets/images/scrape-web-content-logo.png';

export type KindlyGptSourcesType = {
  id: string;
  name: string;
  description: string;
  logoUrl: string;
}[];

export default [
  {
    id: 'scrap-web-content', // used for url as well
    name: 'Scrape web content',
    description:
      'Add the complete list of URLs you want to add to your Kindly GPT bot. \n The text content of the URLs will be scraped and used as a knowledge base in your Kindly GPT bot. \n Scraping happens in the background. Once finished and the toggle below is on, then Kindly GPT is enabled for your bot. ',
    logoUrl: ScrapeWebContentLogo as string,
  },
] as KindlyGptSourcesType;
