import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from 'frontend/components';
import { DragAttributesType } from 'frontend/features/Dnd/propTypes';
import { ChildrenType } from 'frontend/propTypes';

import styles from './Button.scss';
import { BUTTON_TYPES, TYPE_TO_ICON } from '../../constants';
import { ButtonType } from '../../propTypes';

const Button = ({ item: button, isEditing, children, setNodeRef, style, className, dragAttributes = {} }) => {
  const buttonClassNames = cx(styles.button, {
    [styles.buttonDisabled]: !button.isActive,
    [styles.buttonEditing]: isEditing,
    [styles.buttonIconContactDetails]: button.buttonType === BUTTON_TYPES.CONTACT_DETAILS,
    [styles.buttonIconAbortFollowup]: button.buttonType === BUTTON_TYPES.ABORT_FOLLOWUP,
    [styles.buttonIconCheckbox]: button.buttonType === BUTTON_TYPES.CHECKBOX,
  });

  return (
    <div
      className={cx(className, styles.buttonWrapper)}
      ref={setNodeRef}
      style={style}
      aria-describedby={dragAttributes['aria-describedby']}
      aria-pressed={dragAttributes['aria-pressed']}
      aria-roledescription={dragAttributes['aria-roledescription']}
      tabIndex={dragAttributes.tabIndex}
      role={dragAttributes.role}
      translate="no"
    >
      <div className={buttonClassNames}>
        <span className={styles.buttonIcon}>
          <Icon component={TYPE_TO_ICON[button.buttonType]} color="black" />
        </span>
        <span className={styles.buttonLabel}>{button.label}</span>
      </div>
      {children}
    </div>
  );
};

Button.propTypes = {
  item: ButtonType.isRequired,
  children: ChildrenType,
  setNodeRef: PropTypes.func,
  isEditing: PropTypes.bool,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  dragAttributes: DragAttributesType,
};

export default Button;
