import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { capitalize, orderBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { deleteFromArrayInCache } from 'frontend/api/cacheHelpers';
import {
  type AlertType,
  CurrentSeasonDocument,
  DismissAlertDocument,
  GetBotAlertsDocument,
  GetBotUserAlertsDocument,
  type UserAlertType,
} from 'frontend/api/generated';

import Alert, { type AnyAlert, type OtherAlert } from './Alert';
import styles from './AlertsList.scss';
import { ALERTERS, ALERT_LEVELS } from '../../constants';
import dashboardStyles from '../../views/styles.scss';

function getSeasonalWarning(seasonalAppearance: string): OtherAlert {
  return {
    id: 'seasonal-appearance-alert',
    createdAt: new Date().toISOString(), // Will be on top
    level: ALERT_LEVELS.WARNING,
    alerter: ALERTERS.SEASON_CHECK,
    message: `Your chat client is using seasonal appearance '${capitalize(seasonalAppearance)}'. Is this intended?`,
    info: 'The current seasonal appearance could be outdated. You can update it in the chat settings.',
  };
}

type Props = {
  botId: string;
};
const AlertsList = ({ botId }: Props) => {
  const client = useApolloClient();
  const [dismissAlert] = useMutation(DismissAlertDocument);

  const dismiss = useCallback(
    async (id, __typename) => {
      const isUserAlert = __typename === 'UserAlertType';
      const result = await dismissAlert({ variables: { id, botId, isUserAlert } });
      let deleteInCache;

      if (isUserAlert) {
        deleteInCache = deleteFromArrayInCache({
          pathToArrayInCache: 'me.alerts',
          pathToItemInMutationData: 'dismissAlert',
          query: GetBotUserAlertsDocument,
          variables: { botId },
        });
      } else {
        deleteInCache = deleteFromArrayInCache({
          pathToArrayInCache: 'bot.alerts',
          pathToItemInMutationData: 'dismissAlert',
          query: GetBotAlertsDocument,
          variables: { botId },
        });
      }

      deleteInCache(client, result);
    },
    [botId, client, dismissAlert],
  );

  const { data, loading, error } = useQuery(GetBotAlertsDocument, { variables: { botId } });
  const { data: userAlertsData, loading: userAlertsLoading } = useQuery(GetBotUserAlertsDocument, {
    variables: { botId },
  });
  const { data: seasonData } = useQuery(CurrentSeasonDocument, { variables: { botId } });
  const seasonalAppearance = seasonData?.chatbubbleSettings?.seasonalAppearance;

  const alerts: AnyAlert[] = useMemo(() => {
    const botAlerts: AlertType[] = data?.bot?.alerts || [];
    const userAlerts: UserAlertType[] = userAlertsData?.me?.alerts || [];
    const otherAlerts: OtherAlert[] = [];

    if (seasonalAppearance && seasonalAppearance !== seasonData?.currentSeason) {
      otherAlerts.push(getSeasonalWarning(seasonalAppearance));
    }

    return orderBy([...botAlerts, ...userAlerts, ...otherAlerts], 'createdAt', 'desc');
  }, [data?.bot?.alerts, seasonData?.currentSeason, seasonalAppearance, userAlertsData?.me?.alerts]);

  if (loading || userAlertsLoading || error) return null;

  if (alerts.length === 0) return null;
  return (
    <>
      <div className={dashboardStyles.sectionHeader}>
        <h3>Alerts</h3>
      </div>
      <div className={styles.container}>
        {alerts.map((alert) => (
          <Alert key={`alert-${alert.id}`} alert={alert} dismiss={dismiss} botId={botId} />
        ))}
      </div>
    </>
  );
};

export default AlertsList;
