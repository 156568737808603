import { concat, curry } from 'lodash';

export default curry((intersperseElement, array) =>
  array.reduce((accumulator, element, idx) => {
    if (idx > 0) {
      return concat(accumulator, [intersperseElement, element]);
    }
    return concat(accumulator, element);
  }, []),
);
