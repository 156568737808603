import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { deleteFromArrayInCache } from 'frontend/api/cacheHelpers';
import { AllPluginsDocument, DeletePluginDocument } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';

const update = deleteFromArrayInCache({
  pathToItemInMutationData: 'deletePlugin',
  pathToArrayInCache: 'allPlugins',
  query: AllPluginsDocument,
});

export const DeletePlugin = ({ hide, args: { pluginId, name } }) => {
  const navigate = useNavigate();
  const [mutate] = useMutation(DeletePluginDocument, {
    update,
  });
  const onSubmit = async () => {
    await mutate({ variables: { id: pluginId } });
    navigate('/admin/plugins');
  };
  return <DeleteModal onSubmit={onSubmit} hide={hide} name={`plugin ${name}`} />;
};
DeletePlugin.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    pluginId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};
