import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { LabelType } from 'frontend/api/generated';
import { hasSameId } from 'frontend/features/Build/utils';
import { useLabels } from 'frontend/features/Inbox/hooks';
import { CHAT_META, CHAT_TICKET } from 'frontend/features/Inbox/queries';
import { getUserName } from 'frontend/features/Inbox/utils';
import { useBotLanguages } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import useAgentActions from './useAgentActions';
import useDeleteChat from './useDeleteChat';
import useDownloadChat from './useDownloadChat';

function parseContext(context: string | Partial<LabelType>): Partial<LabelType> {
  if (typeof context === 'string') {
    try {
      return JSON.parse(context);
    } catch (e) {
      return {};
    }
  } else {
    return context;
  }
}

function addLabelActive<T extends Partial<LabelType>>(labelsForChat: T[] = []): (label: T) => T & { active: boolean } {
  return (label) => ({
    ...label,
    active: labelsForChat.filter(hasSameId({ id: label.id })).length > 0,
  });
}

const fetchPolicy = 'network-only';
const nextFetchPolicy = 'cache-first';

export default function useMetaData({ chatId, botId, organizationId }) {
  const { data: chatData, loading: loadingChat } = useQuery(CHAT_META, {
    variables: { chatId },
    fetchPolicy,
    nextFetchPolicy,
  });

  const {
    fullName,
    takenOver: handedOver,
    autoUser,
    email,
    phoneNumber,
    emailVerified,
    phoneNumberVerified,
    contactDetails,
    avatarUrl,
    isAuthenticated,
    source,
    context,
  } = chatData?.chatAndBot?.chat ?? {};

  const { data: ticketForChatData, loading: loadingChatTicket } = useQuery(CHAT_TICKET, {
    variables: { chatId, botId },
    fetchPolicy,
    nextFetchPolicy,
  });
  const ticket = useMemo(() => ticketForChatData?.ticketForChat, [ticketForChatData]);

  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });
  const labelsArray = useLabels();
  const { languages: botLanguages, loading: loadingLanguages } = useBotLanguages();

  const { isSubmitting, startHandover, endHandover, createTicket } = useAgentActions({
    botId,
    chatId,
    ticket,
  });

  const userName = getUserName({ fullName, autoUser });

  const downloadChat = useDownloadChat({ botId, chatId });
  const deleteChat = useDeleteChat({ botId, organizationId, chatId });

  const canManageHandovers = hasBotPerms('handover_chats');
  const canManageTickets = hasBotPerms('manage_tickets');

  const labels = useMemo(() => {
    const labelsForChat: Partial<LabelType>[] = chatData?.chatAndBot?.chat?.labels ?? [];
    return labelsArray.map(addLabelActive(labelsForChat));
  }, [labelsArray, chatData]);

  const loading = loadingChat ?? loadingChatTicket ?? loadingLanguages ?? loadingPermissions;
  return {
    loading,
    botLanguages,
    userName,
    downloadChat,
    deleteChat,
    avatarUrl,
    fullName,
    handedOver,
    autoUser,
    email,
    emailVerified,
    phoneNumber,
    phoneNumberVerified,
    contactDetails,
    source,
    isAuthenticated,
    labels,
    canManageTickets,
    canManageHandovers,
    ticket,
    isSubmitting,
    startHandover,
    endHandover,
    createTicket,
    context: context ? parseContext(context) : undefined,
  };
}
