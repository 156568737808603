import useMyPermissions from 'frontend/hooks/useMyPermissions';

import AdminMenu from './AdminMenu';
import Menu from './Menu';

const menuItems = [{ name: 'Organizations', slug: 'organizations' }];

export default function HomeMenu() {
  const { isKindlyAdminManager, loading: loadingPermissions } = useMyPermissions();

  if (loadingPermissions) {
    return null;
  }

  if (isKindlyAdminManager) {
    return <AdminMenu rootItems={menuItems} />;
  }

  return <Menu menuItems={menuItems} />;
}
