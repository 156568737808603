import { gql } from '@apollo/client';

import { anonymizationFragment, privacySettingsFragment } from 'frontend/api/fragments';

export const updatePrivacySettings = gql`
  mutation UpdatePrivacySettings($botId: ID!, $privacySettingsInput: PrivacySettingsInput!) {
    updatePrivacySettings(botId: $botId, privacySettingsInput: $privacySettingsInput) {
      ...PrivacySettings
    }
  }
  ${privacySettingsFragment}
`;

export const getAllAnonymizations = gql`
  query GetAllAnonymizations {
    anonymizations {
      ...Anonymization
    }
  }
  ${anonymizationFragment}
`;
