import type { PartialUser } from 'frontend/features/Organization/modals/AddMemberModal/AddMemberModal';

export function roleIdToFieldName(id: string): string {
  return `role-${id}`;
}

export function roleIdsFromFields(values: Record<string, unknown>): string[] {
  return Object.entries(values)
    .filter(([fieldName, value]) => value && fieldName.startsWith('role-'))
    .map(([fieldName]) => fieldName.split('role-')[1])
    .filter((roleId): roleId is string => Boolean(roleId));
}

export function getUserName(user: PartialUser, email: string) {
  return user.profile.fullName ?? email;
}
