import { useQuery } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';
import { Field } from 'react-final-form';

import { GET_AVAILABLE_TIMEZONES } from 'frontend/api/queries';
import { LoaderSwitch, SelectWithSearch } from 'frontend/components';

import BusinessHoursTable from './BusinessHoursTable';
import styles from '../../InboxSettings.scss';

const BusinessHours = () => {
  const { data, loading } = useQuery(GET_AVAILABLE_TIMEZONES);
  const availableTimezones = data?.availableTimezones;

  const groupSelectTimezones = useMemo(() => {
    if (!availableTimezones) {
      return [];
    }
    const groupObject = groupBy(availableTimezones, (tz) => tz.split('/')[0]);
    return Object.entries(groupObject).map(([groupName, timezones]) => ({
      name: groupName,
      type: 'group',
      items: timezones.map((tz) => ({ name: tz, value: tz })),
    }));
  }, [availableTimezones]);

  return (
    <LoaderSwitch loading={loading}>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Business hours</h3>
      <p className={styles.helpText}>
        If you have enabled handover, you can also choose if you want to set your business hours. These settings define
        what should happen when a user clicks the button for handover request. During business hours, this will trigger
        a notification to all agents. Outside of business hours, the bot will instead answer with the message you define
        below.
      </p>
      <p className={styles.helpText}>
        If you have enabled handover but don&apos;t use business hours, the bot will consider your business hours to be
        always open.
      </p>
      <div className={styles.scheduleContainer}>
        <div className={`${styles.timezoneGroup} m-t-md`}>
          <h4>Time zone</h4>
          <div className={styles.selectContainer}>
            <Field component={SelectWithSearch} name="businessHoursTimezone" options={groupSelectTimezones} />
          </div>
        </div>

        <div className="m-t-xl">
          <BusinessHoursTable />
        </div>
      </div>
    </LoaderSwitch>
  );
};

export default BusinessHours;
