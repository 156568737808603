import cx from 'classnames';
import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';

import { Directions, Labels, Triangle as TriangleUp } from 'frontend/assets/icons';
import { Dropdown } from 'frontend/components';
import TabButton from 'frontend/components/TabButton/TabButton';
import { DIALOGUE_TYPES, isRegularDialogueType } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';
import { useCurrentLanguage } from 'frontend/hooks';
import { hasLanguage } from 'frontend/utils';

import styles from './VerticalToolbar.scss';
import AddRuleModal from '../../modals/AddRule/AddRuleModal';
import RuleDropdown from '../RuleDropdown/RuleDropdown';

type ButtonName = 'input' | 'output' | 'label' | 'rule';

interface Props {
  toggleRightSidePanel?: () => void;
  isOutputPanelShown: boolean;
  isRightSidePanelShown?: boolean;
  /** A list containing the names of the buttons to disable. */
  buttonsToDisable?: ButtonName[];
  isSkill?: boolean;
}

const TriangleDown = ({ className }: { className?: string }) => (
  <TriangleUp className={cx(styles.inputTriangle, className)} />
);

export default function VerticalToolbar({
  toggleRightSidePanel,
  isRightSidePanelShown,
  isOutputPanelShown,
  buttonsToDisable,
  isSkill,
}: Props): React.JSX.Element {
  const {
    values: { labels = [], dialogueRules = [], outputSlot = [], isSubscriptionDialogue = false, dialogueType },
  } = useFormState({ subscription: { values: true } });

  const isRuleOptionEnabled = (() => {
    if (isSkill === true) {
      return false;
    }
    if (isSubscriptionDialogue) {
      return false;
    }
    if (
      isRegularDialogueType(dialogueType) ||
      dialogueType === DIALOGUE_TYPES.GREETING ||
      dialogueType === DIALOGUE_TYPES.FALLBACK
    ) {
      return true;
    }
    return false;
  })();

  const [searchParams, setSearchParams] = useSearchParams();
  const [{ selectedLanguage }] = useCurrentLanguage();

  const setFormContentParams = (content: ButtonName) => () => {
    if (searchParams.get('formContent') === content) return;

    searchParams.set('formContent', content);
    searchParams.delete('tab');
    setSearchParams(searchParams);
  };

  const labelsLength: number | string = labels?.length > 99 ? '∞' : labels?.length;
  const rules = (dialogueRules || []).filter(({ id }) => id).filter(hasLanguage(selectedLanguage));
  const rulesNumber: number | string = rules.length > 99 ? '∞' : rules.length;

  const [isRuleDropdownOpen, setIsRuleDropdownOpen] = useState(false);
  const [showRuleModal] = useModal(AddRuleModal);

  return (
    <div className={styles.wrapper} data-testid="vertical-toolbar">
      <Dropdown
        onToggle={setIsRuleDropdownOpen}
        // eslint-disable-next-line react/no-unstable-nested-components
        overlay={({ close }) => <RuleDropdown close={close} showRuleModal={showRuleModal} />}
        overlayLeft=""
        position="right"
        isDisabled={buttonsToDisable?.includes('rule') || !isRuleOptionEnabled || !!outputSlot.length}
        wrapperClassName={styles.ruleDropdownWrapper}
        overlayClassName={styles.ruleDropdownOverlay}
        element="div"
      >
        <TabButton
          icon={Directions}
          label="Rule"
          badgeNumber={rulesNumber}
          active={isRuleDropdownOpen}
          isDisabled={buttonsToDisable?.includes('rule') || !isRuleOptionEnabled || !!outputSlot.length}
        />
      </Dropdown>
      <TabButton
        icon={TriangleDown}
        label="Input"
        onClick={setFormContentParams('input')}
        active={!isOutputPanelShown}
        isDisabled={buttonsToDisable?.includes('input')}
      />

      <TabButton
        icon={TriangleUp}
        label="Output"
        onClick={setFormContentParams('output')}
        active={isOutputPanelShown}
      />

      <TabButton
        icon={Labels}
        label="Label"
        badgeNumber={labelsLength}
        onClick={toggleRightSidePanel}
        active={isRightSidePanelShown}
        isDisabled={buttonsToDisable?.includes('label')}
      />
    </div>
  );
}
