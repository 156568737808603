import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import UpdateGcsBackupSettings from 'frontend/features/BotSettings/mutations/UpdateGcsInboxBackupSettings.mutation.graphql';
import { useIsRunning } from 'frontend/hooks';

export default ({ botId, initialValues }) => {
  const [bucketUpdateSuccessful, setBucketUpdateSuccessful] = useState();
  const [update] = useMutation(UpdateGcsBackupSettings);

  const onSubmit = useCallback(
    async ({ googleApplicationCredentials, gcsEnabled, bucketName, pathPrefix }) => {
      // only update secret if its been changed, since we censor the private key with asterix
      const secretHasBeenChanged =
        !initialValues || googleApplicationCredentials !== initialValues.googleApplicationCredentials;
      const variables = {
        botId,
        googleApplicationCredentials,
        enabled: gcsEnabled,
        bucketName,
        pathPrefix,
        ...(secretHasBeenChanged ? { googleApplicationCredentials } : {}),
      };

      try {
        await update({ variables });
        if (gcsEnabled) setBucketUpdateSuccessful(true);
      } catch (e) {
        setBucketUpdateSuccessful(false);
      }
    },
    [botId, initialValues, update],
  );

  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmit);

  return { onSubmit: onSubmitWithRunning, isSubmitting, bucketUpdateSuccessful };
};
