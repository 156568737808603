import PropTypes from 'prop-types';

import { Equals } from 'frontend/propTypes';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  restricted: PropTypes.bool.isRequired,
  __typename: Equals('KindlyEntityType'),
});
