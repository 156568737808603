import { url as validateUrl } from 'frontend/form/validators';

import type { ButtonType, LocalErrors } from './ButtonsMeta';

/**
 * Validate the button that is going to be added to the Form.
 * Return `true` if no errors, otherwise return a map of the errors.
 * */
export default function validateButton(button: ButtonType): LocalErrors {
  const errorsMap: LocalErrors = {
    label: null,
    value: null,
  };

  /* External link validation */
  if (button.buttonType === 'link') {
    const potentialError = validateUrl(button.value);
    if (potentialError) {
      errorsMap.value = { error: potentialError, submitFailed: true };
    }
  }

  /* General validation */

  if (!button.label.trim()) {
    errorsMap.label = { error: "This can't be blank", submitFailed: true };
  }

  if (!button.value.trim()) {
    errorsMap.value = { error: "This can't be blank", submitFailed: true };
  }

  return errorsMap;
}
