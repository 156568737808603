import { Route, Routes } from 'react-router-dom';

import { PageContent, SidebarNavigation } from 'frontend/components';

import styles from './SkillSettings.scss';
import Languages from './views/Languages';
import Settings from './views/Settings';

const navItems = [
  {
    key: 'settings',
    url: '',
  },
  {
    key: 'Languages',
    url: 'languages',
  },
];
export default function SkillSettings() {
  return (
    <PageContent hasFormValidation>
      <div className={styles.container}>
        <div>
          <SidebarNavigation items={navItems} />
        </div>
        <Routes>
          <Route path="/" element={<Settings />} />
          <Route path="/languages" element={<Languages />} />
        </Routes>
      </div>
    </PageContent>
  );
}
