import { useMutation, useQuery } from '@apollo/client';
import { mapValues, pick } from 'lodash';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loader, Panel } from 'frontend/components';
import getSmartReplyUpdate from 'frontend/features/Build/utils/getSmartReplyUpdate';
import { useBotOrSkill, useCurrentLanguage, useLanguages } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

import DialogueMod from './DialogueMod';
import styles from '../Build.scss';
import { DialogueForm } from '../components';
import BuildEmptyState from '../components/BuildEmptyState/BuildEmptyState';
import { GetDialogue, UpdateDialogueMutation } from '../graphql';
import { useExternalChanges, useInitialValues, useUpdateCurrentBreadcrumbs } from '../hooks';
import useCurrentRuleId from '../hooks/useCurrentRuleId';
import {
  dialogueInputFields,
  getBuildItemUpdate,
  getButtonsUpdate,
  getImageCarouselsUpdate,
  getLabelUpdate,
  getSlotUpdate,
  getTitleUpdate,
} from '../utils';
import getFormUpdate from '../utils/getFormUpdate';
import getRulesUpdate from '../utils/getRulesUpdate';
import { prepareDialogueBuildItemForBE } from '../utils/prepareDialogueBuildItem';

const UpdateDialogue = () => {
  const { dialogueId } = useParams();
  const { buildIdObject, buildType, buildId, isBot } = useBotOrSkill();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const [hasChanges, setHasChanges] = useState(false);
  const { loading: languagesLoading } = useLanguages({ isBot, buildIdObject, buildType } as BotOrSkill);
  const [updateMutation] = useMutation(UpdateDialogueMutation);

  const { data, loading } = useQuery(GetDialogue, {
    variables: { ...buildIdObject, dialogueId },
  });

  const { isSubscriptionDialogue, parentId } = data?.dialogue ?? {};

  const { initialValues, updateInitialValues } = useInitialValues(data);
  const currentRuleId = useCurrentRuleId();

  const { mergeWithExternalChanges, reset } = useExternalChanges({
    selectedLanguage,
    data,
    hasChanges,
    initialValues,
    isSubscriptionDialogue,
    updateInitialValues,
  });

  const onSubmit = useCallback(
    async (values, form) => {
      const fixedValues = {
        ...values,
        dialogueFallback: mapValues(values.dialogueFallback || {}, (text) => text || ''),
        outputSlots: (values.outputSlots || []).map(({ prompt, ...slot }) => ({ ...slot, prompt: prompt || '' })),
        intent: values.intent || null,
        buttonsDisplayOrientation: values.buttonsDisplayOrientation,
      };

      const { initialValues: initial } = form.getState();

      const titleUpdate = getTitleUpdate(initial.title, values.title);

      // Non-related fields must be explicitly merged with external changes to avoid unintended overwrites
      const nonRelatedFieldsUpdate = mergeWithExternalChanges({
        ...pick(fixedValues, dialogueInputFields),
        ...titleUpdate,
      });

      const sampleUpdate = getBuildItemUpdate(initial, values, 'samples');
      const replyUpdate = getBuildItemUpdate(initial, values, 'replies');
      const smartReplyUpdate = getSmartReplyUpdate(initial, values, 'smartReplies');
      const labelUpdate = getLabelUpdate(initial.labels, values.labels);
      const slotsUpdate = getSlotUpdate(initial, fixedValues);
      const buttonsUpdate = getButtonsUpdate(initial, values);
      const imageCarouselsUpdate = getImageCarouselsUpdate(initial, values);
      const rulesUpdate = getRulesUpdate({ initialValues: initial, values, language: selectedLanguage });
      const formUpdate = getFormUpdate(initial, values);
      const context = prepareDialogueBuildItemForBE(values.context);
      const advancedOptions = prepareDialogueBuildItemForBE(values.advancedOptions);

      const dialogueInput = {
        advancedOptions,
        context,
        ...nonRelatedFieldsUpdate,
        ...titleUpdate,
        ...sampleUpdate,
        ...labelUpdate,
        ...replyUpdate,
        ...smartReplyUpdate,
        ...slotsUpdate,
        ...buttonsUpdate,
        ...imageCarouselsUpdate,
        ...formUpdate,
        ...rulesUpdate,
      };

      const variables = { ...buildIdObject, dialogueId, languageCode: selectedLanguage, dialogueInput };
      const { data: updatedData } = await updateMutation({ variables });

      updateInitialValues({ dialogue: updatedData.updateDialogue }, selectedLanguage);
      reset({});
    },
    [buildIdObject, dialogueId, mergeWithExternalChanges, reset, selectedLanguage, updateInitialValues, updateMutation],
  );

  useUpdateCurrentBreadcrumbs(data?.dialogue);

  if (loading || languagesLoading || !buildId || !buildType) {
    return (
      <Panel className={styles.loadingContainer}>
        <Loader size="large" />
      </Panel>
    );
  }

  if (!data?.dialogue) {
    return <BuildEmptyState />;
  }

  if (isSubscriptionDialogue) {
    return (
      <DialogueMod
        initialValues={initialValues}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        currentLanguage={selectedLanguage}
        currentRuleId={currentRuleId}
        buildIdObject={buildIdObject}
        buildId={buildId}
        mergeWithExternalChanges={mergeWithExternalChanges}
        updateInitialValues={updateInitialValues}
        reset={reset}
      />
    );
  }

  return (
    <DialogueForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      selectedLanguage={selectedLanguage}
      currentRuleId={currentRuleId}
      parentId={parentId}
      buildIdObject={buildIdObject}
      buildType={buildType}
      buildId={buildId}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
    />
  );
};

export default UpdateDialogue;
