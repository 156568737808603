import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { CreateSkillFromBotDocument } from 'frontend/api/generated';
import { Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';

const CreateSkillFromBot = ({ hide, args: { botId, botName } }) => {
  const toast = useToast();
  const initialValues = { name: `${botName} (skill)` };
  const [mutate] = useMutation(CreateSkillFromBotDocument);

  const onSubmit = useCallback(
    async ({ name }) => {
      await mutate({ variables: { botId, name } });
      toast.success(`The bot ${name} is being converted to a skill. This may take a moment...`);
    },
    [toast, mutate, botId],
  );

  return (
    <ModalForm
      onSubmit={onSubmit}
      title="Create skill from bot"
      onOkText="Create"
      hide={hide}
      initialValues={initialValues}
      ignoreDirty
    >
      <Field
        component={Input}
        label="Name your new skill"
        className="m-b-md"
        placeholder="Name"
        name="name"
        validate={required}
        autoFocus
      />
    </ModalForm>
  );
};

CreateSkillFromBot.propTypes = {
  args: PropTypes.shape({ botName: PropTypes.string, botId: PropTypes.string.isRequired }),
  hide: PropTypes.func.isRequired,
};

export default CreateSkillFromBot;
