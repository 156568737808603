import { gql } from '@apollo/client';

import { skillSettingsFragment } from '../fragments';

export default gql`
  query GetSkillSettings($skillId: ID!) {
    skill(id: $skillId) {
      ...SkillSettings
    }
  }
  ${skillSettingsFragment}
`;
