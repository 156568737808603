import { gql } from '@apollo/client';

import POLICY_SIGNATURE from 'frontend/features/Organization/fragments/PolicySignature.fragment.graphql';

export default gql`
  mutation SignPolicy($organizationId: ID!, $policyId: ID!) {
    signPolicy(organizationId: $organizationId, policyId: $policyId) {
      ...PolicySignature
    }
  }
  ${POLICY_SIGNATURE}
`;
