import { $generateHtmlFromNodes } from '@lexical/html';
import { $isRootTextContentEmpty } from '@lexical/text';
import { formatISO } from 'date-fns';
import { EditorState } from 'draft-js';
import type { LexicalEditor as LexicalEditorType } from 'lexical';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import LexicalEditor from 'frontend/components/LexicalEditor/LexicalEditor';
import { required } from 'frontend/form/validators';
import { hasLanguage } from 'frontend/utils';
import hasRule from 'frontend/utils/hasRule';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './SmartReplies.scss';
import buildStyles from '../../Build.scss';
import type { BuildFormType } from '../../propTypes/BuildFormType';

function getEmptySmartReply(languageCode: string, currentRuleId: string | null = null) {
  return {
    languageCode,
    rule: currentRuleId ? { id: currentRuleId } : undefined,
    tempId: randomUUID(),
    createdAt: formatISO(new Date()),
    updatedAt: formatISO(new Date()),
    content: '',
    editorState: EditorState.createEmpty(),
  };
}

interface Props {
  args: {
    currentLanguage: string;
    currentRuleId: string | null;
    buildFormType?: BuildFormType;
    isModDialogue?: boolean;
  };
}

export const cleanLexicalHTML = (htmlString: string) => {
  if ($isRootTextContentEmpty(false)) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const pTags = Array.from(doc.querySelectorAll('p'));
  const spanTags = Array.from(doc.querySelectorAll('span'));

  pTags.forEach((p) => {
    if (!(p.childNodes.length === 1 && p.childNodes[0]?.nodeName === 'BR')) {
      const prevSibling = p.previousElementSibling;
      if (prevSibling && prevSibling.tagName.toLowerCase() === 'p') {
        const br = doc.createElement('br');
        p.parentNode?.insertBefore(br, p);
      }
    }
  });

  [...pTags, ...spanTags].forEach((tag) => {
    tag.replaceWith(...tag.childNodes);
  });

  const innerHTML = doc.body.innerHTML;
  return innerHTML;
};

const SmartReplies = ({ args: { currentLanguage, currentRuleId } }: Props) => {
  const form = useForm();

  const fieldName = 'smartReplies';
  const headline = 'Content';

  const handleLexicalChange = (name: string) => (edState: EditorState, editor: LexicalEditorType) => {
    form.change(`${name}.content`, cleanLexicalHTML($generateHtmlFromNodes(editor, null)));
    form.change(`${name}.editorState`, edState);
  };

  const renderSmartReplies = ({ fields }) => {
    const addReply = () => fields.pushFixed(fields.name, getEmptySmartReply(currentLanguage, currentRuleId));
    const nReplies = (fields.value || []).filter(hasLanguage(currentLanguage)).filter(hasRule(currentRuleId)).length;
    // Greetings and fallbacks can have a maximum of one reply
    const canAddReply = nReplies === 0;

    const addReplyKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === 'Enter') addReply();
    };

    return (
      <>
        {fields.map((name, index) => {
          const value = fields.value?.[index];
          if (!hasLanguage(currentLanguage)(value) || !hasRule(currentRuleId)(value)) {
            return null;
          }

          return (
            <div key={name} className={styles.wrapper}>
              <Field name={`${name}.content`} validate={required}>
                {({ meta }) => (
                  <div className={styles.replyContainer}>
                    <LexicalEditor
                      initialHtml={value.content}
                      handleChange={handleLexicalChange(name)}
                      InputSideComponent={
                        <span>
                          <Icon
                            component={Trash}
                            onClick={(event) => {
                              fields.remove(index);
                              if (!event) return;

                              event.stopPropagation();
                              event.preventDefault();
                            }}
                            color="default"
                            hoverColor="warning"
                            title="Remove"
                          />
                        </span>
                      }
                      classNames={{ inputWrapper: styles.replyInputWrapper }}
                      meta={meta}
                    />
                  </div>
                )}
              </Field>
            </div>
          );
        })}

        {canAddReply && (
          <div onClick={addReply} onKeyDown={addReplyKeyDown} tabIndex={0} className={styles.addReply} role="button">
            Add alternate answer
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <h3 className={buildStyles.sectionTitle}>{headline}</h3>
      <FieldArray name={fieldName} render={renderSmartReplies} />
    </>
  );
};

export default SmartReplies;
