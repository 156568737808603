import { useMemo } from 'react';

import { addListItemByFragment } from 'frontend/api/cacheHelpers';
import { chatListItemFragment } from 'frontend/api/fragments';
import { hasSameId } from 'frontend/features/Build/utils';
import { TICKET } from 'frontend/features/Inbox/fragments';

const hasSameChatId = ({ chatId }, { id }) => id === chatId;

const directListUpdate =
  (cache) =>
  ({ chat, chatShouldBeInList, chatsAndTickets, ticket }) => {
    if (!chatShouldBeInList) return;

    cache.modify({
      id: cache.identify(chatsAndTickets),
      fields: {
        chats: addListItemByFragment(cache, chatListItemFragment, chat),
        tickets: addListItemByFragment(cache, TICKET, ticket),
      },
    });
  };

const delayedListUpdate =
  (setNewerChatsAndTickets) =>
  ({ chat, ticket, chatShouldBeInList }) => {
    setNewerChatsAndTickets((newer) => {
      const isInNewer = newer.chats.find(hasSameId(chat));

      if (isInNewer && chatShouldBeInList) {
        const updatedChats = newer.chats.map((current) => (hasSameId(current, chat) ? chat : current));
        const updatedTickets = newer.tickets.map((current) => (hasSameChatId(current, chat) ? ticket : current));
        if (ticket && !updatedTickets.find(({ id }) => id === ticket.id)) updatedTickets.push(ticket);

        return { chats: updatedChats, tickets: updatedTickets };
      }
      if (!isInNewer && chatShouldBeInList) {
        return { chats: [...newer.chats, chat], tickets: [...newer.tickets, ...(ticket ? [ticket] : [])] };
      }
      if (isInNewer && !chatShouldBeInList) {
        return {
          chats: newer.chats.filter((current) => !hasSameId(current, chat)),
          tickets: newer.tickets.filter((current) => !hasSameChatId(current, chat)),
        };
      }

      return newer;
    });
  };

export default ({ setNewerChatsAndTickets, cache, updateListDirectly }) => {
  const onChatNotInList = useMemo(
    () => (updateListDirectly ? directListUpdate(cache) : delayedListUpdate(setNewerChatsAndTickets)),
    [cache, setNewerChatsAndTickets, updateListDirectly],
  );

  return onChatNotInList;
};
