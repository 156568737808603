import { gql } from '@apollo/client';

const EVALUATION_FRAGMENT = gql`
  fragment Evaluation on EvaluationType {
    id
    title
    languageCode
    totalQueries
    confidenceThreshold
    estimateRunTimeSeconds
    totalFinishedReports
  }
`;

export default EVALUATION_FRAGMENT;
