import PropTypes from 'prop-types';

import { Close } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { ChildrenType } from 'frontend/propTypes';

import styles from './DropdownOverlay.scss';

const DropdownOverlay = ({ header, close, children }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <span>{header}</span>
      <Button flat onClick={close} icon={Close} className={styles.close} />
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);

DropdownOverlay.propTypes = {
  header: PropTypes.string.isRequired,
  children: ChildrenType.isRequired,
  close: PropTypes.func.isRequired,
};

export default DropdownOverlay;
