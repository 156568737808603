import { gql } from '@apollo/client';

import { ENTITY } from '../fragments';

export default gql`
  query SubscriptionsWithEntities($botId: ID!) {
    subscriptionsWithEntities(botId: $botId) {
      id
      skill {
        id
        name
        entities {
          ...Entity
        }
      }
    }
  }
  ${ENTITY}
`;
