import { gql } from '@apollo/client';

export default gql`
  fragment ReplyCandidate on ReplyCandidateType {
    id: Id
    dialogueId
    handler
    rank
    sample
    score
    selected
    replies(botId: $botId, languageCode: $languageCode) {
      id
      text
    }
    path(botId: $botId)
  }
`;
