import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';

import { composerStateToFormValues } from 'frontend/features/Composer';

const FIELD_CONFIG = { subscribe: { value: true } };

export default ({ name, sampleValidator }) => {
  const [error, setError] = useState();

  const {
    input: { value },
  } = useField(name, FIELD_CONFIG);

  useEffect(() => {
    if (!error || !value.editorState) return;
    const { text, instances } = composerStateToFormValues(value.editorState);
    const newError = sampleValidator({ text, instances });
    setError(newError);
  }, [error, sampleValidator, value.editorState]);

  return { error, setError };
};
