import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Alert, Nudge, Tickets, Wink } from 'frontend/assets/icons';
import { Accordion, List } from 'frontend/components';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { useNudges } from 'frontend/features/NudgeEditor/hooks';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';

import styles from './ProactivityView.scss';
import { GlobalIconsList, NudgesList, PushGreetingsList } from './components';
import { ProactivityCount } from '../../components';

export enum ProactivityViewEnums {
  NUDGE = 'NUDGE',
  PUSH_GREETINGS = 'PUSH_GREETINGS',
  MAINTENANCE_ALERT = 'MAINTENANCE_ALERT',
  GLOBAL_ICONS = 'GLOBAL_ICONS',
}

export type NavigationProperties = {
  title: string;
  key: string;
  hasPermissions?: string;
  icon?: React.ReactElement;
  getUrl?: (args: { botId: string; skillId?: string }) => string;
  isBotOnly?: boolean;
  rightContent?: React.ReactElement;
  children?: React.ReactElement;
  hideFromMenu?: boolean;
};

export type ProactivityViewNavigation = Record<keyof typeof ProactivityViewEnums, NavigationProperties>;

const navigationPages: ProactivityViewNavigation = {
  [ProactivityViewEnums.NUDGE]: {
    title: 'Nudge',
    key: 'proactivity-nudge',
    hasPermissions: 'manage_nudges',
    icon: <Nudge />,
    rightContent: <ProactivityCount show="nudge" />,
    children: <NudgesList />,
    getUrl: ({ botId }) => `/workspace/${botId}/build/proactivity/nudge`,
  },
  [ProactivityViewEnums.GLOBAL_ICONS]: {
    title: 'Global icons',
    key: 'proactivity-global-icons',
    icon: <Tickets />,
    children: <GlobalIconsList />,
    rightContent: <ProactivityCount show="global-icons" />,
    getUrl: ({ botId }) => `/workspace/${botId}/build/proactivity/global-icons`,
  },
  [ProactivityViewEnums.MAINTENANCE_ALERT]: {
    title: 'Banner message',
    key: 'proactivity-banner-message',
    icon: <Alert />,
    getUrl: ({ botId }) => `/workspace/${botId}/build/proactivity/banner-message`,
  },
  [ProactivityViewEnums.PUSH_GREETINGS]: {
    title: 'Push greeting',
    key: 'proactivity-push-greeting',
    icon: <Wink />,
    rightContent: <ProactivityCount show="push-greeting" />,
    children: <PushGreetingsList />,
    getUrl: ({ botId }) => `/workspace/${botId}/build/proactivity/push-greetings`,
  },
};

const ProactivityView = () => {
  const { botId } = useParams();
  const hasInitiallyNavigated = useRef(false);
  const [initialOpen, setInitialOpen] = useState({
    'proactivity-push-greeting': window.location.pathname.includes('proactivity/push-greeting'),
    'proactivity-nudge': window.location.pathname.includes('proactivity/nudge'),
    'proactivity-global-icons': window.location.pathname.includes('proactivity/global-icon'),
  });

  const isFeatureEnabled = useFeatureFlags();
  const isGlobalIconsEnabled = isFeatureEnabled('global-icons');

  const { initialValues } = useChatbubbleForm();
  const { nudges, loadingNudges } = useNudges();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !loadingNudges &&
      nudges.length &&
      !hasInitiallyNavigated.current &&
      window.location.pathname.includes('/nudge')
    ) {
      setInitialOpen((current) => ({
        ...current,
        'proactivity-nudge': true,
      }));

      setTimeout(() => navigate(`/workspace/${botId}/build/proactivity/nudge/${nudges[0]?.slug}`));
      hasInitiallyNavigated.current = true;
    }
  }, [nudges, loadingNudges, navigate, botId]);

  return (
    <div className={styles.navigationContainer}>
      <List
        dataSource={Object.values(navigationPages)}
        renderItem={(item: NavigationProperties) => (
          <Accordion
            bodyClassName={styles.navigationAccordionBody}
            headerLeft={item.icon}
            title={item.title}
            key={item.key}
            isOpen={initialOpen[item.key]}
            hideChevron={
              (item.key === 'proactivity-push-greeting' && !initialValues.pushGreetings?.length) ||
              (item.key === 'proactivity-nudge' && !nudges?.length) ||
              (item.key === 'proactivity-global-icons' && !initialValues.globalIcons?.length)
            }
            ignoreActive={
              (item.key === 'proactivity-push-greeting' && initialValues.pushGreetings?.length) ||
              (item.key === 'proactivity-nudge' && nudges?.length)
            }
            fullLengthTitle={item.key === 'proactivity-banner-message'}
            headerRight={
              item.key === 'proactivity-global-icons' && !isGlobalIconsEnabled ? undefined : item.rightContent
            }
            to={item.getUrl?.({ botId: botId! }) as string}
            onClick={(e) => {
              if (
                (item.key === 'proactivity-push-greeting' && initialValues.pushGreetings.length) ||
                (item.key === 'proactivity-nudge' && nudges.length) ||
                (item.key === 'proactivity-global-icons' && isGlobalIconsEnabled && initialValues.globalIcons.length)
              ) {
                e.preventDefault();
              }
            }}
          >
            {item.key === 'proactivity-global-icons' && !isGlobalIconsEnabled ? null : item.children}
          </Accordion>
        )}
      />
    </div>
  );
};

export default ProactivityView;
