import cx from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import { DropdownButton } from 'frontend/components';
import { useMounted } from 'frontend/hooks';

import styles from './SampleOptions.scss';

const SampleOptions = ({ remove, sampleIndex, selected, removeSelected, deselectAll }) => {
  const mounted = useMounted();
  const actions = [];
  const itemStyles = cx('ignoreSelect', styles.dropdownItem);

  if (selected) {
    actions.push({
      title: 'Delete selected samples',
      key: `delete-sample-${sampleIndex}`,
      onClick: removeSelected,
      className: itemStyles,
    });
  } else {
    actions.push({
      title: 'Delete sample',
      key: `delete-sample-${sampleIndex}`,
      onClick: remove,
      className: itemStyles,
    });
  }

  const className = cx(styles.sampleOptions, { [styles.sampleOptionsMounted]: mounted });
  const onClick = useCallback(() => {
    if (!selected) deselectAll();
  }, [deselectAll, selected]);

  return (
    <span className={className}>
      <DropdownButton
        actions={actions}
        position="bottom-right"
        wrapperClassName={styles.dropdown}
        contentClassName={styles.dropdownContent}
        onClick={onClick}
        data-testid={`delete-sample-${sampleIndex}`}
      >
        <div className={cx('ignoreSelect', styles.icon)} />
      </DropdownButton>
    </span>
  );
};

SampleOptions.propTypes = {
  remove: PropTypes.func.isRequired,
  removeSelected: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  sampleIndex: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default memo(SampleOptions);
