import React from 'react';

import styles from './AddAdornment.scss';

interface AddAdornmentProps {
  text?: string;
}

const AddAdornment = ({ text }: AddAdornmentProps) => (
  <div className={styles.addAdornment}>
    <div className={styles.addSvgWrapper}>&#8617; </div> {text || 'to add'}
  </div>
);

export default AddAdornment;
