import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { Panel } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';

import styles from './ClusterDetail.scss';
import { DuplicateMessageChats } from '../../modals';
import { MessageRecordType } from '../../propTypes';
import ClusterDetailMessage from '../ClusterDetailMessage';

const ClusterDetail = forwardRef(({ messages, botId }, ref) => {
  const [showDuplicateModal] = useModal(DuplicateMessageChats);

  return (
    <Panel className={styles.panel}>
      {messages.map((message, idx) => (
        <ClusterDetailMessage
          key={`cluster-message-${message.id}`}
          botId={botId}
          message={message}
          ref={idx === 0 ? ref : null}
          showDuplicateModal={showDuplicateModal}
          counter={idx + 1}
        />
      ))}
    </Panel>
  );
});

ClusterDetail.displayName = 'ClusterDetail';

ClusterDetail.propTypes = {
  botId: PropTypes.number.isRequired,
  messages: PropTypes.arrayOf(MessageRecordType).isRequired,
};

export default ClusterDetail;
