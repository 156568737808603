import kindlyChatLogo from 'frontend/assets/images/kindly_chat_icon.png';
import messengerLogo from 'frontend/assets/images/messenger-logo.svg?url';
import zendeskLogo from 'frontend/assets/images/zendesk_icon.png';

export const FILTER_TYPES = Object.freeze({
  ALL: 'all',
  PLUGINS: 'plugins',
  CHAT_CLIENT: 'chat_clients',
  CUSTOM: 'custom',
});

export const LOGO_URLS = {
  'kindly-chat': kindlyChatLogo,
  messenger: messengerLogo,
  'zendesk-chat': zendeskLogo,
};

export const SCRAPE_WEB_CONTENT_MIN_CHAR_LENGTH = 300;
