import { EditorState } from 'draft-js';
import { orderBy } from 'lodash';

import { DIALOGUE_TYPES } from 'frontend/constants';
import { formValuesToComposerState } from 'frontend/features/Composer';

import emptyButton from './emptyButton';
import handleButtonsAndImages from './handleButtonsAndImages';
import { prepareDialogueContextForFE } from './prepareDialogueBuildItem';

export default ({ dialogue }, selectedLanguage, slotsEnabled) => {
  const orderedReplies = orderBy(dialogue.replies, 'createdAt');

  const newSampleObject =
    dialogue.dialogueType === DIALOGUE_TYPES.SAMPLES ? { newSample: { editorState: EditorState.createEmpty() } } : {};

  const repliesObject = slotsEnabled
    ? {
        replies: orderedReplies.map((reply, index) => ({
          ...reply,
          editorState: formValuesToComposerState(`reply-${index}`, reply.text, reply.instances),
        })),
      }
    : { replies: orderedReplies };
  const context = prepareDialogueContextForFE(dialogue.context);

  return handleButtonsAndImages(selectedLanguage, {
    ...dialogue,
    ...newSampleObject,
    ...repliesObject,
    context,
    newButton: emptyButton,
    buildForms: [...dialogue.buildForms].sort(
      (a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
    ),
  });
};
