import { format } from 'date-fns';
import { upperFirst } from 'lodash';
import { compose, get, map, sum } from 'lodash/fp';

import type { LineChartSeries } from 'frontend/components/Stats/components/LineChart/types';
import type { Granularity } from 'frontend/features/Analytics/types';
import {
  DATE_FORMAT_DATE_LONG,
  DATE_FORMAT_MONTH_AND_YEAR,
  DATE_FORMAT_QUARTER_YEAR,
  DATE_FORMAT_WITH_TIME,
  DATE_FORMAT_YEAR,
} from 'frontend/utils/date';

export const sumYData = compose(sum, map(get('y')), get('data'));

export function roundNumber(number: number, isPercentage = false, isRate = false): number {
  if (isRate) {
    return Number((number * 100).toFixed(2));
  }

  if (isPercentage) {
    return Number(number.toFixed(1));
  }

  return Math.round(number);
}

export function defaultFormatLabelCallback(series: LineChartSeries): string {
  return upperFirst(series.id.replace('_', ' '));
}

export function calculateAxisValues(
  chartData: LineChartSeries[],
  isPercentage = false,
  isRate = false,
): { leftTicks: number[]; bottomTicks: Date[]; maxValue: number; leftAxisMargin: number; bottomAxisMargin: number } {
  const Y_AXIS_INTERVAL = 5;
  const X_AXIS_INTERVAL = 7;

  // Calculate the width of the axis values based on the length of the largest/last value
  const calculateLeftAxisMargin = (maxValue) => maxValue.toString().length * 6 + 22;
  const calculateBottomAxisMargin = (lastValue) => (lastValue.toString().length * 0.58) / 2 + 4;

  const first = chartData[0]?.data ?? [];

  const yValues: number[] = chartData
    .map(({ data }) => data.map((point) => point.y))
    .flat()
    .filter((y): y is number => y !== undefined);
  const maxValue: number = Math.max(...yValues);

  const interval = (isRate ? maxValue : Math.ceil(maxValue)) / Y_AXIS_INTERVAL;
  const leftTicks = Array.from({ length: 6 }, (_, i) => {
    if (maxValue > 1000) {
      return Math.ceil((i * interval) / 100) * 100;
    }
    if (maxValue > 50) {
      return Math.ceil((i * interval) / 10) * 10;
    }
    if (maxValue > 10) {
      return Math.ceil(i * interval);
    }
    if (isRate) {
      return Number((i * interval).toFixed(2));
    }
    return i * interval;
  });

  let bottomTicks: Date[] = first.map((x) => x.x).filter((x): x is Date => x !== undefined);
  const dataLength = first.length - 1;

  // Only show first, middle and last ticks if more than 7 entries
  if (dataLength > X_AXIS_INTERVAL) {
    const middleItem = Math.ceil(dataLength / 2);
    bottomTicks = [first[0]?.x, first[middleItem]?.x, first[dataLength]?.x].filter((x): x is Date => x !== undefined);
  }

  if (isPercentage) {
    return {
      leftTicks: [0, 20, 40, 60, 80, 100],
      bottomTicks,
      maxValue: 100,
      leftAxisMargin: calculateLeftAxisMargin(maxValue),
      bottomAxisMargin: calculateBottomAxisMargin(bottomTicks[bottomTicks.length - 1]),
    };
  }

  return {
    leftTicks,
    bottomTicks,
    maxValue: interval * Y_AXIS_INTERVAL, // Gives some space above the top data point
    leftAxisMargin: calculateLeftAxisMargin(maxValue),
    bottomAxisMargin: calculateBottomAxisMargin(bottomTicks[bottomTicks.length - 1]),
  };
}

export function formatTime(x: number, granularity: Granularity) {
  switch (granularity) {
    case 'year':
      return format(x, DATE_FORMAT_YEAR);
    case 'quarter':
      return `Q${format(x, DATE_FORMAT_QUARTER_YEAR)}`;
    case 'month':
      return format(x, DATE_FORMAT_MONTH_AND_YEAR);
    case 'week':
      return `Week of ${format(x, DATE_FORMAT_DATE_LONG)}`;
    case 'hour':
      return format(x, DATE_FORMAT_WITH_TIME);
    default:
      return format(x, DATE_FORMAT_DATE_LONG);
  }
}
