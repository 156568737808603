import cx from 'classnames';
import { sumBy } from 'lodash';
import { useState } from 'react';

import { Button, Input } from 'frontend/components';
import { DoughnutChart, EmptyState } from 'frontend/components/Stats';
import type { HoursData } from 'frontend/features/Analytics/utils/sageData';
import { useToggle } from 'frontend/hooks';

import styles from './Reports.scss';

function businessHourReport(hours, from, to) {
  const inside = hours.filter((i) => i.hour >= parseInt(from, 10) && i.hour <= parseInt(to, 10));
  const total = sumBy(hours, 'count');
  const totalInside = sumBy(inside, 'count');

  return {
    total,
    data: [
      {
        value: totalInside,
        label: 'inside business hours',
      },
      {
        value: total - totalInside,
        label: 'outside business hours',
      },
    ],
  };
}

function BusinessHourReport({ hours, emptyStateText }: { hours: HoursData[]; emptyStateText: string }) {
  const [businessHourFrom, setBusinessHourFrom] = useState('08');
  const [businessHourTo, setBusinessHourTo] = useState('16');
  const [isEditingBusinessHours, toggleIsEditingBusinessHours] = useToggle(false);

  const showEmptyState = !hours || hours?.length === 0;

  return (
    <>
      <h4 className="m-t-xl">
        Traffic by business hours{' '}
        <span>
          ({businessHourFrom} - {businessHourTo})
        </span>
        {!isEditingBusinessHours && !showEmptyState && (
          <Button flat className={styles.editBusinessHours} onClick={toggleIsEditingBusinessHours}>
            change
          </Button>
        )}
      </h4>
      <div className={`${styles.timeInputsWrapper} m-t-md`}>
        {isEditingBusinessHours && (
          <>
            <div className={cx(styles.timeInputs, styles.timeInputsFrom)}>
              <Input
                inputType="number"
                input={{
                  value: businessHourFrom,
                  onChange: (event) => setBusinessHourFrom(event.target.value),
                }}
                aria-label="From"
              />
            </div>
            <div className={cx(styles.timeInputs, styles.timeInputsTo)}>
              <Input
                inputType="number"
                input={{ value: businessHourTo, onChange: (event) => setBusinessHourTo(event.target.value) }}
                aria-label="To"
              />
            </div>
            <Button flat onClick={toggleIsEditingBusinessHours}>
              Set
            </Button>
          </>
        )}
      </div>
      {!isEditingBusinessHours && !showEmptyState && (
        <DoughnutChart data={businessHourReport(hours, businessHourFrom, businessHourTo)} />
      )}
      {!isEditingBusinessHours && showEmptyState && <EmptyState emptyStateText={emptyStateText} />}
    </>
  );
}

export default BusinessHourReport;
