import { noop } from 'lodash';
import { useEffect } from 'react';
import { useDrop } from 'react-dnd';

import { dragAndDropTypes } from 'frontend/constants';

const DROP_CONFIG = {
  accept: [dragAndDropTypes.DIALOGUE],
  collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
  drop: noop,
};

export default (onDragHover) => {
  const [{ isOver, canDrop }, ref] = useDrop(DROP_CONFIG);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isOver) onDragHover();
    }, 600);

    return () => clearTimeout(timeout);
  }, [isOver, onDragHover]);

  return [ref, canDrop];
};
