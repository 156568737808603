import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  BotLanguageSettingsDocument,
  type LanguageType,
  type LanguageVariantType,
  SkillLanguageSettingsDocument,
} from 'frontend/api/generated';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

type Variant = Pick<LanguageVariantType, 'id' | 'code' | 'name'>;
type Language = Pick<LanguageType, 'id' | 'code' | 'name' | 'active' | 'variants'>;
export type LanguageAugmented = Language & { variantsInWorkspace: Variant[] };
export type VariantAugmented = Variant & { baseLanguageCode: string };

export type LanguageOrVariant = LanguageAugmented | VariantAugmented;

export interface UseLanguagesReturn {
  loading: boolean;
  /** All base languages in the workspace.
   * - `variantsInWorkspace` property lists their variants in the workspace (if any).  */
  languages: LanguageAugmented[];
  languageVariants: LanguageVariantType[];
  /** All languages and variants in the workspace listed together.
   * - `baseLanguageCode` property is only present in variants.
   * - When a base language has variants in the workspace, it will only list his variants and not the base language. */
  languagesAndVariants: LanguageOrVariant[];
  /** A map of base languages codes in the workspace and their full names. */
  languageNameMap: { [key: string]: string };
  /** A map of base languages & variants codes in the workspace and their full names. */
  languageNameMapVariants: { [key: string]: string };
  primaryLanguage: string;
}

function useBotLanguages(buildIdObject?: BuildIdObject): UseLanguagesReturn {
  const { data, loading } = useQuery(BotLanguageSettingsDocument, {
    variables: { botId: buildIdObject!.botId! },
    skip: !buildIdObject,
  });

  const botVariantsCodes = useMemo(() => {
    if (!data?.bot) {
      return [];
    }

    return data.bot.languageVariants.map(({ code }) => code);
  }, [data]);

  const languages: LanguageAugmented[] = useMemo(
    () =>
      ((!loading && data?.bot?.languages) || []).map((language) => {
        const variantsInWorkspace: Variant[] = language?.variants.filter((variant: Variant) =>
          botVariantsCodes.includes(variant.code),
        );

        return {
          ...language,
          variantsInWorkspace,
        };
      }),
    [botVariantsCodes, data, loading],
  );

  const languageVariants = useMemo(() => {
    if (loading) {
      return [];
    }

    return data?.bot?.languageVariants || [];
  }, [data, loading]);

  const languagesAndVariants = useMemo(
    () =>
      languages.flatMap((language) => {
        const finalArray: LanguageOrVariant[] = [];

        if (!language.variantsInWorkspace.length) {
          finalArray.push(language);
        } else if (language.variantsInWorkspace.length) {
          const variants = language.variants.filter((variant) => botVariantsCodes.includes(variant.code));
          const variantsWithBaseLanguage: VariantAugmented[] = variants.map((variant) => ({
            id: variant.id,
            name: variant.name,
            code: variant.code,
            baseLanguageCode: language.code,
          }));
          finalArray.push(...variantsWithBaseLanguage);
        }

        return finalArray;
      }),
    [languages, botVariantsCodes],
  );

  const languageNameMap: { [key: string]: string } = useMemo(
    () =>
      languages.reduce((nameMap, baseLanguage) => {
        const baseLanguageCode = baseLanguage.code;
        nameMap[baseLanguageCode] = baseLanguage.name;
        return nameMap;
      }, {}),
    [languages],
  );

  const languageNameMapVariants: { [key: string]: string } = useMemo(
    () =>
      languagesAndVariants.reduce((nameMap, variantOrBaseLanguage) => {
        const variantOrBaseLanguageCode = variantOrBaseLanguage.code;
        nameMap[variantOrBaseLanguageCode] = variantOrBaseLanguage.name;
        return nameMap;
      }, {}),
    [languagesAndVariants],
  );

  const primaryLanguage = data?.bot?.primaryLanguage || '';

  return {
    loading,
    languages,
    languageVariants,
    languagesAndVariants,
    languageNameMap,
    languageNameMapVariants,
    primaryLanguage,
  };
}

function useSkillLanguages(buildIdObject?: BuildIdObject): UseLanguagesReturn {
  const { data, loading } = useQuery(SkillLanguageSettingsDocument, {
    ...(buildIdObject?.skillId && {
      variables: { skillId: buildIdObject?.skillId as string },
    }),
    skip: !buildIdObject?.skillId,
  });

  const languages: LanguageAugmented[] = useMemo(
    () =>
      ((!loading && data?.skill?.languages) || []).map((language) => ({
        ...language,
        variants: [],
        variantsInWorkspace: [],
        active: true,
      })),
    [data, loading],
  );

  const languageNameMap: { [key: string]: string } = useMemo(
    () =>
      languages.reduce((nameMap, baseLanguage) => {
        const baseLanguageCode = baseLanguage.code;
        nameMap[baseLanguageCode] = baseLanguage.name;
        return nameMap;
      }, {}),
    [languages],
  );

  return {
    loading,
    languages,
    languageVariants: [],
    languagesAndVariants: languages,
    languageNameMap,
    languageNameMapVariants: {},
    primaryLanguage: '',
  };
}

/* NB: all `useMemo`s in this hook are necessary to avoid some weird bugs when creating special and normal dialogues */
/** This is the main custom hook to read languages (and variants) in a Workspace. */
export default function useLanguages(
  { isBot, buildIdObject, buildType }: BotOrSkill,
  { ignoreNoBotOrSkill }: { ignoreNoBotOrSkill?: boolean } = { ignoreNoBotOrSkill: undefined },
): UseLanguagesReturn {
  if (!ignoreNoBotOrSkill && !buildType) {
    console.error('No bot or skill in useLanguages');
  }

  const hook = isBot ? useBotLanguages : useSkillLanguages;

  return hook(buildIdObject);
}
