import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import { fromPairs, toPairs } from 'lodash';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button, Input } from 'frontend/components';
import { required } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';
import PublicLayout from 'frontend/layouts/PublicLayout/PublicLayout';
import { snakeToCamel } from 'frontend/utils';

import styles from './ResetPasswordConfirm.scss';
import loginStyles from '../Login/Login.scss';

const validateForm = ({ newPassword1, newPassword2 }) =>
  newPassword1 !== newPassword2
    ? { newPassword1: 'Passwords do not match', newPassword2: 'Passwords do not match' }
    : undefined;

const ResetPasswordConfirm = () => {
  const toast = useToast();
  const { uidb64, token } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const login = useCallback(
    () => navigate(new URLSearchParams(location.search).get('next')),
    [location.search, navigate],
  );

  const onSubmit = useCallback(
    async ({ newPassword1, newPassword2 }) => {
      let errors;
      const body = JSON.stringify({ uidb64, new_password1: newPassword1, new_password2: newPassword2, token });
      try {
        const response = await fetch(`${window.env.API_URL}/api/v2/accounts/reset_password/confirm/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body,
        });

        if (!response.ok) {
          const payload = await response.json();
          if ('__all__' in payload.errors) {
            toast.error(payload.errors.__all__);
            return { [FORM_ERROR]: payload.errors.__all__.join(' \n') };
          }

          errors = fromPairs(toPairs(payload.errors).map(([key, val]) => [snakeToCamel(key), val.join(' \n')]));
        } else {
          toast.success('Password changed. You can log in with your new password');
        }
      } catch (error) {
        errors = { [FORM_ERROR]: 'API or network error' };
        toast.error('Could not reset password right now, try again later...');
      }
      return errors;
    },
    [toast, token, uidb64],
  );

  return (
    <PublicLayout hideContact>
      <div className={cx(styles.forgotPassword, loginStyles.login)}>
        <Form
          onSubmit={onSubmit}
          validate={(values) => validateForm(values)}
          render={({ handleSubmit, submitting, validating, submitFailed, submitSucceeded, valid }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className={styles.btnContainer}>
                {!submitFailed && submitSucceeded && valid ? (
                  <>
                    <h1>Your password has been updated</h1>
                    <p>You can start using your password right now to login to Kindly.</p>
                    <Button color="primary" className={cx(styles.loginButton, 'm-t-lg')} onClick={login}>
                      Login
                    </Button>
                  </>
                ) : (
                  <>
                    <h1 className="m-b-n">Choose password</h1>
                    <p>Enter your new password in the fields below</p>
                    <Field
                      label="New password"
                      component={Input}
                      className="m-b-sm"
                      name="newPassword1"
                      inputType="password"
                      autoFocus
                      validate={required}
                    />
                    <Field
                      label="Confirm new password"
                      component={Input}
                      className="m-b-md"
                      name="newPassword2"
                      inputType="password"
                      validate={required}
                    />
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      color="primary"
                      disabled={submitting || validating}
                      text="Save"
                      className={styles.submitButton}
                    />
                  </>
                )}
              </div>
            </form>
          )}
        />
      </div>
    </PublicLayout>
  );
};

export default ResetPasswordConfirm;
