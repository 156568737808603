import styles from './styles.scss';
import animationStyles from '../animation.scss';

const SvgLoader = () => (
  <div className={styles.ghostContainer} aria-labelledby="Chat list loading">
    <div className={animationStyles.ghostStripe}>
      <div className={styles.ghostCircle}>
        <svg width="60" height="100">
          <defs>
            <mask id="inboxConversation1" x="0" y="0" width="60" height="100">
              <rect x="0" y="0" width="60" height="100" fill="#fff" />
              <circle cx="20" cy="37" r="20" />
            </mask>
          </defs>
          <rect x="0" y="0" width="60" height="100" mask="url(#inboxConversation1)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>

      <div className={styles.ghostLines}>
        <svg width="100%" height="100px" preserveAspectRatio="none">
          <defs>
            <mask id="inboxConversation2" x="0" y="0" width="100%" height="100">
              <rect x="0" y="0" width="100%" height="100" fill="#FFF" />
              <rect x="0" y="20" rx="2" ry="2" width="100%" height="11" />
              <rect x="0" y="40" rx="2" ry="2" width="100%" height="11" />
              <rect x="0" y="60" rx="2" ry="2" width="60%" height="11" />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="100px" mask="url(#inboxConversation2)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>
    </div>
  </div>
);
const SvgLoader2 = () => (
  <div className={styles.ghostContainer2} aria-labelledby="Chat list loading">
    <div className={animationStyles.ghostStripe}>
      <div className={styles.ghostCircle2}>
        <svg width="60" height="85px">
          <defs>
            <mask id="inboxConversation3" x="0" y="0" width="60" height="85px">
              <rect x="0" y="0" width="60" height="100" fill="#fff" />
              <circle cx="20" cy="37" r="20" />
            </mask>
          </defs>
          <rect x="0" y="0" width="60" height="100" mask="url(#inboxConversation3)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>

      <div className={styles.ghostLines2}>
        <svg width="100%" height="85px" preserveAspectRatio="none">
          <defs>
            <mask id="inboxConversation4" x="0" y="0" width="100%" height="85px">
              <rect x="0" y="0" width="100%" height="100" fill="#FFF" />
              <rect x="0" y="20" rx="2" ry="2" width="100%" height="11" />
              <rect x="0" y="40" rx="2" ry="2" width="60%" height="11" />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="85px" mask="url(#inboxConversation4)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>
    </div>
  </div>
);

const InboxChats = () => (
  <>
    <SvgLoader />
    <SvgLoader2 />
    <SvgLoader />
    <SvgLoader />
  </>
);

export default InboxChats;
