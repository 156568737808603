import { orderBy } from 'lodash';

import { Panel } from 'frontend/components';

import styles from './styles.scss';
import dashboardStyles from '../../views/styles.scss';

const NotificationsList = () => {
  const notifications = [];
  //  TODO: Add notifications list when available in backend

  if (notifications.length === 0) return null;

  return (
    <>
      <div className={dashboardStyles.sectionHeader}>
        <h3>Notifications {notifications && <span>{notifications.length}</span>}</h3>
        <button type="button" className="link">
          Mark all as read
        </button>
      </div>
      <div className={styles.listContainer}>
        {orderBy(notifications, ['updated'], ['desc']).map((chat) => (
          <Panel key={chat.id} size="medium" hover />
        ))}
      </div>
    </>
  );
};

export default NotificationsList;
