import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { softDeleteInCache, updateArrayInCache } from 'frontend/api/cacheHelpers';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { BuildIdObjectType } from 'frontend/propTypes';
import { filterArrayById } from 'frontend/utils';

import { ENTITY } from '../../fragments';
import { DELETE_ENTITY } from '../../mutations';
import { EntityType } from '../../propTypes';
import { ENTITIES } from '../../queries';

const pathToArrayInCache = 'entities';
const query = ENTITIES;
const fragment = ENTITY;
const typeName = 'EntityType';
const mutationName = 'deleteEntity';

const DeleteEntity = ({ hide, args }) => {
  const toast = useToast();
  const {
    entity: { id, name },
    buildIdObject,
  } = args;

  const update = (cache, response) => {
    const arrayUpdate = filterArrayById(id);
    updateArrayInCache({ variables: buildIdObject, pathToArrayInCache, query, cache, arrayUpdate });
    softDeleteInCache({ fragment, typeName, mutationName })(cache, response);
    toast.success('Entity deleted!');
  };

  const [deleteEntity] = useMutation(DELETE_ENTITY, { variables: { ...buildIdObject, id }, update });

  return <DeleteModal titleName={`entity ${name}`} name={`"${name}"`} onSubmit={deleteEntity} hide={hide} />;
};

DeleteEntity.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ entity: EntityType.isRequired, buildIdObject: BuildIdObjectType.isRequired }).isRequired,
};

export default DeleteEntity;
