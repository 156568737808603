import { useCallback, useMemo } from 'react';

import { Plus } from 'frontend/assets/icons';
import { Button, LoaderSwitch } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';

import styles from './KindlyEntities.scss';
import { useKindlyEntities } from '../../hooks';
import { AddKindlyEntities } from '../../modals';
import KindlyEntityList from '../KindlyEntityList';

const KindlyEntities = () => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { isBot, buildIdObject, botId } = useBotOrSkill();
  const [showAddModal] = useModal(AddKindlyEntities);

  const { kindlyEntities, botKindlyEntities, loading } = useKindlyEntities({ buildIdObject, currentLanguage });

  const potentialEntities = useMemo(
    () =>
      isBot ? kindlyEntities.filter(({ id: entityId }) => !botKindlyEntities.find(({ id }) => id === entityId)) : [],
    [botKindlyEntities, isBot, kindlyEntities],
  );

  const onClick = useCallback(() => {
    showAddModal({ kindlyEntities: potentialEntities, botId });
  }, [botId, potentialEntities, showAddModal]);

  return (
    <div className={styles.kindlyEntities}>
      <LoaderSwitch loading={loading} size="large">
        {potentialEntities.length > 0 && (
          <>
            <Button onClick={onClick} icon={Plus} color="primary">
              Add entities
            </Button>
            <br />
            <br />
          </>
        )}
        <KindlyEntityList
          kindlyEntities={isBot ? botKindlyEntities : kindlyEntities}
          withRemove
          withProperties
          noEntities=""
        />
      </LoaderSwitch>
    </div>
  );
};

export default KindlyEntities;
