import { gql } from '@apollo/client';

import USER_DETAIL_FRAGMENT from '../../fragments/UserDetail.fragment.graphql';

export const ADD_USER_TO_ORGANIZATION = gql`
  mutation AddUserToOrganization($userId: ID!, $organizationId: ID!) {
    addUserToOrganization(userId: $userId, organizationId: $organizationId) {
      ...UserDetail
    }
  }
  ${USER_DETAIL_FRAGMENT}
`;
