import { orderBy } from 'lodash';

import getInitialSpaces from './getInitialSpaces';
import getItemName from './getItemName';
import massageText from './massageText';

export default (editorText, editorInstances, { trimText, lowerCaseText } = {}) => {
  let initialText = editorText || '';
  const initialSpaces = trimText ? getInitialSpaces(editorText || '') : 0;
  initialText = massageText(initialText, { trimText, lowerCaseText });

  return orderBy(editorInstances, 'start').reduce(
    ({ text, instances, lengthChange }, editorInstance) => {
      const { start, original } = editorInstance;
      const name = getItemName(editorInstance);

      if (!name) return { text, instances, lengthChange };

      const entityStart = start + lengthChange;
      const entityEnd = entityStart + name.length;
      const formEntityEnd = entityStart + original.length;

      const updatedText = `${text.slice(0, entityStart)}${original}${text.slice(entityEnd)}`;
      const updatedInstances = [...instances, { ...editorInstance, start: entityStart, end: formEntityEnd }];

      const newLengthChange = lengthChange + (updatedText.length - text.length);

      return { text: updatedText, instances: updatedInstances, lengthChange: newLengthChange };
    },
    {
      text: initialText,
      instances: [],
      lengthChange: -initialSpaces,
    },
  );
};
