import { gql } from '@apollo/client';

export default gql`
  fragment Demo on DemoType {
    id
    colorBackground
    image
    slug
    includeDisabledLanguages
    websiteUrl
    isBackgroundCover
  }
`;
