import sanitizeHTML from 'frontend/utils/sanitizeHTML';

interface RenderHTMLProps {
  /** HTML to display */
  html: string;
  className?: string;
}

/** Wrapper component to display sanitized HTML. */
const RenderHTML = ({ html, className }: RenderHTMLProps) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: sanitizeHTML(html) }} />
);

export default RenderHTML;
