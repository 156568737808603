import filterByLanguage from './filterByLanguage';

export default (currentLanguage, dialogue = {}) => ({
  ...dialogue,
  buttons: filterByLanguage(currentLanguage, dialogue.buttons),
  imageCarousels: filterByLanguage(currentLanguage, dialogue.imageCarousels),
  ...(dialogue.modButtons ? { modButtons: filterByLanguage(currentLanguage, dialogue.modButtons) } : {}),
  ...(dialogue.modImageCarousels?.length
    ? { modImageCarousels: filterByLanguage(currentLanguage, dialogue.modImageCarousels) }
    : {}),
  buildForms: filterByLanguage(currentLanguage, dialogue.buildForms),
});
