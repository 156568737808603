import { DeleteModal } from 'frontend/features/Modals';

interface DeleteChatProps {
  hide: () => void;
  args: {
    userName: string;
    deleteChat: () => void;
  };
}

const DeleteChat = ({ hide, args: { userName, deleteChat } }: DeleteChatProps) => (
  <DeleteModal
    onSubmit={() => {
      deleteChat();
      hide();
    }}
    hide={hide}
    titleName="chat"
    name={`this chat with ${userName}`}
  />
);

export default DeleteChat;
