export default () =>
  ({ chatId, label, labels, type }) => ({
    variables: {
      chatId,
      labelId: label.id,
    },
    optimisticResponse: {
      [type]: {
        __typename: 'ChatType',
        id: chatId,
        labels,
      },
    },
  });
