import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { Input } from 'frontend/components';
import { DeleteModal, ModalForm } from 'frontend/features/Modals';
import { chain, integer, required } from 'frontend/form/validators';

const validate = chain(required, integer);

const PreferredHandoverApp = ({ hide, args: { onSubmit, initialValues, remove } }) => {
  const removeId = useCallback(async () => {
    await onSubmit({ ...initialValues, handoverAppId: null });
  }, [initialValues, onSubmit]);

  if (remove) {
    return (
      <DeleteModal
        onSubmit={removeId}
        hide={hide}
        name="the preferred secondary handover app ID"
        titleName="handover app ID"
        action="remove"
        hideWarning
      />
    );
  }

  return (
    <ModalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      hide={hide}
      title="Preferred secondary handover app id"
      showFormErrors={false}
    >
      <Field component={Input} name="handoverAppId" aria-label="App id" placeholder="App id" validate={validate} />
    </ModalForm>
  );
};

PreferredHandoverApp.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({}).isRequired,
    remove: PropTypes.bool,
  }).isRequired,
};

export default PreferredHandoverApp;
