import { captureException } from '@sentry/browser';
import { Component } from 'react';

import { toastError } from 'frontend/features/Toast';

import ErrorStack from './ErrorStack';
import ErrorState from './ErrorState';

const GENERIC_ERROR_MESSAGE = 'Something went wrong, please try again later...';
let previousPathname;

interface ErrorBoundaryProps {
  children: React.ReactNode;
  /** Pass a custom `<ErrorComponent>` to render in the UI. */
  errorComponent?: React.ReactNode;
  /** If true, render the error in the UI.  */
  renderErrorView?: boolean;
  /** If true (true by default), report the `error` to Sentry and Toast it in the UI.  */
  reportError?: boolean;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

const initialState = { error: null, errorInfo: null };

class ErrorBoundary extends Component<
  React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps>>,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  static getDerivedStateFromProps(_props, state) {
    if (state.error && window.location.pathname !== previousPathname) {
      return initialState;
    }
    previousPathname = window.location?.pathname;
    return state;
  }

  componentDidCatch(error, errorInfo) {
    const { error: stateError } = this.state;
    if (stateError) {
      return;
    }
    this.setState({ error, errorInfo });
    const { reportError = true } = this.props;
    if (reportError && window.env.SENTRY_DSN_FRONTEND) {
      const sentryId = captureException(error);
      toastError(GENERIC_ERROR_MESSAGE, { sentryId });
    }
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children, errorComponent, renderErrorView = false } = this.props;
    if (!error) return children;

    if (errorComponent) return errorComponent;

    if (!renderErrorView) return null;

    if (window.env.NODE_ENV === 'development') {
      return <ErrorStack error={error} errorInfo={errorInfo} />;
    }
    return <ErrorState />;
  }
}

export default ErrorBoundary;
