import emojiHappy from 'frontend/assets/images/emoji-happy.png';
import emojiNeutral from 'frontend/assets/images/emoji-neutral.png';
import emojiSad from 'frontend/assets/images/emoji-sad.png';
import happy from 'frontend/assets/images/happy-or-not-happy.svg?url';
import unhappy from 'frontend/assets/images/happy-or-not-not-happy.svg?url';
import veryHappy from 'frontend/assets/images/happy-or-not-very-happy.svg?url';
import veryUnhappy from 'frontend/assets/images/happy-or-not-very-not-happy.svg?url';

export const numberToEmoji = {
  1: { src: emojiSad, description: 'sad face' },
  2: { src: emojiNeutral, description: 'neutral face' },
  3: { src: emojiHappy, description: 'happy face' },
};

export const numberToBinary = {
  1: { description: 'No' },
  2: { description: 'Yes' },
};

export const numberToHappyOrNot = {
  1: { url: veryUnhappy, description: 'very unhappy face' },
  2: { url: unhappy, description: 'unhappy face' },
  3: { url: happy, description: 'happy face' },
  4: { url: veryHappy, description: 'very happy face' },
};

export const typeToMapper = {
  EMOJIS: numberToEmoji,
  BINARY: numberToBinary,
  HAPPY_OR_NOT: numberToHappyOrNot,
};

export default Object.freeze({
  NONE: 'NONE',
  BINARY: 'BINARY',
  EMOJIS: 'EMOJIS',
  HAPPY_OR_NOT: 'HAPPY_OR_NOT',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  NO_FEEDBACK: 'NO_FEEDBACK',
});
