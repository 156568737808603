import { gql } from '@apollo/client';

export default gql`
  fragment SampleCandidate on SampleCandidateType {
    id
    text
    languageCode
    sample {
      id
      createdAt
      dialogue {
        id
      }
    }
    topSuggestion {
      id
      dialogue {
        id
      }
    }
    score
  }
`;
