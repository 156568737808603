import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

const MessageFields = {
  id: IDType.isRequired,
  message: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  chatId: IDType.isRequired,
};

export const MessageRecordType = PropTypes.exact({
  ...MessageFields,
  duplicates: PropTypes.arrayOf(PropTypes.exact(MessageFields)).isRequired,
});

export const SalientTokensType = PropTypes.objectOf(PropTypes.number);
