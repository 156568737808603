import { capitalize, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { useToggle } from 'frontend/hooks';
import { BuildIdObjectType } from 'frontend/propTypes';

import { systemDialogueCategories, systemDialogueMessageTypes } from '../../constants';
import { useIndentationStyle } from '../../hooks';
import { SystemDialogueType } from '../../propTypes';
import { getFolderIconClassnames } from '../../utils';
import DialogueList from '../DialogueList';
import LibraryFolder from '../LibraryFolder';

const messageTypeSorting = ({ messageType }) => {
  switch (messageType) {
    case systemDialogueMessageTypes.REQUEST:
    case systemDialogueMessageTypes.DELETE_CHAT:
    case systemDialogueMessageTypes.REPLY:
      return 0;
    case systemDialogueMessageTypes.CLOSED:
    case systemDialogueMessageTypes.FALLBACK:
      return 1;
    case systemDialogueMessageTypes.CANCEL:
      return 2;
    case systemDialogueMessageTypes.WAITING_FALLBACK:
      return 3;
    case systemDialogueMessageTypes.START:
      return 4;
    case systemDialogueMessageTypes.END:
      return 5;
    default:
      return 6;
  }
};

const InnerFolder = ({ category, dialogues, selectedLanguage, buildIdObject, indentationLevel }) => {
  const [open, toggleOpen] = useToggle(true);
  const indentationStyle = useIndentationStyle(indentationLevel);
  const sorting = useCallback((dialogueList) => orderBy(dialogueList, messageTypeSorting), []);
  const titleClassName = getFolderIconClassnames(open);
  // FIXME(legacy-takeover): Make backend use "HANDOVER" instead of "TAKEOVER"
  const categoryRenameHandover = category === 'TAKEOVER' ? 'HANDOVER' : category;

  return (
    <>
      <LibraryFolder toggleOpen={toggleOpen}>
        <span className={titleClassName} style={indentationStyle}>
          {capitalize(categoryRenameHandover)}
        </span>
      </LibraryFolder>
      {open && (
        <DialogueList
          dialogues={dialogues}
          selectedLanguage={selectedLanguage}
          buildIdObject={buildIdObject}
          sorting={sorting}
          indentationLevel={indentationLevel + 1}
        />
      )}
    </>
  );
};

InnerFolder.propTypes = {
  category: PropTypes.oneOf(Object.values(systemDialogueCategories)).isRequired,
  dialogues: PropTypes.arrayOf(SystemDialogueType).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  buildIdObject: BuildIdObjectType.isRequired,
  indentationLevel: PropTypes.number.isRequired,
};

export default InnerFolder;
