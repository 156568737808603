import { useMutation, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { ChevronLeft } from 'frontend/assets/icons';
import { Button, Input, Loader, Table } from 'frontend/components';
import { NotFound } from 'frontend/features';
import { required } from 'frontend/form/validators';
import { useQueryParams, useToast } from 'frontend/hooks';

import { CREATE_EVALUATION_QUERY, GET_EVALUATION, GET_EVALUATION_DIALOGUES } from '../graphql';

const TestFormColumn = ({ rowData: eligible, columnData: { onSubmit } }) => (
  <Form
    onSubmit={async (values, form) => {
      await onSubmit({ dialogueId: eligible.id, userSays: values[`userSays-${eligible.id}`] });
      setTimeout(form.reset);
    }}
    render={({ handleSubmit }) => (
      <>
        <Field
          name={`userSays-${eligible.id}`}
          component={Input}
          aria-label="User says"
          placeholder="Test query"
          validate={required}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSubmit();
          }}
        />
        <Button onClick={handleSubmit} text="Add" className="m-l-sm" tabIndex={-1} />
      </>
    )}
  />
);

const EvaluationSetUpdate = () => {
  const { botId, evaluationId } = useParams();
  const queryParams = useQueryParams();
  const folderId = queryParams.get('folderId');
  const toast = useToast();

  const { data, loading } = useQuery(GET_EVALUATION, { variables: { evaluationId } });
  const { data: dialogueData, loading: dialogueLoading } = useQuery(GET_EVALUATION_DIALOGUES, {
    variables: { languageCode: data?.evaluation?.languageCode, botId, folderId },
    skip: loading,
  });
  const [createQueryMutation] = useMutation(CREATE_EVALUATION_QUERY);

  const columns = useMemo(
    () => [
      { key: 'title' },
      {
        title: 'Random sample',
        key: 'samples',
        render: ({ data: samples }) => samples[Math.floor(Math.random() * samples.length)],
      },
      { key: 'reply' },
      {
        key: 'tests',
        render: TestFormColumn,
        data: {
          onSubmit: async ({ dialogueId, userSays }) => {
            await createQueryMutation({ variables: { botId, evaluationId, dialogueId, userSays } });
            toast.success('Test query added');
          },
        },
      },
    ],
    [botId, evaluationId, createQueryMutation, toast],
  );
  if (loading || dialogueLoading) {
    return <Loader size="large" />;
  }

  const { title, totalQueries, languageCode } = data.evaluation;

  const tableData = dialogueData.eligibleDialogues;

  if (!tableData.length) {
    return <NotFound text={`No sample dialogues found for language ${languageCode}`} />;
  }

  return (
    <>
      <h1 className="m-b-0">Evaluation set: {title}</h1>
      <p className="m-t-0 m-b">
        Language: <strong>{languageCode}</strong>. Total queries <strong>{totalQueries}</strong>
      </p>
      <div className="m-t-md">
        <Button
          flat
          icon={ChevronLeft}
          to={`/workspace/${botId}/build/evaluation${folderId ? `?folderId=${folderId}` : ''}`}
        >
          Back
        </Button>
        <Button className="m-l-md" to={`/workspace/${botId}/build/evaluation/${evaluationId}/reports`}>
          View reports
        </Button>
      </div>

      <Table data={tableData} columns={columns} className="m-t-lg" />
    </>
  );
};

export default EvaluationSetUpdate;
