import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useEventListener } from 'frontend/hooks';
import { setLibraryWidth } from 'frontend/state/dux/library';
import { useAppSelector } from 'frontend/state/hooks';

// Minimun width to always show the resize-line
const MIN_WIDTH = 296;

let startingPosition = 0;
let startingWidth = 0;
let currentWidth = 0;
let isResizing = false;

// 0.7 is vw from styles (70vw)
const getMaxWidthOfPanel = () => window.document.documentElement.clientWidth * 0.7;
const getWidthOrMinWidth = (width: number) => (width > getMaxWidthOfPanel() ? MIN_WIDTH : width || MIN_WIDTH);

export default () => {
  const dispatch = useDispatch();
  const { width } = useAppSelector(({ library }) => library);
  const panelRef = useRef<HTMLDivElement>(null);

  const onMouseDown = useCallback(
    (event) => {
      if (!isResizing) isResizing = true;
      startingPosition = event.clientX;
      startingWidth = getWidthOrMinWidth(width);
    },
    [width],
  );

  const onMouseUp = useCallback(() => {
    if (currentWidth > MIN_WIDTH) {
      dispatch(setLibraryWidth(currentWidth));
    }
    isResizing = false;
  }, [dispatch]);

  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.style.width = `${getWidthOrMinWidth(width)}px`;
    }
  }, [width, panelRef]);

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (panelRef.current && isResizing) {
        const margins = startingPosition - startingWidth;
        const newWidth = event.clientX - margins;
        if (newWidth > MIN_WIDTH && newWidth < getMaxWidthOfPanel()) {
          currentWidth = newWidth;
          panelRef.current.style.width = `${currentWidth}px`;
        }
      }
    },
    [panelRef],
  );

  useEventListener('mousemove', onMouseMove, document);
  useEventListener('mouseup', onMouseUp, document);

  useEffect(
    // Cleanup of the library resize
    () => () => {
      startingPosition = 0;
      startingWidth = 0;
      isResizing = false;
      currentWidth = 0;
    },
    [],
  );

  return { onMouseDown, setPanelRef: panelRef };
};
