import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import { Input, UrlInput } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { InputMetaType, InputType } from 'frontend/propTypes';

import { BUTTON_TYPES } from '../../constants';

const ButtonField = ({
  input,
  meta,
  readOnly,
  ariaLabel,
  placeholder,
  autoFocus,
  localErrors,
  localSubmitFailed,
  onKeyDown,
  buttonType,
  label,
  inputType = 'text',
  multiline = false,
}) => {
  const ref = useRef();
  const fieldName = input.name.split('.').splice(-1)[0];
  const isExternalLink = fieldName === 'value' && buttonType === BUTTON_TYPES.EXTERNAL_LINK;

  const fieldMeta = useMemo(
    () => (localSubmitFailed ? { error: get(localErrors, fieldName), submitFailed: true } : meta),
    [fieldName, localErrors, localSubmitFailed, meta],
  );

  useEffect(() => {
    if (autoFocus && ref.current) ref.current.focus();
  }, [autoFocus]);

  const Component = isExternalLink ? UrlInput : Input;

  return (
    <Component
      readOnly={readOnly}
      aria-label={ariaLabel}
      placeholder={placeholder}
      autoFocus={autoFocus}
      input={input}
      meta={fieldMeta}
      onKeyDown={onKeyDown}
      ref={isExternalLink ? undefined : ref}
      label={label}
      inputType={inputType}
      multiline={multiline}
      fieldColor={FIELD_COLOR.MISCHKA}
    />
  );
};

ButtonField.propTypes = {
  input: InputType.isRequired,
  meta: InputMetaType.isRequired,
  readOnly: PropTypes.bool,
  ariaLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
  localErrors: PropTypes.shape({}).isRequired,
  localSubmitFailed: PropTypes.bool.isRequired,
  buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  multiline: PropTypes.bool,
  inputType: PropTypes.oneOf(['text', 'number', 'email', 'url', 'radio', 'checkbox']), // List the rest when needed
};

export default ButtonField;
