import { updateArrayInCache } from 'frontend/api/cacheHelpers';
import { CHAT_META } from 'frontend/features/Inbox/queries';

export default () =>
  ({ chatId, label, labels, type }) => ({
    variables: {
      chatId,
      labelId: label.id,
    },
    optimisticResponse: {
      [type]: {
        __typename: 'ChatType',
        id: chatId,
        labels,
      },
    },
    update: (cache, result) => {
      const arrayUpdate = () => [...result.data[type].labels];

      updateArrayInCache({
        variables: { chatId },
        pathToArrayInCache: 'chatAndBot.chat.labels',
        query: CHAT_META,
        cache,
        arrayUpdate,
      });
    },
  });
