import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Search } from 'frontend/components';
import { DropdownList, TagItemType } from 'frontend/features/Composer';
import { EntitySearch } from 'frontend/features/Entities/utils';
import { useClickOutside } from 'frontend/hooks';
import { RefType } from 'frontend/propTypes';

import styles from './SlotsDropdown.scss';

const SlotsDropdown = ({ extra, ready, addTag, close, dropdownRef }) => {
  const { loading, items } = extra;
  const [searchResults, setSearchResults] = useState();

  useEffect(() => {
    if (!loading) ready();
  }, [loading, ready]);

  useClickOutside(dropdownRef, close);

  return (
    <>
      <Search
        className={styles.search}
        searchItems={items}
        setSearchResults={setSearchResults}
        wrapper={EntitySearch.wrapper}
        searchOptions={EntitySearch.options}
        placeholder="Search"
      />
      <DropdownList items={searchResults || items} addTag={addTag} />
    </>
  );
};

SlotsDropdown.propTypes = {
  ready: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  dropdownRef: RefType.isRequired,
  extra: PropTypes.exact({
    loading: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(TagItemType).isRequired,
  }).isRequired,
};

export default SlotsDropdown;
