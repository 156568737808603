import cx from 'classnames';
import { useState } from 'react';
import type { FieldRenderProps } from 'react-final-form';
import TextareaAutosize from 'react-textarea-autosize';

import InfoBox from 'frontend/components/InfoBox/InfoBox';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import { useMarkdownToHtml } from 'frontend/hooks';

import styles from './SimpleTextEditor.scss';
import tabStyles from '../Tabs/Tabs.scss';

type EditorMode = 'edit' | 'preview';

interface PreviewProps {
  value?: string;
}

const Preview = ({ value = '' }: PreviewProps) => {
  const { html } = useMarkdownToHtml(value);
  return <div className={styles.preview} dangerouslySetInnerHTML={{ __html: html }} />;
};

interface SimpleTextEditorProps extends FieldRenderProps<string> {
  /** Text Editor label. */
  label: string;
  /** Whether the preview tab will be shown. */
  preview?: boolean;
  /** Textarea placeholder. */
  placeholder?: string;
}

/** Simple text area that has an optional Preview tab to display rendered Markdown. */
const SimpleTextEditor = ({
  input: { name, value, onChange = () => {} },
  label,
  preview = true,
  placeholder = '',
}: SimpleTextEditorProps) => {
  const [editorMode, setMode] = useState<EditorMode>('edit');
  return (
    <div className={styles.wrap}>
      <label>{label}</label>
      {preview && (
        <div>
          <button
            type="button"
            role="tab"
            onClick={() => setMode('edit')}
            className={cx(tabStyles.tabNotebook, tabStyles.tab, {
              [tabStyles.tabSelected]: editorMode === 'edit',
            })}
          >
            Edit
          </button>
          <button
            type="button"
            role="tab"
            onClick={() => setMode('preview')}
            className={cx(tabStyles.tabNotebook, tabStyles.tab, {
              [tabStyles.tabSelected]: editorMode === 'preview',
            })}
          >
            Preview
          </button>
        </div>
      )}
      <div>
        {editorMode === 'edit' && (
          <TextareaAutosize
            name={name}
            onChange={onChange}
            value={value}
            className={styles.editor}
            style={{}}
            minRows={5}
            placeholder={placeholder}
          />
        )}
        {editorMode === 'preview' && <Preview value={value} />}
      </div>

      <div className={styles.info}>
        <InfoBox title="Advanced styling">
          This field can be styled using markdown formatting.&nbsp;
          <HelpCenterLink path="/advanced-styling">Click here to read more</HelpCenterLink>.
        </InfoBox>
      </div>
    </div>
  );
};
export default SimpleTextEditor;
