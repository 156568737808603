import React, { type ComponentType, useContext } from 'react';
import { Field, type FieldRenderProps } from 'react-final-form';

import { Info } from 'frontend/assets/icons';
import { Icon, Input, Select, Tooltip } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';

import styles from './FieldInput.scss';
import { FieldContext } from '../../context/FieldContext';
import type { FieldConfigFieldType, FieldsConfigType } from '../../utils/configs';
import { validateField } from '../../utils/validation';

const getLabel = (property, index) => {
  if (property.label) {
    if (property.tooltip) {
      return (
        <div className={styles.tooltipContainer}>
          {typeof property.label === 'function' ? property.label(index) : property.label}{' '}
          <Tooltip className={styles.fieldTooltip}>
            <Icon component={Info} />
            <Tooltip.Body>{property.tooltip}</Tooltip.Body>
          </Tooltip>
        </div>
      );
    }

    return typeof property.label === 'function' ? property.label(index) : property.label;
  }
  return undefined;
};

interface FieldInput {
  fieldIndex?: number;
  groupedProperty?: Partial<FieldConfigFieldType>;
  propertyKey: keyof FieldsConfigType;
  property: FieldConfigFieldType;
}

const FieldInput = ({ fieldIndex = 0, groupedProperty, property, propertyKey }: FieldInput) => {
  const { field, baseFieldName } = useContext(FieldContext);

  const properties = groupedProperty || property;

  const component = (properties.type === 'select' ? Select : Input) as ComponentType<
    FieldRenderProps<any, HTMLElement, string | number>
  >;

  if (!properties.getName) throw new Error('Failed to render input. getName does not exist in config.');

  return (
    <Field
      component={component}
      parse={properties.identity}
      type={properties.type}
      defaultValue={field[groupedProperty?.key || propertyKey]}
      placeholder={properties.placeholderText}
      label={getLabel(properties, fieldIndex)}
      name={properties.getName({
        key: groupedProperty?.key || propertyKey,
        index: fieldIndex,
        fieldName: baseFieldName,
        field,
      })}
      validate={(val) => (properties.validation ? validateField(val, properties.validation, field) : null)}
      fieldColor={FIELD_COLOR.MISCHKA}
    >
      {properties.options?.map((option) => (
        <Select.Option
          key={`form-input-select-${propertyKey}-${option.value}`}
          value={option.value}
          label={option.label}
        />
      ))}
    </Field>
  );
};

export default FieldInput;
