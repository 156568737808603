import { useQuery } from '@apollo/client';
import { get } from 'lodash/fp';
import { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';

import { Input, LoaderSwitch } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { chain, max, slugValidator } from 'frontend/form/validators';
import { useBotOrSkill } from 'frontend/hooks';

import { GetAllSlugs } from '../../graphql';

interface Props {
  isModDialogue?: boolean;
  dialogueId?: string;
}

const Triggers = ({ isModDialogue, dialogueId }: Props) => {
  const { submitting: disabled } = useFormState();
  const { buildIdObject } = useBotOrSkill();
  const { data, loading } = useQuery(GetAllSlugs, { variables: buildIdObject });

  const validate = useMemo(() => {
    const otherSlugs = (data?.dialogues || []).filter(({ id }) => id !== dialogueId).map(get('slug'));
    return chain(slugValidator(otherSlugs), max(64));
  }, [data?.dialogues, dialogueId]);

  return (
    <LoaderSwitch size="large" loading={loading}>
      <h4>Trigger dialogue by API</h4>
      <p className="m-b-md">
        This bot reply can not be triggered directly by the user. Your own integration can trigger it via{' '}
        <a href="https://docs.kindly.ai/webhooks" target="_blank" rel="noopener noreferrer">
          webhooks
        </a>
        {' or '}
        <a href="https://docs.kindly.ai/api/application" target="_blank" rel="noopener noreferrer">
          the Application API
        </a>
        .
      </p>
      <strong className="m-b-sm" style={{ display: 'inline-block' }}>
        Dialogue ID
      </strong>
      <Field
        name="id"
        aria-label="Id"
        component={Input}
        placeholder="You must save the dialogue before you will be able to trigger it."
        className="m-b-md"
        readOnly
        fieldColor={FIELD_COLOR.MISCHKA}
      />
      <strong className="m-b-sm" style={{ display: 'inline-block' }}>
        Dialogue slug (optional)
      </strong>
      <Field
        component={Input}
        name="slug"
        aria-label="Slug"
        placeholder="e.g. lower_case_with_underscores"
        inputLimit={64}
        validate={validate}
        readOnly={isModDialogue || disabled}
        fieldColor={FIELD_COLOR.MISCHKA}
      />
    </LoaderSwitch>
  );
};

export default Triggers;
