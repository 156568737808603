import { useMemo, useState } from 'react';

import useChannels from 'frontend/components/NewFilters/hooks/useChannels';
import { sourceData } from 'frontend/state/dux/chatSources';
import { CHAT_SOURCE } from 'frontend/types/chat';

const filterSupportedSources = (hasApplications, hasMessenger) => {
  const sources = sourceData.map((source) => {
    if (Array.isArray(source.value)) {
      let filteredSource = source.value;
      if (!hasApplications) {
        filteredSource = filteredSource.filter((subSource) => subSource.value !== CHAT_SOURCE.CUSTOM_INTEGRATION);
      }

      if (!hasMessenger) {
        filteredSource = filteredSource.filter((subSource) => subSource.value !== CHAT_SOURCE.FB_MESSENGER);
      }

      return {
        label: source.label,
        value: filteredSource,
      };
    }

    return source;
  });

  return sources;
};

export default function useGroupedFilterSource({ sourceFilters }) {
  const filtersCopy = useMemo(() => [...sourceFilters], [sourceFilters]);

  const { hasMessenger, hasApplications, loading: channelsLoading } = useChannels();

  const supportedSources = filterSupportedSources(hasApplications, hasMessenger);

  const getInitialState = () => {
    const initialState: string[] = [];

    supportedSources.forEach((source) => {
      if (Array.isArray(source.value)) {
        const flattenSubSourceValue: string[] = source.value.map((subSource) => subSource.value);
        const intersection = filtersCopy.filter((el) => flattenSubSourceValue.includes(el));

        if (intersection.length === flattenSubSourceValue.length) {
          initialState.push(source.label);
        }
      }
    });

    return initialState;
  };

  const [sourcesGroupChecked, setSourcesGroupChecked] = useState<string[]>(getInitialState());

  const manageSourceHeaders = (subsource: string) => {
    const filterIndex = filtersCopy.findIndex((fCopy) => fCopy === subsource);

    if (!supportedSources.some((source) => source.value === subsource)) {
      supportedSources.forEach((source) => {
        if (Array.isArray(source.value)) {
          const flattenedSubSources: string[] = source.value.map((subSource) => subSource.value);

          if (flattenedSubSources.includes(subsource) && filterIndex >= 0) {
            filtersCopy.splice(filterIndex, 1);
            const intersection = filtersCopy.filter((el) => flattenedSubSources.includes(el));

            /* Unchecking the header of the source filters when at least one of its subsources is unchecked */
            if (intersection.length !== flattenedSubSources.length) {
              setSourcesGroupChecked((current) => current.filter((label) => label !== source.label));
            }
          } else if (!sourcesGroupChecked.includes(source.label) && flattenedSubSources.includes(subsource)) {
            filtersCopy.push(subsource);
            const intersection = filtersCopy.filter((el) => flattenedSubSources.includes(el));

            /* Checking the header of the source filters if all of its subsources are checked */
            if (intersection.length === flattenedSubSources.length) {
              setSourcesGroupChecked([...sourcesGroupChecked, source.label]);
            }
          }
        }
      });
    }
  };

  return {
    sourcesGroupChecked,
    setSourcesGroupChecked,
    manageSourceHeaders,
    sourceData: supportedSources,
    channelsLoading,
  };
}
