import React from 'react';
import { useField, useForm } from 'react-final-form';

import { Checkbox } from 'frontend/components';
import { useGroupedSourcesFilter } from 'frontend/hooks';

interface SourcesFilterProps {
  sourceKey: string;
}

const SourcesFilter = ({ sourceKey }: SourcesFilterProps) => {
  const { change } = useForm();

  const {
    input: { value: sourceFilters },
  } = useField(sourceKey);

  const { sourceData, channelsLoading } = useGroupedSourcesFilter({
    sourceFilters: sourceFilters.length > 0 ? sourceFilters : [],
  });

  if (channelsLoading) return null;

  const sources = sourceData.flatMap(({ label, value }) => {
    if (Array.isArray(value)) {
      return value.flatMap((item) => item);
    }
    return { label, value };
  });

  return (
    <>
      {sources.map((item) => (
        <Checkbox
          key={item.value}
          input={{
            name: item.value,
            checked: sourceFilters.includes(item.value),
          }}
          onClick={() => {
            const valueCopy = [...sourceFilters];
            if (valueCopy.includes(item.value)) {
              valueCopy.splice(valueCopy.indexOf(item.value), 1);
            } else {
              valueCopy.push(item.value);
            }
            change(sourceKey, valueCopy);
          }}
          label={item.label}
        />
      ))}
    </>
  );
};

export default SourcesFilter;
