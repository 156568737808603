import { useMutation } from '@apollo/client';

import { DownloadChatDocument } from 'frontend/api/generated';

export default ({ botId, chatId }) => {
  const [downloadChat] = useMutation(DownloadChatDocument, {
    variables: {
      botId,
      chatId,
    },
    update: (_, { data }) => {
      const url = data?.downloadChat?.url;
      window.location.replace(`${window.env.API_URL}${url}`);
    },
  });

  return downloadChat;
};
