import { gql } from '@apollo/client';

import { sampleFragment } from 'frontend/api/fragments';

export default gql`
  mutation AddSampleToDialogue(
    $botId: ID!
    $dialogueId: UUID!
    $text: String!
    $languageCode: String!
    $sampleCandidateId: UUID!
  ) {
    addSampleToDialogue(
      botId: $botId
      dialogueId: $dialogueId
      text: $text
      languageCode: $languageCode
      sampleCandidateId: $sampleCandidateId
    ) {
      ...Sample
    }
  }
  ${sampleFragment}
`;
