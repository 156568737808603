// There's a bug in final-form-arrays or react-final-form arrays where 'push' and 'remove'
// are called with outdated 'name' (possibly related to stale props in react hooks?).

// Until this is fixed we instead use 'pushFixed' and 'removeFixed', which are given
// the correct 'name' explicitly and otherwise are equal to the original versions:
// https://github.com/final-form/final-form-arrays/blob/master/src/push.js and
// https://github.com/final-form/final-form-arrays/blob/master/src/remove.js

// TODO: Remove this fix once final-form-arrays is updated

function moveFieldState(state, source, destKey, oldState) {
  state.fields[destKey] = {
    ...source,
    name: destKey,
    // prevent functions from being overwritten
    // if the state.fields[destKey] does not exist, it will be created
    // when that field gets registered, with its own change/blur/focus callbacks
    change: oldState.fields[destKey] && oldState.fields[destKey].change,
    blur: oldState.fields[destKey] && oldState.fields[destKey].blur,
    focus: oldState.fields[destKey] && oldState.fields[destKey].focus,
    lastFieldState: undefined, // clearing lastFieldState forces renotification
  };
}

export default (mutators) => ({
  ...mutators,
  pushFixed: ([, name, value], state, { changeValue }) =>
    changeValue(state, name, (array) => (array ? [...array, value] : [value])),
  moveFixed: ([, name, from, to], state, { changeValue }) => {
    if (from === to) {
      return;
    }
    changeValue(state, name, (array) => {
      const copy = [...(array || [])];
      const value = copy[from];
      copy.splice(from, 1);
      copy.splice(to, 0, value);
      return copy;
    });

    const fromPrefix = `${name}[${from}]`;
    Object.keys(state.fields).forEach((key) => {
      if (key.substring(0, fromPrefix.length) === fromPrefix) {
        const suffix = key.substring(fromPrefix.length);
        const fromKey = fromPrefix + suffix;
        const backup = state.fields[fromKey];
        if (from < to) {
          // moving to a higher index
          // decrement all indices between from and to
          for (let i = from; i < to; i++) {
            const destKey = `${name}[${i}]${suffix}`;
            moveFieldState(state, state.fields[`${name}[${i + 1}]${suffix}`], destKey);
          }
        } else {
          // moving to a lower index
          // increment all indices between to and from
          for (let i = from; i > to; i--) {
            const destKey = `${name}[${i}]${suffix}`;
            moveFieldState(state, state.fields[`${name}[${i - 1}]${suffix}`], destKey);
          }
        }
        const toKey = `${name}[${to}]${suffix}`;
        moveFieldState(state, backup, toKey);
      }
    });
  },
  removeFixed: ([, name, index], state, { changeValue }) => {
    let returnValue;
    changeValue(state, name, (array) => {
      const copy = [...(array || [])];
      returnValue = copy[index];
      copy.splice(index, 1);
      return copy;
    });
    // now we have to remove any subfields for our index,
    // and decrement all higher indexes.
    const pattern = new RegExp(`^${name}\\[(\\d+)\\](.*)`);
    const backup = { ...state, fields: { ...state.fields } };
    Object.keys(state.fields).forEach((key) => {
      const tokens = pattern.exec(key);
      if (tokens) {
        const fieldIndex = Number(tokens[1]);
        if (fieldIndex === index) {
          // delete any subfields for this array item
          delete state.fields[key];
        } else if (fieldIndex > index) {
          // shift all higher ones down
          delete state.fields[key];
          const decrementedKey = `${name}[${fieldIndex - 1}]${tokens[2]}`;
          moveFieldState(state, backup.fields[key], decrementedKey, backup);
        }
      }
    });
    return returnValue;
  },
});
