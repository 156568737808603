import { Field, useFormState } from 'react-final-form';

import { Exclamation } from 'frontend/assets/icons';
import { Checkbox, Icon } from 'frontend/components';
import { DEFAULT_RULE_ID } from 'frontend/features/Build/constants';
import useCurrentRuleId from 'frontend/features/Build/hooks/useCurrentRuleId';

import styles from './Settings.scss';

const activeInLanguage =
  (currentLanguage) =>
  ({ isActive, languageCode }) =>
    isActive && languageCode === currentLanguage;

interface Props {
  isModDialogue: boolean;
  currentLanguage: string;
}

export default function Settings({ isModDialogue, currentLanguage }: Props) {
  const { submitting: disabled, values } = useFormState();
  const ruleId = useCurrentRuleId() || DEFAULT_RULE_ID;

  const hasButtons = (values.buttons ?? []).filter(activeInLanguage(currentLanguage)).length > 0;
  const hasForms = (values.buildForms ?? []).filter(activeInLanguage(currentLanguage)).length > 0;
  const isProbablyBadIdea =
    !values.webhookUrl &&
    values.advancedOptions?.[currentLanguage]?.[ruleId]?.chatbubbleHideInputField &&
    !hasButtons &&
    !hasForms;
  const isTriggerDialogue = values.dialogueType === 'TRIGGER';

  return (
    <>
      <div className="m-bl-1" />
      <Field
        component={Checkbox}
        type="checkbox"
        name={`advancedOptions.${currentLanguage}.${ruleId}.chatbubbleHideInputField`}
        label="Chat bubble: Disable text input field"
        disabled={isModDialogue || disabled}
      />
      <p className={styles.checkboxDescription}>
        This will disable the user&apos;s input field and their ability to write questions. Use this when you want to
        make the user interact with the button replies.
      </p>
      {isProbablyBadIdea && (
        <div className={styles.warning}>
          <Icon component={Exclamation} color="support" className={styles.warningIcon} />
          Are you sure the user has a way to continue the conversation?
        </div>
      )}
      {isTriggerDialogue && (
        <>
          <Field
            component={Checkbox}
            type="checkbox"
            name={`advancedOptions.${currentLanguage}.${ruleId}.handoverRequestWhenTriggered`}
            label="Send handover request when triggered"
            disabled={isModDialogue || disabled}
          />
          <p className={styles.checkboxDescription}>
            This will start a handover request to the live agent when the dialogue is triggered. Available only for
            trigger dialogues.
          </p>
        </>
      )}
    </>
  );
}
