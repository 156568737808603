import cx from 'classnames';
import PropTypes from 'prop-types';

import { Triangle } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './SortIcon.scss';

const SortIcon = ({ sort, reverse }) => (
  <Icon
    component={Triangle}
    color="text"
    className={cx(styles.sortIcon, { [styles.reverse]: reverse })}
    title={`Sort by ${sort}`}
  />
);

SortIcon.propTypes = {
  sort: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

export default SortIcon;
