import { useMutation } from '@apollo/client';
import { isEqual, omit } from 'lodash';
import { useCallback } from 'react';
import { Form } from 'react-final-form';

import { UpdateZodiacSettingsDocument } from 'frontend/api/generated';
import { Loader, PageBar, PageContent, Panel } from 'frontend/components';
import { useBotOrSkill, useCurrentLanguage, useLanguages, useTabTitle, useToast } from 'frontend/hooks';

import styles from './Train.scss';
import { ModelList } from './components';
import TrainPageContent from './components/TrainFormContent';

const Train = () => {
  const toast = useToast();
  const { botData, loading: botDataLoading } = useTabTitle('Train');
  const botId = botData?.bot?.id || '';
  const { bot } = botData ?? { bot: undefined };
  const botOrSkillParams = useBotOrSkill();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { languages, loading: languagesLoading } = useLanguages(botOrSkillParams);
  const [mutateZodiacSettings] = useMutation(UpdateZodiacSettingsDocument);

  const onSubmitZodiacSettings = useCallback(
    async (values) => {
      const zodiacSettings = omit(values, ['id', '__typename']);
      await mutateZodiacSettings({
        variables: {
          botId,
          zodiacSettings,
          languageCode: currentLanguage,
        },
      });
      toast.success('Settings updated');
    },
    [botId, currentLanguage, mutateZodiacSettings, toast],
  );

  const zodiacSetting = (bot?.zodiacSettings || []).find((x) => x.languageCode === currentLanguage);
  const initialValues = { zodiacSetting };
  const onSubmit = (values) => {
    if (values.zodiacSetting !== initialValues.zodiacSetting) {
      onSubmitZodiacSettings(values.zodiacSetting);
    }
  };

  if (!botId || botDataLoading || languagesLoading) {
    return <Loader size="large" />;
  }

  return (
    <PageContent className={styles.content} hasFormValidation>
      <Panel>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          initialValuesEqual={isEqual}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <TrainPageContent languages={languages} currentLanguage={currentLanguage} botId={botId} />
            </form>
          )}
        />
        <ModelList selectedLanguage={currentLanguage} className="m-t-4" />
      </Panel>
    </PageContent>
  );
};

export default Train;
