import { multiply, sum } from 'lodash';

export default (samples) =>
  sum(
    samples.map(({ instances }) =>
      instances
        .filter(({ entity }) => entity)
        .map(({ entity: { items } }) => items.length)
        .reduce(multiply, 1),
    ),
  );
