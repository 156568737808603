import cx from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';

import defaultAvatar from 'frontend/assets/images/member_avatar.svg?url';
import { Button } from 'frontend/components';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import styles from '../Evaluation.scss';

const SHOW_INITIAL = 15;

const ReportHistory = ({ reports, selectedReport, selectReport }) => {
  const [showAll, setShowAll] = useState(reports.indexOf(selectedReport) > SHOW_INITIAL);
  return (
    <>
      {reports.map((report, idx) => {
        if (idx > SHOW_INITIAL && !showAll) return null;

        const isSelectedReport = report.id === selectedReport.id;

        const classNames = cx(styles.history, {
          [styles.historySelected]: isSelectedReport,
        });

        return (
          <div className={classNames} key={report.id} title={format(report.createdAt, DATE_FORMAT_WITH_TIME)}>
            <span className={styles.reportId}>{report.id}</span>
            <img src={report?.runBy?.profile?.avatarUrl ?? defaultAvatar} alt="User avatar" className={styles.avatar} />
            <span>{report?.runBy?.fullName}</span>
            <span className={styles.score}>{Math.round(report.fScoreId)}</span>
            <Button onClick={() => selectReport(report)} disabled={isSelectedReport}>
              View
            </Button>
          </div>
        );
      })}
      {!showAll && reports.length > SHOW_INITIAL && (
        <div className={styles.showAll}>
          <button onClick={() => setShowAll(true)} type="button">
            Show all
          </button>
        </div>
      )}
    </>
  );
};

const ReportType = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

ReportHistory.propTypes = {
  reports: PropTypes.arrayOf(ReportType).isRequired,
  selectReport: PropTypes.func.isRequired,
  selectedReport: PropTypes.shape({}).isRequired,
};

export default ReportHistory;
