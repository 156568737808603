import cx from 'classnames';
import { Link, type To } from 'react-router-dom';

import { useLinkTo } from 'frontend/hooks';

import styles from './MaybeLink.scss';

interface MaybeLinkProps {
  /** The address link. */
  to?: To;
  className?: string;
  /** Keep query in the address, or not. */
  keepQuery?: boolean;
  children: React.ReactNode;
}

/** It renders a `<Link>` if a `to` prop is passed, otherwise it just shows children. */
const MaybeLink = ({ to, children, className, keepQuery = true }: MaybeLinkProps) => {
  const linkTo = useLinkTo(to, { keepQuery });

  if (to) {
    return (
      <Link className={cx(styles.link, className)} to={linkTo}>
        {children}
      </Link>
    );
  }

  return <div className={className}>{children}</div>;
};

export default MaybeLink;
