import { useCallback, useMemo } from 'react';

import useComposerState from './useComposerState';
import { clear } from '../stateHandlers';
import { resolveHashtags } from '../utils';

const stopEvent = (event) => {
  event.stopPropagation();
  event.preventDefault();
};

export default function useHandlerProps({
  potentialTagItems,
  openDropdown,
  name,
  composerRef,
  constructTagItem,
  onInsertTag,
}) {
  const { state, setState } = useComposerState(name);

  const handleResolveHashtags = useCallback(
    (updatedState) => {
      const { state: stateWithHashtags, dropdownOpened } = resolveHashtags(updatedState, {
        potentialTagItems,
        openDropdown,
        constructTagItem,
        onInsertTag,
      });

      return { state: stateWithHashtags, dropdownOpened };
    },
    [constructTagItem, onInsertTag, openDropdown, potentialTagItems],
  );

  // Used e.g. to stop the click save button event from overriding dropdown opening
  const stopClickEvent = useCallback((event) => {
    if (!event?.target) return;
    event.target.addEventListener('click', stopEvent);
    setTimeout(() => event.target.removeEventListener('click', stopEvent), 10);
  }, []);

  const handlerProps = useMemo(
    () => ({ state, setState, resolveHashtags: handleResolveHashtags, clear, stopClickEvent, composerRef }),
    [composerRef, handleResolveHashtags, setState, state, stopClickEvent],
  );

  return handlerProps;
}
