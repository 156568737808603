import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import type { AttachmentType } from 'frontend/api/generated';
import { Attachment as AttachmentIcon, ImageBroken } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { ATTACHMENT_STATUS } from 'frontend/features/Inbox/constants';
import { useAccessToken } from 'frontend/hooks';
import { isFileTypeImage } from 'frontend/utils';
import fileExists from 'frontend/utils/fileExists';

import styles from './Attachment.scss';
import { getAttachementSource } from '../../utils/getAttachmentSource';
import chatButtonStyles from '../ChatButton/ChatButton.scss';

interface AttachmentProps {
  attachment: AttachmentType;
}

const Attachment = ({ attachment }: AttachmentProps) => {
  const [source, setSource] = useState();
  const [isValid, setIsValid] = useState(true);
  const token = useAccessToken();

  const isImage = isFileTypeImage(attachment.type);

  const getSource = useCallback(() => getAttachementSource(attachment, token), [attachment, token]);

  // Set source for thumbnail previews etc on load
  useEffect(() => {
    const checkAndSetSource = async (fileSource) => {
      try {
        await fileExists(fileSource);
      } catch (err) {
        setIsValid(false);
      } finally {
        setSource(fileSource);
      }
    };
    const fileSource = getSource();
    checkAndSetSource(fileSource);
  }, [getSource]);

  // Get source with updated access token on click
  const onClick = useCallback(async () => {
    const updatedSource = await getSource();
    window.open(updatedSource, '_blank');
  }, [getSource]);

  if (
    ([ATTACHMENT_STATUS.ERROR, ATTACHMENT_STATUS.INFECTED] as string[]).includes(attachment.status) ||
    (isImage && !isValid)
  ) {
    return (
      <div className={cx(styles.attachmentIconContainer, { [styles.attachmentIconContainerFile]: !isImage })}>
        <Icon component={ImageBroken} />
      </div>
    );
  }

  if (!source) return null;

  if (isImage) {
    return (
      <div
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && onClick}
        className={styles.attachmentImageWrapper}
      >
        <img src={source} alt={attachment.name} />
      </div>
    );
  }

  return (
    <button className={cx(styles.downloadButton, chatButtonStyles.button)} onClick={onClick} type="button">
      <Icon component={AttachmentIcon} />
      <div className={styles.attachmentFileInfo}>
        <div className={styles.attachmentFileInfoTitle}>{attachment.name}</div>
        {attachment.sizeKb && <div className={styles.attachmentFileInfoSize}>{attachment.sizeKb} Kb</div>}
      </div>
    </button>
  );
};

export default Attachment;
