import cx from 'classnames';
import { useEffect } from 'react';
import { Field, useField } from 'react-final-form';

import { Check } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import InputErrorWrapper from 'frontend/components/InputErrorWrapper/InputErrorWrapper';

import styles from './WeekdaySelector.scss';

const WEEKDAYS = {
  monday: {
    short: 'Mon',
    long: 'MONDAY',
  },
  tuesday: {
    short: 'Tue',
    long: 'TUESDAY',
  },
  wednesday: {
    short: 'Wed',
    long: 'WEDNESDAY',
  },
  thursday: {
    short: 'Thu',
    long: 'THURSDAY',
  },
  friday: {
    short: 'Fri',
    long: 'FRIDAY',
  },
  saturday: {
    short: 'Sat',
    long: 'SATURDAY',
  },
  sunday: {
    short: 'Sun',
    long: 'SUNDAY',
  },
};

interface Props {
  fieldName: string;
  partialSubmitFailed: boolean;
}

/** Custom form component that works with React Final Form. */
export default function WeekdaySelector({ fieldName, partialSubmitFailed }: Props) {
  const { meta, input } = useField(fieldName);

  /* Validation in Modal form is buggy because we don't _actually_ submit. This makes so we
  show error also when user tries to submit the modal without touching the field at all. */
  useEffect(() => {
    input.onBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldDisplayError = Boolean((meta?.submitFailed || partialSubmitFailed) && (meta?.submitError || meta?.error));
  const errorMessage = meta?.submitError || meta?.error || '';

  return (
    <InputErrorWrapper hasError={shouldDisplayError} displayError={errorMessage}>
      <fieldset className={styles.container}>
        <legend className="visually-hidden">Select weekdays</legend>
        {Object.entries(WEEKDAYS).map(([day, { short: shortDay, long: longDay }]) => {
          const isSelected = input.value?.includes(longDay);

          return (
            <Field
              key={day}
              name={fieldName}
              type="checkbox"
              value={longDay}
              validate={(value) => (value?.length ? undefined : 'Select at least one day')}
              render={({ input: inputData }) => (
                <label className={cx(styles.label, { [styles.selected]: isSelected })}>
                  <input {...inputData} className="visually-hidden" />
                  {isSelected && <Icon component={Check} className={styles.checkIcon} />}
                  {shortDay}
                </label>
              )}
            />
          );
        })}
      </fieldset>
    </InputErrorWrapper>
  );
}
