import { NudgePreview } from 'frontend/features';

import styles from './styles.scss';

const Intent = () => (
  <div className={styles.intentContainer}>
    <h3 className={styles.intentTitle}>Nudge trigger</h3>
    <em className={styles.intentDescription}>Set by a developer</em>
  </div>
);
const Nudge = ({ nudge }) => (
  <div className={styles.previewBox}>
    <NudgePreview nudge={nudge} />
  </div>
);
const Preview = ({ children }) => <div className={styles.containerPreview}>{children}</div>;

Preview.Intent = Intent;
Preview.Nudge = Nudge;

export default Preview;
