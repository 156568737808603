import { captureException, getClient } from '@sentry/browser';
import { useEffect } from 'react';

import { useBotOrganization } from 'frontend/features/Organization/hooks';

import useMe from './useMe';

// Initially we enable only for those organizations
// we remove this when we feel comfortable
const SUPPORTED_ORGANIZATIONS_IDS = [
  '105', // Norwegian ASA
  '2', // Kindly
  '906', // tech4s
  '164', // Elkjop
];

export default (botId) => {
  const { organization } = useBotOrganization({ botId, skip: !botId });
  const { data } = useMe();

  useEffect(() => {
    const initReplay = async () => {
      const { addIntegration, replayIntegration } = await import(
        /* webpackChunkName: "SentryReplay" */ '@sentry/browser'
      );
      // sentry exposes wrong types for replay, remove when they update
      addIntegration(
        replayIntegration({
          stickySession: false,
          maskAllText: true,
          blockAllMedia: true,
          // networkDetailAllowUrls: [`${window.env.API_URL}/api/v3/graphql`],
          mutationLimit: 1000,
        } as any),
      );
    };
    if (organization?.id && data) {
      const isReplayInitialized = getClient()?.getIntegrationByName?.('Replay');

      if (
        !isReplayInitialized &&
        data?.me.profile.trackingConsentApproved &&
        SUPPORTED_ORGANIZATIONS_IDS.includes(organization?.id)
      ) {
        try {
          initReplay();
        } catch (err) {
          captureException(err);
        }
      }
    }
  }, [organization?.id, data]);
};
