import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  BetaSettingsDocument,
  UpdateBetaSettingsDocument,
  type UpdateBetaSettingsMutation,
  type UpdateBetaSettingsMutationVariables,
} from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

export default function useBetaSettings() {
  const { organizationId } = useParams();
  const { isKindlyAdminManager, loading: loadingPermissions } = useMyPermissions();
  const { data: betaData, loading: betaLoading } = useQuery(BetaSettingsDocument, {
    variables: { organizationId: organizationId! },
    skip: !organizationId,
  });
  const [updateBetaSettings] = useMutation(UpdateBetaSettingsDocument);
  const toast = useToast();

  const loading = loadingPermissions || betaLoading;

  const onSubmit = async (
    values: Pick<UpdateBetaSettingsMutationVariables, 'enableOrganizationBilling' | 'enableOrganizationInbox'>,
  ) => {
    const variables = { ...values, organizationId: organizationId! };
    let res: { data?: UpdateBetaSettingsMutation | null } | undefined;
    try {
      res = await updateBetaSettings({ variables });
    } catch (err) {
      toast.error('Could not update beta settings');
    }
    toast.success(`Updated beta settings for ${res?.data?.updateBetaSettings?.name}`);
  };
  const initialValues = {
    enableOrganizationInbox: !!betaData?.organization?.enableOrganizationInbox,
    enableOrganizationBilling: !!betaData?.organization?.enableOrganizationBilling,
  };

  return { onSubmit, initialValues, isKindlyManager: isKindlyAdminManager, loading };
}
