import cx from 'classnames';
import { Field } from 'react-final-form';

import { InfoButton, Input } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';

import styles from './SuggestionTab.scss';
import buildStyles from '../../../Build.scss';
import { AboutSuggestions } from '../../../modals';

interface Props {
  args: { currentLanguage: string };
}

function SuggestionTab({ args: { currentLanguage } }: Props) {
  const [showAboutModal] = useModal(AboutSuggestions);

  return (
    <>
      <div className={cx(buildStyles.sectionTitle, styles.titleWrapper)}>
        <h3 className={styles.title}>Suggestion</h3>
        <InfoButton className={styles.infoButton} onClick={showAboutModal} />
      </div>
      <Field
        component={Input}
        name={`dialogueFallback.${currentLanguage}`}
        aria-label="Suggestion"
        fieldColor={FIELD_COLOR.MISCHKA}
      />
      <p className="m-t-1 text-color-light">What users will see if this dialogue shows up as a suggested dialogue.</p>
    </>
  );
}

export default SuggestionTab;
