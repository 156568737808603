import { gql } from '@apollo/client';

import { PLATFORM_ALERT_FRAGMENT } from 'frontend/api/fragments';

export const CREATE_PLATFORM_ALERT = gql`
  mutation CreatePlatformAlert($platformAlertInput: PlatformAlertInput!) {
    createPlatformAlert(platformAlertInput: $platformAlertInput) {
      ...PlatformAlert
    }
  }
  ${PLATFORM_ALERT_FRAGMENT}
`;

export const DELETE_PLATFORM_ALERT = gql`
  mutation DeletePlatformAlert($alertId: UUID!) {
    deletePlatformAlert(alertId: $alertId) {
      id
    }
  }
`;

export const UPDATE_PLATFORM_ALERT = gql`
  mutation UpdatePlatformAlert($alertId: UUID!, $platformAlertInput: PlatformAlertInput!) {
    updatePlatformAlert(alertId: $alertId, platformAlertInput: $platformAlertInput) {
      ...PlatformAlert
    }
  }
  ${PLATFORM_ALERT_FRAGMENT}
`;
