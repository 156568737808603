import { DIALOGUE_TYPES } from 'frontend/constants';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { useBotOrSkill } from 'frontend/hooks';
import type { BuildIdObjectBot } from 'frontend/propTypes/BuildIdObjectType';

import { DialogueFolder, SpecialDialogueFolder, SubscriptionFolder } from '../../components';

const DialogueView = () => {
  const { isBot, buildIdObject, botId } = useBotOrSkill();
  const { botSettings } = useBotSettings(botId);

  return (
    <div>
      {isBot && <SpecialDialogueFolder dialogueType={DIALOGUE_TYPES.GREETING} buildIdObject={buildIdObject} />}
      <DialogueFolder buildIdObject={buildIdObject!} />
      {isBot && (
        <>
          <SpecialDialogueFolder dialogueType={DIALOGUE_TYPES.FALLBACK} buildIdObject={buildIdObject} />
          {!botSettings?.intentMatchEnabled && <SubscriptionFolder buildIdObject={buildIdObject as BuildIdObjectBot} />}
        </>
      )}
    </div>
  );
};

export default DialogueView;
