import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { useCurrentLanguage, usePrevious } from 'frontend/hooks';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';

import useCurrentRuleId from './useCurrentRuleId';
import { createDialogueInitialValues, createDialogueModInitialValues } from '../utils';

export default function useInitialValues(data: { dialogue: Record<string, unknown> }) {
  const [{ selectedLanguage }] = useCurrentLanguage();
  const currentRuleId = useCurrentRuleId();
  const previousLanguage = usePrevious(selectedLanguage);
  const previousRule = usePrevious(currentRuleId);
  const isFeatureEnabled = useFeatureFlags();
  const [initialValues, setInitialValues] = useState<Record<string, any>>();
  const { isSubscriptionDialogue } = data?.dialogue ?? {};
  const slotsEnabled = isFeatureEnabled('slots');

  const updateInitialValues = useCallback(
    (updatedData, languageCode) => {
      const createInitialValues = isSubscriptionDialogue ? createDialogueModInitialValues : createDialogueInitialValues;
      setInitialValues(createInitialValues(updatedData, languageCode, slotsEnabled));
    },
    [isSubscriptionDialogue, slotsEnabled],
  );

  useEffect(() => {
    // Set initial values once when data is ready, and when changing language
    if (isEmpty(data)) {
      return;
    }
    const hasChangedLanguage = selectedLanguage && selectedLanguage !== previousLanguage;
    if (isEmpty(initialValues) || hasChangedLanguage) {
      updateInitialValues(data, selectedLanguage);
    }
  }, [data, selectedLanguage, currentRuleId, initialValues, previousLanguage, previousRule, updateInitialValues]);

  return { initialValues: initialValues ?? {}, updateInitialValues };
}
