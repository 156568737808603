import { gql } from '@apollo/client';

const SIMPLE_EVALUATION_FRAGMENT = gql`
  fragment SimpleEvaluation on SimpleEvaluationType {
    id
    inputFile
    languageCode
    results
    state
    title
  }
`;

export default SIMPLE_EVALUATION_FRAGMENT;
