/* eslint-disable @typescript-eslint/no-use-before-define */
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { getAllApps } from 'frontend/api/queries';
import { Plus } from 'frontend/assets/icons';
import { Button, LoaderSwitch, Panel } from 'frontend/components';
import { useTabTitle } from 'frontend/hooks';

import styles from './Applications.scss';
import { ConnectList } from '../components/ConnectList';

export default function Applications() {
  const { botId } = useParams();
  useTabTitle('Applications');

  const { data: applications, loading } = useQuery(getAllApps, { variables: { botId } });

  const hasApps = Boolean(applications?.bot?.applications?.length);

  if (!hasApps) return <NoApps />;

  return (
    <LoaderSwitch loading={loading} size="large">
      <ConnectList
        headerComponent={
          <Button className={styles.newAppBtn} icon={Plus} color="primary" to="new">
            New application
          </Button>
        }
        data={applications.bot.applications}
      />
    </LoaderSwitch>
  );
}

function NoApps() {
  return (
    <Panel>
      <div className={styles.panelInner}>
        <h2 className={styles.header}>Applications</h2>
        <p className={`m-b-4 ${styles.panelDescription}`}>
          The Application API in Kindly offers even further integration towards third-party services and native apps.
          The API allows a service to communicate with your customers through Kindly. Read more in{' '}
          <a className="link" href="https://docs.kindly.ai/api/application" target="_blank" rel="noopener noreferrer">
            our documentation
          </a>
          <br /> <br />
          Let&apos;s set up our first application.
        </p>
        <Button className={styles.newAppBtn} icon={Plus} title="New application" color="primary" to="new">
          New application
        </Button>
      </div>
    </Panel>
  );
}
