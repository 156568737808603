import { gql } from '@apollo/client';

import BOT_ROLE_FRAGMENT from 'frontend/features/Organization/fragments/BotRole.fragment.graphql';

export default gql`
  query BotRoles($botId: ID!) {
    bot(id: $botId) {
      id
      roles {
        ...BotRole
      }
    }
  }
  ${BOT_ROLE_FRAGMENT}
`;
