import PropTypes from 'prop-types';

import RequestDetails from 'frontend/features/DebugConsole/components/RequestDetails';
import { Modal } from 'frontend/features/Modals';

import styles from './styles.scss';

const WebhookDebugInfo = ({ args: data, hide }) => (
  <Modal title="Webhook Response Debug Info" className={styles.modal} hide={hide} footer={false} hideCancel>
    <Modal.Content className={styles.content}>{data && <RequestDetails req={data} />}</Modal.Content>
  </Modal>
);

WebhookDebugInfo.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({}).isRequired,
};

export default WebhookDebugInfo;
