import { Link } from 'react-router-dom';

import { pluralize } from 'frontend/utils';

import styles from './InvitationsList.scss';

export type ResourceType = 'organization' | 'bot' | 'skill' | 'platform';

interface OrganizationOrBotProps {
  data: {
    id?: string;
    name?: string;
    roles?: {
      title?: string;
    }[];
    itemType?: ResourceType;
  };
}

const OrganizationOrBot = ({ data: { id, name, roles = [], itemType } }: OrganizationOrBotProps) => {
  if (!id) return null;

  return (
    <div className={styles.orgOrBot}>
      <Link className="link" to={`/${itemType}/${id}`}>
        {name}
      </Link>
      <div className="m-t-sm">
        {pluralize('Role', roles.length)}: {roles.map((role) => role.title).join(', ')}
      </div>
    </div>
  );
};

export default OrganizationOrBot;
