import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';

import { AdminGetAllSkillsDocument } from 'frontend/api/generated';
import { LoaderSwitch, NoHits, PageContent, Search } from 'frontend/components';
import BotList from 'frontend/features/LegacyBotList';

const getKey = (currentQuery) => (Number(currentQuery) ? 'id' : 'name');
const searchOptions = { listName: 'skills' };

const AdminSkills = () => {
  const { data, loading } = useQuery(AdminGetAllSkillsDocument);
  const organizations = useMemo(() => data?.allOrganizations ?? [], [data]);
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState(organizations);
  const searchInput = useMemo(() => ({ value: query, onChange: ({ target }) => setQuery(target?.value) }), [query]);
  const noHits =
    !loading &&
    searchResults &&
    searchResults.length > 0 &&
    searchResults.every(({ skills }) => !skills || skills.length === 0);

  return (
    <LoaderSwitch loading={loading} size="large">
      <PageContent>
        <div className="m-b-lg">
          <Search
            input={searchInput}
            setSearchResults={setSearchResults}
            searchItems={organizations}
            searchOptions={searchOptions}
            searchKey={getKey}
            placeholder="Search skills"
            aria-label="Search skills"
            icon
          />
        </div>
        {searchResults.map(({ name, id, skills }) => (
          <BotList
            name={name}
            bots={(skills ?? []).map((skill) => ({
              ...skill,
              link: `/skill/${skill.id}/build`,
              projectName: skill.name,
              isArchived: false,
            }))}
            key={id}
            hideIfEmpty={Boolean(query)}
          />
        ))}
        {noHits && <NoHits name="skills" />}
      </PageContent>
    </LoaderSwitch>
  );
};

export default AdminSkills;
