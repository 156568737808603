import { useCallback, useState } from 'react';

export default function useHiddenTabs() {
  const [hidden, setHidden] = useState<number[]>([]);

  const hide = useCallback((idx: number): void => {
    setHidden((currentHidden) => [...currentHidden, idx]);
  }, []);

  const show = useCallback((idx: number): void => {
    setHidden((currentHidden) => currentHidden.filter((itemIdx) => itemIdx !== idx));
  }, []);

  const isHidden = useCallback((idx: number) => Boolean(hidden.find((itemIdx) => itemIdx === idx)), [hidden]);

  return { hide, show, isHidden };
}
