import { gql } from '@apollo/client';

import { sampleFragment } from 'frontend/api/fragments';

export default gql`
  fragment ConfusedSamplePair on ConfusedSamplePairType {
    id
    ignoredAt
    sampleA {
      ...Sample
    }
    sampleB {
      ...Sample
    }
  }
  ${sampleFragment}
`;
