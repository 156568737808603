import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './TicketIcon.scss';
import { TICKET_STATUS } from '../../constants';
import InfoIcon from '../InfoIcon';

const TicketIcon = ({ status, className, infoIcon }) => {
  if (infoIcon) return <InfoIcon />;

  const iconClassName = cx(styles.ticketIcon, className, {
    [styles.ticketIconOpen]: status === TICKET_STATUS.OPEN,
    [styles.ticketIconPending]: status === TICKET_STATUS.PENDING,
    [styles.ticketIconSolved]: status === TICKET_STATUS.SOLVED,
  });

  return <div className={iconClassName} />;
};

TicketIcon.propTypes = {
  status: PropTypes.oneOfType([PropTypes.oneOf(Object.values(TICKET_STATUS), PropTypes.string), PropTypes.string]),
  className: PropTypes.string,
  infoIcon: PropTypes.bool,
};

export default TicketIcon;
