import { gql } from '@apollo/client';

import { DialogueFragment } from 'frontend/features/Build/graphql';

export default gql`
  mutation DuplicateDialogue($botId: ID, $skillId: ID, $dialogueId: UUID!) {
    duplicateDialogue(botId: $botId, skillId: $skillId, dialogueId: $dialogueId) {
      ...Dialogue
    }
  }
  ${DialogueFragment}
`;
