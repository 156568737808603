import HTML5VideoEmbed from './HTML5VideoEmbed';
import VimeoEmbed from './VimeoEmbed';
import YoutubeEmbed from './YoutubeEmbed';

interface VideoEmbedPreview {
  source: string;
}

const VideoEmbedPreview = ({ source }: VideoEmbedPreview) => {
  // What we support must also be reflected in Kindly Chat
  // https://github.com/kindly-ai/kindly-chat/tree/main/packages/react-chat/src/components/VideoEmbed
  const sourceLowercase = source.toLowerCase();
  if (sourceLowercase.includes('youtube.com') || sourceLowercase.includes('youtu.be')) {
    return <YoutubeEmbed source={source} />;
  }
  if (sourceLowercase.includes('vimeo.com')) return <VimeoEmbed source={source} />;
  return <HTML5VideoEmbed source={source} />;
};

export default VideoEmbedPreview;
