import { useQuery } from '@apollo/client';
import { formatISO } from 'date-fns';
import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import { Button, Input, Loader, ToggleSwitch } from 'frontend/components';
import S3InboxBackupSettings from 'frontend/features/BotSettings/queries/S3InboxBackupSettings.query.graphql';
import { required } from 'frontend/form/validators';
import { IDType } from 'frontend/propTypes';

import styles from './styles.scss';
import useOnSubmit from './useOnSubmit';

const S3InboxBackup = ({ botId }: { botId: string }) => {
  const { data, loading } = useQuery(S3InboxBackupSettings, { variables: { botId } });
  const s3BackupSettings = useMemo(() => data?.s3BackupSettings ?? {}, [data]);

  const initialValues = useMemo(
    () => ({
      awsAccessKeyId: '',
      awsSecretAccessKey: '',
      bucketName: '',
      s3Enabled: s3BackupSettings?.enabled ?? false,
      ...s3BackupSettings,
    }),
    [s3BackupSettings],
  );

  const { onSubmit, isSubmitting, bucketUpdateSuccessful } = useOnSubmit({ botId, initialValues });

  if (loading) return <Loader size="large" noMargin />;

  const date = formatISO(new Date(), { representation: 'date' });
  const previewPath = ({ bucketName, pathPrefix }) => {
    if (pathPrefix) {
      return `s3://${bucketName}/${pathPrefix}/${date}/<file_name>.json`;
    }

    return `s3://${bucketName}/${date}/<file_name>.json`;
  };

  return (
    <div className={styles.container}>
      <h3>
        Raw data dump to AWS S3 bucket
        {s3BackupSettings?.lastRunWasSuccess === true && (
          <span className={styles.status} role="img" aria-label="Checkmark emoji">
            ✅️
          </span>
        )}
        {s3BackupSettings?.lastRunWasSuccess === false && (
          <span className={styles.status} role="img" aria-label="X emoji">
            ❌️
          </span>
        )}
      </h3>

      {s3BackupSettings.lastSuccessfulRun && (
        <div>
          Last dump was sent {formatISO(s3BackupSettings.lastSuccessfulRun)}.
          {s3BackupSettings.lastRunWasSuccess === false && (
            <div className={styles.failMessage}>Last run failed, please check credentials</div>
          )}
        </div>
      )}

      <p>
        You can schedule to receive daily dumps from us. This is useful if you e.g. want to routinely analyze your
        message data, or feed it to your own logging systems. For this you will need Amazon Web Services and a S3
        Bucket.
      </p>
      <p>
        <a className="link" href="https://docs.kindly.ai/guides-and-examples/s3-dump">
          Check out our docs on how to setup permissions for Kindly.
        </a>
      </p>

      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values, dirty }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className={styles.toggleEnabled}>
              Enable daily dump <ToggleSwitch className="m-r-md" name="s3Enabled" />
            </div>
            {Boolean(values.s3Enabled) && (
              <>
                <div className={styles.s3settings}>
                  <Field
                    component={Input}
                    validate={required}
                    label="Bucket name*"
                    aria-label="Bucket name"
                    name="bucketName"
                    placeholder="Name of your S3 bucket"
                    multiline
                  />
                  <Field
                    component={Input}
                    label="Path to upload to"
                    aria-label="Path to upload to"
                    name="pathPrefix"
                    placeholder="Directory in your bucket where the files will be stored"
                    multiline
                  />
                  <Field
                    component={Input}
                    validate={required}
                    label="Access key ID*"
                    aria-label="Access key ID"
                    name="awsAccessKeyId"
                    placeholder="Access key ID issued by AWS"
                    multiline
                  />
                  <Field
                    component={Input}
                    validate={required}
                    label="Secret access key*"
                    aria-label="Secret access key"
                    name="awsSecretAccessKey"
                    placeholder="Secret access key issued by AWS"
                    multiline
                  />
                </div>
                {values.bucketName && (
                  <p>
                    The files will be uploaded to a path like <code>{previewPath(values)}</code>
                  </p>
                )}
              </>
            )}
            {bucketUpdateSuccessful === true && (
              <div>
                <span role="img" aria-label="Checkmark emoji">
                  ✅️
                </span>{' '}
                Credentials were successfully tested.
              </div>
            )}
            {bucketUpdateSuccessful === false && (
              <div>
                <span role="img" aria-label="X emoji">
                  ❌️
                </span>{' '}
                Unable to write to bucket.
              </div>
            )}
            <div className={styles.saveBtn}>
              <Button type="submit" color="primary" isSubmitting={isSubmitting} disabled={!dirty}>
                Save S3 settings
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

S3InboxBackup.propTypes = {
  botId: IDType.isRequired,
};

export default S3InboxBackup;
