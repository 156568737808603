import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon } from 'frontend/components';
import { useLinkTo } from 'frontend/hooks';

import styles from './Menu.scss';

interface Props {
  title: string;
  slug: string;
  subNavigation?: boolean;
  keepQuery?: boolean;
  className?: string;
  queryParams?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}
export default function MenuItem({
  title,
  slug,
  subNavigation = false,
  className,
  keepQuery = false,
  queryParams,
  icon,
}: Props) {
  const linkTo = useLinkTo(slug, { keepQuery, queryParams });

  return (
    <NavLink
      to={linkTo}
      className={({ isActive }) =>
        cx(className, {
          [styles.subMenuItem]: subNavigation,
          [styles.menuItem]: !subNavigation,
          [styles.subMenuItemActive]: subNavigation && isActive,
          [styles.menuItemActive]: !subNavigation && isActive,
        })
      }
    >
      {icon && <Icon component={icon} title={title} width={16} height={16} />}
      {title}
    </NavLink>
  );
}
