import { ErrorBoundary, LoaderSwitch } from 'frontend/components';
import { useSageData } from 'frontend/features/Analytics/hooks';
import type { MessagesTotalData, MostActiveBotsData } from 'frontend/features/Analytics/utils/sageData';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';
import type { QueryParam } from 'frontend/utils/stringifyQueryParamsWithBrackets';

import styles from './styles.scss';
import { useMostActiveBots } from '../../hooks';
import ActiveBotCard from '../ActiveBotCard';

interface BotListProps {
  sageData: {
    bots: MostActiveBotsData;
    total: MessagesTotalData;
  };
}

function BotList({ sageData }: BotListProps) {
  const { bots } = useMostActiveBots(sageData);

  return (
    <div className={styles.listContainer}>
      {bots.map((bot) => (
        <ActiveBotCard key={bot.key} bot={bot} />
      ))}
    </div>
  );
}

interface MostActiveBotsProps {
  amount: number;
  filters: Record<string, QueryParam>;
}

function MostActiveBots({ filters, amount }: MostActiveBotsProps) {
  const { loading: loadingBots, data: bots } = useSageData(
    sageScope(SAGE_RESOURCE.PLATFORM),
    'activity/most_active/messages',
    {
      ...filters,
      limit: amount,
    },
  );
  const { loading: loadingTotal, data: total } = useSageData(
    sageScope(SAGE_RESOURCE.PLATFORM),
    'messages/total',
    filters,
  );

  return (
    <ErrorBoundary reportError renderErrorView>
      <LoaderSwitch loading={loadingBots || loadingTotal || !bots || !total} size="large">
        <BotList sageData={{ bots, total }} />
      </LoaderSwitch>
    </ErrorBoundary>
  );
}

export default MostActiveBots;
