import { useApolloClient } from '@apollo/client';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getAccessToken, logout } from 'frontend/state/dux/auth';
import { useAppDispatch } from 'frontend/state/hooks';

import { INVITATION_STATES } from './constants';

const { ACCEPT, COMPLETED, LOGIN, CREATE_USER, INVALID } = INVITATION_STATES;

const FORM_ERROR_CODES = [400, 403, 409];

export const useInvitationState = () => {
  const [invitation, setInvitation] = useState(null);
  const [invitationState, setInvitationState] = useState(null);

  const updateInvitation = useCallback((newInvitation) => {
    setInvitation((currentInvitation) => {
      if (isEqual(currentInvitation, newInvitation)) return currentInvitation;
      return newInvitation;
    });
  }, []);

  return { invitationState, invitation, updateInvitation, setInvitationState };
};

export const useApiActions = ({ authenticated, updateInvitation, setInvitationState }) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const token = search.replace('?token=', '');
  const [isFetching, setIsFetching] = useState(false);
  const client = useApolloClient();

  const apiCall = async ({
    body,
    method,
    onError,
  }: {
    body?: any;
    method: string;
    onError?: (...args) => void;
  }): Promise<void> => {
    const url = `${window.env.API_URL}/api/v2/accounts/invitation/${token}/`;
    const headers: Record<string, string> = { 'Content-Type': 'application/json' };

    if (authenticated) {
      const authToken = await dispatch(getAccessToken());
      if (authToken) headers.Authorization = `Bearer ${authToken}`;
    }

    const response = await fetch(url, { method, headers, body });

    if (response.status === 200) {
      const data = await response.json();
      updateInvitation(data.invitation);

      if (data.invitation.is_accepted) {
        setInvitationState(COMPLETED);
      } else if (data.invitation_matches_current_user) {
        setInvitationState(ACCEPT);
      } else if (data.invited_user_already_exist) {
        setInvitationState(LOGIN);
      } else {
        dispatch(logout(client));
        setInvitationState(CREATE_USER);
      }
    } else if (FORM_ERROR_CODES.includes(response.status)) {
      if (onError) await onError(response);
    } else {
      setInvitationState(INVALID);
    }
  };

  const findInvitation = async () => {
    if (!token) {
      setInvitationState(INVALID);
      return;
    }
    if (isFetching) return;
    setIsFetching(true);

    try {
      await apiCall({ method: 'GET' });
    } finally {
      setIsFetching(false);
    }
  };

  const accept = async ({ body, onError } = { body: undefined, onError: undefined }) => {
    if (isFetching) return;
    setIsFetching(true);

    try {
      await apiCall({ method: 'POST', body, onError });
    } finally {
      setIsFetching(false);
    }
  };

  return { findInvitation, accept };
};
