import defaultBotAvatarUrl from 'frontend/assets/images/all_white.jpg';
import ImageAvatar, { type AutoUser, type ImageAvatarSize } from 'frontend/components/ImageAvatar/ImageAvatar';

interface Message {
  name?: string;
  avatar?: string;
  fromBot?: boolean;
}
interface AvatarProps {
  botAvatarUrl?: string;
  chatAvatarUrl: string;
  autoUser: AutoUser;
  botAvatar?: boolean;
  className: string;
  message: Message;
  size: ImageAvatarSize;
}
const Avatar = ({
  botAvatarUrl,
  chatAvatarUrl,
  autoUser,
  className,
  message: { fromBot = false, name = '', avatar: messageAvatar } = {},
  size = 'large',
  botAvatar = false,
}: AvatarProps) => {
  if (botAvatar || (fromBot && name === '')) {
    return <ImageAvatar className={className} img={botAvatarUrl || defaultBotAvatarUrl} size={size} />;
  }

  if (fromBot) {
    return <ImageAvatar className={className} img={messageAvatar} size={size} />;
  }

  return <ImageAvatar className={className} img={chatAvatarUrl} autoUser={autoUser} size={size} />;
};

export default Avatar;
