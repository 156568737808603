import type { ApolloClient } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { isFunction, noop } from 'lodash';

export default function updateItemInCache({
  fragment,
  typeName,
  fragmentName,
  update,
  cache,
  id,
  variables,
  onNotInCache = noop,
}: {
  fragment: DocumentNode;
  typeName: string;
  fragmentName: string;
  update: unknown;
  cache: ApolloClient<unknown>;
  id: string;
  variables: unknown;
  onNotInCache?: () => void;
}): void {
  const fragmentId = `${typeName}:${id}`;
  const dataInCache = cache.readFragment({ fragment, id: fragmentId, fragmentName, variables });

  if (dataInCache) {
    const updatedData = isFunction(update) ? update(dataInCache) : update;
    cache.writeFragment({ fragment, id: fragmentId, fragmentName, data: updatedData, variables });
  } else {
    onNotInCache();
  }
}
