import { useMutation, useQuery } from '@apollo/client';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { HandoverSettingsDocument, UpdateHandoverSettingsDocument } from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';

export const useHandoverForm = () => {
  const toast = useToast();
  const { botId } = useParams();
  const [updateSettings] = useMutation(UpdateHandoverSettingsDocument, {
    update(cache) {
      /* Invalidate cache for InboxStatusDocument query. */
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'inboxStatus' });
      cache.gc();
    },
  });

  const { data, loading } = useQuery(HandoverSettingsDocument, {
    variables: { botId: botId! },
    skip: !botId,
  });

  const initialValues = useMemo(() => {
    if (loading) return {};
    return {
      ...data?.bot,
      ...data?.handoverSettings,
    };
  }, [data, loading]);

  const onSubmit = useCallback(
    async (values) => {
      const { businessHoursTimezone, businessHours } = values;
      await updateSettings({
        variables: {
          botId: botId!,
          handoverSettingsInput: {
            ...pick(values, [
              'id',
              'mode',
              'webhookUrl',
              'cleanUpRequestedHandoversAfterDays',
              'cleanUpActiveHandoversAfterDays',
              'endHandoverOnSolve',
            ]),
            businessHoursTimezone,
            businessHours: businessHours.map((bh) => ({
              ...pick(bh, ['fromDate', 'id', 'toDate', 'name', 'status', 'type']),
              timeframes: bh.timeframes.map(({ fromTime, toTime }) => ({ fromTime, toTime })),
            })),
          },
        },
      });
    },
    [botId, updateSettings],
  );

  return {
    botId,
    toast,
    loading,
    initialValues,
    onSubmit,
  };
};
