import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { DeleteImportDocument, ImportContentDocument, UploadedContentFilesDocument } from 'frontend/api/generated';
import { UploadCloud } from 'frontend/assets/icons';
import { Loader } from 'frontend/components';
import EmptyState from 'frontend/components/EmptyState/EmptyState';
import { useModal } from 'frontend/features/Modals';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useToast } from 'frontend/hooks';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';
import { convertISO639toEnglish } from 'frontend/utils/language';

import styles from './Import.scss';
import ImportExportDeleteModal from '../DeleteModal/ImportExportDeleteModal';
import ImportPreviewModal from '../ImportPreviewModal/ImportPreviewModal';
import ImportTable from '../ImportTable/ImportTable';

const Import = () => {
  const { botId } = useParams();
  const [showPreviewModal] = useModal(ImportPreviewModal);
  const toast = useToast();

  const [showImportDeleteModal, hideImportDeleteModal] = useModal(ImportExportDeleteModal);

  const {
    data: uploadedContentFileData,
    loading,
    refetch,
  } = useQuery(UploadedContentFilesDocument, { variables: { botId: botId || '' } });
  const uploadedContentFiles =
    uploadedContentFileData?.uploadedContentFiles || ([] as (typeof uploadedContentFileData)[]);

  const [deleteWorkspaceImport] = useMutation(DeleteImportDocument, {
    update: (cache, { data }) => {
      const id = data?.deleteImport?.id;
      if (id) {
        cache.modify({
          fields: {
            uploadedContentFiles: (currentImportList, { readField }) =>
              currentImportList.filter((current) => readField('id', current) !== id),
          },
        });
        toast.success('Workspace import was deleted');
      }
    },
  });

  const [applyImport] = useMutation(ImportContentDocument);

  const handleApply = async (id: string) => {
    await applyImport({ variables: { uploadedFileId: id, botId: botId || '' } });
    toast.success('Workspace is being imported');
  };

  usePusherEvent(getPusherChannel({ botId }), 'content-import', refetch);

  if (loading) {
    return <Loader size="medium" className={styles.loader} />;
  }

  const handleDeleteWorkspaceImport = (id) => {
    showImportDeleteModal({
      handleDelete: () => {
        deleteWorkspaceImport({ variables: { botId: botId || '', id } });
        hideImportDeleteModal();
      },
      title: 'Delete workspace import',
      description: 'You are about to delete this workspace import. Do you want to continue?',
    });
  };

  const formattedImportData = uploadedContentFiles.map((exportLine) => {
    const { id, status, createdAt, originalName, languageCode } = exportLine;
    return {
      status,
      language: convertISO639toEnglish(languageCode),
      created: format(createdAt, DATE_FORMAT_WITH_TIME),
      name: originalName,
      options: {
        status,
        preview: () =>
          showPreviewModal({
            handleApply,
            id,
            botId,
          }),
        deleteImport: () => handleDeleteWorkspaceImport(id),
      },
    };
  });

  if (uploadedContentFiles.length === 0) {
    return (
      <EmptyState
        icon={UploadCloud}
        color="teal"
        maxWidth="300px"
        title="Import a new version of your data"
        description="Add your first new version of data to get started! With this import functionality you can edit your data in other systems before uploading it to Kindly."
      />
    );
  }

  return <ImportTable imports={formattedImportData} />;
};

export default Import;
