import XRegExp from 'xregexp';

import { hasSameId } from 'frontend/features/Build/utils';
import { samplesEqual } from 'frontend/features/Samples/utils';

// In general the word boundary "\b" doesn't work with unicode characters; we make our own
const ignoreCharacters = '\\s\\n\\r\\t\\.,\'"!?';
const wordStart = `(?:^|[${ignoreCharacters}]+)`;
const wordEnd = `(?:$|[${ignoreCharacters}]+)`;

const replaceAt = (sample, start, newWord, oldWord) =>
  sample.substring(0, start) + newWord + sample.substring(start + oldWord.length);

const setInstanceValues = (sample, wordDiff, match) =>
  sample.instances.map((item) => {
    // If match is before instance, calculate new values
    const newStart = match.index <= item.start ? item.start + wordDiff : item.start;
    const newEnd = match.index <= item.start ? item.end + wordDiff : item.end;

    return {
      ...item,
      start: newStart,
      end: newEnd,
    };
  });

export const replaceText = (oldWord, newWord, samples) =>
  (samples || []).reduce((replacedSamples, currentSample) => {
    const { text } = currentSample;
    const searchString = text.toLowerCase();
    const searchQuery = oldWord.toLowerCase().trim();
    const regex = new XRegExp(`${wordStart}${searchQuery}${wordEnd}`, 'iug');

    let match = regex.exec(searchString);
    let newSample = null;
    let timesLooped = 0;

    while (match) {
      const initialCharacters = match[0].indexOf(searchQuery[0]);
      const foundIndex = match.index + initialCharacters;
      const wordDiff = newWord.length - oldWord.length;
      const wordIsEntity = currentSample?.instances.find((x) => foundIndex >= x.start && foundIndex <= x.end);

      if (!wordIsEntity) {
        // Calculate start position based on multiple findings in one sample
        const startPosition = timesLooped > 0 ? foundIndex + wordDiff * timesLooped : foundIndex;
        const newText = replaceAt(newSample?.text || text, startPosition, newWord, oldWord);

        // Change the index of the instance based on which word is changed
        const newInstances = setInstanceValues(newSample || currentSample, wordDiff, match);

        newSample = {
          ...currentSample,
          instances: newInstances,
          text: newText,
        };
      }
      timesLooped += 1;
      match = regex.exec(searchString);
    }

    if (!newSample) return replacedSamples;

    const otherSamples = [...samples, ...replacedSamples].filter((other) => !hasSameId(other, currentSample));
    const equalSampleExists = otherSamples.find((sample) => samplesEqual(sample, newSample));

    if (equalSampleExists) return replacedSamples;

    return [...replacedSamples, newSample];
  }, []);
