import Input from 'frontend/components/Input';
import { FIELD_COLOR } from 'frontend/constants';

import type { ButtonType, LocalErrors } from '../ButtonsMeta';

interface Props {
  buttonForm: ButtonType;
  setButtonForm: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  localErrors: LocalErrors;
  validateButton: () => void;
}

const QuickReplyMeta = ({ buttonForm, setButtonForm, localErrors, validateButton }: Props) => (
  <>
    <Input
      input={{
        name: 'label',
        value: buttonForm.label,
        onChange: setButtonForm,
      }}
      onBlur={() => {
        if (localErrors.label) validateButton();
      }}
      placeholder="Button text"
      fieldColor={FIELD_COLOR.MISCHKA}
      meta={localErrors.label || {}}
    />
    <Input
      input={{
        name: 'value',
        value: buttonForm.value,
        onChange: setButtonForm,
      }}
      onBlur={() => {
        if (localErrors.value) validateButton();
      }}
      placeholder="Quick reply"
      fieldColor={FIELD_COLOR.MISCHKA}
      meta={localErrors.value || {}}
    />
  </>
);

export default QuickReplyMeta;
