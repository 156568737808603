import cx from 'classnames';
import { curry, pick } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { Loader } from 'frontend/components';
import ContextMenu from 'frontend/components/ContextMenu/ContextMenu';
import MenuOverlay from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DIALOGUE_TYPES, dragAndDropTypes } from 'frontend/constants';
import { useBotOrSkill, useCombineRefs, useRemoveDefaultDragPreview, useToggle } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';

import { useDropInTopic, useTopicContextMenu } from './hooks';
import { canDrop } from './utils';
import libraryStyles from '../../Library.scss';
import { useBreadcrumbs, useIndentationStyle } from '../../hooks';
import { LibraryTopicType } from '../../propTypes';
import { getFolderIconClassnames, isSubscriptionDialogue } from '../../utils';
import LibraryFolder from '../LibraryFolder';
import LibraryItemContents from '../LibraryItemContents';

const checkBreadcrumbs = curry((topicId, _, breadcrumbs) =>
  Boolean((breadcrumbs || []).find(({ type, id }) => type === 'topic' && id === topicId)),
);

const TopicItem = ({ topic, ...props }) => {
  const { selectedLanguage, skillId, parentDisabled, indentationLevel } = props;
  const botOrSkillParams = useBotOrSkill();
  const { buildIdObject } = botOrSkillParams;
  const { id, name, isActive } = topic;
  const disabled = !isActive || parentDisabled;
  const isSubscription = isSubscriptionDialogue({ buildIdObject, skillId });

  const [showLoader, setShowLoader] = useState(false);
  const [open, toggleOpen] = useToggle();

  const checkBreadcrumbsMemoed = checkBreadcrumbs(id);
  const { isInBreadcrumbs } = useBreadcrumbs({ open, toggleOpen, checkBreadcrumbs: checkBreadcrumbsMemoed });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    item: {
      ...pick(topic, ['id', 'name', 'parentTopicId']),
      type: dragAndDropTypes.TOPIC,
    },
    type: dragAndDropTypes.TOPIC,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    canDrag: () => !isSubscription,
  });

  const drop = useDropInTopic({ setShowLoader, buildIdObject, id, name, currentLanguage: selectedLanguage });

  const [{ isOver, canDrop: canDropOnCurrent }, dropRef] = useDrop({
    accept: [dragAndDropTypes.DIALOGUE, dragAndDropTypes.TOPIC],
    collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
    canDrop: canDrop({ isSubscription, id }),
    drop,
  });

  const { actions, contextMenuId } = useTopicContextMenu({ topic, botOrSkillParams });
  const indentationStyle = useIndentationStyle(indentationLevel);

  const dragAndDropRef = useCombineRefs(dragRef, dropRef);
  useRemoveDefaultDragPreview(previewRef);

  const active = isInBreadcrumbs && !open;
  const titleClassName = cx(libraryStyles.title, getFolderIconClassnames(open));

  return (
    <>
      <ContextMenu
        id={contextMenuId}
        disabled={isSubscription || actions.length === 0}
        overlay={<MenuOverlay options={actions} />}
      >
        <LibraryFolder
          ref={dragAndDropRef}
          toggleOpen={toggleOpen}
          disabled={disabled}
          isDragging={isDragging}
          canDrop={canDropOnCurrent}
          isOver={isOver}
          active={active}
          title={name}
        >
          <span style={indentationStyle} className={titleClassName}>
            {name}
            {showLoader && <Loader className={libraryStyles.libraryLoader} />}
          </span>
        </LibraryFolder>
      </ContextMenu>
      {open && (
        <LibraryItemContents
          selectedLanguage={selectedLanguage}
          topicId={id}
          buildIdObject={buildIdObject}
          skillId={skillId}
          parentDisabled={disabled}
          dialogueType={DIALOGUE_TYPES.REGULAR}
          setShowLoader={setShowLoader}
          indentationLevel={indentationLevel + 1}
        />
      )}
    </>
  );
};

TopicItem.propTypes = {
  topic: LibraryTopicType,
  skillId: IDType,
  selectedLanguage: PropTypes.string.isRequired,
  parentTopicId: PropTypes.string,
  parentDisabled: PropTypes.bool,
  indentationLevel: PropTypes.number.isRequired,
};

export default TopicItem;
