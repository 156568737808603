import { useCallback, useRef, useState } from 'react';

import { Button } from 'frontend/components';
import { humanReadableSize } from 'frontend/utils';

import type { ButtonColor, ButtonSize } from '../Button/Button';

type ReadAsType = 'data_url' | 'text' | 'blob';

export type ReadFile = { source: FileReader['result'] | Blob; sizeKb; file };

interface Props {
  onUpload: (files: ReadFile[]) => void;
  accept?: string;
  buttonClassName?: string;
  children?: React.ReactNode;
  color?: ButtonColor;
  containerClassName?: string;
  flat?: boolean;
  multiple?: boolean;
  onError?: (err: Error) => void;
  readAs?: ReadAsType;
  size?: ButtonSize;
  sizeLimitKb?: number;
  text?: string;
  icon?: React.ComponentType;
}

const FileUpload = ({
  onUpload,
  onError,
  children,
  containerClassName,
  buttonClassName,
  sizeLimitKb = 0,
  text = 'Upload',
  size,
  accept = 'image/*',
  multiple = false,
  flat = false,
  color = 'secondary',
  readAs = 'data_url',
  icon,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onChange = useCallback(
    async ({ target }: { target: { files: FileList | null } }) => {
      setIsSubmitting(true);
      const humanReadableSizeLimit = humanReadableSize(sizeLimitKb * 1024);

      const readFiles = Array.from(target.files || []).map(
        (file): Promise<ReadFile> =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            if (readAs === 'data_url') {
              reader.readAsDataURL(file);
            } else if (readAs === 'text') {
              reader.readAsText(file);
            } else if (readAs === 'blob') {
              reader.readAsArrayBuffer(file);
            } else {
              throw new Error(`Invalid read type supplied in readAs prop: "${readAs}"`);
            }
            reader.onload = () => {
              const sizeKb = Math.round(file.size / 1024);
              if (sizeLimitKb && sizeKb > sizeLimitKb) {
                reject(new Error(`File exceeds limit of ${humanReadableSizeLimit}`));
              }
              if (readAs === 'blob' && reader.result) {
                resolve({ source: new Blob([reader.result]), sizeKb, file });
              } else {
                resolve({ source: reader.result, sizeKb, file });
              }
            };
            reader.onabort = () => reject(new Error(reader.error?.toString()));
          }),
      );

      try {
        const files = await Promise.all(readFiles);
        onUpload(files);
      } catch (err) {
        onError?.(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [readAs, onError, onUpload, sizeLimitKb],
  );

  const onClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!ref.current) return;

    ref.current.value = '';
    ref.current.click();
  }, []);

  return (
    <div className={containerClassName}>
      <Button
        onClick={onClick}
        className={buttonClassName}
        size={size}
        flat={flat}
        color={color}
        isSubmitting={isSubmitting}
        icon={icon}
      >
        {children || text}
      </Button>
      <input ref={ref} hidden type="file" accept={accept} multiple={multiple} onChange={onChange} />
    </div>
  );
};

export default FileUpload;
