import { gql } from '@apollo/client';

import { botCardFragment } from 'frontend/api/fragments';

// TODO: convert this to .graphql and avoid name confusion with frontend/src/features/Admin/queries/getAllBots.ts
export default gql`
  query GetAllBots {
    allOrganizations {
      id
      name
      bots {
        ...BotCard
      }
    }
  }
  ${botCardFragment}
`;
