const WebhooksIcon = () => (
  <svg viewBox="0 0 48 44">
    <title>Webhooks dashboard empty state icon</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-324 -243)" fillRule="nonzero">
        <g transform="translate(324 243)">
          <path
            id="Path"
            d="m2.3806 42.59-1.9722-1.9722c-0.5445-0.54467-0.5445-1.4276 0-1.9722l17.146-17.146-17.146-17.146c-0.5445-0.54467-0.5445-1.4276 0-1.9722l1.9722-1.9722c0.54467-0.5445 1.4276-0.5445 1.9722 0l20.105 20.105c0.5445 0.54467 0.5445 1.4276 0 1.9722l-20.105 20.105c-0.54467 0.5445-1.4276 0.5445-1.9722 0z"
            fill="#000"
          />
          <path
            id="Path"
            d="m48 41.026c0 0.77032-0.62447 1.3948-1.3948 1.3948h-26.501c-0.77032 0-1.3948-0.62447-1.3948-1.3948v-2.7896c0-0.77032 0.62447-1.3948 1.3948-1.3948h26.501c0.77032 0 1.3948 0.62447 1.3948 1.3948v2.7896z"
            fill="#DA3CFF"
          />
          <path
            id="Path"
            d="m48 22.894c0 0.77032-0.62447 1.3948-1.3948 1.3948h-13.948c-0.77032 0-1.3948-0.62447-1.3948-1.3948v-2.7896c0-0.77032 0.62447-1.3948 1.3948-1.3948h13.948c0.77032 0 1.3948 0.62447 1.3948 1.3948v2.7896z"
            fill="#0069FF"
          />
          <path
            id="a"
            d="m48 4.7615c0 0.77032-0.62447 1.3948-1.3948 1.3948h-26.501c-0.77032 0-1.3948-0.62447-1.3948-1.3948v-2.7896c0-0.77032 0.62447-1.3948 1.3948-1.3948h26.501c0.77032 0 1.3948 0.62447 1.3948 1.3948v2.7896z"
            fill="#000"
          />
          <path
            d="m48 4.7615c0 0.77032-0.18606 1.3948-0.41558 1.3948h-7.8961c-0.22952 0-0.41558-0.62447-0.41558-1.3948v-2.7896c0-0.77032 0.18606-1.3948 0.41558-1.3948h7.8961c0.22952 0 0.41558 0.62447 0.41558 1.3948v2.7896z"
            fill="#0069FF"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default WebhooksIcon;
