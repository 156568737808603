import PropTypes from 'prop-types';
import { useEffect } from 'react';

const HardRedirect = ({ next }) => {
  useEffect(() => {
    window.location = next;
  }, [next]);

  return <div>Redirecting to server side view...</div>;
};

HardRedirect.propTypes = {
  next: PropTypes.string.isRequired,
};

export default HardRedirect;
