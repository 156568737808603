import { useMutation, useQuery } from '@apollo/client';
import { compose, get, isEqual } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { getAvailableLanguages, getSkillSettings } from 'frontend/api/queries';
import { Checkbox, Loader, PageBar, Panel } from 'frontend/components';
import UPDATE_SKILL_LANGUAGES from 'frontend/features/SkillSettings/mutations/updateSkillLanguages';
import { useTabTitle } from 'frontend/hooks';
import type { Language } from 'frontend/types/language';
import { mapToObject } from 'frontend/utils';

const hasName = (name) => compose(isEqual(name), get('name'));

const EMPTY_ARRAY = [];
const LANGUAGE_QUERY_CONFIG = { variables: { onlyEnabled: true } };

const Languages = () => {
  const { skillId } = useParams();
  const { data: skillData, loading: skillLoading } = useQuery(getSkillSettings, { variables: { skillId } });
  const { data: languagesData, loading: languagesLoading } = useQuery(getAvailableLanguages, LANGUAGE_QUERY_CONFIG);
  const [update] = useMutation(UPDATE_SKILL_LANGUAGES);
  const availableLanguages = languagesData?.availableLanguages ?? EMPTY_ARRAY;

  useTabTitle('Settings');

  const initialValues = useMemo(() => {
    const languages = skillData?.skill?.languages ?? [];
    const languageCodes = languages.map(({ code }) => code);

    return mapToObject(({ code, name }) => ({ [name]: languageCodes.includes(code) }), availableLanguages);
  }, [availableLanguages, skillData]);

  const onSubmit = useCallback(
    async (languages: Language[] = []) => {
      const languageCodes = Object.entries(languages)
        .filter(([, active]) => active)
        .map(([name]) => availableLanguages.find(hasName(name))?.code);

      await update({ variables: { skillId, languageCodes } });
    },
    [availableLanguages, skillId, update],
  );

  if (skillLoading || languagesLoading) return <Loader size="large" />;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <PageBar>
            <PageBar.FormButtons />
          </PageBar>
          <Panel>
            {availableLanguages.map(({ name }) => (
              <Field key={name} name={name} component={Checkbox} type="checkbox" label={name} />
            ))}
          </Panel>
        </form>
      )}
    />
  );
};

export default Languages;
