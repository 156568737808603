import cx from 'classnames';
import React, { cloneElement, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useOutsideClick } from 'rooks';

import { useOnEscape } from 'frontend/hooks';

import styles from './ContextMenu.scss';

// This is a map that stores the toggleMenu function for each ContextMenu's `id`, so that we can enforce only one ContextMenu open per `id` at a time.
const MENU_OPEN_CONTEXT_FNS = new Map();

const isMenuMostLikelyOverflowing = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  menuEl: HTMLElement | null,
): { x: boolean; y: boolean } => {
  const { clientY, clientX } = event;
  const { innerHeight, innerWidth } = window;

  const hypotheticalBigMenuHeight = menuEl?.children[0]?.clientHeight || 300;
  const hypotheticalBigMenuWidth = menuEl?.children[0]?.clientWidth || 300;

  return { x: clientX + hypotheticalBigMenuWidth > innerWidth, y: clientY + hypotheticalBigMenuHeight > innerHeight };
};

interface OverlayExtraProps {
  close: () => void;
}

interface Props {
  children: React.ReactNode;
  /** If true, don't display the component at all. */
  disabled?: boolean;
  /** The identifier of the ContextMenu. This is used so that there won't be more than one ContextMenu open per `id` at one time. */
  id: string;
  /** A ReactElement/JSX.Element, either custom or from some templates.
   * @example <ContextMenu overlay={<MenuOverlay options={options} />} />
   * @example <ContextMenu overlay={<div>Test</div>} />
   */
  overlay: React.JSX.Element;
  menuPosition?: 'left' | 'right';
}

export type ContextMenuRef = {
  openMenu: React.MouseEventHandler<HTMLDivElement | SVGSVGElement | HTMLButtonElement>;
};

/** Component to wrap around other components when you want to trigger a custom context menu (replacing the browser one). */
const ContextMenu = forwardRef<ContextMenuRef, Props>(
  ({ overlay, children, id, disabled, menuPosition = 'right' }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [XY, setXY] = useState({ x: 0, y: 0 });
    const [isMenuMaybeOverflowing, setIsMenuMaybeOverflowing] = useState({ x: false, y: false });

    const menuRef = useRef<HTMLDivElement>(null);

    const handleContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        event.preventDefault();

        const toggleMenuFunctionInCtx = MENU_OPEN_CONTEXT_FNS.get(id);
        toggleMenuFunctionInCtx?.(false);
        MENU_OPEN_CONTEXT_FNS.set(id, setIsOpen);

        setXY({ x: event.clientX, y: event.clientY });
        setIsMenuMaybeOverflowing(isMenuMostLikelyOverflowing(event, menuRef.current));
        setIsOpen(true);
      },
      [id, menuRef],
    );

    const closeMenu = useCallback(() => {
      MENU_OPEN_CONTEXT_FNS.delete(id);

      setIsOpen(false);
    }, [id]);

    useImperativeHandle(
      ref,
      () => ({
        openMenu: handleContextMenu,
        closeMenu,
      }),
      [handleContextMenu, closeMenu],
    );

    useOnEscape(closeMenu);
    useOutsideClick(menuRef, closeMenu);

    return (
      <div onContextMenu={handleContextMenu}>
        {children}
        <div className={styles.contextMenuOverlayWrapper} ref={menuRef}>
          {isOpen && !disabled && (
            <div
              className={cx(styles.overlayWrapper, {
                [styles.translateRight]: isMenuMaybeOverflowing.x,
                [styles.translateUp]: isMenuMaybeOverflowing.y,
                [styles.menuLeft]: menuPosition === 'left',
              })}
              style={
                {
                  '--_contextMenuTop': `${XY.y}px`,
                  '--_contextMenuLeft': `${XY.x}px`,
                } as React.CSSProperties
              }
            >
              {cloneElement<OverlayExtraProps>(overlay, { close: closeMenu })}
            </div>
          )}
        </div>
      </div>
    );
  },
);

ContextMenu.displayName = 'ContextMenu';

export default ContextMenu;
