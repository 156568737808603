import { gql } from '@apollo/client';

import { COMMENT } from '../fragments';

export default gql`
  mutation DeleteChatComment($botId: ID!, $chatId: ID!, $commentId: ID!) {
    deleteChatComment(botId: $botId, chatId: $chatId, commentId: $commentId) {
      ...ChatComment
    }
  }
  ${COMMENT}
`;
