import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { ConnectorDocument } from 'frontend/api/generated';
import defaultLogo from 'frontend/assets/images/plugin_logo.png';
import { Button, Loader } from 'frontend/components';
import ConnectDetails from 'frontend/features/Connect/components/ConnectDetails/ConnectDetails';
import { LOGO_URLS } from 'frontend/features/Connect/constants';

import styles from './styles.scss';
import { FacebookMessenger } from '../FacebookMessenger/FacebookMessenger';
import KindlyChat from '../KindlyChat';
import { SlackBot } from '../SlackBot/SlackBot';
import ZendeskHandover from '../ZendeskHandover/ZendeskHandover';

const Connector = () => {
  const { connectorId } = useParams();
  const { data, loading } = useQuery(ConnectorDocument, {
    variables: { connectorId: connectorId as string },
  });

  if (loading) {
    return <Loader size="large" />;
  }

  if (!data?.connector) return null;

  const {
    connector: { name, description, connectorType, ctaButtonLabel, ctaButtonValue, logoUrl, slug },
  } = data;

  return (
    <ConnectDetails backButtonTitle={name}>
      <img className={styles.logo} src={LOGO_URLS[`${slug}`] || logoUrl || defaultLogo} alt={name} />
      <div className={styles.textContainer}>
        <h3>{name}</h3>
        {description.split('\n').map((descriptionParagraph, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={`description-paragraph-${index}`}>{descriptionParagraph}</p>
        ))}
        {connectorType === 'CUSTOM' && (
          <p>
            Custom integrations need special care and setup. Contact us if your are interested in learning more about
            our {name} integration.
          </p>
        )}
      </div>
      <>
        {ctaButtonValue && ctaButtonLabel && (
          <Button className={styles.ctaButton} color="primary" href={ctaButtonValue}>
            {ctaButtonLabel}
          </Button>
        )}
        {slug === 'messenger' && <FacebookMessenger />}
        {slug === 'kindly-chat' && <KindlyChat />}
        {slug === 'zendesk-chat' && <ZendeskHandover />}
        {slug === 'slack' && <SlackBot />}
      </>
    </ConnectDetails>
  );
};

export default Connector;
