/**
 * @param relevantLanguageCode - The language code to check for
 * @param equal - Whether to check for inclusion or exclusion
 *
 * Curried function to pass as a callback in a `array.filter()` or similar, that returns `true` for
 * every item that has a `languageCode` property that matches the `relevantLanguageCode`.
 */
export default function hasLanguage(relevantLanguageCode: string, equal: boolean = true) {
  return ({ languageCode }: { languageCode: string }) => (languageCode === relevantLanguageCode) === equal;
}
