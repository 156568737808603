import { useState } from 'react';

import { ChevronDown, ChevronRight } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './DetailsAccordion.scss';

interface DetailsAccordionProps {
  children: React.ReactNode;
  header: React.ReactNode;
  defaultOpen: boolean;
  dataTestId?: string;
}

const DetailsAccordion = ({ children, header, defaultOpen, dataTestId }: DetailsAccordionProps) => {
  const [open, setOpen] = useState(defaultOpen ?? false);

  return (
    <>
      <div
        data-testid={dataTestId}
        className={styles.accordion}
        onClick={() => setOpen(!open)}
        onKeyDown={(e) => e.key === 'Enter' && setOpen(!open)}
        tabIndex={0}
        role="button"
        aria-expanded={open}
      >
        {header}
        <Icon component={open ? ChevronDown : ChevronRight} title={open ? 'Minimize' : 'Expand'} />
      </div>
      <div className={styles.children} style={{ display: open ? 'flex' : 'none' }}>
        {children}
      </div>
    </>
  );
};

export default DetailsAccordion;
