import { formatISO } from 'date-fns/formatISO';
import { identity, pickBy } from 'lodash';

import { AGENT_VIEW_FILTERS } from 'frontend/state/dux/inbox';

import { FILTER_TYPES } from '../../../constants';

const boolObjectToArray = (values = {}) => Object.keys(pickBy(values, identity));

export const convertFormValuesToQueryParams = (formValues) => {
  const params = new URLSearchParams();

  Object.keys(formValues).forEach((filter) => {
    const value = formValues[filter];

    // when the form value is a non-empty object
    if (typeof value === 'object' && !Array.isArray(value) && value != null && Object.keys(value).length > 0) {
      const valuesArray = boolObjectToArray(value);

      if (valuesArray.length > 0) {
        params.set(filter, valuesArray.join(','));
      }
    }

    // when the form value is a non-empty string
    else if (typeof value === 'string' && value !== '0' && value) {
      params.set(filter, value);
    }

    // when the form value is a non-empty array
    else if (Array.isArray(value) && value.length > 0) {
      const [{ key, endDate, startDate }] = value;
      const isDateFilterValue = FILTER_TYPES.datePicker === key;

      if (isDateFilterValue) {
        const areActualDates = startDate instanceof Date && endDate instanceof Date;

        if (areActualDates) {
          params.set('startDate', formatISO(startDate, { representation: 'date' }));
          params.set('endDate', formatISO(endDate, { representation: 'date' }));
        }
      } else {
        params.set(filter, value.join(','));
      }
    }
  });

  return params.toString();
};

export const convertQueryParamsToPersistedValues = (queryParamsString) => {
  const queryParams = new URLSearchParams(queryParamsString);

  return Array.from(queryParams.entries()).reduce(
    (obj, [key, value]) => {
      if (FILTER_TYPES.STRING.includes(key)) {
        obj[key] = value;
      } else if (key === 'startDate' && value) {
        obj.dateFilter = [
          {
            ...(obj?.dateFilter?.[0] || {}),
            startDate: new Date(value),
            key: 'dateFilter',
          },
        ];
      } else if (key === 'endDate' && value) {
        obj.dateFilter = [
          {
            ...(obj?.dateFilter?.[0] || {}),
            endDate: new Date(new Date(value).setHours(23, 59, 59)),
            key: 'dateFilter',
          },
        ];
      } else if (value) obj[key] = value.split(',');

      return obj;
    },
    {} as {
      ticketAgentFilter?: keyof typeof AGENT_VIEW_FILTERS | string;
      dateFilter: [
        {
          startDate?: Date | null;
          endDate?: Date | null;
          key: 'dateFilter';
        },
      ];
    },
  );
};

export const filterActiveFilters = (values) =>
  Object.entries(values)
    .filter(
      ([key, value]) =>
        !!(
          (Array.isArray(value) ? value.length : value) &&
          key !== 'ticketStatusFilter' &&
          !Object.values(AGENT_VIEW_FILTERS).includes(value as string)
        ),
    )
    .map(([key]) => key);

export const checkActiveFilters = (values, searchParams: URLSearchParams) => {
  const filteredValues = filterActiveFilters(values);
  if (searchParams.has('ticketStatusFilter')) {
    searchParams.delete('ticketStatusFilter');
  }
  if (
    searchParams.has('ticketAgentFilter') &&
    Object.values(AGENT_VIEW_FILTERS).includes(searchParams.get('ticketAgentFilter') as string)
  ) {
    searchParams.delete('ticketAgentFilter');
  }

  const hasActiveFilters = !!filteredValues.length && searchParams.size > 0;

  return { filteredValues, hasActiveFilters };
};
