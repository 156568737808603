import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { LoaderSwitch, Select } from 'frontend/components';
import { ModalForm, useModal } from 'frontend/features/Modals';
import AddMemberToOrganization from 'frontend/features/Organization/modals/AddMemberToOrganization/AddMemberToOrganization';
import { required } from 'frontend/form/validators';
import { getArray } from 'frontend/utils';

import { GET_ALL_ORGANIZATIONS } from '../../../graphql';

const initialValues = { organizationId: null };

const InviteUser = ({ hide }) => {
  const [showAddMemberModal] = useModal(AddMemberToOrganization);
  const { data, loading } = useQuery(GET_ALL_ORGANIZATIONS);
  const organizationList = getArray('allOrganizations', data);

  const onSubmit = useCallback(
    ({ organizationId }) => {
      const { name } = organizationList.find(({ id }) => id === organizationId);
      showAddMemberModal({ id: organizationId, name, onFinished: hide });
    },
    [hide, organizationList, showAddMemberModal],
  );

  return (
    <ModalForm hide={hide} onSubmit={onSubmit} initialValues={initialValues} title="Invite" closeOnSubmit={false}>
      <LoaderSwitch loading={loading} size="large" noMargin>
        <label htmlFor="select-organizationId">Organization</label>
        <Field component={Select} name="organizationId" validate={required}>
          <Select.Option key="add-to-organization-0" value="" label="Choose organization" disabled />
          {organizationList.map(({ id, name }) => (
            <Select.Option key={`add-to-organization-${id}`} value={id} label={name} />
          ))}
        </Field>
      </LoaderSwitch>
    </ModalForm>
  );
};

InviteUser.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default InviteUser;
