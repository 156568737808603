import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getAccessToken } from 'frontend/state/dux/auth';
import { upload } from 'frontend/utils';

import useToast from './useToast';

export type UploadFileType =
  | Blob
  | undefined
  | Blob[]
  | { extra: object; file: Blob }
  | { extra: object; file: Blob }[];

export default (onSubmit, uploadPath, extra = {}) => {
  const [file, setFile] = useState<UploadFileType>();
  const dispatch = useDispatch();
  const toast = useToast();

  const handleUpload = useCallback(async () => {
    if (!file) return undefined;

    // @ts-expect-error: FIXME: type dispatch stuff correctly
    const accessToken: string = await dispatch(getAccessToken());

    if (Array.isArray(file)) {
      return Promise.all(
        file.map((f) =>
          upload(uploadPath, accessToken, f?.file || f, {
            ...extra,
            ...(f.extra || {}),
          }),
        ),
      )
        .then((results) => Promise.all(results.map((res) => res.json())))
        .catch((response) => {
          if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
        });
    }
    const response = await upload(uploadPath, accessToken, (file as { file: Blob; extra: object })?.file || file, {
      ...extra,
      ...((file as { file: Blob; extra: object }).extra || {}),
    });
    if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
    return response;
  }, [dispatch, file, uploadPath, extra]);

  const onSubmitWithUpload = useCallback(
    async (values, form) => {
      try {
        const uploadData = await handleUpload();
        setFile(undefined);
        return onSubmit(values, form, uploadData, setFile);
      } catch (err) {
        toast.error(`Could not upload image. ${err ? err.toString() : ''}`);
        return undefined;
      }
    },
    [handleUpload, onSubmit, toast],
  );

  return [onSubmitWithUpload, setFile];
};
