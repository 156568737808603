import { gql } from '@apollo/client';

const GET_ZODIAC_ENABLED = gql`
  query ZodiacEnabled($botId: ID!) {
    bot(id: $botId) {
      id
      zodiacSettings {
        id
        enabled
        languageCode
      }
    }
  }
`;

export default GET_ZODIAC_ENABLED;
