import { gql } from '@apollo/client';

import { KINDLY_ENTITY } from '../fragments';

export default gql`
  query KindlyEntities($languageCode: String!, $botId: ID, $skillId: ID) {
    kindlyEntities(languageCode: $languageCode, botId: $botId, skillId: $skillId) {
      ...KindlyEntity
    }
  }
  ${KINDLY_ENTITY}
`;
