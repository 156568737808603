import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Keyword } from 'frontend/assets/icons';
import { Modal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import styles from './GeneratePassword.scss';

const GeneratePassword = ({ hide, args: { password, moreThanOneChunk } }) => {
  const toast = useToast();

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(password);
    toast.success('Copied to clipboard');
    hide();
  }, [hide, password, toast]);

  return (
    <Modal icon={Keyword} title="Generated password" hide={hide} onOk={onCopy} onOkText="Copy to Clipboard">
      <Modal.Content className="text-center">
        <p className="m-b">
          The password below is required to open the backup file. We will not save it, and it will be impossible to
          retreive later, so remember to write it down somewhere safe!
        </p>
        {moreThanOneChunk && (
          <p className={styles.multipleWarning}>
            N.B. Backup was split into several files due to the size. <br />
          </p>
        )}
        <input className={styles.passwordInput} value={password} readOnly />
      </Modal.Content>
    </Modal>
  );
};

GeneratePassword.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    password: PropTypes.string.isRequired,
    moreThanOneChunk: PropTypes.bool.isRequired,
  }).isRequired,
};

export default GeneratePassword;
