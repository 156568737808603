import PropTypes from 'prop-types';
import { memo } from 'react';

import { SampleWithEntities } from 'frontend/features/Entities/components';
import { EntityInstancesType } from 'frontend/features/Entities/propTypes';

import styles from './SampleDetail.scss';

const SampleDetail = ({ text, instances, match, isSelected = false }) => {
  const instancesWithId = instances.filter(({ entity, kindlyEntity }) => (entity || kindlyEntity)?.id);
  const hasEntityInstances = Boolean(instancesWithId.length);
  const className = isSelected ? 'disableSelect' : '';

  if (!match && !hasEntityInstances) return <span className={className}>{text}</span>;
  if (!match) return <SampleWithEntities instances={instancesWithId} text={text} className={className} />;

  const { index, length } = match;
  return (
    <>
      {!hasEntityInstances && <span className={className}>{text}</span>}
      {hasEntityInstances && <SampleWithEntities instances={instancesWithId} text={text} className={className} />}
      <div className={styles.sampleMatchContainer}>
        <span className={styles.sampleMatchBefore}>{text.slice(0, index)}</span>
        <span className={styles.sampleMatch}>{text.slice(index, index + length)}</span>
      </div>
    </>
  );
};

SampleDetail.propTypes = {
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  match: PropTypes.shape({
    index: PropTypes.number,
    length: PropTypes.number,
  }),
  instances: EntityInstancesType.isRequired,
};

export default memo(SampleDetail);
