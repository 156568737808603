import { isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';

import FiltersWrapper from './components/FiltersWrapper/FiltersWrapper';
import { convertFormValuesToQueryParams, convertQueryParamsToPersistedValues } from './utils/helpers';

const initialFilterValues = {};
const InboxFilters = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const putFormValuesInQueryParams = useCallback(
    (paramsString) => {
      navigate(
        { search: paramsString },
        {
          replace: true,
        },
      );
    },
    [navigate],
  );

  const onSubmit = (values) => {
    const queryParamsFromFormValues = convertFormValuesToQueryParams(values);
    putFormValuesInQueryParams(queryParamsFromFormValues);
  };

  const initialValues = useMemo(() => {
    const pageQueryParams = location.search;

    if (pageQueryParams) {
      const queryParamsAsPersistedValues = convertQueryParamsToPersistedValues(pageQueryParams);

      const extendedInitialValues = { ...queryParamsAsPersistedValues };

      if (!isEqual(initialFilterValues, extendedInitialValues)) {
        return extendedInitialValues;
      }
    }
    return initialFilterValues;
  }, [location.search]);

  return (
    <Form initialValuesEqual={isEqual} onSubmit={onSubmit} initialValues={initialValues} component={FiltersWrapper} />
  );
};

export default InboxFilters;
