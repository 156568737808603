/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export default Object.freeze({
  DIALOGUE: 'DIALOGUE',
  SYSTEM_DIALOGUE: 'SYSTEM_DIALOGUE',
  FALLBACK: 'FALLBACK',
  GREETING: 'GREETING',
  LABELS: 'LABELS',
});

export type Item = 'dialogue' | 'system-dialogue' | 'fallback' | 'greeting' | 'labels';
