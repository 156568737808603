import { useMemo } from 'react';

import { Panel } from 'frontend/components';
import { Grid, Heading, StatsPanel } from 'frontend/features/Analytics/components/';
import type {
  CampaignData,
  OnsiteCampaignData,
} from 'frontend/features/Organization/views/Conversion/hooks/useEngagementStats';

import { Stat } from '..';
import shared from '../../styles.scss';

function averageOrderValue(campaign) {
  const conversions = campaign?.onsiteConversions || 0;
  const value = campaign?.onsiteConversionsValue || 0;

  if (conversions === 0) {
    return 0;
  }

  return Math.round(value / conversions);
}

const OnsiteRemarketingStats = ({
  campaigns,
  loading,
  error,
}: {
  campaigns: CampaignData[];
  loading: boolean;
  error?: string;
}) => {
  const onsiteCampaings = useMemo(
    () => (campaigns || []).filter((campaign): campaign is OnsiteCampaignData => campaign.campaignType === 'onsite'),
    [campaigns],
  );

  if (loading || error) {
    return (
      <>
        <Heading title="Nudges" />
        <StatsPanel loading={loading} error={error} />
      </>
    );
  }

  return (
    <>
      <Heading title="Nudges" />
      {onsiteCampaings.map((campaign) => (
        <div key={`campain-${campaign?.campaignName}`} className={shared.stats}>
          <StatsPanel loading={loading} error={error}>
            <h4 className={shared.statTitle}>{campaign?.campaignName ?? 'Nudge'}</h4>
            <Grid className={shared.grid}>
              <Panel className={shared.panel}>
                <Stat name="Average order value" value={averageOrderValue(campaign)} type={campaign?.currency} />
              </Panel>
              <Panel className={shared.panel}>
                <Stat name="Total order value" value={campaign?.onsiteConversionsValue} type={campaign?.currency} />
              </Panel>
            </Grid>
            <Grid className={shared.grid}>
              <Panel className={shared.panel}>
                <Stat name="Displays" value={campaign?.onsiteDisplayed} />
              </Panel>
              <Panel className={shared.panel}>
                <Stat name="Display rate" value={campaign?.displayRate} type="%" />
              </Panel>
            </Grid>
            <Grid className={shared.grid}>
              <Panel className={shared.panel}>
                <Stat name="Clicks" value={campaign?.engagedOnsiteClicks} />
              </Panel>
              <Panel className={shared.panel}>
                <Stat name="Click through rate" value={campaign?.clickThroughRate} type="%" />
              </Panel>
            </Grid>
            <Grid className={shared.grid}>
              <Panel className={shared.panel}>
                <Stat name="Conversions" value={campaign?.onsiteConversions} />
              </Panel>
              <Panel className={shared.panel}>
                <Stat name="Click to conversion" value={campaign?.conversionRate} type="%" />
              </Panel>
            </Grid>
          </StatsPanel>
        </div>
      ))}
    </>
  );
};
export default OnsiteRemarketingStats;
