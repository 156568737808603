import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { DropdownButton } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { UpdateInvitation as UpdateInvitationModal } from 'frontend/features/Organization/modals';
import { IDType } from 'frontend/propTypes';

import styles from './InvitationsList.scss';
import { DeleteInvitation } from '../../modals';

const UpdateInvitation = ({ data: { id, email, organizationId } }) => {
  const [showDeleteModal] = useModal(DeleteInvitation);
  const [showUpdateModal] = useModal(UpdateInvitationModal);

  const actions = useMemo(
    () => [
      {
        title: 'Update invitation',
        key: 'update-invitation',
        onClick: () => showUpdateModal({ id, email, organizationId }),
      },
      {
        title: 'Delete invitation',
        key: 'delete-invitation',
        onClick: () => showDeleteModal({ id, email, organizationId }),
      },
    ],
    [email, id, organizationId, showDeleteModal, showUpdateModal],
  );

  return (
    <DropdownButton wrapperClassName={styles.options} actions={actions}>
      <span className={styles.optionsButton} />
    </DropdownButton>
  );
};

UpdateInvitation.propTypes = {
  data: PropTypes.shape({
    id: IDType.isRequired,
    organizationId: IDType.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

export default UpdateInvitation;
