import { useMemo } from 'react';

export default (indentationLevel: number) => {
  const indentationStyle = useMemo(() => {
    const indentationPixels = indentationLevel * 15;

    return {
      position: 'relative',
      left: `${indentationPixels}px`,
      width: `calc(100% - ${indentationPixels}px)`,
    };
  }, [indentationLevel]);

  return indentationStyle;
};
