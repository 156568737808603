import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { removeListItemById } from 'frontend/api/cacheHelpers';
import { getChatPath } from 'frontend/features/Inbox/utils';
import { DELETE_CHAT_MUTATION } from 'frontend/features/Inbox/views/MetaData/hooks/DELETE_CHAT_MUTATION';
import { useToast } from 'frontend/hooks';

export default ({ botId, organizationId, chatId }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [deleteChat] = useMutation(DELETE_CHAT_MUTATION, {
    variables: {
      botId,
      chatId,
    },
    update: (cache) => {
      cache.modify({
        id: `ChatsAndTicketsType:chats-and-tickets-bot-${botId}`,
        fields: { chats: removeListItemById(chatId) },
      });

      toast.success('Chat deleted');
      navigate(getChatPath('chat/deleted', { botId, organizationId }));
    },
  });

  return deleteChat;
};
