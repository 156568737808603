import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import Input from 'frontend/components/Input/Input';
import SelectDialogue from 'frontend/components/SelectDialogue/SelectDialogue';
import { FIELD_COLOR } from 'frontend/constants';
import { GetDialogueList } from 'frontend/features/Build/graphql';

import type { ButtonType, LocalErrors } from '../ButtonsMeta';

interface Props {
  buttonForm: ButtonType;
  setButtonForm: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  localErrors: LocalErrors;
  validateButton: () => void;
}

export default function TriggerFollowupMeta({ buttonForm, setButtonForm, localErrors, validateButton }: Props) {
  const { skillId, botId } = useParams();
  const buildIdObject = { skillId, botId };
  const { data } = useQuery(GetDialogueList, {
    variables: { ...buildIdObject, includeSubscribedSkills: true, excludeGreetings: true },
    fetchPolicy: 'network-only',
  });

  // <SelectDialogue> doesn't pass an event to its onChange handler (just the id), so we fake one for simplicity
  const handleDialogueChange = (dialogueId: string) => {
    const fakeEvent = {
      target: {
        name: 'value',
        value: dialogueId,
      },
    } as React.ChangeEvent<HTMLSelectElement>;
    setButtonForm(fakeEvent);
  };

  return (
    <>
      <Input
        input={{ name: 'label', value: buttonForm.label, onChange: setButtonForm }}
        placeholder="Button text"
        meta={localErrors.label || {}}
        fieldColor={FIELD_COLOR.MISCHKA}
      />
      <SelectDialogue
        shortDropdown
        placeholder="Dialogue"
        dialogueList={data?.dialogueList}
        input={{
          value: buttonForm.value,
          onChange: handleDialogueChange,
          name: 'value',
          onBlur: () => {
            if (localErrors.value) validateButton();
          },
          onFocus: () => {},
        }}
        meta={localErrors.value || {}}
      />
    </>
  );
}
