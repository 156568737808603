import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { Loader } from 'frontend/components';
import { useBotOrSkill, useClickOutside } from 'frontend/hooks';

import styles from './FlowDialoguePreview.scss';
import { GetDialogue } from '../../graphql';
import Preview from '../Preview';

const FlowDialoguePreview = ({ dialogueId, setPreview }) => {
  const ref = useRef();
  const { buildIdObject } = useBotOrSkill();
  const { data, loading } = useQuery(GetDialogue, { variables: { ...buildIdObject, dialogueId } });

  useClickOutside(ref, () => setPreview(false));

  return (
    <div className={styles.preview} ref={ref}>
      {loading && <Loader />}
      {!loading && <Preview dialogue={data?.dialogue} isFlowBuilder isModDialogue={false} />}
    </div>
  );
};

FlowDialoguePreview.propTypes = {
  dialogueId: PropTypes.string.isRequired,
  setPreview: PropTypes.func.isRequired,
};

export default FlowDialoguePreview;
