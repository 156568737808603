import { Calendar, Connect, Timezone } from 'frontend/assets/icons';
import Button from 'frontend/components/Button/Button';

import type { ConditionCategory } from './ConditionForm/ConditionForm';
import styles from './ConditionPicker.scss';

interface Props {
  onButtonClick: (ruleType: ConditionCategory) => void;
}

export default function ConditionPicker({ onButtonClick }: Props): React.JSX.Element {
  return (
    <div className={styles.ruleButtons}>
      <Button
        icon={Calendar}
        color="secondary"
        text="Date"
        onClick={() => {
          onButtonClick('DATE');
        }}
      />
      <Button
        icon={Timezone}
        color="secondary"
        text="Time"
        onClick={() => {
          onButtonClick('TIME');
        }}
      />
      <Button
        icon={Connect}
        color="secondary"
        text="Context"
        onClick={() => {
          onButtonClick('CONTEXT');
        }}
      />
    </div>
  );
}
