import cx from 'classnames';

import styles from './styles.scss';
import animationStyles from '../animation.scss';

const GhostLoaderTop = () => (
  <div className={cx(styles.ghostContainer, styles.topContainer)} aria-labelledby="Chat list loading">
    <div className={cx(styles.ghostStripe, animationStyles.ghostStripe)}>
      <div className={styles.ghostCircle}>
        <svg width="100%" height="70px">
          <defs>
            <mask id="metaDataTop1" x="0" y="0" width="100%" height="70px">
              <rect x="0" y="0" width="100%" height="70px" fill="#fff" />
              <circle cx="22" cy="22" r="22" />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="70px" mask="url(#metaDataTop1)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>
      <div className={styles.ghostLines}>
        <svg width="100%" height="40px" preserveAspectRatio="none">
          <defs>
            <mask id="metaDataTop3" x="0" y="0" width="100%" height="40px">
              <rect x="0" y="0" width="100%" height="100" fill="#FFF" />
              <rect x="0" y="3px" rx="2" ry="2" width="100%" height="20px" />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="40px" mask="url(#metaDataTop3)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>
    </div>
  </div>
);

export default GhostLoaderTop;
