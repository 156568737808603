import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DIALOGUE_TYPES } from 'frontend/constants';
import { useCurrentLanguage, usePrevious } from 'frontend/hooks';
import { selectHasChanges, setHasChanges as setBuildHasChanges } from 'frontend/state/dux/build';
import { formHasChanged } from 'frontend/utils';

interface Props {
  hasChanges: boolean;
  setHasChanges: (hasChanges: boolean) => void;
  notifyBuild?: boolean;
}

export default function NoteFormChanges({ hasChanges, setHasChanges, notifyBuild = false }: Props): null {
  const dispatch = useDispatch();
  const state = useFormState();
  const buildHasChanges = useSelector(selectHasChanges);
  const [{ selectedLanguage }] = useCurrentLanguage();
  const params = useParams();
  const relevantParams = useMemo(() => ({ ...params, selectedLanguage }), [selectedLanguage, params]);
  const prevRelevantParams = usePrevious(relevantParams);

  const isIntentDialogue = state.values?.dialogueType === DIALOGUE_TYPES.INTENT;

  useEffect(() => {
    const updatedHasChanges = formHasChanged(state, !isIntentDialogue);
    setHasChanges(Boolean(updatedHasChanges));
  }, [isIntentDialogue, setHasChanges, state]);

  useEffect(() => {
    if (!notifyBuild) return;
    // Need this in redux so that library can know whether build has
    // changes when attempting to change language
    if (hasChanges !== buildHasChanges) dispatch(setBuildHasChanges(hasChanges));
  }, [dispatch, buildHasChanges, hasChanges, notifyBuild]);

  useEffect(() => {
    // Reset when changing essential parameters
    if (!isEqual(relevantParams, prevRelevantParams)) setHasChanges(false);
  }, [prevRelevantParams, relevantParams, setHasChanges]);

  return null;
}
