import { SplitChatBubble } from 'frontend/components';

import { useBuildItems } from './hooks';

interface Props {
  currentLanguage: string;
  dialogue: {
    id: string | number;
    replies: { text: string }[];
  };
}

export default function PluginDialogue({ dialogue, currentLanguage }: Props) {
  const dialogueReplies: { [key: string]: string }[] = useBuildItems(dialogue, currentLanguage, null, 'replies');

  if (dialogueReplies.length === 0) return null;

  // @ts-expect-error Type useBuildItems hook
  return <SplitChatBubble text={dialogueReplies[0].text} hasImage={false} />;
}
