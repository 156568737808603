import cx from 'classnames';

import styles from './Modal.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Content = ({ children, className }: Props) => <div className={cx(styles.content, className)}>{children}</div>;

export default Content;
