import { gql } from '@apollo/client';

import { DialogueFragment } from 'frontend/features/Build/graphql/fragments/DialogueFragment';

// FIXME: convert to graphql and update fixture in kindly/apps/build/tests/graphql/conftest.py
export const CreateDialogueMutation = gql`
  mutation CreateDialogue(
    $botId: ID
    $skillId: ID
    $parentId: UUID
    $topicId: ID
    $dialogueModParentId: UUID
    $dialogueInput: DialogueInput!
    $languageCode: String!
  ) {
    createDialogue(
      botId: $botId
      skillId: $skillId
      parentId: $parentId
      topicId: $topicId
      dialogueModParentId: $dialogueModParentId
      dialogueInput: $dialogueInput
      languageCode: $languageCode
    ) {
      ...Dialogue
    }
  }
  ${DialogueFragment}
`;
