import { noop } from 'lodash';
import { Link } from 'react-router-dom';

import { Modal } from 'frontend/features/Modals';

import styles from './ConfidenceScoreModal.scss';

interface ConfidenceScoreModalProps {
  hide: () => void;
  args: {
    botId: string;
  };
}

const ConfidenceScoreModal = ({ hide, args: { botId } }: ConfidenceScoreModalProps) => (
  <Modal title="Confidence score" hide={hide} onOk={noop} hideCancel>
    <Modal.Content className={styles.content}>
      <p>
        The chatbot assigns a confidence score to each match (potential reply), ranging from 0 (completely uncertain) to
        100 (completely certain). If the confidence score of the best match/reply is higher than the{' '}
        <Link to={`/workspace/${botId}/settings/general`} onClick={hide}>
          confidence threshold
        </Link>
        , the reply is returned to the user, otherwise, a fallback is triggered.
      </p>
    </Modal.Content>
  </Modal>
);

export default ConfidenceScoreModal;
