import { DRAGGABLE_TYPES } from 'frontend/features/Dnd/constants';

export default Object.freeze({
  id: '',
  buttons: [],
  linkUrl: '',
  imageUrl: '',
  title: '',
  description: '',
  altText: '',
  newTab: true,
  price: 0,
  linkText: '',
  productUrl: '',
  __typename: DRAGGABLE_TYPES.IMAGE,
});
