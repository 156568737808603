import { isEqual } from 'lodash';

import insertHighlightTag from './insertHighlightTag';
import removeHighlightTag from './removeHighlightTag';
import { HIGHLIGHT } from '../constants';
import { IterationDone, checkCharacterForTags, selectionContainsTag } from '../utils';

const getCurrentHighlight = (state) => {
  const content = state.getCurrentContent();
  const checkCharacter = checkCharacterForTags([HIGHLIGHT], content);

  try {
    // eslint-disable-next-line no-restricted-syntax
    for (const [blockKey, block] of content.getBlockMap().entries()) {
      const callback = (start, end) => {
        // Abort by error to avoid mutating an external variable in callback
        throw new IterationDone({ start, end, blockKey });
      };

      block.findEntityRanges(checkCharacter, callback);
    }
  } catch (iterationDone) {
    return iterationDone.getResult();
  }

  return undefined;
};

const getSelectionHighlight = (state) => {
  const selection = state.getSelection();
  const anchorKey = selection.getAnchorKey();

  if (!anchorKey || anchorKey !== selection.getFocusKey()) return undefined;
  if (selection.isCollapsed() || selectionContainsTag(state)) return undefined;

  return { start: selection.getStartOffset(), end: selection.getEndOffset(), blockKey: anchorKey };
};

export default (state) => {
  const currentHighlight = getCurrentHighlight(state);
  const selectionHighlight = getSelectionHighlight(state);

  if (selectionHighlight && !currentHighlight) return insertHighlightTag(state);

  if (selectionHighlight && !isEqual(selectionHighlight, currentHighlight)) {
    const stateWithoutHighlightTag = removeHighlightTag(state);
    return insertHighlightTag(stateWithoutHighlightTag);
  }

  if (currentHighlight && !selectionHighlight) return removeHighlightTag(state);

  return state;
};
