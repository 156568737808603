import cx from 'classnames';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { DropdownButton } from 'frontend/components';
import { BUTTON_TYPES } from 'frontend/features/Build/constants';
import { getBuildUrl } from 'frontend/features/Library/utils';
import { useModal } from 'frontend/features/Modals';
import type { BuildIdObject, BuildType } from 'frontend/propTypes/BuildIdObjectType';

import styles from '../components/Buttons/Buttons.scss';
import ButtonsOverviewItem from '../components/Buttons/ButtonsOverviewItem';
import { DuplicateButton } from '../modals';

function DialogueLink({
  buildType,
  buildIdObject,
  dialogueType,
  target,
  isActive,
  title,
}: {
  buildType: BuildType;
  buildIdObject: BuildIdObject;
  dialogueType: string;
  target: string;
  isActive: boolean;
  title: string;
}): React.JSX.Element {
  return (
    <Link
      className={cx(styles.dialogue, { [styles.dialogueDisabled]: !isActive })}
      to={getBuildUrl({ buildType, buildIdObject, dialogueType, target })}
    >
      {title}
    </Link>
  );
}

export default function useButtonsRenderMap({
  buildIdObject,
  buildType,
  refetchButtonsOverview,
  selectedLanguage,
}: {
  buildIdObject: BuildIdObject;
  buildType: BuildType;
  refetchButtonsOverview: CallableFunction;
  selectedLanguage: string;
}) {
  const [showDuplicateModal] = useModal(DuplicateButton);

  const renderMap = useMemo(
    () => ({
      buttonType: ({ data: type, rowData: { urlStatus } }) => <ButtonsOverviewItem type={type} urlStatus={urlStatus} />,
      value: ({ data: value, rowData: { buttonType, dialogueLink } }) => {
        if (buttonType === BUTTON_TYPES.DIALOGUE_TRIGGER) {
          // Render the trigger button values as dialogue links
          const isActive = dialogueLink.dialogue.isActive[selectedLanguage];
          const title = dialogueLink.dialogue.title[selectedLanguage || 'default'];
          const dialogueType = dialogueLink.dialogue.dialogueType;
          return (
            <DialogueLink
              buildType={buildType}
              buildIdObject={buildIdObject}
              dialogueType={dialogueType}
              target={value}
              isActive={isActive}
              title={title}
            />
          );
        }

        return value;
      },
      dialogue: ({ data: { id, title, dialogueType, isActive } }) => (
        <DialogueLink
          buildType={buildType}
          buildIdObject={buildIdObject}
          dialogueType={dialogueType}
          target={id}
          isActive={isActive}
          title={title}
        />
      ),
      actions: ({ data: { button, id } }) => (
        <DropdownButton
          wrapperClassName={styles.actionsButton}
          actions={[
            {
              title: 'Add button to another dialogue',
              key: 'duplicate',
              onClick: () =>
                showDuplicateModal({
                  button,
                  dialogueId: id,
                  buildIdObject,
                  refetchButtonsOverview,
                }),
            },
            {
              title: 'Copy button id to clipboard',
              key: 'copy-button-id',
              onClick: () => navigator.clipboard?.writeText(button.id),
            },
          ]}
        />
      ),
      label: ({ data: label }) => <div className={styles.button}>{label}</div>,
    }),
    [buildIdObject, buildType, showDuplicateModal, refetchButtonsOverview, selectedLanguage],
  );

  return renderMap;
}
