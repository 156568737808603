import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import cx from 'classnames';
import React from 'react';
import { Controller, type UseFormReturn, useFieldArray } from 'react-hook-form';

import { Plus } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import InputRHF from 'frontend/components/Input/InputRHF';
import ToggleRHF from 'frontend/components/ToggleSwitch/ToggleRHF';
import moveArrayItem from 'frontend/utils/moveArrayItem';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './PredefinedButtonSection.scss';
import PreviewCard from './PreviewCard';
import type { CustomerFeedbackInputs } from '../CustomerFeedback';
import FakeButton from '../FakeButton/FakeButton';
import type { RHF_FORMS_NAME, Sentiment } from '../constants';
import shared from '../shared.scss';

interface Props {
  rhfMethods: UseFormReturn<CustomerFeedbackInputs>;
  rhfFormPath: `${typeof RHF_FORMS_NAME}.${number}`;
}

export default function PredefinedButtonsSection({ rhfMethods, rhfFormPath }: Props): React.JSX.Element {
  const { watch, control } = rhfMethods;

  const watchEnableStep = watch(`${rhfFormPath}.predefinedButtons.enableStep`);
  const watchLabels = watch(`${rhfFormPath}.ratingScale.editLabels`);
  const watchUseSentimentButtons = watch(`${rhfFormPath}.predefinedButtons.useSentimentButtons`);
  const watchSentimentButtons = watch(`${rhfFormPath}.predefinedButtons.sentiments`);

  const availableSentiments = Array.from(new Set(watchLabels.map((label) => label.sentiment)));

  const {
    fields: arrayNoSentimentButtons,
    remove: removeNoSentimentButtons,
    append: appendNoSentimentButtons,
    update: updateNoSentimentButtons,
    replace: replaceNoSentimentButtons,
  } = useFieldArray({
    name: `${rhfFormPath}.predefinedButtons.sentiments.noSentiment.buttons`,
    control,
  });
  const {
    fields: arrayPositiveButtons,
    remove: removePositiveButtons,
    append: appendPositiveButtons,
    update: updatePositiveButtons,
    replace: replacePositiveButtons,
  } = useFieldArray({
    name: `${rhfFormPath}.predefinedButtons.sentiments.positive.buttons`,
    control,
  });
  const {
    fields: arrayNeutralButtons,
    remove: removeNeutralButtons,
    append: appendNeutralButtons,
    update: updateNeutralButtons,
    replace: replaceNeutralButtons,
  } = useFieldArray({
    name: `${rhfFormPath}.predefinedButtons.sentiments.neutral.buttons`,
    control,
  });
  const {
    fields: arrayNegativeButtons,
    remove: removeNegativeButtons,
    append: appendNegativeButtons,
    update: updateNegativeButtons,
    replace: replaceNegativeButtons,
  } = useFieldArray({
    name: `${rhfFormPath}.predefinedButtons.sentiments.negative.buttons`,
    control,
  });

  const handleDragEndForSentiment =
    (
      buttons: any,
      replace:
        | typeof replaceNoSentimentButtons
        | typeof replacePositiveButtons
        | typeof replaceNeutralButtons
        | typeof replaceNegativeButtons,
    ) =>
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over?.id && active.id !== over.id) {
        const newButtons = structuredClone(buttons);

        const oldIndex = newButtons.findIndex((button) => button.id === active.id);
        const newIndex = newButtons.findIndex((button) => button.id === over.id);
        moveArrayItem(newButtons, oldIndex, newIndex);

        replace(newButtons);
      }
    };

  return (
    <section className={styles.sectionGrid}>
      <div className={shared.leftSideSection}>
        <div>
          <h4 className={shared.heading}>Predefined buttons</h4>
          <p>
            Make predefined buttons based on the sentiment expressed by the end user in the previous rating step. By
            tailoring the options, you can enhance the accuracy and relevance of the feedback.
          </p>
        </div>

        {watchEnableStep && (
          <div>
            <ToggleRHF
              name={`${rhfFormPath}.predefinedButtons.useSentimentButtons`}
              rhfMethods={rhfMethods}
              label="Enable sentiment specific buttons"
            />
            <p className={styles.useSentimentButtonsLabel}>
              {' '}
              Showcase different buttons based on the user&apos;s sentiment
            </p>
          </div>
        )}
      </div>

      <ToggleRHF
        name={`${rhfFormPath}.predefinedButtons.enableStep`}
        rhfMethods={rhfMethods}
        label="Enable this step"
        className={styles.enableStep}
      />

      {watchEnableStep &&
        (watchUseSentimentButtons ? availableSentiments : (['noSentiment'] as const satisfies Sentiment[])).map(
          (sentiment: Sentiment) => {
            const buttons = {
              noSentiment: arrayNoSentimentButtons,
              positive: arrayPositiveButtons,
              neutral: arrayNeutralButtons,
              negative: arrayNegativeButtons,
            }[sentiment];

            const watchButtons = {
              noSentiment: watchSentimentButtons.noSentiment,
              positive: watchSentimentButtons.positive,
              neutral: watchSentimentButtons.neutral,
              negative: watchSentimentButtons.negative,
            }[sentiment];

            const removeButton = {
              noSentiment: removeNoSentimentButtons,
              positive: removePositiveButtons,
              neutral: removeNeutralButtons,
              negative: removeNegativeButtons,
            }[sentiment];

            const appendButton = {
              noSentiment: appendNoSentimentButtons,
              positive: appendPositiveButtons,
              neutral: appendNeutralButtons,
              negative: appendNegativeButtons,
            }[sentiment];

            const updateButton = {
              noSentiment: updateNoSentimentButtons,
              positive: updatePositiveButtons,
              neutral: updateNeutralButtons,
              negative: updateNegativeButtons,
            }[sentiment];

            const replaceButtons = {
              noSentiment: replaceNoSentimentButtons,
              positive: replacePositiveButtons,
              neutral: replaceNeutralButtons,
              negative: replaceNegativeButtons,
            }[sentiment];

            const handleDragEnd = handleDragEndForSentiment(buttons, replaceButtons);

            return (
              <>
                <div className={cx('m-bl-3', shared.leftSideSection, shared.maxWidth)}>
                  {watchUseSentimentButtons && (
                    <div>
                      <p className="m-bl-0">
                        {' '}
                        If user has selected sentiment: <b>{sentiment}</b>
                      </p>
                      <div>
                        <div className={styles.sentimentImages}>
                          {watchLabels.map((label) => {
                            const isSelected = ['noSentiment', label.sentiment].includes(sentiment);

                            return (
                              <div
                                key={label.rating}
                                className={styles.sentimentImageWrapper}
                                data-selected={isSelected}
                              >
                                <img src={label.image} alt={label.rating} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <InputRHF
                      name={`${rhfFormPath}.predefinedButtons.sentiments.${sentiment}.title`}
                      rhfMethods={rhfMethods}
                      rhfOptions={{ required: true, maxLength: 50 }}
                      label="Title description"
                      inputLimit={50}
                      fieldColor="mischka"
                    />
                  </div>

                  <div>
                    <h4 className={shared.heading}>Buttons</h4>
                    <div className={styles.fakeButtonsWrapper} data-buttons-number={buttons.length}>
                      <DndContext onDragEnd={handleDragEnd}>
                        <SortableContext items={buttons}>
                          {buttons.map((button, index) => (
                            <Controller
                              key={button.id}
                              name={`${rhfFormPath}.predefinedButtons.sentiments.${sentiment}.buttons.${index}.text`}
                              control={control}
                              defaultValue={button.text}
                              render={({ field }) => (
                                <FakeButton
                                  buttonId={button.id}
                                  field={field}
                                  index={index}
                                  removeButton={removeButton}
                                  updateButton={updateButton}
                                  key={button.id}
                                  name={field.name}
                                />
                              )}
                            />
                          ))}
                        </SortableContext>
                      </DndContext>
                      {buttons.length < 5 && (
                        <button
                          type="button"
                          onClick={() => appendButton({ id: randomUUID(), text: 'New button' })}
                          color="secondary"
                          className={styles.addButton}
                        >
                          <Icon component={Plus} />
                          Add button
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <PreviewCard heading={watchButtons.title} className={styles.previewCard}>
                  <div className={styles.previewCardButtons}>
                    {watchButtons.buttons.map((button) => (
                      <div key={button.id} className={styles.previewCardButton}>
                        {button.text}
                      </div>
                    ))}
                  </div>
                </PreviewCard>
              </>
            );
          },
        )}
    </section>
  );
}
