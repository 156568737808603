import cx from 'classnames';
import PropTypes from 'prop-types';

import feedbackFormTypes, { numberToEmoji, numberToHappyOrNot } from 'frontend/constants/feedbackFormTypes';

import styles from './FeedbackRating.scss';

const FeedbackRating = ({ data: { rating, feedbackType } }) => {
  if (feedbackType === feedbackFormTypes.BINARY) {
    const isNo = rating === 1;
    const className = cx(styles.badgeRating, { [styles.badgeRatingRed]: isNo });
    return <div className={className}>{isNo ? 'NO' : 'YES'}</div>;
  }

  if (feedbackType === feedbackFormTypes.EMOJIS) {
    const { src, description } = numberToEmoji[rating];
    return (
      <span className={styles.emojiRating}>
        <img src={src} alt={description} />
      </span>
    );
  }

  if (feedbackType === feedbackFormTypes.HAPPY_OR_NOT) {
    const { description, url } = numberToHappyOrNot[rating];
    return (
      <span
        role="img"
        aria-label={description}
        className={styles.happyOrNotRating}
        style={{ backgroundImage: `url(${url})` }}
      />
    );
  }
  if (feedbackType === feedbackFormTypes.NO_FEEDBACK) {
    return <div>Include no feedback</div>;
  }

  console.error(`Unknown feedback form type '${feedbackType}'`);
  return null;
};

FeedbackRating.propTypes = {
  data: PropTypes.exact({
    rating: PropTypes.number.isRequired,
    feedbackType: PropTypes.oneOf(Object.keys(feedbackFormTypes)).isRequired,
  }).isRequired,
};

export default FeedbackRating;
