import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { entityLimits } from 'frontend/constants';
import { BuildIdObjectType } from 'frontend/propTypes';

import InputForm from './InputForm';
import { handleEntityItemsInput } from '../../utils';

const itemsEquivalent =
  (item1 = '') =>
  (item2 = '') =>
    item1.toLowerCase().trim() === item2.toLowerCase().trim();

const itemsValidator = (entityItemsInput) => (items) => {
  const inputItems = handleEntityItemsInput(entityItemsInput);
  const allItems = [...inputItems, ...(items || [])];
  const totalItems = allItems.length;

  if (totalItems === 0) return 'The entity must have at least one item';
  if (totalItems > entityLimits.MAX_ITEMS_PER_ENTITY) {
    return `Each entity can have up to ${entityLimits.MAX_ITEMS_PER_ENTITY} items`;
  }

  const duplicateItem = allItems.find((item, idx) => allItems.slice(idx + 1).find(itemsEquivalent(item)));
  if (duplicateItem) return `Duplicate item: ${duplicateItem}`;

  return undefined;
};

const EntityItemsInput = ({
  autoFocus = true,
  className,
  onEscape,
  hue,
  addItems,
  newEntityItems,
  setNewEntityItems,
  buildIdObject,
}) => {
  const validate = useMemo(() => itemsValidator(newEntityItems), [newEntityItems]);

  return (
    <div className={className}>
      <FieldArray
        name="items"
        validate={validate}
        component={InputForm}
        autoFocus={autoFocus}
        onEscape={onEscape}
        hue={hue}
        addItems={addItems}
        newEntityItems={newEntityItems}
        setNewEntityItems={setNewEntityItems}
        buildIdObject={buildIdObject}
      />
    </div>
  );
};

EntityItemsInput.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  hue: PropTypes.number,
  addItems: PropTypes.func.isRequired,
  onEscape: PropTypes.func,
  newEntityItems: PropTypes.string.isRequired,
  setNewEntityItems: PropTypes.func.isRequired,
  buildIdObject: BuildIdObjectType.isRequired,
};

export default EntityItemsInput;
