import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LoaderSwitch } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import { BuildIdObjectType } from 'frontend/propTypes';
import { getArray } from 'frontend/utils';

import styles from './EntityInfo.scss';
import { EntityType, KindlyEntityType } from '../../propTypes';
import { ENTITY_DIALOGUES, KINDLY_ENTITY_DIALOGUES } from '../../queries';

const numberOfSamplesWithEntity = (entityId, samples) =>
  samples.filter(({ instances }) =>
    instances.find(({ entity, kindlyEntity }) => (entity || kindlyEntity).id === entityId),
  ).length;

const EntityInfo = ({ hide, args }) => {
  const {
    entity: { id, name, description },
    buildIdObject,
  } = args;
  const isKindlyEntity = Boolean(description);

  const { data: dialoguesData, loading: dialoguesLoading } = useQuery(ENTITY_DIALOGUES, {
    variables: { ...buildIdObject, id },
    skip: isKindlyEntity,
    fetchPolicy: 'network-only',
  });

  const { data: kindlyDialoguesData, loading: kindlyDialoguesLoading } = useQuery(KINDLY_ENTITY_DIALOGUES, {
    variables: { ...buildIdObject, id },
    skip: !isKindlyEntity,
    fetchPolicy: 'network-only',
  });

  const dialogues = get(dialoguesData, 'entity.dialogues') || getArray('kindlyEntity.dialogues', kindlyDialoguesData);
  const dialogueMods =
    get(dialoguesData, 'entity.dialogueMods') || getArray('kindlyEntity.dialogueMods', kindlyDialoguesData);

  return (
    <Modal title={`${isKindlyEntity ? 'Kindly e' : 'E'}ntity: ${name}`} hide={hide} footer={false}>
      <Modal.Content className="text-center">
        <LoaderSwitch size="medium" loading={dialoguesLoading || kindlyDialoguesLoading}>
          {dialogues.length === 0 && dialogueMods.length === 0 && <span>Entity not in use</span>}
          {dialogues.length > 0 && (
            <>
              <h5 className={styles.dialogueHeader}>Dialogues</h5>
              <div className={styles.dialogueList}>
                {dialogues.map(({ title, url, id: dialogueId, samples }) => {
                  const numberOfSamples = numberOfSamplesWithEntity(id, samples);

                  return (
                    <div key={`entity-dialogue-${dialogueId}`}>
                      <Link className={styles.dialogueItem} to={url}>
                        {title.default}
                      </Link>
                      <span> ({numberOfSamples})</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {dialogueMods.length > 0 && (
            <>
              <h5 className={styles.dialogueHeader}>Dialogue mods</h5>
              <div className={styles.dialogueList}>
                {dialogueMods.map(({ url, id: dialogueModId, modSamples, modDialogue: { title } }) => {
                  const numberOfSamples = numberOfSamplesWithEntity(id, modSamples);

                  return (
                    <div key={`entity-dialogue-mod-${dialogueModId}`}>
                      <Link className={styles.dialogueItem} to={url}>
                        {title.default}
                      </Link>
                      <span> ({numberOfSamples})</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </LoaderSwitch>
      </Modal.Content>
    </Modal>
  );
};

EntityInfo.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    entity: PropTypes.oneOfType([EntityType, KindlyEntityType]).isRequired,
    buildIdObject: BuildIdObjectType.isRequired,
  }).isRequired,
};

export default EntityInfo;
