export const SAGE_RESOURCE = Object.freeze({
  PLATFORM: 'PLATFORM',
  BOT: 'BOT',
  ORG: 'ORG',
});

export function sageScope(resource: string, id?: string): string {
  if (!SAGE_RESOURCE[resource]) {
    throw new Error(`Invalid resource ${resource}`);
  }

  if (resource === SAGE_RESOURCE.PLATFORM) {
    return resource;
  }

  return `${resource.toLowerCase()}-${id}`;
}

export function parseSageScope(scope): [string, string] {
  const [resource, id] = scope.split('-');
  if (!SAGE_RESOURCE[resource.toUpperCase()]) {
    throw new Error(`Invalid resource ${resource}`);
  }

  return [id, resource];
}
