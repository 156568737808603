import { split } from 'lodash';
import { useMemo } from 'react';

import { useUrlSearchParams } from 'frontend/hooks';

import { getUrlArrayParam } from '../utils';

const filterToUrlParams = getUrlArrayParam('feedbacks');

function useFeedbackTypes(): [string[], CallableFunction] {
  const [{ feedbacks }] = useUrlSearchParams();
  const initialValues = useMemo(() => (feedbacks ? split(feedbacks, '-') : []), [feedbacks]);

  return [initialValues, filterToUrlParams];
}

export default useFeedbackTypes;
