import { LoaderSwitch } from 'frontend/components';

import { ActivateDemo, DemoForm } from './components';
import { useDemoForm } from '../../../hooks';

const Demo = () => {
  const { botId, initialValues, loading } = useDemoForm();
  const activeDemo = initialValues?.id;

  return (
    <LoaderSwitch loading={loading} size="large">
      {activeDemo ? <DemoForm /> : <ActivateDemo botId={botId} />}
    </LoaderSwitch>
  );
};

export default Demo;
