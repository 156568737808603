import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { Field } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { FetchCampaignListDocument } from 'frontend/api/generated';
import { Radio } from 'frontend/components';
import { useEngagementForm } from 'frontend/features/BotSettings/hooks';

import styles from './CampaignPickerForm.scss';

export default function CampaignPickerForm({ className }: { className: string }) {
  const { botId } = useParams();
  const { initialValues: engagementData, loading: engagementDataLoading } = useEngagementForm();
  const engagementId = engagementData?.engagementId;
  const { data, loading } = useQuery(FetchCampaignListDocument, {
    variables: { botId: botId!, engagementId: engagementId!, campaignType: 'email' },
    skip: !botId || !engagementId,
  });

  if (loading || engagementDataLoading || !data?.campaignList || data?.campaignList?.length === 0) {
    return null;
  }

  const campaigns = data.campaignList.map((campaign) => ({
    id: campaign.campaignId,
    name: campaign.name,
    isLive: campaign.isActive && campaign.isRunning,
  }));

  const sortedCampaigns = campaigns.sort((a, b) => Number(b.isLive) - Number(a.isLive));

  return (
    <>
      <span className={styles.label}>Campaign shown in graphs</span>
      <div className={cx(styles.wrapper, className)}>
        <Field name="campaign_id" type="radio" value="" label="All" component={Radio} />
        {sortedCampaigns.map((campaign) => (
          <Field
            key={`campaign_filter_${campaign.id}`}
            name="campaign_id"
            type="radio"
            value={campaign.id.toString()}
            label={`${campaign.id.toString()} ${campaign.name} ${campaign.isLive ? '(live)' : ''}`}
            component={Radio}
          />
        ))}
      </div>
    </>
  );
}
