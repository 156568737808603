import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const RouterRedirect = ({ next }) => <Navigate replace to={`${next || '/'}`} />;

RouterRedirect.propTypes = {
  next: PropTypes.string.isRequired,
};

export default RouterRedirect;
