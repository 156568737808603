import React, { useCallback } from 'react';

import { Attachment } from 'frontend/assets/icons';
import { useAccessToken } from 'frontend/hooks';
import { humanReadableSize } from 'frontend/utils';

import styles from './FileComponent.scss';
import { ATTACHMENT_STATUS } from '../../utils/constants';
import { getAttachementSource } from '../../utils/getAttachmentSource';

interface FileComponentProps {
  file: {
    name: string;
    sizeKb: number;
    storagePath: string;
  };
}

const FileComponent = ({ file }: FileComponentProps) => {
  const token = useAccessToken();

  const getSource = useCallback(
    async () =>
      getAttachementSource({ ...file, storagePath: file.storagePath, status: ATTACHMENT_STATUS.CLEAN }, token),
    [file, token],
  );

  // Get source with updated access token on click
  const onClick = useCallback(async () => {
    const updatedSource = await getSource();
    window.open(updatedSource, '_blank');
  }, [getSource]);

  return (
    <div className={styles.fileComponentWrapper} role="button" onClick={onClick} tabIndex={0} onKeyDown={onClick}>
      <div className={styles.fileComponentIcon}>
        <Attachment />
      </div>
      <div className={styles.fileComponentContent}>
        <div className={styles.fileComponentFileName}>{file.name}</div>
        <div className={styles.fileComponentFileSize}>{humanReadableSize(file.sizeKb * 1024)}</div>
      </div>
    </div>
  );
};

export default FileComponent;
