import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BotMetaDocument, UpdateEngagementIdDocument } from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';

export default () => {
  const { botId } = useParams();
  const toast = useToast();
  const { data, loading } = useQuery(BotMetaDocument, { variables: { id: botId! } });
  const [updateEngagementIdMutation] = useMutation(UpdateEngagementIdDocument, {
    update: () => toast.success('Engagement ID updated'),
  });

  const initialValues = useMemo(() => {
    if (loading) {
      return {};
    }
    return {
      engagementId: Number(data?.bot?.engagementId) || null,
    };
  }, [data, loading]);

  const onSubmit = useCallback(
    async (values) => {
      const { engagementId } = values;
      await updateEngagementIdMutation({
        variables: {
          botId: botId!,
          engagementId,
        },
      });
    },
    [botId, updateEngagementIdMutation],
  );

  return {
    initialValues,
    onSubmit,
    botId,
    loading,
  };
};
