interface PluralizeOptions {
  noPluralYEnding?: boolean;
}

/**
 * Pluralize a word (`singular`) based on the quantity (`counter`) of it.
 * @param singular The singular form of the word.
 * @param counter The quantity of the word.
 * @param options Options for pluralization. E.g. if the word ends with a y and should have different plural.
 */
export default function pluralize(
  singular: string,
  counter: number,
  { noPluralYEnding }: PluralizeOptions = {},
): string {
  if (!noPluralYEnding && singular.slice(-1) === 'y') return counter === 1 ? singular : `${singular.slice(0, -1)}ies`;

  let suffix = '';
  if (counter !== 1) {
    if (singular.endsWith('s')) {
      suffix = 'es';
    } else {
      suffix = 's';
    }
  }
  return `${singular}${suffix}`;
}
