import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { compareAsc } from 'date-fns';
import { isEmpty, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Loader, LoaderSwitch, PageContent, SelectLanguage } from 'frontend/components';
import { Pusher, getPusherChannel } from 'frontend/features/Pusher';
import { useBotLanguages, useCurrentLanguage, useToast } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { camelCaseKeys } from 'frontend/utils';

import styles from './MessageClustering.scss';
import { ClusterDetail, ClusterList, DatePickers, DeleteReport, SelectMessageType, SelectReport } from './components';
import { COMPUTATION_STATES } from './constants';
import { useClusteringState, useClusters, useDates } from './hooks';
import { MESSAGE_CLUSTER_REPORTS } from './queries';

const noClustersMessage = 'No groups found in the date range.';
const noReportsMessage = 'No reports ready yet. Come back later!';

const camelCaseMessage = ({ duplicates, ...rest }) => ({
  ...camelCaseKeys(rest),
  duplicates: duplicates.map(camelCaseKeys),
});

const MessageClustering = () => {
  const toast = useToast();
  const { botId } = useParams();
  const { data } = useMe();
  const userId = data?.me?.id;
  const [{ currentLanguage }] = useCurrentLanguage();
  const [currentReportId, setCurrentReportId] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const { loading: languagesLoading, languages } = useBotLanguages();
  const clusterDetailTopRef = useRef();
  const {
    loading: reportsLoading,
    data: reportsData,
    refetch: refetchReports,
  } = useQuery(MESSAGE_CLUSTER_REPORTS, {
    variables: { botId },
  });

  const reports = useMemo(
    () =>
      (reportsData?.messageClusterReports ?? []).filter(
        ({ languageCode, status }) => languageCode === currentLanguage && status !== COMPUTATION_STATES.ERROR,
      ),
    [currentLanguage, reportsData],
  );

  const reportsFound = reports.length > 0;

  const currentReport = useMemo(
    () => reports.find(({ id }) => id === currentReportId) || {},
    [currentReportId, reports],
  );

  const { currentClusterIndex, messageType, setIndex, setType, selectCluster } = useClusteringState({
    clusterDetailTopRef,
  });

  const { fromDate, setFromDate, toDate, setToDate } = useDates();

  const {
    create,
    getClustersReport,
    pollingForId,
    clusterData,
    loading: clustersLoading,
    error,
    stopPolling,
  } = useClusters({
    messageType,
    currentLanguage,
    botId,
    setIndex,
    setCurrentReportId,
    refetchReports,
    setIsCreating,
    fromDate,
    toDate,
  });

  useEffect(() => {
    if (!reportsFound) {
      return;
    }

    if (currentReport.languageCode !== currentLanguage) {
      const reportId = reports.find(({ redacted }) => !redacted)?.id;
      if (!reportId) {
        return;
      }

      setCurrentReportId(reportId);
      getClustersReport(reportId);
    }
  }, [reportsFound, currentLanguage, currentReport.languageCode, currentReportId, reports, getClustersReport]);

  useEffect(() => {
    if (!currentReportId) {
      return;
    }
    if (!isEmpty(clusterData) && clusterData.id !== currentReportId) {
      getClustersReport(currentReportId);
    } else if (pollingForId !== currentReportId) {
      getClustersReport(currentReportId);
    }
  }, [clusterData, currentReportId, getClustersReport, pollingForId]);

  const orderedClusters = useMemo(() => orderBy(clusterData?.clusters ?? [], 'index'), [clusterData]);

  const salientTokensList = useMemo(() => orderedClusters.map(({ salientTokens }) => salientTokens), [orderedClusters]);

  const currentClusterMessages = useMemo(() => {
    if (typeof currentClusterIndex !== 'number' || orderedClusters.length === 0) {
      return [];
    }
    const chatMessages = clusterData?.chatMessages ?? [];
    const userMessages = orderedClusters?.[currentClusterIndex]?.userMessages ?? [];

    return userMessages.map(({ ids }) => {
      const firstMessage = chatMessages.find(({ id }) => id === ids[0]);
      const duplicates = ids.slice(1).map((firstId) => chatMessages.find(({ id }) => id === firstId));

      return camelCaseMessage({ ...firstMessage, duplicates });
    });
  }, [clusterData, currentClusterIndex, orderedClusters]);

  const handleCreate = useCallback(() => {
    if (compareAsc(fromDate, toDate) > -1) {
      toast.error('The "from" date has to be before the "to" date');
      return;
    }

    setIsCreating(true);
    create();
  }, [create, fromDate, toDate, toast]);

  useEffect(() => {
    if (isCreating && clustersLoading) {
      setIsCreating(false);
    }
  }, [clustersLoading, isCreating]);

  const onUpdate = useCallback(
    ({ maxMessages }) => {
      toast.warning(`There were too many messages in the time interval; only the first ${maxMessages} will be used.`);
    },
    [toast],
  );

  if (languagesLoading || reportsLoading) {
    return <Loader size="large" />;
  }

  const clustersFound = !error && clusterData?.clusters?.length > 0;
  const noClustersFound = !error && Boolean(clusterData) && (clusterData?.clusters?.length ?? 0) === 0;

  return (
    <PageContent>
      <div className={styles.header}>
        <div className={styles.configuration}>
          <div>
            {languages?.length > 1 && (
              <SelectLanguage
                languages={languages}
                guardChanges={false}
                className={styles.selectLanguage}
                supportVariants={false}
              />
            )}
            <SelectReport currentReportId={currentReportId} setCurrentReportId={setCurrentReportId} reports={reports} />
          </div>
          <div>
            <Button onClick={handleCreate} isSubmitting={isCreating} color="primary">
              Create new
            </Button>
            <SelectMessageType messageType={messageType} setMessageType={setType} />
            <DatePickers fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} />
            {!isEmpty(currentReport) && (
              <DeleteReport botId={botId} reportId={currentReport.id} stopPolling={stopPolling} />
            )}
          </div>
        </div>
      </div>
      <LoaderSwitch
        loading={clustersLoading}
        size="large"
        loaderText="Experimental feature, loading might take a while"
      >
        {Boolean(error) && <div className={styles.noClusters}>{error}</div>}
        {!reportsFound && <div className={styles.noClusters}>{noReportsMessage}</div>}
        {reportsFound && noClustersFound && <div className={styles.noClusters}>{noClustersMessage}</div>}
        {reportsFound && clustersFound && (
          <>
            <div className={cx(styles.results, styles.resultsHeader)}>
              <h3>Groups</h3>
              <h3>Messages</h3>
            </div>
            <div className={styles.results}>
              <ClusterList
                currentClusterIndex={currentClusterIndex}
                selectCluster={selectCluster}
                salientTokensList={salientTokensList}
              />
              <ClusterDetail botId={botId} messages={currentClusterMessages} ref={clusterDetailTopRef} />
            </div>
          </>
        )}
      </LoaderSwitch>
      <Pusher channel={getPusherChannel({ userId })} event="too-many-messages-in-group" onUpdate={onUpdate} />
    </PageContent>
  );
};

export default MessageClustering;
