import { DndContext, type DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import cx from 'classnames';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

import { Close, Directions, Info, Plus } from 'frontend/assets/icons';
import { Button, EmptyState, Icon } from 'frontend/components';
import { useModal } from 'frontend/features/Modals/hooks';
import { useCurrentLanguage, useUrlSearchParams } from 'frontend/hooks';
import { hasLanguage } from 'frontend/utils';
import moveArrayItem from 'frontend/utils/moveArrayItem';

import styles from './RuleDropdown.scss';
import RuleInfoModal from './RuleInfoModal';
import RuleItem from './RuleItem';
import ruleItemStyles from './RuleItem.scss';
import useCurrentRuleId from '../../hooks/useCurrentRuleId';

interface RuleDropdownProps {
  close: () => void;
  showRuleModal: (callArgs?: any) => void;
}

function RuleDropdown({ close, showRuleModal }: RuleDropdownProps): React.JSX.Element {
  const form = useForm();
  const { values } = useFormState();
  const [, setParams, unsetParams] = useUrlSearchParams();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const [showRuleInfoModal] = useModal(RuleInfoModal);
  const currentRuleId = useCurrentRuleId();

  // Only activate dragging when moving a minimum of 5px
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  });
  const sensors = useSensors(pointerSensor);

  const rules = (values.dialogueRules || []).filter(hasLanguage(selectedLanguage));

  const onConditionClick = (conditionId: string | null = null): void => {
    if (conditionId) {
      setParams({ rule: conditionId });
    } else {
      unsetParams(['rule']);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const newRules = structuredClone(rules);

      const oldIndex = newRules.findIndex((rule) => rule.id === active.id);
      const newIndex = newRules.findIndex((rule) => rule.id === over.id);
      moveArrayItem(newRules, oldIndex, newIndex);

      form.change('dialogueRules', newRules);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.dropdownHeader}>
        <span className={styles.ruleHeading}>Rule</span>
        <button
          type="button"
          className={cx(styles.resetButtonStyles, styles.infoButton)}
          onClick={() => showRuleInfoModal()}
        >
          <Icon component={Info} />
        </button>
        <button type="button" className={cx(styles.resetButtonStyles, styles.closeButton)} onClick={() => close()}>
          <Icon component={Close} />
        </button>
      </div>

      {rules.length ? (
        <div className={styles.ruleList}>
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={rules}>
              {rules.map(({ id, languageCode, name }, index) => {
                if (languageCode !== selectedLanguage) {
                  return null;
                }
                return (
                  <RuleItem
                    key={id}
                    id={id}
                    name={name}
                    ruleIndex={index}
                    onConditionClick={onConditionClick}
                    showRuleModal={showRuleModal}
                    close={close}
                    className={cx({ [styles.selectedRule]: id === currentRuleId })}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
          <div
            className={cx(ruleItemStyles.ruleListItem, { [styles.selectedRule]: !currentRuleId })}
            onClick={() => onConditionClick()}
            onKeyDown={() => onConditionClick()}
            role="button"
            tabIndex={0}
          >
            <div className={ruleItemStyles.listItemNumber}>↳</div>
            <div className={ruleItemStyles.ruleName}>Default rule</div>
          </div>
        </div>
      ) : (
        <EmptyState
          color="blue"
          icon={Directions}
          title="Create a rule"
          description="A rule is a great way to give a user different replies in different scenarios"
          className={styles.emptyState}
        />
      )}

      <div className={styles.dropdownFooter}>
        <Button
          icon={Plus}
          color="primary"
          onClick={() => {
            showRuleModal({ modalType: 'create' });
            close();
          }}
          className={styles.createRuleButton}
        >
          Create rule
        </Button>
      </div>
    </div>
  );
}

export default RuleDropdown;
