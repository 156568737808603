import cx from 'classnames';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';

import {
  FormErrors,
  GuardUnsaved,
  LoaderSwitch,
  PageBar,
  Panel,
  Range,
  Select,
  SelectLanguage,
  ToggleSwitch,
} from 'frontend/components';
import { useChatbubbleForm, useGeneralForm } from 'frontend/features/BotSettings/hooks';
import { useToast } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import { bubbleSizeTypes } from '../../constants';
import chatBubbleStyles from '../../styles.scss';

const SUBSCRIBE = {};

const formatBubbleSizeType = (type) => Boolean(type) && type !== bubbleSizeTypes.NORMAL;
const parseBubbleSizeType = (checked) => (checked ? bubbleSizeTypes.SMALL : bubbleSizeTypes.NORMAL);

export default function Behavior() {
  const { initialValues, onSubmit, languages, languagesLoading, loading } = useChatbubbleForm();

  const { isKindlyAdminManager, loading: loadingPermissions } = useMyPermissions();
  const {
    initialValues: initialBotSettings,
    onSubmit: onBotSettingSubmit,
    loading: botSettingsLoading,
  } = useGeneralForm();

  const toast = useToast();

  const onFormSubmit = async (values) => {
    try {
      if (values.phaticExpressionEnabled !== initialBotSettings.bot.nlpSettings?.phaticExpressionEnabled) {
        await onBotSettingSubmit({
          bot: {
            nlpPhaticExpressionEnabled: values.phaticExpressionEnabled,
          },
        });
      } else {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { phaticExpressionEnabled, ...chatSettings } = values;
        await onSubmit(chatSettings);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <LoaderSwitch loading={loading || languagesLoading || loadingPermissions || botSettingsLoading} size="large">
      <Form
        onSubmit={onFormSubmit}
        initialValues={{
          ...initialValues,
          phaticExpressionEnabled: initialBotSettings?.bot?.nlpSettings?.phaticExpressionEnabled,
        }}
        mutators={{ ...arrayMutators }}
        subscribe={SUBSCRIBE}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={chatBubbleStyles.formErrors} />
              <div className={chatBubbleStyles.chatbubbleSettingsWrapper}>
                <Panel>
                  <div className={chatBubbleStyles.selectLanguage}>
                    {languages.length > 1 && <SelectLanguage languages={languages} supportVariants={false} />}
                  </div>
                  <h3 className="m-b-lg">Behavior</h3>
                  <label htmlFor="ToggleSwitch-typingIndicator">
                    Delay the bot reply and indicate that the bot is typing:
                  </label>
                  <div className={`${chatBubbleStyles.flexContainer} m-t-sm m-b-md`}>
                    <ToggleSwitch name="typingIndicator" className="m-r-md" />
                    <div className={chatBubbleStyles.inputContainer}>
                      <Field name="typingIndicator">
                        {({ input }) => (
                          <Range
                            name="typingDuration"
                            min={0}
                            max={10000}
                            step={10}
                            displayValue={(v) => v / 1000}
                            displayFixedDecimals={1}
                            unit="s"
                            color={input.value ? 'dark' : 'deactive'}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={cx(chatBubbleStyles.flexContainer, 'm-t-xl')}>
                    <Field
                      component={Select}
                      id="Select-persist-state-life"
                      name="chatbubblePersistStateLifespanSeconds"
                    >
                      <Select.Option value="0" label="Never" />
                      <Select.Option value="-1" label="Forever" />
                      <Select.Option value="2592000" label="1 month" />
                      <Select.Option value="604800" label="1 week" />
                      <Select.Option value="86400" label="1 day" />
                      <Select.Option value="14400" label="4 hours" />
                      <Select.Option value="3600" label="1 hour" />
                    </Field>
                    <label htmlFor="Select-persist-state-life" className="m-l-md">
                      Resume conversation if user leaves site and then comes back.
                    </label>
                  </div>
                  <div className={cx(chatBubbleStyles.flexContainer, 'm-t-md')}>
                    <ToggleSwitch name="chatbubbleKeepOpen" className="m-r-md" />
                    <label htmlFor="ToggleSwitch-chatbubbleKeepOpen">
                      Keep the chat bubble open when <br />
                      the user navigates on Desktop.
                    </label>
                  </div>
                  <div className={cx(chatBubbleStyles.flexContainer, chatBubbleStyles.flexContainerSpaced, 'm-t-md')}>
                    <span className={chatBubbleStyles.flexContainer}>
                      <ToggleSwitch name="chatbubbleKeepOpenMobile" className="m-r-md" />
                      <label htmlFor="ToggleSwitch-chatbubbleKeepOpenMobile">
                        Keep the chat bubble open when <br />
                        the user navigates on mobile.
                      </label>
                    </span>
                  </div>
                  <div
                    className={cx(
                      chatBubbleStyles.flexContainer,
                      chatBubbleStyles.flexContainerSpaced,
                      'm-t-md m-b-xl',
                    )}
                  >
                    <span className={chatBubbleStyles.flexContainer}>
                      <ToggleSwitch
                        name="bubbleSize"
                        className="m-r-md"
                        format={formatBubbleSizeType}
                        parse={parseBubbleSizeType}
                      />
                      <label htmlFor="ToggleSwitch-bubbleSize">Use a smaller bubble on mobile.</label>
                    </span>
                  </div>
                  <p>Adjust the text alignment of content in the chat bubble.</p>

                  <div className={cx(chatBubbleStyles.flexContainer, chatBubbleStyles.flexContainerSpaced, 'm-b-xl')}>
                    <span className={chatBubbleStyles.flexContainer}>
                      <ToggleSwitch name="textRightToLeft" className="m-r-md" />
                      <label htmlFor="ToggleSwitch-textRightToLeft">Right align text (RTL) in Chat</label>
                    </span>
                  </div>
                  <p>Would you like the chat bubble to automatically pop up on your page? Set the time here!</p>
                  <label htmlFor="ToggleSwitch-chatbubbleAutopopup">Enable auto-popup after:</label>
                  <div className={cx(chatBubbleStyles.flexContainer, 'm-t-sm m-b-md')}>
                    <ToggleSwitch name="chatbubbleAutopopup" className="m-r-md" />
                    <div className={chatBubbleStyles.inputContainer}>
                      <Field name="chatbubbleAutopopup">
                        {({ input }) => (
                          <Range
                            name="chatbubbleAutopopupTime"
                            min={0}
                            max={120000}
                            step={1000}
                            displayValue={(value) => value / 1000}
                            displayFixedDecimals={0}
                            unit="s"
                            color={input.value ? 'dark' : 'deactive'}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <i>
                    Note: this feature is not supported on mobile devices and is only active for first time visitors.
                    For recurring visitors, the chat bubble will not pop up automatically.
                  </i>
                  <label htmlFor="ToggleSwitch-chatbubbleInputLimitEnabled" className="m-t-md">
                    Limit the amount of characters the user can input while talking to the bot.
                  </label>
                  <div className={chatBubbleStyles.flexContainer}>
                    <ToggleSwitch name="chatbubbleInputLimitEnabled" className="m-r-md" />
                    <div className={chatBubbleStyles.inputContainer}>
                      <Field name="chatbubbleInputLimitEnabled">
                        {({ input }) => (
                          <Range
                            name="chatbubbleInputLimit"
                            min={60}
                            max={200}
                            step={20}
                            displayValue={(value) => value}
                            displayFixedDecimals={0}
                            unit=" characters"
                            color={input.value ? 'dark' : 'deactive'}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={cx(chatBubbleStyles.flexContainer, chatBubbleStyles.flexContainerSpaced, 'm-t-md')}>
                    <span className={chatBubbleStyles.flexContainer}>
                      <ToggleSwitch name="showHandoverQueue" className="m-r-md" />
                      <label htmlFor="ToggleSwitch-showHandoverQueue">Show handover queue</label>
                    </span>
                  </div>
                  {initialBotSettings.bot?.intentMatchEnabled && (
                    <div className={cx(chatBubbleStyles.flexContainer, 'm-t-md')}>
                      <ToggleSwitch name="phaticExpressionEnabled" className="m-r-md" />
                      <label htmlFor="ToggleSwitch-phaticExpressionEnabled">Enable small talk.</label>
                    </div>
                  )}
                  <div className={cx(chatBubbleStyles.flexContainer, chatBubbleStyles.flexContainerSpaced, 'm-t-md')}>
                    <span className={chatBubbleStyles.flexContainer}>
                      <ToggleSwitch name="trackUtm" className="m-r-md" />
                      <label htmlFor="ToggleSwitch-trackUtm">
                        Track UTM parameters and send them through the chat context
                      </label>
                    </span>
                  </div>
                  {isKindlyAdminManager && (
                    <div className={cx(chatBubbleStyles.flexContainer, 'm-t-md', 'kindlyAdminManager')}>
                      <ToggleSwitch name="detectUserIdleToggle" className="m-r-md" />
                      <label htmlFor="ToggleSwitch-detectUserIdleToggle">Detect user idle.</label>
                    </div>
                  )}
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
}
