import { gql } from '@apollo/client';

export default gql`
  fragment Anonymization on AnonymizationType {
    id
    label
    value
    languageCode
  }
`;
