import { EditorState } from 'draft-js';
import { orderBy, pick } from 'lodash';

import { DIALOGUE_TYPES } from 'frontend/constants';

import emptyButton from './emptyButton';
import filterByLanguage from './filterByLanguage';
import handleButtonsAndImages from './handleButtonsAndImages';
import modDialogueInputFields from './modDialogueInputFields';

export default ({ dialogue }, currentLanguage) => {
  const { mod } = dialogue;

  const newSampleObject =
    dialogue.dialogueType === DIALOGUE_TYPES.SAMPLES ? { newSample: { editorState: EditorState.createEmpty() } } : {};

  if (mod) {
    return handleButtonsAndImages(currentLanguage, {
      ...dialogue,
      ...pick(mod, [...modDialogueInputFields, 'modSamples', 'modButtons', 'modImageCarousels']),
      modReplies: orderBy(mod.modReplies, 'createdAt'),
      ...newSampleObject,
      newButton: emptyButton,
    });
  }

  return handleButtonsAndImages(currentLanguage, {
    ...dialogue,
    modSamples: [],
    modReplies: orderBy(dialogue.replies, 'createdAt'),
    modButtons: filterByLanguage(currentLanguage, dialogue.buttons),
    modImageCarousels: filterByLanguage(currentLanguage, dialogue.imageCarousels),
    modWebhookUrls: dialogue.webhookUrls,
    modIsActive: dialogue.isActive,
    modImageCarouselSize: dialogue.imageCarouselSize,
    modVideoSources: dialogue.videoSources,
    ...newSampleObject,
    newButton: emptyButton,
  });
};
