import { invert } from 'lodash';

import { BUTTON_CONTACT_DETAILS_TYPES } from 'frontend/features/Build/constants';

const getContactDetailsTypeName = ({ contact_type: contactType }) =>
  (invert(BUTTON_CONTACT_DETAILS_TYPES)[contactType] ?? '').toLowerCase();

export const getStatus = ({ requestedContact, completedContact, contactDetails }) => {
  if (requestedContact) return `Requested ${getContactDetailsTypeName(contactDetails)}`;
  if (completedContact) return 'Was contacted';
  return 'Contact status';
};
