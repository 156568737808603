import { useApolloClient } from '@apollo/client';
import { get, pick } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { useMixpanel } from 'frontend/hooks';

import usePolling from './usePolling';
import { COMPUTATION_STATES } from '../constants';
import { CREATE_MESSAGE_CLUSTERS } from '../mutations';
import { MESSAGE_CLUSTER_REPORT } from '../queries';

const { FINISHED, INSUFFICIENT_DATA } = COMPUTATION_STATES;

const getQueryConfig = (botId, id) => ({
  query: MESSAGE_CLUSTER_REPORT,
  variables: { botId, id },
  fetchPolicy: 'network-only',
  skip: !id,
});

export default ({
  messageType,
  currentLanguage,
  botId,
  setIndex,
  setCurrentReportId,
  refetchReports,
  fromDate,
  toDate,
}) => {
  const client = useApolloClient();
  const [clusterData, setClusterData] = useState(null);
  const [pollingForId, setPollingForId] = useState();
  const { mixpanel } = useMixpanel();

  const variables = useMemo(
    () => ({ fromDate, toDate, messageType, languageCode: currentLanguage, botId }),
    [botId, currentLanguage, messageType, fromDate, toDate],
  );

  const onFinished = useCallback(
    (result) => {
      setClusterData(pick(result, ['id', 'clusters', 'chatMessages']));
      setIndex(0);
    },
    [setIndex],
  );

  const pollFunction = useCallback(
    async (queryConfig) => {
      const result = await client.query(queryConfig);

      return get(result, 'data.messageClusterReport');
    },
    [client],
  );

  const { error, stopPolling, startPolling, isPolling } = usePolling({
    onFinished,
    pollFunction,
  });

  const getClustersReport = useCallback(
    (reportId) => {
      stopPolling();
      const queryConfig = getQueryConfig(botId, reportId);
      setPollingForId(reportId);

      try {
        // Check if the desired result is already in the cache to avoid sending extra request
        const { messageClusterReport } = client.readQuery(queryConfig);

        if ([FINISHED, INSUFFICIENT_DATA].includes(messageClusterReport.status)) {
          setClusterData(pick(messageClusterReport, ['id', 'clusters', 'chatMessages']));
          return;
        }
      } catch (err) {
        // Data not in cache
      }

      startPolling(queryConfig);
    },
    [botId, client, startPolling, stopPolling],
  );

  const create = useCallback(async () => {
    const { data } = await client.mutate({ mutation: CREATE_MESSAGE_CLUSTERS, variables });
    const reportId = get(data, 'createMessageClusters.id');
    mixpanel.track('Create message group', variables);

    await refetchReports();
    setCurrentReportId(reportId);
  }, [mixpanel, client, refetchReports, setCurrentReportId, variables]);

  return { getClustersReport, pollingForId, create, clusterData, loading: isPolling, error, stopPolling };
};
