import { Field } from 'react-final-form';

import { Calendar, Language } from 'frontend/assets/icons';
import { FilterAccordion, FilterBackButton, FilterSideBar, Radio } from 'frontend/components';
import DatePicker from 'frontend/components/DatePicker';
import { useBotLanguages } from 'frontend/hooks';
import type { FiltersType } from 'frontend/routes/BuildRoutes';

import { ClearButton } from '..';

interface FilterItemsProps {
  handleSubmit: () => void;
  filters: FiltersType;
  showResetFilters: boolean;
  resetFilters: () => unknown;
}

const FilterItems = ({
  handleSubmit,
  showResetFilters,
  resetFilters,
  filters: { fromDate, toDate },
}: FilterItemsProps) => {
  const { languages } = useBotLanguages();
  const hasLanguageFilter = languages?.length > 1;

  return (
    <FilterSideBar>
      <FilterSideBar.Header>
        <FilterBackButton type="submit" handleSubmit={handleSubmit} />
        {showResetFilters && <ClearButton resetFilters={resetFilters} />}
      </FilterSideBar.Header>
      <FilterSideBar.Content>
        <FilterAccordion title="Date" icon={Calendar}>
          <Field
            /* eslint-disable-next-line react/no-unstable-nested-components */
            component={({ input: { name, onChange } }) => (
              <DatePicker
                initialStartDate={fromDate}
                initialEndDate={toDate}
                showSelect
                name={name}
                onChange={onChange}
              />
            )}
            name="datePicker"
          />
        </FilterAccordion>

        {hasLanguageFilter && (
          <FilterAccordion title="Language" icon={Language}>
            {languages.map((lang) => (
              <Field
                key={lang.code}
                name="languageSelect"
                type="radio"
                component={Radio}
                label={lang.name}
                value={lang.code}
              />
            ))}
          </FilterAccordion>
        )}
      </FilterSideBar.Content>
    </FilterSideBar>
  );
};

export default FilterItems;
