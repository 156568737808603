import React from 'react';
import { useField } from 'react-final-form';

import { ToggleSwitch } from 'frontend/components';

import styles from './FormSwitch.scss';
import type { FieldConfigFieldType } from '../../utils/configs';

interface FormSwitchProps {
  property: Partial<FieldConfigFieldType>;
  fieldName: string;
  parse?: (val: any) => any;
}

const FormSwitch = ({ property, fieldName, parse }: FormSwitchProps) => {
  useField(fieldName);

  return (
    <div className={styles.fieldCheckboxWrapper}>
      <ToggleSwitch
        name={fieldName}
        className={styles.fieldSwitch}
        values={property.switchValues}
        {...(parse && { parse })}
      />
      <label htmlFor={`ToggleSwitch-${fieldName}`}>{typeof property.label === 'string' ? property.label : ''}</label>
    </div>
  );
};

export default FormSwitch;
