import { gql } from '@apollo/client';

import { sampleFragment } from 'frontend/api/fragments';
import ImageCarousel from 'frontend/api/fragments/ImageCarousel.fragment.graphql';
import PluginInstanceFragment from 'frontend/api/fragments/PluginInstance.fragment.graphql';
import ButtonFragment from 'frontend/features/Build/graphql/fragments/Button.fragment.graphql';
import { Reply } from 'frontend/features/Build/graphql/fragments/Reply';
import LibraryModFragment from 'frontend/features/Library/fragments/LibraryMod.fragment.graphql';

export const DialogueModFragment = gql`
  fragment DialogueMod on DialogueModType {
    ...LibraryMod
    modWebhookUrls
    modDialogueId
    modVideoSources
    modButtons {
      ...Button
    }
    modReplies {
      ...Reply
    }
    modSamples {
      ...Sample
    }
    modPluginInstance {
      ...PluginInstance
    }
    modImageCarousels {
      ...ImageCarousel
    }
  }
  ${LibraryModFragment}
  ${sampleFragment}
  ${ButtonFragment}
  ${Reply}
  ${ImageCarousel}
  ${PluginInstanceFragment}
`;
