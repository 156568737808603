import { gql } from '@apollo/client';

// FIXME(legacy-takeover): Rename fields
export default gql`
  fragment ChatListItem on ChatType {
    id
    botId
    active
    fullName
    avatarUrl
    languageCode
    updated
    commentsCount
    takenOver
    hasRequestedTakeover
    requestedHandoverAt
    completedTakeover
    takeoverByAgentId
    source
    autoUser
    lastMessageIsFromUser
    preview
    contactDetails
    email
    phoneNumber
    emailVerified
    phoneNumberVerified
    labelIds
    feedbacks {
      id
      rating
      createdAt
      languageCode
      sentiment
      freeformText
    }
  }
`;
