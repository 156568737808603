import cx from 'classnames';
import { differenceInDays } from 'date-fns';
import { Field, type FieldInputProps, useFormState } from 'react-final-form';

import type { Granularity } from 'frontend/features/Analytics/types';

import styles from './styles.scss';

interface RadioProps {
  label?: string;
  input?: FieldInputProps<string>;
  hideHourly?: boolean;
}

const Radio = ({ label, input, hideHourly }: RadioProps) => (
  <label
    className={cx(styles.button, {
      [styles.hideHourly]: hideHourly,
    })}
  >
    <input type="radio" {...input} />
    <span>{label}</span>
  </label>
);

interface GranularityPickerFormProps {
  className?: string;
}

const GranularityPickerForm = ({ className }: GranularityPickerFormProps) => {
  const { values } = useFormState();
  const hideHourly = differenceInDays(values?.before, values?.after) >= 7;

  return (
    <>
      <span className={styles.label}>Granularity shown in graph</span>
      <div className={cx(styles.wrapper, className)}>
        <Field<Granularity>
          name="granularity"
          type="radio"
          value="hour"
          label="Hour"
          component={Radio}
          hideHourly={hideHourly}
        />
        <Field<Granularity> name="granularity" type="radio" value="day" label="Day" component={Radio} />
        <Field<Granularity> name="granularity" type="radio" value="week" label="Week" component={Radio} />
        <Field<Granularity> name="granularity" type="radio" value="month" label="Month" component={Radio} />
        <Field<Granularity> name="granularity" type="radio" value="quarter" label="Quarter" component={Radio} />
        <Field<Granularity> name="granularity" type="radio" value="year" label="Year" component={Radio} />
      </div>
    </>
  );
};

export default GranularityPickerForm;
