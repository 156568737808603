import { useQuery } from '@apollo/client';
import cx from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';

import { BotDocument } from 'frontend/api/generated';
import { SENDER_TYPES } from 'frontend/features/Inbox/constants';
import { useAppSelector } from 'frontend/state/hooks';

import styles from './BubbleHeader.scss';
import getUserName from '../../utils/getUserName';
import isButtonClick from '../../utils/isButtonClick';
import type { CompleteChatMessageType } from '../../utils/types';
import ConfidenceScore from '../ConfidenceScore/ConfidenceScore';
import Timestamp from '../Timestamp/Timestamp';

interface BubbleHeaderProps {
  message: CompleteChatMessageType;
  firstMessage?: CompleteChatMessageType;
}

const BubbleHeader = ({ firstMessage, message }: BubbleHeaderProps) => {
  const { selectedChat } = useAppSelector((state) => state.insights);
  const { botId } = useParams();
  const { data: botData, loading: botLoading } = useQuery(BotDocument, { variables: { botId: botId as string } });

  const userName = getUserName(selectedChat);

  const getSender = () => {
    if ((message.sender === SENDER_TYPES.BOT || message.sender === SENDER_TYPES.SYSTEM) && botData?.bot?.name) {
      return botData?.bot?.name;
    }
    if (message.sender === SENDER_TYPES.AGENT) {
      return 'Agent';
    }
    return selectedChat?.subject || userName;
  };

  if (botLoading) return null;

  return (
    <span className={cx(styles.bubbleHeader, { [styles.fromUser]: message.sender === SENDER_TYPES.USER })}>
      {firstMessage && message.__typename !== 'ButtonClickType' && <ConfidenceScore firstMessage={firstMessage} />}
      {getSender()} {isButtonClick(message) && ' has clicked on this button'} <Timestamp timestamp={message.created} />
    </span>
  );
};

export default BubbleHeader;
