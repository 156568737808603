import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { OrganizationSkillsDocument } from 'frontend/api/generated';

export default () => {
  const { organizationId: id } = useParams();
  const { loading, data } = useQuery(OrganizationSkillsDocument, { variables: { id: id! } });
  const skills = useMemo(() => data?.organization?.skills ?? [], [data]);

  return { skills, loading };
};
