import PropTypes from 'prop-types';

const OrganizationType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});
export interface Organization {
  id: string;
  name: string;
}

export default OrganizationType;
