import { capitalize } from 'lodash';
import { useCallback, useMemo } from 'react';

import { SortHeader } from 'frontend/components';
import { camelToHumanReadable } from 'frontend/utils';

import styles from '../BuildResources.scss';

const isOrderedColumn = (field, columnFields, orderedColumns) => {
  if (orderedColumns) return orderedColumns.includes(field);
  return columnFields.includes(field);
};

type useColumnsProps = {
  columnFields: string[];
  columnLabels?: string[];
  orderByField?: string;
  setOrderByField?: (field: string) => void;
  descending?: boolean;
  setDescending?: (descending: boolean) => void;
  orderedColumns?: string[];
  renderMap?: Record<string, (data: any) => React.JSX.Element | null>;
};

export default ({
  columnFields,
  columnLabels,
  orderByField,
  setOrderByField,
  descending,
  setDescending,
  orderedColumns,
  renderMap,
}: useColumnsProps) => {
  const renderSortHeader = useCallback(
    ({ data }) => (
      <SortHeader
        title={data.title}
        currentOrderType={orderByField!}
        setOrderType={setOrderByField!}
        descending={descending!}
        setDescending={setDescending!}
        orderType={data.key}
      />
    ),
    [orderByField, setOrderByField, descending, setDescending],
  );

  const renderActionsHead = useCallback(({ data }) => <div className={styles.actionsHead}>{data.title}</div>, []);

  const columns = useMemo(
    () =>
      columnFields.map((field, index) => {
        const actionsColumn = field === 'actions' && renderActionsHead;
        const sortHeaderColumn = isOrderedColumn(field, columnFields, orderedColumns) && renderSortHeader;
        return {
          id: `resource-list-${field}`,
          key: field,
          title: columnLabels?.[index] || capitalize(camelToHumanReadable(field)),
          render: renderMap?.[field],
          columnComponent: actionsColumn || sortHeaderColumn,
        };
      }),
    [columnFields, columnLabels, orderedColumns, renderActionsHead, renderMap, renderSortHeader],
  );

  return columns;
};
