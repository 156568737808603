import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { type ChatMessageType, WebhookRequestsDocument, type WebhookRequestsQuery } from 'frontend/api/generated';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

export type WebhookType = NonNullable<WebhookRequestsQuery['webhookRequests']>[0];

function useWebhookRequests(
  botId: string,
  messages: { chatMessages: { messages: Partial<ChatMessageType>[] } },
): {
  webhooks: WebhookType[];
  loading: boolean;
} {
  const { chatId } = useParams();
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });
  const canConnectBot = hasBotPerms('connect_bot');

  const { loading, data } = useQuery(WebhookRequestsDocument, {
    variables: { botId, chatId: chatId!, loggedBy: ['BOT_WEBHOOK'] },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !canConnectBot || !botId,
  });

  const webhooks: WebhookType[] = useMemo(() => {
    const orderedMessages = orderBy(messages?.chatMessages?.messages ?? [], 'created', 'asc');
    const oldestMessageDatetime = orderedMessages[0]?.created;

    return (data?.webhookRequests ?? []).filter(({ created }) =>
      oldestMessageDatetime ? created > oldestMessageDatetime : true,
    );
  }, [data, messages]);

  return { loading: loading || loadingPermissions, webhooks };
}

export default useWebhookRequests;
