import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { deleteFromArrayInCache } from 'frontend/api/cacheHelpers';
import { GetAllInvitationsDocument, OrganizationInvitationsDocument } from 'frontend/api/generated';
import { Modal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';
import { combineFunctions } from 'frontend/utils';

import { DELETE_INVITATION } from '../../mutations';

const adminUpdate = deleteFromArrayInCache({
  pathToItemInMutationData: 'deleteInvitation',
  pathToArrayInCache: 'allInvitations',
  query: GetAllInvitationsDocument,
});

const DeleteInvitation = ({ hide, args: { id, organizationId, email } }) => {
  const organizationUpdate = deleteFromArrayInCache({
    pathToItemInMutationData: 'deleteInvitation',
    variables: { organizationId },
    pathToArrayInCache: 'organizationInvitations',
    query: OrganizationInvitationsDocument,
  });

  const [mutate] = useMutation(DELETE_INVITATION, { update: combineFunctions(organizationUpdate, adminUpdate) });
  const toast = useToast();

  const onSubmit = useCallback(async () => {
    await mutate({ variables: { id } });
    toast.success('Invitation was deleted');
  }, [id, mutate, toast]);

  return (
    <Modal title="Delete invitation" footer hide={hide} onOkText="Yes, delete invitation" onOk={onSubmit}>
      <Modal.Content>
        <p>
          <strong>Delete invitation to {email}</strong>
        </p>
        <p>Are you sure you want to delete this invitation? This can not be undone.</p>
      </Modal.Content>
    </Modal>
  );
};

DeleteInvitation.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    id: IDType.isRequired,
    email: PropTypes.string.isRequired,
    organizationId: IDType.isRequired,
  }).isRequired,
};

export default DeleteInvitation;
