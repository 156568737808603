import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Tooltip } from 'frontend/components';
import { useKeyDown } from 'frontend/hooks';
import { friendlyTimestamp } from 'frontend/utils';

import styles from './ClusterDetailMessage.scss';
import { MessageRecordType } from '../../propTypes';

const ClusterDetailMessage = forwardRef(({ message, botId, showDuplicateModal, counter }, ref) => {
  const { message: userMessage, created, chatId, duplicates, id } = message;
  const numberOfDuplicates = duplicates.length;

  const showModal = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      showDuplicateModal({ message, botId });
    },
    [botId, message, showDuplicateModal],
  );

  const onKeyDown = useKeyDown({ action: showModal });

  return (
    <Link className={styles.message} to={`/workspace/${botId}/inbox/chat/${chatId}#${id}`} ref={ref} target="_blank">
      <div className={styles.messageIndex}>{counter}</div>
      <div className={styles.messageTextWrapper}>
        <span className={styles.messageText}>{userMessage}</span>
        {numberOfDuplicates > 0 && (
          <Tooltip className={styles.tooltip}>
            <span
              className={styles.duplicateBadge}
              onClick={showModal}
              onKeyDown={onKeyDown}
              role="button"
              tabIndex={0}
            >
              {Math.min(numberOfDuplicates, 999)}
            </span>
            <Tooltip.Body className={styles.tooltipBody}>Click to see duplicates</Tooltip.Body>
          </Tooltip>
        )}
      </div>
      <div className={styles.messageTimestamp}>{friendlyTimestamp(created)}</div>
    </Link>
  );
});

ClusterDetailMessage.displayName = 'ClusterDetailMessage';

ClusterDetailMessage.propTypes = {
  botId: PropTypes.number.isRequired,
  message: MessageRecordType.isRequired,
  showDuplicateModal: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
};

export default ClusterDetailMessage;
