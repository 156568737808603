import { gql } from '@apollo/client';

import { DialogueFragment } from 'frontend/features/Build/graphql/fragments/DialogueFragment';

export const UpdateDialogueMutation = gql`
  mutation UpdateDialogue(
    $botId: ID
    $skillId: ID
    $dialogueId: UUID!
    $dialogueInput: DialogueInput!
    $languageCode: String!
  ) {
    updateDialogue(
      botId: $botId
      skillId: $skillId
      id: $dialogueId
      dialogueInput: $dialogueInput
      languageCode: $languageCode
    ) {
      ...Dialogue
    }
  }
  ${DialogueFragment}
`;
