import { useCallback, useEffect, useRef, useState } from 'react';

export default (
  wrappedFunction,
  { updateWhenFinished } = { updateWhenFinished: true },
): [(callArgs?: any) => void, boolean, () => void] => {
  const [isRunning, setIsRunning] = useState(false);
  const setFinished = useCallback(() => setIsRunning(false), []);
  const isMountedRef = useRef(true);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  const runFunction = useCallback(
    async (...args) => {
      setIsRunning(true);
      try {
        const result = await wrappedFunction(...args);
        return result;
      } finally {
        if (updateWhenFinished && isMountedRef.current) setIsRunning(false);
      }
    },
    [updateWhenFinished, wrappedFunction],
  );

  return [runFunction, isRunning, setFinished];
};
