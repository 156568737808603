import cx from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { ArrowLeft, Filter } from 'frontend/assets/icons';
import { Button, Dropdown, Icon } from 'frontend/components';
import type { Option } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';

import styles from './FiltersWrapper.scss';
import type { FiltersProps } from '../../Filters';
import useHasActiveFilters from '../../hooks/useHasActiveFilters';
import useUrlFormSync from '../../hooks/useUrlFormSync';
import { FILTERS_LIST, type FilterType } from '../../utils/config';
import { getFilterWithoutIndex } from '../../utils/helpers';
import ActiveFilters from '../ActiveFilters/ActiveFilters';

const getFilterComponent = (componentKey, shouldSubmitOnToggle) => (
  <div className={styles.filterOverlayWrapper} role="complementary">
    {FILTERS_LIST.find((filterItem) => componentKey.startsWith(filterItem.filterKey))?.component(
      componentKey,
      shouldSubmitOnToggle,
    ) || null}
  </div>
);

const FiltersWrapper = ({
  submitOn,
  canIncludeExclude,
  canAddMoreThanOne,
  filters,
  onClear,
  pinnedFilters,
  clearButtonTitle,
  className,
}: FiltersProps) => {
  const { submit, getState, reset } = useForm();
  const { values } = getState();
  const [currentActiveDropdown, setCurrentActiveDropdown] = useState<{ title: string; value: string } | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const hasActiveFilters = useHasActiveFilters();

  useUrlFormSync();

  const menuOptionsList = FILTERS_LIST.filter(({ filterKey }) => {
    if (filters) {
      return filters.includes(filterKey);
    }
    return true;
  })
    .reduce((acc, currValue) => {
      if (!acc.some((val) => val.text === currValue.text)) {
        return [...acc, currValue];
      }
      return acc;
    }, [] as FilterType[])
    .map((option) => ({
      text: option.text,
      key: option.filterKey,
      icon: option.icon,
      closeMenuOnSelection: false,
      disabled:
        !canAddMoreThanOne &&
        Object.keys(values)
          .map((key) => getFilterWithoutIndex(key))
          .includes(option.filterKey),
      onClick: (_, clickOption: Option) => {
        setCurrentActiveDropdown({
          title: clickOption.text,
          value: `${clickOption.key}{${Object.values(values).length}}`,
        });
      },
    }));

  const shouldSubmitOnToggle = submitOn === 'toggle';

  return (
    <div className={cx(styles.filtersPanel, className)}>
      <Dropdown
        element={Button}
        elementProps={{
          color: 'white',
          size: 'small',
          icon: Filter,
          text: 'Add filter',
        }}
        triggerClassName={styles.trigger}
        overlayClassName={styles.dropdown}
        position="bottom-right"
        title="Add filters"
        outsideClick="pointerdown"
        onToggle={(active) => {
          if (!active) {
            if (currentActiveDropdown && !shouldSubmitOnToggle) {
              submit();
            }
            setCurrentActiveDropdown(null);
          }
        }}
        overlay={
          currentActiveDropdown ? (
            <>
              <div className={styles.backButton}>
                <Icon onClick={() => setCurrentActiveDropdown(null)} component={ArrowLeft} />
                {currentActiveDropdown.title}
              </div>
              <div className={styles.activeDropdownWrapper}>
                {getFilterComponent(currentActiveDropdown.value, shouldSubmitOnToggle)}
              </div>
            </>
          ) : (
            <Dropdown.MenuOverlay options={menuOptionsList} />
          )
        }
      />
      {hasActiveFilters && (
        <>
          <ActiveFilters
            pinnedFilters={pinnedFilters}
            canIncludeExclude={canIncludeExclude}
            shouldSubmitOnToggle={shouldSubmitOnToggle}
          />
          <Button
            className={styles.clearFiltersBtn}
            flat
            text={clearButtonTitle || 'Clear filters'}
            onClick={() => {
              if (onClear) {
                onClear(values, reset);
                return;
              }
              let path = location.pathname;
              const searchParams = new URLSearchParams(location.search);
              const allSearchParams = Array.from(searchParams);
              const remainingSearchParams = allSearchParams.filter(
                ([paramKey]) =>
                  ![...FILTERS_LIST.map(({ filterKey }) => filterKey), 'startDate', 'endDate'].includes(paramKey),
              );

              if (remainingSearchParams.length > 0) {
                remainingSearchParams.forEach(([key, value], index) => {
                  if (index === 0) {
                    path += '?';
                  }
                  path += `${key}=${value}`;
                });
              }

              navigate(path);
              reset({});
            }}
          />
        </>
      )}
    </div>
  );
};

export default FiltersWrapper;
