import { gql } from '@apollo/client';

export default gql`
  mutation UpdateColorLabel($botId: ID, $skillId: ID, $dialogueId: UUID!, $color: String!, $languageCode: String!) {
    updateColorLabel(
      botId: $botId
      skillId: $skillId
      dialogueId: $dialogueId
      color: $color
      languageCode: $languageCode
    ) {
      id
      colorLabel
    }
  }
`;
