class IterationDone extends Error {
  constructor(result) {
    super();
    this._result = result;
  }

  getResult() {
    return this._result;
  }
}

export default IterationDone;
