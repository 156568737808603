import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';

import { dragAndDropTypes } from 'frontend/constants';
import { useRemoveDefaultDragPreview } from 'frontend/hooks';
import { ChildrenType } from 'frontend/propTypes';

import styles from './DragContainer.scss';
import { SampleType } from '../../propTypes';

const DragContainer = ({
  children,
  selectedSamples,
  dialogueId,
  deselectAll,
  isModDialogue,
  shiftKey,
  isEditingSample,
}) => {
  const { hasChanges } = useSelector(({ build }) => build);

  const [, dragRef, previewRef] = useDrag({
    item: {
      id: 'drag-samples',
      type: dragAndDropTypes.SAMPLES,
      sampleIds: selectedSamples.map(({ id }) => id),
      dialogueId,
      dialogueHasChanges: hasChanges,
    },
    type: dragAndDropTypes.SAMPLES,
    canDrag: () => !isModDialogue && !shiftKey && selectedSamples.length > 0,
    end: (item, monitor) => {
      if (monitor.didDrop()) deselectAll();
    },
  });
  // Don't attach ref when editing; otherwise mouse select and navigate doesn't work in input field on Firefox
  const ref = isEditingSample ? noop : dragRef;

  useRemoveDefaultDragPreview(previewRef);

  return (
    <div className={styles.dragContainer} ref={ref}>
      {children}
    </div>
  );
};

DragContainer.propTypes = {
  children: ChildrenType.isRequired,
  dialogueId: PropTypes.string,
  isModDialogue: PropTypes.bool,
  deselectAll: PropTypes.func.isRequired,
  shiftKey: PropTypes.bool.isRequired,
  isEditingSample: PropTypes.bool.isRequired,
  selectedSamples: PropTypes.arrayOf(SampleType).isRequired,
};

export default DragContainer;
