import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { ChangePasswordDocument } from 'frontend/api/generated';
import { Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';

interface UpdatePasswordModalProps {
  hide(): Promise<void>;
}

const UpdatePasswordModal = ({ hide }: UpdatePasswordModalProps) => {
  const toast = useToast();
  const [updatePasswordMutation] = useMutation(ChangePasswordDocument);
  const onSubmit = useCallback(
    async (values) => {
      const { data } = await updatePasswordMutation({
        variables: values,
      });
      const error = data?.changePassword?.error;

      if (error) {
        return { [FORM_ERROR]: error };
      }
      toast.success('Password was changed');
      return undefined;
    },
    [toast, updatePasswordMutation],
  );
  return (
    <ModalForm title="Change password" onSubmit={onSubmit} hide={hide}>
      <h4 className="m-b-sm">Current password</h4>
      <Field
        component={Input}
        name="currentPassword"
        inputType="password"
        className="m-b-md"
        autoFocus
        validate={required}
      />
      <h4 className="m-b-sm">New password</h4>
      <Field component={Input} name="newPassword" inputType="password" className="m-b-md" validate={required} />
      <h4 className="m-b-sm">Confirm new password</h4>
      <Field component={Input} name="confirmNewPassword" inputType="password" className="m-b-md" validate={required} />
    </ModalForm>
  );
};

export default UpdatePasswordModal;
