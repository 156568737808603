import React from 'react';

import type { ChatFeedbackType } from 'frontend/api/generated';

import styles from './Feedback.scss';
import FeedbackSentiment from '../FeedbackSentiment/FeedbackSentiment';
import Timestamp from '../Timestamp/Timestamp';

const TRIGGER_TYPE_TO_LABEL = {
  bot_conversation: 'Bot conversation',
  handover_conversation: 'Handover conversation',
};

const Feedback = ({ feedback }: { feedback?: ChatFeedbackType }) => {
  if (!feedback) return null;

  const triggerTypeLabel = feedback.triggerType ? ` (${TRIGGER_TYPE_TO_LABEL[feedback.triggerType]})` : null;

  return (
    <div className={styles.feedbackWrapper}>
      <div className={styles.feedbackContainer}>
        Feedback{triggerTypeLabel}:<FeedbackSentiment className={styles.feedbackContent} data={feedback} />{' '}
        {feedback.freeformText && <div className={styles.feedbackContent}>{feedback.freeformText}</div>}
      </div>
      <Timestamp timestamp={feedback.createdAt} />
    </div>
  );
};

export default Feedback;
