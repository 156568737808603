import { CACHE_TYPE } from 'frontend/features/Inbox/constants';
import { camelCaseKeys } from 'frontend/utils';

export const EMPTY_CHAT = Object.freeze({
  id: '',
  active: false,
  fullName: '',
  avatarUrl: '',
  languageCode: '',
  updated: '',
  commentsCount: 0,
  takenOver: false,
  hasRequestedTakeover: false,
  requestedHandoverAt: null,
  completedTakeover: false,
  takeoverByAgentId: null,
  source: null,
  autoUser: {},
  preview: '',
  contactDetails: {},
  email: '',
  phoneNumber: '',
  labelIds: [],
  __typename: CACHE_TYPE.CHAT_TYPE,
});

export default (chat) => ({ ...EMPTY_CHAT, ...camelCaseKeys(chat) });
