import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'frontend/components';
import styles from 'frontend/features/Admin/views/AdminBots/styles.scss';

const Filters = ({ setFilters, filters }) => (
  <div className={styles.filters}>
    <div>
      <Button
        className={cx(styles.filterButton, filters.trial === true && styles.active)}
        onClick={() => setFilters({ ...filters, trial: true })}
        text="Trial"
        flat
      />
      <Button
        className={cx(styles.filterButton, filters.trial === false && styles.active)}
        onClick={() => setFilters({ ...filters, trial: false })}
        text="No trial"
        flat
      />
      <Button
        className={cx(styles.filterButton, filters.trial === undefined && styles.active, styles.default)}
        onClick={() => setFilters({ ...filters, trial: null })}
        text="Unset"
        flat
      />
    </div>
    <div>
      <Button
        className={cx(styles.filterButton, filters.active === true && styles.active)}
        onClick={() => setFilters({ ...filters, active: true })}
        text="Active"
        flat
      />
      <Button
        className={cx(styles.filterButton, filters.active === false && styles.active)}
        onClick={() => setFilters({ ...filters, active: false })}
        text="Not active"
        flat
      />
      <Button
        className={cx(styles.filterButton, filters.active === undefined && styles.active, styles.default)}
        onClick={() => setFilters({ ...filters, active: null })}
        text="Unset"
        flat
      />
    </div>
  </div>
);
Filters.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({ trial: PropTypes.bool, active: PropTypes.bool }).isRequired,
};
export default Filters;
