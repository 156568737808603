import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { LineChart } from 'frontend/components/Stats';
import { StatsPanel } from 'frontend/features/Analytics/components/';
import { useSageData } from 'frontend/features/Analytics/hooks';
import { sageSeriesToLineData } from 'frontend/features/Analytics/utils/sage';

const asPercentage = (value, total = 1) => `${((value / total) * 100).toFixed(1)}%`;
const seriesAsPercentage = (data) => (data || []).map((item) => ({ ...item, count: item.rate * 100 }));

const Engagement = ({ filters, to, scope, priority, isPreview }) => {
  const total = useSageData(scope, '/sessions/engagement/total', filters, {
    priority,
    apiVersion: 'v2',
  });
  const series = useSageData(scope, '/sessions/engagement', filters, {
    priority,
    apiVersion: 'v2',
  });
  const loading = total.loading || series.loading;
  const error = total.error || series.error;
  const data = useMemo(
    () => total.data && series.data && sageSeriesToLineData(seriesAsPercentage(series.data), undefined, 'engagement'),
    [total.data, series.data],
  );

  const formatLabelCallback = useCallback(
    () => `Overall engagement rate: ${asPercentage(total.data.rate)}`,
    [total.data],
  );

  const title = 'Greeting engagement rate';
  const subtitle = 'Percentage of users responding after a greeting';

  return (
    <StatsPanel loading={loading} error={error} to={to} title={title} subtitle={subtitle}>
      {data && (
        <LineChart
          isPreview={isPreview}
          isPercentage
          data={[data]}
          granularity={filters.granularity}
          formatLabelCallback={formatLabelCallback}
          csvFilename="engagement.csv"
        />
      )}
    </StatsPanel>
  );
};

Engagement.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  to: PropTypes.string,
  scope: PropTypes.string.isRequired,
  priority: PropTypes.number,
  isPreview: PropTypes.bool,
};

export default Engagement;
