import cx from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Loader, Tooltip } from 'frontend/components';
import { DefaultComponent } from 'frontend/components/TextDiff';
import { spellingCorrectionAvailableInLanguage } from 'frontend/features/BotSettings/Languages/components/SpellingCorrection';
import { useBotSettings, useUpdateBot } from 'frontend/features/BotSettings/hooks';
import { useIsRunning, useKeyDown, useToggle } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';
import { getArray } from 'frontend/utils';

import AddToAllowList from './AddToAllowList';
import styles from './ChatMessage.scss';

const SpellCorrectionDiff = ({ value, alteredValue, removed, extra: { chatLanguageCode, botId } }) => {
  const [addingToAllowlist, toggleAddingToAllowList] = useToggle();

  const { botSettings } = useBotSettings(botId);
  const updateBot = useUpdateBot(botSettings);

  const languageIsAvailable = spellingCorrectionAvailableInLanguage(chatLanguageCode);
  const allowlist = getArray(`nlpSettings.spellingCorrectionWhitelist.${chatLanguageCode}`, botSettings);
  const isInAllowlist = allowlist.includes(value);
  const canAddToAllowlist = languageIsAvailable && !isInAllowlist;

  const onClick = canAddToAllowlist ? toggleAddingToAllowList : noop;
  const onKeyDown = useKeyDown({ action: onClick });

  const onAddToAllowlist = useCallback(async () => {
    await updateBot({
      nlpSpellingCorrectionWhitelist: {
        ...botSettings?.nlpSettings?.spellingCorrectionWhitelist,
        [chatLanguageCode]: [...allowlist, value],
      },
    });
    toggleAddingToAllowList();
  }, [botSettings, chatLanguageCode, toggleAddingToAllowList, updateBot, value, allowlist]);

  const [addToAllowlist, isRunnining] = useIsRunning(onAddToAllowlist);

  if (!alteredValue && !removed) return <DefaultComponent value={value} />;

  if (removed) {
    return (
      <Tooltip className={styles.tooltip}>
        <span className={styles.removedText}>{value}</span>
        <Tooltip.Body>Text removed</Tooltip.Body>
      </Tooltip>
    );
  }

  return (
    <Tooltip className={styles.tooltip}>
      <span
        onClick={onClick}
        onKeyDown={onKeyDown}
        className={cx(styles.correctedText, { [styles.correctedTextClickable]: canAddToAllowlist })}
        role={canAddToAllowlist ? 'button' : ''}
      >
        {value}
      </span>
      {!addingToAllowlist && !isRunnining && (
        <Tooltip.Body>
          Spelling corrected to: {alteredValue}
          {canAddToAllowlist && (
            <span>
              <br />
              Click to add to allowlist
            </span>
          )}
        </Tooltip.Body>
      )}
      {canAddToAllowlist && addingToAllowlist && !isRunnining && (
        <AddToAllowList addToAllowlist={addToAllowlist} close={toggleAddingToAllowList} />
      )}
      {isRunnining && <Loader className={styles.loader} />}
    </Tooltip>
  );
};

SpellCorrectionDiff.propTypes = {
  value: PropTypes.string.isRequired,
  alteredValue: PropTypes.string,
  removed: PropTypes.bool,
  extra: PropTypes.exact({
    chatLanguageCode: PropTypes.string.isRequired,
    botId: IDType.isRequired,
  }).isRequired,
};

export default SpellCorrectionDiff;
