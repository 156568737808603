import cx from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { useKeyDown } from 'frontend/hooks';
import { ChildrenType } from 'frontend/propTypes';
import { DATE_FORMAT_WITH_FULL_TIME, TIME_FORMAT_HOURS } from 'frontend/utils/date';

import styles from './LogItem.scss';

const LogItem = ({ children, text, timeStamp, className, singleLine, onClick, hideTimestamp = false }) => {
  const onKeyDown = useKeyDown({ action: onClick });
  const contentClassName = cx(styles.logItemContent, {
    [styles.logItemContentSingleLine]: singleLine,
    [styles.logItemContentClickable]: onClick,
  });

  return (
    <div className={cx(className, styles.logItemWrapper)}>
      <div className={contentClassName} onClick={onClick} onKeyDown={onKeyDown} role={onClick ? 'button' : undefined}>
        <div className={styles.icon}>{children}</div>
        {text ? <div className={styles.text}>{text}</div> : <span> </span>}
        {hideTimestamp ? (
          <span />
        ) : (
          <div className={styles.timeStamp} title={format(timeStamp, DATE_FORMAT_WITH_FULL_TIME)}>
            {format(timeStamp, TIME_FORMAT_HOURS)}
          </div>
        )}
      </div>
    </div>
  );
};

LogItem.propTypes = {
  children: ChildrenType.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  timeStamp: PropTypes.string,
  singleLine: PropTypes.bool,
  hideTimestamp: PropTypes.bool,
  className: PropTypes.string,
};

export default LogItem;
