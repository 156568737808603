import {
  BotDocument,
  BotMembersDocument,
  MyOrganizationMembershipCountDocument,
  MyPermissionsDocument,
  OrganizationBotsDocument,
  OrganizationMembersDocument,
} from 'frontend/api/generated';

import { MEMBERSHIP_TYPES } from '../constants';

// When updating memberships the resulting changes are too complicated to effect using
// direct cache manipulation. Therefore we refetch instead.

type Props =
  | {
      botId: string;
      organizationId?: never;
      membershipType: 'workspace';
    }
  | {
      botId?: never;
      organizationId: string;
      membershipType: 'workspace' | 'organization';
    };
export default function membershipRefetches({ botId, organizationId, membershipType }: Props) {
  if (membershipType === MEMBERSHIP_TYPES.WORKSPACE) {
    return [
      { query: BotDocument, variables: { botId } },
      { query: BotMembersDocument, variables: { id: botId } },
      { query: MyPermissionsDocument, variables: { organizationId, botId } },
    ];
  }

  return [
    { query: MyOrganizationMembershipCountDocument },
    { query: OrganizationMembersDocument, variables: { id: organizationId } },
    { query: MyPermissionsDocument, variables: { organizationId } },
    { query: OrganizationBotsDocument, variables: { id: organizationId } },
  ];
}
