import { orderBy } from 'lodash';

import getInitialSpaces from './getInitialSpaces';
import getItemName from './getItemName';
import massageText from './massageText';

export default (formText, formInstances, { trimText, lowerCaseText } = {}) => {
  let initialText = formText || '';
  const initialSpaces = trimText ? getInitialSpaces(formText || '') : 0;
  initialText = massageText(initialText, { trimText, lowerCaseText });

  return orderBy(formInstances || [], 'start').reduce(
    ({ text, instances, lengthChange }, instance) => {
      const { start, end } = instance;
      const name = getItemName(instance);

      const updatedText = `${text.slice(0, start + lengthChange)}${name}${text.slice(end + lengthChange)}`;
      const original = text.slice(start + lengthChange, end + lengthChange);

      const updatedLengthChange = lengthChange + (updatedText.length - text.length);
      const updatedInstances = [
        ...instances,
        { ...instance, start: start + lengthChange, end: end + updatedLengthChange, original },
      ];

      return { text: updatedText, instances: updatedInstances, lengthChange: updatedLengthChange };
    },
    {
      text: initialText,
      instances: [],
      lengthChange: -initialSpaces,
    },
  );
};
