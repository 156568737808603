import { gql } from '@apollo/client';

import { chatListItemFragment } from 'frontend/api/fragments';
import { TICKET } from 'frontend/features/Inbox/fragments';

export default gql`
  query ChatsAndTickets(
    $botId: ID
    $organizationId: ID
    $sourceFilter: [String]
    $handoverStatusFilter: [String]
    $contactDetailsStatusFilter: [String]
    $before: DateTime
    $after: DateTime
    $labelFilter: [ID]
    $languageFilter: [String]
    $orderBy: String
    $ticketStatusFilter: [String]
    $ticketAgentFilter: String
    $botFilter: [String]
    $noLimit: Boolean
    $replyTypeFilter: [String!]
  ) {
    chatsAndTickets(
      botId: $botId
      organizationId: $organizationId
      sourceFilter: $sourceFilter
      handoverStatusFilter: $handoverStatusFilter
      contactDetailsStatusFilter: $contactDetailsStatusFilter
      before: $before
      after: $after
      labelFilter: $labelFilter
      languageFilter: $languageFilter
      orderBy: $orderBy
      ticketStatusFilter: $ticketStatusFilter
      ticketAgentFilter: $ticketAgentFilter
      botFilter: $botFilter
      noLimit: $noLimit
      replyTypeFilter: $replyTypeFilter
    ) {
      id
      chats {
        ...ChatListItem
      }
      tickets {
        ...Ticket
      }
    }
  }
  ${chatListItemFragment}
  ${TICKET}
`;
