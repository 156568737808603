import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Debug, GoTo, Info } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { LogItem } from 'frontend/features/Inbox/components';
import { useModal } from 'frontend/features/Modals';

import styles from './ConversationLogItem.scss';
import { WebhookDebugInfo } from '../../modals/WebhookDebugInfo';

interface WebhookData {
  appId?: string;
  appName?: string;
  botId: number;
  created?: string;
  data?: Record<string, unknown>;
  errorMessage?: string;
  headers?: string;
  id: string;
  loggedBy?: string;
  responseData?: string;
  responseHeaders?: string;
  responseText?: string;
  statusCode?: number;
  time?: string;
  url?: string;
}

interface WebhookProps {
  data: WebhookData;
}

const Webhook = ({ data }: WebhookProps) => {
  const { botId, created, statusCode } = data;
  const hasSuccessfulResponse = statusCode && statusCode >= 200 && statusCode < 300;
  const [showWebhookDebugInfoModal] = useModal(WebhookDebugInfo);
  const openWebhookDebugInfoModal = useCallback(
    () => showWebhookDebugInfoModal(data),
    [data, showWebhookDebugInfoModal],
  );

  return (
    <LogItem
      timeStamp={created}
      className={styles.optionsContainer}
      text={`${hasSuccessfulResponse ? 'Successful' : 'Failed'} webhook request`}
    >
      {hasSuccessfulResponse ? <Icon component={Info} /> : <Icon component={Debug} color="warning" />}
      <div className={styles.options}>
        <button
          onClick={openWebhookDebugInfoModal}
          title="Show Debug Info"
          type="button"
          className={styles.optionsItem}
        >
          <Icon
            component={Info}
            color="gray"
            hoverColor="primary"
            title="Show Debug Info"
            className={styles.infoIcon}
          />
        </button>
        <Link
          to={`/workspace/${botId}/connect/debug-console`}
          target="_blank"
          title="Go to Debug Console"
          className={styles.optionsItem}
        >
          <Icon
            component={GoTo}
            color="gray"
            hoverColor="primary"
            title="Go to Debug Console"
            className={styles.externalLinkIcon}
          />
        </Link>
      </div>
    </LogItem>
  );
};

export default Webhook;
