import { gql } from '@apollo/client';

export default gql`
  query KindlyEntityReport($id: UUID!, $botId: ID!) {
    kindlyEntityReport(id: $id, botId: $botId) {
      id
      dialogues {
        id
        name
        samples {
          id
          text
          matches {
            id
            kindlyEntityId
            start
            end
            body
          }
        }
      }
    }
  }
`;
