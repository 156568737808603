import { Drag, Edit, ExternalLink, QuickReply, Trash, Trigger } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import { useSortableItem } from 'frontend/features/Dnd/hooks';

import styles from './FakeButton.scss';

interface Props {
  buttonId: string;
  children: React.ReactNode;
  onDelete(): void;
  onEdit(): void;
  type: string;
}

export const typeToIcon = {
  link: ExternalLink,
  dialogue_trigger: Trigger,
  quick_reply: QuickReply,
};

export default function FakeButton({ type, children, buttonId, onEdit, onDelete }: Props) {
  const { style, dragAttributes, onPointerDown, setNodeRef } = useSortableItem({ id: buttonId });

  return (
    <div className={styles.fakeButton} ref={setNodeRef} style={style} {...dragAttributes} translate="no">
      <Icon component={typeToIcon[type]} className={styles.icon} />

      {children}

      <div className={styles.iconsWrapper}>
        <button type="button" className={styles.iconButton} onClick={onEdit}>
          <Icon component={Edit} />
        </button>
        <button type="button" className={styles.iconButton} onPointerDown={onPointerDown}>
          <Icon component={Drag} />
        </button>
        <button type="button" className={styles.iconButton} onClick={onDelete}>
          <Icon component={Trash} className={styles.iconTrash} />
        </button>
      </div>
    </div>
  );
}
