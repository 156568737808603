import { SortableContext } from '@dnd-kit/sortable';
import cx from 'classnames';
import React, { useState } from 'react';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import { DRAGGABLE_TYPES } from 'frontend/features/Dnd/constants';
import { useFormSort, useSortableItem } from 'frontend/features/Dnd/hooks';

import styles from './SortableFields.scss';

interface SortableFieldProps {
  fieldName: string;
  fieldIndex: number;
  children: React.ReactElement | React.ReactElement[];
}

export const SortableField = (props: SortableFieldProps) => {
  const { fieldName, fieldIndex, children } = props;

  const [draggingIndex, setDraggingIndex] = useState<number | undefined>();

  const {
    input: { value: field },
  } = useField(fieldName);

  const { style, dragAttributes, onPointerDown, setNodeRef, index, isSorting } = useSortableItem(field);

  return (
    <div
      style={style}
      ref={setNodeRef}
      aria-describedby={dragAttributes['aria-describedby']}
      aria-pressed={dragAttributes['aria-pressed']}
      aria-roledescription={dragAttributes['aria-roledescription']}
      tabIndex={dragAttributes.tabIndex}
      role={dragAttributes.role}
      className={cx({ [styles.isSorting]: isSorting && (draggingIndex as number) >= 0 && fieldIndex === index })}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(
          child,
          // if string, then its regular dom element and we don't want to add those props
          typeof child.type !== 'string'
            ? {
                onPointerDown,
                onDragging: (dragging: number) => {
                  if (draggingIndex !== dragging) {
                    setDraggingIndex(dragging);
                  }
                },
              }
            : {},
        ),
      )}
    </div>
  );
};

interface SortableFieldsProps {
  children: React.ReactChild;
  fieldName: string;
}

const SortableFields = ({ children, fieldName }: SortableFieldsProps) => {
  const {
    fields: { value },
  } = useFieldArray(fieldName);

  useFormSort(DRAGGABLE_TYPES.FORM_FIELD_ATTRIBUTE_OPTIONS, fieldName);

  if (!value || !value?.some((val) => val)) return null;

  return <SortableContext items={value?.map((val) => (val as { id: string }).id)}>{children}</SortableContext>;
};

export default SortableFields;
