import { gql } from '@apollo/client';

import { ENTITY } from '../fragments';

export default gql`
  query ENTITIES($botId: ID, $skillId: ID) {
    entities(botId: $botId, skillId: $skillId) {
      ...Entity
    }
  }
  ${ENTITY}
`;
