import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { Checkbox, LoaderSwitch } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { IDType } from 'frontend/propTypes';

import styles from './styles.scss';
import { SIGN_POLICY } from '../../mutations';
import { GET_ORGANIZATION, GET_POLICIES } from '../../queries';

const initialValues = {
  legalAccept: false,
  readAccept: false,
};

const SignPolicy = ({ hide, args }) => {
  const { policyId, url, organizationId, name } = args;

  const update = appendArrayInCache({
    variables: { organizationId },
    pathToItemInMutationData: 'signPolicy',
    pathToArrayInCache: 'signatures',
    query: GET_POLICIES,
  });
  const [create] = useMutation(SIGN_POLICY, { update });

  const { data, loading } = useQuery(GET_ORGANIZATION, { variables: { id: organizationId } });
  const organizationName = useMemo(() => data?.organization.name, [data]);

  const onSubmit = useCallback(async () => {
    await create({ variables: { organizationId, policyId } });
  }, [create, organizationId, policyId]);

  return (
    <ModalForm
      onSubmit={onSubmit}
      title={`Accept ${name}`}
      hide={hide}
      onOkText="I Accept"
      initialValues={initialValues}
    >
      <LoaderSwitch loading={loading} size="large" noMargin>
        <div className={styles.infoSection}>
          Please follow the link to read the policy:
          <p>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          </p>
        </div>

        <div className={styles.consentSection}>
          You represent and warrant that:
          <Field
            validate={required}
            component={Checkbox}
            name="legalAccept"
            type="checkbox"
            label={`You have full legal authority to agree to these terms presented above on behalf of ${organizationName}`}
          />
          <Field
            validate={required}
            component={Checkbox}
            name="readAccept"
            type="checkbox"
            label="You have read and understand these terms"
          />
        </div>
      </LoaderSwitch>
    </ModalForm>
  );
};

SignPolicy.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    organizationId: IDType.isRequired,
    policyId: IDType.isRequired,
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default SignPolicy;
