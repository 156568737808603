import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type { BuildIdObjectBot, BuildIdObjectSkill } from 'frontend/propTypes/BuildIdObjectType';

export type BotOrSkill =
  | {
      botId: string;
      skillId?: never;
      buildIdObject: BuildIdObjectBot;
      buildType: 'bot';
      buildId: string;
      isBot: true;
      isSkill: false;
    }
  | {
      botId?: never;
      skillId: string;
      buildIdObject: BuildIdObjectSkill;
      buildType: 'skill';
      buildId: string;
      isBot: false;
      isSkill: true;
    }
  | {
      botId?: never;
      skillId?: never;
      buildIdObject: undefined;
      buildType: undefined;
      buildId: undefined;
      isBot: false;
      isSkill: false;
    };

export default function useBotOrSkill({ ignoreNoBotOrSkill = false } = {}): BotOrSkill {
  const { skillId, botId } = useParams();

  return useMemo(() => {
    if (botId) {
      return {
        isBot: true,
        isSkill: false,
        buildType: 'bot',
        buildId: botId,
        buildIdObject: { botId },
        botId,
      } as const;
    }

    if (skillId) {
      return {
        isBot: false,
        isSkill: true,
        buildType: 'skill',
        buildId: skillId,
        buildIdObject: { skillId },
        skillId,
      } as const;
    }

    if (!ignoreNoBotOrSkill) {
      console.error('useBotOrSkill could not detect neither bot nor skill');
    }

    return {
      isBot: false,
      isSkill: false,
      buildIdObject: undefined,
      buildType: undefined,
      buildId: undefined,
    };
  }, [botId, skillId, ignoreNoBotOrSkill]);
}
