import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import ButtonField from './ButtonField';
import styles from './Slider.scss';
import { parseSliderValue } from './utils';
import InputErrorWrapper from '../../../../components/InputErrorWrapper';
import { SLIDER_AFFIX_TYPES } from '../../constants';

const Slider = ({ name, disabled, localErrors, localSubmitFailed, onKeyDown, isNew, validators }) => (
  <>
    <Field
      component={ButtonField}
      name={`${name}.label`}
      placeholder="Button text"
      autoFocus={!isNew}
      validate={isNew ? undefined : validators.label}
      readOnly={disabled}
      ariaLabel="Button text"
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      onKeyDown={onKeyDown}
    />
    <Field
      component={ButtonField}
      name={`${name}.sliderDetails.fromValue`}
      placeholder="From value"
      autoFocus={!isNew}
      validate={isNew ? undefined : validators.fromValue}
      readOnly={disabled}
      ariaLabel="From value"
      parse={parseSliderValue}
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      onKeyDown={onKeyDown}
      inputType="number"
    />
    <Field
      component={ButtonField}
      name={`${name}.sliderDetails.toValue`}
      placeholder="To value"
      validate={isNew ? undefined : validators.toValue}
      readOnly={disabled}
      ariaLabel="To value"
      parse={parseSliderValue}
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      onKeyDown={onKeyDown}
      inputType="number"
    />
    <Field
      component={ButtonField}
      name={`${name}.sliderDetails.value`}
      placeholder="Default value"
      parse={parseSliderValue}
      validate={isNew ? undefined : validators.value}
      readOnly={disabled}
      ariaLabel="Default value"
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      onKeyDown={onKeyDown}
      inputType="number"
    />
    <Field
      component={ButtonField}
      name={`${name}.sliderDetails.stepValue`}
      placeholder="Step value"
      parse={parseSliderValue}
      validate={isNew ? undefined : validators.stepValue}
      readOnly={disabled}
      ariaLabel="Step value"
      localErrors={localErrors}
      localSubmitFailed={localSubmitFailed}
      onKeyDown={onKeyDown}
      inputType="number"
    />
    <div className={styles.affixWrapper}>
      <Field
        name={`${name}.sliderDetails.affix`}
        validate={isNew ? undefined : validators.affix}
        readOnly={disabled}
        ariaLabel="Affix value"
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
        validateFields={[`${name}.sliderDetails.affix`]}
        render={({ input: { value: inputValue, ...restInputProps } }) => {
          const hasErrors = !!localErrors.affix && localSubmitFailed;
          return (
            <div className={styles.selectWrapper}>
              <InputErrorWrapper hasError={hasErrors} displayError={localErrors.affix}>
                <select
                  className={`${styles.select}${hasErrors ? ` ${styles.error}` : ''}`}
                  value={inputValue}
                  {...restInputProps}
                >
                  {Object.values(SLIDER_AFFIX_TYPES).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </InputErrorWrapper>
            </div>
          );
        }}
      >
        {Object.values(SLIDER_AFFIX_TYPES).map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Field>
      <Field
        component={ButtonField}
        name={`${name}.sliderDetails.affixValue`}
        placeholder="Affix value (Optional)"
        readOnly={disabled}
        validate={isNew ? undefined : validators.affixValue}
        ariaLabel="Affix value"
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
      />
    </div>
  </>
);

Slider.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  localErrors: PropTypes.shape({}).isRequired,
  localSubmitFailed: PropTypes.bool.isRequired,
  onKeyDown: PropTypes.func,
  isNew: PropTypes.bool.isRequired,
  validators: PropTypes.shape({}).isRequired,
};

export default Slider;
