import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { Close } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { useToast } from 'frontend/hooks';

import styles from './ConfusionActions.scss';
import { IGNORE_DIALOGUE_CONFUSION } from '../../mutations';

interface Props {
  showResolveDialogueConfusion: (...args: any[]) => any;
  confusedDialogues: object | null | undefined;
  botId?: string;
  selectedLanguage: string | null | undefined;
  setIgnored: (...args: any[]) => any;
  refetchConfusedDialogues: ((...args: any[]) => any) | null | undefined;
  reportId: string;
}

const ConfusionActions = ({
  showResolveDialogueConfusion,
  confusedDialogues,
  botId,
  selectedLanguage,
  setIgnored,
  refetchConfusedDialogues,
  reportId,
}: Props) => {
  const toast = useToast();

  const [ignore] = useMutation(IGNORE_DIALOGUE_CONFUSION, {
    variables: {
      botId,
      reportId,
    },
    update: () => {
      setIgnored(true);
      toast.success('Dialogue was ignored');
    },
    onError: () => toast.error('Something went wrong, please try again'),
  });

  const showModal = useCallback(() => {
    showResolveDialogueConfusion({
      selectedLanguage,
      confusedDialogues,
      botId,
      ignore,
      toast,
      refetchConfusedDialogues,
    });
  }, [
    botId,
    confusedDialogues,
    ignore,
    selectedLanguage,
    showResolveDialogueConfusion,
    toast,
    refetchConfusedDialogues,
  ]);

  return (
    <div className={styles.actions}>
      <Button className={styles.resolve} onClick={showModal}>
        View conflicts
      </Button>
      <Button icon={Close} className={styles.resolve} flat onClick={() => ignore()} />
    </div>
  );
};

export default ConfusionActions;
