import { format } from 'date-fns';
import { capitalize, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';

import { Select } from 'frontend/components';
import { IDType } from 'frontend/propTypes';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

const formatDate = (date) => format(date, DATE_FORMAT_DATE);

const getLabel = (fromDate, toDate, messageType, redacted) =>
  `${capitalize(messageType)}${redacted ? ' (REDACTED)' : ''}: ${formatDate(fromDate)} - ${formatDate(toDate)}`;

const SelectReport = ({ currentReportId, setCurrentReportId, reports }) => {
  const selectReportInput = useMemo(
    () => ({
      name: 'messageClusterReport',
      value: currentReportId,
      onChange: (event) => setCurrentReportId(event.target.value),
    }),
    [currentReportId, setCurrentReportId],
  );

  useEffect(() => {
    if (reports.length === 0) setCurrentReportId(null);
  }, [reports.length, setCurrentReportId]);

  const sortedReports = useMemo(() => orderBy(reports, ['toDate', 'fromDate']), [reports]);

  if (!currentReportId) return null;

  return (
    <Select input={selectReportInput}>
      {sortedReports.map(({ fromDate, toDate, id, messageType, redacted }) => (
        <Select.Option
          key={`cluster-report-${id}`}
          value={id}
          label={getLabel(fromDate, toDate, messageType, redacted)}
          disabled={Boolean(redacted)}
        />
      ))}
    </Select>
  );
};

SelectReport.propTypes = {
  currentReportId: IDType,
  setCurrentReportId: PropTypes.func.isRequired,
  reports: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
};

export default SelectReport;
