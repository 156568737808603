import { gql } from '@apollo/client';

export default gql`
  query IsTopicInTopic($id: ID!, $parentTopicId: ID!, $botId: ID, $skillId: ID) {
    topic(id: $id, botId: $botId, skillId: $skillId) {
      id
      isInTopic(parentTopicId: $parentTopicId)
    }
  }
`;
