import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { isAfter } from 'date-fns';
import { useCallback, useRef, useState } from 'react';

import { GET_PLATFORM_ALERT_PUBLISHED } from 'frontend/api/queries';
import { Close, Exclamation } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import { useClickOutside } from 'frontend/hooks';

import styles from './PlatformAlerts.scss';

const LOCALSTORAGE_KEY = 'kindly-platform-alerts';
const defaultLocalStorageObject = {
  lastSeen: new Date(1970, 1, 1),
};

const PlatformAlert = () => {
  const ref = useRef();
  const [alertOpen, setAlertOpen] = useState(false);

  const { data, loading } = useQuery(GET_PLATFORM_ALERT_PUBLISHED, {
    onCompleted: ({ platformAlert }) => {
      const local = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || defaultLocalStorageObject;
      const userHasSeen = isAfter(local.lastSeen, platformAlert?.createdAt);
      setAlertOpen(!userHasSeen);
    },
  });

  const onDismiss = useCallback(() => {
    const localState = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || defaultLocalStorageObject;
    localState.lastSeen = new Date().toISOString();
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(localState));
    setAlertOpen(!alertOpen);
  }, [alertOpen]);

  useClickOutside(ref, () => alertOpen && setAlertOpen(false));

  if (loading || !data?.platformAlert || !data?.platformAlert?.published) {
    return null;
  }

  const { platformAlert } = data;
  return (
    <div className={styles.wrapper}>
      <Button flat onClick={onDismiss} className={styles.warning}>
        <Icon component={Exclamation} color="warning" />
      </Button>
      <div
        ref={ref}
        className={cx(styles.dropdown, {
          [styles.dropdownOpen]: alertOpen,
        })}
      >
        <div className={styles.header}>
          <span className={styles.iconText}>
            <Icon component={Exclamation} /> Platform status
          </span>
          <Button icon={Close} onClick={onDismiss} className={styles.close} flat />
        </div>
        <p className={styles.text}>{platformAlert.text}</p>
        {platformAlert.readMoreUrl && (
          <div className={styles.link}>
            <Button
              className={styles.linkButton}
              target="_blank"
              rel="noopener noreferrer"
              href={platformAlert.readMoreUrl}
            >
              Read more
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlatformAlert;
