import { gql } from '@apollo/client';

const EVALUATION_DIALOGUE_FRAGMENT = gql`
  fragment EvaluationDialogue on EvaluationDialogueType {
    id
    title
    reply
    samples
  }
`;

export default EVALUATION_DIALOGUE_FRAGMENT;
