import cx from 'classnames';

import { useHelpCenterUrl } from './useHelpCenterUrl';

type Props = {
  path?: `/${string}`;
  children: React.ReactNode;
  className?: string;
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;

const HelpCenterLink = ({ path, children, className, ...rest }: Props) => {
  const url = useHelpCenterUrl(path);
  const classes = cx('link', className);
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={classes} {...rest}>
      {children}
    </a>
  );
};

export default HelpCenterLink;
