import { useCallback, useEffect, useState } from 'react';

import usePrevious from './usePrevious';

export default function useActionFeedback() {
  const [actionsPerformed, setActionsPerformed] = useState(0);
  const prevActionsPreformed = usePrevious(actionsPerformed);

  const increaseActionsPerformed = useCallback(
    () => setActionsPerformed((currentActionsPerformed) => currentActionsPerformed + 1),
    [],
  );

  useEffect(() => {
    if (actionsPerformed > (prevActionsPreformed || 0)) setActionsPerformed(prevActionsPreformed || 0);
  }, [actionsPerformed, prevActionsPreformed]);

  return [actionsPerformed, increaseActionsPerformed] as const;
}
