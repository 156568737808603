import { formatDistanceToNow } from 'date-fns';
import { orderBy, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Panel } from 'frontend/components';
import { Avatar } from 'frontend/features/Inbox/components';
import { getAccessToken } from 'frontend/state/dux/auth';

import styles from './LatestFallbacks.scss';

// FIXME: replace with graphql query
const fallbacksRequest = async (accessToken, botId, page) => {
  const url = `${window.env.API_URL}/api/v1/bot/${botId}/analytics/fallbacks/?page=${page}&page_size=5`;
  const res = await fetch(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (res.status !== 200) {
    throw new Error(res.statusText);
  }
  return res.json();
};

const addChatMessages = (newChatMessages) => (existingChatMessages) =>
  uniqBy(orderBy([...existingChatMessages, ...newChatMessages], 'created', 'desc'), 'id');

const LatestFallbacks = ({ botId }) => {
  const dispatch = useDispatch();
  const [, setAdditionalPages] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);

  const loadNextPage = useCallback(async () => {
    const accessToken = await dispatch(getAccessToken());

    setAdditionalPages((currentAdditionalPages) => {
      fallbacksRequest(accessToken, botId, 1 + currentAdditionalPages)
        .then((data) => {
          setChatMessages(addChatMessages(data.chatmessages || []));
          setHasNext(data.has_next);
        })
        .catch((err) => {
          console.error(err);
          setHasNext(false);
        });

      return currentAdditionalPages + 1;
    });
  }, [botId, dispatch]);

  useEffect(() => {
    loadNextPage();
  }, [loadNextPage]);

  return (
    <div className={styles.container}>
      <h3>Latest to fallback</h3>
      <div className={styles.listContainer}>
        {chatMessages.length === 0 && (
          <Panel className={styles.panel} size="small">
            <span className={styles.noFallbackText}>No messages have gone to fallback yet</span>
          </Panel>
        )}
        {chatMessages.map(
          ({ id, chat_id: chatId, created, avatar, auto_user: autoUser, reply_to_message: message }) => (
            <Panel className={styles.panel} key={`${id}_${created}`} size="small" hover>
              <Link to={`../inbox/chat/${chatId}#${id}`}>
                <div className={styles.messageContainer}>
                  <Avatar chatAvatarUrl={avatar} autoUser={autoUser} hash={chatId} className={styles.avatar} />
                  <span className={styles.messageText}>{message}</span>
                  <span className={styles.date}>{`${formatDistanceToNow(created)} ago`}</span>
                </div>
              </Link>
            </Panel>
          ),
        )}
      </div>
      {hasNext && (
        <Button className={styles.showMore} onClick={loadNextPage} flat>
          Show more
        </Button>
      )}
    </div>
  );
};

LatestFallbacks.propTypes = {
  botId: PropTypes.string.isRequired,
};

export default LatestFallbacks;
