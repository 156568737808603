/* eslint-disable max-classes-per-file */
import type { ServerError, ServerParseError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { captureException, getActiveSpan, withScope } from '@sentry/browser';
import type { GraphQLErrorExtensions } from 'graphql';

import { toastError } from 'frontend/features/Toast';

const OUTDATED_CLIENT_ERROR_MESSAGE =
  'Something went wrong. Please refresh your browser to get the updated version of Kindly 🔄';
const GENERIC_ERROR_MESSAGE = 'Something went wrong';
const NETWORK_ERROR_MESSAGE = 'Looks like something went wrong with the connection to Kindly.';

class GraphQLError extends Error {
  constructor(message) {
    super(message);
    this.name = 'GraphQLError';
  }
}
class NetworkErrorWithCode extends Error {
  constructor(message, statusCode) {
    super(`[${statusCode}] ${message}`);
    this.name = 'NetworkErrorWithCode';
  }
}

const LINK_REGEX = /\[LINK\]\((.+)\)/i;

export const showToastError = ({ message, sentryId }: { message: string; sentryId?: string }) => {
  let errorMessage = message;
  let errorOptions = {};
  let to;

  const linkRegexMatch = LINK_REGEX.exec(errorMessage);

  if (sentryId && window.env.SENTRY_DSN_FRONTEND) {
    errorOptions = { sentryId };
  }
  if (linkRegexMatch) {
    const messageWithoutLink = message.replace(LINK_REGEX, '').trim();
    errorMessage = messageWithoutLink;
    to = linkRegexMatch[1];
  }

  toastError(errorMessage, errorOptions, to);
};

function isServerError(error: Error | ServerError | ServerParseError): error is ServerError {
  return 'result' in error;
}
function isServerParseError(error: Error | ServerError | ServerParseError): error is ServerParseError {
  return 'bodyText' in error;
}

export default () =>
  onError(({ graphQLErrors, networkError, operation: { operationName, getContext } }) => {
    if (
      networkError &&
      (isServerError(networkError) || isServerParseError(networkError)) &&
      networkError.statusCode === 401
    ) {
      // 401 used in cases where we want user to re-login
      return;
    }

    const context = getContext();
    const apiVersion = context.response?.headers?.get('kindly-app-version');
    const clientIsOutdated = apiVersion && window.env.APP_VERSION && apiVersion !== window.env.APP_VERSION;

    getActiveSpan()?.setAttribute('operationName', operationName);

    // API errors (validation errors, not found, permission denied, unhandled exceptions)
    (graphQLErrors || []).forEach(
      ({ message, extensions }: { message: string; extensions: GraphQLErrorExtensions | undefined }) => {
        if (extensions?.code === 'NOT_FOUND') {
          return;
        }

        const errorMessage = clientIsOutdated ? OUTDATED_CLIENT_ERROR_MESSAGE : message;
        if (extensions?.code === 'VALIDATION_ERROR') {
          showToastError({ message: errorMessage });
        } else if (extensions?.code === 'PERMISSION_DENIED') {
          showToastError({ message: errorMessage });
        } else {
          const sentryId = captureException(new GraphQLError(message));
          showToastError({
            message: clientIsOutdated ? OUTDATED_CLIENT_ERROR_MESSAGE : GENERIC_ERROR_MESSAGE,
            sentryId,
          });
        }
        console.error(`GraphQLError: ${operationName}: ${errorMessage}`);
      },
    );

    if (networkError) {
      const errorMessage = (() => {
        if (isServerError(networkError)) {
          return `[${networkError.statusCode}] ${networkError.message} - ${JSON.stringify(networkError.result)}`;
        }
        if (isServerParseError(networkError)) {
          return `[${networkError.statusCode}] ${networkError.bodyText}`;
        }
        return networkError.message;
      })();
      if (isServerError(networkError) || isServerParseError(networkError)) {
        // Invalid graphql query/mutation
        if (networkError.statusCode === 400) {
          const sentryId = captureException(new GraphQLError(errorMessage));
          showToastError({
            message: clientIsOutdated ? OUTDATED_CLIENT_ERROR_MESSAGE : GENERIC_ERROR_MESSAGE,
            sentryId,
          });
          console.error(`Invalid GraphQL query/mutation: ${operationName}: ${errorMessage}`);
        } else {
          // Network errors
          // Log as warnings for now (better support for offline can help reduce these)
          new Promise<string>((resolve) => {
            withScope((scope) => {
              scope.setExtra('network_error', JSON.stringify(networkError));
              scope.setLevel('warning');
              const sentryId = captureException(
                new NetworkErrorWithCode(networkError.message, networkError.statusCode),
              );
              resolve(sentryId);
            });
          }).then((sentryId) => {
            showToastError({ message: NETWORK_ERROR_MESSAGE, sentryId });
            console.error(`Network Error: [${networkError.statusCode}] ${networkError.message}`);
          });
        }
      } else if (networkError.name !== 'AbortError') {
        // We don't want to log when we have aborted a query
        const sentryId = captureException(networkError);
        showToastError({ message: NETWORK_ERROR_MESSAGE, sentryId });
        console.error(`Network Error: ${networkError.message}`);
      }
    }
  });
