import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

const DELETE_APPLICATION = gql`
  mutation DeleteApplication($botId: ID!, $appId: ID!) {
    deleteApplication(botId: $botId, appId: $appId) {
      id
    }
  }
`;

const DeleteApplicationModal = ({ hide, args: { app, botId } }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [mutate] = useMutation(DELETE_APPLICATION);

  const onSubmit = useCallback(async () => {
    await mutate({ variables: { botId, appId: app.id } });
    toast.success(`${app.name} was deleted`);
    navigate(`/workspace/${botId}/connect/applications`);
  }, [app, botId, mutate, navigate, toast]);

  return <DeleteModal onSubmit={onSubmit} hide={hide} name={`application ${app.name}`} />;
};
export default DeleteApplicationModal;
