import React, { type ComponentType } from 'react';

import { Table } from 'frontend/components';
import type { Column } from 'frontend/components/Table/Table';

import { BackupOptions } from '..';
import styles from './Backups.scss';

const standardColumns = [{ title: 'ID', key: 'id' }, { key: 'created' }, { key: 'status' }];

const optionColumn = { title: '', key: 'options', component: BackupOptions as ComponentType<any> };

interface BackupsProps {
  backups: object[];
  extraColumns?: {
    key: string;
    title?: string;
    component?: React.ReactNode;
  }[];
}

const Backups = ({ backups, extraColumns }: BackupsProps) => {
  const columns = [...standardColumns, ...(extraColumns || []), optionColumn];

  return (
    <div className={styles.container}>
      <h2> Backups list</h2>
      <Table data={backups} columns={columns as Column[]} className={styles.table} />
    </div>
  );
};

export default Backups;
