import type { BotType, LanguageType } from 'frontend/api/generated';

import BotCard from './components/BotCard';
import styles from './styles.scss';

// Because of course this is also used for skills, we just pretend that they are bots 🤷
interface BotIsh extends Pick<BotType, 'id' | 'name' | 'projectName' | 'isArchived'> {
  __typename?: 'BotType' | 'SkillType';
  link: string;
  languages: Pick<LanguageType, 'id' | 'code'>[];
  avatarUrl?: string;
}

interface Props {
  bots: BotIsh[];
  name?: string;
  children?: React.ReactNode;
  hideIfEmpty?: boolean;
}

export default function BotList({ hideIfEmpty = false, name, bots, children }: Props) {
  if (hideIfEmpty && bots.length === 0) {
    return null;
  }

  const botsWithId = bots.filter(({ id }) => id);
  return (
    <div className="m-b-5">
      {botsWithId.length > 0 ? (
        <>
          {name && <h1 className={styles.title}>{name}</h1>}
          {children}
          <div className={styles.botItems}>
            {botsWithId.map((bot) => (
              <BotCard
                avatarUrl={bot.avatarUrl}
                link={bot.link}
                id={bot.id}
                name={bot.name}
                languages={bot.languages}
                projectName={bot.projectName}
                isArchived={bot.isArchived}
                key={bot.id}
              />
            ))}
          </div>
        </>
      ) : (
        children && (
          <>
            <h1 className={styles.title}>{name}</h1>
            {children}
          </>
        )
      )}
    </div>
  );
}
