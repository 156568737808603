import { useParams } from 'react-router-dom';

import { Button } from 'frontend/components';
import { DIALOGUE_TYPES, regularDialogueTypes } from 'frontend/constants';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { useQueryParams } from 'frontend/hooks';
import { capitalize } from 'frontend/utils';

const DialogueType = () => {
  const params = useQueryParams();
  const { botId } = useParams();
  const { loading, botSettings } = useBotSettings(botId);

  if (loading) return null;
  const intentEnabled = botSettings?.intentMatchEnabled;
  const dialogueTypes = regularDialogueTypes.filter((dialogueType) => {
    if (intentEnabled && dialogueType === DIALOGUE_TYPES.SAMPLES) {
      return false;
    }
    if (!intentEnabled && dialogueType === DIALOGUE_TYPES.INTENT) {
      return false;
    }
    return true;
  });

  return (
    <>
      {dialogueTypes.map((type) => {
        params.set('dialogueType', type);
        return <Button key={type} size="small" to={`?${params.toString()}`} text={capitalize(type)} />;
      })}
    </>
  );
};

export default DialogueType;
