import KindlyReactChat from '@kindly/react-chat';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withErrorHandler } from 'frontend/enhancers';
import { usePrevious } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { getAccessToken } from 'frontend/state/dux/auth';
import { chatBubbleSettingsUpdated } from 'frontend/state/dux/bot';
import { triggerNudge } from 'frontend/state/dux/chat';

function useGetAuthToken(dispatch) {
  return useCallback(
    async (chatId) => {
      const accessToken = await dispatch(getAccessToken());
      let jwt;
      const res = await fetch(`${window.env.API_URL}/api/v2/chatbubble/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ chat_id: chatId }),
      });
      try {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        const data = await res.json();
        jwt = data.token;
      } catch (err) {
        console.warn(
          'Could not get kindly-chat access token. Are public/private keys setup and are we detecting in platform correctly?',
        );
        throw err;
      }

      return jwt;
    },
    [dispatch],
  );
}

const KindlyChat = ({ botKey }) => {
  const dispatch = useDispatch();
  const prevBotKey = usePrevious(botKey);
  const { data, loading } = useMe();

  const { chatBubbleSettingsHaveUpdated } = useSelector(({ bot }) => bot);
  const { nudgeHaveBeenCalled, slug } = useSelector(({ chat }) => chat);

  const ref = useRef();

  const enableChatAdminMode = Boolean(data?.me?.profile.enableChatAdminMode);

  useEffect(() => {
    if (nudgeHaveBeenCalled && ref.current) {
      ref.current.showNudge({ undefined, slug });
    }
  }, [dispatch, nudgeHaveBeenCalled, slug]);

  useEffect(() => {
    if (chatBubbleSettingsHaveUpdated) {
      dispatch(chatBubbleSettingsUpdated(false));
    }
  }, [chatBubbleSettingsHaveUpdated, dispatch]);

  const getAuthToken = useGetAuthToken(dispatch);

  const options = useMemo(
    () => ({
      getAuthToken,
      inPlatform: true,
      admin: enableChatAdminMode,
      onNudgeDismiss: () => dispatch(triggerNudge(false)),
      environment: pick(window.env, [
        'PAT_HOST',
        'PUSHER_KEY',
        'STATUS_PAGE',
        'KINDLY_CHAT_URL',
        'SETTINGS_HOST',
        'SAGE_WS_HOST',
        'SAGE_HOST',
      ]),
    }),
    [dispatch, getAuthToken, enableChatAdminMode],
  );

  if (loading) {
    return null;
  }

  const shouldRemount = chatBubbleSettingsHaveUpdated || (prevBotKey && botKey !== prevBotKey);

  return shouldRemount ? <div /> : <KindlyReactChat botKey={botKey} setKindlyChatRef={ref} options={options} />;
};

KindlyChat.propTypes = {
  botKey: PropTypes.string.isRequired,
};

export default withErrorHandler(KindlyChat);
