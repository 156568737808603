import { useBotOrSkill } from 'frontend/hooks';

import { SystemDialogueFolder } from '../../components';

const SystemView = () => {
  const { buildIdObject } = useBotOrSkill();

  return <SystemDialogueFolder buildIdObject={buildIdObject!} />;
};

export default SystemView;
