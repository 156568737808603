import { gql } from '@apollo/client';

import { KINDLY_ENTITY } from '../fragments';

export default gql`
  mutation RemoveKindlyEntity($id: UUID!, $botId: ID!) {
    removeKindlyEntity(id: $id, botId: $botId) {
      id
      kindlyEntity {
        ...KindlyEntity
      }
    }
  }
  ${KINDLY_ENTITY}
`;
