import PropTypes from 'prop-types';

import { DefaultComponent } from 'frontend/components/TextDiff';
import { EntityTag } from 'frontend/features/Entities/components';

const KindlyEntityDiff = ({ value, alteredValue }) => {
  if (!alteredValue) return <DefaultComponent value={value} />;

  const name = alteredValue.replace(/^kindlyEntity/, '').toLowerCase();

  return <EntityTag name={name} description={`User wrote: ${value}`} size="small" />;
};

KindlyEntityDiff.propTypes = {
  value: PropTypes.string.isRequired,
  alteredValue: PropTypes.string,
};

export default KindlyEntityDiff;
