import { useMemo } from 'react';
import { useForm } from 'react-final-form';

import { useUrlSearchParams } from 'frontend/hooks';
import { pluralize } from 'frontend/utils';

import styles from './ButtonTitle.scss';
import { getFilterWithoutIndex } from '../../utils/helpers';
import AgentTitle from '../AgentTitle/AgentTitle';
import FeedbackTitle from '../FeedbackTitle/FeedbackTitle';
import LabelsTitle from '../LabelsTitle/LabelsTitle';

export type FilterKeyType =
  | 'dateFilter'
  | 'sourceFilter'
  | 'languageFilter'
  | 'labelFilter'
  | 'replyTypeFilter'
  | 'ticketAgentFilter'
  | 'handoverStatusFilter'
  | 'contactDetailsStatusFilter'
  | 'feedbackFilter'
  | 'exclDateFilter'
  | 'exclSourceFilter'
  | 'exclLanguageFilter'
  | 'exclLabelFilter'
  | 'exclReplyTypeFilter'
  | 'exclTicketAgentFilter'
  | 'exclHandoverStatusFilter'
  | 'exclContactDetailsStatusFilter'
  | 'exclFeedbackFilter';

interface ButtonTitleProps {
  filterKey: FilterKeyType;
}

const getLength = (values) => {
  if (typeof values === 'string') {
    return 1;
  }
  if (Array.isArray(values)) {
    return values.length;
  }
  return Object.keys(values || {})?.length;
};

const ButtonTitle = ({ filterKey }: ButtonTitleProps) => {
  const [params] = useUrlSearchParams();
  const { getState } = useForm();

  const filters = useMemo(() => {
    const { values } = getState();
    return values;
    // The params as dependency is so we update the filters only when we close the dropdown
    // When we do that we submit and we update the params, thus triggering the memo hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, getState]);

  if (!filters) return null;

  const filterValue = filters[filterKey];
  const length = getLength(filterValue);

  const cleanFilterKey = getFilterWithoutIndex(filterKey);

  const renderTitle = () => {
    switch (cleanFilterKey) {
      case 'dateFilter':
      case 'exclDateFilter': {
        if (!filterValue?.[0]) return null;
        const [startDate, endDate] = filterValue[0].split('|');
        return `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`;
      }
      case 'sourceFilter':
      case 'exclSourceFilter':
        return `${length} ${pluralize('source', length)}`;
      case 'labelFilter':
      case 'exclLabelFilter':
        return <LabelsTitle values={filterValue} />;
      case 'handoverStatusFilter':
      case 'exclHandoverStatusFilter':
        return `${length} ${pluralize('status', length)}`;
      case 'languageFilter':
      case 'exclLanguageFilter':
        return `${length} ${pluralize('language', length)}`;
      case 'replyTypeFilter':
      case 'exclReplyTypeFilter':
        return `${length} ${pluralize('type', length)}`;
      case 'contactDetailsStatusFilter':
      case 'exclContactDetailsStatusFilter':
        return `${length} ${pluralize('filter', length)}`;
      case 'ticketAgentFilter':
      case 'exclTicketAgentFilter':
        return <AgentTitle agentId={filterValue} />;
      case 'feedbackFilter':
      case 'exclFeedbackFilter':
        return <FeedbackTitle values={filterValue} />;
      default:
        return `${length} ${pluralize('item', length)}`;
    }
  };

  return <div className={styles.buttonTitleWrapper}>{renderTitle()}</div>;
};

export default ButtonTitle;
