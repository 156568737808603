import { gql } from '@apollo/client';

export default gql`
  fragment InboxAgent on UserType {
    id
    email
    username
    profile {
      id
      firstName
      lastName
      fullName
      avatarUrl
      alias
    }
  }
`;
