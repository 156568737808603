import cx from 'classnames';

import styles from './ChatButton.scss';

interface ChatButtonProps {
  buttonType?: string;
  label: string;
  contactDetails?: { edit_text: string };
  title: string;
  isButtonClick?: boolean;
}
const ChatButton = ({ label, buttonType, contactDetails, title, isButtonClick = false }: ChatButtonProps) => {
  const buttonLabel = buttonType === 'edit_contact_details' ? contactDetails?.edit_text : label;
  const className = cx(styles.button, {
    [styles.buttonClick]: isButtonClick,
  });

  return (
    <div className={className} title={title}>
      {buttonLabel}
    </div>
  );
};

export default ChatButton;
