import { useMutation } from '@apollo/client';
import { Form } from 'react-final-form';

import { Button, Panel } from 'frontend/components';

import { useDemoForm } from '../../../../hooks';
import styles from '../Demo.scss';
import { activateDemo, getDemo } from '../graphql';

const ActivateDemo = () => {
  const { botId } = useDemoForm();

  const [onSubmit] = useMutation(activateDemo, {
    variables: { botId },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: getDemo,
        data: { demo: data.activateDemo },
        variables: { botId },
      });
    },
  });

  return (
    <div className={styles.demoLayout}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Panel>
              <div>
                <h3 className={`${styles.sectionTitle} m-b-3`}>Demo page</h3>
                <p>Set up a demo page to start user testing</p>
                <Button type="submit">Activate now</Button>
              </div>
            </Panel>
          </form>
        )}
      />
    </div>
  );
};

export default ActivateDemo;
