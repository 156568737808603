import { Route, Routes, useParams } from 'react-router-dom';

import { Loader } from 'frontend/components';
import NotFound from 'frontend/features/NotFound';
import { useTabTitle } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import EvaluationIndex from './views/EvaluationIndex';
import EvaluationSetDetail from './views/EvaluationSetDetail';
import EvaluationSetUpdate from './views/EvaluationSetUpdate';
import NoReports from './views/NoReports';
import SimpleEvaluationDetail from './views/SimpleEvaluationDetail';

const Evaluation = () => {
  useTabTitle('Evaluation');
  const { botId } = useParams();
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });

  const canManageEvaluation = hasBotPerms('manage_evaluation');

  if (loadingPermissions) {
    return <Loader size="large" />;
  }

  if (!canManageEvaluation) {
    return <NotFound />;
  }

  return (
    <Routes>
      <Route path="/simple/:evaluationId" element={<SimpleEvaluationDetail />} />
      <Route path="/:evaluationId/reports/create" element={<NoReports />} />
      <Route path="/:evaluationId/reports" element={<EvaluationSetDetail />} />
      <Route path="/:evaluationId/reports/:reportId" element={<EvaluationSetDetail />} />
      <Route path="/:evaluationId/:folderId" element={<EvaluationSetUpdate />} />
      <Route path="/:evaluationId" element={<EvaluationSetUpdate />} />
      <Route path="/" element={<EvaluationIndex />} />
      <Route element={<NotFound />} />
    </Routes>
  );
};

export default Evaluation;
