import { useCallback, useState } from 'react';

export default ({ addChats, newerChatsAndTickets, setNewerChatsAndTickets }) => {
  const [chatDividers, setChatDividers] = useState([]);

  const showNewChats = useCallback(() => {
    addChats(newerChatsAndTickets);
    setNewerChatsAndTickets({ chats: [], tickets: [] });
    const newChatsCount = newerChatsAndTickets.chats.length;
    setChatDividers((currentDividers) => currentDividers.map((old) => old + newChatsCount).concat(newChatsCount));
  }, [addChats, newerChatsAndTickets, setNewerChatsAndTickets]);

  return { showNewChats, chatDividers };
};
