import cx from 'classnames';

import removeTagIcon from 'frontend/assets/images/remove_tag.svg?url'; // FIXME: use Icon component
import { Tooltip } from 'frontend/components';
import { useBooleanState } from 'frontend/hooks';

import styles from './Tag.scss';

export type TagSize = 'small' | 'large';

interface TagProps {
  name?: string;
  removeTag?(): void;
  children?: React.ReactNode;
  size?: TagSize;
  className?: string;
  tooltipText?: string;
  style?: React.CSSProperties;
}

const Tag = ({ className, name, children, removeTag, size, style, tooltipText }: TagProps) => {
  const [hovered, setHovered, setNotHovered] = useBooleanState();

  const tagClassNames = cx(styles.tag, className, {
    [styles.tagSmall]: size === 'small',
    [styles.tagLarge]: size === 'large',
  });

  return (
    <span className={tagClassNames} style={style} onMouseEnter={setHovered} onMouseLeave={setNotHovered}>
      {children || name}
      {removeTag && hovered && (
        <button className={styles.removeTag} onClick={removeTag} contentEditable={false} type="button">
          <img src={removeTagIcon} alt="remove" />
        </button>
      )}
      {!!tooltipText && hovered && <Tooltip.Standalone className={styles.tooltip}>{tooltipText}</Tooltip.Standalone>}
    </span>
  );
};

export default Tag;
