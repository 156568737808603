import { compose, get, lowerCase, orderBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { LabelBadge, Tooltip } from 'frontend/components';
import { LabelType } from 'frontend/propTypes';

import styles from './ChatPreview.scss';

const MAX_ITEMS = 3;

const getLabelArrays = (labels) => {
  const sortedLabels = orderBy(compose(lowerCase, get('name')), 'asc')(labels);
  return [sortedLabels.slice(0, MAX_ITEMS), sortedLabels.slice(MAX_ITEMS)];
};

const LabelList = ({ labels }) => {
  const [currentLabels, restLabels] = useMemo(() => getLabelArrays(labels), [labels]);

  return (
    <div className={styles.labelsContainer}>
      {currentLabels.map((label) => (
        <LabelBadge key={`label-${label.id}`} label={label} className={styles.label} />
      ))}

      {labels.length > MAX_ITEMS && (
        <Tooltip className={styles.tooltipWrapper}>
          <div className={styles.hiddenLabels}>+{labels.length - MAX_ITEMS}</div>
          <Tooltip.Body>{restLabels.map((label) => label.name).join(', ')}</Tooltip.Body>
        </Tooltip>
      )}
    </div>
  );
};

LabelList.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.oneOfType([LabelType, PropTypes.shape({})])),
};

export default LabelList;
