import { motion } from 'framer-motion';
import { Route, Routes } from 'react-router-dom';

import { Button } from 'frontend/components';
import Portal from 'frontend/components/Portal/Portal';
import { useToggle } from 'frontend/hooks';

import styles from './Menu.scss';
import MenuItem from './MenuItem';
import MobileMenuItem from './MobileMenuItem';
import SubMenu from './SubMenu';

export interface MenuItem {
  name: string;
  slug: string;
  hide?: boolean;
  subNavigation?: boolean;
  keepQuery?: boolean;
  className?: string;
  path?: string;
  submenu?: Array<MenuItem>;
  queryParams?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const variants = {
  hidden: {
    x: '-100%',
    transition: {
      ease: 'easeIn',
    },
  },
  visible: {
    x: 0,
    transition: {
      ease: 'easeOut',
    },
  },
};

interface MobileMenuProps {
  animate: string;
  children: React.ReactNode;
  className?: string;
}

function MobileMenu({ children, animate, className }: MobileMenuProps) {
  return (
    <motion.div variants={variants} animate={animate} className={className}>
      {children}
    </motion.div>
  );
}

interface MenuProps {
  menuItems: Array<MenuItem>;
}

export default function Menu({ menuItems }: MenuProps) {
  const [menuIsOpen, toggleMenuOpen] = useToggle();

  return (
    <div>
      <div className={styles.mainMenu}>
        {menuItems.map(
          (item) =>
            !item.hide && (
              <MenuItem
                subNavigation={item.subNavigation}
                title={item.name}
                slug={item.slug}
                key={`sub-menu-${item.name}`}
                className={item?.className ?? ''}
                keepQuery={item.keepQuery}
                queryParams={item?.queryParams}
                icon={item?.icon}
              />
            ),
        )}
      </div>
      <Routes>
        {menuItems
          .filter((item) => item.submenu)
          .map((item) => (
            <Route key={`menu-item-${item.name}`} path={item.path} element={<SubMenu menuItems={item.submenu!} />} />
          ))}
      </Routes>
      <Portal attachElementId="submenu-target" wrapperId="mobile-menu">
        <div className={styles.subMenuMobile}>
          <Button onClick={toggleMenuOpen} className={styles.mobileMenuToggleButton} size="small" text="Menu" />
        </div>
      </Portal>

      <MobileMenu animate={menuIsOpen ? 'visible' : 'hidden'} className={styles.mobileMenu}>
        {menuItems.map(
          (item) =>
            !item.hide && <MobileMenuItem toggleMenuOpen={toggleMenuOpen} item={item} key={`sub-menu-${item.name}`} />,
        )}
      </MobileMenu>
    </div>
  );
}
