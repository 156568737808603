import PropTypes from 'prop-types';

import { Button } from 'frontend/components';

import styles from './AddKindlyEntities.scss';

const Waiting = ({ waitMinutes, hide }) => (
  <div className={styles.loader}>
    <h3>
      Finding existing Kindly entities is an intensive operation and cannot be started too often per language in each
      bot.
      <br />
      <br />
      Please wait {waitMinutes} minutes and then try again.
      <div className={styles.waitButton}>
        <Button onClick={hide} color="primary">
          Ok
        </Button>
      </div>
    </h3>
  </div>
);

Waiting.propTypes = {
  waitMinutes: PropTypes.number.isRequired,
  hide: PropTypes.func.isRequired,
};

export default Waiting;
