import { createStateSyncMiddleware } from 'redux-state-sync';
import { thunk } from 'redux-thunk';

import logger from './logger';

const config = {
  channel: 'redux_state_sync',
  whitelist: ['auth/LOGIN_SUCCESS', 'auth/SIGN_OUT', 'auth/REFRESH_TOKEN_ERROR'],
};

export default [thunk, logger, createStateSyncMiddleware(config)];
