import { get } from 'lodash';
import { useCallback } from 'react';

import { dialogueCanBeDisabled } from '../queries';

export default () => {
  const checkCanDisable = useCallback(async ({ client, variables }) => {
    const { data } = await client.query({ query: dialogueCanBeDisabled, variables, fetchPolicy: 'network-only' });
    return get(data, 'dialogue.canBeDisabled');
  }, []);
  return checkCanDisable;
};
