import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Fallback, GreetingBubble, KindlyBlob } from 'frontend/assets/icons';
import { useBotOrSkill } from 'frontend/hooks';

import { getBuildUrl, getDialogueTypeName } from '../../utils';

const getIconFromType = (dialogueType) => {
  switch (dialogueType) {
    case 'greeting':
      return GreetingBubble;
    case 'fallback':
      return Fallback;
    default:
      return KindlyBlob;
  }
};

export const useSpecialDialogueContextMenu = ({ dialogueType }) => {
  const navigate = useNavigate();
  const { buildType, buildId } = useBotOrSkill();

  const dialogueTypeName = getDialogueTypeName(dialogueType);
  const contextMenuId = `library-context-menu`;
  const newDialogueUrl = getBuildUrl({ buildType, buildId, dialogueType, target: 'new' });

  const actions = useMemo(
    () => [
      {
        text: `New ${dialogueTypeName}`,
        icon: getIconFromType(dialogueTypeName),
        onClick: () => navigate(newDialogueUrl),
      },
    ],
    [dialogueTypeName, navigate, newDialogueUrl],
  );

  return { actions, contextMenuId };
};
