import PropTypes from 'prop-types';

import { Feedback } from 'frontend/features/Inbox/components';
import { MESSAGE_TYPE } from 'frontend/features/Inbox/constants';
import { AttachmentType } from 'frontend/features/Inbox/propTypes';
import { IDType } from 'frontend/propTypes';
import { snakeToHumanReadable } from 'frontend/utils';

import MessageUrlLocation from './MessageUrlLocation';
import TimeStamp from './TimeStamp';
import UserLeft from './UserLeft';
import Webhook from './Webhook';
import { ConversationDataType, MessageOrLogType } from '../../propTypes';
import { isMessage, isMessageOrButtonClick } from '../../utils';
import ChatButton from '../ChatButton';
import ChatGroup from '../ChatGroup';
import ChatMessageLogItem from '../ChatMessageLogItem';
import TicketLogItem from '../TicketLogItem';

const EMPTY_ITEM = {};

const ConversationLogItem = ({
  data,
  botId,
  conversationData,
  showConfidenceScoreModal,
  userName,
  chatLanguageCode,
  scrollToBottom,
  isLastSeen,
  botName,
  canViewDialogues,
  attachments,
}) => {
  const botAvatarUrl = conversationData?.chatAndBot?.bot?.avatarUrl ?? '';
  const chatAvatarUrl = conversationData?.chatAndBot?.chat?.avatarUrl;
  const autoUser = conversationData?.chatAndBot?.chat?.autoUser;
  const chatFullName = conversationData?.chatAndBot?.chat?.fullName;
  const email = conversationData?.chatAndBot?.chat?.email;
  const firstItem = data?.[0] ?? EMPTY_ITEM;

  if (firstItem.messageType === MESSAGE_TYPE.FEEDBACK) {
    return <Feedback feedback={firstItem.feedback} />;
  }

  if (firstItem.messageType === MESSAGE_TYPE.USER_LEFT) {
    return <UserLeft userName={userName} userLeft={firstItem.created} />;
  }

  if (firstItem.messageType === MESSAGE_TYPE.TIMESTAMP) {
    return <TimeStamp timestamp={firstItem.created} />;
  }

  if (firstItem.messageType === MESSAGE_TYPE.URL) {
    return <MessageUrlLocation url={firstItem.url} created={firstItem.created} userName={userName} />;
  }

  if (firstItem.__typename === 'WebhookRequestType') {
    return <Webhook data={firstItem} />;
  }

  if (!isMessageOrButtonClick(firstItem)) {
    return <TicketLogItem data={firstItem} autoUser={autoUser} chatFullName={chatFullName} />;
  }
  return (
    <ChatGroup
      showModal={showConfidenceScoreModal}
      firstMessage={firstItem}
      botAvatarUrl={botAvatarUrl}
      chatAvatarUrl={chatAvatarUrl}
      autoUser={autoUser}
      fromBot={firstItem.fromBot}
      botOrAgentName={firstItem.name || botName}
      userName={userName}
      canViewDialogues={canViewDialogues}
      seen={isLastSeen}
      botId={botId}
      email={email}
    >
      {data.map(
        ({
          id,
          message,
          messageReferences,
          correctedMessage,
          messageWithKindlyEntities,
          buttons,
          attachments: legacyAttachments,
          attachmentIds,
          image,
          imageCarousel,
          imageCarouselSize,
          sender,
          suggestions,
          button,
          form,
          formSubmission,
          chatSource,
          __typename,
        }) =>
          isMessage({ __typename }) ? (
            <ChatMessageLogItem
              key={id}
              botId={botId}
              message={message}
              messageReferences={messageReferences}
              correctedMessage={correctedMessage}
              messageWithKindlyEntities={messageWithKindlyEntities}
              buttons={buttons}
              attachments={attachments}
              attachmentIds={attachmentIds}
              form={form}
              formSubmission={formSubmission}
              legacyAttachments={legacyAttachments}
              image={image}
              imageCarousel={imageCarousel}
              imageCarouselSize={imageCarouselSize}
              sender={sender}
              suggestions={suggestions}
              chatLanguageCode={chatLanguageCode}
              scrollToBottom={scrollToBottom}
              chatSource={chatSource}
            />
          ) : (
            <ChatButton
              key={id}
              label={button.label}
              title={`Clicked ${snakeToHumanReadable(button.button_type)} button`}
              isButtonClick
            />
          ),
      )}
    </ChatGroup>
  );
};

ConversationLogItem.propTypes = {
  data: PropTypes.arrayOf(MessageOrLogType).isRequired,
  conversationData: ConversationDataType.isRequired,
  showConfidenceScoreModal: PropTypes.func.isRequired,
  userName: PropTypes.string,
  chatLanguageCode: PropTypes.string,
  scrollToBottom: PropTypes.func.isRequired,
  isLastSeen: PropTypes.bool.isRequired,
  botName: PropTypes.string.isRequired,
  canViewDialogues: PropTypes.bool.isRequired,
  attachments: PropTypes.arrayOf(AttachmentType),
  botId: IDType.isRequired,
};

export default ConversationLogItem;
