import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DeleteTopicDocument } from 'frontend/api/generated';
import { isDialogueInTopic } from 'frontend/api/queries';
import { updateLibraryTopics } from 'frontend/features/Library/cacheHelpers';
import { useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';
import { getCurrentFormId } from 'frontend/utils';

async function isRedirectNecessary({
  client,
  buildIdObject,
  deletedTopicId,
  pathname,
}: {
  client: any;
  buildIdObject: BuildIdObject;
  deletedTopicId: string;
  pathname: string;
}): Promise<boolean> {
  const currentDialogueId = getCurrentFormId('dialogue', pathname);
  if (currentDialogueId && currentDialogueId !== 'new') {
    try {
      const { data } = await client.query({
        query: isDialogueInTopic,
        variables: {
          ...buildIdObject,
          topicId: deletedTopicId,
          dialogueId: currentDialogueId,
        },
      });
      return data.dialogue.isInTopic;
    } catch (e) {
      // If current dialogue is a skill subscription dialogue it will not be found,
      // but this also means it is not followup to a deleted topic
      return false;
    }
  }
  return false;
}

export function useDeleteTopic({
  topic,
  botOrSkillParams,
}: {
  topic: { name: string; id: string; parentTopicId?: string | null };
  botOrSkillParams: {
    buildIdObject: BuildIdObject;
    buildType: string;
    buildId: string;
  };
}) {
  const [deleteMutation] = useMutation(DeleteTopicDocument);
  const client = useApolloClient();
  const toast = useToast();
  const { buildIdObject, buildType, buildId } = botOrSkillParams;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const deleteTopicFunction = useCallback(async () => {
    const { id } = topic;
    const redirect = await isRedirectNecessary({ client, buildIdObject, deletedTopicId: id, pathname });
    await deleteMutation({ variables: { topicId: id, ...buildIdObject } });

    const { parentTopicId } = topic;
    const variables = {
      ...buildIdObject,
      ...(parentTopicId ? { parentTopicId } : {}),
    };
    updateLibraryTopics({ variables, client, removedTopic: topic });

    if (redirect) {
      navigate(`/${buildType}/${buildId}/build`);
    }
    toast.success(`Folder '${topic.name}' was deleted!`);
  }, [buildId, buildIdObject, buildType, client, deleteMutation, pathname, navigate, toast, topic]);

  return deleteTopicFunction;
}
