export default (tagTypes, content) => (character) => {
  if (!character) return false;
  const key = character.getEntity();

  if (key != null) {
    const draftEntity = content.getEntity(key);
    return tagTypes.includes(draftEntity.getType());
  }
  return false;
};
