import { useQuery } from '@apollo/client';

import { HandoverSettingsDocument, type TakeoverHandoverSettingsModeChoices } from 'frontend/api/generated';

type Return = { mode: TakeoverHandoverSettingsModeChoices | undefined; loading: boolean };
export default function useHandoverMode(botId: string | undefined): Return {
  const { data, loading } = useQuery(HandoverSettingsDocument, {
    variables: { botId: botId! },
    skip: !botId,
  });

  const mode = data?.handoverSettings?.mode;

  return { mode, loading };
}
