import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { CONFUSED_DIALOGUES, CONFUSED_LANGUAGES } from '../queries';

export default (currentLanguage) => {
  const { botId } = useParams();

  const {
    data: confusedDialoguesData,
    loading: confusedDialoguesLoading,
    refetch: refetchConfusedDialogues,
  } = useQuery(CONFUSED_DIALOGUES, {
    variables: {
      botId,
      confusionReportLanguage: currentLanguage,
    },
  });

  const { data: confusedLanguagesData, loading: confusedLanguagesLoading } = useQuery(CONFUSED_LANGUAGES, {
    variables: { botId },
  });

  return {
    refetchConfusedDialogues,
    confusedDialogues: confusedDialoguesData?.confusedDialogues ?? [],
    confusedDialoguesLoading,
    confusedLanguages: confusedLanguagesData?.confusedLanguages ?? [],
    confusedLanguagesLoading,
  };
};
