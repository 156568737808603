import { useMemo } from 'react';

import type { LabelType } from 'frontend/api/generated';

export default ({ botLabels, labelIds }: { labelIds: string[]; botLabels: Partial<LabelType>[] }) => {
  const labels = useMemo(
    () => botLabels.filter(({ id: labelId }) => labelIds.includes(labelId)),
    [botLabels, labelIds],
  );

  return labels;
};
