import { EmptyState } from 'frontend/components/Stats';

import { COLORS } from './constants';
import styles from './styles.scss';

const calcPercent = (value, total) => Math.round((value / total) * 100);

interface BarChartLabel {
  id?: string;
  color?: string;
  label?: string;
  value: number;
}

interface BarChartData {
  /** total number of all the values together */
  total: number;
  /** array of object containing data of single bars */
  labels: BarChartLabel[];
}

interface BarChartProps {
  /** Object containing the data */
  data: BarChartData;
  /** Function that gets passed the value and shows it. */
  renderValue?(val: number): string | number | undefined;
  /** Function that gets passed the label and shows it. */
  renderLabel?(val?: string): string | undefined;
  /** Maximum number of bars to display. */
  limit?: number;
}

/** Chart made of colored bars indicating the different values */
const BarChart = ({ data, renderLabel = (label) => label, renderValue = (title) => title, limit }: BarChartProps) => {
  const labels = data?.labels ?? [];

  if (labels.length === 0) return <EmptyState />;

  return (
    <div className={styles.wrapper}>
      {labels.slice(0, limit).map((row, index) => (
        <div className={styles.row} key={row.id || index}>
          <div className={styles.title}>
            <span>{`${renderLabel(row.label)} (${renderValue(row.value)})`}</span>
          </div>
          <div className={styles.bar}>
            <div
              className={styles.barInner}
              style={{
                width: `${calcPercent(row.value, data.total)}%`,
                backgroundColor: row.color || COLORS[index % COLORS.length],
              }}
            />
          </div>
          <span className={styles.value} title={row.label}>
            {`${calcPercent(row.value, data.total)}%`}
          </span>
        </div>
      ))}
    </div>
  );
};

export default BarChart;
