import cx from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { SpeechMessage } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { DEFAULT_DIALOGUE_LABEL_COLOR, DIALOGUE_LABEL_COLORS } from 'frontend/features/Library/constants';
import { getBuildUrl } from 'frontend/features/Library/utils';

import styles from '../components/Images/Images.scss';

export default ({ buildIdObject }) => {
  const renderMap = useMemo(
    () => ({
      dialogue: ({ data: { id, title, dialogueType, isActive, colorLabel, ruleID } }) => {
        const backgroundColor = DIALOGUE_LABEL_COLORS.get(colorLabel) ?? DEFAULT_DIALOGUE_LABEL_COLOR;

        const updateBackgroundColor = (element, color) => {
          if (element) {
            element.style.setProperty('--dialogue-after-bg-color', color);
          }
        };

        return (
          <Link
            className={cx(styles.dialogue, { [styles.dialogueDisabled]: !isActive })}
            to={`${getBuildUrl({
              buildIdObject,
              dialogueType,
              target: id,
            })}?rule=${ruleID}&tab=advanced+options&formContent=output`}
            ref={(element) => updateBackgroundColor(element, backgroundColor)}
          >
            {title}
          </Link>
        );
      },
      rule: ({ data: { name, id, dialogueType, dialogueID } }) => (
        <Link
          className={styles.rule}
          to={`${getBuildUrl({
            buildIdObject,
            dialogueType,
            target: dialogueID,
          })}?rule=${id}&tab=advanced+options&formContent=output`}
        >
          <Icon component={SpeechMessage} />
          {name}
        </Link>
      ),
    }),
    [buildIdObject],
  );
  return renderMap;
};
