import { gql } from '@apollo/client';

export default gql`
  fragment ZodiacModel on ZodiacModelType {
    id
    status
    created
    language
    learner
    featurizer
    samples
    accuracy
    apsc
    f1
    precision
    recall
  }
`;
