import { useCallback } from 'react';

// Useful when it is desireable to attach several refs to the same node

const setRef = (ref, value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null) {
    ref.current = value;
  }
};

export default (ref1, ref2) =>
  useCallback(
    (instance) => {
      setRef(ref1, instance);
      setRef(ref2, instance);
    },
    [ref1, ref2],
  );
