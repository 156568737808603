import { useMutation } from '@apollo/client';
import { orderBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { type OrganizationType, TransferToOrganizationDocument } from 'frontend/api/generated';
import { Select } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

interface TransferToOrganizationProps {
  hide(): Promise<void>;
  args: {
    botId: string;
    orgId: string;
    organizations: Partial<OrganizationType>[];
  };
}
const TransferToOrganization = ({ hide, args: { botId, orgId, organizations } }: TransferToOrganizationProps) => {
  const toast = useToast();
  const [mutate] = useMutation(TransferToOrganizationDocument);
  const organizationsList = useMemo(() => orderBy(organizations, ['name', 'id']), [organizations]);
  const onSubmit = useCallback(
    async ({ organizationId }) => {
      await mutate({
        variables: { botId, organizationId },
        update: (_, { data }) => {
          toast.success(`The workspace has been transferred to ${data?.transferToOrganization?.organization.name}`);
        },
      });
    },
    [toast, botId, mutate],
  );

  return (
    <ModalForm
      title="Transfer to organization"
      hide={hide}
      onSubmit={onSubmit}
      initialValues={{ organizationId: orgId }}
    >
      <Field component={Select} name="organizationId">
        {organizationsList.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Field>
    </ModalForm>
  );
};

export default TransferToOrganization;
