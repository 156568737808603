import { gql } from '@apollo/client';

import { TICKET } from 'frontend/features/Inbox/fragments';

export default gql`
  mutation TicketAssignUser($ticketId: ID!, $userId: ID!, $assign: Boolean!) {
    ticketAssignUser(ticketId: $ticketId, userId: $userId, assign: $assign) {
      ...Ticket
    }
  }
  ${TICKET}
`;
