import { debounce, range, uniqBy } from 'lodash';
import { Socket } from 'phoenix-socket';
import { defineAction } from 'redux-define';

import { getBotJWToken, getPlatformJWToken } from './auth';

const DASHBOARD = defineAction(
  'dashboard',
  ['RESET', 'SET_AGENTS', 'AGENT_JOINED', 'AGENT_LEFT', 'SET_ACTIVE_USERS', 'ACTIVE_USER_JOINED', 'ACTIVE_USER_LEFT'],
  'analytics',
);

let socket;
let platformSocket;
let channel;
let platformChannel;

const initialState = {
  agents: [],
  chatbubbleActive: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.RESET:
      return initialState;
    case DASHBOARD.SET_AGENTS:
      return {
        ...state,
        agents: [...action.data],
      };
    case DASHBOARD.AGENT_JOINED:
      return {
        ...state,
        agents: uniqBy([...state.agents, action.data], 'id'),
      };
    case DASHBOARD.AGENT_LEFT:
      return {
        ...state,
        agents: state.agents.filter((item) => item.phxRef !== action.data),
      };
    case DASHBOARD.SET_ACTIVE_USERS:
      return {
        ...state,
        chatbubbleActive: [...action.data],
      };
    case DASHBOARD.ACTIVE_USER_JOINED:
      return {
        ...state,
        chatbubbleActive: [...state.chatbubbleActive, action.data],
      };
    case DASHBOARD.ACTIVE_USER_LEFT:
      return {
        ...state,
        chatbubbleActive: state.chatbubbleActive.filter((item) => item !== action.data),
      };
    default:
      return state;
  }
};

// selectors
export const selectActiveChatbubbles = (state) => state.analytics.dashboard.chatbubbleActive;
export const selectActiveAgents = (state) => state.analytics.dashboard.agents;

export const selectCurrentTraffic = (state) => ({
  chatbubbleActive: selectActiveChatbubbles(state),
  agents: uniqBy(selectActiveAgents(state), 'id'),
});

const onPlatformState = (dispatch) => (payload) => {
  dispatch({
    type: DASHBOARD.SET_ACTIVE_USERS,
    data: range(payload.users.active),
  });
};

const connectPlatformSocket = (token) => (dispatch, getState) => {
  if (window.env.SAGE_WS_DISABLED === '1') {
    return;
  }

  platformSocket = new Socket(`${window.env.SAGE_WS_HOST}/platform/socket`, {
    params: {
      token,
    },
  });
  platformSocket.connect();

  platformChannel = platformSocket.channel('platform:presence');
  platformChannel.on('after_join', debounce(onPlatformState(dispatch, getState), 100));
  platformChannel.join();
};

const connectAgentSocket = (token, botId) => (dispatch, getState) => {
  if (window.env.SAGE_WS_DISABLED === '1') {
    return;
  }

  socket = new Socket(`${window.env.SAGE_WS_HOST}/agent/${botId}/socket`, {
    params: {
      token,
    },
  });
  socket.connect();
  channel = socket.channel(`agent:${botId}`);

  const onAgentState = (payload) => {
    const data = uniqBy(
      payload.agents.metas.map((item) => ({
        fullName: item.full_name,
        phxRef: item.phx_ref,
        id: item.user_id,
      })),
      'id',
    );
    dispatch({
      type: DASHBOARD.SET_AGENTS,
      data,
    });
  };

  const onAgentDiff = (payload) => {
    if (payload.joins.agents) {
      payload.joins.agents.metas.forEach((item) => {
        dispatch({
          type: DASHBOARD.AGENT_JOINED,
          data: {
            fullName: item.full_name,
            phxRef: item.phx_ref,
            id: item.user_id,
          },
        });
      });
    }
    if (payload.leaves.agents) {
      payload.leaves.agents.metas.forEach((item) => {
        dispatch({
          type: DASHBOARD.AGENT_LEFT,
          data: item.phx_ref,
        });
      });
    }
  };

  const onChatBubbleActiveState = (payload) => {
    if (!payload.users) return;
    const data = payload.users.metas.map((item) => item.phx_ref);
    dispatch({
      type: DASHBOARD.SET_ACTIVE_USERS,
      data,
    });
  };

  const onChatBubbleActiveDiff = (payload) => {
    const users = getState().analytics.dashboard.chatbubbleActive;
    if (payload.joins.users) {
      payload.joins.users.metas.forEach((item) => {
        if (users.indexOf(item.phx_ref) === -1) {
          dispatch({
            type: DASHBOARD.ACTIVE_USER_JOINED,
            data: item.phx_ref,
          });
        }
      });
    }
    if (payload.leaves.users) {
      payload.leaves.users.metas.forEach((item) => {
        if (users.indexOf(item.phx_ref) !== -1) {
          dispatch({
            type: DASHBOARD.ACTIVE_USER_LEFT,
            data: item.phx_ref,
          });
        }
      });
    }
  };

  const UPDATE_ANALYTICS_DELAY = 2 * 1000; // in ms
  channel.on('agent_state', debounce(onAgentState, UPDATE_ANALYTICS_DELAY));
  channel.on('agent_diff', debounce(onAgentDiff, UPDATE_ANALYTICS_DELAY));
  channel.on('chatbubble_active_state', debounce(onChatBubbleActiveState, UPDATE_ANALYTICS_DELAY));
  channel.on('chatbubble_active_diff', debounce(onChatBubbleActiveDiff, UPDATE_ANALYTICS_DELAY));
  channel.join();
};

export const connectAnalytics = (botId) => async (dispatch) => {
  const token = await dispatch(getBotJWToken(botId));
  await dispatch(connectAgentSocket(token, botId));
};

export const disconnectAnalytics = () => async (dispatch) => {
  if (socket) {
    socket.disconnect();
  }

  dispatch({
    type: DASHBOARD.RESET,
  });
};

export const connectPlatformAnalytics = () => async (dispatch) => {
  const token = await dispatch(getPlatformJWToken());
  await dispatch(connectPlatformSocket(token));
};

export const disconnectPlatformAnalytics = () => async (dispatch) => {
  if (platformSocket) {
    platformSocket.disconnect();
  }

  dispatch({
    type: DASHBOARD.RESET,
  });
};
