import StatisticsEmptyIcon from 'frontend/assets/icons-unsync/StatisticsEmptyIcon';
import { Icon } from 'frontend/components';

import styles from './Panel.scss';

interface EmptyStateProps {
  children: React.ReactNode;
}

const EmptyState = ({ children }: EmptyStateProps) => (
  <div className={styles.emptyState}>
    <Icon component={StatisticsEmptyIcon} className={styles.icon} />
    <div className={styles.emptyStateContent}>{children}</div>
  </div>
);

export default EmptyState;
