import { useMutation } from '@apollo/client';
import { Field } from 'react-final-form';

import { CloneBotDocument } from 'frontend/api/generated';
import { Checkbox, Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';

interface CloneBotModalProps {
  hide(): Promise<void>;
  args: {
    botId: string;
    botName: string;
    isIntentBot: boolean;
    canManageFeatureFlags: boolean;
  };
}
export default function CloneBotModal({
  hide,
  args: { botId, botName, isIntentBot, canManageFeatureFlags },
}: CloneBotModalProps) {
  const validName = botName.slice(0, 42); // max 50 chars in backend minus 8 chars for the text ' (clone)'
  const initialValues = { name: `${validName} (clone)`, convertToIntents: false };

  const toast = useToast();
  const [mutate] = useMutation(CloneBotDocument);
  const onSubmit = async ({ name, convertToIntents }) => {
    await mutate({ variables: { botId, cloneName: name, convertToIntents } });
    toast.success(`The workspace ${name} is being cloned. This may take a moment...`);
  };

  return (
    <ModalForm
      onSubmit={onSubmit}
      title="Clone workspace"
      onOkText="Clone"
      hide={hide}
      initialValues={initialValues}
      ignoreDirty
    >
      <Field
        component={Input}
        label="Name your cloned workspace"
        className="m-b-sm"
        placeholder="Name"
        name="name"
        inputLimit={50}
        validate={chain([required, max(50)])}
        autoFocus
      />
      {!isIntentBot && canManageFeatureFlags && (
        <Field
          component={Checkbox}
          label="Convert Dialogue Samples to Intents"
          name="convertToIntents"
          type="checkbox"
          className="kindlyAdminManager"
        />
      )}
    </ModalForm>
  );
}
