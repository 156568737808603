const MailIcon = () => (
  <svg width="104" height="86" viewBox="0 0 104 86" xmlns="http://www.w3.org/2000/svg">
    <g strokeWidth="4" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M95.2330827 2H8.21553885C4.48621554 2 2 4.40601504 2 8.01503759" stroke="#2C2C2C" />
      <path
        d="M2 8.01503759v8.74219451l50.0036134 28.0999108 42.4775896-23.282155"
        stroke="#0069FF"
        strokeLinejoin="round"
      />
      <path d="M101.2481203 17.7894737V6c0-2.209139-1.790861-4-4-4h-8.7819549" stroke="#ED34FC" />
      <path
        d="M2 25.3082707V76.977767c0 3.7351443 2.5 6.2252405 6.25 6.2252405h87.5c3.75 0 6.25-2.4900962 6.25-6.2252405V25.3082707"
        stroke="#3DD01E"
      />
    </g>
  </svg>
);

export default MailIcon;
