import { useQuery } from '@apollo/client';

import { UserOrganizationMembershipsDocument, type UserOrganizationMembershipsQuery } from 'frontend/api/generated';

type Return =
  | { loading: true; user: undefined; organizationRoles: never[]; botMemberships: never[] }
  | {
      loading: false;
      user: UserOrganizationMembershipsQuery['organization']['member']['user'] | undefined;
      organizationRoles: UserOrganizationMembershipsQuery['organization']['member']['membership']['roles'];
      botMemberships: UserOrganizationMembershipsQuery['organization']['member']['membership']['botMemberships'];
    };

export function useOrganizationUserDetail({
  organizationId,
  userId,
}: {
  organizationId: string;
  userId: string;
}): Return {
  const { data, loading } = useQuery(UserOrganizationMembershipsDocument, { variables: { organizationId, userId } });
  const user = data?.organization.member?.user;
  const organizationRoles = data?.organization.member.membership.roles ?? [];
  const botMemberships = data?.organization?.member?.membership?.botMemberships ?? [];

  return loading
    ? { loading: true, user: undefined, organizationRoles: [], botMemberships: [] }
    : {
        loading: false,
        user,
        organizationRoles,
        botMemberships,
      };
}
