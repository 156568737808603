import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { AllPermissionsDocument } from 'frontend/api/generated';

import { RoleSettings } from '../../../../components';
import { useOrgSettings } from '../../useOrgSettings';

const EMPTY_STATE_TEXT =
  'This organization has no custom roles. To create one, click the "Create new custom role" button above.';

export default function CustomRoles() {
  const { loading, onSubmit, initialValues } = useOrgSettings();
  const { loading: allPermissionsLoading, data: allPermissionsData } = useQuery(AllPermissionsDocument);
  const allPermissions = useMemo(() => allPermissionsData?.allPermissions ?? [], [allPermissionsData]);

  return (
    <RoleSettings
      loading={loading || allPermissionsLoading}
      onSubmit={onSubmit}
      initialValues={initialValues}
      allPermissions={allPermissions}
      emptyStateText={EMPTY_STATE_TEXT}
    />
  );
}
