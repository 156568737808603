import { isRegularDialogueType } from 'frontend/constants';

import removedialogueFromCache from './removeDialogue';
import updateFollowupsCount from './updateFollowupsCount';
import updateLibraryDialogues from './updateLibraryDialogues';
import updateModFollowupsCount from './updateModFollowupsCount';
import { getDialogueParentVariables } from '../utils';

const updateRegularParent = ({ dialogue, buildIdObject, client }) => {
  // Evict dialogue from cache
  removedialogueFromCache(dialogue.id, client.cache);
  // Also remove its dangling references from the other queries
  const parentVariables = getDialogueParentVariables(buildIdObject, dialogue);
  updateLibraryDialogues({ variables: parentVariables, client, removedDialogue: dialogue });

  // Update followup count of original parent if it exists
  if (dialogue.parentId) {
    updateFollowupsCount({
      client,
      update: -1,
      id: dialogue.parentId,
      variables: buildIdObject,
    });
  } else if (dialogue.dialogueModParentId) {
    updateModFollowupsCount({ client, update: -1, id: dialogue.dialogueModParentId, variables: buildIdObject });
  }
};

const updateSpecialFolder = ({ buildIdObject, dialogueType, client, dialogue }) => {
  // Evict dialogue from cache
  removedialogueFromCache(dialogue.id, client.cache);
  // Also remove its dangling references from the other queries
  const variables = { ...buildIdObject, dialogueType, regular: false };
  updateLibraryDialogues({ variables, client, removedDialogue: dialogue });
};

export default ({ dialogue, buildIdObject, client }) => {
  const { dialogueType } = dialogue;
  if (!isRegularDialogueType(dialogueType)) updateSpecialFolder({ buildIdObject, dialogueType, client, dialogue });
  else updateRegularParent({ dialogue, buildIdObject, client });
};
