export const MESSAGE_TYPES = Object.freeze({
  ALL: 'ALL',
  FALLBACKS: 'FALLBACKS',
  DIALOGUES: 'DIALOGUES',
});

export const COMPUTATION_STATES = Object.freeze({
  ERROR: 'ERROR',
  FINISHED: 'FINISHED',
  INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
  PENDING: 'PENDING',
});
