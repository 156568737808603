import { gql } from '@apollo/client';

import ORGANIZATION_USER from 'frontend/features/Organization/fragments/OrganizationUser.fragment.graphql';

export default gql`
  query OrganizationUsers($id: ID!) {
    organization(id: $id) {
      id
      name
      permissions
      members {
        id
        user {
          ...OrganizationUser
        }
        membership {
          id
          agentProfile {
            id
            availability
          }
        }
      }
    }
  }
  ${ORGANIZATION_USER}
`;
