import { format } from 'date-fns';
import { useCallback } from 'react';

import { Calendar } from 'frontend/assets/icons';
import { DatePicker, Dropdown, Icon } from 'frontend/components';
import type { DropdownPosition } from 'frontend/components/Dropdown/Dropdown';
import { useDateRanges } from 'frontend/features/Analytics/hooks';
import { useUrlSearchParams } from 'frontend/hooks';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

import styles from './styles.scss';

interface DropDownOverlayProps {
  close?(): void;
  from: Date;
  to: Date;
  updateDateRanges(filters): void;
}

const DropDownOverlay = ({ close, from, to, updateDateRanges }: DropDownOverlayProps) => (
  <DatePicker
    showDatepickerStaticRange
    initialStartDate={from}
    initialEndDate={to}
    name="dropDownDatePicker"
    onSave={(dateFilters) => {
      updateDateRanges(dateFilters);
      close?.();
    }}
    hideCompare
  />
);

interface DatePickerDropdownProps {
  position?: DropdownPosition;
}

const DatePickerDropdown = ({ position = 'bottom-right' }: DatePickerDropdownProps) => {
  const [, setParams] = useUrlSearchParams();
  const { from, to, toParams } = useDateRanges();
  const updateDateRanges = useCallback(
    (dateFilters) => setParams(toParams({ after: dateFilters?.[0].startDate, before: dateFilters?.[0]?.endDate })),
    [setParams, toParams],
  );

  return (
    <div>
      <Dropdown
        overlayClassName={styles.dropdown}
        position={position}
        overlay={<DropDownOverlay from={from} to={to} updateDateRanges={updateDateRanges} />}
      >
        <div className={styles.calendarWrapper}>
          <div>
            <div className={styles.calendarButton}>
              <Icon component={Calendar} />
            </div>
          </div>
          <div className={styles.dateWrapper}>
            <div className={styles.primaryDates}>
              {format(from, DATE_FORMAT_DATE)} - {format(to, DATE_FORMAT_DATE)}
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default DatePickerDropdown;
