import { gql } from '@apollo/client';

import { INBOX_AGENT } from '../fragments';

export default gql`
  query InboxAgents($id: ID!) {
    bot(id: $id) {
      id
      inboxAgentMembers {
        id
        user {
          ...InboxAgent
        }
      }
    }
  }
  ${INBOX_AGENT}
`;
