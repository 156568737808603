import cx from 'classnames';
import type { ReactNode } from 'react';

import { Button, InfoButton } from 'frontend/components';

import styles from './styles.scss';

interface HeadingProps {
  title: string;
  subtitle?: string | ReactNode;
  metric?: React.ReactNode;
  className?: string;
  onHeaderIconClick?: (title: string) => void;
  onShowDetailClick?: () => void;
  to?: string;
}

const Heading = ({
  title,
  className,
  subtitle = '',
  metric = null,
  onHeaderIconClick,
  onShowDetailClick,
  to,
}: HeadingProps) => (
  <div className={cx(styles.heading, className)}>
    <div className={styles.titleWrapper}>
      <h1 className={styles.title}>
        {title} {!!metric && <span className={styles.metric}>{metric}</span>}
      </h1>
      {onHeaderIconClick && (
        <InfoButton title="More information" className={styles.infoButton} onClick={() => onHeaderIconClick(title)} />
      )}
    </div>
    {!!to && <Button to={to} size="small" text="Details" />}
    {onShowDetailClick && <Button onClick={onShowDetailClick} size="small" text="Details" />}
    <p className={styles.subtitle}>{subtitle}</p>
  </div>
);

export default Heading;
