import cx from 'classnames';

import styles from './Image.scss';

interface DragAttributes {
  'aria-describedby'?: string;
  'aria-pressed'?: boolean;
  'aria-roledescription'?: string;
  tabIndex?: number;
  role?: string;
}

export interface ImageType {
  id?: string;
  tempId?: string;
  linkUrl?: string;
  linkText?: string;
  imageUrl: string;
  productUrl?: string;
  price?: number;
  title?: string;
  description?: string;
  altText?: string;
  newTab?: boolean;
}
export type ImageField = Exclude<keyof ImageType, 'id' | 'tempId'>;

interface ImageProps {
  item: ImageType;
  setNodeRef?(node: HTMLElement | null): void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  isSelected?: boolean;
  dragAttributes?: DragAttributes;
}

const Image = ({
  item: image,
  className,
  isSelected,
  children,
  style,
  setNodeRef,
  dragAttributes = {},
}: ImageProps) => (
  <div
    className={cx(styles.image, className, { [styles.imageSelected]: isSelected })}
    style={style}
    ref={setNodeRef}
    aria-describedby={dragAttributes['aria-describedby']}
    aria-pressed={dragAttributes['aria-pressed']}
    aria-roledescription={dragAttributes['aria-roledescription']}
    tabIndex={dragAttributes.tabIndex}
    role={dragAttributes.role}
  >
    <img src={image.imageUrl} alt="User upload" />
    {children}
  </div>
);

export default Image;
