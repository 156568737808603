import { DndContext } from '@dnd-kit/core';

import { ChildrenType } from 'frontend/propTypes';

import DndWrapper from './DndWrapper';

const DndContainer = ({ children }) => (
  <DndContext>
    <DndWrapper>{children}</DndWrapper>
  </DndContext>
);

DndContainer.propTypes = {
  children: ChildrenType.isRequired,
};

export default DndContainer;
