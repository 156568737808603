import cx from 'classnames';
import PropTypes from 'prop-types';

import { Close } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import { AttachmentType } from 'frontend/features/Inbox/propTypes';
import { isFileTypeImage } from 'frontend/utils';

import styles from './HandoverForm.scss';

const Attachment = ({ file, removeFile }) => (
  <div className={styles.attachmentWrapper}>
    {isFileTypeImage(file.type) ? (
      <img src={file.source} alt="Uploaded from agent" />
    ) : (
      <div className={styles.attachment}>{file.name}</div>
    )}
    {removeFile && (
      <Button className={cx(styles.closeButton, styles.closeButtonAttachment)} onClick={removeFile} flat>
        <Icon component={Close} color="white" tabIndex={0} title={`Remove file ${file.name}`} />
      </Button>
    )}
  </div>
);

Attachment.propTypes = {
  file: AttachmentType.isRequired,
  removeFile: PropTypes.func,
};

export default Attachment;
