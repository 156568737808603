import { isNil } from 'lodash';
import { useState } from 'react';
import { useDrop } from 'react-dnd';

import { DIALOGUE_TYPES, dragAndDropTypes } from 'frontend/constants';
import type { BreadCrumbCheck } from 'frontend/features/Library/utils/breadcrumbs';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { useDialogueFolderContextMenu, useDropInFolder } from './hooks';
import { getFolderClassnames, isSubscriptionPath } from '../../utils';
import BottomFolder from '../BottomFolder';

const checkBreadcrumbs: BreadCrumbCheck = (path, breadcrumbs) => {
  // Wait for the breadcrumbs to load, or there will be false positives on dialogue mod followups
  if (isNil(breadcrumbs)) return false;
  if (isSubscriptionPath(path, breadcrumbs)) return false;

  return Boolean(breadcrumbs.find(({ type }) => type === 'dialogues'));
};

const canDrop = ({ type, parentId, topicId, parentTopicId, dialogueModParentId }): boolean => {
  if (type === dragAndDropTypes.TOPIC) return Boolean(parentTopicId);
  if (type === dragAndDropTypes.DIALOGUE) return Boolean(topicId || parentId || dialogueModParentId);
  return false;
};

type Props = { buildIdObject: BuildIdObject };
const DialogueFolder = ({ buildIdObject }: Props) => {
  const { actions, contextMenuId } = useDialogueFolderContextMenu();
  const [showLoader, setShowLoader] = useState(false);
  const { drop } = useDropInFolder({ buildIdObject, setShowLoader });

  const [dropProps, dropRef] = useDrop({
    accept: [dragAndDropTypes.DIALOGUE, dragAndDropTypes.TOPIC],
    collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
    canDrop,
    drop,
  });

  return (
    <BottomFolder
      buildIdObject={buildIdObject}
      actions={actions}
      contextMenuId={contextMenuId}
      showLoader={showLoader}
      name="Dialogues"
      ref={dropRef}
      checkBreadcrumbs={checkBreadcrumbs}
      className={getFolderClassnames(dropProps)}
      dialogueType={DIALOGUE_TYPES.REGULAR}
      setShowLoader={setShowLoader}
      indentationLevel={0}
    />
  );
};

export default DialogueFolder;
