import { split } from 'lodash';
import { useMemo } from 'react';

import { useUrlSearchParams } from 'frontend/hooks';

import { getUrlArrayParam } from '../utils';

const filterToUrlParams = getUrlArrayParam('lang');

function useLanguageCodes(): [string[], CallableFunction] {
  const [{ lang }] = useUrlSearchParams();
  const languageCodes = useMemo(() => (lang ? split(lang, '-') : []), [lang]);

  return [languageCodes, filterToUrlParams];
}

export default useLanguageCodes;
