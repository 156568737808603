import { useMemo } from 'react';

import type { SkillType } from 'frontend/api/generated';
import { DropdownButton, PageBar } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';

import CloneSkillModal from './CloneSkillModal';
import DeleteSkillModal from './DeleteSkillModal';
import TransferSkillToOrganizationModal from './TransferSkillToOrganizationModal';

type Props = { skill: Pick<SkillType, 'id' | 'name' | 'permissions'> };
const SettingsPageBar = ({ skill }: Props) => {
  const showDropdown = (skill?.permissions || []).includes('manage_skill');
  const { id: skillId, name } = skill;

  const [showCloneSkillModal] = useModal(CloneSkillModal);
  const [showDeleteSkillModal] = useModal(DeleteSkillModal);
  const [showTransferSkillToOrganizationModal] = useModal(TransferSkillToOrganizationModal);

  const actions = useMemo(
    () => [
      {
        title: 'Clone skill',
        key: 'skill-settings-dropdown-clone-skill',
        onClick: () => showCloneSkillModal({ skillId, name }),
      },
      {
        title: 'Delete skill',
        key: 'skill-settings-dropdown-delete-skill',
        onClick: () => showDeleteSkillModal({ skillId, name }),
        color: 'danger',
      },
      {
        title: 'Transfer ownership to organization',
        key: 'skill-settings-dropdown-transfer-skill-to-organization',
        onClick: () => showTransferSkillToOrganizationModal({ skillId }),
      },
    ],
    [name, showCloneSkillModal, showDeleteSkillModal, showTransferSkillToOrganizationModal, skillId],
  );

  return (
    <PageBar>
      <PageBar.FormButtons className="m-r-1" />
      {showDropdown && <DropdownButton actions={actions} vertical />}
    </PageBar>
  );
};

export default SettingsPageBar;
