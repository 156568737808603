import { useQuery } from '@apollo/client';

import { AllConnectorsDocument, type ConnectorType } from 'frontend/api/generated';
import { LoaderSwitch } from 'frontend/components';

import { ConnectList } from '../components/ConnectList';

const ChatClients = () => {
  const { data: connectorsData, loading } = useQuery(AllConnectorsDocument);
  if (!connectorsData?.allConnectors) return null;

  return (
    <LoaderSwitch size="large" loading={loading}>
      <ConnectList data={connectorsData.allConnectors as ConnectorType[]} />
    </LoaderSwitch>
  );
};

export default ChatClients;
