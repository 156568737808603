import { gql } from '@apollo/client';

import { BUILD_ACTIVITY } from '../fragments';

export default gql`
  query BuildActivity($groupId: ID!, $botId: ID, $skillId: ID) {
    buildActivity(groupId: $groupId, botId: $botId, skillId: $skillId) {
      ...BuildActivity
    }
  }
  ${BUILD_ACTIVITY}
`;
