import { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { Condition, LoaderSwitch, PageBar, Panel } from 'frontend/components';
import { useCurrentLanguage, useToast } from 'frontend/hooks';

import { AllowList, ToggleSpellingCorrection } from './components';
import { useBotSettings, useUpdateBot } from '../../../hooks';

const SpellingCorrection = () => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { botId } = useParams();
  const toast = useToast();

  const { loading, botSettings } = useBotSettings(botId);
  const onUpdate = useCallback(() => toast.success('Spelling correction settings updated!'), [toast]);
  const updateBot = useUpdateBot(botSettings, onUpdate);

  const initialValues = useMemo(
    () => ({
      nlpUseSpellingCorrection: botSettings?.nlpSettings?.useSpellingCorrection || {},
      nlpSpellingCorrectionWhitelist: botSettings?.nlpSettings?.spellingCorrectionWhitelist || {},
    }),
    [botSettings],
  );

  return (
    <LoaderSwitch loading={!!loading} size="large">
      <Form
        onSubmit={updateBot}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <PageBar>
              <PageBar.FormButtons />
            </PageBar>

            <Panel>
              <ToggleSpellingCorrection currentLanguage={currentLanguage} />
              <Condition when={`nlpUseSpellingCorrection.${currentLanguage}`} is>
                <>
                  <Panel.Separator />
                  <AllowList currentLanguage={currentLanguage} />
                </>
              </Condition>
            </Panel>
          </form>
        )}
      />
    </LoaderSwitch>
  );
};

export default SpellingCorrection;
