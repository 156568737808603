import PropTypes from 'prop-types';

import styles from './RoleSettings.scss';

const EmptyCustomRoles = ({ text }) => <div className={styles.emptyCustomRoles}>{text}</div>;

EmptyCustomRoles.propTypes = {
  text: PropTypes.string.isRequired,
};

export default EmptyCustomRoles;
