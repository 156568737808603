import cx from 'classnames';
import { capitalize } from 'lodash';
import { createRef } from 'react';
import { useParams } from 'react-router-dom';

import { DotsVertical, Duplicate, Eye, EyeOff, Trash } from 'frontend/assets/icons';
import { Button, Icon, List } from 'frontend/components';
import ContextMenu, { type ContextMenuRef } from 'frontend/components/ContextMenu/ContextMenu';
import MenuOverlay, { type Option } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DeleteModal, useModal } from 'frontend/features/Modals';
import { useCurrentLanguage } from 'frontend/hooks';

import styles from './ProactivityItem.scss';

const ProactivityDelete = ({ hide, args: { onSubmit, item, currentLanguage, show } }) => (
  <DeleteModal
    onSubmit={onSubmit}
    name={`${item.title?.[currentLanguage] || capitalize(show.replace('-', ' '))}`}
    hide={hide}
    action="delete"
  >
    Are you sure you want to delete {capitalize(show.replace('-', ' '))} <b>{item.title?.[currentLanguage] || ''}</b>.{' '}
    This cannot be undone.
  </DeleteModal>
);

const getLinkType = (showType) => {
  switch (showType) {
    case 'push-greeting':
      return 'push-greeting';
    case 'global-icon':
      return 'global-icon';
    default:
      return 'nudge';
  }
};

type ProactivityItemType = {
  item: {
    enabled?: Record<string, boolean>;
    type?: string | null | undefined;
    title?: Record<string, boolean>;
    slug?: string | null | undefined;
    id: string;
  };
  show: 'nudge' | 'push-greeting' | 'global-icon';
  onClick: (action: 'publish' | 'duplicate' | 'delete') => any;
};

const ProactivityItem = ({ item, show, onClick }: ProactivityItemType) => {
  const { botId } = useParams();
  const [{ currentLanguage }] = useCurrentLanguage();
  const [showDeleteModal] = useModal(ProactivityDelete);

  const ref = createRef<ContextMenuRef>();

  const { enabled, type, title, slug, id } = item;

  const currentTitle = title?.[currentLanguage] || slug;
  const currentEnabled = enabled?.[currentLanguage];

  const actions: ('separator' | Option)[] = [
    {
      text: currentEnabled ? 'Unpublish' : 'Publish',
      icon: currentEnabled ? EyeOff : Eye,
      onClick: () => onClick('publish'),
    },
    'separator',
    {
      text: 'Delete',
      color: 'red',
      icon: Trash,
      onClick: () => showDeleteModal({ onSubmit: () => onClick('delete'), currentLanguage, item, botId, show }),
    },
  ];

  // TODO support duplicate for nudges
  // its more complicated due to the slug that is unique and set by the user upon nudge creation
  if (show === 'push-greeting' || show === 'global-icon') {
    actions.splice(1, 0, {
      text: 'Duplicate',
      icon: Duplicate,
      onClick: () => onClick('duplicate'),
    });
  }

  return (
    <List.NavLinkItem
      key={id}
      className={styles.proactivtyNavLink}
      to={`/workspace/${botId}/build/proactivity/${getLinkType(show)}/${slug || id}`}
    >
      <div className={cx(styles.item, { [styles.itemDisabled]: !currentEnabled })}>
        <span className={styles.title}>{currentTitle || type}</span>
      </div>
      <ContextMenu id={`context-menu-${id}`} ref={ref} overlay={<MenuOverlay options={actions} />}>
        <Button
          className={styles.dropdownTriggerBtn}
          size="small"
          onClick={(e) => ref?.current?.openMenu(e as React.MouseEvent<HTMLButtonElement>)}
        >
          <Icon component={DotsVertical} />
        </Button>
      </ContextMenu>
    </List.NavLinkItem>
  );
};

export default ProactivityItem;
