import { useQuery } from '@apollo/client';

import type { DialogueRuleType } from 'frontend/api/generated';
import { GetDialogue } from 'frontend/features/Build/graphql';

interface Props {
  botId: string;
  dialogueId: string;
  language: string;
}

/** Custom hook to get dialogue rules for a given dialogue (it doesn't add the default rule).
 * @param botId - The ID of the bot
 * @param dialogueId - The ID of the dialogue
 * @param language - The language code
 * @returns {dialogueRules, loading} - The dialogue rules, loading state
 */
export function useDialogueRules({ botId, dialogueId, language }: Props): {
  dialogueRules: Partial<DialogueRuleType>[];
  loading: boolean;
} {
  const { data, loading } = useQuery(GetDialogue, {
    variables: { botId, dialogueId, languageCode: language },
    skip: !botId || !dialogueId || !language,
  });

  const dialogueRules =
    data?.dialogue?.dialogueRules?.filter((rule: DialogueRuleType) => rule.languageCode === language) || [];

  return { dialogueRules, loading };
}
