import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { Button, Input, ShakeDiv } from 'frontend/components';
import { chain, email, required } from 'frontend/form/validators';
import { useAzureLogin, useGoogleLogin, useUrlSearchParams } from 'frontend/hooks';
import PublicLayout from 'frontend/layouts/PublicLayout/PublicLayout';
import { isAuthenticated, login, selectError } from 'frontend/state/dux/auth';
import { useAppDispatch } from 'frontend/state/hooks';

import AzureLoginButton from './AzureLoginButton';
import GoogleLoginButton from './GoogleLoginButton';
import HardRedirect from './HardRedirect';
import styles from './Login.scss';
import RouterRedirect from './RouterRedirect';

const authViewPath = '/accounts/help_center_auth/';

const Login = () => {
  const dispatch = useAppDispatch();
  const { search, pathname } = useLocation();
  const authenticated = useSelector(isAuthenticated);
  const loginError = useSelector(selectError);
  const onGoogleResponse = useGoogleLogin();
  const onAzureResponse = useAzureLogin();

  const onSubmit = useCallback(({ username, password }) => dispatch(login(username, password)), [dispatch]);

  const [{ next }] = useUrlSearchParams();
  const nextIsServerSide = next?.startsWith(authViewPath);

  if (authenticated && nextIsServerSide) return <HardRedirect next={next} />;
  if (authenticated) return <RouterRedirect next={next} />;

  return (
    <PublicLayout>
      <div className={styles.login}>
        <h1 className={styles.loginHeader}>Login</h1>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                component={Input}
                className="m-b-2"
                name="username"
                placeholder="Email"
                inputType="email"
                validate={chain([email, required])}
                label="Email"
                autoFocus
                autoComplete="username"
              />
              <Field
                component={Input}
                className="m-b-2"
                name="password"
                placeholder="Password"
                inputType="password"
                validate={required}
                label="Password"
                autoComplete="current-password"
              />
              <ShakeDiv animate={loginError ? 'error' : 'normal'} className="m-t-4 m-b-4">
                {loginError && <div className={styles.loginError}>{loginError?.message}</div>}
              </ShakeDiv>
              <div className={styles.btnContainer}>
                <NavLink to={`/reset_password/?next=${window.encodeURI(pathname + search)}`}>
                  Having trouble logging in?
                </NavLink>
                <Button type="submit" color="primary">
                  Login
                </Button>
              </div>
              <div className="g-signin2" data-onsuccess="onSignIn" data-theme="dark" />
              <div className={styles.ssoOptions}>
                <GoogleLoginButton onGoogleResponse={onGoogleResponse} />
                <AzureLoginButton onAzureResponse={onAzureResponse} />
              </div>
            </form>
          )}
        />
      </div>
    </PublicLayout>
  );
};

export default Login;
