import { uniqBy } from 'lodash';
import { useCallback } from 'react';

import { updateItemInCache } from 'frontend/api/cacheHelpers';
import { CACHE_TYPE } from 'frontend/features/Inbox/constants';

import { CHAT } from '../fragments';

const { BUTTON_CLICK_TYPE, CHAT_TYPE } = CACHE_TYPE;

const fragment = CHAT;
const fragmentName = 'Chat';

export default (cache) => {
  const onButtonClick = useCallback(
    (buttonClick) => {
      const id = buttonClick.chatId;
      const update = ({ buttonClicks = [], ...chat }) => ({
        ...chat,
        buttonClicks: uniqBy([...buttonClicks, { ...buttonClick, __typename: BUTTON_CLICK_TYPE }], 'id'),
      });

      updateItemInCache({ fragment, typeName: CHAT_TYPE, fragmentName, update, cache, id });
    },
    [cache],
  );

  return onButtonClick;
};
