import { useCallback, useMemo, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { validate as validateUUID } from 'uuid';

import type { ChatbubbleSettingsQuery } from 'frontend/api/generated';
import PushGreetingsImage from 'frontend/assets/images/push_greeting.png';
import { FormErrors, GuardUnsaved, LoaderSwitch, Panel } from 'frontend/components';
import EmptyFeature from 'frontend/components/EmptyFeature';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';

import styles from './PushGreetings.scss';
import { validatePushGreetings } from './validators';
import PushGreetingItem from '../PushGreetingItem';

interface FormValues {
  pushGreetings: object;
}

const getPushGreetingName = (pushGreetings, id) => {
  if (id && validateUUID(id)) {
    return `pushGreetings[${pushGreetings?.findIndex((item) => id === item.id)}]`;
  }
  return `pushGreetings[${pushGreetings.length}]`;
};

const isOnPushGreetingsRoute = (locationPath) =>
  locationPath.endsWith('push-greetings') || locationPath.endsWith('push-greetings/');

const PushGreetings = () => {
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const onSuccess = (data: NonNullable<ChatbubbleSettingsQuery>['chatbubbleSettings'] | null): void => {
    if (
      data?.pushGreetings?.length &&
      // navigate to the created item if new
      window.location.pathname.endsWith('push-greetings')
    ) {
      navigate(`../proactivity/push-greeting/${data.pushGreetings[0]?.id || ''}`);
    }
  };

  const location = useLocation();

  const { onSubmit, initialValues, currentLanguage, loading, languagesLoading } = useChatbubbleForm({
    onSuccess,
  });

  const defaultValues = useMemo<Record<string, any>>(
    () => ({
      ...initialValues,
      pushGreetings: [
        ...(initialValues.pushGreetings || []),
        ...(isOnPushGreetingsRoute(location.pathname)
          ? [
              {
                enabled: { [currentLanguage]: false },
              },
            ]
          : []),
      ],
    }),
    [initialValues, currentLanguage, location],
  );

  const validate = useCallback((values) => validatePushGreetings(currentLanguage, values), [currentLanguage]);

  const { pushGreetingId } = useParams();

  const { Form } = withTypes<FormValues>();

  return (
    <LoaderSwitch loading={loading || languagesLoading} size="large">
      {!initialValues.pushGreetings?.length && !isCreating ? (
        <EmptyFeature
          imageSrc={PushGreetingsImage as string}
          title="Create a friendly welcome message"
          buttonText="Create push greeting"
          description="Push greetings appear next to the chat bubble. Create a push message to encourage site visitors to chat with the chatbot."
          onClick={() => setIsCreating(true)}
        />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={defaultValues}
          validate={validate}
          render={({ handleSubmit }) => (
            <Panel size="medium">
              <form onSubmit={handleSubmit}>
                {!!pushGreetingId && <GuardUnsaved />}
                <FormErrors className={styles.formErrors} />
                <PushGreetingItem name={getPushGreetingName(initialValues.pushGreetings, pushGreetingId)} />
              </form>
            </Panel>
          )}
        />
      )}
    </LoaderSwitch>
  );
};

export default PushGreetings;
