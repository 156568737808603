import { merge } from 'lodash';

import { filterObject } from 'frontend/utils';

import { URL_DIALOGUE_TYPES } from '../constants';

const removeLanguage = (languageCode, object) => filterObject((_, key) => key !== languageCode, object);

export default (
  currentLanguage,
  {
    urlTrigger,
    urlTriggerType,
    ...rest
  }: { urlTrigger: Record<string, string>; urlTriggerType: Record<string, unknown> } & Record<string, unknown>,
) => {
  const urlTriggerObject = { urlTrigger, urlTriggerType };

  if (!urlTrigger?.[currentLanguage]) {
    urlTriggerObject.urlTrigger = removeLanguage(currentLanguage, urlTriggerObject.urlTrigger || {});
    urlTriggerObject.urlTriggerType = removeLanguage(currentLanguage, urlTriggerObject.urlTriggerType || {});
  }

  if (urlTrigger?.[currentLanguage] && !urlTriggerType?.[currentLanguage]) {
    urlTriggerObject.urlTriggerType = merge({}, { [currentLanguage]: URL_DIALOGUE_TYPES.URL_PATH_MATCH });
  }

  return { ...rest, ...urlTriggerObject };
};
