// FIXME: component text alignment is super broken, especially without title
import cx from 'classnames';

import { Exclamation, Info } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';

import styles from './InfoBox.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  /** Component severity level.
   * @default 'info'
   */
  level?: 'info' | 'warning';
  size?: 'small' | 'medium' | 'large';
  title?: string;
}
const iconByLevel = {
  info: Info,
  warning: Exclamation,
};

/** Info component that is supposed to be used with `<p>` as text children. */
const InfoBox = ({ children, title, size = 'small', className = '', level = 'info' }: Props) => (
  <div className={cx(styles.container, styles[size], className, styles[level])}>
    <div>
      <Icon component={iconByLevel[level]} className={styles.icon} />
    </div>

    <div className={styles.text}>
      {!!title && <h4 className={styles.title}>{title}</h4>}
      {children}
    </div>
  </div>
);

export default InfoBox;
