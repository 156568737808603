import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';

import { GET_BOT_KEY } from 'frontend/api/queries';
import { Button, Panel, ToggleSwitch } from 'frontend/components';
import { useToast } from 'frontend/hooks';

import styles from './styles.scss';

const renderHTMLSnippet = (botKey) => `<script \
id="kindly-chat" \
src="${window.env.KINDLY_CHAT_URL}/kindly-chat.js" \
data-bot-key="${botKey}" \
data-shadow-dom \
defer></script>`;

const renderGTMSnippet = (botKey) => `<script>
  var script = document.createElement('script');
  script.src = '${window.env.KINDLY_CHAT_URL}/kindly-chat.js';
  script.defer = true;
  script.id = 'kindly-chat';
  script.setAttribute('data-shadow-dom', '1');
  script.setAttribute('data-bot-key', '${botKey}');
  document.body.appendChild(script);
</script>`;

const KindlyChat = () => {
  const toast = useToast();
  const { botId } = useParams();
  const [showGTM, setShowGTM] = useState(false);

  const { data, loading, error } = useQuery(GET_BOT_KEY, { variables: { botId } });

  if (loading || error) return null;

  const htmlSnippet = renderHTMLSnippet(data.bot.botKey);
  const gtmSnippet = renderGTMSnippet(data.bot.botKey);

  return (
    <>
      <span>
        Read more on configuring Kindly Chat in our{' '}
        <a className="link" target="_blank" rel="noopener noreferrer" href="https://docs.kindly.ai/api/chat">
          documentation
        </a>
        .
      </span>
      <Panel.Separator />
      <div className={styles.textContainer}>
        <h3>Get HTML code</h3>
        <ToggleSwitch.Switch
          input={{ value: showGTM }}
          onClick={() => setShowGTM(!showGTM)}
          status={['Show HTML snippet', 'Show snippet for Google Tag Manager']}
        />
        {showGTM ? (
          <>
            <p>
              When using Google Tag Manager to publish your chatbot, some additional rules apply to make it function as
              intended. <br />
              Please refer to{' '}
              <a href="https://docs.kindly.ai/api/chat#using-google-tag-manager" className="link">
                this part of our docs
              </a>{' '}
              for a complete run down of what&apos;s required.
            </p>
            <p>Put this code inside the &quot;Custom HTML&quot; editor when adding a new tag.</p>
            <pre className={styles.snippet}>{gtmSnippet}</pre>
            <CopyToClipboard
              text={gtmSnippet}
              onCopy={() => {
                toast.success('Copied to clipboard');
              }}
            >
              <Button color="primary" text="Copy to clipboard" className={`m-t-2 ${styles.copyButton}`} />
            </CopyToClipboard>
          </>
        ) : (
          <>
            <p>
              Put this code snippet inside your {'<head>'} or {'<body>'} tags:
            </p>
            <pre className={styles.snippet}>{htmlSnippet}</pre>
            <CopyToClipboard
              text={htmlSnippet}
              onCopy={() => {
                toast.success('Copied to clipboard');
              }}
            >
              <Button color="primary" text="Copy to clipboard" className={`m-t-2 ${styles.copyButton}`} />
            </CopyToClipboard>
          </>
        )}
      </div>
    </>
  );
};

export default KindlyChat;
