import { gql } from '@apollo/client';

import { botCardFragment } from 'frontend/api/fragments';

export default gql`
  query SkillSubscribers($skillId: ID!) {
    skill(id: $skillId) {
      id
      landingPageActive
      subscriptions {
        id
        bot {
          ...BotCard
        }
      }
    }
  }
  ${botCardFragment}
`;
