import { gql, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { useCurrentLanguage } from 'frontend/hooks';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import styles from './styles.scss';

const GET_UNPUBLISHED_DIALOGUES = gql`
  query GetUnpublishedDialogues($botId: ID!) {
    unpublishedDialogues(botId: $botId) {
      id
      title
      ... on DialogueType {
        updatedAt
      }
    }
  }
`;

interface Props {
  botId: string | number;
  hide: () => void;
}

const DialogueTable = ({ botId, hide }: Props) => {
  const [{ currentLanguage: currentBaseLanguage }] = useCurrentLanguage();

  const { data } = useQuery(GET_UNPUBLISHED_DIALOGUES, { variables: { botId }, fetchPolicy: 'network-only' });

  if (!(data?.unpublishedDialogues?.length > 0)) {
    // TODO: show something sensible
    return null;
  }

  return (
    <>
      <div className={styles.listHeader}>
        <span>Dialogue</span>
        <span>Last updated</span>
      </div>
      <div className={styles.dialoguesList}>
        {(data?.unpublishedDialogues ?? []).map(({ id, title, updatedAt }) => (
          <div key={id} className={styles.listElement}>
            <Link to={`/workspace/${botId}/build/dialogue/${id}`} onClick={hide}>
              {title[currentBaseLanguage]}
            </Link>
            <span>{format(updatedAt, DATE_FORMAT_WITH_TIME)}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default DialogueTable;
