import PropTypes from 'prop-types';

import styles from './styles.scss';

const HandoverDuration = ({ title, description, data }) => {
  const amount = data?.n;
  const mean = data?.mean || 0;
  const durationTime = mean / 60;
  const roundedDurationTime = Math.round(durationTime * 10) / 10;

  return (
    <>
      {amount <= 3 && <p>Not enough data to calculate an average.</p>}
      {amount > 3 && (
        <div>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{description}</p>
          <div className={styles.duration}>{`${roundedDurationTime} min`}</div>
        </div>
      )}
    </>
  );
};

HandoverDuration.propTypes = {
  data: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default HandoverDuration;
