import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ChatDeleted,
  ChatViewContent,
  ChatViewSelector,
  Conversation,
  ConversationList,
  EmptyInbox,
  MessageClustering,
} from 'frontend/features/Inbox/views';
import { useTabTitle } from 'frontend/hooks';
import { InboxLayout } from 'frontend/layouts';

const NullComponent = () => null;

const inboxPaths = Object.freeze({
  CHAT_BOTTOM: '',
  CHAT: ':chatId',
  CHAT_EMPTY: 'empty',
  CHAT_DELETED: 'deleted',
});

const InboxWrapper = () => (
  <InboxLayout>
    <ChatViewSelector />
    <ChatViewContent>
      <ConversationList />
      <Routes>
        <Route path={inboxPaths.CHAT_EMPTY} element={<EmptyInbox />} />
        <Route path={inboxPaths.CHAT_DELETED} element={<ChatDeleted />} />
        <Route path={inboxPaths.CHAT_BOTTOM} element={<NullComponent />} />
        <Route path={inboxPaths.CHAT} element={<Conversation />} />
      </Routes>
    </ChatViewContent>
  </InboxLayout>
);

const InboxRoutes = () => {
  useTabTitle('Inbox');

  return (
    // Each Routes context needs to be in a Route with exact paths to get URL params
    <Routes>
      <Route path="message-groups" element={<MessageClustering />} />
      <Route path="chat/*" element={<InboxWrapper />} />
      <Route path="/" element={<Navigate to="chat" replace />} />
    </Routes>
  );
};

export default InboxRoutes;
