import { gql } from '@apollo/client';

export default gql`
  fragment ChatComment on ChatCommentType {
    id
    name
    avatar
    message
    created
    userId
  }
`;
