import { gql } from '@apollo/client';

export const GET_ADMIN_GROUPS = gql`
  query GetAdminGroups($names: [String]) {
    allGroups(names: $names) {
      id
      name
    }
  }
`;
