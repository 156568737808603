import { gql } from '@apollo/client';

export const GetDialogueList = gql`
  query DialogueList(
    $botId: ID
    $skillId: ID
    $includeSubscribedSkills: Boolean
    $dialogueType: String
    $excludeGreetings: Boolean
  ) {
    dialogueList(
      botId: $botId
      skillId: $skillId
      includeSubscribedSkills: $includeSubscribedSkills
      dialogueType: $dialogueType
      excludeGreetings: $excludeGreetings
    ) {
      id
      total
      dialogues
      subscribedSkills {
        id
        name
        total
        dialogues
      }
    }
  }
`;
