import { gql } from '@apollo/client';

import ORGANIZATION_FRAGMENT from 'frontend/features/Organization/fragments/Organization.fragment.graphql';

export default gql`
  mutation ActivateOrganization($id: ID!) {
    activateOrganization(id: $id) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
