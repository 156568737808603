import { gql } from '@apollo/client';

export default gql`
  query GetPublishingMode($botId: ID!) {
    bot(id: $botId) {
      id
      publishingMode
    }
  }
`;
