import PropTypes from 'prop-types';

import { MaybeLink } from 'frontend/components';
import { buildTypes } from 'frontend/constants';
import { DialogueIcon } from 'frontend/features/Library/components';
import { getBuildUrl } from 'frontend/features/Library/utils';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { IDType } from 'frontend/propTypes';

import styles from './ButtonClicks.scss';
import { DialogueType } from './propTypes';

const Dialogue = ({ data: { botId, dialogue, languageCode } }) => {
  const { hasBotPerms } = useMyPermissions({ botId });
  const canViewDialogues = hasBotPerms('view_templates');

  if (!dialogue) {
    return <>N/A</>;
  }

  const { id, title, dialogueType, colorLabel } = dialogue;
  const url = getBuildUrl({ buildType: buildTypes.BOT, buildId: botId, target: id, dialogueType });

  return (
    <MaybeLink to={canViewDialogues ? url : undefined} className={styles.value}>
      <DialogueIcon
        dialogueType={dialogueType}
        colorLabel={colorLabel}
        currentLanguage={languageCode}
        className={styles.dialogueIcon}
      />
      {title?.[languageCode] || title.default}
    </MaybeLink>
  );
};

Dialogue.propTypes = {
  data: PropTypes.exact({
    botId: IDType.isRequired,
    languageCode: PropTypes.string,
    dialogue: DialogueType,
  }).isRequired,
};

export default Dialogue;
