import cx from 'classnames';
import type { FieldRenderProps } from 'react-final-form';

import { ChevronDown } from 'frontend/assets/icons';
import ShakeDiv from 'frontend/components/ShakeDiv';
import { FIELD_COLOR } from 'frontend/constants';

import styles from './Select.scss';
import { Icon } from '../Icon/Icon';

interface SelectProps extends FieldRenderProps<string> {
  children?: React.ReactNode;
  className?: string;
  direction?: string;
  label?: string | React.ReactNode;
  wrapperClassName?: string;
  onClick?(): void;
  'data-testid'?: string;
  fieldColor?: string;
  labelClassName?: string;
}

const Select = ({
  className,
  wrapperClassName,
  input: { name, value, onChange, onBlur, onFocus },
  onClick = () => {},
  children,
  direction = 'ltr',
  'data-testid': dataTestid,
  meta,
  label,
  labelClassName,
  fieldColor = FIELD_COLOR.WHITE,
  ...rest
}: SelectProps) => {
  const hasError = meta && meta.error && meta.submitFailed;
  const classNames = cx(styles.formControl, className, {
    [styles.formControlError]: hasError,
    [styles.formControlMischka]: fieldColor === FIELD_COLOR.MISCHKA,
  });

  const labelClass = cx(styles.labelWrapper, labelClassName);

  return (
    <>
      {!!label && (
        <div className={labelClass}>
          <label htmlFor={`select-${name}`}>{label}</label>
        </div>
      )}
      <ShakeDiv
        animate={hasError ? 'error' : 'normal'}
        className={cx(styles.arrow, styles.selectWrapper, wrapperClassName)}
      >
        <select
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          name={name}
          className={classNames}
          data-testid={dataTestid}
          dir={direction}
          id={`select-${name}`}
          {...rest}
        >
          {children}
        </select>
        <Icon className={styles.selectChevron} component={ChevronDown} />
        {hasError && <div className={styles.errorMessage}>{meta.error}</div>}
      </ShakeDiv>
    </>
  );
};

interface OptionProps {
  value: string | number;
  label: string;
  localErrors?: Record<string, unknown>;
}

const Option = ({ value, label, ...rest }: OptionProps) => (
  <option value={value} key={value} {...rest}>
    {label}
  </option>
);

Select.Option = Option;

export default Select;
