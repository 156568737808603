import { Button } from 'frontend/components';
import type { Language } from 'frontend/types/language';

import styles from './SelectorOverlay.scss';
import sharedStyles from './language-selector-styles.scss';

interface Props {
  languages: Language[];
  close: (...args: unknown[]) => void;
  startHandover: (...args: unknown[]) => void;
}

function SelectorOverlay({ languages, close, startHandover }: Props) {
  return (
    <div className={styles.overlay}>
      <div>
        <span className={styles.overlaySpan}>Select a language for this conversation to start handover</span>
        <div className={styles.languages}>
          {languages.map(({ code, name }) => (
            <Button
              key={code}
              className={styles.cancelButton}
              text={name}
              onClick={() => {
                startHandover(code);
                close();
              }}
              color="primary"
              width="full"
              data-cy={`select-language-${code}`}
            />
          ))}
        </div>
      </div>
      <Button className={sharedStyles.cancelButton} text="Cancel" onClick={close} color="warning" width="full" />
    </div>
  );
}

export default SelectorOverlay;
