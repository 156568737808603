import cx from 'classnames';
import React from 'react';

import { MaybeLink, Tooltip } from 'frontend/components';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import CSVDownloadButton from 'frontend/features/Analytics/views/Sessions/components/CSVDownloadButton/CSVDownloadButton';
import { DialogueIcon } from 'frontend/features/Library/components';
import type { LabelColor } from 'frontend/features/Library/propTypes/ColorLabelType';
import { formatNumber } from 'frontend/utils';

import styles from './DialogueList.scss';
import EmptyState from '../EmptyState';

function asPercentage(f) {
  return `${(f * 100).toFixed(1)}%`;
}

interface ListItem {
  id: string;
  found: boolean;
  url: string | undefined;
  dialogueType?: DialogueType;
  count: number;
  ratio: number;
  title: Record<string, string>;
  colorLabel?: LabelColor;
}

function frequentDialoguesToCSV(
  data: { ratio: number; count: number; id: string; title: { [key: string]: string } }[],
  currentLanguage: string,
) {
  return data.map(({ ratio, count, id, title }) => ({
    ratio,
    count,
    dialogue_id: id,
    title: title[currentLanguage],
  }));
}

interface DialogueListItemProps {
  item: ListItem;
  currentLanguage: string;
  isPreview: boolean;
  link?: boolean;
}

const DialogueListItem = ({
  item: { found, url, dialogueType, count, ratio, title: titles, colorLabel },
  currentLanguage,
  isPreview,
  link = true,
}: DialogueListItemProps) => {
  const title = titles[currentLanguage] || titles.default;
  const className = cx(styles.listItem, { [styles.notFound]: !found, [styles.listItemDisabled]: isPreview || !link });
  return (
    <MaybeLink className={className} to={link && !isPreview ? url : undefined}>
      <span>
        <DialogueIcon
          dialogueType={dialogueType}
          colorLabel={colorLabel}
          className={styles.dialogueIcon}
          currentLanguage={currentLanguage}
        />
      </span>
      <span className={styles.amount}>
        <Tooltip>
          {formatNumber(count)} ({asPercentage(ratio)})<Tooltip.Body>Times triggered</Tooltip.Body>
        </Tooltip>
      </span>
      <span className={styles.listItemName}>{title}</span>
    </MaybeLink>
  );
};

interface DialogueListProps extends React.HTMLAttributes<HTMLDivElement> {
  dialogues: ListItem[];
  enableHeader?: boolean;
  children: React.ReactNode;
  className?: string;
  currentLanguage: string;
  isPreview?: boolean;
  canViewDialogues: boolean;
}

const DialogueList = ({
  dialogues,
  children,
  currentLanguage,
  canViewDialogues,
  className,
  isPreview = false,
  enableHeader = true,
  ...rest
}: DialogueListProps) => {
  if (dialogues.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className={cx(styles.wrapper, className)} {...rest}>
      {enableHeader && (
        <div className={styles.header}>
          {children}
          {!isPreview && (
            <CSVDownloadButton
              filename="frequent_dialogues.csv"
              data={frequentDialoguesToCSV(dialogues, currentLanguage)}
            />
          )}
        </div>
      )}
      {dialogues.map((item) => (
        <DialogueListItem
          key={item.id}
          item={item}
          currentLanguage={currentLanguage}
          isPreview={isPreview}
          link={canViewDialogues}
        />
      ))}
    </div>
  );
};

export default DialogueList;
