import React from 'react';
import { useField, useForm } from 'react-final-form';

import { Checkbox, LoaderSwitch } from 'frontend/components';
import { useBotOrSkill, useLanguages } from 'frontend/hooks';

const LanguageFilter = ({ filterKey, shouldSubmitOnToggle }: { filterKey: string; shouldSubmitOnToggle: boolean }) => {
  const botOrSkillParams = useBotOrSkill();
  const { languages, loading: languagesLoading } = useLanguages(botOrSkillParams);

  const { change, submit } = useForm();
  const {
    input: { value },
  } = useField(filterKey);

  return (
    <LoaderSwitch loading={languagesLoading}>
      {languages.map(({ code, name }) => (
        <Checkbox
          key={code}
          input={{
            name: code,
            checked: value.includes(code),
          }}
          allowToggleOnWrapper
          onClick={() => {
            const valueCopy = [...value];
            if (valueCopy.includes(code)) {
              valueCopy.splice(valueCopy.indexOf(code), 1);
            } else {
              valueCopy.push(code);
            }
            change(filterKey, valueCopy.length ? valueCopy : undefined);
            if (shouldSubmitOnToggle) {
              submit();
            }
          }}
          label={name}
        />
      ))}
    </LoaderSwitch>
  );
};

export default LanguageFilter;
