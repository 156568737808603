import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

import { BUTTON_TYPES } from '../constants';

export default PropTypes.shape({
  id: IDType,
  buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES)).isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  value: PropTypes.string,
  languageCode: PropTypes.string.isRequired,
});
