import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { InfoBox, ToggleSwitch } from 'frontend/components';
import { REQUIRED_ALTERNATIVES } from 'frontend/constants';

import ButtonField from './ButtonField';
import { buttonTypeUsesValue } from './utils';
import { BUTTON_TYPES } from '../../constants';

const showValueField = (type) =>
  [REQUIRED_ALTERNATIVES.REQUIRED, REQUIRED_ALTERNATIVES.OPTIONAL].includes(buttonTypeUsesValue[type]);

const buttonTypeToPlaceholder = (type) => {
  switch (type) {
    case BUTTON_TYPES.EXTERNAL_LINK:
      return 'URL';
    case BUTTON_TYPES.DIALOGUE_TRIGGER:
      return 'Dialogue';
    case BUTTON_TYPES.UPLOAD_ATTACHMENT:
      return 'Dialogue triggered after completed upload';
    case BUTTON_TYPES.SUBMIT:
      return 'Dialogue';
    case BUTTON_TYPES.PHONE:
      return 'Phone number';
    case BUTTON_TYPES.ABORT_FOLLOWUP:
      return 'Response (optional)';
    default:
      return 'Value';
  }
};

const DefaultButtonInputs = ({
  buttonType,
  name,
  disabled,
  localErrors,
  localSubmitFailed,
  onKeyDown,
  isNew,
  validators,
}) => {
  const valuePlaceholder = buttonTypeToPlaceholder(buttonType);
  const isHandoverType = buttonType === BUTTON_TYPES.HANDOVER_REQUEST;

  return (
    <>
      <Field
        component={ButtonField}
        name={`${name}.label`}
        placeholder="Button text"
        autoFocus={!isNew}
        validate={isNew ? undefined : validators.label}
        readOnly={disabled}
        ariaLabel="Button text"
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
      />
      {showValueField(buttonType) && (
        <Field
          component={ButtonField}
          name={`${name}.value`}
          placeholder={valuePlaceholder}
          validate={isNew ? undefined : validators.value}
          readOnly={disabled}
          ariaLabel="Button value"
          localErrors={localErrors}
          localSubmitFailed={localSubmitFailed}
          onKeyDown={onKeyDown}
          buttonType={buttonType}
        />
      )}
      {isHandoverType && (
        <>
          <ToggleSwitch name={`${name}.hideOutsideBusinessHours`} status={['Hide button outside business hours']} />
          <InfoBox title="About “Hide button outside business hours”">
            Enabling this feature will automatically hide the handover button for end user outside defined business
            hours.
          </InfoBox>
        </>
      )}
    </>
  );
};

DefaultButtonInputs.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  localErrors: PropTypes.shape({}).isRequired,
  localSubmitFailed: PropTypes.bool.isRequired,
  onKeyDown: PropTypes.func,
  isNew: PropTypes.bool.isRequired,
  validators: PropTypes.shape({}).isRequired,
  buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
};

export default DefaultButtonInputs;
