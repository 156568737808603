import { gql } from '@apollo/client';

export default gql`
  fragment BotCard on BotType {
    id
    name
    projectName
    avatarUrl
    archivedAt
    isArchived
    subscriptionCount
    languages {
      id
      code
    }
  }
`;
