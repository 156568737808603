import cx from 'classnames';

import { Triangle } from 'frontend/assets/icons';
import { Icon, Panel as SuperPanel, Tooltip } from 'frontend/components';
import { formatNumber } from 'frontend/utils';

import styles from './Metric.scss';

interface RelativeArrowProps {
  value: number;
  relativeValue?: number;
}

const RelativeArrow = ({ value, relativeValue }: RelativeArrowProps) => {
  if (relativeValue === undefined || value === relativeValue) return null;
  const classNames = cx({
    [styles.arrow]: relativeValue !== undefined,
    [styles.arrowDown]: value > relativeValue,
  });
  const color = value > relativeValue ? 'success' : 'warning';
  return <Icon component={Triangle} className={classNames} color={color} />;
};

interface MetricTooltipBodyProps {
  metric: number;
  postfix?: string;
  reference?: number;
}

const MetricTooltipBody = ({ metric, postfix, reference }: MetricTooltipBodyProps) => (
  <>
    {formatNumber(metric)}
    {postfix} / {formatNumber(reference)}
    {postfix}
  </>
);

interface MetricProps {
  topText?: string;
  bottomText?: string;
  metric?: number;
  reference?: number;
  classNames?: string;
  center?: boolean;
  postfix?: string;
  /** Component which renders the tooltip. Recieves metric, postfix, reference as props */
  TooltipComponent?: React.ElementType;
}

const Metric = ({
  metric,
  reference,
  classNames = '',
  topText = '',
  bottomText = '',
  center = true,
  postfix = '',
  TooltipComponent = MetricTooltipBody,
}: MetricProps) => (
  <Tooltip className={cx(classNames, styles.metricWrapper, { [styles.metricWrapperCenter]: center })}>
    {Boolean(topText) && <div className={styles.title}>{topText}</div>}
    <h1 className={styles.metric}>
      {metric !== undefined ? (
        <>
          {formatNumber(metric)}
          {postfix}
          <RelativeArrow value={metric} relativeValue={reference} />
        </>
      ) : (
        'N/A'
      )}
    </h1>
    {!!bottomText && <div className={styles.title}>{bottomText}</div>}
    {typeof metric === 'number' && typeof reference === 'number' && (
      <Tooltip.Body>
        <TooltipComponent metric={metric} reference={reference} postfix={postfix} />
      </Tooltip.Body>
    )}
  </Tooltip>
);

interface PanelProps {
  children?: React.ReactNode;
}

const Panel = ({ children }: PanelProps) => <SuperPanel className={styles.miniPanelTiny}>{children}</SuperPanel>;

interface GridProps {
  children?: React.ReactNode;
  className?: string;
}

const Grid = ({ children, className }: GridProps) => <div className={cx(styles.grid, className)}>{children}</div>;

Metric.Panel = Panel;
Metric.Grid = Grid;

export default Metric;
