import { type ToastOptions, toast } from 'react-toastify';

import { Toast } from './components';
import type { ToastLevel } from './components/Toast';
import styles from './styles.scss';

export interface ToastOptionsExtended extends ToastOptions {
  sentryId?: string;
  undo?: () => void;
}

const DEFAULT_OPTIONS = Object.freeze({
  position: 'top-right',
  autoClose: 5 * 1000, // seconds
  pauseOnFocusLoss: true,
  closeButton: false,
  className: styles.toast,
  bodyClassName: styles.toastBody,
  progressClassName: styles.progressBar,
});

const getToastId = (type: ToastLevel, message: string): string => `${type}-${message.trim().toLowerCase()}`;

const showToast = (type: ToastLevel, message: string, options: ToastOptionsExtended = {}, to?: string): void => {
  const { sentryId, undo } = options;
  const toastId = getToastId(type, message);
  const isError = type === 'ERROR';

  if (isError && toast.isActive(toastId)) return; // The toast error is already showing

  const toastOptions: ToastOptionsExtended = {
    ...DEFAULT_OPTIONS,
    ...(sentryId ? { autoClose: false, closeOnClick: false } : {}),
    ...options,
    toastId,
  };

  toast(<Toast message={message} type={type} to={to} sentryId={sentryId} undo={undo} />, toastOptions);
};

const closeToast = (type: ToastLevel, message: string): void => {
  const toastId = getToastId(type, message);
  toast.dismiss(toastId);
};

type ToastShowTuple = [message: string, options?: ToastOptionsExtended, to?: string];

export const toastInfo = (...args: ToastShowTuple) => showToast('INFO', ...args);
export const toastSuccess = (...args: ToastShowTuple) => showToast('SUCCESS', ...args);
export const toastWarning = (...args: ToastShowTuple) => showToast('WARNING', ...args);
export const toastError = (...args: ToastShowTuple) => showToast('ERROR', ...args);

export const toastInfoClose = (message: string) => closeToast('INFO', message);
export const toastSuccessClose = (message: string) => closeToast('SUCCESS', message);
export const toastWarningClose = (message: string) => closeToast('WARNING', message);
export const toastErrorClose = (message: string) => closeToast('ERROR', message);
