import { useMutation } from '@apollo/client';

import { softDeleteInCache } from 'frontend/api/cacheHelpers';
import { BotApiKeyFragmentDoc, type BotApiKeyType, DeleteBotApiKeyDocument } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';

interface DeleteBotApiKeyProps {
  hide: () => void;
  args: {
    botId: string;
    botApiKey: BotApiKeyType;
  };
}

const DeleteBotApiKey = ({ hide, args: { botId, botApiKey } }: DeleteBotApiKeyProps) => {
  const update = softDeleteInCache({
    fragmentName: 'BotApiKey',
    fragment: BotApiKeyFragmentDoc,
    typeName: 'BotApiKeyType',
    mutationName: 'deleteBotApiKey',
  });
  const [deleteKey] = useMutation(DeleteBotApiKeyDocument, { update });
  const onSubmit = async () => {
    await deleteKey({ variables: { botId, id: botApiKey.id } });
  };

  return (
    <DeleteModal onSubmit={onSubmit} name={botApiKey.name} hide={hide}>
      Are you sure you want to delete the API key {`'${botApiKey.name}'`}? All apps connected with this API key will
      loose access.
    </DeleteModal>
  );
};

export default DeleteBotApiKey;
