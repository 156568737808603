import { compose, filter, sortBy } from 'lodash/fp';
import { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';

import { Accordion, Condition, ToggleSwitch } from 'frontend/components';
import { indexList } from 'frontend/features/Build/utils';

import AnonymizationRow from './AnonymizationRow';
import styles from '../../Privacy.scss';

const getAnonymizations = (hasLanguage) =>
  compose(
    sortBy('label'),
    filter(({ languageCode }) => Boolean(languageCode) === hasLanguage),
  );

const Anonymization = () => {
  const { values } = useFormState();
  const anonymizations = useMemo(() => indexList(values?.anonymizations || []), [values]);
  const generalAnonymizations = useMemo(() => getAnonymizations(false)(anonymizations), [anonymizations]);
  const languageAnonymizations = useMemo(() => getAnonymizations(true)(anonymizations), [anonymizations]);
  const usesLanguageAnonymizations = languageAnonymizations.some(({ isActive }) => isActive);

  return (
    <div className={styles.sectionContainer}>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Anonymize sensitive information</h3>
      <p className="m-b-lg">
        This will ensure that sensitive data, such as personal identification numbers, will be anonymized. Sensitive
        data will then be replaced by a placeholder in the Kindly inbox.
      </p>
      <div className="m-b-lg">
        <ToggleSwitch name="anonymizationIsActive" status={['Hide sensitive data automatically']} />
      </div>
      <Condition when="anonymizationIsActive" is>
        <div className="m-b-lg">
          <ToggleSwitch name="anonymizationDuringHandover" status={['Also hide sensitive data during handover']} />
        </div>
        <div className="m-b-lg">
          {generalAnonymizations.map(({ id, index }) => (
            <Field key={`anonymization-${id}`} name={`anonymizations.${index}`} component={AnonymizationRow} />
          ))}
          <br />
          <Accordion title="Country specific" isOpen={usesLanguageAnonymizations}>
            {languageAnonymizations.map(({ id, index }) => (
              <Field key={`anonymization-${id}`} name={`anonymizations.${index}`} component={AnonymizationRow} />
            ))}
          </Accordion>
        </div>
      </Condition>
    </div>
  );
};

export default Anonymization;
