import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { GetAppSettingsDocument, UpdateApplicationDocument } from 'frontend/api/generated';
import { Button, Input, Loader } from 'frontend/components';
import { NotFound } from 'frontend/features';
import ConnectDetails from 'frontend/features/Connect/components/ConnectDetails/ConnectDetails';
import { useModal } from 'frontend/features/Modals';
import { chain, max, url } from 'frontend/form/validators';
import { useIsSubmitting, useToast } from 'frontend/hooks';

import styles from '../ApplicationSettings.scss';
import DeleteApplicationModal from '../DeleteApplicationModal';
import { FormButtons } from '../index';

export default function UpdateApplicationForm() {
  const toast = useToast();
  const [mutate] = useMutation(UpdateApplicationDocument);
  const [isSubmitting, submitWrapper] = useIsSubmitting();
  const { botId } = useParams();
  const { appId } = useParams();
  const { loading, data } = useQuery(GetAppSettingsDocument, {
    variables: { botId: botId as string, appId: appId as string },
    skip: !botId || !appId,
  });
  const app = data?.bot?.application;

  const initialValues = useMemo(
    () => ({
      name: app?.name,
      webhookUrl: app?.webhookUrl,
    }),
    [app],
  );
  const [showDeleteApplication] = useModal(DeleteApplicationModal);

  const openModal = useCallback(() => showDeleteApplication({ app, botId }), [showDeleteApplication, app, botId]);

  const onSubmit = useCallback(
    ({ name, webhookUrl = '' }) => {
      mutate({ variables: { botId: botId as string, appId: appId as string, name, webhookUrl } });
    },
    [appId, botId, mutate],
  );

  if (loading) {
    return <Loader size="large" />;
  }
  if (!app) {
    return <NotFound text="Application not found" />;
  }

  return (
    <Form
      onSubmit={submitWrapper(onSubmit)}
      initialValues={initialValues}
      render={({ form: { reset }, handleSubmit, values: { name } }) => (
        <form>
          <ConnectDetails backButtonTitle={app.name}>
            <h3 className={styles.title}>{name || 'New application'}</h3>
            <div className={styles.inputContainer}>
              <Field component={Input} label="Name" name="name" placeholder="Application name" validate={max(50)} />
            </div>
            <div className={styles.apiContainer}>
              <h5 className={styles.title}>API key</h5>
              <span>Get your API key to connect with your bot.</span>
              <code>{app.apiKey}</code>
              <CopyToClipboard
                text={app.apiKey}
                onCopy={() => {
                  toast.success('Copied to clipboard');
                }}
              >
                <Button text="Copy to clipboard" className={styles.copyButton} />
              </CopyToClipboard>
            </div>
            <div className={styles.inputContainer}>
              <Field
                component={Input}
                label="Webhook"
                name="webhookUrl"
                placeholder="https://example.com"
                validate={chain(max(300), url)}
              />
            </div>
            <p>
              We will post replies to the following URL. Only replies to requests with API key for this app will be
              posted. <br />
              For more information,{' '}
              <a
                href="https://docs.kindly.ai/api/application"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                check out the docs
              </a>
              .
            </p>
            <FormButtons
              openModal={openModal}
              handleCancel={reset}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </ConnectDetails>
        </form>
      )}
    />
  );
}
