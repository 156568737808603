import { defineAction } from 'redux-define';

import { getAccessToken } from 'frontend/state/dux/auth';

const WAIT_PENDING = 200; // ms
const EXPIRE_MARGIN = 10 * 1000; // ms
export const MAX_FAILURES = 3;

const AUTH = defineAction(
  'auth',
  [
    'BOT_TOKEN_REQUEST',
    'BOT_TOKEN_SUCCESS',
    'BOT_TOKEN_FAILURE',

    'ORG_TOKEN_REQUEST',
    'ORG_TOKEN_SUCCESS',
    'ORG_TOKEN_FAILURE',

    'PLATFORM_TOKEN_REQUEST',
    'PLATFORM_TOKEN_SUCCESS',
    'PLATFORM_TOKEN_FAILURE',
  ],
  'analytics',
);

const initialState = {
  bot: {
    botId: null,
    pending: false,
    token: null,
    expires: 0,
    error: '',
    failureCount: 0,
  },
  org: {
    organizationId: null,
    pending: false,
    token: null,
    expires: 0,
    error: '',
    failureCount: 0,
  },
  platform: {
    pending: false,
    token: null,
    expires: 0,
    error: '',
    failureCount: 0,
  },
};

const mergeBotState = (state, botUpdate) => ({
  ...state,
  bot: { ...state.bot, ...botUpdate },
});
const mergeOrgState = (state, orgUpdate) => ({
  ...state,
  org: { ...state.org, ...orgUpdate },
});
const mergePlatformState = (state, platformUpdate) => ({
  ...state,
  platform: { ...state.platform, ...platformUpdate },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH.BOT_TOKEN_REQUEST:
      return mergeBotState(state, {
        ...initialState.bot,
        pending: true,
        botId: action.botId,
        failureCount: state.bot.failureCount,
      });
    case AUTH.BOT_TOKEN_SUCCESS:
      return mergeBotState(state, {
        pending: false,
        token: action.token,
        expires: action.expires,
        failureCount: 0,
      });
    case AUTH.BOT_TOKEN_FAILURE:
      return mergeBotState(state, {
        pending: false,
        error: action.error,
        failureCount: state.bot.failureCount + 1,
      });

    case AUTH.ORG_TOKEN_REQUEST:
      return mergeOrgState(state, {
        ...initialState.org,
        pending: true,
        organizationId: action.organizationId,
        failureCount: state.bot.failureCount,
      });
    case AUTH.ORG_TOKEN_SUCCESS:
      return mergeOrgState(state, {
        pending: false,
        token: action.token,
        expires: action.expires,
        failureCount: 0,
      });
    case AUTH.ORG_TOKEN_FAILURE:
      return mergeOrgState(state, {
        pending: false,
        error: action.error,
        failureCount: state.bot.failureCount + 1,
      });

    case AUTH.PLATFORM_TOKEN_REQUEST:
      return mergePlatformState(state, {
        ...initialState.platform,
        pending: true,
        failureCount: state.platform.failureCount,
      });
    case AUTH.PLATFORM_TOKEN_SUCCESS:
      return mergePlatformState(state, {
        pending: false,
        token: action.token,
        expires: action.expires,
        failureCount: 0,
      });
    case AUTH.PLATFORM_TOKEN_FAILURE:
      return mergePlatformState(state, {
        pending: false,
        error: action.error,
        failureCount: state.platform.failureCount + 1,
      });

    default:
      return state;
  }
};

const selectState = (state) => state.analytics.auth;
const selectBotAuth = (state) => selectState(state).bot;
const selectOrgAuth = (state) => selectState(state).org;
const selectPlatformAuth = (state) => selectState(state).platform;

// internal actions, exported for testing only
export const botTokenRequest = (botId) => ({ type: AUTH.BOT_TOKEN_REQUEST, botId });
export const botTokenSuccess = (token, expires) => ({ type: AUTH.BOT_TOKEN_SUCCESS, token, expires });
export const botTokenFailure = (error) => ({ type: AUTH.BOT_TOKEN_FAILURE, error });

// internal actions, exported for testing only
export const orgTokenRequest = (organizationId) => ({ type: AUTH.ORG_TOKEN_REQUEST, organizationId });
export const orgTokenSuccess = (token, expires) => ({ type: AUTH.ORG_TOKEN_SUCCESS, token, expires });
export const orgTokenFailure = (error) => ({ type: AUTH.ORG_TOKEN_FAILURE, error });

// internal actions, exported for testing only
export const platformTokenRequest = () => ({ type: AUTH.PLATFORM_TOKEN_REQUEST });
export const platformTokenSuccess = (token, expires) => ({ type: AUTH.PLATFORM_TOKEN_SUCCESS, token, expires });
export const platformTokenFailure = (error) => ({ type: AUTH.PLATFORM_TOKEN_FAILURE, error });

/**
 * Action creator that requests JWToken for bot, and resolves to a non-expired token.
 *
 * `await dispatch(getBotJWToken(botId))` can be used without checking for pending requests.
 *
 * @param {string} botId
 */
export const getBotJWToken = (botId) => async (dispatch, getState) => {
  const { pending, token, expires, botId: botIdFromState, failureCount } = selectBotAuth(getState());
  if (pending) {
    await new Promise((resolve) => {
      setTimeout(resolve, WAIT_PENDING);
    });
    // try again, waiting for pending request to resolve with token
    return getBotJWToken(botId)(dispatch, getState);
  }
  if (token && expires > Date.now()) {
    if (botId === botIdFromState) {
      return token;
    }
    console.warn(`
      getBotJWT: will now throw away non-expired token.
      getBotJWT is not optimized for concurrent use with more than one botId.
    `);
  }
  if (failureCount >= MAX_FAILURES) {
    console.error('getBotJWToken: max failureCount reached');
    return Promise.reject();
  }

  dispatch(botTokenRequest(botId));
  try {
    const accessToken = await dispatch(getAccessToken());
    const response = await fetch(`${window.env.API_URL}/api/v2/bot/${botId}/sage/auth`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const data = response.ok && (await response.json());
    if (data && data.jwt && data.ttl) {
      const ttl = data.ttl * 1000;
      if (ttl < 2 * EXPIRE_MARGIN) console.warn('tokenRequest TTL is less than 2x EXPIRE_MARGIN');
      const newExpires = Date.now() + ttl - EXPIRE_MARGIN;
      dispatch(botTokenSuccess(data.jwt, newExpires));
      return data.jwt;
    }
    throw new Error('response did not include jwt or ttl');
  } catch (err) {
    dispatch(botTokenFailure('Failed to obtain Sage JWT'));
    console.error('Failed to obtain Sage JWT', err);
  }
  return Promise.reject();
};

/**
 * Action creator that requests JWToken for organization, and resolves to a non-expired token.
 *
 * `await dispatch(getOrgJWToken(scope))` can be used without checking for pending requests.
 *
 * @param {string} organizationId
 */
export const getOrgJWToken = (organizationId) => async (dispatch, getState) => {
  const { pending, token, expires, organizationId: organizationIdFromState, failureCount } = selectOrgAuth(getState());
  if (pending) {
    await new Promise((resolve) => {
      setTimeout(resolve, WAIT_PENDING);
    });
    // try again, waiting for pending request to resolve with token
    return getOrgJWToken(organizationId)(dispatch, getState);
  }
  if (token && expires > Date.now()) {
    if (organizationId === organizationIdFromState) {
      return token;
    }
    console.warn(`
      getOrgJWToken: will now throw away non-expired token.
      getOrgJWToken is not optimized for concurrent use with more than one botId.
    `);
  }
  if (failureCount >= MAX_FAILURES) {
    console.error('getOrgJWToken: max failureCount reached');
    return Promise.reject();
  }

  dispatch(orgTokenRequest(organizationId));
  try {
    const accessToken = await dispatch(getAccessToken());
    const response = await fetch(`${window.env.API_URL}/api/v2/org/${organizationId}/sage/auth`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const data = response.ok && (await response.json());
    if (data && data.jwt && data.ttl) {
      const ttl = data.ttl * 1000;
      if (ttl < 2 * EXPIRE_MARGIN) console.warn('tokenRequest TTL is less than 2x EXPIRE_MARGIN');
      const newExpires = Date.now() + ttl - EXPIRE_MARGIN;
      dispatch(orgTokenSuccess(data.jwt, newExpires));
      return data.jwt;
    }
    throw new Error('response did not include jwt or ttl');
  } catch (err) {
    dispatch(orgTokenFailure('Failed to obtain Sage JWT'));
    console.error('Failed to obtain Sage JWT', err);
  }
  return Promise.reject();
};

/**
 * Action creator that requests JWToken for platform, and resolves to a non-expired token.
 *
 * `await dispatch(getPlatformJWToken())` can be used without checking for pending requests.
 */
export const getPlatformJWToken = () => async (dispatch, getState) => {
  const { pending, token, expires, failureCount } = selectPlatformAuth(getState());
  if (pending) {
    await new Promise((resolve) => {
      setTimeout(resolve, WAIT_PENDING);
    });
    // try again, waiting for pending request to resolve with token
    return getPlatformJWToken()(dispatch, getState);
  }
  if (token && expires > Date.now()) {
    return token;
  }
  if (failureCount >= MAX_FAILURES) {
    console.error('getBotJWToken: max failureCount reached');
    return Promise.reject();
  }

  dispatch(platformTokenRequest());
  try {
    const accessToken = await dispatch(getAccessToken());
    const response = await fetch(`${window.env.API_URL}/api/v2/sage/platform_auth`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const data = response.ok && (await response.json());

    if (data && data.jwt && data.ttl) {
      const ttl = data.ttl * 1000;
      if (ttl < 2 * EXPIRE_MARGIN) console.warn('platformJWToken TTL is less then 2x EXPIRE_MARGIN');
      const newExpires = Date.now() + ttl - EXPIRE_MARGIN;
      dispatch(platformTokenSuccess(data.jwt, newExpires));
      return data.jwt;
    }
    throw new Error('response did not include jwt or ttl');
  } catch (err) {
    dispatch(platformTokenFailure('Failed to obtain Sage JWT'));
    console.error('Failed to obtain Sage JWT', err);
  }
  return Promise.reject();
};
