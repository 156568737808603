import cx from 'classnames';

import { Attachment, Send, Slot } from 'frontend/assets/icons';
import { Button, FileUpload, Icon } from 'frontend/components';
import type { HandoverType } from 'frontend/features/Inbox/constants';
import sharedStyles from 'frontend/features/Inbox/inbox-styles.scss';
import { useModal } from 'frontend/features/Modals';
import { CHAT_SOURCE } from 'frontend/types/chat';

import EmojiPicker from './EmojiPicker';
import styles from './HandoverForm.scss';
import ModalAIAssistance from './ModalAIAssistance';
import StyleButton from './StyleButton';
import { useHandoverActions } from '../../hooks';
import type { MessagesDataShape } from '../../propTypes';

interface Props {
  botId: string;
  editorState: { [key: string]: unknown };
  handoverType: HandoverType;
  messages: MessagesDataShape;
  onKeyDown: React.KeyboardEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onUpload: (...args: unknown[]) => unknown;
  onUploadError: ({ message }: { message: string }) => unknown;
  source: CHAT_SOURCE;
  submitDisabled: boolean;
}

const inlineStyles = ['BOLD', 'ITALIC', 'UNDERLINE'];
const isKindlyChatbubble = (source: CHAT_SOURCE) =>
  source === CHAT_SOURCE.WEB || source === CHAT_SOURCE.TEST || source === CHAT_SOURCE.WEB_MOBILE;

const Toolbar = ({
  botId,
  editorState,
  handoverType,
  messages,
  onKeyDown,
  onUpload,
  onUploadError,
  source,
  submitDisabled,
}: Props) => {
  const { onEmojiSelect, onToggleBlockStyle, onToggleInlineStyle, replaceEditorState, getCurrentEditorText } =
    // @ts-expect-error: Convert useHandoverAction to TS
    useHandoverActions({
      messages,
      botId,
    });
  const [showAIModal] = useModal(ModalAIAssistance);

  const isEditorEmpty = getCurrentEditorText().trim().length === 0;

  return (
    <div className={styles.iconGroup}>
      {inlineStyles.map((type) => (
        <StyleButton type={type} editorState={editorState} onToggle={onToggleInlineStyle} key={type} />
      ))}
      <EmojiPicker onEmojiSelect={onEmojiSelect} />
      {isKindlyChatbubble(source) && (
        <FileUpload
          buttonClassName={cx(styles.icon)}
          accept="image/*,text/*,.pdf"
          sizeLimitKb={5 * 1024}
          onUpload={onUpload}
          onError={onUploadError}
          flat
        >
          <Icon component={Attachment} title="Upload file" />
        </FileUpload>
      )}
      <StyleButton type="code-block" editorState={editorState} onToggle={onToggleBlockStyle} />
      <button
        type="button"
        onClick={() => showAIModal({ replaceEditorState, getCurrentEditorText })}
        className={cx(styles.icon, styles.AIButton)}
        title="Reformulate message with AI"
        disabled={isEditorEmpty}
      >
        <Icon component={Slot} className={styles.AIIcon} />
      </button>
      <Button
        className={cx(sharedStyles.submit, {
          [sharedStyles.submitDisabled]: submitDisabled,
          [sharedStyles.submitExpanded]: handoverType === 'EMAIL',
        })}
        type="submit"
        onKeyDown={onKeyDown}
        icon={Send}
        flat
        title="Send"
        text={handoverType === 'EMAIL' ? 'Send' : ''}
      >
        <span
          className={cx(sharedStyles.submitText, {
            [sharedStyles.submitTextExpanded]: handoverType === 'EMAIL',
          })}
        >
          Send
        </span>
      </Button>
    </div>
  );
};

export default Toolbar;
