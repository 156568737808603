import useAgents from 'frontend/components/NewFilters/hooks/useAgents';

import styles from './AgentTitle.scss';

interface AgentTitleProps {
  agentId: string;
}

const AgentTitle = ({ agentId }: AgentTitleProps) => {
  const { agents } = useAgents();
  const agent = agents.find(({ user: { id } }) => id === agentId);
  if (!agent) return null;
  return (
    <div className={styles.selectedAgent}>
      <img src={agent.user.profile.avatarUrl} alt={`${agent.user.profile.firstName} avatar`} />
      {agent.user.profile.firstName}
    </div>
  );
};

export default AgentTitle;
