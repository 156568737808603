import mixpanel from 'mixpanel-browser';
import { createContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export interface MixpanelCtx {
  mixpanel: typeof mixpanel;
}

interface Props {
  children: React.ReactNode;
}

const MixpanelContext = createContext<MixpanelCtx | null>(null);

const config = {
  api_host: 'https://api-eu.mixpanel.com',
  opt_out_tracking_by_default: true,
  ip: false,
};

const MixpanelProvider = ({ children }: Props) => {
  const location = useLocation();

  // https://github.com/mixpanel/mixpanel-js/issues/133#issuecomment-371623167
  mixpanel.init(window.env?.MIXPANEL_PROJECT_TOKEN || 'BOGUS_TOKEN', config);
  if (!window.env?.MIXPANEL_PROJECT_TOKEN) {
    mixpanel.disable();
  }

  useEffect(() => {
    if (!location.pathname) return;
    mixpanel.track('Visit a page', {
      path: location.pathname,
      query: location.search,
    });
  }, [location]);

  const providerValue: MixpanelCtx = useMemo(() => ({ mixpanel }), []);

  return <MixpanelContext.Provider value={providerValue}>{children}</MixpanelContext.Provider>;
};

export { MixpanelContext };
export default MixpanelProvider;
