import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

import TargetDialogueType from './TargetDialogueType';
import { BUILD_ACTION } from '../constants';

export default PropTypes.shape({
  id: IDType.isRequired,
  action: PropTypes.oneOf(Object.keys(BUILD_ACTION)),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  actionByUsername: PropTypes.string,
  numActivities: PropTypes.number,
  message: PropTypes.string,
  actionBy: PropTypes.exact({
    id: IDType.isRequired,
    username: PropTypes.string.isRequired,
    profile: PropTypes.exact({
      id: IDType.isRequired,
      avatarUrl: PropTypes.string.isRequired,
      fullName: PropTypes.string,
      __typename: PropTypes.oneOf(['ProfileType']).isRequired,
    }).isRequired,
    __typename: PropTypes.oneOf(['UserType']).isRequired,
  }),
  TargetDialogueType,
  __typename: PropTypes.oneOf(['ActivityGroupType', 'ActivityType']).isRequired,
});
