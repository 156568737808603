import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-final-form';

import { Trash } from 'frontend/assets/icons';
import { Icon, InputErrorWrapper } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import Composer, { TAG_TYPES, getType } from 'frontend/features/Composer';
import { useEntities } from 'frontend/features/Entities/hooks';
import { DIALOGUE_SLOT_INITIAL } from 'frontend/features/Slots/constants';
import { SLOTS } from 'frontend/features/Slots/queries';
import { getDefaultPrompt } from 'frontend/features/Slots/utils';
import { useBotOrSkill } from 'frontend/hooks';
import { InputMetaType, InputType } from 'frontend/propTypes';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './ReplyWithSlots.scss';
import SlotsDropdown from '../SlotsDropdown';

const ReplyWithSlots = ({ input: { name }, meta, currentLanguage, readOnly, placeholder, remove }) => {
  const { submit, mutators, getState } = useForm();
  const hasError = meta.submitFailed && !!meta.error;
  const { buildIdObject } = useBotOrSkill();
  const { entities, loading: entitiesLoading } = useEntities({ currentLanguage, buildIdObject });
  const variables = { ...buildIdObject, languageCode: currentLanguage };
  const { data: slotsData, loading: slotsLoading } = useQuery(SLOTS, { variables });
  const items = useMemo(() => [...(slotsData?.slots ?? []), ...entities], [entities, slotsData]);

  const constructTagItem = useCallback(
    (item) => {
      const isEntity = [TAG_TYPES.ENTITY, TAG_TYPES.KINDLY_ENTITY].includes(getType(item));

      if (isEntity) {
        return {
          name: `entity__${item.name}`,
          entity: item,
          languageCode: currentLanguage,
          tempId: randomUUID(),
        };
      }
      return item;
    },
    [currentLanguage],
  );

  const onEscape = useCallback(({ state, setState, resolveHashtags, composerRef }) => {
    const { state: resolvedState, dropdownOpened } = resolveHashtags(state);
    setState(resolvedState);
    if (!dropdownOpened) setTimeout(composerRef.current.blur);
  }, []);

  const onClickOutside = useCallback(({ state, setState, resolveHashtags, stopClickEvent, event }) => {
    const { state: resolvedState, dropdownOpened } = resolveHashtags(state);
    setState(resolvedState);
    if (dropdownOpened) stopClickEvent(event);
  }, []);

  const onSave = useCallback(
    ({ state, setState, resolveHashtags }) => {
      const { state: resolvedState, dropdownOpened } = resolveHashtags(state);
      setState(resolvedState);
      if (!dropdownOpened) setTimeout(submit);
    },
    [submit],
  );

  const onInsertTag = useCallback(
    async (slot) => {
      const { outputSlots } = getState().values;
      const outputSlot = outputSlots.find(({ slot: { name: slotName } }) => slotName === slot.name);
      if (outputSlot) return;

      const newOutputSlot = {
        ...DIALOGUE_SLOT_INITIAL,
        entityId: slot?.entity?.id,
        prompt: getDefaultPrompt(currentLanguage, slot),
        required: false,
        slot,
      };
      mutators.pushFixed('outputSlots', 'outputSlots', newOutputSlot);
    },
    [currentLanguage, getState, mutators],
  );

  const dropdownExtra = useMemo(
    () => ({ loading: entitiesLoading || slotsLoading, items }),
    [entitiesLoading, items, slotsLoading],
  );

  return (
    <div className={styles.replyWrapper}>
      <InputErrorWrapper hasError={hasError} displayError={meta.error} errorMessageClassName={styles.errorMessage}>
        <Composer
          dropdownComponent={SlotsDropdown}
          dropdownExtra={dropdownExtra}
          name={name}
          constructTagItem={constructTagItem}
          onEscape={onEscape}
          onClickOutside={onClickOutside}
          onSave={onSave}
          potentialTagItems={items}
          onInsertTag={onInsertTag}
          readOnly={readOnly}
          placeholder={placeholder}
          highlightButtonText="Add slot"
          maxLength={4096}
          multiline
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      </InputErrorWrapper>
      <Icon
        component={Trash}
        onClick={remove}
        color="default"
        hoverColor="warning"
        title="Remove"
        className={styles.deleteIcon}
      />
    </div>
  );
};

ReplyWithSlots.propTypes = {
  input: InputType.isRequired,
  meta: InputMetaType.isRequired,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  remove: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default ReplyWithSlots;
