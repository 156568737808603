import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Button } from 'frontend/components';
import { VIEWS } from 'frontend/features/Inbox/constants';
import { capitalize, snakeToHumanReadable } from 'frontend/utils';

import styles from './ChatView.scss';

const ChatView = ({ title, active, selectView, count }) => {
  const onClick = useCallback(() => {
    selectView(title);
  }, [selectView, title]);

  return (
    // Use a wrapper to win style selector war
    <div className={styles.wrapper}>
      <Button onClick={onClick} className={cx(styles.viewButton, { [styles.viewButtonActive]: active })}>
        {capitalize(snakeToHumanReadable(title))}
        {count >= 0 && <div className={styles.count}>{count}</div>}
      </Button>
    </div>
  );
};

ChatView.propTypes = {
  title: PropTypes.oneOf(Array.from(VIEWS.keys())).isRequired,
  selectView: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  count: PropTypes.number,
};

export default ChatView;
