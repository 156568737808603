import cx from 'classnames';

import styles from './ImageAvatar.scss';

interface AutoAvatarProps {
  img: string;
  color: string;
  className?: string;
}

const AutoAvatar = ({ img, color, className }: AutoAvatarProps) => (
  <span className={cx(className, styles.autoAvatarWrapper)} style={{ backgroundColor: color }}>
    <img src={img} alt="avatar" />
  </span>
);

export default AutoAvatar;
