import PropTypes from 'prop-types';

import Section from './Section';
import styles from '../shared.scss';

const Text = ({ title, description, link }) => (
  <Section>
    {title && <span className={styles.title}>{title}</span>}
    {description && <span className={styles.description}>{description}</span>}
    {link && <span className={styles.link}>{link}</span>}
  </Section>
);

Text.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};

export default Text;
