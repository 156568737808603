import React from 'react';

import { Checkbox } from 'frontend/components';
import { setInsightsSearchUIFilters } from 'frontend/state/dux/insights';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';

const insightsSearchFilterSort = [
  { label: 'Show labels', value: 'showLabels' },
  { label: 'Assigned', value: 'assigned' },
  { label: 'Feedback', value: 'feedback' },
  { label: 'User name', value: 'userName' },
];

const SearchUIFilter = () => {
  const searchUIFilters = useAppSelector((state) => state.insights.searchUIFilters);
  const dispatch = useAppDispatch();

  return insightsSearchFilterSort.map((item) => (
    <Checkbox
      key={item.value}
      input={{
        name: item.value,
        checked: searchUIFilters?.[item.value],
      }}
      onClick={() => dispatch(setInsightsSearchUIFilters({ [item.value]: !searchUIFilters[item.value] }))}
      label={item.label}
    />
  ));
};

export default SearchUIFilter;
