import { gql } from '@apollo/client';

import { sampleCandidateFragment } from '../fragments';

export default gql`
  mutation RemoveSampleFromDialogue($botId: ID!, $dialogueId: UUID!, $sampleCandidateId: UUID!) {
    removeSampleFromDialogue(botId: $botId, dialogueId: $dialogueId, sampleCandidateId: $sampleCandidateId) {
      ...SampleCandidate
    }
  }
  ${sampleCandidateFragment}
`;
