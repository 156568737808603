import styles from './styles.scss';

const DotsButton = () => (
  <div className={styles.button}>
    <div className={styles.inner}>
      <span />
    </div>
  </div>
);

export default DotsButton;
