import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import {
  OrganizationBotsDocument,
  OrganizationMetaDocument,
  OrganizationSettingsDocument,
  OrganizationSkillsDocument,
  OrganizationUsersDetailDocument,
} from 'frontend/api/generated';
import { ORGANIZATION_USERS } from 'frontend/features/Organization/queries';
import { usePusherEvent } from 'frontend/features/Pusher';
import { fetchIfNecessary } from 'frontend/utils';

const updateQueries = [
  OrganizationBotsDocument,
  OrganizationMetaDocument,
  OrganizationSettingsDocument,
  OrganizationSkillsDocument,
  ORGANIZATION_USERS,
  OrganizationUsersDetailDocument,
];

export default (id) => {
  const client = useApolloClient();

  const onUpdate = useCallback(
    () => updateQueries.forEach((query) => fetchIfNecessary({ client, query, variables: { id } })),
    [client, id],
  );

  usePusherEvent(`private-organization-${id}`, 'organization-updated', onUpdate);
};
