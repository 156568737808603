import { motion } from 'framer-motion';
import { every } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Loader, Search } from 'frontend/components';
import { useDialogueResults } from 'frontend/features/Evaluation/hooks';
import { ChildrenType } from 'frontend/propTypes';

import ResultsForDialogue from './ResultsForDialogue';
import styles from '../Evaluation.scss';

const FadeInWrapper = ({ children }) => {
  const variants = {
    initital: {
      opacity: 0,
    },
    fadeIn: {
      opacity: 1,
    },
  };
  return (
    <motion.div initial="initial" animate="fadeIn" variants={variants}>
      {children}
    </motion.div>
  );
};
FadeInWrapper.propTypes = {
  children: ChildrenType,
};

const searchKey = ['title'];

const DetailedReport = ({ evaluationId, reportId }) => {
  const activeFilters = useSelector((state) => state.evaluation.filters);
  const { dialogueResults, loading } = useDialogueResults(evaluationId, reportId);
  const [searchResults, setSearchResults] = useState(dialogueResults);

  /* Search results */
  const searchFiltered = useMemo(() => {
    if (activeFilters.length === 0) return searchResults;
    return searchResults.map((dialogue) => ({
      ...dialogue,
      tests: dialogue.tests.filter((test) => every(activeFilters.map((fn) => fn(test)))),
    }));
  }, [activeFilters, searchResults]);
  const showingTestCount = searchFiltered.reduce((a, b) => a + b.tests.length, 0);
  const originalTestCount = dialogueResults.reduce((a, b) => a + b.tests.length, 0);

  useEffect(() => {
    if (dialogueResults.length && !searchResults.length) {
      setSearchResults(dialogueResults);
    }
  }, [dialogueResults, searchResults]);

  if (loading) {
    return <Loader size="large" />;
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles.search}>
          <Search
            setSearchResults={setSearchResults}
            searchItems={dialogueResults}
            searchKey={searchKey}
            placeholder="Search dialogues"
            aria-label="Search dialogues"
            icon
          />
        </div>
      </div>
      <FadeInWrapper>
        <div className={styles.dialogueList}>
          {showingTestCount !== originalTestCount && (
            <span className={styles.testCount}>
              Showing {showingTestCount} of {originalTestCount} queries
            </span>
          )}
          {searchFiltered.map((result) =>
            result.tests.length > 0 ? (
              <ResultsForDialogue
                results={result.tests}
                title={result.title}
                key={result.title}
                dialogueLink={result.dialogueLink}
              />
            ) : null,
          )}
        </div>
      </FadeInWrapper>
    </>
  );
};
DetailedReport.propTypes = {
  evaluationId: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
};

export default DetailedReport;
