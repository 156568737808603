import { useMutation } from '@apollo/client';

import { UpdateTrackingConsentDocument } from 'frontend/api/generated';
import { EmojiHappy } from 'frontend/assets/icons';
import { Modal } from 'frontend/features/Modals';

import styles from './styles.scss';

interface TrackingConsentModal {
  hide(): Promise<void>;
}
const TrackingConsentModal = ({ hide }: TrackingConsentModal) => {
  const [updateTrackingConsent] = useMutation(UpdateTrackingConsentDocument);

  const onOk = async () => {
    await updateTrackingConsent({ variables: { accept: true } });
  };

  const onCancel = async () => {
    await updateTrackingConsent({ variables: { accept: false } });
  };

  return (
    <Modal
      title="Help us get better!"
      icon={EmojiHappy}
      hide={hide}
      onOkText="I approve"
      onCancelText="No thanks"
      onOk={onOk}
      onCancel={onCancel}
    >
      <Modal.Content>
        <p>
          We want to improve your user experience. To help with this we want to ask you to let us gather more data about
          your user activity when using Kindly, so that we can learn more about how you navigate and use our features.
          Your privacy is of the highest importance to us at Kindly, we don&apos;t share this with anyone.
        </p>
        You can read more about our privacy and terms of service at{' '}
        <a href="https://www.kindly.ai/legal" target="_blank" rel="noreferrer" className={styles.link}>
          kindly.ai/legal
        </a>
        .
      </Modal.Content>
    </Modal>
  );
};

export default TrackingConsentModal;
