import { defineAction } from 'redux-define';

const PUSHER = defineAction('pusher');
export const INIT = PUSHER.defineAction('INIT', ['SUCCESS', 'ERROR']);

export const onPusherConnectSuccess = (socketId) => ({ type: INIT.SUCCESS, socketId });
export const onPusherConnectError = () => ({ type: INIT.ERROR });

const selectPusher = ({ pusher }) => pusher;
export const selectPusherInitiated = (state) => selectPusher(state).initiated;

const initialState = {
  initiated: false,
  error: false,
  socketId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT.SUCCESS:
      return { ...state, socketId: action.socketId, initiated: true };
    case INIT.ERROR:
      return { ...state, error: true };
    default:
      return state;
  }
};
