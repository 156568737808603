import { ResponsiveLine } from '@nivo/line';
import { startOfWeek, subDays, subWeeks } from 'date-fns';

import { Loader } from 'frontend/components';
import useSageData from 'frontend/features/Analytics/hooks/useSageData';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';
import { type DateRange, WEEK_STARTS_ON_MONDAY } from 'frontend/utils/date';

interface BotActivityProps {
  botId: string;
}

function getDateRange(): DateRange {
  const mondayThisWeek = startOfWeek(new Date(), WEEK_STARTS_ON_MONDAY);
  const endDate = subDays(mondayThisWeek, 1); // most recent sunday
  const startDate = subWeeks(mondayThisWeek, 52); // monday 52 weeks ago

  return { startDate, endDate };
}

function BotActivity({ botId }: BotActivityProps) {
  const { startDate, endDate } = getDateRange();

  const { loading, data } = useSageData(sageScope(SAGE_RESOURCE.BOT, botId), '/sessions/messages', {
    from: startDate,
    to: endDate,
    granularity: 'week',
    sources: ['-test'],
  });

  if (loading) {
    return <Loader size="medium" />;
  }

  const lineData = [
    {
      id: `bot-${botId}-activity`,
      data: data?.map(({ count }, index) => ({ x: index, y: count })) || [],
    },
  ];

  if (!data) return null;

  return (
    <div style={{ width: '100px', height: '50px' }}>
      <ResponsiveLine
        data={lineData}
        xScale={{ type: 'linear' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        colors={['#4fb9f3']}
        enablePoints={false}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        areaOpacity={0}
        isInteractive={false}
        enableCrosshair={false}
        legends={[]}
        animate={false}
      />
    </div>
  );
}

export default BotActivity;
