import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { Button } from 'frontend/components';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import styles from './EntityList.scss';
import Options from './Options';
import OptionsWithDeleteFromDialogue from './OptionsWithDeleteFromDialogue';
import { EntityType } from '../../propTypes';
import EntityItemList from '../EntityItemList';

const ExpandButton = ({ expanded, nOverflowItems, onClick }) => (
  <Button className={styles.expandButton} onClick={onClick} flat>
    {expanded ? 'Less...' : `More (${nOverflowItems + 1})`}
  </Button>
);

ExpandButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  nOverflowItems: PropTypes.number,
};

const EntityListItemCell = ({
  entity,
  areSkillEntities,
  withProperties,
  hideDelete,
  maxItems,
  allowExpand,
  deleteFromDialogue,
  isModDialogue,
  botOrSkillParams,
}) => {
  const { items, id, hue } = entity;
  const [itemsExpanded, setItemsExpanded] = useState(false);

  const onClick = useCallback(
    () => allowExpand && setItemsExpanded(!itemsExpanded),
    [allowExpand, itemsExpanded, setItemsExpanded],
  );
  const nOverflowItems = maxItems && (items || []).length - maxItems;

  return (
    <div className={styles.itemCell}>
      <EntityItemList
        items={items}
        hue={hue}
        entityId={id}
        className={styles.itemList}
        maxItems={maxItems}
        itemsExpanded={itemsExpanded}
      >
        <ExpandButton onClick={onClick} expanded={itemsExpanded} nOverflowItems={nOverflowItems} />
      </EntityItemList>
      {deleteFromDialogue ? (
        <OptionsWithDeleteFromDialogue
          entity={entity}
          areSkillEntities={areSkillEntities}
          withProperties={withProperties}
          hideDelete={hideDelete}
          isModDialogue={isModDialogue}
          botOrSkillParams={botOrSkillParams}
        />
      ) : (
        <Options
          entity={entity}
          areSkillEntities={areSkillEntities}
          withProperties={withProperties}
          hideDelete={hideDelete}
          botOrSkillParams={botOrSkillParams}
        />
      )}
    </div>
  );
};

EntityListItemCell.propTypes = {
  areSkillEntities: PropTypes.bool,
  withProperties: PropTypes.bool,
  hideDelete: PropTypes.bool,
  maxItems: PropTypes.number,
  allowExpand: PropTypes.bool,
  deleteFromDialogue: PropTypes.bool.isRequired,
  isModDialogue: PropTypes.bool,
  entity: EntityType.isRequired,
  botOrSkillParams: BotOrSkillParamsType.isRequired,
};

export default EntityListItemCell;
