import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const BOT_ORGANIZATION_INBOX_ENABLED = gql`
  query BotOrganizationInboxEnabled($botId: ID!) {
    bot(id: $botId) {
      id
      organization {
        id
        enableOrganizationInbox
      }
    }
  }
`;

const ORGANIZATION_INBOX_ENABLED = gql`
  query OrganizationInboxEnabled($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      enableOrganizationInbox
    }
  }
`;

export default () => {
  const { botId, organizationId } = useParams();

  const { data: botData, loading: botLoading } = useQuery(BOT_ORGANIZATION_INBOX_ENABLED, {
    variables: { botId },
    skip: !botId,
  });

  const { data: orgData, loading: orgLoading } = useQuery(ORGANIZATION_INBOX_ENABLED, {
    variables: { organizationId },
    skip: !organizationId,
  });

  const loading = botLoading || orgLoading;
  const organizationInboxEnabled = botId
    ? botData?.bot?.organization?.enableOrganizationInbox
    : orgData?.organization?.enableOrganizationInbox;

  return { organizationInboxEnabled, loading, botOrganizationId: botData?.bot?.organization?.id };
};
