/* eslint-disable no-use-before-define */
/**
 * This function returns the provided `lightColor` if the provided `bgColor`
 * is dark or the `darkColor` if the `bgColor` is light. It's useful to
 * ensure that the front color is readable on whatever background according to WCAG standards.
 *
 * It accepts **RGB** (`rgb(22,226,22)` or `rgb(255 255 255)`) and **long** (`#ffffff`) & **short** (`#333`) hex color codes.
 * @param {string} bgColor - background hex color string
 * @param {string} lightColor - light color to use if `bgColor` is dark (works best with _white_)
 * @param {string} darkColor - dark color to use if `bgColor` is light (works best with _black_)
 * @returns the contrast color (`lightColor` or `darkColor`) based on the provided background color
 * @see https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
 * @see https://stackoverflow.com/questions/1855884/determine-font-color-based-on-background-color
 * @example getContrastColor('#000000', '#ffffff', '#000000') // '#ffffff'
 */

const shorthandHexRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
const isColorRGB = (color) => color.match(/^rgb/);
const isColorShortHex = (color) => color.match(shorthandHexRegex);

function RGBToLongHex(rgb) {
  const separator = rgb.indexOf(',') > -1 ? ',' : ' ';
  // From "rgb(r,g,b)" to [r,g,b]
  rgb = rgb.substr(4).split(')')[0].split(separator);

  let r = (+rgb[0]).toString(16);
  let g = (+rgb[1]).toString(16);
  let b = (+rgb[2]).toString(16);

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;

  return `#${r}${g}${b}`;
}

function shortHexToLongHex(shortHex) {
  return shortHex.replace(shorthandHexRegex, (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`);
}

/**
 * Check if the passed color is bright or dark according to WCAG standards.
 *
 * It accepts **RGB** (`rgb(22,226,22)` or `rgb(255 255 255)`) and **long** (`#ffffff`) & **short** (`#333`) hex color codes.
 * @param {string} bgColor the color to check
 * @returns {boolean} **true** if the color is bright, **false** if it's dark
 */

export function isColorBright(bgColor) {
  let parsedColor = bgColor;
  if (isColorRGB(bgColor)) parsedColor = RGBToLongHex(bgColor);
  if (isColorShortHex(bgColor)) parsedColor = shortHexToLongHex(bgColor);

  const color = parsedColor.charAt(0) === '#' ? parsedColor.substring(1, 7) : parsedColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors: [number, number, number] = [r / 255, g / 255, b / 255];

  const c: [number, number, number] = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return ((col + 0.055) / 1.055) ** 2.4;
  }) as [number, number, number];
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179;
}

export function getContrastColor(bgColor, lightColor, darkColor) {
  return isColorBright(bgColor) ? darkColor : lightColor;
}
