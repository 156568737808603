import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { Button, FormErrors, Input, PageContent, Panel, ToggleSwitch } from 'frontend/components';
import { chain, max, required, url } from 'frontend/form/validators';
import { useTabTitle } from 'frontend/hooks';

import styles from './PlatformAlerts.scss';

const PUBLISHED_STATES = ['Published', 'Not published'];

const PlatformAlertForm = ({ onSubmit, initialValues }) => {
  useTabTitle('Platform alerts');
  const navigate = useNavigate();

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      render={({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <PageContent hasFormValidation>
            <div>
              <Button size="small" onClick={() => navigate(-1)}>
                Go back
              </Button>
            </div>
            <FormErrors />
            <div className={`${styles.newAlert} m-t-xl`}>
              <Panel className={styles.panel}>
                <div className={styles.alertsForm}>
                  <Field
                    component={Input}
                    label="Text"
                    validate={chain([required, max(300)])}
                    className="m-b-lg"
                    placeholder="Text"
                    name="text"
                  />
                  <Field
                    component={Input}
                    label="Read more URL"
                    validate={url}
                    className="m-b-lg"
                    placeholder="Read more URL"
                    name="readMoreUrl"
                  />
                  <ToggleSwitch status={PUBLISHED_STATES} name="published" />
                  <br />
                  <Button color="primary" type="submit" disabled={!dirty} className="m-t-md">
                    Save
                  </Button>
                </div>
              </Panel>
            </div>
          </PageContent>
        </form>
      )}
    />
  );
};

export default PlatformAlertForm;
