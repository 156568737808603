import { gql } from '@apollo/client';

import { KINDLY_ENTITY } from '../fragments';

export default gql`
  mutation AddKindlyEntities($botId: ID!, $kindlyEntityIds: [UUID]!) {
    addKindlyEntities(botId: $botId, kindlyEntityIds: $kindlyEntityIds) {
      id
      waitMinutes
      kindlyEntities {
        ...KindlyEntity
      }
    }
  }
  ${KINDLY_ENTITY}
`;
