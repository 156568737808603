/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames';
import React from 'react';

import styles from './ButtonGroup.scss';
import Button, { type ButtonProps } from '../Button/Button';
import Dropdown, { type DropdownProps } from '../Dropdown/Dropdown';

export type ButtonGroupButtonType = {
  type: 'button' | 'dropdown';
  props: Partial<ButtonProps> & Partial<DropdownProps<typeof Button | 'button'>>;
  key: string;
  children?: React.ReactElement | React.ReactElement[] | string;
};

interface ButtonGroupProps {
  buttons: ButtonGroupButtonType[];
}

const ButtonGroup = ({ buttons }: ButtonGroupProps) => {
  const renderButton = ({ props, type, children, key }: ButtonGroupButtonType, index: number) => {
    const className = cx(props.className, {
      [styles.btnGroupFirst]: index === 0 && buttons.length > 0,
      [styles.btnGroupLast]: index === buttons.length - 1,
      [styles.btnGroupMiddle]: buttons.length > 1 && index > 0 && index < buttons.length - 1,
    });

    if (type === 'dropdown') {
      return (
        <Dropdown
          key={key}
          {...props}
          overlay={props.overlay!}
          elementProps={{ ...props.elementProps, className: cx(props.elementProps?.className, className) }}
        >
          {children}
        </Dropdown>
      );
    }
    return (
      <Button key={key} {...props} className={className}>
        {children}
      </Button>
    );
  };
  return <div className={styles.buttonGroupWrapper}>{buttons.map(renderButton)}</div>;
};

export default ButtonGroup;
