import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import {
  CreateBotDocument,
  OrganizationBotsDocument,
  OrganizationMetaDocument,
  OrganizationSkillsDocument,
} from 'frontend/api/generated';
import { createSkill } from 'frontend/api/mutations';
import { getAvailableLanguages } from 'frontend/api/queries';
import { Input, Select, ToggleSwitch } from 'frontend/components';
import { buildTypes } from 'frontend/constants';
import { ModalForm } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';
import { useMixpanel, useToast } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { capitalize } from 'frontend/utils';

const CreateBotLikeModal = ({ hide, args: { buildType, primaryLanguage = 'nb', organizationId } }) => {
  const { data: languageData, loading } = useQuery(getAvailableLanguages);
  const toast = useToast();
  const navigate = useNavigate();
  const { mixpanel } = useMixpanel();

  const availableLanguages = languageData?.availableLanguages;
  const { isKindlyAdminViewer } = useMyPermissions();

  const mutation = buildType === buildTypes.BOT ? CreateBotDocument : createSkill;
  const [mutate] = useMutation(mutation);
  const renameBuildTypes = buildType === buildTypes.BOT ? 'workspace' : buildType;
  const onSubmit = useCallback(
    async (values) => {
      values.name = values.name.trim();
      await mutate({
        variables: {
          organizationId,
          initialLanguage: values.primaryLanguage,
          ...(buildType === buildTypes.BOT ? { intentMatchEnabled: !values.isSample } : {}),
          ...values,
        },
        awaitRefetchQueries: true,
        refetchQueries: [OrganizationMetaDocument, OrganizationBotsDocument, OrganizationSkillsDocument],
        update: async (_cache, { data }) => {
          const newBotLike = data[`create${capitalize(buildType)}`];
          const { id, name, organization } = newBotLike;
          toast.success(`Created the ${renameBuildTypes}: ${name}`);
          mixpanel.track('Create workspace', { botId: id, organization });
          navigate(`/${renameBuildTypes}/${id}`);
        },
      });
    },
    [mixpanel, navigate, organizationId, mutate, toast, renameBuildTypes, buildType],
  );
  if (loading) {
    return null;
  }
  return (
    <ModalForm
      hide={hide}
      onSubmit={onSubmit}
      initialValues={{
        primaryLanguage,
      }}
      title={`Create ${renameBuildTypes}`}
      footer
      onOkText="Create"
    >
      <Field
        component={Input}
        className="m-b-md"
        placeholder={`${capitalize(renameBuildTypes)} name`}
        name="name"
        label="Name"
        inputLimit={50}
        validate={chain([required, max(50)])}
        autoFocus
      />
      <label htmlFor="primaryLanguage">Language</label>
      <Field component={Select} name="primaryLanguage">
        {availableLanguages.map((item) => (
          <Select.Option value={item.code} label={item.name} key={item.code} />
        ))}
      </Field>
      {buildType === buildTypes.BOT && isKindlyAdminViewer && (
        <div className="m-t-4">
          <ToggleSwitch isKindlyAdmin label="Create as Sample Bot" name="isSample" status={['Create as Sample Bot']} />
        </div>
      )}
    </ModalForm>
  );
};
CreateBotLikeModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    organizationId: PropTypes.string.isRequired,
    buildType: PropTypes.oneOf(Object.values(buildTypes)).isRequired,
    primaryLanguage: PropTypes.string,
  }),
};
export default CreateBotLikeModal;
