import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AllPluginsDocument, type PluginType } from 'frontend/api/generated';
import { Plus } from 'frontend/assets/icons';
import { Button, LoaderSwitch, PageContent } from 'frontend/components';
import { withErrorHandler } from 'frontend/enhancers';
import NotFound from 'frontend/features/NotFound/NotFound';
import { useDocumentTitle } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import ConnectList from '../components/ConnectList/ConnectList';

export default withErrorHandler(() => {
  useDocumentTitle('Plugins');
  const { isKindlyAdminManager, loading: loadingPermissions } = useMyPermissions();
  const { data, loading: loadingPlugins } = useQuery(AllPluginsDocument);
  const plugins = useMemo(() => orderBy(data?.allPlugins ?? [], ({ name }) => name.toLocaleString()), [data]);

  const location = useLocation();
  const isAdminRoute = location.pathname?.startsWith('/admin/');

  const renderPlugins = () =>
    !plugins.length ? (
      <NotFound text="No plugins found" />
    ) : (
      <ConnectList isAdmin={isKindlyAdminManager && isAdminRoute} data={plugins as PluginType[]} />
    );

  if (!isAdminRoute) {
    return (
      <LoaderSwitch size="large" loading={loadingPlugins || loadingPermissions}>
        {renderPlugins()}
      </LoaderSwitch>
    );
  }

  return (
    <LoaderSwitch loading={loadingPlugins || loadingPermissions}>
      <PageContent>
        <div className="p-b-lg">
          <Link to="/admin/plugins/new">
            <Button color="primary" icon={Plus} text="Create plugin" />
          </Link>
        </div>

        {renderPlugins()}
      </PageContent>
    </LoaderSwitch>
  );
});
