import { useSortable } from '@dnd-kit/sortable';
import type { Transform } from '@dnd-kit/utilities';

import { getId } from 'frontend/utils';

const transformToString = (transform: Transform | null) => {
  if (transform == null || (transform.x == null && transform.y == null)) return 'none';
  return `translate(${transform.x}px, ${transform.y}px)`;
};

export default function useSortableItem(item: Record<string, any>, disabled = false) {
  const id = getId(item);
  if (!id) throw new Error('Missing prop id or tempId on item');

  const {
    attributes: dragAttributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    index,
    isSorting,
  } = useSortable({
    id,
    data: item,
    disabled,
    animateLayoutChanges: () => false,
  });

  const style =
    transform || transition
      ? { transform: transformToString(transform), transition: transition || undefined }
      : undefined;

  return {
    isSorting,
    style,
    dragAttributes,
    onPointerDown: listeners?.onPointerDown as (event: React.PointerEvent) => void,
    setNodeRef,
    index,
  };
}
