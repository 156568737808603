import { useLocation } from 'react-router-dom';

import { BUILD_FORM_TYPES } from 'frontend/features/Build/propTypes/BuildFormType';

/** Return the type of the dialogue (read from the `pathname`) or `null` if it's not a known type. */
export default function useDialogueType() {
  const { pathname } = useLocation();

  // eslint-disable-next-line no-restricted-syntax
  for (const dialogueType of BUILD_FORM_TYPES) {
    if (pathname.includes(`/${dialogueType}/`)) return dialogueType;
  }

  return null;
}
