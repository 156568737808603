import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSageData } from 'frontend/features/Analytics/hooks/index';
import { PRIORITY } from 'frontend/state/dux/analytics/dataQueue';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

const firstInYear = (fullYear) => new Date(`${fullYear}-01-01 00:00:00`);
const lastInYear = (fullYear) => new Date(`${fullYear}-12-31 23:59:59`);

const DEFAULT_MIN_DATE = firstInYear(2017);
const DEFAULT_MAX_DATE = lastInYear(new Date().getFullYear());

const useMinMaxDates = () => {
  const { botId } = useParams();
  const scope = sageScope(SAGE_RESOURCE.BOT, botId);
  const { data: botMeta } = useSageData(
    scope,
    'meta/options',
    { from: DEFAULT_MIN_DATE, to: DEFAULT_MAX_DATE },
    { enable: Boolean(botId), priority: PRIORITY.LOWEST },
  );

  return useMemo(
    () => ({
      minDate: botMeta?.first ? firstInYear(new Date(botMeta.first).getFullYear()) : DEFAULT_MIN_DATE,
      maxDate: botMeta?.last ? lastInYear(new Date(botMeta.last).getFullYear()) : DEFAULT_MAX_DATE,
    }),
    [botMeta],
  );
};
export default useMinMaxDates;
