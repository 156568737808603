import PropTypes from 'prop-types';

import { SlotType } from 'frontend/features/Slots/propTypes';

import EntityType from './EntityType';
import KindlyEntityType from './KindlyEntityType';

export default PropTypes.arrayOf(
  PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    tempId: PropTypes.string,
    id: PropTypes.string,
    entity: PropTypes.oneOfType([EntityType, SlotType]),
    kindlyEntity: KindlyEntityType,
  }),
);
