import { gql } from '@apollo/client';

export default gql`
  fragment SubscribedSkill on SkillSubscriptionType {
    id
    skill {
      id
      name
      languages {
        id
        name
      }
      createdBy {
        id
      }
      organization {
        id
        name
      }
    }
  }
`;
