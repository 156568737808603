import { gql } from '@apollo/client';

import { CONTEXT_FIELD } from '../fragments';

export default gql`
  mutation UpdateContextField($botId: ID!, $contextFieldId: ID!, $key: String!, $value: String!) {
    updateContextField(botId: $botId, contextFieldId: $contextFieldId, key: $key, value: $value) {
      ...ContextField
    }
  }
  ${CONTEXT_FIELD}
`;
