import { useMutation } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { DropdownList } from 'frontend/features/Composer';
import { CREATE_ENTITY } from 'frontend/features/Entities/mutations';
import { EntityType, KindlyEntityType } from 'frontend/features/Entities/propTypes';
import { ENTITIES } from 'frontend/features/Entities/queries';
import { entityName } from 'frontend/features/Entities/validators';
import { useClickOutside, useToggle } from 'frontend/hooks';
import { BuildIdObjectType, RefType } from 'frontend/propTypes';
import { selectAutoGeneratingEntityItems } from 'frontend/state/dux/build';

import CreateEntity from './CreateEntity';
import { getEntityItemsIncludingInput } from '../../utils';

const pathToItemInMutationData = 'createEntity';
const pathToArrayInCache = 'entities';
const query = ENTITIES;

const EntityDropdown = ({ close, ready, addTag, dropdownRef, fromHashtag, selectedText, extra }) => {
  const { currentLanguage, buildIdObject, entities, loading } = extra;
  const [creatingEntity, toggleCreatingEntity] = useToggle();
  const [searchResults, setSearchResults] = useState(null);
  const [newEntityItems, setNewEntityItems] = useState('');
  const isGenerating = useSelector(selectAutoGeneratingEntityItems);

  const update = appendArrayInCache({
    pathToItemInMutationData,
    variables: buildIdObject,
    pathToArrayInCache,
    query,
  });
  const [createEntity] = useMutation(CREATE_ENTITY, { update });

  useEffect(() => {
    if (!loading) {
      ready();
    }
  }, [loading, ready]);

  const onSubmit = useCallback(
    async ({ name, items }) => {
      if (!creatingEntity) {
        toggleCreatingEntity();
        return;
      }

      const entityInput = {
        name,
        items: getEntityItemsIncludingInput(items, newEntityItems),
      };
      const variables = {
        ...buildIdObject,
        entityInput,
        languageCode: currentLanguage,
      };
      const { data } = await createEntity({ variables });
      addTag(data.createEntity);
    },
    [addTag, buildIdObject, createEntity, creatingEntity, currentLanguage, newEntityItems, toggleCreatingEntity],
  );

  const initialValues = useMemo(
    () =>
      fromHashtag
        ? { name: selectedText, items: [] }
        : {
            name: '',
            items: [selectedText],
          },
    [fromHashtag, selectedText],
  );

  const entityNameValidator = useCallback(
    ({ name }) => {
      const result = entityName(entities)(name);
      if (result) {
        return { name: result };
      }
      return undefined;
    },
    [entities],
  );

  const onClickOutside = useMemo(() => (isGenerating ? noop : close), [close, isGenerating]);
  useClickOutside(dropdownRef, onClickOutside);

  const listItems = useMemo(() => {
    if (searchResults) {
      return searchResults;
    }
    return entities.filter(({ items }) => items); // Hide Kindly entities when not searching
  }, [entities, searchResults]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        component={CreateEntity}
        entities={entities}
        creatingEntity={creatingEntity}
        toggleCreatingEntity={toggleCreatingEntity}
        setSearchResults={setSearchResults}
        currentLanguage={currentLanguage}
        validate={entityNameValidator}
        mutators={{ ...arrayMutators }}
        newEntityItems={newEntityItems}
        setNewEntityItems={setNewEntityItems}
        buildIdObject={buildIdObject}
      />
      {!creatingEntity && <DropdownList items={listItems} addTag={addTag} />}
    </>
  );
};

EntityDropdown.propTypes = {
  close: PropTypes.func.isRequired,
  ready: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  selectedText: PropTypes.string.isRequired,
  fromHashtag: PropTypes.bool,
  dropdownRef: RefType.isRequired,
  extra: PropTypes.exact({
    currentLanguage: PropTypes.string.isRequired,
    buildIdObject: BuildIdObjectType.isRequired,
    loading: PropTypes.bool.isRequired,
    entities: PropTypes.arrayOf(PropTypes.oneOfType([EntityType, KindlyEntityType])).isRequired,
  }).isRequired,
};

export default EntityDropdown;
