import { useQuery } from '@apollo/client';
import { shuffle } from 'lodash';
import { useRef, useState } from 'react';

import { GeneratedSamplesDocument, type SampleType } from 'frontend/api/generated';
import { Slot } from 'frontend/assets/icons';
import { CheckboxOnly, List, Loader } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import { useMixpanel } from 'frontend/hooks';

import styles from './GenerateSamples.scss';
import type { GeneratedSampleType } from '../../components/SamplesHeadline/SamplesHeadline';

const MAX_INPUT_SAMPLES = 10;

type GeneratedValidatedSamplesType = {
  text: string;
  id: string;
};

interface GenerateSamplesProps {
  hide(): Promise<void>;
  args: {
    botId: string;
    handleGenerateSamples(samples?: GeneratedSampleType[]): Promise<Record<string, unknown> | void>;
    initialSamples: SampleType[];
    sampleValidator: (sample) => string | undefined;
    trackingData: Record<string, string>;
  };
}

const GenerateSamples = ({
  hide,
  args: { initialSamples, botId, handleGenerateSamples, sampleValidator, trackingData },
}: GenerateSamplesProps) => {
  const [selectedSamplesIds, setSelectedSamplesIds] = useState<Set<string>>(new Set());

  const { mixpanel } = useMixpanel();

  /* Apollo thinks that the array is changing on every render (because of shuffle),
  so we need to use useRef to avoid a query loop */
  const initialSamplesShuffledAndCapped = useRef(
    shuffle(initialSamples.map(({ text }) => text)).slice(0, MAX_INPUT_SAMPLES),
  ).current;

  const { data, loading, error } = useQuery(GeneratedSamplesDocument, {
    variables: {
      botId,
      sampleTexts: initialSamplesShuffledAndCapped,
    },
    fetchPolicy: 'no-cache',
    /* Select all generated samples at first */
    onCompleted: (dataCompleted) => {
      const generatedSamples = dataCompleted.generatedSamples;
      const newSelectedSamplesIds = new Set<string>();
      generatedSamples.forEach(({ id }) => newSelectedSamplesIds.add(id));
      setSelectedSamplesIds(newSelectedSamplesIds);
    },
    skip: initialSamples.length === 0,
  });
  const generatedSamples = data?.generatedSamples || [];
  const generatedValidatedSamples: GeneratedValidatedSamplesType[] = generatedSamples.flatMap((sample) => {
    if (sampleValidator({ text: sample.text })) return [];
    return [sample];
  });

  const validateModal = (): string | false => {
    if (initialSamples.length === 0) {
      return "You don't have any visible sample to start from. Please add samples or change your search/filter.";
    }
    if (error || (generatedValidatedSamples.length === 0 && !loading)) {
      return 'Oops sorry, the AI 🤖 is short of ideas, please try again.';
    }

    return false;
  };

  const toggleSelected = (id: string): void => {
    const newSelectedSamples = new Set(selectedSamplesIds);

    if (newSelectedSamples.delete(id)) setSelectedSamplesIds(newSelectedSamples);
    else setSelectedSamplesIds(newSelectedSamples.add(id));
  };

  const acceptSelectedSamples = async (): Promise<void> => {
    mixpanel.track('GPT-3 Samples Generation: Accepting Samples', trackingData);
    handleGenerateSamples(generatedValidatedSamples.filter(({ id }) => selectedSamplesIds.has(id)));
  };

  return (
    <Modal
      icon={Slot}
      title="Suggested Samples"
      disabled={loading || !!validateModal()}
      hide={hide}
      onOk={acceptSelectedSamples}
      onOkText="Accept Samples"
    >
      <Modal.Content>
        {/* eslint-disable-next-line no-nested-ternary */}
        {validateModal() ? (
          <p>{validateModal()}</p>
        ) : loading ? (
          <Loader />
        ) : (
          <>
            <p className="m-t-0">
              These samples are generated by the AI based on your existing samples.
              <br />
              You can search/filter your existing samples to generate narrow-scoped samples.
            </p>
            <List
              className={styles.generatedList}
              dataSource={generatedValidatedSamples}
              renderItem={(item: GeneratedValidatedSamplesType) => (
                <label key={item.id} className={styles.generatedListItem}>
                  <CheckboxOnly
                    name={`checkbox_${item.id}`}
                    onChange={() => toggleSelected(item.id)}
                    checked={selectedSamplesIds.has(item.id)}
                  />
                  {item.text}
                </label>
              )}
            />
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default GenerateSamples;
