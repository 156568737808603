import { Route, Routes } from 'react-router-dom';

import { NavLinkTab, PageContent, Panel } from 'frontend/components';
import Separator from 'frontend/components/Panel/Separator';
import { FIELD_COLOR } from 'frontend/constants';

import Export from './components/Export/Export';
import ExportAction from './components/ExportAction/ExportAction';
import Import from './components/Import/Import';
import ImportAction from './components/ImportAction/ImportAction';
import styles from './index.scss';

const ImportExport = () => (
  <PageContent className={styles.container}>
    <Panel size="medium">
      <div className={styles.panelHeader}>
        <div className={styles.navLinkTab}>
          <NavLinkTab title="Export" to="export" tabColor={FIELD_COLOR.WHITE} />
          <NavLinkTab title="Import" to="import" tabColor={FIELD_COLOR.WHITE} />
        </div>
        <Routes>
          <Route path="/export" element={<ExportAction />} />
          <Route path="/import" element={<ImportAction />} />
        </Routes>
      </div>
      <Separator className={styles.separator} />
      <Routes>
        <Route path="/export" element={<Export />} />
        <Route path="/import" element={<Import />} />
      </Routes>
    </Panel>
  </PageContent>
);

export default ImportExport;
