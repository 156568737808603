export default {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  NUMBER: 'NUMBER',
  RANGE: 'RANGE',
  SELECT: 'SELECT',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  FILE: 'FILE',
  TEXTAREA: 'TEXTAREA',
};

export const inputTypeTitleMap = {
  TEXT: 'Text',
  EMAIL: 'Email',
  NUMBER: 'Number',
  RANGE: 'Slider',
  SELECT: 'Dropdown',
  CHECKBOX: 'Multiple choice',
  RADIO: 'Multiple choice',
  FILE: 'File upload',
  TEXTAREA: 'Multiline Text',
};

export const validators = ['minLength', 'maxLength', 'maximum', 'minimum', 'pattern'];

export const formTexts = {
  en: {
    submitButtonText: 'Send',
    cancelButtonText: 'Cancel',
    unansweredText: 'Form was not answered',
    errorText: 'An error occurred',
    cancelText: 'Form was exited',
  },
  nn: {
    submitButtonText: 'Send inn',
    cancelButtonText: 'Avbryt',
    unansweredText: 'Skjemaet vart ikkje besvart',
    errorText: 'Det har oppstått ein feil',
    cancelText: 'Skjemaet vart avbrutt',
  },
  nb: {
    submitButtonText: 'Send inn',
    cancelButtonText: 'Avbryt',
    unansweredText: 'Skjemaet ble ikke besvart',
    errorText: 'Det har oppstått en feil',
    cancelText: 'Skjemaet ble avbrutt',
  },
  sv: {
    submitButtonText: 'Skicka',
    cancelButtonText: 'Avbryt',
    unansweredText: 'Formuläret besvarades inte',
    errorText: 'Det har uppstått ett fel',
    cancelText: 'Formuläret avbröts',
  },
};

export const fileUploadPlaceholderText = {
  en: 'Drag and drop or [click to upload]',
  nb: 'Drag and drop eller [klikk for å laste opp]',
  nn: 'Drag and drop eller [klikk for å laste opp]  ',
  sv: 'Drag and drop eller [klicka för att ladda upp]',
};
