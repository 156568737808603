import { gql } from '@apollo/client';

export default gql`
  mutation CreateMessageClusters(
    $botId: ID!
    $messageType: String!
    $languageCode: String!
    $fromDate: String!
    $toDate: String!
  ) {
    createMessageClusters(
      botId: $botId
      messageType: $messageType
      languageCode: $languageCode
      fromDate: $fromDate
      toDate: $toDate
    ) {
      id
      status
    }
  }
`;
