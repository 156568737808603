import cx from 'classnames';
import { truncate as truncateFunction } from 'lodash';

import { Tooltip } from 'frontend/components';

import styles from './styles.scss';

export interface Label {
  id: string;
  name: string;
  active?: boolean;
  color?: string;
  background?: string;
}
interface LabelBadgeProps {
  /** Label data object (id, name, color, background, active) */
  label: Label;
  /** Truncate the label when it's longer than passed value */
  truncate?: number;
  children?: React.ReactNode;
  onClick?(): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLDivElement>): void;
  className?: string;
}

const LabelBadge = ({
  label: { color, name, background },
  onClick,
  onKeyDown,
  className,
  children,
  truncate = 15,
}: LabelBadgeProps) => (
  <div
    style={{ background, color, borderColor: color }}
    className={cx(styles.label, className)}
    onClick={onClick}
    onKeyDown={onKeyDown}
    role={onClick ? 'button' : undefined}
    tabIndex={onClick ? -1 : undefined}
  >
    <div className={styles.labelContent}>
      <span className={styles.dot} style={{ backgroundColor: color }} />
      <span className={styles.labelName}>{truncate > 0 ? truncateFunction(name, { length: truncate }) : name}</span>
    </div>
    {children && <div className={styles.labelChildren}>{children}</div>}
    {truncate > 0 && name.length > truncate && (
      <Tooltip.Standalone className={styles.tooltip}>{name}</Tooltip.Standalone>
    )}
  </div>
);

export default LabelBadge;
