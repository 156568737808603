import cx from 'classnames';

import { camelToHumanReadable, capitalize } from 'frontend/utils';

import Contents from './Contents';
import type { CellProps } from './Table';
import styles from './Table.scss';

interface TableHeadProps {
  data: { columnComponent?: (props: CellProps) => React.JSX.Element; title?: string; key: string };
}

const TableHead = ({ data }: TableHeadProps) => {
  const title = data?.title ?? capitalize(camelToHumanReadable(data.key));
  return (
    <div className={cx(styles.cell, styles.cellHeader)} role="cell">
      {data?.columnComponent ? (
        <Contents data={data} component={data.columnComponent} columnName={data.title} />
      ) : (
        title
      )}
    </div>
  );
};

export default TableHead;
