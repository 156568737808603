import { useMutation } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Close, GoTo } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import { useMixpanel, useToast } from 'frontend/hooks';

import styles from './CandidateActions.scss';
import {
  ADD_SAMPLE_TO_DIALOGUE,
  ARCHIVE_SAMPLE_CANDIDATE,
  REMOVE_SAMPLE_FROM_DIALOGUE,
  UNDO_ARCHIVED_SAMPLE_CANDIDATE,
} from '../../mutations';

const CandidateActions = ({
  text,
  dialogueId,
  languageCode,
  sampleCandidateId,
  completed,
  setCompleted,
  setArchived,
  setIgnoredDialogues,
  ignoredDialogues,
}) => {
  const { botId } = useParams();
  const toast = useToast();
  const { mixpanel } = useMixpanel();
  const [loading, setLoading] = useState(false);

  const [undo] = useMutation(REMOVE_SAMPLE_FROM_DIALOGUE, {
    variables: { botId, dialogueId, sampleCandidateId },
    onCompleted: () => {
      setCompleted(false);
      setIgnoredDialogues(ignoredDialogues.filter(({ id }) => id !== sampleCandidateId));
    },
  });

  const [undoArchived] = useMutation(UNDO_ARCHIVED_SAMPLE_CANDIDATE, {
    variables: { botId, sampleCandidateId },
    onCompleted: () => {
      setIgnoredDialogues(ignoredDialogues.filter(({ id }) => id !== sampleCandidateId));
      setArchived(false);
    },
    onError: () => {
      setArchived(false);
    },
  });
  const [archive] = useMutation(ARCHIVE_SAMPLE_CANDIDATE, {
    variables: { botId, sampleCandidateId },
    onCompleted: () => {
      setArchived(true);
      setIgnoredDialogues([...ignoredDialogues, sampleCandidateId]);
      toast.success('Sample Candidate ignored', { undo: undoArchived });
      mixpanel.track('Archive sample candidate', { botId, sampleCandidateId });
    },
    onError: () => {
      setArchived(false);
    },
  });

  const [save] = useMutation(ADD_SAMPLE_TO_DIALOGUE, {
    variables: { botId, dialogueId, text, languageCode, sampleCandidateId },
    onCompleted: () => {
      setCompleted(true);
      setLoading(false);
      toast.success('Sample Candidate added as a sample', { undo });
      mixpanel.track('Add sample candidate to dialogue', { botId, dialogueId, languageCode, sampleCandidateId });
      setIgnoredDialogues([...ignoredDialogues, sampleCandidateId]);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const handleClick = useCallback(async (mutation) => {
    await mutation();
  }, []);

  if (completed) {
    return null;
  }

  return (
    <div className={cx(styles.actions, { [styles.actionsCompleted]: completed })}>
      <Link
        onClick={() =>
          mixpanel.track('Sample Candidate go to dialogue', { botId, dialogueId, languageCode, sampleCandidateId })
        }
        target="_blank"
        to={`/bot/${botId}/build/dialogue/${dialogueId}`}
        className={styles.goToIcon}
      >
        <Icon component={GoTo} />
      </Link>
      <div className={styles.actions}>
        <Button
          isSubmitting={loading}
          className={cx(styles.resolve, {
            [styles.resolveActive]: dialogueId,
          })}
          onClick={() => {
            setLoading(true);
            handleClick(save);
          }}
        >
          Create sample
        </Button>
        <Button icon={Close} flat className={styles.resolve} onClick={() => handleClick(archive)} />
      </div>
    </div>
  );
};

CandidateActions.propTypes = {
  text: PropTypes.string,
  dialogueId: PropTypes.string,
  languageCode: PropTypes.string,
  sampleCandidateId: PropTypes.string,
  completed: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setCompleted: PropTypes.func,
  setArchived: PropTypes.func,
  setIgnoredDialogues: PropTypes.func,
  ignoredDialogues: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
};

export default CandidateActions;
