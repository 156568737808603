import { gql } from '@apollo/client';

export default gql`
  query ConfusedLanguages($botId: ID!) {
    confusedLanguages(botId: $botId) {
      id
      languageCode
      languageName
    }
  }
`;
