import { captureMessage, getCurrentScope, withScope } from '@sentry/browser';
import { jwtDecode } from 'jwt-decode';
import mixpanel from 'mixpanel-browser';
import { defineAction } from 'redux-define';

import { toastError } from 'frontend/features/Toast';

/**
 * Authentication using two tokens
 *
 * 1. A refreshToken passed to the browser via an HttpOnly only cookie from /obtain.
 *    This means javascript can't touch this token, but can forward it using `fetch(_, {credentials: 'include'})`.
 *    This token is long lived, ie. days.
 *
 * 2. An accessToken passed to the browser in the response body from /obtain and /refresh.
 *    The accessToken is stored in local storage (to make it available to multiple tabs).
 *    The token is passed in the Authorization header in all API request.
 *    If refresh fails, auth state is cleared and the user is logged out.
 *    This token is short lived, ie. minutes.
 *
 * References:
 *  - [Request flows diagram](https://supertokens.io/static/webflow/blog/securely-manage/images/image113x-p-800.png)
 *  - [XXS and CSRF article on this pattern](http://www.redotheweb.com/2015/11/09/api-security.html)
 * */
const AUTH = defineAction('auth');

export const LOGIN = AUTH.defineAction('LOGIN', ['SUCCESS', 'ERROR']);
export const REFRESH_TOKEN = AUTH.defineAction('REFRESH_TOKEN', ['SUCCESS', 'ERROR']);
export const SIGN_OUT = AUTH.defineAction('SIGN_OUT');

export const selectAuth = ({ auth }) => auth;
export const selectLoginError = (state) => selectAuth(state).error;
export const selectAuthenticated = (state) => selectAuth(state).authenticated;

export const initialState = {
  access: undefined,
  authenticated: false,
  error: null,
  isRefreshing: false,
};

let tokenExpTimeout;
const EXPIRY_MARGIN_IN_SECONDS = 10;

function formatLoginError(payload, response) {
  const errorKey = payload && Object.keys(payload) ? Object.keys(payload)[0] : undefined;
  let errorText = 'Incorrect username or password';
  if (response.status === 429) {
    errorText = 'Too many login attempts. Please wait a few minutes.';
  } else if (errorKey && Array.isArray(payload[errorKey])) {
    errorText = payload[errorKey][0];
  } else if (errorKey && typeof payload[errorKey] === 'string') {
    errorText = payload[errorKey];
  }

  return errorText;
}

function handleRefreshError(response, getState, e) {
  if (response.status === 400 && getState().auth.authenticated) {
    withScope((scope) => {
      scope.setExtra('response', JSON.stringify(response));
      captureMessage('user got logged out');
    });
  } else if (response.status !== 401 && getState().auth.authenticated) {
    toastError('Something went wrong with your session, please log in again.');
    withScope((scope) => {
      scope.setExtra('response', JSON.stringify(response));
      scope.setExtra('original_error', JSON.stringify(e));
      captureMessage('unknown error in refresh');
    });
  }
}

const isAccessTokenExpired = (auth) => {
  if (auth.access?.exp) {
    const secondsSinceEpoch = new Date().getTime() / 1000;
    return auth.access.exp - secondsSinceEpoch < EXPIRY_MARGIN_IN_SECONDS;
  }

  return true;
};

const getAccessTokenTimeoutTime = (auth) => {
  const currentTimeInSeconds = new Date().getTime() / 1000;
  const timeDiff = auth.access.exp - currentTimeInSeconds;
  return timeDiff - EXPIRY_MARGIN_IN_SECONDS; // 10 seconds before it expires
};

export const login = (username, password) => async (dispatch) => {
  dispatch({ type: LOGIN.ACTION });

  const response = await fetch('/api/v2/auth/session/obtain/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  });

  let payload;
  try {
    payload = await response.json();
  } catch (e) {
    toastError('Service unavailable. Please try again later');
    return null;
  }

  if (response.status >= 400 && response.status < 500) {
    const errorText = formatLoginError(payload, response);

    return dispatch({
      type: LOGIN.ERROR,
      payload: errorText,
    });
  }

  const decoded = jwtDecode(payload.access);
  const userId = decoded.user_id;
  mixpanel.identify(userId);
  getCurrentScope().setUser({ id: userId });
  return dispatch({
    type: LOGIN.SUCCESS,
    payload: {
      token: payload.access,
      ...decoded,
    },
  });
};

export const logout = (client, skipRequest = false) => {
  client?.clearStore();

  if (tokenExpTimeout) clearTimeout(tokenExpTimeout);

  if (!skipRequest) {
    fetch('/api/v2/auth/session/logout/', {
      credentials: 'include',
      method: 'POST',
    });
  }

  return {
    type: SIGN_OUT.ACTION,
  };
};

const refreshAccessToken = () => async (dispatch, getState) => {
  /** Don't export refreshAccessToken, use getAccessToken which queues calls */
  dispatch({
    type: REFRESH_TOKEN.ACTION,
  });

  const response = await fetch('/api/v2/auth/session/refresh/', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  let payload;
  try {
    payload = await response.json();
    if (response.status !== 200) {
      throw new Error('Refresh unsuccessful');
    }
  } catch (e) {
    handleRefreshError(response, getState, e, dispatch);
    return dispatch({
      type: REFRESH_TOKEN.ERROR,
      payload: e?.message,
    });
  }

  const decoded = jwtDecode(payload.access);
  const userId = decoded.user_id;
  mixpanel.identify(userId);
  getCurrentScope().setUser({ id: userId });

  return dispatch({
    type: REFRESH_TOKEN.SUCCESS,
    payload: {
      token: payload.access,
      ...decoded,
    },
  });
};

export const getAccessToken = () => async (dispatch, getState) => {
  const { auth } = getState();

  if (!auth?.access?.exp || !auth?.access?.token) return undefined;

  if (auth.isRefreshing) {
    // something already called getAccessToken and is refreshing it, wait...
    await new Promise((resolve) => {
      setTimeout(resolve, 200);
    });
    return getAccessToken()(dispatch, getState);
  }

  if (tokenExpTimeout) clearTimeout(tokenExpTimeout);

  /*
   * If the user is idle, we preemptively renew the refresh token due to its short lived expiry.
   * TODO: If the user is not on the page for any reason for longer than the refresh token expire,
   * then the user is going to be logged out, since there is no valid JWT token to exchange with the server.
   * */
  tokenExpTimeout = setTimeout(
    () => getAccessToken()(dispatch, getState),
    Math.max(getAccessTokenTimeoutTime(auth), EXPIRY_MARGIN_IN_SECONDS * 1000),
  );

  if (isAccessTokenExpired(auth)) {
    const refreshedToken = await dispatch(refreshAccessToken());
    return refreshedToken.payload.token;
  }

  return auth.access.token;
};

export const isAuthenticated = ({ auth }) => auth.authenticated;
export const selectError = ({ auth }) => auth.error;

export const setLoginError = (error) => ({
  type: LOGIN.ERROR,
  payload: error,
});

export const setLoginSuccess = (access) => (dispatch) => {
  const decoded = jwtDecode(access);
  getCurrentScope().setUser({ id: decoded.user_id });

  return dispatch({
    type: LOGIN.SUCCESS,
    payload: {
      token: access,
      ...decoded,
    },
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.SUCCESS:
      return {
        access: {
          ...action.payload,
        },
        authenticated: true,
        error: null,
      };
    case LOGIN.ERROR:
      return {
        ...initialState,
        error: {
          type: action.type,
          message: action.payload,
        },
      };
    case REFRESH_TOKEN.ACTION:
      return {
        ...state,
        isRefreshing: true,
      };
    case REFRESH_TOKEN.SUCCESS:
      return {
        ...state,
        access: {
          ...action.payload,
        },
        error: null,
        authenticated: true,
        isRefreshing: false,
      };
    case REFRESH_TOKEN.ERROR:
      return {
        ...initialState,
        error: {
          type: action.type,
          message: action.payload,
        },
      };
    case SIGN_OUT.ACTION:
      return initialState;
    default:
      return state;
  }
};
