import cx from 'classnames';
import { useState } from 'react';

import { ChevronDown, ChevronRight } from 'frontend/assets/icons';
import { Icon, type IconColor, type IconHoverColor } from 'frontend/components/Icon/Icon';

import styles from './Filters.scss';

interface FilterAccordionProps {
  children: React.ReactNode;
  icon: React.ComponentType;
  iconClass?: string;
  iconColor?: IconColor;
  iconHoverColor?: IconHoverColor;
  title: string;
}

const FilterAccordion = ({
  children,
  title,
  icon: IconComponent,
  iconColor,
  iconHoverColor,
  iconClass,
}: FilterAccordionProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.accordion}>
      <div
        className={styles.accordionHeader}
        onClick={() => setOpen(!open)}
        onKeyDown={(e) => e.key === 'Enter' && setOpen(!open)}
        tabIndex={0}
        role="button"
        aria-expanded={open}
      >
        <Icon
          component={IconComponent}
          color={iconColor}
          hoverColor={iconHoverColor}
          className={iconClass}
          title={`${open ? 'Hide' : 'Show'} ${title} filter`}
        />
        <h4 className={styles.accordionTitle}>{title}</h4>
        <Icon component={open ? ChevronDown : ChevronRight} title={open ? 'Minimize' : 'Expand'} />
      </div>
      <div
        className={cx(styles.accordionContent, {
          [styles.accordionContentOpen]: open,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default FilterAccordion;
