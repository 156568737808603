import { gql } from '@apollo/client';

import LabelFragment from 'frontend/api/fragments/Label.fragment.graphql';

export default gql`
  mutation ActivateChatLabel($chatId: ID!, $labelId: ID!) {
    activateChatLabel(chatId: $chatId, labelId: $labelId) {
      id
      labels {
        ...Label
      }
    }
  }
  ${LabelFragment}
`;
