import { CACHE_TYPE } from 'frontend/features/Inbox/constants';
import { camelCaseKeys } from 'frontend/utils';

const EMPTY_PROFILE = {
  id: '',
  fullName: '',
  avatarUrl: '',
  alias: '',
  __typename: CACHE_TYPE.PROFILE_TYPE,
};

export default (agent) => {
  if (!agent) return null;

  return {
    ...camelCaseKeys({
      ...agent,
      profile: { ...EMPTY_PROFILE, ...camelCaseKeys(agent.profile) },
    }),
    __typename: CACHE_TYPE.USER_TYPE,
  };
};
