import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { DoughnutChart, InfoModal, Metric } from 'frontend/components/Stats';
import { Grid, StatsPanel } from 'frontend/features/Analytics/components';
import {
  baywatchRateToPieData,
  baywatchSeriesToLineData,
  baywatchSeriesToRateSeries,
  baywatchSeriesToValue,
  baywatchValuesToAverage,
  baywatchValuesToPieData,
  baywatchValuesToRate,
} from 'frontend/features/Analytics/utils/baywatch';
import { useModal } from 'frontend/features/Modals';

import LineChartPanel from './components/LineChartPanel';
import MetricWithDoughnutPanel from './components/MetricWithDoughnutPanel';
import SummaryPanel from './components/SummaryPanel';

const BaywatchOverview = ({ campaignType, campaignId, campaignData, granularity, loading }) => {
  const isNudge = campaignType === 'nudge';

  // Aggregated absolute values
  const conversions = useMemo(() => baywatchSeriesToValue(campaignData, 'conversions'), [campaignData]);
  const conversionsMobile = useMemo(() => baywatchSeriesToValue(campaignData, 'conversionsMobile'), [campaignData]);
  const conversionsDesktop = useMemo(() => baywatchSeriesToValue(campaignData, 'conversionsDesktop'), [campaignData]);
  const conversionValue = useMemo(() => baywatchSeriesToValue(campaignData, 'conversionValueInNok'), [campaignData]);
  const allClicks = useMemo(() => baywatchSeriesToValue(campaignData, 'allClicks'), [campaignData]);
  const abandonments = useMemo(() => baywatchSeriesToValue(campaignData, 'abandonments'), [campaignData]);
  const positiveClicks = useMemo(
    () => baywatchSeriesToValue(campaignData, isNudge ? 'positiveClicks' : 'emailsClicked'),
    [campaignData, isNudge],
  );
  const triggers = useMemo(
    () => baywatchSeriesToValue(campaignData, isNudge ? 'displays' : 'emailsSent'),
    [campaignData, isNudge],
  );
  const averageConversionValue = useMemo(
    () => baywatchValuesToAverage(conversionValue, conversions),
    [conversionValue, conversions],
  );

  // Linechart data
  const ordersValueChartData = useMemo(
    () => baywatchSeriesToLineData(campaignData, 'conversionValueInNok'),
    [campaignData],
  );
  const conversionsChartData = useMemo(() => baywatchSeriesToLineData(campaignData, 'conversions'), [campaignData]);
  const averageOrderValueChartData = useMemo(
    () => baywatchSeriesToRateSeries(campaignData, 'conversionValueInNok', 'conversions'),
    [campaignData],
  );
  const triggerToConversionRateChartData = useMemo(
    () => baywatchSeriesToRateSeries(campaignData, 'conversions', isNudge ? 'displays' : 'emailsSent'),
    [campaignData, isNudge],
  );

  // Rates
  const interactionRate = useMemo(() => baywatchValuesToRate(allClicks, triggers), [allClicks, triggers]);
  const positiveClickRate = useMemo(() => baywatchValuesToRate(positiveClicks, allClicks), [positiveClicks, allClicks]);
  const triggerToConversionRate = useMemo(() => baywatchValuesToRate(conversions, triggers), [conversions, triggers]);
  const captureRate = useMemo(() => baywatchValuesToRate(triggers, abandonments), [triggers, abandonments]);
  const clickThroughRate = useMemo(() => baywatchValuesToRate(positiveClicks, triggers), [positiveClicks, triggers]);
  const clickToConversionRate = useMemo(
    () => baywatchValuesToRate(conversions, positiveClicks),
    [conversions, positiveClicks],
  );

  // Doughnut chart data
  const interactionRateChartData = useMemo(() => baywatchRateToPieData(interactionRate), [interactionRate]);
  const positiveClickRateChartData = useMemo(() => baywatchRateToPieData(positiveClickRate), [positiveClickRate]);
  const captureRateChartData = useMemo(() => baywatchRateToPieData(captureRate), [captureRate]);
  const clickThroughRateChartData = useMemo(() => baywatchRateToPieData(clickThroughRate), [clickThroughRate]);
  const clickToConversionRateChartData = useMemo(
    () => baywatchRateToPieData(clickToConversionRate),
    [clickToConversionRate],
  );
  const mobileVsDesktopConversionsChartData = useMemo(
    () => baywatchValuesToPieData(conversionsMobile, conversionsDesktop),
    [conversionsMobile, conversionsDesktop],
  );

  const [showInfoModal] = useModal(InfoModal);

  const onInfoCallback = useCallback((title, content) => showInfoModal({ title, content }), [showInfoModal]);

  return (
    <>
      <SummaryPanel
        campaignId={campaignId}
        loading={loading}
        metrics={[
          { metric: conversionValue, text: 'Total order value', postfix: ' NOK' },
          { metric: averageConversionValue, text: 'Average order value', postfix: ' NOK' },
          { metric: conversions, text: 'Total completed orders' },
        ]}
      />
      <Grid>
        <Grid flow="row">
          <LineChartPanel
            title="Order value"
            loading={loading}
            granularity={granularity}
            chartData={ordersValueChartData}
            metrics={[{ metric: conversionValue, text: 'Order value', postfix: ' NOK' }]}
            onHeaderIconClick={() => onInfoCallback('Order value', 'Total value of completed orders in NOK.')}
            isPreview
          />
          <LineChartPanel
            title="Number of conversions"
            loading={loading}
            granularity={granularity}
            chartData={conversionsChartData}
            metrics={[{ metric: conversions, text: 'Total number of conversions' }]}
            onHeaderIconClick={() => onInfoCallback('Number of conversions', 'Total sum of completed orders.')}
            isPreview
          />
          <LineChartPanel
            title="Average order value"
            loading={loading}
            granularity={granularity}
            chartData={averageOrderValueChartData}
            onHeaderIconClick={() => onInfoCallback('Average order value', 'Average value of completed orders.')}
            isPreview
          />
        </Grid>
        <Grid flow="row">
          {isNudge && (
            <>
              <MetricWithDoughnutPanel
                title="Interactions"
                loading={loading}
                chartData={interactionRateChartData}
                absoluteMetric={{ metric: allClicks, text: 'Total interactions' }}
                rateMetric={{ metric: interactionRate, text: 'Interaction rate' }}
                onHeaderIconClick={() =>
                  onInfoCallback(
                    'Interactions',
                    'Total interactions represent sum of all nudge clicks. Interaction rate is all clicks divided by nudge displays.',
                  )
                }
              />
              <MetricWithDoughnutPanel
                title="Positive clicks"
                loading={loading}
                chartData={positiveClickRateChartData}
                absoluteMetric={{ metric: positiveClicks, text: 'Positive clicks' }}
                rateMetric={{ metric: positiveClickRate, text: 'Positive click rate' }}
                onHeaderIconClick={() =>
                  onInfoCallback(
                    'Positive clicks',
                    'Positive clicks represent sum of all nudge CTA clicks. Positive click rate is positive clicks divided by all clicks.',
                  )
                }
              />
            </>
          )}
          {!isNudge && (
            <>
              <MetricWithDoughnutPanel
                title="Emails sent"
                loading={loading}
                chartData={captureRateChartData}
                absoluteMetric={{ metric: triggers, text: 'Sent emails' }}
                rateMetric={{ metric: captureRate, text: 'Capture rate' }}
                onHeaderIconClick={() =>
                  onInfoCallback(
                    'Emails sent',
                    'Number of sent out emails. Capture rate represents percentage of these out of all abandoned sessions.',
                  )
                }
              />
              <MetricWithDoughnutPanel
                title="Positive clicks"
                loading={loading}
                chartData={clickThroughRateChartData}
                absoluteMetric={{ metric: positiveClicks, text: 'Positive clicks' }}
                rateMetric={{ metric: clickThroughRate, text: 'Click-through rate' }}
                onHeaderIconClick={() =>
                  onInfoCallback(
                    'Positive clicks',
                    'Positive clicks represent sum of all nudge CTA clicks. Positive click rate is positive clicks divided by all clicks.',
                  )
                }
              />
            </>
          )}
          <MetricWithDoughnutPanel
            title="Conversions"
            loading={loading}
            chartData={clickToConversionRateChartData}
            absoluteMetric={{ metric: conversions, text: 'Total conversions' }}
            rateMetric={{ metric: clickToConversionRate, text: 'Click to conversion rate' }}
            onHeaderIconClick={() =>
              onInfoCallback(
                'Conversions',
                'Sum of recoverd purchases considered as conversions. Click to conversion rate represents percentage of positive clicks out of all conversions.',
              )
            }
          />
          <StatsPanel
            loading={loading}
            title="Devices"
            onHeaderIconClick={() => onInfoCallback('Devices', 'Device distribution for recovered orders.')}
          >
            {(!!conversionsMobile || !!conversionsDesktop) && (
              <Grid columns={4} noMargin>
                <DoughnutChart data={mobileVsDesktopConversionsChartData} nested />
                <StatsPanel nested>
                  <Metric metric={conversionsMobile} topText="Mobile conversions" center={false} />
                </StatsPanel>
                <StatsPanel nested>
                  <Metric metric={conversionsDesktop} topText="Desktop conversions" center={false} />
                </StatsPanel>
              </Grid>
            )}
          </StatsPanel>
          <LineChartPanel
            title={isNudge ? 'Display to conversion rate' : 'Emails sent to conversion rate'}
            loading={loading}
            granularity={granularity}
            chartData={triggerToConversionRateChartData}
            metrics={[
              {
                metric: triggerToConversionRate,
                text: isNudge ? 'Average display to conversion rate' : 'Average email sent to conversion rate',
                postfix: '%',
              },
              {
                metric: triggers,
                text: isNudge ? 'Nudge displays' : 'Sent emails',
              },
            ]}
            onHeaderIconClick={() =>
              onInfoCallback(
                isNudge ? 'Display to conversion rate' : 'Emails sent to conversion rate',
                isNudge
                  ? 'Number of nudges displayed as well as percentage of these from all conversions.'
                  : 'Shows number of sent out emails as well as percentage of these from all conversions.',
              )
            }
            isPreview
            isRate
          />
        </Grid>
      </Grid>
    </>
  );
};

BaywatchOverview.propTypes = {
  campaignType: PropTypes.oneOf(['nudge', 'email']).isRequired,
  campaignId: PropTypes.string,
  campaignData: PropTypes.arrayOf(PropTypes.shape({})),
  granularity: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default BaywatchOverview;
