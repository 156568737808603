import { useQuery } from '@apollo/client';

import { AllMonthlyReportsDocument } from 'frontend/api/generated';

export function useAllReports(organizationId: string) {
  const { data, loading } = useQuery(AllMonthlyReportsDocument, {
    variables: { organizationId },
  });

  return {
    loading,
    organizationTimezone: data?.organization.timezone,
    meta: data?.organization.allMonthlyReports ?? [],
  };
}
