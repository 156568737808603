import { Navbar } from 'frontend/features';

import { useFullscreenLayout, useTabNotifications } from './hooks';

interface AuthenticatedLayoutProps {
  children: React.ReactNode;
}

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutProps) => {
  const fullScreenLayout = useFullscreenLayout();

  useTabNotifications();

  if (fullScreenLayout) {
    return <div>{children}</div>;
  }

  return (
    <main>
      <Navbar />
      {children}
    </main>
  );
};

export default AuthenticatedLayout;
