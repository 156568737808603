import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  BotMembershipDocument,
  BotPermissionsDocument,
  UpdateBotMembershipDocument,
  type UserType,
} from 'frontend/api/generated';
import { useIsRunning, useToast } from 'frontend/hooks';

import { MEMBERSHIP_TYPES } from '../../constants';
import { membershipRefetches } from '../../utils';
import UpdateMemberModal from '../AddMemberModal/UpdateMemberModal';
import { getUserName, roleIdsFromFields } from '../AddMemberModal/utils';

function UpdateBotMember({
  hide,
  args: { botId, user, organizationId, onBeforeSubmit },
}: {
  hide: (...args: unknown[]) => Promise<void>;
  args: {
    botId: string;
    organizationId: string;
    user: UserType;
    onBeforeSubmit?: () => unknown;
  };
}) {
  const toast = useToast();

  const { data, loading } = useQuery(BotMembershipDocument, {
    variables: { id: botId, userId: user.id },
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(BotPermissionsDocument);
  const refetchQueries = membershipRefetches(
    botId
      ? {
          botId,
          membershipType: MEMBERSHIP_TYPES.WORKSPACE,
        }
      : {
          organizationId,
          membershipType: MEMBERSHIP_TYPES.WORKSPACE,
        },
  );
  const [mutate] = useMutation(UpdateBotMembershipDocument, { refetchQueries });

  const allPermissions = permissionsData?.botPermissions ?? [];
  const roles = useMemo(() => data?.bot.roles ?? [], [data]);
  const currentRoles = useMemo(() => data?.bot.member.membership?.roles ?? [], [data]);
  const users = useMemo(() => {
    const u = data?.bot.member.user;
    if (!u) {
      return [] as UserType[];
    }
    return [u] as UserType[];
  }, [data]);
  const name = useMemo(() => data?.bot.name ?? '', [data]);

  const submit = useCallback(
    async (values) => {
      if (typeof onBeforeSubmit === 'function') {
        await onBeforeSubmit();
      }
      const roleIds = roleIdsFromFields(values);
      await mutate({ variables: { botId, userId: user.id, roleIds } });
      toast.success(`${getUserName(user, values.email)}'s ${name} membership was updated!`);
      hide();
    },
    [botId, hide, mutate, name, toast, user, onBeforeSubmit],
  );

  const [onSubmit, isSubmitting] = useIsRunning(submit);

  if (loading || permissionsLoading) {
    return null;
  }

  return (
    <UpdateMemberModal
      currentRoles={currentRoles}
      users={users}
      userId={user.id}
      email={user.username}
      hide={hide}
      name={name}
      roles={roles}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      allPermissions={allPermissions}
      disallowOrganizationRoles
      organizationId={organizationId}
    />
  );
}

export default UpdateBotMember;
