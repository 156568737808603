import cx from 'classnames';
import { NavLink, type To } from 'react-router-dom';

import { Accordion, Icon } from 'frontend/components';

import styles from './SubNav.scss';

interface SubNavProps {
  children: React.ReactNode;
  className?: string;
}

export default function SubNav({ children, className }: SubNavProps) {
  return <div className={cx(styles.container, className)}>{children}</div>;
}

interface ItemProps {
  children: React.ReactNode;
  to: To;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  className?: string;
}

function Item({ children, to, className, icon }: ItemProps) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cx(className, styles.item, {
          [styles.selected]: isActive,
        })
      }
    >
      <Icon component={icon} className={styles.icon} />
      {children}
    </NavLink>
  );
}

interface NavAccordionProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: React.ReactNode;
  children: React.ReactNode;
  to: To;
  className?: string;
}

function NavAccordion({ icon: AccIcon, title, children, to, className }: NavAccordionProps) {
  const AccordionIcon = <AccIcon className={styles.accordionIcon} />;

  return (
    <Accordion
      headerLeft={AccordionIcon}
      title={title}
      headerClassName={cx(styles.accordionHeader, className)}
      to={to}
      ignoreActive
    >
      {children}
    </Accordion>
  );
}

interface NavAccordionItemProps {
  children: React.ReactNode;
  to: To;
  className?: string;
}

function NavAccordionItem({ children, to, className }: NavAccordionItemProps) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cx(className, styles.item, {
          [styles.selected]: isActive,
        })
      }
    >
      <span className={styles.accordionItemIcon} />
      {children}
    </NavLink>
  );
}

SubNav.Item = Item;
SubNav.Accordion = NavAccordion;
SubNav.AccordionItem = NavAccordionItem;
