import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { useCurrentLanguage } from 'frontend/hooks';

import { GetNumberOfPatternsInOtherDialogues } from '../graphql';
import { dialogue } from '../validators';

export default ({ buildIdObject, dialogueId, parentId, isModDialogue, isIntentDialogue }) => {
  const [{ selectedLanguage, currentLanguage }] = useCurrentLanguage();
  const { data } = useQuery(GetNumberOfPatternsInOtherDialogues, {
    variables: { ...buildIdObject, languageCode: currentLanguage, dialogueId, parentId },
    skip: !dialogueId,
  });
  const numberOfPatternsInOtherDialogues = data?.numberOfPatternsInOtherDialogues ?? 0;

  const validate = useMemo(
    () =>
      dialogue({
        selectedLanguage,
        currentLanguage,
        isModDialogue,
        numberOfPatternsInOtherDialogues,
        isIntentDialogue,
      }),
    [selectedLanguage, currentLanguage, isModDialogue, numberOfPatternsInOtherDialogues, isIntentDialogue],
  );

  return validate;
};
