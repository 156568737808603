import { gql } from '@apollo/client';

import { SIMPLE_EVALUATION_FRAGMENT } from './fragments';

const CREATE_SIMPLE_EVALUATION = gql`
  mutation CreateEvaluationSet($botId: ID!, $languageCode: String!, $title: String!) {
    createSimpleEvaluation(botId: $botId, languageCode: $languageCode, title: $title) {
      ...SimpleEvaluation
    }
  }
  ${SIMPLE_EVALUATION_FRAGMENT}
`;
export default CREATE_SIMPLE_EVALUATION;
