import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';

import {
  useButtonTypes,
  useDateRanges,
  useFeedbackTypes,
  useGranularity,
  useLanguageCodes,
  useNudge,
  useSessionType,
  useSource,
} from 'frontend/features/Analytics/hooks';
import useMinMaxDates from 'frontend/features/Analytics/hooks/useMinMaxDates';
import { HideFilterType } from 'frontend/features/Analytics/propTypes';
import { useUrlSearchParams } from 'frontend/hooks';
import { ChildrenType } from 'frontend/propTypes';
import { mapToObject } from 'frontend/utils';

import styles from './Filters.scss';
import { FilterItems } from './components';
import useCampaignId from '../../hooks/useCampaignId';

const SUBSCRIPTION = {};

const FilterAnimation = ({ children, ...rest }) => {
  const variants = {
    show: {
      x: 0,
      transition: {
        ease: [0.2, 0.2, 0.2, 0.9],
        duration: 0.2,
      },
    },
    hide: {
      x: '-100%',
      transition: { duration: 0.2 },
    },
  };
  return (
    <motion.div variants={variants} {...rest}>
      {children}
    </motion.div>
  );
};
FilterAnimation.propTypes = {
  children: ChildrenType,
};

const arrayToBoolObject = mapToObject((item) => ({ [item]: true }));

export const urlParamsToFormValues = ({
  from,
  to,
  languageCodes,
  sources,
  buttonTypes,
  granularity,
  /* eslint-disable camelcase */
  nudge_id,
  campaign_id,
  /* eslint-enable camelcase */
  feedbacks,
  type,
}) => ({
  granularity,
  after: from,
  before: to,
  languageCodes: arrayToBoolObject(languageCodes),
  sources: sources.reduce((acc, currValue) => [...acc, ...currValue.split(',')], []),
  buttonTypes: arrayToBoolObject(buttonTypes),
  /* eslint-disable camelcase */
  nudge_id,
  campaign_id,
  /* eslint-enable camelcase */
  feedbacks: arrayToBoolObject(feedbacks),
  type,
});

// TODO: FIX this
// useMinMaxDates is triggered multiple times with the same values due to the sage state being set multiple times by resolving the promise
// this causes the Form to re-render for each sage reset, which in other hand causes the DatePicker to mount and unmount
// which resets its state, and thus causes a flickering effect
// This optimiziation does not allow for the form to be reset
const FormRenderer = (props) => <Form {...props} component={FilterItems} subscription={SUBSCRIPTION} />;

const arePropsEqual = (prevProps, nextProps) =>
  (prevProps.filters.minDate === nextProps.filters.minDate ||
    prevProps.filters.maxDate === nextProps.filters.maxDate) &&
  (prevProps.filters.before === nextProps.filters.before || prevProps.filters.after === nextProps.filters.after);

const FormRendererOptimized = React.memo(FormRenderer, arePropsEqual);

const Filters = ({
  hideFilterView,
  filterViewShowing,
  hideFilter,
  filterOnButtonTypes,
  platformAnalytics = false,
  didSubmit = () => undefined,
}) => {
  const [, setParams] = useUrlSearchParams();
  const { from, to, toParams: toDateRangesParam } = useDateRanges();
  const [languageCodes, toLanguageCodesParam] = useLanguageCodes();
  const [granularity, toGranularityParam] = useGranularity();
  const [sources] = useSource();

  const [feedbacks, toFeedbackTypeParam] = useFeedbackTypes();
  const [buttonTypes, toButtonTypesParam] = useButtonTypes();
  const { minDate, maxDate } = useMinMaxDates();
  // eslint-disable-next-line camelcase
  const [nudge_id, toNudgeParam] = useNudge();
  // eslint-disable-next-line camelcase
  const [campaign_id, toCampaignIdParam] = useCampaignId();
  const [type, toTypeParam] = useSessionType();

  const initialValues = useMemo(
    () => ({
      ...urlParamsToFormValues({
        from,
        to,
        languageCodes,
        sources,
        buttonTypes,
        granularity,
        feedbacks,
        /* eslint-disable camelcase */
        nudge_id,
        campaign_id,
        /* eslint-enable camelcase */
        type,
      }),
      minDate,
      maxDate,
    }),
    // eslint-disable-next-line camelcase
    [
      buttonTypes,
      /* eslint-disable camelcase */
      nudge_id,
      campaign_id,
      /* eslint-enable camelcase */
      from,
      granularity,
      languageCodes,
      sources,
      to,
      minDate,
      maxDate,
      feedbacks,
      type,
    ],
  );

  const onSubmit = useCallback(
    ({
      after,
      before,
      datePicker,
      granularity: granularityFilter,
      languageCodes: languageCodesFilter,
      sources: sourceFilters,
      buttonTypes: buttonTypeFilters,
      nudge_id: nudgeFilter,
      campaign_id: campaignIdFilter,
      feedbacks: ratingFilter,
      type: sessionTypeFilter,
    }) => {
      const urlSearchParams = {
        ...toLanguageCodesParam(languageCodesFilter),
        sources: sourceFilters.join('-'),
        ...toButtonTypesParam(buttonTypeFilters),
        ...toDateRangesParam(
          {
            before: datePicker?.[0]?.endDate || before,
            after: datePicker?.[0]?.startDate || after,
          },
          toGranularityParam(granularityFilter),
        ),
        ...toCampaignIdParam(campaignIdFilter),
        ...(!hideFilter?.nudges ? toNudgeParam(nudgeFilter) : {}),
        ...(!hideFilter?.feedbacks ? toFeedbackTypeParam(ratingFilter) : {}),
        ...(!hideFilter?.sessionTypes ? toTypeParam(sessionTypeFilter) : {}),
      };

      setParams(urlSearchParams, { merge: false });
      if (didSubmit) didSubmit();
      hideFilterView();
    },
    [
      didSubmit,
      hideFilterView,
      setParams,
      toButtonTypesParam,
      toDateRangesParam,
      toGranularityParam,
      toLanguageCodesParam,
      toNudgeParam,
      toCampaignIdParam,
      toFeedbackTypeParam,
      toTypeParam,
      hideFilter,
    ],
  );

  return (
    <FilterAnimation animate={filterViewShowing ? 'show' : 'hide'} className={`${styles.animated}`} initial={false}>
      <FormRendererOptimized
        onSubmit={onSubmit}
        initialValues={initialValues}
        hideFilterView={hideFilterView}
        filterViewShowing={filterViewShowing}
        hideFilter={hideFilter}
        filters={{ before: initialValues?.before, after: initialValues?.after, minDate, maxDate }}
        filterOnButtonTypes={filterOnButtonTypes}
        platformAnalytics={platformAnalytics}
      />
    </FilterAnimation>
  );
};

Filters.propTypes = {
  filterViewShowing: PropTypes.bool.isRequired,
  hideFilterView: PropTypes.func.isRequired,
  hideFilter: HideFilterType,
  filterOnButtonTypes: PropTypes.bool,
  platformAnalytics: PropTypes.bool,
  didSubmit: PropTypes.func,
};

export default Filters;
