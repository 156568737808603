import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { softDeleteInCache } from 'frontend/api/cacheHelpers';
import BOT_FRAGMENT from 'frontend/api/fragments/Bot.fragment.graphql';
import {
  DeleteBotDocument,
  OrganizationBotsDocument,
  OrganizationMetaDocument,
  OrganizationSkillsDocument,
} from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import { useMixpanel, useToast } from 'frontend/hooks';

const cacheUpdate = softDeleteInCache({
  fragment: BOT_FRAGMENT,
  typeName: 'BotType',
  mutationName: 'deleteBot',
  fragmentName: 'Bot',
});

interface DeleteBotProps {
  hide(): Promise<void>;
  args: {
    name?: string;
    botId: string;
    organizationId: string;
  };
}

const DeleteBot = ({ hide, args: { botId, name, organizationId } }: DeleteBotProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { mixpanel } = useMixpanel();
  const [deleteBotMutation] = useMutation(DeleteBotDocument, {
    variables: { botId },
    update: (cache, result) => {
      toast.success(`${name} was deleted`);
      mixpanel.track('Delete bot', { botId, organizationId });
      cacheUpdate(cache, result);
      navigate(`/organization/${organizationId}/workspaces`);
    },
    refetchQueries: [OrganizationMetaDocument, OrganizationBotsDocument, OrganizationSkillsDocument],
  });

  return (
    <DeleteModal titleName={`workspace ${name}`} name={name} onSubmit={deleteBotMutation} hide={hide} confirmName />
  );
};

export default DeleteBot;
