import cx from 'classnames';
import { Link, useParams } from 'react-router-dom';

import { confidenceScore, stripHtmlTags } from 'frontend/utils';

import styles from '../Evaluation.scss';
import { EvaluationQueryResultType } from '../propTypes';

const DetailedDialogueResult = ({ result }) => {
  const { botId } = useParams();

  const predictorReplyLink = result.predictorReplyDialogue
    ? `/workspace/${botId}/build/dialogue/${result.predictorReplyDialogue.id}`
    : null; // FIXME: what about skill subscriptions?

  const statusStyles = cx(styles.status, {
    [styles.statusAll]: result.predictorMatchesId,
    [styles.statusNone]: !result.predictorMatchesId,
  });
  return (
    <div key={result.id} className={styles.detailedDialogueResult}>
      <div className={styles.header}>
        <div className={statusStyles} />
        {result.userSays}{' '}
        {result.predictorMatchesId && !result.isKeyword && (
          <span className={styles.confidenceScoreMatch}>{confidenceScore(result.predictorScore)}</span>
        )}
        {result.predictorMatchesId && result.isKeyword && <span className={styles.confidenceScoreMatch}>KEYWORD</span>}
      </div>
      {!result.predictorMatchesId && result.predictorMatchesReply && (
        <div className={styles.showErrors}>
          <span className={styles.predictorReply}>
            <span className={styles.explanation}>
              {predictorReplyLink ? <Link to={predictorReplyLink}>Actual reply</Link> : 'Actual reply'}
            </span>
            Bot replied as expected, but it hit a different dialogue
          </span>
        </div>
      )}
      {!result.predictorMatchesId && !result.predictorMatchesReply && (
        <div className={styles.showErrors}>
          <span className={styles.dialogueReply}>
            <span className={styles.explanation}>Expected reply</span>
            {`${stripHtmlTags(result.dialogueReply)}`}
          </span>
          <span className={styles.predictorReply}>
            <span className={styles.explanation}>
              {predictorReplyLink ? <Link to={predictorReplyLink}>Actual reply</Link> : 'Actual reply'}
            </span>
            {result.predictorReplyIsFallback ? <strong>Fallback</strong> : stripHtmlTags(result.predictorReply)}
            {!result.predictorMatchesId && !result.predictorReplyIsFallback && (
              <span className={styles.confidenceScoreMismatch}>{confidenceScore(result.predictorScore)}</span>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

DetailedDialogueResult.propTypes = {
  result: EvaluationQueryResultType,
};

export default DetailedDialogueResult;
