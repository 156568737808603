import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import React, { useMemo, useState } from 'react';
import { type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import {
  ChatbubbleSettingsDocument,
  FeedbackSettingsForBotDocument,
  MutateFeedbackFormsV2Document,
} from 'frontend/api/generated';
import { Plus } from 'frontend/assets/icons';
import { Button, Panel, SelectLanguage } from 'frontend/components';
import PageBarRHF from 'frontend/components/PageBar/PageBarRHF';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import useBotOrSkill from 'frontend/hooks/useBotOrSkill';
import useCurrentLanguage from 'frontend/hooks/useCurrentLanguage';
import useLanguages from 'frontend/hooks/useLanguages';
import getArrayUpdateV2 from 'frontend/utils/getArrayUpdateV2';
import { getContrastColor } from 'frontend/utils/getContrastColor';

import styles from './CustomerFeedback.scss';
import ManageFormCard from './ManageForm/ManageFormCard';
import NewFeedbackForm from './NewForm/NewFeedbackForm';
import { type FeedbackForm, RHF_FORMS_NAME, createNewForm } from './constants';
import shared from './shared.scss';

export interface CustomerFeedbackInputs {
  id: string;
  [RHF_FORMS_NAME]: FeedbackForm[];
}

export default function CustomerFeedback() {
  const { botId = '' } = useParams<{ botId: string }>();
  const [formIndexToEdit, setFormIndexToEdit] = useState<null | number>(null);
  const { data } = useQuery(ChatbubbleSettingsDocument, { variables: { botId } });

  const [{ selectedLanguage }] = useCurrentLanguage();
  const botOrSkillParams = useBotOrSkill();
  const { languages } = useLanguages(botOrSkillParams);

  const { data: feedbackSettings, loading: loadingFeedbackSettings } = useQuery(FeedbackSettingsForBotDocument, {
    variables: { botId, languageCode: selectedLanguage },
  });
  const [mutateFeedbackForms] = useMutation(MutateFeedbackFormsV2Document);

  const getFormPath = (index: number): `${typeof RHF_FORMS_NAME}.${number}` => `${RHF_FORMS_NAME}.${index}`;

  const rhfMethods = useForm<CustomerFeedbackInputs>({
    values: {
      ...(feedbackSettings?.feedbackSettingsForBot as CustomerFeedbackInputs),
    }, // We use `as` because it's gonna be `undefined` only when the query is loading, which won't display any UI anyway
  });

  const primaryButtonBackgroundColor = data?.chatbubbleSettings?.colorButtonBackground || '#ffffff';
  const primaryButtonTextColor = data?.chatbubbleSettings?.colorButtonText || '#000000';
  const panelBackgroundColor = data?.chatbubbleSettings?.colorPanelBackground || '#ffffff';
  const secondaryButtonBackgroundColor = data?.chatbubbleSettings?.colorSecondaryButtonBackground || '#ffffff';
  const panelTextColor = useMemo(
    () => getContrastColor(panelBackgroundColor, '#ffffff', '#000000'),
    [panelBackgroundColor],
  );
  const secondaryButtonTextColor = useMemo(
    () => getContrastColor(secondaryButtonBackgroundColor, '#ffffff', '#000000'),
    [secondaryButtonBackgroundColor],
  );

  const {
    control,
    handleSubmit,
    formState: { errors: _errors, isDirty, isSubmitting, defaultValues },
    reset,
  } = rhfMethods;

  const clearRHFChanges = () => {
    reset();
  };

  const { fields, remove, append } = useFieldArray({
    control,
    name: RHF_FORMS_NAME,
  });

  const onSubmit: SubmitHandler<CustomerFeedbackInputs> = async (formValues) => {
    const { created, updated, deleted } = getArrayUpdateV2<FeedbackForm>(
      defaultValues![RHF_FORMS_NAME] as FeedbackForm[],
      formValues[RHF_FORMS_NAME],
    );

    const variables = {
      botId,
      languageCode: selectedLanguage,
      create: created.map(({ id: _id, ...restForm }) => ({ ...restForm })),
      update: updated,
      delete: deleted.map(({ id }) => id),
    };

    const mutatedFeedbackSettings = await mutateFeedbackForms({
      variables,
      // We do some automatic creation of new forms for other languages, so we want to refetch the settings for the other languages
      update: (cache, { data: mutatedData }) => {
        const updatedSettings = mutatedData?.mutateFeedbackForms;
        if (updatedSettings && variables.create.length) {
          cache.modify({
            fields: {
              feedbackSettingsForBot: (existingSettings, { readField }) => {
                if (readField('id', existingSettings) !== updatedSettings.id) {
                  return undefined;
                }
                return existingSettings;
              },
            },
          });
        }
      },
    });

    reset(mutatedFeedbackSettings.data!.mutateFeedbackForms as CustomerFeedbackInputs);
  };

  const addNewForm = () => {
    const newForm = createNewForm();
    append(newForm);
    setFormIndexToEdit(fields.length);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={
          {
            '--panelBackgroundColor-1729529624': panelBackgroundColor,
            '--panelTextColor-1729529624': panelTextColor,
            '--secondaryButtonBackgroundColor-1729529624': secondaryButtonBackgroundColor,
            '--secondaryButtonTextColor-1729529624': secondaryButtonTextColor,
            '--primaryButtonBackgroundColor-1729529624': primaryButtonBackgroundColor,
            '--primaryButtonTextColor-1729529624': primaryButtonTextColor,
          } as React.CSSProperties
        }
      >
        <PageBarRHF>
          <PageBarRHF.FormButtons isDirty={isDirty} reset={clearRHFChanges} isSubmitting={isSubmitting} />
        </PageBarRHF>

        <Panel>
          {typeof formIndexToEdit === 'number' ? (
            <NewFeedbackForm
              rhfMethods={rhfMethods}
              rhfFormPath={getFormPath(formIndexToEdit)}
              setFormIndexToEdit={setFormIndexToEdit}
            />
          ) : (
            <>
              <section>
                <div className={styles.headerSection}>
                  <div className={styles.headingWrapper}>
                    <h3 className={shared.heading}>Customer feedback </h3>
                    <SelectLanguage languages={languages} supportVariants={false} />
                  </div>
                  <p>
                    Create and manage your customer feedback forms. They will appear to users when the conversation with
                    the bot or a human agent has ended.
                  </p>
                  <p>
                    Each form is unique per language, so remember to check your edits cross language if you have more
                    than one.
                  </p>
                  <p>
                    Read more details on our <HelpCenterLink path="/customer-feedback">help center</HelpCenterLink>.
                  </p>
                  <p>
                    <strong>Note:</strong> The feedback form will not appear when testing in the platform. But hey, go
                    to the{' '}
                    <Link className="link" to="../demo-page">
                      demo page
                    </Link>{' '}
                    and test it there instead!
                  </p>
                  <Button
                    icon={Plus}
                    color="white"
                    onClick={addNewForm}
                    className={cx({ [styles.noPointerEvents]: loadingFeedbackSettings })}
                  >
                    New feedback form
                  </Button>
                </div>
              </section>

              <section className={styles.manageSection}>
                {!loadingFeedbackSettings &&
                  fields.map((feedbackForm, index) => {
                    const isFirst = index === 0;
                    const isLast = index === fields.length - 1;

                    return (
                      <React.Fragment key={feedbackForm.id}>
                        {isFirst && <Panel.Separator />}
                        <ManageFormCard
                          rhfFormPath={getFormPath(index)}
                          rhfMethods={rhfMethods}
                          formIndex={index}
                          removeFieldArray={remove}
                          setFormIndexToEdit={setFormIndexToEdit}
                        />
                        {!isLast && <Panel.Separator />}
                      </React.Fragment>
                    );
                  })}
              </section>
            </>
          )}
        </Panel>
      </form>
    </div>
  );
}
