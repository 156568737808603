import { addDays, format, setHours, setMinutes } from 'date-fns';
import { useFormState } from 'react-final-form';

import { Plus } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import type { LanguageAugmented } from 'frontend/hooks/useLanguages';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import { DeepLearningFormFields, TrainModelModal } from '.';
import styles from '../Train.scss';

interface TrainPageContentProps {
  languages: LanguageAugmented[];
  currentLanguage: string;
  botId: string;
}

function TrainPageContent({ languages, currentLanguage, botId }: TrainPageContentProps) {
  const { values, dirty } = useFormState();
  const [showTrainModelModal] = useModal(TrainModelModal);

  const nextTrainingTime = setMinutes(setHours(addDays(new Date(), 1), 3), 0);

  return (
    <>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Deep Learning</h3>
      <p>
        This is a deep learning tool that requires model training. Training a new model might take a while depending on
        the selected parameters.
      </p>

      <DeepLearningFormFields languages={languages} currentLanguage={currentLanguage} />

      {values.zodiacSetting.trainDaily && (
        <p className="m-t-1">
          The next daily training is scheduled for {format(nextTrainingTime, DATE_FORMAT_WITH_TIME)}
        </p>
      )}

      {values.zodiacSetting.enabled && (
        <div className={styles.actionPanel}>
          <Button
            icon={Plus}
            color="primary"
            onClick={() => showTrainModelModal({ botId, enabledLanguages: languages })}
            className={styles.trainButton}
            disabled={dirty}
          >
            Train
          </Button>
        </div>
      )}
    </>
  );
}

export default TrainPageContent;
