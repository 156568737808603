import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { BotLanguageSettingsDocument } from 'frontend/api/generated';
import { Input, Select } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';
import { IDType } from 'frontend/propTypes';

import { CREATE_EVALUATION_SET } from '../graphql';
import { EVALUATION_FRAGMENT } from '../graphql/fragments';

const CreateEvaluation = ({ hide, args: { botId } }) => {
  const navigate = useNavigate();

  const { data, loading } = useQuery(BotLanguageSettingsDocument, {
    variables: { botId },
  });
  const botLanguages = (data?.bot?.languages ?? []).filter(({ active }) => active);

  const update = (cache, { data: { createEvaluation } }) => {
    const newRef = cache.writeFragment({ data: createEvaluation, fragment: EVALUATION_FRAGMENT });
    cache.modify({
      fields: {
        evaluations: (currentRefs = []) => [...currentRefs, newRef],
      },
    });
    navigate(`/workspace/${botId}/build/evaluation/${createEvaluation.id}`);
  };

  const [mutate] = useMutation(CREATE_EVALUATION_SET, { update });
  const onSubmit = async ({ title, languageCode }) => {
    await mutate({ variables: { botId, languageCode, title } });
  };

  if (loading) {
    return null;
  }

  const initialValues = {
    languageCode: botLanguages[0].code,
  };
  return (
    <ModalForm
      onSubmit={onSubmit}
      hide={hide}
      initialValues={initialValues}
      title="Create evaluation set"
      onOkText="Create"
    >
      <Field
        component={Input}
        name="title"
        aria-label="Title"
        autoFocus
        placeholder="Evaluation title"
        inputLimit={300}
        validate={chain([required, max(300)])}
      />
      <br />
      <Field component={Select} name="languageCode">
        {botLanguages.map((lang) => (
          <Select.Option key={`lang${lang.code}`} value={lang.code} label={lang.name} />
        ))}
      </Field>
    </ModalForm>
  );
};

CreateEvaluation.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ botId: IDType.isRequired }).isRequired,
};

export default CreateEvaluation;
