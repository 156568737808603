import { useQuery } from '@apollo/client';
import { capitalize, isEmpty, snakeCase } from 'lodash/fp';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type { ButtonType, DialogueType } from 'frontend/api/generated';
import { Table } from 'frontend/components';
import type { Row } from 'frontend/components/Table/Table';
import { AnalyticsContent, Grid, Heading } from 'frontend/features/Analytics/components';
import { useButtonTypes, useFilters, useSageData } from 'frontend/features/Analytics/hooks';
import { BUTTONS } from 'frontend/features/Analytics/queries';
import Filters, { FilterInfo } from 'frontend/features/Analytics/views/Filters';
import CSVDownloadButton from 'frontend/features/Analytics/views/Sessions/components/CSVDownloadButton/CSVDownloadButton';
import { useBooleanState } from 'frontend/hooks';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';
import { snakeToHumanReadable } from 'frontend/utils';

import Action from './Action';
import styles from './ButtonClicks.scss';
import Dialogue from './Dialogue';
import Label from './Label';
import PercentageBar from './PercentageBar';
import { getButtonIds, getTotalClicks } from './utils';

type ButtonsTableRow = {
  label: { label: string; className?: string };
  dialogue: { dialogue: DialogueType; languageCode: string; botId: number };
  clicks: { ratio: number; count: number; className?: string };
  type: string;
  action: { button: ButtonType; botId: number };
};

const N_BUTTONS = 30;
const FILTER_EXTRA = { limit: N_BUTTONS };
const HIDE_FILTERS = { nudges: true, engagementCampaigns: true };
const SKIP = Object.keys(HIDE_FILTERS);

const columns = [
  { key: 'clicks', component: PercentageBar },
  { key: 'type' },
  { key: 'label', component: Label },
  { key: 'dialogue', component: Dialogue },
  { key: 'action', component: Action },
];

function getButtonData({ botId, button, ratio, count }): ButtonsTableRow {
  let buttonType = capitalize(snakeToHumanReadable(button.buttonType));
  if (button.buttonType === 'takeover_request') {
    buttonType = 'Handover request';
  }

  return {
    label: { label: button.label, className: styles.labelDetail },
    dialogue: {
      dialogue: button.dialogue ?? button.dialogueMod?.modDialogue,
      languageCode: button.languageCode,
      botId,
    },
    clicks: { ratio, count, className: styles.triggeredCell },
    type: buttonType,
    action: { button, botId },
  };
}

function tableDataToCSV(data: ButtonsTableRow[]) {
  return data.map(({ label, dialogue, clicks, type, action }) => ({
    clicks: clicks.count,
    ratio: clicks.ratio,
    type,
    label: label.label,
    dialogue: dialogue?.dialogue?.title.default ?? '?',
    action: action.button.label,
  }));
}

// Button types may have been changed after they were clicked
function hasCorrectButtonType(
  { buttonTypes }: { buttonTypes: string[] },
  { buttonType }: { buttonType?: string },
): boolean {
  return isEmpty(buttonTypes) || Boolean(buttonType && buttonTypes.includes(snakeCase(buttonType)));
}

const ButtonClicksDetail = () => {
  const { botId } = useParams();
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const [buttonTypes] = useButtonTypes();
  const commonFilters = useFilters({ extra: FILTER_EXTRA, skip: SKIP });

  const filters = useMemo(() => ({ ...commonFilters, buttonTypes }), [buttonTypes, commonFilters]);
  const { loading, error, data } = useSageData(sageScope(SAGE_RESOURCE.BOT, botId), '/buttons/most_clicked', filters, {
    filterOnButtonTypes: true,
  });

  const { data: buttonData, loading: buttonsLoading } = useQuery<{ buttons: ButtonType[] }>(BUTTONS, {
    variables: { botId, buttonIds: getButtonIds(data), includeDialogue: true },
    skip: loading || Boolean(error),
  });

  const tableData: ButtonsTableRow[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data
      .map(({ button_id: buttonId, ratio, count }) => {
        const button = (buttonData?.buttons ?? []).find(({ id }) => id === buttonId);
        if (!button || !hasCorrectButtonType(filters, button)) {
          return undefined;
        }
        return getButtonData({ botId, button, ratio, count });
      })
      .filter((item): item is ButtonsTableRow => Boolean(item));
  }, [botId, buttonData, data, filters]);

  return (
    <>
      <Filters
        hideFilterView={hideFilterView}
        filterViewShowing={filterViewShowing}
        hideFilter={HIDE_FILTERS}
        filterOnButtonTypes
      />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} filterOnButtonTypes />
        <div>
          <Heading
            title="Button clicks"
            subtitle={
              <Grid columns={2}>
                <>
                  Showing number of clicks for the {tableData.length} most used buttons. Total number of clicks in
                  period:
                  {getTotalClicks(data) || 0}
                </>
                {!loading && (
                  <div className={styles.downloadButtonWrapper}>
                    <CSVDownloadButton filename="button_clicks.csv" data={tableDataToCSV(tableData)} color="white" />
                  </div>
                )}
              </Grid>
            }
          />

          <Grid columns={1}>
            <Table
              columns={columns}
              data={tableData as Row[]}
              className={styles.table}
              loading={loading || buttonsLoading}
            />
          </Grid>
        </div>
      </AnalyticsContent>
    </>
  );
};

export default ButtonClicksDetail;
