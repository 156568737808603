import cx from 'classnames';
import { useCallback } from 'react';

import { Drag, Edit, Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { useSortableItem } from 'frontend/features/Dnd/hooks';

import styles from './EditableIcon.scss';

type ButtonType = Record<string, any>;

interface EditableIconProps {
  button: ButtonType;
  setEditingButton: (button: ButtonType) => void;
  removeByIndex: () => void;
  isSorting: boolean;
  children: React.ReactChild | React.ReactChild[];
  style?: Record<string, any>;
  className?: string;
}

const EditableIcon = ({
  button,
  setEditingButton,
  removeByIndex,
  isSorting,
  children,
  style: additionalStyle,
  className,
}: EditableIconProps) => {
  const { style, dragAttributes, onPointerDown, setNodeRef } = useSortableItem(button);

  const remove = useCallback(
    (event) => {
      removeByIndex();
      event.stopPropagation();
    },
    [removeByIndex],
  );

  const edit = useCallback(() => setEditingButton(button), [button, setEditingButton]);

  return (
    <div
      ref={setNodeRef}
      style={{ ...style, ...additionalStyle }}
      aria-describedby={dragAttributes['aria-describedby']}
      aria-pressed={dragAttributes['aria-pressed']}
      aria-roledescription={dragAttributes['aria-roledescription']}
      tabIndex={dragAttributes.tabIndex}
      className={cx(styles.buttonWrapper, className)}
      role={dragAttributes.role}
      translate="no"
    >
      <div
        className={cx(styles.button, {
          [styles.buttonDragging]: isSorting,
        })}
      >
        {children}
      </div>
      <span className={styles.options}>
        <span className={styles.optionsButtons}>
          <Icon className={styles.optionsButtonsItem} component={Edit} color="black" onClick={edit} />
          <Icon
            className={`${styles.optionsButtonsItem} ${styles.dragHandle}`}
            component={Drag}
            color="black"
            onPointerDown={onPointerDown}
          />
          <Icon className={styles.optionsButtonsItem} component={Trash} color="warning" onClick={remove} />
        </span>
      </span>
    </div>
  );
};

export default EditableIcon;
