import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Checkbox, Condition, Input, Select } from 'frontend/components';

import styles from './styles.scss';
import { SCOPE } from '../../constants';

const applyName = (name, field) => (name ? `${name}.${field}` : field);

const scopeValueToName = (value) => {
  if (value === SCOPE.GLOBAL) return 'Anywhere';
  if (value === SCOPE.LAST_MESSAGE) return 'Last user message';
  return capitalize(value).replace(/_/g, ' ');
};

const SlotFields = ({ name }) => (
  <>
    <Field
      name={applyName(name, 'required')}
      component={Checkbox}
      label="Required (only match dialogue if this slot is filled)"
      type="checkbox"
    />
    <Condition when={applyName(name, 'slot.entity')} isNot="">
      <Field name={applyName(name, 'exactMatch')} component={Checkbox} label="Exact matches only" type="checkbox" />
    </Condition>
    <label htmlFor={`${name}-scope`} className={styles.scope}>
      Use slot filled in
      <Field name={applyName(name, 'scope')} component={Select} id={`${name}-scope`}>
        {Object.values(SCOPE).map((value) => (
          <option key={`slot-scope-${value}`} value={value}>
            {scopeValueToName(value)}
          </option>
        ))}
      </Field>
    </label>
    <Field
      name={applyName(name, 'prompt')}
      component={Input}
      label="Prompt"
      placeholder="Ask user when slot is not filled (optional)"
    />
  </>
);

SlotFields.propTypes = {
  name: PropTypes.string,
};

export default SlotFields;
