import cx from 'classnames';
import React from 'react';

import styles from './Panel.scss';
import Separator from './Separator';

type HeaderProps = {
  children?: string | React.JSX.Element | React.JSX.Element[];
  className?: string;
};

const Header = ({ children, className }: HeaderProps) => (
  <div className={styles.panelHeader}>
    <div className={cx(styles.panelHeaderContainer, className)}>{children}</div>
    <Separator className={styles.headerSeparator} />
  </div>
);

export default Header;
