import cx from 'classnames';

import styles from './Blob.scss';

interface BlobProps {
  color?: string;
  className?: string;
  size?: number;
}

export const Blob = ({ className, color = '#3cd01e', size = 36 }: BlobProps) => {
  const blobStyles = cx(styles.circle, className);
  return <div className={blobStyles} style={{ backgroundColor: color, width: size, height: size }} />;
};
