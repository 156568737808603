import { get } from 'lodash';

import { getArrayFieldUpdate } from 'frontend/utils';

const relevantFields = ['required', 'exactMatch', 'scope', 'prompt', 'description', 'slot'];

export default (initialValues, values) => {
  const name = 'outputSlots';
  const initial = get(initialValues, name, {});
  const current = get(values, name, {});

  const { created, updated, deleted } = getArrayFieldUpdate(initial, current, relevantFields);

  return { createdOutputSlots: created, deletedOutputSlots: deleted, updatedOutputSlots: updated };
};
