import { useQuery } from '@apollo/client';
import { formatISO } from 'date-fns';
import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import { GcsInboxBackupSettingsDocument, type GcsInboxBackupSettingsQuery } from 'frontend/api/generated';
import { Button, Input, Loader, ToggleSwitch } from 'frontend/components';
import { required } from 'frontend/form/validators';

import styles from './styles.scss';
import useOnSubmit from './useOnSubmit';

export default function GcsInboxBackup({ botId }: { botId: string }) {
  const { data, loading } = useQuery(GcsInboxBackupSettingsDocument, { variables: { botId } });
  const gcsBackupSettings = useMemo<Partial<GcsInboxBackupSettingsQuery['gcsBackupSettings']>>(
    () => data?.gcsBackupSettings ?? {},
    [data],
  );

  const initialValues = useMemo(
    () => ({
      googleApplicationCredentials: '',
      bucketName: '',
      gcsEnabled: gcsBackupSettings?.enabled ?? false,
      ...gcsBackupSettings,
    }),
    [gcsBackupSettings],
  );

  const { onSubmit, isSubmitting, bucketUpdateSuccessful } = useOnSubmit({ botId, initialValues });

  if (loading) return <Loader size="large" noMargin />;

  const date = formatISO(new Date(), { representation: 'date' });
  const previewPath = ({ bucketName, pathPrefix }) => {
    if (pathPrefix) {
      return `gcs://${bucketName}/${pathPrefix}/${date}/<file_name>.json`;
    }

    return `gcs://${bucketName}/${date}/<file_name>.json`;
  };

  return (
    <div className={styles.container}>
      <h3>
        Raw data dump to Google Cloud Storage bucket
        {gcsBackupSettings?.lastRunWasSuccess === true && (
          <span className={styles.status} role="img" aria-label="Checkmark emoji">
            ✅️
          </span>
        )}
        {gcsBackupSettings?.lastRunWasSuccess === false && (
          <span className={styles.status} role="img" aria-label="X emoji">
            ❌️
          </span>
        )}
      </h3>

      {gcsBackupSettings?.lastSuccessfulRun && (
        <div>
          Last dump was sent {formatISO(gcsBackupSettings?.lastSuccessfulRun)}.
          {gcsBackupSettings?.lastRunWasSuccess === false && (
            <div className={styles.failMessage}>Last run failed, please check credentials</div>
          )}
        </div>
      )}

      <p>
        You can schedule to receive daily dumps from us. This is useful if you e.g. want to routinely analyze your
        message data, or feed it to your own logging systems. For this you will need Google Cloud Platform and a Cloud
        Storage Bucket.
      </p>
      <p>
        <a className="link" href="https://docs.kindly.ai/guides-and-examples/gcs-dump">
          Check out our docs on how to setup permissions for Kindly.
        </a>
      </p>

      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values, dirty }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className={styles.toggleEnabled}>
              Enable daily backup <ToggleSwitch className="m-r-md" name="gcsEnabled" />
            </div>
            {Boolean(values.gcsEnabled) && (
              <>
                <div className={styles.gcssettings}>
                  <Field
                    component={Input}
                    validate={required}
                    label="Bucket name*"
                    aria-label="Bucket name"
                    name="bucketName"
                    placeholder="Name of your GCS bucket"
                    multiline
                  />
                  <Field
                    component={Input}
                    label="Path to upload to"
                    aria-label="Path to upload to"
                    name="pathPrefix"
                    placeholder="Directory in your bucket where the files will be stored"
                    multiline
                  />
                  <Field
                    component={Input}
                    validate={required}
                    label="Google Application Credential*"
                    aria-label="Google Application Credential"
                    name="googleApplicationCredentials"
                    placeholder="Content of the Google Application Credential Key File"
                    multiline
                  />
                </div>
                {values.bucketName && (
                  <p>
                    The files will be uploaded to a path like <code>{previewPath(values)}</code>
                  </p>
                )}
              </>
            )}
            {bucketUpdateSuccessful === true && (
              <div>
                <span role="img" aria-label="Checkmark emoji">
                  ✅️
                </span>{' '}
                Credentials were successfully tested.
              </div>
            )}
            {bucketUpdateSuccessful === false && (
              <div>
                <span role="img" aria-label="X emoji">
                  ❌️
                </span>{' '}
                Unable to write to bucket.
              </div>
            )}
            <div className={styles.saveBtn}>
              <Button type="submit" color="primary" isSubmitting={isSubmitting} disabled={!dirty}>
                Save GCS settings
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
}
