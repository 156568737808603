import { Fragment, useCallback, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Button, Input, ToggleSwitch } from 'frontend/components';
import { chain, max, required } from 'frontend/form/validators';

import Regex from './Regex';

const STATUS = ['Case sensitive'];
const validate = chain(required, max(150));

interface Props {
  currentLanguage: string;
  isModDialogue?: boolean;
  parentId?: string;
}

const Patterns = ({ isModDialogue, parentId, currentLanguage }: Props) => {
  const fieldName = `patterns.${currentLanguage}`;
  const { submitting: disabled, values } = useFormState();
  const { change } = useForm();
  const currentPatterns = useMemo(() => values.patterns?.[currentLanguage] || [], [currentLanguage, values]);

  const createNew = useCallback(
    (event) => {
      const newPatterns = [...currentPatterns, { pattern: '', case_sensitive: false }];
      change(fieldName, newPatterns);
      event.preventDefault();
      return false;
    },
    [change, currentPatterns, fieldName],
  );

  return (
    <>
      <h4>Slot key</h4>
      <Field
        component={Input}
        name="patternKey"
        readOnly={isModDialogue || disabled}
        inputLimit={150}
        validate={validate}
        data-testid="dialogue-pattern-key"
      />
      <div className="m-b-xl" />
      <h4 className="m-b-md">Regular expression patterns</h4>
      {!isModDialogue && (
        <div className="m-b-md">
          <Button size="small" onClick={createNew} text="New pattern" disabled={disabled} />
        </div>
      )}
      {currentPatterns.map((_, index) => (
        // FIXME: Disallow duplicate patterns and start using the pattern as key
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`pattern-${fieldName}-${index}`}>
          <Regex
            fieldName={fieldName}
            index={index}
            parentId={parentId}
            change={change}
            currentPatterns={currentPatterns}
            readOnly={isModDialogue || disabled}
          />
          <ToggleSwitch
            disabled={isModDialogue || disabled}
            name={`${fieldName}.${index}.case_sensitive`}
            aria-label="Case sensitive"
            status={STATUS}
          />
        </Fragment>
      ))}
    </>
  );
};

export default Patterns;
