import { List } from 'frontend/components';
import type { AnyRole } from 'frontend/features/Organization/types';

import { sortRoles } from '../../utils';

interface RoleListProps {
  roles: AnyRole[];
}

const RoleList = ({ roles }: RoleListProps) => {
  const sortedRoles = sortRoles(roles, 'title');

  return (
    <List
      renderItem={(item: AnyRole) => <List.Item key={item.title}>{item.title}</List.Item>}
      dataSource={sortedRoles}
    />
  );
};

export default RoleList;
