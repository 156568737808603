import { gql } from '@apollo/client';

export default gql`
  mutation UpdateChatMeta($botId: ID!, $chatId: ID!, $chatMetaInput: ChatMetaInput!) {
    updateChatMeta(botId: $botId, chatId: $chatId, chatMetaInput: $chatMetaInput) {
      id
      email
      phoneNumber
      contactDetails
    }
  }
`;
