import React from 'react';

import { Plus } from 'frontend/assets/icons';
import { Button, Panel } from 'frontend/components';

import styles from './EmptyFeature.scss';

interface EmptyFeatureProps {
  title: string;
  description: string | React.ReactElement;
  buttonText: string;
  onClick?: () => void;
  imageSrc: string;
}

const EmptyFeature = ({ title, description, buttonText, onClick, imageSrc }: EmptyFeatureProps) => (
  <Panel className={styles.emptyFeaturePanel}>
    <div className={styles.emptyFeatureWrapper}>
      <div className={styles.emptyFeatureImageContainer}>
        <img alt="emp" src={imageSrc} />
      </div>
      <div className={styles.emptyFeatureDescriptionContainer}>
        <div className={styles.emptyFeatureTitle}>{title}</div>
        <div className={styles.emtpyProactivtyDescription}>{description}</div>
        {onClick && <Button iconPosition="left" color="primary" icon={Plus} text={buttonText} onClick={onClick} />}
      </div>
    </div>
  </Panel>
);

export default EmptyFeature;
