import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { deleteFromArrayInCache } from 'frontend/api/cacheHelpers';
import { AllConnectorsDocument, DeleteConnectorDocument } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';

const update = deleteFromArrayInCache({
  pathToItemInMutationData: 'deleteConnector',
  pathToArrayInCache: 'allConnectors',
  query: AllConnectorsDocument,
});

export const DeleteConnector = ({ hide, args: { connectorId, name } }) => {
  const navigate = useNavigate();
  const [mutate] = useMutation(DeleteConnectorDocument, {
    update,
  });
  const onSubmit = async () => {
    await mutate({ variables: { connectorId } });
    navigate('/admin/connectors');
  };
  return <DeleteModal onSubmit={onSubmit} hide={hide} name={`connector ${name}`} />;
};
DeleteConnector.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    connectorId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};
