import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  ArchiveBotDocument,
  OrganizationBotsDocument,
  OrganizationMetaDocument,
  OrganizationSkillsDocument,
} from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import { useMixpanel, useToast } from 'frontend/hooks';

interface ArchiveBotProps {
  hide(): Promise<void>;
  args: {
    name?: string;
    botId: string;
  };
}
const ArchiveBot = ({ hide, args: { botId, name } }: ArchiveBotProps) => {
  const toast = useToast();
  const { mixpanel } = useMixpanel();

  const [archiveBot] = useMutation(ArchiveBotDocument, {
    variables: { botId },
    awaitRefetchQueries: true,
    refetchQueries: [OrganizationMetaDocument, OrganizationBotsDocument, OrganizationSkillsDocument],
  });

  const onSubmit = useCallback(async () => {
    await archiveBot();
    toast.success(`Workspace "${name}" is archived`);
    mixpanel.track('Archive bot', { botId });
  }, [mixpanel, botId, archiveBot, name, toast]);

  return (
    <DeleteModal action="archive" onSubmit={onSubmit} hide={hide} confirmName name={name}>
      <h3>Are you sure you want to archive workspace {name}?</h3>
      <p>
        If the bot or nudge is published, it will become unavailable to end users.
        <br />
        You may recover archived workspaces if you need them later.
      </p>
    </DeleteModal>
  );
};

export default ArchiveBot;
