import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import cx from 'classnames';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  type TextFormatType,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './ToolbarPlugin.scss';
import LinkIcon from '../icons/LinkIcon';
import ListOl from '../icons/ListOl';
import ListUl from '../icons/ListUl';
import Strikethrough from '../icons/Strikethrough';
import TypeBold from '../icons/TypeBold';
import TypeItalic from '../icons/TypeItalic';
import { getSelectedNode } from '../utils/getSelectedNode';

type BlockType = 'ul' | 'ol' | 'paragraph';

const LOW_PRIORITY = 1;
const BUTTON_TO_ICON = {
  bold: <TypeBold className={styles.icon} />,
  italic: <TypeItalic className={styles.icon} />,
  ul: <ListUl className={styles.icon} />,
  ol: <ListOl className={styles.icon} />,
  link: <LinkIcon className={styles.icon} />,
  strikethrough: <Strikethrough className={styles.icon} />,
};

const positionLinkEditorElement = (editor, rect) => {
  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
    editor.setAttribute('inert', '');
  } else {
    editor.style.opacity = '1';
    editor.style.top = `${rect.top + rect.height + window.scrollY + 10}px`;
    editor.style.left = `${rect.left + window.scrollX - editor.offsetWidth / 2 + rect.width / 2}px`;
    editor.removeAttribute('inert');
  }
};

function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return undefined;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      rootElement !== null &&
      // @ts-expect-error Improve TS
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      // @ts-expect-error Improve TS
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      // @ts-expect-error Improve TS
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionLinkEditorElement(editorElem, rect);
      }
      // @ts-expect-error fix TS
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== styles['link-input']) {
      positionLinkEditorElement(editorElem, null);
      setLastSelection(null);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor();
            return true;
          },
          LOW_PRIORITY,
        ),
      ),
    [editor, updateLinkEditor],
  );

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  return (
    <div ref={editorRef} className={styles['link-editor']}>
      <input
        ref={inputRef}
        className={styles['link-input']}
        value={linkUrl}
        onChange={(event) => {
          setLinkUrl(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            if (lastSelection !== null) {
              if (linkUrl !== '') {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
              }
            }
          } else if (event.key === 'Escape') {
            event.preventDefault();
          }
        }}
      />
    </div>
  );
}

export function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isStrikeThrough, setIsStrikeThrough] = useState(false);

  const handleOnClick = (formatType: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
  };

  const formatList = (listType) => {
    if (listType === 'ol' && blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType('ol');
    } else if (listType === 'ul' && blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('ul');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    }
  };

  const insertLink = () => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  };

  /* Check if the selected text has any format */
  useEffect(
    () =>
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            if (elementDOM !== null) {
              // Update block type
              if ($isListNode(element)) {
                const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                const type = parentList ? parentList.getTag() : element.getTag();
                setBlockType(type);
              } else {
                const type = element.getType();
                setBlockType(type as 'paragraph');
              }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsStrikeThrough(selection.hasFormat('strikethrough'));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
              setIsLink(true);
            } else {
              setIsLink(false);
            }
          }
        });
      }),
    [editor],
  );

  /* Disable underline formatting */
  useEffect(() => {
    editor.registerCommand(
      FORMAT_TEXT_COMMAND,
      (event) => {
        if (['underline'].includes(event)) {
          return true;
        }
        return false;
      },
      1,
    );
  }, [editor]);

  return (
    <div className={styles.container}>
      {['bold', 'italic', 'strikethrough' /* 'Code', 'Highlight', 'Subscript', 'Superscript' */].map((value) => (
        <button
          type="button"
          key={value}
          onClick={() => handleOnClick(value.toLowerCase() as TextFormatType)}
          className={cx(styles.button, {
            [styles.buttonActive]:
              (value === 'bold' && isBold) ||
              (value === 'italic' && isItalic) ||
              (value === 'strikethrough' && isStrikeThrough),
          })}
        >
          {BUTTON_TO_ICON[value]}
        </button>
      ))}
      {['ul', 'ol'].map((value) => (
        <button
          type="button"
          key={value}
          onClick={() => formatList(value)}
          className={cx(styles.button, { [styles.buttonActive]: blockType === value })}
        >
          {BUTTON_TO_ICON[value]}
        </button>
      ))}
      <button type="button" onClick={insertLink} className={cx(styles.button, { [styles.buttonActive]: isLink })}>
        {BUTTON_TO_ICON.link}
      </button>
      {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
    </div>
  );
}
