import { Button, NudgeWrapper, ProductCarousel, Text } from '..';

const ProductNudge = ({ nudge, currentLanguage }) => {
  const { title, bodyText, buttonText, carouselTitle, imageCarousel } = nudge;

  return (
    <NudgeWrapper>
      <Text title={title?.[currentLanguage]} description={bodyText?.[currentLanguage]} />
      {buttonText?.[currentLanguage] && <Button text={buttonText[currentLanguage]} />}
      {imageCarousel?.[currentLanguage] && (
        <ProductCarousel
          carouselTitle={carouselTitle?.[currentLanguage]}
          imageCarousel={imageCarousel[currentLanguage]}
        />
      )}
    </NudgeWrapper>
  );
};

export default ProductNudge;
