import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { DropdownButton, Panel } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill } from 'frontend/hooks';

import styles from './KindlyEntityList.scss';
import { EntityInfo, RemoveKindlyEntity } from '../../modals';

const KindlyEntityListItem = ({ entity, withRemove, withProperties }) => {
  const { isBot, buildIdObject, botId } = useBotOrSkill();
  const [showInfoModal] = useModal(EntityInfo);
  const [showRemoveModal] = useModal(RemoveKindlyEntity);

  const actions = useMemo(() => {
    const actionsList = [];
    if (isBot && withRemove) {
      actionsList.push({
        title: 'Remove',
        key: 'kindly-entity-option-remove',
        onClick: () => showRemoveModal({ entity, botId }),
      });
    }
    if (withProperties) {
      actionsList.push({
        key: 'kindly-entity-option-separator',
        separator: true,
      });
      actionsList.push({
        title: 'Properties',
        key: 'kindly-entity-option-properties',
        onClick: () => showInfoModal({ entity, buildIdObject }),
      });
    }

    return actionsList;
  }, [botId, buildIdObject, entity, isBot, showInfoModal, showRemoveModal, withProperties, withRemove]);

  return (
    <Panel key={entity.key} className={styles.panel}>
      <span className={styles.name}>{entity.name}</span>
      <span className={styles.description}>{entity.description}</span>
      <DropdownButton actions={actions} className={styles.optionsButton} />
    </Panel>
  );
};

KindlyEntityListItem.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  withRemove: PropTypes.bool,
  withProperties: PropTypes.bool,
};

export default KindlyEntityListItem;
