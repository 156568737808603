import { useCallback } from 'react';

import { getAccessToken } from 'frontend/state/dux/auth';
import { onPusherConnectError } from 'frontend/state/dux/pusher';
import { useAppDispatch } from 'frontend/state/hooks';

export default () => {
  const dispatch = useAppDispatch();

  const authorizer = useCallback(
    (channel) => ({
      authorize: async (socketId, callback) => {
        const accessToken = await dispatch(getAccessToken());
        const form = new FormData();
        form.append('socket_id', socketId);
        form.append('channel_name', channel.name);

        const response = await fetch(`${window.env.API_URL}/api/v2/pusher/auth/`, {
          method: 'POST',
          body: form,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        try {
          const authData = await response.json();
          callback(false, authData);
        } catch (e) {
          console.error({
            message: 'Could not authenticate with Pusher',
            extra: { status: response.status, response },
          });
          dispatch(onPusherConnectError());
        }
      },
    }),
    [dispatch],
  );

  return authorizer;
};
