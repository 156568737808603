import { useCallback, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { useEntities } from 'frontend/features/Entities/hooks';
import { EntitySearchAndReplace } from 'frontend/features/Entities/modals';
import { useModal } from 'frontend/features/Modals';
import { SearchAndReplace } from 'frontend/features/Samples/modals';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import { hasLanguage } from 'frontend/utils';

export const useActions = ({
  actionsEnabled,
  setSamplesExpanded,
  samplesExpanded,
  fieldName,
  totalSampleCount,
  expandedSampleCount,
  totalSkillSampleCount,
  expandedSkillSampleCount,
}) => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { buildIdObject } = useBotOrSkill();
  const { entities } = useEntities({ buildIdObject, currentLanguage });
  const { values } = useFormState();
  const { change } = useForm();
  const samples = useMemo(() => values[fieldName] || [], [values, fieldName]);
  const canExpand = totalSampleCount < expandedSampleCount || totalSkillSampleCount < expandedSkillSampleCount;

  const setSamplesInLanguage = useCallback(
    (sampleList) => {
      const samplesInOtherLanguages = samples.filter(({ languageCode }) => currentLanguage !== languageCode);
      change(fieldName, [...samplesInOtherLanguages, ...sampleList]);
    },
    [fieldName, change, currentLanguage, samples],
  );

  const [showReplaceModal] = useModal(EntitySearchAndReplace);
  const [showReplaceWordModal] = useModal(SearchAndReplace);

  const actions = useMemo(() => {
    if (!actionsEnabled) return [];
    const currentActions = [];

    if (canExpand) {
      currentActions.push({
        title: samplesExpanded ? 'Collapse samples' : 'Expand samples',
        key: 'samples-options-expand-entities',
        onClick: () => setSamplesExpanded(!samplesExpanded),
      });
    }

    if (entities.length > 0) {
      currentActions.push({
        title: 'Replace word with entity',
        key: 'samples-options-replace-entities',
        onClick: () =>
          showReplaceModal({
            setSamplesInLanguage,
            samples: samples.filter(hasLanguage(currentLanguage)),
            buildIdObject,
          }),
      });
    }

    currentActions.push({
      title: 'Replace words in Samples',
      key: 'samples-options-replace-words',
      onClick: () =>
        showReplaceWordModal({
          setSamplesInLanguage,
          samples: samples.filter(hasLanguage(currentLanguage)),
          buildIdObject,
        }),
    });

    return currentActions;
  }, [
    actionsEnabled,
    buildIdObject,
    canExpand,
    currentLanguage,
    entities.length,
    samples,
    samplesExpanded,
    setSamplesExpanded,
    setSamplesInLanguage,
    showReplaceModal,
    showReplaceWordModal,
  ]);

  return actions;
};
