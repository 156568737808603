import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePusherEvent } from 'frontend/features/Pusher';

import { GET_EVALUATION_REPORT } from '../graphql';

export default ({ botId, toast, setIsRunning, evaluationId, client }) => {
  const navigate = useNavigate();
  const onUpdate = useCallback(
    async ({ reportId, status }) => {
      setIsRunning(false);
      const toastArgs = {
        autoClose: false,
      };
      if (status === 'FINISHED') {
        toast.success('Evaluation run finished.', toastArgs);
        // fetch the new report and add it to apollo cache
        const variables = { evaluationId, reportId };
        const {
          data: {
            evaluation: { evaluationReport },
          },
        } = await client.query({ query: GET_EVALUATION_REPORT, variables });

        client.cache.modify({
          id: `EvaluationType:${evaluationId}`,
          fields: {
            evaluationReports: (current = []) => [{ ...evaluationReport, evaluationId }, ...current],
          },
        });

        // FIXME: not sure if we can avoid setTimeout by awaiting addToCacheList?
        setTimeout(() => {
          navigate(`/workspace/${botId}/build/evaluation/${evaluationId}/reports/${evaluationReport.id}`, {
            replace: true,
          });
        }, 250);
      } else if (status === 'ERROR') {
        toast.error('Evaluation failed to run. If the problem continues please contact support.', toastArgs);
      } else {
        toast.error('Unexpected error while running the report.', toastArgs);
      }
    },
    [botId, client, evaluationId, navigate, setIsRunning, toast],
  );

  usePusherEvent(`private-bot-evaluation-${botId}`, 'evaluation-run-finished', onUpdate);
};
