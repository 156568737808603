import { get } from 'lodash';
import { Field } from 'react-final-form';

import { Button, Panel, ToggleSwitch } from 'frontend/components';

import styles from './Beta.scss';

interface Props {
  change: (name: string, value: boolean) => void;
  values: { [key: string]: unknown };
  path: string;
  showBetaModal: (args: {
    enable: () => void;
    featureTitle: string;
    description?: string;
    oneTimeOnly?: boolean;
  }) => void;
  featureTitle: string;
  description?: string;
  /** If true, the beta feature can be only permanently activated and not switched back off. */
  oneTimeOnly?: boolean;
}

export default function BetaField({
  change,
  values,
  path,
  showBetaModal,
  featureTitle,
  description,
  oneTimeOnly,
}: Props): React.JSX.Element {
  const enabled = get(values, path);
  const input = { value: enabled };

  const onClick = () => {
    if (!enabled) showBetaModal({ enable: () => change(path, true), featureTitle, description, oneTimeOnly });
    else change(path, false);
  };

  return (
    <>
      <Panel.Separator />

      {/* 👇 Make sure field is registered: https://github.com/final-form/final-form/issues/169#issuecomment-430939734 */}
      <Field name={path} component={() => null} />

      {oneTimeOnly ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {enabled ? (
            <p>
              The beta feature <b>{featureTitle}</b> has been permanently enabled on this Workspace.
            </p>
          ) : (
            <Button onClick={onClick}>Enable {featureTitle} permanently</Button>
          )}
        </>
      ) : (
        <div className={styles.switchContainer}>
          <ToggleSwitch.Switch input={input} className="m-r-md" onClick={onClick} />
          Enable {featureTitle}
        </div>
      )}
    </>
  );
}
