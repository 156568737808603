import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { differenceInCalendarDays, differenceInSeconds, parseISO } from 'date-fns';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { createRef, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BotMemberDocument } from 'frontend/api/generated';
import { Button, Loader } from 'frontend/components';
import { ChatFeedbackType } from 'frontend/features/Analytics/propTypes';
import { MESSAGE_TYPE } from 'frontend/features/Inbox/constants';
import { AttachmentType, WebhookDataType } from 'frontend/features/Inbox/propTypes';
import { getUserName } from 'frontend/features/Inbox/utils';
import { useModal } from 'frontend/features/Modals';
import { usePrevious } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { IDType, RefType } from 'frontend/propTypes';
import { setScrollHeight } from 'frontend/state/dux/inbox';
import { getArray } from 'frontend/utils';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './ConversationLog.scss';
import { useAutoScroll } from '../../hooks';
import { ConfidenceScore } from '../../modals';
import { ConversationDataType, MessagesDataType, TicketLogType } from '../../propTypes';
import { handleButtonClicks, isMessage, isMessageOrButtonClick } from '../../utils';
import ConversationLogItem from '../ConversationLogItem';
import HandoverForm from '../HandoverForm';

const FIFTEEN_MINUTES = 15 * 60;

const getCreatedTimestamp = ({ created }) => parseISO(created).getTime();

// Group and order messages and logs
const groupData = (messages, ticketLog, feedback, userLeft, buttonClicks, webhooks) => {
  const data = [...(ticketLog || []), ...messages, ...handleButtonClicks(buttonClicks, messages), ...webhooks];

  if (feedback) {
    data.push({
      feedback,
      messageType: MESSAGE_TYPE.FEEDBACK,
      created: feedback.created,
      id: 'feedback-log-item',
    });
  }

  if (userLeft) {
    data.push({
      messageType: MESSAGE_TYPE.USER_LEFT,
      created: userLeft,
      id: 'user-left-log-item',
    });
  }

  const dataOrdered = orderBy(data, getCreatedTimestamp, 'asc');

  const groupedData = [];
  let prevItem = {};
  let prevUrl = null;

  dataOrdered.forEach((item) => {
    const url = item.webHost && item.webPath ? `https://${item.webHost}${item.webPath}` : null;
    const newDay = differenceInCalendarDays(item.created, prevItem.created) > 0;

    // Create new custom item if it's a new day
    if (!prevItem.created || newDay) {
      const newDayItem = {
        created: item.created,
        messageType: MESSAGE_TYPE.TIMESTAMP,
        id: randomUUID(),
      };
      groupedData.push([newDayItem]);
      prevItem = newDayItem;
    }

    // Create new custom item if the URL has changed
    if (url !== prevUrl && url) {
      const urlItem = {
        url,
        created: item.created,
        messageType: MESSAGE_TYPE.URL,
        id: randomUUID(),
      };
      groupedData.push([urlItem]);
      prevItem = urlItem;
    }

    // Add item to existing array or append new item
    if (
      isMessageOrButtonClick(item) &&
      isMessageOrButtonClick(prevItem) &&
      item.sender !== 'BOT' &&
      item.fromBot === prevItem.fromBot &&
      item.name === prevItem.name &&
      item.exchangeType === prevItem.exchangeType &&
      item.fromWebhook === prevItem.fromWebhook &&
      differenceInSeconds(item.created, prevItem.created) < FIFTEEN_MINUTES &&
      !newDay
    ) {
      groupedData[groupedData.length - 1].push(item);
    } else {
      groupedData.push([item]);
    }

    // Set values to match in next iteration
    prevItem = item;
    if (!item.fromBot) {
      prevUrl = url;
    }
  });

  return groupedData;
};

const groupHasMessages = (group) => group?.length > 0 && isMessage(group[0]);

// Index of the last message group of which all messages are seen
const getLastGroupIndexSeen = (lastMessageIdSeen, groupedData) =>
  (groupedData || []).reduce((result, group, index) => {
    if (Number.isInteger(result)) {
      return result;
    }

    const containsLastMessageSeen = group.find(({ id }) => id === lastMessageIdSeen);
    if (!containsLastMessageSeen) {
      return undefined;
    }

    const lastMessageInGroup = group
      .slice()
      .reverse()
      .find((groupItem) => isMessage(groupItem));

    if (lastMessageInGroup.id === lastMessageIdSeen) {
      return index;
    }

    const precedingMessages = groupedData.slice(0, index);
    const reversedIndex = precedingMessages.reverse().findIndex(groupHasMessages);
    return precedingMessages.length - reversedIndex - 1;
  }, []);

const ConversationLog = ({
  attachments,
  botId,
  bottomRef,
  conversationData,
  feedback,
  loadMore,
  loading,
  messages,
  ticketLog,
  userIsTyping,
  webhooks,
  canViewDialogues,
}) => {
  const conversationElement = createRef();
  const [showFade, setShowFade] = useState(false);
  const [showConfidenceScoreModal] = useModal(ConfidenceScore);
  const onClickConfidenceScore = useCallback(
    () => showConfidenceScoreModal({ botId }),
    [botId, showConfidenceScoreModal],
  );
  const dispatch = useDispatch();

  const handleScroll = () => {
    const topOffset = conversationElement.current.scrollTop;
    if (topOffset > 10) {
      setShowFade(true);
    } else {
      setShowFade(false);
    }
  };

  const { data: meData, loading: meLoading } = useMe();
  const chatMessages = getArray('chatMessages.messages', messages);
  const remainingMessages = messages?.chatMessages?.remaining ?? 0;
  const prevRemainingMessages = usePrevious(remainingMessages);

  const botName = conversationData?.chatAndBot?.bot?.name;
  const chat = conversationData?.chatAndBot?.chat || {};
  const {
    userLeft,
    languageCode,
    lastMessageIdSeen,
    source,
    buttonClicks = [],
    /* FIXME(legacy-takeover): Rename fields */
    takeoverByAgentId: handoverByAgentId,
    completedTakeover: completedHandover,
    takenOver: handedOver,
  } = chat;

  const loadMoreMessages = useCallback(() => {
    dispatch(setScrollHeight(conversationElement.current.scrollHeight));
    loadMore();
  }, [conversationElement, dispatch, loadMore]);

  const { scrollToBottom } = useAutoScroll({
    bottomRef,
    loading,
    chatMessages,
    ticketLog,
    remainingMessages,
    prevRemainingMessages,
    conversationElement,
  });

  const { data: agentData } = useQuery(BotMemberDocument, {
    skip: !handoverByAgentId,
    variables: {
      id: botId,
      userId: handoverByAgentId,
    },
  });

  const groupedData = useMemo(
    () => groupData(chatMessages, ticketLog, feedback, userLeft, buttonClicks, webhooks),
    [buttonClicks, chatMessages, feedback, ticketLog, userLeft, webhooks],
  );

  const lastGroupIndexSeen = getLastGroupIndexSeen(lastMessageIdSeen, groupedData);

  if (loading || meLoading) {
    return (
      <div className={cx(styles.conversation, styles.conversationGhost)}>
        <Loader type="inbox_conversation" />
      </div>
    );
  }

  const agentName = agentData?.bot?.member?.user?.profile?.fullName || '<Deleted user>';
  const anotherAgentHasHandover =
    handoverByAgentId && !completedHandover && meData?.me?.id?.toString() !== handoverByAgentId.toString();
  const userHasLeft = !!conversationData?.chatAndBot?.chat?.userLeft;
  const userName = getUserName(conversationData?.chatAndBot?.chat);

  return (
    <div
      className={cx(styles.conversation, {
        [styles.showFade]: showFade,
      })}
    >
      {anotherAgentHasHandover && !userHasLeft && (
        <div className={styles.handoverBanner}>{agentName} currently in handover mode</div>
      )}
      <div className={cx(styles.conversationBubblesWrapper)} onScroll={handleScroll} ref={conversationElement}>
        {Boolean(remainingMessages) && (
          <div className={styles.loadMore}>
            <Button onClick={loadMoreMessages}>{`Load more (${remainingMessages})`}</Button>
          </div>
        )}
        {groupedData.map((data, index) => (
          <ConversationLogItem
            key={`conversation-log-item-${data[0].id}`}
            data={data}
            botId={botId}
            conversationData={conversationData}
            attachments={attachments}
            showConfidenceScoreModal={onClickConfidenceScore}
            userName={userName}
            chatLanguageCode={languageCode}
            scrollToBottom={scrollToBottom}
            botName={botName}
            canViewDialogues={canViewDialogues}
            isLastSeen={lastGroupIndexSeen === index}
          />
        ))}
        <div className="m-b-xl" ref={bottomRef} />
      </div>
      {handedOver && <HandoverForm chat={chat} messages={messages} source={source} botId={botId} />}
      {userIsTyping && (
        <div className={styles.userIsTyping}>
          <span>{userName}</span> is typing
        </div>
      )}
    </div>
  );
};

ConversationLog.propTypes = {
  botId: IDType.isRequired,
  loading: PropTypes.bool,
  loadMore: PropTypes.func,
  bottomRef: RefType.isRequired,
  conversationData: ConversationDataType.isRequired,
  ticketLog: PropTypes.arrayOf(TicketLogType).isRequired,
  userIsTyping: PropTypes.bool.isRequired,
  feedback: ChatFeedbackType,
  attachments: PropTypes.arrayOf(AttachmentType),
  webhooks: PropTypes.arrayOf(WebhookDataType),
  messages: MessagesDataType.isRequired, // TODO: Clean up this nesting (messages.chatMessages.messages), probably via useChatMessages
  canViewDialogues: PropTypes.bool.isRequired,
};

export default ConversationLog;
