import { useCallback, useEffect, useState } from 'react';

import { PageContent, Select } from 'frontend/components';
import { DatePickerDropdown } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import { connectPlatformAnalytics, disconnectPlatformAnalytics } from 'frontend/state/dux/analytics/dashboard';
import { useAppDispatch } from 'frontend/state/hooks';

import styles from './styles.scss';
import { DashboardHeader, MostActiveBots } from '../../components';

const SKIP = ['languageCodes', 'sources'];

const Dashboard = () => {
  const [topBotsAmount, setTopBotsAmount] = useState(5);
  const filters = useFilters({ skip: SKIP });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(connectPlatformAnalytics());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(disconnectPlatformAnalytics());
    },
    [dispatch],
  );

  const changeSelection = useCallback((e) => {
    setTopBotsAmount(parseInt(e.target.value, 10));
  }, []);

  return (
    <PageContent>
      <DashboardHeader />
      <div className={styles.sectionHeader}>
        <h3>Most active bots</h3>
        <Select
          input={{
            name: 'topBots',
            value: String(topBotsAmount),
            onChange: changeSelection,
            onBlur: () => undefined,
            onFocus: () => undefined,
          }}
          meta={{}}
        >
          <Select.Option value={10} label="Top 10" />
          <Select.Option value={25} label="Top 25" />
          <Select.Option value={50} label="Top 50" />
          <Select.Option value={100} label="Top 100" />
        </Select>
        <DatePickerDropdown position="bottom-left" />
      </div>
      <MostActiveBots filters={filters} amount={topBotsAmount} />
    </PageContent>
  );
};

export default Dashboard;
