import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { type DialogueType, DialoguesWithImagesDocument } from 'frontend/api/generated';
import BuildTypes from 'frontend/constants/buildTypes';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';

import { COLUMN_FIELDS, ORDERED_COLUMNS } from './constants';
import { DASHBOARD_VIEW } from '../../constants';
import { useImagesRenderMap } from '../../hooks';
import { compiledImageList } from '../../utils';
import BuildResources from '../BuildResources';

const Images = () => {
  const { buildIdObject, buildType } = useBotOrSkill();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const { data, loading } = useQuery(DialoguesWithImagesDocument, {
    variables: { ...buildIdObject, languageCode: selectedLanguage },
    fetchPolicy: 'no-cache',
    skip: !selectedLanguage,
  });

  const imageList = useMemo(() => {
    const filteredDialogues = (data?.dialoguesWithImages || []).filter(
      (dialogue): dialogue is DialogueType => dialogue !== null,
    );
    return compiledImageList(selectedLanguage, filteredDialogues);
  }, [selectedLanguage, data]);
  const noData = !loading && imageList.length === 0;

  const renderMap = useImagesRenderMap({ buildIdObject });

  return (
    <BuildResources
      resources={imageList}
      renderMap={renderMap}
      columnFields={COLUMN_FIELDS}
      orderedColumns={ORDERED_COLUMNS}
      dashboardView={DASHBOARD_VIEW.IMAGES}
      loading={loading}
      buildType={buildType ?? BuildTypes.BOT}
      noData={noData}
    />
  );
};

export default Images;
