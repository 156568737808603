import { useQuery } from '@apollo/client';

import { FeedbackDocument, type FeedbackQuery } from 'frontend/api/generated';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';

type PartialFeedback = NonNullable<FeedbackQuery>['chatBubbleFeedback'];
export default function useFeedback({
  botId,
  chatId,
}: {
  botId: string | undefined;
  chatId: string | undefined;
}): PartialFeedback | null {
  const { data, refetch } = useQuery(FeedbackDocument, {
    variables: { botId: botId!, chatId: chatId! },
    skip: !botId || !chatId,
  });

  usePusherEvent(getPusherChannel({ chatId }), 'feedback-update', refetch);

  return data?.chatBubbleFeedback ?? null;
}
