import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import type { MenuItem } from './Menu';
import styles from './Menu.scss';
import MobileSubMenuItem from './MobileSubMenuItem';

interface Props {
  item: MenuItem;
  toggleMenuOpen: () => void;
}

export default function MobileMenuItem({ item, toggleMenuOpen }: Props) {
  if (item.submenu && !item.hide) {
    return (
      <MobileSubMenuItem
        rootPath={item.slug}
        toggleMenuOpen={toggleMenuOpen}
        title={item.name}
        menuItems={item.submenu}
        key={`menu-item-${item.name}`}
      />
    );
  }

  return (
    <NavLink
      to={item.slug}
      className={({ isActive }) =>
        cx(item.className, styles.mobileMenuItem, { [styles.mobileMenuItemActive]: isActive })
      }
      onClick={toggleMenuOpen}
    >
      {item.name}
    </NavLink>
  );
}
