import { gql } from '@apollo/client';

import ENTITY from './ENTITY';
import KINDLY_ENTITY from './KINDLY_ENTITY';

export default gql`
  fragment EntityInstance on EntityInstanceType {
    id
    start
    end
    entity {
      ...Entity
    }
    kindlyEntity {
      ...KindlyEntity
    }
  }
  ${ENTITY}
  ${KINDLY_ENTITY}
`;
