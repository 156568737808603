import { useCallback } from 'react';

import { addListItemByFragment } from 'frontend/api/cacheHelpers';
import { TICKET_LOG } from 'frontend/features/Inbox/fragments';
import { prepareTicketLog } from 'frontend/features/Inbox/utils';

export default ({ cache }) => {
  const onTicketLog = useCallback(
    (payload) => {
      const ticketLog = prepareTicketLog(payload);

      cache.modify({
        fields: { ticketLog: addListItemByFragment(cache, TICKET_LOG, ticketLog) },
      });
    },
    [cache],
  );

  return onTicketLog;
};
