import DOMPurify from 'dompurify';

// Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element
// Ref: https://github.com/cure53/DOMPurify#can-i-configure-dompurify
// default allowed tags: https://github.com/cure53/DOMPurify/blob/211f0c899136145c3ecc8df4bfff1548946263e2/src/tags.js#L3
const forbidTags = ['form', 'input'];
const addAttributes = ['target'];
const forbidAttributes = ['action'];

const sanitizeHTML = (html) =>
  DOMPurify.sanitize(html, {
    ADD_ATTR: addAttributes,
    FORBID_ATTR: forbidAttributes,
    FORBID_TAGS: forbidTags,
    USE_PROFILES: { svg: false, svgFilters: false, mathMl: false, html: true },
  });

export default sanitizeHTML;
