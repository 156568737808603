import { gql } from '@apollo/client';

import { COMMENT } from '../fragments';

export default gql`
  query Comments($chatId: ID!) {
    chatAndBot(chatId: $chatId) {
      id
      chat {
        id
        comments {
          ...ChatComment
        }
      }
    }
  }
  ${COMMENT}
`;
