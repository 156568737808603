import { useMemo } from 'react';

import type { ChatType, LabelType } from 'frontend/api/generated';
import { useBotLabels } from 'frontend/hooks';

const addLabelActive = (labelsForChat: string[]) => (label: Partial<LabelType>) => ({
  ...label,
  active: labelsForChat.includes(label.id),
});

export default (chat?: ChatType) => {
  const botLabels = useBotLabels();

  const labels = useMemo(() => {
    const labelsForChat = chat?.labelIds ?? [];
    return botLabels.map(addLabelActive(labelsForChat));
  }, [botLabels, chat]);

  return [labels, labels.filter((label) => label.active)];
};
