import { useQuery } from '@apollo/client';
import { isArray } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { type Location, useLocation, useParams } from 'react-router-dom';

import { type MeQuery, OrganizationBotsDocument, type OrganizationBotsQuery } from 'frontend/api/generated';
import { Search as SearchIcon } from 'frontend/assets/icons';
import { Button, EmptyState, LoaderSwitch, Panel, Search, Table } from 'frontend/components';
import type { SearchRef } from 'frontend/components/Search/Search';
import type { Column, Row } from 'frontend/components/Table/Table';
import { buildTypes } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';
import { CreateBotLikeModal } from 'frontend/features/Organization/modals';
import { usePrevious, useUrlSearchParams } from 'frontend/hooks';

import { ActivityCell } from './Cells/ActivityCell';
import { DropDownActions } from './Cells/DropDownActions';
import { LanguageCell } from './Cells/LanguageCell';
import { QuantityCell } from './Cells/QuantityCell';
import { WorkspaceCell } from './Cells/WorkspaceCell';
import NoWorkspace from './NoWorkspace/NoWorkspace';
import styles from './styles.scss';

const getQuantityCell = (propertyName: 'dialogueCount' | 'subscriptionCount') => {
  const WrappedQuantityCell = ({ rowData }: { rowData?: Row }) => (
    <QuantityCell rowData={rowData} propertyName={propertyName} />
  );
  WrappedQuantityCell.displayName = `WrappedQuantityCell(${propertyName})`;
  return WrappedQuantityCell;
};

const getDropDownActions = (me: MeQuery['me']) => {
  const WrappedDropdownActions = ({ rowData }: { rowData?: Row }) => {
    if (!rowData) return null;
    return <DropDownActions rowData={rowData} me={me} />;
  };
  WrappedDropdownActions.displayName = `WrappedDropdownActions`;
  return WrappedDropdownActions;
};

const getTableColumns = (me) => [
  { key: 'workspace', render: WorkspaceCell },
  { key: 'languages', render: LanguageCell },
  { key: 'dialogues', render: getQuantityCell('dialogueCount') },
  { key: 'skills', render: getQuantityCell('subscriptionCount') },
  { key: 'activity', render: ({ rowData }) => <ActivityCell rowData={rowData} /> },
  { key: '', render: getDropDownActions(me) },
];

const SEARCH_PARAMS_CONFIG = { default: { page: 1 }, types: { archived: Boolean, page: Number } };

type SearchParamUpdates = {
  [key: string]: string | number | boolean | null;
};
const getCurrentLocationWithUpdatedSearchParams = (location: Location, updates: SearchParamUpdates): string => {
  const searchParams = new URLSearchParams(location.search);
  Object.keys(updates).forEach((key) => {
    const value = updates[key];
    if (value === null) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, String(value));
    }
  });
  return `?${searchParams.toString()}`;
};

type Bot = NonNullable<OrganizationBotsQuery['organization']['bots']>[number];
const filterBots = (bots: Bot[], archived: boolean) =>
  bots.filter((bot) => (archived ? bot.isArchived : !bot.isArchived));

const BOTS_PER_PAGE = 10;
interface WorkspaceListProps {
  canCreateBots: boolean;
  me: MeQuery['me'];
  botCount: number | undefined;
}

const WorkspaceList = ({ canCreateBots, me, botCount }: WorkspaceListProps) => {
  const { organizationId } = useParams();

  const [{ page, archived }, setParams, unsetParams] = useUrlSearchParams(SEARCH_PARAMS_CONFIG);
  const [showCreateBotLikeModal] = useModal(CreateBotLikeModal);

  const { data, loading: isBotsLoading } = useQuery(OrganizationBotsDocument, {
    variables: { id: organizationId! },
  });
  const allBots = data?.organization.bots || [];
  const [searchResults, setSearchResults] = useState<Bot[] | null>(isBotsLoading ? null : allBots);
  const bots = filterBots(searchResults || [], archived);
  const totalPages = Math.ceil(bots.length / BOTS_PER_PAGE);
  const indexOfLastBot = page * BOTS_PER_PAGE;
  const indexOfFirstBot = indexOfLastBot - BOTS_PER_PAGE;
  const currentBots = bots.slice(indexOfFirstBot, indexOfLastBot);

  const location = useLocation();

  const isLoading = isBotsLoading || !isArray(searchResults);

  const searchRef = useRef<SearchRef>(null);

  const previousSearchResults = usePrevious(searchResults);
  useEffect(() => {
    if (previousSearchResults !== searchResults && page > 1) {
      unsetParams(['page']);
    }
  }, [previousSearchResults, searchResults, page, unsetParams]);

  const createNewWorkspace = () => showCreateBotLikeModal({ organizationId, buildType: buildTypes.BOT });

  if (!isLoading && !allBots.length) {
    return (
      <div>
        <Panel>
          <NoWorkspace onCreateWorkspace={createNewWorkspace} />
        </Panel>
      </div>
    );
  }

  return (
    <Panel size="noPadding" className={styles.container}>
      <header>
        <h1 className={styles.title}>
          Workspaces{' '}
          <span className={styles.quantity} role="status" aria-label={`${botCount} workspaces in your organization`}>
            {botCount || '-'}
          </span>
        </h1>
        <section className={styles.actions}>
          <fieldset className={styles.filters}>
            <Button
              color={archived ? 'transparent' : 'secondary'}
              text="Active"
              to={getCurrentLocationWithUpdatedSearchParams(location, { archived: null, page: null })}
            />
            <Button
              color={archived ? 'secondary' : 'transparent'}
              text="Archived"
              to={getCurrentLocationWithUpdatedSearchParams(location, { archived: true, page: null })}
            />
            <Search
              ref={searchRef}
              setSearchResults={setSearchResults}
              searchItems={allBots || []}
              searchKey="name"
              placeholder="Search workspaces"
              aria-label="Search workspaces"
              icon
            />
          </fieldset>
          {canCreateBots && <Button color="primary" text="Create new workspace" onClick={createNewWorkspace} />}
        </section>
      </header>
      <LoaderSwitch loading={isLoading} size="large">
        {!currentBots || currentBots.length <= 0 ? (
          <div className={styles.emptyState}>
            <EmptyState
              color="pink"
              title="No results"
              description="Your search did not give any results"
              icon={SearchIcon}
            />
          </div>
        ) : (
          <Table
            nested
            columns={getTableColumns(me) as Column[]}
            data={
              currentBots.map((bot) => ({
                ...bot,
                to: bot?.id ? `/workspace/${bot.id}` : undefined,
              })) as Row[]
            }
            rowClassName={styles.row}
            pagination={
              totalPages
                ? {
                    currentPage: page,
                    pages: totalPages,
                    setPage: (pageNumber) =>
                      pageNumber <= 1 ? unsetParams(['page']) : setParams({ page: pageNumber }),
                  }
                : undefined
            }
          />
        )}
      </LoaderSwitch>
    </Panel>
  );
};
export default WorkspaceList;
