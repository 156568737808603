import { useContext } from 'react';

import { MixpanelContext, type MixpanelCtx } from 'frontend/config/mixpanel';

/**
 * Use the mixpanel client to track events.
 *
 * @example
 * const { mixpanel } = useMixpanel();
 * mixpanel.track('Sign up');
 *
 * @see {@link https://developer.mixpanel.com/docs/javascript-full-api-reference|Mixpanel docs}
 */
function useMixpanel(): MixpanelCtx {
  const context = useContext(MixpanelContext);

  if (context == null) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }

  return context;
}
export default useMixpanel;
