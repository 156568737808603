import PropTypes from 'prop-types';

const ValueType = PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({}), PropTypes.bool]);

export default PropTypes.shape({
  value: PropTypes.arrayOf(ValueType),
  name: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  map: PropTypes.func.isRequired,
  pushFixed: PropTypes.func.isRequired,
  removeFixed: PropTypes.func.isRequired,
  moveFixed: PropTypes.func.isRequired,
});
