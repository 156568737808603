import styles from './Filters.scss';

interface CommonProps {
  children?: React.ReactNode;
}
const Header = ({ children }: CommonProps) => <div className={styles.header}>{children}</div>;

const Content = ({ children }: CommonProps) => <div className={styles.content}>{children}</div>;

const FilterSideBar = ({ children }: CommonProps) => (
  <div className={styles.filterWrapper} role="complementary">
    {children}
  </div>
);

FilterSideBar.Header = Header;
FilterSideBar.Content = Content;

export default FilterSideBar;
