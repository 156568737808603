/* eslint-disable react-hooks/rules-of-hooks */ /* FIXME: conditional hooks! */
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import type { FormApi } from 'final-form';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { removeFieldsFromKeyWithPotentialVariables } from 'frontend/api/cacheHelpers';
import { BotLanguageSettingsDocument } from 'frontend/api/generated';
import { updateBotLanguages } from 'frontend/api/mutations';
import { useBotLanguages, useToast } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { chatBubbleSettingsUpdated } from 'frontend/state/dux/bot';

import type { Values } from '../BotLanguagesController';

const useLanguageForm = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { botId } = useParams();
  const { hasBotPerms } = useMyPermissions({ botId });
  const canViewTemplates = hasBotPerms('view_templates');
  const apolloClient = useApolloClient();

  const { data, loading } = useQuery(BotLanguageSettingsDocument, { variables: { botId: botId! } });
  const { languages, languageVariants, primaryLanguage } = useBotLanguages();

  const selectLanguageGreeting = data?.bot?.selectLanguageGreeting;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeLanguageVariants: string[] = data?.bot?.activeLanguageVariants || [];

  const update = (cache, { data: { updateBotLanguages: updatedLanguageData } }) => {
    const updatedData = { ...data, bot: { ...data?.bot, ...updatedLanguageData } };
    setTimeout(() => cache.writeQuery({ query: BotLanguageSettingsDocument, variables: { botId }, data: updatedData }));

    dispatch(chatBubbleSettingsUpdated(true));

    if (!canViewTemplates) {
      return;
    }

    const fieldsToClear = [
      'dialogues',
      'dialogue',
      'numberOfPatternsInOtherDialogues',
      'buttons',
      'dialoguesWithWebhooks',
      'someDialogue',
      'buildStatistics',
      'chatbubbleSettings',
    ];

    removeFieldsFromKeyWithPotentialVariables(apolloClient, fieldsToClear, { botId });
  };

  // @ts-expect-error FIXME: update function is not ok
  const [updateLanguages] = useMutation(updateBotLanguages, { update });

  const onSubmit = async (values: Values, form: FormApi<Values>) => {
    if (!botId) {
      return;
    }

    const { initialValues } = form.getState();
    const { botLanguageVariants, botActiveLanguageVariants } = values;

    const languageCodes = values.languages.map(({ code }) => code);
    const activeLanguageCodes = values.languages.filter(({ active }) => active).map(({ code }) => code);

    /* Check existence and what is the first new variant of each language. */
    const convertToVariant = values.languages.reduce((acc, language) => {
      const variantCodes = language.variants.map(({ code }) => code);

      const hadVariantsAlready = variantCodes.some((code) => initialValues.botLanguageVariants?.has(code));
      const isNewLanguage = !initialValues.languages?.some((lang) => lang.code === language.code);
      if (hadVariantsAlready || isNewLanguage) {
        return acc;
      }

      const firstNewVariant = variantCodes.find((code) => botLanguageVariants.has(code));
      if (firstNewVariant) {
        acc[language.code] = firstNewVariant;
        return acc;
      }

      return acc;
    }, {});

    try {
      await updateLanguages({
        variables: {
          botId,
          selectLanguageGreeting: values.selectLanguageGreeting,
          languageCodes,
          activeLanguageCodes,
          languageVariants: Array.from(botLanguageVariants),
          activeLanguageVariants: Array.from(botActiveLanguageVariants),
          convertToVariant,
          primaryLanguage: values.primaryLanguage,
        },
      });
      toast.success('Saved');
    } catch (e) {
      toast.error(e.message);
    }
  };

  /* Using memo to preserve referential equality and don't re-render the form while submitting.
  Without this, the form would reset to initial state while submitting, to reach the final state after a few secs. */
  const initialValues: Values = useMemo(
    () => ({
      selectLanguageGreeting,
      languages,
      botLanguageVariants: new Set(languageVariants.map((variant) => variant.code)),
      botActiveLanguageVariants: new Set(activeLanguageVariants),
      primaryLanguage,
    }),
    [activeLanguageVariants, primaryLanguage, languageVariants, languages, selectLanguageGreeting],
  );

  return { loading, initialValues, onSubmit };
};

export default useLanguageForm;
