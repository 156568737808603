import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Channel, Language } from 'frontend/assets/icons';
import { Checkbox, FilterAccordion } from 'frontend/components';
import SourcesFilter from 'frontend/features/Inbox/components/InboxFilters/components/SourcesFilter/SourcesFilter';
import { useBotLanguages } from 'frontend/hooks';

import styles from '../../FilterItems.scss';

const BotDataItems = ({ hideFilter }) => {
  const { languages } = useBotLanguages();
  const hasLanguageFilter = languages?.length > 1 && !hideFilter?.language;

  return (
    <>
      {hasLanguageFilter && (
        <FilterAccordion title="Language" icon={Language}>
          {languages.map((item) => (
            <Field
              key={item.code}
              name={`languageCodes.${item.code}`}
              className={styles.filterListItem}
              component={Checkbox}
              type="checkbox"
              label={item.name}
            />
          ))}
        </FilterAccordion>
      )}
      {!hideFilter?.sources && (
        <FilterAccordion title="Channel" icon={Channel}>
          <SourcesFilter sourceKey="sources" />
        </FilterAccordion>
      )}
    </>
  );
};

BotDataItems.propTypes = {
  hideFilter: PropTypes.shape({
    language: PropTypes.bool,
    sources: PropTypes.bool,
    engagementCampaigns: PropTypes.bool,
    feedbacks: PropTypes.bool,
    nudges: PropTypes.bool,
  }),
};

export default BotDataItems;
