import { orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { useModal } from 'frontend/features/Modals';
import { useToggle } from 'frontend/hooks';

import { SORT_ITEMS, USER_SORT_ITEMS } from './constants';
import AddMemberToOrganization from '../../modals/AddMemberToOrganization/AddMemberToOrganization';

export function useSort(showPending: boolean = false) {
  const [sortBy, setSortBy] = useState<(typeof SORT_ITEMS)[keyof typeof SORT_ITEMS]>(SORT_ITEMS.NAME);
  const [reverse, toggleReverse] = useToggle();

  const sortFunction = useCallback((list) => orderBy(list, sortBy, reverse ? 'desc' : 'asc'), [reverse, sortBy]);

  useEffect(() => {
    if (showPending && (USER_SORT_ITEMS as string[]).includes(sortBy)) {
      setSortBy(SORT_ITEMS.EMAIL);
    } else if (!showPending && !(USER_SORT_ITEMS as string[]).includes(sortBy)) {
      setSortBy(SORT_ITEMS.NAME);
    }
  }, [showPending, sortBy]);

  return { sortBy, setSortBy, reverse, toggleReverse, sortFunction };
}

export type SortParams = {
  sortBy: (typeof SORT_ITEMS)[keyof typeof SORT_ITEMS];
  setSortBy: CallableFunction;
  toggleReverse: CallableFunction;
  reverse: boolean;
};

export function useSortColumn(
  sortItem: unknown,
  { sortBy, setSortBy, toggleReverse }: SortParams,
): [boolean, () => void] {
  const isSortedByItem = sortBy === sortItem;

  const onClick = useCallback(() => {
    if (isSortedByItem) toggleReverse();
    else setSortBy(sortItem);
  }, [isSortedByItem, setSortBy, sortItem, toggleReverse]);

  return [isSortedByItem, onClick];
}

export function useOpenInvite(id: string, name: string): () => void {
  const [showAddMemberModal] = useModal(AddMemberToOrganization);
  const openInvite = useCallback(() => showAddMemberModal({ id, name }), [id, name, showAddMemberModal]);
  return openInvite;
}
