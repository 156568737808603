import { useMemo } from 'react';

import { Panel } from 'frontend/components';
import { Grid, Heading, StatsPanel } from 'frontend/features/Analytics/components/';
import type {
  CampaignData,
  EmailCampaignData,
} from 'frontend/features/Organization/views/Conversion/hooks/useEngagementStats';

import { Stat } from '..';
import shared from '../../styles.scss';

function averageOrderValue(campaign) {
  const conversions = campaign?.emailConversions || 0;
  const value = campaign?.emailConversionValue || 0;

  if (conversions === 0) {
    return 0;
  }

  return Math.round(value / conversions);
}

const EmailRemarketingStats = ({
  campaigns,
  loading,
  error,
}: {
  campaigns: CampaignData[];
  loading: boolean;
  error?: string;
}) => {
  const emailCampaigns = useMemo(
    () => campaigns.filter((campaign): campaign is EmailCampaignData => campaign.campaignType === 'email'),
    [campaigns],
  );

  if (loading || error) {
    return (
      <>
        <Heading title="Email" />
        <StatsPanel loading={loading} error={error} />
      </>
    );
  }

  return (
    <>
      <Heading title="Email" />
      {emailCampaigns.map((campaign) => (
        <div key={`campain-${campaign?.campaignName}`} className={shared.stats}>
          <StatsPanel loading={loading} error={error}>
            <h4 className={shared.statTitle}>{campaign?.campaignName ?? 'Campaign'}</h4>
            <Grid className={shared.grid}>
              <Panel className={shared.panel}>
                <Stat name="Average order value" value={averageOrderValue(campaign)} type={campaign?.currency} />
              </Panel>
              <Panel className={shared.panel}>
                <Stat name="Total order value" value={campaign?.emailConversionValue} type={campaign?.currency} />
              </Panel>
            </Grid>
            <Grid className={shared.grid}>
              <Panel className={shared.panel}>
                <Stat name="Sent" value={campaign?.emailsSent} />
              </Panel>
              <Panel className={shared.panel}>
                <Stat name="Capture rate" value={campaign?.captureRate} type="%" />
              </Panel>
            </Grid>
            <Grid className={shared.grid}>
              <Panel className={shared.panel}>
                <Stat name="Opened" value={campaign?.emailsOpened} />
              </Panel>
              <Panel className={shared.panel}>
                <Stat name="Open rate" value={campaign?.openRate} type="%" />
              </Panel>
            </Grid>
            <Grid className={shared.grid}>
              <Panel className={shared.panel}>
                <Stat name="Conversions" value={campaign?.emailConversions} />
              </Panel>
              <Panel className={shared.panel}>
                <Stat name="Conversion rate" value={campaign?.conversionRate} type="%" />
              </Panel>
            </Grid>
          </StatsPanel>
        </div>
      ))}
    </>
  );
};

export default EmailRemarketingStats;
