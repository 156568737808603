import { chain, max, required, url as validateUrl } from 'frontend/form/validators';

const validateGreeting = (currentLanguage, greeting) => {
  if (!greeting) return undefined;

  const { title, message, url, enabled } = greeting;

  if (!enabled?.[currentLanguage]) return undefined;

  const titleError = chain(max(50), required)(title?.[currentLanguage]);
  if (titleError) return { title: { [currentLanguage]: titleError } };

  const messageError = chain(max(200), required)(message?.[currentLanguage]);
  if (messageError) return { message: { [currentLanguage]: messageError } };

  const urlError = validateUrl(url);
  if (urlError) return { url: urlError };

  return undefined;
};

export const validatePushGreetings = (currentLanguage, { pushGreetings }) => {
  for (let idx = 0; idx < pushGreetings?.length || 0; idx += 1) {
    const error = validateGreeting(currentLanguage, pushGreetings[idx]);
    if (error) return { pushGreetings: [...Array(idx), error] };
  }

  return undefined;
};
