import { Pagination } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import { usePagination } from 'frontend/hooks';

import styles from './ModalMessageList.scss';
import MessageListElement from '../../components/MessageListElement/MessageListElement';

const PAGE_SIZE = 8;

const ModalMessageList = ({
  args: { chatmessageIds },
  hide,
}: {
  args: { chatmessageIds: string[] };
  hide: () => void;
}) => {
  const [currentMessageList, currentPage, pages, setPage] = usePagination({
    objects: chatmessageIds,
    pageSize: PAGE_SIZE,
  });

  return (
    <Modal title="User messages" hideCancel onOkText="Ok" hide={hide} onOk={hide}>
      <Modal.Content>
        <ul className={styles.messageList}>
          {currentMessageList.map((chatMessageId, index) => (
            // MessageIds should be unique, because they cannot be reordered, we also use the index as key, just in case.
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${chatMessageId}${index}`}>
              <MessageListElement chatMessageId={chatMessageId} />
            </li>
          ))}
          <Pagination currentPage={currentPage} pages={pages} setPage={setPage} />
        </ul>
      </Modal.Content>
    </Modal>
  );
};

export default ModalMessageList;
