import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { ChildrenType } from 'frontend/propTypes';

import { TAG_COMPONENT_MAPPING } from '../../constants';
import { useComposerState } from '../../hooks';
import { getItem, removeTagByPosition } from '../../utils';

const CustomTag = ({ children, contentState, entityKey, blockKey, start, end, name }) => {
  const { state, setState } = useComposerState(name);
  const draftEntity = contentState.getEntity(entityKey);
  const type = draftEntity.getType();
  const draftEntityData = draftEntity.getData();
  const item = getItem(draftEntityData);
  const { original } = draftEntityData;
  const TagComponent = TAG_COMPONENT_MAPPING[type];

  const removeTag = useCallback(
    () => removeTagByPosition(state, setState, blockKey, start, end, original),
    [blockKey, end, original, setState, start, state],
  );

  if (!item) return children;
  const { hue, items, description, detail, entity: slotEntity } = item;

  return (
    <TagComponent
      items={items}
      hue={hue}
      size="small"
      removeTag={removeTag}
      transparent
      description={description}
      detail={detail}
      slotEntity={slotEntity}
    >
      {children}
    </TagComponent>
  );
};

CustomTag.propTypes = {
  children: ChildrenType.isRequired,
  contentState: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  entityKey: PropTypes.string,
  blockKey: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
};

export default CustomTag;
