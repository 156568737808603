import { capitalize } from 'lodash';
import React, { memo } from 'react';

import { Trash } from 'frontend/assets/icons';
import { Accordion } from 'frontend/components';

import styles from './FieldAccordion.scss';
import FieldHeaderContextProvider from '../../context/FieldContext';
import inputConfigs, { type FieldConfigFieldType, type FieldsConfigType } from '../../utils/configs';
import { inputTypeTitleMap } from '../../utils/constants';
import FieldHeader from '../FieldHeader/FieldHeader';
import FieldOptionsButton from '../FieldOptionsButton/FieldOptionsButton';
import FieldRow from '../FieldRow/FieldRow';
import FormSwitch from '../FormSwitch/FormSwitch';

interface FieldAccordionProps {
  field: {
    id: string;
    inputType: string;
  };
  fieldIndex: number;
  remove: (index: number) => void;
  fieldName: string;
  onPointerDown?: (event: React.PointerEvent<Element>) => void;
  onDragging?: (dragging?: number) => void;
}

const FieldAccordion = ({ field, fieldIndex, onPointerDown, onDragging, remove, fieldName }: FieldAccordionProps) => (
  <Accordion
    isOpen
    title={inputTypeTitleMap[field.inputType] || capitalize(field.inputType)}
    className={styles.fieldAccordion}
    headerRight={
      <div className={styles.fieldActionRow}>
        <FieldOptionsButton
          onDragging={(dragging) => {
            if (onDragging) {
              if (dragging) {
                onDragging(fieldIndex);
              } else {
                onDragging(-1);
              }
            }
          }}
          id={`context-menu-${field.id}`}
          onPointerDown={onPointerDown as (event: React.PointerEvent<Element>) => void}
          options={[
            {
              text: 'Delete',
              color: 'red',
              icon: Trash,
              onClick: (event) => {
                event?.stopPropagation();
                remove(fieldIndex);
              },
            },
          ]}
        />
      </div>
    }
  >
    {inputConfigs[field.inputType].some((config) => config.inputDescription) && (
      <div className={styles.inputDescription}>
        {inputConfigs[field.inputType].find((config) => config.inputDescription)?.inputDescription}
      </div>
    )}
    <FieldHeaderContextProvider values={{ baseFieldName: fieldName, baseFieldIndex: fieldIndex }}>
      <FieldHeader />
      {inputConfigs[field.inputType]?.map((configField) => {
        const fieldTextsToRender = Object.entries(configField);
        // Improvement => define separate property for the key, don't use the property name
        const [[key, property]] = fieldTextsToRender as unknown as [[keyof FieldsConfigType, FieldConfigFieldType]];

        if (key === 'inputDescription') {
          return null;
        }

        return (
          <div key={`${fieldName}-${key}`} className={styles.fieldWrapper}>
            {property.type === 'checkbox' ? (
              <div key={`f-c-${field.id}`} style={{ display: 'flex' }}>
                <FormSwitch property={property} fieldName={`${fieldName}.${key}`} />
                {property.groupWith.map((groupedCheckbox) => (
                  <FormSwitch
                    key={groupedCheckbox.key}
                    property={groupedCheckbox}
                    parse={(value) => groupedCheckbox.identity?.(value)}
                    fieldName={groupedCheckbox.getName?.({ fieldName, key }) as string}
                  />
                ))}
              </div>
            ) : (
              <FieldRow propertyKey={key} property={property} />
            )}
          </div>
        );
      })}
    </FieldHeaderContextProvider>
  </Accordion>
);

const arePropsEqual = (oldProps, newProps) =>
  oldProps.onPointerDown === newProps.onPointerDown &&
  oldProps.field.id === newProps.field.id &&
  oldProps.field.inputType === newProps.field.inputType;

export default memo(FieldAccordion, arePropsEqual);
