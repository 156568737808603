import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { LoaderSwitch } from 'frontend/components';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { getBuildUrl } from 'frontend/features/Library/utils';
import { Modal } from 'frontend/features/Modals';
import { useCurrentLanguage } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';

import styles from './AddKindlyEntitiesReport.scss';
import DialogueReport from './DialogueReport';
import EmptyState from './EmptyState';
import Finished from './Finished';
import Inserting from './Inserting';
import { useReport } from './hooks';
import useKindlyEntities from '../../hooks/useKindlyEntities';
import { INSERT_FOUND_KINDLY_ENTITIES, REMOVE_KINDLY_ENTITY } from '../../mutations';

const getDialogueUrl = (botId, dialogueId) =>
  getBuildUrl({ buildIdObject: { botId }, dialogueType: DIALOGUE_TYPES.REGULAR, target: dialogueId });

const AddKindlyEntitiesReport = ({ hide, args }) => {
  const { kindlyEntityReportId, botId, addedKindlyEntityIds, hideFirstModal } = args;
  const [{ currentLanguage }] = useCurrentLanguage();
  const [isInserting, setIsInserting] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const [insertFoundKindlyEntities] = useMutation(INSERT_FOUND_KINDLY_ENTITIES);
  const [removeKindlyEntity] = useMutation(REMOVE_KINDLY_ENTITY);

  const {
    kindlyEntities,
    loading: kindlyEntitiesLoading,
    refetch,
  } = useKindlyEntities({
    buildIdObject: { botId },
    currentLanguage,
  });

  const {
    foundKindlyEntities,
    next,
    previous,
    currentPage,
    currentDialogue,
    loading: reportLoading,
  } = useReport({
    kindlyEntityReportId,
    botId,
  });
  const loading = reportLoading || kindlyEntitiesLoading;
  const showReport = !loading && foundKindlyEntities && !isInserting && !isFinished;

  const removeAddedKindlyEntities = useCallback(() => {
    addedKindlyEntityIds.map((id) => removeKindlyEntity({ variables: { botId, id } }));
  }, [addedKindlyEntityIds, botId, removeKindlyEntity]);

  const close = useCallback(() => {
    if (foundKindlyEntities && !isFinished) removeAddedKindlyEntities();
    hide();
    hideFirstModal();
  }, [foundKindlyEntities, hide, hideFirstModal, isFinished, removeAddedKindlyEntities]);

  useEffect(() => {
    // If no Kindly entities were found we don't need to insert any but should update the cache
    if (!loading && !foundKindlyEntities) refetch();
  }, [foundKindlyEntities, loading, refetch]);

  const onOk = useCallback(() => {
    if (!foundKindlyEntities || isFinished) {
      hide();
      hideFirstModal();
      return;
    }

    setIsInserting(true);
    insertFoundKindlyEntities({ variables: { id: kindlyEntityReportId, botId } });
  }, [foundKindlyEntities, isFinished, insertFoundKindlyEntities, kindlyEntityReportId, botId, hide, hideFirstModal]);

  return (
    <Modal
      title="Kindly entities in existing dialogues"
      hide={close}
      onOk={onOk}
      onOkText={foundKindlyEntities && !isFinished ? 'Insert entities' : 'Ok'}
      footer={isFinished || !isInserting}
      hideCancel={!foundKindlyEntities || isFinished}
      closeOnSubmit={false}
    >
      <Modal.Content>
        <LoaderSwitch size="large" loading={loading} noMargin>
          {!loading && !foundKindlyEntities && <EmptyState />}
          {showReport && (
            <>
              <span>
                The following dialogues were found to be suitable for inserting Kindly entities. If you want to enable
                Kindly entities they must be inserted, or the bot will not function as it should.
              </span>
              <br />
              <br />
              <h3>Dialogues</h3>
              <br />
              <div className={styles.header}>
                <button onClick={previous} type="button">
                  {'<'}
                </button>
                <div>
                  <Link to={getDialogueUrl(botId, currentDialogue.id)} target="_blank" rel="noopener">
                    {currentDialogue.name}
                  </Link>
                  ({currentPage})
                </div>
                <button onClick={next} type="button">
                  {'>'}
                </button>
              </div>
              <DialogueReport
                dialogue={currentDialogue}
                kindlyEntities={kindlyEntities}
                currentLanguage={currentLanguage}
              />
            </>
          )}
          {isInserting && !isFinished && <Inserting setIsFinished={setIsFinished} />}
          {isFinished && <Finished />}
        </LoaderSwitch>
      </Modal.Content>
    </Modal>
  );
};

AddKindlyEntitiesReport.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    kindlyEntityReportId: IDType.isRequired,
    addedKindlyEntityIds: PropTypes.arrayOf(IDType).isRequired,
    botId: IDType.isRequired,
    hideFirstModal: PropTypes.func.isRequired,
  }).isRequired,
};

export default AddKindlyEntitiesReport;
