import defaultAvatar from 'frontend/assets/images/member_avatar.svg?url';
import { timeAgo } from 'frontend/utils';

import styles from './ActivityGroupTitle.scss';
import { BUILD_ACTION } from '../../constants';
import { ActivityType } from '../../propTypes';

const getName = (actionBy) => {
  if (!actionBy) return 'Deleted user';
  const { username, profile: { fullName } = {} } = actionBy;
  return fullName ?? username;
};

const isUpdateAction = (action) => BUILD_ACTION[action] === BUILD_ACTION.DIALOGUE_UPDATE;

const getActivityDescription = ({ numActivities, action }) => {
  if (isUpdateAction(action) || numActivities === 1) return `${BUILD_ACTION[action]} a dialogue`;

  return `${BUILD_ACTION[action]} ${numActivities} dialogues`;
};

const ActivityGroupTitle = ({ activity: { actionBy, action, numActivities, updatedAt } }) => {
  const name = getName(actionBy);

  return (
    <div className={styles.container}>
      <div className={styles.action}>
        <div className={styles.avatar}>
          <img alt={name} src={actionBy?.profile?.avatarUrl || defaultAvatar} />
        </div>
        <span>{name}</span>
        <span>{getActivityDescription({ action, numActivities })}</span>
      </div>
      <span>{timeAgo(updatedAt)}</span>
    </div>
  );
};

ActivityGroupTitle.propTypes = {
  activity: ActivityType.isRequired,
};

export default ActivityGroupTitle;
