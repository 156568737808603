import { gql } from '@apollo/client';

export default gql`
  fragment BotBackup on BotBackupType {
    id
    name
    status
    createdAt
    fileSize
    fileName
  }
`;
