import cx from 'classnames';
import { useCallback } from 'react';

import { useKeyDown } from 'frontend/hooks';

import styles from './SortHeader.scss';

interface SortHeaderProps {
  /** Name of the header. */
  title: string;
  /** The order type of this header. */
  orderType: string;
  /** Function to set the order type equal to this header, or if it's already like that, trigger the sorting order of the column (descending/ascending). */
  setOrderType(orderType): void;
  /** The order type selected at the moment among all headers. */
  currentOrderType: string;
  /** Whether you want to sort in ascending or descending order. */
  descending: boolean;
  /** Set the state about descending/ascending column sorting. */
  setDescending(order: boolean): void;
}

/** Header to use in a Table.
 *
 * It's clickable to sort the items by type (among all headers) and by ascending/descending order (in its column). */
const SortHeader = ({
  title,
  orderType,
  setOrderType,
  currentOrderType,
  descending,
  setDescending,
}: SortHeaderProps) => {
  const hasCurrentOrderType = currentOrderType === orderType;
  const headerStyles = cx(styles.sortHeader, { [styles.sortHeaderSelected]: hasCurrentOrderType });
  const arrowStyles = cx(styles.sortHeaderArrow, { [styles.sortHeaderArrowUp]: !descending });
  const arrow = descending ? '⌄' : '⌃';

  const onClick = useCallback(
    () => (hasCurrentOrderType ? setDescending(!descending) : setOrderType(orderType)),
    [descending, hasCurrentOrderType, orderType, setDescending, setOrderType],
  );
  const onKeyDown = useKeyDown({ action: onClick });

  return (
    <span className={headerStyles} onClick={onClick} onKeyDown={onKeyDown} role="button" tabIndex={0}>
      {title}
      {hasCurrentOrderType && <span className={arrowStyles}>{arrow}</span>}
    </span>
  );
};

export default SortHeader;
