import { gql } from '@apollo/client';

import { EVALUATION_DIALOGUE_FRAGMENT } from './fragments';

const GET_EVALUATION_DIALOGUES = gql`
  query GetDialoguesForEvaluation($languageCode: String!, $botId: ID!, $folderId: ID) {
    eligibleDialogues(botId: $botId, languageCode: $languageCode, folderId: $folderId) {
      ...EvaluationDialogue
    }
  }
  ${EVALUATION_DIALOGUE_FRAGMENT}
`;
export default GET_EVALUATION_DIALOGUES;
