import { isEmpty } from 'lodash';

const ANONYMOUS_NAME = 'Anonymous';

const avatarToLastName = (avatar) => avatar.replace(/[A-Z]/g, (m) => ` ${m.toLowerCase()}`);

const getAutoUserName = (autoUser) => `${autoUser.colorName} ${avatarToLastName(autoUser.avatar)}`;

export default (chat) => {
  if (!chat) {
    console.warn('Received unexpected argument while getting username in Inbox');
    return '';
  }

  const { fullName, autoUser } = chat;
  if (fullName && fullName !== ANONYMOUS_NAME) return fullName;
  if (isEmpty(autoUser)) return '';
  return getAutoUserName(autoUser);
};
