/* eslint-disable camelcase */
import { parseISO } from 'date-fns';

import palette from 'frontend/components/Stats/Palette';
import type { TimeSeriesData } from 'frontend/components/Stats/components/LineChart/types';

export const sageSeriesToLineData = (
  data,
  yKey = 'count',
  rangeId,
  color = palette[0],
): {
  id: string;
  color: string;
  data: TimeSeriesData;
} => ({
  id: rangeId,
  color,
  data: (data || []).map((item) => {
    const x = parseISO(item.date);
    return {
      displayX: x,
      x,
      y: item[yKey],
      color,
    };
  }),
});

export const sageSeriesToMultiLineData = (data, yKey = 'count', customLineGroups = null) => {
  const lineGroups = customLineGroups || Object.keys(data?.[0]?.[yKey] || {});
  const mappedLines = lineGroups.map((group, index) => {
    const lineDataForGroup = data.map((item) => ({ [group]: item[yKey][group], date: item.date }));
    return sageSeriesToLineData(lineDataForGroup, group, `${group}`, palette[index % palette.length]);
  });

  return mappedLines;
};
