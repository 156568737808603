import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { AvailableBotsDocument } from 'frontend/api/generated';
import { addSkillSubscription } from 'frontend/api/mutations';
import logo from 'frontend/assets/images/logo.svg?url';
import { Button, CenteredComponent, LoaderSwitch, Select } from 'frontend/components';
import { Pusher, getPusherChannel } from 'frontend/features/Pusher';
import { useIsRunning, useToast } from 'frontend/hooks';

import styles from './SubscribeToSkill.scss';

const SubscribeToSkill = () => {
  const { cache } = useApolloClient();
  const toast = useToast();
  const { skillId: slug } = useParams();
  const [addSubscription] = useMutation(addSkillSubscription);
  const { loading, data, refetch } = useQuery(AvailableBotsDocument, { variables: { slug: slug! } });
  const availableBots = useMemo(() => data?.skillBySlug?.availableBots ?? [], [data]);

  const onSubmit = useCallback(
    async ({ botId }) => {
      if (!data?.skillBySlug) {
        return;
      }
      await addSubscription({ variables: { botId, skillId: data.skillBySlug.id } });
      toast.success('Successful subscription');
      cache.modify({
        id: cache.identify(data.skillBySlug),
        fields: {
          availableBots: (current, { readField }) => current.filter((field) => readField('id', field) !== botId),
        },
      });
    },
    [addSubscription, cache, data, toast],
  );

  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmit);

  return (
    <div className={`${styles.wrapper}`}>
      <LoaderSwitch loading={loading} size="large">
        <Form
          onSubmit={onSubmitWithRunning}
          render={({ handleSubmit }) => (
            <form className={styles.form} onSubmit={handleSubmit} autoComplete="off">
              <img className={styles.logo} src={logo} alt="Kindly logo" />
              <CenteredComponent>
                <div className={styles.content}>
                  <h1 className={`${styles.title} m-b-md`}>
                    Subscribe to <span className={styles.skillName}>{data?.skillBySlug?.name}</span>
                  </h1>
                  <label htmlFor="select-bot">Which bot should subscribe to this skill?</label>
                  <div className="m-b-lg">
                    <Field component={Select} name="botId" id="select-bot">
                      <Select.Option label="Select bot" value={0} />
                      {availableBots.map(({ name, projectName, id }) => (
                        <Select.Option
                          label={`${name} ${projectName ? `(${projectName})` : ''}`}
                          value={id}
                          key={`bot-${id}`}
                        />
                      ))}
                    </Field>
                  </div>
                  <Button
                    className={styles.submit}
                    type="submit"
                    color="primary"
                    text="Subscribe"
                    isSubmitting={isSubmitting}
                  />
                </div>
              </CenteredComponent>
            </form>
          )}
        />
        {!loading && (
          <Pusher
            channel={getPusherChannel({ skillId: data?.skillBySlug?.id })}
            event="skill-subscribers-updated"
            onUpdate={refetch}
          />
        )}
      </LoaderSwitch>
    </div>
  );
};

export default SubscribeToSkill;
