import { differenceInCalendarDays, format } from 'date-fns';
import PropTypes from 'prop-types';

import { friendlyTimestamp } from 'frontend/utils';
import { DATE_FORMAT_WITH_FULL_TIME } from 'frontend/utils/date';

import styles from './ConversationLogItem.scss';

const formatDate = (timestamp) => {
  const daysFromToday = differenceInCalendarDays(new Date(), timestamp);

  if (daysFromToday === 0) return 'Today';
  if (daysFromToday === 1) return 'Yesterday';

  return friendlyTimestamp(timestamp, { monthName: true });
};

const TimeStamp = ({ timestamp }) => {
  const date = formatDate(timestamp);
  return (
    <h4 className={styles.timestamp} title={format(timestamp, DATE_FORMAT_WITH_FULL_TIME)}>
      <span>{date}</span>
    </h4>
  );
};

TimeStamp.propTypes = {
  timestamp: PropTypes.string.isRequired,
};

export default TimeStamp;
