import cx from 'classnames';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Search } from 'frontend/assets/icons';
import { Badge, Button, Icon, Input, NoHits, PageContent, Radio, Table } from 'frontend/components';
import type { Column } from 'frontend/components/Table/Table';
import { BotType } from 'frontend/propTypes';
import type { Language } from 'frontend/types/language';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import styles from './styles.scss';
import useAdminBots from './useAdminBots';
import { botFilters } from '../../constants';

const NameCell = ({ rowData: bot }) => (
  <>
    <Link to={`/workspace/${bot.id}`}>{bot.name}</Link>
    {bot.archivedAt && (
      <Badge
        title="Archived"
        tooltip={`Archived at ${format(bot.archivedAt, DATE_FORMAT_WITH_TIME)}`}
        className={styles.tableBadge}
      />
    )}
  </>
);

function LanguagesCell({ data: languages }: { data: Language[] }) {
  return (
    <div>
      {languages.map(({ code }) => (
        <span key={code} className={styles.languageCode}>
          {code}
        </span>
      ))}
    </div>
  );
}

const ActionsCell = ({ rowData: bot }) => <Button size="small" text="View" to={`/workspace/${bot.id}`} />;

const OrganizationCell = ({ rowData: bot }) => (
  <Link to={`/organization/${bot.organization.id}`}>{bot.organization.name}</Link>
);

const columns = [
  { key: 'name', component: NameCell },
  { key: 'languages', component: LanguagesCell },
  { key: 'organization', component: OrganizationCell },
  { key: 'actions', component: ActionsCell },
];

const AdminBots = () => {
  const { archivedInput, archived, bots, loading, noHits, onResetClick, pagination, searchInput } = useAdminBots();

  const handleFilterChange = useCallback(
    (e) => {
      const { value } = e.currentTarget;
      archivedInput(value);
    },
    [archivedInput],
  );

  return (
    <PageContent>
      <div className={styles.header}>
        <Input
          input={searchInput}
          className={styles.search}
          placeholder="Search workspaces"
          aria-label="Search workspaces"
          adornment={<Icon component={Search} />}
          adornmentPosition="left"
          autoFocus
        />
        {typeof archived !== 'undefined' && <Button flat onClick={onResetClick} text="Reset" />}
      </div>
      <div className={styles.botFilters}>
        <Radio
          className={cx(styles.button)}
          label={botFilters.ALL}
          input={{
            checked: typeof archived === 'undefined',
            value: botFilters.ALL,
            name: 'filter',
            onChange: handleFilterChange,
          }}
        />
        <Radio
          className={cx(styles.button)}
          label={botFilters.ACTIVE}
          input={{
            checked: archived === false,
            value: botFilters.ACTIVE,
            name: 'filter',
            onChange: handleFilterChange,
          }}
        />
        <Radio
          className={cx(styles.button)}
          label={botFilters.ARCHIVED}
          input={{
            checked: archived,
            value: botFilters.ARCHIVED,
            name: 'filter',
            onChange: handleFilterChange,
          }}
        />
      </div>
      <Table columns={columns as Column[]} data={bots} loading={loading} pagination={pagination} />
      {noHits && <NoHits name="workspaces" />}
    </PageContent>
  );
};

NameCell.propTypes = {
  rowData: BotType.isRequired,
};

ActionsCell.propTypes = {
  rowData: BotType.isRequired,
};
OrganizationCell.propTypes = {
  rowData: BotType.isRequired,
};

export default AdminBots;
