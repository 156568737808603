import cx from 'classnames';
import React, { forwardRef } from 'react';
import { NavLink, type To } from 'react-router-dom';

import styles from './List.scss';

interface NavLinkItemProps {
  children: React.ReactNode;
  className?: string;
  to: To;
  ignoreActive?: boolean;
  onClick?: (evt: React.MouseEvent<HTMLAnchorElement>) => unknown;
}

const NavLinkItem = forwardRef(
  (
    { children, className, to, onClick, ignoreActive, ...restProps }: NavLinkItemProps,
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) => (
    <NavLink
      ref={ref}
      className={({ isActive }) =>
        cx(styles.navLinkItem, isActive && !ignoreActive ? styles.navLinkItemActive : undefined, className)
      }
      onClick={onClick}
      to={to}
      {...restProps}
    >
      {children}
    </NavLink>
  ),
);

NavLinkItem.displayName = 'NavLinkItem';

export interface ListItemProps {
  children?: React.ReactNode;
  onClick?(event?: React.MouseEvent<HTMLDivElement>): void;
  className?: string;
  tabIndex?: number;
  'data-testid'?: string;
}

const Item = ({
  children,
  onClick = () => undefined,
  className,
  tabIndex = 0,
  'data-testid': testId,
}: ListItemProps) => (
  <div
    onClick={onClick}
    tabIndex={tabIndex}
    onKeyDown={(e) => e.key === ' ' && onClick()}
    className={`${styles.item} ${className}`}
    role="button"
    data-testid={testId}
  >
    {children}
  </div>
);

interface ListProps<P extends Record<string, unknown>> {
  /** The width of the list. */
  width?: string;
  /** Function to render a header element. */
  renderHeader?(): React.ReactNode;
  /** Function to render a component, like `<List.Item>`. */
  renderItem(props: [string, P] | P): React.ReactNode | null;
  /** Function to render a footer element. */
  renderFooter?(): React.ReactNode;
  /** Array of objects containing info about the list items. */
  dataSource: P[] | [string, P][];
  className?: string;
  headerClassName?: string;
  /** Classname for the div wrapping the list items. */
  bodyClassName?: string;
}

/** Render a list of elements.
 *
 * It has a `<List.Item>` property to accommodate data objects.
 */
const List = <P extends Record<string, unknown>>({
  width = 'auto',
  renderHeader,
  renderFooter,
  renderItem,
  dataSource,
  headerClassName,
  bodyClassName,
  className,
}: ListProps<P>) => (
  <div className={cx(styles.list, className)} style={{ width: width || 'auto' }}>
    {renderHeader && <div className={cx(styles.header, headerClassName)}>{renderHeader()}</div>}
    <div className={cx(styles.main, bodyClassName)}>{dataSource.map(renderItem)}</div>
    {renderFooter && <div className={styles.footer}>{renderFooter()}</div>}
  </div>
);

List.Item = Item;
List.NavLinkItem = NavLinkItem;

export default List;
