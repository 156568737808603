import cx from 'classnames';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';

import { Filter } from 'frontend/assets/icons';
import { Button, Dropdown, Search, Table } from 'frontend/components';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import { usePagination } from 'frontend/hooks';

import styles from './BuildResources.scss';
import { useColumns } from './hooks';
import { DASHBOARD_VIEW } from '../../constants';
import FilterOptions from '../Buttons/FilterOptions';
import { buttonTypes } from '../Buttons/constants';
import EmptyState from '../EmptyState';

const PAGE_SIZE = 20;

type Props = {
  resources: {
    key: string;
    dialogue: {
      id: string;
      title: string;
      dialogueType: DialogueType;
    };
  }[];
  columnFields: string[];
  orderedColumns: string[];
  dashboardView: keyof typeof DASHBOARD_VIEW;
  loading: boolean;
  buildType: string;
  noData: boolean;
  renderMap: Record<string, (data: any) => React.JSX.Element>;
};

function BuildResources({
  resources,
  columnFields,
  orderedColumns,
  dashboardView,
  loading,
  buildType,
  noData,
  renderMap = {},
}: Props) {
  const isWebhook = dashboardView === DASHBOARD_VIEW.WEBHOOKS;
  const isImage = dashboardView === DASHBOARD_VIEW.IMAGES;
  const [descending, setDescending] = useState(false);
  const [orderByField, setOrderByField] = useState(() =>
    orderedColumns?.length > 0 ? orderedColumns[0] : columnFields[0],
  );

  const columns = useColumns({
    columnFields,
    orderByField,
    setOrderByField,
    descending,
    setDescending,
    orderedColumns,
    renderMap,
  });

  const [searchResults, setSearchResults] = useState(resources);

  const orderedResults = useMemo(
    () => orderBy(searchResults, orderByField, descending ? 'desc' : undefined),
    [descending, orderByField, searchResults],
  );

  const [currentObjects, page, pages, setPage] = usePagination({ objects: orderedResults, pageSize: PAGE_SIZE });

  const pagination = useMemo(
    () => ({
      currentPage: page,
      pages,
      setPage,
      summary: {
        totalCount: orderedResults.length,
        firstVisible: (page - 1) * PAGE_SIZE + 1,
        lastVisible: page === pages ? orderedResults.length : page * PAGE_SIZE,
      },
    }),
    [page, pages, setPage, orderedResults],
  );

  if (noData) {
    const resourceName = (() => {
      if (isWebhook) {
        return 'webhook';
      }
      if (isImage) {
        return 'image';
      }
      return 'button';
    })();

    return (
      <EmptyState
        className="m-t-7"
        headLine={`No ${resourceName}s found in this ${buildType} in this language`}
        text={`You can use this page to get an overview of ${resourceName}s in the ${buildType}, after you have created some dialogues with ${resourceName}s.`}
        dashboardView={dashboardView}
      />
    );
  }

  return (
    <>
      <div
        className={cx(styles.filterWrapper, 'm-t-3', {
          [styles.filterWrapperWebhook]: isWebhook,
        })}
      >
        <Search
          searchItems={resources}
          searchKey={orderedColumns || columnFields}
          setSearchResults={setSearchResults}
          className={styles.searchInput}
          placeholder="Search"
          aria-label="Search"
        />
        {!(isWebhook || isImage) && (
          <Dropdown element="div" overlay={<FilterOptions buttonTypes={buttonTypes} />}>
            <Button icon={Filter}>Filter</Button>
          </Dropdown>
        )}
      </div>
      <Table data={currentObjects} columns={columns} pagination={pagination} loading={loading} />
    </>
  );
}

export default BuildResources;
