import { diffChars, diffWords } from 'diff';
import { useMemo } from 'react';

import DefaultComponent from './DefaultComponent';

// The diff is returned in an array with removed text followed by added, grouping the two
export const groupDiffSegments = (segments) =>
  segments.reduce((grouped, { value, removed, added }, idx) => {
    const notLastIndex = idx < segments.length - 1;
    const nextSegment = segments[idx + 1];
    if (notLastIndex && removed && nextSegment.added) {
      return [...grouped, { value, alteredValue: nextSegment.value }];
    }

    if (removed) return [...grouped, { value, removed: true }];

    if (idx > 0 && added && segments[idx - 1].removed) return grouped;
    return [...grouped, { value }];
  }, []);

interface TextDiffProps {
  component?: React.ElementType;
  diffByCharacters?: boolean;
  extra?: Record<string, unknown>;
  originalText: string;
  updatedText: string;
}

function TextDiff({
  component: Component = DefaultComponent,
  diffByCharacters,
  extra,
  originalText,
  updatedText,
}: TextDiffProps) {
  const segments = useMemo(() => {
    const diff = diffByCharacters ? diffChars : diffWords;
    const diffResult = diff(originalText, updatedText, { ignoreCase: true });
    return groupDiffSegments(diffResult);
  }, [diffByCharacters, originalText, updatedText]);

  return segments.map(({ value, alteredValue, removed, added }, idx) => (
    <Component
      /* eslint-disable-next-line react/no-array-index-key */
      key={idx}
      value={value}
      alteredValue={alteredValue}
      removed={removed}
      added={added}
      extra={extra}
      diffByCharacters={diffByCharacters}
    />
  ));
}

export default TextDiff;
