import cx from 'classnames';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { Button } from 'frontend/components';

import styles from '../../Invitation.scss';
import { InvitationType } from '../../propTypes';
import ContentBox from '../ContentBox';
import Info from '../Info';

const AcceptForm = ({ accept, invitation }) => (
  <ContentBox noGrid>
    <Form
      onSubmit={accept}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={styles.createUser} autoComplete="off">
          <Info invitation={invitation} />
          <div className={cx(styles.buttonContainer, 'm-t-4')}>
            <Button color="primary" type="submit" text="Accept invitation" />
          </div>
        </form>
      )}
    />
  </ContentBox>
);

AcceptForm.propTypes = {
  accept: PropTypes.func.isRequired,
  invitation: InvitationType.isRequired,
};

export default AcceptForm;
