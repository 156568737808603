import { Link } from 'react-router-dom';

import styles from '../styles.scss';

interface Props {
  to?: string;
  children: React.ReactNode;
}

const ToastWrapper = ({ to, children }: Props) => {
  if (to) {
    return (
      <Link to={to} className={styles.toastContent}>
        {children}
      </Link>
    );
  }

  return <div className={styles.toastContent}>{children}</div>;
};

export default ToastWrapper;
