import { gql } from '@apollo/client';

export default gql`
  query BotSearch(
    $botId: ID!
    $languageCode: String!
    $query: String!
    $colors: [String!]
    $types: [String!]
    $labels: [String!]
  ) {
    bot(id: $botId) {
      id
      searchNudges(query: $query) {
        ... on FormNudgeType {
          id
          slug
          enabled
          title
          type
        }

        ... on ProductNudgeType {
          id
          slug
          enabled
          title
          type
        }

        ... on TextNudgeType {
          id
          slug
          enabled
          title
          type
        }

        ... on CustomNudgeType {
          id
          slug
          enabled
          title
          type
        }

        ... on MultipleChoiceNudgeType {
          id
          slug
          enabled
          title
          type
        }
      }
      search(query: $query, languageCode: $languageCode, colors: $colors, types: $types, labels: $labels) {
        id
        title
        dialogueType
        isActive
        samplesCount
        numberOfFollowups
        colorLabel
        labels {
          id
          name
        }

        url(botId: $botId)
        skill {
          id
          skillSubscriptions {
            id
            bot {
              id
            }
          }
        }
      }
    }
  }
`;
