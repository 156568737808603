import { gql } from '@apollo/client';

import { SIMPLE_EVALUATION_FRAGMENT } from './fragments';

const GET_SIMPLE_EVALUATION = gql`
  query GetSimpleEvaluation($evaluationId: ID!) {
    simpleEvaluation(evaluationId: $evaluationId) {
      ...SimpleEvaluation
    }
  }
  ${SIMPLE_EVALUATION_FRAGMENT}
`;
export default GET_SIMPLE_EVALUATION;
