import cx from 'classnames';

import styles from './Input.scss';

interface InputLimitProps {
  hasError?: boolean;
  currentInput?: number;
  limit?: number;
}

const InputLimit = ({ hasError, currentInput, limit }: InputLimitProps) => {
  const classNames = cx(styles.inlineLimit, {
    [styles.inlineLimitError]: hasError === true,
    [styles.aboveLimit]: limit && currentInput && currentInput > limit,
  });

  return (
    <div className={classNames}>
      <span>{`${currentInput}/${limit}`}</span>
    </div>
  );
};

export default InputLimit;
