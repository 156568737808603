import { Button } from 'frontend/components';
import ResetFormModal from 'frontend/components/PageBar/ResetFormModal';
import { useModal } from 'frontend/features/Modals';
import { useFormChanges } from 'frontend/hooks';

import styles from './styles.scss';

export default function FormButtons({ openModal, handleCancel, handleSubmit, isSubmitting }) {
  const [showResetFormModal] = useModal(ResetFormModal);
  const hasChanged = useFormChanges();

  return (
    <div className={styles.buttonContainer}>
      {hasChanged && handleCancel && (
        <Button flat onClick={() => showResetFormModal({ handleCancel })}>
          Clear changes
        </Button>
      )}
      <Button
        disabled={!hasChanged}
        className={styles.saveButton}
        onClick={() => !isSubmitting && handleSubmit()}
        color={hasChanged ? 'primary' : 'secondary'}
        isSubmitting={isSubmitting}
      >
        {hasChanged ? 'Save' : 'Saved'}
      </Button>
      <Button color="warning" onClick={() => openModal()}>
        Delete
      </Button>
    </div>
  );
}
