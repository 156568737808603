import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { removeListItemById } from 'frontend/api/cacheHelpers';
import { getChatPath } from 'frontend/features/Inbox/utils';
import { useToast } from 'frontend/hooks';

export default ({ botId, organizationId, chatsAndTickets, cache, chatId }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const onChatDeleted = useCallback(
    ({ id }) => {
      cache.modify({
        id: cache.identify(chatsAndTickets),
        fields: { chats: removeListItemById(id) },
      });

      if (chatId && chatId === id) {
        navigate(getChatPath('chat/deleted', { botId, organizationId }));
        toast.success('Conversation was deleted by another operator');
      }
    },
    [botId, chatsAndTickets, cache, chatId, organizationId, navigate, toast],
  );

  return onChatDeleted;
};
