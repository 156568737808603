import { useMemo } from 'react';

import LinkList from './LinkList';

const OrganizationList = ({ rowData: user }) => {
  const collection = useMemo(
    () => user.organizationMemberships.map((om) => om.organization),
    [user.organizationMemberships],
  );
  return <LinkList type="organization" collection={collection} size="small" />;
};

export default OrganizationList;
