import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { IDType } from 'frontend/propTypes';

import { DeleteMessageClustersReport } from '../../modals';

const DeleteReport = ({ botId, reportId, stopPolling }) => {
  const [showDeleteModal] = useModal(DeleteMessageClustersReport);
  const onClick = useCallback(
    () => showDeleteModal({ botId, reportId, stopPolling }),
    [botId, reportId, showDeleteModal, stopPolling],
  );

  return <Button text="Delete report" color="warning" onClick={onClick} />;
};

DeleteReport.propTypes = {
  botId: IDType.isRequired,
  reportId: IDType.isRequired,
  stopPolling: PropTypes.func.isRequired,
};

export default DeleteReport;
