import { useQuery } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { GetAllNudgeSlugsDocument, type MultipleChoiceNudgeType } from 'frontend/api/generated';
import { Button, FormErrors, Input, Panel, SelectDialogue, ToggleSwitch } from 'frontend/components';
import { GetDialogueList } from 'frontend/features/Build/graphql';
import { chain, max, required } from 'frontend/form/validators';
import { useBotOrSkill, useIsRunning } from 'frontend/hooks';
import { finalFormArraysMutatorsFix } from 'frontend/utils';

import styles from './styles.scss';
import { nudgeDefaultTexts } from '../../constants';
import { useNudges, useSlugValidator, useUpdateNudges } from '../../hooks';
import { Header } from '../Header';
import { Preview } from '../Preview';
import sharedStyles from '../shared.scss';

interface MultipleChoiceNudgeProps {
  nudge?: MultipleChoiceNudgeType;
}

const MultipleChoiceNudge = ({ nudge }: MultipleChoiceNudgeProps) => {
  const ref = useRef();
  const { buildIdObject } = useBotOrSkill();

  const editing = !!nudge;
  const { onMultipleChoiceUpdate } = useUpdateNudges();
  const { botId, onMultipleChoiceCreate, currentLanguage } = useNudges();
  const [onSubmit] = useIsRunning(editing ? onMultipleChoiceUpdate : onMultipleChoiceCreate);
  const { data: allNudges } = useQuery(GetAllNudgeSlugsDocument, { variables: { botId: botId as string } });
  const { validateSlug } = useSlugValidator({ data: allNudges, nudge });

  // TODO get dialogues from hook to remomve duplicate query
  const { data, loading } = useQuery(GetDialogueList, {
    variables: {
      ...buildIdObject,
      includeSubscribedSkills: true,
    },
  });

  return (
    <Panel className={sharedStyles.formPanel}>
      <Form
        mutators={finalFormArraysMutatorsFix(arrayMutators)}
        onSubmit={onSubmit}
        initialValues={nudge || {}}
        render={({
          handleSubmit,
          values,
          form: {
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit} className={sharedStyles.container}>
            <Header
              title={values?.title?.[currentLanguage] ?? nudgeDefaultTexts.title}
              slug={values?.slug ?? nudgeDefaultTexts.slug}
            />
            <Preview>
              <Preview.Intent />
              <Preview.Nudge nudge={values} />
            </Preview>
            <div className={sharedStyles.containerEditor}>
              <div className={sharedStyles.panelHeader}>
                <h3>Multiple choice Nudge</h3>
                <p>Ask a question to get a multiple choice style answer back</p>
              </div>
              <FormErrors className={sharedStyles.formErrors} />
              <div className={sharedStyles.inputContainer}>
                <Field
                  name="slug"
                  label="Slug (this can not be edited later)"
                  component={Input}
                  readOnly={!!nudge}
                  inputLimit={50}
                  validate={chain([required, validateSlug])}
                  placeholder="Choose a slug for your nudge"
                />
              </div>
              <div className={sharedStyles.seperator} />
              <h5>Text Content</h5>
              <div className={`m-b-1 ${sharedStyles.inputContainer}`}>
                <Field
                  name={`title.${currentLanguage}`}
                  label="Title"
                  component={Input}
                  inputLimit={80}
                  validate={chain([required, max(80)])}
                  placeholder="Choose a title"
                />
              </div>
              <div className={sharedStyles.inputContainer}>
                <Field
                  name={`bodyText.${currentLanguage}`}
                  label="Body text"
                  component={Input}
                  inputLimit={255}
                  validate={chain([required, max(255)])}
                  placeholder="Choose a body text"
                  multiline
                />
              </div>
              <div className={sharedStyles.seperator} />
              <h5>Button choices</h5>
              {editing ? (
                <FieldArray name="buttonBlocks">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={name} className={styles.buttonBlock}>
                        <div>
                          <Field
                            name={`${name}.buttonText.${currentLanguage}`}
                            component={Input}
                            label="Button text"
                            placeholder="Your text"
                            inputLimit={80}
                            validate={chain([required, max(80)])}
                          />
                        </div>
                        <div>
                          <div>
                            <label htmlFor={`${name}.dialogueId`}>Trigger dialogue</label>
                            <Field
                              component={SelectDialogue}
                              name={`${name}.dialogueId`}
                              label="Button text"
                              aria-label="Dialogue"
                              placeholder="Dialogue"
                              componentRef={ref}
                              dialogueList={data?.dialogueList}
                              loading={loading}
                              activeOnly
                              allowEmpty
                            />
                          </div>
                        </div>
                        <div>
                          <Button onClick={() => fields.remove(index)} color="warning" text="Remove" />
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              ) : (
                <div>Hit save to create button choices</div>
              )}
              {editing && <Button text="Add button" className="m-t-3" onClick={() => push?.('buttonBlocks')} />}
              <div className={sharedStyles.seperator} />
              <h5>Toggle nudge</h5>
              <div className={`m-b-1 ${sharedStyles.inputContainer}`}>
                <span className={sharedStyles.helpText}>You can toggle this nudge for the selected language</span>
                <div className={sharedStyles.toggleContainer}>
                  <ToggleSwitch name={`enabled.${currentLanguage}`} />
                  <label htmlFor="ToggleSwitch-enabled">
                    {values?.enabled?.[currentLanguage] ? 'Enabled' : 'Disabled'}
                  </label>
                </div>
              </div>
              <div className={sharedStyles.seperator} />
              <p className={styles.helpText}>Setup url for completed purchase</p>
              <div className={`m-b-3 ${styles.inputContainer}`}>
                <Field
                  component={Input}
                  name="analyticsCompletionPagePath"
                  aria-label="Completion Page Url"
                  label="Completion Page Url"
                  placeholder="/order/"
                />
              </div>
            </div>
          </form>
        )}
      />
    </Panel>
  );
};

export default MultipleChoiceNudge;
