import { defineAction } from 'redux-define';

const PLATFORM = defineAction('platform');

export const SET_LANGUAGE = PLATFORM.defineAction('SET_LANGUAGE');
export const TOGGLE_FAKE_DATA = PLATFORM.defineAction('TOGGLE_FAKE_DATA');
export const SET_ORGANIZATION_DELETE_IN_PROGRESS = PLATFORM.defineAction('SET_ORGANIZATION_DELETE_IN_PROGRESS');
export const REMOVE_ORGANIZATION_DELETE_IN_PROGRESS = PLATFORM.defineAction('REMOVE_ORGANIZATION_DELETE_IN_PROGRESS');

export const setCurrentLanguage = (languageCode: string, variantCode?: string) => ({
  type: SET_LANGUAGE.ACTION,
  languageCode,
  variantCode,
});
export const toggleFakeContent = () => ({
  type: TOGGLE_FAKE_DATA.ACTION,
});

export const setOrganizationDeleteInProgress = (organizationId: string) => ({
  type: SET_ORGANIZATION_DELETE_IN_PROGRESS.ACTION,
  organizationId,
});

export const removeOrganizationDeleteInProgress = (organizationId: string) => ({
  type: REMOVE_ORGANIZATION_DELETE_IN_PROGRESS.ACTION,
  organizationId,
});

const initialState: PlatformState = {
  currentLanguage: null,
  currentVariant: null,
  fakeDataEnabled: false,
  organizationDeleteInProgress: [],
};

export const selectState = (state) => state.platform;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE.ACTION:
      return { ...state, currentLanguage: action.languageCode, currentVariant: action.variantCode };
    case TOGGLE_FAKE_DATA.ACTION:
      return { ...state, fakeDataEnabled: !state.fakeDataEnabled };
    case SET_ORGANIZATION_DELETE_IN_PROGRESS.ACTION:
      return {
        ...state,
        organizationDeleteInProgress: [
          ...(state.organizationDeleteInProgress || []),
          { id: action.organizationId, started: new Date() },
        ],
      };
    case REMOVE_ORGANIZATION_DELETE_IN_PROGRESS.ACTION:
      return {
        ...state,
        organizationDeleteInProgress: (state.organizationDeleteInProgress || [])?.filter(
          (org) => org.id !== action.organizationId,
        ),
      };
    default:
      return state;
  }
};
