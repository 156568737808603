import { defineAction } from 'redux-define';

const BOT = defineAction('bot');

export const CHATBUBBLE_SETTINGS_UPDATED = BOT.defineAction('CHATBUBBLE_SETTINGS_UPDATED');

export const chatBubbleSettingsUpdated = (haveUpdated) => ({
  type: CHATBUBBLE_SETTINGS_UPDATED.ACTION,
  haveUpdated,
});

const initialState = {
  chatBubbleSettingsHaveUpdated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHATBUBBLE_SETTINGS_UPDATED.ACTION:
      return { ...state, chatBubbleSettingsHaveUpdated: action.haveUpdated };
    default:
      return state;
  }
};
