import { gql } from '@apollo/client';

export default gql`
  mutation UploadAttachmentsComplete($botId: ID!, $chatId: ID!, $attachmentIds: [ID]!, $temporaryChatMessageId: ID!) {
    uploadAttachmentsComplete(
      botId: $botId
      chatId: $chatId
      attachmentIds: $attachmentIds
      temporaryChatMessageId: $temporaryChatMessageId
    ) {
      id
    }
  }
`;
