import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GetAllOrganizationsDocument } from 'frontend/api/generated';
import { Panel } from 'frontend/components';

import styles from './styles.scss';

const HeaderStats = ({ title, data }) => (
  <div className={styles.statContainer}>
    <span className={styles.statName}>{title}</span>
    <span className={styles.statContent}>{data > 0 ? data : '-'}</span>
  </div>
);

HeaderStats.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
};

const DashboardHeader = () => {
  const { data, loading } = useQuery(GetAllOrganizationsDocument);

  if (loading || !data) return <div>Loading...</div>;

  const { allOrganizations } = data;

  return (
    <Panel>
      <div className={styles.headerContainer}>
        <div className={styles.brandingContainer}>
          <div className={styles.dashboardLogo} />
          <h1 className={styles.dashboardName}>Platform dashboard</h1>
        </div>
        <div className={styles.metaContainer}>
          <HeaderStats title="Organizations" data={allOrganizations.length} />
          {/* Add more stats as the become available */}
        </div>
      </div>
    </Panel>
  );
};

export default DashboardHeader;
