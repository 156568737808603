import React from 'react';

import { Condition } from 'frontend/components';
import { DIALOGUE_TYPES } from 'frontend/constants';
import Samples from 'frontend/features/Samples';
import { useCurrentLanguage } from 'frontend/hooks';

import styles from './DialogueFormInput.scss';
import Intent from '../Intent';
import Keywords from '../Keywords';
import Patterns from '../Patterns';
import Triggers from '../Triggers';

interface Props {
  dialogueData: {
    dialogueId?: string;
    isModDialogue?: boolean;
    parentId?: string;
  };
}

const DialogueFormInput = ({ dialogueData: { isModDialogue, dialogueId, parentId } }: Props): React.JSX.Element => {
  const [{ currentLanguage: currentBaseLanguage }] = useCurrentLanguage();
  return (
    <div className={styles.tabContent}>
      <Condition when="dialogueType" is={DIALOGUE_TYPES.INTENT}>
        <Intent />
      </Condition>
      <Condition when="dialogueType" is={DIALOGUE_TYPES.SAMPLES}>
        <Samples isModDialogue={isModDialogue} currentLanguage={currentBaseLanguage} />
      </Condition>
      <Condition when="dialogueType" is={DIALOGUE_TYPES.KEYWORDS}>
        <Keywords currentLanguage={currentBaseLanguage} isModDialogue={isModDialogue} />
      </Condition>
      <Condition when="dialogueType" is={DIALOGUE_TYPES.PATTERNS}>
        <Patterns isModDialogue={isModDialogue} parentId={parentId} currentLanguage={currentBaseLanguage} />
      </Condition>
      <Condition when="dialogueType" is={DIALOGUE_TYPES.TRIGGER}>
        <Triggers dialogueId={dialogueId} isModDialogue={isModDialogue} />
      </Condition>
    </div>
  );
};

export default DialogueFormInput;
