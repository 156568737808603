import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Modal } from 'frontend/features/Modals';
import { IDType } from 'frontend/propTypes';

import styles from './styles.scss';

const ConfidenceScore = ({ hide, args: { botId } }) => (
  <Modal title="Confidence score" hide={hide} onOk={noop} hideCancel>
    <Modal.Content className={styles.content}>
      <p>
        The chatbot assigns a confidence score to each match (potential reply), ranging from 0 (completely uncertain) to
        100 (completely certain). If the confidence score of the best match/reply is higher than the{' '}
        <Link to={`/workspace/${botId}/settings/general`} onClick={hide}>
          confidence threshold
        </Link>
        , the reply is returned to the user, otherwise, a fallback is triggered.
      </p>
    </Modal.Content>
  </Modal>
);

ConfidenceScore.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ botId: IDType.isRequired }).isRequired,
};

export default ConfidenceScore;
