import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default (
  to,
  { keepQuery, queryParams }: { keepQuery: boolean; queryParams?: string } = {
    keepQuery: false,
    queryParams: undefined,
  },
) => {
  const { search } = useLocation();
  const linkTo = useMemo(
    () => ({ pathname: to, search: keepQuery ? search : queryParams }),
    [to, keepQuery, search, queryParams],
  );

  return linkTo;
};
