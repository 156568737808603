import cx from 'classnames';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { ImageAvatar } from 'frontend/components';
import { ChatStatus } from 'frontend/features/Inbox/components';
import { ContactDetailsType, TicketType } from 'frontend/features/Inbox/propTypes';
import { getUserName, handoverRequestedAfterTicketSolved, hasRequestedContact } from 'frontend/features/Inbox/utils';
import { AutoUserType, IDType, LabelType } from 'frontend/propTypes';
import { friendlyTimestamp, pluralize } from 'frontend/utils';

import AgentAvatar from './AgentAvatar';
import styles from './ChatPreview.scss';
import LabelList from './LabelList';

// TODO: Possibly show active status once we've implemented a more reliable status using heartbeat

const MAX_PREVIEW_LENGTH = 110;

const getChatStatus = ({
  lastMessageIsFromUser,
  solvedAt,
  handedOver,
  requestedHandoverAt,
  hasRequestedHandover,
  completedHandover,
  requestedContact,
}) => {
  const requestedHandover =
    hasRequestedHandover && handoverRequestedAfterTicketSolved({ solvedAt }, { requestedHandoverAt });

  if (requestedHandover) return 'has_requested_handover';
  if (lastMessageIsFromUser && handedOver) return 'awaiting_response';
  if (handedOver) return 'handed_over';
  if (completedHandover) return 'completed_handover';
  if (requestedContact) return 'contact_requested';
  return null;
};

const useLabels = ({ botLabels, labelIds }) => {
  const labels = useMemo(
    () => botLabels.filter(({ id: labelId }) => labelIds.includes(labelId)),
    [botLabels, labelIds],
  );

  return labels;
};

const ChatPreview = ({
  id,
  botId,
  to,
  updated,
  avatarUrl = '',
  autoUser,
  commentsCount = 0,
  fullName,
  subject,
  preview,
  className,
  lastMessageIsFromUser,
  contactDetails,
  hasRequestedHandover = false,
  handoverByAgentId = 0,
  handedOver = false,
  completedHandover = false,
  requestedHandoverAt = null,
  botLabels,
  labelIds = [],
  tickets,
  replaceHistory = false,
}) => {
  const ticket = tickets.find(({ chatId }) => chatId.toString() === id.toString());
  const labels = useLabels({ botLabels, labelIds });

  const { solvedAt, assignedAgent } = ticket ?? {};
  const userName = getUserName({ fullName, autoUser });
  const hasLabels = labels.length > 0;
  const requestedContact = hasRequestedContact({ contactDetails });

  const chatStatus = getChatStatus({
    lastMessageIsFromUser,
    hasRequestedHandover,
    solvedAt,
    handedOver,
    requestedHandoverAt,
    completedHandover,
    requestedContact,
  });

  const shouldDisplayNotification = chatStatus && !completedHandover && lastMessageIsFromUser;

  return (
    <NavLink
      to={to}
      replace={replaceHistory}
      className={({ isActive }) =>
        cx(styles.wrapper, className, {
          [styles.wrapperWithLabelsOrAction]: hasLabels || chatStatus,
          [styles.active]: isActive,
        })
      }
      data-cy="navlink-chat-preview"
    >
      {ChatStatus && <ChatStatus status={chatStatus} notification={shouldDisplayNotification} />}
      {hasLabels && <LabelList labels={labels} />}
      <div className={styles.textContainer}>
        <h4 className={styles.title}>{subject || userName}</h4>
      </div>
      <div className={styles.textContainer}>
        <p className={styles.message}>{truncate(preview, { length: MAX_PREVIEW_LENGTH })}</p>
      </div>
      <div className={styles.metaContainer}>
        <ImageAvatar className={styles.avatar} hash={to} img={avatarUrl} autoUser={autoUser} />
        <span className={styles.time}>{friendlyTimestamp(updated, { monthName: true })}</span>
        {commentsCount > 0 && <span>{`${commentsCount} ${pluralize('Note', commentsCount)}`}</span>}
        <AgentAvatar
          assignedAgent={assignedAgent}
          handoverByAgentId={handoverByAgentId}
          completedHandover={completedHandover}
          ticketSolved={Boolean(solvedAt)}
          botId={botId}
        />
      </div>
    </NavLink>
  );
};

ChatPreview.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]).isRequired,
  updated: PropTypes.string.isRequired,
  preview: PropTypes.string,
  avatarUrl: PropTypes.string,
  autoUser: AutoUserType,
  commentsCount: PropTypes.number,
  fullName: PropTypes.string,
  subject: PropTypes.string,
  className: PropTypes.string,
  lastMessageIsFromUser: PropTypes.bool,
  hasRequestedHandover: PropTypes.bool,
  handoverByAgentId: PropTypes.number,
  contactDetails: ContactDetailsType,
  completedHandover: PropTypes.bool,
  handedOver: PropTypes.bool,
  requestedHandoverAt: PropTypes.string,
  botLabels: PropTypes.arrayOf(LabelType).isRequired,
  labelIds: PropTypes.arrayOf(IDType),
  id: IDType.isRequired,
  botId: IDType.isRequired,
  tickets: PropTypes.arrayOf(TicketType),
  replaceHistory: PropTypes.bool,
};

export default ChatPreview;
