import cx from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { DIALOGUE_TYPES } from 'frontend/constants';
import { useCurrentLanguage } from 'frontend/hooks';
import { addObjects } from 'frontend/utils';

import styles from './DialogueItem.scss';
import { LibraryDialogueType } from '../../propTypes';

const countGetter = (currentLanguage) => (count) => Math.min(get(count, currentLanguage, 0), 999);

const SampleCount = ({ dialogue, active }) => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { dialogueType, samplesCount, expandedSampleCount } = dialogue;

  if (dialogueType !== DIALOGUE_TYPES.SAMPLES) return null;

  const modSamplesCount = get(dialogue, 'mod.modSamplesCount', 0);
  const expandedModSampleCount = get(dialogue, 'mod.expandedModSampleCount', 0);
  const getCount = countGetter(currentLanguage);
  const totalSamples = getCount(addObjects(samplesCount, modSamplesCount));
  const totalExpandedSamples = getCount(addObjects(expandedSampleCount, expandedModSampleCount));

  const className = cx(styles.sampleCount, { [styles.sampleCountActive]: active });

  return (
    <span title={`Expanded: ${totalExpandedSamples}`} className={className}>
      {totalSamples}
    </span>
  );
};

SampleCount.propTypes = {
  dialogue: LibraryDialogueType.isRequired,
  modSamplesCount: PropTypes.shape({}),
  active: PropTypes.bool,
};

export default SampleCount;
