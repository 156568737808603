import { gql } from '@apollo/client';

const GET_BOT_KEY = gql`
  query GetBotKey($botId: ID!) {
    bot(id: $botId) {
      id
      botKey
    }
  }
`;

export default GET_BOT_KEY;
