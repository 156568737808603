import arrayMutators from 'final-form-arrays';
import { isEqual, noop } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import { hasSameId } from 'frontend/features/Build/utils';
import { ModalForm } from 'frontend/features/Modals';
import { SampleType } from 'frontend/features/Samples/propTypes';
import { useCurrentLanguage } from 'frontend/hooks';
import { BuildIdObjectType } from 'frontend/propTypes';

import ChooseForm from './ChooseForm';
import PreviewForm from './PreviewForm';
import {
  arrayToBoolObject,
  boolObjectToArray,
  replaceTextByEntity,
  sampleIsLegal,
  searchAndReplaceValidator,
} from './helpers';
import { useEntities } from '../../hooks';

const EntitySearchAndReplace = ({ hide, args }) => {
  const { setSamplesInLanguage, samples, buildIdObject } = args;
  const [{ currentLanguage }] = useCurrentLanguage();

  const [chosenEntity, setChosenEntity] = useState();
  const [chosenReplaceText, setChosenReplaceText] = useState();
  const isChoosing = !chosenEntity && !chosenReplaceText;

  const { loading: entitiesLoading, entities } = useEntities({ buildIdObject, currentLanguage });

  const updatedSamples = useMemo(
    () => (isChoosing ? [] : replaceTextByEntity(chosenReplaceText, chosenEntity, samples)),
    [chosenEntity, chosenReplaceText, isChoosing, samples],
  );

  const onSubmit = useCallback(
    ({ entityId, replaceText, includedSamples }) => {
      if (isChoosing) {
        const entity = entities.find(({ id }) => id === entityId);
        setChosenEntity(entity);
        setChosenReplaceText(replaceText);
        return;
      }

      const includedSampleIds = boolObjectToArray(includedSamples);
      const samplesWithEntities = samples.map((sample) => {
        if (!includedSampleIds.includes(sample.id || sample.tempId)) {
          return sample;
        }
        return updatedSamples.find((updated) => hasSameId(sample, updated));
      });

      setSamplesInLanguage(samplesWithEntities);
    },
    [entities, isChoosing, samples, setSamplesInLanguage, updatedSamples],
  );

  const renderForm = useCallback(
    ({ handleSubmit }) =>
      isChoosing ? (
        <ChooseForm
          handleSubmit={handleSubmit}
          entities={entities}
          currentLanguage={currentLanguage}
          loading={entitiesLoading}
        />
      ) : (
        <PreviewForm updatedSamples={updatedSamples} replaceText={chosenReplaceText} />
      ),
    [chosenReplaceText, currentLanguage, entities, entitiesLoading, isChoosing, updatedSamples],
  );

  const initialValues = useMemo(() => {
    if (isChoosing) {
      return { replaceText: '', entityId: null };
    }
    const includedSamples = arrayToBoolObject(
      samples.filter((existing) => sampleIsLegal(updatedSamples.find((updated) => hasSameId(existing, updated)))),
    );
    return { includedSamples };
  }, [isChoosing, samples, updatedSamples]);

  const noSamplesFound = updatedSamples.length === 0;
  const title = `Replace word with entity${!isChoosing ? ': Preview' : ''}`;
  const onOkText = isChoosing ? 'Preview' : (noSamplesFound && 'Ok') || 'Replace';
  const validate = isChoosing || noSamplesFound ? noop : searchAndReplaceValidator;

  return (
    <ModalForm
      title={title}
      onOkText={onOkText}
      render={renderForm}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      hide={hide}
      closeOnSubmit={!isChoosing}
      validate={validate}
      ignoreDirty={!isChoosing}
    />
  );
};

EntitySearchAndReplace.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    setSamplesInLanguage: PropTypes.func.isRequired,
    samples: PropTypes.arrayOf(SampleType).isRequired,
    buildIdObject: BuildIdObjectType.isRequired,
  }).isRequired,
};

export default EntitySearchAndReplace;
