import { gql } from '@apollo/client';

const PLATFORM_ALERT_FRAGMENT = gql`
  fragment PlatformAlert on PlatformAlertType {
    id
    createdAt
    text
    readMoreUrl
    published
  }
`;
export default PLATFORM_ALERT_FRAGMENT;
