import { camelCase, isEqual, isNil, omit, pick } from 'lodash';

import type {
  BuildFormType,
  CreatedFormInput,
  DeletedFormInput,
  FieldAttributesSelectOptionInput,
  UpdatedFormInput,
} from 'frontend/api/generated';
import { deepOmitBy } from 'frontend/utils/deepOmitBy';

type CombinedFormType = CreatedFormInput & UpdatedFormInput & DeletedFormInput;

function getFieldName(firstSegment: string, secondSegment?: string) {
  return camelCase(`${firstSegment ?? ''}_${secondSegment ?? ''}_build_forms`);
}

const omitTempIds: (form: BuildFormType) => CombinedFormType = (form) => {
  const valueHasTempId = (value, key) => key === 'id' && value.startsWith('temporary-uuid-');
  const formWithoutTempIds = { ...deepOmitBy(form, valueHasTempId) };
  const formFields = (formWithoutTempIds?.fields || []).map((field) => omit(field, ['createdAt']));
  return { ...omit(formWithoutTempIds, ['createdAt', 'dialogue']), fields: formFields } as CombinedFormType;
};

const validatorsToValidate = ['maxLength', 'minimum', 'maximum', 'pattern'];

const filterFields = (form: CombinedFormType) => {
  const formCopy = { ...form };
  if (formCopy.fields.length > 0) {
    if (formCopy.texts) {
      formCopy.texts.title = formCopy.texts.title ? formCopy.texts.title.trim() : '';
      formCopy.texts.cancelButtonText = formCopy.texts.cancelButtonText ? formCopy.texts.cancelButtonText.trim() : '';
      formCopy.texts.submitButtonText = formCopy.texts.submitButtonText ? formCopy.texts.submitButtonText.trim() : '';
      formCopy.texts.cancelText = formCopy.texts.cancelText ? formCopy.texts.cancelText.trim() : '';
      formCopy.texts.unansweredText = formCopy.texts.unansweredText ? formCopy.texts.unansweredText.trim() : '';
      formCopy.texts.errorText = formCopy.texts.errorText ? formCopy.texts.errorText.trim() : '';
    }

    formCopy.fields = formCopy.fields.map((field, fieldIndex) => {
      // Trim values
      field.slug = field.slug?.trim() || '';

      // Update order accordingly
      field.order = fieldIndex;

      if (field.texts) {
        // TODO For some reason some forms send no placeholderText or helpText or label...
        // I can't reproduce 13 December 2022
        // This should make sure that we always send at least empty string if no placeholderText is provided
        // until this is reproduced
        field.texts.helpText = field.texts.helpText ? field.texts.helpText.trim() : '';
        field.texts.label = field.texts.label ? field.texts.label.trim() : '';
        field.texts.placeholderText = field.texts.placeholderText ? field.texts.placeholderText.trim() : '';
      }

      if (field.attributes && !isNil(field.attributes?.defaultValue)) {
        field.attributes.defaultValue = field.attributes.defaultValue.toString().trim();
      }

      if (field.attributes?.options) {
        const omittedOptions = field.attributes.options.map((obj) =>
          omit(obj, ['index']),
        ) as unknown as FieldAttributesSelectOptionInput[];
        field.attributes.options = omittedOptions;
      }

      if (field.validators.length) {
        field.validators = field.validators
          .filter((validator) => {
            const validatorKeys = Object.keys(validator).filter((key) => !isNil(validator[key]));
            const foundValidator = validatorsToValidate.find((compareValidator) =>
              validatorKeys.includes(compareValidator),
            );

            // Filter out validators that don't have value
            return foundValidator && (typeof validator[foundValidator] === 'number' || validator[foundValidator]);
          })
          .map((validator) => {
            if (!validator.texts?.text) {
              validator = {
                ...validator,
                // Text is required creating a validator by the backend
                // So in case we have created the validator on fly, we add an empty text
                texts: {
                  text: '',
                },
              };
            }
            return validator;
          });
      }
      return field;
    });
  }
  return formCopy;
};

export default (initialValues, values) => {
  const initial = initialValues?.buildForms ?? [];
  const current = values?.buildForms ?? [];

  const forms: {
    updatedBuildForms?: UpdatedFormInput[];
    createdBuildForms?: CreatedFormInput[];
    deletedBuildForms?: DeletedFormInput[];
  } = {};

  current.forEach((currentForm) => {
    const initialForm = initial.find((c) => c.id === currentForm.id);
    let fieldName;
    if (!isEqual(initialForm, currentForm)) {
      // updated
      fieldName = getFieldName('updated');
    }
    if (!initialForm) {
      // created
      fieldName = getFieldName('created');
    }
    const form = filterFields(omitTempIds(currentForm));
    if (fieldName) {
      forms[fieldName] = [...(forms[fieldName] || []), form];
    }
  });

  initial.forEach((initialForm) => {
    const currentForm = current.find((c) => c.id === initialForm.id);
    // deleted
    if (!currentForm) {
      const fieldName = getFieldName('deleted');
      forms[fieldName] = [...(forms[fieldName] || []), pick(initialForm, ['id'])];
    }
  });

  return forms;
};
