import cx from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import styles from './LabelBadgeSlider.scss';

const DOT_WIDTH = 15;

/**
 * Colored label badge that automatically scrolls long text on hover.
 */
function LabelBadgeSlider({ labelName, labelColor, labelBackground, htmlFor, className, isClickable }) {
  const [labelCalcs, setLabelCalcs] = useState({ isLabelOverflowing: false, labelWidth: '100%' });
  const span = useRef();
  const label = useRef();

  const handleLabelHover = () => {
    const isLabelOverflowing = span.current.scrollWidth + DOT_WIDTH > label.current.offsetWidth;
    const labelWidth = isLabelOverflowing ? `${label.current.offsetWidth * 0.2}px` : '100%';
    setLabelCalcs({ isLabelOverflowing, labelWidth });
  };

  return (
    <label
      className={cx(styles.label, { [styles.clickable]: isClickable }, className)}
      onMouseEnter={handleLabelHover}
      htmlFor={htmlFor}
      style={{
        '--_label-color': labelColor,
        '--_label-background-color': labelBackground,
        '--_label-overflow-delta': labelCalcs.labelWidth,
      }}
      ref={label}
    >
      <span className={styles.dot} />

      <span ref={span} className={styles.text}>
        {labelName}
      </span>
    </label>
  );
}

LabelBadgeSlider.propTypes = {
  /** If true, style the label as clickable (e.g. cursor: pointer). */
  isClickable: PropTypes.bool,
  /** The label name. */
  labelName: PropTypes.string.isRequired,
  /** The color of the dot. */
  labelColor: PropTypes.string,
  /** The background color of the label. */
  labelBackground: PropTypes.string,
  /** The html `for` attribute to bind the label to an input. */
  htmlFor: PropTypes.string,
  className: PropTypes.string,
};

export default LabelBadgeSlider;
