import { get, isFunction, isString, orderBy } from 'lodash';

import type { AnyRole } from 'frontend/features/Organization/types';
import { normalizeKey } from 'frontend/utils';

import { ROLE_ORDER } from '../constants';

const normalizedOrder = ROLE_ORDER.map(normalizeKey);

function getRoleName<T>(key: string | ((key: AnyRole) => T), element): T {
  if (isFunction(key)) return key(element);
  if (isString(key)) return get(element, key);
  return element;
}

/** Sort by index if role found in ROLE_ORDER, otherwise (i.e. custom roles) list them last ordered by name */
function getRoleOrder(key: string) {
  return (element) => {
    const roleName = getRoleName<string>(key, element);
    const roleIndex = normalizedOrder.findIndex((name) => name === normalizeKey(roleName));

    return `${roleIndex > -1 ? roleIndex : normalizedOrder.length}-${roleName}`;
  };
}

export default function sortRoles(roles: AnyRole[], key: string) {
  const roleOrder = getRoleOrder(key);

  return orderBy(roles, roleOrder);
}
