import { Check, Exclamation, Info } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import type { IconColor } from 'frontend/components/Icon/Icon';

import ToastSentryError from './ToastSentryError';
import ToastWrapper from './ToastWrapper';
import styles from '../styles.scss';

export type ToastLevel = 'INFO' | 'SUCCESS' | 'ERROR' | 'WARNING';

interface ToastProps {
  type: ToastLevel;
  to?: string;
  message: string;
  sentryId?: string;
  closeToast?: () => void; // always provided by react-toastify under the hood
  undo?: () => void;
}

export const TOAST_COLORS: Record<ToastLevel, IconColor> = {
  INFO: 'primary',
  SUCCESS: 'success',
  ERROR: 'warning',
  WARNING: 'warning',
} as const;

export const TOAST_ICONS: Record<ToastLevel, React.ComponentType<React.SVGProps<SVGSVGElement>>> = {
  INFO: Info,
  SUCCESS: Check,
  ERROR: Exclamation,
  WARNING: Exclamation,
} as const;

const Toast = ({ type, to, message, sentryId, closeToast, undo }: ToastProps) => {
  if (sentryId) return <ToastSentryError message={message} sentryId={sentryId} closeToast={closeToast!} />;

  const ToastIcon = TOAST_ICONS[type];
  const iconColor = TOAST_COLORS[type];

  return (
    <ToastWrapper to={to}>
      <Icon component={ToastIcon} color={iconColor} className={styles.icon} />
      <div>
        <p>{message}</p>
        {undo && (
          <Button flat className={styles.ignore} onClick={undo}>
            Undo
          </Button>
        )}
      </div>
    </ToastWrapper>
  );
};

export default Toast;
