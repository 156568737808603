import { createSelectable } from '@kindly/react-selectable-fast';
import cx from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
// TODO: Revert to original repo once https://github.com/valerybugakov/react-selectable-fast/pull/63 and
// https://github.com/valerybugakov/react-selectable-fast/pull/65 are merged
import { memo, useCallback } from 'react';

import styles from './SampleItem.scss';
import { useHover, useSampleItemCallbacks } from './hooks';
import { SampleType } from '../../propTypes';
import EditSample from '../EditSample';
import SampleDetail from '../SampleDetail';
import SampleOptions from '../SampleOptions';

const SampleItem = ({
  isSelected,
  isSelecting,
  deselectAll,
  fieldName,
  removeSample,
  sampleValidator,
  selectableRef,
  setSampleSelected,
  selectedSamples,
  sample,
  editingSample,
  setEditingSample,
  stopEditing,
  sampleOptionsDisabled = false,
}) => {
  const name = `${fieldName}.${sample.sampleIndex}`;
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();
  const { onClickItem, remove, removeSelected, isEditing } = useSampleItemCallbacks({
    removeSample,
    sample,
    name,
    setSampleSelected,
    isSelected,
    deselectAll,
    selectedSamples,
    fieldName,
    editingSample,
    setEditingSample,
  });

  const onEnter = useCallback(
    (e) => {
      if (e.key === 'Enter') onClickItem(e);
    },
    [onClickItem],
  );

  const containerClassNames = cx(styles.sampleContainer, 'editSampleContainer', {
    [styles.sampleContainerSelected]: isSelected || isSelecting,
    [styles.sampleContainerEditing]: isEditing,
  });
  const itemClassnames = cx(styles.sample, 'disableSelect', {
    [styles.sampleSelected]: isSelected,
  });

  return (
    <div className={styles.sampleWrapper} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {!isEditing &&
        !sampleOptionsDisabled &&
        isHovered && ( // Only render when hovered, for performance (not equivalent to display: none)
          <SampleOptions
            remove={remove}
            removeSelected={removeSelected}
            sampleIndex={sample.sampleIndex}
            selected={isSelected}
            deselectAll={deselectAll}
          />
        )}
      <div className={containerClassNames}>
        {isEditing && (
          <EditSample name={name} sampleValidator={sampleValidator} stopEditing={stopEditing} remove={remove} />
        )}
        <div
          className={itemClassnames}
          ref={selectableRef}
          onClick={onClickItem}
          onKeyDown={onEnter}
          role="button"
          tabIndex={0}
          translate="no"
        >
          <SampleDetail text={sample.text} match={sample.match} instances={sample.instances} />
        </div>
      </div>
    </div>
  );
};

SampleItem.propTypes = {
  sampleValidator: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSelecting: PropTypes.bool.isRequired,
  deselectAll: PropTypes.func.isRequired,
  selectedSamples: PropTypes.arrayOf(SampleType).isRequired,
  fieldName: PropTypes.string.isRequired,
  removeSample: PropTypes.func.isRequired,
  selectableRef: PropTypes.func.isRequired,
  setSampleSelected: PropTypes.func.isRequired,
  sample: SampleType.isRequired,
  editingSample: PropTypes.string,
  setEditingSample: PropTypes.func.isRequired,
  stopEditing: PropTypes.func.isRequired,
  sampleOptionsDisabled: PropTypes.bool,
};

export default flowRight(memo, createSelectable)(SampleItem);
