import cx from 'classnames';
import type { ConnectDropTarget } from 'react-dnd';

import { ArrowLeft } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { useOnDragHover } from 'frontend/features/Library/hooks';
import { LibraryViewNavEnum } from 'frontend/features/Library/views/NavigationView/NavigationView';

import styles from './Back.scss';

interface Props {
  onClick: (event?: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined) => void;
  currentView: keyof typeof LibraryViewNavEnum;
}

const Back = ({ onClick, currentView }: Props) => {
  const [ref, canDrop] = useOnDragHover(onClick);

  if (currentView === LibraryViewNavEnum.NAVIGATION) return null;

  return (
    <div className={styles.navButtonWrapper} ref={ref as ConnectDropTarget}>
      <Icon
        component={ArrowLeft}
        className={cx(styles.navButton, { [styles.navButtonCanDrop]: canDrop })}
        onClick={onClick}
      />
    </div>
  );
};

export default Back;
