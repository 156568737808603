import { useForm, useFormState } from 'react-final-form';

import LabelsSection from 'frontend/features/Labels/LabelsSection';
import useLabels from 'frontend/hooks/useLabels';
import type { Label } from 'frontend/propTypes/LabelType';

import styles from './LabelsFilter.scss';

const LabelsFilter = ({ filterKey, shouldSubmitOnToggle }: { filterKey: string; shouldSubmitOnToggle: boolean }) => {
  const botLabels = useLabels();
  const { change, submit } = useForm();
  const { values } = useFormState({ subscription: { values: true } });

  const handleLabelToggle = (label: Label) => {
    if (!values[`${filterKey}`]?.includes(label.id)) {
      change(filterKey, [...(values[`${filterKey}`] || []), label.id]);
    } else {
      const labels = [...values[`${filterKey}`]];
      labels.splice(labels.indexOf(label.id), 1);
      change(filterKey, labels);
    }

    if (shouldSubmitOnToggle) {
      submit();
    }
  };

  return (
    <LabelsSection
      className={styles.labelFilterWrapper}
      activeLabelsIds={values[`${filterKey}`] || []}
      botLabels={botLabels}
      canEditLabels={false}
      handleLabelToggle={handleLabelToggle}
      inputPlaceholder="Search"
      shouldDisplaySystemLabels
    />
  );
};

export default LabelsFilter;
