import cx from 'classnames';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import type { ImageType } from 'frontend/api/generated';
import { Icon } from 'frontend/components';
import { typeToIcon as imageButtonTypeToIcon } from 'frontend/components/ImageCarousel/components/FakeButton/FakeButton';

import styles from './ImageCarousel.scss';

// FIXME (TS) : The image in Inbox is not the same of the Build one, so sharing the type is technically wrong. For now I patched the different property.
interface ImageButton {
  button_type: string;
  id: string;
  is_active: boolean;
  label: string;
  value: string;
}

export type CorrectedImageType = Omit<ImageType, 'buttons'> & {
  buttons: ImageButton[];
};

interface Props {
  imageCarousel: CorrectedImageType[];
  size: number;
}

export default function ImageCarousel({ imageCarousel, size }: Props) {
  const nImages = imageCarousel.length;
  const isCarousel = nImages > 1;

  const [imageCarouselIndex, setImageCarouselIndex] = useState(0);
  const currentImage = imageCarousel[imageCarouselIndex];
  const hasImageText = Boolean(currentImage?.title?.trim() || currentImage?.description?.trim());

  const next = useCallback(() => {
    setImageCarouselIndex((imageCarouselIndex + 1) % nImages);
  }, [imageCarouselIndex, nImages]);

  if (!currentImage) return null;

  return (
    <div
      className={cx(styles.container, { [styles.isCarousel]: isCarousel })}
      title={isCarousel ? 'Click to see next image' : undefined}
      onClick={isCarousel ? next : noop}
      onKeyDown={(e) => e.key === 'Enter' && isCarousel && next()}
      tabIndex={isCarousel ? 0 : -1}
      role="button"
    >
      <img
        src={currentImage.imageUrl || undefined}
        alt={currentImage.altText}
        title={isCarousel ? 'Click to go through all images' : ''}
      />
      <div className={styles.belowSection}>
        {hasImageText && (
          <div className={styles.imageMetaWrapper}>
            <span className={styles.imageTitle}>{currentImage.title}</span>
            <span className={styles.imageDescription}>{currentImage.description}</span>
          </div>
        )}

        {!!currentImage.buttons?.length &&
          size !== 600 &&
          currentImage.buttons.map((button) => (
            <div className={styles.imageButton} key={button.id}>
              <span className={styles.imageButtonLabel}>{button.label}</span>
              {imageButtonTypeToIcon[button.button_type] && (
                <Icon component={imageButtonTypeToIcon[button.button_type]} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
