import PropTypes from 'prop-types';

import { Modal } from 'frontend/features/Modals';

const AboutVideoEmbed = ({ hide }) => (
  <Modal hide={hide} onOk={hide} hideCancel title="About video embed">
    <Modal.Content>
      <p className="m-t-0">Paste the URL to a video hosted on YouTube, Vimeo or your own hosting.</p>
      <p>
        <strong>Vimeo;</strong> copy the url from the address bar in your browser.
      </p>
      <p className="m-b-0">
        <strong>Youtube;</strong> we currently support regular video, link to current time and link to a playlist.
      </p>
    </Modal.Content>
  </Modal>
);

AboutVideoEmbed.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default AboutVideoEmbed;
