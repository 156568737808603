import { differenceInDays } from 'date-fns';

import { MAX_NUMBER_OF_DAYS } from './constants';

export default ({ days: numberOfDays, dateRangeEnabled, from, to }) => {
  const days = dateRangeEnabled ? differenceInDays(to, from) : numberOfDays;

  if (days > MAX_NUMBER_OF_DAYS) return { NumberOfDays: `You can use up to ${MAX_NUMBER_OF_DAYS} days` };

  return undefined;
};
