import { capitalize, isNil } from 'lodash';

import type { FieldValidatorType, FormFieldAttributesType, FormFieldType } from 'frontend/api/generated';

export const validateField = (value, validation, field?: FormFieldType) => {
  if (Array.isArray(validation)) {
    for (let i = 0; i < validation.length; i += 1) {
      if (typeof validation[i] === 'function') {
        const validate = validation[i](value, field);
        if (validate) return validate;
      }
    }
  }
  return undefined;
};

export const validateSlug = (value) => {
  const pattern = /^[\p{L}\p{M}\d_\-.:]*$/u;
  if (value && !value.match(pattern)) {
    return 'Use only uppercase/lowercase letters, numbers 0-9, colon (:), dash(-) and underscore (_).';
  }
  return undefined;
};

const comparers = {
  minimumMaximum: {
    compare: (value: string, valueToCompare: string) => value >= valueToCompare,
    message: 'Minimum should be less than maximum.',
  },
  maximumMinimum: {
    compare: (value: string, valueToCompare: string) => value <= valueToCompare,
    message: 'Maximum should be greater than minimum.',
  },
  defaultValueMinimum: {
    compare: (value: string, valueToCompare: number) => parseInt(value, 10) < valueToCompare,
    message: 'Default Value should be between minimum and maximum.',
  },
  defaultValueMaximum: {
    compare: (value: string, valueToCompare: number) => parseInt(value, 10) > valueToCompare,
    message: 'Default Value should be between minimum and maximum.',
  },
  defaultValueStep: {
    compare: (value: string, valueToCompare: string) => parseInt(value, 10) % parseInt(valueToCompare, 10) !== 0,
    message: 'Default Value and Step should be dividable to each other.',
  },
  stepMinimum: {
    compare: (value: string, valueToCompare: number) => parseInt(value, 10) <= valueToCompare,
    message: 'Step should be between minimum and maximum.',
  },
  stepMaximum: {
    compare: (value: string, valueToCompare: number) => parseInt(value, 10) >= valueToCompare,
    message: 'Step should be between minimum and maximum.',
  },
  stepDefaultvalue: {
    compare: (value: string, valueToCompare: string) => parseInt(value, 10) % parseInt(valueToCompare, 10) !== 0,
    message: 'Step and Default Value should be dividable to each other.',
  },
};

// Compare two values in the form and return errors if any of the comparers don't match
// we have two places from where we can compare values intertwined, attributes and validators
export const linkedValidatorComparer =
  (
    compare: keyof FieldValidatorType | keyof FormFieldAttributesType,
    compareTo: keyof FieldValidatorType | keyof FormFieldAttributesType,
    comparePath: 'attributes' | 'validators' = 'validators',
    compareToPath: 'attributes' | 'validators' = 'validators',
  ) =>
  (value: string, field: FormFieldType): string | undefined => {
    if (!field) return undefined;

    if (!isNil(value)) {
      let validatorIndex;
      // In case its a validator (array), we find the index of the value we are looking for
      if (field[comparePath] && Array.isArray(field[comparePath])) {
        validatorIndex = (field[comparePath] as FieldValidatorType[]).findIndex(
          (validator) => !isNil(validator[compare]),
        );
      }

      // We handle the case where we have an array (in this case validators) or we have object (in this case attributes)
      // validators are found by their index, objects are found by their key
      if (
        (!isNil(validatorIndex) && value !== field[comparePath]?.[validatorIndex]?.[compare]) ||
        (!isNil(field[comparePath]?.[compare]) && value !== field[comparePath]?.[compare])
      ) {
        // We need to find the index of the validator, in case its a validators (array check) we want to compare the value to
        let findCompareValidatorIndex;
        if (field[compareToPath] && Array.isArray(field[compareToPath])) {
          findCompareValidatorIndex = (field[compareToPath] as FieldValidatorType[])?.findIndex(
            (validator) => !isNil(validator[compareTo]),
          );
        }

        if (
          compareTo &&
          comparers[`${compare}${capitalize(compareTo)}`]?.compare(
            value,
            // In case its an array, we find the field value by its index
            // otherwise by its key
            field[compareToPath]?.[findCompareValidatorIndex]?.[compareTo] || field[compareToPath]?.[compareTo],
          )
        ) {
          // comparers follow the keyword naming
          // compareCompareTo
          return (
            comparers[`${compare}${capitalize(compareTo)}`]?.message || 'There is something wrong with this field.'
          );
        }
      }
    }
    return undefined;
  };
