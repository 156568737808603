import type { DialogueType } from 'frontend/api/generated';
import { DEFAULT_RULE_ID } from 'frontend/features/Build/constants';

function getDialogueRuleName(dialogue: DialogueType, ruleId: string) {
  if (ruleId === DEFAULT_RULE_ID) {
    return 'Default rule';
  }
  const rule = dialogue.dialogueRules.find((r) => r.id === ruleId);
  return rule ? rule.name : '-';
}

export default (currentLanguage, dialoguesWithWebhooks: DialogueType[] = []) =>
  dialoguesWithWebhooks.flatMap((dialogue) =>
    Object.entries(dialogue.webhookUrls[currentLanguage] || {}).map(([ruleId, webhookUrl]) => ({
      rule: {
        name: getDialogueRuleName(dialogue, ruleId),
        id: ruleId === 'defaultRule' ? DEFAULT_RULE_ID : ruleId,
        dialogueType: dialogue.dialogueType,
        dialogueID: dialogue.id,
      },
      webhookUrl,
      dialogue: {
        ruleID: ruleId,
        title: dialogue.title[currentLanguage] ?? dialogue.title.default,
        isActive: dialogue.isActive[currentLanguage],
        id: dialogue.id,
        dialogueType: dialogue.dialogueType,
        colorLabel: dialogue.colorLabel[currentLanguage],
      },
      key: `webhook-dialogue-${dialogue.id}-${ruleId}`, // Unique key for each item
    })),
  );
