import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Conversion, Reports } from 'frontend/features/Organization/views';

const OrganizationAnalyticsRoutes = () => (
  <Routes>
    <Route path="conversions/*" element={<Conversion />} />
    <Route path="reports" element={<Reports />} />
    <Route path="reports/:month/*" element={<Reports />} />
    <Route path="/" element={<Navigate to="reports" replace />} />
  </Routes>
);

export default OrganizationAnalyticsRoutes;
