import { split } from 'lodash';
import { useMemo } from 'react';

import { useUrlSearchParams } from 'frontend/hooks';

import { getUrlArrayParam } from '../utils';

const filterToUrlParams = getUrlArrayParam('buttonTypes');

export default () => {
  const [{ buttonTypes: types }] = useUrlSearchParams();
  const buttonTypes = useMemo(() => (types ? split(types, '-') : []), [types]);

  return [buttonTypes, filterToUrlParams] as const;
};
