import { updateArrayInCache, updateItemInCache } from 'frontend/api/cacheHelpers';
import { chatListItemFragment } from 'frontend/api/fragments';
import { camelCaseKeys } from 'frontend/utils';

import { COMMENTS } from './queries';

const handleCommentPayload = (commentPayload) => ({ ...camelCaseKeys(commentPayload), __typename: 'ChatCommentType' });

const chatCacheUpdate = ({ chatId, botId, arrayUpdate, cache }) =>
  updateArrayInCache({
    pathToArrayInCache: 'chatAndBot.chat.comments',
    query: COMMENTS,
    arrayUpdate,
    cache,
    variables: { chatId, botId },
  });

export const commentCreatedCacheUpdate = ({ comment, chatId, cache, botId }) => {
  const arrayUpdate = (currentComments) => [...currentComments, handleCommentPayload(comment)];
  chatCacheUpdate({ chatId, arrayUpdate, cache, botId });
};

export const commentUpdatedCacheUpdate = ({ botId, comment, chatId, cache }) => {
  const arrayUpdate = (currentComments) => {
    const commentIndex = currentComments.findIndex(({ id }) => id === comment.id);
    if (commentIndex < 0) return currentComments;
    return currentComments.map((item) => (comment.id === item.id ? handleCommentPayload(comment) : item));
  };
  chatCacheUpdate({ chatId, arrayUpdate, cache, botId });
};

export const commentDeletedCacheUpdate = ({ comment, chatId, cache, botId }) => {
  const arrayUpdate = (currentComments) => currentComments.filter(({ id }) => id !== comment.id);
  chatCacheUpdate({ chatId, arrayUpdate, cache, botId });
};

export const commentsCountCacheUpdate = ({ cache, chatId, countDifference }) =>
  updateItemInCache({
    fragment: chatListItemFragment,
    typeName: 'ChatType',
    fragmentName: 'ChatListItem',
    cache,
    id: chatId,
    update: (chat) => ({ ...chat, commentsCount: chat.commentsCount + countDifference }),
  });
