import PropTypes from 'prop-types';

import { buildTypes } from 'frontend/constants';

import BuildIdObjectType from './BuildIdObjectType';

export default PropTypes.shape({
  buildIdObject: BuildIdObjectType,
  isBot: PropTypes.bool,
  buildType: PropTypes.oneOf(Object.values(buildTypes)),
});
