import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { LoaderSwitch, SelectWithSearch } from 'frontend/components';
import { EntityTag } from 'frontend/features/Entities/components';
import { useEntities } from 'frontend/features/Entities/hooks';
import { ModalForm } from 'frontend/features/Modals';
import { BuildIdObjectType } from 'frontend/propTypes';
import { getArray } from 'frontend/utils';

import styles from './AddDialogueSlot.scss';
import EmptyState from './EmptyState';
import { SlotFields, SlotTag } from '../../components';
import { DIALOGUE_SLOT_INITIAL } from '../../constants';
import { SLOTS } from '../../queries';
import { slotFromEntity } from '../../utils';

const validate = ({ slotId, required, prompt }) => {
  if (!slotId) return { Slot: 'You must select a slot or entity' };
  if (required && !prompt) return { Slot: 'Required slots must have a prompt' };

  return undefined;
};

const SLOT = 'SLOT';
const ENTITY = 'ENTITY';

const AddDialogueSlot = ({ hide, args }) => {
  const { currentLanguage, addDialogueSlot, buildIdObject } = args;
  const { entities, loading: entitiesLoading } = useEntities({ buildIdObject, currentLanguage });
  const { data, loading } = useQuery(SLOTS, { variables: { ...buildIdObject, languageCode: currentLanguage } });
  const nonEntitySlots = getArray('slots', data);

  const options = useMemo(() => {
    const entityOptions = entities.map(({ id, name, hue }) => ({ name, value: id, hue, type: ENTITY }));
    const slotOptions = nonEntitySlots.map(({ id, name }) => ({ name, value: id, type: SLOT }));
    const showEntitiesHeadline = entityOptions.length > 0 && slotOptions.length > 0;

    return [
      ...slotOptions,
      ...(showEntitiesHeadline ? [{ name: 'Entities', type: 'group', items: entityOptions }] : entityOptions),
    ];
  }, [entities, nonEntitySlots]);

  const renderOption = useCallback(
    ({ name, hue, type }) =>
      type === ENTITY ? (
        <EntityTag name={name} hue={hue} className={styles.option} />
      ) : (
        <SlotTag name={name} className={styles.option} />
      ),
    [],
  );

  const onSubmit = useCallback(
    async ({ slotId, ...values }) => {
      const entity = entities.find(({ id }) => id === slotId);
      const slot = entity
        ? slotFromEntity({ entity, currentLanguage })
        : nonEntitySlots.find(({ id }) => id === slotId);

      await addDialogueSlot({ slot, ...values });
    },
    [addDialogueSlot, currentLanguage, entities, nonEntitySlots],
  );

  return (
    <ModalForm
      title="Add slot"
      hide={hide}
      onSubmit={onSubmit}
      initialValues={DIALOGUE_SLOT_INITIAL}
      validate={validate}
    >
      <LoaderSwitch loading={loading || entitiesLoading} size="medium">
        {entities.length === 0 && nonEntitySlots.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            <label htmlFor="slotId">Slot</label>
            <Field name="slotId" component={SelectWithSearch} options={options} renderOption={renderOption} />
            <br />
            <SlotFields />
          </>
        )}
      </LoaderSwitch>
    </ModalForm>
  );
};

AddDialogueSlot.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    currentLanguage: PropTypes.string.isRequired,
    addDialogueSlot: PropTypes.func.isRequired,
    buildIdObject: BuildIdObjectType.isRequired,
  }).isRequired,
};

export default AddDialogueSlot;
