import { motion } from 'framer-motion';

interface ShakeDivProps {
  children?: React.ReactNode;
  /** If equal to `error`, shake the div. */
  animate: 'normal' | 'error';
  className?: string;
}

/** Div wrapper to trigger a shake animation using _animate_ property. */
const ShakeDiv = ({ children, animate, className }: ShakeDivProps) => {
  const variants = {
    normal: {
      x: 0,
    },
    error: {
      x: [0, 10, -10, 0],
      transition: { duration: 0.3 },
    },
  };
  return (
    <motion.div variants={variants} animate={animate} className={className}>
      {children}
    </motion.div>
  );
};
export default ShakeDiv;
