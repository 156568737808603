import { useQuery } from '@apollo/client';
import { get, identity, map } from 'lodash/fp';
import { useMemo } from 'react';

import { RecentUserActivitiesDocument } from 'frontend/api/generated';
import { Clock } from 'frontend/assets/icons';
import { Accordion, Icon } from 'frontend/components';
import { useCurrentLanguage } from 'frontend/hooks';
import { BuildIdObjectType } from 'frontend/propTypes';

import styles from './RecentlyUsed.scss';
import DialogueList from '../DialogueList';

const getDialogues = map(get('targetDialogue'));

const RecentlyUsed = ({ buildIdObject }) => {
  const [{ selectedLanguage }] = useCurrentLanguage();

  const { data, loading } = useQuery(RecentUserActivitiesDocument, { variables: buildIdObject });
  const dialogues = useMemo(() => getDialogues(data?.recentUserActivities ?? []), [data]);

  if (loading || dialogues.length <= 0) {
    return null;
  }

  return (
    <Accordion
      isOpen
      className={styles.recentlyUsedWrapper}
      bodyClassName={styles.recentlyUsedBody}
      title={
        <div className={styles.titleWrapper}>
          <Icon component={Clock} />
          <span className={styles.title}>Recently updated dialogues</span>
        </div>
      }
    >
      <DialogueList
        dialogues={dialogues}
        sorting={identity}
        selectedLanguage={selectedLanguage}
        buildIdObject={buildIdObject}
        indentationLevel={0}
        keyPostfix="-recent"
        hideFollowups
      />
    </Accordion>
  );
};

RecentlyUsed.propTypes = {
  buildIdObject: BuildIdObjectType.isRequired,
};

export default RecentlyUsed;
