import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { ImageBroken } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { ATTACHMENT_STATUS } from 'frontend/features/Inbox/constants';
import { AttachmentType } from 'frontend/features/Inbox/propTypes';
import { useAccessToken } from 'frontend/hooks';
import { isFileTypeImage } from 'frontend/utils';

import styles from './Attachment.scss';
import Loading from './Loading';
import { getAttachementSource } from '../../utils';
import chatButtonStyles from '../ChatButton/ChatButton.scss';
import chatGroupStyles from '../ChatGroup/ChatGroup.scss';

const Attachment = ({ attachment, scrollToBottom }) => {
  const [source, setSource] = useState();
  const token = useAccessToken();

  const processing = attachment.status === ATTACHMENT_STATUS.PROCESSING;
  const isImage = isFileTypeImage(attachment.type);

  const getSource = useCallback(async () => getAttachementSource(attachment, token), [attachment, token]);

  // Set source for thumbnail previews etc on load
  useEffect(() => {
    const updateSource = async () => setSource(await getSource());
    updateSource();
  }, [getSource]);

  // Get source with updated access token on click
  const onClick = useCallback(async () => {
    const updatedSource = await getSource();
    window.open(updatedSource, '_blank');
  }, [getSource]);

  if ([ATTACHMENT_STATUS.ERROR, ATTACHMENT_STATUS.INFECTED].includes(attachment.status)) {
    return (
      <div className={cx(styles.attachmentIconContainer, { [styles.attachmentIconContainerFile]: !isImage })}>
        <Icon component={ImageBroken} />
      </div>
    );
  }

  if (!source) return null;

  if (isImage) {
    return (
      <div className={cx(styles.attachmentImageWrapper, { [styles.attachmentImageWrapperLoading]: processing })}>
        <img onLoad={scrollToBottom} src={source} alt={attachment.name} />
        <Loading loading={processing}>Uploading</Loading>
        {!processing && (
          <button type="button" onClick={onClick}>
            Click to open
          </button>
        )}
      </div>
    );
  }

  return (
    <button
      className={cx(chatButtonStyles.button, chatGroupStyles.chatGroupButton, styles.downloadButton)}
      onClick={onClick}
      type="button"
    >
      <Loading loading={processing} fullWidth />
      {attachment.name}
    </button>
  );
};

Attachment.propTypes = {
  attachment: AttachmentType.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
};

export default Attachment;
