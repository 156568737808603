import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import InputAutosize from 'react-input-autosize';

import styles from './EditableText.scss';
import InputErrorWrapper from '../InputErrorWrapper';
import Tooltip from '../Tooltip';

interface EditableTextProps {
  name?: string;
  className?: string;
  placeholder?: string;
  minWidth?: number;
  tooltip?: 'enter-to-add' | string | React.ReactNode;
  onKeyPress?: (ev: KeyboardEvent) => any;
  type?: string;
  meta?: any;
  inputStyle?: React.CSSProperties;
}

interface AutoSizeInputProps {
  className?: string;
  inputRef?: (inputRef: { input: HTMLInputElement }) => void;
  meta?: any;
  type?: string;
  inputStyle?: React.CSSProperties;
}

const AutoSizeInput = ({ className, inputRef, meta, type, inputStyle, ...restProps }: AutoSizeInputProps) => (
  <InputErrorWrapper hasError={meta?.error && meta?.touched} displayError={meta?.error} errorPosition="bottom">
    <InputAutosize
      ref={inputRef}
      className={styles.text}
      inputClassName={cx(styles.editableText, className, { [styles.editableError]: meta?.error && meta?.touched })}
      type={type}
      inputStyle={inputStyle}
      {...restProps}
    />
  </InputErrorWrapper>
);

const EnterToAdd = () => (
  <div className={styles.inputEnter}>
    <div className={styles.inputEnterIcon}>&#8617;</div>
    <div className={styles.inputEnterAction}>Enter</div>
    to add
  </div>
);

const EditableText = ({
  className,
  tooltip,
  onKeyPress,
  meta,
  type = 'text',
  inputStyle,
  ...restProps
}: EditableTextProps) => {
  const [ref, setRef] = useState<{ input: HTMLInputElement }>();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const handleFocusOn = () => setIsFocused(true);
    const handleFocusOff = () => setIsFocused(false);

    const handleKeyPress = (event: KeyboardEvent) => {
      if (onKeyPress) onKeyPress(event);
      if (event.key === 'Enter' && tooltip === 'enter-to-add') {
        (event.target as HTMLElement)?.blur();
      }
    };

    if (tooltip === 'enter-to-add' || onKeyPress) {
      ref?.input.addEventListener('keypress', handleKeyPress);
    }

    if (ref?.input && tooltip) {
      ref.input.addEventListener('focus', handleFocusOn);
      ref.input.addEventListener('blur', handleFocusOff);
    }

    return () => {
      if (ref?.input && tooltip) {
        ref.input.removeEventListener('focus', handleFocusOn);
        ref.input.removeEventListener('blur', handleFocusOff);
      }

      if (ref?.input && (tooltip === 'enter-to-add' || onKeyPress)) {
        ref?.input.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [ref, tooltip, onKeyPress]);

  const renderTooltip = () => {
    switch (tooltip) {
      case 'enter-to-add':
        return <EnterToAdd />;
      default:
        return tooltip;
    }
  };

  return (
    <Tooltip alwaysActive>
      <AutoSizeInput
        className={className}
        inputRef={(inputRef) => setRef(inputRef)}
        meta={meta}
        type={type}
        inputStyle={inputStyle}
        {...restProps}
      />
      {tooltip && isFocused && (
        <Tooltip.Body includeChevron={false} className={styles.editableTextTooltipBody}>
          {renderTooltip()}
        </Tooltip.Body>
      )}
    </Tooltip>
  );
};

export default EditableText;
