import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { FormErrors, LoaderSwitch, PageBar, Panel } from 'frontend/components';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';

import styles from './Privacy.scss';
import { Anonymization, PersonalData, PrivacySkill } from './components';
import { usePrivacyForm } from '../hooks';

const MUTATORS = {
  ...arrayMutators,
  increaseDaysToDelete: (args, state, utils) => {
    utils.changeValue(state, 'daysToDelete', () => 2);
  },
};

const Privacy = () => {
  const { initialValues, onSubmit, botId, privacyLoading, anonymizationLoading, refetch } = usePrivacyForm();

  usePusherEvent(getPusherChannel({ botId }), 'updated-gdpr-skill-subscription', refetch);

  return (
    <LoaderSwitch loading={privacyLoading || anonymizationLoading} size="large">
      <div className={styles.privacyWrapper}>
        <Form
          onSubmit={onSubmit}
          mutators={MUTATORS}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>

              <Panel>
                <FormErrors />
                <PrivacySkill botId={botId} />
                <Panel.Separator />
                <PersonalData />
                <Panel.Separator />
                <Anonymization />
              </Panel>
            </form>
          )}
        />
      </div>
    </LoaderSwitch>
  );
};

export default Privacy;
