import PropTypes from 'prop-types';

import { Check, Close } from 'frontend/assets/icons';
import { Icon, Loader } from 'frontend/components';

import styles from '../Train.scss';

const STATUS = Object.freeze({
  TRAINING: 'TRAINING',
  SKIPPED: 'SKIPPED',
  TRAINED: 'TRAINED',
  ACTIVE: 'ACTIVE',
  /* Failed states */
  FAILED: 'FAILED',
  TRAINER_FAILED: 'TRAINER FAILED',
  PREDICTOR_FAILED: 'PREDICTOR FAILED',
  KONDUKTOR_FAILED: 'KONDUKTOR FAILED',
});

const TrainStatusIcon = ({ status }) => {
  if (status === STATUS.TRAINING) {
    return <Loader size="small" strokeColor={styles.trainingColor} />;
  }

  if ([STATUS.ACTIVE, STATUS.TRAINED].includes(status)) {
    return <Icon component={Check} color={STATUS.TRAINED ? 'success' : undefined} />;
  }

  return <Icon component={Close} color={status !== STATUS.SKIPPED ? 'warning' : undefined} />;
};

TrainStatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUS)).isRequired,
};
export default TrainStatusIcon;
