import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';

import APIKeyModal from './APIKeyModal';
import RegenerateWebhookSigningKey from './RegenerateWebhookSigningKey';
import styles from '../../General/styles.scss';
import { GET_WEBHOOK_SIGNING_KEY } from '../graphql';

const WebhookKeys = () => {
  const { botId } = useParams();
  const { data: chatKey } = useQuery(GET_WEBHOOK_SIGNING_KEY, { variables: { botId } });
  const [showAPIKeyModal] = useModal(APIKeyModal);
  const [showRegenerateWebhookSigningKey] = useModal(RegenerateWebhookSigningKey);

  if (!chatKey) {
    return null;
  }

  return (
    <div>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Webhook HMAC</h3>
      <p>
        You can verify that webhook requests were sent by Kindly by checking the HMAC code. Read more on signature
        verification in the <a href="https://docs.kindly.ai/hmac">docs</a>.
      </p>
      <Button
        text="Show key"
        onClick={() => showAPIKeyModal({ apiKey: chatKey.webhookSigningKey.apiKey })}
        className="m-r-md"
      />
      <p>
        Make sure you store the key securely, as anyone who has the key can impersonate a webhook requests from Kindly.
        If you suspect that the key might have been compromised, you can revoke it and issue a new key by clicking{' '}
        <i>Regenerate key</i>.
      </p>
      <Button text="Regenerate key" color="warning" onClick={() => showRegenerateWebhookSigningKey({ botId })} />
    </div>
  );
};

export default WebhookKeys;
