import { useRef } from 'react';

import { DotsMove } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import ContextMenu, { type ContextMenuRef } from 'frontend/components/ContextMenu/ContextMenu';
import MenuOverlay, { type Option } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';

import styles from './FieldOptionsButton.scss';

interface FieldOptionsButton {
  id: string;
  options: Array<Option | 'separator' | React.ReactElement>;
  onPointerDown: (event: React.PointerEvent<Element>) => void;
  onDragging?: (dragging: boolean) => void;
}

const FieldOptionsButton = ({ id, options, onPointerDown, onDragging }: FieldOptionsButton) => {
  const fieldOptionsRef = useRef<ContextMenuRef>(null);
  const isClicked = useRef(false);
  const isDragging = useRef(false);

  const setIsDragging = (dragging: boolean) => {
    isDragging.current = dragging;
    onDragging?.(dragging);
  };

  return (
    <ContextMenu id={id} ref={fieldOptionsRef} overlay={<MenuOverlay options={options} />}>
      <Button
        flat
        size="small"
        className={styles.fieldOptionsButton}
        onClick={(event) => {
          isClicked.current = false;
          if (!isDragging.current) {
            fieldOptionsRef?.current?.openMenu(event as React.MouseEvent<HTMLButtonElement>);
          }
          setIsDragging(false);
          event?.stopPropagation();
        }}
        color="gray"
        onMouseMove={() => {
          if (isClicked.current && !isDragging.current) {
            setIsDragging(true);
          }
        }}
        onPointerDown={(e) => {
          isClicked.current = true;
          setIsDragging(false);
          onPointerDown(e);
          e.stopPropagation();
        }}
      >
        <Icon component={DotsMove} hoverColor="primary" />
      </Button>
    </ContextMenu>
  );
};

export default FieldOptionsButton;
