import { orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectSorting, sortingTypes } from 'frontend/state/dux/build';
import { getId } from 'frontend/utils';

import useSampleSearch from './useSampleSearch';
import { countExpandedSamples, expandSamples, sortByText } from '../utils';
import { sample } from '../validators';

// TODO: Can probably leave these as numbers
const addItemKeys = (array) => (array || []).map((item, index) => ({ ...item, sampleIndex: index.toString() }));

const sortByDate = (array) => orderBy(array, ({ updatedAt, tempUpdatedAt }) => tempUpdatedAt || updatedAt, ['desc']);
const sortByWordCount = (array) => array.sort((a, b) => b.text.length - a.text.length);
const samplesInLanguage = (language, samples) =>
  addItemKeys(samples).filter((sampleItem) => getId(sampleItem) && sampleItem.languageCode === language);

export default ({ currentLanguage, isModDialogue }) => {
  const { values } = useFormState();

  const sorting = useSelector(selectSorting);
  const [allSamplesInLanguage, setAllSamplesInLanguage] = useState([]);
  const [allSkillSamplesInLanguage, setAllSkillSamplesInLanguage] = useState([]);
  const [expandedSampleCount, setExpandedSampleCount] = useState(0);
  const [expandedSamples, setExpandedSamples] = useState([]);
  const [expandedSkillSampleCount, setExpandedSkillSampleCount] = useState(0);
  const [expandedSkillSamples, setExpandedSkillSamples] = useState([]);

  const dialogueId = values.id;

  useEffect(() => {
    const allSamples = samplesInLanguage(currentLanguage, isModDialogue ? values.modSamples : values.samples);
    const allSkillSamples = samplesInLanguage(currentLanguage, isModDialogue ? values.samples : []);

    const chooseSortingType =
      {
        [sortingTypes.ALPHABETICAL]: sortByText,
        [sortingTypes.WORD_COUNT]: sortByWordCount,
        [sortingTypes.RECENTLY_CHANGED]: sortByDate,
      }[sorting] || sortByDate;

    const sortedSamples = chooseSortingType(allSamples);
    const sortedSkillSamples = chooseSortingType(allSkillSamples);

    setAllSamplesInLanguage(sortedSamples);
    setAllSkillSamplesInLanguage(sortedSkillSamples);
    setExpandedSamples(expandSamples(sortedSamples));
    setExpandedSampleCount(countExpandedSamples(allSamples)); // Can't just count expanded samples because they are truncated
    setExpandedSkillSamples(expandSamples(sortedSkillSamples));
    setExpandedSkillSampleCount(countExpandedSamples(allSkillSamples));
  }, [currentLanguage, isModDialogue, sorting, values.modSamples, values.samples]);

  const sampleValidator = useCallback((...args) => sample(allSamplesInLanguage)(...args), [allSamplesInLanguage]);

  const { searchResults, skillSearchResults } = useSampleSearch({
    allSamplesInLanguage,
    allSkillSamplesInLanguage,
    values,
  });

  return {
    dialogueId,
    sampleValidator,
    sampleList: searchResults || allSamplesInLanguage,
    skillSampleList: skillSearchResults || allSkillSamplesInLanguage,
    totalSampleCount: allSamplesInLanguage.length,
    totalSkillSampleCount: allSkillSamplesInLanguage.length,
    expandedSamples,
    expandedSampleCount,
    expandedSkillSamples,
    expandedSkillSampleCount,
  };
};
