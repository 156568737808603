import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { softDeleteInCache } from 'frontend/api/cacheHelpers';
import { RemoveBotMembershipDocument, type UserType } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import BOT_MEMBERSHIP_FRAGMENT from 'frontend/features/Organization/fragments/BotMembership.fragment.graphql';
import { useToast } from 'frontend/hooks';

type Props = {
  hide: () => void;
  args: { botId: string; user: UserType };
};
const RemoveBotMember = ({ hide, args: { botId, user } }: Props) => {
  const toast = useToast();

  const update = softDeleteInCache({
    fragment: BOT_MEMBERSHIP_FRAGMENT,
    fragmentName: 'BotMembership',
    typeName: 'BotMembershipType',
    mutationName: 'removeBotMembership',
  });

  const [mutate] = useMutation(RemoveBotMembershipDocument, { update });

  // TODO: display more information about membership
  // e.g. what roles the member will lose
  const userName = user.email;

  const onSubmit = useCallback(async () => {
    await mutate({ variables: { botId, userId: user.id } });
    toast.success(`${userName} was removed.`);
    hide();
  }, [mutate, botId, user.id, toast, userName, hide]);

  return (
    <DeleteModal
      onSubmit={onSubmit}
      text={`Are you sure you want to remove all permissions for ${userName} to this bot?`}
      titleName="member"
      action="remove"
      hide={hide}
    />
  );
};
export default RemoveBotMember;
