/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Agent,
  Calendar,
  Channel,
  Directions,
  Email,
  Handover,
  Labels as LabelsIcon,
  Language,
  UnorderedList,
} from 'frontend/assets/icons';
import { BUTTON_CONTACT_DETAILS_TYPES } from 'frontend/features/Build/constants';
import SourcesFilter from 'frontend/features/Inbox/components/InboxFilters/components/SourcesFilter/SourcesFilter';
import { CONTACT_DETAILS_STATUS } from 'frontend/state/dux/inbox';

import AgentsFilter from '../components/AgentsFilter/AgentsFilter';
import BotsFilter from '../components/BotsFilter/BotsFilters';
import ButtonTitle from '../components/ButtonTitle/ButtonTitle';
import ContactDetailsFilter from '../components/ContactDetailsFilter/ContactDetailsFilter';
import DateFilter from '../components/DateFilter/DateFilter';
import HandoverFilter from '../components/HandoverFilter/HandoverFilter';
import LabelsFilter from '../components/LabelsFilter/LabelsFilter';
import LanguageFilter from '../components/LanguageFilter/LanguageFilter';
import ReplyTypesFilter from '../components/ReplyTypesFilter/ReplyTypesFilter';

export const FILTERS_LIST = [
  {
    activeTitle: (dropdownActive: boolean) => <ButtonTitle filterKey="dateFilter" dropdownActive={dropdownActive} />,
    text: 'Date',
    key: 'dateFilter',
    icon: Calendar,
    component: <DateFilter />,
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (dropdownActive: boolean) => <ButtonTitle filterKey="labelFilter" dropdownActive={dropdownActive} />,
    text: 'Labels',
    key: 'labelFilter',
    suffix: 'label',
    icon: LabelsIcon,
    component: <LabelsFilter />,
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (dropdownActive: boolean) => (
      <ButtonTitle filterKey="replyTypeFilter" dropdownActive={dropdownActive} />
    ),
    text: 'Reply type',
    key: 'replyTypeFilter',
    suffix: 'type',
    icon: Directions,
    component: <ReplyTypesFilter />,
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (dropdownActive: boolean) => (
      <ButtonTitle filterKey="ticketAgentFilter" dropdownActive={dropdownActive} />
    ),
    text: 'Agent',
    key: 'ticketAgentFilter',
    icon: Agent,
    component: <AgentsFilter />,
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (dropdownActive: boolean) => <ButtonTitle filterKey="sourceFilter" dropdownActive={dropdownActive} />,
    text: 'Channel',
    key: 'sourceFilter',
    suffix: 'channel',
    icon: Channel,
    component: <SourcesFilter sourceKey="sourceFilter" />,
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (dropdownActive: boolean) => (
      <ButtonTitle filterKey="handoverStatusFilter" dropdownActive={dropdownActive} />
    ),
    text: 'Handover',
    key: 'handoverStatusFilter',
    suffix: 'status',
    icon: Handover,
    component: <HandoverFilter />,
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (dropdownActive: boolean) => (
      <ButtonTitle filterKey="languageFilter" dropdownActive={dropdownActive} />
    ),
    text: 'Language',
    key: 'languageFilter',
    suffix: 'language',
    icon: Language,
    component: <LanguageFilter />,
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (dropdownActive: boolean) => (
      <ButtonTitle filterKey="contactDetailsStatusFilter" dropdownActive={dropdownActive} />
    ),
    text: 'Contact info',
    key: 'contactDetailsStatusFilter',
    icon: Email,
    component: <ContactDetailsFilter />,
    closeMenuOnSelection: false,
  },
  // TODO remove when we get rid of the organization inbox
  {
    activeTitle: (dropdownActive: boolean) => <ButtonTitle filterKey="botFilter" dropdownActive={dropdownActive} />,
    text: 'Bot filter',
    key: 'botFilter',
    icon: UnorderedList,
    component: <BotsFilter />,
    onlyOrganizationInbox: true,
    closeMenuOnSelection: false,
  },
];

export const contactDetailsStatusData = [
  {
    label: 'Requested contact by email',
    value: BUTTON_CONTACT_DETAILS_TYPES.EMAIL,
  },
  {
    label: 'Requested contact by phone',
    value: BUTTON_CONTACT_DETAILS_TYPES.PHONE,
  },
  { label: 'Was contacted', value: CONTACT_DETAILS_STATUS.done },
];

/* `value`s must be the same as they come from `pat` */
export const replyTypes = [
  { label: 'Fallback', value: 'FALLBACK' },
  { label: 'Sample', value: 'SAMPLE_MATCH' },
  { label: 'Intent', value: 'INTENT_MATCH' },
  { label: 'Intent (Simplified)', value: 'INTENT_MATCH_SIMPLIFIED' },
  { label: 'AI smalltalk', value: 'AI_SMALLTALK' },
  { label: 'Keyword (Exact)', value: 'KEYWORD_EXACT_MATCH' },
  { label: 'Keyword (Mention)', value: 'KEYWORD_MENTION' },
  { label: 'Pattern', value: 'PATTERN_MATCH' },
  { label: 'AI generated', value: 'MIMIR' },
  { label: 'Suggestion', value: 'FALLBACK_SUGGESTION' },
];

export const handoverStatusData = Object.freeze([
  {
    label: 'Requested handover',
    value: 'has_requested_takeover',
  },
  {
    label: 'Handover in progress',
    value: 'taken_over',
  },
  {
    label: 'Completed handover',
    value: 'completed_takeover',
  },
]);

export const initialFilters = {
  sourceFilter: [],
  handoverStatusFilter: [],
  labelFilter: [],
  languageFilter: [],
  ticketAgentFilter: '',
  botFilter: [],
  dateFilter: [] as unknown as [{ startDate?: Date | null; endDate?: Date | null; key: 'dateFilter' }],
  replyTypeFilter: [],
};
