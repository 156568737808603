import { get, mapValues, pick } from 'lodash';

import { getArrayFieldUpdate } from 'frontend/utils';

import { ROLE_TITLE_DELIMITER } from '../constants';

const RELEVANT_CUSTOM_ROLE_FIELDS = ['id', 'title', 'permissions'];

const getCustomTitle = (role) => get(role.title.split(ROLE_TITLE_DELIMITER), 1);

const pruneCustomRoleUpdate = (customRoleUpdate) =>
  mapValues(customRoleUpdate, (roles) =>
    roles.map((role) => ({ ...pick(role, RELEVANT_CUSTOM_ROLE_FIELDS), title: getCustomTitle(role) })),
  );

export default (values, form) => {
  const { initialValues } = form.getState();
  const {
    created: createdCustomRoles,
    updated: updatedCustomRoles,
    deleted: deletedCustomRoles,
  } = getArrayFieldUpdate(initialValues.customRoles, values.customRoles, RELEVANT_CUSTOM_ROLE_FIELDS);

  return pruneCustomRoleUpdate({ createdCustomRoles, updatedCustomRoles, deletedCustomRoles });
};
