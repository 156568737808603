import { omit } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ChatbubbleGlobalIconsType } from 'frontend/api/generated';
import { LoaderSwitch } from 'frontend/components';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { ProactivityItem } from 'frontend/features/Library/components';
import { useCurrentLanguage } from 'frontend/hooks';
import randomUUID from 'frontend/utils/randomUUID';

const GlobalIconsList = () => {
  const { loading, initialValues, onSubmit } = useChatbubbleForm({
    toastSuccessMessage: 'Success',
  });

  const [{ currentLanguage }] = useCurrentLanguage();

  const navigate = useNavigate();

  const handleClick = async (
    action: 'delete' | 'publish' | 'duplicate',
    pg: ChatbubbleGlobalIconsType,
    index: number,
  ) => {
    if (action === 'delete') {
      await onSubmit?.({
        ...initialValues,
        globalIcons: initialValues?.globalIcons?.filter((globalIcon) => globalIcon?.id !== pg.id),
      });

      if (window.location.pathname.endsWith(pg.id)) {
        const findPreviousItemId = initialValues.globalIcons[index - 1]?.id || initialValues.globalIcons[index + 1]?.id;
        navigate(`proactivity/global-icon/${findPreviousItemId ? `${findPreviousItemId}` : ''}`);
      }
    }

    if (action === 'publish') {
      await onSubmit?.({
        ...initialValues,
        globalIcons: { ...initialValues }?.globalIcons?.map((globalIcon) => {
          if (globalIcon?.id === pg.id) {
            const icon = { ...globalIcon };

            icon.enabled = {
              ...icon.enabled,
              [currentLanguage]: !icon.enabled[currentLanguage],
            };
            return icon;
          }
          return globalIcon;
        }),
      });
    }

    if (action === 'duplicate') {
      const duplicate = omit(
        {
          ...pg,
          tempId: randomUUID(),
        },
        ['id'],
      );
      const globalIconsCopy = [...initialValues.globalIcons];
      globalIconsCopy.push(duplicate);

      await onSubmit?.({
        ...initialValues,
        globalIcons: [...globalIconsCopy],
      });
    }
  };

  return (
    <LoaderSwitch loading={loading} size="large">
      {initialValues?.globalIcons?.map((pg, index) => (
        <ProactivityItem
          onClick={(action) => handleClick(action, pg, index)}
          show="global-icon"
          key={`pg-${pg.id}`}
          item={pg}
        />
      ))}
    </LoaderSwitch>
  );
};

export default GlobalIconsList;
