import { useApolloClient, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { dragAndDropTypes } from 'frontend/constants';
import { updateDialogueParentOnRemove } from 'frontend/features/Library/cacheHelpers';
import { useToast } from 'frontend/hooks';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import { TRANSFER_DIALOGUE } from '../../mutations';
import { LibraryDialogueType } from '../../propTypes';
import TransferForm, { NONE_SELECTED, removeNoneSelected } from '../TransferForm';

const TransferDialogue = ({ hide, args }) => {
  const toast = useToast();
  const { dialogue, buildIdObject, selectedLanguage, botOrSkillParams } = args;
  const [transfer] = useMutation(TRANSFER_DIALOGUE);
  const client = useApolloClient();

  const onSubmit = useCallback(
    async (values) => {
      const variables = { id: dialogue.id, ...removeNoneSelected(values), languageCode: selectedLanguage };
      const { data } = await transfer({ variables });

      toast.success('The dialogue is being transferred. This may take a moment...');
      if (data.transferDialogue.deletedOriginal) updateDialogueParentOnRemove({ client, buildIdObject, dialogue });
    },
    [buildIdObject, client, selectedLanguage, dialogue, toast, transfer],
  );

  const initialValues = useMemo(
    () => ({
      title: dialogue.title.default,
      deleteOriginal: false,
      botId: NONE_SELECTED,
      skillId: NONE_SELECTED,
    }),
    [dialogue.title.default],
  );

  return (
    <TransferForm
      hide={hide}
      onSubmit={onSubmit}
      initialValues={initialValues}
      type={dragAndDropTypes.DIALOGUE}
      botOrSkillParams={botOrSkillParams}
    />
  );
};

TransferDialogue.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    dialogue: LibraryDialogueType.isRequired,
    botOrSkillParams: BotOrSkillParamsType.isRequired,
    selectedLanguage: PropTypes.string.isRequired,
  }).isRequired,
};

export default TransferDialogue;
