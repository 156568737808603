import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getAccessToken } from 'frontend/state/dux/auth';

const getToken = (dispatch) => dispatch(getAccessToken());

export default () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState();

  useEffect(() => {
    (async () => {
      setToken(await getToken(dispatch));
    })();
  }, [dispatch]);

  return token;
};
