import PropTypes from 'prop-types';

import { Trash } from 'frontend/assets/icons';
import { Modal } from 'frontend/features/Modals';
import { ChatCommentType } from 'frontend/propTypes';

import { CommentBody } from '../components';

const DeleteComment = ({ hide, args: { onConfirm, comment } }) => (
  <Modal title="Delete note" icon={Trash} color="warning" hide={hide} onOk={onConfirm} onOkText="Delete">
    <Modal.Content>
      <CommentBody comment={comment} hideActions />
    </Modal.Content>
  </Modal>
);

DeleteComment.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({ onConfirm: PropTypes.func.isRequired, comment: ChatCommentType }),
};

export default DeleteComment;
