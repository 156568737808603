import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  ConnectSlackWorkspaceBotDocument,
  DisconnectSlackWorkspaceBotDocument,
  SlackWorkspaceBotDocument,
} from 'frontend/api/generated';
import useConnectedAccounts from 'frontend/features/Profile/views/ConnectedAccounts/useConnectedAccounts';
import { useToast } from 'frontend/hooks';

const connectBotUpdate = (cache, { data }: { data?: any }) => {
  cache.modify({
    id: `BotType:${data.connectSlackWorkspaceBot.bot.id}`,
    fields: {
      slackWorkspaceBot() {
        return data.connectSlackWorkspaceBot;
      },
    },
  });
};

const useConnectSlackBot = () => {
  const { botId } = useParams();
  const toast = useToast();
  const { slackWorkspaceProfile, loading: profileLoading } = useConnectedAccounts();
  const [connectBot, { loading: connectLoading }] = useMutation(ConnectSlackWorkspaceBotDocument);
  const [disconnectBot, { loading: disconnectLoading }] = useMutation(DisconnectSlackWorkspaceBotDocument);
  const { data: workspaceBotData, loading: workspaceBotLoading } = useQuery(SlackWorkspaceBotDocument, {
    variables: { botId },
  });

  const disconnectBotUpdate = useCallback(
    (cache, { data }: { data?: any }) => {
      cache.evict({ id: `SlackWorkspaceBotType:${data.disconnectSlackWorkspaceBot.id}` });
      cache.modify({
        id: `BotType:${botId}`,
        fields: {
          slackWorkspaceBot() {
            return null;
          },
        },
      });
    },
    [botId],
  );

  const slackWorkspaceId = slackWorkspaceProfile?.id;
  const slackWorkspaceBotId = workspaceBotData?.bot?.slackWorkspaceBot?.id;

  const loading = profileLoading || workspaceBotLoading || connectLoading;

  const handleConnect = useCallback(async () => {
    let res;
    try {
      res = await connectBot({
        variables: { botId: botId as string, slackWorkspaceId: slackWorkspaceId as string },
        update: connectBotUpdate,
      });
    } catch (err) {
      console.error(err);
    }

    const { connectSlackWorkspaceBot } = res.data;
    toast.success(
      `Connected ${connectSlackWorkspaceBot.bot.name} to slack workspace ${connectSlackWorkspaceBot.workspaceName}`,
    );
  }, [toast, connectBot, botId, slackWorkspaceId]);

  const handleDisconnect = useCallback(async () => {
    try {
      await disconnectBot({
        variables: { botId: botId as string, slackWorkspaceBotId: slackWorkspaceBotId as string },
        update: disconnectBotUpdate,
      });
    } catch (err) {
      console.error(err);
    }

    toast.success('Disconnected bot from slack workspace');
  }, [toast, disconnectBot, botId, slackWorkspaceBotId, disconnectBotUpdate]);

  const slackWorkspaceBot = useMemo(
    () => workspaceBotData?.bot?.slackWorkspaceBot,
    [workspaceBotData?.bot?.slackWorkspaceBot],
  );
  const bot = useMemo(() => workspaceBotData?.bot, [workspaceBotData?.bot]);

  return {
    loading,
    connectLoading,
    bot,
    handleConnect,
    handleDisconnect,
    slackWorkspaceBot,
    slackWorkspaceProfile,
    disconnectLoading,
  };
};

export default useConnectSlackBot;
