import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { DIALOGUE_TYPES } from 'frontend/constants';
import { GetDialogueList } from 'frontend/features/Build/graphql';

import { SAMPLE_CANDIDATES } from '../queries';

export default (filters, botId) => {
  const { data: sampleCandidatesData, loading: sampleCandidatesLoading } = useQuery(SAMPLE_CANDIDATES, {
    fetchPolicy: 'network-only',
    variables: {
      botId,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
    },
  });

  const { data, loading: dialoguesLoading } = useQuery(GetDialogueList, {
    variables: { botId, dialogueType: DIALOGUE_TYPES.SAMPLES },
  });

  const languageSampleCandidates = useMemo(
    () =>
      (sampleCandidatesData?.sampleCandidates ?? []).map((candidate) => ({
        ...candidate,
        completed: Boolean(candidate.sample),
      })),
    [sampleCandidatesData],
  );

  return {
    sampleCandidatesLoading,
    languageSampleCandidates,
    dialogueList: data?.dialogueList,
    dialoguesLoading,
  };
};
