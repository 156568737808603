import { useCallback, useEffect } from 'react';

/**
 * Hook that calls `callback` when user clicks (or another `eventName`) outside of the element (`ref`).
 */
const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: (event: MouseEvent) => void,
  eventName: 'click' | 'mousedown' = 'click',
) => {
  const handleClick = useCallback(
    (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      callback(event);
    },
    [callback, ref],
  );

  useEffect(() => {
    document.addEventListener(eventName, handleClick, { capture: true });

    return () => {
      document.removeEventListener(eventName, handleClick, { capture: true });
    };
  }, [handleClick, eventName]);
};

export default useClickOutside;
