import { CACHE_TYPE, TICKET_STATUS } from 'frontend/features/Inbox/constants';
import { camelCaseKeys } from 'frontend/utils';

import prepareAgent from './prepareAgent';

const EMPTY_TICKET = {
  id: '',
  createdAt: null,
  solvedAt: null,
  status: TICKET_STATUS.OPEN,
  assignedAgent: null,
  chatId: '',
  actionByEndUser: false,
  __typename: CACHE_TYPE.TICKET_TYPE,
};

export default (ticket) => ({
  ...EMPTY_TICKET,
  ...camelCaseKeys({
    ...ticket,
    assignedAgent: prepareAgent(ticket.assigned_agent),
  }),
});
