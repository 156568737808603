import { useMemo, useState } from 'react';

import { PageContent } from 'frontend/components';

import styles from './styles.scss';

interface ErrorStackProps {
  error: Error;
  errorInfo: React.ErrorInfo | null;
}

const ErrorStack = ({ error, errorInfo }: ErrorStackProps) => {
  const [expandErrorStack, setExpandErrorStack] = useState(false);
  const [expandComponentStack, setExpandComponentStack] = useState(false);
  const errorStack = useMemo(
    () =>
      expandErrorStack
        ? (error?.stack ?? '').replace(/ /, '').split('\n')
        : (error?.stack ?? '').replace(/ /, '').split('\n').slice(0, 10),
    [expandErrorStack, error],
  );
  const componentStack = useMemo(
    () =>
      expandComponentStack
        ? (errorInfo?.componentStack ?? '').replace(/^\n/, '').replace(/ /, '').split('\n')
        : (errorInfo?.componentStack ?? '').replace(/^\n/, '').replace(/ /, '').split('\n').slice(0, 10),
    [expandComponentStack, errorInfo?.componentStack],
  );

  return (
    <PageContent className={styles.errorStack}>
      <h1>Exception caught by ErrorBoundary</h1>

      <hr />

      <h2>Error message</h2>
      <p className={styles.message}>{error.message}</p>

      <hr />

      <h2>Stack</h2>
      <ol className={styles.stack}>
        {errorStack.map((line, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>{line}</li>
        ))}
      </ol>
      {!expandErrorStack && (
        <button className={styles.expandButton} type="button" onClick={() => setExpandErrorStack(true)}>
          show more
        </button>
      )}

      <hr />

      <h2>Component stack</h2>
      <ol className={styles.stack}>
        {componentStack.map((line, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>{line}</li>
        ))}
      </ol>
      {!expandComponentStack && (
        <button className={styles.expandButton} type="button" onClick={() => setExpandComponentStack(true)}>
          show more
        </button>
      )}
    </PageContent>
  );
};

export default ErrorStack;
