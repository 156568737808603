import cx from 'classnames';

import styles from './InputErrorWrapper.scss';
import ShakeDiv from '../ShakeDiv';

export type ErrorPosition = 'top' | 'bottom';

interface InputErrorWrapperProps {
  hasError: boolean;
  className?: string;
  errorMessageClassName?: string;
  children: React.ReactNode;
  displayError?: string;
  errorPosition?: ErrorPosition;
}

const InputErrorWrapper = ({
  hasError,
  className,
  errorMessageClassName,
  children,
  displayError,
  errorPosition = 'top',
}: InputErrorWrapperProps) => (
  <ShakeDiv animate={hasError ? 'error' : 'normal'} className={cx(styles.inputWrapper, className)}>
    {children}
    {hasError && (
      <div
        className={cx(styles.errorMessage, errorMessageClassName, {
          [styles.errorTop]: errorPosition === 'top',
          [styles.errorBottom]: errorPosition === 'bottom',
        })}
      >
        {displayError}
      </div>
    )}
  </ShakeDiv>
);

export default InputErrorWrapper;
