import { useApolloClient, useMutation } from '@apollo/client';
import { capitalize, get, pick, upperCase } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { buildTypes } from 'frontend/constants';
import { updateDialogueParentOnCreate } from 'frontend/features/Library/cacheHelpers';
import { getBuildUrl, getDialogueTypeName } from 'frontend/features/Library/utils';
import { useToast } from 'frontend/hooks';

import { CreateDialogueMutation, GetDialogue } from '../graphql';
import { dialogueInputFields, getButtonsUpdate, getImageCarouselsUpdate, handleUrlTrigger } from '../utils';
import getFormUpdate from '../utils/getFormUpdate';
import { prepareDialogueBuildItemForBE } from '../utils/prepareDialogueBuildItem';

type Props = { botId: string; dialogueType: string; selectedLanguage: string };
type Return = [(values: unknown, form: unknown) => void, string | null];
export default ({ botId, dialogueType, selectedLanguage }: Props): Return => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [createSpecialDialogue] = useMutation(CreateDialogueMutation);
  const toast = useToast();
  const [createdUrl, setCreatedUrl] = useState<string | null>(null);

  const create = useCallback(
    async (values, form) => {
      const imageCarouselsUpdate = getImageCarouselsUpdate(undefined, values);
      const buttonsUpdate = getButtonsUpdate(undefined, values);
      const { initialValues: initial } = form.getState();
      const formUpdate = getFormUpdate(initial, values);
      const context = prepareDialogueBuildItemForBE(values.context);
      const advancedOptions = prepareDialogueBuildItemForBE(values.advancedOptions);

      const rest = pick(values, dialogueInputFields) as unknown as {
        urlTrigger: Record<string, string>;
        urlTriggerType: Record<string, unknown>;
      } & Record<string, unknown>;
      const dialogueInput = {
        ...handleUrlTrigger(selectedLanguage, rest),
        title: values.title,
        dialogueType: upperCase(dialogueType), // the query expects the type to be upper case
        createdReplies: values.replies,
        ...buttonsUpdate,
        ...formUpdate,
        ...imageCarouselsUpdate,
        context,
        advancedOptions,
      };
      const result = await createSpecialDialogue({
        variables: { botId, dialogueInput, languageCode: selectedLanguage },
      });
      const dialogue = get(result, 'data.createDialogue');

      client.writeQuery({
        query: GetDialogue,
        variables: { botId, dialogueId: dialogue.id, languageCode: selectedLanguage },
        data: { dialogue },
      });
      updateDialogueParentOnCreate({ buildIdObject: { botId }, client, dialogue });

      const url = getBuildUrl({ buildType: buildTypes.BOT, buildId: botId, dialogueType, target: dialogue.id });
      setCreatedUrl(url);
      toast.success(`${capitalize(getDialogueTypeName(dialogueType))} created!`);
    },
    [botId, client, selectedLanguage, createSpecialDialogue, dialogueType, toast],
  );

  useEffect(() => {
    if (createdUrl) {
      navigate(createdUrl);
    }
  }, [navigate, createdUrl]);

  return [create, createdUrl];
};
