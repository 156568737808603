import { useApolloClient, useMutation } from '@apollo/client';
import { get } from 'lodash/fp';
import { useParams } from 'react-router-dom';

import { ActivateChatLabelDocument, DeactivateChatLabelDocument } from 'frontend/api/generated';
import { Labels } from 'frontend/assets/icons';
import { Dropdown } from 'frontend/components';
import TabButton from 'frontend/components/TabButton/TabButton';
import LabelsSection from 'frontend/features/Labels/LabelsSection';
import { useBotLabels, useToast } from 'frontend/hooks';
import type { Label } from 'frontend/propTypes/LabelType';
import { setInsightsSelectedChat } from 'frontend/state/dux/insights';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';

import useActiveBotLabels from '../../hooks/useActiveBotLabels';
import useChatLabelArguments from '../../hooks/useChatLabelArguments';

const LabelsDropdown = () => {
  const botLabels = useBotLabels();
  const { chatId } = useParams();
  const { cache } = useApolloClient();
  const dispatch = useAppDispatch();

  const { selectedChat } = useAppSelector((state) => state.insights);

  const [labels] = useActiveBotLabels(selectedChat);

  const [activateLabel] = useMutation(ActivateChatLabelDocument);
  const [deactivateLabel] = useMutation(DeactivateChatLabelDocument);
  const chatLabelArguments = useChatLabelArguments();

  const toast = useToast();

  if (!labels) return null;

  const activeLabelsIds = labels.filter((label) => label.active).map((label) => label.id);

  const handleLabelToggle = async (label: Label, status: boolean) => {
    if (!chatId) {
      toast.error('Please select chat to add labels.');
      return;
    }
    let newLabelList: Partial<Label>[];

    const oldLabelList = selectedChat.labels;

    try {
      if (status) {
        newLabelList = labels.filter(({ active, id }) => active || id === label.id);

        const args = chatLabelArguments({ chatId, label, labels: newLabelList, type: 'activateChatLabel' });
        await activateLabel(args);
      } else {
        newLabelList = labels.filter(({ active, id }) => active && id !== label.id);

        const args = chatLabelArguments({ chatId, label, labels: newLabelList, type: 'deactivateChatLabel' });
        await deactivateLabel(args);
      }

      dispatch(
        setInsightsSelectedChat({
          ...selectedChat,
          labels: newLabelList,
          labelIds: newLabelList.map(({ id }) => id),
        }),
      );
      cache.modify({
        id: cache.identify({ __typename: 'ChatType', id: chatId }),
        fields: { labelIds: () => newLabelList.map(get('id')) },
      });
    } catch (err) {
      dispatch(
        setInsightsSelectedChat({
          ...selectedChat,
          labels: oldLabelList,
          labelIds: oldLabelList.map(({ id }) => id),
        }),
      );
      toast.error('Failed to update label.');
    }
  };

  return (
    <Dropdown
      isDisabled={!chatId || !selectedChat}
      overlay={
        <LabelsSection
          canEditLabels
          activeLabelsIds={activeLabelsIds}
          botLabels={botLabels}
          handleLabelToggle={handleLabelToggle}
          inputAutofocus
        />
      }
      element={TabButton}
      elementProps={{ icon: Labels, label: 'Label' }}
      position="right"
    />
  );
};

export default LabelsDropdown;
