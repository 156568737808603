import { EditorState, convertFromRaw } from 'draft-js';

import { getId, mapToObject } from 'frontend/utils';

import formValuesToComposerBlock from './formValuesToComposerBlock';
import getType from './getType';
import { MUTABILITY_TYPES } from '../constants';

const getBlockStart = (blockTexts, idx) =>
  blockTexts.slice(0, idx).reduce((sum, textSegment) => sum + textSegment.length, 0) + idx; // idx accounts for newlines

const makeDraftEntity = (text) => (instance) => ({
  [getId(instance)]: {
    type: getType(instance),
    mutability: MUTABILITY_TYPES.IMMUTABLE,
    data: { ...instance, original: text.replace(/\n/g, '').slice(instance.start, instance.end) },
  },
});

const getDraftEntityMap = (text, instances) => mapToObject(makeDraftEntity(text), instances);

const valuesToRaw = (name, text, instances) => {
  let entityMap = {};
  const blockTexts = text.split('\n');

  const blocks = blockTexts.map((textSegment, idx) => {
    const blockStart = getBlockStart(blockTexts, idx);
    const blockEnd = blockStart + textSegment.length;

    const instancesInBlock = instances
      .filter(({ start }) => start >= blockStart && start < blockEnd)
      .map(({ start, end, ...rest }) => ({ ...rest, start: start - blockStart, end: end - blockStart }));

    const newDraftEntityMap = getDraftEntityMap(textSegment, instancesInBlock);

    const { text: editorText, instances: editorInstances } = formValuesToComposerBlock(textSegment, instancesInBlock);

    entityMap = { ...entityMap, ...newDraftEntityMap };

    return {
      key: `content-block-${name}-${idx}`,
      text: editorText,
      entityRanges: editorInstances.map((instance) => ({
        key: getId(instance),
        offset: instance.start,
        length: instance.end - instance.start,
      })),
    };
  });

  return { blocks, entityMap };
};

export default (name, text, instance) => {
  const { blocks, entityMap } = valuesToRaw(name, text, instance);
  const content = convertFromRaw({ blocks, entityMap });
  return EditorState.createWithContent(content);
};
