import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';

import { ImageAvatar, Input, MultiSelect } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import type { PartialUser } from 'frontend/features/Organization/modals/AddMemberModal/AddMemberModal';
import { chain, email as emailValidation, required } from 'frontend/form/validators';

import styles from './styles.scss';

type Props = {
  existingEmails: string[];
  to: { organizationId: string; botId?: never } | { botId: string; organizationId: string };
} & (
  | {
      type: 'select';
      users: PartialUser[];
    }
  | {
      type: 'input';
      users: never[];
    }
);
export default function UserPicker({ type, users, existingEmails, to }: Props) {
  const isBotMembership = !!to.botId;

  const validateUser = useCallback(
    (value: string | undefined): string | undefined => {
      if (existingEmails.includes((value || '').toLowerCase().trim())) {
        return 'A user with this email already exists';
      }
      return undefined;
    },
    [existingEmails],
  );

  const userOptions = useMemo(() => {
    if (type === 'input') {
      return [];
    }

    return users.map((user) => {
      const isMember = existingEmails.includes((user.username || '').toLowerCase().trim());
      return {
        value: user.username,
        label: (
          <div
            className={styles.email}
            title={emailValidation(user.username) || (isMember ? 'UserPicker with this email already exists' : '')}
          >
            <ImageAvatar img={user.profile.avatarUrl} className={styles.avatar} /> {user.username}
          </div>
        ),
        isDisabled: isMember || emailValidation(user.username),
      };
    });
  }, [type, users, existingEmails]);

  const element =
    type === 'select' ? (
      <Field
        component={MultiSelect}
        name="selectedUsers"
        type="email"
        placeholder="example@example.com"
        options={userOptions}
        maxMenuHeight={150}
        noOptionsMessage={() => 'No more users'}
        validate={(val) => required(val.length)}
        autoFocus
        aria-label="email"
      />
    ) : (
      <Field
        component={Input}
        name="email"
        type="email"
        placeholder="example@example.com"
        validate={chain(required, emailValidation, validateUser)}
        autoFocus
        autoComplete="off"
        aria-label="email"
      />
    );

  return (
    <Modal.Content className={styles.content}>
      <p className="m-t-0">You can change or remove permissions later.</p>
      {isBotMembership && (
        <p className={styles.mentionOrgInvite}>
          <br />
          Before inviting a new user to a workspace, you must{' '}
          <Link to={`/organization/${to.organizationId}/members`}>invite them to your organization</Link>.
        </p>
      )}
      <h4>Email</h4>
      {element}
    </Modal.Content>
  );
}
