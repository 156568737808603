import cx from 'classnames';

import Tooltip from 'frontend/components/Tooltip/Tooltip';

import styles from './Badge.scss';

interface BadgeProps {
  title: string;
  tooltip: string;
  tooltipMaxWidth?: string;
  className?: string;
}

const Badge = ({ title, tooltip, tooltipMaxWidth, className }: BadgeProps) => {
  const wrapStyles = cx(styles.badgeWrapLabel, className);
  return (
    <div className={wrapStyles}>
      <Tooltip>
        <span className={styles.badgeLabel}>{title}</span>
        {!!tooltip && (
          <Tooltip.Body className={styles.pushDown} maxWidth={tooltipMaxWidth}>
            {tooltip}
          </Tooltip.Body>
        )}
      </Tooltip>
    </div>
  );
};

export default Badge;
