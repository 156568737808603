import PropTypes from 'prop-types';
import { Field, useForm, useFormState } from 'react-final-form';

import { Trash } from 'frontend/assets/icons';
import { Icon, Input } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { url } from 'frontend/form/validators';
import { useCurrentLanguage } from 'frontend/hooks';

import styles from './Webhook.scss';
import { DEFAULT_RULE_ID, dialogueFeatureTypes } from '../../constants';
import useCurrentRuleId from '../../hooks/useCurrentRuleId';

const Webhook = ({ setFeatureType, isModDialogue = false }) => {
  const { change } = useForm();
  const { submitting: disabled } = useFormState();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const currentRuleId = useCurrentRuleId() || DEFAULT_RULE_ID;

  return (
    <div className="m-b-3">
      <p className="m-t-0">Post a query to this URL</p>
      <Field
        name={`${isModDialogue ? 'modWebhookUrl' : 'webhookUrls'}.${selectedLanguage}.${currentRuleId}`}
        inputType="url"
        component={Input as any}
        validate={url}
        placeholder="Webhook URL"
        aria-label="Webhook URL"
        parse={(val) => {
          if (!val) return null;
          return val;
        }}
        readOnly={disabled}
        fieldColor={FIELD_COLOR.MISCHKA}
      />
      <div className={styles.webhookFooter}>
        <p className="m-n">
          <a href="https://docs.kindly.ai/webhooks" className="link" target="_blank" rel="noopener noreferrer">
            Documentation
          </a>
        </p>
        {!isModDialogue && (
          <Icon
            component={Trash}
            onClick={() => {
              setFeatureType(dialogueFeatureTypes.NONE);
              change(`${isModDialogue ? 'modWebhookUrl' : 'webhookUrls'}.${selectedLanguage}.${currentRuleId}`, null);
            }}
            color="default"
            hoverColor="warning"
          />
        )}
      </div>
    </div>
  );
};

Webhook.propTypes = {
  setFeatureType: PropTypes.func.isRequired,
  isModDialogue: PropTypes.bool,
};

export default Webhook;
