import { useEffect, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Select, SelectLanguage, ToggleSwitch } from 'frontend/components';
import { featurizerChoices } from 'frontend/features/Train/constants';
import type { LanguageAugmented } from 'frontend/hooks/useLanguages';

import styles from '../Train.scss';

function getSupportedFeaturizers() {
  return Object.entries(featurizerChoices)
    .filter(([, featurizer]) => featurizer.isMultilingual)
    .map(([, featurizer]) => featurizer);
}

interface TrainFormFieldsProps {
  languages: LanguageAugmented[];
  currentLanguage: string;
}

function TrainFormFields({ languages, currentLanguage }: TrainFormFieldsProps) {
  const { change } = useForm();
  const { values } = useFormState();

  const { supportedLearners } = useMemo(() => {
    const featurizer = values.zodiacSetting.featurizer;
    const featurizerNames = Object.entries(featurizerChoices).map(([, { name }]) => name);
    const selectedFeaturizer =
      featurizer !== undefined && featurizerNames.includes(featurizer) ? featurizer : featurizerNames[0];

    return {
      supportedLearners: featurizerChoices[selectedFeaturizer]?.supportedLearners ?? [],
    };
  }, [values.zodiacSetting.featurizer]);

  // Ensure featurizer is supported, if not then set default values
  useEffect(() => {
    const supportedFeaturizers = getSupportedFeaturizers();
    const supportedFeaturizer = supportedFeaturizers[0];

    if (
      supportedFeaturizer &&
      !supportedFeaturizers.map((featurizer) => featurizer.name).includes(values.zodiacSetting.featurizer)
    ) {
      change(`zodiacSetting.featurizer`, supportedFeaturizer.name);
    }

    if (
      supportedLearners.length === 0 ||
      !supportedLearners.map((learner) => learner.name).includes(values.zodiacSetting.learner)
    ) {
      change(`zodiacSetting.learner`, supportedLearners[0]?.name);
    }
  }, [change, currentLanguage, supportedLearners, values]);

  return (
    <>
      <label htmlFor="select-language">
        Select language
        <div className={styles.selectContainer}>
          <SelectLanguage languages={languages} guardChanges={false} supportVariants={false} />
        </div>
      </label>
      <div className={styles.languageToggles}>
        <ToggleSwitch
          name="zodiacSetting.enabled"
          status={['Enabled', 'Disabled']}
          onClick={() => change(`zodiacSetting.trainDaily`, false)}
        />
        <ToggleSwitch
          name="zodiacSetting.trainDaily"
          status={['Daily training on', 'Daily training off']}
          disabled={!values.zodiacSetting.enabled}
        />
      </div>
      <br />

      <label htmlFor="select-featurizer">
        Featurizer
        <div className={styles.selectContainer}>
          <Field component={Select} name="zodiacSetting.featurizer">
            {Object.entries(featurizerChoices).map(([, featurizer]) => (
              <Select.Option
                key={`featurizer-option-${featurizer.name}`}
                value={featurizer.name}
                label={featurizer.label}
              />
            ))}
          </Field>
        </div>
      </label>

      <label htmlFor="select-learner">
        Learner
        <div className={styles.selectContainer}>
          <Field component={Select} name="zodiacSetting.learner">
            {supportedLearners.map((learner) => (
              <Select.Option key={`learner-option-${learner.name}`} value={learner.name} label={learner.label} />
            ))}
          </Field>
        </div>
      </label>
    </>
  );
}

export default TrainFormFields;
