import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import { Button } from 'frontend/components';
import { DEFAULT_DIALOGUE_LABEL_COLOR } from 'frontend/features/Library/constants';
import { Modal } from 'frontend/features/Modals';

import styles from './ResolveConflicts.scss';
import SamplePairRow from '../components/SamplePairRow';

const ResolveConflicts = ({ hide, args: { confusedDialogues, selectedLanguage, botId } }) => {
  const { confusedSamplePairs, dialogueA, dialogueB } = confusedDialogues;

  return (
    <Form
      onSubmit={() => false}
      render={({ handleSubmit }) => (
        <Modal onOk={handleSubmit} className={styles.modalContainer} footer={false} hide={hide}>
          <Modal.Content className={styles.modalContent}>
            <div className={styles.titleContainer}>
              <Link className={styles.title} to={`/bot/${botId}/build/dialogue/${dialogueA.id}`} target="_blank">
                <div
                  style={{ backgroundColor: dialogueA?.colorLabel?.[selectedLanguage] ?? DEFAULT_DIALOGUE_LABEL_COLOR }}
                  className={styles.icon}
                />
                <div className={styles.text}>{dialogueA?.title[selectedLanguage]}</div>
              </Link>
              <Link className={styles.title} to={`/bot/${botId}/build/dialogue/${dialogueB.id}`} target="_blank">
                <div
                  style={{ backgroundColor: dialogueB?.colorLabel?.[selectedLanguage] ?? DEFAULT_DIALOGUE_LABEL_COLOR }}
                  className={styles.icon}
                />
                <div className={styles.text}>{dialogueB?.title[selectedLanguage]}</div>
              </Link>
              <span />
            </div>

            {confusedSamplePairs.map((samplePair) => (
              <SamplePairRow key={samplePair.id} confusedSamplePair={samplePair} selectedLanguage={selectedLanguage} />
            ))}
          </Modal.Content>

          <Modal.Footer className={styles.modalFooter}>
            <span>{confusedSamplePairs?.length} samples in conflict</span>
            <Button onClick={() => hide()} color="dark">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    />
  );
};

ResolveConflicts.propTypes = {
  hide: PropTypes.func,
  args: PropTypes.shape({}),
};

export default ResolveConflicts;
