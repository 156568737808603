import cx from 'classnames';
import { NavLink, type To } from 'react-router-dom';

import { FIELD_COLOR } from 'frontend/constants';

import styles from './Tabs.scss';

interface NavLinkTabProps {
  title: string;
  to: To;
  tabColor?: string;
}

const NavLinkTab = ({ title, to, tabColor }: NavLinkTabProps) => (
  <NavLink
    className={({ isActive }) =>
      cx(styles.btn, styles.tab, {
        [styles.tabColorMischka]: tabColor === FIELD_COLOR.MISCHKA,
        [styles.tabSelected]: isActive,
      })
    }
    to={to}
  >
    <span>{title}</span>
  </NavLink>
);

export default NavLinkTab;
