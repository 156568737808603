import { gql } from '@apollo/client';

import { DIALOGUE_MOD_WITH_ENTITIES, DIALOGUE_WITH_ENTITIES } from '../fragments';

export default gql`
  query EntityDialogues($botId: ID, $skillId: ID, $id: UUID!) {
    entity(botId: $botId, skillId: $skillId, id: $id) {
      id
      name
      dialogues {
        ...DialogueWithEntities
      }
      dialogueMods {
        ...DialogueModWithEntities
      }
    }
  }
  ${DIALOGUE_WITH_ENTITIES}
  ${DIALOGUE_MOD_WITH_ENTITIES}
`;
