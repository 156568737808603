import { useLocation, useParams } from 'react-router-dom';

import { AnalyticsContent, FrequentDialogues, Grid, LuzmoHighlight } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import LabelsStats from 'frontend/features/Analytics/views/Sessions/components/Labels/LabelsStats';
import { useBooleanState } from 'frontend/hooks';
import { PRIORITY } from 'frontend/state/dux/analytics/dataQueue';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

import { ButtonClicks, ChatbubblePages, Engagement, FallbackRate, Interactions, Sessions } from './components';
import getSessionsUrl from './getSessionsUrl';
import Filters, { FilterInfo } from '../Filters';

const FAQ_LIMIT = 5;
const HIDE_FILTERS = { nudges: true, feedbacks: true, engagementCampaigns: true };

const SessionsOverview = () => {
  const { botId } = useParams();
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const filters = useFilters();
  const scope = sageScope(SAGE_RESOURCE.BOT, botId);
  const { search: queryParams } = useLocation();

  const URLFor = (view) => `${getSessionsUrl(botId, view)}${queryParams}`;

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} hideFilter={HIDE_FILTERS} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} />
        <div className="m-b-4">
          <Grid columns={1}>
            <LuzmoHighlight botId={botId!} />
          </Grid>
          <Grid>
            <div>
              <Sessions to={URLFor('sessions-detail')} scope={scope} filters={filters} isPreview />
            </div>
            <div>
              <Interactions
                to={URLFor('messages-detail')}
                priority={PRIORITY.HIGH}
                scope={scope}
                filters={filters}
                isPreview
              />
            </div>
          </Grid>
          <Grid>
            <div>
              <Engagement to={URLFor('engagement')} priority={PRIORITY.LOW} scope={scope} filters={filters} isPreview />
            </div>
            <div>
              <FallbackRate
                to={URLFor('fallback-rate')}
                priority={PRIORITY.LOW}
                scope={scope}
                filters={filters}
                isPreview
              />
            </div>
          </Grid>
          <Grid>
            <div>
              <FrequentDialogues
                botId={botId!}
                to={URLFor('frequent-dialogues')}
                scope={scope}
                filters={{ ...filters, limit: FAQ_LIMIT }}
                faqLimit={FAQ_LIMIT}
                isPreview
              />
            </div>
            <div>
              <ChatbubblePages
                to={URLFor('chatbubble-pages')}
                scope={scope}
                filters={{ ...filters, limit: 5 }}
                isPreview
              />
            </div>
          </Grid>
          <Grid>
            <div>
              <ButtonClicks
                to={URLFor('button-clicks')}
                botId={botId!}
                filters={filters}
                scope={scope}
                priority={PRIORITY.LOW}
              />
            </div>
            <div>
              <LabelsStats
                to={URLFor('labels-detail')}
                scope={scope}
                filters={filters}
                priority={PRIORITY.LOW}
                isPreview
              />
            </div>
          </Grid>
        </div>
      </AnalyticsContent>
    </>
  );
};

export default SessionsOverview;
