import cx from 'classnames';
import type { FieldRenderProps } from 'react-final-form';
import type { FieldValues, UseFormRegister } from 'react-hook-form';

import styles from './Radio.scss';

type FinalFormFieldRenderProps = Omit<FieldRenderProps<string>, 'input' | 'meta'>;
interface RadioProps<T extends FieldValues> extends FinalFormFieldRenderProps {
  input?: Omit<FieldRenderProps<string>['input'], 'onBlur' | 'onFocus'>;
  className?: string;
  label?: React.ReactElement | string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  /** Allow multiple `<Radio>` to be displayed `inline` or each on a new line (`block`). */
  layout?: 'inline' | 'block';
  /** React Hook Form register function and eventual options. */
  rhf?: {
    register: UseFormRegister<T>;
    options?: Parameters<UseFormRegister<T>>[1];
  };
}

export default function Radio<T extends FieldValues>({ input, label, onClick, className, layout, rhf }: RadioProps<T>) {
  return (
    <div className={cx(styles.radio, { [styles.inline]: layout === 'inline' })}>
      <label className={className}>
        <input
          className={styles.radioInput}
          onClick={onClick}
          type="radio"
          {...input}
          {...rhf?.register(input?.name, rhf?.options)}
        />
        <div className={styles.radioLabel}>{label}</div>
      </label>
    </div>
  );
}
