import cx from 'classnames';
import React, { memo } from 'react';

import { Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './Field.scss';
import type { FieldConfigFieldType, FieldsConfigType } from '../../utils/configs';
import FieldInput from '../FieldInput/FieldInput';
import FieldOptionsButton from '../FieldOptionsButton/FieldOptionsButton';

export interface RenderFieldProps {
  onFieldRemove: () => void;
  canHideRow: boolean;
  fieldIndex?: number;
  property: FieldConfigFieldType;
  propertyKey: keyof FieldsConfigType;
  onPointerDown?: (event: React.PointerEvent<Element>) => void;
  onDragging?: (dragging?: number) => void;
}

const Field = ({
  fieldIndex,
  onFieldRemove,
  canHideRow,
  propertyKey,
  property,
  onPointerDown,
  onDragging,
}: RenderFieldProps) => {
  const className = cx(styles.fieldInputWrapper, {
    [styles.fieldInputColumn]: property.groupDisplay === 'column',
  });

  const renderButton = () => {
    if (onPointerDown) {
      return (
        <FieldOptionsButton
          onDragging={(dragging) => {
            if (dragging) {
              onDragging?.(fieldIndex);
            } else {
              onDragging?.(-1);
            }
          }}
          id={`context-menu-${propertyKey}`}
          onPointerDown={onPointerDown}
          options={[
            {
              text: 'Delete',
              color: 'red',
              icon: Trash,
              onClick: (event) => {
                event?.stopPropagation();
                onFieldRemove();
              },
            },
          ]}
        />
      );
    }
    return <Icon hoverColor="warning" component={Trash} color="gray" onClick={onFieldRemove} />;
  };

  const isGroupedWith = property.groupWith && property.groupWith.length > 0;
  return (
    <div className={className}>
      <div className={styles.fieldInnerWrapper}>
        <div className={styles.fieldContainer}>
          <FieldInput propertyKey={propertyKey} property={property} fieldIndex={fieldIndex} />
        </div>
        {isGroupedWith &&
          property.groupWith.map((groupedProperty) => (
            <div key={`fieldGroup-${groupedProperty.key}`} className={styles.fieldContainer}>
              <FieldInput
                propertyKey={propertyKey}
                property={property}
                fieldIndex={fieldIndex}
                groupedProperty={groupedProperty}
              />
            </div>
          ))}
        {canHideRow ? renderButton() : <div className={styles.hidePlaceholderContent} />}
      </div>
      {property.description && <div className={styles.fieldDescription}>{property.description}</div>}
    </div>
  );
};

const arePropsEqual = (oldProps, newProps) =>
  oldProps.onPointerDown === newProps.onPointerDown &&
  oldProps.canHideRow === newProps.canHideRow &&
  oldProps.property.key === newProps.property.key &&
  oldProps.onFieldRemove === newProps.onFieldRemove;

export default memo(Field, arePropsEqual);
