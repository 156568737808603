import cx from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { LinkBreak } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import styles from 'frontend/features/BuildDashboard/components/Buttons/Buttons.scss';
import BrokenLinksInput from 'frontend/features/Library/components/BrokenLinks/components/BrokenLinksInput';
import { getBuildUrl } from 'frontend/features/Library/utils';

import brokenLinkStyles from '../BrokenLinks.scss';

export default ({ buildIdObject, buildType }) => {
  const renderMap = useMemo(
    () => ({
      // eslint-disable-next-line react/display-name, react/prop-types
      label: ({ data: label }) => (
        <div className={brokenLinkStyles.brokenLinkIconWrapper}>
          <div className={brokenLinkStyles.brokenLinkIconContainer}>
            <Icon component={LinkBreak} className={cx(brokenLinkStyles.buttonIcon)} />
          </div>
          <div className={brokenLinkStyles.button}>{label}</div>
        </div>
      ),
      // eslint-disable-next-line react/display-name, react/prop-types
      dialogue: ({ data: { id, title, dialogueType, isActive } }) => (
        <Link
          className={cx(styles.dialogue, { [styles.dialogueDisabled]: !isActive })}
          to={getBuildUrl({ buildType, buildIdObject, dialogueType, target: id })}
        >
          {title}
        </Link>
      ),
      link: ({ rowData: { id, value } }) => <BrokenLinksInput id={id} initialValue={value} />,
    }),
    [buildIdObject, buildType],
  );

  return renderMap;
};
