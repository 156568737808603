import { gql } from '@apollo/client';

import { TICKET } from 'frontend/features/Inbox/fragments';

export default gql`
  mutation ChangeTicketStatus($ticketId: ID!, $status: Status!) {
    changeTicketStatus(ticketId: $ticketId, status: $status) {
      ...Ticket
    }
  }
  ${TICKET}
`;
