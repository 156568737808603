import { defineAction } from 'redux-define';

const CHAT = defineAction('chat');

export const SHOW_NUDGE = CHAT.defineAction('SHOW_NUDGE');

export const triggerNudge = (nudgeHaveBeenCalled, slug) => ({
  type: SHOW_NUDGE.ACTION,
  nudgeHaveBeenCalled,
  slug,
});

const initialState = {
  slug: '',
  nudgeHaveBeenCalled: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NUDGE.ACTION:
      return { ...state, nudgeHaveBeenCalled: action.nudgeHaveBeenCalled, slug: action.slug };
    default:
      return state;
  }
};
