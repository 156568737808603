import { Loader } from 'frontend/components';

const Searching = () => (
  <>
    <Loader size="large" noMargin />
    <br />
    <br />
    <h3>Looking for Kindly entities in existing dialogues. This might take a while..</h3>
  </>
);

export default Searching;
