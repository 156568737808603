import cx from 'classnames';
import { Link } from 'react-router-dom';

import type { Row } from './Table';
import styles from './Table.scss';

type TableRowProps = Omit<Row, 'id'> & { children: React.ReactNode; className?: string };

const TableRow = ({ children, className: rowClassName, to, onClick, onKeyDown }: TableRowProps) => {
  const role = onClick ? 'button' : 'row';
  const className = cx(styles.row, rowClassName, { [styles.rowDarkensOnRowHover]: role === 'button' || Boolean(to) });

  if (to) {
    return (
      <Link className={className} to={to} onClick={onClick} onKeyDown={onKeyDown} role={role} tabIndex={0}>
        {children}
      </Link>
    );
  }

  return (
    <div className={className} onClick={onClick} onKeyDown={onKeyDown} role={role} tabIndex={0}>
      {children}
    </div>
  );
};

export default TableRow;
