import styles from './ActivityLog.scss';
import animationStyles from '../animation.scss';

const SvgLoader = () => (
  <div className={styles.ghostContainer}>
    <div className={animationStyles.ghostStripe}>
      <div className={styles.ghostCircle}>
        <svg width="60" height="100">
          <defs>
            <mask id="mask" x="0" y="0" width="60" height="100">
              <rect x="0" y="0" width="60" height="100" fill="#fff" />
              <circle cx="30" cy="30" r="15" />
            </mask>
          </defs>
          <rect x="0" y="0" width="60" height="100" mask="url(#mask)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>

      <div className={styles.ghostLines}>
        <svg width="100%" height="120px" preserveAspectRatio="none">
          <defs>
            <mask id="mask2" x="0" y="0" width="100%" height="100">
              <rect x="0" y="0" width="100%" height="100" fill="#FFF" />
              <rect x="2" y="21px" rx="2" ry="2" width="97%" height="15px" />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="100px" mask="url(#mask2)" fill="#FFF" fillOpacity="1" />
        </svg>
      </div>
    </div>
  </div>
);

const ActivityLog = () => (
  <>
    <SvgLoader />
    <SvgLoader />
    <SvgLoader />
    <SvgLoader />
  </>
);

export default ActivityLog;
