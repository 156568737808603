import { SortableContext } from '@dnd-kit/sortable';
import { get } from 'lodash';
import { useRef, useState } from 'react';
import { useFormState } from 'react-final-form';

import { DRAGGABLE_TYPES } from 'frontend/features/Dnd/constants';
import { useFormSort } from 'frontend/features/Dnd/hooks';
import { useClickOutside } from 'frontend/hooks';
import { getId } from 'frontend/utils';

import styles from './ImageCarousel.scss';
import { EditableImage, ImageMeta, Upload } from './components';
import ButtonsMeta from './components/ButtonsMeta/ButtonsMeta';
import type { ImageField } from './components/Image/Image';

interface ImageCarouselProps {
  fieldName: string;
  currentLanguage: string;
  currentRuleId?: string;
  className?: string;
  children?: React.ReactNode;
  imageFields?: ImageField[];
  /** Slot to inject the image size component. */
  componentImageSize?: React.JSX.Element;
  /** If true, the image carousel is used in Dialogues. */
  isDialogueCarousel?: boolean;
}

export default function ImageCarousel({
  fieldName: imageCarouselFieldName,
  currentLanguage,
  currentRuleId,
  children,
  className,
  imageFields = ['title', 'description', 'altText', 'linkUrl', 'newTab'],
  componentImageSize,
  isDialogueCarousel,
}: ImageCarouselProps) {
  const [selectedImageId, setSelectedImageId] = useState('');
  const { submitting: disabled, values } = useFormState();

  const metaSectionRef = useRef<HTMLElement>(null);
  useClickOutside(metaSectionRef, () => {
    setSelectedImageId('');
  });

  const images = get(values, imageCarouselFieldName)?.images ?? [];
  const sortingImageId = useFormSort(DRAGGABLE_TYPES.IMAGE, `${imageCarouselFieldName}.images`);

  return (
    <div className={className}>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>Images</h3>
        {children}
      </div>

      <p className="m-t-2 text-color-light">Upload multiple images to create a carousel.</p>
      <Upload fieldName={imageCarouselFieldName} currentLanguage={currentLanguage} currentRuleId={currentRuleId} />

      {componentImageSize}

      {images.length > 0 && (
        <div className={styles.images}>
          <SortableContext items={images}>
            {images.map((image) => (
              <EditableImage
                key={`image-${getId(image)}`}
                image={image}
                singleImage={images.length === 1}
                setSelectedImageId={setSelectedImageId}
                selectedImageId={selectedImageId}
                isSorting={getId(image) === sortingImageId}
                disabled={disabled}
              />
            ))}
          </SortableContext>
        </div>
      )}

      <section ref={metaSectionRef} className={styles.accordionsWrapper}>
        <ImageMeta
          images={images}
          fieldName={imageCarouselFieldName}
          selectedImageId={selectedImageId}
          setSelectedImageId={setSelectedImageId}
          imageFields={imageFields}
        />
        {isDialogueCarousel && (
          <ButtonsMeta images={images} fieldName={imageCarouselFieldName} selectedImageId={selectedImageId} />
        )}
      </section>
    </div>
  );
}
