import PropTypes from 'prop-types';

import { DropdownButton } from 'frontend/components';

import styles from './ContextFields.scss';

const FieldActions = ({ rowData, columnData: { deleteField } }) => {
  const type = rowData?.__typename;

  if (type !== 'ContextFieldType') return null;

  return (
    <div className={styles.fieldAction}>
      <DropdownButton
        wrapperClassName={styles.actionsButton}
        actions={[
          {
            title: 'Delete field',
            key: 'delete',
            onClick: () => deleteField(rowData),
          },
        ]}
      />
    </div>
  );
};

FieldActions.propTypes = {
  rowData: PropTypes.shape({
    __typename: PropTypes.string,
  }).isRequired,
  columnData: PropTypes.shape({
    deleteField: PropTypes.func,
  }).isRequired,
};

export default FieldActions;
