import PropTypes from 'prop-types';

import { Metric } from 'frontend/components/Stats';
import { Grid, StatsPanel } from 'frontend/features/Analytics/components';

import DoughnutPanel from './DoughnutPanel';
import styles from '../../../styles.scss';

const MetricWithDoughnutPanel = ({ absoluteMetric, rateMetric, chartData, title, onHeaderIconClick, loading }) => (
  <StatsPanel loading={loading} title={title} onHeaderIconClick={onHeaderIconClick}>
    {!Number.isNaN(rateMetric.metric) && (
      <Grid columns={2} noMargin>
        <StatsPanel className={styles.statsPanel} nested>
          <Metric metric={absoluteMetric.metric} topText={absoluteMetric.text} center={false} />
        </StatsPanel>
        <DoughnutPanel data={chartData} metric={rateMetric.metric} text={rateMetric.text} />
      </Grid>
    )}
  </StatsPanel>
);

MetricWithDoughnutPanel.propTypes = {
  title: PropTypes.string,
  absoluteMetric: PropTypes.shape({
    metric: PropTypes.number,
    text: PropTypes.string,
  }),
  rateMetric: PropTypes.shape({
    metric: PropTypes.number,
    text: PropTypes.string,
  }),
  chartData: PropTypes.shape({}),
  loading: PropTypes.bool,
  onHeaderIconClick: PropTypes.func,
};

export default MetricWithDoughnutPanel;
