import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { List } from 'frontend/components';

import { OrganizationContentType } from './propTypes';
import styles from '../styles.scss';

type OrganizationContentMenuData = {
  title: string;
  key: string;
  to: string;
};

const OrganizationContentMenu = ({ close = () => null, organization }) => {
  const data: OrganizationContentMenuData[] = [
    {
      title: `Workspaces (${organization.botCount})`,
      key: 'navbar-organization-bots-key',
      to: `organization/${organization.id}/dashboard`,
    },
    {
      title: `Skills (${organization.skillCount})`,
      key: 'navbar-organization-skills-key',
      to: `organization/${organization.id}/skills`,
    },
  ];

  return (
    <List
      width="255px"
      dataSource={data}
      className={styles.listWrapper}
      headerClassName={styles.headerWrapper}
      renderHeader={() => <div className={styles.listHeader}>{organization.name}</div>}
      renderItem={(item: OrganizationContentMenuData) => (
        <List.Item className={styles.userMenuItem} onClick={close} key={item.key} tabIndex={item.to ? -1 : 0}>
          <Link to={item.to}>{item.title}</Link>
        </List.Item>
      )}
    />
  );
};

OrganizationContentMenu.propTypes = {
  close: PropTypes.func,
  organization: OrganizationContentType.isRequired,
};

export default OrganizationContentMenu;
