import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { KindlyBlob, Nudge, Search } from 'frontend/assets/icons';
import { EmptyState, Icon } from 'frontend/components';
import { useBotOrSkill } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { resetLibrarySearch, selectLibrarySearch } from 'frontend/state/dux/library';

import { ClearSearchButton } from './ClearSearchButton';
import DialogueItem from '../../../DialogueItem';
import ProactivityItem from '../../../ProactivityItem/ProactivityItem';
import styles from '../../LibrarySearch.scss';

export default function Searching() {
  const { botId } = useParams();
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });
  const { buildIdObject } = useBotOrSkill();

  const dispatch = useDispatch();

  const { hits, selected, searchQuery, filters } = useSelector(selectLibrarySearch);

  const { enabledDialogueLabels, enabledDialogueTypes, enabledDialogueColors } = filters;

  const enabledDialogueColorLength = enabledDialogueColors.length;
  const enabledDialogueTypesLength = enabledDialogueTypes.length;
  const enabledDialogueLabelsLength = enabledDialogueLabels.length;

  const filtersActive =
    enabledDialogueLabelsLength > 0 || enabledDialogueColorLength > 0 || enabledDialogueTypesLength > 0;

  const activeQuery = filtersActive || !!searchQuery;

  const numNudges = hits.nudges.length;
  const hasNudges = Boolean(numNudges);
  const numDialogues = hits.dialogues.length;
  const hasDialogues = Boolean(numDialogues);
  const noSearchResult = isEmpty(hits.nudges) && isEmpty(hits.dialogues);

  const canManageNudges = hasBotPerms('manage_nudges');

  const clearSearch = () => {
    dispatch(resetLibrarySearch());
  };

  return (
    <div className={styles.results}>
      {noSearchResult && activeQuery && (
        <EmptyState
          title="No results"
          icon={Search}
          description="Your search did not give any results"
          color="pink"
          className={styles.emptyState}
        >
          <ClearSearchButton clearSearch={clearSearch} />
        </EmptyState>
      )}
      {noSearchResult && !activeQuery && (
        <EmptyState
          title="Start searching"
          icon={Search}
          description="You can search by title, sample texts, URLs, button texts and more"
          color="pink"
          className={styles.emptyState}
        />
      )}
      {!noSearchResult && activeQuery && (
        <>
          <div className={styles.resultsHeader}>
            <Icon component={KindlyBlob} className={styles.resultsHeaderIcon} />
            Dialogues ({numDialogues})
          </div>
          {hasDialogues &&
            hits.dialogues.map((dialogue, index) => (
              <DialogueItem
                key={`search-result-${dialogue.id}`}
                dialogue={dialogue}
                buildIdObject={buildIdObject!}
                selected={selected === index}
                parentDisabled={false}
                indentationLevel={0}
                showContextMenu={false}
              />
            ))}
          {!hasDialogues && <div className={styles.emptyResult}>No dialogues matches your search</div>}
          {!loadingPermissions && canManageNudges && (
            <>
              <div className={cx(styles.resultsHeader, styles.resultsHeaderNudge)}>
                <Icon component={Nudge} className={styles.resultsHeaderIcon} />
                Nudges ({numNudges})
              </div>
              {!hasNudges && <div className={styles.emptyResult}>No nudges matches your search</div>}
              {hasNudges &&
                hits?.nudges?.map((nudge) => (
                  <ProactivityItem show="nudge" key={`search-result-${nudge.slug}`} onClick={() => {}} item={nudge} />
                ))}
            </>
          )}
        </>
      )}
    </div>
  );
}
