import { useEffect } from 'react';
import { pageview } from 'react-ga';

import avatarOmg from 'frontend/assets/images/avatar-omg.png';
import { CenteredComponent, PageContent } from 'frontend/components';
import { useTabTitle } from 'frontend/hooks';

import styles from './NotFound.scss';

interface NotFoundProps {
  className?: string;
  text?: string;
  children?: React.ReactNode;
}

const NotFound = ({ className, text = 'This page cannot be found', children }: NotFoundProps) => {
  useTabTitle('Page not found');

  useEffect(() => {
    pageview('/not-found');
  }, []);

  return (
    <PageContent className={className}>
      <CenteredComponent>
        <div className={styles.wrapper}>
          <img className={styles.avatar} src={avatarOmg as string} alt="404 Not found illustration" />
          <div>
            <h2 className={styles.subtitle}>{text}</h2>
            {children}
          </div>
        </div>
      </CenteredComponent>
    </PageContent>
  );
};

export default NotFound;
export { NotFound as NotFoundComponent };
