import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GetOrganizationDocument } from 'frontend/api/generated';
import { Badge, Button, LoaderSwitch, PageContent } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { AddSubscription, EngagementId, OrganizationActivation } from 'frontend/features/Organization/modals';
import { SET_ENGAGEMENT_ID } from 'frontend/features/Organization/mutations';
import DeleteOrganization from 'frontend/features/Organization/views/Settings/components/DeleteOrganization/DeleteOrganization';
import { useIsRunning, useToast } from 'frontend/hooks';
import { pluralize } from 'frontend/utils';

import { SubscriptionList } from './components';

function OrganizationDetails() {
  const { organizationId: id } = useParams();
  const toast = useToast();

  const [setEngagementIdMutation] = useMutation(SET_ENGAGEMENT_ID, {
    update: () => toast.success('Engagement ID deleted'),
  });

  const [showDeactivateModal] = useModal(OrganizationActivation);
  const [showAddSubscriptionModal] = useModal(AddSubscription);
  const [showEngagementIdModal] = useModal(EngagementId);
  const [showDeleteModal] = useModal(DeleteOrganization);

  const { data, loading } = useQuery(GetOrganizationDocument, { variables: { id: id! }, skip: !id });
  const organization = data?.organization;

  const openEngagementIdModal = () => {
    if (!organization) {
      return;
    }

    showEngagementIdModal({
      organizationId: organization.id,
      engagementId: organization.engagementId,
    });
  };

  const [setEngagementId, isDeletingEngagementId] = useIsRunning(setEngagementIdMutation);
  const unsetEngagementId = () => setEngagementId({ variables: { organizationId: organization?.id } });

  if (loading || !organization) {
    return null;
  }

  return (
    <PageContent>
      <LoaderSwitch loading={loading} size="large">
        <h1>
          {organization.name}{' '}
          {organization.engagementId && (
            <Badge
              title="Engagement"
              tooltip={`This organization exists in Engagement with id:${organization.engagementId}`}
            />
          )}
        </h1>
        <h3 className="m-b-md">{pluralize('Subscription', organization.subscriptions?.length)}</h3>
        <Button onClick={() => showAddSubscriptionModal({ organization })} className="m-b-md">
          Add subscription
        </Button>
        <SubscriptionList organization={organization} />
        <h3 className="m-b-md m-t-md">Engagement</h3>
        <Button onClick={openEngagementIdModal} text="Set Engagement ID" />
        {organization.engagementId && (
          <Button
            className="m-l-2"
            onClick={unsetEngagementId}
            text="Delete Engagement ID"
            color="warning"
            isSubmitting={isDeletingEngagementId}
          />
        )}
        <h3 className="m-b-md m-t-md">{organization.isActive ? 'Danger zone' : 'Actions'}</h3>

        {organization.isActive ? (
          <Button color="warning" onClick={() => showDeactivateModal({ organization })}>
            Deactivate organization
          </Button>
        ) : (
          <Button color="primary" onClick={() => showDeactivateModal({ organization, reactivate: true })}>
            Reactivate organization
          </Button>
        )}
        <Button
          color="warning"
          className="m-l-2"
          text="Delete organization"
          onClick={() => showDeleteModal({ name: organization.name, organizationId: organization.id })}
        />
      </LoaderSwitch>
    </PageContent>
  );
}

export default OrganizationDetails;
