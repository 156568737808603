import { defineAction } from 'redux-define';

export const DIALOGUE_FILTERS = {
  HIDE_FALLBACK: (result) => !result.predictorReplyIsFallback,
  HIDE_MATCH: (result) => !result.predictorMatchesId,
};

const EVALUATION = defineAction('EVALUATION', ['ENABLE_FILTER', 'DISABLE_FILTER']);

const initialState = {
  filters: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EVALUATION.ENABLE_FILTER:
      return {
        ...state,
        filters: state.filters.concat(DIALOGUE_FILTERS[action.payload]),
      };
    case EVALUATION.DISABLE_FILTER:
      return {
        ...state,
        filters: state.filters.filter((fn) => fn !== DIALOGUE_FILTERS[action.payload]),
      };
    default:
      return state;
  }
};

export const toggleFallback = () => (dispatch, getState) => {
  const {
    evaluation: { filters },
  } = getState();
  if (filters.indexOf(DIALOGUE_FILTERS.HIDE_FALLBACK) === -1) {
    dispatch({
      type: EVALUATION.ENABLE_FILTER,
      payload: 'HIDE_FALLBACK',
    });
  } else {
    dispatch({
      type: EVALUATION.DISABLE_FILTER,
      payload: 'HIDE_FALLBACK',
    });
  }
};

export const toggleMatch = () => (dispatch, getState) => {
  const {
    evaluation: { filters },
  } = getState();
  if (filters.indexOf(DIALOGUE_FILTERS.HIDE_MATCH) === -1) {
    dispatch({
      type: EVALUATION.ENABLE_FILTER,
      payload: 'HIDE_MATCH',
    });
  } else {
    dispatch({
      type: EVALUATION.DISABLE_FILTER,
      payload: 'HIDE_MATCH',
    });
  }
};
