import PropTypes from 'prop-types';
import { useCallback } from 'react';

import styles from './DatePickers.scss';

const DatePickers = ({ fromDate, setFromDate, toDate, setToDate }) => {
  const onChangeFrom = useCallback((event) => setFromDate(event.target.value), [setFromDate]);
  const onChangeTo = useCallback((event) => setToDate(event.target.value), [setToDate]);

  return (
    <div className={styles.datePickersWrapper}>
      <label>
        From: <input type="date" value={fromDate} onChange={onChangeFrom} />
      </label>
      <label>
        To: <input type="date" value={toDate} onChange={onChangeTo} />
      </label>
    </div>
  );
};

DatePickers.propTypes = {
  fromDate: PropTypes.string.isRequired,
  setFromDate: PropTypes.func.isRequired,
  toDate: PropTypes.string.isRequired,
  setToDate: PropTypes.func.isRequired,
};

export default DatePickers;
