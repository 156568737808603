import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  hideTabNotification,
  selectTabActive,
  selectTabNotificationMessage,
  setTabActive,
} from 'frontend/state/dux/tabNotification';

export const useTabNotifications = () => {
  const dispatch = useDispatch();
  const tabActive = useSelector(selectTabActive);
  const tabNotificationMessage = useSelector(selectTabNotificationMessage);

  const onTabVisibilityChange = useCallback(() => {
    dispatch(setTabActive(document.visibilityState === 'visible'));
  }, [dispatch]);

  const onFocus = useCallback(() => {
    dispatch(setTabActive(true));
  }, [dispatch]);

  const onBlur = useCallback(() => {
    dispatch(setTabActive(false));
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onTabVisibilityChange);

    const body = document.getElementsByTagName('body')[0];
    body.onfocus = onFocus;
    body.onblur = onBlur;

    return () => {
      document.removeEventListener('visibilitychange', onTabVisibilityChange);

      body.onfocus = null;
      body.onblur = null;
    };
  }, [onBlur, onFocus, onTabVisibilityChange]);

  useEffect(() => {
    if (tabActive && tabNotificationMessage) {
      dispatch(hideTabNotification());
    }
  }, [dispatch, tabActive, tabNotificationMessage]);
};

export const useFullscreenLayout = () => {
  const { skillId } = useParams();

  return Boolean(skillId);
};
