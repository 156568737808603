import { isEmpty, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Button, Search } from 'frontend/components';
import { EntityItemsInput } from 'frontend/features/Entities/components';
import { EntityType, KindlyEntityType } from 'frontend/features/Entities/propTypes';
import { EntitySearch } from 'frontend/features/Entities/utils';
import { BuildIdObjectType } from 'frontend/propTypes';

import styles from './EntityDropdown.scss';

const CreateEntity = ({
  handleSubmit,
  entities,
  currentLanguage,
  creatingEntity,
  toggleCreatingEntity,
  setSearchResults,
  newEntityItems,
  setNewEntityItems,
  buildIdObject,
}) => {
  const { errors, values } = useFormState();
  const { change } = useForm();
  const hasErrors = !isEmpty(errors);

  const addItems = useCallback(
    (newItems) => change('items', uniq([...values.items, ...newItems])),
    [change, values.items],
  );

  return (
    <div className={styles.createEntity}>
      {creatingEntity ? (
        <EntityItemsInput
          className="m-r"
          currentLanguage={currentLanguage}
          addItems={addItems}
          onEscape={toggleCreatingEntity}
          newEntityItems={newEntityItems}
          setNewEntityItems={setNewEntityItems}
          buildIdObject={buildIdObject}
        />
      ) : (
        <Field
          component={Search}
          name="name"
          searchItems={entities}
          setSearchResults={setSearchResults}
          className="m-r-4"
          onSubmit={handleSubmit}
          wrapper={EntitySearch.wrapper}
          searchOptions={EntitySearch.options}
          placeholder="Find or create entity"
          aria-label="Find or create entity"
        />
      )}
      <Button onClick={handleSubmit} disabled={hasErrors} color="primary">
        Create
      </Button>
    </div>
  );
};

CreateEntity.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  creatingEntity: PropTypes.bool.isRequired,
  toggleCreatingEntity: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  newEntityItems: PropTypes.string.isRequired,
  setNewEntityItems: PropTypes.func.isRequired,
  buildIdObject: BuildIdObjectType.isRequired,
  entities: PropTypes.arrayOf(PropTypes.oneOfType([EntityType, KindlyEntityType])).isRequired,
};

export default CreateEntity;
