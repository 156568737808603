import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { commentCreatedCacheUpdate, commentDeletedCacheUpdate, commentUpdatedCacheUpdate } from '../../Comments/utils';

export default ({ botId }) => {
  const { cache } = useApolloClient();

  const onChatComment = useCallback(
    ({ comment, chat: chatId }) => {
      if (!botId) return;
      commentCreatedCacheUpdate({ comment, chatId, cache, botId });
    },
    [botId, cache],
  );

  const onChatCommentUpdate = useCallback(
    ({ comment, chat: chatId }) => {
      if (!botId) return;
      commentUpdatedCacheUpdate({ comment, chatId, cache, botId });
    },
    [botId, cache],
  );

  const onChatCommentDelete = useCallback(
    ({ comment, chat: chatId }) => {
      if (!botId) return;
      commentDeletedCacheUpdate({ comment, chatId, cache, botId });
    },
    [botId, cache],
  );

  return { onChatComment, onChatCommentUpdate, onChatCommentDelete };
};
