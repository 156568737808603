import cx from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useFormState } from 'react-final-form';

import { AnnotationWarning } from 'frontend/assets/icons';
import { Icon, InputErrorWrapper } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import Composer from 'frontend/features/Composer';
import { EntityDropdown } from 'frontend/features/Entities/components';
import { useEntityDropdown } from 'frontend/features/Entities/hooks';
import { useModal } from 'frontend/features/Modals';
import pluralize from 'frontend/utils/pluralize';

import styles from './NewSample.scss';
import WordCountModal from './WordCountModal';
import { useOnEnter, useOnPaste } from './hooks';
import { useSampleError } from '../../hooks';

const NAME = 'newSample';
const SAMPLE_RECOMM_WORD_LENGTH = 15;
const WORD_COUNT_MODAL_TITLE = 'Word count';
const WORD_COUNT_MODAL_TEXT = `Your sample contains more than ${SAMPLE_RECOMM_WORD_LENGTH} words. This might make the intention unclear. Reduce the number of words by removing filler words etc. or divide it into two separate samples.`;

const NewSample = ({ add, dialogueId, sampleValidator, currentLanguage, label }) => {
  const { submitting: readOnly, values } = useFormState();
  const { extra, allEntities } = useEntityDropdown({ currentLanguage });
  const { error, setError } = useSampleError({ name: NAME, sampleValidator });

  const onEnter = useOnEnter({ add, sampleValidator, setError, currentLanguage, dialogueId });
  const onPaste = useOnPaste({ add, currentLanguage, dialogueId });
  const onEscape = useCallback(({ composerRef }) => composerRef.current.blur(), []);

  const sampleText = values.newSample.editorState.getCurrentContent().getPlainText();
  const sampleWordCount = sampleText.split(' ').filter(Boolean).length;
  const isSampleOverRecommLength = sampleWordCount > SAMPLE_RECOMM_WORD_LENGTH;
  const [showWordCountModal] = useModal(WordCountModal);

  return (
    <>
      {label && (
        <div className={styles.labelWrapper}>
          <label htmlFor={NAME} className={styles.label}>
            {label}
          </label>

          <div className={styles.wordCountWrapper}>
            {isSampleOverRecommLength && (
              <Icon
                component={AnnotationWarning}
                className={styles.iconWarning}
                color="warning"
                onClick={() =>
                  showWordCountModal({
                    title: WORD_COUNT_MODAL_TITLE,
                    text: WORD_COUNT_MODAL_TEXT,
                  })
                }
              />
            )}
            <span
              className={styles.wordCount}
              style={{
                '--_word-counter-color': isSampleOverRecommLength ? 'var(--text-color)' : 'var(--text-color-light)',
              }}
            >
              {`${sampleWordCount} ${pluralize('word', sampleWordCount)}`}
            </span>
          </div>
        </div>
      )}

      <InputErrorWrapper hasError={!!error} displayError={error} errorMessageClassName={styles.errorMessage}>
        <Composer
          dropdownComponent={EntityDropdown}
          dropdownExtra={extra}
          name={`${NAME}.editorState`}
          onEnter={onEnter}
          onEscape={onEscape}
          onPaste={onPaste}
          potentialTagItems={allEntities}
          placeholder="Enter sample"
          className={cx(styles.newSample, { [styles.newSampleError]: error })}
          readOnly={readOnly}
          maxLength={500}
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      </InputErrorWrapper>
    </>
  );
};

NewSample.propTypes = {
  dialogueId: PropTypes.string,
  add: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  sampleValidator: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default memo(NewSample);
