import { get, isNil, pickBy } from 'lodash';

// Don't include topicId if parentId or dialogueModParentId is included
const filterTopicId = (object) =>
  pickBy(object, (_, key) => key !== 'topicId' || (isNil(object.parentId) && isNil(object.dialogueModParentId)));

export default (buildIdObject, dialogue) =>
  filterTopicId({
    ...buildIdObject,
    // Have to set parentId and topicId with get's (instead of pick) and with an
    // OR-guard instead of default arguments, or the variables will not be correct.
    topicId: get(dialogue, 'topicId') || undefined,
    parentTopicId: get(dialogue, 'parentTopicId') || undefined,
    parentId: get(dialogue, 'parentId') || undefined,
    dialogueModParentId: get(dialogue, 'dialogueModParentId') || undefined,
    regular: true,
  });
