import { useQuery } from '@apollo/client';

import { MonthlyReportDocument, type MonthlyReportType } from 'frontend/api/generated';
import { DEFAULT_TIMEZONE } from 'frontend/features/Analytics/constants';
import { useSageData } from 'frontend/features/Analytics/hooks';
import type { ReportData } from 'frontend/features/Analytics/utils/sageData';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

export function useReport(
  organizationId: string,
  month: string | undefined,
  tz?: string,
  skip = false,
): {
  loading: boolean;
  meta: Partial<MonthlyReportType> | undefined;
  data: ReportData | undefined;
} {
  const sageOrgScope = sageScope(SAGE_RESOURCE.ORG, organizationId);
  const { data, loading: metaLoading } = useQuery(MonthlyReportDocument, {
    variables: { organizationId, month: month!, tz: tz ?? DEFAULT_TIMEZONE },
    skip: skip || !month,
  });
  const { data: sageData, loading: sageDataLoading } = useSageData(
    sageOrgScope,
    '/monthly',
    {
      month,
      tz: data?.organization?.monthlyReport?.tz,
    },
    {
      report: true,
      enable: !skip && Boolean(data),
    },
  );
  return {
    loading: metaLoading || sageDataLoading,
    meta: data?.organization?.monthlyReport ?? undefined,
    data: sageData,
  };
}
