import { gql } from '@apollo/client';

export const BuildForms = gql`
  fragment BuildForms on BuildFormType {
    id
    cancelDialogueId
    createdAt
    dialogue {
      id
    }
    fields {
      id
      createdAt
      slug
      required
      order
      affix
      inputType
      attributes {
        id
        step
        defaultValue
        options {
          id
          value
          label
        }
      }
      texts {
        id
        label
        helpText
        requiredText
        placeholderText
        affixValue
      }
      validators {
        id
        minLength
        maxLength
        maximum
        minimum
        pattern
        texts {
          id
          text
        }
      }
    }
    languageCode
    submitDialogueId
    texts {
      id
      title
      submitButtonText
      cancelButtonText
      cancelText
      errorText
      unansweredText
    }
    rule {
      id
    }
  }
`;
