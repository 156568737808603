import React from 'react';

import { ExitCircle, Language } from 'frontend/assets/icons';

import styles from './LanguageDetectionPreview.scss';
import buildQuickLinks from './QuickLink';

type LanguageDetectionPreviewProps = {
  content: string;
};

const LanguageDetectionPreview = ({ content }: LanguageDetectionPreviewProps) => (
  <div className={styles.notificationPreviewContainer}>
    <div>
      <Language className={styles.languageIcon} />
    </div>
    <p className={styles.notificationContent}>{buildQuickLinks(content)}</p>
    <ExitCircle className={styles.exitCircleIcon} />
  </div>
);

export default LanguageDetectionPreview;
