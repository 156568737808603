import { gql } from '@apollo/client';

export default gql`
  fragment UserListEntry on UserType {
    id
    email
    dateJoined
    lastLogin
    firstName
    lastName
    fullName
    groups {
      id
      name
      label
    }
    organizationMemberships {
      id
      organization {
        id
        name
      }
    }
  }
`;
