import React from 'react';
import type { Range } from 'react-date-range';
import { Field, useField } from 'react-final-form';

import { DatePicker } from 'frontend/components';
import { FILTER_TYPES } from 'frontend/features/Inbox/constants';

interface DateFilterComponentProps {
  input: {
    name: string;
    onChange: (ranges: Range[]) => void;
  };
}

const DateFilterComponent = ({ input: { name, onChange } }: DateFilterComponentProps) => {
  const {
    input: { value },
  } = useField(FILTER_TYPES.datePicker);

  return (
    <DatePicker
      initialEndDate={value?.[0]?.endDate}
      initialStartDate={value?.[0]?.startDate}
      showSelect
      name={name}
      onChange={onChange}
    />
  );
};

const DateFilter = () => <Field component={DateFilterComponent} name={FILTER_TYPES.datePicker} />;

export default DateFilter;
