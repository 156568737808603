import React from 'react';

import { KindlyBlob } from 'frontend/assets/icons';

import styles from './ChatbubblePreview.scss';

interface ChatbubblePreviewProps {
  chatBubbleTextColor?: string;
  chatbubbleOutline?: string;
  chatbubbleColor: string;
  chatbubbleText?: string;
  chatbubbleTextEnabled?: boolean;
}

const ChatbubblePreview = ({
  chatBubbleTextColor,
  chatbubbleOutline,
  chatbubbleColor,
  chatbubbleText,
  chatbubbleTextEnabled,
}: ChatbubblePreviewProps) => (
  <div className={styles.chatPreviewButton}>
    <div
      style={{
        color: chatBubbleTextColor,
        backgroundColor: chatbubbleColor,
        padding: chatbubbleText && chatbubbleTextEnabled ? '6px 6px 6px 16px' : 'inherit',
        ...(chatbubbleOutline && { border: `2px solid ${chatbubbleOutline}` }),
      }}
    >
      {chatbubbleTextEnabled && chatbubbleText}
      <KindlyBlob width={36} height={36} />
    </div>
  </div>
);

export default ChatbubblePreview;
