import { usePusher } from '@harelpls/use-pusher';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { onPusherConnectSuccess } from 'frontend/state/dux/pusher';

const OnConnect = () => {
  const dispatch = useDispatch();
  const { client } = usePusher();

  useEffect(() => {
    if (!client) return;

    client.connection.bind('connected', () => {
      dispatch(onPusherConnectSuccess(client.connection.socket_id));
    });
  }, [client, dispatch]);

  return null;
};

export default OnConnect;
