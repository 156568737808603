import alien from './alien.svg?url';
import apple from './apple.svg?url';
import avocado from './avocado.svg?url';
import banana from './banana.svg?url';
import bathTub from './bathTub.svg?url';
import bed from './bed.svg?url';
import berries from './berries.svg?url';
import boat from './boat.svg?url';
import bowlingPin from './bowlingPin.svg?url';
import bread from './bread.svg?url';
import bullseye from './bullseye.svg?url';
import cloud from './cloud.svg?url';
import coffeeBean from './coffeeBean.svg?url';
import coins from './coins.svg?url';
import compass from './compass.svg?url';
import crane from './crane.svg?url';
import crown from './crown.svg?url';
import cupcake from './cupcake.svg?url';
import curlingStone from './curlingStone.svg?url';
import dumbbell from './dumbbell.svg?url';
import egg from './egg.svg?url';
import eggplant from './eggplant.svg?url';
import eye from './eye.svg?url';
import fence from './fence.svg?url';
import fish from './fish.svg?url';
import flame from './flame.svg?url';
import football from './football.svg?url';
import garlic from './garlic.svg?url';
import grapes from './grapes.svg?url';
import hand from './hand.svg?url';
import handshake from './handshake.svg?url';
import heart from './heart.svg?url';
import helmet from './helmet.svg?url';
import horse from './horse.svg?url';
import house from './house.svg?url';
import iceCream from './iceCream.svg?url';
import iceSkates from './iceSkates.svg?url';
import iron from './iron.svg?url';
import key from './key.svg?url';
import kiwi from './kiwi.svg?url';
import leaf from './leaf.svg?url';
import lemon from './lemon.svg?url';
import lightning from './lightning.svg?url';
import magnet from './magnet.svg?url';
import microphone from './microphone.svg?url';
import milkshake from './milkshake.svg?url';
import moon from './moon.svg?url';
import mortar from './mortar.svg?url';
import mushroom from './mushroom.svg?url';
import note from './note.svg?url';
import nut from './nut.svg?url';
import pants from './pants.svg?url';
import paw from './paw.svg?url';
import pencil from './pencil.svg?url';
import photoCamera from './photoCamera.svg?url';
import pineapple from './pineapple.svg?url';
import pingpong from './pingpong.svg?url';
import pizza from './pizza.svg?url';
import popcorn from './popcorn.svg?url';
import sausage from './sausage.svg?url';
import shell from './shell.svg?url';
import shield from './shield.svg?url';
import shorts from './shorts.svg?url';
import sock from './sock.svg?url';
import soup from './soup.svg?url';
import soySauce from './soySauce.svg?url';
import spaceship from './spaceship.svg?url';
import speaker from './speaker.svg?url';
import spectrum from './spectrum.svg?url';
import speedometer from './speedometer.svg?url';
import spider from './spider.svg?url';
import stairs from './stairs.svg?url';
import star from './star.svg?url';
import steeringWheel from './steeringWheel.svg?url';
import surfboard from './surfboard.svg?url';
import tennisBall from './tennisBall.svg?url';
import tooth from './tooth.svg?url';
import tree from './tree.svg?url';
import trophy from './trophy.svg?url';
import truck from './truck.svg?url';
import tshirt from './tshirt.svg?url';
import turtle from './turtle.svg?url';
import umbrella from './umbrella.svg?url';
import videoCamera from './videoCamera.svg?url';
import volleyball from './volleyball.svg?url';
import wave from './wave.svg?url';

export default {
  alien,
  apple,
  banana,
  berries,
  boat,
  bread,
  cloud,
  coffeeBean,
  coins,
  compass,
  crown,
  cupcake,
  egg,
  eye,
  fish,
  flame,
  football,
  grapes,
  heart,
  house,
  iceCream,
  iron,
  key,
  leaf,
  lightning,
  magnet,
  microphone,
  moon,
  mushroom,
  note,
  nut,
  paw,
  pencil,
  photoCamera,
  pingpong,
  pizza,
  sausage,
  shield,
  sock,
  soup,
  spaceship,
  speaker,
  spider,
  star,
  steeringWheel,
  tennisBall,
  tree,
  umbrella,
  videoCamera,
  avocado,
  bathTub,
  bed,
  bowlingPin,
  bullseye,
  crane,
  curlingStone,
  dumbbell,
  eggplant,
  fence,
  garlic,
  hand,
  handshake,
  helmet,
  horse,
  iceSkates,
  kiwi,
  lemon,
  milkshake,
  mortar,
  pants,
  pineapple,
  popcorn,
  shell,
  shorts,
  soySauce,
  spectrum,
  speedometer,
  stairs,
  surfboard,
  tooth,
  trophy,
  truck,
  tshirt,
  turtle,
  volleyball,
  wave,
};
