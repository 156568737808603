import { useApolloClient, useQuery } from '@apollo/client';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Loader } from 'frontend/components';
import { NavigationButton } from 'frontend/features/Inbox/components';
import sharedStyles from 'frontend/features/Inbox/inbox-styles.scss';
import { TICKET_LOG } from 'frontend/features/Inbox/queries';
import NotFound from 'frontend/features/NotFound';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useBooleanState } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './Conversation.scss';
import { ConversationLog } from './components';
import {
  useAddedAttachments,
  useButtonClickUpdate,
  useChatMessages,
  useChatUpdate,
  useCommentUpdates,
  useFeedback,
  useOnTicketLog,
  useWebhookRequests,
} from './hooks';
import { ATTACHMENTS, CHAT_AND_BOT } from './queries';
import Comments from '../Comments';
import MetaData from '../MetaData';

const fetchPolicy = 'network-only';
const nextFetchPolicy = 'cache-first';

export default function Conversation() {
  const client = useApolloClient();
  const bottomRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { botId: botIdFromParams, chatId } = useParams();
  const [userIsTyping, setUserIsTyping] = useState(false);

  const { data, loading } = useQuery(CHAT_AND_BOT, {
    variables: { chatId },
    fetchPolicy,
    nextFetchPolicy,
  });

  const botId: string | undefined = botIdFromParams || data?.chatAndBot?.bot?.id;
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });
  const canViewDialogues = hasBotPerms('view_templates');

  const feedback = useFeedback({ botId, chatId });
  const { data: messages, loading: messagesLoading, loadMore } = useChatMessages(botId);
  const { data: ticketLogData, loading: ticketLogLoading } = useQuery(TICKET_LOG, {
    variables: { botId, chatId },
    fetchPolicy,
    skip: !botId,
  });
  const { data: attachmentsData, loading: attachmentsLoading } = useQuery(ATTACHMENTS, {
    variables: { botId, chatId },
    skip: !botId,
  });
  const { webhooks, loading: webhookDataLoading } = useWebhookRequests(botId!, messages);
  const ticketLog = useMemo(() => ticketLogData?.ticketLog ?? [], [ticketLogData]);

  const chat = data?.chatAndBot?.chat;
  const commentsCount = chat?.commentsCount ?? 0;
  const subject = chat?.subject;

  const { onChatComment, onChatCommentUpdate, onChatCommentDelete } = useCommentUpdates({ botId });
  const onChatUpdate = useChatUpdate({ cache: client.cache, messages });
  const onButtonClick = useButtonClickUpdate(client);
  const onTypingUpdate = useCallback(({ typing }) => setUserIsTyping(typing), []);
  const onAddedAttachments = useAddedAttachments(client);
  const onTicketLog = useOnTicketLog({ cache: client.cache });

  const [showNotes, setShowNotes, unsetShowNotes] = useBooleanState();

  const onClickBack = useCallback(() => navigate(`${location.pathname}?screen=chat-list`), [location, navigate]);
  const onClickActions = useCallback(() => navigate(`${location.pathname}?screen=meta`), [location, navigate]);
  const onClickBackToChat = useCallback(() => navigate(`${location.pathname}?screen=`), [location, navigate]);

  const pusherChannel = getPusherChannel({ chatId });
  usePusherEvent(pusherChannel, 'update', onChatUpdate);
  usePusherEvent(pusherChannel, 'button-click', onButtonClick);
  usePusherEvent(pusherChannel, 'typing', onTypingUpdate);
  usePusherEvent(pusherChannel, 'chat-comment-added', onChatComment);
  usePusherEvent(pusherChannel, 'chat-comment-updated', onChatCommentUpdate);
  usePusherEvent(pusherChannel, 'chat-comment-deleted', onChatCommentDelete);
  usePusherEvent(pusherChannel, 'added-attachments', onAddedAttachments);
  usePusherEvent(pusherChannel, 'ticket-log', onTicketLog);

  if (loading || ticketLogLoading || messagesLoading || attachmentsLoading || webhookDataLoading || !botId) {
    return <Loader size="large" />;
  }

  if (isEmpty(data) && !loading) {
    return <NotFound />;
  }

  return (
    <>
      <div className={styles.conversationContainer}>
        <div className={styles.conversationHeader}>
          <div className={styles.subjectContainer} title={subject}>
            <div className={styles.subject}>{subject}</div>
          </div>
          <div className={styles.navigationButtons}>
            <NavigationButton onClick={onClickBack} text="Back" mobileOnly />
            <NavigationButton className="m-r-2" onClick={unsetShowNotes} active={!showNotes} text="Messages" />
            <NavigationButton
              onClick={setShowNotes}
              active={showNotes}
              text={`Notes ${commentsCount > 0 ? `(${commentsCount})` : ''}`}
            />
          </div>
          <NavigationButton onClick={onClickActions} text="Info" mobileOnly />
        </div>
        {!showNotes && (
          <ConversationLog
            attachments={attachmentsData?.attachments}
            botId={botId}
            bottomRef={bottomRef}
            conversationData={data}
            feedback={feedback}
            loadMore={loadMore}
            loading={loading || ticketLogLoading || loadingPermissions}
            messages={messages}
            ticketLog={ticketLog}
            userIsTyping={userIsTyping}
            webhooks={webhooks}
            canViewDialogues={canViewDialogues}
          />
        )}
        {showNotes && <Comments botId={botId} />}
      </div>
      <div className={styles.sidebarContainer}>
        <div className={cx(sharedStyles.navigationButtons, sharedStyles.navigationButtonsMobileOnly)}>
          <NavigationButton onClick={onClickBackToChat} text="Back" />
        </div>
        <MetaData conversationData={data} bottomRef={bottomRef} feedback={feedback} />
      </div>
    </>
  );
}
