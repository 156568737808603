import { EditorState, Modifier } from 'draft-js';

import { MUTABILITY_TYPES } from '../constants';
import { createSelectionState } from '../utils';

const { IMMUTABLE } = MUTABILITY_TYPES;

const createDraftEntity = (state, type, mutabilityType, data) => {
  const content = state.getCurrentContent();
  const contentWithDraftEntity = content.createEntity(type, mutabilityType, data);
  const key = contentWithDraftEntity.getLastCreatedEntityKey();

  const stateWithDraftEntity = EditorState.push(state, contentWithDraftEntity, 'insert-entity'); // Unsure of correct change type

  return { key, state: stateWithDraftEntity };
};

export default (state, { type, data, length, selection, keepSelection }) => {
  const anchorKey = selection.getAnchorKey();

  if (anchorKey !== selection.getFocusKey()) {
    console.error('Composer: Tried to insert draft entity on selection spanning several lines');
    return state;
  }

  const start = selection.getStartOffset();
  const { key, state: stateWithItem } = createDraftEntity(state, type, IMMUTABLE, data);
  const contentWithItem = stateWithItem.getCurrentContent();

  const draftEntitySelection = createSelectionState({ anchorKey, start, end: start + length });
  const contentWithDraftEntityApplied = Modifier.applyEntity(contentWithItem, draftEntitySelection, key);
  const stateWithDraftEntityApplied = EditorState.push(stateWithItem, contentWithDraftEntityApplied, 'apply-entity');

  if (keepSelection) return EditorState.forceSelection(stateWithDraftEntityApplied, selection);
  return stateWithDraftEntityApplied;
};
