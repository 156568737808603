import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { AnalyticsContent, Grid, Heading } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import { useBooleanState } from 'frontend/hooks';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

import Filters, { FilterInfo } from '../../../Filters';
import ConversionLineChart from '../ConversionLineChart';

const ConversionDetail = ({ title, subtitle, event }) => {
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const { botId } = useParams();
  const filters = useFilters();

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} />
        <div>
          <Heading className="m-t-0" title={title} subtitle={`${subtitle} per ${filters.granularity}.`} />
          <Grid columns={1}>
            <ConversionLineChart scope={sageScope(SAGE_RESOURCE.BOT, botId)} filters={filters} event={event} />
          </Grid>
        </div>
      </AnalyticsContent>
    </>
  );
};

ConversionDetail.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  event: PropTypes.oneOf(Object.values(ConversionLineChart.ACTION_TYPES)).isRequired,
};

export default ConversionDetail;
