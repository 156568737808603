import { differenceBy, differenceWith, identity, isEqual, pick } from 'lodash';

const compareFields = (relevantFields) => (value1, value2) =>
  isEqual(pick(value1, relevantFields), pick(value2, relevantFields));

/** This algorithm is buggy, please use `getArrayUpdateV2`.
 * @deprecated
 */
export default function getArrayFieldUpdates<T extends Record<string, unknown>>(
  initialValues: T[],
  values: T[],
  relevantFields: string[],
  handler: CallableFunction = identity,
) {
  const compare = compareFields(relevantFields);

  const created = handler(differenceBy(values, initialValues, 'id'));
  const deleted = handler(differenceBy(initialValues, values, 'id'));
  const updated = handler(
    differenceWith(differenceBy(values, created, 'id'), differenceBy(initialValues, deleted, 'id'), compare),
  );

  return { created, deleted, updated };
}
