import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

export default PropTypes.exact({
  id: IDType.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.objectOf(PropTypes.string).isRequired,
  __typename: PropTypes.oneOf(['DialogueType']).isRequired,
});
