import { useMemo } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { ExternalLink } from 'frontend/assets/icons';
import { Icon, List } from 'frontend/components';
import { useHelpCenterUrl } from 'frontend/features/HelpCenter/useHelpCenterUrl';

import styles from './styles.scss';

type InfoMenuData = {
  title: string;
  key: string;
  onClick?: () => NavigateFunction | void;
  href?: string;
};

interface Props {
  close?: () => void;
}

export default function InfoMenu({ close = () => null }: Props): React.JSX.Element {
  const navigate = useNavigate();
  const helpCenterUrl = useHelpCenterUrl();

  const data = useMemo<InfoMenuData[]>(
    () => [
      {
        title: 'Feedback',
        key: 'navbar-usermenu-feedback-key',
        onClick: () => navigate('/feedback'),
      },
      {
        title: 'Help Center',
        key: 'navbar-infomenu-help-center-key',
        href: helpCenterUrl,
      },
      {
        title: 'Platform status',
        key: 'navbar-infomenu-platform-status-key',
        href: 'https://status.kindly.ai/',
      },
    ],
    [helpCenterUrl, navigate],
  );

  const renderFooter = () => <div className={styles.menuFooter}>{globalThis.env.APP_VERSION}</div>;

  return (
    <List
      width="165px"
      dataSource={data}
      className={styles.listWrapper}
      renderItem={(item: InfoMenuData) => (
        <List.Item
          className={styles.infoMenuItem}
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }

            close();
          }}
          key={item.key}
          tabIndex={item.href ? -1 : 0}
        >
          {item.href ? (
            <a rel="noopener noreferrer" target="_blank" href={item.href}>
              {item.title}
              <Icon component={ExternalLink} className={styles.iconLink} />
            </a>
          ) : (
            <span>{item.title}</span>
          )}
        </List.Item>
      )}
      renderFooter={renderFooter}
    />
  );
}
