import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { EnableDialogueDocument } from 'frontend/api/generated';

import useCanDisableDialogue from './useCanDisableDialogue';
import { dialogueCanBeEnabled } from '../queries';
import { getDialogueTypeName } from '../utils';

function useEnableDialogue({
  dialogue,
  disabled,
  selectedLanguage,
  buildIdObject,
  languageNameMapVariants,
  client,
  toast,
}) {
  const [enable] = useMutation(EnableDialogueDocument);
  const checkCanDisable = useCanDisableDialogue();

  const enableFunction = useCallback(async () => {
    const { id, dialogueType } = dialogue;
    const dialogueTypeName = getDialogueTypeName(dialogueType);
    const variables = { ...buildIdObject, id, languageCode: selectedLanguage };
    const languageName = languageNameMapVariants[selectedLanguage];

    if (disabled) {
      const { data } = await client.query({ query: dialogueCanBeEnabled, variables, fetchPolicy: 'network-only' });
      const { canBeEnabled } = data.dialogue;
      if (!canBeEnabled) {
        toast.error(`Cannot enable ${dialogueTypeName}. Does it have at least one output in ${languageName}?`);
        return;
      }
    } else {
      const canBeDisabled = await checkCanDisable({ client, variables });
      if (!canBeDisabled) {
        toast.error(`Cannot be disabled. Is it the only ${dialogueTypeName} enabled in ${languageName}?`);
        return;
      }
    }

    const {
      data: {
        // @ts-expect-error Fix generated mutation type
        enableDialogue: { hasButtonsLinked },
      },
    } = await enable({
      variables: { ...buildIdObject, id, enabled: Boolean(disabled), languageCode: selectedLanguage },
    });
    if (!disabled && hasButtonsLinked) {
      toast.success('Be aware that the disabled dialogue had buttons linked to it');
    }
  }, [
    buildIdObject,
    checkCanDisable,
    client,
    selectedLanguage,
    dialogue,
    disabled,
    enable,
    languageNameMapVariants,
    toast,
  ]);

  return enableFunction;
}

export default useEnableDialogue;
