import { useQuery } from '@apollo/client';
import type { FieldInputProps } from 'react-final-form';

import { GET_AVAILABLE_TIMEZONES } from 'frontend/api/queries';
import { SelectWithSearch } from 'frontend/components';

interface Props {
  input: FieldInputProps<string, HTMLElement>;
  className?: string;
}

/** Timezone picker that works inside React Final Form. */
const OrganizationTimezonePicker = ({ input, className }: Props) => {
  const { data: timezones, loading } = useQuery(GET_AVAILABLE_TIMEZONES);

  return (
    <div className={className}>
      {!loading && (
        <SelectWithSearch
          input={input}
          meta={{}}
          options={timezones?.availableTimezones.map((tzItem) => ({ name: tzItem, value: tzItem })) || []}
        />
      )}
    </div>
  );
};

export default OrganizationTimezonePicker;
