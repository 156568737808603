import { Handover } from 'frontend/assets/icons';
import { Button, Dropdown } from 'frontend/components';
import type { Language } from 'frontend/types/language';

import styles from './LanguageSelector.scss';
import SelectorOverlay from './SelectorOverlay';
import sharedStyles from './language-selector-styles.scss';

interface Props {
  startHandover: (...args: unknown[]) => void;
  languages: Language[];
}

function LanguageSelector({ startHandover, languages }: Props) {
  return (
    <Dropdown
      wrapperClassName={styles.dropdownWrapper}
      overlayClassName={styles.languageDropdown}
      /* eslint-disable-next-line react/no-unstable-nested-components */
      overlay={({ close }) => <SelectorOverlay languages={languages} startHandover={startHandover} close={close} />}
      ignoreOutsideClick
      position="top"
      element="div"
      overlayMaxWidth="parent"
    >
      <Button
        className={sharedStyles.cancelButton}
        text="Start handover"
        color="primary"
        width="full"
        data-cy="button-start-handover"
        icon={Handover}
      />
    </Dropdown>
  );
}
export default LanguageSelector;
