import { useMemo } from 'react';

import { Table } from 'frontend/components';
import { EmptyState } from 'frontend/components/Stats';
import type { Column, Row } from 'frontend/components/Table/Table';
import { StatsPanel } from 'frontend/features/Analytics/components';
import { useSageData } from 'frontend/features/Analytics/hooks';
import type { ChatbubblePagesData } from 'frontend/features/Analytics/utils/sageData';
import CSVDownloadButton from 'frontend/features/Analytics/views/Sessions/components/CSVDownloadButton/CSVDownloadButton';
import { formatNumber } from 'frontend/utils';
import type { QueryParam } from 'frontend/utils/stringifyQueryParamsWithBrackets';

function mapDataToTable(data: ChatbubblePagesData | null): {
  isEmpty: boolean;
  tableData: Row[];
  tableColumns: Column[];
} {
  if (!data || data.length === 0) {
    return {
      isEmpty: true,
      tableData: [],
      tableColumns: [],
    };
  }

  const rows = data.map((item) => ({
    key: `${item.web_host}${item.web_path}`,
    page: `${item.web_host}${item.web_path}`,
    sessions: formatNumber(item.sessions),
    messages: formatNumber(item.messages),
  }));
  const columns = [
    {
      key: 'page',
      title: 'Page',
    },
    {
      key: 'sessions',
      title: 'Sessions',
    },
    {
      key: 'messages',
      title: 'Messages',
    },
  ];
  return {
    isEmpty: false,
    tableData: rows,
    tableColumns: columns,
  };
}

function sitePagesToCSV(data: ChatbubblePagesData) {
  return data.map(({ web_host: host, web_path: path, sessions, messages }) => ({
    page: host + path,
    sessions,
    messages,
  }));
}

type Props = {
  scope: string;
  filters: Record<string, QueryParam>;
  to?: string;
  priority?: number;
  isPreview?: boolean;
};

export default function ChatbubblePages({ filters, isPreview, scope, priority, to }: Props) {
  const { loading, error, data } = useSageData(scope, '/chatbubble/pages', filters, { priority });

  const { isEmpty, tableData, tableColumns } = useMemo(() => mapDataToTable(data), [data]);

  const title = 'Chat bubble: Site pages';
  const subtitle = 'Popular site locations for interacting with the bot';

  return (
    <StatsPanel
      loading={loading}
      error={error}
      to={to}
      noPadding={!loading && !isEmpty && !error}
      title={title}
      subtitle={subtitle}
    >
      {data && !isEmpty ? (
        <>
          {!isPreview && <CSVDownloadButton filename="chatbubble_site_pages.csv" data={sitePagesToCSV(data)} />}
          <Table data={tableData} columns={tableColumns as Column[]} nested />
        </>
      ) : (
        <EmptyState />
      )}
    </StatsPanel>
  );
}
