import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import { fromPairs, toPairs } from 'lodash';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'frontend/components';
import Input from 'frontend/components/Input/Input';
import { chain, email as emailValidator, required } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';
import { PublicLayout } from 'frontend/layouts/';
import { snakeToCamel } from 'frontend/utils';

import MailIcon from './MailIcon';
import styles from './ResetPassword.scss';
import loginStyles from '../Login/Login.scss';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const toast = useToast();

  const onSubmit = useCallback(
    async ({ email }) => {
      let errors;
      try {
        const response = await fetch(`${window.env.API_URL}/api/v2/accounts/reset_password/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, next: search }),
        });
        const payload = await response.json();
        if (!response.ok) {
          errors = fromPairs(toPairs(payload.errors).map(([key, val]) => [snakeToCamel(key), val.join(' \n')]));
        } else {
          toast.success('An email is on the way');
        }
      } catch (e) {
        errors = { [FORM_ERROR]: 'API or network error' };
        toast.error('Could not send the password reset email right now, try again later...');
      }
      return errors;
    },
    [search, toast],
  );

  return (
    <PublicLayout hideContact>
      <div className={cx(styles.forgotPassword, loginStyles.login)}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values, validating, submitting, submitFailed, submitSucceeded, valid }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              {!submitFailed && submitSucceeded && valid ? (
                <div className={styles.mailWrapper}>
                  <MailIcon />
                  <div>
                    <h1 className={styles.header}>You got an email!</h1>
                    <p className={styles.paragraph}>
                      An email was sent to <strong>{values.email}</strong>. Press the link in the email to set your new
                      password.
                    </p>
                    <Button onClick={handleSubmit} type="submit" color="primary">
                      Resend email
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <h1 className={styles.header}>Reset your password</h1>
                  <p className={styles.paragraph}>
                    Enter your email and we will send you an email with instructions on how to reset your password.
                  </p>
                  <Field
                    component={Input}
                    label="E-post"
                    name="email"
                    inputType="email"
                    placeholder="Email"
                    validate={chain([emailValidator, required])}
                    autoFocus
                  />
                  <div className="f-sb m-t-lg">
                    <Button className={styles.backButton} onClick={() => navigate('/')} flat text="< Back" />
                    <Button
                      onClick={handleSubmit}
                      className={styles.submitButton}
                      type="submit"
                      color="primary"
                      disabled={submitting || validating}
                      text="Send"
                    />
                  </div>
                </>
              )}
            </form>
          )}
        />
      </div>
    </PublicLayout>
  );
};

export default ResetPassword;
