import { gql } from '@apollo/client';

import { CHANGELOG_ENTRY_FRAGMENT } from 'frontend/features/Admin/views/Changelog/graphql';

export const SYNC_CHANGELOG = gql`
  mutation SyncChangelog {
    syncChangelog {
      id
      changes
      entries {
        ...ChangelogEntry
      }
    }
  }
  ${CHANGELOG_ENTRY_FRAGMENT}
`;
