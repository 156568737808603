import cx from 'classnames';
import { range } from 'lodash';
import { useCallback } from 'react';

import { ChevronLeft } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import NumberButton from './NumberButton';
import styles from './Pagination.scss';

interface PaginationProps {
  maxInView?: number;
  currentPage: number;
  pages: number;
  setPage(page: number): void;
}

const Pagination = ({ pages, maxInView = 10, currentPage, setPage }: PaginationProps) => {
  const next = useCallback(() => setPage(currentPage + 1), [currentPage, setPage]);
  const previous = useCallback(() => setPage(currentPage - 1), [currentPage, setPage]);
  const first = useCallback(() => setPage(1), [setPage]);
  const last = useCallback(() => setPage(pages), [pages, setPage]);

  if (pages < 2) return null;

  const halfWay = Math.floor(maxInView / 2);
  const start = Math.max(1, Math.min(currentPage - halfWay, pages - maxInView + 1));
  const end = Math.min(pages + 1, Math.max(start + maxInView, currentPage + halfWay));

  const firstBtnStyles = cx(styles.arrow, styles.first, { [styles.disabled]: currentPage === 1 });
  const prevBtnStyles = cx(styles.arrow, styles.prev, { [styles.disabled]: currentPage === 1 });
  const nextBtnStyles = cx(styles.arrow, styles.next, { [styles.disabled]: currentPage === pages });
  const lastBtnStyles = cx(styles.arrow, styles.last, { [styles.disabled]: currentPage === pages });

  return (
    <div className={styles.pagination}>
      {pages > maxInView && (
        <button className={firstBtnStyles} onClick={first} type="button">
          <Icon component={ChevronLeft} color="text" />
          <Icon component={ChevronLeft} color="text" />
        </button>
      )}
      <button type="button" onClick={previous} className={prevBtnStyles}>
        <Icon component={ChevronLeft} color="text" />
        <span>Prev</span>
      </button>
      <div className={styles.pagesContainer}>
        {range(start, end).map((number) => (
          <NumberButton
            key={`pagination-number-${number}`}
            number={number}
            currentPage={currentPage}
            setPage={setPage}
          />
        ))}
      </div>
      <button type="button" onClick={next} className={nextBtnStyles}>
        <span>Next</span>
        <Icon component={ChevronLeft} color="text" />
      </button>
      {pages > maxInView && (
        <button className={lastBtnStyles} onClick={last} type="button">
          <Icon component={ChevronLeft} color="text" />
          <Icon component={ChevronLeft} color="text" />
        </button>
      )}
    </div>
  );
};

export default Pagination;
