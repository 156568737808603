import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { DeleteModal } from 'frontend/features/Modals';

const UNPUBLISH_SKILL_MUTATION = gql`
  mutation UnpublishSkill($skillId: ID!) {
    unpublishSkill(skillId: $skillId) {
      id
      landingPageActive
    }
  }
`;

const UnpublishSkillModal = ({ hide, args: { skillId } }) => {
  const [mutate] = useMutation(UNPUBLISH_SKILL_MUTATION);
  const onSubmit = useCallback(async () => {
    await mutate({ variables: { skillId } });
  }, [skillId, mutate]);

  return (
    <DeleteModal onSubmit={onSubmit} hide={hide} name="skill" action="unpublish">
      <p>When unpublished, this skill will no longer be available for bots to subscribe to via custom link.</p>
      <p>Already subscribed bots will still be subscribed.</p>
    </DeleteModal>
  );
};

UnpublishSkillModal.propTypes = {
  args: PropTypes.shape({ skillId: PropTypes.string.isRequired }).isRequired,
  hide: PropTypes.func.isRequired,
};
export default UnpublishSkillModal;
