import { Modal } from 'frontend/features/Modals';

interface InfoModalProps {
  hide(): Promise<void>;
  args: {
    title: string;
    content: string;
  };
}

const InfoModal = ({ hide, args: { title, content } }: InfoModalProps) => (
  <Modal title={title} color="secondary" hide={hide} hideCancel>
    <Modal.Content className="m-t-2 m-b-0">{content}</Modal.Content>
  </Modal>
);

export default InfoModal;
