import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';

import { Accordion, LoaderSwitch, Panel } from 'frontend/components';
import { usePusherEvent } from 'frontend/features/Pusher';
import { BuildIdObjectType } from 'frontend/propTypes';

import styles from './ActivityGroup.scss';
import { ActivityType } from '../../propTypes';
import { ACTIVITY_GROUP, BUILD_ACTIVITY } from '../../queries';
import ActivityGroupTitle from '../ActivityGroupTitle';
import ActivityItem from '../ActivityItem';

const fetchPolicy = 'network-only';

const ActivityGroup = ({ buildIdObject, activityGroup, pusherChannel, groupIsInView, setHasNewer }) => {
  const client = useApolloClient();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const isOpen = useRef(); // Use a ref because the Pusher component doesn't listen to changes in onUpdate

  // Can't just use useLazyQuery because of https://github.com/apollographql/@apollo/client/issues/3355
  const fetchActivities = useCallback(
    async ({ skipLoading } = {}) => {
      if (!skipLoading) setLoading(true);

      const variables = { ...buildIdObject, groupId: activityGroup.id };
      const { data } = await client.query({ query: BUILD_ACTIVITY, variables, fetchPolicy });

      setLoading(false);
      setActivities(data.buildActivity ?? []);
    },
    [buildIdObject, client, activityGroup.id],
  );

  const onClick = useCallback(() => {
    isOpen.current = !isOpen.current;
    if (!isOpen.current) return;
    fetchActivities();
  }, [fetchActivities, isOpen]);

  const onUpdate = useCallback(
    ({ groupId }) => {
      // Update group view
      client.query({ query: ACTIVITY_GROUP, variables: { ...buildIdObject, groupId }, fetchPolicy });

      if (isOpen.current) fetchActivities({ skipLoading: true }); // Update detail view (activities)

      if (!groupIsInView(groupId.toString())) setHasNewer(); // An older group got updated; show fetch new button
    },
    [buildIdObject, client, fetchActivities, groupIsInView, setHasNewer],
  );

  usePusherEvent(pusherChannel, 'build-activity-group-updated', onUpdate);

  return (
    <Panel size="small" className={styles.activityPanel}>
      <Accordion
        title={<ActivityGroupTitle activity={activityGroup} />}
        className={styles.activityAccordion}
        onClick={onClick}
        fullLengthTitle
      >
        <div className={styles.itemList}>
          <LoaderSwitch loading={loading}>
            {activities.map((activity) => (
              <ActivityItem key={`activity-${activity.id}`} activity={activity} />
            ))}
          </LoaderSwitch>
        </div>
      </Accordion>
    </Panel>
  );
};

ActivityGroup.propTypes = {
  buildIdObject: BuildIdObjectType.isRequired,
  activityGroup: ActivityType.isRequired,
  pusherChannel: PropTypes.string.isRequired,
  groupIsInView: PropTypes.func.isRequired,
  setHasNewer: PropTypes.func.isRequired,
};

export default ActivityGroup;
