import PropTypes from 'prop-types';

const Annulus = ({ title, color = '#FFAC4A' }) => (
  <svg width="8px" height="8px" viewBox="0 0 7 7">
    <title>{title || 'Annulus icon'}</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Build-Library" transform="translate(-55.000000, -721.000000)" stroke={color} strokeWidth="1.5">
        <g id="Group-10" transform="translate(55.000000, 720.000000)">
          <g id="samples" transform="translate(0.000000, 1.000000)">
            <circle id="Oval" cx="3.5" cy="3.5" r="2.75" />{' '}
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Annulus.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

export default Annulus;
