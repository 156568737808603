import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { LabelsForBotDocument, type LabelsForBotQuery } from 'frontend/api/generated';

// FIXME: Make OrganizationLabelsQuery and LabelsForBotDocument have a consistent naming convention and format
export type BotLabel = NonNullable<LabelsForBotQuery>[`labelsForBot`][number];

export default function useBotLabels(): BotLabel[] {
  const { botId } = useParams();
  const { data, loading } = useQuery(LabelsForBotDocument, {
    variables: { botId: botId! },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !botId,
  });

  if (loading || !data?.labelsForBot) {
    return [];
  }

  return data.labelsForBot;
}
