import type { CellProps, RenderFunction } from './Table';

interface ContentsProps extends CellProps {
  render?: RenderFunction;
  component?: React.ComponentType<CellProps>;
  rowIdx?: string | number;
}

const Contents = ({ data, rowData, render, component: Component, columnName, columnData, rowIdx }: ContentsProps) => {
  if (render) {
    return render({ data, rowData, columnName, columnData, rowIdx });
  }
  if (Component) {
    return <Component data={data} rowData={rowData} columnName={columnName} columnData={columnData} rowIdx={rowIdx} />;
  }
  return data?.name ?? data;
};
export default Contents;
