import { gql } from '@apollo/client';

export default gql`
  query MessageClusterReports($botId: ID!) {
    messageClusterReports(botId: $botId) {
      id
      status
      languageCode
      messageType
      fromDate
      toDate
      redacted
    }
  }
`;
