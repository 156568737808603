import PropTypes from 'prop-types';

import { Modal } from 'frontend/features/Modals';

import styles from '../../styles.scss';

const LineChartModal = ({ hide, args: { content } }) => (
  <Modal className={styles.modal} hide={hide} hideCancel>
    <Modal.Content className="m-t-2 m-b-0 text-center">{content}</Modal.Content>
  </Modal>
);

LineChartModal.propTypes = {
  hide: PropTypes.func,
  args: PropTypes.exact({ content: PropTypes.shape({}).isRequired }).isRequired,
};

export default LineChartModal;
