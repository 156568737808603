import { capitalize } from 'lodash';

import { ChevronUp } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import Dropdown from 'frontend/components/Dropdown';
import { TicketIcon } from 'frontend/features/Inbox/components';
import type { Ticket } from 'frontend/features/Inbox/propTypes';

import StatusList from './StatusList';
import styles from './TicketStatus.scss';
import sharedStyles from '../../metadata-styles.scss';

function TicketStatus({ ticket }: { ticket: Ticket }) {
  return (
    <Dropdown
      overlayMaxWidth="parent"
      overlay={<StatusList ticket={ticket} />}
      wrapperClassName={sharedStyles.dropdownWrapper}
      triggerClassName={sharedStyles.dropdownTrigger}
      overlayClassName={sharedStyles.dropdownOverlay}
      position="top"
    >
      <div className={styles.iconAndTextWrapper}>
        <TicketIcon className={styles.icon} status={ticket.status} />
        <span className={sharedStyles.buttonText}>{capitalize(ticket.status)} ticket</span>
      </div>
      <Icon component={ChevronUp} className={sharedStyles.iconChevron} color="black" />
    </Dropdown>
  );
}
export default TicketStatus;
