import { useMutation } from '@apollo/client';
import cx from 'classnames';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import { UpdateButtonDocument } from 'frontend/api/generated';
import { CheckboxCheck, Close } from 'frontend/assets/icons';
import { Button, Input } from 'frontend/components';
import { useBotOrSkill, useToast } from 'frontend/hooks';

import styles from './BrokenLinksInput.scss';

const BrokenLinksInput = ({ initialValue, id }: { initialValue: string; id: string }) => {
  const [showActions, setShowActions] = useState(false);
  const { botId } = useBotOrSkill();
  const [updateButton] = useMutation(UpdateButtonDocument);

  const { error, success } = useToast();

  const onSubmit = async ({ value }) => {
    try {
      await updateButton({ variables: { botId: botId as string, buttonId: id, value } });
      success('Broken link repaired');
    } catch (err) {
      error('Failed to repair broken link.');
    }
    setShowActions(false);
  };

  return (
    <div className={styles.brokenLinksFormWrapper}>
      <Form initialValues={{ value: initialValue }} onSubmit={onSubmit}>
        {({ handleSubmit, form }) => {
          const isDirty = form.getState().dirty;
          return (
            <form className={styles.brokenLinksFromContainer} onSubmit={handleSubmit}>
              <Field
                onFocus={() => setShowActions(true)}
                onBlur={() => (!isDirty ? setShowActions(false) : undefined)}
                component={Input}
                className={cx(styles.brokenLinksInput, {
                  [styles.brokenLinksInputActive]: isDirty,
                })}
                type="input"
                name="value"
              />
              {showActions && (
                <>
                  <Button icon={CheckboxCheck} size="small" type="submit" />
                  <Button
                    icon={Close}
                    onClick={() => {
                      setShowActions(false);
                      form.reset();
                    }}
                  />
                </>
              )}
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default BrokenLinksInput;
