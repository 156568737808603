import applyDraftEntity from './applyDraftEntity';
import { HIGHLIGHT } from '../constants';

export default function insertHighlightTag(state, { currentSelection, data } = {}) {
  const selection = currentSelection || state.getSelection();
  const startOffset = selection.getStartOffset();
  const endOffset = Math.min(selection.getEndOffset(), state.getCurrentContent().getPlainText().length); // getEndOffset() bugs if selection starts from out the Composer, so we cap it

  const length = endOffset - startOffset;

  return applyDraftEntity(state, { type: HIGHLIGHT, data, length, selection, keepSelection: true });
}
