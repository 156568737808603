import { Field, useFormState } from 'react-final-form';

import { Sparkles } from 'frontend/assets/icons';
import { InfoBox, Input } from 'frontend/components';
import { required } from 'frontend/form/validators';

import styles from './Intent.scss';

export default function Intent() {
  const { submitting } = useFormState();

  return (
    <div>
      <Field
        validate={required}
        label={
          <div className={styles.userIntentTitle}>
            <Sparkles width={20} height={20} />
            &nbsp;User intent
          </div>
        }
        placeholder="Create an intent"
        name="intent"
        component={Input}
        multiline
        disabled={submitting}
      />
      <InfoBox className="m-t-3">
        <p>
          The User Intent is a short description of the reason for interacting with the chatbot. To write it, think
          about why someone would use the chatbot and what they hope to achieve. Write a brief statement that captures
          their goal, to help the chatbot provide relevant and helpful responses.
        </p>
        <div className="m-t-1">
          Examples:
          <ul className="m-t-0">
            <li>The user wants to return their item</li>
            <li>The user received the wrong order</li>
          </ul>
        </div>
      </InfoBox>
    </div>
  );
}
