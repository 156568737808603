import { URL_SCHEMES } from 'frontend/constants';

const { HTTP, HTTPS, NONE } = URL_SCHEMES;

const HTTP_REGEX = new RegExp(`^${HTTP}`, 'i');
const HTTPS_REGEX = new RegExp(`^${HTTPS}`, 'i');

const isHttp = (value) => Boolean((value || '').trim().match(HTTP_REGEX));
const isHttps = (value) => Boolean((value || '').trim().match(HTTPS_REGEX));

export const getScheme = (value) => {
  if (isHttp(value)) return HTTP;
  if (isHttps(value)) return HTTPS;
  return NONE;
};

export const addScheme = (value, scheme) => {
  if (scheme === NONE) return value;
  return `${scheme}${value}`;
};

export const stripScheme = (value) => {
  if (isHttp(value)) return (value || '').trim().replace(HTTP_REGEX, '');
  if (isHttps(value)) return (value || '').trim().replace(HTTPS_REGEX, '');
  return value;
};

export const changeScheme = (value, onChange, event) => {
  const scheme = event.target.value;
  const currentScheme = getScheme(value);

  if (currentScheme === scheme) return;

  onChange(addScheme(stripScheme(value), scheme));
};

export const changeAddress = (value, onChange, event) => {
  const scheme = getScheme(value);

  onChange(addScheme(event.target.value.trim(), scheme));
};

export const getSecondScheme = (value) => {
  const secondScheme = getScheme(stripScheme(value));
  if (secondScheme && secondScheme !== NONE) return secondScheme;
  return null;
};
