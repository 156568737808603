import PropTypes from 'prop-types';

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  color: PropTypes.string,
  background: PropTypes.string,
});
export interface Label {
  id: string;
  name: string;
  active?: boolean;
  color?: string;
  background?: string;
  editable?: boolean;
  type?: string;
}
