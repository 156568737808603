import { removeListItemById } from 'frontend/api/cacheHelpers';

export default ({ cache, chatsAndTickets, chat, ticket }) => {
  cache.modify({
    id: cache.identify(chatsAndTickets),
    fields: {
      chats: removeListItemById(chat?.id),
      tickets: removeListItemById(ticket?.id),
    },
  });
};
