import { get } from 'lodash';
import React from 'react';
import { Controller, type FieldError, type UseFormReturn, useFieldArray } from 'react-hook-form';

import { Alert, Eye, EyeOff } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import InputLimit from 'frontend/components/Input/InputLimit';
import InputRHF from 'frontend/components/Input/InputRHF';
import SelectRHF from 'frontend/components/Select/SelectRHF';
import randomUUID from 'frontend/utils/randomUUID';

import EmojiUploaderRHF from './EmojiUploaderRHF';
import PreviewCard from './PreviewCard';
import styles from './RatingScaleSection.scss';
import type { CustomerFeedbackInputs } from '../CustomerFeedback';
import { DEFAULT_RATING_SYSTEMS_TO_RATINGS, type FeedbackForm, type RHF_FORMS_NAME } from '../constants';
import shared from '../shared.scss';

interface Props {
  rhfMethods: UseFormReturn<CustomerFeedbackInputs>;
  rhfFormPath: `${typeof RHF_FORMS_NAME}.${number}`;
}

export default function RatingScaleSection({ rhfMethods, rhfFormPath }: Props): React.JSX.Element {
  const {
    watch,
    control,
    formState: { errors },
  } = rhfMethods;
  const {
    fields: editLabelFields,
    replace: replaceEditLabels,
    update: updateEditLabel,
  } = useFieldArray({
    name: `${rhfFormPath}.ratingScale.editLabels`,
    control,
  });

  const watchEditLabels = watch(`${rhfFormPath}.ratingScale.editLabels`);
  const watchRatingCardTitle = watch(`${rhfFormPath}.ratingScale.title`);

  const getLabelImageError = (index: number): FieldError | undefined =>
    get(errors, `${rhfFormPath}.ratingScale.editLabels.${index}.image`);

  const toggleShowLabel = (labelIndex: number) => () => {
    const label = watchEditLabels[labelIndex]!;

    updateEditLabel(labelIndex, {
      ...label,
      showRating: !label.showRating,
    });
  };

  return (
    <section className={shared.sectionSplit}>
      <div className={shared.leftSideSection}>
        <div>
          <h4 className={shared.heading}>Rating scale</h4>
          <p>
            Choose your feedback form&apos;s rating system to match your brand and the nature of the feedback
            you&apos;re seeking.
          </p>
        </div>

        <div className={shared.maxWidth}>
          <SelectRHF
            name={`${rhfFormPath}.ratingScale.ratingSystem`}
            rhfMethods={rhfMethods}
            label="Point system"
            rhfOptions={{
              valueAsNumber: true,
              onChange(event) {
                const newEditLabels = Array.from({ length: event.target.value }, (_, index) => {
                  const ratingSystem = event.target.value as FeedbackForm['ratingScale']['ratingSystem'];

                  const id = randomUUID();
                  const image = DEFAULT_RATING_SYSTEMS_TO_RATINGS[ratingSystem].image[index]!;
                  const rating = DEFAULT_RATING_SYSTEMS_TO_RATINGS[ratingSystem].rating[index]!;
                  const sentiment = DEFAULT_RATING_SYSTEMS_TO_RATINGS[ratingSystem].sentiment[index]!;
                  const showRating = DEFAULT_RATING_SYSTEMS_TO_RATINGS[ratingSystem].showRating[index]!;

                  return { id, image, rating, sentiment, showRating };
                });

                replaceEditLabels(newEditLabels);
              },
            }}
            fieldColor="mischka"
          >
            <SelectRHF.Option value={2} label="2" />
            <SelectRHF.Option value={3} label="3" />
            <SelectRHF.Option value={4} label="4" />
            <SelectRHF.Option value={5} label="5" />
            <SelectRHF.Option value={11} label="11" />
          </SelectRHF>
        </div>

        <div className={shared.maxWidth}>
          <InputRHF
            name={`${rhfFormPath}.ratingScale.title`}
            rhfMethods={rhfMethods}
            rhfOptions={{ required: true, maxLength: 50 }}
            label="Title description"
            inputLimit={50}
            fieldColor="mischka"
          />
        </div>

        <div className={shared.maxWidth}>
          <span className={styles.labelSentiment}>Sentiment</span>
          <span className={styles.labelEditLabels}>Edit labels</span>
          <ol className={styles.labelRowWrapper}>
            {editLabelFields.map((editLabelField, editLabelIndex) => {
              const watchLabel = watch(`${rhfFormPath}.ratingScale.editLabels.${editLabelIndex}.rating`, '');
              const watchShowLabel = watch(`${rhfFormPath}.ratingScale.editLabels.${editLabelIndex}.showRating`);
              const labelError = getLabelImageError(editLabelIndex);

              return (
                <React.Fragment key={editLabelField.id}>
                  <li className={styles.labelRow}>
                    <SelectRHF
                      name={`${rhfFormPath}.ratingScale.editLabels.${editLabelIndex}.sentiment`}
                      rhfMethods={rhfMethods}
                      fieldColor="mischka"
                      classNames={{ wrapper: styles.selectSentiment }}
                    >
                      <SelectRHF.Option value="negative" label="Negative" />
                      <SelectRHF.Option value="neutral" label="Neutral" />
                      <SelectRHF.Option value="positive" label="Positive" />
                    </SelectRHF>
                    <Controller
                      name={`${rhfFormPath}.ratingScale.editLabels.${editLabelIndex}.image`}
                      control={control}
                      render={({ field: imageField }) => (
                        <EmojiUploaderRHF
                          field={imageField}
                          rhfMethods={rhfMethods}
                          rhfFormPath={rhfFormPath}
                          index={editLabelIndex}
                        />
                      )}
                    />
                    <div className={styles.labelInputWrapper}>
                      <InputRHF
                        name={`${rhfFormPath}.ratingScale.editLabels.${editLabelIndex}.rating`}
                        rhfMethods={rhfMethods}
                        rhfOptions={{ required: true, maxLength: 15 }}
                        fieldColor="mischka"
                      />
                      <button
                        type="button"
                        className={styles.showLabelButton}
                        onClick={toggleShowLabel(editLabelIndex)}
                      >
                        <Icon component={watchShowLabel ? Eye : EyeOff} />
                      </button>
                    </div>
                    <InputLimit hasError={false} currentInput={watchLabel.length} limit={15} />
                  </li>
                  {labelError && (
                    <p className={styles.labelErrorMessage}>
                      <Icon component={Alert} />
                      {labelError.message}
                    </p>
                  )}
                </React.Fragment>
              );
            })}
          </ol>
        </div>
      </div>

      <div className={shared.rightSideSection}>
        <PreviewCard heading={watchRatingCardTitle}>
          <div className={styles.ratePreviewCardValues}>
            {watchEditLabels.map((editLabel) => (
              <div className={styles.ratePreviewCardValue} key={editLabel.id}>
                <img src={editLabel.image} alt="emoji" />
                {editLabel.showRating && <span>{editLabel.rating}</span>}
              </div>
            ))}
          </div>
        </PreviewCard>
      </div>
    </section>
  );
}
