import { gql } from '@apollo/client';

import { SLOT } from '../fragments';

export default gql`
  query SLOTS($botId: ID, $skillId: ID, $languageCode: String!) {
    slots(botId: $botId, skillId: $skillId, languageCode: $languageCode, nonEntityOnly: true) {
      ...SLOT
    }
  }
  ${SLOT}
`;
