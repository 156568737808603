import { withErrorBoundary } from 'react-error-boundary';

export default function composeErrorBoundary(Component) {
  return withErrorBoundary(Component, {
    fallback: <div />,
    onError: (error) => {
      console.error(error);
      throw error;
    },
  });
}
