import PropTypes from 'prop-types';

import ChildrenType from 'frontend/propTypes/ChildrenType';

import styles from './UserDetail.scss';

const UserItem = ({ title, children = '' }) => (
  <li className={styles.item}>
    <strong>{title}</strong>
    <div className={styles.itemContent}>{children}</div>
  </li>
);

UserItem.propTypes = {
  children: ChildrenType,
  title: PropTypes.string.isRequired,
};

export default UserItem;
