import cx from 'classnames';
import { formatISO } from 'date-fns';
import { omit } from 'lodash';
import { useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

import type { SampleType } from 'frontend/api/generated';
import { Button, DropdownButton } from 'frontend/components';
import { entityLimits } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';
import { useMixpanel, useToast } from 'frontend/hooks';

import styles from './SamplesHeadline.scss';
import { useActions } from './hooks';
import GenerateSamples from '../../modals/GenerateSamples/GenerateSamples';

/** Anti-abuse function to limit feature usage to `maxUsage` number of times per day.
 * TODO: move this to backend when possible.
 * @returns true if the feature can be used, false otherwise
 */
const antiAbuseGPT = (maxUsage: number): boolean => {
  const today = new Date();
  const todayDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  const local = localStorage.getItem('antiAbuseGPT');

  if (local) {
    const storageAntiAbuse = JSON.parse(local);

    if (storageAntiAbuse.date === todayDate) {
      if (storageAntiAbuse.usage >= maxUsage) return false;

      storageAntiAbuse.usage += 1;
      localStorage.setItem('antiAbuseGPT', JSON.stringify(storageAntiAbuse));
      return true;
    }
  }

  localStorage.setItem('antiAbuseGPT', JSON.stringify({ date: todayDate, usage: 1 }));
  return true;
};

export type GeneratedSampleType = Pick<SampleType, 'id' | 'text'>;

interface ExpandedSamplesCountProps {
  count: number;
  samplesExpanded?: boolean;
}

const ExpandedSamplesCount = ({ count, samplesExpanded = false }: ExpandedSamplesCountProps) => {
  const classNames = cx(styles.expandedSamplesCount, {
    [styles.expandedSamplesCountWarning]: count >= Math.round(entityLimits.MAX_SAMPLES_PER_DIALOGUE * 0.9),
    [styles.expandedSamplesCountError]: count > entityLimits.MAX_SAMPLES_PER_DIALOGUE,
  });

  return (
    <span className={classNames}>
      {!samplesExpanded && '('}
      {count}
      {!samplesExpanded && ')'}
    </span>
  );
};

interface SamplesHeadlineProps {
  children: React.ReactNode;
  sampleCount: number;
  expandedSampleCount: number;
  totalSampleCount: number;
  sampleList: SampleType[];
  sampleValidator: (sample: SampleType) => string | undefined;
  samplesExpanded: boolean;
  actionsEnabled?: boolean;
  setSamplesExpanded?(...args: unknown[]): void;
  fieldName: string;
  totalSkillSampleCount?: number;
  expandedSkillSampleCount?: number;
  currentLanguage?: string;
}

const SamplesHeadline = ({
  children,
  sampleList,
  sampleCount,
  sampleValidator,
  expandedSampleCount,
  totalSampleCount,
  samplesExpanded,
  fieldName,
  actionsEnabled,
  setSamplesExpanded,
  totalSkillSampleCount,
  expandedSkillSampleCount,
  currentLanguage,
}: SamplesHeadlineProps) => {
  const hasSearchResults = sampleCount < totalSampleCount;
  const hasEntities = expandedSampleCount > totalSampleCount;
  const hasExpandedSampleCount = samplesExpanded || (!hasSearchResults && hasEntities);
  const actions = useActions({
    actionsEnabled,
    setSamplesExpanded,
    samplesExpanded,
    fieldName,
    totalSampleCount,
    expandedSampleCount,
    totalSkillSampleCount,
    expandedSkillSampleCount,
  });

  const { botId, dialogueId } = useParams();
  const { mutators } = useForm();
  const toast = useToast();
  const { mixpanel } = useMixpanel();

  const [showSuggestSamplesModal] = useModal(GenerateSamples);

  const handleGenerateSamples = (samples: GeneratedSampleType[]) => {
    samples.forEach((sample) => {
      if (!mutators?.pushFixed) {
        return;
      }
      mutators.pushFixed('', fieldName, {
        dialogueId: null,
        tempId: sample.id,
        tempUpdatedAt: formatISO(new Date()),
        ...omit(sample, ['id']),
        languageCode: currentLanguage,
        instances: [],
      });
    });
  };

  const onGenerateSamples = () => {
    if (!antiAbuseGPT(300)) {
      toast.error('You have reached the maximum A.I. 🧠 capacity today. Please try again tomorrow.');
      return;
    }
    const trackingData = { botId, dialogueId, currentLanguage };
    mixpanel.track('GPT-3 Samples Generation: Requesting Samples', trackingData);
    showSuggestSamplesModal({
      handleGenerateSamples,
      initialSamples: sampleList,
      botId,
      sampleValidator,
      trackingData,
    });
  };

  return (
    <h3 className={styles.sampleHeader}>
      <div>
        {children}
        {!samplesExpanded && (
          <span className={styles.sampleCount}>
            {sampleCount}
            {hasSearchResults && (
              <span>
                {' / '}
                {totalSampleCount}
              </span>
            )}
          </span>
        )}
        {hasExpandedSampleCount && (
          <ExpandedSamplesCount samplesExpanded={samplesExpanded} count={expandedSampleCount} />
        )}
      </div>
      <div className={styles.samplesActions}>
        {!!botId && <Button color="primary" onClick={onGenerateSamples} text="Suggest samples" />}
        <DropdownButton className={styles.dropdownButton} actions={actions} />
      </div>
    </h3>
  );
};

export default SamplesHeadline;
