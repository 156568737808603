import cx from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { useClickOutside } from 'frontend/hooks';

import styles from './ChatMessage.scss';

const AddToAllowList = ({ addToAllowlist, close }) => {
  const ref = useRef();

  useClickOutside(ref, close);

  return (
    <button
      onClick={addToAllowlist}
      type="button"
      className={cx(styles.addToAllowlist, 'kindlyAdminManager')}
      ref={ref}
    >
      Add to allowlist
    </button>
  );
};

AddToAllowList.propTypes = {
  close: PropTypes.func.isRequired,
  addToAllowlist: PropTypes.func.isRequired,
};

export default AddToAllowList;
