import cx from 'classnames';

import styles from './Tooltip.scss';

interface TooltipProps {
  children: React.ReactNode;
  className?: string;
  alwaysActive?: boolean;
  /** If true, the tooltip won't display if the Tooltip has focus within.
   * Useful for example when wrapping a tooltip around a dropdown and wanting to hide the tooltip when the dropdown is opened. */
  hideOnFocusWithin?: boolean;
}

const Tooltip = ({ children, className, alwaysActive, hideOnFocusWithin }: TooltipProps) => (
  <div
    className={cx(styles.tooltipWrapper, className, {
      [styles.alwaysActive]: alwaysActive,
      [styles.hideOnFocusWithin]: hideOnFocusWithin,
    })}
  >
    {children}
  </div>
);

interface TooltipContentProps {
  children: React.ReactNode;
  className?: string;
  maxWidth?: string;
  includeChevron?: boolean;
}

const Body = ({ children, className, maxWidth, includeChevron = true }: TooltipContentProps) => (
  <div
    className={cx(styles.tooltip, className, { [styles.hasChevron]: includeChevron })}
    style={{ '--_maxWidth': maxWidth } as React.CSSProperties}
  >
    {children}
  </div>
);

const Standalone = ({ children, className, maxWidth, includeChevron = true }: TooltipContentProps) => (
  <span
    contentEditable={false}
    className={styles.standalone}
    style={{ '--_maxWidth': maxWidth } as React.CSSProperties}
  >
    <span className={cx(className, { [styles.hasChevron]: includeChevron })}>{children}</span>
  </span>
);

Tooltip.Body = Body;
Tooltip.Standalone = Standalone;

export default Tooltip;
