import type { ProductImageCarouselType } from 'frontend/api/generated';

export const handleCarouselTitle = (
  currentLanguage,
  {
    imageCarousel,
    carouselTitle,
    ...values
  }: { imageCarousel: ProductImageCarouselType | undefined; carouselTitle: string | undefined; values: unknown } = {
    imageCarousel: undefined,
    carouselTitle: undefined,
    values: {},
  },
) => {
  if (!imageCarousel) {
    return values;
  }
  const carouselWithTitle = { ...imageCarousel, title: carouselTitle?.[currentLanguage] ?? '' };

  return { ...values, imageCarousels: [carouselWithTitle] };
};
