import type { DialogueCandidateType } from 'frontend/api/generated';
import { useModal } from 'frontend/features/Modals';

import styles from './Occurrences.scss';
import ModalMessageList from '../../modals/ModalMessageList/ModalMessageList';

const Occurrences = ({ rowData }: { rowData: Partial<DialogueCandidateType> }) => {
  const [showModalMessageList] = useModal(ModalMessageList);
  return (
    <button
      type="button"
      onClick={() => showModalMessageList({ chatmessageIds: rowData.chatmessageIds })}
      className={styles.occurrenceButton}
    >
      {rowData.occurrences}
    </button>
  );
};

export default Occurrences;
