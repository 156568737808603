import { compose, get, isEqual } from 'lodash/fp';
import { useCallback } from 'react';

import { hasSameId } from 'frontend/features/Build/utils';
import { TICKET_STATUS } from 'frontend/features/Inbox/constants';
import { CHAT_TICKET, TICKET_VIEW_COUNTS } from 'frontend/features/Inbox/queries';
import {
  addTicket,
  chatPredicatesFromFilters,
  checkPredicates,
  prepareChat,
  prepareTicket,
  removeChatAndTicket,
  ticketPredicatesFromFilters,
} from 'frontend/features/Inbox/utils';
import useMe from 'frontend/hooks/useMe';
import { camelCaseKeys, valuesToFunctions } from 'frontend/utils';

import useViewConversation from './useViewConversation';

const checkAllPredicates = (filters, ticket, chat, userId) =>
  checkPredicates(ticketPredicatesFromFilters(filters, userId), ticket) &&
  checkPredicates(chatPredicatesFromFilters(filters), chat);

const updateTicket = ({ cache, ticket }) =>
  cache.modify({ id: cache.identify(ticket), fields: valuesToFunctions(ticket) });

const updateChatTicketQuery = ({ cache, botId, ticket }) => {
  const variables = { botId, chatId: ticket.chatId };
  const queryIsActive = Boolean(cache.readQuery({ query: CHAT_TICKET, variables }));
  if (queryIsActive) {
    cache.writeQuery({ query: CHAT_TICKET, variables, data: { ticketForChat: ticket } });
  }
};

const hasTicketChatId = ({ chatId }) => compose(isEqual(chatId), get('id'));

const statusCountField = (status = '') => `${status.toLowerCase()}Tickets`;

const solved = (status) => status === TICKET_STATUS.SOLVED;

// Can't use cache.modify because of https://github.com/apollographql/apollo-client/issues/6208
const updateTicketViewCounts = (botId, organizationId, userId, cache, ticket, previousValues = {}) => {
  const variables = { botId, organizationId };
  const ticketViewCounts = cache.readQuery({ query: TICKET_VIEW_COUNTS, variables })?.ticketViewCounts;

  if (!ticketViewCounts) {
    return;
  } // Query is not active

  const updatedTicketViewCounts = { ...ticketViewCounts };
  const { previousStatus, previousAgentId, created } = previousValues;
  const statusUpdated = created || 'previousStatus' in previousValues;

  if (statusUpdated && previousStatus !== ticket.status) {
    updatedTicketViewCounts[statusCountField(previousStatus)] -= 1;
    updatedTicketViewCounts[statusCountField(ticket.status)] += 1;
  }

  const assignedToMe = ticket.assignedAgent?.id?.toString() === userId?.toString();
  const previouslyAssignedToMe = previousAgentId?.toString() === userId?.toString();
  const assignedToMeChanged = 'previousAgentId' in previousValues && assignedToMe !== previouslyAssignedToMe;
  const assignedToMeSolvedChange = statusUpdated && assignedToMe && (solved(ticket.status) || solved(previousStatus));

  if (assignedToMeChanged) {
    updatedTicketViewCounts.assignedToMe += assignedToMe ? 1 : -1;
  } else if (assignedToMeSolvedChange) {
    updatedTicketViewCounts.assignedToMe += solved(previousStatus) ? 1 : -1;
  }

  const unassigned = !ticket.assignedAgent;
  const previouslyUnassigned = !previousAgentId;
  const unAssignedChanged = 'previousAgentId' in previousValues && unassigned !== previouslyUnassigned;
  const unAssignedSolvedChange = statusUpdated && unassigned && (solved(ticket.status) || solved(previousStatus));

  if (created) {
    updatedTicketViewCounts.unassignedTickets += 1;
  } else if (unAssignedChanged) {
    updatedTicketViewCounts.unassignedTickets += unassigned ? 1 : -1;
  } else if (unAssignedSolvedChange) {
    updatedTicketViewCounts.unassignedTickets += solved(previousStatus) ? 1 : -1;
  }

  if (isEqual(updatedTicketViewCounts, ticketViewCounts)) {
    return;
  } // No change

  const data = { ticketViewCounts: updatedTicketViewCounts };
  cache.writeQuery({ query: TICKET_VIEW_COUNTS, variables: { botId }, data });
};

export default ({ botId, organizationId, filters, chatsAndTickets, cache, onChatNotInList }) => {
  const { data } = useMe();
  const userId = data?.me?.id;
  const viewConversation = useViewConversation();

  const onTicketUpdated = useCallback(
    (payload) => {
      const { ticket: ticketPayload, chat: chatPayload, ...rest } = payload;
      const ticket = prepareTicket(ticketPayload);
      const chat = prepareChat(chatPayload);
      const previousValues = camelCaseKeys(rest);

      if (!viewConversation(chat)) {
        return;
      }

      const currentTicket = (chatsAndTickets?.tickets || []).find(hasSameId(ticket));
      const currentChat = (chatsAndTickets?.chats || []).find(hasTicketChatId(ticket));

      updateTicket({ cache, ticket });
      updateChatTicketQuery({ cache, botId, ticket });
      updateTicketViewCounts(botId, organizationId, userId, cache, ticket, previousValues);

      const chatShouldBeInList = checkAllPredicates(filters, ticket, chat, userId);

      if (!currentChat) {
        onChatNotInList({ chatsAndTickets, chat, ticket, chatShouldBeInList });
        return;
      }

      if (chatShouldBeInList && !currentTicket) {
        addTicket({ cache, chatsAndTickets, ticket });
      }

      if (!chatShouldBeInList) {
        removeChatAndTicket({ cache, chatsAndTickets, chat, ticket });
      }
    },
    [botId, cache, chatsAndTickets, filters, onChatNotInList, organizationId, userId, viewConversation],
  );

  return onTicketUpdated;
};
