import { ResponsiveHeatMap } from '@nivo/heatmap';
import { groupBy, sum } from 'lodash';
import { useMemo } from 'react';

import { EmptyState } from 'frontend/components/Stats';
import { getContrastYIQ } from 'frontend/utils/colors';

import styles from '../LineChart/LineChart.scss';

const ISOWeekdays = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

function toGridData(data) {
  return Object.entries(groupBy(data, 'weekday')).map(([weekday, entries]) => ({
    id: `${ISOWeekdays[weekday]}s`,
    data: entries.map(({ count, hour }) => ({ x: hour, y: count })),
  }));
}

interface HeatMapData {
  count: number;
}
interface TrafficHeatMapProps {
  data: HeatMapData[];
  emptyStateText?: string;
}

const TrafficHeatMap = ({ data, emptyStateText }: TrafficHeatMapProps) => {
  const [gridData, total] = useMemo(() => {
    if (!data) {
      return [[{ id: 'test', data: [{ x: 0, y: 0 }] }], 0];
    }
    const gridified = toGridData(data);

    const summed = data ? sum(data.map(({ count }) => count)) : 0;

    return [gridified, summed];
  }, [data]);

  if (total === 0) {
    return <EmptyState emptyStateText={emptyStateText} />;
  }

  return (
    <div style={{ height: '280px' }} className={styles.heatMap}>
      <ResponsiveHeatMap
        data={gridData}
        enableLabels={false}
        margin={{ left: 74 }}
        xOuterPadding={1}
        yInnerPadding={0.1}
        xInnerPadding={0.15}
        yOuterPadding={1}
        forceSquare
        colors={{
          type: 'diverging',
          scheme: 'blues',
        }}
        axisTop={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 90,
          legend: '',
          legendOffset: 0,
        }}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 0,
        }}
        opacity={1}
        cellComponent="circle"
        borderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
        labelTextColor={({ color }) => getContrastYIQ(color)}
        animate={false}
        hoverTarget="row"
        inactiveOpacity={0.25}
      />
    </div>
  );
};

export default TrafficHeatMap;
