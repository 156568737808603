import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import { Blob, CenteredComponent, Loader } from 'frontend/components';
import { isAuthenticated } from 'frontend/state/dux/auth';

import styles from './Invitation.scss';
import { AcceptForm, CreateUserForm, LoginForm, WrongUser } from './components';
import { INVITATION_STATES } from './constants';
import { useApiActions, useInvitationState } from './hooks';

const { ACCEPT, COMPLETED, LOGIN, CREATE_USER, INVALID, WRONG_USER } = INVITATION_STATES;

const Invitation = () => {
  const authenticated = useSelector(isAuthenticated);

  const { invitationState, invitation, updateInvitation, setInvitationState } = useInvitationState();
  const { findInvitation, accept } = useApiActions({ authenticated, updateInvitation, setInvitationState });
  useEffect(() => {
    if (!invitation) findInvitation();
  }, [invitation, findInvitation]);

  const onError = useCallback(
    ({ status }) => {
      if (status === 403) {
        setInvitationState(WRONG_USER);
      } else setInvitationState(INVALID);
    },
    [setInvitationState],
  );

  const goToLogin = useCallback(() => {
    setInvitationState(LOGIN);
  }, [setInvitationState]);

  useEffect(() => {
    if (invitationState === LOGIN && authenticated) accept({ onError });
  }, [accept, authenticated, invitationState, onError]);

  const loading =
    !invitationState || (invitationState === ACCEPT && !authenticated) || (invitationState === LOGIN && authenticated);

  return (
    <CenteredComponent className={styles.fullPage}>
      <div className={styles.branding}>
        <div className={styles.blobContainer}>
          <Blob speed={0.003} intensity={2} vectorAmount={300} />
        </div>
        <Link to="/" className={styles.brandingLink}>
          <span className={styles.brandingName}>Kindly</span>
        </Link>
      </div>
      {loading && <Loader size="large" />}
      {invitationState === ACCEPT && authenticated && <AcceptForm invitation={invitation} accept={accept} />}
      {invitationState === CREATE_USER && <CreateUserForm invitation={invitation} accept={accept} />}
      {invitationState === LOGIN && !authenticated && <LoginForm invitation={invitation} />}
      {invitationState === COMPLETED && <Navigate to="/invitation/complete" state={invitation} />}
      {invitationState === INVALID && <Navigate to="/invitation/invalid" state={invitation} />}
      {invitationState === WRONG_USER && <WrongUser invitation={invitation} goToLogin={goToLogin} />}
    </CenteredComponent>
  );
};

export default Invitation;
