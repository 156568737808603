import { gql } from '@apollo/client';

import { EVALUATION_FRAGMENT, EVALUATION_REPORT_FRAGMENT } from './fragments';

const GET_EVALUATION = gql`
  query GetEvaluation($evaluationId: ID!) {
    evaluation(evaluationId: $evaluationId) {
      ...Evaluation
      evaluationReports {
        ...EvaluationReport
      }
    }
  }
  ${EVALUATION_FRAGMENT}
  ${EVALUATION_REPORT_FRAGMENT}
`;
export default GET_EVALUATION;
