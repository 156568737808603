import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { useCurrentLanguage, useToast } from 'frontend/hooks';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import { CREATE_ENTITY } from '../../mutations';
import { ENTITIES } from '../../queries';
import { getEntityItemsIncludingInput } from '../../utils';
import EntityModal, { OPERATIONS } from '../EntityModal';

const pathToItemInMutationData = 'createEntity';
const pathToArrayInCache = 'entities';
const query = ENTITIES;

const CreateEntity = ({ args: { name: initialName, botOrSkillParams }, hide }) => {
  const toast = useToast();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { buildIdObject } = botOrSkillParams;
  const [newEntityItems, setNewEntityItems] = useState('');

  const update = appendArrayInCache({ pathToItemInMutationData, variables: buildIdObject, pathToArrayInCache, query });
  const [createEntity] = useMutation(CREATE_ENTITY, { update });

  const onSubmit = useCallback(
    async ({ name, items }) => {
      const entityInput = { name, items: getEntityItemsIncludingInput(items, newEntityItems) };
      await createEntity({ variables: { ...buildIdObject, languageCode: currentLanguage, entityInput } });
      toast.success('Entity created!');
    },
    [buildIdObject, createEntity, currentLanguage, newEntityItems, toast],
  );

  return (
    <EntityModal
      operation={OPERATIONS.CREATE}
      onSubmit={onSubmit}
      initialName={initialName}
      hide={hide}
      currentLanguage={currentLanguage}
      newEntityItems={newEntityItems}
      setNewEntityItems={setNewEntityItems}
      botOrSkillParams={botOrSkillParams}
    />
  );
};

CreateEntity.propTypes = {
  args: PropTypes.exact({ name: PropTypes.string, botOrSkillParams: BotOrSkillParamsType.isRequired }).isRequired,
  hide: PropTypes.func.isRequired,
};

export default CreateEntity;
