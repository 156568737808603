import { get } from 'lodash/fp';
import { useMemo } from 'react';

import { chain, max, slugValidator } from 'frontend/form/validators';

export default ({ data, nudge }) => {
  const validateSlug = useMemo(() => {
    const otherSlugs = (data?.allNudges || []).filter(({ slug }) => slug !== nudge?.slug).map(get('slug'));
    return chain(slugValidator(otherSlugs), max(50));
  }, [data?.allNudges, nudge]);

  return { validateSlug };
};
