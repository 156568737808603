export default (url: string): string | undefined => {
  const forbiddenExtensions: string[] = [
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.zip',
    '.rar',
    '.mp3',
    '.mp4',
  ];

  const urlPathName = new URL(url).pathname;
  const lastDotIndex = urlPathName.lastIndexOf('.');
  const fileExtension = lastDotIndex !== -1 ? urlPathName.substring(lastDotIndex).toLowerCase() : '';

  const forbiddenExtension = forbiddenExtensions.find((ext) => ext === fileExtension);
  if (forbiddenExtension) {
    return `The URL cannot be a ${forbiddenExtension.substring(1)} file`;
  }

  return undefined;
};
