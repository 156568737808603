import { isEqual, omit, pick } from 'lodash';

interface OnlyCheckProps {
  /**  If true, only these properties will be checked when calculating `updated`. */
  onlyCheckProps?: string[];
  ignoreProps?: never;
}

interface IgnoreProps {
  /** If true, these properties will be ignored when calculating `updated`. */
  ignoreProps?: string[];
  onlyCheckProps?: never;
}

type OptionProps = OnlyCheckProps | IgnoreProps;

/**
 * Compare arrays of objects that have an `id` and returns `created`, `updated`, `deleted` comparing how the array has changed from _initial_ to _new_.
 */
export default function getArrayUpdateV2<T extends { id: string }>(
  initialArray: T[],
  newArray: T[],
  options: OptionProps = {},
): {
  created: T[];
  updated: T[];
  deleted: T[];
} {
  const { ignoreProps, onlyCheckProps } = options;

  const result: {
    created: T[];
    updated: T[];
    deleted: T[];
  } = {
    created: [],
    updated: [],
    deleted: [],
  };

  const initialMap = new Map(initialArray.map((initialElement) => [initialElement.id, initialElement]));
  const newMap = new Map(newArray.map((newElement) => [newElement.id, newElement]));

  // Check for created and updated elements
  newArray.forEach((newElement) => {
    const initialCommonElement = initialMap.get(newElement.id);

    const initialElementData = onlyCheckProps
      ? pick(initialCommonElement, onlyCheckProps)
      : omit(initialCommonElement, ignoreProps ?? []);
    const newElementData = onlyCheckProps ? pick(newElement, onlyCheckProps) : omit(newElement, ignoreProps ?? []);

    if (!initialCommonElement) {
      result.created.push(newElement);
    } else if (!isEqual(initialElementData, newElementData)) {
      result.updated.push(newElement);
    }
  });

  // Check for deleted elements
  initialArray.forEach((initialElement) => {
    if (!newMap.has(initialElement.id)) {
      result.deleted.push(initialElement);
    }
  });

  return result;
}
