import { gql } from '@apollo/client';

export default gql`
  query BotContext($botId: ID!) {
    bot(id: $botId) {
      id
      name
      projectName
      avatarUrl
      organization {
        id
        name
        logo
        botCount
        skillCount
      }
    }
  }
`;
