import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Image, Rows2, Settings, Slot, SpeechMessage, TextCapitalize, Video } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import type { BuildFormType } from 'frontend/features/Build/propTypes/BuildFormType';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';

import styles from './button-overlays.scss';
import type { TabName, TabsShown } from '../../../hooks/useDialogueOutputs';

interface Props {
  dialogueType?: BuildFormType | null;
  isModDialogue: boolean | undefined;
  setTabsToShowExtra: React.Dispatch<React.SetStateAction<TabName[]>>;
  tabsShownMap: TabsShown;
}

function OutputOverlay({ dialogueType, isModDialogue, tabsShownMap, setTabsToShowExtra }: Props): React.JSX.Element {
  const isFeatureEnabled = useFeatureFlags();
  const [searchParams, setSearchParams] = useSearchParams();

  const slotsEnabled = isFeatureEnabled('slots');
  const isFallbackOrGreeting = ['fallback', 'greeting'].includes(dialogueType as string);

  const showTab: (tab: TabName) => () => void = (tab) => () => {
    setTabsToShowExtra((prevTabsShown) => [...prevTabsShown, tab]);

    searchParams.set('formContent', 'output');
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      <button type="button" disabled={!!tabsShownMap.REPLIES} className={styles.button} onClick={showTab('reply')}>
        <Icon component={TextCapitalize} className={styles.icon} />
        Reply
      </button>

      <button type="button" disabled={!!tabsShownMap.BUTTONS} className={styles.button} onClick={showTab('button')}>
        <Icon component={Button} className={styles.icon} />
        Button
      </button>

      <button type="button" disabled={!!tabsShownMap.IMAGES} className={styles.button} onClick={showTab('image')}>
        <Icon component={Image} className={styles.icon} />
        Image
      </button>

      <button type="button" disabled={!!tabsShownMap.VIDEOS} className={styles.button} onClick={showTab('video')}>
        <Icon component={Video} className={styles.icon} />
        Video
      </button>

      {!isModDialogue && !isFallbackOrGreeting && (
        <>
          <button
            type="button"
            disabled={!!tabsShownMap.SUGGESTION}
            className={styles.button}
            onClick={showTab('suggestion')}
          >
            <Icon component={SpeechMessage} className={styles.icon} />
            Suggestion
          </button>

          {slotsEnabled && (
            <button type="button" disabled={!!tabsShownMap.SLOTS} className={styles.button} onClick={showTab('slot')}>
              <Icon component={Slot} className={styles.icon} />
              Slot
            </button>
          )}
        </>
      )}

      <button type="button" disabled={!!tabsShownMap.FORMS} className={styles.button} onClick={showTab('form')}>
        <Icon component={Rows2} className={styles.icon} />
        Forms
      </button>

      <button
        type="button"
        disabled={!!tabsShownMap.ADVANCED}
        className={styles.button}
        onClick={showTab('advanced options')}
      >
        <Icon component={Settings} className={styles.icon} />
        Advanced Options
      </button>
    </div>
  );
}

export default OutputOverlay;
