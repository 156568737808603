import { gql } from '@apollo/client';

import { SuggestionDialogueFragment } from 'frontend/features/Build/graphql/fragments/SuggestionDialogueFragment';

export const UpdateSuggestionDialogue = gql`
  mutation UpdateSuggestionDialogue($botId: ID!, $id: UUID!, $suggestionDialogueInput: SuggestionDialogueInputType!) {
    updateSuggestionDialogue(botId: $botId, id: $id, suggestionDialogueInput: $suggestionDialogueInput) {
      ...SuggestionDialogue
    }
  }
  ${SuggestionDialogueFragment}
`;
