import { format, subMonths } from 'date-fns';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import type { BotType, MonthlyReportType } from 'frontend/api/generated';
import { InfoButton, LoaderSwitch, Panel, SidebarNavigation } from 'frontend/components';
import { EmptyState, TrafficHeatMap } from 'frontend/components/Stats';
import { AnalyticsContent } from 'frontend/features/Analytics/components';
import type { ReportBot, ReportData } from 'frontend/features/Analytics/utils/sageData';
import { useCreateReport } from 'frontend/features/Organization/views/Reports/useCreateReport';
import { isEmptyReport } from 'frontend/features/Organization/views/Reports/utils';
import { useTabTitle } from 'frontend/hooks';
import { DATE_FORMAT_YEAR_MONTH } from 'frontend/utils/date';

import { BotReport } from './BotReport';
import BusinessHourReport from './BusinessHourReport';
import { ChatBotTraffic } from './ChatBotTraffic';
import NoData from './NoData';
import styles from './Reports.scss';
import useReports from './useReports';

function Content({
  loading,
  report,
  botReports,
  reportData,
  previousReportData,
  startDate,
  endDate,
}: {
  loading: boolean;
  report: Partial<MonthlyReportType>;
  botReports: { data: ReportBot; meta: BotType; previous?: ReportBot }[];
  reportData: ReportData;
  previousReportData: ReportData | undefined;
  startDate: string;
  endDate: string;
}): React.JSX.Element {
  return (
    <>
      <h2 className={styles.trafficSummary}>
        Traffic summary
        <InfoButton title={`The timezone used for this report is "${report?.tz}"`} />
      </h2>
      <Panel className="m-b">
        <h3>Chatbot traffic</h3>
        <ChatBotTraffic data={reportData} previous={previousReportData} loading={loading} />
        <h3>Traffic hours</h3>
        <TrafficHeatMap data={reportData?.hours || []} emptyStateText="Nothing to see this month" />
        {reportData?.hours && (
          <BusinessHourReport hours={reportData?.hours ?? []} emptyStateText="Nothing to see this month" />
        )}
      </Panel>
      <h2>Chatbots</h2>
      <Panel className="m-b">
        <h3>Chatbot traffic</h3>
        <p className={styles.subTitle}>Sorted by sessions</p>
        {botReports.map((botReport) => (
          <BotReport
            key={botReport.data.bot_id}
            botReport={botReport}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
          />
        ))}
        {!botReports?.length && <EmptyState emptyStateText="Nothing to see this month" />}
      </Panel>
    </>
  );
}

export default function Reports(): React.JSX.Element {
  const { organizationId, month } = useParams();
  const [searchParams] = useSearchParams();
  const tz = searchParams.get('tz') ?? undefined;
  const navigate = useNavigate();

  const defaultMonth = format(subMonths(new Date(), 1), DATE_FORMAT_YEAR_MONTH);

  useEffect(() => {
    // If there is no selected month we redirect to the last one relative to _today_
    if (!month) {
      navigate(`/organization/${organizationId}/analytics/reports/${defaultMonth}`, { replace: true });
    }
  }, [organizationId, navigate, month, defaultMonth]);

  useTabTitle(`Monthly report (${month})`);

  const { loading, report, previous, navigation } = useReports({
    organizationId: organizationId!,
    month: month ?? defaultMonth,
    tz,
  });

  const createReport = useCreateReport(organizationId!, month ?? defaultMonth, tz);

  const content =
    loading || !report || isEmptyReport(report.meta) ? (
      <NoData report={report?.meta} month={month} tz={tz} createReport={createReport} />
    ) : (
      <Content
        report={report.meta}
        loading={loading}
        reportData={report.data}
        previousReportData={previous?.data}
        botReports={report.bots}
        startDate={report.startDate}
        endDate={report.endDate}
      />
    );

  return (
    <AnalyticsContent>
      <div>
        <LoaderSwitch loading={navigation.loading}>
          <SidebarNavigation items={navigation.items} />
        </LoaderSwitch>
      </div>
      <div>{content}</div>
    </AnalyticsContent>
  );
}
