import { useParams } from 'react-router-dom';

import { useCurrentLanguage, useLanguages } from 'frontend/hooks';

import useDialogueType from './useDialogueType';

export default function useSpecialDialogue() {
  const dialogueType = useDialogueType();
  const { botId, dialogueId } = useParams();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const { loading, languagesAndVariants } = useLanguages({
    isBot: true,
    isSkill: false,
    botId: botId!,
    buildIdObject: { botId: botId! },
    buildType: 'bot',
    buildId: botId!,
  });

  return { loading, languagesAndVariants, dialogueType, selectedLanguage, botId, dialogueId };
}
