import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Field, useForm } from 'react-final-form';

import { SelectDialogue } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { useBotOrSkill } from 'frontend/hooks';

import ButtonField from './ButtonField';
import { GetDialogueList } from '../../graphql';

const DialogueTrigger = ({
  name,
  disabled,
  localErrors,
  localSubmitFailed,
  onKeyDown,
  isNew,
  validators,
  currentLanguage,
}) => {
  const { buildIdObject } = useBotOrSkill();
  const { data, loading } = useQuery(GetDialogueList, {
    variables: { ...buildIdObject, includeSubscribedSkills: true, excludeGreetings: true },
    fetchPolicy: 'network-only',
  });

  const { registerField, change } = useForm();

  useEffect(() => {
    if (!data) {
      return undefined;
    }
    const unsubscribe = registerField(
      `${name}.value`,
      (fieldState) => {
        const value = fieldState.value;
        const dialogue = data?.dialogueList.dialogues.find(({ id }) => id === value);
        if (dialogue) {
          change(`${name}.dialogueLink.dialogue.isActive`, dialogue.is_active);
        }
      },
      { value: true },
    );
    return () => unsubscribe();
  }, [registerField, change, name, data]);

  return (
    <>
      <Field
        component={ButtonField}
        name={`${name}.label`}
        placeholder="Button text"
        autoFocus={!isNew}
        validate={isNew ? undefined : validators.label}
        readOnly={disabled}
        ariaLabel="Button text"
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
        currentLanguage={currentLanguage}
      />
      <Field
        component={SelectDialogue}
        readOnly={disabled}
        placeholder="Dialogue"
        name={`${name}.value`}
        dialogueList={data?.dialogueList}
        loading={loading}
        activeOnly={false}
        fieldColor={FIELD_COLOR.MISCHKA}
      />
    </>
  );
};

DialogueTrigger.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  localErrors: PropTypes.shape({}).isRequired,
  localSubmitFailed: PropTypes.bool.isRequired,
  onKeyDown: PropTypes.func,
  isNew: PropTypes.bool.isRequired,
  validators: PropTypes.shape({}).isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default DialogueTrigger;
