import { useMutation } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  UpdateCustomNudgeDocument,
  UpdateFormNudgeDocument,
  UpdateMultipleChoiceNudgeDocument,
  UpdateProductNudgeDocument,
  UpdateTextNudgeDocument,
} from 'frontend/api/generated';
import { getImageCarouselsUpdate } from 'frontend/features/Build/utils';
import { useCurrentLanguage, useToast } from 'frontend/hooks';
import { getArrayFieldUpdate } from 'frontend/utils';

import { nudgeType } from '../constants';
import { handleCarouselTitle } from '../utils';

const textBlockFields = ['id', 'value', 'slug'];
const buttonBlockFields = ['id', 'buttonText', 'dialogueId'];

export default () => {
  const toast = useToast();
  const { botId } = useParams();
  const [{ currentLanguage }] = useCurrentLanguage();

  const update = useCallback(() => toast.success('Nudge was updated'), [toast]);

  const [updateFormNudge] = useMutation(UpdateFormNudgeDocument, { update });
  const [updateProductNudge] = useMutation(UpdateProductNudgeDocument, { update });
  const [updateTextNudge] = useMutation(UpdateTextNudgeDocument, { update });
  const [updateCustomNudge] = useMutation(UpdateCustomNudgeDocument, { update });
  const [updateMultipleChoiceNudge] = useMutation(UpdateMultipleChoiceNudgeDocument, { update });

  const onFormUpdate = useCallback(
    async (values) => {
      const variables = {
        botId,
        formInput: {
          ...omit(values, ['__typename']),
        },
      };

      await updateFormNudge({ variables });
    },
    [botId, updateFormNudge],
  );

  const onProductUpdate = useCallback(
    async (values, { getState }) => {
      const { initialValues } = getState();
      const imageCarouseUpdate = getImageCarouselsUpdate(
        handleCarouselTitle(currentLanguage, initialValues),
        handleCarouselTitle(currentLanguage, values),
        { isNudgeCarousel: true },
      );

      const variables = {
        botId,
        productInput: {
          ...omit(values, ['__typename', 'imageCarousel', 'imageCarousels', 'carouselTitle']),
          ...imageCarouseUpdate,
        },
      };

      await updateProductNudge({ variables });
    },
    [botId, currentLanguage, updateProductNudge],
  );

  const onTextUpdate = useCallback(
    async (values) => {
      const variables = {
        botId,
        textInput: {
          ...omit(values, ['__typename']),
        },
      };

      await updateTextNudge({ variables });
    },
    [botId, updateTextNudge],
  );

  const onCustomUpdate = useCallback(
    async (values, { getState }) => {
      const { initialValues } = getState();

      const {
        created: createdTextBlocks,
        updated: updatedTextBlocks,
        deleted: deletedTextBlocks,
      } = getArrayFieldUpdate(initialValues.textBlocks, values.textBlocks, textBlockFields);

      const variables = {
        botId,
        textInput: {
          ...omit(values, ['__typename', 'textBlocks']),
          updatedTextBlocks,
          createdTextBlocks,
          deletedTextBlocks,
        },
      };

      await updateCustomNudge({ variables });
    },
    [botId, updateCustomNudge],
  );

  const onMultipleChoiceUpdate = useCallback(
    async (values, { getState }) => {
      const { initialValues } = getState();

      const {
        created: createdButtonBlocks,
        updated: updatedButtonBlocks,
        deleted: deletedButtonBlocks,
      } = getArrayFieldUpdate(initialValues.buttonBlocks, values.buttonBlocks, buttonBlockFields);

      const variables = {
        botId,
        multipleChoiceNudgeInput: {
          ...omit(values, ['__typename', 'buttonBlocks']),
          updatedButtonBlocks,
          createdButtonBlocks,
          deletedButtonBlocks,
        },
      };

      await updateMultipleChoiceNudge({ variables });
    },
    [botId, updateMultipleChoiceNudge],
  );

  const getState = (initialValues) => ({ initialValues });

  // TODO refactor this whole hook to handle better these cases
  const onUpdate = (values, initialValues) => {
    switch (values.type) {
      case nudgeType.FORM:
        return onFormUpdate(values);
      case nudgeType.PRODUCT:
        return onProductUpdate(values, { getState: () => getState(initialValues) });
      case nudgeType.MULTIPLE_CHOICE:
        return onMultipleChoiceUpdate(values, { getState: () => getState(initialValues) });
      case nudgeType.CUSTOM:
        return onCustomUpdate(values, { getState: () => getState(initialValues) });
      default:
        return onTextUpdate(values);
    }
  };

  return {
    onFormUpdate,
    onProductUpdate,
    onTextUpdate,
    onCustomUpdate,
    onMultipleChoiceUpdate,
    onUpdate,
  };
};
