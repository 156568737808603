import { DIALOGUE_TYPES } from 'frontend/constants';
import { capitalize, getArrayFieldUpdate } from 'frontend/utils';

import handleEditorState from './handleEditorState';

const relevantFields = ['content', 'id'];
const getFieldName = (action, name) => `${action}${capitalize(name)}`;
const handler = (items) => items.filter(({ content }) => content.trim());

export default (initialValues, values, name) => {
  const isIntentDialogue = initialValues.dialogueType === DIALOGUE_TYPES.INTENT;

  const initial = (initialValues?.[name] ?? []).map((item) => handleEditorState(item, isIntentDialogue));
  const current = (values?.[name] ?? []).map((item) => handleEditorState(item, isIntentDialogue));
  const { created, updated, deleted } = getArrayFieldUpdate(initial, current, relevantFields, handler);

  const createdName = getFieldName('created', name);
  const updatedName = getFieldName('updated', name);
  const deletedName = getFieldName('deleted', name);

  return { [createdName]: created, [deletedName]: deleted, [updatedName]: updated };
};
