import { gql } from '@apollo/client';

import { USER_LIST_ENTRY_FRAGMENT } from 'frontend/features/Admin/views/Users/fragments';

export const USER_SEARCH = gql`
  query UserSearch($query: String, $filters: UserFiltersInput, $first: Int, $after: Int, $sort: [String]) {
    userSearch(query: $query, filters: $filters, first: $first, after: $after, sort: $sort) {
      id
      hits
      objects {
        ...UserListEntry
      }
    }
  }
  ${USER_LIST_ENTRY_FRAGMENT}
`;
