import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { DELETE_EVALUATION_SET } from 'frontend/features/Evaluation/graphql';
import { DeleteModal } from 'frontend/features/Modals';

const DeleteEvaluation = ({ hide, args: { evaluation } }) => {
  const [mutate] = useMutation(DELETE_EVALUATION_SET, {
    update: (
      cache,
      {
        data: {
          deleteEvaluation: { id },
        },
      },
    ) => {
      cache.evict({ id: `EvaluationType:${id}` });
    },
  });
  const onSubmit = async () => {
    await mutate({ variables: { evaluationId: evaluation.id } });
  };
  return <DeleteModal onSubmit={onSubmit} hide={hide} name={`evaluation set "${evaluation.title}"`} />;
};

DeleteEvaluation.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    evaluation: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default DeleteEvaluation;
