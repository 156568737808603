import { get } from 'lodash';

import { isRegularDialogueType } from 'frontend/constants';
import { DialogueModFragment, GetDialogue } from 'frontend/features/Build/graphql';

import updateFollowupsCount from './updateFollowupsCount';
import updateLibraryDialogues from './updateLibraryDialogues';
import { getDialogueMod } from '../queries';
import { getDialogueParentVariables } from '../utils';

const fetchModParentDialogue = async ({ client, id, buildIdObject }) => {
  const fetchPolicy = 'network-only';
  const fragment = DialogueModFragment;
  let dialogueId;

  try {
    const modInCache = client.readFragment({ fragment, id: `DialogueModType:${id}`, fragmentName: 'DialogueMod' });
    dialogueId = get(modInCache, 'modDialogueId');
  } catch (e) {
    // No action required
  }

  if (!dialogueId) {
    // Have to fetch mod from backend (just created, or there was a problem reading from the cache)
    const result = await client.query({
      query: getDialogueMod,
      variables: { ...buildIdObject, id },
      fetchPolicy,
    });
    dialogueId = get(result, 'data.dialogueMod.modDialogueId');
  }

  await client.query({
    query: GetDialogue,
    variables: { ...buildIdObject, dialogueId },
    fetchPolicy,
  });
};

const updateRegularParent = async ({ buildIdObject, client, dialogue }) => {
  const { parentId, dialogueModParentId } = dialogue;

  // Update followups of the new dialogue's parent
  const variables = getDialogueParentVariables(buildIdObject, dialogue);
  updateLibraryDialogues({ variables, client, addedDialogue: dialogue });

  // Update followup count of the new dialogue's parent
  if (parentId) updateFollowupsCount({ client, update: 1, id: parentId, variables: buildIdObject });
  else if (dialogueModParentId) await fetchModParentDialogue({ client, id: dialogueModParentId, buildIdObject });
};

const updateSpecialFolder = ({ buildIdObject, dialogue, dialogueType, client }) => {
  const variables = { ...buildIdObject, dialogueType, regular: false };
  updateLibraryDialogues({ variables, client, addedDialogue: dialogue });
};

export default ({ buildIdObject, client, dialogue }) => {
  const { dialogueType } = dialogue;
  if (!isRegularDialogueType(dialogueType)) updateSpecialFolder({ buildIdObject, dialogueType, client, dialogue });
  else updateRegularParent({ dialogue, buildIdObject, client });
};
