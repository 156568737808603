import { gql } from '@apollo/client';

import { CONTEXT_FIELD } from '../fragments';

export default gql`
  mutation CreateContextField($botId: ID!, $key: String!, $value: String!) {
    createContextField(botId: $botId, key: $key, value: $value) {
      ...ContextField
    }
  }
  ${CONTEXT_FIELD}
`;
