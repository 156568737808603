import { EditorState, Modifier } from 'draft-js';

import createSelectionState from './createSelectionState';
import getHashtagMatch from './getHashtagMatch';
import getHashtagRegex from './getHashtagRegex';
import { insertCustomTag, insertHighlightTag } from '../stateHandlers';

export default (state, { potentialTagItems, openDropdown, constructTagItem, onInsertTag }) => {
  const blockMap = state.getCurrentContent().getBlockMap();
  let updatedState = state;
  let openDropdownSelectedText = false;

  blockMap.forEach((block, blockKey) => {
    if (openDropdownSelectedText) return;

    const hashtagRegex = getHashtagRegex();
    let potentialNameMatch = getHashtagMatch(hashtagRegex, block.getText());

    while (potentialNameMatch && !openDropdownSelectedText) {
      const { start, end, word: potentialName } = potentialNameMatch;

      const selectionWithHashtag = createSelectionState({ anchorKey: blockKey, start, end: start + 1 });
      const contentWithoutHashtag = Modifier.replaceText(updatedState.getCurrentContent(), selectionWithHashtag, '');
      const stateWithoutHashtag = EditorState.push(updatedState, contentWithoutHashtag, 'insert-characters');

      const selection = createSelectionState({ anchorKey: blockKey, start, end: end - 1 });
      const foundItem = potentialTagItems.find(({ name }) => name === potentialName.toLowerCase());

      if (foundItem) {
        const item = constructTagItem(foundItem);
        onInsertTag?.(item);
        updatedState = insertCustomTag(stateWithoutHashtag, { item, currentSelection: selection });
        const updatedBlock = updatedState.getCurrentContent().getBlockMap().get(blockKey);
        potentialNameMatch = getHashtagMatch(hashtagRegex, updatedBlock.getText());
      } else {
        const data = { noButton: true };
        updatedState = insertHighlightTag(stateWithoutHashtag, { currentSelection: selection, data });
        openDropdownSelectedText = potentialName;
      }
    }
  });

  if (openDropdownSelectedText) openDropdown({ selectedText: openDropdownSelectedText, fromHashtag: true });

  return { state: updatedState, dropdownOpened: !!openDropdownSelectedText };
};
