import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { hasSameId, indexList } from 'frontend/features/Build/utils';
import { DeleteModal } from 'frontend/features/Modals';
import { useCurrentLanguage } from 'frontend/hooks';

import { EntityType } from '../../propTypes';

const RemoveEntityFromDialogue = ({ hide, args }) => {
  const { entity, form, isModDialogue } = args;
  const [{ currentLanguage }] = useCurrentLanguage();

  const onSubmit = useCallback(() => {
    const { update } = form.mutators;
    const fieldName = isModDialogue ? 'modSamples' : 'samples';
    const samples = form.getState().values[fieldName];

    indexList(samples)
      .filter(({ languageCode }) => languageCode === currentLanguage)
      .forEach((sample) => {
        const sampleHasEntity = (sample.instances || []).find(({ entity: instanceEntity }) =>
          hasSameId(entity, instanceEntity),
        );
        if (!sampleHasEntity) return;

        const updatedSample = {
          ...sample,
          instances: sample.instances.filter(
            ({ entity: instanceEntity }) => !instanceEntity || !hasSameId(instanceEntity, entity),
          ),
        };

        update(fieldName, sample.index, updatedSample);
      });
  }, [currentLanguage, entity, form, isModDialogue]);

  return (
    <DeleteModal
      hide={hide}
      onSubmit={onSubmit}
      titleName="entity"
      name={`"${entity.name}" from this dialogue`}
      action="remove"
      hideWarning
    />
  );
};

RemoveEntityFromDialogue.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    entity: EntityType.isRequired,
    form: PropTypes.shape({}).isRequired,
    isModDialogue: PropTypes.bool,
  }).isRequired,
};

export default RemoveEntityFromDialogue;
