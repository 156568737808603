import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './Portal.scss';

interface Portal {
  children: React.ReactNode | React.ReactNode[] | null;
  attachElementId: string;
  wrapperId: string;
}

const createWrapperAndAppendToElement = (attachElementId, wrapperId) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  const attachToElement = document.getElementById(attachElementId);
  if (attachToElement) {
    wrapperElement.classList.add(styles.portalWrapperElement);
    attachToElement.appendChild(wrapperElement);
    return wrapperElement;
  }
  return null;
};

const Portal = ({ children, attachElementId, wrapperId }: Portal) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToElement(attachElementId, wrapperId);
    }
    setWrapperElement(element);

    return () => {
      // delete the programatically created element
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId, attachElementId]);

  // wrapperElement state will be null on very first render.
  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};

export default Portal;
