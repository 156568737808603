export default ({
  botId,
  skillId,
  userId,
  chatId,
  organizationId,
  isInbox = false,
  isAvailability = false,
}: {
  botId?: string;
  skillId?: string;
  userId?: string;
  chatId?: string;
  organizationId?: string;
  isInbox?: boolean;
  isAvailability?: boolean;
}) => {
  if (organizationId && isAvailability) return `private-availability-organization-${organizationId}`;
  if (organizationId && isInbox) return `private-inbox-organization-${organizationId}`;
  if (botId && isInbox) return `private-inbox-bot-${botId}`;
  if (botId) return `private-build-bot-${botId}`;
  if (skillId) return `private-build-skill-${skillId}`;
  if (userId) return `private-notifications-${userId}`;
  if (chatId) return `private-chat-${chatId}`;

  console.error('Tried to get Pusher channel with no relevant id');
  return '';
};
