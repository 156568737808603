import { useCallback, useEffect, useState } from 'react';

export default function useToogle(initial = false) {
  const [active, setActive] = useState(initial);

  const toggle = useCallback(() => {
    setActive((current) => !current);
  }, [setActive]);

  useEffect(() => {
    setActive(initial);
  }, [initial]);

  const unset = useCallback(() => setActive(false), [setActive]);

  return [active, toggle, unset] as const;
}
