import { Field } from 'react-final-form';

import { Trash } from 'frontend/assets/icons';
import { Icon, Input } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { required } from 'frontend/form/validators';
import type { Input as InputType } from 'frontend/propTypes/InputType';

import styles from './Replies.scss';

interface Props {
  input: InputType;
  readOnly?: boolean;
  placeholder: string;
  remove: () => void;
}

const Reply = ({ input: { name }, readOnly, placeholder, remove, ...props }: Props) => (
  <div className={styles.inputWrapper}>
    <Field
      name={name}
      component={Input}
      tabIndex={0}
      className="m-b-sm"
      validate={required}
      readOnly={readOnly}
      placeholder={placeholder}
      parse={(v) => v} // converts undefined to ''
      multiline
      fieldColor={FIELD_COLOR.MISCHKA}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
    <span className={styles.deleteWrapper}>
      <Icon component={Trash} onClick={remove} color="default" hoverColor="warning" title="Remove" />
    </span>
  </div>
);

export default Reply;
