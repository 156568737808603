import { useApolloClient, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Button, Loader } from 'frontend/components';
import { RunEvaluation } from 'frontend/features/Evaluation/modals';
import { useModal } from 'frontend/features/Modals';
import { NotFoundComponent } from 'frontend/features/NotFound';
import { useToast } from 'frontend/hooks';

import { GET_EVALUATION } from '../graphql';
import { useEvaluationFinishedUpdate, useRunReport } from '../hooks';

const NoReports = () => {
  const { botId } = useParams();
  const { evaluationId } = useParams();
  const toast = useToast();
  const client = useApolloClient();
  const [showRunEvalution] = useModal(RunEvaluation);
  const { data, loading } = useQuery(GET_EVALUATION, { variables: { evaluationId } });
  const { run, isRunning, setIsRunning } = useRunReport({ evaluationId });
  useEvaluationFinishedUpdate({ botId, evaluationId, setIsRunning, toast, client });

  if (loading) {
    return <Loader size="large" />;
  }
  const { evaluation } = data;

  return (
    <NotFoundComponent
      text={
        evaluation.totalQueries > 0
          ? 'Click here to create your first report.'
          : 'You need to make some evaluation queries before you can create a report.'
      }
    >
      {evaluation.totalQueries > 0 ? (
        <Button
          onClick={() =>
            showRunEvalution({
              run,
              confidenceThreshold: evaluation.confidenceThreshold,
              estimateRunTimeSeconds: evaluation.estimateRunTimeSeconds,
            })
          }
          color="primary"
          isSubmitting={isRunning}
          disabled={isRunning}
        >
          Click here to create
        </Button>
      ) : (
        <Button onClick={() => window.history.back()} color="primary">
          Go back
        </Button>
      )}
    </NotFoundComponent>
  );
};

export default NoReports;
