import PropTypes from 'prop-types';

import { Trash } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { EntityTag } from 'frontend/features/Entities/components';
import { SlotTag } from 'frontend/features/Slots/components';

import styles from './SlotItem.scss';

const Title = ({ input: { value }, entity, counter, remove }) => (
  <div className={styles.slotItemTitle}>
    <span>
      {counter + 1}
      .&ensp;
      {entity ? (
        <EntityTag className={styles.slotItemTitle} name={value.name} hue={value.hue} items={value.items} />
      ) : (
        <SlotTag name={value} />
      )}
    </span>
    {remove && <Button flat color="warning" icon={Trash} onClick={remove} />}
  </div>
);

Title.propTypes = {
  counter: PropTypes.number.isRequired,
  entity: PropTypes.bool,
  remove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        hue: PropTypes.number.isRequired,
        items: PropTypes.arrayOf(PropTypes.string),
      }),
    ]).isRequired,
  }),
};

export default Title;
