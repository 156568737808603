import cx from 'classnames';

import avatarTalkBubble from 'frontend/assets/images/avatar-talk-bubble.svg?url';
import defaultAvatar from 'frontend/assets/images/avatar.svg?url';

import styles from './ChatDeleted.scss';

const ChatDeleted = () => (
  <div className={styles.chatDeleted}>
    <div className={styles.chatDeletedSplash}>
      <div className={cx(styles.chatDeletedImage, styles.avatar)} style={{ backgroundImage: `url(${defaultAvatar})` }}>
        <div
          className={cx(styles.chatDeletedImage, styles.talkBubble)}
          style={{ backgroundImage: `url(${avatarTalkBubble})` }}
        />
      </div>
      <p>Chat was deleted</p>
    </div>
  </div>
);

export default ChatDeleted;
