import PropTypes from 'prop-types';

import LanguageType from './LanguageType';
import OrganizationType from './OrganizationType';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  primaryLanguage: PropTypes.string,
  avatarUrl: PropTypes.string,
  organization: OrganizationType,
  languages: PropTypes.arrayOf(LanguageType),
});
