import { useQuery } from '@apollo/client';

import { OrganizationInvitationsDocument, type OrganizationInvitationsQuery } from 'frontend/api/generated';

export type Invitation = NonNullable<OrganizationInvitationsQuery['organizationInvitations']>[number];

export default function useInvitations(organizationId: string) {
  const { data, loading } = useQuery(OrganizationInvitationsDocument, { variables: { organizationId } });
  const invitations: Invitation[] = data?.organizationInvitations ?? [];

  return { invitations, loading };
}
