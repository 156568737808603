import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useIsRunning, useOnSubmitWithUpload } from 'frontend/hooks';

import { getDemo, updateDemo } from '../Chatbubble/components/Demo/graphql';

export default () => {
  const { botId } = useParams();
  const [updateSettings] = useMutation(updateDemo);
  const { data, loading } = useQuery(getDemo, { variables: { botId } });

  const onSubmit = useCallback(
    async (values) => {
      await updateSettings({
        variables: {
          botId,
          demo: {
            ...omit(values, ['__typename', 'id', 'image']),
            deleteImage: values.image === null,
          },
        },
      });
    },
    [botId, updateSettings],
  );

  const [onSubmitWithUpload, setFile] = useOnSubmitWithUpload(onSubmit, `api/v2/bot/${botId}/demo/upload_image/`);
  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmitWithUpload);

  return {
    botId,
    loading,
    initialValues: data?.demo,
    onSubmit: onSubmitWithRunning,
    isSubmitting,
    setFile,
  };
};
