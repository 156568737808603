import { get } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import type { PlatformPath, SageData, SagePath } from 'frontend/features/Analytics/utils/sageData';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { selectState, toggleFakeContent } from 'frontend/state/dux/platform';

import analyticMockData, { type GraphqlData, type GraphqlKeys } from '../utils/analyticMockData';

const PATHS_ELIGIBLE_FOR_FAKE_DATA = [
  'workspace/[0-9]+/analytics/sessions',
  'workspace/[0-9]+/analytics/feedback',
  'workspace/[0-9]+/analytics/handover',
];

type FakeDataHook<T extends SageData | GraphqlData> = {
  fakeData: T | undefined;
  fakeDataEnabled: boolean;
  eligibleForFakeData: boolean;
  toggleFakeData: () => unknown;
};

export default function useFakeData<T extends SageData | GraphqlData>(
  key?: SagePath | GraphqlKeys | PlatformPath,
): FakeDataHook<T> {
  const dispatch = useDispatch();
  const { fakeDataEnabled } = useSelector(selectState) as PlatformState;
  const { pathname: urlPath } = useLocation();
  const { botId } = useParams();

  const { platformPermissions } = useMyPermissions({ botId });
  const canManageProtectedFeatures = platformPermissions.some((permission) =>
    permission.includes('manage_protected_features'),
  );
  const eligibleForFakeData =
    canManageProtectedFeatures && Boolean(PATHS_ELIGIBLE_FOR_FAKE_DATA.find((section) => urlPath.match(section)));

  const toggleFakeData = useCallback(() => dispatch(toggleFakeContent()), [dispatch]);
  const fakeData =
    fakeDataEnabled && eligibleForFakeData && key ? get(analyticMockData, key, () => undefined)?.() : undefined;

  return {
    fakeData: fakeData as T,
    fakeDataEnabled,
    toggleFakeData,
    eligibleForFakeData,
  };
}
