import { gql } from '@apollo/client';

import { sampleCandidateFragment } from '../fragments';

export default gql`
  mutation ArchiveSampleCandidate($botId: ID!, $sampleCandidateId: UUID!) {
    archiveSampleCandidate(botId: $botId, sampleCandidateId: $sampleCandidateId) {
      ...SampleCandidate
    }
  }
  ${sampleCandidateFragment}
`;
