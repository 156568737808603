import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  AllPermissionsDocument,
  type OrganizationRoleType,
  OrganizationSettingsDocument,
} from 'frontend/api/generated';
import { LoaderSwitch, Panel } from 'frontend/components';

import { RoleDetails } from '../../../../components';
import { CUSTOM_ROLE_NAME } from '../../../../constants';

const Roles = () => {
  const { organizationId } = useParams();

  if (!organizationId) {
    throw new Error('Organization ID is required');
  }

  const { data: settingsData, loading: settingsLoading } = useQuery(OrganizationSettingsDocument, {
    variables: { id: organizationId! },
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(AllPermissionsDocument);

  const predefinedRoles = useMemo(
    () =>
      (settingsData?.organization.roles ?? ([] as OrganizationRoleType[])).filter(
        ({ name }) => name !== CUSTOM_ROLE_NAME,
      ),
    [settingsData],
  );
  const allPermissions = useMemo(() => permissionsData?.allPermissions ?? [], [permissionsData]);

  return (
    <Panel>
      <LoaderSwitch loading={settingsLoading || permissionsLoading} size="large">
        <RoleDetails roles={predefinedRoles} allPermissions={allPermissions} />
      </LoaderSwitch>
    </Panel>
  );
};

export default Roles;
