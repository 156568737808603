import { $generateHtmlFromNodes } from '@lexical/html';
import { $isRootTextContentEmpty } from '@lexical/text';
import type { EditorState, LexicalEditor as LexicalEditorType } from 'lexical';
import { Field, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import LexicalEditor from 'frontend/components/LexicalEditor/LexicalEditor';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { getPlainText } from 'frontend/features/Composer';
import { ReplyWithSlots } from 'frontend/features/Slots/components';
import { required } from 'frontend/form/validators';
import { useBotOrSkill } from 'frontend/hooks';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';
import { hasLanguage } from 'frontend/utils';
import hasRule from 'frontend/utils/hasRule';

import styles from './Replies.scss';
import Reply from './Reply';
import SlotsWarning from './SlotsWarning';
import buildStyles from '../../Build.scss';
import type { BuildFormType } from '../../propTypes/BuildFormType';
import getEmptyReply from '../../utils/getEmptyReply';

interface Props {
  args: {
    currentLanguage: string;
    currentRuleId: string | null;
    buildFormType?: BuildFormType;
    isModDialogue?: boolean;
  };
}

/**
 * Lexical adds `<p>` and `<span>` everywhere, we don't want them.
 */
export const cleanLexicalHTML = (htmlString: string) => {
  if ($isRootTextContentEmpty(false)) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const pTags = Array.from(doc.querySelectorAll('p'));
  const spanTags = Array.from(doc.querySelectorAll('span'));

  // Add a <br> before every paragraph that is not just a <br> wrapper (that's how we go to a new line)
  pTags.forEach((p) => {
    if (!(p.childNodes.length === 1 && p.childNodes[0]?.nodeName === 'BR')) {
      const prevSibling = p.previousElementSibling;
      if (prevSibling && prevSibling.tagName.toLowerCase() === 'p') {
        const br = doc.createElement('br');
        p.parentNode?.insertBefore(br, p);
      }
    }
  });

  // Replace all <p> and <span> elements with their inner content
  [...pTags, ...spanTags].forEach((tag) => {
    tag.replaceWith(...tag.childNodes);
  });

  const innerHTML = doc.body.innerHTML;
  return innerHTML;
};

const validateReply = (editorState) => (getPlainText({ editorState }) ? undefined : "This can't be blank");

const Replies = ({ args: { currentLanguage, currentRuleId, buildFormType, isModDialogue } }: Props) => {
  const { isBot } = useBotOrSkill();
  const isFeatureEnabled = useFeatureFlags();
  const { values, submitting: readOnly } = useFormState();
  const form = useForm();

  const buildFormName = buildFormType?.split('-').join(' ');
  const fieldName = isModDialogue ? 'modReplies' : 'replies';
  const replyPlaceholder = `Enter a ${buildFormName}`;
  const headline = buildFormType === 'greeting' ? 'Bot says' : 'Bot replies';
  const isIntentDialogue = values.dialogueType === DIALOGUE_TYPES.INTENT;
  const areSlotsEnabled = isFeatureEnabled('slots');
  const replySlotsEnabled = !isModDialogue && isBot && buildFormType === 'dialogue' && areSlotsEnabled;

  const handleLexicalChange = (name: string) => (edState: EditorState, editor: LexicalEditorType) => {
    form.change(`${name}.text`, cleanLexicalHTML($generateHtmlFromNodes(editor, null)));
    form.change(`${name}.editorState`, edState);
  };

  const render = ({ fields }) => {
    const nReplies = (fields.value || []).filter(hasLanguage(currentLanguage)).filter(hasRule(currentRuleId)).length;
    // Greetings and fallbacks can have a maximum of one reply
    const canAddReply = !['greeting', 'fallback'].includes(buildFormType!) || nReplies === 0;
    const addReply = () =>
      fields.pushFixed(fields.name, getEmptyReply(currentLanguage, currentRuleId, replySlotsEnabled));
    const addReplyKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === 'Enter') addReply();
    };

    return (
      <>
        {fields.map((name, index) => {
          const value = fields.value[index];
          if (!hasLanguage(currentLanguage)(value) || !hasRule(currentRuleId)(value)) {
            return null;
          }

          return (
            <div key={name} className={styles.wrapper}>
              {isIntentDialogue ? (
                <Field name={`${name}.text`} validate={required}>
                  {({ meta }) => (
                    <div className={styles.replyContainer}>
                      <LexicalEditor
                        initialHtml={value.text}
                        handleChange={handleLexicalChange(name)}
                        InputSideComponent={
                          <span className={styles.deleteWrapper}>
                            <Icon
                              component={Trash}
                              onClick={(event) => {
                                fields.remove(index);
                                if (!event) return;

                                event.stopPropagation();
                                event.preventDefault();
                              }}
                              color="default"
                              hoverColor="warning"
                              title="Remove"
                            />
                          </span>
                        }
                        classNames={{ inputWrapper: styles.replyInputWrapper }}
                        meta={meta}
                      />
                    </div>
                  )}
                </Field>
              ) : (
                <Field
                  // @ts-expect-error Convert <ReplyWithSlots> : <Reply> to TS?
                  component={replySlotsEnabled ? ReplyWithSlots : Reply}
                  name={`${name}.${replySlotsEnabled ? 'editorState' : 'text'}`}
                  validate={replySlotsEnabled ? validateReply : required}
                  validateReply={validateReply}
                  readOnly={readOnly}
                  placeholder={replyPlaceholder}
                  currentLanguage={currentLanguage}
                  remove={(event) => {
                    fields.remove(index);
                    if (!event) return;
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                />
              )}
            </div>
          );
        })}

        {canAddReply && (
          <div onClick={addReply} onKeyDown={addReplyKeyDown} tabIndex={0} className={styles.addReply} role="button">
            Add alternate answer
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <h3 className={buildStyles.sectionTitle}>{headline}</h3>
      <SlotsWarning currentLanguage={currentLanguage} />
      <FieldArray name={fieldName} render={render} />
    </>
  );
};

export default Replies;
