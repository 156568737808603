import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { BuildStatisticsDocument } from 'frontend/api/generated';
import { ToggleSwitch } from 'frontend/components';
import { Grid, Heading, StatsPanel } from 'frontend/features/Analytics/components';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { useCurrentLanguage, useToggle } from 'frontend/hooks';

import styles from './BuildStatistics.scss';

const BuildStatistics = () => {
  const { botId, skillId } = useParams();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { loading: botSettingsLoading, botSettings } = useBotSettings(botId);
  const [showActive, toggleShowActive] = useToggle();

  const { data, loading } = useQuery(BuildStatisticsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { ...(botId ? { botId } : { skillId }), languageCode: currentLanguage, showActive },
  });

  const numIntentDialogues = data?.buildStatistics?.numIntentDialogues ?? 0;
  const numSampleDialogues = data?.buildStatistics?.numSampleDialogues ?? 0;
  const numKwDialogues = data?.buildStatistics?.numKwDialogues ?? 0;
  const numPatternDialogues = data?.buildStatistics?.numPatternDialogues ?? 0;
  const numTriggerDialogues = data?.buildStatistics?.numTriggerDialogues ?? 0;
  const numRootDialoguesWithFollowups = data?.buildStatistics?.numRootDialoguesWithFollowups ?? 0;
  const numFollowupDialogues = data?.buildStatistics?.numFollowupDialogues ?? 0;
  const numSamples = data?.buildStatistics?.numSamples ?? 0;
  const numSamplesInclExpanded = data?.buildStatistics?.numSamplesInclExpanded ?? 0;
  const avgSamplesPerDialogue = data?.buildStatistics?.avgSamplesPerDialogue ?? 0;
  const avgSamplesPerDialogueInclExpanded = data?.buildStatistics?.avgSamplesPerDialogueInclExpanded ?? 0;
  const avgWordsPerSample = data?.buildStatistics?.avgWordsPerSample ?? 0;

  return (
    <div className="m-t-3">
      <ToggleSwitch.Switch
        status={['Include all dialogues', 'Include only active dialogues']}
        input={{ value: showActive, name: 'data-to-show' }}
        onClick={toggleShowActive}
      />
      <Grid className="m-t-3" columns={botSettingsLoading || botSettings?.intentMatchEnabled ? 1 : 2}>
        <div>
          <Heading title="Dialogues" />
          <StatsPanel loading={loading}>
            <div className={styles.listContainer}>
              <div className={styles.listElementHeader}>
                <span>Type</span>
                <span>Amount</span>
              </div>

              <div className={styles.listElement}>
                {botSettings?.intentMatchEnabled ? (
                  <>
                    <span>Intent dialogues</span>
                    <span className={styles.amount}>{numIntentDialogues}</span>
                  </>
                ) : (
                  <>
                    <span>Sample dialogues</span>
                    <span className={styles.amount}>{numSampleDialogues}</span>
                  </>
                )}
              </div>

              <div className={styles.listElement}>
                <span>Keyword dialogues</span>
                <span className={styles.amount}>{numKwDialogues}</span>
              </div>
              <div className={styles.listElement}>
                <span>Pattern dialogues</span>
                <span className={styles.amount}>{numPatternDialogues}</span>
              </div>
              <div className={styles.listElement}>
                <span>Trigger dialogues</span>
                <span className={styles.amount}>{numTriggerDialogues}</span>
              </div>
              <div className={styles.listElement}>
                <span>Follow-up dialogues</span>
                <span className={styles.amount}>{numFollowupDialogues}</span>
              </div>
              <div className={styles.listElement}>
                <span>Root dialogues with follow-ups</span>
                <span className={styles.amount}>{numRootDialoguesWithFollowups}</span>
              </div>
            </div>
          </StatsPanel>
        </div>
        {!botSettings?.intentMatchEnabled && (
          <div>
            <Heading title="Samples" />
            <StatsPanel loading={loading}>
              <div className={styles.listContainer}>
                <div className={styles.listElementHeader}>
                  <span>Type</span>
                  <span>Amount</span>
                </div>
                <div className={styles.listElement}>
                  <span>Samples</span>
                  <span className={styles.amount}>{numSamples}</span>
                </div>
                <div className={styles.listElement}>
                  <span>Samples (incl. expanded samples)</span>
                  <span className={styles.amount}>{numSamplesInclExpanded}</span>
                </div>
                <div className={styles.listElement}>
                  <span>Samples per dialogue on average</span>
                  <span className={styles.amount}>{avgSamplesPerDialogue.toFixed(2)}</span>
                </div>
                <div className={styles.listElement}>
                  <span>Samples per dialogue on average (incl. expanded samples)</span>
                  <span className={styles.amount}>{avgSamplesPerDialogueInclExpanded.toFixed(2)}</span>
                </div>
                <div className={styles.listElement}>
                  <span>Words per sample on average</span>
                  <span className={styles.amount}>{avgWordsPerSample.toFixed(2)}</span>
                </div>
              </div>
            </StatsPanel>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default BuildStatistics;
