import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { softDeleteInCache } from 'frontend/api/cacheHelpers';
import { DELETE_USER } from 'frontend/features/Admin/views/Users/modals/DeleteUser/DELETE_USER';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';

import USER_DETAIL_FRAGMENT from '../../fragments/UserDetail.fragment.graphql';

const update = softDeleteInCache({
  fragment: USER_DETAIL_FRAGMENT,
  typeName: 'UserType',
  mutationName: 'deleteUser',
});

const DeleteUser = ({ hide, args: { email, id, onDelete } }) => {
  const toast = useToast();
  const [remove] = useMutation(DELETE_USER, { variables: { id }, update });
  const onSubmit = useCallback(async () => {
    await remove();
    onDelete?.();
    toast.success(`User ${email} was deleted!`);
  }, [email, onDelete, remove, toast]);

  return <DeleteModal name={`user ${email}`} titleName="user" onSubmit={onSubmit} hide={hide} />;
};

DeleteUser.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    id: IDType.isRequired,
    email: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
  }).isRequired,
};

export default DeleteUser;
