import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import {
  CreateCustomNudgeDocument,
  CreateFormNudgeDocument,
  CreateMultipleChoiceNudgeDocument,
  CreateProductNudgeDocument,
  CreateTextNudgeDocument,
  GetAllNudgesDocument,
} from 'frontend/api/generated';
import { getImageCarouselsUpdate } from 'frontend/features/Build/utils';
import { useBotLanguages, useCurrentLanguage, useToast } from 'frontend/hooks';
import { triggerNudge } from 'frontend/state/dux/chat';

import { nudgeType } from '../constants';
import { handleCarouselTitle } from '../utils';

export default (defaultBotId) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { languages } = useBotLanguages();
  let { botId } = useParams();
  if (!botId && defaultBotId) botId = defaultBotId;
  const { nudgeHaveBeenCalled } = useSelector(({ chat }) => chat);

  const { data: nudgesData, loading: loadingNudges } = useQuery(GetAllNudgesDocument, { variables: { botId } });

  const update = useCallback(
    async (cache, { data }) => {
      const nudge =
        data?.createFormNudge ??
        data?.createProductNudge ??
        data?.createTextNudge ??
        data?.createCustomNudge ??
        data?.createMultipleChoiceNudge;

      await appendArrayInCache({
        variables: { botId },
        pathToItemInMutationData: `create${nudge.type}Nudge`,
        pathToArrayInCache: 'allNudges',
        query: GetAllNudgesDocument,
      })(cache, { data });

      navigate(`/workspace/${botId}/build/proactivity/nudge/${nudge.slug}`);
      toast.success(`${nudge.type} nudge was created`);
    },
    [botId, navigate, toast],
  );

  const [createFormNudge] = useMutation(CreateFormNudgeDocument, { update });
  const [createProductNudge] = useMutation(CreateProductNudgeDocument, { update });
  const [createTextNudge] = useMutation(CreateTextNudgeDocument, { update });
  const [createCustomNudge] = useMutation(CreateCustomNudgeDocument, { update });
  const [createMultipleChoiceNudge] = useMutation(CreateMultipleChoiceNudgeDocument, { update });

  const nudges = useMemo(() => nudgesData?.allNudges ?? [], [nudgesData]);

  const previewNudge = useCallback(
    (slug) => {
      dispatch(triggerNudge(!nudgeHaveBeenCalled, slug));
    },
    [dispatch, nudgeHaveBeenCalled],
  );

  const onFormCreate = useCallback(
    async (values) => {
      const variables = {
        botId,
        formInput: {
          type: nudgeType.FORM,
          ...values,
        },
      };

      await createFormNudge({ variables });
    },
    [botId, createFormNudge],
  );

  const onProductCreate = useCallback(
    async (values, { getState }) => {
      const { initialValues } = getState();
      const imageCarouselUpdate = getImageCarouselsUpdate(
        handleCarouselTitle(currentLanguage, initialValues),
        handleCarouselTitle(currentLanguage, values),
        { isNudgeCarousel: true },
      );

      const variables = {
        botId,
        productInput: {
          type: nudgeType.PRODUCT,
          ...omit(values, ['imageCarousel', 'imageCarousels', 'carouselTitle']),
          ...imageCarouselUpdate,
        },
      };

      await createProductNudge({ variables });
    },
    [botId, createProductNudge, currentLanguage],
  );

  const onTextCreate = useCallback(
    async (values) => {
      const variables = {
        botId,
        textInput: {
          type: nudgeType.TEXT,
          ...values,
        },
      };

      await createTextNudge({ variables });
    },
    [botId, createTextNudge],
  );

  const onCustomCreate = useCallback(
    async (values) => {
      const variables = {
        botId,
        textInput: {
          type: nudgeType.CUSTOM,
          ...values,
        },
      };

      await createCustomNudge({ variables });
    },
    [botId, createCustomNudge],
  );

  const onMultipleChoiceCreate = useCallback(
    async (values) => {
      const variables = {
        botId,
        multipleChoiceNudgeInput: {
          type: nudgeType.MULTIPLE_CHOICE,
          ...values,
        },
      };

      await createMultipleChoiceNudge({ variables });
    },
    [botId, createMultipleChoiceNudge],
  );

  return {
    botId,
    nudges,
    loadingNudges,
    languages,
    currentLanguage,
    onFormCreate,
    onProductCreate,
    onTextCreate,
    previewNudge,
    onCustomCreate,
    onMultipleChoiceCreate,
  };
};
