import { initMessageListener } from 'redux-state-sync';

import configureStore from './redux/configureStore';

const rootStore = configureStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof rootStore.dispatch;

initMessageListener(rootStore);

export default rootStore;
