import { gql } from '@apollo/client';

export default gql`
  query IncompleteDialogues($botId: ID!, $languageCode: String!, $fromDate: DateTime, $toDate: DateTime) {
    incompleteDialogues(
      botId: $botId
      languageCode: $languageCode

      fromDate: $fromDate
      toDate: $toDate
    ) {
      id
      title
      samplesCount
      colorLabel
    }
  }
`;
