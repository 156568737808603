import cx from 'classnames';

import styles from './ChatButton.scss';
import chatGroupStyles from '../ChatGroup/ChatGroup.scss';

interface Props {
  buttonType?: string;
  label: string;
  contactDetails?: { edit_text: string };
  title: string;
  isButtonClick?: boolean;
}
const ChatButton = ({ label, buttonType, contactDetails, title, isButtonClick = false }: Props) => {
  const buttonLabel = buttonType === 'edit_contact_details' ? contactDetails?.edit_text : label;
  const className = cx(styles.button, chatGroupStyles.chatGroupButton, {
    [styles.buttonClick]: isButtonClick,
    [chatGroupStyles.chatGroupButtonClick]: isButtonClick,
  });

  return (
    <div className={className} title={title}>
      {buttonLabel}
    </div>
  );
};

export default ChatButton;
