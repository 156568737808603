import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Checkbox } from 'frontend/components';

import styles from '../../Privacy.scss';

const AnonymizationRow = ({ input }) => (
  <div className={styles.anonymizationRow}>
    <Field component={Checkbox} type="checkbox" name={`${input.name}.isActive`} label={input.value.label} />
  </div>
);

AnonymizationRow.propTypes = {
  input: PropTypes.shape({ value: PropTypes.shape({}) }).isRequired,
};

export default AnonymizationRow;
