import { useMemo } from 'react';

import type { ButtonType } from 'frontend/api/generated';
import { hasSameId } from 'frontend/features/Build/utils';

import Label from './Label';
import PercentageBar from './PercentageBar';

export const getButtonLabel = (buttons: Partial<ButtonType>[] = [], buttonId = ''): string =>
  buttons.find(hasSameId({ id: buttonId }))?.label ?? '';

type Props = {
  buttons: Partial<ButtonType>[];
  buttonId: string;
  count: number;
  ratio: number;
};
const OverviewItem = ({ buttons, buttonId, count, ratio }: Props) => {
  const label = getButtonLabel(buttons, buttonId);
  const percentageData = useMemo(() => ({ count, ratio }), [count, ratio]);
  const labelData = useMemo(() => ({ label }), [label]);

  if (!label) return null;

  return (
    <>
      <PercentageBar data={percentageData} />
      <Label data={labelData} />
    </>
  );
};

export default OverviewItem;
