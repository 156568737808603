import cx from 'classnames';
import { useForm, useFormState } from 'react-final-form';

import { AlignArrowDown, AlignArrowUp, ArrowDown, ArrowUp, DotsVertical, Edit, Trash } from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';
import { useSortableItem } from 'frontend/features/Dnd/hooks';

import styles from './RuleItem.scss';

interface Props {
  id: string;
  name: string;
  ruleIndex: number;
  onConditionClick: (conditionId: string | null) => void;
  showRuleModal: (callArgs?: any) => void;
  close: () => void;
  className?: string;
}

export default function RuleItem({ id, name, ruleIndex, onConditionClick, showRuleModal, close, className }: Props) {
  const form = useForm();
  const { values } = useFormState();
  const { style, dragAttributes, onPointerDown, setNodeRef } = useSortableItem({ id });

  const moveRule = (index: number, direction: 'up' | 'down' | 'top' | 'bottom'): void => {
    const newList = structuredClone(values.dialogueRules);
    const newIndex = {
      up: index - 1,
      down: index + 1,
      top: 0,
      bottom: newList.length - 1,
    }[direction];

    const [removed] = newList.splice(index, 1);
    newList.splice(newIndex, 0, removed);
    form.change(`dialogueRules`, newList);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      key={id}
      className={cx(styles.ruleListItem, className)}
      onClick={() => onConditionClick(id)}
      onKeyDown={() => onConditionClick(id)}
      onPointerDown={onPointerDown}
      ref={setNodeRef}
      style={style}
      {...dragAttributes}
    >
      <div className={styles.listItemNumber}>{ruleIndex + 1}</div>
      <div className={styles.ruleName}>{name}</div>

      <Dropdown
        position="right"
        stopPropagation
        outsideClick="pointerdown"
        wrapperClassName={styles.settings}
        overlayClassName={styles.settingsOverlay}
        overlay={
          <Dropdown.MenuOverlay
            options={[
              {
                icon: Edit,
                text: 'Edit rule',
                onClick: () => {
                  showRuleModal({ id, modalType: 'edit' });
                  close();
                },
              },
              {
                icon: AlignArrowUp,
                text: 'Move to top',
                onClick: (event) => {
                  event.stopPropagation();
                  moveRule(ruleIndex, 'top');
                },
              },
              {
                icon: ArrowUp,
                text: 'Move up',
                onClick: (event) => {
                  event.stopPropagation();
                  moveRule(ruleIndex, 'up');
                },
              },
              {
                icon: ArrowDown,
                text: 'Move down',
                onClick: (event) => {
                  event.stopPropagation();
                  moveRule(ruleIndex, 'down');
                },
              },
              {
                icon: AlignArrowDown,
                text: 'Move to bottom',
                onClick: (event) => {
                  event.stopPropagation();
                  moveRule(ruleIndex, 'bottom');
                },
              },
              'separator',
              {
                icon: Trash,
                text: 'Delete rule',
                color: 'red',
                onClick: (event) => {
                  event.stopPropagation();
                  const newList = [...values.dialogueRules];
                  const ruleIndexToDelete = newList.findIndex((rule) => rule.id === id);
                  if (ruleIndexToDelete !== -1) {
                    newList.splice(ruleIndexToDelete, 1);
                    form.change(`dialogueRules`, newList);
                  }
                },
              },
            ]}
          />
        }
      >
        <Icon component={DotsVertical} />
      </Dropdown>
    </div>
  );
}
