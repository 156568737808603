import { isEqual } from 'lodash';
import { Field, type FormRenderProps } from 'react-final-form';

import type { LanguageVariantType } from 'frontend/api/generated';
import { FormErrors, GuardUnsaved, Input, PageBar, Panel, Table } from 'frontend/components';
import type { Column } from 'frontend/components/Table/Table';
import { required } from 'frontend/form/validators';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import AddLanguageButton from './AddLanguageButton';
import type { LanguageAppended, Values, VariantAppended } from './BotLanguagesController';
import styles from './BotLanguagesView.scss';
import LanguageCell from './Table/LanguageCell';
import VariantCell from './Table/VariantCell';
import rowStyles from './Table/table.scss';
import useLanguageManager from './hooks/useLanguageManager';

interface Props {
  handleSubmit: FormRenderProps<Values>['handleSubmit'];
  values: Values;
}

export default function BotLanguagesView({ handleSubmit, values }: Props): JSX.Element {
  const { languages, botLanguageVariants, botActiveLanguageVariants, primaryLanguage } = values;

  const hasBotLanguageVariants = botLanguageVariants.size > 0;

  const { isKindlyAdminManager } = useMyPermissions();

  const {
    enableLanguage,
    disableLanguage,
    removeLanguage,
    addLanguageVariant,
    enableVariant,
    disableVariant,
    removeVariant,
    makePrimaryLanguage,
  } = useLanguageManager({
    languages,
    botLanguageVariants,
    botActiveLanguageVariants,
  });

  const appendActiveToVariants = (variants: LanguageVariantType[]): VariantAppended[] =>
    variants.map((variant: LanguageVariantType): VariantAppended => {
      const isActive = botActiveLanguageVariants.has(variant.code);
      return { ...variant, active: isActive };
    });

  const tableColumns = [
    { key: 'language', component: LanguageCell },
    ...(hasBotLanguageVariants ? [{ key: 'variant', component: VariantCell }] : []),
  ] as Column[];

  const sharedTableData = {
    addLanguageVariant,
    enableLanguage,
    disableLanguage,
    removeLanguage,
    enableVariant,
    disableVariant,
    removeVariant,
    hasBotLanguageVariants,
    isKindlyManager: isKindlyAdminManager,
    makePrimaryLanguage,
    primaryLanguage,
  } as const;

  const tableData = languages.flatMap((language) => {
    const finalTableData: Array<{
      language: Partial<LanguageAppended>;
      variant?: Partial<VariantAppended>;
      shared: typeof sharedTableData;
    }> = [];
    const languageVariantsAppended = appendActiveToVariants(language.variants);

    /* Table in no-variant mode. */
    if (!hasBotLanguageVariants) {
      finalTableData.push({
        language: {
          name: language.name,
          code: language.code,
          active: language.active,
          variants: languageVariantsAppended,
        },
        shared: sharedTableData,
      });
    } else {
      /* Table in variant mode. */
      const variants = language.variants.filter((variant) => botLanguageVariants.has(variant.code));

      const numVariants = variants.length;
      const numActiveVariants = variants.filter((variant) => botActiveLanguageVariants.has(variant.code)).length;
      const hasVariants = numVariants > 0;
      const hasActiveVariants = numActiveVariants > 0;

      finalTableData.push({
        language: {
          name: language.name,
          code: language.code,
          active: language.active,
          variants: languageVariantsAppended,
        },
        variant: hasVariants
          ? {
              text: `${language.active ? numActiveVariants : '0'}/${numVariants} active`,
              active: hasActiveVariants,
              code: '',
              name: '',
            }
          : { text: `-`, active: true, code: '', name: '' },
        shared: sharedTableData,
      });

      if (hasVariants) {
        variants.forEach((variant) => {
          const isVariantActive = botActiveLanguageVariants.has(variant.code);

          finalTableData.push({
            language: {
              text: '',
              name: language.name,
              code: language.code,
              active: language.active,
              variants: languageVariantsAppended,
            },
            variant: {
              name: variant.name,
              code: variant.code,
              active: isVariantActive,
            },
            shared: sharedTableData,
          });
        });
      }
    }

    return finalTableData;
  });

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <PageBar>
        <PageBar.FormButtons />
      </PageBar>

      <GuardUnsaved />

      <Panel>
        <FormErrors />

        <h3 className={`${styles.sectionTitle} m-b-3`}>Bot languages</h3>
        <p>
          Add a language to your bot, or manage existing languages.
          <br />
          Disabled languages are still editable in Build.
        </p>
        <AddLanguageButton />
        <Table columns={tableColumns} data={tableData} className="m-t-2" rowClassName={rowStyles.rowClassName} nested />
        {/* Make sure field is registered: https://github.com/final-form/final-form/issues/169#issuecomment-430939734 */}
        <Field name="languages" component={() => null} isEqual={isEqual} />
        <Field name="botLanguageVariants" component={() => null} isEqual={isEqual} />
        <Field name="botActiveLanguageVariants" component={() => null} isEqual={isEqual} />
        <Field name="primaryLanguage" component={() => null} isEqual={isEqual} />
        <Panel.Separator />
        <div className={styles.promptWrapper}>
          <h3 className={`${styles.sectionTitle} m-b-3`}>Language prompt</h3>
          <p>To get language preferences from a new user the bot will start a conversation by asking:</p>
          <Field
            component={Input}
            name="selectLanguageGreeting"
            placeholder="Eg. Please select your language"
            validate={required}
            aria-label="Select language greeting"
          />
        </div>
      </Panel>
    </form>
  );
}
