import { Fragment } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useLocation, useParams } from 'react-router-dom';

import { Accordion, Button, InfoButton, Input, Select } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { DEFAULT_RULE_ID, URL_DIALOGUE_TYPES } from 'frontend/features/Build/constants';
import type { BuildFormType } from 'frontend/features/Build/propTypes/BuildFormType';
import { useModal } from 'frontend/features/Modals';
import { url } from 'frontend/form/validators';

import styles from './AdvancedFeatures.scss';
import ContextRow from './ContextRow';
import FeatureContents from './FeatureContents';
import useCurrentRuleId from '../../hooks/useCurrentRuleId';
import AboutUrlTrigger from '../../modals/AboutUrlTrigger';
import Settings from '../Settings';

interface Props {
  args: {
    dialogueId?: string;
    isModDialogue: boolean;
    currentLanguage: string;
    dialogueType?: string;
    buildFormType: BuildFormType;
  };
}

const AdvancedFeatures = ({ args: { isModDialogue, currentLanguage, dialogueId, buildFormType } }: Props) => {
  const { pathname } = useLocation();
  const { skillId } = useParams<{ skillId?: string }>();
  const [showAboutTriggerModal] = useModal(AboutUrlTrigger);

  const ruleId = useCurrentRuleId() || DEFAULT_RULE_ID;
  const isGreeting = buildFormType === 'greeting' || pathname.includes('greeting/new');
  const isNotSkill = !skillId;

  const {
    values: {
      webhookUrls,
      chatbubbleHideInputField,
      pluginTemp,
      modWebhookUrls,
      plugininstance,
      context,
      handoverRequestWhenTriggered,
    },
  } = useFormState();

  const {
    mutators: { push },
  } = useForm();

  return (
    <>
      <Accordion
        title="Webhook & Plugin"
        isOpen={Boolean(webhookUrls || modWebhookUrls || plugininstance)}
        className="m-t-3"
      >
        <>
          <FeatureContents
            webhookUrls={webhookUrls || modWebhookUrls}
            pluginInstance={plugininstance}
            pluginTemp={pluginTemp}
            isModDialogue={isModDialogue}
            buildFormType={buildFormType}
            dialogueId={dialogueId}
          />

          {isGreeting && (
            <div className="m-b-2">
              <div className={styles.titleWrapper}>
                <label className="m-b-0" htmlFor={`urlTrigger.${currentLanguage}`}>
                  Trigger on URL
                </label>
                <InfoButton className={styles.infoButton} onClick={showAboutTriggerModal} />
              </div>
              <Field
                className={styles.urlTriggerSelection}
                component={Select}
                name={`urlTriggerType.${currentLanguage}`}
                fieldColor={FIELD_COLOR.MISCHKA}
              >
                <Select.Option label={URL_DIALOGUE_TYPES.URL_PATH_MATCH} value={URL_DIALOGUE_TYPES.URL_PATH_MATCH} />
                <Select.Option label={URL_DIALOGUE_TYPES.EXACT_MATCH} value={URL_DIALOGUE_TYPES.EXACT_MATCH} />
              </Field>
              <div className="m-t-2" />
              <Field
                component={Input}
                name={`urlTrigger.${currentLanguage}`}
                aria-label="Trigger on URL"
                validate={url}
                fieldColor={FIELD_COLOR.MISCHKA}
              />
            </div>
          )}
        </>
      </Accordion>

      {isNotSkill && (
        <>
          <hr className={styles.separator} />

          <Accordion title="Context fields" isOpen bodyClassName={styles.contextAccordionBody} className="m-bl-3">
            <p>
              Here you can establish which context fields will be set when this dialogue gets triggered. These fields
              will override any identical fields that already exist within the chat context.
            </p>
            <Button
              type="button"
              onClick={() => push?.(`context.${currentLanguage}.${ruleId}`, undefined)}
              className={styles.addContextButton}
            >
              Add context field
            </Button>

            <div className={styles.contextGrid}>
              {context?.length > 0 && (
                <>
                  <span className={styles.keyLabel}>Key</span>
                  <span className={styles.valueLabel}>Value</span>
                </>
              )}
              <FieldArray name={`context.${currentLanguage}.${ruleId}`}>
                {({ fields }) =>
                  fields.map((name, index) => (
                    <Fragment key={name}>
                      <Field
                        name={name}
                        component={ContextRow}
                        validate={(e) => (!e?.split('.')?.[0].trim().length ? "This can't be blank" : undefined)}
                      />
                      <button type="button" onClick={() => fields.remove(index)} className={styles.deleteContextButton}>
                        ❌
                      </button>
                    </Fragment>
                  ))
                }
              </FieldArray>
            </div>
          </Accordion>
        </>
      )}

      <hr className={styles.separator} />

      <Accordion title="Settings" className="m-t-3" isOpen={chatbubbleHideInputField || handoverRequestWhenTriggered}>
        <Settings isModDialogue={isModDialogue} currentLanguage={currentLanguage} />
      </Accordion>
    </>
  );
};

export default AdvancedFeatures;
