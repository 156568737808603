import type { DialogueType } from 'frontend/api/generated';
import { DEFAULT_RULE_ID } from 'frontend/features/Build/constants';

export default (currentLanguage, dialoguesWithImages: DialogueType[] = []) =>
  dialoguesWithImages.flatMap((dialogue: DialogueType) =>
    dialogue.imageCarousels
      .filter((carousel) => carousel.languageCode === currentLanguage)
      .flatMap((carousel) => {
        const imagesGroupedByRuleId = carousel.images.reduce((accumulator, image) => {
          const ruleId = carousel.rule?.id || DEFAULT_RULE_ID;
          accumulator[ruleId] = accumulator[ruleId] || [];
          accumulator[ruleId].push(image.imageUrl);
          return accumulator;
        }, {});

        const getSizeClass = (size) => {
          switch (size) {
            case 600:
              return 'S';
            case 800:
              return 'M';
            case 1000:
              return 'L';
            default:
              return '-';
          }
        };

        return Object.entries(imagesGroupedByRuleId).map(([ruleId, images]) => ({
          rule: {
            name: carousel.rule?.name ?? 'Default rule',
            id: ruleId,
            dialogueType: dialogue.dialogueType,
            dialogueID: dialogue.id,
          },
          size: getSizeClass(carousel.size),
          images,
          dialogue: {
            ruleID: ruleId,
            id: dialogue.id,
            title: dialogue.title[currentLanguage] ?? dialogue.title.default,
            dialogueType: dialogue.dialogueType,
            isActive: dialogue.isActive[currentLanguage],
            colorLabel: dialogue.colorLabel[currentLanguage],
          },
          count: carousel.images.length.toString(),
          key: `images-dialogue-${dialogue.id}`,
        }));
      }),
  );
