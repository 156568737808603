import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { DIALOGUE_TYPES, buildTypes } from 'frontend/constants';
import { useUploadCarouselImages } from 'frontend/hooks';
import { getUploadPath } from 'frontend/hooks/useUploadCarouselImages';
import { BuildIdObjectType, IDType } from 'frontend/propTypes';
import { finalFormArraysMutatorsFix } from 'frontend/utils';

import DialogueFormContents from './DialogueFormContents';
import { usePluginInstance, useValidateDialogue } from '../../hooks';
import { InitialValuesType } from '../../propTypes';

const SUBSCRIPTION = {};

const mutators = finalFormArraysMutatorsFix(arrayMutators);

const identical = (a, b) => a === b;

const DialogueForm = ({
  onSubmit,
  initialValues,
  selectedLanguage,
  currentRuleId,
  parentId,
  buildIdObject,
  buildType,
  buildId,
  hasChanges,
  setHasChanges,
  allowRedirect = false,
  isModDialogue = false,
}) => {
  const dialogueId = initialValues.id;
  const isCreateForm = !dialogueId;
  const isIntentDialogue = initialValues.dialogueType === DIALOGUE_TYPES.INTENT;

  const validate = useValidateDialogue({
    buildIdObject,
    dialogueId,
    parentId,
    isModDialogue,
    isIntentDialogue,
  });

  const onSubmitWithPlugin = usePluginInstance(onSubmit);
  const onSubmitWithUpload = useUploadCarouselImages(onSubmitWithPlugin, {
    isModDialogue,
    uploadPath: getUploadPath(buildType, buildId),
  });

  return (
    <Form
      onSubmit={onSubmitWithUpload}
      mutators={mutators}
      initialValues={initialValues}
      initialValuesEqual={identical}
      validate={validate}
      subscription={SUBSCRIPTION}
      component={DialogueFormContents}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      allowRedirect={allowRedirect}
      isModDialogue={isModDialogue}
      isCreateForm={isCreateForm}
      selectedLanguage={selectedLanguage}
      currentRuleId={currentRuleId}
      dialogueId={dialogueId}
      parentId={parentId}
      buildIdObject={buildIdObject}
    />
  );
};

DialogueForm.propTypes = {
  initialValues: InitialValuesType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isModDialogue: PropTypes.bool,
  selectedLanguage: PropTypes.string.isRequired,
  currentRuleId: PropTypes.string,
  allowRedirect: PropTypes.bool,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buildIdObject: BuildIdObjectType.isRequired,
  buildId: IDType.isRequired,
  buildType: PropTypes.oneOf(Object.values(buildTypes)).isRequired,
  hasChanges: PropTypes.bool.isRequired,
  setHasChanges: PropTypes.func.isRequired,
};

export default DialogueForm;
