import type { ApolloClient } from '@apollo/client';

import { updateArrayInCache } from 'frontend/api/cacheHelpers';
import { type DialogueType, LibraryDialoguesDocument } from 'frontend/api/generated';

type Props = {
  variables: Record<string, unknown>;
  client: ApolloClient<object>;
} & (
  | {
      addedDialogue: DialogueType;
      removedDialogue?: never;
    }
  | {
      addedDialogue?: never;
      removedDialogue: DialogueType;
    }
);
export default ({ variables, client, removedDialogue, addedDialogue }: Props) =>
  updateArrayInCache({
    variables,
    pathToArrayInCache: 'dialogues',
    query: LibraryDialoguesDocument,
    cache: client,
    arrayUpdate: (dialogues: DialogueType[]) =>
      addedDialogue
        ? [...dialogues, addedDialogue]
        : dialogues.filter(({ id: dialogueId }) => dialogueId !== removedDialogue.id),
  });
