/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { set } from 'lodash';

interface BackendBuildItem {
  [key: string]: {
    [key: string]: {
      [key: string]: string | boolean;
    };
  };
}

interface FrontendBuildItemNested {
  [key: string]: {
    [key: string]: {
      [key: string]: string[] | string | boolean;
    };
  };
}

interface FrontendBuildItemFlat {
  [key: string]: {
    [key: string]: string[] | boolean;
  };
}

type FrontendBuildItem = FrontendBuildItemNested | FrontendBuildItemFlat;

export function prepareDialogueBuildItemForBE(buildItem: FrontendBuildItem = {}): BackendBuildItem {
  if (Object.keys(buildItem).length === 0) {
    return {};
  }

  const result = Object.entries(buildItem).reduce((acc, [lang, rules]) => {
    if (Object.keys(rules).length === 0) {
      return acc;
    }
    for (const [rule, options] of Object.entries(rules)) {
      if (Array.isArray(options)) {
        const newOptions = options.reduce((obj, option) => {
          const parts = option.split('.');
          const key = parts[0];
          const value = parts.slice(1).join('.');

          if (key && value) {
            obj[key] = value;
          }
          return obj;
        }, {});

        set(acc, `${lang}.${rule}`, newOptions);
      } else if (typeof options === 'object') {
        set(acc, `${lang}.${rule}`, options);
      }
    }

    return acc;
  }, {});

  return result;
}

/** This utility prepares the dialogue context from the backend for the frontend Form.
 * @example
 * prepareDialogueContextForFE({
 *  en: {
 *    defaultRule: {
 *      contextKey: 'contextValue',
 *      contextKey2: 'contextValue2'
 *    },
 *    rule1: {
 *      contextKey3: 'contextValue3',
 *      contextKey4: 'contextValue4'
 *    }
 *  }
 * })
 * // =>
 * {
 *  en: {
 *    defaultRule: ['contextKey.contextValue', 'contextKey2.contextValue2']
 *    rule1: ['contextKey3.contextValue3', 'contextKey4.contextValue4']
 *  }
 * }
 */
export function prepareDialogueContextForFE(context: BackendBuildItem = {}): FrontendBuildItem {
  if (Object.keys(context).length === 0) {
    return {};
  }

  const result = Object.entries(context).reduce((acc, [lang, rules]) => {
    if (Object.keys(rules).length === 0) {
      return acc;
    }

    for (const [rule, values] of Object.entries(rules)) {
      if (Object.keys(values).length === 0) {
        set(acc, `${lang}.${rule}`, []);
        continue;
      }

      for (const [contextKey, contextValue] of Object.entries(values)) {
        const combinedValue = `${contextKey}.${contextValue}`;
        set(acc, `${lang}.${rule}`, (acc[lang]?.[rule] || []).concat(combinedValue));
      }
    }

    return acc;
  }, {});

  return result;
}
