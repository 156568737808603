import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { BotIndicesDocument, PublishBotDocument } from 'frontend/api/generated';
import { Button } from 'frontend/components';
import { PublishBot } from 'frontend/features/Build/modals';
import { hasUnpublishedChanges as hasUnpublishedChangesQuery } from 'frontend/features/Library/queries';
import { useModal } from 'frontend/features/Modals/hooks';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useToast } from 'frontend/hooks';

import styles from './AddDialoguesButton.scss';

const PublishChangesButton = () => {
  const [isPublishPending, setIsPublishPending] = useState(false);

  const toast = useToast();
  const { botId } = useParams();
  const client = useApolloClient();
  const { data } = useQuery(hasUnpublishedChangesQuery, { variables: { botId } });
  const hasUnpublishedChanges = data?.hasUnpublishedChanges ?? false;
  const [showPublishModal, hideModal] = useModal(PublishBot);

  useQuery(BotIndicesDocument, {
    variables: { botId: String(botId) },
    fetchPolicy: 'network-only',
    onCompleted(queryData) {
      const isPublishIndexPending = queryData.publishedIndices?.[0]?.state === 'PENDING';
      setIsPublishPending(isPublishIndexPending);
    },
  });

  const handlePublishBotStarted = () => {
    setIsPublishPending(true);
  };

  const handlePublishBotCompleted = () => {
    setIsPublishPending(false);
  };

  const updateHasUnPublishedChanges = (newHasUnPublishedChanges) => {
    client.writeQuery({
      query: hasUnpublishedChangesQuery,
      variables: { botId },
      data: { hasUnpublishedChanges: Boolean(newHasUnPublishedChanges.status) },
    });
  };

  const [publishBot] = useMutation(PublishBotDocument, {
    variables: {
      botId: botId!,
    },
    update: () => {
      hideModal();
      updateHasUnPublishedChanges({ status: false });
      toast.success('Changes are being published');
    },
  });

  const onClickModal = () => {
    showPublishModal({ onSubmit: publishBot, botId });
  };

  usePusherEvent(getPusherChannel({ botId }), 'has_unpublished_changes', updateHasUnPublishedChanges);
  usePusherEvent(getPusherChannel({ botId }), 'publish-bot-started', handlePublishBotStarted);
  usePusherEvent(getPusherChannel({ botId }), 'publish-bot-completed', handlePublishBotCompleted);

  return (
    <Button
      className={styles.publishChangesButton}
      color="white"
      width="full"
      text={isPublishPending ? 'Publishing changes...' : 'Publish changes'}
      onClick={onClickModal}
      disabled={!hasUnpublishedChanges || isPublishPending}
    />
  );
};
export default PublishChangesButton;
