import { format } from 'date-fns';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Modal } from 'frontend/features/Modals';
import { IDType } from 'frontend/propTypes';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import styles from './DuplicateMessageChats.scss';
import { MessageRecordType } from '../../propTypes';

const DuplicateMessageChats = ({ args, hide }) => {
  const { message, botId } = args;
  const { message: userMessage, duplicates } = message;
  const nDuplicates = duplicates.length;

  return (
    <Modal title={`"${truncate(userMessage)}" duplicates`} footer={false} hide={hide}>
      <Modal.Content>
        <span>
          The message {`"${userMessage}"`} also occurred in the following{' '}
          {nDuplicates > 1 ? `${nDuplicates} chats` : 'chat'}:
        </span>
        <div className={styles.linkList}>
          {duplicates.map(({ created, chatId, id }, index) => (
            <Link key={`message-duplicate-${id}`} to={`/workspace/${botId}/inbox/chat/${chatId}#${id}`}>
              {index + 1}:{'  '}
              {format(created, DATE_FORMAT_WITH_TIME)}
            </Link>
          ))}
        </div>
      </Modal.Content>
    </Modal>
  );
};

DuplicateMessageChats.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ botId: IDType.isRequired, message: MessageRecordType.isRequired }).isRequired,
};

export default DuplicateMessageChats;
