import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { DeleteModal } from 'frontend/features/Modals';

import { removeDialogueMods } from '../../cacheHelpers';
import { RESET_SUBSCRIPTION } from '../../mutations';

const ResetSubscription = ({ hide, args: { botId, skillId } }) => {
  const [mutate] = useMutation(RESET_SUBSCRIPTION, { update: removeDialogueMods });

  const onSubmit = useCallback(async () => {
    await mutate({ variables: { botId, skillId } });
  }, [botId, mutate, skillId]);

  return (
    <DeleteModal onSubmit={onSubmit} hide={hide} action="reset" name="skill">
      Are you sure you want to reset this skill? All changes made to the skill in this bot will be lost.
    </DeleteModal>
  );
};

ResetSubscription.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    botId: PropTypes.string.isRequired,
    skillId: PropTypes.string.isRequired,
  }).isRequired,
};

export default ResetSubscription;
