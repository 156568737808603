import arrayMutators from 'final-form-arrays';
import { isEqual, noop } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import { hasSameId } from 'frontend/features/Build/utils';
import {
  arrayToBoolObject,
  boolObjectToArray,
  sampleIsLegal,
  searchAndReplaceValidator,
} from 'frontend/features/Entities/modals/EntitySearchAndReplace/helpers';
import { ModalForm } from 'frontend/features/Modals';
import { SampleType } from 'frontend/features/Samples/propTypes';
import { BuildIdObjectType } from 'frontend/propTypes';

import ChooseForm from './ChooseForm';
import PreviewForm from './PreviewForm';
import { replaceText } from './helpers';

const SearchAndReplace = ({ hide, args }) => {
  const { setSamplesInLanguage, samples } = args;

  const [oldWord, setOldWord] = useState();
  const [newWord, setNewWord] = useState();
  const isChoosing = !oldWord && !newWord;

  const updatedSamples = useMemo(
    () => (isChoosing ? [] : replaceText(oldWord, newWord, samples)),
    [oldWord, newWord, isChoosing, samples],
  );

  const onSubmit = useCallback(
    ({ replaceWord, removeWord, includedSamples }) => {
      if (isChoosing) {
        setOldWord(removeWord);
        setNewWord(replaceWord);
        return;
      }

      const includedSampleIds = boolObjectToArray(includedSamples);
      const sampleList = samples.map((sample) => {
        if (!includedSampleIds.includes(sample.id || sample.tempId)) {
          return sample;
        }
        return updatedSamples.find((updated) => hasSameId(sample, updated));
      });
      setSamplesInLanguage(sampleList);
    },
    [isChoosing, samples, setSamplesInLanguage, updatedSamples],
  );

  const renderForm = useCallback(
    ({ handleSubmit }) =>
      isChoosing ? (
        <ChooseForm handleSubmit={handleSubmit} />
      ) : (
        <PreviewForm updatedSamples={updatedSamples} oldWord={oldWord} />
      ),
    [oldWord, isChoosing, updatedSamples],
  );

  const initialValues = useMemo(() => {
    if (isChoosing) {
      return { removeWord: '', replaceWord: '' };
    }
    const includedSamples = arrayToBoolObject(
      samples.filter((existing) => sampleIsLegal(updatedSamples.find((updated) => hasSameId(existing, updated)))),
    );
    return { includedSamples };
  }, [isChoosing, samples, updatedSamples]);

  const noSamplesFound = updatedSamples.length === 0;
  const title = 'Replace words in Samples';
  const onOkText = isChoosing ? 'Preview' : (noSamplesFound && 'Ok') || 'Replace';
  const validate = isChoosing || noSamplesFound ? noop : searchAndReplaceValidator;

  return (
    <ModalForm
      title={title}
      onOkText={onOkText}
      render={renderForm}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      hide={hide}
      closeOnSubmit={!isChoosing}
      validate={validate}
      ignoreDirty={!isChoosing}
    />
  );
};

SearchAndReplace.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    setSamplesInLanguage: PropTypes.func.isRequired,
    samples: PropTypes.arrayOf(SampleType).isRequired,
    buildIdObject: BuildIdObjectType.isRequired,
  }).isRequired,
};

export default SearchAndReplace;
