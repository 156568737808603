import { gql } from '@apollo/client';

export default gql`
  query DialogueCanBeDisabled($id: UUID!, $botId: ID, $skillId: ID, $languageCode: String!) {
    dialogue(id: $id, botId: $botId, skillId: $skillId) {
      id
      canBeDisabled(languageCode: $languageCode)
    }
  }
`;
