import { defineAction } from 'redux-define';

import { getBotJWToken } from './auth';

const ENGAGEMENT = defineAction('engagement');

const SET_CAMPAIGNS = ENGAGEMENT.defineAction('SET_CAMPAIGNS');

export const selectEngagementCampaigns = (state) => state.analytics.engagement.campaigns;

export const setCampaigns = (botId) => async (dispatch, getState) => {
  const token = getState().analytics.auth.bot.token || (await dispatch(getBotJWToken(botId)));
  const response = await fetch(`${window.env.ENGAGEMENT_MAYFLY_URL}/kindly/manager?action=getCampaigns`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const responseJson = await response.json();
  const { data: campaigns, err: error } = responseJson;
  dispatch({
    type: SET_CAMPAIGNS.ACTION,
    campaigns,
    error,
  });
};

const initialState = {
  campaigns: {
    data: [],
    error: null,
    isLoading: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGNS.ACTION:
      return { ...state, campaigns: { data: action.campaigns, error: action.error, isLoading: false } };
    default:
      return state;
  }
};
