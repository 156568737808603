import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { LoaderSwitch, Panel } from 'frontend/components';
import { GET_POLICIES } from 'frontend/features/Organization/queries';

import Policy from './Policy';

const LegalCompliance = () => {
  const { organizationId } = useParams();

  const { data, loading } = useQuery(GET_POLICIES, { variables: { organizationId } });

  const policies = useMemo(
    () =>
      data?.policies?.map((policy) => ({
        ...policy,
        signature: data?.signatures?.find(({ policy: { id: policyId } }) => policyId === policy.id),
      })) ?? [],
    [data],
  );

  return (
    <LoaderSwitch loading={loading} size="large">
      <div>
        <Panel>
          {policies.map((policy) => (
            <Policy organizationId={organizationId} key={`policy-${policy.id}`} policy={policy} />
          ))}
        </Panel>
      </div>
    </LoaderSwitch>
  );
};

export default LegalCompliance;
