import cx from 'classnames';
import PropTypes from 'prop-types';

import { ErrorBoundary } from 'frontend/components';
import Panel from 'frontend/components/Panel';
import { EmptyState } from 'frontend/components/Stats';
import Heading from 'frontend/features/Analytics/components/Heading/Heading';
import { ChildrenType } from 'frontend/propTypes';

import styles from './styles.scss';

const StatsPanelContent = ({ loading, error, children }) => {
  if (loading) return <Panel.LoadingState />;
  if (error) return <Panel.ErrorState>An error occurred while retrieving your data</Panel.ErrorState>;
  if (children) return children;
  return <EmptyState />;
};

StatsPanelContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  children: ChildrenType,
};

const StatsPanel = ({
  className,
  to = '',
  children,
  loading = false,
  error = '',
  noPadding = false,
  nested = false,
  title,
  subtitle,
  onHeaderIconClick,
  onShowDetailClick,
}) => (
  <Panel
    className={cx(styles.statsPanel, className, {
      [styles.statsPanelNoPadding]: noPadding || title,
    })}
    hover={Boolean(to)}
    nested={nested}
  >
    <ErrorBoundary>
      {title && (
        <Heading
          title={title}
          subtitle={subtitle}
          onHeaderIconClick={onHeaderIconClick}
          onShowDetailClick={onShowDetailClick}
          to={to}
        />
      )}
      <div className={cx({ [styles.statsContainer]: title, [styles.statsContainerNoPadding]: noPadding })}>
        <StatsPanelContent loading={loading} error={error}>
          {children}
        </StatsPanelContent>
      </div>
    </ErrorBoundary>
  </Panel>
);

StatsPanel.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: ChildrenType,
  loading: PropTypes.bool,
  error: PropTypes.string,
  noPadding: PropTypes.bool,
  nested: PropTypes.bool,
  title: PropTypes.string,
  subtitle: ChildrenType,
  onHeaderIconClick: PropTypes.func,
  onShowDetailClick: PropTypes.func,
};

export default StatsPanel;
