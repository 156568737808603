import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { type LabelType, OrganizationLabelsDocument } from 'frontend/api/generated';
import { useBotLabels } from 'frontend/hooks';

const getBotName = (hideBotName, botName, name) => (hideBotName ? name : `${name} (${botName})`);

const handleLabelName =
  (hideBotName, botName) =>
  ({ name, ...label }) => ({
    ...label,
    name: getBotName(hideBotName, botName, name),
  });

export default ({ hideBotName }: { hideBotName?: boolean } = {}): Partial<LabelType>[] | undefined => {
  const { botId, organizationId } = useParams();
  const botLabels = useBotLabels();
  const { data, loading } = useQuery(OrganizationLabelsDocument, {
    variables: { organizationId: organizationId as string },
    skip: !organizationId,
  });

  const organizationLabels = useMemo(() => {
    if (!organizationId || loading) return [];

    return data?.organizationLabels
      ?.map(({ labels, botName }) => labels.map(handleLabelName(hideBotName, botName)))
      .flat();
  }, [data, hideBotName, loading, organizationId]);

  return botId ? botLabels : organizationLabels;
};
