import PropTypes from 'prop-types';

import { Close } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from '../shared.scss';

const NudgeWrapper = ({ children }) => (
  <div className={styles.nudgeContainer}>
    <div className={styles.borderElement} />
    <div className={styles.dismissContainer}>
      <Icon component={Close} />
    </div>
    <div className={styles.layoutContainer}>{children}</div>
  </div>
);

NudgeWrapper.propTypes = {
  children: PropTypes.node,
};

export default NudgeWrapper;
