import PropTypes from 'prop-types';

import { Check } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

const CheckMark = ({ onClick }) => <Icon component={Check} onClick={onClick} color="primary" />;

CheckMark.propTypes = {
  onClick: PropTypes.func,
};

export default CheckMark;
