import { isEmpty } from 'lodash';

import type { ChatType } from 'frontend/api/generated';

const ANONYMOUS_NAME = 'Anonymous';

const avatarToLastName = (avatar) => avatar.replace(/[A-Z]/g, (m) => ` ${m.toLowerCase()}`);

const getAutoUserName = (autoUser) => `${autoUser.colorName} ${avatarToLastName(autoUser.avatar)}`;

export default (chat?: Partial<ChatType>) => {
  if (!chat) {
    console.warn('Received unexpected argument while getting username in Insights');
    return '';
  }

  const { fullName, autoUser } = chat;
  if (fullName && fullName !== ANONYMOUS_NAME) return fullName;
  if (isEmpty(autoUser)) return '';
  return getAutoUserName(autoUser);
};
