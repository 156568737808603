import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { Field } from 'react-final-form';

import { Input } from 'frontend/components';
import { required } from 'frontend/form/validators';
import { onEnter } from 'frontend/utils';

import styles from '../../../Entities/modals/EntitySearchAndReplace/EntitySearchAndReplace.scss';

const ChooseForm = ({ handleSubmit }) => {
  const replaceWordRef = useRef(null);

  const focusReplaceWordInput = useCallback(() => {
    replaceWordRef.current.focus();
  }, [replaceWordRef]);

  return (
    <>
      <Field
        className={styles.field}
        component={Input}
        name="removeWord"
        placeholder="Word to remove"
        onKeyDown={onEnter(focusReplaceWordInput)}
        validate={required}
        label="Search"
        autoFocus
      />
      <br />
      <Field
        className={styles.field}
        component={Input}
        name="replaceWord"
        placeholder="Word to use as replacement"
        onKeyDown={onEnter(handleSubmit)}
        validate={required}
        label="Replace"
        ref={replaceWordRef}
      />
    </>
  );
};

ChooseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ChooseForm;
