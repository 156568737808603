import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { camelCase, uniq } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { NavigationButton } from 'frontend/features/Inbox/components';
import { VIEWS } from 'frontend/features/Inbox/constants';
import { TICKET_VIEW_COUNTS } from 'frontend/features/Inbox/queries';
import { viewFromFilters } from 'frontend/features/Inbox/utils';
import { useHandoverMode } from 'frontend/features/Inbox/views/MetaData/hooks';
import { useBotOrganization } from 'frontend/features/Organization/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { AGENT_VIEW_FILTERS } from 'frontend/state/dux/inbox';
import { mapToObject } from 'frontend/utils';

import AgentAvailability from './AgentAvailability/AgentAvailability';
import styles from './ChatViewSelector.scss';
import { ChatView, OrganizationInboxWarning } from './components';
import {
  convertFormValuesToQueryParams,
  convertQueryParamsToPersistedValues,
} from '../../components/InboxFilters/utils/helpers';

const chooseFilter = (key, oldFilters, newFilters) => {
  if (key !== 'ticketAgentFilter') {
    return key in newFilters ? newFilters[key] : oldFilters[key];
  }
  if (newFilters[key]) {
    return newFilters[key];
  }
  if (oldFilters[key] in AGENT_VIEW_FILTERS) {
    return null;
  }
  return oldFilters[key];
};

const getUpdatedFilters = (oldFilters = {}, newFilters = {}) => {
  const keys = uniq([...Object.keys(oldFilters), ...Object.keys(newFilters)]);
  return mapToObject((key) => ({ [key]: chooseFilter(key, oldFilters, newFilters) }), keys);
};

const ChatViewSelector = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { botId: botIdFromParams, organizationId: orgIdFromParams } = useParams(); // Only one exists in params at once, whether we're in the org or bot inbox
  const isOrgInbox = !!orgIdFromParams;
  const isWorkspaceInbox = !!botIdFromParams;

  const currentFilters = convertQueryParamsToPersistedValues(location.search);
  const currentViewTitle = viewFromFilters(currentFilters);

  const { data } = useQuery(TICKET_VIEW_COUNTS, {
    variables: { botId: botIdFromParams, organizationId: orgIdFromParams, ...currentFilters },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { mode, loading: handoverModeLoading } = useHandoverMode(botIdFromParams);
  const isHandoverEnabled = mode === 'PLATFORM';

  const { organization, loading: orgLoading } = useBotOrganization({ botId: botIdFromParams });
  const orgIdFromHook = organization?.id;

  const { botPermissions: userBotPermissions } = useMyPermissions({
    botId: botIdFromParams,
    organizationId: orgIdFromHook,
  });
  const canViewConversations = (userBotPermissions || []).includes('view_conversations');

  const shouldShowAgentAvailability =
    isHandoverEnabled && isWorkspaceInbox && !orgLoading && !handoverModeLoading && canViewConversations;

  const selectView = (title) => {
    const newFilters = getUpdatedFilters(currentFilters, VIEWS.get(title));

    navigate(
      `/${isOrgInbox ? 'organization' : 'workspace'}/${
        orgIdFromParams || botIdFromParams
      }/inbox/chat?${convertFormValuesToQueryParams(newFilters)}`,
      {
        replace: true,
      },
    );
  };

  const handleClickList = () => navigate(`${location.pathname}?screen=chat-list`);

  return (
    <div className={styles.viewSelectorContainer}>
      <div className={styles.header}>
        <NavigationButton onClick={handleClickList} text="Conversations" mobileOnly />
      </div>
      <div className={cx(styles.viewSelectorWrapper)}>
        {Array.from(VIEWS.keys()).map((title) => (
          <ChatView
            key={title}
            title={title}
            selectView={selectView}
            active={title === currentViewTitle}
            count={data?.ticketViewCounts?.[camelCase(title)]}
          />
        ))}
      </div>
      <OrganizationInboxWarning orgInboxId={orgIdFromParams} />
      {shouldShowAgentAvailability && <AgentAvailability orgInboxId={orgIdFromHook} />}
    </div>
  );
};

export default ChatViewSelector;
