import { updateItemInCache } from 'frontend/api/cacheHelpers';
import { DialogueModFragment } from 'frontend/features/Build/graphql';

export default ({ client, update, id }) =>
  updateItemInCache({
    fragment: DialogueModFragment,
    typeName: 'DialogueModType',
    fragmentName: 'DialogueMod',
    update: (mod) => ({
      ...mod,
      numberOfModFollowups: mod.numberOfModFollowups + update,
    }),
    cache: client,
    id,
  });
