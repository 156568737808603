import { Send } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import Section from './Section';
import styles from '../shared.scss';

interface Props {
  label?: string;
  placeholder: string;
}
const Form = ({ label = '', placeholder }: Props) => (
  <Section>
    {!!label && <span className={styles.label}>{label}</span>}
    <div className={styles.formGroup}>
      <input placeholder={placeholder} />
      <div className={styles.formButton}>
        <Icon component={Send} className={styles.sendIcon} />
      </div>
    </div>
  </Section>
);

export default Form;
