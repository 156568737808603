import { gql } from '@apollo/client';

import { ATTACHMENT } from '../fragments';

export default gql`
  mutation CreateAttachments($botId: ID!, $chatId: ID!, $attachmentInputs: [AttachmentInputType]!) {
    createAttachments(botId: $botId, chatId: $chatId, attachmentInputs: $attachmentInputs) {
      id
      attachments {
        ...Attachment
      }
    }
  }
  ${ATTACHMENT}
`;
