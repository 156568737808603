import PropTypes from 'prop-types';
import { useEffect } from 'react';

import LoadingButton from './LoadingButton';

const RetryButton = ({ retryDisconnect }) => {
  useEffect(() => {
    retryDisconnect();
  }, [retryDisconnect]);

  return <LoadingButton />;
};

RetryButton.propTypes = {
  retryDisconnect: PropTypes.func.isRequired,
};

export default RetryButton;
