import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowLeft } from 'frontend/assets/icons';
import { Button, Panel } from 'frontend/components';

import styles from './ConnectDetails.scss';

interface ConnectDetails {
  children: React.ReactElement | React.ReactElement[] | null | undefined;
  backButtonTitle: string;
  headerContent?: React.ReactElement | React.ReactElement[] | null | undefined;
}

const ConnectDetails = ({ backButtonTitle, headerContent, children }: ConnectDetails) => {
  const navigate = useNavigate();
  return (
    <Panel className={styles.panel}>
      <div className={styles.buttonContainer}>
        <Button flat icon={ArrowLeft} onClick={() => navigate(-1)}>
          {backButtonTitle}
        </Button>
        {headerContent}
      </div>

      <Panel.Separator className={styles.panelSeparator} />
      {children}
    </Panel>
  );
};

export default ConnectDetails;
