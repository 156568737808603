import { useQuery } from '@apollo/client';
import { get, groupBy, orderBy } from 'lodash';
import { useMemo } from 'react';

import { LibraryDialoguesDocument } from 'frontend/api/generated';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { useCurrentLanguage } from 'frontend/hooks';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';
import { BuildIdObjectType } from 'frontend/propTypes';

import InnerFolder from './InnerFolder';
import { systemDialogueCategories } from '../../constants';

const categorySorting = ([category]) => {
  switch (category) {
    case systemDialogueCategories.SUGGESTIONS:
      return 1;
    case systemDialogueCategories.PRIVACY:
      return 2;
    case systemDialogueCategories.HANDOVER:
      return 3;
    default:
      return 4;
  }
};

const dialogueType = DIALOGUE_TYPES.SYSTEM_DIALOGUE;

const betaFilter = (suggestionsEnabled, dialogues) =>
  suggestionsEnabled
    ? dialogues
    : dialogues.filter(({ category }) => category !== systemDialogueCategories.SUGGESTIONS);

const getGroupedDialogues = ({ data, suggestionsEnabled }) => {
  const dialogues = get(data, 'dialogues', []);
  const filteredDialogues = betaFilter(suggestionsEnabled, dialogues);

  return Object.entries(groupBy(filteredDialogues, 'category'));
};

const SystemDialogueFolder = ({ buildIdObject }) => {
  const [{ selectedLanguage }] = useCurrentLanguage();
  const variables = { ...buildIdObject, dialogueType, regular: false };
  const { loading, data } = useQuery(LibraryDialoguesDocument, { variables });
  const isFeatureEnabled = useFeatureFlags();

  const suggestionsEnabled = isFeatureEnabled('suggestions');

  const groupedByCategory = useMemo(
    () => getGroupedDialogues({ suggestionsEnabled, data }),
    [data, suggestionsEnabled],
  );

  if (loading) {
    return null;
  }

  return (
    <>
      {orderBy(groupedByCategory, categorySorting).map(([category, dialogues]) => (
        <InnerFolder
          key={category}
          category={category}
          dialogues={dialogues}
          selectedLanguage={selectedLanguage}
          buildIdObject={buildIdObject}
          indentationLevel={0}
        />
      ))}
    </>
  );
};

SystemDialogueFolder.propTypes = {
  buildIdObject: BuildIdObjectType.isRequired,
};

export default SystemDialogueFolder;
