import PropTypes from 'prop-types';

import { SampleItem } from 'frontend/features/Samples/components';

import styles from './SamplePairRow.scss';
import { useSampleLists } from '../../hooks';

const Sample = ({ sample, sampleValidator }) => {
  if (!sample) {
    return null;
  }

  return (
    <div className={styles.sampleContainer}>
      <SampleItem
        sample={sample}
        key={`sample-${sample.index}`}
        name={`sample.${sample.index}`}
        selectedSamples={[]}
        sampleValidator={sampleValidator}
        removeSample={() => false}
        deselectAll={() => false}
        fieldName="sample"
        isSelected={false}
        setSampleSelected={() => false}
        editingSample=""
        setEditingSample={() => false}
        stopEditing={() => false}
        sampleOptionsDisabled
      />
    </div>
  );
};
Sample.propTypes = {
  sample: PropTypes.shape({}),
  sampleValidator: PropTypes.func,
};

const SamplePairRow = ({ confusedSamplePair, selectedLanguage }) => {
  const { sampleA, sampleB } = confusedSamplePair;

  const { sampleValidator: sampleValidatorA, sampleList: sampleListA } = useSampleLists({
    currentLanguage: selectedLanguage,
    values: [sampleA],
  });

  const { sampleValidator: sampleValidatorB, sampleList: sampleListB } = useSampleLists({
    currentLanguage: selectedLanguage,
    values: [sampleB],
  });

  return (
    <div className={styles.rowContainer}>
      <Sample sample={sampleListA[0]} sampleValidator={sampleValidatorA} />
      <Sample sample={sampleListB[0]} sampleValidator={sampleValidatorB} />
    </div>
  );
};

SamplePairRow.propTypes = {
  confusedSamplePair: PropTypes.shape({}),
  selectedLanguage: PropTypes.string,
};

export default SamplePairRow;
