import { gql } from '@apollo/client';

export default gql`
  query SampleDialogues($botId: ID!) {
    sampleDialogues(botId: $botId) {
      id
      title
      breadcrumbs
    }
  }
`;
