import cx from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import styles from './Names.scss';

const Names = ({ organizationName, borOrSkillname }) => {
  const titleRef = useRef(null);
  const subTitleRef = useRef(null);
  const [titleOverflow, setTitleOverflow] = useState(false);
  const [subTitleOverflow, setSubTitleOverflow] = useState(false);

  useEffect(() => {
    if (titleRef.current.offsetWidth === 150) {
      setTitleOverflow(true);
    } else {
      setTitleOverflow(false);
    }

    if (borOrSkillname && subTitleRef.current.offsetWidth === 150) {
      setSubTitleOverflow(true);
    } else {
      setSubTitleOverflow(false);
    }
  }, [borOrSkillname]);

  // The content is duplicated since the scroll animation must be absolute.
  // To obtain a variable width the content must be relative.
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <span
          className={cx(styles.title, styles.static, {
            [styles.overflow]: titleOverflow,
          })}
          ref={titleRef}
        >
          {organizationName}
        </span>
        {titleOverflow && <span className={cx(styles.title, styles.animate)}>{organizationName}</span>}
      </div>
      {borOrSkillname && (
        <div className={styles.titleWrapper}>
          <span
            className={cx(styles.subTitle, styles.static, {
              [styles.overflow]: subTitleOverflow,
            })}
            ref={subTitleRef}
          >
            {borOrSkillname}
          </span>
          {subTitleOverflow && <span className={cx(styles.subTitle, styles.animate)}>{borOrSkillname}</span>}
        </div>
      )}
    </div>
  );
};

Names.propTypes = {
  organizationName: PropTypes.string.isRequired,
  borOrSkillname: PropTypes.string,
};

export default Names;
