import handoverAudio from '../assets/audio/handover.mp3';

const audio = new Audio(handoverAudio);

export default async function playNotificationSound() {
  try {
    await audio.play();
  } catch (err) {
    console.error('Could not play sound', err);
  }
}
