import React from 'react';

import { formatNumber } from 'frontend/utils';

type Metric = { metric: number; reference: number };

function Change({ metric, reference }: Metric): React.JSX.Element | null {
  const delta = metric - reference;
  const relativeChange = (delta / reference) * 100;

  if (reference === metric) return <>No change</>;

  if (!Number.isFinite(relativeChange)) return null;

  return <>Change: {relativeChange.toFixed(2)}%</>;
}

export function MonthlyMetricTooltip({ metric, reference }: Metric): React.JSX.Element {
  return (
    <>
      This month: {formatNumber(metric)}
      <br />
      Last month: {formatNumber(reference) || '-'}
      <br />
      <Change metric={metric} reference={reference} />
    </>
  );
}
