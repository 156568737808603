import { gql } from '@apollo/client';

import anonymizationFragment from './anonymizationFragment';

export default gql`
  fragment PrivacySettings on PrivacySettingsType {
    id
    deletePersonalData
    deletePersonalDataDays
    useGdprSkill
    gdprSkillSubscription
    anonymizationIsActive
    anonymizationDuringHandover
    anonymizations {
      ...Anonymization
    }
  }
  ${anonymizationFragment}
`;
