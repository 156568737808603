import cx from 'classnames';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Menu.scss';

interface Props {
  title: string;
  menuItems: any[];
  rootPath?: string;
  toggleMenuOpen: () => void;
}

export default function MobileSubMenuItem({ title, menuItems, rootPath, toggleMenuOpen }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        className={cx(styles.mobileMenuItem, styles.mobileSubMenuParent, {
          [styles.mobileSubMenuParentOpen]: open,
        })}
        onClick={() => setOpen(!open)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setOpen(!open);
        }}
        tabIndex={0}
        role="button"
      >
        {title}
      </div>
      <div className={open ? styles.mobileSubMenuItemOpen : styles.mobileSubMenuItemClosed}>
        {menuItems
          .filter((item) => !item.hide)
          .map((item) => (
            <NavLink
              key={`mobile-sub-menu-item-${item.name}`}
              to={`${rootPath}/${item.slug}`}
              className={({ isActive }) =>
                `${styles.mobileSubMenuItem} ${item.className ?? ''} ${isActive ? styles.mobileSubMenuItemActive : ''}`
              }
              onClick={toggleMenuOpen}
            >
              {item.name}
            </NavLink>
          ))}
      </div>
    </div>
  );
}
