import { gql, useMutation } from '@apollo/client';

const DOWNLOAD_CHAT_MUTATION = gql`
  mutation DownloadChat($botId: ID!, $chatId: ID!) {
    downloadChat(botId: $botId, chatId: $chatId) {
      id
      url
    }
  }
`;

export default ({ botId, chatId }) => {
  const [downloadChat] = useMutation(DOWNLOAD_CHAT_MUTATION, {
    variables: {
      botId,
      chatId,
    },
    update: (_, { data }) => {
      const url = data.downloadChat.url;
      window.location.replace(`${window.env.API_URL}${url}`);
    },
  });

  return downloadChat;
};
