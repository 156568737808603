import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';

import { useDebounce, useUrlSearchParams } from 'frontend/hooks';

import { BOT_SEARCH } from './queries';
import { botFilters } from '../../constants';

const SEARCH_PARAMS_CONFIG = { default: { page: 1 }, types: { archived: Boolean, page: Number } };

const useAdminBots = (pageSize = 25) => {
  const [runSearch, { data, loading, called }] = useLazyQuery(BOT_SEARCH, { fetchPolicy: 'cache-and-network' });
  const bots = useMemo(() => data?.botSearch?.objects ?? [], [data]);
  const [{ page, q: query, archived }, setParams, unsetParams] = useUrlSearchParams(SEARCH_PARAMS_CONFIG);

  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    runSearch({
      variables: {
        query: debouncedQuery,
        filters: { archived },
        first: pageSize,
        after: (page - 1) * pageSize,
      },
      fetchPolicy: 'network-only',
    });
  }, [pageSize, runSearch, debouncedQuery, archived, page]);

  const noHits = !loading && bots.length === 0 && called;
  const totalCount = data?.botSearch?.hits ?? 0;
  const pages = Math.ceil(totalCount / pageSize);

  const searchInput = useMemo(
    () => ({
      value: query ?? '',
      onChange: ({ target }) => {
        setParams({ q: target.value, page: 1 });
      },
    }),
    [setParams, query],
  );

  const archivedInput = useCallback(
    (value) => {
      switch (value) {
        case botFilters.ACTIVE:
          setParams({ archived: false });
          break;
        case botFilters.ARCHIVED:
          setParams({ archived: true });
          break;
        default:
          unsetParams(['archived']);
          break;
      }
    },
    [unsetParams, setParams],
  );

  const onResetClick = useCallback(() => unsetParams(['archived', 'page', 'q']), [unsetParams]);

  const pagination = useMemo(
    () => ({
      currentPage: page,
      pages,
      setPage: (newPage) => setParams({ page: newPage }),
      summary: {
        totalCount,
        firstVisible: (page - 1) * pageSize + 1,
        lastVisible: page === pages ? bots.length : page * pageSize,
      },
    }),
    [bots, page, pages, setParams, totalCount, pageSize],
  );

  return {
    archived,
    archivedInput,
    bots,
    loading,
    noHits,
    onResetClick,
    pagination,
    searchInput,
  };
};
export default useAdminBots;
