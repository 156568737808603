import { usePusherEvent } from '../../hooks';
import type { OnUpdate } from '../../hooks/usePusherEvent';

interface PusherProps {
  channel: string;
  event: string;
  onUpdate: OnUpdate;
}

/** Wrap in a component to be able to use conditionally (e.g. after necessary requests) */
const Pusher = ({ channel: channelName, event, onUpdate }: PusherProps) => {
  usePusherEvent(channelName, event, onUpdate);
  return null;
};

export default Pusher;
