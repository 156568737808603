import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ConnectSlackWorkspaceDocument, SlackWorkspaceProfileDocument } from 'frontend/api/generated';
import { useModal } from 'frontend/features/Modals';
import DisconnectSlackModal from 'frontend/features/Profile/views/ConnectedAccounts/DisconnectSlackModal';
import { useToast, useUrlSearchParams } from 'frontend/hooks';

async function connectWithCode(code, toast, slackConnect, unsetParams, redirect) {
  try {
    await slackConnect({ variables: { code, redirect } });
  } catch (err) {
    toast.error('Could not connect to slack', err);
    return;
  }
  unsetParams(['code', 'state']);
  toast.success('Connected to slack');
}

const update = (cache, result) => {
  const connectSlackWorkspace = result?.data?.connectSlackWorkspace;

  cache.modify({
    id: `UserType:${connectSlackWorkspace.user.id}`,
    fields: {
      slackWorkspaceProfile() {
        return connectSlackWorkspace;
      },
    },
  });
};

const useConnectedAccounts = () => {
  const toast = useToast();
  const { pathname: redirect } = useLocation();
  const { data: slackData, loading } = useQuery(SlackWorkspaceProfileDocument);
  const [disconnectSlack] = useModal(DisconnectSlackModal);

  const [{ code }, , unsetParams] = useUrlSearchParams();
  const [connectSlack, { loading: isSubmitting }] = useMutation(ConnectSlackWorkspaceDocument, {
    update,
  });

  const handleSlackDisconnect = useCallback(() => {
    const me = slackData?.me;

    disconnectSlack({
      id: me?.slackWorkspaceProfile?.id,
      workspaceName: me?.slackWorkspaceProfile?.workspaceName,
      userId: me?.id,
    });
  }, [disconnectSlack, slackData?.me]);

  useEffect(() => {
    if (!code || loading) return;
    connectWithCode(code, toast, connectSlack, unsetParams, redirect);
  }, [loading, toast, connectSlack, code, unsetParams, redirect]);

  const slackWorkspaceProfile = slackData?.me?.slackWorkspaceProfile;

  return { loading, isSubmitting, handleSlackDisconnect, slackWorkspaceProfile };
};
export default useConnectedAccounts;
