import { useMutation } from '@apollo/client';

import { duplicateDialogue } from '../mutations';

export default ({ dialogue, buildIdObject, toast }) => {
  const [duplicateMutation] = useMutation(duplicateDialogue);

  const duplicate = async () => {
    await duplicateMutation({ variables: { ...buildIdObject, dialogueId: dialogue.id } });
    toast.info('Duplicating the dialogue... For large dialogues this may take a while.');
  };

  return duplicate;
};
