import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { Checkbox } from 'frontend/components';
import feedbackFormTypes, { typeToMapper } from 'frontend/constants/feedbackFormTypes';
import ChatbubbleSettings from 'frontend/features/BotSettings/Chatbubble/queries/ChatbubbleSettings.query.graphql';

import { FeedbackRating } from '../../views/Feedback';

const FeedbackPickerForm = () => {
  const { botId } = useParams();
  const { data } = useQuery(ChatbubbleSettings, { variables: { botId } });
  const { feedbackFormType: selectedType } = useMemo(() => data?.chatbubbleSettings ?? [], [data]);
  const mapper = typeToMapper[selectedType];

  return (
    <>
      <Field
        key="feedback-rating-nofeedback"
        name="feedbacks.NO_FEEDBACK_1"
        component={Checkbox}
        type="checkbox"
        label="No feedback"
      />
      {!!mapper &&
        Object.entries(mapper).map(([rating]) => (
          <Field
            key={`feedback-rating-${rating}`}
            name={`feedbacks.${feedbackFormTypes[selectedType]}_${rating}`}
            component={Checkbox}
            type="checkbox"
            label={
              <FeedbackRating data={{ feedbackType: feedbackFormTypes[selectedType], rating: parseInt(rating, 10) }} />
            }
          />
        ))}
    </>
  );
};

export default FeedbackPickerForm;
