import React from 'react';

import { Settings } from 'frontend/assets/icons';

import styles from './LanguageDetectionPreview.scss';

const quickSettingIcons = {
  '#settings/language-select': Settings,
};

export default function buildQuickLinks(content): React.JSX.Element[] {
  const parts: React.JSX.Element[] = [];
  let startIndex = 0;

  function addTextPart(textPart) {
    if (textPart.length > 0) {
      parts.push(<span key={startIndex}>{textPart}</span>);
      startIndex += textPart.length;
    }
  }

  const linkRegex = /\[([^\\\]]+(?:\\.[^\\\]]*)*)\]\(([^)]+)\)/g;
  let match = linkRegex.exec(content);
  while (match !== null) {
    const linkText = match[1];
    const linkUrl = match[2];

    addTextPart(content.substring(startIndex, match.index));
    if (linkUrl && quickSettingIcons[linkUrl]) {
      const Icon = quickSettingIcons[linkUrl];
      parts.push(
        <span key={startIndex + match.index} className={styles.quickLink}>
          <Icon /> {linkText}
        </span>,
      );
    } else {
      parts.push(<span key={startIndex + match.index}>{linkText}</span>);
    }
    startIndex = match.index + match[0].length;

    match = linkRegex.exec(content);
  }

  addTextPart(content.substring(startIndex));
  return parts;
}
