import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { GetAllInvitationsDocument } from 'frontend/api/generated';
import { LoaderSwitch, Pagination, Table } from 'frontend/components';
import { usePagination } from 'frontend/hooks';

import styles from './InvitationsList.scss';
import OrganizationOrBot from './OrganizationOrBot';
import UpdateInvitation from './UpdateInvitation';

const columns = [
  {
    key: 'id',
    title: 'ID',
  },
  {
    key: 'email',
    title: 'Email',
  },
  {
    key: 'organization',
    title: 'Organization',
    component: OrganizationOrBot,
  },
  {
    key: 'bot',
    title: 'Workspace',
    component: OrganizationOrBot,
  },
  {
    key: 'updateInvitation',
    title: 'Actions',
    component: UpdateInvitation,
  },
];

const InvitationsList = () => {
  const { data, loading } = useQuery(GetAllInvitationsDocument);

  const objects = useMemo(
    () =>
      (data?.allInvitations ?? [])
        .filter(({ id }) => id)
        .map(({ id, email, organization, organizationRoles, bot, botRoles }) => ({
          id,
          email,
          organization: { ...organization, roles: organizationRoles, itemType: 'organization' },
          bot: { ...bot, roles: botRoles, itemType: 'bot' },
          updateInvitation: { id, email, organizationId: organization.id },
          key: id,
        })),
    [data],
  );

  const [currentData, page, pages, setPage] = usePagination({ objects, pageSize: 20 });

  return (
    <LoaderSwitch loading={loading} size="large">
      <Table data={currentData} columns={columns} />
      <div className={styles.paginationWrapper}>
        <Pagination currentPage={page} pages={pages} setPage={setPage} />
      </div>
    </LoaderSwitch>
  );
};

export default InvitationsList;
