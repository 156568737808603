import checkCharacterForTags from './checkCharacterForTags';
import { TAG_TYPES } from '../constants';

export default (state, { withinTag } = {}) => {
  const selection = state.getSelection();
  const { anchorKey, focusKey } = selection;
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();

  if (!anchorKey || anchorKey !== focusKey) return false;

  let selectionInsideTag = false;
  const content = state.getCurrentContent();
  const contentBlock = content.getBlockForKey(anchorKey);
  const checkCharacter = checkCharacterForTags(Object.values(TAG_TYPES), content);

  const callback = (tagStart, tagEnd) => {
    if (withinTag && start > tagStart && end < tagEnd) selectionInsideTag = true;
    else if (!withinTag && end > tagStart && start < tagEnd) selectionInsideTag = true;
  };

  contentBlock.findEntityRanges(checkCharacter, callback);

  return selectionInsideTag;
};
