import cx from 'classnames';

import libraryStyles from '../Library.scss';

type Props = {
  hasFollowups?: boolean;
  isFollowup?: boolean;
  url?: string;
  disabled?: boolean;
  isDragging?: boolean;
  canDrop?: boolean;
  isOver?: boolean;
  active?: boolean;
};
export default ({ hasFollowups, isFollowup, url, disabled, isDragging, canDrop, isOver, active }: Props) =>
  cx(libraryStyles.item, {
    [libraryStyles.itemLink]: url,
    [libraryStyles.itemIsFollowup]: isFollowup,
    [libraryStyles.itemHasFollowups]: hasFollowups,
    [libraryStyles.itemDisabled]: disabled,
    [libraryStyles.itemDragging]: isDragging,
    [libraryStyles.itemCanDrop]: canDrop && isOver,
    [libraryStyles.itemFolderActive]: active && !url,
    [libraryStyles.itemActive]: active && url,
  });
