import { PageContent } from 'frontend/components';
import { ChildrenType } from 'frontend/propTypes';

import styles from './AnalyticsContent.scss';

const AnalyticsContent = ({ children }) => <PageContent className={styles.pageContent}>{children}</PageContent>;

AnalyticsContent.propTypes = {
  children: ChildrenType,
};

export default AnalyticsContent;
