import { useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';

import Role from './Role';
import styles from './styles.scss';
import type { AnyRole } from '../../../../types';
import { sortRoles } from '../../../../utils';

function DefinedRoles({ roles }: { roles: AnyRole[] }) {
  const { change } = useForm();
  const { values } = useFormState();
  const sortedRoles = useMemo(() => sortRoles(roles, 'title'), [roles]);

  if (roles.length === 0) {
    return (
      <div className={styles.emptyState}>
        There are no available roles. Remember that if a user already has all permissions through their organization
        membership, no bot membership is required.
      </div>
    );
  }

  return (
    <div className={styles.boxContainer}>
      {sortedRoles.map((role) => (
        <Role key={`role-${role.id}`} change={change} role={role} values={values} />
      ))}
    </div>
  );
}

export default DefinedRoles;
