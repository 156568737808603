/**
  Example usage: remove chat corresponding to id from bot.chats
  cache.modify({
    id: cache.identify(bot),
    fields: { chats: removeListItemById(chat.id) },
  });
*/
export default (id) =>
  // eslint-disable-next-line default-param-last
  (currentList = [], { readField }) =>
    currentList.filter((item) => readField('id', item) !== id);
