import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Link, useParams } from 'react-router-dom';

import { LibraryDialoguesDocument } from 'frontend/api/generated';
import { ToggleSwitch } from 'frontend/components';
import { DIALOGUE_TYPES, buildTypes } from 'frontend/constants';
import { systemDialogueMessageTypes } from 'frontend/features/Library/constants';
import { getBuildUrl } from 'frontend/features/Library/utils';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from '../../Privacy.scss';

const PrivacySkill = () => {
  const { botId } = useParams();
  const { hasBotPerms } = useMyPermissions({ botId });
  const canViewDialogues = hasBotPerms('view_templates');

  const { data, loading } = useQuery(LibraryDialoguesDocument, {
    variables: { botId, regular: false, dialogueType: DIALOGUE_TYPES.SYSTEM_DIALOGUE },
    skip: !canViewDialogues,
  });

  if (loading) {
    return null;
  }

  const { id: deleteChatDialogueId } =
    get(data, 'dialogues', []).find(({ messageType }) => messageType === systemDialogueMessageTypes.DELETE_CHAT) || {};

  return (
    <div className={styles.sectionContainer}>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Privacy skill</h3>
      <p>
        Using the Privacy skill in a chat will allow the end user to delete or download all data stored in a chat log.
      </p>
      <p>
        This skill is available in <strong>Build</strong> and is not activated by default.
      </p>
      <div className="m-t-lg m-b-lg">
        <ToggleSwitch name="useGdprSkill" status={['Use Privacy skill']} />
      </div>
      {canViewDialogues && (
        <>
          <Link to={`/workspace/${botId}/build`} className="link">
            Show skill in Build
          </Link>
          <br />
          <br />
        </>
      )}
      {deleteChatDialogueId && (
        <p className="m-b-0">
          The message shown when deleting a chat can be customized in the relevant{' '}
          <Link
            to={getBuildUrl({
              buildType: buildTypes.BOT,
              buildId: botId,
              dialogueType: DIALOGUE_TYPES.SYSTEM_DIALOGUE,
              target: deleteChatDialogueId,
            })}
            className="link"
          >
            section of Build.
          </Link>
        </p>
      )}
    </div>
  );
};

export default PrivacySkill;
