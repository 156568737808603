import { get } from 'lodash';

import updateArrayInCache from './updateArrayInCache';

export default ({ pathToItemInMutationData, ...args }) =>
  (cache, { data }: { data?: any }) => {
    const item = get(data, pathToItemInMutationData);
    const arrayUpdate = (currentArray) => currentArray.filter(({ id }) => id !== item.id);

    updateArrayInCache({ variables: {}, query: {}, pathToArrayInCache: '', ...args, cache, arrayUpdate });
  };
