import cx from 'classnames';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Agent, Connect, Handover } from 'frontend/assets/icons';
import { Button, DotsButton, Dropdown, Icon, LabelBadge, List, Loader } from 'frontend/components';
import { ChatFeedbackType } from 'frontend/features/Analytics/propTypes';
import { Avatar, Feedback } from 'frontend/features/Inbox/components';
import { useModal } from 'frontend/features/Modals';
import { RefType } from 'frontend/propTypes';
import { stopEvent } from 'frontend/utils';

import styles from './MetaData.scss';
import { ContactStatus, LanguageSelector, TicketAssignManager, TicketStatus, UserMeta } from './components';
import Context from './components/Context';
import LabelsController from './components/LabelsController/LabelsController';
import MetaAccordion from './components/MetaAccordion/MetaAccordion';
import { useHandoverMode, useMetaData } from './hooks';
import { DeleteChat } from './modals';
import { HANDOVER_MODE } from '../../constants';
import { ConversationDataType } from '../Conversation/propTypes';

const MetaDataOptions = ({ close, actions }) => (
  <List
    dataSource={actions}
    renderItem={({ key, onClick, title, className }) => (
      <List.Item
        className={cx(styles.optionsItem, className)}
        key={key}
        onClick={stopEvent(() => {
          close();
          onClick();
        })}
      >
        {title}
      </List.Item>
    )}
  />
);

MetaDataOptions.propTypes = {
  close: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const OptionsDropdown = ({ actions }) => (
  <Dropdown overlay={<MetaDataOptions actions={actions} />}>
    <DotsButton />
  </Dropdown>
);

OptionsDropdown.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})),
};

const MetaData = ({ bottomRef, feedback, conversationData }) => {
  const bot = conversationData?.chatAndBot?.bot;
  const botId = bot?.id;
  const { chatId, organizationId } = useParams();
  const [showDeleteModal] = useModal(DeleteChat);
  const { mode, loading: loadingMode } = useHandoverMode(botId);

  const {
    loading,
    canManageTickets,
    canManageHandovers,
    botLanguages,
    labels,
    ticket,
    isSubmitting,
    createTicket,
    endHandover,
    startHandover,
    userName,
    downloadChat,
    deleteChat,
    autoUser,
    handedOver,
    source,
    email,
    emailVerified,
    avatarUrl,
    phoneNumber,
    phoneNumberVerified,
    contactDetails,
    isAuthenticated,
    context,
  } = useMetaData({
    chatId,
    botId,
    organizationId,
  });

  const chatLanguage = conversationData?.chatAndBot?.chat?.languageCode ?? false;
  const emailCc = conversationData?.chatAndBot?.chat?.emailCc ?? [];
  const startHandoverInChatLanguage = () => startHandover(chatLanguage);
  const handoverDisabled = mode === HANDOVER_MODE.DISABLED.value;

  const sortedActiveLabels = labels.filter((label) => label.active).sort((a, b) => a.name.localeCompare(b.name));

  const actions = [
    {
      title: 'Delete',
      key: 'chat-delete',
      onClick: () => showDeleteModal({ deleteChat, userName }),
    },
    { title: 'Download', key: 'chat-download', onClick: downloadChat },
  ];

  const handleClickFeedBack = () => bottomRef?.current?.scrollIntoView?.();

  if (loading || loadingMode) {
    return (
      <div className={styles.wrapper}>
        <Loader type="inbox_meta_data" />
      </div>
    );
  }

  const showEndHandover = handedOver && canManageHandovers;
  const showCreateTicket = !handedOver && !ticket && canManageTickets;
  const couldShowStartHandover = !handedOver && !showCreateTicket && canManageHandovers;
  const showStartHandover = couldShowStartHandover && !handoverDisabled;
  const showHandoverDisabled = couldShowStartHandover && handoverDisabled;

  const isOrganizationInbox = !!organizationId;

  return (
    <div className={styles.wrapper} data-cy="inbox-metadata">
      <div>
        {isOrganizationInbox && (
          <div className={styles.avatar}>
            <Avatar className={styles.avatarImage} botAvatarUrl={bot.avatarUrl} botAvatar />
            <h4>
              {bot.name}
              {bot.projectName ? ` (${bot.projectName})` : ''}
            </h4>
            <div className={styles.dropDownWrapper}>
              <OptionsDropdown actions={actions} />
            </div>
          </div>
        )}
        <div className={styles.avatar}>
          <Avatar className={styles.avatarImage} chatAvatarUrl={avatarUrl} autoUser={autoUser} />
          <h4>{userName}</h4>
          {!isOrganizationInbox && (
            <div className={styles.dropDownWrapper}>
              <OptionsDropdown actions={actions} />
            </div>
          )}
        </div>
        <MetaAccordion
          defaultOpen
          header={
            <h4>
              <Icon component={Agent} />
              Chat details
            </h4>
          }
        >
          <>
            <UserMeta
              email={email}
              phoneNumber={phoneNumber}
              emailVerified={emailVerified}
              phoneNumberVerified={phoneNumberVerified}
              botId={botId}
              chatId={chatId}
            />
            <div className={cx('m-b-1', styles.metaInfo)}>
              {isAuthenticated && <span>User is authenticated</span>}
              {source && <span>Source: {source}</span>}
            </div>
            <div className={cx(styles.labels, 'm-b-2 m-t-2')}>
              {sortedActiveLabels.map((label) => (
                <LabelBadge key={label.id} label={label} truncate={0} />
              ))}
            </div>
          </>
        </MetaAccordion>
        <MetaAccordion
          header={
            <h4>
              <Icon component={Connect} />
              Chat data
            </h4>
          }
        >
          <Context context={context || {}} />
        </MetaAccordion>

        {feedback && (
          <>
            <h4>Feedback</h4>
            <Feedback feedback={feedback} onClick={handleClickFeedBack} hideTimestamp singleLine />
          </>
        )}
      </div>
      <div className={styles.actions}>
        {Object.keys(contactDetails) > 0 && (
          <ContactStatus email={email} phoneNumber={phoneNumber} contactDetails={contactDetails} botId={botId} />
        )}
        {ticket && canManageTickets && <TicketStatus ticket={ticket} />}
        {ticket && canManageTickets && <TicketAssignManager ticket={ticket} botId={botId} emailCc={emailCc} />}
        <LabelsController botLabels={labels} />
        {/* Handover & ticket buttons */}
        {showEndHandover && (
          <Button
            className={styles.ticketHandoverButton}
            text="End handover"
            onClick={endHandover}
            color="warning"
            width="full"
            isSubmitting={isSubmitting}
            icon={Handover}
          />
        )}
        {showCreateTicket && (
          <Button
            className={styles.ticketHandoverButton}
            text="Create ticket"
            onClick={createTicket}
            color="primary"
            width="full"
            isSubmitting={isSubmitting}
            icon={Handover}
          />
        )}
        {showStartHandover && !chatLanguage && (
          <LanguageSelector startHandover={startHandover} languages={botLanguages} />
        )}
        {showStartHandover && chatLanguage && (
          <Button
            className={styles.ticketHandoverButton}
            text="Start handover"
            onClick={startHandoverInChatLanguage}
            color="primary"
            width="full"
            disabled={handoverDisabled}
            isSubmitting={isSubmitting}
            icon={Handover}
          />
        )}
        {showHandoverDisabled && <span className={styles.handoverSpan}>Handover is disabled for this bot</span>}
      </div>
    </div>
  );
};

MetaData.propTypes = {
  bottomRef: RefType.isRequired,
  feedback: ChatFeedbackType,
  conversationData: ConversationDataType,
};

export default MetaData;
