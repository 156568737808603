import { Link } from 'react-router-dom';

import { Exclamation } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { useOrganizationInboxEnabled } from 'frontend/features/Inbox/hooks';

import styles from './OrganizationInboxWarning.scss';

interface OrganizationInboxWarningProps {
  orgInboxId: string | undefined;
}
const OrganizationInboxWarning = ({ orgInboxId }: OrganizationInboxWarningProps) => {
  const { organizationInboxEnabled, botOrganizationId, loading } = useOrganizationInboxEnabled();
  const showUpdateWarning = Boolean(orgInboxId) !== organizationInboxEnabled;

  if (loading || !showUpdateWarning) return null;

  return (
    <div className={styles.warning}>
      <Icon component={Exclamation} color="warning" className={styles.warningIcon} />
      <span>This page does not currently have realtime updates.</span>
      <br />
      To get updates go to{' '}
      {orgInboxId ? (
        "an individual Bot's Inbox."
      ) : (
        <>
          the <Link to={`/organization/${botOrganizationId}/inbox`}>Organization Inbox.</Link>
        </>
      )}
    </div>
  );
};

export default OrganizationInboxWarning;
