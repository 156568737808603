import defaultMemberAvatar from 'frontend/assets/images/member_avatar.svg?url';

interface DefaultAvatarProps {
  alt?: string;
  className?: string;
}

const DefaultAvatar = ({ className, alt }: DefaultAvatarProps) => (
  <img className={className} src={defaultMemberAvatar} alt={alt} />
);

export default DefaultAvatar;
