import { gql } from '@apollo/client';

export const SuggestionDialogueFragment = gql`
  fragment SuggestionDialogue on SuggestionDialogueType {
    id
    preface
    prompt
    accept
    dismiss
    dismissalResponse
  }
`;
