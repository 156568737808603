import { useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { Blob, CenteredComponent } from 'frontend/components';

import styles from '../../Invitation.scss';
import ContentBox from '../ContentBox';

const InvitationCompleted = () => {
  const { state: invitation } = useLocation();
  const redirectUrl = `/organization/${invitation.organization.id}`;
  const [redirectSeconds, setRedirectSeconds] = useState(10);

  useEffect(() => {
    const countdownIntervalId = setInterval(() => {
      setRedirectSeconds((currentRedirectSeconds) => (currentRedirectSeconds > 0 ? currentRedirectSeconds - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdownIntervalId);
    };
  }, []);

  if (redirectSeconds === 0) return <Navigate to={redirectUrl} />;

  return (
    <CenteredComponent className={styles.fullPage}>
      <div className={styles.branding}>
        <div className={styles.blobContainer}>
          <Blob speed={0.003} intensity={2} vectorAmount={300} />
        </div>
        <Link to="/" className={styles.brandingLink}>
          <span className={styles.brandingName}>Kindly</span>
        </Link>
      </div>
      <ContentBox noGrid>
        <h1 className={styles.title}>Accepted</h1>
        <p className={styles.description}>You accepted the invitation to {invitation.organization.name}.</p>
        <p className={styles.description}>
          <a href={redirectUrl} className={styles.link}>
            Go to kindly.ai
          </a>{' '}
          or wait to be automatically redirected in <span className={styles.seconds}>{redirectSeconds}</span> seconds.
        </p>
      </ContentBox>
    </CenteredComponent>
  );
};

export default InvitationCompleted;
