import { gql } from '@apollo/client';

import { ENTITY_INSTANCE } from 'frontend/features/Entities/fragments';

export default gql`
  fragment Sample on SampleType {
    id
    text
    updatedAt
    languageCode
    createdAt
    instances {
      ...EntityInstance
    }
  }
  ${ENTITY_INSTANCE}
`;
