import { pick } from 'lodash';
import { applyMiddleware, compose, createStore } from 'redux';
import persistState from 'redux-localstorage';

import middleware from './middleware';
import createRootReducer, { authInitialState } from '../dux';
import { initialState as initialBuildState } from '../dux/build';
import { initialState as initialInboxState } from '../dux/inbox';
import { slicer as navbarSlicer } from '../dux/navbar';

const hasResetInboxState = (state) => Boolean(state?.inbox?.resetStateAt);
const now = () => new Date().toISOString();

const mergeInboxState = (_, persistedState) => {
  // We reset the localStorage inbox state in order to set new filter defaults for ticket status.
  // This mechanism can also be used for later resets by comparing against date.
  const state = hasResetInboxState(persistedState)
    ? persistedState
    : { inbox: { ...initialInboxState, resetStateAt: now() } };

  // Make sure botFilter is defined
  if (state.inbox.botFilter !== undefined) return state;
  return { ...state, inbox: { ...state.inbox, botFilter: [] } };
};

function configureStore() {
  return createStore(
    createRootReducer(),
    compose(
      applyMiddleware(...middleware),
      persistState('navbar', {
        key: 'navbar',
        slicer: navbarSlicer,
      }),
      persistState('inbox', {
        key: 'kindly-inbox',
        merge: mergeInboxState,
      }),
      persistState('auth', {
        key: 'kindly-auth',
        slicer:
          () =>
          ({ auth }) => ({
            auth: {
              ...authInitialState,
              authenticated: auth.authenticated,
              access: auth.access,
            },
          }),
      }),
      persistState('build', {
        key: 'kindly-build',
        slicer:
          () =>
          ({ build }) => ({ build: { ...initialBuildState, sorting: build.sorting } }),
      }),
      persistState('platform', {
        key: 'kindly-platform',
        slicer:
          () =>
          ({ platform }) => ({
            platform: {
              currentLanguage: platform.currentLanguage,
              currentVariant: platform.currentVariant,
              organizationDeleteInProgress: platform.organizationDeleteInProgress,
            },
          }),
      }),
      persistState('analytics', {
        key: 'kindly-analytics',
        slicer: () => (state) => pick(state, 'analytics.filters'),
      }),
      persistState('messageClustering', {
        key: 'kindly-message-clustering',
      }),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );
}

export default configureStore;
