import cx from 'classnames';
import { debounce } from 'lodash';
import { useRef } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Field, type FieldRenderProps } from 'react-final-form';

import { Icon, Input } from 'frontend/components';
import { useClickOutside, useOnEscape, useToggle } from 'frontend/hooks';

import styles from './ColorPickerInput.scss';

interface ColorPickerProps extends FieldRenderProps<string> {
  unsetActive(...args: unknown[]): unknown;
}

const ColorPicker = ({ unsetActive, input: { onChange, value } }: ColorPickerProps) => {
  useOnEscape(unsetActive);
  const debouncedOnChange = debounce(onChange, 25);

  return (
    <div className={styles.picker}>
      <HexColorPicker color={value} onChange={debouncedOnChange} />
      <div className={styles.pickerInputWrap}>
        <HexColorInput color={value} onChange={onChange} className={styles.pickerInput} />
      </div>
    </div>
  );
};

interface ColorProps extends FieldRenderProps<string> {
  icon?: React.ComponentType;
}

const Color = ({ input: { value }, icon: IconComponent }: ColorProps) => {
  const style = { background: value };
  return (
    <div className={styles.color}>
      <span className={styles.colorPreview} style={style} />
      <span className={styles.colorHex}>{value}</span>
      {IconComponent && <Icon component={IconComponent} color="gray" />}
    </div>
  );
};

interface ColorPickerInputProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  name: string;
  'aria-label'?: string;
  placeholder?: string;
  validate?(...args: unknown[]): unknown;
  wrapButton?: boolean;
  disabled?: boolean;
  icon?: React.ComponentType;
}

const ColorPickerInput = ({
  children,
  className,
  name,
  placeholder,
  validate,
  disabled,
  id,
  'aria-label': ariaLabel,
  wrapButton = false,
  icon,
}: ColorPickerInputProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [active, toggleActive, unsetActive] = useToggle();

  useClickOutside(ref, unsetActive);

  if (wrapButton) {
    return (
      <div className={cx(styles.colorPickerWrapper, className)} ref={ref}>
        <button type="button" onClick={!disabled ? toggleActive : undefined}>
          <Field aria-label={ariaLabel} name={name} component={Color} />
          {children}
        </button>
        {active && <Field name={name} component={ColorPicker} unsetActive={unsetActive} placeholder="#FFFFFF" />}
      </div>
    );
  }

  return (
    <div className={styles.wrapper} ref={ref}>
      <Field
        name={name}
        id={id}
        component={Input}
        onClick={!disabled ? toggleActive : undefined}
        validate={validate}
        className={styles.colorInput}
        placeholder={placeholder}
        aria-label={ariaLabel}
      />
      <Field name={name} component={Color} icon={icon} />
      {active && <Field name={name} component={ColorPicker} unsetActive={unsetActive} />}
    </div>
  );
};

export default ColorPickerInput;
