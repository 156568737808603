export const CACHE_TYPE = {
  ATTACHMENT_TYPE: 'AttachmentType',
  CHAT_MESSAGE_TYPE: 'ChatMessageType',
  CHAT_TYPE: 'ChatType',
  BUTTON_CLICK_TYPE: 'ButtonClickType',
  PROFILE_TYPE: 'ProfileType',
  USER_TYPE: 'UserType',
};

export const FILTER_TYPES = Object.freeze({
  datePicker: 'dateFilter',
  // TODO remove this when we remove inbox
  STRING: ['ticketAgentFilter'],
});

export const ATTACHMENT_STATUS = Object.freeze({
  CLEAN: 'CLEAN',
  ERROR: 'ERROR',
  INFECTED: 'INFECTED',
  PROCESSING: 'PROCESSING',
});

export const SYSTEM_MESSAGE_TYPE = {
  URL: 'ChatMessageUrlType',
  FEEDBACK: 'ChatMessageFeedbackType',
  USER_LEFT: 'ChatMessageUserLeftType',
  WEBHOOK: 'WebhookRequestType',
};

export const SENDER_TYPES = Object.freeze({
  AGENT: 'AGENT',
  BOT: 'BOT',
  EXTERNAL: 'EXTERNAL',
  SYSTEM: 'SYSTEM',
  USER: 'USER',
  WEBHOOK: 'WEBHOOK',
});

export const MINIMUM_CHARACTERS_BEFORE_SEARCH = 3;
