import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';

import {
  OrganizationSettingsDocument,
  type OrganizationSettingsQuery,
  UpdateOrganizationSsoDocument,
} from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';
import { formatSubmitErrors } from 'frontend/utils';

export interface InitialValues {
  id?: OrganizationSettingsQuery['organization']['id'];
  ssoSettings?: OrganizationSettingsQuery['organization']['ssoSettings'];
}

interface HookReturn {
  loading: boolean;
  onSubmit: (values: any) => Promise<any>;
  initialValues: InitialValues;
}

export default function useSso(): HookReturn {
  const { organizationId: id } = useParams();

  if (!id) {
    throw new Error('Organization ID is required');
  }

  const toast = useToast();
  const { data, loading } = useQuery(OrganizationSettingsDocument, { variables: { id } });
  const [updateSettings] = useMutation(UpdateOrganizationSsoDocument);

  const onSubmit = async (values) => {
    const variables = {
      id,
      ssoSettings: {
        ...omit(values.ssoSettings, ['__typename', 'id']),
        tokenId: values.OauthtokenId,
        tenantDomain: values?.ssoSettings?.tenantDomain || '',
        provider: values?.ssoSettings?.provider || '',
        active: Boolean(values?.ssoSettings?.active),
      },
    };

    try {
      await updateSettings({
        variables,
        update: () => {
          toast.success('Organization SSO updated');
        },
      });
    } catch (err) {
      return formatSubmitErrors(err);
    }
    return undefined;
  };

  const initialValues = {
    id: data?.organization.id,
    ssoSettings: data?.organization.ssoSettings,
  };

  return { loading, onSubmit, initialValues };
}
