import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import {
  AddBotMembershipDocument,
  BotMembersDocument,
  BotPermissionsDocument,
  CreateBotMembershipDocument,
} from 'frontend/api/generated';
import { useIsSubmitting, useToast } from 'frontend/hooks';

import NoEligibleUsers from './NoEligibleUsers';
import AddMemberModal from '../AddMemberModal/AddMemberModal';
import { getUserName, roleIdsFromFields } from '../AddMemberModal/utils';

const PATH = 'bot.memberships';

type Props = {
  hide: () => Promise<void>;
  args: {
    botId: string;
    organizationId: string;
  };
};

function AddMemberToBot({ hide, args: { botId, organizationId } }: Props) {
  const toast = useToast();
  const [isSubmitting, submitWrapper] = useIsSubmitting();
  const { data, loading: membershipsLoading } = useQuery(AddBotMembershipDocument, {
    variables: { id: botId },
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(BotPermissionsDocument);
  const allPermissions = permissionsData?.botPermissions ?? [];

  const bot = data?.bot;
  const roles = (bot?.roles ?? []).map((role) => ({
    ...role,
    isDefaultRole: Boolean(role.isDefaultRole),
  }));
  const name = bot?.name ?? '';
  const currentBotMembershipIds = (data?.[PATH] ?? []).map(({ user: { id } }) => id);
  const currentBotMembershipEmails = (data?.[PATH] ?? []).map(({ user: { email } }) => email);
  const loading = membershipsLoading || permissionsLoading;

  const eligibleUsers = (bot?.organization?.users ?? [])
    .filter((x) => Boolean(x))
    .filter(({ id }) => !currentBotMembershipIds.includes(id));

  const refetchQueries = [{ query: BotMembersDocument, variables: { id: botId } }];
  const [mutate] = useMutation(CreateBotMembershipDocument, { refetchQueries });

  const onSubmit = useCallback(
    async (values) => {
      const selectedEmails = values.selectedUsers.map((user) => user.value);
      const roleIds = roleIdsFromFields(values);
      const users = eligibleUsers.filter((eligibleUser) => selectedEmails.includes(eligibleUser.username));
      const fullNames = users
        .filter((user) => selectedEmails.includes(user.email))
        .map((user) => getUserName(user, user.email))
        .reduce((acc, val, i, arr) => acc + (i < arr.length - 1 ? ', ' : ' and ') + val);
      await Promise.all(users.map((user) => mutate({ variables: { botId, userId: user.id, roleIds } })));

      toast.success(`${fullNames} ${users.length === 1 ? 'was' : 'were'} added to ${name}!`);
      hide();
    },
    [botId, eligibleUsers, hide, mutate, name, toast],
  );

  if (!loading && eligibleUsers.length === 0) return <NoEligibleUsers hide={hide} />;

  return (
    !loading && (
      <AddMemberModal
        to={{ botId, organizationId }}
        type="select"
        users={eligibleUsers}
        existingEmails={currentBotMembershipEmails}
        hide={hide}
        name={name}
        roles={roles}
        allPermissions={allPermissions}
        onSubmit={submitWrapper(onSubmit)}
        isSubmitting={isSubmitting}
        disallowOrganizationRoles
        loading={loading}
      />
    )
  );
}

export default AddMemberToBot;
