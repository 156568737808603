import { has, isArray, isEmpty, isEqualWith, isNil, isObject, omit } from 'lodash';

import { composerStateEqualsFormState, composerStatesEqual } from 'frontend/features/Composer';

import isEqualExceptField from './isEqualExceptField';

const isEquivalentToEmptyArray = (item) => isNil(item) || (isArray(item) && isEmpty(item));

const isObjectOfEmptyArrays = (object) => Object.values(object).every((value) => isArray(value) && isEmpty(value));

const isEquivalentToEmptyObject = (item) =>
  isNil(item) || (isObject(item) && (isEmpty(item) || isObjectOfEmptyArrays(item)));

const getComparison = (doCompareEditorStates) => (first, second) => {
  if (isEquivalentToEmptyArray(first) && isEquivalentToEmptyArray(second)) return true;
  if (isEquivalentToEmptyObject(first) && isEquivalentToEmptyObject(second)) return true;
  if (has(first, 'original') || has(second, 'original')) return isEqualExceptField('original', first, second);

  if (first?.editorState || second?.editorState) {
    if (!doCompareEditorStates) return isEqualExceptField('editorState', first, second);
    if (!first?.editorState) return composerStateEqualsFormState(second.editorState, first);
    if (!second?.editorState) return composerStateEqualsFormState(first.editorState, second);
    return composerStatesEqual(first.editorState, second.editorState);
  }

  return undefined;
};

const ignoredFields = ['newSample', 'newButton'];

export default (state1, state2, { doCompareEditorStates } = {}) => {
  const comparison = getComparison(doCompareEditorStates);
  return isEqualWith(omit(state1, ignoredFields), omit(state2, ignoredFields), comparison);
};
