import styles from 'frontend/styles/colors.scss';

const palette = Object.freeze([
  styles['blue-ribbon'],
  styles['pink-heliotrope'],
  styles['green-harlequin'],
  styles['yellow-sunglow'],
  styles['red-pomegranate'],
  styles['blue-malibu'],
  styles.grayscale70,
]);

export default palette;
