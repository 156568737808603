import cx from 'classnames';
import { Editor } from 'draft-js';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';

import 'draft-js/dist/Draft.css';

import { FIELD_COLOR } from 'frontend/constants';
import { useClickOutside } from 'frontend/hooks';

import styles from './Composer.scss';
import { DropdownContainer } from './components';
import { EVENT } from './constants';
import { useComposerState, useDecorator, useDropdown, useHandlerProps, useKeyHandlers } from './hooks';
import { TagItemType } from './propTypes';
import { updateHighlightTag } from './stateHandlers';
import { newTextLength } from './utils';

/*
Draft-js uses an immutable state model, where every update (including changes in text, decorated components
and selection) results in a new EditorState object.

The EditorState contains the current Selection and ContentState. The ContentState contains an EntityMap
and a number of ContentBlocks, corresponding to different lines in the input. Each ContentBlock contains
text and EntityRanges, associating text intervals with Entities in the EntityMap.

Draft-js's concept of entities cannot, however, be placed in a one-to-one relationship with ours. We have to
create one *draft Entity* for each of our *tags* (i.e. entity tags, slot tags etc). The draft Entities in the
central store, EntityMap, could each be applied several places in the text - but there is no way to differentiate
between these beyond their position, so we couldn't e.g. replace the entity name or slot name by the underlying
original text when removing an instance.
*/

const Composer = ({
  name,
  className,
  readOnly,
  autoFocus,
  placeholder,
  multiline = false,
  highlightButtonText = 'Add entity',
  maxLength,
  onEnter,
  onSave,
  onFocus,
  onBlur,
  onPaste,
  onInsertTag,
  onEscape,
  onClickOutside,
  constructTagItem = identity,
  dropdownExtra,
  dropdownComponent,
  potentialTagItems,
  fieldColor = FIELD_COLOR.WHITE,
}) => {
  const wrapperRef = useRef();
  const composerRef = useRef();
  const { state, setState } = useComposerState(name);

  const { dropdownState, openDropdown, closeDropdown } = useDropdown(name);

  const handlerProps = useHandlerProps({
    name,
    potentialTagItems,
    openDropdown,
    onInsertTag,
    constructTagItem,
    composerRef,
  });

  const { keyBindingFn, handleKeyCommand } = useKeyHandlers({
    name,
    multiline,
    onEnter,
    onEscape,
    onSave,
    handlerProps,
  });

  const handleChange = useCallback(
    (newState) => {
      const stateWithHighlightUpdate = updateHighlightTag(newState);
      setState(stateWithHighlightUpdate);
    },
    [setState],
  );

  const handleClickOutside = useCallback(
    (event) => onClickOutside?.({ ...handlerProps, event }),
    [handlerProps, onClickOutside],
  );

  const handleBeforeInput = useCallback(
    (newText, currentState) => {
      if (newTextLength(newText, currentState) > maxLength) return EVENT.HANDLED;
      return EVENT.NOT_HANDLED;
    },
    [maxLength],
  );

  const handlePastedText = useCallback(
    (pastedText) => {
      const handled = onPaste?.(pastedText);
      if (handled) return EVENT.HANDLED;

      if (newTextLength(pastedText, state) > maxLength) return EVENT.HANDLED;
      return EVENT.NOT_HANDLED;
    },
    [maxLength, onPaste, state],
  );

  // Listen to 'mousedown' rather than 'click' so that the handler runs before other click handlers.
  // In particular, we want to allow resolving hashtags before submitting when clicking the save button.
  useClickOutside(wrapperRef, handleClickOutside, 'mousedown');

  useDecorator({ highlightButtonText, openDropdown, name });

  useEffect(() => {
    if (autoFocus && composerRef.current) composerRef.current.focus();
  }, [autoFocus]);

  return (
    <div
      className={cx(styles.composerWrapper, className, {
        [styles.formControlMischka]: fieldColor === FIELD_COLOR.MISCHKA,
      })}
      ref={wrapperRef}
    >
      <Editor
        ref={composerRef}
        editorState={state}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        keyBindingFn={keyBindingFn}
        handleKeyCommand={handleKeyCommand}
        handlePastedText={handlePastedText}
        handleBeforeInput={handleBeforeInput}
        placeholder={placeholder}
        readOnly={readOnly}
        stripPastedStyles
      />
      {dropdownState.open && (
        <DropdownContainer
          name={name}
          close={closeDropdown}
          constructTagItem={constructTagItem}
          dropdownComponent={dropdownComponent}
          extra={dropdownExtra}
          selectedText={dropdownState.selectedText}
          fromHashtag={dropdownState.fromHashtag}
          onInsertTag={onInsertTag}
        />
      )}
    </div>
  );
};

Composer.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  highlightButtonText: PropTypes.string,
  maxLength: PropTypes.number,
  onEnter: PropTypes.func,
  onSave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onPaste: PropTypes.func,
  onInsertTag: PropTypes.func,
  onEscape: PropTypes.func,
  onClickOutside: PropTypes.func,
  constructTagItem: PropTypes.func,
  dropdownExtra: PropTypes.shape({}),
  dropdownComponent: PropTypes.elementType.isRequired,
  potentialTagItems: PropTypes.arrayOf(TagItemType).isRequired,
  fieldColor: PropTypes.oneOf(Object.values(FIELD_COLOR)),
};

export default Composer;
