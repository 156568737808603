import PropTypes from 'prop-types';

/** @deprecated For new uses, use TS and the twin type in frontend/src/types/language.ts. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
const LanguageType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
});

export default LanguageType;
