import cx from 'classnames';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import removeTag from 'frontend/assets/images/remove_tag.svg?url';
import { ChildrenType } from 'frontend/propTypes';
import { onEnter } from 'frontend/utils';

import styles from './EntityItemTag.scss';
import { hueToEntityColor } from '../../utils';

const EntityItemTag = ({
  item,
  remove,
  setRef,
  children,
  onClick,
  outline,
  className,
  hue,
  onPressEnter,
  tabbable,
}) => {
  const hasHue = !isNil(hue);
  const classNames = cx(styles.itemTag, className, {
    [styles.itemTagClickable]: Boolean(onClick),
    [styles.itemTagOutline]: Boolean(outline),
    [styles.itemTagWithHue]: hasHue,
  });
  const hueStyle = hasHue ? { background: hueToEntityColor(hue) } : {};

  return (
    <div
      className={classNames}
      ref={setRef}
      onClick={onClick}
      style={hueStyle}
      tabIndex={tabbable ? 0 : -1}
      onKeyPress={onEnter(onPressEnter)}
      role="button"
    >
      {children || item}
      {remove && (
        <button
          className={styles.itemTagRemove}
          onMouseDown={remove}
          tabIndex={-1}
          contentEditable={false}
          type="button"
        >
          <img src={removeTag} alt="Remove" />
        </button>
      )}
    </div>
  );
};

EntityItemTag.propTypes = {
  item: PropTypes.string,
  remove: PropTypes.func,
  setRef: PropTypes.func,
  onClick: PropTypes.func,
  onPressEnter: PropTypes.func,
  outline: PropTypes.bool,
  className: PropTypes.string,
  hue: PropTypes.number,
  children: ChildrenType,
  tabbable: PropTypes.bool,
};

export default EntityItemTag;
