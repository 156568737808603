import cx from 'classnames';

import styles from './PageContent.scss';

interface PageContentProps {
  className?: string;
  children: React.ReactNode;
  hasFormValidation?: boolean;
}

const PageContent = ({ className = '', children, hasFormValidation = false }: PageContentProps) => {
  const classNames = cx(styles.pageContent, className, { [styles.pageContentWithFormValidation]: hasFormValidation });
  return (
    <div tabIndex={-1} className={classNames}>
      {children}
    </div>
  );
};

export default PageContent;
