import { init } from '@sentry/browser';

export default () => {
  /** Initialize sentry */
  const sentryEnvironment = window.env.SENTRY_ENVIRONMENT;
  init({
    dsn: window.env.SENTRY_DSN_FRONTEND,
    environment: sentryEnvironment,
    release: window.env.APP_VERSION,
    maxValueLength: 600,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [],
  });
};
