import { get } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentClusterIndex, setMessageType } from 'frontend/state/dux/messageClustering';

export default ({ clusterDetailTopRef }) => {
  const dispatch = useDispatch();
  const { currentClusterIndex, messageType } = useSelector(({ messageClustering }) => messageClustering);

  const setIndex = useCallback((index) => dispatch(setCurrentClusterIndex(index)), [dispatch]);
  const setType = useCallback((type) => dispatch(setMessageType(type)), [dispatch]);

  const selectCluster = useCallback(
    (idx) => {
      setIndex(idx);
      const headlineRef = get(clusterDetailTopRef, 'current');
      if (headlineRef) headlineRef.scrollIntoView({ block: 'center' });
    },
    [clusterDetailTopRef, setIndex],
  );

  return { currentClusterIndex, messageType, setIndex, setType, selectCluster };
};
