import { gql } from '@apollo/client';

import { KINDLY_ENTITY } from '../fragments';

export default gql`
  query BotKindlyEntities($botId: ID!, $languageCode: String) {
    bot(id: $botId) {
      id
      kindlyEntities(languageCode: $languageCode) {
        ...KindlyEntity
      }
    }
  }
  ${KINDLY_ENTITY}
`;
