import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import {
  AddOrganizationMembershipDocument,
  AllPermissionsDocument,
  CreateInvitationDocument,
  GetAllInvitationsDocument,
  OrganizationInvitationsDocument,
} from 'frontend/api/generated';
import { useIsRunning, useToast } from 'frontend/hooks';
import { combineFunctions } from 'frontend/utils';

import AddMemberModal from '../AddMemberModal/AddMemberModal';
import { roleIdsFromFields } from '../AddMemberModal/utils';

const adminUpdate = appendArrayInCache({
  pathToArrayInCache: 'allInvitations',
  pathToItemInMutationData: 'createInvitation',
  query: GetAllInvitationsDocument,
});

type Props = {
  hide: (...args: unknown[]) => Promise<void>;
  args: {
    id: string;
    name: string;
    onFinished?: (...args: unknown[]) => unknown;
  };
};

function AddMemberToOrganization({ hide, args: { name, id, onFinished } }: Props) {
  const toast = useToast();
  const { data, loading } = useQuery(AddOrganizationMembershipDocument, { variables: { id } });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(AllPermissionsDocument);

  const organizationUpdate = appendArrayInCache({
    pathToArrayInCache: 'organizationInvitations',
    pathToItemInMutationData: 'createInvitation',
    query: OrganizationInvitationsDocument,
    variables: { organizationId: id },
  });

  const [mutate] = useMutation(CreateInvitationDocument, { update: combineFunctions(organizationUpdate, adminUpdate) });

  const allPermissions = permissionsData?.allPermissions ?? [];
  const roles = data?.organization.roles ?? [];
  const currentUserEmails = (data?.organization.users ?? []).map(({ username }) => username);

  const submit = useCallback(
    async (values: { email: string }) => {
      const organizationRoleIds = roleIdsFromFields(values);
      const input = { organizationId: id, organizationRoleIds };
      await mutate({ variables: { email: values.email, input } });
      toast.success(`${values.email} was invited to ${name}!`);
      if (onFinished) {
        onFinished();
      } else {
        hide();
      }
    },
    [hide, id, mutate, name, onFinished, toast],
  );

  const [onSubmit, isSubmitting] = useIsRunning(submit);

  return (
    <AddMemberModal
      to={{ organizationId: id }}
      type="input"
      users={[]}
      existingEmails={currentUserEmails}
      hide={hide}
      name={name}
      roles={roles}
      allPermissions={allPermissions}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      loading={loading || permissionsLoading}
    />
  );
}

export default AddMemberToOrganization;
