import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { type BotApiKeyType, RegenerateBotApiKeyDocument } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

interface RegenerateBotApiKeyProps {
  hide: () => void;
  args: {
    botId: string;
    botApiKey: BotApiKeyType;
  };
}

const RegenerateBotApiKey = ({ hide, args: { botId, botApiKey } }: RegenerateBotApiKeyProps) => {
  const toast = useToast();
  const update = () => toast.success('API key regenerated!');
  const [regenerateKey] = useMutation(RegenerateBotApiKeyDocument, { update });
  const onSubmit = useCallback(async () => {
    await regenerateKey({ variables: { botId, id: botApiKey.id } });
  }, [botId, botApiKey.id, regenerateKey]);

  return (
    <DeleteModal onSubmit={onSubmit} name="API key" hide={hide} action="regenerate">
      Are you sure you want to regenerate the API key {`'${botApiKey.name}'`}? Anyone using it to access the API will no
      longer be able to.
    </DeleteModal>
  );
};

export default RegenerateBotApiKey;
