const DIALOGUE_TYPES = {
  SAMPLES: 'SAMPLES',
  KEYWORDS: 'KEYWORDS',
  PATTERNS: 'PATTERNS',
  TRIGGER: 'TRIGGER',
  GREETING: 'GREETING',
  FALLBACK: 'FALLBACK',
  SYSTEM_DIALOGUE: 'SYSTEM',
  REGULAR: 'REGULAR',
  INTENT: 'INTENT',
} as const;

export type DialogueType = (typeof DIALOGUE_TYPES)[keyof typeof DIALOGUE_TYPES];

export const regularDialogueTypes: DialogueType[] = [
  DIALOGUE_TYPES.INTENT,
  DIALOGUE_TYPES.SAMPLES,
  DIALOGUE_TYPES.KEYWORDS,
  DIALOGUE_TYPES.PATTERNS,
  DIALOGUE_TYPES.TRIGGER,
];

export const isRegularDialogueType = (dialogueType) =>
  dialogueType === DIALOGUE_TYPES.REGULAR || regularDialogueTypes.includes(dialogueType);

export default DIALOGUE_TYPES;
