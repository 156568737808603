import { sortBy } from 'lodash';

export default (topics) =>
  sortBy(topics, [
    (o) => {
      let name = 'Missing folder';
      if (o.name) {
        name = o.name.toLowerCase();
      }
      return name;
    },
  ]);
