import { gql } from '@apollo/client';

import { subscribedSkillFragment } from 'frontend/api/fragments';

export default gql`
  query SubscribedSkills($botId: ID!) {
    bot(id: $botId) {
      id
      skillSubscriptions {
        ...SubscribedSkill
      }
    }
  }
  ${subscribedSkillFragment}
`;
