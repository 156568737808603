import cx from 'classnames';
import type { SelectSearchProps } from 'react-select-search';

import { Tooltip } from 'frontend/components';

import styles from './SelectDialogue.scss';

const DialogueOption: SelectSearchProps['renderOption'] = (
  optionProps,
  { name, tooltip, value, isActive, activeOnly },
  { highlighted, selected },
) => {
  const buttonStyles = cx(styles.renderDialogue, {
    [styles.disabled]: !isActive && !activeOnly,
    [styles.highlighted]: highlighted,
    [styles.isSelected]: selected,
  });

  return (
    <Tooltip className={styles.tooltipWrapper}>
      {/* @ts-expect-error Upstream react-select-search types are incorrect */}
      <button type="button" {...optionProps} className={buttonStyles} value={value}>
        {name}
      </button>
      {tooltip && <Tooltip.Body className={styles.tooltip}>{tooltip}</Tooltip.Body>}
    </Tooltip>
  );
};

export default DialogueOption;
