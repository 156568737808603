import { Button, Panel } from 'frontend/components';

import styles from './styles.scss';

interface CreateCard {
  text: string;
  title: string;
  image: string;
  link: string;
}

const CreateCard = ({ title, text, image, link }: CreateCard) => (
  <Panel className={styles.createPanel}>
    <div className={styles.nudgeIllustration} style={{ backgroundImage: `url(${image})` }} />
    <div className={styles.textContainer}>
      <h5>{title}</h5>
      <p className="m-b-0">{text}</p>
      <Button className={styles.createButton} color="primary" to={link}>
        Create
      </Button>
    </div>
  </Panel>
);

export default CreateCard;
